import styled from 'styled-components';

const ProfileCardWrapper = styled.div`
  margin: 25px 15px;
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;

  @media print {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .arrow-large {
    font-size: 18px;
  }
`;

export default ProfileCardWrapper;
