import storage from './utils/storage';
import store from './store';

import { isProductionEnv, isDevelopmentEnv, isTestingEnv } from './appVersion';
import impersonateUser from './utils/impersonateUser';

export const debugModeNamespace = 'bmsDebug';

const defaultLoggerOptions = {
  showPropsChange: true,
  showActionsDispatch: true,
  showPropsChangeLogFor: [
    'Field',
    'ObjectFieldWrapper',
    'ObjectField',
    'Section',
    'SectionWrapper',
    'Tabs',
    'TabsHeader',
    'Repeater',
    'RequirementCompliant',
    'Grid',
    'FieldGroupItem',
    'TabsContent',
    'ConditionalAssert',
    'SectionChildren',
    'SectionWithConditions',
  ],
  showActionLogsFor: [],
};

const booleanValues = ['showPropsChange', 'showActionsDispatch'];

export const getDebugMode = () => global[debugModeNamespace];

const exposeDebugMode = () => {
  global[debugModeNamespace] = { loggerIsEnabled: false };
  global[debugModeNamespace].getEnv = () => JSON.stringify(process.env);
  global[debugModeNamespace].getState = store.getState;

  global[debugModeNamespace].getLoggerValue = (propName, defaultValue) => {
    const value = global[debugModeNamespace][`log.${propName}`];
    if (value === undefined && defaultValue !== undefined) {
      return defaultValue;
    }
    return value;
  };

  global[debugModeNamespace].disableLogger = options => {
    global[debugModeNamespace].loggerIsEnabled = false;

    const config = Object.assign(
      {},
      defaultLoggerOptions,
      {
        showPropsChange: false,
        showActionsDispatch: false,
      },
      options
    );

    Object.keys(config).forEach(propName => {
      if (booleanValues.includes(propName)) {
        global[debugModeNamespace][`log.${propName}`] = false;
      } else {
        if (Object.keys(defaultLoggerOptions).includes(propName)) {
          global[debugModeNamespace][`log.${propName}`] = config[propName];
        }
      }
    });

    return global[debugModeNamespace].loggerIsEnabled;
  };

  global[debugModeNamespace].enableLogger = options => {
    global[debugModeNamespace].loggerIsEnabled = true;

    const config = Object.assign({}, defaultLoggerOptions, options);

    Object.keys(config).forEach(propName => {
      if (booleanValues.includes(propName)) {
        global[debugModeNamespace][`log.${propName}`] = true;
      } else {
        if (Object.keys(defaultLoggerOptions).includes(propName)) {
          global[debugModeNamespace][`log.${propName}`] = config[propName];
        }
      }
    });

    return global[debugModeNamespace].loggerIsEnabled;
  };

  if (isDevelopmentEnv) {
    global[debugModeNamespace].inpersonateUser = impersonateUser;
  }

  global[debugModeNamespace].__exposed = true;

  return global[debugModeNamespace];
};

export default () => {
  global[debugModeNamespace] = () => {};

  if (isTestingEnv) return;

  if (isDevelopmentEnv) {
    return exposeDebugMode();
  }

  global[debugModeNamespace] = () => {
    const token = storage.get('token');

    if (global[debugModeNamespace].__exposed && isProductionEnv) {
      return global[debugModeNamespace];
    }

    if (isDevelopmentEnv) {
      return exposeDebugMode();
    } else if (
      global.prompt('PLEASE STOP IF YOU DONT KNOW WHAT IT MEANS') === token
    ) {
      exposeDebugMode();
    }
  };
};
