import styled from 'styled-components';

import { BLUE, DARK_GRAY } from '../../../config/theme';

const getFontSize = size => {
  switch (size) {
    case 'medium':
      return 16;
    case 'large':
      return 20;
    case 'x-large':
      return 32;
    default:
      return 14;
  }
};

const SectionTitle = styled.h2.attrs({
  className: 'bdm-form-section-title',
})`
  margin-bottom: 15px;
  font-weight: ${props =>
    props.size === 'small' || props.size === 'medium' ? 'bold' : '600'};
  font-size: ${props => getFontSize(props.size)}px;
  color: ${DARK_GRAY};
  line-height: 1.2em;

  &.double-margin-bottom {
    margin-bottom: 30px;
  }

  .anticon {
    font-size: 0.8em;
    color: ${BLUE};
    cursor: help;
  }
`;

export default SectionTitle;
