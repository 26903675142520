import { castArray } from 'lodash';
import getAllFieldsFlat from './getAllFieldsFlat';

/**
 *
 * @param {Object} fields: form fields
 * @param {Array | String} fieldKeys: to look relation into fields
 * @param {function} getRelated: function to evaluate the field relation
 * @returns Array of related fields
 */
const getFieldsRelated = (fields, fieldKeys, getRelated) => {
  const _fieldKeys = castArray(fieldKeys).map(f => f.key);
  const allFields = getAllFieldsFlat(fields);
  const { fieldsByKey } = allFields;
  return Object.keys(fieldsByKey).reduce((all, key) => {
    const field = fieldsByKey[key];
    return getRelated(_fieldKeys, field, all);
  }, []);
};

export default getFieldsRelated;
