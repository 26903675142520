import { get, pickBy } from 'lodash';
import getFormFields from './getFormFields';
import { AUTOCOMPLETE } from 'APP_ROOT/constants/fieldTypes';
import validateComponentsTree from './validateComponentsTree';
/**
 *
 * @param {Object} validations validations section from the FB json file
 * @param {Object} form form validations section from the FB json file
 * @param {Object} enums enums section from the FB json file
 * @returns {Object} object with the validations verified and keys
 *                  removed found { validations, removed}
 */
const verifyFields = (validations, form, enums, isNote) => {
  const properties = get(form, 'properties', []);

  const { fieldsByKey, repeatersByKey } = properties.reduce(getFormFields, {
    fieldsByKey: {},
    repeatersByKey: {},
  });
  const allFieldsByKey = { ...fieldsByKey, ...repeatersByKey };
  const allAutoCompleteFieldsByKey = pickBy(
    allFieldsByKey,
    value => value.field_type === AUTOCOMPLETE
  );
  const newFields = validateComponentsTree(
    properties,
    validations,
    isNote,
    allAutoCompleteFieldsByKey
  );

  return {
    form: { ...form, properties: newFields.properties },
    validations: newFields.validations,
    calculated: newFields.calculated,
    stringType: newFields.stringType,
    added: newFields.added,
  };
};

export default verifyFields;
