import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import first from 'lodash/first';
import kebabCase from 'lodash/kebabCase';
import _template from 'lodash/template';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';

import { getFormTemplates, getSelectedForm } from '../../selectors/form';

import getFormTemplate from '../../actions/get-form-template';

import route from '../../utils/get-route';

class LinkingFormsDescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templateId: undefined,
      linkedFormTemplateId: undefined,
      linkedMessageFormat: undefined,
      linkedFormShortName: undefined,
      formShortName: undefined,
      linkedFormTemplateType: undefined,
      linkedFormTemplateName: undefined,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      propsHasChanged(nextProps, this.props) ||
      propsHasChanged(nextState, this.state)
    );
  }

  getLinkedData = (linkedFormTemplateId, linkedFormTemplateType) => {
    const {
      selectedForm: { meta: { formType = '', templateId, agencyId } = {} },
      templates,
      dispatch,
    } = this.props;

    this.setState({ templateId, linkedFormTemplateId, linkedFormTemplateType });
    const linkedFormTemplate = get(
      templates,
      kebabCase(linkedFormTemplateType),
      null
    );
    const template = get(templates, kebabCase(formType), null);

    if (!template) {
      dispatch(
        getFormTemplate(
          { templateId, agencyIdFromOptions: agencyId },
          (error, response) => {
            this.saveTemplateData(response);
          }
        )
      );
    } else {
      this.saveTemplateData(template);
    }
    if (!linkedFormTemplate) {
      dispatch(
        getFormTemplate(
          {
            templateId: linkedFormTemplateId,
            agencyIdFromOptions: agencyId,
          },
          (error, response) => {
            this.saveLinkedFormTemplateData(response);
          }
        )
      );
    } else {
      this.saveLinkedFormTemplateData(linkedFormTemplate);
    }
  };

  componentDidUpdate(prevProps) {
    const {
      selectedForm: { meta: { linkedForms = [], templateId } = {} },
      templates,
    } = this.props;

    if (linkedForms.length === 1 && linkedForms[0]) {
      const firstLinkedForm = first(linkedForms);
      const linkedFormTemplateType = get(firstLinkedForm, 'formType', '');
      const linkedFormTemplateName = get(
        templates,
        `${linkedFormTemplateType}.name`,
        linkedFormTemplateType
      );
      const linkedFormTemplateId = get(firstLinkedForm, 'templateId', '');

      if (
        linkedFormTemplateId !== this.state.linkedFormTemplateId ||
        templateId !== this.state.templateId
      ) {
        this.setState({ linkedFormTemplateName });
        this.getLinkedData(linkedFormTemplateId, linkedFormTemplateType);
      }
    }
  }

  getReportRoute(id) {
    const {
      match: {
        params: { agencyId },
      },
    } = this.props;
    if (agencyId) {
      return route('administratorAgencyReport', {
        agencyId,
        id,
      });
    } else {
      return route('report', { id });
    }
  }

  saveTemplateData = template => {
    const {
      selectedForm: { meta: { formType = '' } = {} },
    } = this.props;

    const defaultLinkedMessageFormat =
      'This <% print(templateName) %> involves a <% print(linkedFormName) %> report :linkedFormLink:';
    const linkedMessageFormat = get(
      template,
      'linkedFillMessageFormat',
      defaultLinkedMessageFormat
    );
    const formShortName = get(template, 'shortName', formType);

    this.setState({ linkedMessageFormat, formShortName });
  };

  saveLinkedFormTemplateData = linkedFormTemplate => {
    const { linkedFormTemplateType } = this.state;
    const linkedFormShortName = get(
      linkedFormTemplate,
      'shortName',
      linkedFormTemplateType
    );

    this.setState({ linkedFormShortName });
  };

  getLinkedFormTemplate = () => {
    const {
      selectedForm: {
        meta: {
          linkedForms = [],
          formType = '',
          templateId,
          agencyId,
          ...currentForm
        } = {},
        template: { name = '', label = '' } = {},
      },
    } = this.props;
    const firstLinkedForm = first(linkedForms);

    if (firstLinkedForm) {
      const {
        linkedMessageFormat,
        linkedFormShortName,
        formShortName,
        linkedFormTemplateType,
        linkedFormTemplateName,
        linkedFormTemplateId,
      } = this.state;

      const linkedFormLink = (
        <Link to={this.getReportRoute(firstLinkedForm.id)}>
          {firstLinkedForm.formNumber}
        </Link>
      );
      const formLink = (
        <Link to={this.getReportRoute(currentForm.id)}>
          {currentForm.formNumber}
        </Link>
      );

      const messageFormat = _template(linkedMessageFormat);
      const message = messageFormat({
        formType,
        templateName: name || label,
        formTemplateId: templateId,
        formShortName,
        linkedFormType: linkedFormTemplateType,
        linkedFormName: linkedFormTemplateName,
        linkedFormShortName,
        linkedFormTemplateId,
      }).split(' ');

      const reservedNames = [':linkedFormLink:', ':formLink', ':icon:'];

      return (
        <span>
          {message.map((word, wordIndex) => {
            if (reservedNames.includes(word)) {
              switch (word) {
                case reservedNames[0]:
                  return <span key={wordIndex}>{linkedFormLink} </span>;
                case reservedNames[1]:
                  return <span key={wordIndex}>{formLink} </span>;
                case reservedNames[2]:
                  return <span key={wordIndex} />;
                default:
                  return <span key={wordIndex}>{word} </span>;
              }
            }

            return <span key={wordIndex}>{word} </span>;
          })}
        </span>
      );
    }

    return <span />;
  };

  render() {
    const {
      selectedForm: { meta: { linkedForms = [], formType = '' } = {} },
    } = this.props;

    let involvedForms = null;

    if (!!linkedForms.length) {
      if (linkedForms.length === 1) {
        involvedForms = this.getLinkedFormTemplate();
      } else {
        involvedForms = (
          <span>
            This {formType} involves the following{' '}
            {linkedForms.map((form, index) => {
              return (
                <span key={index}>
                  <Link key={form.id} to={route('report', { id: form.id })}>
                    {form.formNumber}
                  </Link>
                  {index < linkedForms.length - 1 && ', '}
                </span>
              );
            })}
            <br />
          </span>
        );
      }
    }

    return <span className="linking-form-description">{involvedForms}</span>;
  }
}

const mapState = state => ({
  templates: getFormTemplates(state),
  selectedForm: getSelectedForm(state),
});

export default connect(mapState)(LinkingFormsDescription);
