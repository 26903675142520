import React from 'react';
import ContentLoader from 'react-content-loader';
import { Row, Col } from 'antd';
import styled from 'styled-components';

import If from '../../../../components/utils/ConditionalRender';

const FieldLoadingSkeleton = () => (
  <ContentLoader
    height={20}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
    <rect x="165" y="0" rx="3" ry="3" width="200" height="20" />
  </ContentLoader>
);

const FormSkeleton = ({ small = false, className }) => (
  <div className={className}>
    <Row>
      <Col xs={24} sm={12} md={12} lg={12}>
        <FieldLoadingSkeleton />
      </Col>
    </Row>
    <Row>
      <Col xs={24} sm={12} md={12} lg={12}>
        <FieldLoadingSkeleton />
      </Col>
      <Col xs={24} sm={12} md={12} lg={12}>
        <FieldLoadingSkeleton />
      </Col>
    </Row>
    <Row>
      <Col xs={24} sm={12} md={12} lg={12}>
        <FieldLoadingSkeleton />
      </Col>
      <Col xs={24} sm={12} md={12} lg={12}>
        <FieldLoadingSkeleton />
      </Col>
    </Row>
    <If condition={!small}>
      <div>
        <Row>
          <Col xs={24} sm={12} md={12} lg={12}>
            <FieldLoadingSkeleton />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} md={12} lg={12}>
            <FieldLoadingSkeleton />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <FieldLoadingSkeleton />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} md={12} lg={12}>
            <FieldLoadingSkeleton />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <FieldLoadingSkeleton />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} md={12} lg={12}>
            <FieldLoadingSkeleton />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} md={12} lg={12}>
            <FieldLoadingSkeleton />
          </Col>
        </Row>
      </div>
    </If>
  </div>
);

export default styled(FormSkeleton)`
  svg {
    max-width: 400px;
    margin-bottom: 20px;
    padding: 5px 0;
  }
`;
