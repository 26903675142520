import React, { Component } from 'react';
import { groupBy, capitalize } from 'lodash';
import { Row, Col, Input as InputField } from 'antd';
import { Field } from 'redux-form';

import Section from 'APP_COMPONENTS/form-viewer/styled/section';
import ProfileCardWrapper from '../styled/ProfileCardWrapper';
import LabelInRow from '../styled/LabelInRow';
import Title from 'APP_COMPONENTS/form-viewer/styled/section-title';
import Input from '../../../administrator/components/input';
import getStatusDate from '../helpers';

const TextField = Input(InputField);

class Firearm extends Component {
  getEquipmentItems(listItems) {
    return listItems.map(item => {
      return (
        <Section key={item.id}>
          <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="equipmentTypeType"
                label="Type"
                component={TextField}
                defaultValue={item.equipmentTypeType}
                disabled={true}
                plainText={true}
              />
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="equipmentTypeSubtype"
                label="Subtype"
                component={TextField}
                defaultValue={item.equipmentTypeSubtype}
                disabled={true}
                plainText={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="equipmentMake"
                label="Make"
                component={TextField}
                defaultValue={item.equipmentMake}
                disabled={true}
                plainText={true}
              />
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="equipmentModel"
                label="Model"
                component={TextField}
                defaultValue={item.equipmentModel}
                disabled={true}
                plainText={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="equipmentCaliberClass"
                label="Caliber/Class"
                component={TextField}
                defaultValue={item.equipmentCaliberClass}
                disabled={true}
                plainText={true}
              />
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="equipmentImporter"
                label="Importer"
                component={TextField}
                defaultValue={item.equipmentImporter}
                disabled={true}
                plainText={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="equipmentSerialNumber"
                label="Serial Number"
                component={TextField}
                defaultValue={item.equipmentSerialNumber}
                disabled={true}
                plainText={true}
              />
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="equipmentRegistrationNumber"
                label="Registration Number"
                component={TextField}
                defaultValue={item.equipmentRegistrationNumber}
                disabled={true}
                plainText={true}
              />
            </Col>
          </Row>
        </Section>
      );
    });
  }

  getCertificationItems(listItems, timezone) {
    return listItems.map(item => {
      return (
        <Row key={item.id}>
          <Col xs={24} sm={8} md={8} lg={8}>
            <LabelInRow>
              {item.course.courseLink ? (
                <a href={item.course.courseLink}>{item.course.name}</a>
              ) : (
                item.course.name
              )}
            </LabelInRow>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8}>
            <Field
              type="text"
              name="completionDate"
              component={TextField}
              defaultValue={getStatusDate(
                item.statusName,
                item.completionDate,
                timezone
              )}
              disabled={true}
              plainText={true}
            />
          </Col>
        </Row>
      );
    });
  }

  render() {
    const { equipment = [], firearmCertifications = [], timezone } = this.props;
    const groupByType = groupBy(equipment, 'equipmentType');
    const groups = Object.keys(groupByType);
    return (
      <ProfileCardWrapper>
        {groups.map(title => {
          return (
            <Section
              key={title}
              className="bdm-form-section--no-padding-bottom bdm-form-section--no-margin-bottom"
            >
              <Row>
                <Col className="gutter-row" span={24}>
                  <Title size="default">{capitalize(title)}</Title>
                </Col>
              </Row>
              {this.getEquipmentItems(groupByType[title])}
            </Section>
          );
        })}
        <Section className="bdm-form-section--no-padding-bottom bdm-form-section--no-margin-bottom">
          <Row>
            <Col className="gutter-row" span={24}>
              <Title size="default">Certifications</Title>
            </Col>
          </Row>
          {this.getCertificationItems(firearmCertifications, timezone)}
        </Section>
      </ProfileCardWrapper>
    );
  }
}

export default Firearm;
