import styled from 'styled-components';

const SectionWrapper = styled.div.attrs({
  className: 'bdm-section-wrapper',
})`
  position: relative;
  border-radius: 6px;
  background-color: #fff;

  @media screen {
    margin: 10px 0 20px;
    padding: 24px 16px;
  }

  @media screen and (max-width: 992px) {
    margin: 15px 0;
    padding: 20px 16px;
  }

  &.no-bottom-padding {
    padding-bottom: 0;
  }

  &.bdm-section-wrapper-no-padding {
    padding: 0;
  }
`;

export default SectionWrapper;
