export const FETCH_STATUSES_LOADING = 'FETCH_STATUSES_LOADING';
export const FETCH_STATUSES_LOADING_SUCCESSFUL =
  'FETCH_STATUSES_LOADING_SUCCESSFUL';
export const FETCH_STATUSES_LOADING_ERROR = 'FETCH_STATUSES_LOADING_ERROR';
export const FETCH_REASONS_LOADING = 'FETCH_REASONS_LOADING';
export const FETCH_REASONS_LOADING_SUCCESSFUL =
  'FETCH_REASONS_LOADING_SUCCESSFUL';
export const FETCH_REASONS_LOADING_ERROR = 'FETCH_REASONS_LOADING_ERROR';
export const FETCH_OUTCOMES_LOADING = 'FETCH_OUTCOMES_LOADING';
export const FETCH_OUTCOMES_LOADING_SUCCESSFUL =
  'FETCH_OUTCOMES_LOADING_SUCCESSFUL';
export const FETCH_OUTCOMES_LOADING_ERROR = 'FETCH_OUTCOMES_LOADING_ERROR';

export const setStatusesLoading = () => ({ type: FETCH_STATUSES_LOADING });

export const setStatusesLoadingError = () => ({
  type: FETCH_STATUSES_LOADING_ERROR,
});

export const setStatusesLoadingSuccessful = statuses => ({
  type: FETCH_STATUSES_LOADING_SUCCESSFUL,
  payload: statuses,
});

export const setReasonsLoading = () => ({ type: FETCH_REASONS_LOADING });

export const setReasonsLoadingError = () => ({
  type: FETCH_REASONS_LOADING_ERROR,
});

export const setReasonsLoadingSuccessful = reasons => ({
  type: FETCH_REASONS_LOADING_SUCCESSFUL,
  payload: reasons,
});

export const setOutcomesLoading = () => ({ type: FETCH_OUTCOMES_LOADING });

export const setOutcomesLoadingError = () => ({
  type: FETCH_OUTCOMES_LOADING_ERROR,
});

export const setOutcomesLoadingSuccessful = outcomes => ({
  type: FETCH_OUTCOMES_LOADING_SUCCESSFUL,
  payload: outcomes,
});
