import get from 'lodash/get';

import {
  GET_AGENCY_TEMPLATES_REQUEST,
  GET_AGENCY_TEMPLATES_FULLFILLED,
  GET_AGENCY_TEMPLATES_REJECTED,
} from '../ConfigureWorkflows.actionTypes';

import apiEndpoints from 'APP_ROOT/modules/FormBuilder/services/apiEndpoints';
import transformTemplateConfiguration, {
  getTemplateType,
  getTemplateOptions,
} from 'APP_ROOT/utils/transformTemplateConfiguration';

export default (
  agencyId,
  config,
  callback,
  { templateTypeKey = 'type' } = {}
) => dispatch => {
  dispatch({ type: GET_AGENCY_TEMPLATES_REQUEST, payload: { agencyId } });

  const next = (...args) =>
    callback && typeof callback === 'function' && callback(...args);

  return new Promise(async (resolve, reject) => {
    try {
      const { data: templates } = await apiEndpoints.getTemplateList({
        agencyId,
        onlyPublished: true,
      });
      const entries = templates.map(template =>
        Object.assign({}, template, {
          config: transformTemplateConfiguration(
            getTemplateOptions(
              getTemplateType(template, templateTypeKey),
              get(config, 'options', [])
            )
          ),
        })
      );
      dispatch({
        type: GET_AGENCY_TEMPLATES_FULLFILLED,
        payload: { agencyId, entries },
      });
      resolve(entries);
      next(null, entries);
    } catch (error) {
      dispatch({
        type: GET_AGENCY_TEMPLATES_REJECTED,
        payload: { agencyId, error },
      });
      reject(error);
      next(error);
    }
  });
};
