import React, { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { Button, Row, Col, message } from 'antd';
import { initialize, submit, change, arrayPush, arrayRemove } from 'redux-form';
import { connect } from 'react-redux';
import { get, omit, has, unionWith, isEqual, orderBy } from 'lodash';
import { Link } from 'react-router-dom';
import { stopSubmit } from 'redux-form';

import PageHeader from './PageHeader';
import withModal from 'APP_COMPONENTS/common/modal/base';
import createModal from '../../../../actions/create-modal';
import showModal from '../../../../actions/show-modal';
import modalMessages from './errors/UserErrorMessages';
import { apiErrorPayload } from './submission/showErrorSubmission';

import getRoute from 'APP_ROOT/utils/get-route';
import { VIEW_USER, ADD_USER } from '../AgencyUsers.constants';
import ProfileCardWrapper from './ProfileCardWrapper.styled';
import UserInformation from './UserInformation/UserInformation';
import UserStatus from './UserStatus';
import UserContactInformation from './UserContactInformation';
import UserEmploymentHistory from './UserEmploymentHistory';
import FooterActions from './FooterActions.styled';
import TextActions from './TextActions.styled';
import connectForm from '../../components/connected-forms';
import getUserInfo from '../actions/get-user';
import getUserSections from '../actions/get-user-sections';
import getUnitAssignments from '../../agency-profile/actions/get-unit-assignments';
import getRanks from '../../agency-profile/actions/get-ranks';
import getUserSecurityRoles from '../../agency-profile/actions/get-user-security-roles';
import getAgencySecurityRoles from '../../agency-profile/actions/get-security-roles';
import getAgency from '../../agency-profile/actions/get-agency-info';
import getSectionsData from './getSectionsData';
import getUserEmployment from '../actions/get-user-employment';
import getOrganizationalUnits from '../actions/get-organizational-units';
import getOrganizationalUnitsTree from '../actions/get-organizational-units-tree';
import getOrganizationalUnitsAssignedToUser from '../actions/get-organizational-units-assigned-to-user.js';
import getUserDocuments from '../actions/get-user-documents';
import getUserReports from '../actions/get-user-reports';
import getUserEmploymentConfig from '../actions/get-user-employment-config';
import getUserAssignment from '../actions/get-user-assignment.js';
import {
  getLoadUserEmploymentDetail,
  getUserEmploymentData,
} from './employment-history/ou-attribute-action-open-modal';
import attributeActions from './employment-history/ou-attribute-action-literals';
import { genOptions, route, fetchRequestFile } from 'APP_ROOT/utils/request';
import storage from 'APP_ROOT/utils/storage';

import {
  selectAgency,
  selectAgencyUserSubmissionState,
} from '../../agency-profile/AgencyProfile.selectors';
import { getAgencyUser, getFormValues } from '../AgencyUsers.selectors';
import { formatInchesToFtInch } from '../../../../utils/units-transformation';

import parseDate, {
  BENCHMARK_DATE_TIMEZ_FORMAT,
  BENCHMARK_DATE_FORMAT,
  formatDate,
} from '../../../../utils/parse-date';
import { hasPermissions, PERMISSIONS } from '../../../../utils/admin';

import { shouldSeeAnyField } from './profileFieldsVisibility';
import { shouldBeReadOnlyField } from './profileFieldsEditable';
import { getFieldLabel } from './profileFieldsLabel';

import configurationEndpoints from '../../../../api/configuration/configurationEndpoints';
import { registerNewDocument } from '../../../../api/documents';

import {
  ORGANIZATION_HISTORY_CONFIG,
  EMPLOYMENT_HISTORY_CONFIG,
} from './UserProfile.constants';
import moment from 'moment';

import UserSections from '../UserSections';

import {
  userProfileFailureLog,
  callUserProfileSuccessLog,
} from '../actions/user-profile-views-logs';
import { onUserSubmit } from './submission';
import {
  PROTECTED_FIELDS_CREATE,
  PROTECTED_FIELDS_EDIT,
  FIELDS_SPECIAL_PERMISSIONS_SEE,
  FIELDS_SPECIAL_PERMISSIONS,
  FIELDS_SPECIAL_PERMISSIONS_EDITABLE,
} from './constants/fields-see-permissions.constants';
import { userProfileFormName } from './constants/form-values.constants';

const LEAVE_MESSAGE =
  'You have unsaved changes, are you sure you want to leave?';
class UserProfile extends withModal(Component) {
  state = {
    loading: true,
    profileConfiguration: {},
    sectionsDefinition: [],
    employmentHistoryData: [],
    organizationalUnitList: [],
    organizationalUnitTreeList: [],
    organizationalUnitsAssignedToUserList: [],
    employmentRankOptions: [],
    userPictureSource: '',
    organizationHistoryAttributes: [],
    userEmploymentAttributes: [],
    customField1Value: '',
    saving: false,
    auditId: '',
    isLogUpdated: false,
  };

  handleWindowBeforeUnload = event => {
    const e = event || window.event;
    if (this.formHasChanged()) {
      e.preventDefault();
      e.returnValue = LEAVE_MESSAGE;
      return LEAVE_MESSAGE;
    }
  };

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleWindowBeforeUnload);
    this.loadData();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleWindowBeforeUnload);
  }

  loadData() {
    const { dispatch, params, hasOwnership = true, session = {} } = this.props;
    if (!hasOwnership) {
      return;
    }
    this.setState({
      loading: true,
      profileConfiguration: {},
      sectionsDefinition: [],
      employmentHistoryData: [],
      organizationalUnitList: [],
      organizationalUnitsAssignedToUserList: [],
      employmentRankOptions: [],
      saving: false,
    });

    const agencyId = get(params, 'agencyId');
    const { currentUser = {} } = session;
    const { permissions = [] } = currentUser;

    const canGetOrganizationalUnitsList = hasPermissions(permissions, [
      PERMISSIONS.manageOUAssignment,
      PERMISSIONS.viewOUAssignment,
      PERMISSIONS.manageAgencies,
      PERMISSIONS.listOrganizationsByHK,
    ]);

    this.getAgency();

    dispatch(getAgencySecurityRoles(agencyId, false));

    dispatch(getUnitAssignments(agencyId));

    dispatch(
      getRanks(agencyId, (err, ranks) => {
        if (err) {
          return;
        }

        this.setState({ employmentRankOptions: ranks });
      })
    );

    configurationEndpoints
      .getAgencyProfileConfiguration(agencyId)
      .then(this.setProfileConfiguration);

    if (canGetOrganizationalUnitsList) {
      this.getOrganizationalUnitsList();
      this.getOrganizationalUnitsTreeList();
    }
  }

  getAgency = () => {
    const {
      action,
      dispatch,
      history: { push },
      params,
      session: {
        currentUser: { permissions = [] },
      },
    } = this.props;

    const agencyId = get(params, 'agencyId');

    const canViewUserEmploymentHistory = hasPermissions(permissions, [
      PERMISSIONS.manageOUAssignment,
      PERMISSIONS.viewOUAssignment,
    ]);

    dispatch(
      getAgency(agencyId, (error, result) => {
        if (error) {
          message.error('The agency does not exist or its invalid');
          push('/administrator');
          return false;
        }
        const agencyName = get(result, 'response.name');
        const tenantId = get(result, 'response.integrationId');
        this.setState({ tenantId });

        if (canViewUserEmploymentHistory) {
          this.getUserEmploymentConfiguration(tenantId);
        }

        if (action === VIEW_USER) {
          this.getUserProfile();
        } else {
          dispatch(
            initialize(userProfileFormName, {
              agency: agencyName,
              agencyId: agencyId,
            })
          );

          this.setDoneLoading();
        }
      })
    );
  };

  getOrganizationalUnitsList = () => {
    const { dispatch } = this.props;
    dispatch(
      getOrganizationalUnits((error, data) => {
        if (error) {
          return;
        }
        this.updateOrganizationalUnitsList(data);
      })
    );
  };

  getUserEmploymentConfiguration = tenantId => {
    const { dispatch } = this.props;
    dispatch(
      getUserEmploymentConfig(
        tenantId,
        EMPLOYMENT_HISTORY_CONFIG,
        (error, data) => {
          if (error) {
            return;
          }
          this.setState({ userEmploymentAttributes: data });
        }
      )
    );

    dispatch(
      getUserEmploymentConfig(
        tenantId,
        ORGANIZATION_HISTORY_CONFIG,
        (error, data) => {
          if (error) {
            return;
          }
          this.setState({ organizationHistoryAttributes: data });
        }
      )
    );
  };

  getOrganizationalUnitsTreeList = () => {
    const { dispatch } = this.props;
    dispatch(
      getOrganizationalUnitsTree((error, data) => {
        if (error) {
          return;
        }
        this.setState({ organizationalUnitTreeList: data });
      })
    );
  };

  getOrganizationalUnitsAssignedToUserList = userIntegrationId => {
    const {
      dispatch,
      session: {
        currentUser: { permissions = [] },
      },
    } = this.props;

    const canViewUserEmploymentHistory = hasPermissions(permissions, [
      PERMISSIONS.manageOUAssignment,
      PERMISSIONS.viewOUAssignment,
    ]);

    dispatch(
      getOrganizationalUnitsAssignedToUser(
        { userIntegrationId },
        (error, data) => {
          if (error) {
            message.error(
              'Something wrong occurred, setting the Agency/Organization name to this profile, please try again later.'
            );
            return false;
          }
          if (canViewUserEmploymentHistory) {
            this.loadUserAssignment(userIntegrationId, data);
          } else {
            this.setState({ organizationalUnitsAssignedToUserList: data });
          }
        }
      )
    );
  };

  addOrganizationalUnits = ({ organizationalUnitHistory = [] }) => {
    const listofMissingOUs = organizationalUnitHistory.map(unit => {
      return {
        value: unit.organizationalUnitId,
        label: unit.organizationalUnitName,
      };
    });
    this.updateOrganizationalUnitsList(listofMissingOUs);
  };

  updateOrganizationalUnitsList = newList => {
    const { organizationalUnitList } = this.state;
    const data = unionWith(organizationalUnitList, newList, isEqual);
    this.setState({ organizationalUnitList: data });
  };

  registerDocumentSpace = userIntegrationId => {
    const {
      dispatch,
      session: {
        currentUser: { permissions = [] },
      },
    } = this.props;
    if (hasPermissions(permissions, [PERMISSIONS.manageDocuments])) {
      registerNewDocument(userIntegrationId).then(data => {
        const { id } = data;
        dispatch(
          change(
            userProfileFormName,
            'userDocumentsData.registeredDocumentId',
            id
          )
        );
      });
    }
  };

  updateUserProfilePicture = ({ documents = [] }) => {
    const userPictures = orderBy(
      documents.filter(d => d.documentType == 'Profile Picture'),
      ['createdAt'],
      'desc'
    );
    if (userPictures.length > 0) {
      const getPictureUrl = userPictures[0].locationUrl;
      const url = route(getPictureUrl);
      const options = genOptions('GET', null, storage.get('token'));
      const s3Url = url.includes('/request-url');
      fetchRequestFile(url, options)
        .then(response => (s3Url ? response.json() : response.blob()))
        .then(s3Response => {
          const url = s3Url
            ? s3Response.url
            : window.URL.createObjectURL(new Blob([s3Response]));
          this.setState({
            userPictureSource: url,
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  };

  callUserProfileFailureLog = async (tenantId, userId) => {
    const { id } = await userProfileFailureLog(tenantId, userId);
    this.setState({ auditId: id });
  };

  getUserProfile = () => {
    const {
      dispatch,
      params,
      history: { push },
      agencyProfile: {
        data: { name: agencyName, integrationId: tenantId },
      },
    } = this.props;

    const agencyId = get(params, 'agencyId');
    const userId = get(params, 'userId');

    dispatch(
      getUserInfo(
        { agencyId, userId, includeDisabled: true },
        (error, data) => {
          const isNotSameAgency = Number(agencyId) !== Number(data.agencyId);
          if (error || isNotSameAgency) {
            let errorMessage =
              'Something wrong occurred, please try again later.';

            if (error && error.status === 404) {
              errorMessage =
                'The requested user does not exist or is no longer active in the system.';
            }

            message.error(errorMessage);
            const goRoute = goRoute(this.props);
            push(getRoute(goRoute, { agencyId }));
            return false;
          }

          const userIntegrationId = data.integrationId;
          this.setState({ userIntegrationId: userIntegrationId });
          this.callUserProfileFailureLog(tenantId, userIntegrationId);

          if (!userIntegrationId) {
            message.error(
              'Something wrong occurred, the requested user does not have assigned an integration id.'
            );
            const goRoute = goRoute(this.props);
            push(getRoute(goRoute, { agencyId }));
            return false;
          }
          if (data.customField1) {
            this.setState({
              customField1Value: data.customField1.value,
            });
          }

          dispatch(
            getUserSecurityRoles(
              userIntegrationId,
              agencyId,
              (error, rolesResponse) => {
                if (error) {
                  const errorMessage =
                    'Something wrong occurred, please try again later.';
                  message.error(errorMessage);
                  const goRoute = goRoute(this.props);
                  push(getRoute(goRoute, { agencyId }));
                  return false;
                }
                const userSecurityRoles = rolesResponse;

                dispatch(
                  getUserSections(
                    {
                      tenantId: tenantId,
                      userId: userIntegrationId,
                    },
                    (error, sections = {}) => {
                      if (error && error.status !== 403) {
                        message.error(
                          'Something wrong occurred, loading custom sections please try again later.'
                        );
                        const goRoute = goRoute(this.props);
                        push(getRoute(goRoute, { agencyId }));
                        return false;
                      }

                      const { sections: sectionList = [] } = sections;

                      // convert sections data into a hash object
                      const sectionsData = getSectionsData(sectionList);

                      this.setState({
                        sectionsDefinition: sectionList,
                      });

                      const { height, equipment = [], ...result } = data;
                      const { feet, inches } = formatInchesToFtInch(height);
                      let organizationalUnitHistoryData = [];
                      let userDocumentsData = [];

                      dispatch(
                        getUserEmployment(
                          {
                            userId: userIntegrationId,
                          },
                          (error, employmentHistory = []) => {
                            if (error && error.status !== 403) {
                              message.error(
                                'Something wrong occurred, loading user employment history please try again later.'
                              );
                            } else {
                              const {
                                organizationalUnitHistory,
                              } = employmentHistory;
                              organizationalUnitHistoryData = {
                                employment: getUserEmploymentData(
                                  organizationalUnitHistory,
                                  this.props
                                ),
                              };
                              this.addOrganizationalUnits(employmentHistory);
                              this.getOrganizationalUnitsAssignedToUserList(
                                userIntegrationId
                              );
                            }

                            dispatch(
                              getUserDocuments(
                                {
                                  userId: userIntegrationId,
                                },
                                (error, data = { documents: [] }) => {
                                  if (error && error.status !== 403) {
                                    message.error(
                                      'Something wrong occurred, loading user documents please try again later.'
                                    );
                                  } else {
                                    this.setState({
                                      documentsData: data.documents,
                                    });
                                    userDocumentsData = {
                                      registeredDocumentId: null,
                                      documents: this.getUserDocumentsData(
                                        data.documents
                                      ),
                                    };
                                    this.registerDocumentSpace(
                                      userIntegrationId
                                    );
                                    this.updateUserProfilePicture(data);
                                  }

                                  dispatch(
                                    getUserReports(
                                      userId,
                                      agencyId,
                                      (error, data = []) => {
                                        let userReportsData = { reports: [] };
                                        if (data.results.length > 0) {
                                          userReportsData = {
                                            reports: this.getUserReportsData(
                                              data.results
                                            ),
                                          };
                                        }
                                        dispatch(
                                          initialize(userProfileFormName, {
                                            ...result,
                                            sectionsData,
                                            organizationalUnitHistoryData,
                                            userDocumentsData,
                                            userReportsData,
                                            feet,
                                            inches,
                                            rank: get(result, 'title'),
                                            role: userSecurityRoles.map(
                                              role => role.id + []
                                            ),
                                            securityRoles: userSecurityRoles,
                                            equipment,
                                            agency: agencyName,
                                            agencyId: agencyId,
                                          })
                                        );
                                        this.registerDocumentSpace(
                                          userIntegrationId
                                        );
                                        this.setDoneLoading();
                                      }
                                    )
                                  );
                                }
                              )
                            );
                          }
                        )
                      );
                    }
                  )
                );
              }
            )
          );
        }
      )
    );
  };

  getUserDocumentsData = (documents = []) => {
    const {
      session: {
        currentUser: {
          agency: { timezone = '' },
        },
      },
    } = this.props;

    const formatedData = documents.map(document => {
      const createdAt = parseDate(
        document.createdAt,
        timezone,
        'MM/DD/YYYY, HH:mm'
      );
      return {
        ...document,
        uploaded: document.createdBy
          ? `${createdAt} by ${document.createdBy} `
          : createdAt,
      };
    });

    const keys = Object.keys(
      omit(formatedData[0], [
        'createdAt',
        'updatedAt',
        'createdBy',
        'lastModifiedBy',
      ])
    );
    return formatedData.reduce(
      (allData, document) => {
        return keys.reduce((data, key) => {
          data[key].push(document[key]);
          return data;
        }, allData);
      },
      keys.reduce((o, key) => ({ ...o, [key]: [] }), {})
    );
  };

  getUserReportsData = (reports = []) => {
    const {
      session: {
        currentUser: {
          agency: { timezone = '' },
        },
      },
    } = this.props;
    const formatedData = reports.map(report => {
      const submittedDate = parseDate(
        report.submittedDate,
        timezone,
        'MM/DD/YYYY, hh:mm:ss A'
      );
      return {
        ...report,
        submittedDate,
      };
    });

    const keys = Object.keys(formatedData[0]);
    return formatedData.reduce(
      (allData, report) => {
        return keys.reduce((data, key) => {
          data[key].push(report[key]);
          return data;
        }, allData);
      },
      keys.reduce((o, key) => ({ ...o, [key]: [] }), {})
    );
  };

  loadUserAssignment = (userId, ouAssignedToUserList) => {
    const {
      dispatch,
      session: {
        currentUser: {
          agency: { timezone = '' },
        },
      },
    } = this.props;

    dispatch(
      getUserAssignment(
        {
          userId,
        },
        (error, userAssignment = []) => {
          if (error) {
            message.error(
              'Something wrong occurred, loading user assignments, please try again later.'
            );
            return false;
          }
          if (userAssignment.length > 0) {
            const organizationalUnitsAssignedToUserList = [];
            ouAssignedToUserList.forEach(ou => {
              const currentAssignment = userAssignment.find(
                ua => ua.organizationalUnitId == ou.value
              );
              if (currentAssignment && currentAssignment.employment) {
                const formattedData = currentAssignment.employment.map(item =>
                  item.effectiveDate
                    ? {
                        ...item,
                        effectiveDate: parseDate(
                          item.effectiveDate,
                          timezone,
                          BENCHMARK_DATE_FORMAT
                        ),
                      }
                    : item
                );
                const assignments = orderBy(
                  formattedData,
                  ['effectiveDate'],
                  ['desc']
                );

                //Check if there is a detail record
                const detailRecord = assignments.find(
                  a =>
                    a.employmentType === 'Detail' &&
                    a.employmentAction === 'Hire'
                );
                const endOfDetailRecord = assignments.find(
                  a =>
                    a.employmentType === 'Detail' &&
                    moment(a.effectiveDate, BENCHMARK_DATE_FORMAT).isAfter(
                      moment(new Date(), BENCHMARK_DATE_FORMAT)
                    ) &&
                    a.employmentAction.toLowerCase().replace(/\s+/g, '') ===
                      'EndofDetail'.toLowerCase()
                );

                if (detailRecord && endOfDetailRecord) {
                  organizationalUnitsAssignedToUserList.push({
                    ...ou,
                    employmentType: detailRecord.employmentType,
                    hireDate: detailRecord.effectiveDate,
                    endOfDetailDate: endOfDetailRecord.effectiveDate,
                    isDetail: true,
                    tourOfDuty: detailRecord.tourOfDuty,
                  });
                } else {
                  organizationalUnitsAssignedToUserList.push({
                    ...ou,
                    employmentType: assignments[0].employmentType,
                    hireDate: null,
                    endOfDetailDate: null,
                    isDetail: false,
                    tourOfDuty: assignments[0].tourOfDuty,
                  });
                }
              }
            });
            this.setState({
              organizationalUnitsAssignedToUserList: orderBy(
                organizationalUnitsAssignedToUserList,
                ['isDetail'],
                'asc'
              ),
            });
          } else {
            this.setState({
              organizationalUnitsAssignedToUserList: ouAssignedToUserList,
            });
          }
        }
      )
    );
  };

  loadUserEmploymentDetail = getLoadUserEmploymentDetail(this);
  loadAttributeActions = attributeActions(this);

  disabledDate = current => {
    // Can not select future dates
    return current && current.valueOf() >= Date.now();
  };

  setProfileConfiguration = (config = {}) => {
    const { action, session = {}, params = {} } = this.props;
    const { currentUser = {} } = session;
    const { permissions = [], agencyId, userId } = currentUser;
    const sameUser = agencyId == params.agencyId && userId == params.userId;

    const canUpdateUsers = hasPermissions(permissions, [
      PERMISSIONS.updateAnyUser,
      PERMISSIONS.updateAgencyUser,
    ]);

    const canUpdateOwnProfile =
      hasPermissions(permissions, [PERMISSIONS.updateOwnUserProfile]) &&
      sameUser;

    const currentAction = action === ADD_USER;

    const protectedFields = currentAction
      ? PROTECTED_FIELDS_CREATE
      : PROTECTED_FIELDS_EDIT;

    const fieldsPermissionsSee = currentAction
      ? {}
      : FIELDS_SPECIAL_PERMISSIONS_SEE;

    const fieldsPermissionsEdit = currentAction
      ? {}
      : FIELDS_SPECIAL_PERMISSIONS;

    // check if there is any special permission for the field

    const isVisible = ({ field, visible }) => {
      if (!visible) return false;
      return (
        !fieldsPermissionsSee.hasOwnProperty(field) ||
        hasPermissions(permissions, fieldsPermissionsSee[field])
      );
    };

    const isEditable = ({ field, editable }) => {
      if (protectedFields.includes(field)) return false;
      var capableToSee = canUpdateUsers || (canUpdateOwnProfile && editable);
      return (
        capableToSee &&
        (!fieldsPermissionsEdit.hasOwnProperty(field) ||
          hasPermissions(permissions, fieldsPermissionsEdit[field]))
      );
    };

    const overridesEditable = value => {
      if (FIELDS_SPECIAL_PERMISSIONS_EDITABLE.hasOwnProperty(value.field)) {
        return (
          value.editable &&
          hasPermissions(
            permissions,
            FIELDS_SPECIAL_PERMISSIONS_EDITABLE[value.field]
          )
        );
      }
      return isEditable(value);
    };

    const reduceData = (acc, value) => {
      acc[value.field] = {
        editable: overridesEditable(value),
        visible: isVisible(value),
        label: value.label,
      };
      return acc;
    };
    const profileConfiguration = (config || []).reduce(reduceData, {});

    this.setState({ profileConfiguration });
  };

  setDoneLoading = () => {
    this.setState({ loading: false });
  };

  getUserAction = action => {
    const component = UserInformation;
    let title;

    if (action === ADD_USER) {
      title = 'New User';
    } else if (action === VIEW_USER) {
      title = 'User Profile';
    }

    return { component, title };
  };

  get isEditing() {
    const { userProfile } = this.props;

    return !!userProfile.id;
  }

  get isLoading() {
    const { agencyProfile, submitting } = this.props;
    const { loading } = this.state;

    return agencyProfile.loading || submitting || loading;
  }

  get canSave() {
    const { session = {}, params = {} } = this.props;
    const { currentUser = {} } = session;
    const { permissions = [], agencyId, userId } = currentUser;
    const sameUser = agencyId == params.agencyId && userId == params.userId;

    const canUpdateUsers =
      hasPermissions(permissions, [
        PERMISSIONS.updateAnyUser,
        PERMISSIONS.updateAgencyUser,
      ]) ||
      (hasPermissions(permissions, [PERMISSIONS.updateOwnUserProfile]) &&
        sameUser);

    const canCreateUsers = hasPermissions(permissions, [
      PERMISSIONS.createAnyUser,
      PERMISSIONS.createAgencyUser,
    ]);

    return this.isEditing ? canUpdateUsers : canCreateUsers;
  }

  getHeaderActions = () => {
    const {
      userProfile,
      session: {
        currentUser: {
          agency: { timezone = '' },
        },
      },
    } = this.props;

    const { saving = false } = this.state;

    const lastUpdatedInfo = this.isEditing ? (
      <TextActions key="1">
        Last updated:{' '}
        <span>
          {parseDate(
            userProfile.updatedAt,
            timezone,
            BENCHMARK_DATE_TIMEZ_FORMAT
          )}
        </span>
      </TextActions>
    ) : null;

    return [
      lastUpdatedInfo,
      <Button
        type="primary"
        size="default"
        key="2"
        onClick={this.triggerSubmit}
        disabled={this.isLoading || !this.canSave || saving}
        loading={this.isLoading}
      >
        Save
      </Button>,
    ];
  };

  updateUserSectionField = (field, value) => {
    const { dispatch } = this.props;
    dispatch(change(userProfileFormName, field, value));
  };

  addUserSectionFieldValue = (field, value) => {
    const { dispatch } = this.props;
    dispatch(arrayPush(userProfileFormName, field, value));
  };

  removeUserSectionFieldValue = (field, index) => {
    const { dispatch } = this.props;
    dispatch(arrayRemove(userProfileFormName, field, index));
    this.setState({ editUserSection: null });
  };

  setEditUserSectionTable = (sectionId, key) => {
    if (sectionId) {
      this.setState({ editUserSection: { sectionId, key } });
    } else {
      this.setState({ editUserSection: null });
    }
  };

  refreshPage = async () => {
    this.loadData();
  };

  redirectTo = async user => {
    const {
      history: { push },
    } = this.props;
    const { id, agencyId } = user;

    if (id && agencyId) {
      push(
        getRoute('administratorAgencyUser', {
          agencyId,
          userId: id,
        })
      );
    }
  };

  getMessageModal = async (error, user) => {
    const { dispatch } = this.props;
    if (error) {
      const prepareErrorMessage = { formError: [] };
      if (has(error, 'response')) {
        const response = await error.response.json();
        prepareErrorMessage.message =
          get(response, 'error.message') ||
          get(response, 'userMessage') ||
          'API response is not ok. The error response is not readable in this context.';
        const codes = get(response, 'error.details.codes', []);
        apiErrorPayload(codes, response.status);
      } else {
        prepareErrorMessage.message = has(error, 'message')
          ? error.message
          : error;
      }
      prepareErrorMessage.status = has(error, 'status') ? error.status : '';
      modalMessages.showErrorMessageModal(this.props, prepareErrorMessage);
      return dispatch(
        stopSubmit(userProfileFormName, prepareErrorMessage.formError)
      );
    }

    const options = {
      id: 'create-user-modal',
      title: modalMessages.successTitle,
      children: modalMessages.successFields,
    };
    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  getConnectedForm = () => {
    if (!this.connectedForm) {
      this.connectedForm = connectForm(userProfileFormName, this.onSubmit);
    }

    return this.connectedForm;
  };

  triggerSubmit = () => {
    const { dispatch } = this.props;

    dispatch(submit(userProfileFormName));
  };

  onSubmit = values => {
    return onUserSubmit(
      values,
      this.props,
      this.state,
      this.savingState,
      this.getMessageModal,
      this.redirectTo,
      this.refreshPage
    );
  };

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  savingState = () => {
    this.setState({ saving: true });
  };

  onToggleUserState = values => {
    const { dispatch } = this.props;
    this.setState({ isDeleted: values.isDeleted });
    dispatch(change(userProfileFormName, 'isDeleted', values.isDeleted));
    return onUserSubmit(
      {
        ...values,
        enableOrDisable: values.isDeleted,
      },
      this.props,
      this.state,
      this.savingState,
      this.getMessageModal,
      this.redirectTo,
      this.goBack
    );
  };

  saveRef = el => {
    if (el) {
      this.equipmentRep = el.getWrappedInstance();
    }
  };

  formHasChanged = () => {
    const { profileForm = {} } = this.props;
    const { saving } = this.state;
    const {
      anyTouched = false,
      fields = {},
      initial = {},
      values = {},
    } = profileForm;

    return !saving && anyTouched
      ? Object.keys(fields).some(field => initial[field] !== values[field])
      : false;
  };

  validatePermissions = permissions => {
    return {
      isSuperAdmin: hasPermissions(permissions, PERMISSIONS.manageAgencies),
      canModifyUsers: hasPermissions(permissions, [
        PERMISSIONS.updateAnyUser,
        PERMISSIONS.updateAgencyUser,
      ]),
      canModifyRoles: hasPermissions(permissions, [
        PERMISSIONS.createRole,
        PERMISSIONS.assignRole,
      ]),
      canViewUserEmploymentHistory: hasPermissions(permissions, [
        PERMISSIONS.manageOUAssignment,
        PERMISSIONS.viewOUAssignment,
      ]),
      canManageUserEmploymentHistory: hasPermissions(permissions, [
        PERMISSIONS.manageOUAssignment,
      ]),
      canViewDocuments: hasPermissions(permissions, [
        PERMISSIONS.viewDocument,
        PERMISSIONS.manageDocuments,
      ]),
      canManageDocuments: hasPermissions(permissions, [
        PERMISSIONS.manageDocuments,
      ]),
      canViewStatsPersonnel: hasPermissions(permissions, [
        PERMISSIONS.viewStatsPersonnel,
      ]),
      canViewReportsWithMention: hasPermissions(permissions, [
        PERMISSIONS.viewReportsWithMention,
      ]),
      canViewUnMaskedSSN: hasPermissions(permissions, [
        PERMISSIONS.viewUnMaskedSSN,
      ]),
      canUpdateSSN: hasPermissions(permissions, [PERMISSIONS.updateSSN]),
    };
  };

  render() {
    const {
      action,
      profileForm,
      agencyProfile,
      userProfile,
      timezone,
      from = '',
      history,
      userId,
    } = this.props;

    const {
      loading,
      profileConfiguration,
      sectionsDefinition,
      editUserSection,
      organizationalUnitList,
      organizationalUnitTreeList,
      organizationalUnitsAssignedToUserList,
      employmentRankOptions,
      userPictureSource,
      employmentHistoryData,
      organizationHistoryAttributes,
      userEmploymentAttributes,
      auditId,
    } = this.state;
    const { component: PageComponent, title } = this.getUserAction(action);
    const ProfileForm = this.getConnectedForm();
    const { agencyId: currentAgencyId = '', session = {} } = this.props;
    const { currentUser = {} } = session;
    const { agencyId: userAgencyId, permissions = [], id } = currentUser;
    const { data: { integrationId = '' } = {} } = agencyProfile;

    const {
      isSuperAdmin,
      canModifyUsers,
      canModifyRoles,
      canViewUserEmploymentHistory,
      canManageUserEmploymentHistory,
      canViewDocuments,
      canManageDocuments,
      canViewStatsPersonnel,
      canViewReportsWithMention,
      canViewUnMaskedSSN,
      canUpdateSSN,
    } = this.validatePermissions(permissions);

    const agencyId = isSuperAdmin ? currentAgencyId : userAgencyId;

    const shouldSeeAnyFieldWrapper = fieldList =>
      shouldSeeAnyField(fieldList, profileConfiguration);

    const shouldBeReadOnlyFieldWrapper = fieldList =>
      shouldBeReadOnlyField(fieldList, profileConfiguration);

    const getFieldLabelWrapper = field =>
      getFieldLabel(field, profileConfiguration);

    const goRoute = from == '' ? 'administratorAgencyUsers' : from;

    callUserProfileSuccessLog({
      tenantId: integrationId,
      auditId,
      contextOfThis: this,
      action,
      VIEW_USER,
    });

    return (
      <div>
        <Prompt when={this.formHasChanged()} message={LEAVE_MESSAGE} />
        <PageHeader
          title={title}
          history={history}
          actions={this.getHeaderActions()}
        />
        <ProfileForm
          render={({ handleSubmit }) => (
            <form
              className="ant-form ant-form-horizontal"
              onSubmit={handleSubmit(this.onSubmit)}
            >
              <PageComponent
                formName={userProfileFormName}
                profileForm={profileForm}
                fetchingAgency={agencyProfile.loading || loading}
                isCreatingUser={ADD_USER === action}
                unitAssignments={agencyProfile.unitAssignments}
                ranks={agencyProfile.ranks}
                agencySecurityRoles={agencyProfile.securityRoles}
                canModifyRoles={canModifyRoles}
                saveRef={this.saveRef}
                shouldSeeAnyField={shouldSeeAnyFieldWrapper}
                shouldBeReadOnlyField={shouldBeReadOnlyFieldWrapper}
                getFieldLabel={getFieldLabelWrapper}
                canViewStatsPersonnel={canViewStatsPersonnel}
                organizationalUnitsAssignedToUserList={
                  organizationalUnitsAssignedToUserList
                }
                userPictureSource={userPictureSource}
                organizationalUnitTreeList={organizationalUnitTreeList}
                canViewUserAssignment={canViewUserEmploymentHistory}
                canViewUnMaskedSSN={canViewUnMaskedSSN}
                canUpdateSSN={canUpdateSSN}
              />
              <UserContactInformation
                profileForm={profileForm}
                fetchingAgency={agencyProfile.loading || loading}
                shouldSeeAnyField={shouldSeeAnyFieldWrapper}
                shouldBeReadOnlyField={shouldBeReadOnlyFieldWrapper}
                getFieldLabel={getFieldLabelWrapper}
                updateField={this.updateUserSectionField}
                isLoading={this.isLoading}
              />

              {canViewUserEmploymentHistory && (
                <UserEmploymentHistory
                  profileForm={profileForm}
                  timezone={timezone}
                  editSection={editUserSection}
                  updateSectionField={this.updateUserSectionField}
                  addSectionFieldValue={this.addUserSectionFieldValue}
                  removeSectionFieldValue={this.removeUserSectionFieldValue}
                  setEditSectionTable={this.setEditUserSectionTable}
                  loadEmploymentDetail={this.loadUserEmploymentDetail}
                  organizationalUnitList={organizationalUnitList}
                  organizationalUnitTreeList={organizationalUnitTreeList}
                  employmentRankOptions={employmentRankOptions}
                  isSectionReadOnly={!canManageUserEmploymentHistory}
                  storedInDatabase={employmentHistoryData}
                  organizationHistoryAttributes={organizationHistoryAttributes}
                  userEmploymentAttributes={userEmploymentAttributes}
                  session={session}
                  attributeActions={this.loadAttributeActions}
                />
              )}

              <UserSections
                action={action}
                userProfile={userProfile}
                session={session}
                tenantId={this.state.tenantId}
                userIntegrationId={this.state.userIntegrationId}
                dispatch={this.props.dispatch}
                profileForm={profileForm}
                timezone={timezone}
                sectionsDefinition={sectionsDefinition}
                editUserSection={editUserSection}
                updateUserSectionField={this.updateUserSectionField}
                addUserSectionFieldValue={this.addUserSectionFieldValue}
                removeUserSectionFieldValue={this.removeUserSectionFieldValue}
                setEditUserSectionTable={this.setEditUserSectionTable}
                canViewDocuments={canViewDocuments}
                canManageDocuments={canManageDocuments}
                canViewReportsWithMention={canViewReportsWithMention}
                history={history}
                currentUser={currentUser}
              />

              {ADD_USER !== action &&
                !!userProfile &&
                canModifyUsers &&
                shouldSeeAnyFieldWrapper(['isDeleted']) && (
                  <ProfileCardWrapper>
                    <UserStatus
                      benchmarkDateFormat={BENCHMARK_DATE_FORMAT}
                      timezone={timezone}
                      disabledDate={this.disabledDate}
                      formatDate={formatDate}
                      profileForm={profileForm}
                      fetchingAgency={agencyProfile.loading || loading}
                      onToggleUserState={this.onToggleUserState}
                      canDelete={canModifyUsers}
                      shouldSeeAnyField={shouldSeeAnyFieldWrapper}
                      shouldBeReadOnlyField={shouldBeReadOnlyFieldWrapper}
                      getFieldLabel={getFieldLabelWrapper}
                      isOwnProfile={id === userId}
                    />
                  </ProfileCardWrapper>
                )}

              <FooterActions>
                <Row>
                  <Col span={24} className="footer-actions-col">
                    <Link to={getRoute(goRoute, { agencyId })}>
                      <Button
                        type="primary"
                        ghost
                        size="default"
                        data-test="profile footer cancel"
                      >
                        Cancel
                      </Button>
                    </Link>{' '}
                    <Button
                      type="primary"
                      size="default"
                      htmlType="submit"
                      data-test="profile footer save"
                      disabled={this.isLoading || !this.canSave}
                      loading={this.isLoading}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </FooterActions>
            </form>
          )}
        />
      </div>
    );
  }
}

const mapState = (state, props) => {
  const agencyId = get(props, 'params.agencyId');
  const userId = get(props, 'params.userId');
  const from = get(props, 'params.from');
  const timezone = get(props, 'session.currentUser.agency.timezone');
  return {
    profileForm: getFormValues(state, userProfileFormName),
    userProfile: getAgencyUser(state, agencyId, userId),
    agencyProfile: selectAgency(state, agencyId),
    submitting: selectAgencyUserSubmissionState(state, agencyId),
    timezone,
    from,
  };
};

export default connect(mapState)(UserProfile);
