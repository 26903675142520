import React from 'react';

import parsedDate from '../../utils/parse-date';
import { getSubmissionMessage } from '../../utils/form';
import getTransitionMessageFromtemplate from '../../utils/get-transition-message-from-template';

const WorkflowItem = props => {
  const {
    item,
    index,
    timezone,
    participants,
    formType,
    notes,
    usersOnBehalf = [],
  } = props;
  const { timelineMessage } = item;

  const submissionMessage = timelineMessage
    ? getTransitionMessageFromtemplate(
        timelineMessage,
        item,
        participants,
        notes
      )
    : getSubmissionMessage(item, formType, participants, usersOnBehalf);

  return (
    <div>
      <p className={`timeline-item-date-${index}`}>
        {parsedDate(item.created, timezone)}
      </p>
      <p>
        <b>
          {' '}
          {item.formType} {item.formNumber}{' '}
        </b>
      </p>
      <p
        className={`timeline-item-message-${index}`}
        dangerouslySetInnerHTML={{ __html: submissionMessage }}
      />
    </div>
  );
};

export default WorkflowItem;
