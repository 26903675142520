import { mapToTemplateEnumBy } from '../../../containers/administrator/utils/transformChanges';

export function isReviewNote(template) {
  if (!template) {
    return false;
  }
  return mapToTemplateEnumBy(template.category) === 'NOTE';
}

export function toCapitalizedWords(name) {
  if (name) {
    var words = name.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(capitalize).join(' ');
  }
  return '';
}

export function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
  );
}

function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.substring(1);
}
