export const caseSensitive = (inputValue, path) => {
  return path.some(
    option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  );
};

export const fuzzySearchFiter = (inputValue, path) => {
  const normalizedInputVal = normalizeString(inputValue);
  return path.some(
    option => normalizeString(option.label).indexOf(normalizedInputVal) >= 0
  );
};

export const findReviewer = (inputValue, path) => {
  return path.some(option => {
    const { label = '' } = option;
    return (
      label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
      matchAnyId(inputValue, option)
    );
  });
};

export const findReviewerUsingFuzzySearch = (inputValue, path) => {
  return path.some(option => {
    const { label = '' } = option;
    return (
      normalizeString(label).indexOf(normalizeString(inputValue)) >= 0 ||
      matchAnyId(inputValue, option)
    );
  });
};

export const matchAnyId = (inputValue, option) => {
  const lowerCaseValue = inputValue.toLowerCase();
  const { benchmarkId = '', employeeId = '', starNumber = '' } = option;
  return (
    (benchmarkId !== null &&
      benchmarkId.toLowerCase().indexOf(lowerCaseValue) >= 0) ||
    (employeeId !== null &&
      employeeId.toLowerCase().indexOf(lowerCaseValue) >= 0) ||
    (starNumber !== null &&
      starNumber.toLowerCase().indexOf(lowerCaseValue) >= 0)
  );
};

export const normalizeString = inputStr => {
  const normalizedStr = inputStr
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  return normalizedStr.toLowerCase().replace(/[^\w]+/g, '');
};

export default findReviewerUsingFuzzySearch;
