import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import getRoute from '../../../utils/get-route';

import AdministratorWrapper from '../Administrator.styled';
import FileTableColumns from './FileTableColumns.json';
import FileTableWrapper from './FileTableWrapper';
import FilterableColumnTable from '../../../components/filterable-column-table/FilterableColumnTable';
import DashboardPage from '../../../components/dashboard';
import getDocumentMetadata from './actions/get-document-metadata';
import getS3Document from './actions/get-s3-document';
import getTenant from './actions/get-tenant';
import PageHeader from '../../../components/PageHeader/PageHeader';
import withoutClutter from '../../../components/without-clutter';
import withOwnershipGuard from '../components/agency-ownership-guard';

import { Icon } from 'antd';
import createModal from '../../../actions/create-modal';
import ModalBody from '../../../components/common/modal/body';
import ModalTitle from '../../../components/common/modal/title';
import showModal from '../../../actions/show-modal';
import { hasPermissions, PERMISSIONS } from '../../../utils/admin';

const pageSize = process.env.MIGRATED_DOCUMENTS_TABLE_PAGE_SIZE || 50;

function AgencyMigratedFiles({ agencyId, dispatch, tenantId, ...props }) {
  const [documentMetadata, setDocumentMetadata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [sortColumn, setSortColumn] = useState('uploadedDate');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [tenantSlug, setTenantSlug] = useState();
  const [totalElements, setTotalElements] = useState(0);
  const userTenantSlug = props.currentUser.organizationUnit.tenantSlug;
  const userAgencyId = props.agency.id;
  const manageAgencies = hasPermissions(
    props.permissions,
    PERMISSIONS.manageAgencies
  );

  useEffect(() => {
    if (userAgencyId == agencyId || !manageAgencies) {
      setTenantSlug(userTenantSlug);
    } else {
      dispatch(
        getTenant(tenantId, (error, data) => {
          if (error) {
            showErrorMessage();
            return false;
          }
          setTenantSlug(data.slug);
        })
      );
    }
  }, []);

  useEffect(() => {
    if (tenantSlug) {
      dispatch(
        getDocumentMetadata(
          sortDirection,
          currentPage,
          pageSize,
          sortColumn,
          filters,
          tenantSlug,
          (error, data) => {
            if (error) {
              showErrorMessage();
              return false;
            }
            setDocumentMetadata(data.content.data);
            setTotalElements(data.content.page.totalRecords);
          }
        )
      );
    }
  }, [tenantSlug, currentPage, sortColumn, sortDirection, filters]);

  const showErrorMessage = error => {
    const errorTitle = (
      <ModalTitle error>
        <Icon type="close-circle" /> <span>Heads Up!</span>
      </ModalTitle>
    );

    const successFields = (
      <ModalBody>
        <p>{error ?? 'Something wrong occurred, please try again later.'}</p>
      </ModalBody>
    );

    const options = {
      id: 'ou-error-modal',
      title: errorTitle,
      children: successFields,
    };

    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  const fetchDataCallback = (pagination, filters, sort) => {
    setCurrentPage(pagination.currentPage);
    setFilters(filters);
    setSortColumn(sort.column);
    setSortDirection(sort.order === 'descend' ? 'DESC' : 'ASC');
  };

  const recordRenderCallback = (text, record, dataIndex) =>
    dataIndex === 'name' ? (
      <a
        onClick={event => {
          event.preventDefault();

          dispatch(
            getS3Document(record.id, (error, data) => {
              if (error) {
                showErrorMessage('Failed to fetch file.');
                return false;
              }

              const a = document.createElement('a');
              const blob = new Blob([data]);
              const url = window.URL.createObjectURL(blob);

              document.body.appendChild(a);
              a.style = 'display: none';
              a.href = url;
              a.download = text;
              a.click();
              window.URL.revokeObjectURL(url);
            })
          );
        }}
      >
        {text}
      </a>
    ) : (
      text
    );

  return (
    <AdministratorWrapper>
      <PageHeader
        title="Migrated Files"
        goBackTo={getRoute('administratorAgency', { agencyId })}
      />
      <FileTableWrapper>
        <FilterableColumnTable
          columns={FileTableColumns}
          data={documentMetadata}
          fetchDataCallback={fetchDataCallback}
          pageSize={pageSize}
          totalRows={totalElements}
          recordRenderCallback={recordRenderCallback}
        />
      </FileTableWrapper>
    </AdministratorWrapper>
  );
}

const mapStateToProps = (state, props) => ({
  agencyId: get(props, 'match.params.agencyId'),
  tenantId: get(props, 'match.params.tenantId'),
});

export default withoutClutter(
  DashboardPage(
    mapStateToProps,
    null,
    {}
  )(withOwnershipGuard(AgencyMigratedFiles, 'administratorAgencyMigratedFiles'))
);
