import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes';

import { MULTISELECT } from 'APP_ROOT/constants/fieldTypes';

import FormComponent from '../models/FormComponent';

const getBodyDiagramChildrenDefault = postfix => {
  return [
    new FormComponent(FIELD, {
      field_type: MULTISELECT,
      key: `interactionInjuriesFront${postfix}`,
      enumRef: `injuriesBodySideFront${postfix}`,
      options: {
        defaultValue: [],
        side: 'front',
      },
    }),
    new FormComponent(FIELD, {
      field_type: MULTISELECT,
      key: `interactionInjuriesBack${postfix}`,
      enumRef: `injuriesBodySideBack${postfix}`,
      options: {
        defaultValue: [],
        side: 'back',
      },
    }),
  ];
};

export default getBodyDiagramChildrenDefault;
