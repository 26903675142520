import React from 'react';
import { Steps } from 'antd';

const StepsComponent = ({ direction, size, stepsArray, ...OtherProps }) => {
  const { Step } = Steps;
  const getSteps = stepsArray => {
    return stepsArray.map((element, index) => {
      return (
        <Step
          key={index}
          status={element.status}
          title={element.title}
          description={element.description}
        />
      );
    });
  };
  return (
    <Steps direction={direction} size={size} {...OtherProps}>
      {getSteps(stepsArray)}
    </Steps>
  );
};

export default StepsComponent;
