import React from 'react';
import { states } from '../../../utils/states-list/states';
import { Select } from 'antd';
const { Option } = Select;

export default () => {
  return new Promise((resolve, reject) => {
    let selectOptions = states.map((l, key) => (
      <Option key={key} value={l.value}>
        {l.value}
      </Option>
    ));
    resolve({ selectOptions, error: null });
  });
};
