import { cloneElement } from 'react';

const ConditionalRender = ({
  condition = true,
  children,
  drillProps = false,
  ...props
}) => {
  if (condition) {
    return children ? cloneElement(children, drillProps ? props : {}) : null;
  }
  return null;
};

export default ConditionalRender;
