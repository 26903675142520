import { Breadcrumb } from 'antd';
import styled from 'styled-components';

export default styled(Breadcrumb)`
  .ant-breadcrumb-separator,
  .ant-breadcrumb-link,
  .ant-breadcrumb > span:last-child {
    font-size: 24px;
    line-height: 29px;
    color: #000;
    font-weight: normal;
  }
`;

export const Item = Breadcrumb.Item;
