import { FILTER_ARRAY_SEPARATOR } from './constants';

const getStatusFilter = (status = '') => {
  if (status) {
    const [propagateDirectValue, ...items] = status.split(
      FILTER_ARRAY_SEPARATOR
    );
    return propagateDirectValue.toLowerCase() === 'true'
      ? items
      : [
          {
            column: 'status',
            operator: 'inq',
            value: items,
          },
        ];
  }
  return [];
};

export default getStatusFilter;
