import styled from 'styled-components';

const FormPickerWrapper = styled.div`
  .ant-form-picker-select {
    width: 100%;
  }

  .btn-wrapper {
    .ant-btn {
      display: block;
      width: 100%;
      max-width: 400px;
      margin: 0 auto 10px;
      height: auto;
      min-height: 60px;

      img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }

      span {
        text-align: left;
        width: 250px;
        margin: 0 auto;
        font-size: 17px;
        display: inline-block;
        vertical-align: middle;

        &::before {
          font-size: 35px;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }

    &.analytics-report-button {
      .ant-btn span {
        width: 100%;
      }
    }
  }
`;

export default FormPickerWrapper;
