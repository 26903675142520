import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import MainTitle from '../../components/common/main-title';
import Panel, {
  Divider,
  PanelTitle,
  PanelSectionTable,
  TablePanel,
} from '../../components/common/panel';

import parseDate, { BENCHMARK_DATE_FORMAT } from '../../utils/parse-date';
import getStats from '../../actions/get-stats';
import { getAgencyTZ } from '../../selectors/session';

const renderInvestigationLink = (text, record, index) => {
  const { investigationId, formNumber } = record;
  const UnderlinedLink = styled(Link)`
    text-decoration: underline;
  `;
  return (
    <UnderlinedLink to={`/reports/${investigationId}`}>
      {formNumber}
    </UnderlinedLink>
  );
};

const getFirstTableColumns = timezone => [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: text => parseDate(text, timezone, BENCHMARK_DATE_FORMAT),
  },
  {
    title: 'Allegation',
    dataIndex: 'allegationType',
    key: 'allegationType',
  },
  {
    title: 'Finding',
    dataIndex: 'findingType',
    key: 'findingType',
  },
  {
    title: 'Disciplinary Actions',
    dataIndex: 'recommendedActions',
    key: 'recommendedActions',
  },
  {
    title: 'IA Investigation',
    dataIndex: 'investigationId',
    key: 'investigationId',
    render: renderInvestigationLink,
  },
];

/* const secondTableColumns = [
  {
    title: 'Indicators',
    dataIndex: 'indicators',
    key: 'indicators',
    width: '66%'
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: '34%'
  }
]; */

/* const secondTableData = [
  {
    key: '1',
    indicators: 'Injuries to Subjects in Custody',
    value: 0
  },
  {
    key: '2',
    indicators: 'Warrantless Searches',
    value: 0
  },
  {
    key: '3',
    indicators: 'Criminal Investigaton Involving Officer',
    value: 0
  },
  {
    key: '4',
    indicators: 'Arrest of Officer',
    value: 0
  },
  {
    key: '5',
    indicators: 'Interview/Interrogation in Violation of Policy',
    value: 0
  },
  {
    key: '6',
    indicators: 'Lost/Theft of Agency Property',
    value: 0
  },
  {
    key: '7',
    indicators: 'Vehicle Pursuits',
    value: 0
  },
  {
    key: '8',
    indicators: 'Traffic Crash Involving Officer',
    value: 0
  },
  {
    key: '9',
    indicators: 'Civil/Administrative Complaints',
    value: 0
  },
  {
    key: '10',
    indicators: 'Civil Lawsuit Activity from Officer Duties',
    value: 0
  },
  {
    key: '11',
    indicators: 'Protective/Restraints Orders Issued Against Officer',
    value: 0
  }
]; */

class InternalAffairs extends Component {
  UNSAFE_componentWillMount() {
    const { getStatsIA } = this.props;
    getStatsIA('internal-affairs');
  }

  render() {
    const {
      timezone,
      selectedUser: { stats: statsData = {} },
    } = this.props;
    const {
      data: { allegationsHistory: history = [], stats = [] } = {},
    } = statsData;
    const allegationsHistory = history.map((allegation, i) => ({
      key: i,
      ...allegation,
    }));
    const firstTableColumns = getFirstTableColumns(timezone);
    return (
      <div>
        <StyledMainTitle className="text-xs-center">
          Internal Affairs
        </StyledMainTitle>

        <Panel>
          <PanelTitle className="text-xs-center">
            <h2>General Information</h2>
          </PanelTitle>

          <Divider />

          {stats.map(({ label, value }, index) => (
            <div key={index}>
              <PanelSectionTable>
                <Row type="flex" align="middle">
                  <Col xs={12} sm={8} md={6} lg={4}>
                    <p className="title">{label}:</p>
                  </Col>
                  <Col xs={12} sm={16} md={18} lg={20}>
                    <p>{value}</p>
                  </Col>
                </Row>
              </PanelSectionTable>
              {index < stats.length - 1 && <Divider />}
            </div>
          ))}
        </Panel>

        <Panel paddingless bordered>
          <StyledPanelTitle className="text-xs-center">
            <h2>Allegations History</h2>
          </StyledPanelTitle>
          <Divider />
          <TablePanel
            columns={firstTableColumns}
            dataSource={allegationsHistory}
          />
        </Panel>

        {/*
        <Panel paddingless bordered>
          <StyledPanelTitle className="text-xs-center">
            <h2>Critical FirstSign Indicators</h2>
          </StyledPanelTitle>
          <Divider />
          <TablePanel
            columns={secondTableColumns}
            dataSource={secondTableData}
          />
        </Panel>
        */}
      </div>
    );
  }
}

const StyledMainTitle = styled(MainTitle)`
  padding: 0;
  margin-bottom: 24px;
`;

const StyledPanelTitle = styled(PanelTitle)`
  padding: 24px 16px 16px 16px;
`;

const mapState = state => {
  const { app, session, selectedUser } = state;
  return {
    app,
    session,
    selectedUser: {
      ...selectedUser,
      stats: get(selectedUser, 'stats.internal-affairs', {}),
    },
    timezone: getAgencyTZ(state),
  };
};

export default connect(mapState, { getStatsIA: getStats })(InternalAffairs);
