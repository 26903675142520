// to call the API, for instance
// https://templates-service:3006/templates/v1/agencies/template?agencyId=1&name=nombreee&abbreviation=abb&description=descr

import { fetchRequest } from '../../../utils/request';
import ServiceClient from '../../../api/serviceClient';

const protocol = process.env.HTTPS ? 'https' : 'http';
const DEV_HOST = `${protocol}://localhost:3006`;

export const REACT_APP_TEMPLATES_SERVICE_URI =
  process.env.REACT_APP_TEMPLATES_SERVICE_URI || DEV_HOST;

export const API_PREFIX = 'templates/v1';

class apiEndpoints extends ServiceClient {
  constructor() {
    super(REACT_APP_TEMPLATES_SERVICE_URI, API_PREFIX);
  }

  saveForm(templateSchema) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/agencies/templates'),
        method: 'POST',
      },
      {},
      {},
      templateSchema
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  async updateForm(agencyId, formId, templateSchema) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/agencies/:agencyId/templates/:templateId'),
        method: 'PUT',
      },
      { templateId: formId, agencyId },
      {},
      templateSchema
    );
    await fetchRequest(fetchParams.URI, fetchParams.request);
    return true;
  }

  publishForm(templateId, agencyId, templateSchema) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/agencies/:agencyId/templates/:templateId/publish'
        ),
        method: 'PUT',
      },
      { agencyId, templateId },
      {},
      templateSchema
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  async deleteForm(agencyId, formId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/agencies/:agencyId/templates/:templateId'),
        method: 'DELETE',
      },
      { templateId: formId, agencyId },
      {}
    );
    await fetchRequest(fetchParams.URI, fetchParams.request);
    return true;
  }

  getTemplateList({
    agencyId,
    onlyPublished = false,
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
  }) {
    const params = {
      onlyPublished,
    };
    if (pageNumber !== undefined) {
      params.pageNumber = pageNumber;
      params.pageSize = pageSize;
    }
    if (sortBy !== undefined) {
      params.sortBy = sortBy;
      params.sortDirection = sortDirection;
    }
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/agencies/:agencyId/templates'),
        method: 'GET',
      },
      { agencyId },
      params
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  async getForm(
    agencyId,
    formId,
    isDefault = false,
    onlyPublished = false,
    templateVersionId = undefined
  ) {
    let queryParams = {
      default: isDefault,
      onlyPublished,
    };

    if (templateVersionId) {
      queryParams.templateVersionId = templateVersionId;
    }

    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/agencies/:agencyId/templates/:templateId'),
        method: 'GET',
      },
      { templateId: formId, agencyId },
      queryParams
    );
    const response = await fetchRequest(fetchParams.URI, fetchParams.request);
    const { json, ...data } = response;

    const parsedJson = JSON.parse(json);
    const { formSchema, ...parsedData } = parsedJson;

    const template = {
      formSchema: formSchema,
      ...data,
      ...parsedData,
    };

    return template;
  }

  async getFormWithReviewNotes(agencyId, formId, isDefault) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/agencies/:agencyId/templates/:templateId'),
        method: 'GET',
      },
      { templateId: formId, agencyId },
      { default: isDefault, includeRelatedNotes: true }
    );

    const schema = await fetchRequest(fetchParams.URI, fetchParams.request);
    const { json, ...data } = schema;

    const template = {
      ...data,
      formSchema: JSON.parse(json).formSchema,
    };

    return template;
  }

  getConfig(agencyId, formId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/export/agencies/:agencyId/templates/:templateId/configs'
        ),
        method: 'GET',
      },
      { templateId: formId, agencyId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getChangelogList(agencyId, templateId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/agencies/:agencyId/templates/:templateId/changelogs'
        ),
        method: 'GET',
      },
      { agencyId, templateId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getTemplateVisibility(templateId, agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/Templates/:templateId/getVisibilityConstraints'
        ),
        method: 'GET',
      },
      { templateId },
      { agencyId: agencyId }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  setTemplateShareable(templateId, agencyId, isShareable) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/agencies/:agencyId/templates/:templateId/share'
        ),
        method: 'POST',
      },
      { agencyId, templateId },
      { isShareable }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getTemplateIntegration(agencyId, templateId, integrationId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/agencies/:agencyId/templates/:templateId/cad-rms-integration/:integrationId'
        ),
        method: 'GET',
      },
      { agencyId, templateId, integrationId },
      {}
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }
}

export default new apiEndpoints();
