import { has, isEmpty } from 'lodash';
import findConfigField from '../utils/findConfigField';
import { getValidationsDefaultFieldType } from '../utils/getValidationsDefault';
import removeEnumRef from '../utils/removeEnumRef';
import { STRING } from 'APP_ROOT/constants/fieldTypes';

const verifyType = (rule, fieldsByKey, enums, fieldKey) => {
  const { type, ...rest } = rule;
  const field = fieldsByKey[fieldKey];
  const { field_type, fromSource = {} } = field;
  const { key: keyFromSource } = fromSource;

  const fieldType = getValidationsDefaultFieldType(field_type);
  if (has(field, 'options.populateFrom')) {
    const { enumRef, parentEnum } = field;
    // populateFrom should map to string type validation
    // like interactionWho, since the options are generated
    // dinamically, not from an enumRef array, also remove
    // enumRef if exist to avoid validation problems later
    return [
      { type: STRING, ...rest },
      removeEnumRef(enums, enumRef, parentEnum),
      type !== STRING,
    ];
  }

  if (keyFromSource) {
    // verifies calculated fields and White list fields
    const config = findConfigField(keyFromSource);
    if (config.type !== field_type) {
      if (isEmpty(config.key) || Array.isArray(keyFromSource)) {
        return [{ type: STRING, ...rest }, enums, true];
      }
      return [
        { type: getValidationsDefaultFieldType(config.type), ...rest },
        enums,
        true,
      ];
    }
  }

  if (type !== fieldType) {
    return [{ type: fieldType, ...rest }, enums, true];
  }
  return [rule, enums, false];
};

export default verifyType;
