import { kebabCase, omit } from 'lodash';

import { CREATE_NEW_FORM } from '../../';
import getFormSuccess from '../../get-form-success';
import getFormPresentation from '../../get-form-presentation';
import createFormAfter from '../../events/create-form-after';

const createNewForm = ({
  templateType,
  currentUser,
  dispatch,
  template,
  next,
}) => {
  const type = kebabCase(templateType);
  const {
    formSchema: { form: schema = {}, validations, enums, overrides } = {},
  } = template;

  dispatch(getFormSuccess(template));
  dispatch({
    type: CREATE_NEW_FORM,
    payload: {
      template: templateType,
      currentUser: omit(currentUser, ['agency']),
    },
  });
  dispatch(
    getFormPresentation({ schema, type, validations, enums, overrides }, () => {
      dispatch(createFormAfter());
    })
  );

  next && next();
};

export default createNewForm;
