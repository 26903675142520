import styled from 'styled-components';

const GroupEditor = styled.div`
  border: 1px dashed #ababab;
  border-radius: 10px;
  padding: 2px 2px 5px 2px;

  .show-reviewer {
    background-color: hsla(180, 100%, 25%, 0.1);
  }

  .hide-reviewer {
    background-color: hsla(0, 100%, 50%, 0.1);
  }

  .uppercase {
    text-transform: uppercase;
  }

  .box-card {
    position: relative;
  }

  .margin-left {
    margin-left: auto;
  }
`;

export default GroupEditor;
