import React from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';

import {
  PURPLE,
  ORANGE,
  GREEN,
  YELLOW,
  SILVER,
  PINK,
} from '../../config/theme';

const StatusAvatar = ({
  type = 'draft',
  className,
  active = true,
  ...props
}) => {
  const isActiveReviewer = active ? 'is-active-reviewer' : '';
  return (
    <StyledAvatar
      className={`${className || ''} status-${type ||
        'draft'} ${isActiveReviewer}`}
      {...props}
    />
  );
};

const StyledAvatar = styled(Avatar)`
  &.ant-avatar {
    &.status-approved,
    &.status-in-review,
    &.status-command-review,
    &.status-submitted,
    &.status-draft,
    &.status-take-action,
    &.status-done,
    &.status-closed,
    &.status-pending,
    &.status-voided,
    &.status-assigned {
      background-color: ${SILVER};
    }

    &.status-draft {
      &.is-active-reviewer {
        background-color: ${PURPLE};
      }
    }

    &.status-pending {
      &.is-active-reviewer {
        background-color: ${PINK};
      }
    }

    &.status-in-review,
    &.status-command-review,
    &.status-assigned,
    &.status-submitted {
      &.is-active-reviewer {
        background-color: ${ORANGE};
      }
    }

    &.status-approved,
    &.status-done,
    &.status-closed {
      &.is-active-reviewer {
        background-color: ${GREEN};
      }
    }

    &.status-take-action {
      &.is-active-reviewer {
        background-color: ${YELLOW};
      }
    }
  }
`;

export default StatusAvatar;
