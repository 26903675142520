import { last } from 'lodash';

import { MATH } from '../../../constants/fieldTypes';

/**
 * To evaluate the field relation
 * @param {Array} fieldKeys
 * @param {Object} field: math field to evaluate relation with fieldKeys
 * @param {Array} related: current list of related fields
 * @returns
 */
const getMathRelated = (fieldKeys, field, related = []) => {
  const { field_type, options } = field;
  if (field_type === MATH) {
    const { operands = [] } = options;
    operands.forEach(operand => {
      if (fieldKeys.includes(last(operand.split('.')))) {
        related.push(field);
      }
    });
  }
  return related;
};

export default getMathRelated;
