import { Icon } from 'antd';
import React from 'react';

import ModalTitle from 'APP_COMPONENTS/common/modal/title';

const errorTitle = (
  <ModalTitle type={'error'}>
    <Icon type="close-circle" /> <span>Heads Up!</span>
  </ModalTitle>
);

export default errorTitle;
