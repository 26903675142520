import React, { Component } from 'react';

import bodyDiagramBack from '../body-parts/diagram-back.json';
import Part from '../part-types';
import SVG from '../common/inline-svg';

class BodyBack extends Component {
  render() {
    const {
      onClick,
      selectedParts = [],
      validParts=[],
      isReviewer = false,
      disabled = false,
    } = this.props;

    const bodySide = bodyDiagramBack;
    const { childs } = bodySide;

    const strokeColor = disabled ? '#CCC' : '#2D2D2D';

    return (
      <SVG
        version="1.1"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 125.3 252"
        enableBackground="new 0 0 125.3 252"
        xmlSpace="preserve">
        <g id="body-sections">
          {childs &&
            childs.filter(child => validParts.includes(child.name)).map((child, index) => {
              const selected = selectedParts
                ? selectedParts.includes(child.name)
                : false;

              return (
                <Part
                  key={index}
                  path={child}
                  onClick={onClick}
                  selected={selected}
                  isReviewer={isReviewer}
                />
              );
            })}
        </g>
        <g id="body-diagram">
          <path
            fill="none"
            stroke={strokeColor}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            d="M72.2,34.7c0,0,0,2.7,0,5.2
        		c0,4.7,4.6,5.5,9.6,7.1c3.8,1.2,11.7,3.3,13.7,5.3c3.7,3.7,3.8,6.7,3.8,13c0,7.5-0.1,9.6-0.1,12.5c0,2.5,0.7,11.8,1.2,13.6
        		c0.5,2,4.4,7.3,6.3,15c2.5,10,5.6,16.9,7.3,22.3c0,0,1.9,2.3,5.5,5.7c2.3,2.1,1.9,4.3,3.2,6.3c1.3,2,2.2,4,0.5,4
        		c-1.8,0-3-3.2-4.6-3.6c0,0,0.2,2.4,0.2,4.7c0,3.1-0.3,5.3-0.3,7c-0.1,1.3,0.1,4.4-2.7,4.4c0,0-0.4,1.2-1.8,1.2
        		c-1.4,0-1.7-0.9-1.7-0.9s-0.5,0.3-1.4,0c-1.5-0.5-1.8-2.7-1.8-2.7s-0.6,0.4-1.4,0.1c-1.1-0.5-1.4-2.8-1.5-3.6
        		c-0.5-2.4-0.8-3.1-1.6-5.6c-0.8-2.6-1.2-4.1-1.1-10c0.1-5.7-2.4-8.6-3.5-11c-2.1-4.7-7.4-11.8-8.5-13.6c-1.6-2.7-2.4-6.4-2.8-11
        		c-0.4-4.6-3-7.8-2.9-10.7c0,0,0,0-0.9,3.2c-1.2,4.1-2.2,9.7-1.3,13.6c0.7,3,0.8,4.7,0.6,7.7c-0.1,1.2,1.6,3.5,2,5.1
        		c1.7,6.8,2.7,13.1,2.7,22c0.1,12.6-2,21.6-4.5,28.3c-2.8,7.3-4.6,11.1-5.1,13.3c-0.5,1.8,1,6.6,2,10.9c0.8,3.5,1.6,12.1,0.4,19.2
        		c-1.1,6.4-4.6,13.7-5.8,17.2c-2.1,5.7-1.5,8.3-0.5,9.8c1.4,2.1-0.8,2.8,3.4,5.9c3.1,2.3,3.4,2.9,3.4,4.1c0,1.1-2.3,1-2.3,1
        		s-9.1,0-11,0c-1.5,0-3,0.2-3.6-2c-0.5-1.6-0.1-3.2,0-5.2c0.1-1.2,0.4-4.8,0.4-6.3c0.2-3.8,0.1-4.4-0.3-12.1
        		c-0.2-3.8-0.6-4-0.6-15.9c0-5.6,2.3-7.8,1.6-11.4c-0.9-4.3-1.3-5.2-1.2-9.7c0-1.3-0.2-11.7-0.1-22.8c0.1-10.3-0.3-29.4-0.3-29.4
        		S64.2,147,64,151.2c-0.1,4.8,0.3,10.8,0.4,16.3c0.1,11.1-0.5,20.3-0.5,21.6c0,4.4-0.6,7.2-0.8,8.8c-0.3,2.9-0.9,3.2,0.3,7.8
        		c1.2,4.8,0.2,11-0.1,14.8c-0.8,9.4-0.9,12.4-0.7,16.2c0.1,1.5,0.6,4.2,0.6,6.5c0,2,0.3,3.9-0.2,5.4c-0.6,2.1-1.5,2-3.1,2
        		c-2,0-11.6,0-11.6,0s-2.3,0.1-2.3-1c0-1.2,0.4-1.7,3.4-4.1c4.1-3.1,3.8-3.6,3.9-6.1c0.1-2.2,0.7-4-1.3-9.7
        		c-1.3-3.5-4.3-11.8-5.4-18.2c-1.2-7.1-0.6-15,0.2-18.5c1-4.3,0.7-8.5,0.3-10.4c-0.6-2.2-1.7-5.8-3.8-13.4
        		c-2.3-8.3-3.7-14.7-3.7-27.3c0-8.6,1-17.4,2.4-22.3c0.5-1.6,2.2-4,2.2-5.7c0-3.1,0.7-4.2,1.1-7.3c0.5-4-0.5-8.9-1.8-14
        		c-0.7-2.6-1.5-4.9-1.5-4.9c0.2,3.9-1.1,8.4-1.4,13c-0.4,4.6-2.7,7.6-4.3,10.3c-1.1,1.8-5.5,8.6-8,13.1c-1.9,3.4-6.8,7.6-6.8,11.6
        		c0,5.9,0.4,7.5-0.4,10.1c-0.8,2.5-1.1,3.2-1.6,5.6c-0.2,0.8-0.4,3.1-1.5,3.6c-0.8,0.4-1.4-0.1-1.4-0.1s-0.3,2.1-1.8,2.7
        		c-1,0.3-1.4,0-1.4,0s-0.2,0.9-1.7,0.9c-1.4,0-1.8-1.2-1.8-1.2c-2.8,0-2.7-3.1-2.7-4.4c-0.1-1.7-0.3-3.8-0.3-7c0-2.3,0.3-4,0.3-4
        		c-1.7,1.1-2.9,2.8-4.7,2.8c-1.7,0-0.8-2,0.5-4s0.9-4.2,3.2-6.3c3.6-3.4,5.8-5.7,5.8-5.7c2.3-3.2,6.5-13.4,9.4-23.3
        		c2.5-8.8,5.3-12,5.8-14c0.8-3.5,1.8-8.9,1.8-13.6c0-2.9,0-5,0-12.5c0-6.3,0.3-9.4,4-13c2.5-2.5,7.4-4.4,11.3-5.3
        		c5.4-1.3,12.8-2.5,12.8-7c0-2.4,0-5.1,0-5.1c0-1-0.8-1.7-0.8-1.7c-3.5-3.5-3.3-9.5-3.3-9.5c-2,0-2.5-2.1-2.5-4.1c0-2.6,2-1.8,2-1.8
        		s-0.2-1.8-0.2-2.8C51.7,7.1,57.7,2.3,64.4,2.3C70.5,2.3,77,6,77,14c0,0.9-0.2,3.7-0.2,3.7s2-0.8,2,1.3c0,2-0.6,4.3-2.7,4.3
        		c0,0,0.2,6.4-2.9,9.5C73.1,32.9,72.2,33.5,72.2,34.7z"
          />
          <g>
            <g>
              <path
                fill={strokeColor}
                d="M41.5,93.3c0,0-0.1-0.3-0.2-0.7c-0.1-0.4-0.2-1.1-0.3-1.8c-0.1-0.8-0.1-1.7-0.2-2.6c0-0.5,0-1,0-1.5
        				c0-0.5,0.1-1,0.1-1.6c0.1-0.9,0.2-2,0.4-2.9c0.1-0.5,0.2-0.9,0.3-1.4c0.2-0.5,0.1-0.7,0.1-1.1c-0.1-0.7-0.2-1.4-0.2-1.8
        				c-0.1-0.4-0.1-0.7-0.1-0.7l0.1,0c0,0,0.3,1,0.6,2.5c0.1,0.4,0.1,0.8,0.1,1.2c0,0.4-0.1,0.9-0.1,1.4c0,0.5,0,1-0.1,1.5
        				c0,0.5,0,1-0.1,1.5c-0.2,1-0.2,2-0.3,2.9c0,0.9-0.1,1.8-0.1,2.6c0,1.5,0,2.5,0,2.5L41.5,93.3z"
              />
            </g>
            <g>
              <g>
                <path
                  fill={strokeColor}
                  d="M10.1,157.6c0,0-0.3-0.8-0.5-1.9c-0.1-0.6-0.2-1.2-0.3-1.9c-0.1-0.7-0.1-1.5-0.1-2.2
        					c0-0.8,0-1.5,0.1-2.2c0-0.3,0.1-0.7,0.1-1.1c0.1-0.4,0.2-0.7,0.3-1c0.4-1.1,1-1.7,1-1.7l0.1,0c0,0-0.3,0.7-0.5,1.8
        					c-0.1,0.6-0.1,1.2-0.1,1.9c0,0.7,0,1.5,0,2.2C10.2,154.5,10.2,157.6,10.1,157.6L10.1,157.6z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M12.8,157.7c0,0-0.2-0.8-0.3-2c-0.1-0.6-0.1-1.3-0.1-2.1c0-0.8,0.1-1.6,0.1-2.4c0-0.8,0-1.6,0.1-2.4
        					c0.1-0.8,0.2-1.5,0.3-2.1c0.1-0.6,0.3-1.1,0.4-1.4c0.1-0.3,0.1-0.5,0.1-0.5h0.1c0,0,0,0.2,0,0.6c0,0.4-0.1,0.9-0.2,1.5
        					c-0.1,0.6-0.1,1.3-0.1,2.1c0,0.7,0.1,1.5,0.1,2.4c0,0.8,0.1,1.6,0.1,2.4c0,0.8-0.1,1.5-0.2,2.1
        					C13.1,156.9,12.9,157.7,12.8,157.7L12.8,157.7z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M16.1,156c0,0-0.2-0.7-0.4-1.7c0-0.2-0.1-0.5-0.1-0.8c0-0.3-0.1-0.6-0.1-0.9c0-0.6,0-1.3,0-1.9
        					c0-0.3,0.1-0.7,0.1-1c0-0.3,0.1-0.6,0.1-0.9c0.1-0.6,0.2-1.2,0.4-1.7c0.3-1,0.6-1.6,0.6-1.6h0.1c0,0,0,0.7,0,1.6
        					c-0.1,1-0.1,2.3-0.2,3.6C16.3,153.3,16.2,156,16.1,156L16.1,156z"
                />
              </g>
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M35,101.9c0,0,0.4-0.1,0.9-0.3c0.5-0.2,1.2-0.4,1.8-0.7c0.3-0.1,0.6-0.3,0.9-0.4c0.3-0.1,0.6-0.2,0.9-0.2
        				c0.5-0.1,1-0.1,1-0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0-0.2,0.4-0.7,0.7c-0.2,0.2-0.5,0.3-0.8,0.5c-0.3,0.1-0.7,0.2-1.1,0.2
        				c-0.7,0.1-1.5,0.2-2,0.3C35.4,102,35,102,35,101.9C35,102,34.9,102,35,101.9C34.9,102,35,101.9,35,101.9z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill={strokeColor}
                d="M85.9,77.9c0,0,0.3,0.9,0.4,2.3c0.1,0.7,0.1,1.5,0.1,2.4c0,0.2,0,0.4,0,0.7c0,0.2-0.1,0.4-0.1,0.7
        				c0,0.4,0,0.8,0,1.2c0,0.9,0,1.8,0,2.7c0,0.9-0.1,1.7-0.1,2.3c-0.1,1.4-0.3,2.3-0.3,2.3l-0.1,0c0,0-0.1-0.9-0.2-2.3
        				c-0.1-1.4-0.2-3.2-0.3-5c0-0.5,0-0.9,0-1.4c0.1-0.5,0.1-0.9,0.2-1.3c0.1-0.9,0.1-1.7,0.2-2.3C85.8,78.8,85.8,77.9,85.9,77.9
        				L85.9,77.9z"
              />
            </g>
            <g>
              <g>
                <path
                  fill={strokeColor}
                  d="M109.4,155.9c0,0-0.3-0.7-0.5-1.7c-0.1-0.5-0.2-1.1-0.3-1.7c-0.1-0.6-0.2-1.3-0.2-1.9c0-0.3,0-0.7-0.1-1
        					c0-0.3-0.1-0.6-0.1-0.9c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.4c0-0.3-0.1-0.6-0.1-0.8c-0.1-1,0.1-1.8,0.1-1.8l0.1,0
        					c0,0,0.2,0.6,0.5,1.6c0.1,0.5,0.3,1,0.4,1.7c0.1,0.6,0.2,1.3,0.2,2C109.4,153.2,109.5,155.9,109.4,155.9L109.4,155.9z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M112.3,157.7c0,0-0.2-0.9-0.4-2.2c-0.2-1.3-0.4-3-0.5-4.8c-0.1-0.9-0.1-1.7-0.3-2.5
        					c-0.2-0.8-0.3-1.6-0.4-2.2c-0.1-0.7-0.2-1.2-0.2-1.6c0-0.4,0-0.6,0-0.6l0.1,0c0,0,0,0.2,0.1,0.6c0.1,0.4,0.2,0.9,0.4,1.5
        					c0.2,0.6,0.4,1.4,0.7,2.1c0.2,0.8,0.3,1.7,0.5,2.6c0.3,1.7,0.3,3.5,0.3,4.8C112.5,156.8,112.4,157.7,112.3,157.7L112.3,157.7z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M115.4,157.7c0,0-0.2-0.8-0.4-2.1c-0.1-0.6-0.2-1.3-0.3-2.1c0-0.4-0.1-0.8-0.1-1.2c0-0.4,0-0.8-0.1-1.2
        					c0-0.4,0-0.8-0.1-1.2c-0.1-0.4-0.1-0.8-0.1-1.1c-0.1-0.7-0.2-1.4-0.3-2c-0.3-1.2-0.6-2-0.6-2l0.1-0.1c0,0,0.6,0.6,1,1.8
        					c0.5,1.1,0.9,2.8,1,4.5c0,1.7,0,3.3,0,4.6C115.5,156.8,115.5,157.7,115.4,157.7L115.4,157.7z"
                />
              </g>
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M96.4,103.1c0,0-0.4,0.2-1.1,0.2c-0.3,0-0.7,0-1.1-0.1c-0.4,0-0.9-0.2-1.3-0.3c-0.4-0.2-0.8-0.4-1.2-0.6
        				c-0.4-0.3-0.6-0.6-0.8-0.8c-0.4-0.6-0.5-1-0.5-1c0,0,0.1,0.1,0.3,0.2c0.1,0.1,0.4,0.3,0.6,0.4c0.1,0.1,0.3,0.2,0.4,0.2
        				c0.1,0.1,0.3,0.2,0.5,0.2c0.3,0.2,0.7,0.3,1.1,0.5c0.8,0.3,1.5,0.5,2.1,0.7C96,102.9,96.4,103.1,96.4,103.1z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill={strokeColor}
                d="M69.3,186.1c0,0,0.4-0.3,0.9-0.4c0.3-0.1,0.6-0.2,1-0.2c0.4,0,0.8,0,1.2,0.1c0.8,0.1,1.6,0.1,2.2,0.3
        				c0.6,0.1,1,0.3,1,0.3l0,0.1c0,0-0.4,0-1,0c-0.6,0-1.4,0.1-2.1,0.2c-0.4,0-0.8,0.1-1.1,0.2c-0.4,0-0.7,0-1-0.1
        				C69.7,186.4,69.3,186.2,69.3,186.1L69.3,186.1z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M51.7,186.3c0,0,0.4-0.2,1-0.3c0.6-0.1,1.4-0.2,2.3-0.2c0.8,0,1.7,0.1,2.3,0.2c0.3,0.1,0.6,0.1,0.8,0.2
        				c0.1,0,0.2,0,0.2,0.1c0,0-0.1,0-0.1,0s0,0,0.1,0c0,0.1-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.1-0.8,0.2c-0.6,0.1-1.4,0.2-2.3,0.2
        				c-0.8,0-1.7-0.1-2.3-0.2C52.1,186.4,51.7,186.3,51.7,186.3z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill={strokeColor}
                d="M74.6,250.5c0,0,0.3-0.4,0.8-0.6c0.2-0.1,0.5-0.2,0.8-0.3c0.1,0,0.3,0,0.4,0c0.2,0,0.3,0,0.4,0
        				c0.1,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.1-0.3,0-0.5c-0.1-0.2-0.2-0.4-0.2-0.5c-0.1-0.1-0.1-0.2-0.1-0.2l0.1-0.1
        				c0,0,0.3,0.2,0.7,0.6c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.4,0,0.7c-0.1,0.3-0.3,0.5-0.4,0.6c-0.2,0.2-0.4,0.3-0.5,0.3
        				c-0.4,0.2-0.7,0.3-1.1,0.4c-0.3,0-0.7,0-0.9-0.1c-0.5-0.2-0.9-0.5-0.9-0.5L74.6,250.5z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M52.6,250.7c0,0-0.2,0.4-0.6,0.6c-0.2,0.1-0.5,0.2-0.8,0.1c-0.3-0.1-0.6-0.3-0.9-0.5
        				c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.2,0-0.3,0-0.4c0-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.4,0.2-0.5
        				c0.1-0.1,0.1-0.2,0.1-0.2l0.1,0c0,0,0,0.1,0,0.2c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.1,0.2,0.1,0.2
        				c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.2,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0.1,0.5,0.2c0.3,0.2,0.6,0.5,0.6,0.5
        				L52.6,250.7z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill={strokeColor}
                d="M78.3,140.7c-1.5,0.5-3.2,0.7-4.8,0.7c-0.8,0-1.6-0.1-2.5-0.2c-0.4-0.1-0.8-0.2-1.2-0.2
        				c-0.4-0.1-0.8-0.2-1.2-0.4c-0.4-0.2-0.8-0.3-1.2-0.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.2-0.5-0.3c-0.7-0.5-1.3-1.2-1.8-2
        				c-0.4-0.8-0.7-1.6-0.9-2.5c-0.1-0.8-0.2-1.7-0.2-2.5l1.5-0.1c0.1,0.5,0.1,0.9,0.1,1.3c0,0.4,0,0.9-0.1,1.3
        				c-0.1,0.9-0.3,1.7-0.7,2.5c-0.2,0.4-0.4,0.8-0.7,1.1c-0.1,0.2-0.3,0.3-0.4,0.5l-0.1,0.1l0,0l-0.1,0.1l-0.1,0l-0.2,0.2
        				c-0.3,0.2-0.8,0.5-1.1,0.7c-0.4,0.2-0.8,0.4-1.2,0.5c-0.8,0.3-1.6,0.4-2.4,0.5c-0.8,0.1-1.6,0.1-2.4,0.1c-1.6,0-3.2-0.1-4.8-0.2
        				c1.6-0.1,3.2-0.2,4.8-0.4c0.8-0.1,1.6-0.2,2.3-0.3c0.8-0.1,1.5-0.3,2.2-0.6c0.4-0.1,0.7-0.3,1-0.5c0.3-0.2,0.6-0.3,0.9-0.6
        				l0.2-0.2l0.1-0.1l0,0l0,0c0,0,0,0,0,0l0,0l0,0l0.1-0.1c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.4-0.6,0.5-0.9c0.3-0.7,0.4-1.4,0.5-2.1
        				c0-0.4,0.1-0.7,0-1.1c0-0.4,0-0.8-0.1-1.1l1.5-0.1c0,1.5,0.2,3.1,0.8,4.3c0.3,0.6,0.8,1.2,1.3,1.6c0.1,0.1,0.3,0.2,0.5,0.3
        				c0.1,0.1,0.3,0.2,0.5,0.3c0.3,0.2,0.7,0.4,1,0.5c0.3,0.2,0.7,0.3,1.1,0.4c0.4,0.1,0.7,0.2,1.1,0.3c0.7,0.2,1.5,0.3,2.3,0.4
        				C75.1,140.9,76.7,140.9,78.3,140.7z"
              />
            </g>
            <g>
              <polygon
                fill={strokeColor}
                points="63.5,132.5 63.9,123.7 65,132.5 			"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill={strokeColor}
                d="M53.1,26.2c0,0-0.3-0.6-0.5-1.4c-0.1-0.4-0.2-0.9-0.3-1.4c0-0.3-0.1-0.5-0.1-0.8c0-0.3,0-0.5-0.1-0.8
        				c0-0.3,0-0.6,0-0.8c0-0.3,0-0.5,0-0.8c0-0.5,0.1-1,0.1-1.4c0.1-0.8,0.2-1.4,0.2-1.4l0.1,0c0,0,0.1,0.5,0.3,1.4
        				c0.1,0.8,0.2,1.9,0.3,3.1c0.1,2.2,0.1,4.5,0.1,4.5L53.1,26.2z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M75.8,24.4c0,0-0.2-0.6-0.4-1.4c-0.1-0.4-0.1-0.9-0.1-1.4c0-0.5,0-1,0-1.6c0-0.3,0.1-0.5,0.1-0.8
        				c0-0.3,0.1-0.5,0.1-0.8c0.1-0.5,0.2-1,0.4-1.3c0.3-0.8,0.6-1.3,0.6-1.3l0.1,0c0,0-0.2,2.1-0.3,4.3C76.1,22.3,75.9,24.4,75.8,24.4
        				L75.8,24.4z"
              />
            </g>
          </g>
        </g>
      </SVG>
    );
  }
}

export default BodyBack;
