export const itemKeyword = 'item';
export const dataKeyword = 'data';
export const repeaterKeyDivider = '.0.';
export const repeaterItemDivider = `.${itemKeyword}.`;
export const dateFormat = 'YYYY-MM-DD';
export const expectations = {
  neq: 'neq',
  eq: 'eq',
  lt: 'lt',
  lte: 'lte',
  gt: 'gt',
  gte: 'gte',
  inq: 'inq',
  ninq: 'ninq',
};
