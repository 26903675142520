import { isEmpty } from 'lodash';
import HyperlinkFieldOption from './getHyperlinkFieldOptions';

export default class {
  constructor() {
    this.name = 'hyperlink';
    this.label = '';
    this.component = HyperlinkFieldOption;
    this.defaultValue = { url: '', urlText: '', staticContent: true };
  }

  isValid = values => {
    const { url, staticContent = true } = values[this.name];

    return staticContent ? !isEmpty(url) : true;
  };

  onChange = (value, values) => {
    return {
      ...values,
      [this.name]: value,
    };
  };

  onSave = values => {
    const { url, urlText, staticContent = true } = values[this.name];

    return {
      ...values,
      [this.name]: undefined,
      options: {
        url,
        urlText,
        staticContent,
      },
    };
  };

  getInitialValue = values => {
    if (values.options === undefined) {
      return this.defaultValue;
    }

    const { url = '', urlText = '', staticContent = true } = values.options;

    return {
      url,
      urlText,
      staticContent,
    };
  };
}
