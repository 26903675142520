import getFieldData from './getFieldData';

const mapEquipmentFieldValues = ({
  defaultedModalFields = [],
  equipmentRecord = {},
}) => {
  if (equipmentRecord && equipmentRecord.fieldsValues) {
    return defaultedModalFields.map(record => {
      const equipmentField = equipmentRecord.fieldsValues.find(
        ({ id }) => id === record.id
      );
      if (equipmentField) {
        return {
          ...record,
          fieldValueId: equipmentField.fieldValueId,
          defaultValue:
            equipmentField.fieldValue === 'null'
              ? record.defaultValue
              : getFieldData(equipmentField),
        };
      }
      return record;
    });
  }
  return defaultedModalFields;
};

export default mapEquipmentFieldValues;
