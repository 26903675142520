import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';

import { TAB } from 'APP_ROOT/constants/layoutComponentTypes';

import sortTabs from '../../services/utils/sortTabs';
import TabsWrapper from './TabList.styled';

// Drag & Drop node
class TabNode extends React.Component {
  render() {
    const { connectDragSource, connectDropTarget, children } = this.props;

    return connectDragSource(connectDropTarget(children));
  }
}

const cardTarget = {
  drop(props, monitor) {
    const dragKey = monitor.getItem().index;
    const hoverKey = props.index;

    if (dragKey === hoverKey) {
      return;
    }

    props.moveTabNode(dragKey, hoverKey);
    monitor.getItem().index = hoverKey;
  },
};

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const WrapTabNode = DropTarget(TAB, cardTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))(
  DragSource(TAB, cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))(TabNode)
);

class DraggableTabs extends React.Component {
  state = {
    order: [],
  };

  moveTabNode = (dragKey, hoverKey) => {
    const newOrder = this.state.order.slice();
    const { children = [], onChangeTabOrder } = this.props;

    children.forEach(c => {
      if (newOrder.indexOf(c.key) === -1) {
        newOrder.push(c.key);
      }
    });

    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);

    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragKey);

    this.setState({
      order: newOrder,
    });
    onChangeTabOrder(newOrder);
  };

  renderTabBar = (props, DefaultTabBar) => (
    <DefaultTabBar {...props}>
      {node => (
        <WrapTabNode
          key={node.key}
          index={node.key}
          moveTabNode={this.moveTabNode}
        >
          {node}
        </WrapTabNode>
      )}
    </DefaultTabBar>
  );

  render() {
    const { children } = this.props;
    const { order } = this.state;

    const tabs = [];
    children.forEach(c => {
      tabs.push(c);
    });

    const orderTabs = sortTabs(tabs, order);

    return (
      <TabsWrapper renderTabBar={this.renderTabBar} {...this.props}>
        {orderTabs}
      </TabsWrapper>
    );
  }
}

export default DraggableTabs;
