import React from 'react';

import createModal from 'APP_ROOT/actions/create-modal';
import showModal from 'APP_ROOT/actions/show-modal';
import { DELETE_MODAL_ID } from '../constants/delete';
import DeleteModal from '../components/DeleteModal';
import DeleteModalHeader from '../components/DeleteModalHeader';
import DeleteModalFooter from '../components/DeleteModalFooter';
import ChangesCaseFileDelete from '../services/changesCaseFileDelete';

export default isCasefileClosed => {
  return (dispatch, getState) => {
    const title = <DeleteModalHeader />;
    const body = <DeleteModal />;
    const footer = <DeleteModalFooter isCasefileClosed={isCasefileClosed} />;
    ChangesCaseFileDelete.setIsOpenModal(true);
    dispatch(
      createModal({
        id: DELETE_MODAL_ID,
        visible: true,
        title,
        children: body,
        footer,
      })
    );
    dispatch(showModal(DELETE_MODAL_ID));
  };
};
