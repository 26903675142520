import store from 'APP_ROOT/store';
import { UPDATE_TAB_WITH_ERRORS } from '../../actions';
import uniq from 'lodash/uniq';

export const setTabsWithErrors = (current, errors = [], tabsWithErrors) => {
  let shouldReset = false;
  if (errors.length === 0) {
    shouldReset = true;
  }

  if (errors.length > 0 && errors[current].length === 0) {
    shouldReset = true;
  }

  if (
    errors.length > 0 &&
    errors[current].length > 0 &&
    !tabsWithErrors.includes(current)
  ) {
    updateTabsWithError([...tabsWithErrors, current]);
  }

  if (shouldReset) {
    updateTabsWithError([...tabsWithErrors.filter(item => item !== current)]);
  }
};

export const updateTabsWithError = (tabsWithErrors = []) => {
  store.dispatch({ type: UPDATE_TAB_WITH_ERRORS, payload: tabsWithErrors });
};

export const validateCurrentTabFields = (
  current,
  validateFields,
  tabsWithErrors
) => {
  validateFields(errors => {
    if (errors) {
      updateTabsWithError(uniq([...tabsWithErrors, current]));
    } else {
      setTabsWithErrors(current, undefined, tabsWithErrors);
    }
  });
};
