import styled from 'styled-components';

const PageWrapper = styled.div`
  padding: 24px;
`;

const Content = styled.div`
  background: #fff;
  padding: 16px;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: 600;
`;

export { PageWrapper, Content, Header, Title };
