import { createElement } from 'react';
import { Col } from 'antd';
import { memoize } from 'lodash';

import { halfColumn } from '../ReviewPanel/ReviewPanel.constants';

const HalfSizeColumn = memoize(({ children, ...props }) =>
  createElement(Col, Object.assign({}, halfColumn, props), children)
);

export default HalfSizeColumn;
