import React from 'react';
import get from 'lodash/get';
import { Row, Col, Button, Icon } from 'antd';
import Panel, {
  PanelSection,
  PanelTitle,
  PanelSectionTable,
  Divider,
} from '../common/panel';
import { Link } from 'react-router-dom';
import IconButton from '../common/buttons/icon-button';
import InformationPanel from './information-panel';
import route from '../../utils/get-route';
import getLmsUrl from 'APP_ROOT/utils/getLmsUrl';
import { hasPermissions, PERMISSIONS } from '../../utils/admin';

function parseUpcomingCourse(upcomingCourse = {}) {
  if (!upcomingCourse.name || !upcomingCourse.status) return [];

  return [
    {
      label: 'Course',
      value: upcomingCourse.name,
    },
    {
      label: 'Status',
      value: upcomingCourse.status,
    },
  ];
}

function parseLastFirearmCertification(firearmCertification = {}) {
  if (!firearmCertification.date) return [];

  return [
    {
      label: 'Qualification',
      value: `Complete ${firearmCertification.date}`,
    },
    {
      label: 'Certified by',
      value: 'P.O. Smith, Star 4917',
    },
  ];
}

const TrainingReportPanel = ({
  user = {},
  nonFirearmCertifications = [],
  latestUpcomingCourse = {},
  lastFiremarmCertification = {},
  permissions = [],
}) => (
  <div>
    <Row gutter={16} type="flex">
      <Col xs={24} sm={12}>
        <Row>
          <InformationPanel
            renderTitle={() => (
              <Row type="flex" align="middle">
                <Col span={10}>
                  <h2>Upcoming Training</h2>
                </Col>
                <Col span={14} className="text-right">
                  <Link to={route('officerTranscripts', { id: user.id })}>
                    <Icon type="arrow-right" />
                  </Link>
                </Col>
              </Row>
            )}
            data={parseUpcomingCourse(latestUpcomingCourse)}
          />
        </Row>
        {lastFiremarmCertification.title && (
          <Row>
            <InformationPanel
              renderTitle={() => (
                <Row type="flex" align="middle">
                  <Col span={10}>
                    <h2>{lastFiremarmCertification.title}</h2>
                  </Col>
                  <Col span={14} className="text-right">
                    <Link to={route('officerTranscripts', { id: user.id })}>
                      <Icon type="arrow-right" />
                    </Link>
                  </Col>
                </Row>
              )}
              data={parseLastFirearmCertification(lastFiremarmCertification)}
              right={<IconButton icon="arrow-right" />}
            />
          </Row>
        )}

        {hasPermissions(permissions, PERMISSIONS.accessAssignTrainingPage) && (
          <Row>
            <Panel>
              <PanelTitle>
                <h2>Assign Training</h2>
              </PanelTitle>
              <Divider />
              <PanelSection>
                <a
                  href={getLmsUrl(
                    `/lms/admin/edit/user/${get(
                      user,
                      ['kmi_user', 'kmiId'],
                      null
                    )}/registrations/create`
                  )}
                >
                  <Button type="primary" size="default">
                    Assign Training
                  </Button>
                </a>
              </PanelSection>
            </Panel>
          </Row>
        )}
      </Col>
      <Col xs={24} sm={12}>
        <Row>
          <Panel>
            <PanelTitle>
              <Row type="flex" align="middle">
                <Col span={10}>
                  <h2>Certifications</h2>
                </Col>
                <Col span={14} className="text-right">
                  <Link to={route('officerTranscripts', { id: user.id })}>
                    <Icon type="arrow-right" />
                  </Link>
                </Col>
              </Row>
            </PanelTitle>
            <Divider />
            {nonFirearmCertifications && nonFirearmCertifications.length ? (
              nonFirearmCertifications.map((c, idx) => (
                <PanelSectionTable key={`${c.id}.${idx}`}>
                  <Row type="flex" align="middle">
                    <Col>
                      <p className="title">{c.name}</p>
                    </Col>
                  </Row>
                </PanelSectionTable>
              ))
            ) : (
              <PanelSectionTable>
                <Row type="flex" align="middle">
                  <Col>
                    <p className="title">No records found</p>
                  </Col>
                </Row>
              </PanelSectionTable>
            )}
          </Panel>
        </Row>
        <Row>
          <Panel>
            <PanelTitle className="is-paddingless">
              <Row type="flex" align="middle">
                <Col span={10}>
                  <h2>Training Transcript</h2>
                </Col>
                <Col span={14} className="text-right">
                  <Link to={route('officerTranscripts', { id: user.id })}>
                    <Icon type="arrow-right" />
                  </Link>
                </Col>
              </Row>
            </PanelTitle>
          </Panel>
        </Row>
      </Col>
    </Row>
  </div>
);
export default TrainingReportPanel;
