import has from 'lodash/has';
import each from 'lodash/each';

import getTabPresentation from './get-tab-presentation';
import getDataFieldPresentation from './get-data-field-presentation';
import getItemWithKey from '../getItemWithKey';

export default (fields, data = {}, overrides, enums) => ({
  parent,
  parentKey,
  getPresentation,
  withProfileData,
}) => (property, index) => {
  if (has(property, 'type') && property.type === 'tab')
    getTabPresentation({
      fields,
      property,
      index,
      getPresentation,
      withProfileData,
    });
  else if (has(property, 'key') && getItemWithKey(property.type)) {
    getDataFieldPresentation({
      fields,
      property,
      index,
      parent,
      parentKey,
      overrides,
      data,
      enums,
      getPresentation,
      withProfileData,
    });
  } else {
    each(
      property.properties,
      getPresentation({ parent, parentKey, getPresentation, withProfileData })
    );
  }
};
