import get from 'lodash/get';
import {
  GET_FORM_HISTORY,
  GET_FORM_HISTORY_ERROR,
  GET_FORM_HISTORY_SUCCESS,
} from './';

import { getReport } from '../api/reports';

const callback = (cb, ...args) => {
  if (cb && typeof cb === 'function') {
    cb(...args);
  }
};

export default (id, next) => {
  return async (dispatch, getState) => {
    const { session } = getState();
    const agencyId = get(session, 'currentUser.agencyId', null);

    return new Promise(async (resolve, reject) => {
      if (agencyId) {
        const filter = {
          fields: {
            data: false,
            created: false,
            updated: false,
            lastReviewerDate: false,
            isDeleted: false,
          },
          include: [
            {
              relation: 'participants',
              scope: {
                fields: [
                  'fullName',
                  'lastName',
                  'firstName',
                  'starNumber',
                  'email',
                  'rankId',
                ],
                include: [
                  {
                    relation: 'rank',
                    scope: {
                      fields: { name: true, abbreviation: true, id: true },
                    },
                  },
                ],
              },
            },
            {
              relation: 'linkedSubmissions',
              scope: {
                include: {
                  relation: 'actions',
                  scope: {
                    where: { status: 'pending' },
                  },
                },
              },
            },
          ],
        };

        dispatch({ type: GET_FORM_HISTORY, payload: { filter, id, agencyId } });

        try {
          const report = await getReport({ agencyId, filter, id });
          dispatch({
            type: GET_FORM_HISTORY_SUCCESS,
            payload: { filter, id, agencyId, report },
          });

          callback(next, null, report);
          resolve();
        } catch (error) {
          dispatch({ type: GET_FORM_HISTORY_ERROR, payload: error });
          callback(next, error);
          reject();
        }
      } else {
        dispatch({ type: GET_FORM_HISTORY_ERROR, payload: 401 });
        callback(next, 401);
        reject();
      }
    });
  };
};
