import _ from 'lodash';

import { GET_TRAINING_USERS_REQUEST } from './';

import { getTrainingUsers } from '../api/users';
import getUsersSuccess from './get-users-success';
import getUsersError from './get-users-error';

const usersFilter = { include: 'rank' };

export default (page = 1, filters = usersFilter, next = () => {}) => {
  return (dispatch, getState) => {
    const {
      session: { currentUser: { agencyId = '' } = {} },
      users: { per_page = 10 },
    } = getState();
    dispatch({
      type: GET_TRAINING_USERS_REQUEST,
      payload: { filters, page },
    });

    const limit = filters.per_page || per_page || 10;

    const filter = {
      ...(filters || {}),
      limit,
      skip: (page > 0 ? page - 1 : page) * limit,
      order: 'lastName ASC',
    };

    getTrainingUsers(agencyId, filter)
      .then(data => {
        const { dashboard, count } = data;
        dispatch(getUsersSuccess({ data: dashboard, count }));
        next(data);
      })
      .catch(error => {
        dispatch(getUsersError(_.get(error, ['response', 'status'])));
        next(error);
      });
  };
};
