import React, { useEffect, useState } from 'react';

const useEquipmentDefault = ({
  loading,
  selectedCategory = '',
  equipmentModalFields,
}) => {
  const [equipmentForm, setEquipmentForm] = useState([]);

  const updateFieldValues = defaultFields => {
    setEquipmentForm(getDefaultFieldValues(defaultFields));
  };

  const getDefaultFieldValues = defaultFields => {
    return defaultFields.map(field => ({
      fieldId: field.id,
      fieldValueId: field.fieldValueId,
      value: field.defaultValue,
      type: field.type,
    }));
  };

  useEffect(() => {
    if (!loading && selectedCategory) {
      const defaultFields = equipmentModalFields.filter(
        field => field.defaultValue
      );
      updateFieldValues(defaultFields);
    }
  }, [loading, selectedCategory]);

  return { equipmentForm };
};

export default useEquipmentDefault;
