import React from 'react';
import { Link } from 'react-router-dom';
import savePreviousPath from 'APP_ROOT/utils/savePreviousPath';
import getRoute from 'APP_ROOT/utils/get-route';

const goToProfile = (text, record) => {
  return (
    <Link
      to={savePreviousPath(
        getRoute('administratorAgencyUser', {
          agencyId: record.agencyId,
          userId: record.id,
        })
      )}
    >
      {text}
    </Link>
  );
};

//to be removed when the configuration feature comes around
export const constants = {
  copost: 'copost',
  certified: 'certified',
  removeOnDataIndex: 'dataIndex',
  removeOnKey: 'key',
};

export const employeeTableDataCol = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    render: (text, record) => goToProfile(text, record),
  },
  {
    title: 'Title',
    dataIndex: 'title',
  },
  {
    title: 'Benchmark Id',
    dataIndex: 'benchmarkId',
  },
  {
    title: 'Organizational Unit Name(s)',
    dataIndex: 'ouNames',
  },
  {
    title: 'Certified',
    dataIndex: 'certified',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
];

export const employeeDownloadHeader = [
  {
    label: 'Name',
    key: 'fullName',
  },
  {
    label: 'Title',
    key: 'title',
  },
  {
    label: 'Benchmark Id',
    key: 'benchmarkId',
  },
  {
    label: 'Organizational Unit Name(s)',
    key: 'ouNames',
  },
  {
    label: 'Certified',
    key: 'certified',
  },
  {
    label: 'Email',
    key: 'email',
  },
];

export const getEmployeeHeaderColumnsBaseOnTenantSlug = (
  columns,
  tenantSlug,
  key,
  valueToRemove
) => {
  const isCertifiedColumnAllow = constants.copost === tenantSlug;
  if (!isCertifiedColumnAllow)
    return columns.filter(column => column[key] !== valueToRemove);
  return columns;
};
