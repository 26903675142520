import React, { PureComponent } from 'react';
import { Alert } from 'antd';
import styled from 'styled-components';
import { BLUE } from '../../../config/theme';

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;

  &.ant-alert-info,
  &.ant-alert-success,
  &.ant-alert-warning,
  &.ant-alert-error {
    background: none;
    border: none;
    padding-left: 22px;
  }

  & .ant-alert-icon {
    left: 0;
  }

  & .ant-alert-message {
    color: ${BLUE};
  }
`;
export default class extends PureComponent {
  render() {
    const { alertType = 'success', message = '', options } = this.props;

    return (
      <StyledAlert
        type={alertType}
        message={message}
        className={options.className}
        showIcon
      />
    );
  }
}
