import React from 'react';
import AntPagination from 'antd/lib/pagination';
import Col from 'antd/lib/col';

import PaginationRow from './PaginationRow.styled';

const Pagination = ({ page, total, per_page, onChange }) => (
  <PaginationRow>
    <Col span={24} className="text-right">
      <AntPagination
        defaultCurrent={1}
        current={page}
        total={total}
        pageSize={per_page}
        onChange={onChange}
      />
    </Col>
  </PaginationRow>
);

export default Pagination;
