import { createReducer } from 'redux-create-reducer';

import {
  ORGANIZATION_GET_SSN_LOGS_SUCCESS,
  ORGANIZATION_GET_SSN_LOGS_ERROR,
  ORGANIZATION_GET_SSN_LOGS_REQUEST,
} from '../../actions/organizations/get-ssn-audit-logs';

export const initialState = {
  loading: false,
  total: undefined,
  totalPages: undefined,
  lastPage: undefined,
  lastPerPage: undefined,
  lastFilter: undefined,
  error: undefined,
  entries: [],
};

const events = {
  [ORGANIZATION_GET_SSN_LOGS_REQUEST]: (
    state,
    { payload: { page, size, filter } }
  ) => ({
    ...state,
    lastPage: page,
    lastPerPage: size,
    lastFilter: filter,
    loading: true,
  }),
  [ORGANIZATION_GET_SSN_LOGS_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    page: payload.content.pageable.pageNumber,
    pageSize: payload.content.pageable.pageSize,
    total: payload.content.totalElements,
    totalPages: payload.content.totalPages,
    entries: payload.content.content,
  }),
  [ORGANIZATION_GET_SSN_LOGS_ERROR]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
};

export default createReducer(initialState, events);
