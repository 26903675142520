import React, { Component } from 'react';
import { get, isNumber, isString } from 'lodash';
import styled from 'styled-components';
import { InputNumber, Icon } from 'antd';
import { findDOMNode } from 'react-dom';

import { ACCENT } from '../../../config/theme';
import format, { isMeasuringUnit } from '../../../utils/units-transformation';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';

const setInputFocus = node => {
  const wrapperNode = findDOMNode(node);
  const isFocused = wrapperNode.classList.contains('ant-input-number-focused');
  if (wrapperNode) {
    const inputs = wrapperNode.getElementsByTagName('input');
    if (inputs && inputs.length > 0 && !isFocused) {
      inputs[0].focus();
    }
  }
};

class Number extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  onChange = (...args) => {
    const {
      onChange,
      options: { defaultValue },
    } = this.props;
    const inputValue = args[0] ? args[0] : defaultValue;

    onChange(inputValue);

    if (this.input) {
      setInputFocus(this.input);
    }
  };
  onClickHandler = () => {
    if (this.input) {
      setInputFocus(this.input);
    }
  };

  onIncrease = value => {
    const { onChange } = this.props;
    const newValue = parseInt(value, 10) + 1;
    onChange(newValue);
  };

  formatReviewerValue(number_type, unit, calculatedValue, value) {
    const valueToParse =
      calculatedValue !== undefined ? calculatedValue : value;
    if (!isNumber(valueToParse) && !isString(valueToParse)) {
      return '';
    }
    const isMeasurement = isMeasuringUnit(number_type);
    const formattedValue = isMeasurement
      ? format(valueToParse, unit)
      : calculatedValue || value;
    return !isMeasurement && isNaN(formattedValue) ? ' ' : formattedValue;
  }

  render() {
    const {
      enums,
      options = {},
      isReviewer = false,
      dataKey,
      parentKey,
      parentIndex,
      calculatedValue,
      timezone,
      onChange,
      data,
      field_type,
      value: originalValue,
      ...props
    } = this.props;

    const {
      number_type = '',
      unit = '',
      isCounter = false,
      min = 0,
      defaultValue = 0,
      ...otherOptions
    } = options;
    let value;
    if (originalValue === undefined) {
      // fallback code since the ant component is ignoring
      // defaultValue when it is set to 0
      value = min > defaultValue ? min : defaultValue;
    } else {
      value = originalValue;
    }
    let overrideEditRights = false;
    let overrideReviewer = isReviewer;
    // Depends on fields will show as read-only always
    if (this.props.isContributeReport && !this.props.dependsOn) {
      if (this.props.contributorAssignmentCanEdit) {
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        overrideReviewer = true;
      } else {
        return <StyledReviewerOnly />;
      }
    }

    if (overrideReviewer && !overrideEditRights) {
      const formattedValueWithFallback = this.formatReviewerValue(
        number_type,
        unit,
        calculatedValue,
        value
      );
      const reviewerFromData = get(data, 'isReviewer', false);
      const isDisable = get(this.props, 'disabled', false);

      return (
        <StyledReviewerOnly>
          <div className="reviewer-field text-field">
            {formattedValueWithFallback}&nbsp;
          </div>
          {isCounter && !reviewerFromData && !isDisable && (
            <Icon
              type="plus-circle-o"
              onClick={() => this.onIncrease(formattedValueWithFallback)}
            />
          )}
        </StyledReviewerOnly>
      );
    }

    return (
      <InputNumber
        size="default"
        ref={ref => (this.input = ref)}
        min={min}
        defaultValue={defaultValue}
        upHandler={
          <StyledHandlerIcon type="up" onClick={this.onClickHandler} />
        }
        downHandler={
          <StyledHandlerIcon type="down" onClick={this.onClickHandler} />
        }
        onChange={this.onChange}
        onClick={this.onClickHandler}
        {...otherOptions}
        {...props}
        value={value}
      />
    );
  }
}

const StyledReviewerOnly = styled.div`
  display: flex;
  align-items: center;

  .anticon {
    margin: 0 10px;
    font-size: 25px;
    color: ${ACCENT};
  }
`;

const StyledHandlerIcon = styled(Icon)`
  margin-top: 3px;
`;

export default Number;
