import { compose, applyMiddleware, createStore } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import rootReducer from './reducers';
import { SERVER_ERROR } from './actions';
import analytics from './middleware/analytics';

const serverErrors = store => next => action => {
  if (action.type === SERVER_ERROR) {
    // we will track server errors in the future
  }

  let result = next(action);
  return result;
};

function configureStoreProd(initialState = {}, history) {
  const routeMiddleware = routerMiddleware(history);

  const middlewares = [thunk, routeMiddleware, analytics];

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  );
}

function configureStoreDev(initialState = {}, history) {
  // eslint-disable-next-line no-console
  console.warn('Dev store is configured, redux performance will be degraded');
  const routeMiddleware = routerMiddleware(history);

  const middlewares = [
    reduxImmutableStateInvariant({
      ignore: ['form'],
    }),
    thunk,
    routeMiddleware,
    serverErrors,
    analytics,
  ];

  const composeEnhancers =
    typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose; // add support for Redux dev tools
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextReducer = require('./reducers').default; // eslint-disable-line global-require
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

const configureStore =
  process.env.NODE_ENV === 'production'
    ? configureStoreProd
    : configureStoreDev;

export const history = createBrowserHistory();
export default configureStore({}, history);
