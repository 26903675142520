import { replace } from 'lodash';
import formActionDispatcher from 'APP_ROOT/utils/formDispatchEmitter';
import setFormValues from '../actions/set-form-values';

const events = {
  onItemsLengthReachZero: ({ effect, formName, props }) => {
    const effects = Object.entries(effect);

    effects.forEach(([effectName, args]) => {
      if (effectName === 'setFieldValue') {
        formActionDispatcher(formName, setFormValues(args, props));
      }
    });
  },
  onTaskMarkedCompleted: ({
    effect,
    items,
    prevItems,
    formName,
    type,
    props,
  }) => {
    const effects = Object.entries(effect);
    const indexDiff = items.findIndex((item, index) => {
      const currentCompleted = !!item.taskCompleted;
      const prevCompleted = !!prevItems[index].taskCompleted;

      return currentCompleted !== prevCompleted;
    });
    const { taskCompleted = true } = items[indexDiff] || {};
    const setFields = fields =>
      Object.keys(fields).reduce((result, field) => {
        const isRepeater = field.includes('.item.');
        const value = taskCompleted ? fields[field] : '';
        return {
          ...result,
          [isRepeater
            ? replace(field, '.item.', `.${indexDiff}.`)
            : field]: value,
        };
      }, {});

    effects.forEach(([effectName, args]) => {
      if (effectName === 'setFieldValue') {
        formActionDispatcher(formName, setFormValues(setFields(args), props));
      }
    });
  },
};

export default (type, ...args) => {
  const effect = events[type];
  if (effect) {
    effect(...args);
  }
};
