import { Col } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import SortAndFilterTable from '../../../../components/filterable-sortable-table/SortAndFilterTable';

import {
  StyledRow,
  StyledRowPagination,
  StyledPagination,
} from '../../../audit-logs/audit-logs-table.styled';
import { reportTimelineAuditLogsColumns } from '../AgencyAuditLogsReportTimelineLogsTableColumns';
import AgencyAuditLogsReportTimelineSearchFilters from '../AgencyAuditLogsReportTimelineSearchFilters';
const StyledTable = styled.div`
  margin: 12px 7px 0 0;
`;

const ReportTimelineAuditLogsPage = ({
  dispatch,
  agencyId,
  tenantId,
  reportTimelineLogs = [],
  timezone,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const currentDefaultForPagination = 1;

  const { data, total = 0, pageSize = 50 } = reportTimelineLogs;

  const changePage = page => setCurrentPage(page);

  return (
    <>
      <StyledRow type="flex" gutter={16} justify="end">
        <AgencyAuditLogsReportTimelineSearchFilters
          dispatch={dispatch}
          agencyId={agencyId}
          tenantId={tenantId}
          pageNumber={currentPage}
          pageSize={pageSize}
        />
        <StyledTable>
          <SortAndFilterTable
            columns={reportTimelineAuditLogsColumns(timezone)}
            data={data}
            pagination={false}
            rowKey={'id'}
            scroll={true}
          />
        </StyledTable>
      </StyledRow>
      {total > 0 && (
        <StyledRowPagination>
          <Col span={12} />
          <Col span={12}>
            <StyledPagination
              defaultCurrent={currentDefaultForPagination}
              current={currentPage}
              total={total}
              pageSize={pageSize}
              onChange={changePage}
            />
          </Col>
        </StyledRowPagination>
      )}
    </>
  );
};

export default ReportTimelineAuditLogsPage;
