import styled from 'styled-components';

import { DIVIDER, ACCENT, GRAY_5 } from '../../../config/theme';

const ReviewPanel = styled.div.attrs({
  className: 'bdm-form-review-panel',
})`
  margin: 20px;
  border-top: 1px solid ${DIVIDER};

  &.loading {
    border-color: #eaeaea;
  }

  .bdm-panel {
    border: 1px solid ${ACCENT};

    .resolution {
      .ant-select-selection__placeholder,
      .ant-select-arrow {
        color: ${GRAY_5};
      }
    }
  }

  .bdm-panel-title h2 {
    color: ${ACCENT};
  }

  .ant-btn {
    padding-left: 25px;
    padding-right: 25px;
  }
`;

export default ReviewPanel;
