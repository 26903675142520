import React, { useState, useEffect } from 'react';
import { Timeline } from 'antd';

import timelineItemByType from '../../utils/get-timeline-item-by-type';

import TimeoutItem from './timeout-item';
import WorkflowItem from './workflow-item';
import ActivityItem from './activity-item';
import ShareItem from './share-item';
import ReassignItem from './reassign-item';
import AdminEditItem from './administrative-edit-item';
import contributeReportItem from './contribute-report-item';
import { filterByIds } from '../../modules/ReportSharing/utils/networkRequestFilters';
import { getUsers } from '../../modules/ReportSharing/services/api';
import { get } from 'lodash';

const getTimelineItemByType = timelineItemByType({
  timeout: TimeoutItem,
  workflow: WorkflowItem,
  activity: ActivityItem,
  share: ShareItem,
  reassign: ReassignItem,
  formAdministrativeEdit: AdminEditItem,
  'contribute-report': contributeReportItem,
});

export default ({
  formType,
  history,
  timezone,
  participants,
  participantsIds,
  notes,
  number,
  agencyId,
  usersOnBehalf = [],
}) => {
  const [timelineData, setTimelineData] = useState([]);
  const [participantList, setParticipantList] = useState({});

  useEffect(() => {
    // Fix for SW-1043, especifically the missing user names in the timeline.
    // This issue is related to new users brought into the form life cycle via reassign,
    // which causes the new user to be missing from the participants list, which is where the timeline items check
    // for user data when displaying the timeline messages.

    // Formats the timeline data as usual, then checks for submitters that aren't present in the participant list. The missing
    // submitter data is fetched and added to the participants list so the timeline items have access to it.

    const processTimelineData = async () => {
      const formattedData = timeLineDataFormatter(history);
      let participantsData = { ...participants };

      for (const item of formattedData) {
        if (!item.submitter) continue;

        const filter = filterByIds([item.submitter]);
        const found = get(participants, item.submitter, null);

        if (!found) {
          const userData = await getUsers(agencyId, filter);
          participantsData = {
            ...participantsData,
            [userData[0].id]: userData[0],
          };
        }
      }

      setTimelineData(filterOutShareEntryForCTR(formattedData));
      setParticipantList(participantsData);
    };

    processTimelineData();
  }, [history, participants]);

  //This function is formatting the data that comes from history to use it into timeline component.
  const timeLineDataFormatter = value => {
    const sortedValue = [...value];
    sortedValue.sort(
      //Here the data is been sort by created date.
      (a, b) =>
        new Date(a.created || a.createdAt) - new Date(b.created || b.createdAt)
    );
    return sortedValue;
  };

  const filterOutShareEntryForCTR = formattedData => {
    const toFilterOut = [];
    const ctrActions = ['section-assign', 'section-unassign'];

    formattedData.forEach(item => {
      if (ctrActions.includes(item.action)) {
        toFilterOut.push({
          action: item.action === 'section-assign' ? 'share' : 'unshare',
          userId: item.assignedId,
          fromId: item.assignatId,
          applied: false,
        });
      }
    });

    const filteredData = formattedData.filter(item => {
      let keep = true;
      let itemRedacted = false;

      toFilterOut.forEach((denyItem, index) => {
        if (
          !itemRedacted &&
          !denyItem.applied &&
          denyItem.action === item.action &&
          Number(denyItem.fromId) === item.shareFromId &&
          Array.isArray(item.shareToId) &&
          Number(denyItem.userId) === item.shareToId[0]
        ) {
          toFilterOut[index] = {
            ...toFilterOut[index],
            applied: true,
          };
          itemRedacted = true;
          keep = false;
        }
      });
      return keep;
    });

    return filteredData;
  };

  return (
    <Timeline>
      {timelineData.map((item, index) => {
        const TimelineItem = getTimelineItemByType(item.type);
        return (
          <Timeline.Item key={index}>
            <TimelineItem
              item={item}
              formType={formType}
              history={history}
              notes={notes}
              timezone={timezone}
              participants={participantList}
              participantsIds={participantsIds}
              index={index}
              number={number}
              agencyId={agencyId}
              usersOnBehalf={index === 0 ? usersOnBehalf : []}
            />
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};
