import React from 'react';
import openModal from '../utils/onDeleteButtonClick';
import { ButtonActionsWrapper } from '../CaseFile.styled';

const ShareButton = props => {
  const { isCasefileClosed } = props;
  return (
    <ButtonActionsWrapper
      icon="delete"
      type="icon"
      shape="circle"
      title="Delete"
      tooltipMessage="Delete File"
      onClick={() => openModal(isCasefileClosed)}
    />
  );
};

export default ShareButton;
