export const AUTOCOMPLETE = 'autocomplete';
export const AVATAR = 'avatar';
export const CHECKBOX = 'checkbox';
export const DATE = 'date';
export const DATE_TIME = 'date-time';
export const DYNAMIC_SELECT = 'dynamic-select';
export const FILE = 'file';
export const MASKED = 'MASKED';
export const MULTISELECT = 'multiselect';
export const NUMBER = 'number';
export const SELECT = 'select';
export const SWITCH = 'switch';
export const TEXT = 'text';
export const TEXTAREA = 'textarea';
export const TIME = 'time';
export const VERTICAL_INPUT = 'vertical-input';
export const STRING = 'string';
export const BOOLEAN = 'boolean';
export const SYNC_TO = 'syncTo';
export const SOURCE_FIELD = 'sourceField';
export const HYPERLINK = 'hyperlink';
export const STATIC_CONTENT = 'static-content';
export const DURATION = 'duration';
export const MATH = 'math';
