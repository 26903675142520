import _ from 'lodash';

import { UPDATE_DRAFT_REPORT_REQUEST } from './';
import { updateDraftReport } from '../api/reports';
import updateDraftReportSuccess from './update-draft-report-success';
import updateDraftReportError from './update-draft-report-error';

export default ({ id, data }, next = () => {}) => async (
  dispatch,
  getState
) => {
  const {
    session: {
      currentUser: { agencyId, organizationalUnitId, hierarchyKey },
    },
  } = getState();
  dispatch({ type: UPDATE_DRAFT_REPORT_REQUEST });

  if (data.data && !data.data.organizationalUnitId) {
    data.data = {
      ...data.data,
      organizationalUnitId: organizationalUnitId,
      hierarchyKey: hierarchyKey,
    };
  }

  return new Promise(async (resolve, reject) => {
    try {
      const report = await updateDraftReport({
        agencyId,
        id,
        data,
      });
      dispatch(updateDraftReportSuccess(report));
      resolve(report);
    } catch (e) {
      dispatch(updateDraftReportError(_.get(e, ['response', 'status'])));
      next(e);
      reject(e);
    }
  });
};
