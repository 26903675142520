import styled from 'styled-components';

const Button = styled.a`
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    text-decoration: none;
    color: #4e4e4e;

    i {
      color: #4e4e4e;
    }
  }
`;

export default Button;
