import { createReducer } from 'redux-create-reducer';

import {
  FORMS_GET_REPORT_TIMELINE_LOGS_REQUEST,
  FORMS_GET_REPORT_TIMELINE_LOGS_SUCCESS,
  FORMS_GET_REPORT_TIMELINE_LOGS_ERROR,
  FORMS_CLEAN_REPORT_TIMELINE_LOGS,
} from '../../actions/organizations/get-report-timeline-audit-logs';

const initialState = {
  total: 0,
  totalPages: 0,
  pageSize: 50,
  error: false,
  loading: false,
  data: [],
};

const events = {
  [FORMS_GET_REPORT_TIMELINE_LOGS_REQUEST]: (state, { payload }) => ({
    ...state,
    payload,
    loading: true,
  }),
  [FORMS_GET_REPORT_TIMELINE_LOGS_SUCCESS]: (state, { payload }) => {
    const {
      content: {
        page: { noOfRecordsPerPage, totalRecords },
        data,
      },
    } = payload;
    return {
      ...state,
      total: totalRecords,
      pageSize: noOfRecordsPerPage,
      loading: false,
      data,
    };
  },
  [FORMS_GET_REPORT_TIMELINE_LOGS_ERROR]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [FORMS_CLEAN_REPORT_TIMELINE_LOGS]: () => ({ ...initialState }),
};

export default createReducer(initialState, events);
