import React, { Component } from 'react';
import { Spin } from 'antd';
import { omit, get } from 'lodash';

import { getTemplateComponent } from 'APP_ROOT/utils/form';
import ObjectFieldWithConditions from './ObjectFieldWithConditions';
import TopNavigation from './tabs-header';
import BottomNavigation from './tabs-footer';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';
import withObjectFieldChildren from './withObjectFieldChildren';

class ObjectFieldWrapper extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  spinWrapperStyle = {
    textAlign: 'center',
    background: 'rgba(0,0,0,0.05)',
    borderRadius: '4px',
    marginbottom: '20px',
    padding: '10px 50px',
    margin: '20px 0',
  };

  renderComponent = ({
    type,
    isTopNavigation,
    isBottomNavigation,
    TopNavigation,
    BottomNavigation,
    defaultComponent,
  }) => {
    const isTabNavigation = type === 'tabs';

    switch (true) {
      case isTabNavigation && isTopNavigation:
        return TopNavigation;
      case isTabNavigation && isBottomNavigation:
        return BottomNavigation;
      default:
        return defaultComponent;
    }
  };

  childrenObjectField = withObjectFieldChildren(this.props);

  render() {
    const {
      type = '',
      isReviewer = false,
      isViewer = false,
      canSubmit = true,
      isTopNavigation = false,
      isBottomNavigation = false,
      settings = {},
      conditions,
      dataSelector = '',
    } = this.props;
    const { isEditor } = settings;
    const Component = getTemplateComponent(type, isEditor);
    const isObjectField = type === 'object';
    const propsWithReviewer = {
      ...omit(this.props, ['isTopNavigation', 'dispatch', 'data', 'dataEnums']),
      isViewer,
      isReviewer,
      canSubmit,
    };
    const rules = get(conditions, 'rules');
    const hasRules = rules && Array.isArray(rules) && rules.length > 0;
    const ChildrenObjectField = this.childrenObjectField;

    const RenderComponent = this.renderComponent({
      type,
      isTopNavigation,
      isBottomNavigation,
      TopNavigation,
      BottomNavigation,
      defaultComponent: Component,
      dataSelector,
    });

    if (!type) {
      return (
        <div style={this.spinWrapperStyle}>
          <Spin />
        </div>
      );
    } else if (!hasRules && isObjectField) {
      return <ChildrenObjectField />;
    } else if (hasRules && isObjectField) {
      return React.createElement(ObjectFieldWithConditions, this.props);
    }

    return React.createElement(RenderComponent, propsWithReviewer);
  }
}

export default ObjectFieldWrapper;
