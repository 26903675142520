import React from 'react';
import { kebabCase, isPlainObject } from 'lodash';
import { Select } from 'antd';
import { BENCHMARK_DATE_FORMAT } from '../../../../../utils/parse-date';

export const commonFieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
  inputCol: 'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
};
export const noLabelFieldOptions = {
  labelCol: 'ant-col-24',
  inputCol: 'ant-col-24',
};
export const smallFieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
  inputCol: 'ant-col-xs-12 ant-col-sm-7 ant-col-md-8 ant-col-lg-8',
};

const Option = Select.Option;

export const genderOptions = [
  <Option key="female" value="female">
    Female
  </Option>,
  <Option key="male" value="male">
    Male
  </Option>,
  <Option key="Other" value="Other">
    N/A
  </Option>,
];

export const raceOptions = [
  { label: 'American Indian or Alaska Native', value: 'American Indian' },
  'Asian',
  { label: 'Black / African American', value: 'Black' },
  'Hispanic',
  { label: 'Native Hawaiian or Pacific Islander', value: 'Pacific Islander' },
  'Other',
  { label: 'White / European', value: 'White' },
].map(race => {
  const { label, value } = isPlainObject(race)
    ? race
    : { label: race, value: race };

  return (
    <Option key={kebabCase(value)} value={value}>
      {label}
    </Option>
  );
});

export const ethnicityOptions = [
  'Hispanic',
  'Non-Hispanic',
  { label: 'N/A', value: null },
].map(ethnicity => {
  const { label, value } = isPlainObject(ethnicity)
    ? ethnicity
    : { label: ethnicity, value: ethnicity };

  return (
    <Option key={kebabCase(value)} value={value}>
      {label}
    </Option>
  );
});

export const dominantHandOptions = ['Both', 'Left', 'N/A', 'Right'].map(
  race => (
    <Option key={kebabCase(race)} value={race.toLowerCase()}>
      {race}
    </Option>
  )
);

const disabledDate = current => {
  // Can not select future dates
  return current && current.valueOf() >= Date.now();
};

export const dateOptionsBeforeNow = {
  format: BENCHMARK_DATE_FORMAT,
  disabledDate: disabledDate,
  style: { width: '100%' },
};

export const dateOptions = {
  format: BENCHMARK_DATE_FORMAT,
  style: { width: '100%' },
};

export const coverToNumber = value => (!isNaN(value) ? +value : value);
