import updateFieldValidationRule from './updateFieldValidationRule';

import {
  UPLOAD,
  SINGLE_UPLOAD,
  FIELD,
  REPEATER,
} from 'APP_ROOT/constants/layoutComponentTypes';

const updateFieldValidation = (field, rawValidations, validations, isNote) => {
  const { validations: fieldValidations } = rawValidations;
  const { rules = {}, ...untouchedValidations } = validations;
  const { key, type } = field;
  let newValidations;

  switch (type) {
    case FIELD:
      newValidations = {
        ...untouchedValidations,
        rules: updateFieldValidationRule(rawValidations, rules, field, isNote),
      };
      break;
    case REPEATER:
    case UPLOAD:
      newValidations = {
        ...untouchedValidations,
        rules: {
          ...rules,
          [key]: [
            {
              ...fieldValidations,
            },
          ],
        },
      };
      break;
    case SINGLE_UPLOAD:
      newValidations = {
        ...untouchedValidations,
        rules: {
          ...rules,
          ...fieldValidations,
        },
      };
      break;
    default:
      break;
  }
  return newValidations;
};

export default updateFieldValidation;
