import { camelCase } from 'lodash';

import ConfigureWorkflows from './ConfigureWorkflows';
import { routes as templateWorkflows } from './template-workflows';
import { PERMISSIONS } from '../../../utils/admin';

export default ({ path = '', name = '' }) => {
  const routeConfig = {
    path: `${path}/configure-workflows`,
    name: camelCase(`${name}-workflows`),
  };

  return [
    {
      ...routeConfig,
      component: ConfigureWorkflows,
      protectedWith: [PERMISSIONS.manageWorkflows],
      childRoutes: templateWorkflows(routeConfig),
    },
  ];
};
