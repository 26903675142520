import React, { Component } from 'react';

import { get } from 'lodash';

import getInitials from 'APP_ROOT/utils/get-initials';

class Avatar extends Component {
  render() {
    const { className, data, dataKey } = this.props;
    const value = getInitials(get(data, dataKey));

    return (
      <div className={className}>
        <span>
          <span>{value}</span>
        </span>
      </div>
    );
  }
}

export default Avatar;
