import { get, uniq } from 'lodash';
import { SubmissionError } from 'redux-form';
import getFieldErrorMessage from '../errors/UserFieldsErrorMessages';

const mapFieldName = fieldName => {
  switch (fieldName) {
    case 'rankId':
      return 'rank';
    default:
      return fieldName;
  }
};

const generatePayload = (fieldMessages, status) => {
  return {
    status: status,
    formErrors: {
      ...fieldMessages,
      _error: 'Submission failed due to errors!',
    },
    message: Object.keys(fieldMessages)
      .map(field => {
        return fieldMessages[field];
      })
      .join(' • '),
  };
};

const formFieldsErrorMessages = fields => {
  return {
    ...Object.keys(fields).reduce(
      (output, fieldName) => ({
        ...output,
        [fieldName]: get(fields, fieldName, [])
          .map(error => error.message)
          .join(', '),
      }),
      {}
    ),
  };
};

export const formErrorPayload = (fields = []) => {
  const fieldErrorsMessagesStrings = formFieldsErrorMessages(fields);
  return generatePayload(fieldErrorsMessagesStrings, 'form-error');
};

export const apiFieldsErrorMessagesToString = fields => {
  return {
    ...Object.keys(fields).reduce(
      (output, fieldName) => ({
        ...output,
        [mapFieldName(fieldName)]: uniq(get(fields, fieldName, []))
          .map(code => getFieldErrorMessage(fieldName, code))
          .join(', '),
      }),
      {}
    ),
  };
};

export const apiErrorPayload = (fields = [], status = 412) => {
  const fieldErrorsMessagesStrings = apiFieldsErrorMessagesToString(fields);
  return generatePayload(fieldErrorsMessagesStrings, status);
};

function showErrorSubmission(fields = [], showMessageCb = () => {}) {
  const payload = formErrorPayload(fields);
  showMessageCb(payload);
  throw new SubmissionError(payload.formErrors);
}

export default showErrorSubmission;
