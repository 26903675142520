import _ from 'lodash';
import organizationEndpoints from '../../api/organization/organizationEndpoints';

export const ORGANIZATION_GET_SSN_LOGS_SUCCESS =
  'ORGANIZATION_GET_SSN_LOGS_SUCCESS';

export function organizationGetSSNLogsSuccess(response) {
  return { type: ORGANIZATION_GET_SSN_LOGS_SUCCESS, payload: response };
}

export const ORGANIZATION_GET_SSN_LOGS_ERROR =
  'ORGANIZATION_GET_SSN_LOGS_ERROR';

export function organizationGetSSNLogsError(error) {
  return { type: ORGANIZATION_GET_SSN_LOGS_ERROR, error };
}

export const ORGANIZATION_GET_SSN_LOGS_REQUEST =
  'ORGANIZATION_GET_SSN_LOGS_REQUEST';

export function organizationGetSSNLogsRequest(
  auditPageNumber,
  auditPageSize,
  tenantId,
  agencyId,
  eventType,
  initialDate,
  endDate
) {
  return {
    type: ORGANIZATION_GET_SSN_LOGS_REQUEST,
    payload: {
      auditPageNumber,
      auditPageSize,
      tenantId,
      agencyId,
      eventType,
      initialDate,
      endDate,
    },
  };
}

export default (
  auditPageNumber,
  auditPageSize,
  tenantId,
  agencyId,
  eventType,
  initialDate,
  endDate,
  next = () => {}
) => {
  return dispatch => {
    dispatch({
      type: ORGANIZATION_GET_SSN_LOGS_REQUEST,
      payload: {
        auditPageNumber,
        auditPageSize,
        tenantId,
        agencyId,
        eventType,
        initialDate,
        endDate,
      },
    });

    return organizationEndpoints
      .getSSNLogs(
        auditPageNumber - 1,
        auditPageSize,
        tenantId,
        agencyId,
        eventType,
        initialDate,
        endDate
      )
      .then(response => {
        dispatch(organizationGetSSNLogsSuccess(response));
        next(null, response);
      })
      .catch(error => {
        dispatch(
          organizationGetSSNLogsError(_.get(error, ['response', 'status']))
        );
        next(error);
      });
  };
};
