import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, Input as InputField } from 'antd';
import { withRouter } from 'react-router-dom';
import { Field } from 'redux-form';

import getLmsUrl from '../../../utils/getLmsUrl';

import DashboardPage from 'APP_COMPONENTS/dashboard';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import IconButton from 'APP_COMPONENTS/common/buttons/icon-button';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import connectForm from '../../administrator/components/connected-forms';

import { getUserProfile } from 'APP_ROOT/actions/get-user';
import { getAgencyTZ } from 'APP_ROOT/selectors/session';
import parseDate, { BENCHMARK_DATE_FORMAT } from 'APP_ROOT/utils/parse-date';
import { GRAY_2 } from 'APP_ROOT/config/theme';

import Section from 'APP_COMPONENTS/form-viewer/styled/section';
import Title from 'APP_COMPONENTS/form-viewer/styled/section-title';
import Input from '../../administrator/components/input';

import Firearm from './sections/firearm';
import NonFirearm from './sections/non-firearm';
import Training from './sections/training';
import FieldTraining from './sections/field-training';
import ProfileCardWrapper from './styled/ProfileCardWrapper';

const TextField = Input(InputField);

const formName = 'manageUserTrainingTranscription';

class TrainingTranscript extends Component {
  formName = formName;

  UNSAFE_componentWillMount() {
    const { match: { params: { id } = {} } = {}, dispatch } = this.props;
    dispatch(getUserProfile(id));
  }

  pageButtons = () => {
    return [
      <IconButton
        key="icon-print"
        shape="circle"
        size="default"
        onClick={global.print}
      >
        <img src={require('../../../icons/bt_print.svg')} alt="Button" />
      </IconButton>,
    ];
  };

  getConnectedForm = () => {
    if (!this.connectedForm) {
      this.connectedForm = connectForm(this.formName, () => {});
    }

    return this.connectedForm;
  };

  render() {
    const {
      user: {
        data: {
          id = '',
          fullName: officerName = '',
          employeeId = '',
          appointmentDate = '',
          equipment = [],
          agencyId = '',
        },
        firearmCertifications,
        classes = [],
        nonFirearmCertifications = [],
      },
      agency: { name: agencyName },
      timezone,
    } = this.props;
    const PageForm = this.getConnectedForm();

    return (
      <WrapperPage>
        <PageHeader
          title={`${officerName}'s Training Transcript`}
          showBackButton={false}
          showBackToKmiButton={true}
          goBackToKmi={getLmsUrl('/lms/admin/admin/transcripts')}
          actions={this.pageButtons()}
        />
        <div className="page-content">
          <PageForm
            render={() => (
              <div>
                <ProfileCardWrapper>
                  <Section className="bdm-form-section--no-padding-bottom bdm-form-section--no-margin-bottom">
                    <Row>
                      <Col className="gutter-row" span={24}>
                        <Title size="default">{agencyName}</Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={8}>
                        <Field
                          type="text"
                          name="officerName"
                          label="Officer Name"
                          component={TextField}
                          defaultValue={officerName}
                          disabled={true}
                          plainText={true}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8}>
                        <Field
                          type="text"
                          name="officerEmployeeId"
                          label="Employee ID"
                          component={TextField}
                          defaultValue={employeeId}
                          disabled={true}
                          plainText={true}
                        />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8}>
                        <Field
                          type="text"
                          name="officerDateOfAppointment"
                          label="Date of Appointment"
                          component={TextField}
                          defaultValue={parseDate(
                            appointmentDate,
                            timezone,
                            BENCHMARK_DATE_FORMAT
                          )}
                          disabled={true}
                          plainText={true}
                        />
                      </Col>
                    </Row>
                  </Section>
                </ProfileCardWrapper>
                {id && (
                  <FieldTraining
                    userId={id}
                    timezone={timezone}
                    agencyId={agencyId}
                  />
                )}
                <Firearm
                  equipment={equipment}
                  firearmCertifications={firearmCertifications}
                  timezone={timezone}
                />
                {nonFirearmCertifications &&
                  nonFirearmCertifications.length > 0 && (
                    <NonFirearm
                      nonFirearmCertifications={nonFirearmCertifications}
                      timezone={timezone}
                    />
                  )}
                {classes && classes.length > 0 && (
                  <Training classes={classes} timezone={timezone} />
                )}
              </div>
            )}
          />
          <PageFooter>
            <p>
              Powered by{' '}
              <img
                className="footer-logo"
                src={require('../../../images/benchmark-logo.svg')}
                alt="Benchmark Analytics"
              />
            </p>
          </PageFooter>
        </div>
      </WrapperPage>
    );
  }
}

const WrapperPage = styled.div`
  .page-content {
    padding: 6px 16px;
  }
`;

const PageFooter = styled.div`
  text-align: center;
  color: ${GRAY_2};

  .footer-logo {
    display: inline-block;
    width: 150px;
    margin: 0 5px;
    vertical-align: middle;
  }
`;

const mapState = state => {
  const { selectedUser: user } = state;
  return {
    user,
    timezone: getAgencyTZ(state),
  };
};

const OfficerTrainingTranscript = withRouter(
  withoutClutter(DashboardPage(mapState, null)(TrainingTranscript))
);
export default OfficerTrainingTranscript;
