import React from 'react';

import {
  Column,
  Field,
  FieldEditor,
  FieldGroup,
  FieldGroupEditor,
  Repeater,
  RepeaterAddItem,
  Row,
  Section,
  SectionWrapper,
  SectionCollapse,
  Tab,
  TabContent,
  ObjectField,
  Alert,
  RequirentCompliant,
  Divider,
  TrainingTaskDocument,
  TrainingTaskTimeline,
  VerticalInput,
  Avatar,
  GotoBlock,
  HistoricalUnitAssignment,
} from '../components/form-viewer/object-types';

import {
  Date as DatePicker,
  DateTime,
  Time,
  MultiSelect,
  Number as NumberPicker,
  Select,
  Switch,
  Text,
  Textarea,
  File,
  Checkbox,
  AutoComplete,
  InputMasked,
  Upload,
  Hyperlink,
  StaticContent,
  Duration,
  MathField,
  SingleUpload,
} from '../components/form-viewer/field-types';

import BodyDiagram from '../components/body-diagram';

import * as FORM_COMPONENT_TYPES from './layoutComponentTypes';
import * as FORM_FIELD_TYPES from './fieldTypes';

const PlainText = props => {
  const { options: { className = '' } = {} } = props;
  return <div className={className}>{props.text || 'component'}</div>;
};

export const LAYOUT_TYPES = {
  [FORM_COMPONENT_TYPES.FIELD_GROUP]: FieldGroup,
  [FORM_COMPONENT_TYPES.INJURIES_BODY]: BodyDiagram,
  [FORM_COMPONENT_TYPES.PLAN_TEXT]: PlainText,
  [FORM_COMPONENT_TYPES.REPEATER_ACTION]: RepeaterAddItem,
  [FORM_COMPONENT_TYPES.REQUIREMENT_COMPLIANT]: RequirentCompliant,
  [FORM_COMPONENT_TYPES.SECTION_COLLAPSE]: SectionCollapse,
  [FORM_COMPONENT_TYPES.SECTION_WRAPPER]: SectionWrapper,
  [FORM_COMPONENT_TYPES.TRAINING_TASK_DOCUMENT]: TrainingTaskDocument,
  [FORM_COMPONENT_TYPES.TRAINING_TASK_TIMELIME]: TrainingTaskTimeline,
  [FORM_COMPONENT_TYPES.ALERT_MESSAGE]: Alert,
  [FORM_COMPONENT_TYPES.COLUMN]: Column,
  [FORM_COMPONENT_TYPES.DIVIDER]: Divider,
  [FORM_COMPONENT_TYPES.FIELD]: Field,
  [FORM_COMPONENT_TYPES.GROUP]: ObjectField,
  [FORM_COMPONENT_TYPES.REPEATER]: Repeater,
  [FORM_COMPONENT_TYPES.ROW]: Row,
  [FORM_COMPONENT_TYPES.SECTION]: Section,
  [FORM_COMPONENT_TYPES.TAB]: Tab,
  [FORM_COMPONENT_TYPES.TABS]: TabContent,
  [FORM_COMPONENT_TYPES.UPLOAD]: Upload,
  [FORM_COMPONENT_TYPES.SINGLE_UPLOAD]: SingleUpload,
  [FORM_COMPONENT_TYPES.GOTO_BLOCK]: GotoBlock,
  [FORM_COMPONENT_TYPES.HISTORICAL_UNIT_ASSIGNMENT]: HistoricalUnitAssignment,
};

export const SELF_SERVICE_LAYOUT_TYPES = {
  ...LAYOUT_TYPES,
  [FORM_COMPONENT_TYPES.FIELD]: FieldEditor,
  [FORM_COMPONENT_TYPES.FIELD_GROUP]: FieldGroupEditor,
};

export const FIELD_TYPES = {
  [FORM_FIELD_TYPES.DATE_TIME]: DateTime,
  [FORM_FIELD_TYPES.DYNAMIC_SELECT]: Select,
  [FORM_FIELD_TYPES.AUTOCOMPLETE]: AutoComplete,
  [FORM_FIELD_TYPES.CHECKBOX]: Checkbox,
  [FORM_FIELD_TYPES.DATE]: DatePicker,
  [FORM_FIELD_TYPES.FILE]: File,
  [FORM_FIELD_TYPES.MASKED.toLowerCase()]: InputMasked,
  [FORM_FIELD_TYPES.MULTISELECT]: MultiSelect,
  [FORM_FIELD_TYPES.NUMBER]: NumberPicker,
  [FORM_FIELD_TYPES.SELECT]: Select,
  [FORM_FIELD_TYPES.SWITCH]: Switch,
  [FORM_FIELD_TYPES.TEXT]: Text,
  [FORM_FIELD_TYPES.TEXTAREA]: Textarea,
  [FORM_FIELD_TYPES.TIME]: Time,
  [FORM_FIELD_TYPES.VERTICAL_INPUT]: VerticalInput,
  [FORM_FIELD_TYPES.AVATAR]: Avatar,
  [FORM_FIELD_TYPES.HYPERLINK]: Hyperlink,
  [FORM_FIELD_TYPES.STATIC_CONTENT]: StaticContent,
  [FORM_FIELD_TYPES.DURATION]: Duration,
  [FORM_FIELD_TYPES.MATH]: MathField,
};

export const emptyObject = {};
export const emptyArray = [];
