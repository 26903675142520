import store from '../../store';
import { getCurrentUserPermissions } from '../../selectors/session';
import CabalitiesManager from '../../services/capabilitiesManager';
import { isAgencyOwner } from './capabilitiesValidators';

const capabilities = (capability, options) => {
  const { validator = isAgencyOwner, params, stateManager = store } = options;
  const state = stateManager.getState();
  const isValid = validator({ state, ...params });
  const permissions = getCurrentUserPermissions(state);
  const cabalitiesManager = new CabalitiesManager(permissions);
  return cabalitiesManager.can(capability, isValid);
};

export default capabilities;
