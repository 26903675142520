import React from 'react';
import ModalBody from 'APP_ROOT/components/common/modal/body';
import { isEmpty } from 'lodash';
export default props => {
  const { namesToShare, namesToUnshare, title } = props;

  const shares = [];
  const hasNamesToShare = !isEmpty(namesToShare);
  const hasNamesToUnshare = !isEmpty(namesToUnshare);
  if (hasNamesToShare) {
    shares.push(<strong key="shared">shared</strong>, ` with ${namesToShare}`);
    if (hasNamesToUnshare) {
      shares.push(' and ');
    }
  }

  if (hasNamesToUnshare) {
    shares.push(
      <strong key="unshared">unshared</strong>,
      ` with ${namesToUnshare}`
    );
  }

  return (
    <ModalBody>
      <p>
        Case File {title} has been&nbsp; {shares.map(s => s)}
      </p>
    </ModalBody>
  );
};
