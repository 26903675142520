import React from 'react';
import ContentLoader from 'react-content-loader';

import LoaderWrapper from './LoaderWrapper.styled';

const ReviewPanelSkeleton = () => (
  <LoaderWrapper>
    <ContentLoader
      height={280}
      width={500}
      speed={2}
      primaryColor={'#f3f3f3'}
      uniquekey="review-panel-skeleton"
      secondaryColor={'#ecebeb'}
    >
      <rect x="15" y="25" rx="4" ry="4" width="110" height="20" />
      <rect x="170" y="25" rx="4" ry="4" width="225" height="25" />
      <rect x="15" y="65" rx="4" ry="4" width="110" height="20" />
      <rect x="170" y="65" rx="4" ry="4" width="340" height="90" />
      <rect x="15" y="205" rx="4" ry="4" width="120" height="30" />
      <rect x="145" y="205" rx="4" ry="4" width="130" height="30" />
    </ContentLoader>
  </LoaderWrapper>
);

export default ReviewPanelSkeleton;
