import { isEmpty, last } from 'lodash';
import {
  OPERATOR_EQUAL,
  OPERATOR_NOT_EQUAL,
  OPERATOR_IN,
  OPERATOR_NOT_IN,
  OPERATOR_OR,
} from '../../constants/conditionalOperators';

import {
  ACTION,
  SUBMITTER_ID,
  IS_REVIEWER,
  IS_HIDDEN,
  EMPTY,
  INDEX,
} from '../../constants/conditions';

import { itemKeyword, dataKeyword } from '../../../../utils/constants';

const transformRule = (newRules, { key, toBe, expect }) => {
  if (isEmpty(key) || [IS_REVIEWER, IS_HIDDEN, EMPTY, INDEX].includes(key)) {
    // checking key has valid values if not
    // just return
    return newRules;
  }
  let splittedKey = key.split('.');
  splittedKey[0] === dataKeyword && splittedKey.shift();
  if (
    splittedKey.length > 1 &&
    splittedKey[splittedKey.length - 2] !== itemKeyword &&
    last(splittedKey) !== 'id'
  ) {
    // something like 'repeater.#.field' is not valid in
    // validation rules. The only exception is 'repeater.#.id'
    splittedKey[splittedKey.length - 2] = itemKeyword;
  }
  const rule = { key: splittedKey.join('.') };
  switch (toBe) {
    case OPERATOR_EQUAL:
      rule.expected = expect;
      break;
    case OPERATOR_NOT_EQUAL:
      rule.notExpected = expect;
      break;
    case OPERATOR_IN:
      rule.includes = [].concat(expect);
      break;
    case OPERATOR_NOT_IN:
      rule.notExpected = expect;
      break;
    default:
      rule.expected = expect;
  }
  newRules.push(rule);

  return newRules;
};

// Transform from:
// conditions {
//   every: true,
//   behavior: "hide",
//   rules: [
//     {
//       key: "key456",
//       toBe": "eq",
//       expect: 'value'
//     }
//   ]
// },
// to:
// conditions: [{ key: otherKey, expected: array | string | num }],
const transformConditions = (
  conditions = {},
  { fieldKey, required, encrypt },
  isNote
) => {
  const { rules = [], every } = conditions;
  const newRules = (required && rules.reduce(transformRule, [])) || [];

  // This enables the form submission to send the values
  const complaintFieldsKey = isNote ? ACTION : SUBMITTER_ID;

  let newConditions = {};

  if (newRules.length) {
    // if there are conditions we need to set 'mustExist' instead of
    // 'required' because 'required' makes the field required all the
    // time not only when it hits the contidion
    newConditions = {
      conditionType: every ? undefined : OPERATOR_OR,
      mustExist: fieldKey,
      conditions: newRules,
    };
  } else if (required) {
    newConditions = rules.length
      ? { mustExist: fieldKey }
      : {
          required: true,
          optional: false,
        };
  } else {
    // when optional is true, we need to set 'keepInData'
    newConditions = {
      required: false,
      optional: true,
      keepInData: {
        key: complaintFieldsKey,
        expect: '',
        toBe: OPERATOR_NOT_EQUAL,
      },
    };
  }
  newConditions.encrypt = encrypt ? true : undefined;
  return newConditions;
};

export default transformConditions;
