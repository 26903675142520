import React from 'react';
import { Route } from 'react-router';

import Login from '../../containers/login';
import ForgotPassword from '../../containers/password/forgot';
import ForgotPasswordConfirmation from '../../containers/password/forgot-confirmation';
import ResetPassword from '../../containers/password/reset';

const guestRoutes = [
  <Route path="/login" name="login" key="login" exact component={Login} />,
  <Route
    path="/forgot-password"
    name="forgotPassword"
    key="forgotPassword"
    exact
    component={ForgotPassword}
  />,
  <Route
    path="/forgot-password-confirmation"
    name="forgotPasswordConfirmation"
    key="forgotPasswordConfirmation"
    exact
    component={ForgotPasswordConfirmation}
  />,
  <Route
    path="/reset-password"
    name="resetPassword"
    key="resetPassword"
    exact
    component={ResetPassword}
  />,
];

export default guestRoutes;
