import _ from 'lodash';

import { REFRESH_ACTIONS_REQUEST } from './';

import { fetchNotifications } from '../api/activity';
import refreshActionsSuccess from './refresh-action-items-success';
import refreshActionsError from './refresh-action-items-error';

export default (next = () => {}) => {
  return (dispatch, getState) => {
    const {
      session: { currentUser: { userId } = {} } = {},
      activity: { actions: { filter = {} } = {} } = {},
    } = getState();
    dispatch({ type: REFRESH_ACTIONS_REQUEST });

    return fetchNotifications(userId, filter)
      .then(response => {
        dispatch(refreshActionsSuccess(response));
        next(null, response);
      })
      .catch(error => {
        dispatch(refreshActionsError(_.get(error, ['response', 'status'])));
        next(error);
      });
  };
};
