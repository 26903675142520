import { last } from 'lodash';

import { DURATION } from '../../../constants/fieldTypes';

/**
 * To evaluate the field relation
 * @param {Array} fieldKeys
 * @param {Object} field: duration field to evaluate relation with fieldKeys
 * @param {Array} related: current list of related fields
 * @returns
 */
const getDurationRelated = (fieldKeys, field, related = []) => {
  const { field_type, options } = field;
  if (field_type === DURATION) {
    const { initial = '', final = '' } = options;
    if (
      fieldKeys.includes(last(initial.split('.'))) ||
      fieldKeys.includes(last(final.split('.')))
    ) {
      related.push(field);
    }
  }
  return related;
};

export default getDurationRelated;
