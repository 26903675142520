import React from 'react';
import { Icon } from 'antd';
import { CLOSED_STATUS, renderDate } from '../../constants';

export default (timezone, handleClick, statusesCount = {}) => [
  {
    title: 'Status Name',
    dataIndex: 'label',
    key: 'label',
  },
  {
    title: 'Status Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 150,
    render: data => renderDate(data, timezone),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: 90,
    render: (_, data) => {
      const { type } = data;
      const numberOfType = statusesCount[type.toLowerCase()];

      return type === CLOSED_STATUS || numberOfType === 1 ? (
        <></>
      ) : (
        <Icon
          type="delete"
          style={{ color: 'red' }}
          onClick={() => handleClick(data)}
        />
      );
    },
  },
];
