import React from 'react';

import ModalBody from 'APP_COMPONENTS/common/modal/body';

const setApiResponse = message => {
  return (
    <ModalBody>
      <div>
        <p>{message}</p>
      </div>
    </ModalBody>
  );
};

export default setApiResponse;
