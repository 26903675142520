/* eslint-disable no-useless-escape */
import React, { Fragment, useState, useEffect } from 'react';
import { get, uniq, concat, upperFirst } from 'lodash';

import withoutClutter from 'APP_COMPONENTS/without-clutter';
import DashboardPage from 'APP_COMPONENTS/dashboard';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import getRoute from 'APP_ROOT/utils/get-route';
import withOwnershipGuard from '../../components/agency-ownership-guard';
import CustomDrawer from 'APP_COMPONENTS/common/custom-drawer';
import getBadgeStatus from 'APP_COMPONENTS/common/custom-drawer/changelog-drawer-helpers/badgeStatusses';
import apiEndpoints from '../../../../modules/FormBuilder/services/apiEndpoints';
import { Tabs, Badge, message } from 'antd';

import Table from './ChangelogTable';

const { TabPane } = Tabs;

const TemplateChangelog = props => {
  const { agencyId, templateId, templateName } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [changelogs, setChangelogs] = useState([]);
  const [selectedChangelog, setSelectedChangelog] = useState([]);
  const [changesList, setChangesList] = useState([]);

  const getChangelogs = () => {
    const changelog = apiEndpoints
      .getChangelogList(agencyId, templateId)
      .then(response => response)
      .catch(e => {
        message.error('Something went wrong', 10);
      })
      .finally(() => setLoading(false));
    return changelog;
  };

  useEffect(() => {
    (async function() {
      const data = await getChangelogs();
      setChangelogs(data);
    })();
  }, []);

  const showChangelogData = changelog => {
    toggleDrawer();
    const parsedChangelog = JSON.parse(changelog);
    setSelectedChangelog(parsedChangelog);
    setChangesList(parsedChangelog);
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const filterChangelog = tabKey => {
    const initialTab = 'all';
    if (tabKey === initialTab) {
      return setSelectedChangelog(changesList);
    }
    const filteredChangelog = changesList.filter(
      field => field.changeType === tabKey
    );
    setSelectedChangelog(filteredChangelog);
  };

  const renderChangelog = () => {
    return selectedChangelog.map((field, index) => {
      const { componentId, changeType, fieldType, title, reportingKey } = field;
      const fieldName = <b> {` (${reportingKey})`}</b>;
      return (
        <div key={`${componentId}${index}`}>
          <Badge
            status={getBadgeStatus(changeType)}
            text={
              <Fragment>
                {changeType} {fieldType} {title} {fieldName}
              </Fragment>
            }
          />
          <br />
        </div>
      );
    });
  };

  const renderTabPane = () => {
    const initialTab = 'all';
    const changelogTabs = changesList.map(field => field.changeType);
    const tabs = concat(initialTab, uniq(changelogTabs));
    return tabs.map(change => {
      return (
        <TabPane tab={upperFirst(change)} key={change}>
          {renderChangelog()}
        </TabPane>
      );
    });
  };

  return (
    <div>
      {openDrawer && (
        <CustomDrawer
          title="Changelog"
          placement="right"
          width={500}
          closable={false}
          onClose={toggleDrawer}
          visible={openDrawer}
        >
          <Tabs onChange={tabKey => filterChangelog(tabKey)}>
            {renderTabPane()}
          </Tabs>
        </CustomDrawer>
      )}

      <PageHeader
        title={`Changelogs - ${templateName}`}
        goBackTo={getRoute('administratorAgencyFormBuilderList', { agencyId })}
        actions={null}
      />
      <div className="changelog-table-wrapper">
        <Table
          dataSource={changelogs}
          loading={loading}
          onClickRow={showChangelogData}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (_state, props) => ({
  agencyId: get(props, 'match.params.agencyId'),
  templateId: get(props, 'match.params.templateId'),
  templateName: get(props, 'match.params.templateName'),
});

export default withoutClutter(
  DashboardPage(mapStateToProps, null, { sidebarCollapsed: true })(
    withOwnershipGuard(TemplateChangelog, 'administratorAgencyFormBuilderList')
  )
);
