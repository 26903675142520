import organizationEndpoints from '../../../../api/organization/organizationEndpoints';
import {
  GET_USER_SECURITY_ROLES_FULFILLED,
  GET_USER_SECURITY_ROLES_REJECTED,
  GET_USER_SECURITY_ROLES_REQUEST,
} from '../../agency-users/AgencyUsers.actionTypes';

export default (userId, agencyId, next) => dispatch => {
  dispatch({ type: GET_USER_SECURITY_ROLES_REQUEST });

  return organizationEndpoints
    .getUserOURoles(userId)
    .then(response => {
      const roles = response.content;
      dispatch({
        type: GET_USER_SECURITY_ROLES_FULFILLED,
        payload: { userId, agencyId, roles },
      });
      next && next(null, roles);
    })
    .catch(error => {
      dispatch({
        type: GET_USER_SECURITY_ROLES_REJECTED,
        payload: { userId, agencyId, error },
      });
      next && next(error);
    });
};
