import styled from 'styled-components';

import { ERROR } from '../../../config/theme';

const SectionWrapper = styled.div.attrs({
  className: 'bdm-requirement-compliant',
})`
  position: relative;

  .bdm-requirement-compliant-section-wrapper {
    border: 1px solid #fff;
    transition: border-color 0.08s ease;
  }

  .bdm-requirement-compliant-alert {
    margin-bottom: 0;
    display: none;

    .ant-alert-message {
      color: ${ERROR};
    }
  }

  &.bdm-requirement-compliant-has-errors {
    .bdm-form-section-title {
      margin-bottom: 0;
    }

    .bdm-requirement-compliant-alert {
      display: block;
    }

    .bdm-requirement-compliant-section-wrapper {
      border-color: ${ERROR};
    }
  }
`;

export default SectionWrapper;
