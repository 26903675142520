export default {
  title: 'Status',
  dataIndex: 'status',
  filterMultiple: false,
  filters: [
    {
      text: 'Approved',
      value: 'Approved',
    },
    {
      text: 'Assessment Pending',
      value: 'Assessment Pending',
    },
    {
      text: 'Completed',
      value: 'Completed',
    },
    {
      text: 'Declined',
      value: 'Declined',
    },
    {
      text: 'Evaluation Pending',
      value: 'Evaluation Pending',
    },
    {
      text: 'Expired',
      value: 'Expired',
    },
    {
      text: 'In Progress',
      value: 'In Progress',
    },
    {
      text: 'Not Passed',
      value: 'Not Passed',
    },
    {
      text: 'Not Started',
      value: 'Not Started',
    },
    {
      text: 'Passed',
      value: 'Passed',
    },
    {
      text: 'Pending',
      value: 'Pending',
    },
    {
      text: 'Post Assessment Submitted',
      value: 'Post Assessment Submitted',
    },
    {
      text: 'Pre-Assessment Pending',
      value: 'Pre-Assessment Pending',
    },
    {
      text: 'Wait List',
      value: 'Wait List',
    },
    {
      text: 'Withdrawn',
      value: 'Withdrawn',
    },
  ],
};
