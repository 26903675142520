import React, { Fragment } from 'react';
import EllipsisInput from './EllipsisInput';
import {
  DatePicker,
  Icon,
  Input as InputField,
  InputNumber,
  Select,
  TimePicker,
  TreeSelect,
} from 'antd';
import { Field } from 'redux-form';
import {
  get,
  isEmpty,
  isPlainObject,
  isString,
  kebabCase,
  union,
} from 'lodash';
import moment from 'moment-timezone';

import Input from '../../components/input';
import Switch from '../../../../components/form-viewer/styled/input-switch';
import MultipleSelect from '../../components/input/MultipleSelect';
//import { PhoneNumberField as PhoneNumberInput } from '../../../../components/form-viewer/field-types/input-masked';
import {
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_DATE_TIME_FORMAT,
  BENCHMARK_TIME_FORMAT,
  momentWithTZ,
  formatDate,
} from '../../../../utils/parse-date';

import {
  ATTRIBUTE_TYPE_DATE,
  ATTRIBUTE_TYPE_DATETIME,
  ATTRIBUTE_TYPE_NUMBER,
  ATTRIBUTE_TYPE_STRING,
  ATTRIBUTE_TYPE_SWITCH,
  ATTRIBUTE_TYPE_TEXTAREA,
  ATTRIBUTE_TYPE_TIME,
  ATTRIBUTE_TYPE_TREE,
} from './UserProfile.constants';

const TextField = Input(EllipsisInput);
const NumberField = Input(InputNumber);
const DateField = Input(DatePicker);
const TimeField = Input(TimePicker);
const SwitchField = Input(Switch);
const SelectField = Input(Select);
const TextAreaField = Input(InputField.TextArea);
const TreeSelectField = Input(TreeSelect);
// const PhoneNumberField = Input(PhoneNumberInput);

const Option = Select.Option;

const commonFieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-5 ant-col-lg-5',
  inputCol: 'ant-col-xs-24 ant-col-sm-16 ant-col-md-19 ant-col-lg-19',
};
const MAX_LENGTH = 40;
const TEXT_MAX_LENGTH = 1000;

const noLabelFieldOptions = {
  labelCol: 'ant-col-24',
  inputCol: 'ant-col-24',
};

const dateProps = {
  format: BENCHMARK_DATE_FORMAT,
  style: { width: '100%' },
};
const timeProps = {
  format: BENCHMARK_TIME_FORMAT,
  style: { width: '100%' },
};
const datetimeProps = {
  ...dateProps,
  ...timeProps,
  format: BENCHMARK_DATE_TIME_FORMAT,
  showTime: {
    format: BENCHMARK_TIME_FORMAT,
    defaultValue: moment('00:00', BENCHMARK_TIME_FORMAT),
  },
};
const selectProps = {
  style: { width: '100%' },
};

const coverToNumber = value => (!isNaN(value) ? +value : value);
const numberProps = {
  min: 0,
};

const convertToBoolean = value => (isString(value) ? value === 'true' : value);
const textareaProps = {
  rows: 4,
  style: { resize: 'none' },
};

const SectionAttribute = props => {
  const {
    profileForm,
    timezone = 'UTC',
    name,
    title,
    type,
    maxLength = TEXT_MAX_LENGTH,
    ellipsisLength = MAX_LENGTH,
    disabledValues = [],
    validValues = null,
    unique = false,
    isArray = false,
    readOnly = false,
    disabled = false,
    onChange = () => {},
    onFocus = () => {},
    onSearch = () => {},
    showSearch = false,
    plainValidValues = [],
    'data-test': dataTest,
    disabledDate = () => {},
    allowClear = false,
    onDeselect = () => {},
    formatDateWithoutTimezone = null,
    filterOption = false,
    isCaseFileOwnerField = false,
    renderOwnerWarningIcon = false,
  } = props;

  const plainText = readOnly;

  const textProps = {
    readOnly: plainText,
    ellipsislength: ellipsisLength,
    maxLength: maxLength,
  };

  const fieldOptions = isEmpty(title)
    ? noLabelFieldOptions
    : commonFieldOptions;

  let takenValues = [];
  if (unique && validValues) {
    // we need to remove the index reference in case it has it
    // from 'section.attribute[1]' to 'section.attribute' in order
    // to get all the values already used
    let splitedName = name.split('.');
    const [val] = splitedName.splice(-1, 1);
    const plainName = [
      'values',
      ...splitedName,
      val.replace(/\[[^\][]*\]/, ''),
    ];
    takenValues = get(profileForm, plainName.join('.'), []);
  }

  takenValues = union(takenValues, disabledValues);

  const plainSelectOptions =
    validValues &&
    validValues.map(val =>
      isPlainObject(val) ? val : { label: val, value: val, ...val }
    );

  let selectOptions;
  if (type === ATTRIBUTE_TYPE_TREE) {
    selectOptions =
      plainSelectOptions &&
      plainSelectOptions.slice(0, 1).map(ou => {
        function createTreeNode(ou) {
          if (
            ou.children == [] ||
            ou.children === null ||
            ou.children == undefined
          ) {
            return ou;
          } else {
            return {
              label: ou.label,
              value: ou.value,
              disabled: takenValues.includes(ou.value) || false,
              children: ou.children
                .map(childOu => createTreeNode(childOu))
                .sort((ou1, ou2) => {
                  const displayName1 = ou1.label.toLowerCase();
                  const displayName2 = ou2.label.toLowerCase();
                  if (displayName1 < displayName2) {
                    return -1;
                  } else if (displayName1 > displayName2) {
                    return 1;
                  } else {
                    return 0;
                  }
                }),
            };
          }
        }
        return createTreeNode(ou);
      });
  } else {
    selectOptions =
      plainSelectOptions &&
      plainSelectOptions.map(({ label, value, disabled = false }) => (
        <Option
          key={kebabCase(value)}
          value={value}
          disabled={takenValues.includes(value) || disabled}
        >
          {label}
        </Option>
      ));
  }

  const getSelectConfig = () => {
    if (isCaseFileOwnerField && renderOwnerWarningIcon) {
      return {
        showSearch: true,
        filterOption: (input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
      };
    }

    if (!showSearch) {
      return selectProps;
    }

    return {
      ...selectProps,
      showSearch: true,
      filterOption: (input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    };
  };

  const getTreeSelectConfig = () => {
    return {
      style: { width: '100%' },
      showSearch: true,
      treeNodeFilterProp: 'title',
      placeholder: 'Please select',
      treeDefaultExpandAll: true,
      dropdownStyle: { maxHeight: 400, overflow: 'auto' },
      treeData: selectOptions,
    };
  };

  switch (type) {
    case ATTRIBUTE_TYPE_DATE:
      return (
        <Field
          type="date"
          name={name}
          data-test={dataTest || 'section-attribute-date'}
          label={title}
          placeholder="Please Select"
          component={DateField}
          inputProps={{ ...dateProps, timezone, disabledDate }}
          options={fieldOptions}
          format={value => {
            if (formatDateWithoutTimezone) {
              return formatDateWithoutTimezone(value);
            } else {
              return value && formatDate(value);
            }
          }}
          disabled={disabled}
          plainText={plainText}
          onChange={onChange}
        />
      );

    case ATTRIBUTE_TYPE_DATETIME:
      return (
        <Field
          type="datetime"
          mode="date"
          name={name}
          data-test={dataTest || 'section-attribute-datetime'}
          label={title}
          placeholder="Please Select"
          component={DateField}
          inputProps={datetimeProps}
          options={fieldOptions}
          format={value =>
            value &&
            momentWithTZ(value, timezone, BENCHMARK_DATE_TIME_FORMAT, false)
          }
          normalize={value =>
            value &&
            momentWithTZ(value, timezone, BENCHMARK_DATE_TIME_FORMAT, false)
          }
          disabled={disabled}
          plainText={plainText}
        />
      );

    case ATTRIBUTE_TYPE_TIME:
      return (
        <Field
          type="time"
          name={name}
          data-test={dataTest || 'section-attribute-time'}
          label={title}
          placeholder="Please Select"
          component={TimeField}
          inputProps={timeProps}
          options={fieldOptions}
          format={value =>
            value && momentWithTZ(value, timezone, BENCHMARK_TIME_FORMAT, false)
          }
          disabled={disabled}
          plainText={plainText}
        />
      );

    case ATTRIBUTE_TYPE_NUMBER:
      return (
        <Field
          type="number"
          name={name}
          data-test={dataTest || 'section-attribute-number'}
          label={title}
          component={NumberField}
          options={fieldOptions}
          inputProps={numberProps}
          normalize={coverToNumber}
          disabled={disabled}
          plainText={plainText}
        />
      );

    case ATTRIBUTE_TYPE_SWITCH:
      return (
        <Field
          type="boolean"
          name={name}
          data-test={dataTest || 'section-attribute-switch'}
          label={title}
          component={SwitchField}
          inputProps={{
            checked: get(profileForm, `values.${name}`, false),
            size: 'large',
            checkedChildren: 'Yes',
            unCheckedChildren: 'No',
          }}
          normalize={convertToBoolean}
          options={fieldOptions}
          disabled={disabled}
          plainText={plainText}
        />
      );

    case ATTRIBUTE_TYPE_TEXTAREA:
      return (
        <Field
          type="text"
          name={name}
          data-test={dataTest || 'section-attribute-textarea'}
          label={title}
          component={TextAreaField}
          options={fieldOptions}
          inputProps={textareaProps}
          disabled={disabled}
          plainText={plainText}
        />
      );

    case ATTRIBUTE_TYPE_STRING:
      const selectInputConfig = getSelectConfig();
      const icon =
        isCaseFileOwnerField && renderOwnerWarningIcon ? (
          <Icon type="warning" style={{ fontSize: '16px' }} />
        ) : null;
      const tooltipMessage =
        isCaseFileOwnerField && renderOwnerWarningIcon
          ? 'This user no longer has the permissions necessary to be the owner of the case file. Please reassign the owner or contact your implementation specialist.'
          : '';
      if (selectOptions) {
        return isArray ? (
          <Field
            type="select"
            mode="multiple"
            name={name}
            data-test={dataTest || 'section-attribute-multiselect'}
            label={title}
            inputProps={{ ...selectInputConfig, allowClear, onDeselect }}
            component={MultipleSelect}
            options={fieldOptions}
            dropdownOptions={selectOptions}
            plainDropdownOptions={plainSelectOptions}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            disabled={disabled || plainText}
            plainText={plainText}
            icon={icon}
            tooltipMessage={tooltipMessage}
          />
        ) : (
          <Fragment>
            <Field
              type="select"
              name={name}
              data-test={dataTest || 'section-attribute-select'}
              label={title}
              inputProps={{
                ...selectInputConfig,
                allowClear,
                filterOption,
                onSearch,
              }}
              component={SelectField}
              options={fieldOptions}
              dropdownOptions={selectOptions}
              plainDropdownOptions={plainSelectOptions}
              disabled={disabled}
              plainText={plainText}
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              icon={icon}
              tooltipMessage={tooltipMessage}
            />
          </Fragment>
        );
      }
      return (
        <Field
          type="text"
          name={name}
          data-test={dataTest || 'section-attribute-string'}
          label={title}
          component={TextField}
          options={fieldOptions}
          disabled={disabled}
          readOnly={plainText}
          inputProps={textProps}
        />
      );
    case ATTRIBUTE_TYPE_TREE:
      const selectInputConfigTree = getTreeSelectConfig();
      return (
        <Field
          type="tree-select"
          name={name}
          label={title}
          data-test={dataTest || 'section-attribute-select'}
          inputProps={selectInputConfigTree}
          component={TreeSelectField}
          plainDropdownOptions={plainValidValues}
          disabled={disabled}
          plainText={plainText}
          optionFilterProp="children"
        />
      );
    default:
      return (
        <Field
          type="text"
          name={name}
          data-test={dataTest || 'section-attribute-string'}
          label={title}
          component={TextField}
          options={fieldOptions}
          disabled={disabled}
          readOnly={plainText}
          inputProps={textProps}
        />
      );
  }
};

export default SectionAttribute;
