import {
  LOOKUP_SYSTEM_UPDATES_REQUEST,
  LOOKUP_SYSTEM_UPDATES_SUCCESS,
  LOOKUP_SYSTEM_UPDATES_ERROR,
} from './';
import get from 'lodash/get';

import { lookupForUpdates } from '../api/system-updates';

export default (next = () => {}) => (dispatch, getState) => {
  const { systemUpdates: { lookingUp } = {} } = getState();

  const callback = (...args) => {
    next && typeof next === 'function' && next(...args);
  };

  return new Promise(async (resolve, reject) => {
    if (!lookingUp) {
      dispatch({ type: LOOKUP_SYSTEM_UPDATES_REQUEST });

      try {
        const versions = await lookupForUpdates();

        dispatch({ type: LOOKUP_SYSTEM_UPDATES_SUCCESS, payload: versions });
        callback();
        resolve();
      } catch (err) {
        dispatch({
          type: LOOKUP_SYSTEM_UPDATES_ERROR,
          payload: get(err, 'response.status'),
        });
        callback(err);
        reject(err);
      }
    }
  });
};
