import React from 'react';
import Input from 'antd/lib/input';
import Col from 'antd/lib/col';

import Row from './Row.styled';

const PageActions = ({
  onSearchBoxPressEnter,
  onSearchBoxChange,
  searchSuffix,
  query,
}) => (
  <Row type="flex" gutter={16} justify="end">
    <Col span={6}>
      <Input
        placeholder="Search"
        size="default"
        onPressEnter={onSearchBoxPressEnter}
        onChange={onSearchBoxChange}
        suffix={searchSuffix}
        value={query}
      />
    </Col>
  </Row>
);

export default PageActions;
