import { STRING, TEXT } from 'APP_ROOT/constants/fieldTypes';

const verifyStringType = field => {
  const { field_type, ...rest } = field;
  if (field_type === STRING) {
    return [{ field_type: TEXT, ...rest }, true];
  }
  return [field, false];
};

export default verifyStringType;
