import styled from 'styled-components';

export const LiveEventCheckInScanWrapper = styled.div.attrs({
  className: 'live-event-check-in-wrapper',
})`
  padding: 6px 16px;
  background-color: white;

  .scanner {
    width: 300px;
    height: 300px;
    border: 1px solid lightgray;
    margin: 10px 0;
    border-radius: 5px;

    &.success {
      border: 1px solid green;
    }

    &.error {
      border: 1px solid red;
    }

    &.result {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 110px;
    }
  }

  .ready-to-scan {
    color: darkgray;
  }

  button {
    &.success {
      background-color: green;
      color: white;
    }

    &.error {
      background-color: red;
      color: white;
    }
  }
`;
