import { get } from 'lodash';

import { EDIT_FORM } from './';
import editFormError from './edit-form-error';
import onGetReportSuccess from './utils/edit-form/onGetReportSuccess';
import retrieveReport from './utils/edit-form/retrieveReport';
import getFormTemplatesSuccess from './get-form-templates-success';
import getTemplateAtVersionBeforeCreateReport from './utils/get-form/getTemplateAtVersionBeforeCreateReport';
import replaceValues from './utils/edit-form/replace-special-characters';

const editForm = (id = '', agencyIdFromOptions, next) =>
  async function withChunk(dispatch, getState) {
    const { session } = getState();
    const currentUser = get(session, 'currentUser');
    const agencyId = agencyIdFromOptions || get(currentUser, 'agencyId');

    const filter = [
      {
        relation: 'participants',
        scope: {
          fields: [
            'fullName',
            'lastName',
            'firstName',
            'starNumber',
            'email',
            'rankId',
          ],
          include: [
            {
              relation: 'rank',
              scope: {
                fields: { name: true, abbreviation: true, id: true },
              },
            },
          ],
        },
      },
      {
        relation: 'notes',
        scope: {
          include: ['historyStep'],
          order: 'created ASC',
        },
      },
      {
        relation: 'actions',
        scope: {
          order: 'created ASC',
        },
      },
      {
        relation: 'linkedForms',
        scope: {
          include: {
            relation: 'actions',
          },
        },
      },
      {
        relation: 'submittedForms',
        scope: {
          include: {
            relation: 'actions',
            scope: {
              where: { status: 'pending' },
            },
          },
        },
      },
      {
        relation: 'formLinks',
        scope: {
          include: ['start', 'end'],
        },
      },
      {
        relation: 'linkedSubmissions',
        scope: {
          include: {
            relation: 'actions',
            scope: {
              where: { status: 'pending' },
            },
          },
        },
      },
    ];

    dispatch({ type: EDIT_FORM });

    const onDone = next && typeof next === 'function' ? next : () => {};

    const [error, report] = await retrieveReport({ agencyId, filter, id });

    if (error) {
      dispatch(editFormError(get(error, ['response', 'status']), id));
      return next(error);
    }

    const [
      responseError,
      template,
    ] = await getTemplateAtVersionBeforeCreateReport({
      template: null,
      agencyId,
      templateVersionId: report.templateVersionId,
      onlyPublished: true,
      templateIdParam: report.templateId,
    });

    if (responseError) {
      dispatch(editFormError(get(responseError, ['response', 'status']), id));
      return next(responseError);
    }

    replaceValues({ report, template });
    dispatch(getFormTemplatesSuccess([template]));

    return onGetReportSuccess({
      dispatch,
      report,
      templates: null,
      next: onDone,
      templateParam: template,
    });
  };

export default editForm;
