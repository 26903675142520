import React, { Component } from 'react';

// Containers
import DashboardPage from '../../components/dashboard';
import withoutClutter from '../../components/without-clutter';

import InsertEmbed from '../../components/izenda-embed/InsertEmbed';
import withFlexibleContainer from '../../components/izenda-embed/WithFlexibleLayout';

class EmbediZendaDashboard extends Component {
  componentWillUnmount() {
    if (this.izenda) {
      this.izenda.destroy();
    }
  }

  loadDashboardViewer = (izenda, target) => {
    this.izenda = izenda.renderDashboardPage(target);
  };

  render() {
    return <InsertEmbed onReady={this.loadDashboardViewer} />;
  }
}

const mapState = () => ({});

export default withoutClutter(
  DashboardPage(mapState, null)(withFlexibleContainer(EmbediZendaDashboard))
);
