import { combineReducers } from 'redux';
import ouUsersReducer from './ou-users';
import ssnLogsReducer from './ssn-logs';
import auditLogsReducer from './audit-logs';
import communityList from './community-list';
import reportTimelineLogsReducer from './report-timeline-logs';
import organizationList from './organization-list';

export default combineReducers({
  ouUsers: ouUsersReducer,
  ssnLogs: ssnLogsReducer,
  auditLogs: auditLogsReducer,
  community: communityList,
  reportTimelineLogs: reportTimelineLogsReducer,
  ouList: organizationList,
});
