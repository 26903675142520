import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Row } from 'antd';

import { DIVIDER } from '../../../../config/theme';

const CustomRow = props => {
  const Clickable = ({ link, children, ...rest }) => (
    <Link to={link}>
      <Row style={{ color: '#000' }} {...rest}>
        {children}
      </Row>
      <Row />
    </Link>
  );
  const Normal = ({ link, ...rest }) => <Row {...rest} />;
  const CurrentRow = props.link ? Clickable : Normal;
  return <CurrentRow {...props} />;
};

export const PanelTableRow = styled(CustomRow).attrs({
  className: 'bdm-panel-table-row',
})`
  font-size: 14px;
  margin: 0 !important;
  line-height: 28px;
  border-bottom: 1px solid ${DIVIDER};

  &:last-of-type {
    border-bottom: none;
  }

  div[class^='ant-col'] {
    padding: 16px 0;
  }

  div[class^='ant-col']:first-of-type {
    border-right: 1px solid ${DIVIDER};
  }

  @media (max-width: 768px) {
    div[class^='ant-col'] {
      padding: 10px 0;
    }

    div[class^='ant-col']:first-of-type {
      border-right: none;
    }
  }
`;

export default PanelTableRow;
