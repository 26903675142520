import { every } from 'lodash';

import complyKeepInData from './complyKeepInData';

const transformRule = (
  data,
  fieldConditions,
  compliesToConditions,
  compliesToBackendConditions,
  requiredByConditions
) => rule => {
  const { conditions: ruleConditions } = rule;
  const requiredByVisibility = fieldConditions ? compliesToConditions : false;
  const isOptional = !rule.mustExist || rule.optional;
  const requiredByBackend = ruleConditions
    ? compliesToBackendConditions
    : isOptional;
  const isRequired =
    rule.required ||
    (requiredByVisibility && requiredByConditions) ||
    requiredByBackend ||
    false;

  if (rule.keepInData) {
    const compliesKeepInData = complyKeepInData(data, rule.keepInData);
    return every(compliesKeepInData, Boolean);
  }

  return rule.optional ? false : isRequired;
};

export default transformRule;
