import { get, memoize } from 'lodash';

const getFilteredRepeaterData = memoize(
  ({
    parentIndex,
    data,
    presenceUsageFrom,
    loopFromEnumRef,
    presenceUsageFilterBy,
    dataEnums,
  }) => {
    const currentItem = get(dataEnums, `${loopFromEnumRef}.${parentIndex}`);
    return get(data, presenceUsageFrom, []).filter(
      item => get(item, presenceUsageFilterBy, '') === currentItem
    );
  }
);

export const getPresenceUsageCount = ({
  dataKey,
  parentIndex,
  data,
  presenceUsageFrom,
  presenceUsageValue,
  loopFromEnumRef,
  presenceUsageFilterBy,
  dataEnums,
}) =>
  getFilteredRepeaterData({
    dataKey,
    parentIndex,
    data,
    presenceUsageFrom,
    presenceUsageValue,
    loopFromEnumRef,
    presenceUsageFilterBy,
    dataEnums,
  })
    .map(item => get(item, presenceUsageValue, 0))
    .reduce(
      (accumulated, currentValue) => accumulated + Number(currentValue),
      0
    );

export const getPresenceUsageAverage = ({
  dataKey,
  parentIndex,
  data,
  presenceUsageFrom,
  presenceUsageValue,
  loopFromEnumRef,
  presenceUsageFilterBy,
  dataEnums,
}) => {
  const filteredItems = getFilteredRepeaterData({
    dataKey,
    parentIndex,
    data,
    presenceUsageFrom,
    presenceUsageValue,
    loopFromEnumRef,
    presenceUsageFilterBy,
    dataEnums,
  }).map(item => get(item, presenceUsageValue, 0));
  const count = filteredItems.reduce(
    (accumulated, currentValue) => accumulated + Number(currentValue),
    0
  );

  const average =
    count > 0 && filteredItems.length > 0 ? count / filteredItems.length : 0;

  return average;
};

const uniquePathResolver = memoize(
  ({
    dataEnums,
    loopFromEnumRef,
    parentIndex,
    showPresenceUsage,
    presenceUsageFilterBy,
    presenceUsageFrom,
    presenceUsageValue,
  }) =>
    `__${showPresenceUsage}.${loopFromEnumRef}.${parentIndex}.${get(
      dataEnums,
      `${loopFromEnumRef}.${parentIndex}`
    )}.${presenceUsageValue}.${presenceUsageFilterBy}.${presenceUsageFrom}__`
);

const getPresenceUsage = ({ showPresenceUsage = 'count', ...properties }) => {
  switch (showPresenceUsage) {
    case 'average':
      return getPresenceUsageAverage(properties);
    default:
      return getPresenceUsageCount(properties);
  }
};

const compoundResolver = memoize(properties =>
  getFilteredRepeaterData(properties)
    .map(item => get(item, get(properties, 'presenceUsageValue')))
    .concat(uniquePathResolver(properties))
    .join('.')
);

export default memoize(getPresenceUsage, compoundResolver);
