import React from 'react';

import createModal from 'APP_ROOT/actions/create-modal';
import showModal from 'APP_ROOT/actions/show-modal';
import { SHARE_MODAL_ID } from '../constants/share';
import ShareModal from '../components/ShareModal';
import ShareModalHeader from '../components/ShareModalHeader';
import ShareModalFooter from '../components/ShareModalFooter';
import ChangesCaseFileSharing from '../services/changesCaseFileSharing';

export default isCasefileClosed => {
  return (dispatch, getState) => {
    const title = <ShareModalHeader />;
    const body = <ShareModal />;
    const footer = <ShareModalFooter isCasefileClosed={isCasefileClosed} />;
    ChangesCaseFileSharing.setIsOpenModal(true);
    dispatch(
      createModal({
        id: SHARE_MODAL_ID,
        visible: true,
        title,
        children: body,
        footer,
        isCasefileClosed,
      })
    );
    dispatch(showModal(SHARE_MODAL_ID));
  };
};
