import FieldSelect from '../../components/FieldSelect';

export default {
  name: 'columns',
  label: 'Layout',
  component: FieldSelect,
  defaultValue: 2,
  selectOptions: [
    {
      value: 1,
      label: '1 Column',
    },
    {
      value: 2,
      label: '2 Columns',
    },
    {
      value: 3,
      label: '3 Columns',
    },
    {
      value: 4,
      label: '4 Columns',
    },
  ],
  onChange(value, values) {
    return {
      ...values,
      [this.name]: Number(value),
    };
  },
  getInitialValue(values) {
    return values.columns || this.defaultValue;
  },
};
