import { LEGACY_FIELD_WL } from '../formValidator/constants';
import calculatedFieldsConfig from '../calculatedFieldsConfig';

const findConfigField = keyFromSource => {
  const calculatedFieldConfig = calculatedFieldsConfig.calculatedFieldConfig;
  const config = calculatedFieldConfig.find(c => c.value === keyFromSource);
  if (config === undefined) {
    const legacyData =
      LEGACY_FIELD_WL.find(field => field.key === keyFromSource) || {};
    return { key: legacyData.key, type: legacyData.type };
  }
  return { key: config.value, type: config.type };
};

export default findConfigField;
