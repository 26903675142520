import {
  POST_FORM_TOGGLE_TIMEOUT_REQUEST,
  POST_FORM_TOGGLE_TIMEOUT_SUCCESS,
  POST_FORM_TOGGLE_TIMEOUT_ERROR,
} from './';
import { getAgencyId } from '../selectors/session';
import { getErrorResponseStatus } from './utils';
import { postFormSubmissionToggleTimeout } from '../api/reports';

export default (formSubmissionId, reason, notes, onComplete) => (
  dispatch,
  getState
) => {
  const agencyId = getAgencyId(getState());

  dispatch({
    type: POST_FORM_TOGGLE_TIMEOUT_REQUEST,
    payload: { formSubmissionId },
  });

  const callback = (...args) =>
    onComplete && typeof onComplete === 'function'
      ? onComplete(...args)
      : () => {};

  return postFormSubmissionToggleTimeout(
    agencyId,
    formSubmissionId,
    !isNaN(reason) ? +reason : reason,
    notes
  )
    .then(response => {
      dispatch({
        type: POST_FORM_TOGGLE_TIMEOUT_SUCCESS,
        payload: response,
      });

      callback(null, response);
    })
    .catch(responseError => {
      callback(responseError);

      dispatch({
        type: POST_FORM_TOGGLE_TIMEOUT_ERROR,
        payload: {
          error: getErrorResponseStatus(responseError),
          formSubmissionId,
        },
      });
    });
};
