import React, { PureComponent } from 'react';

import { BASE, BG } from '../../config/theme';
import { AppRoot } from '../../';

class Loading extends PureComponent {
  componentDidMount() {
    AppRoot.children.item(0).className =
      AppRoot.children.item(0).className.replace('ant-layout', '') +
      'ant-layout';
  }

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderTop: `2px solid ${BASE}`,
          backgroundColor: BG,
        }}
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
      body {
          padding: 0;
          margin: 0;
        }
    `,
          }}
        />
      </div>
    );
  }
}
export default Loading;
