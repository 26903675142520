import { createReducer } from 'redux-create-reducer';
import {
  SET_OULIST_SEARCH_KEYWORD,
  SET_OULIST_FROM_ORG_PROFILE,
} from '../../containers/organizations/actions/ou-list-actions';

const initialState = {
  searchKeyword: '',
  fromOuOrgProfile: false,
};

const events = {
  [SET_OULIST_SEARCH_KEYWORD]: (state, { payload }) => ({
    ...state,
    searchKeyword: payload.searchKeyword,
  }),
  [SET_OULIST_FROM_ORG_PROFILE]: (state, { payload }) => ({
    ...state,
    fromOuOrgProfile: payload.fromOuOrgProfile,
  }),
};

export default createReducer(initialState, events);
