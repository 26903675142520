import { ATTRIBUTE_TYPE_BOOL } from '../../UserProfile/UserProfile.constants';

const changeFieldValuesNew = ({ equipment, value, field }) => {
  const existingFieldIndex = equipment.findIndex(
    item => item.fieldId === field.id
  );

  if ((!value || value === '') && field.type !== ATTRIBUTE_TYPE_BOOL) {
    return removeField({ equipment, fieldId: field.id });
  } else {
    return updateOrCreateField({ equipment, existingFieldIndex, value, field });
  }
};

const removeField = ({ equipment, fieldId }) => {
  return equipment.filter(item => item.fieldId !== fieldId);
};

const updateOrCreateField = ({
  equipment,
  existingFieldIndex,
  value,
  field,
}) => {
  const updatedFieldValues =
    existingFieldIndex !== -1
      ? [...equipment]
      : [...equipment, { fieldId: field.id, value, type: field.type }];

  if (existingFieldIndex !== -1) {
    updatedFieldValues[existingFieldIndex] = {
      ...updatedFieldValues[existingFieldIndex],
      value,
      type: field.type,
    };
  }
  return updatedFieldValues;
};
export default changeFieldValuesNew;
