import resource from './resource';

export default {
  ...resource,
  filter: {
    where: { actionItem: true },
    order: 'created_at DESC',
    limit: 10,
  },
};
