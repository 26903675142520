import React, { Component } from 'react';
import { DndProvider } from 'react-dnd';
import { get, cloneDeep, isArray, isEmpty } from 'lodash';

import { Tabs } from 'antd';
import IconButton from '../../components/common/buttons/icon-button';

import HTML5Backend from 'react-dnd-html5-backend';

import componentsManager from './services/componentsManager';
import FormBuilderWrapper from './FormBuilder.styled';
import Workspace from './components/Workspace';
import Sidebar from './components/Sidebar';

import { withRouter } from 'react-router-dom';

import apiEndpoints from './services/apiEndpoints';

import {
  TAB,
  SECTION_WRAPPER,
  SECTION,
  ROW,
  COLUMN,
} from 'APP_ROOT/constants/layoutComponentTypes';
import { isReviewNote } from '../FormBuilder/utils/general-utilities';

import ReviewNoteDrawer from '../FormBuilder/components/review-note-drawer/review-note-drawer.component';
import getColumnOptionsByColumnNumber from './utils/getColumnOptionsByColumnNumber';
import FormComponent from './models/FormComponent';

const { TabPane } = Tabs;

const columnOptions = getColumnOptionsByColumnNumber(2);

const initialState = [
  new FormComponent(
    TAB,
    {
      key: 'tab_1',
      title: 'Tab 1',
    },
    [
      new FormComponent(SECTION_WRAPPER, null, [
        new FormComponent(SECTION, { columns: 2 }, [
          new FormComponent(ROW, null, [
            new FormComponent(COLUMN, columnOptions),
            new FormComponent(COLUMN, columnOptions),
          ]),
        ]),
      ]),
    ]
  ),
];

const initialStateReviewNote = [
  new FormComponent(
    SECTION_WRAPPER,
    {
      key: 'section_wrapper_1',
    },
    [
      new FormComponent(SECTION, { columns: 2 }, [
        new FormComponent(ROW, null, [
          new FormComponent(COLUMN),
          new FormComponent(COLUMN),
        ]),
      ]),
    ]
  ),
];

const MAIN_TEMPLATE_INDEX = 0;

class FormBuilder extends Component {
  propagateActionsKey = 'propagateAction';
  propagateFieldsKey = 'propagateFields';

  state = {
    executedAction: false,
    index: undefined,
    isNew: false,
    isNotesDrawerOpen: false,
    templates: [],
  };

  componentDidMount() {
    const {
      location,
      match: {
        params: { agencyId, id },
      },
      executedAction,
    } = this.props;

    componentsManager.updateNotification.subscribe({
      next: this.reloadUpdatedTemplate,
    });

    const params = new URLSearchParams(location.search);
    const isDefault = params.get('isDefault') === 'true';
    const isNote = params.get('isReviewNote') === 'true';
    const isNewForm = id === 'create';
    const importedSchema = get(location, 'state.schema', undefined);
    const isImporting =
      get(location, 'state.import', false) && importedSchema !== undefined;

    if (isImporting) {
      this.renderImported(importedSchema);
    } else if (isNewForm) {
      this.renderNewTemplate(executedAction, isNote);
    } else {
      this.renderExistingTemplate(agencyId, id, isDefault, executedAction);
    }
  }

  render() {
    const { headerHeight } = this.props;
    const mainTemplate = this.state.templates[MAIN_TEMPLATE_INDEX];
    const selectedTemplate = this.state.templates[this.state.index];
    const drawerName = selectedTemplate ? selectedTemplate.name : 'Review Note';
    const action = this.extractPropagateAction(selectedTemplate);
    const fields = this.extractPropagateFields(selectedTemplate);
    return (
      <div style={{ paddingTop: headerHeight }}>
        {this.state.templates.length === 1 ? (
          this.drawSidebarAndWorkarea(mainTemplate, isReviewNote(mainTemplate))
        ) : (
          <Tabs defaultActiveKey="1" onChange={this.changeTab}>
            {this.state.templates.map(this.drawTemplates)}
          </Tabs>
        )}
        {
          <ReviewNoteDrawer
            name={drawerName}
            mainTemplate={mainTemplate}
            propagateActionsKey={this.propagateActionsKey}
            propagateFieldsKey={this.propagateFieldsKey}
            propagateActions={action}
            propagateFields={fields}
            onSubmission={this.handlePropagationData}
            selectedTemplate={selectedTemplate}
            visible={this.state.isNotesDrawerOpen}
            onClose={this.toggleReviewNoteDrawer}
          ></ReviewNoteDrawer>
        }
      </div>
    );
  }

  renderNewTemplate(executedAction, isNote) {
    if (isNote) {
      this.createEmptyReviewNoteTemplate(executedAction);
    } else {
      this.createEmptyTemplate(executedAction);
    }
  }

  drawTemplates = template => {
    const index = this.state.templates.indexOf(template);
    const isNote = isReviewNote(template);
    return (
      <TabPane
        tab={this.drawTabPaneTabTitle(template.name, isNote)}
        key={index}
      >
        {this.drawSidebarAndWorkarea(template, isNote)}
      </TabPane>
    );
  };

  drawTabPaneTabTitle(name, isNote) {
    return (
      <span>
        {name}
        {isNote && (
          <IconButton
            icon="setting"
            onClick={this.toggleReviewNoteDrawer}
            style={{ paddingLeft: '15px', paddingTop: '7px' }}
          ></IconButton>
        )}
      </span>
    );
  }

  drawSidebarAndWorkarea(template, isNote) {
    if (template) {
      const { headerHeight } = this.props;
      return (
        <FormBuilderWrapper
          style={{
            opacity: this.state.executedAction ? '0.6' : '1',
            pointerEvents: this.state.executedAction ? 'none' : 'auto',
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <Sidebar
              className="form-builder__sidebar"
              offsetTop={headerHeight}
            />
            <Workspace
              className="form-builder__workspace"
              isReviewNote={isNote}
              schema={template}
              offsetTop={headerHeight}
            />
          </DndProvider>
        </FormBuilderWrapper>
      );
    }
  }

  loadSchema(schema) {
    if (schema) {
      const {
        name = '',
        description,
        abbreviation = '',
        category = 'FORM',
        shareable = true,
        version = 1,
        status,
      } = schema;
      const isDefault = schema.default;
      // If template is default, remove its id so its not updatable.
      // Instead, force the creation of a custom template based on the
      // default template on save.
      let id = !isDefault ? schema.id : undefined;
      // On template import, updatable is undefined. Mark it as true
      // so the Save button is enabled.
      const updatable =
        schema.updatable === undefined ? true : schema.updatable;
      componentsManager.header = {
        id,
        name: name ? name.trim() : '',
        abbreviation: abbreviation ? abbreviation.trim() : '',
        description,
        category,
        updatable,
        shareable,
        version,
        status,
        default: isDefault,
        isNote: isReviewNote(schema),
      };
      componentsManager.uploadFormSchema(
        isEmpty(schema.formSchema) ? schema.jsonSchema : schema.formSchema
      );
    }
  }

  createEmptyTemplate(executedAction) {
    componentsManager.header = {
      name: 'Form Builder',
      description: 'Description',
      abbreviation: 'ABBR',
      category: 'FORM',
      default: false,
      updatable: true,
      shareable: true,
      id: undefined,
      isLoading: false,
      isNote: false,
      fromTemplateId: undefined,
    };
    const template = componentsManager.hydrate(cloneDeep(initialState));
    this.setState({
      executedAction: executedAction,
      index: 0,
      isNew: true,
      templates: [template],
    });
  }

  createEmptyReviewNoteTemplate(executedAction) {
    componentsManager.header = {
      name: 'Custom Review Note',
      description: 'This is a custom review note',
      abbreviation: 'CRN',
      category: 'NOTE',
      default: false,
      updatable: true,
      id: undefined,
      isLoading: false,
      isNote: true,
      fromTemplateId: undefined,
    };
    const template = componentsManager.hydrate(
      cloneDeep(initialStateReviewNote)
    );
    this.setState({
      executedAction: executedAction,
      index: 0,
      isNew: true,
      templates: [template],
    });
  }

  renderImported(importedSchema) {
    const { executedAction } = this.props;
    this.loadSchema(importedSchema);
    this.setState({
      executedAction: executedAction,
      index: 0,
      isNew: true,
      templates: [importedSchema],
    });
  }

  extractPropagateAction(schema) {
    const actions = get(schema, 'formSchema.propagateAction', []);
    return isArray(actions) ? actions : [];
  }

  extractPropagateFields(schema) {
    const fields = get(schema, 'formSchema.propagateFields', []);
    return isArray(fields) ? fields : [];
  }

  // async methods

  async renderExistingTemplate(agencyId, id, isDefault, executedAction) {
    const mainTemplate = await apiEndpoints.getFormWithReviewNotes(
      agencyId,
      id,
      isDefault
    );
    this.loadSchema(mainTemplate);
    const reviewNotes = await this.fetchReviewNoteTemplateBy(
      agencyId,
      mainTemplate.reviewNoteIds
    );
    const templates = [mainTemplate].concat(reviewNotes);
    this.setState({
      executedAction: executedAction,
      index: 0,
      templates: templates,
    });
  }

  async fetchReviewNoteTemplateBy(agencyId, reviewNoteIds) {
    if (reviewNoteIds && reviewNoteIds.length > 0) {
      return Promise.all(
        reviewNoteIds.map(async reviewNoteId =>
          apiEndpoints.getForm(agencyId, reviewNoteId, false)
        )
      );
    }
    return [];
  }

  // amon methods

  changeTab = key => {
    this.loadSchema(this.state.templates[key]);
    this.setState({ index: key });
  };

  toggleReviewNoteDrawer = () => {
    this.setState({ isNotesDrawerOpen: !this.state.isNotesDrawerOpen });
  };

  handlePropagationData = data => {
    componentsManager.setPropagateAction(data[this.propagateActionsKey]);
    componentsManager.setPropagateFields(data[this.propagateFieldsKey]);
  };

  reloadUpdatedTemplate = async () => {
    const {
      location,
      match: {
        params: { agencyId, id },
      },
    } = this.props;
    if (!isNaN(Number(id))) {
      const params = new URLSearchParams(location.search);
      const isDefault = params.get('isDefault') === 'true';
      const mainTemplate = await apiEndpoints.getFormWithReviewNotes(
        agencyId,
        id,
        isDefault
      );
      const reviewNotes = await this.fetchReviewNoteTemplateBy(
        agencyId,
        mainTemplate.reviewNoteIds
      );
      const templates = [mainTemplate].concat(reviewNotes);
      let name;
      let description;
      let abbreviation;
      let category;
      let updatable;
      let shareable;
      let version;
      let status;

      if (mainTemplate.id === componentsManager.header.id) {
        ({
          name,
          description,
          abbreviation,
          category,
          updatable,
          shareable = true,
          version = 1,
          status,
        } = mainTemplate);
      } else {
        [
          {
            name,
            description,
            abbreviation,
            category,
            updatable,
            shareable = true,
            version = 1,
            status,
          },
        ] = reviewNotes;
      }
      componentsManager.header = {
        name,
        description,
        abbreviation,
        category,
        updatable,
        shareable,
        version,
        status,
      };

      this.setState({ templates: templates });
    }
  };
}

export default withRouter(FormBuilder);
