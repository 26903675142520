export const JOIN_TYPE_ALL = 'all';
export const JOIN_TYPE_SOME = 'some';

export const OPERATOR_EQUAL = 'eq';
export const OPERATOR_NOT_EQUAL = 'neq';
export const OPERATOR_GREATER_THAN = 'gt';
export const OPERATOR_LESS_THAN = 'lt';
export const OPERATOR_GREATHER_EQUAL = 'gte';
export const OPERATOR_LESS_EQUAL = 'lte';
export const OPERATOR_IN = 'inq';
export const OPERATOR_NOT_IN = 'ninq';

export const OPERATOR_AND = 'and';
export const OPERATOR_OR = 'or';
