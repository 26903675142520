import { isEmpty, get } from 'lodash';

import DurationFieldOptions from './getDurationFieldOptions';

import {
  TIME_UNITS,
  TIME_UNITS_MINUTES,
  TIME_UNITS_YEARS,
  DURATION_FIELDS_YEARS,
  DURATION_FIELDS_MINUTES,
} from '../../constants/constants';

import componentsManager from '../../services/componentsManager';
import getCascaderOptionsFromFields from '../getCascaderOptionsFromFields';
import getCascaderOption, { saveValue } from './getCascaderOption';
import getAllFieldsFlat from '../getAllFieldsFlat';

export default class {
  constructor(values) {
    this.name = 'duration';
    this.label = '';
    this.fieldKey = values.key;
    this.component = DurationFieldOptions;
    this.units = '';
    const units = TIME_UNITS[0].value;
    this.defaultValue = { initial: '', final: '', units };

    const { repeatersReference } = componentsManager;
    let repeaterKey = '';

    this.getCascaderOptions(units);

    Object.keys(repeatersReference).some(key => {
      return repeatersReference[key].some(r => {
        if (r.fields[this.fieldKey]) {
          repeaterKey = r.repeaterKey;
          return true;
        }
        return false;
      });
    });
    this.parentRepeater = repeaterKey;
  }

  getFilterBy = units => {
    let filterBy;
    switch (units) {
      case TIME_UNITS_YEARS:
        filterBy = DURATION_FIELDS_YEARS;
        break;
      case TIME_UNITS_MINUTES:
        filterBy = DURATION_FIELDS_MINUTES;
        break;
      default:
        filterBy = [];
        break;
    }
    return filterBy;
  };

  getCascaderOptions = (units = '') => {
    if (units === '' || this.units === units) return this.cascaderOptions;
    this.units = units;

    const filterBy = this.getFilterBy(units);

    let allFields = getAllFieldsFlat(componentsManager.fields);
    allFields = {
      ...allFields,
      allTabs: allFields.allTabs.map((tab, i) => ({
        ...tab,
        properties: tab.properties.filter(
          prop =>
            filterBy.includes(prop.field_type) &&
            get(prop, 'options.allowFuture', false) === false
        ),
      })),
    };
    const { repeatersReference } = componentsManager;

    this.cascaderOptions = getCascaderOptionsFromFields(undefined, false, [], {
      allFields,
      repeatersReference,
    });

    return this.cascaderOptions;
  };

  isValid = values => {
    const { initial, final } = values[this.name];

    return !isEmpty(initial) && !isEmpty(final);
  };

  onChange = (value, values) => {
    const { units } = value;

    this.getCascaderOptions(units);

    return {
      ...values,
      [this.name]: value,
    };
  };

  onSave = values => {
    const { initial, final, units } = values[this.name];
    const { options = {} } = values;

    return {
      ...values,
      [this.name]: undefined,
      options: {
        ...options,
        initial: saveValue(this.parentRepeater, initial),
        final: saveValue(this.parentRepeater, final),
        units,
      },
    };
  };

  getInitialValue = values => {
    if (values.options === undefined) {
      return this.defaultValue;
    }
    const {
      initial: _initial,
      final: _final,
      units: _units,
    } = this.defaultValue;
    const {
      initial = _initial,
      final = _final,
      units = _units,
    } = values.options;

    this.getCascaderOptions(units);

    // initial could be data.key, meaning field is in a repeater,
    // but initial is not
    return {
      initial: getCascaderOption(
        this.cascaderOptions,
        this.parentRepeater,
        initial
      ),
      final: getCascaderOption(
        this.cascaderOptions,
        this.parentRepeater,
        final
      ),
      units,
    };
  };
}
