import React, { useState, useEffect } from 'react';
import { Button, InputNumber, Switch, Input, Skeleton } from 'antd';

import {
  StyledModal,
  StyledRow,
  StyledCol,
  StyledLabel,
} from '../../../../modules/Security/components/RolesModal/index.styled';
import ReadOnlyData from './ReadOnlyData';
import { getRole, updateRole } from './utils';

const PermissionsModal = ({
  isModalVisible,
  onModalVisibleAction,
  roleId,
  getListOfRoles,
}) => {
  const [roleData, setRoleData] = useState({});
  const [isRoleDataLoading, setIsRoleDataLoading] = useState(true);
  const [isUpdateButtonLoading, setIsUpdateButtonLoading] = useState(false);
  const [isInternalValue, setIsInternalValue] = useState(false);
  const [rankNumberValue, setRankNumberValue] = useState(0);
  const [addPermissionsValue, setAddPermissionsValue] = useState('');
  const [removePermissionsValue, setRemovePermissionsValue] = useState('');
  const getRoleById = async roleId => {
    const result = await getRole(roleId);
    const { isInternal, rankNumber } = result[0];
    setIsInternalValue(isInternal);
    setRankNumberValue(rankNumber);
    setRoleData(result[0]);
    setIsRoleDataLoading(false);
  };

  useEffect(() => {
    getRoleById(roleId);
  }, [onModalVisibleAction]);

  const closeModal = () => {
    onModalVisibleAction(false);
    setIsUpdateButtonLoading(false);
    setAddPermissionsValue('');
    setRemovePermissionsValue('');
  };
  const onIsInternalChange = e => setIsInternalValue(e);
  const onRankNumberChange = e => setRankNumberValue(e);
  const onAddPermissionsChange = e => setAddPermissionsValue(e.target.value);
  const onRemovePermissionsChange = e =>
    setRemovePermissionsValue(e.target.value);
  const onRequestSuccess = () => {
    closeModal();
    getListOfRoles();
    setIsUpdateButtonLoading(false);
  };

  const onRequestFailure = () => {
    setIsUpdateButtonLoading(false);
  };

  const onUpdateClickButton = values => {
    updateRole(values, onRequestSuccess, onRequestFailure);
    setIsUpdateButtonLoading(true);
  };

  const footer = [
    <Button key="cancel" onClick={() => closeModal()}>
      Cancel
    </Button>,
    <Button
      key="create"
      type="primary"
      onClick={() => {
        onUpdateClickButton({
          roleId: roleData.id,
          isInternalValue,
          rankNumberValue,
          addPermissionsValue,
          removePermissionsValue,
        });
      }}
      disabled={isRoleDataLoading}
      loading={isUpdateButtonLoading}
    >
      Update
    </Button>,
  ];

  const renderContent = () => {
    if (isRoleDataLoading) return <Skeleton active />;

    return (
      <>
        <ReadOnlyData roleData={roleData} />
        <StyledRow>
          <StyledCol xs={24} sm={12} md={12} lg={12}>
            <StyledLabel>Rank number</StyledLabel>
            <InputNumber
              min={0}
              size="small"
              onChange={onRankNumberChange}
              defaultValue={rankNumberValue}
              value={rankNumberValue}
            />
          </StyledCol>
          <StyledCol xs={24} sm={12} md={12} lg={12}>
            <StyledLabel>Internal Flag</StyledLabel>
            <Switch
              checkedChildren="true"
              unCheckedChildren="False"
              onChange={onIsInternalChange}
              defaultChecked={isInternalValue}
              checked={isInternalValue}
            />
          </StyledCol>
        </StyledRow>
        <StyledRow>
          <StyledCol xs={24} sm={12} md={12} lg={12}>
            <StyledLabel>Add permissions</StyledLabel>
            <Input.TextArea
              autoSize={{ minRows: 8, maxRows: 8 }}
              onChange={onAddPermissionsChange}
              value={addPermissionsValue}
            />
          </StyledCol>
          <StyledCol xs={24} sm={12} md={12} lg={12}>
            <StyledLabel>Remove permissions</StyledLabel>
            <Input.TextArea
              autoSize={{ minRows: 8, maxRows: 8 }}
              onChange={onRemovePermissionsChange}
              value={removePermissionsValue}
            />
          </StyledCol>
        </StyledRow>
      </>
    );
  };

  return (
    <StyledModal
      title="Update Role"
      visible={isModalVisible}
      onCancel={() => closeModal()}
      footer={footer}
      maskClosable={false}
    >
      {renderContent()}
    </StyledModal>
  );
};

export default PermissionsModal;
