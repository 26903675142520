import { camelCase } from 'lodash';
import { PERMISSIONS } from '../../../utils/admin';
import PermissionAccessView from './PermissionAccessView';

export default ({ path = '', name = '', permission = 'permission' }) => [
  {
    path: `${path}/roles-permissions/:permission/:roleId/:agencyName`,
    name: camelCase(`${name}-permissions-attributes`),
    component: PermissionAccessView,
    protectedWith: [PERMISSIONS.createRole],
    childRoutes: [],
  },
];
