import React from 'react';
import Form from 'antd/lib/form';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Checkbox from 'antd/lib/checkbox';
import Button from 'antd/lib/button';
import Switch from 'antd/lib/switch';
import styled from 'styled-components';
import ModalBody from '../../modal/body';
import { Divider } from '../../panel';
const { TextArea } = Input;

const CustomCheckbox = styled(Checkbox)`
  padding: 10px 0;
`;

const ModalButton = ({ label, onClick, ...props }) => (
  <Button onClick={onClick} size="default" {...props}>
    {label}
  </Button>
);

const BodyReviewDismiss = (
  <ModalBody>
    <div className="inner-body">
      Reason for Dismissal
      <TextArea rows={8} style={{ marginTop: '16px' }} />
    </div>
  </ModalBody>
);

const BodyActiveCare = (
  <ModalBody>
    <div className="inner-body">
      <CustomCheckbox>Take Tactical Communication Course</CustomCheckbox>
      <CustomCheckbox>Assign Sergeant for oversight / mentoring</CustomCheckbox>
      <CustomCheckbox>
        Complete documented verbal warning for missed court appearances
      </CustomCheckbox>
    </div>
    <Divider />
    <div className="inner-body">
      <p>Forward this information to others:</p>
      <Row align="middle">
        <Col xs={24} sm={22}>
          <p>Notify Supervisor</p>
        </Col>
        <Col xs={24} sm={2}>
          <Switch
            defaultChecked={false}
            size="default"
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </Col>
      </Row>
      <Row align="middle">
        <Col xs={24} sm={22}>
          <p>Forward to Internal Affairs</p>
        </Col>
        <Col xs={24} sm={2}>
          <Switch
            defaultChecked={false}
            size="default"
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </Col>
      </Row>
    </div>
  </ModalBody>
);

const SuccessActiveCare = (
  <ModalBody>
    <div className="inner-body">
      <p>
        <Icon type="check-circle" /> <span>Training Section notified</span>
      </p>
      <p>
        <Icon type="check-circle" />{' '}
        <span>
          Patrol Commander notified to identify and assign Sergeant for
          mentoring
        </span>
      </p>
      <p>
        <Icon type="check-circle" />{' '}
        <span>Sergeant assigned (via Patrol) for Verbal Warning</span>
      </p>
    </div>
  </ModalBody>
);

const BodyInternalAffairs = (
  <ModalBody>
    <div className="inner-body">
      <CustomCheckbox>
        Forward to Internal Affairs confidentially
      </CustomCheckbox>
    </div>
  </ModalBody>
);

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;

  label {
    padding: 8px;
  }
`;

const CustomForm = props => (
  <Form onSubmit={e => props.onSubmit(e, props.form.getFieldsValue())}>
    {props.possibleActions.map((action, index) => (
      <StyledFormItem key={index} style={{ marginBottom: 0 }}>
        {props.form.getFieldDecorator(action.name, {
          valuePropName: 'checked',
          initialValue: false,
        })(<CustomCheckbox>{action.description}</CustomCheckbox>)}
      </StyledFormItem>
    ))}
    <input type="submit" style={{ display: 'none' }} ref={props.submitRef} />
    <input
      type="button"
      style={{ display: 'none' }}
      ref={props.resetRef}
      onClick={() => props.form.resetFields()}
    />
  </Form>
);
const WrappedForm = Form.create()(CustomForm);

const BodyRecognize = props => {
  const { possibleActions, onSubmit } = props;
  return (
    <ModalBody>
      <div className="inner-body">
        <WrappedForm
          onSubmit={onSubmit}
          possibleActions={possibleActions}
          submitRef={props.submitRef}
          resetRef={props.resetRef}
        />
      </div>
    </ModalBody>
  );
};

const SuccessRecognize = props => {
  const { successfulActions } = props;
  return (
    <ModalBody>
      <div className="inner-body">
        {successfulActions.map((action, index) => (
          <p key={index}>
            <Icon type="check-circle" />
            <span>{action.successMessage}</span>
          </p>
        ))}
      </div>
    </ModalBody>
  );
};

export {
  ModalButton,
  BodyReviewDismiss,
  BodyActiveCare,
  SuccessActiveCare,
  BodyInternalAffairs,
  BodyRecognize,
  SuccessRecognize,
};
