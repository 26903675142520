import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';

import { Routes } from './routes';
import store, { history } from './store';

const originalFetch = window.fetch;

const exampleConfig = {
  method: 'GET',
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  redirect: 'follow',
  referrerPolicy: 'no-referrer',
  headers: {},
  body: {},
};

function createFetcher(
  instanceConfig = {
    interceptors: {
      async before(exampleConfig) {
        return exampleConfig;
      },
      async challenge(exampleConfig) {
        return exampleConfig;
      },
    },
  }
) {
  const instance = async (
    url,
    config = exampleConfig,
    interceptors = {
      async before(exampleConfig) {
        return exampleConfig;
      },
      async challenge(exampleConfig) {
        return exampleConfig;
      },
    }
  ) => {
    if (typeof url === 'object') return originalFetch(url);
    if (interceptors?.before) config = await interceptors.before(config);
    if (instanceConfig.interceptors?.before)
      config = await instanceConfig.interceptors.before(config);
    let response = await originalFetch(url, config);
    if (response.headers.get('cf-mitigated') === 'challenge') {
      if (interceptors?.challenge)
        config = await interceptors.challenge(config);
      if (instanceConfig.interceptors?.challenge)
        config = await instanceConfig.interceptors.challenge(config);
      if (interceptors?.challenge || instanceConfig.interceptors?.challenge)
        response = await originalFetch(url, config);
    }
    return response;
  };

  return instance;
}

const overlay = document.createElement('div');
overlay.style.display = 'none';
overlay.id = 'turnstile_widget';
document.body.appendChild(overlay);

// Inject challenge interceptor into fetch
window.fetch = createFetcher({
  interceptors: {
    async challenge(config) {
      return new Promise((resolve, reject) => {
        // turnstile comes from index.html as a source tag
        // eslint-disable-next-line no-undef
        turnstile.render('#turnstile_widget', {
          sitekey: process.env.REACT_APP_TURNSTILE_SITE_KEY,
          'error-callback': function(e) {
            reject(e);
          },
          callback: function(token, preClearanceObtained) {
            if (preClearanceObtained) {
              // The visitor successfully solved the challenge on the page.
              resolve(config);
            } else {
              reject(new Error('Unable to obtain pre-clearance'));
            }
          },
        });
      });
    },
  },
});

const persistor = persistStore(store);

const BenchmarkManagementSystem = props => (
  <ConfigProvider locale={enUS}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ConfigProvider>
);

/**
 * Automatically review if app is under maintenance
 * checks every 60 seconds only on prod-like deployments
 * and by calling its own host.
 * If page is under maintenance, the window will reload
 */
if (process.env.NODE_ENV === 'production') {
  setInterval(async () => {
    const responseStream = await fetch('/maintenance-mode', {
      method: 'GET',
      cache: 'no-cache',
    });
    const body = await responseStream.text();
    if (body === 'true') {
      window.location.reload();
    }
  }, 60000);
}

export default BenchmarkManagementSystem;
