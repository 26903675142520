import {
  GET_RANKS_REQUEST,
  GET_RANKS_FULLFILLED,
  GET_RANKS_REJECTED,
} from '../AgencyProfile.actionTypes';

import organizationEndpoints from '../../../../api/organization/organizationEndpoints';

export default (agencyId, next = () => {}) => dispatch => {
  dispatch({ type: GET_RANKS_REQUEST });

  dispatch({
    type: GET_RANKS_FULLFILLED,
    payload: { agencyId, response: [] },
  });

  return organizationEndpoints
    .getRanks(agencyId)
    .then(response => {
      dispatch({
        type: GET_RANKS_FULLFILLED,
        payload: { agencyId, response },
      });
      next(null, response);
    })
    .catch(error => {
      dispatch({ type: GET_RANKS_REJECTED, payload: error });
      next(error);
    });
};
