import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import styled from 'styled-components';

import Panel, { PanelTitle } from '../../components/common/panel';

const FormItem = Form.Item;
const FormItemStyled = styled(FormItem)`
  .ant-form-item-label {
    text-align: left;
  }
`;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

const SUBMISSION_SENT_MSG =
  'A Benchmark representative will respond to you as soon as possible.';
const salesforceWTC =
  'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';
const dummyURL = 'http://127.0.0.1';

const SupportForm = props => {
  const [message, setMessage] = useState('');
  const { email, fullName, agencyName } = props;
  const [description, setDescription] = useState('');

  useEffect(() => {
    const submitListener = () => {
      setMessage(SUBMISSION_SENT_MSG);
      setTimeout(() => {
        // Resets form
        setMessage('');
        setDescription('');

        if (props.controlPopup && typeof props.controlPopup === 'function') {
          props.controlPopup(false);
        }
      }, 5000);
    };

    if (document)
      document
        .getElementById('wtc-form')
        .addEventListener('submit', submitListener);

    return () => {
      if (document)
        document
          .getElementById('wtc-form')
          .removeEventListener('submit', submitListener);
    };
  }, []);

  const onDescriptionChange = ({ target: { value } }) => {
    setDescription(value);
  };

  const renderMessage = () => {
    if (message) {
      return (
        <Panel>
          <PanelTitle>
            <h2 className="text-center">{message}</h2>
          </PanelTitle>
        </Panel>
      );
    }
  };

  return (
    <div style={{ padding: 10 }}>
      {renderMessage()}
      <iframe
        src="about:blank"
        id="dummyIframe"
        name="dummyIframe"
        style={{ display: 'none' }}
      ></iframe>
      <form
        id="wtc-form"
        style={{ display: !message ? 'block' : 'none' }}
        method="POST"
        target="dummyIframe"
        action={salesforceWTC}
      >
        <FormItemStyled
          {...formItemLayout}
          label="Please enter your question here"
          colon={false}
          hasFeedback
        >
          <Input type="hidden" name="orgid" value="00D1U000000xZ9K" />
          <Input type="hidden" name="retURL" value={dummyURL} />
          <Input type="hidden" name="email" value={email} />
          <Input type="hidden" name="00N1U00000VzSVg" value={fullName} />
          <Input type="hidden" name="00N1U00000VzSVl" value={agencyName} />
          <Input.TextArea
            value={description}
            onChange={onDescriptionChange}
            rows={8}
            name="description"
            id="description"
            addonBefore="Please enter your question here"
          />
        </FormItemStyled>

        <FormItem {...formItemLayout}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </FormItem>
      </form>
    </div>
  );
};

const WrappedForm = Form.create()(SupportForm);

const mapState = state => {
  //  Get the logged user's email, full name and their agency's name
  const {
    session: {
      currentUser: {
        email,
        fullName,
        agency: { name },
      },
    },
  } = state;
  return { email, fullName, agencyName: name };
};

export default connect(mapState, null)(WrappedForm);
