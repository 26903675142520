import { REMOVE_REFERENCE_SELECTION } from './';

export default (officerId = '', source = '', fields = []) => (
  dispatch,
  getState
) => {
  dispatch({
    type: REMOVE_REFERENCE_SELECTION,
    payload: { officerId, source, fields },
  });
};
