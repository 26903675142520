import { isEmpty, omit } from 'lodash';

import { SECTION_TYPE_FORM } from '../UserProfile.constants';

const getSectionsValues = (sectionsDefinition, { sectionsData = {} }) => {
  let data = [];

  sectionsDefinition.forEach(section => {
    const { type, sectionId, sectionAttributes } = section;
    const attributesData = sectionsData[sectionId];
    if (type === SECTION_TYPE_FORM) {
      const { formValues } = section;
      const changedValues = sectionAttributes
        .map(({ attributeId, attributeName }) => {
          const value = formValues.find(f => f.attributeId === attributeId) || {
            attributeId,
            value: null,
          };
          return value.value !== attributesData[attributeName]
            ? {
                ...omit(value, [
                  'createdAt',
                  'createdBy',
                  'lastModifiedBy',
                  'updatedAt',
                ]),
                attributeId,
                value: attributesData[attributeName],
              }
            : null;
        })
        .filter(v => v !== null);

      if (changedValues.length) {
        data.push({
          sectionId,
          formValues: changedValues,
        });
      }
    } else {
      const { tableValues } = section;
      let changedValues;
      const objectValueId = attributesData.objectValueId || [];
      objectValueId.forEach((objectValueId, index) => {
        const values =
          tableValues.find(f => f.objectValueId === objectValueId) || {};
        const { objectAttributeValues = [] } = values;
        changedValues = sectionAttributes
          .map(({ attributeId, attributeName }) => {
            const value = objectAttributeValues.find(
              f => f.attributeId === attributeId
            );
            // when the record is new, value === undefined which means
            // we don't have valueId
            return !value ||
              value.value !== attributesData[attributeName][index]
              ? {
                  ...omit(value, [
                    'createdAt',
                    'createdBy',
                    'lastModifiedBy',
                    'updatedAt',
                  ]),
                  attributeId,
                  value: attributesData[attributeName][index],
                }
              : null;
          })
          .filter(v => v !== null);

        if (changedValues.length) {
          const finalValues = changedValues.map(v => {
            return {
              ...v,
              value: v.value !== null ? v.value : '',
            };
          });
          // when isEmpty(values) we have a new row and objectValueId
          // was generated en UI, does not exist in DB, so don't sent it
          const item = data.find(d => d.sectionId === sectionId);
          if (item) {
            item.tableValues.push({
              objectValueId: isEmpty(values) ? undefined : objectValueId,
              objectAttributeValues: finalValues,
            });
          } else {
            data.push({
              sectionId,
              tableValues: [
                {
                  objectValueId: isEmpty(values) ? undefined : objectValueId,
                  objectAttributeValues: finalValues,
                },
              ],
            });
          }
        }
      });
    }
  });

  return data.length ? { sections: data } : null;
};

export default getSectionsValues;
