import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import get from 'lodash/get';
import set from 'lodash/set';
import each from 'lodash/each';
import first from 'lodash/first';
import some from 'lodash/some';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuid } from 'uuid';

import getRepeaterSymlink, {
  updateSymlinkCacheFor,
  clearSymlinksCache,
} from './get-repeater-symlink';

export const symlinkIdPropertyName = 'symlink$id';
export const symlinkPathPropertyName = 'symlink$path';
export const symlinkIndexPropertyName = 'symlink$index';
export const symlinkFromPropertyName = 'symlink$from';
export const symlinkToPropertyName = 'symlink$to';
export const symlinksPropName = '__repeaterSymlinks';
export const symlinksPropertiesPropName = 'symlink$props';

export const getSymlinkInfo = (item, simlinks) => {
  const symlinkPath = get(item, symlinkPathPropertyName);
  const symlink = find(
    simlinks,
    _symlink => _symlink[symlinkPathPropertyName] === symlinkPath
  );

  return {
    path: get(symlink, symlinkPathPropertyName),
    from: get(symlink, symlinkFromPropertyName),
    to: get(symlink, symlinkToPropertyName),
    index: get(symlink, symlinkIndexPropertyName),
    id: get(symlink, symlinkIdPropertyName),
    symlink$meta: symlink,
  };
};

export const hasSymlinkedRepeater = data =>
  some(data, item => has(item, symlinkPathPropertyName));

export const isSymlinked = item =>
  has(item, symlinkPathPropertyName) &&
  !isEmpty(get(item, symlinkPathPropertyName));

export const hasSymlinks = item => has(item, symlinksPropertiesPropName);
export const getSymlinks = item => get(item, symlinksPropertiesPropName, []);
export const getAllSymlinks = item => get(item, symlinksPropName, []);

export const isSymlinkedByProps = (item, key) =>
  get(item, symlinksPropertiesPropName, []).includes(key);

export const createSymlink = (
  initialData,
  key,
  data,
  currentSymlinks = [],
  formData,
  presentation
) => {
  const index = data.length;
  const currentRepeaterName = key;
  let symlinks = getSymlinks(initialData);
  let repeaterSymLinks = [].concat(currentSymlinks);
  let parent = Object.assign({}, initialData);
  let _formData = {};

  const newItem = symlinks.reduce((output, symlinkKey) => {
    const repeaterName = symlinkKey;
    const symlinkId = getRepeaterSymlink(
      currentRepeaterName,
      repeaterName,
      index
    );
    const symlinkPath = `symlink$${repeaterName}$${symlinkId}`;

    const symlink = {
      [symlinkToPropertyName]: repeaterName,
      [symlinkFromPropertyName]: currentRepeaterName,
      [symlinkIndexPropertyName]: index,
      [symlinkPathPropertyName]: symlinkPath,
      [symlinkIdPropertyName]: symlinkId,
    };

    repeaterSymLinks.push(symlink);
    parent[symlinkKey] = symlinkPath;

    const symlinkedRepeaterTemplate =
      first(get(presentation, symlinkKey, [])) || {};
    _formData[symlinkKey] = get(formData, symlinkKey, []).concat(
      Object.assign({}, symlinkedRepeaterTemplate, {
        [symlinkPathPropertyName]: symlinkPath,
      })
    );

    return {
      ...output,
      ...parent,
      [symlinkKey]: symlinkPath,
    };
  }, {});

  const result = {
    symlinks: repeaterSymLinks,
    newItem,
    data: _formData,
  };

  return result;
};

export const removeSymlink = (key, index, formData, presentation) => {
  let symlinks = getAllSymlinks(formData); // [ {id, index, path, from, to} ]
  let repeater = get(formData, key, []); // [ { categories: path, symlinks: [] }]
  let repeaterItem = get(repeater, index, {}); // { categories: path, symlinks: [] }
  let currentSymlinks = getSymlinks(repeaterItem); // [ categories ]
  let _formData = Object.assign({}, formData); // { call: [], categories: [], ...etc }
  const hasNestedSymlinks = some(repeater, hasSymlinks);

  if (hasNestedSymlinks) {
    _formData = currentSymlinks.reduce((_output, symlinkKey) => {
      const repeaterName = symlinkKey; // categories
      const symlinkedRepeater = get(_output, repeaterName, []); // { path, ...rest }
      const { path, id } = getSymlinkInfo(
        {
          [symlinkPathPropertyName]: get(repeaterItem, symlinkKey),
        },
        symlinks
      ); // { from, to, path, id, index }

      return {
        ..._output,
        [symlinksPropName]: symlinks.filter(
          symlink => symlink[symlinkIdPropertyName] !== id
        ),
        [repeaterName]: symlinkedRepeater.filter(
          item => item[symlinkPathPropertyName] !== path
        ),
      };
    }, _formData);

    _formData[symlinksPropName] = _formData[symlinksPropName].map(item => {
      const {
        [symlinkFromPropertyName]: from,
        [symlinkToPropertyName]: to,
        [symlinkPathPropertyName]: path,
        [symlinkIdPropertyName]: id,
      } = item;
      const repeaterFromData = get(_formData, from, []).filter(
        (_, itemIndex) => itemIndex !== index
      );
      const _index = findIndex(
        repeaterFromData,
        _repeaterItem => _repeaterItem[to] === path
      );
      clearSymlinksCache();
      updateSymlinkCacheFor(from, to, _index, id);
      return Object.assign({}, item, { [symlinkIndexPropertyName]: _index });
    });
  }

  // remove requested item form its repeater
  _formData[key] = repeater.filter((_, itemIndex) => itemIndex !== index);

  return _formData;
};

export default ({
  fields,
  property,
  key,
  index,
  defaultValue,
  properties,
  loopFromEnumRef,
  fromSourceValue,
  parent,
  parentKey,
  data,
  getPresentation,
  withProfileData,
}) => {
  // this method is to create the nested repeater and index has
  // the position in the properties array from main field structure
  // in the json, however fields.fields only have repeater data and
  // the idea is to create the first (0) repeater element
  const _INDEX = 0;
  const repeaterParent = fields['fields'][_INDEX];
  const repeaterName = property.key;
  const repeaterSymLinksInData = get(data, symlinksPropName, []);
  let repeaterSymLinks = get(fields, `data.${symlinksPropName}`);
  // get(data, symlinksPropName) || get(fields, `data.${symlinksPropName}`);

  // Create symlink property in data if it does not exist
  if (!repeaterSymLinks) {
    fields['data'][symlinksPropName] = [].concat(repeaterSymLinksInData);
    repeaterSymLinks = fields['data'][symlinksPropName];
  }

  const symlinkId = getRepeaterSymlink(
    parentKey,
    repeaterName,
    _INDEX,
    repeaterSymLinksInData
  );
  const symlinkPath = `symlink$${property.key}$${symlinkId}`;

  const symlink = {
    [symlinkToPropertyName]: repeaterName,
    [symlinkFromPropertyName]: parentKey,
    [symlinkIndexPropertyName]: _INDEX,
    [symlinkPathPropertyName]: symlinkPath,
    [symlinkIdPropertyName]: symlinkId,
  };

  // Ensure that the symlinked repeater (nested) exists in root data
  const existentSymlinkRepeater = repeaterParent[repeaterName];
  if (!existentSymlinkRepeater) {
    repeaterParent[repeaterName] = defaultValue || [
      {
        id: uuid(),
        [symlinkPathPropertyName]: symlinkPath,
      },
    ];
  }

  if (loopFromEnumRef) {
    const repeaterData = get(data, key, []);
    parent[key] = fromSourceValue.map((item, _index) => {
      const itemData = get(repeaterData, _index, {});

      return {
        ...itemData,
        ...item,
        id: itemData.id || uuid(),
        [symlinkPathPropertyName]:
          itemData[symlinkPathPropertyName] || symlinkPath,
      };
    });
  }

  // Custom check for repeaters firts item
  if (repeaterParent[repeaterName].length) {
    each(
      properties,
      getPresentation({
        parent: repeaterParent[repeaterName][0],
        parentKey: property.key,
        getPresentation,
        withProfileData,
      })
    );
  }

  if (get(first(repeaterParent[repeaterName]), 'id') === undefined) {
    set(first(repeaterParent[repeaterName]), 'id', uuid());
  }

  if (
    get(first(repeaterParent[repeaterName]), symlinkPathPropertyName) ===
    undefined
  ) {
    set(
      first(repeaterParent[repeaterName]),
      symlinkPathPropertyName,
      symlinkPath
    );
  }

  const synlinkIndex = findIndex(
    repeaterSymLinks,
    item => item[symlinkIdPropertyName] === symlinkId
  );
  if (synlinkIndex === -1) {
    repeaterSymLinks.push(symlink);
  }

  parent[key] = symlinkPath;

  if (!parent[symlinksPropertiesPropName]) {
    parent[symlinksPropertiesPropName] = [];
  }

  const symlinkPropsFromData = get(
    data,
    `${parentKey}.${_INDEX}.${symlinksPropertiesPropName}`,
    []
  );
  const symlinkPropsFromParent = [].concat(
    get(parent, symlinksPropertiesPropName, [])
  );

  parent[symlinksPropertiesPropName] = []
    .concat(symlinkPropsFromParent)
    .concat(symlinkPropsFromData)
    .filter(prop => prop !== key)
    .concat(key);
};
