import styled from 'styled-components';

import PageLoaderComponent from './PageLoader';

const PageLoader = styled(PageLoaderComponent)`
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100%;
  background-color: #ccc;
  transition: height 0.2s ease, width 0.2s ease;
  transition-delay: 1s;
  overflow: hidden;
  z-index: 999;

  .progress-wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    width: 100vw;
    min-height: 4px;
  }

  &.is-loading {
    height: 4px;
    transition-delay: 0s;
  }

  &.is-intermitent {
    transition: height 0.2s ease;
    pointer-events: none;
    z-index: inherit;

    &.is-loading {
      height: 2px;
      transition-delay: 0s;
    }
  }
`;

export default PageLoader;
