import _ from 'lodash';
import { getAuditLogs } from '../../api/forms/auditLogsEndpoints';

export const FORMS_GET_REPORT_TIMELINE_LOGS_REQUEST =
  'FORMS_GET_REPORT_TIMELINE_LOGS_REQUEST';
export const FORMS_GET_REPORT_TIMELINE_LOGS_SUCCESS =
  'FORMS_GET_REPORT_TIMELINE_LOGS_SUCCESS';
export const FORMS_GET_REPORT_TIMELINE_LOGS_ERROR =
  'FORMS_GET_REPORT_TIMELINE_LOGS_ERROR';
export const FORMS_CLEAN_REPORT_TIMELINE_LOGS =
  'FORMS_CLEAN_REPORT_TIMELINE_LOGS';

export const formsGetReportTimelineLogsSuccess = response => {
  return { type: FORMS_GET_REPORT_TIMELINE_LOGS_SUCCESS, payload: response };
};

export const formsGetReportTimelineLogsError = error => {
  return { type: FORMS_GET_REPORT_TIMELINE_LOGS_ERROR, error };
};

export const formCleanReportTimelineLogs = () => {
  return dispatch => {
    dispatch({ type: FORMS_CLEAN_REPORT_TIMELINE_LOGS });
  };
};

export default (
  tenantId = '',
  executedBy = '',
  rangeDate = {},
  pageData = {},
  eventTypeConstant = ''
) => {
  return dispatch => {
    dispatch({
      type: FORMS_GET_REPORT_TIMELINE_LOGS_REQUEST,
      payload: {
        tenantId,
        userId: executedBy,
        initialDate: rangeDate.initialDate,
        endDate: rangeDate.endDate,
        pageNumber: pageData.pageNumber,
        pageSize: pageData.pageSize,
        eventType: eventTypeConstant,
      },
    });

    return getAuditLogs(tenantId, {
      userId: executedBy,
      initialDate: rangeDate.initialDate,
      endDate: rangeDate.endDate,
      pageNumber: pageData.pageNumber,
      pageSize: pageData.pageSize,
      eventType: eventTypeConstant,
    })
      .then(response => {
        dispatch(formsGetReportTimelineLogsSuccess(response));
      })
      .catch(error => {
        dispatch(
          formsGetReportTimelineLogsError(_.get(error, ['response', 'status']))
        );
      });
  };
};
