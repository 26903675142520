import React from 'react';
import { Route } from 'react-router';

import Protected from '../../containers/protected';
import EmbedReportUOF from '../../containers/izenda/report-uof';
import EmbediZendaDesigner from '../../containers/izenda/designer';
import EmbediZendaViewer from '../../containers/izenda/viewer';
import EmbediZendaSettings from '../../containers/izenda/settings';
import EmbediZendaReportPartViewer from '../../containers/izenda/report-part';
import EmbediZendaSingleReportViewer from '../../containers/izenda/single-report-viewer';
import EmbediZendaDashboard from '../../containers/izenda/dashboard';
import EmbediZendaSingleDashboardViewer from '../../containers/izenda/single-dashboard-viewer';
import { PERMISSIONS } from '../../utils/admin';

const izendaRoutes = [
  <Route
    path="/viewer/reportpart/:reportPartId"
    exact
    name="izendaReportViewer"
    key="izendaReportViewer"
    render={Protected(EmbediZendaViewer, ['everybody'])}
  />,
  <Route
    path="/dashboard"
    exact
    name="izendaDashboardViewer"
    key="izendaDashboardViewer"
    component={Protected(EmbediZendaDashboard, ['everybody'])}
  />,
  <Route
    path="/dashboard/edit/:dashboardId"
    exact
    name="izendaDashboardViewerSingle"
    key="izendaDashboardViewerSingle"
    render={Protected(EmbediZendaSingleDashboardViewer, ['everybody'])}
  />,
  <Route
    path="/reports/uof-report/:reportId"
    exact
    name="izendaReportsUOF"
    key="izendaReportsUOF"
    render={Protected(EmbedReportUOF, ['everybody'])}
  />,
  <Route
    path="/reports/uof-report"
    exact
    name="izendaReportsUOF"
    key="izendaReportsUOF"
    render={Protected(EmbedReportUOF, ['everybody'])}
  />,
  <Route
    path="/canned-reports/view/:reportId"
    exact
    name="izendaReportViewerSingle"
    key="izendaReportViewerSingle"
    render={Protected(EmbediZendaSingleReportViewer, [
      PERMISSIONS.accessAnalyticsReports,
    ])}
  />,
  <Route
    path="/report-designer"
    exact
    name="izendaReportDesigner"
    key="izendaReportDesigner"
    render={Protected(EmbediZendaDesigner, [
      'accessAnalyticsDesigner',
      PERMISSIONS.accessAnalyticsDesign,
    ])}
  />,
  <Route
    path="/canned-reports"
    exact
    name="izendaReportViewer"
    key="izendaReportViewer"
    render={Protected(EmbediZendaViewer, [PERMISSIONS.accessAnalyticsReports])}
  />,
  <Route
    path="/viewer/reportpart/:reportPartId"
    exact
    name="izendaReportPartViewer"
    key="izendaReportPartViewer"
    render={Protected(EmbediZendaReportPartViewer, ['everybody'])}
  />,
  <Route
    path="/admin-reporting"
    exact
    name="izendaReportSettings"
    key="izendaReportSettings"
    render={Protected(EmbediZendaSettings, [PERMISSIONS.accessAnalyticsAdmin])}
  />,
];

export default izendaRoutes;
