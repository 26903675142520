export default {
  data: {},
  meta: {},
  actions: [],
  template: null,
  fetched: false,
  state: {},
  validations: {
    form: {
      touched: false,
      dirty: false,
      submitRequested: false,
      submitting: false,
    },
    sections: {},
    tabsWithErrors: [],
  },
  presentation: null,
  tab: 0,
  enums: {},
  sources: {},
  events: {
    beforeCreate: null,
    afterCreate: null,
    beforeSubmit: null,
    afterSubmit: null,
  },
  resolutionLabel: '',
  isActive: true,
};
