import { createSelector } from 'reselect';

export const getInternalAffairCaseLoad = createSelector(
  state => state.internalAffairCaseLoad,
  internalAffairCaseLoad => internalAffairCaseLoad
);

export const getInternalAffairCaseLoadEntities = createSelector(
  getInternalAffairCaseLoad,
  internalAffairCaseLoad => internalAffairCaseLoad.entities
);

export const getInternalAffairCaseLoadPerPage = createSelector(
  getInternalAffairCaseLoad,
  internalAffairCaseLoad => internalAffairCaseLoad.perPage
);

export const getInternalAffairCaseLoadPage = createSelector(
  getInternalAffairCaseLoad,
  internalAffairCaseLoad => internalAffairCaseLoad.page
);

export const getInternalAffairCaseLoadTotal = createSelector(
  getInternalAffairCaseLoad,
  agencyCaseLoad => agencyCaseLoad.total
);

export const getInternalAffairCaseLoadReasons = createSelector(
  getInternalAffairCaseLoad,
  internalAffairCaseLoad => internalAffairCaseLoad.agencyReasons
);

export const getInternalAffairCaseLoadReasonsData = createSelector(
  getInternalAffairCaseLoadReasons,
  getInternalAffairCaseLoadReasons => getInternalAffairCaseLoadReasons.data
);

export const getInternalAffairCaseLoadPending = createSelector(
  getInternalAffairCaseLoad,
  internalAffairCaseLoad => internalAffairCaseLoad.pendingToggle
);

export const getInternalAffairCaseLoadIsLoading = createSelector(
  getInternalAffairCaseLoad,
  internalAffairCaseLoad => internalAffairCaseLoad.loading
);
