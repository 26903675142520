import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';
import moment from 'moment';
import { BENCHMARK_DATE_FORMAT } from '../../../../utils/parse-date';
import { StyledAlert } from './AlertOverdueBanner.styled';
import { OPEN_STATUS } from '../../../administrator/agency-casefiles/constants';

const AlertOverdueBanner = ({ data, statuses }) => {
  const [showBanner, setShowBanner] = useState(false);
  const [message, setMessage] = useState('');

  const warningIconRed = (
    <Icon type="exclamation-circle" theme="filled" style={{ color: 'red' }} />
  );

  useEffect(() => {
    if ((!statuses || statuses.length === 0) && !data) {
      return;
    }
    const { status: statusID, deadline } = data;
    const statusType = statuses.find(element => element.id === statusID);

    if (typeof statusType !== 'object') {
      return;
    }

    const { type } = statusType;
    const deadlineConverted = moment(
      moment
        .utc(deadline)
        .startOf('day')
        .format(BENCHMARK_DATE_FORMAT)
    );

    const isDeadlineToday =
      moment(0, 'HH').diff(deadlineConverted, 'days') == 0;
    const isDeadlineOverdue = deadlineConverted.isBefore();

    const newShowBanner =
      type === OPEN_STATUS && (isDeadlineOverdue || isDeadlineToday);
    let newMessage = isDeadlineToday
      ? 'This Case File is due today!'
      : isDeadlineOverdue
      ? 'This Case File is overdue!'
      : '';

    setShowBanner(newShowBanner);
    setMessage(newMessage);
  }, [data, statuses]);

  return (
    <StyledAlert
      showIcon
      message={message}
      type="error"
      icon={warningIconRed}
      style={{ display: `${showBanner ? 'block' : 'none'}` }}
    />
  );
};

export default AlertOverdueBanner;
