import React from 'react';
import { Tooltip } from 'antd';
import WrapperInput from './EllipsisInput.styled';

const EllipsisInput = props => {
  const { value, ellipsislength, readOnly } = props;
  let showEllipsis = false;
  if (Array.isArray(value)) {
    if (value.length > 0) {
      showEllipsis = value[0].length > ellipsislength && readOnly;
    }
  } else if (typeof value === 'string' || value instanceof String) {
    showEllipsis = value.length > ellipsislength && readOnly;
  }

  return showEllipsis ? (
    <Tooltip placement="topLeft" title={value}>
      <WrapperInput {...props} ellipsis={showEllipsis} />
    </Tooltip>
  ) : (
    <WrapperInput {...props} ellipsis={showEllipsis} />
  );
};

export default EllipsisInput;
