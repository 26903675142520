const getEnumOptions = props => {
  const { dataEnums, enumRef = '', parentEnum } = props;

  return {
    dataEnums,
    enumRef,
    parentEnum,
  };
};

export default getEnumOptions;
