import styled from 'styled-components';

import { ACCENT } from '../../../../config/theme';

const FieldEditorWrapper = styled.div.attrs({
  className: 'field-editor',
})`
  margin-bottom: 5px;
  border-radius: 0;
  box-shadow: 0 0 0 transparent;
  transition: all 0.2s;
  padding-top: 5px;

  &.unsupported {
    background-color: #e8e8e8;
    border-radius: 5px;

    .disabled {
      pointer-events: none;
      color: darkgray;

      .icon-btn i {
        color: darkgray;
      }

      .ant-tag {
        background: #d8d8d8;

        &,
        .ant-tag a,
        .ant-tag a:hover {
          color: rgba(102, 102, 102, 0.65);
        }
      }
    }
  }

  .field-header {
    border: 1px solid lightgray;
    border-bottom: none;

    &:hover {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
    }

    .ant-row {
      margin-bottom: 0;
    }

    .field-status {
      display: inline-block;
      float: right;
      margin-top: 5px;
    }
  }

  .field-detail {
    border: 1px solid lightgray;
    border-top: none;
    height: 50px;
    display: flex;
  }

  .field-header:hover,
  .field-header:hover + .field-detail {
    border-color: blue;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid lightgray;
    margin: 0;
    padding: 0;
  }

  .field-editor__btns {
    display: inline-block;
  }

  .show-reviewer {
    background-color: hsla(180, 100%, 25%, 0.1);
  }

  .hide-reviewer {
    background-color: hsla(0, 100%, 50%, 0.1);
  }

  .field-editor__input {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1em;

    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .field-editor__type {
      display: none;
    }

    .edit-btn {
      display: block;
      background: transparent;
      border: none;
      padding: 2px 10px;
      cursor: pointer;
      outline: none;
      width: 100%;
      text-align: left;
      border-radius: 0;

      &:hover,
      &:focus {
        background-color: #e6f2fb;
        color: #476275;
      }

      &:focus,
      &:active {
        box-shadow: 0 0 0 2px #3772ff;
      }
    }
  }
  /* stylelint-disable */
  .field-editor__type {
    padding: 0 5px 0 5px;
    font-size: 0.8em;
    text-transform: uppercase;
    font-weight: 600;
  }

  .field-editor__actions {
    display: inline-block;
    .ant-btn {
      font-size: 12px;
    }
  }

  &.is-dragging {
    opacity: 0.3;
  }

  &.is-compact {
    border: 1px solid #e6f2fa;
    border-radius: 5px;

    .field-editor__input {
      position: relative;
      z-index: 1;

      .edit-btn {
        border-radius: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: ${ACCENT};
        padding: 5px;

        &,
        &:hover,
        &:focus {
          background-color: transparent;
        }

        &:hover,
        &:focus {
          padding-right: 30px;
        }
      }
    }

    .field-status {
      margin-top: 0;

      .ant-tag {
        font-size: 10px;
        padding: 3px;
        line-height: 1;
      }
    }

    &:hover {
      .edit-btn {
        padding-right: 30px;
      }
    }

    > .ant-row-flex {
      flex: none;
      display: block;
    }

    &,
    > .ant-row-flex,
    [class*='ant-row'] {
      margin-bottom: 0;
    }

    .field-editor__actions {
      .ant-btn {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export default FieldEditorWrapper;
