import {
  STATUS_CLOSED,
  STATUS_OPEN,
  STATUS_OVERDUE,
  STATUS_PAUSED,
  STATUS_WITHOUT,
} from './caseFileList';

export const CARD_THEMES = {
  [STATUS_OPEN]: `case-file-status-card-${STATUS_OPEN}`,
  [STATUS_PAUSED]: `case-file-status-card-${STATUS_PAUSED}`,
  [STATUS_OVERDUE]: `case-file-status-card-${STATUS_OVERDUE}`,
  [STATUS_CLOSED]: `case-file-status-card-${STATUS_CLOSED}`,
  [STATUS_WITHOUT]: `case-file-status-card-${STATUS_WITHOUT}`,
};
