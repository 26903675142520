import { isPlainObject } from 'lodash';

import { mapTitle } from 'APP_ROOT/utils/conditionals';
import templateString from 'APP_ROOT/utils/template-string';
import getLabel from './getLabel';
import getGroupData from './getGroupData';

const getInterpolatedLabel = (props, render) => {
  const { data = {}, parentIndex = 0 } = props;

  const label = getLabel(props);
  const groupData = getGroupData(props);
  const text = label
    ? isPlainObject(label)
      ? mapTitle(label, parentIndex, data, groupData)
      : templateString(label, { index: parentIndex + 1 })
    : '';
  const renderWith =
    render && typeof render === 'function' ? render : () => text;

  return renderWith(text);
};

export default getInterpolatedLabel;
