import React, { Component } from 'react';

// Containers
import DashboardPage from '../../components/dashboard';
import withoutClutter from '../../components/without-clutter';

import InsertEmbed from '../../components/izenda-embed/InsertEmbed';
import withFlexibleContainer from '../../components/izenda-embed/WithFlexibleLayout';

class EmbedReport extends Component {
  componentWillUnmount() {
    if (this.izenda) {
      this.izenda.destroy();
    }
  }

  loadDesigner = (izenda, target) => {
    const reportId = 'ea99fd83-4031-4380-9904-81cf8705cb2e';
    izenda.renderReportViewerPage(target, reportId);
  };

  render() {
    return <InsertEmbed onReady={this.loadDesigner} />;
  }
}

const mapState = () => ({});

export default withoutClutter(
  DashboardPage(mapState, null)(withFlexibleContainer(EmbedReport))
);
