import _ from 'lodash';

import { FETCH_ACTIVITY_FEED_REQUEST } from './';
import { fetchNotifications, getNotificationsCount } from '../api/activity';

import getFeedSuccess from './get-feed-success';
import getFeedError from './get-feed-error';

const initialFilters = {};

const getCount = (get_count = false, count = 0, options) =>
  get_count ? getNotificationsCount(options) : Promise.resolve({ count });

export default (
  page,
  filters = initialFilters,
  get_count = false,
  next = () => {}
) => (dispatch, getState) => {
  const {
    session: { currentUser: { userId } = {} } = {},
    activity: {
      activityFeed: { filter: filters = {}, per_page, total: count } = {},
    } = {},
  } = getState();

  const filter = {
    ...(filters || initialFilters),
    limit: per_page,
    skip: (page - 1) * per_page,
  };

  dispatch({ type: FETCH_ACTIVITY_FEED_REQUEST, payload: { filter, page } });

  return new Promise((resolve, reject) => {
    if (userId) {
      Promise.all([
        fetchNotifications(userId, filter),
        getCount(get_count, count, { userId, filter: filter.where }),
      ])
        .then(([jsonResponse, count]) => {
          dispatch(getFeedSuccess(jsonResponse, count));
          next(jsonResponse);
          resolve(jsonResponse);
        })
        .catch(error => {
          dispatch(getFeedError(_.get(error, ['response', 'status'], null)));
          next(error);
          reject(_.get(error, ['response', 'status'], null));
        });
    } else {
      reject();
    }
  });
};
