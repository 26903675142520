import React from 'react';
import { Drawer, List, Tag, Divider, Button } from 'antd';
import { isEmpty } from 'lodash';

import { MATH } from '../../../../constants/fieldTypes';

const renderFooter = ({ disabled, deleteAffectedFields, onCancel }) => {
  return (
    <div className="wrapper-margin-bottom">
      <Button
        type="danger"
        size="default"
        icon="delete"
        disabled={disabled}
        onClick={deleteAffectedFields}
        className="button-margin-top button-margin-right"
      >
        Yes, continue
      </Button>
      <Button
        type="default"
        size="default"
        icon="close"
        onClick={onCancel}
        className="button-margin-top"
      >
        Cancel
      </Button>
    </div>
  );
};

const renderFieldList = (fieldList = [], title, tagColor) => {
  return (
    <List size="small" bordered header={title}>
      {fieldList.map(({ id, title, reportingKey }) => {
        return (
          <List.Item key={id}>
            <Tag color={tagColor}>{title || reportingKey}</Tag>
          </List.Item>
        );
      })}
    </List>
  );
};

const OptionsModalConfirm = ({
  placement,
  showConfirmation,
  affectedFields,
  title,
  width,
  disabled,
  deleteAffectedFields,
  onClose,
  onCancel,
}) => {
  const { fieldsToRemove = [], fieldsToUpdate = [] } = affectedFields;
  const [field = {}] = fieldsToUpdate;
  const updateTitle =
    field.field_type === MATH
      ? 'This field will be removed as an operand from the math field(s) listed below.'
      : 'The fields listed below will be updated.';

  return (
    <Drawer
      title={title}
      placement={placement}
      closable={true}
      onClose={onClose}
      visible={showConfirmation}
      maskClosable={true}
      width={width}
    >
      {!isEmpty(fieldsToRemove) &&
        renderFieldList(
          fieldsToRemove,
          'The fields listed below will be removed.',
          'magenta'
        )}

      <Divider />
      {!isEmpty(fieldsToUpdate) &&
        renderFieldList(fieldsToUpdate, updateTitle, 'gold')}
      {renderFooter({ disabled, deleteAffectedFields, onCancel })}
    </Drawer>
  );
};

export default OptionsModalConfirm;
