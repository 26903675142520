import React from 'react';
import { Row, Button } from 'antd';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import ContentLoader from 'react-content-loader';

import withoutClutter from 'APP_COMPONENTS/without-clutter';
import DashboardPage from 'APP_COMPONENTS/dashboard';
import PageHeader from 'APP_COMPONENTS/PageHeader';

import AdministratorWrapper from '../Administrator.styled';
import ButtonWrapper from '../agency-profile/AgencyMenuButton.styled';

import { getTemplates } from '../configure-workflows/ConfigureWorkflows.selectors';
import { getTemplatesConfiguration } from 'APP_ROOT/selectors/session';
import getAgencyTemplates from '../configure-workflows/actions/get-agency-templates';
import withOwnershipGuard from '../components/agency-ownership-guard';

import { filterTemplateSelfService } from '../utils/filterTemplates';

import getRoute from 'APP_ROOT/utils/get-route';

export class Component extends React.Component {
  componentDidMount() {
    const { dispatch, agencyId, templatesConfig } = this.props;

    dispatch(getAgencyTemplates(agencyId, templatesConfig));
  }

  templatesLoader = () => (
    <div className="templates-list-loader">
      {Array(4)
        .fill(0)
        .map((_i, index) => (
          <div
            className="template-loader-wrapper"
            key={index}
            style={{ opacity: 1 - index * 0.2 }}
          >
            <ContentLoader
              height={25}
              width={280}
              speed={1}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="5" ry="5" width="25" height="25" />
              <rect x="45" y="0" rx="5" ry="5" width="235" height="25" />
            </ContentLoader>
          </div>
        ))}
    </div>
  );

  templateRow = template => {
    const { agencyTemplates, agencyId } = this.props;

    return (
      <ButtonWrapper span={24} className="btn-wrapper" key={template.id}>
        <Link
          to={getRoute('administratorAgencyTemplate', {
            agencyId,
            templateId: template.id,
          })}
          disabled={agencyTemplates.loading}
        >
          <Button disabled={agencyTemplates.loading}>
            <div className={`btn__text`}>
              <div
                className={`btn__icon bdm-icon ${get(
                  template,
                  'config.class',
                  ''
                )}`}
              />{' '}
              <span>{template.label}</span>
            </div>
          </Button>
        </Link>
      </ButtonWrapper>
    );
  };

  render() {
    const { agencyTemplates, agencyId } = this.props;
    const enties = get(agencyTemplates, 'entries', {});
    const templates = filterTemplateSelfService(enties);

    return (
      <AdministratorWrapper>
        <PageHeader
          title="Data Fields"
          goBackTo={getRoute('administratorAgency', { agencyId })}
          loading={agencyTemplates.loading}
        />
        <div className="administrator-content">
          <Row gutter={60} className="admin-menu templates-list">
            {agencyTemplates.loading &&
              React.createElement(this.templatesLoader)}

            {!agencyTemplates.loading &&
              templates.length > 0 &&
              templates.map(this.templateRow)}
          </Row>
        </div>
      </AdministratorWrapper>
    );
  }
}

const mapStateToProps = (state, props) => ({
  agencyTemplates: getTemplates(state),
  agencyId: get(props, 'match.params.agencyId'),
  templatesConfig: getTemplatesConfiguration(state),
});

export default withoutClutter(
  DashboardPage(mapStateToProps)(
    withOwnershipGuard(Component, 'administratorAgencyTemplates')
  )
);
