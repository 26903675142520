/**
 * Import the child routes
 */
import agencyRoutes from './agencies/Agencies.routes';
import Administrator from './Administrator';

/**
 * This route config in a const so that you can pass it to children routes in a handy way
 */
const parentRouteConfig = {
  path: '/administrator',
  name: 'administrator',
};

export default [
  {
    ...parentRouteConfig,
    protectedWith: [],
    component: Administrator,
    childRoutes: [...agencyRoutes(parentRouteConfig)],
  },
];
