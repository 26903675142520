import _, { omit } from 'lodash';
import { getFormPresentation, getFormFields } from '../utils/form';
import { clearSymlinksCache } from '../utils/get-repeater-symlink';
import getRequirementCompliantFormFields from '../utils/get-requirement-compliant-fields';
import { transformValidations } from '../utils/validations';
import { getAgencyTZ } from '../selectors/session';
import { GET_FORM_PRESENTATION, SYNC_TAB_NUMBER } from './';

export default (
  {
    schema: formSchema = {},
    type: formType,
    validations = {},
    enums = {},
    formData = {},
    actions,
    overrides,
    withProfileData = false,
  },
  next = () => {}
) => async (dispatch, getState) => {
  const state = getState();
  const { session: { currentUser = {} } = {} } = state;
  const timezone = getAgencyTZ(state);
  const isPending = _.some(actions, action => action.status === 'pending');
  const formDataFilled = isPending
    ? _.get(
        actions.filter(action => action.status === 'pending'),
        [0, 'data'],
        formData
      )
    : formData;

  const dataWithCurrentUser = {
    ...formDataFilled,
    currentUserId: _.get(formDataFilled, 'currentUserId', '') + [],
    submitterId: _.get(formDataFilled, 'submitterId', currentUser.userId) + [],
    __users: {
      [currentUser.userId]: {
        ...omit(currentUser, 'agency'),
        id: currentUser.id + [],
      },
      ..._.get(formDataFilled, '__users', {}),
    },
  };

  clearSymlinksCache();
  const schema = getFormPresentation({
    schema: formSchema,
    data: dataWithCurrentUser,
    overrides,
    enums,
    withProfileData,
  });
  const validationRules = await transformValidations(
    validations,
    enums,
    timezone
  );
  const formFields = await getFormFields(formSchema, validationRules);
  const requirementCompliantFields = await getRequirementCompliantFormFields(
    formSchema,
    validationRules
  );
  const tabsLength = schema.fields.length;
  const type = _.kebabCase(formType);

  dispatch({ type: SYNC_TAB_NUMBER, payload: tabsLength });
  dispatch({
    type: GET_FORM_PRESENTATION,
    payload: {
      schema,
      type,
      formFields,
      requirementCompliantFields,
      formData: Object.assign({}, dataWithCurrentUser, schema.data || {}),
    },
  });
  next && next();
};
