import React from 'react';
import UserEquipmentSection from '../UserProfile/UserEquipmentSection';
import UserTrainingSection from '../UserProfile/UserTrainingSection';
import UserProfileCustomSections from '../UserProfile/UserCustomSections';
import UserReportSection from '../UserProfile/UserReportSection';
import UserDocumentSection from '../UserProfile/UserDocumentSection';

import { ADD_USER } from '../AgencyUsers.constants';

const UserSections = ({
  action,
  userProfile,
  session,
  tenantId,
  userIntegrationId,
  dispatch,
  profileForm,
  timezone,
  sectionsDefinition,
  editUserSection,
  canViewDocuments,
  canManageDocuments,
  canViewReportsWithMention,
  history,
  currentUser,
  updateUserSectionField,
  addUserSectionFieldValue,
  removeUserSectionFieldValue,
  setEditUserSectionTable,
}) => (
  <>
    {ADD_USER !== action && (
      <>
        <UserProfileCustomSections
          {...{
            profileForm,
            timezone,
            sectionsDefinition,
            editUserSection,
            updateUserSectionField,
            addUserSectionFieldValue,
            removeUserSectionFieldValue,
            setEditUserSectionTable,
            session,
          }}
        />
        <UserDocumentSection
          {...{
            profileForm,
            timezone,
            editUserSection,
            updateUserSectionField,
            addUserSectionFieldValue,
            removeUserSectionFieldValue,
            setEditUserSectionTable,
            userProfile,
            canViewDocuments,
            canManageDocuments,
          }}
        />
        <UserReportSection
          {...{
            profileForm,
            timezone,
            editUserSection,
            updateUserSectionField,
            addUserSectionFieldValue,
            removeUserSectionFieldValue,
            setEditUserSectionTable,
            userProfile,
            canViewReportsWithMention,
            history,
            currentUser,
          }}
        />
        <UserEquipmentSection
          {...{ dispatch, userId: userIntegrationId, tenantId, session }}
        />
        <UserTrainingSection
          {...{
            dispatch,
            userId: userIntegrationId,
            tenantId,
            userProfile,
            session,
          }}
        />
      </>
    )}
  </>
);

export default UserSections;
