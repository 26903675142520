import styled from 'styled-components';

import { DIVIDER } from '../../../../config/theme';

export const Divider = styled.div.attrs({
  className: 'bdm-panel-divider',
})`
  border-bottom: 1px solid ${DIVIDER};
`;

export default Divider;
