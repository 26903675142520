import { get } from 'lodash';

import { POST_WEBSITELOG_REQUEST } from './';
import postWebsiteLogsSuccess from './post-website-logs-success';
import postWebsiteLogsError from './post-website-logs-error';
import { postWebsiteLogs as postWebsiteLogsApi } from '../api/websiteLogs';

const postWebsiteLogs = data => {
  return async dispatch => {
    dispatch({
      type: POST_WEBSITELOG_REQUEST,
      payload: { data },
    });
    try {
      const response = await postWebsiteLogsApi(data);
      dispatch(postWebsiteLogsSuccess(response));
    } catch (e) {
      dispatch(postWebsiteLogsError(get(e, ['response', 'status'])));
    }
  };
};

export default postWebsiteLogs;
