import get from 'lodash/get';

import {
  GET_ALL_AGENCIES_REQUEST,
  GET_ALL_AGENCIES_FULLFILLED,
  GET_ALL_AGENCIES_REJECTED,
} from '../Agencies.actionTypes';

import { getAllAgencies, getAgenciesCount } from 'APP_ROOT/api/manageUsers';

export default (page = 1, get_count = false, filters = {}, next = () => {}) => {
  return (dispatch, getState) => {
    const { agencies = {} } = getState();

    const { per_page = 10 } = agencies;

    dispatch({
      type: GET_ALL_AGENCIES_REQUEST,
      payload: { filters, page, get_count },
    });

    const limit = filters.per_page || per_page || 10;

    const filter = {
      ...(filters || {}),
      counts: 'users',
      limit,
      skip: (page > 0 ? page - 1 : page) * limit,
      order: 'name ASC',
    };

    const { where } = filters;

    return new Promise((resolve, reject) => {
      Promise.all([getAllAgencies(filter), getAgenciesCount(where)])
        .then(([data, total]) => {
          dispatch({
            type: GET_ALL_AGENCIES_FULLFILLED,
            payload: { data, count: total },
          });
          next(data);
          resolve(data);
        })
        .catch(error => {
          dispatch({
            type: GET_ALL_AGENCIES_REJECTED,
            payload: { error: get(error, ['response', 'status']) },
          });
          next(error);
          reject(error);
        });
    });
  };
};
