import React, { Component } from 'react';
import { Row, Col, Icon } from 'antd';
import cx from 'classnames';
import actionsLoader from 'APP_COMPONENTS/PageHeader/actionsLoader';
import PageHeaderWrapper from 'APP_COMPONENTS/PageHeader/PageHeader.styled';
import getRoute from 'APP_ROOT/utils/get-route';
import { get } from 'lodash';

class PageHeader extends Component {
  goBack = () => {
    const { history } = this.props;
    const previousPath = get(history, 'location.state.previousPath');
    const search = get(history, 'location.state.search', '');
    const path = previousPath ? previousPath + search : getRoute('dashboard');

    // history.goBack();
    // using push to avoid a refresh problem on user profile
    // when the user leaves the page without saving, yes it's a hack
    // ¯\_(ツ)_/¯
    history.push(path);
  };

  render() {
    const {
      title = '',
      showTitle = true,
      showBackButton = true,
      actions = [],
      children = [],
      alert = null,
      saveRef,
      stickToTop = false,
      sidebarIsCollapsed = false,
      isLoading,
    } = this.props;
    return (
      <PageHeaderWrapper
        innerRef={saveRef}
        className={cx({
          'is-fixed': stickToTop,
          'sidebar-is-collapsed': sidebarIsCollapsed,
        })}
      >
        <div className="page-header-content">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            sx={{ flexGrow: 1 }}
            className="header-info"
          >
            <Col className="header-title">
              {showBackButton && (
                <a
                  href="#"
                  className="back-button"
                  onClick={() => this.goBack()}
                >
                  <Icon type={isLoading ? 'loading' : 'left'} />
                </a>
              )}
              {showTitle && <h1 className="header-title__text">{title}</h1>}
            </Col>
            <Col className="header-actions">
              {isLoading ? React.createElement(actionsLoader) : actions}
            </Col>
          </Row>
          <Row type="flex" justify="center" className="header-content">
            {children}
          </Row>
        </div>
        {alert && <div className="page-header-alert">{alert}</div>}
      </PageHeaderWrapper>
    );
  }
}
export default PageHeader;
