const getTypeFilter = (value = '', formTypes) => {
  const { title = '' } = formTypes[value] || {};

  if (value) {
    return [
      {
        column: 'formType',
        operator: 'eq',
        value: title,
        type: value,
      },
    ];
  }
  return [];
};

export default getTypeFilter;
