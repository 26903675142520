import _ from 'lodash';

import { POST_REPORT_REQUEST } from './';
import { postReport } from '../api/reports';
import postReportSuccess from './post-report-success';
import postReportError from './post-report-error';
import resetOnBehalf from './reset-onBehalf';

export default (
  { agencyId, id, templateId, reviewer, data = undefined, action, notes = {} },
  next = () => {}
) => async (dispatch, getState) => {
  dispatch({ type: POST_REPORT_REQUEST });
  const reviewersList = _.castArray(reviewer).map(item => parseInt(item, 10));

  const {
    session: {
      currentUser: { organizationalUnitId, hierarchyKey },
    },
    form: { __usersOnBehalf },
  } = getState();

  if (data && !data.organizationalUnitId) {
    data = {
      ...data,
      organizationalUnitId: organizationalUnitId,
      hierarchyKey: hierarchyKey,
    };
  }

  try {
    const report = await postReport({
      agencyId,
      id,
      reviewer: reviewer && reviewersList.length ? reviewersList : undefined,
      data: {
        ...data,
        __usersOnBehalf: !_.isEmpty(__usersOnBehalf) ? __usersOnBehalf : {},
      },
      action,
      notes,
      templateId,
    });
    dispatch(postReportSuccess(report));
    dispatch(resetOnBehalf());
    next(false, report);
  } catch (error) {
    dispatch(postReportError(_.get(error, ['response', 'status'])));
    next(error);
  }
};
