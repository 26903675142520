// validates a boolean value to be true
export const booleanValidator = (rule, value, callback) => {
  const validationMessage = rule.message ? rule.message : 'Field is required.';
  if (rule && rule.required && !value) {
    callback(validationMessage);
  } else {
    callback();
  }
};

const booleanRule = (rule = {}) => ({
  type: rule.type,
  required: rule.required || !!rule.mustExist,
  message: rule.message,
  validator: booleanValidator,
});

export default booleanRule;
