import { get, omit } from 'lodash';
import getSectionsValues from './getSectionsValues';
import {
  ADD_USER,
  CREATE_DEFAULT,
  CREATE_FROM_OU,
} from '../../AgencyUsers.constants';
import normalizeProfileData from './getNormalizeProfileData';
import uuid from 'uuid/v4';
import Schema from 'async-validator';
import { shouldBeReadOnlyField } from '../profileFieldsEditable';
import { hasPermissions, PERMISSIONS } from '../../../../../utils/admin';
import updateUser from '../../actions/update-user';
import getUserEmploymentValues from './getUserEmploymentValues';
import getUserDocumentsValues from './getUserDocumentsValues';
import showErrorSubmission from './showErrorSubmission';
import validatorDescriptor from './getValidatorDescriptor';
import createUser from '../../actions/create-user';

const onUserSubmit = (
  values,
  props,
  state,
  saveState = () => {},
  getMessageModal = () => {},
  redirectTo = () => {},
  refreshPage = () => {}
) => {
  const {
    params,
    action,
    agencyProfile: {
      securityRoles,
      data: { integrationId: tenantId },
    },
    profileForm: { registeredFields },
  } = props;
  const { sectionsDefinition } = state;

  const agencyId = get(params, 'agencyId');
  const userId = get(params, 'userId');

  const sectionsData = getSectionsValues(sectionsDefinition, values);
  const employmentHistory = getUserEmploymentValues(values, state);
  const userDocuments = getUserDocumentsValues(values, state);

  const data = {
    agencyId,
    isNewUser: action === ADD_USER,
    ...omit(values, [
      'sectionsData',
      'employmentHistory',
      'userDocumentsData',
      'organizationalUnitHistoryData',
      'userReportsData',
    ]),
  };
  const normalizeData = normalizeProfileData(
    data,
    securityRoles,
    state,
    uuid()
  );

  return new Schema(validatorDescriptor(registeredFields)).validate(
    data,
    (errors, fields) => {
      const { dispatch } = props;
      if (errors && errors.length > 0) {
        return showErrorSubmission(fields, getMessageModal);
      }

      const { profileConfiguration } = state;

      const { params, session = {} } = props;
      const agencyId = get(params, 'agencyId');
      const { currentUser = {} } = session;
      const { permissions = [] } = currentUser;
      const userIntegrationId = get(data, `integrationId`);

      const isRoleFieldEditable = !shouldBeReadOnlyField(
        ['roles'],
        profileConfiguration
      );

      // we should update security only if is and admin user
      const updateSecurity =
        isRoleFieldEditable &&
        hasPermissions(permissions, [
          PERMISSIONS.manageAgencies,
          PERMISSIONS.assignRole,
        ]);

      saveState({ saving: true });
      const newUser = [CREATE_DEFAULT, CREATE_FROM_OU].includes(userId);

      const payload = {
        userId,
        userIntegrationId,
        data: normalizeData,
        agencyId,
        tenantId,
        updateSecurity,
        sectionsData,
        employmentHistory,
        userDocuments,
        showMessageCb: getMessageModal,
        callback: newUser ? redirectTo : refreshPage,
        enableOrDisable: values.enableOrDisable,
      };

      dispatch(newUser ? createUser(payload) : updateUser(payload));
    }
  );
};

export { onUserSubmit };
