import { get, has, isEqual, isPlainObject } from 'lodash';

import {
  SOURCE_TYPE,
  USERS,
  OFFICERS_LABEL,
  OFFICERS_VALUE,
  OFFICERS_RANK_LABEL,
  OFFICERS_RANK_VALUE,
  OFFICERS_CIVILIANS_LABEL,
  OFFICERS_CIVILIANS_VALUE,
  CIVILIANS_LABEL,
  CIVILIANS_VALUE,
  TENANT_LABEL,
  TENANT_VALUE,
  TENANT_USERS,
  REPEATING_LABEL,
  REPEATING_VALUE,
  OPTIONS_TYPE,
  FILTER_TYPE,
  POPULATED_TYPE,
  BASIC_FROM,
  SPECIFIC_FROM,
  BENCHMARK_ID,
  OR_CRITERIA,
  STAR_NUMBER,
  EMPLOYEE_ID,
  FILTER_NON_OP_USERS,
  IS_CONTRIBUTOR_SECTION,
} from '../../constants/fieldAutocomplete';
import { AUTOCOMPLETE } from '../../../../constants/fieldTypes';
import AutocompleteFieldOptions from './getAutocompleteFieldOptions';

import componentsManager from '../../services/componentsManager';
import calculatedFieldsConfig from '../../services/calculatedFieldsConfig';

const encodePopulateFrom = source => {
  const { repeater = [] } = source;
  // The repeater should always have 3 elements:
  // 0 -> tab index
  // 1 -> repeater key
  // 2 -> field key
  return {
    [repeater[1]]: {
      label: [repeater[2]],
      value: 'id',
      tab: repeater[0],
      isAutocomplete: true,
    },
  };
};

const decodePopulateFrom = source => {
  return Object.keys(source).reduce((result, key) => {
    const item = source[key];

    // At the moment we only support the popular option from a single source.
    // TODO: add support for multiple sources in the UI
    if (!isPlainObject(item)) {
      return [];
    }
    return [item.tab, key, item.label[0]];
  }, []);
};

const getOrCriteriaOptions = () => {
  const config = calculatedFieldsConfig.calculatedFieldConfig || [];
  const starOption = config.find(e => e.value == STAR_NUMBER) || {
    value: STAR_NUMBER,
    label: 'Star/Badge Number',
  };
  const bidOption = config.find(e => e.value == BENCHMARK_ID) || {
    value: BENCHMARK_ID,
    label: 'Benchmark Id',
  };

  const eidOption = config.find(e => e.value == EMPLOYEE_ID) || {
    value: EMPLOYEE_ID,
    label: 'Employee Id',
  };

  return [
    {
      value: bidOption.value,
      label: bidOption.label,
    },
    {
      value: eidOption.value,
      label: eidOption.label,
    },
    {
      value: starOption.value,
      label: starOption.label,
    },
  ].sort((a, b) =>
    a.label.localeCompare(b.label, undefined, { sensitivity: 'base' })
  );
};

const getMultiSelectOptions = () => {
  const fields = componentsManager.fields;
  const repeaters = componentsManager.repeatersReference;

  return fields
    .map((tab, i) => {
      return {
        value: i,
        label: tab.title,
        children: (repeaters[i] || []).map(repeater => {
          const [repeaterItem] = componentsManager.findContainersByKey(
            fields[i].properties,
            repeater.repeaterKey
          );
          return {
            label: get(
              repeaterItem,
              ['options', 'name'],
              repeaterItem.reportingKey
            ),
            value: repeater.repeaterKey,
            children: Object.keys(repeater.fields)
              .map(field => {
                const [fieldItem] = componentsManager.findFieldsByKey(
                  fields[i].properties,
                  field
                );

                return {
                  label: fieldItem.title || fieldItem.reportingKey,
                  value: fieldItem.key,
                  meta: {
                    field_type: fieldItem.field_type,
                  },
                };
              })
              .filter(
                option => get(option, ['meta', 'field_type']) == AUTOCOMPLETE
              ),
          };
        }),
      };
    })
    .filter(tab => tab.children.length); // Return only tabs with repeaters
};

const SELECT_OPTIONS = [
  {
    value: OFFICERS_VALUE,
    label: OFFICERS_LABEL,
    filter: {
      where: { sworn: true },
    },
    from: SPECIFIC_FROM('firstName', 'lastName'),
  },
  {
    value: OFFICERS_RANK_VALUE,
    label: OFFICERS_RANK_LABEL,
    filter: {
      where: { sworn: true },
      include: 'rank',
    },
    from: BASIC_FROM,
  },
  {
    value: OFFICERS_CIVILIANS_VALUE,
    label: OFFICERS_CIVILIANS_LABEL,
    filter: {
      where: {},
    },
    from: BASIC_FROM,
  },
  {
    value: CIVILIANS_VALUE,
    label: CIVILIANS_LABEL,
    filter: {
      where: { sworn: false },
    },
    from: BASIC_FROM,
  },
  {
    value: TENANT_VALUE,
    label: TENANT_LABEL,
    filter: {
      where: {},
    },
    from: BASIC_FROM,
    tenantUsers: true,
  },
  {
    value: REPEATING_VALUE,
    label: REPEATING_LABEL,
  },
];

export default class autocompleteField {
  constructor(contributorSection = false) {
    this.name = SOURCE_TYPE;
    this.label = '';
    this.component = AutocompleteFieldOptions;
    this.defaultValue = {
      source: OFFICERS_VALUE,
      fromRepeating: false,
      orCriteria: [STAR_NUMBER],
      filterNonOpUsers: false,
      isContributorSection: false,
    };
    this.popoverContent = contributorSection
      ? ''
      : `Static options such as Officers, allows to feed an autocomplete field with
    a list of users. Dynamic options lists all repeating elements found in the template.`;
    this.selectOptions = contributorSection
      ? SELECT_OPTIONS.filter(o => o.value !== REPEATING_VALUE)
      : SELECT_OPTIONS;
    this.multiselectOptions = getMultiSelectOptions();

    this.orCriteriaOptions = getOrCriteriaOptions();
    this.contributorSection = contributorSection;
  }

  getInitialValue = values => {
    const hasPopulate = has(values, [OPTIONS_TYPE, POPULATED_TYPE]);
    const fromUsers = has(values, [OPTIONS_TYPE, POPULATED_TYPE, '__users']);
    const hasFilter = has(values, [OPTIONS_TYPE, FILTER_TYPE]);
    const tenantUsers = get(values, [OPTIONS_TYPE, TENANT_USERS], false);
    const isContributorSection = get(
      values,
      [OPTIONS_TYPE, IS_CONTRIBUTOR_SECTION],
      false
    );

    const filterNonOpUsers = get(
      values,
      [OPTIONS_TYPE, FILTER_NON_OP_USERS],
      false
    );

    if (hasPopulate && !fromUsers) {
      const source = get(values, [OPTIONS_TYPE, POPULATED_TYPE]);
      return {
        source: REPEATING_VALUE,
        repeater: decodePopulateFrom(source),
        fromRepeating: true,
        filterNonOpUsers,
        isContributorSection,
      };
    } else if (tenantUsers) {
      const orCriteria = get(values, `${OPTIONS_TYPE}.${OR_CRITERIA}`);
      return {
        source: TENANT_VALUE,
        fromRepeating: false,
        orCriteria: orCriteria || [STAR_NUMBER],
        filterNonOpUsers,
        isContributorSection,
      };
    } else if (hasFilter) {
      const filter = get(values, `${OPTIONS_TYPE}.${FILTER_TYPE}`);
      const selectedOptionO = this.selectOptions.find(option =>
        isEqual(option.filter, filter)
      );
      if (selectedOptionO) {
        const orCriteria = get(values, `${OPTIONS_TYPE}.${OR_CRITERIA}`);

        return {
          source: selectedOptionO.value,
          fromRepeating: false,
          orCriteria: orCriteria || [STAR_NUMBER],
          filterNonOpUsers,
          isContributorSection,
        };
      }
    }
    return this.defaultValue;
  };

  onChange = (value, values) => {
    const changed = {
      ...values,
      [this.name]: value,
    };

    return changed;
  };

  onSave = values => {
    const {
      source: selectedValue,
      fromRepeating,
      orCriteria,
      filterNonOpUsers,
      isContributorSection,
    } = values[this.name];

    const contributor = this.contributorSection ? { isContributorSection } : {};
    const selectedOption = this.selectOptions.find(
      option => option.value === selectedValue
    );

    const { from, filter = {}, tenantUsers } = selectedOption;

    const result = {
      ...values,
      [this.name]: undefined,
      options: {
        ...values.options,
        [this.name]: fromRepeating ? REPEATING_VALUE : USERS,
        filter,
        tenantUsers,
        resetSource: true,
        populateFrom: fromRepeating
          ? encodePopulateFrom(values[this.name])
          : from,
        orCriteria: orCriteria || undefined,
        filterNonOpUsers,
        ...contributor,
      },
    };

    return result;
  };
}
