import { ATTRIBUTE_TYPE_STRING } from '../../../../../components/custom-sections/sections/constants';

const filterOrganizationHistoryAttributes = (
  organizationHistoryAttributes,
  organizationalUnitList
) => {
  const finalOrganizationHistoryAttributes = organizationHistoryAttributes
    //Override the attributes organizationUnitId and updated since there are reserved.
    .filter(
      attr => !['organizationalUnitId', 'updated'].includes(attr.attributeName)
    )
    .map(attr => {
      return {
        ...attr,
        settings: {
          ...attr.settings,
          tableColumn: attr.settings.tableColumn + 1,
        },
      };
    });

  finalOrganizationHistoryAttributes.unshift({
    attributeName: 'organizationalUnitId',
    type: ATTRIBUTE_TYPE_STRING,
    title: 'Organization',
    settings: {
      tableColumn: 1,
      defaultValue: null,
    },
    validValues: organizationalUnitList,
  });

  finalOrganizationHistoryAttributes.push({
    attributeName: 'updated',
    type: ATTRIBUTE_TYPE_STRING,
    title: 'Updated',
    settings: {
      tableColumn: finalOrganizationHistoryAttributes.length + 1,
      defaultValue: null,
    },
    validValues: null,
  });

  return finalOrganizationHistoryAttributes;
};

export default filterOrganizationHistoryAttributes;
