import { get } from 'lodash';

import { REFRESH_ACTIVITIES_REQUEST } from './';

import { fetchNotifications } from '../api/activity';
import refreshActivitiesSuccess from './refresh-activity-items-success';
import refreshActivitiesError from './refresh-activity-items-error';

const initialFilters = {};

export default (page = 1, filters = initialFilters, next = () => {}) => {
  return (dispatch, getState) => {
    const {
      session: { currentUser: { userId } = {} } = {},
      activity: {
        actionItemsFeed: { filter: filters = {}, per_page } = {},
      } = {},
    } = getState();
    dispatch({ type: REFRESH_ACTIVITIES_REQUEST });

    const filter = {
      ...(filters || initialFilters),
      limit: per_page,
      skip: (page - 1) * per_page,
    };

    return fetchNotifications(userId, filter)
      .then(response => {
        dispatch(refreshActivitiesSuccess(response));
        next(null, response);
      })
      .catch(error => {
        dispatch(refreshActivitiesError(get(error, ['response', 'status'])));
        next(error);
      });
  };
};
