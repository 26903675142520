import React, { useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;

const YearPicker = ({
  onChange = () => {},
  minYear = 2022,
  maxYear = new Date().getFullYear(),
}) => {
  const [value, setValue] = useState(null);
  const handlePanelChange = value => {
    setValue(value);
    onChange(value);
  };
  if (!value) {
    const currentYear = new Date().getFullYear();
    setValue(currentYear);
    onChange(currentYear);
  }
  const options = [];
  for (let i = minYear; i <= maxYear; i++) {
    options.push(<Option value={i}>{i}</Option>);
  }

  return (
    <Select
      placeholder="Select Year"
      value={value}
      className="full-width"
      onChange={handlePanelChange}
    >
      {options}
    </Select>
  );
};
export default YearPicker;
