export default {
  total: 0,
  per_page: 10,
  page: 1,
  fetched_at: null,
  refreshed_at: null,
  data: [],
  entries: {},
  filter: {},
  loading: false,
  error: null,
  refreshing: false,
};
