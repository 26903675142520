import React, { Component, Fragment } from 'react';
import { Button, Input } from 'antd';
import { startsWith } from 'lodash';
import { unescape as htmlUnescape } from 'html-escaper';

const ADDRESS_DELIMITER = '||';

class Hyperlink extends Component {
  constructor(props) {
    super(props);
    const { calculatedValue = '', value = '' } = props;
    const link = calculatedValue || value;
    const [title = '', address = ''] = link.split(ADDRESS_DELIMITER);

    this.state = { title, address };
  }

  onChangeTitle = e => {
    const title = e.target.value;
    const { onChange } = this.props;
    const { address } = this.state;
    this.setState({ title });
    onChange && onChange(`${title}${ADDRESS_DELIMITER}${address}`);
  };

  onChangeAddress = e => {
    const address = e.target.value;
    const { onChange } = this.props;
    const { title } = this.state;

    this.setState({ address });
    onChange && onChange(`${title}${ADDRESS_DELIMITER}${address}`);
  };

  customizeEvent = (event, value) => {
    const { currentTarget, target } = event;

    return {
      ...event,
      currentTarget: {
        ...currentTarget,
        value,
      },
      target: {
        ...target,
        value,
      },
    };
  };

  onBlurTitle = e => {
    const { onBlur } = this.props;
    const { address } = this.state;

    const event = this.customizeEvent(
      e,
      `${e.currentTarget.value}${ADDRESS_DELIMITER}${address}`
    );
    onBlur && onBlur(event);
  };

  onBlurAddress = e => {
    const { onBlur, options: { url = '' } = {} } = this.props;
    const { title, address } = this.state;
    // we could validate the address entered is valid with a regular exp like
    // /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/

    // if the address starts with the root location, remove it
    let addr;
    if (startsWith(address, url)) {
      addr = address.slice(url.length);
      this.setState({ address: addr });
    } else {
      addr = address;
    }

    const event = this.customizeEvent(e, `${title}${ADDRESS_DELIMITER}${addr}`);
    onBlur && onBlur(event);
  };

  renderStaticContent = () => {
    const {
      enums,
      options,
      isReviewer,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      field_type,
      value,
      ...props
    } = this.props;

    const { url, urlText = '' } = options;

    return (
      <Button {...props} type="link" target="_blank" href={url}>
        {urlText || url}
      </Button>
    );
  };

  renderDynamicContent = () => {
    const {
      enums,
      options,
      isReviewer,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      field_type,
      ...props
    } = this.props;

    const { url = '' } = options;
    const { title = '', address = '' } = this.state;

    if (isReviewer) {
      // url contains the root location if any
      const addr = htmlUnescape(decodeURIComponent(url + address));
      return address ? (
        <Button {...props} type="link" target="_blank" href={addr}>
          {htmlUnescape(decodeURIComponent(title || addr))}
        </Button>
      ) : (
        <Fragment />
      );
    }

    return (
      <Fragment>
        <Input
          id={`${props.id}-title`}
          form={props.form}
          onChange={this.onChangeTitle}
          onBlur={this.onBlurTitle}
          value={htmlUnescape(title)}
          placeholder="Text to display"
        ></Input>
        <Input
          id={`${props.id}-address`}
          form={props.form}
          onChange={this.onChangeAddress}
          onBlur={this.onBlurAddress}
          addonBefore={url}
          mask={[new RegExp('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$')]}
          placeholderChar="\u2000"
          value={htmlUnescape(address)}
          placeholder="Address"
        ></Input>
      </Fragment>
    );
  };

  render() {
    const { options } = this.props;

    const { staticContent = true } = options;

    return staticContent
      ? this.renderStaticContent()
      : this.renderDynamicContent();
  }
}

export default Hyperlink;
