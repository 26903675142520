import React from 'react';
import { Alert, Divider } from 'antd';

import {
  StyledRow,
  StyledCol,
  StyledInput,
} from '../../../../modules/Security/components/RolesModal/index.styled';
import StepsComponent from '../../../../modules/Security/components/RolesModal/StepsComponent';
import { stepsArray } from './utils';

const ReadOnlyData = ({ roleData }) => {
  const { id, name } = roleData;
  const getAlertDescription = stepsArray => {
    return (
      <StepsComponent
        direction="horizontal"
        size="small"
        stepsArray={stepsArray}
        progressDot
      />
    );
  };

  return (
    <>
      <StyledRow>
        <StyledCol>
          <Alert
            message="Updating a role"
            description={getAlertDescription(stepsArray)}
            type="info"
            showIcon
          />
        </StyledCol>
      </StyledRow>
      <Divider orientation="left">Role Information</Divider>
      <StyledRow>
        <StyledCol xs={24} sm={12} md={12} lg={12}>
          <StyledInput addonBefore="Role Id" value={id} disabled={true} />
        </StyledCol>
        <StyledCol xs={24} sm={12} md={12} lg={12}>
          <StyledInput addonBefore="Role name" value={name} disabled={true} />
        </StyledCol>
      </StyledRow>
      <Divider />
    </>
  );
};

export default ReadOnlyData;
