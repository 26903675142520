import styled from 'styled-components';
import { Table } from 'antd';

const StyledTable = styled(Table)`
  background-color: #fff;

  thead {
    tr {
      th {
        background-color: #e3e3e3;
      }
    }
  }

  td.cursor-pointer {
    cursor: pointer;
  }
`;

export default StyledTable;
