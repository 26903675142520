import React, { Fragment } from 'react';

import FieldText from '../../components/FieldText';
import FieldCheckbox from '../../components/FieldCheckbox';
import FieldTextarea from '../../components/FieldTextarea';

import { Row, Form } from 'antd';

const { Item } = Form;

export default class RepeaterDeleteModalOptions extends React.Component {
  onChange = key => newValue => {
    const { onChange, value } = this.props;

    onChange && onChange({ ...value, [key]: newValue });
  };

  render = () => {
    const itemLayoutOptions = {
      colon: false,
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
    };
    const {
      value: { title, okText, cancelText, hideCounter, content },
    } = this.props;

    return (
      <Fragment>
        {/* <Row> */}
        <Item label=" " {...itemLayoutOptions}>
          <label className="title">Delete Modal Options</label>
        </Item>
        {/* </Row> */}
        <Row>
          <Item
            key="title"
            label="Title"
            {...itemLayoutOptions}
            help="Maximun 100 characters"
          >
            <FieldText
              value={title}
              onChange={this.onChange('title')}
              style={{ width: '100%' }}
              placeholder="Insert delete modal title text"
              maxLength={100}
              data-test="modal-title"
            ></FieldText>
          </Item>
        </Row>
        <Row>
          <Item
            key="okText"
            label="Ok text"
            {...itemLayoutOptions}
            help="Maximun 20 characters"
          >
            <FieldText
              value={okText}
              onChange={this.onChange('okText')}
              style={{ width: '100%' }}
              placeholder="Insert Ok text"
              maxLength={20}
              data-test="modal-ok"
            ></FieldText>
          </Item>
        </Row>
        <Row>
          <Item
            key="cancelText"
            label="Cancel text"
            {...itemLayoutOptions}
            help="Maximun 20 characters"
          >
            <FieldText
              value={cancelText}
              onChange={this.onChange('cancelText')}
              style={{ width: '100%' }}
              placeholder="Insert cancel text"
              maxLength={20}
              data-test="modal-cancel"
            ></FieldText>
          </Item>
        </Row>
        <Row>
          <Item
            key="content"
            label="Content"
            {...itemLayoutOptions}
            help="Maximun 500 characters"
          >
            <FieldTextarea
              value={content}
              rows={3}
              onChange={this.onChange('content')}
              style={{ width: '100%' }}
              placeholder="Insert content"
              maxLength={500}
              data-test="modal-content"
            ></FieldTextarea>
          </Item>
        </Row>
        <Row>
          <Item key="hideCounter" label=" " {...itemLayoutOptions}>
            <FieldCheckbox
              checkboxLabel="Hide counter"
              value={hideCounter}
              onChange={this.onChange('hideCounter')}
              style={{ width: '100%' }}
              data-test="modal-counter"
            ></FieldCheckbox>
          </Item>
        </Row>
      </Fragment>
    );
  };
}
