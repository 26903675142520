import findConfigField from '../utils/findConfigField';
import { TEXT } from 'APP_ROOT/constants/fieldTypes';
import { isEmpty } from 'lodash';

const verifyCalculated = (field, allAutoCompleteFieldsByKey = {}) => {
  const { fromSource, field_type, ...rest } = field;
  if (fromSource) {
    const { key: keyFromSource, field: fieldFromSource } = fromSource;
    //Checking white list keys and calculated fields
    const config = findConfigField(keyFromSource);

    if (config.type !== field_type) {
      //Checking relation to an Autocomplete field
      if (
        !Object.keys(allAutoCompleteFieldsByKey).some(
          autoCompleteKey => autoCompleteKey === fieldFromSource
        )
      ) {
        // if the autocomplete field was not found, the object 'fromSource' should be removed
        // but because of PLAT-224 we are not going to remove that now.
        return [{ fromSource, field_type, ...rest }, true];
      }

      if (isEmpty(config.key) || Array.isArray(keyFromSource)) {
        return [{ fromSource, field_type: TEXT, ...rest }, true];
      }
      const legacyFieldType = (config && config.type) || TEXT;
      return [{ fromSource, field_type: legacyFieldType, ...rest }, true];
    }
  }
  return [field, false];
};

export default verifyCalculated;
