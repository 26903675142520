import { memoize } from 'lodash';

const getFormProps = (
  presentation,
  form,
  overrides,
  settings,
  validations,
  tab,
  onSubmit,
  isReviewer,
  isViewer,
  canSubmit,
  loading,
  templates,
  timezone
) => ({
  presentation,
  form,
  overrides,
  settings,
  validations,
  tab,
  onSubmit,
  isReviewer,
  isViewer,
  canSubmit,
  loading,
  templates,
  timezone,
});

export default getFormProps;
export const getFormPropsMemo = memoize(getFormProps);
