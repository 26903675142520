import { createReducer } from 'redux-create-reducer';
import { merge } from 'lodash';

import initialState from './state/initial-state';

import formPreparationEvents from './form-preparation';
import dataSyncingEvents from './data-syncing';
import dataFetchingEvents from './data-fetching';
import miscellaneousEvents from './misc';
import formParentEvents from './form-parent';
import reportSharing from 'APP_ROOT/modules/ReportSharing/ReportSharing.reducer';

const events = merge(
  formPreparationEvents,
  dataSyncingEvents,
  dataFetchingEvents,
  miscellaneousEvents,
  formParentEvents,
  reportSharing
);

export default createReducer(initialState, events);
