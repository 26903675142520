import styled from 'styled-components';
import { ACCENT } from '../../../config/theme';

const Label = styled.div.attrs({
  className: 'bdm-label-editor',
})`
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px
    ${props => (props.isActive ? ACCENT : 'transparent')};
  transition: box-shadow 0.2s, background-color 0.3s;

  &.is-disabled {
    color: #b7b7b7;
  }

  &.has-changes {
    box-shadow: inset 0 0 0 1px ${ACCENT};
    background-color: #eaf1ff;
  }

  &:hover,
  &.is-active {
    box-shadow: inset 0 0 0 2px ${ACCENT};
  }
`;

export default Label;
