import styled from 'styled-components';
import { Modal } from 'antd';

const StyledModal = styled(Modal)`
  &.add-report-modal {
    max-width: 800px;
    width: 100% !important;

    .ant-table-tbody > tr > td {
      padding: 6px 16px;
    }
  }
`;

export default StyledModal;
