import { isEmpty } from 'lodash';
import getRepeaterDeleteModalOptions from './getRepeaterDeleteModalOptions';

export default class {
  constructor() {
    this.name = 'deleteModal';
    this.label = '';
    this.component = getRepeaterDeleteModalOptions;
    this.defaultValue = {
      title: '',
      okText: '',
      cancelText: '',
      hideCounter: false,
      content: '',
    };
  }

  // isValid = values => {
  //   const { url } = values[this.name];

  //   return !isEmpty(url);
  // };

  onChange = (value, values) => {
    return {
      ...values,
      [this.name]: value,
    };
  };

  onSave = values => {
    const { title, okText, cancelText, hideCounter, content } = values[
      this.name
    ];
    let deleteModal;
    if (
      !isEmpty(title) ||
      !isEmpty(okText) ||
      !isEmpty(cancelText) ||
      !isEmpty(content) ||
      hideCounter
    ) {
      deleteModal = {
        deleteModalOptions: {
          title,
          okText,
          cancelText,
          hideCounter,
          content,
        },
      };
    }

    return {
      ...values,
      [this.name]: undefined,
      options: {
        ...values.options,
        ...deleteModal,
      },
    };
  };

  getInitialValue = values => {
    if (values.options === undefined) {
      return this.defaultValue;
    }

    const { deleteModalOptions = this.defaultValue } = values.options;

    const {
      title = '',
      okText = '',
      cancelText = '',
      hideCounter = false,
      content = '',
    } = deleteModalOptions;

    return {
      title,
      okText,
      cancelText,
      hideCounter,
      content,
    };
  };
}
