import React from 'react';
import { InputNumber } from 'antd';

class FieldNumber extends React.Component {
  onBlur = e => {
    const { onBlur } = this.props;

    onBlur && onBlur(Number(e.target.value));
  };

  onChange = e => {
    const { onChange } = this.props;

    onChange && onChange(e);
  };

  render() {
    const {
      defaultValue,
      value,
      placeholder = '',
      style = { width: 200 },
      min,
      max,
      'data-test': dataTest = '',
    } = this.props;

    return (
      <InputNumber
        type="text"
        className="edit-input"
        defaultValue={defaultValue}
        style={style}
        value={value}
        onBlur={this.onBlur}
        onChange={this.onChange}
        placeholder={placeholder}
        data-test={dataTest}
        min={min}
        max={max}
      />
    );
  }
}

export default FieldNumber;
