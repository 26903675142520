import { genOptions, route, fetchRequest } from '../utils/request';
import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';

export const linkingReports = ({
  currentReportId = '',
  targetReportId = '',
  linkType = 'Blend',
}) => {
  const url = route(urlBuilder(`/Links`));
  const options = genOptions(
    'POST',
    { linkedFromId: currentReportId, linkedToId: targetReportId, linkType },
    storage.get('token')
  );

  return fetchRequest(url, options);
};

export const unLinkingReports = id => {
  const url = route(urlBuilder(`/Links/:id`, { id }));
  const options = genOptions('DELETE', {}, storage.get('token'));

  return fetchRequest(url, options);
};
