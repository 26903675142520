import { get } from 'lodash';

const findInSource = (collection = {}, { key }) => {
  const found = Object.keys(collection).reduce((result, id) => {
    const item = get(collection, id, {});

    if (item) {
      return get(item, key, null);
    }

    return result;
  }, null);

  return found;
};

export default (wholeData, rules, fieldsData, dataEnums) =>
  rules.reduce(
    (output, { key: dataKey, fromSource }) => {
      if (fromSource) {
        const { source = '', key: keyInSource = '' } = fromSource;

        const sources = Array.isArray(source) ? source : [source];

        const item = sources.reduce((result, sourceName) => {
          const sourceData = get(wholeData, sourceName, {});
          const transformedData = Array.isArray(sourceData)
            ? sourceData.reduce(
                (res, item, index) => ({
                  ...res,
                  [item[keyInSource] || index]: item,
                }),
                {}
              )
            : sourceData;

          const found = findInSource(transformedData, {
            key: keyInSource || 'id',
          });
          const reduced = found ? found : result;

          return reduced;
        }, null);

        return {
          ...output,
          [dataKey]: get(item, dataKey),
        };
      }

      return {
        ...output,
        [dataKey]: get(fieldsData, dataKey),
      };
    },
    { dataEnums }
  );
