import { createReducer } from 'redux-create-reducer';

import {
  ORGANIZATION_OU_USERS_SUCCESS,
  ORGANIZATION_OU_USERS_ERROR,
  ORGANIZATION_OU_USERS_REQUEST,
} from '../../actions/organizations/get-ou-users';

export const initialState = {
  loading: false,
  total: undefined,
  totalPages: undefined,
  lastPage: undefined,
  lastPerPage: undefined,
  lastFilter: undefined,
  error: undefined,
  ids: [],
  entries: {},
};

const events = {
  [ORGANIZATION_OU_USERS_REQUEST]: (
    state,
    { payload: { page, size, filter } }
  ) => ({
    ...state,
    lastPage: page,
    lastPerPage: size,
    lastFilter: filter,
    loading: true,
  }),
  [ORGANIZATION_OU_USERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    total: payload.total,
    totalPages: payload.totalPages,
    ids: payload.users.map(item => item.integrationId),
    entries: payload.users.reduce(
      (res, item) => ({
        ...res,
        [item.integrationId]: item,
      }),
      {}
    ),
  }),
  [ORGANIZATION_OU_USERS_ERROR]: (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }),
};

export default createReducer(initialState, events);
