import React, { Component } from 'react';
import { connect } from 'react-redux';

import fetchActionItems from '../../../actions/fetch-action-items';
import refreshActionItems from '../../../actions/fetch-action-items';
import fetchActivitiesItems from '../../../actions/fetch-activity-items';
import refreshActivitiesItems from '../../../actions/fetch-activity-items';

class DashboardActivities extends Component {
  static getPageConfig() {
    return {
      title: 'Home',
    };
  }

  pollingWorkers = {};

  UNSAFE_componentWillMount() {
    const {
      dispatch,
      actionItems: { loading = false, fetched_at, fetching = false } = {},
      acitivities: {
        loading: activities_loading = false,
        fetched_at: actvities_fetched_at,
        fetching: activities_fetching = false,
      } = {},
    } = this.props;

    if (!loading && !fetching) {
      if (fetched_at) {
        dispatch(fetchActionItems());
      } else {
        dispatch(refreshActionItems());
      }
    }

    if (!activities_loading && !activities_fetching) {
      if (actvities_fetched_at) {
        dispatch(fetchActivitiesItems());
      } else {
        dispatch(refreshActivitiesItems());
      }
    }

    this.startPolling('action_items', () => dispatch(refreshActionItems()));
    this.startPolling('activities', () => dispatch(refreshActivitiesItems()));
  }

  componentWillUnmount() {
    const { pollingWorkers, endPolling } = this;
    const workers = Object.keys(pollingWorkers);

    workers.forEach(endPolling);
  }

  startPolling = (id, callback) => {
    const second = 1000;
    const minute = 60 * second;

    this.pollingWorkers[id] = {
      worker: window.setInterval(callback, 5 * minute),
      id,
      callback,
    };
  };

  endPolling = id => {
    const polling = this.pollingWorkers[id];

    if (polling) {
      window.clearInterval(polling.worker);
      this.pollingWorkers[id] = undefined;
    }
  };

  render() {
    const { children, ...props } = this.props;
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, props)
    );

    return <div className="dashboard-with-activities">{childrenWithProps}</div>;
  }
}

const mapState = ({
  activity: { actions: actionItems = {}, activities = {} },
}) => ({
  actionItems,
  activities,
});

const withActivities = Component =>
  connect(
    mapState,
    null
  )(props => (
    <DashboardActivities {...props}>
      <Component />
    </DashboardActivities>
  ));

export default withActivities;
