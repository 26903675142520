import { isObject } from 'lodash';

// for sections and fields, title could be an string or
// {
//   conditions:[],
//   defaultTitle,
//   source: '',
// }

const getTitle = (title, defaultValue) => {
  return (
    (isObject(title) && (title.defaultTitle || defaultValue)) ||
    title ||
    defaultValue
  );
};

export default getTitle;
