import React from 'react';
import styled from 'styled-components';

const AttachmentsWarningImportant = () => (
  <StyledWarning>
    <div>
      <strong>Important</strong>: Changes to this section <strong>are not saved</strong> until you <strong>Save</strong> on the main page.
    </div>
    <div>
      Do not navigate away from the page, or close the tab or window; otherwise, the upload will be interrupted.
    </div>
    <div>
      You may experience longer upload times when importing larger files. To
      reduce load time, you may want to consider compressing or zipping large
      files.
    </div>
  </StyledWarning>
);

const StyledWarning = styled.p`
  margin-bottom: 18px;
`;

export default AttachmentsWarningImportant;
