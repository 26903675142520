import * as Rx from 'rxjs';
import { isArray, isObject, has, uniqBy } from 'lodash';

import removeEnumRef from './removeEnumRef';
import getFieldMeta from '../../utils/getFieldMeta';

import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes';
import { SELECT, MULTISELECT } from 'APP_ROOT/constants/fieldTypes';

class enumsManager {
  constructor() {
    this.enums$ = new Rx.BehaviorSubject({});
  }

  set enums(enums) {
    this.enums$.next(enums);
  }

  get enums() {
    return this.enums$.value;
  }

  getEnum(enumRef, parentEnum = '') {
    const enums = this.enums;
    if (!parentEnum) {
      return enums[enumRef] || [];
    }

    const parentEnums = enums[parentEnum];
    const enumOptions = parentEnums.reduce(
      (accumulator, e) => accumulator.concat(e[enumRef]),
      []
    );
    return enumOptions || [];
  }

  convertEnumOption = (option, index) => {
    const { label, enums, value, order = index + 1 } = option;
    if (label) {
      if (enums) {
        return {
          label,
          enums: enums.reduce(
            (all, value) =>
              all.concat(this.convertEnumOption(value, all.length)),
            []
          ),
          order,
        };
      } else {
        return { label, value, order };
      }
    } else {
      // no label, then create the option
      return { label: option, value: option, order };
    }
  };

  convertParentEnum = option => {
    if (has(option, 'label')) {
      // regular option, just return
      return option;
    } else {
      // is a parentEnum object, we need to convert it
      return Object.entries(option).reduce((allValues, [key, values]) => {
        allValues[key] = values.reduce(
          (all, value) => all.concat(this.convertEnumOption(value, all.length)),
          []
        );
        return allValues;
      }, {});
    }
  };

  convertToObject = (allValues, value) =>
    allValues.concat(
      isObject(value)
        ? this.convertParentEnum(value)
        : { label: value, value, order: allValues.length }
    );

  uploadEnums = enums => {
    const newEnums = Object.entries(enums).reduce((allEnums, [key, value]) => {
      allEnums[key] = isArray(value)
        ? value.reduce(this.convertToObject, [])
        : value;
      return allEnums;
    }, {});
    this.enums = newEnums;
  };

  updateEnums(
    { options, fields, componentId, parentEnum, hasPopulateFrom },
    validations
  ) {
    const field = getFieldMeta({ fields, value: componentId });
    const { key, enumRef, type, field_type } = field;

    if ([FIELD].includes(type) && [SELECT, MULTISELECT].includes(field_type)) {
      if (!hasPopulateFrom) {
        this.updateEnum(enumRef, { parentEnum, key, validations }, options);
      } else {
        this.removeEnumRef(enumRef, parentEnum);
      }
    }
  }

  updateEnum(enumRef, { parentEnum, key, validations } = {}, options = []) {
    const untouchedEnums = this.enums;
    let enums;
    if (parentEnum) {
      // const validations = this.validations;
      const { rules } = validations;
      const [{ enumRef: flatRef }] = rules[key];
      const [untouchedParentEnums] = untouchedEnums[parentEnum];
      enums = {
        ...untouchedEnums,
        [parentEnum]: [
          {
            ...untouchedParentEnums,
            [enumRef]: options.map(this.convertEnumOption),
          },
        ],
      };
      enums = {
        ...enums,
        [flatRef]: uniqBy(
          Object.values(enums[parentEnum][0])
            .reduce((accumulator, e) => accumulator.concat(e), [])
            .reduce(
              (accumulator, e) => accumulator.concat(e.enums ? e.enums : e),
              []
            ),
          e => e.value
        ),
      };
    } else {
      enums = {
        ...untouchedEnums,
        [enumRef]: options.map(this.convertEnumOption),
      };
    }
    this.enums = enums;
  }

  removeEnumRef(enumRef, parentEnum) {
    this.enums = removeEnumRef(this.enums, enumRef, parentEnum);
  }
}

export default enumsManager;
