import bigDecimal from 'js-big-decimal';

import getFieldValues from './getFieldValues';

import {
  MATH_OPERATION_SUM,
  MATH_ROUND,
} from '../../../modules/FormBuilder/constants/constants';

const toNumber = value => new bigDecimal(isNaN(value) ? 0 : value);

export const applyRounding = (props, value) => {
  const { options } = props;
  const { rounding } = options;
  return rounding === MATH_ROUND ? value.round(2) : value.round();
};

const computeMathFieldValue = props => {
  const { options } = props;
  const { operands, operation } = options;
  let totalValues = 0;
  const total = operands.reduce((tot, op) => {
    const values = getFieldValues(props, op, 0);
    totalValues += values.length;
    return values.reduce(
      (totValues, value) => totValues.add(toNumber(value)),
      tot
    );
  }, new bigDecimal(0));
  const result =
    operation === MATH_OPERATION_SUM
      ? total
      : total.divide(new bigDecimal(totalValues));
  const rounded = applyRounding(props, result);
  return rounded.getValue();
};

export default computeMathFieldValue;
