import { EMAIL, PHONE_NUMBER, SSN_TYPE } from './fieldMasked';

export const REG_EXP_PATTERNS = {
  [PHONE_NUMBER]: [
    '(',
    { regExp: '[1-9]' },
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
    ')',
    ' ',
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
    '-',
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
  ],

  [EMAIL]: '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$',
  [SSN_TYPE]: [
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
    '-',
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
    '-',
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
    {
      regExp: '\\d',
    },
  ],
};

export const REG_EXP_PATTERNS_STRING = {
  [PHONE_NUMBER]:
    '^([0-9]( |-)?)?(\\(?[0-9]{3}\\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$',
  [EMAIL]: '^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$',
  [SSN_TYPE]:
    '^(?!666|000|9\\d{2})\\d{3}( |-)?(?!00)\\d{2}( |-)?(?!0{4})\\d{4}$',
};
