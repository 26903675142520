import React, { useEffect, useReducer, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Input as InputField,
  Input,
  InputNumber,
  Row,
  Select,
  TimePicker,
  TreeSelect,
} from 'antd';
import StyledModal from './UserEmploymentModal.styled';
import Section from '../../../../components/form-viewer/styled/section';
import { InlineInputWrapper } from '../../components/input';
import { defaultTo, get, isPlainObject, kebabCase, orderBy } from 'lodash';
import {
  ATTRIBUTE_TYPE_DATE,
  ATTRIBUTE_TYPE_DATETIME,
  ATTRIBUTE_TYPE_NUMBER,
  ATTRIBUTE_TYPE_STRING,
  ATTRIBUTE_TYPE_SWITCH,
  ATTRIBUTE_TYPE_TEXTAREA,
  ATTRIBUTE_TYPE_TIME,
} from './UserProfile.constants';
import {
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_DATE_TIME_FORMAT,
  BENCHMARK_TIME_FORMAT,
  formatDate,
  momentWithTZ,
} from '../../../../utils/parse-date';
import StyledSwitch from '../../../../components/form-viewer/styled/input-switch';
import EllipsisInput from './EllipsisInput';
import moment from 'moment';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';
import showModal from '../../../../actions/show-modal';
import createModal from '../../../../actions/create-modal';
import { connect } from 'react-redux';
import modalMessages from './errors/UserErrorMessages';
import showErrorMessages from './errors/OuErrorMessages';
import { EMPLOYMENT_ACTION_SEPARATION } from './userEmployment.constants';

const Option = Select.Option;

const reducer = (state, action) => {
  switch (action.type) {
    case 'append_to_source':
      return {
        ...state,
        separationRequest: { ...state.separationRequest, ...action.payload },
      };
    case 'append_to_target':
      return {
        ...state,
        hireRequest: { ...state.hireRequest, ...action.payload },
      };
    case 'replace_state_keep_comments':
      return {
        ...action.payload,
        separationRequest: {
          ...action.payload.separationRequest,
          comment: state.separationRequest.comment,
        },
        hireRequest: {
          ...action.payload.hireRequest,
          comment: state.hireRequest.comment,
        },
      };
    default:
      throw new Error();
  }
};

const initialState = {
  separationRequest: {},
  hireRequest: {},
};

function TransferEmploymentModal({
  attributes,
  onCancel,
  onOk,
  title,
  prefix,
  profileForm,
  rowIndex,
  sectionId,
  timezone,
  visible,
  treeDropdownOptions = [],
  disabledValues = [],
  session,
  dispatch: reduxDispatch,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [
    selectedOuHasSeparationRecord,
    setSelectedOuHasSeparationRecord,
  ] = useState(false);
  const [sourceOuName, setSourceOuName] = useState('');
  const [targetOuName, setTargetOuName] = useState('');

  useEffect(() => {
    const currentUser = get(session, 'currentUser.fullName', null);
    const userToBeTransferred = get(profileForm, 'values.fullName', null);
    const today = moment().format(BENCHMARK_DATE_FORMAT);
    const generatedComment = `On ${today} ${currentUser} transferred ${userToBeTransferred} from ${sourceOuName} to ${targetOuName}`;

    dispatch({
      type: 'append_to_source',
      payload: { comment: generatedComment },
    });
    dispatch({
      type: 'append_to_target',
      payload: { comment: generatedComment },
    });
  }, [profileForm, rowIndex, sectionId, sourceOuName, targetOuName]);

  useEffect(() => {
    if (attributes.length) {
      const sourceOuEmploymentActions = get(
        profileForm,
        `values.${prefix}.${sectionId}.employmentAction`,
        []
      );
      const separationIndex = sourceOuEmploymentActions.findIndex(
        e => e === EMPLOYMENT_ACTION_SEPARATION
      );
      setSelectedOuHasSeparationRecord(!(separationIndex === -1));

      const originOuName = get(
        profileForm,
        `values.organizationalUnitHistoryData.employment.organizationalUnitName[${rowIndex}]`,
        null
      );
      const originOuId = get(
        profileForm,
        `values.organizationalUnitHistoryData.employment.organizationalUnitId[${rowIndex}]`,
        null
      );

      setSourceOuName(originOuName);

      const newState = attributes
        .filter(attribute => attribute.attributeName !== 'comment')
        .reduce((a, v) => ({
          separationRequest: {
            ...a.separationRequest,
            [v.attributeName]:
              v.attributeName !== 'effectiveDate'
                ? v.isArray
                  ? defaultTo(
                      get(
                        profileForm,
                        `values.${prefix}.${sectionId}.${v.attributeName}[${rowIndex}]`
                      ),
                      []
                    ).filter(e => e)
                  : get(
                      profileForm,
                      `values.${prefix}.${sectionId}.${v.attributeName}[${rowIndex}]`,
                      v.type === 'boolean' ? false : null
                    )
                : null,
          },
          hireRequest: {
            ...a.hireRequest,
            [v.attributeName]: v.isArray
              ? []
              : v.type === 'boolean'
              ? false
              : null,
          },
        }));

      newState.separationRequest.organizationalUnitHistoryId = sectionId;
      newState.separationRequest.organizationalUnitId = originOuId;
      newState.hireRequest.organizationalUnitId = null;
      newState.tenantIdContext = get(
        session,
        'currentUser.agency.integrationId',
        null
      );

      dispatch({ type: 'replace_state_keep_comments', payload: newState });
    }
  }, [profileForm, rowIndex, sectionId]);

  const showSuccessMessage = () => {
    const options = {
      id: 'create-user-modal',
      title: modalMessages.successTitle,
      children: modalMessages.successFields,
      onOk: () => window.location.reload(),
      onCancel: () => window.location.reload(),
    };

    reduxDispatch(createModal(options));
    reduxDispatch(showModal(options.id));
  };

  const generateField = (
    {
      attributeId,
      attributeName,
      title,
      type,
      validValues,
      unique,
      disabled = false,
      isArray,
      settings,
      readOnly = false,
      editable = true,
      onChange = () => {},
    },
    side,
    actionType
  ) => {
    const dispatchCallbackDate = (_, date) => {
      dispatch({
        type: actionType,
        payload: { [attributeName]: date },
      });
    };
    const dispatchCallbackEvent = event => {
      dispatch({
        type: actionType,
        payload: { [attributeName]: event.target.value },
      });
    };
    const dispatchCallbackValue = value => {
      dispatch({
        type: actionType,
        payload: { [attributeName]: value },
      });
    };

    const getType = type => {
      const plainSelectOptions =
        validValues &&
        validValues.map(val =>
          isPlainObject(val) ? val : { label: val, value: val, ...val }
        );

      switch (type) {
        case ATTRIBUTE_TYPE_DATE:
          return (
            <DatePicker
              disabled={selectedOuHasSeparationRecord}
              value={
                state[side][attributeName]
                  ? formatDate(state[side][attributeName], timezone)
                  : undefined
              }
              format={BENCHMARK_DATE_FORMAT}
              onChange={dispatchCallbackDate}
            />
          );

        case ATTRIBUTE_TYPE_DATETIME:
          return (
            <DatePicker
              disabled={selectedOuHasSeparationRecord}
              value={
                state[side][attributeName]
                  ? momentWithTZ(
                      state[side][attributeName],
                      timezone,
                      BENCHMARK_DATE_TIME_FORMAT,
                      false
                    )
                  : undefined
              }
              format={BENCHMARK_DATE_TIME_FORMAT}
              onChange={dispatchCallbackDate}
            />
          );

        case ATTRIBUTE_TYPE_TIME:
          return (
            <TimePicker
              disabled={selectedOuHasSeparationRecord}
              value={
                state[side][attributeName]
                  ? momentWithTZ(
                      state[side][attributeName],
                      timezone,
                      BENCHMARK_TIME_FORMAT,
                      false
                    )
                  : undefined
              }
              format={BENCHMARK_TIME_FORMAT}
              onChange={dispatchCallbackDate}
            />
          );

        case ATTRIBUTE_TYPE_NUMBER:
          return (
            <InputNumber
              disabled={selectedOuHasSeparationRecord}
              value={parseInt(state[side][attributeName])}
              onChange={dispatchCallbackEvent}
            />
          );

        case ATTRIBUTE_TYPE_SWITCH:
          return (
            <StyledSwitch
              disabled={selectedOuHasSeparationRecord}
              checked={state[side][attributeName]}
              onChange={dispatchCallbackValue}
            />
          );

        case ATTRIBUTE_TYPE_TEXTAREA:
          return (
            <InputField.TextArea
              disabled={selectedOuHasSeparationRecord}
              value={state[side][attributeName]}
              onChange={dispatchCallbackEvent}
            />
          );

        case ATTRIBUTE_TYPE_STRING:
          let selectConfig = {};

          if (settings?.showSearch) {
            selectConfig = {
              ...selectConfig,
              showSearch: true,
              filterOption: (input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0,
            };
          }

          const selectOptions =
            plainSelectOptions &&
            plainSelectOptions.map(({ label, value, disabled = false }) => (
              <Option key={kebabCase(value)} value={value} disabled={disabled}>
                {label}
              </Option>
            ));

          if (selectOptions) {
            return isArray ? (
              <Select
                disabled={selectedOuHasSeparationRecord}
                onChange={dispatchCallbackValue}
                mode="multiple"
                style={{ width: '100%' }}
                value={state[side][attributeName] || []}
                {...selectConfig}
              >
                {selectOptions}
              </Select>
            ) : (
              <Select
                disabled={selectedOuHasSeparationRecord}
                onChange={dispatchCallbackValue}
                style={{ width: '100%' }}
                value={state[side][attributeName]}
                {...selectConfig}
              >
                {selectOptions}
              </Select>
            );
          }

          return (
            <EllipsisInput
              disabled={selectedOuHasSeparationRecord}
              onChange={dispatchCallbackEvent}
              value={state[side][attributeName]}
            />
          );

        default:
          return (
            <Input
              disabled={selectedOuHasSeparationRecord}
              value={state[side][attributeName]}
              onChange={dispatchCallbackEvent}
            />
          );
      }
    };

    return (
      <InlineInputWrapper
        key={attributeId}
        label={title}
        options={{
          labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
          inputCol: 'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
        }}
      >
        {getType(type)}
      </InlineInputWrapper>
    );
  };

  const generateTreeField = ({
    validValues = [],
    attributeName,
    title = '',
    side,
    actionType,
    disabled = false,
    setStateCallback = () => {},
  }) => {
    const plainSelectOptions =
      validValues &&
      validValues.map(val =>
        isPlainObject(val) ? val : { label: val, value: val, ...val }
      );

    const treeSelectOptions =
      plainSelectOptions &&
      plainSelectOptions.slice(0, 1).map(ou => {
        function createTreeNode(ou) {
          if (
            ou.children === [] ||
            ou.children === null ||
            ou.children === undefined
          ) {
            return ou;
          } else {
            return {
              label: ou.label,
              value: ou.value,
              disabled: disabledValues.includes(ou.value) || false,
              children: ou.children
                .map(childOu => createTreeNode(childOu))
                .sort((ou1, ou2) => {
                  const displayName1 = ou1.label.toLowerCase();
                  const displayName2 = ou2.label.toLowerCase();
                  if (displayName1 < displayName2) {
                    return -1;
                  } else if (displayName1 > displayName2) {
                    return 1;
                  } else {
                    return 0;
                  }
                }),
            };
          }
        }
        return createTreeNode(ou);
      });

    const treeData = {
      style: { width: '100%' },
      showSearch: true,
      treeNodeFilterProp: 'title',
      placeholder: 'Please select',
      treeDefaultExpandAll: true,
      dropdownStyle: { maxHeight: 400, overflow: 'auto' },
      treeData: treeSelectOptions,
    };

    const onChangeCallback = (value, label) => {
      setStateCallback(label[0]);
      dispatch({
        type: actionType,
        payload: { [attributeName]: value },
      });
    };

    return (
      <InlineInputWrapper
        label={title}
        options={{
          labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
          inputCol: 'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
        }}
      >
        <TreeSelect
          disabled={disabled || selectedOuHasSeparationRecord}
          value={state[side][attributeName]}
          onChange={onChangeCallback}
          {...treeData}
        />
      </InlineInputWrapper>
    );
  };

  const sortedAttributes = orderBy(
    attributes.filter(
      attribute => attribute.attributeName !== 'employmentAction'
    ),
    ['settings.formOrder', 'settings.formColumn'],
    ['asc', 'asc']
  );

  const fromOu = generateTreeField({
    validValues: treeDropdownOptions,
    attributeName: 'organizationalUnitId',
    title: 'Assignment',
    side: 'separationRequest',
    actionType: 'append_to_source',
    disabled: true,
    setStateCallback: setSourceOuName,
  });
  const fromFields = sortedAttributes.map(attribute =>
    generateField(attribute, 'separationRequest', 'append_to_source')
  );

  const toOu = generateTreeField({
    validValues: treeDropdownOptions,
    attributeName: 'organizationalUnitId',
    title: 'Assignment',
    side: 'hireRequest',
    actionType: 'append_to_target',
    setStateCallback: setTargetOuName,
  });
  const toFields = sortedAttributes.map(attribute =>
    generateField(attribute, 'hireRequest', 'append_to_target')
  );

  const transferDisabled =
    !(
      state?.separationRequest?.organizationalUnitId &&
      state?.hireRequest?.organizationalUnitId &&
      state?.separationRequest?.effectiveDate &&
      state?.hireRequest?.effectiveDate
    ) || selectedOuHasSeparationRecord;

  const onTransfer = async () => {
    const userId = get(profileForm, 'values.integrationId', '');
    try {
      await organizationEndpoints.postTransferEmployment(
        userId,
        state.separationRequest.organizationalUnitId,
        state
      );
      onOk();
      showSuccessMessage();
    } catch (error) {
      showErrorMessages(reduxDispatch, error);
    }
  };

  return (
    <StyledModal
      title={title}
      centered
      visible={visible}
      onOk={onTransfer}
      onCancel={onCancel}
      className="user-profile-modal"
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="Transfer"
          type="primary"
          onClick={onTransfer}
          disabled={transferDisabled}
        >
          Transfer
        </Button>,
      ]}
    >
      <Section>
        <Row>
          <Col xs={24} sm={12} md={12} lg={12}>
            <h2>Transfer from: </h2>
            <br />
            {fromOu}
            {fromFields}
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <h2>Transfer to: </h2>
            <br />
            {toOu}
            {toFields}
          </Col>
        </Row>
      </Section>
    </StyledModal>
  );
}

export default connect()(TransferEmploymentModal);
