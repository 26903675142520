import React, { useEffect } from 'react';
import DashboardPage from '../../components/dashboard';
import withoutClutter from '../../components/without-clutter';
import storage from '../../utils/storage';
import { getEnvironmentVariable } from '../../appVersion';
import logout from '../../actions/logout-direct';
import callbacks from '../../utils/callback';

const Logout = React.forwardRef((props, ref) => {
  useEffect(() => {
    const { dispatch } = props;
    const token = storage.get('token');
    const ssoPreLogoutURI = getEnvironmentVariable('SSO_PRE_LOGOUT_URI');
    const ssoLogoutURI = getEnvironmentVariable('SSO_LOGOUT_URI');
    const securityLogoutURI = getEnvironmentVariable('SECURITY_SERVICE_URI');
    const execLogoutUrl = `${ssoPreLogoutURI}?token=${token}&sso_logout_uri=${ssoLogoutURI}&client_id=benchmark&scope=openid+email+agency+roles+rank+permissions&kmi_logout=false&security_host=${securityLogoutURI}`;

    dispatch(
      logout(
        callbacks({
          onComplete: () => {
            window.location.href = execLogoutUrl;
          },
        })
      )
    );
  }, []);
  return <h1>Logout</h1>;
});

const mapStateToProps = () => {};

export default withoutClutter(DashboardPage(mapStateToProps, null, {})(Logout));
