// to call the API, for instance
// https://{secure-service}:3003/api/organizations/v1/agencies/ranks/9001

import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';

class securityEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'http://localhost:3005';
    super(process.env.REACT_APP_SECURITY_SERVICE_URI || DEV_URI, 'auth/v1');
  }

  getAgencySecurityRoles(agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/agencies/:agencyId/roles'),
        method: 'GET',
      },
      {
        agencyId,
      }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getUsersByAgencyId(agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/agencies/:agencyId/users'),
        method: 'GET',
      },
      { agencyId }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getUserSecurityRoles(userId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userId/roles'),
        method: 'GET',
      },
      { userId }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  // managedUser needs to be a list
  manageSecurityUserRoles(userId, roleIds = []) {
    const postParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userId/roles'),
        method: 'PUT',
      },
      {
        userId,
      },
      roleIds.length && { roleIds }
    );
    return fetchRequest(postParams.URI, postParams.request);
  }

  refreshUserToken(ouId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/refresh-user-token'),
        method: 'POST',
      },
      {},
      {},
      { ouId }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getRolePermissions(roleId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/roles/:roleId/permissions'),
        method: 'GET',
      },
      {
        roleId,
      }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }
}

export default new securityEndpoints();
