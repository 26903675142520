import React from 'react';

import styled from 'styled-components';
import { Row, Col } from 'antd';

import { DIVIDER, GRAY_2 } from '../../../config/theme';
import { xs } from '../../../config/breakpoints';
import Panel, { PanelTitle, Divider } from '../../../components/common/panel';
import MainTitle from '../../../components/common/main-title';
import IconButton from '../../../components/common/buttons/icon-button';
import DashboardPage from '../../../components/dashboard';

const greenFlag = require('../../../icons/ic_flag_green.svg');
const redFlag = require('../../../icons/ic_flag.svg');

const data = {
  officer: 'Ross Evers',
  flag: 'red',
  categories: [
    {
      name: 'Use of Force',
      count: 2,
      timeframe: 'Within 4 weeks',
      complaints: [{ reason: 'Physical Action' }, { reason: 'Taser' }],
    },
    {
      name: 'Citizen Complaints',
      count: 3,
      timeframe: 'Within 12 weeks',
      complaints: [
        { reason: 'Verbal Abuse', status: 'Under Investigation' },
        { reason: 'Excessive Force', status: 'Under Investigation' },
        { reason: 'Failure to Provide Service', status: 'Under Investigation' },
      ],
    },
    {
      name: 'Missed Court Appearances',
      count: 2,
      timeframe: 'Within 12 weeks',
    },
  ],
};

const CustomRow = styled(Row)`
  min-height: 44px;
  line-height: 44px;
  border-bottom: 1px solid ${DIVIDER};

  &:last-child {
    border-bottom: 0;
  }

  .reason,
  .status {
    color: ${GRAY_2};
  }

  .icon {
    text-align: right;
  }
`;

const CustomPanel = styled(Panel)`
  padding: 16px;
  padding-bottom: 0;
`;

const Flag = props => {
  const FlagContainer = styled.div`
    text-align: right;
  `;
  return (
    <FlagContainer>
      <img
        src={props.color === 'red' ? redFlag : greenFlag}
        alt="Flag"
        className="officer-flag"
      />
    </FlagContainer>
  );
};

const Timeframe = styled.div`
  text-align: right;
  color: ${GRAY_2};

  @media screen and (max-width: ${xs}) {
    text-align: left;
    padding-bottom: 16px;
  }
`;

const Complaint = () => {
  return (
    <div>
      <MainTitle>
        Home &gt; Squad 1: Complaints &gt; Officer {data.officer}
      </MainTitle>
      <Row>
        <Col xs={20} sm={20}>
          <PanelTitle>
            <h2>Officer {data.officer}</h2>
          </PanelTitle>
        </Col>
        <Col xs={4} sm={4}>
          <Flag color={data.flag} />
        </Col>
      </Row>
      {data.categories.map((category, catIndex) => {
        return (
          <CustomPanel key={catIndex}>
            <Row>
              <Col xs={24} sm={20}>
                <PanelTitle>
                  <h2>{`${category.count} ${category.name}`}</h2>
                </PanelTitle>
              </Col>
              <Col xs={24} sm={4}>
                <Timeframe>{category.timeframe}</Timeframe>
              </Col>
            </Row>
            {category.complaints && <Divider />}
            {category.complaints &&
              category.complaints.map((complaint, compIndex) => {
                const reasonSizes = {
                  xs: 24,
                  sm: complaint.status ? 10 : 24,
                  md: complaint.status ? 8 : 24,
                };
                const statusSizes = {
                  xs: 24,
                  sm: 14,
                  md: 16,
                };
                return (
                  <CustomRow key={compIndex}>
                    <Col xs={20} sm={22}>
                      <Row>
                        <Col className="reason" {...reasonSizes}>
                          {complaint.reason}
                        </Col>
                        {complaint.status && (
                          <Col className="status" {...statusSizes}>
                            {complaint.status}
                          </Col>
                        )}
                      </Row>
                    </Col>
                    <Col className="icon" xs={4} sm={2}>
                      <IconButton icon="arrow-right" />
                    </Col>
                  </CustomRow>
                );
              })}
          </CustomPanel>
        );
      })}
    </div>
  );
};

const mapState = state => ({
  session: state.session,
});

export default DashboardPage(mapState, null)(Complaint);
