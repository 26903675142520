/**
 * Finds a container object by type.
 *
 * @param {[Object]} fields Object field list.
 * @param {String} type String value that represent the container type.
 */
const findContainersByType = (fields, type, findAll = false) =>
  fields.reduce((acc, r) => {
    const hasProperties = r.properties && r.properties.length > 0;
    if (r.type === type) {
      acc.push(r);
      if (findAll && hasProperties) {
        acc = acc.concat(findContainersByType(r.properties, type, findAll));
      }
    } else {
      if (hasProperties) {
        acc = acc.concat(findContainersByType(r.properties, type, findAll));
      }
    }

    return acc;
  }, []);

export default findContainersByType;
