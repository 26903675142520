import React from 'react';
import moment from 'moment';
import ContentEditable from 'react-contenteditable';

import parseDate, {
  BENCHMARK_DATE_TIME_FORMAT,
} from '../../../../../utils/parse-date';

function renderDate(data, timezone) {
  if (data) {
    const date = parseDate(
      moment.utc(data).toISOString(),
      timezone,
      BENCHMARK_DATE_TIME_FORMAT
    );
    return `${date}`;
  }
  return '';
}

const renderString = value => <ContentEditable html={value} disabled={true} />;

export default timezone => [
  {
    title: 'Case File',
    dataIndex: 'casefileNumber',
    key: 'casefileNumber',
    sorter: false,
    fixed: 'left',
    width: 150,
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
    sorter: false,
    render: data => renderDate(data, timezone),
    width: 200,
  },
  {
    title: 'Executed By',
    dataIndex: 'executedByFullName',
    key: 'executedByFullName',
    render: renderString,
    sorter: false,
    width: 150,
  },
  {
    title: 'Event Type',
    dataIndex: 'eventType',
    key: 'eventType',
    sorter: false,
    width: 150,
  },
  {
    title: 'Section Name',
    dataIndex: 'tableName',
    key: 'tableName',
    sorter: false,
    width: 150,
  },
  {
    title: 'Results',
    dataIndex: 'result',
    key: 'result',
    sorter: false,
    width: 150,
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
    render: renderString,
    sorter: false,
    width: 500,
  },
];
