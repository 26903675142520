import styled from 'styled-components';
import { Button } from 'antd';

import { ACCENT } from '../../../config/theme';

export default styled(Button).attrs({
  className: 'icon-btn',
})`
  &.ant-btn {
    padding: 10px;
    font-size: 20px;
    color: ${ACCENT};
    line-height: normal;
    border: none;

    &:hover {
      border: none !important;
    }
  }
`;
