import { get } from 'lodash';

const mapOfficerNames = (ids = [], source) =>
  ids
    .map(_id => get(source, _id, -1))
    .filter(item => item !== -1)
    .map(item => item.fullName)
    .join(', ');

export default mapOfficerNames;
