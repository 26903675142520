import { get } from 'lodash';

const getFieldValue = (props, fieldKey, defaultValue) => {
  const { parentIndex, parentKey, data } = props;
  let fieldValue;
  if (parentKey) {
    fieldValue = fieldKey.includes('data.')
      ? get(props, fieldKey)
      : get(data, [parentKey, parentIndex, fieldKey]);
  } else {
    fieldValue = get(data, fieldKey);
  }
  return fieldValue || defaultValue;
};

export default getFieldValue;
