import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DashboardPage from '../../components/dashboard';
import SubTitle from '../../components/common/sub-title';
import {
  PanelSection,
  Divider,
  CollapsePanel,
} from '../../components/common/panel';
import BigButton from '../../components/common/buttons/big-button';
import Breadcrumb, {
  Item as BreadcrumbItem,
} from '../../components/common/breadcrumb';
import { ButtonsContainer } from './styled';

// Modal
import showModal from '../../actions/show-modal';
import hideModal from '../../actions/hide-modal';
import updateModal from '../../actions/update-modal';
import createModal from '../../actions/create-modal';
import ModalTitle from '../../components/common/modal/title';
import {
  ModalButton,
  BodyReviewDismiss,
  BodyRecognize,
  SuccessRecognize,
} from '../../components/common/modal/partials/internal-affairs';

const greenFlag = require('../../icons/ic_flag_green.svg');
const redFlag = require('../../icons/ic_flag.svg');

const recognizeActions = [
  {
    name: 'forward-email',
    description: 'Forward via email to Chief for recognition',
    successMessage: 'Forwarded to Chief for recognition',
  },
  {
    name: 'forward-patrol',
    description: 'Forward to Patrol Supervision to issue roll call mention',
    successMessage:
      'Forwarded to Patrol Supervision to issue roll call mention',
  },
  {
    name: 'forward-commander',
    description: 'Forward to Commander to assign Lieutenant for mentorship',
    successMessage:
      'Forwarded to Commander to assign Lieutenant for mentorship',
  },
];

class ExceptionalPerformance extends Component {
  static propTypes = {
    isNeedingReview: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isNeedingReview: false,
  };

  state = {
    loading: false,
  };

  // Review and Dismiss
  onReviewDismiss = values => {
    const { dispatch } = this.props;
    const modalId = 'iacommander-review-reuben';

    const onCancel = () => {
      dispatch(hideModal(modalId));
    };

    const Footer = [
      <ModalButton key="cancel" label="Cancel" onClick={onCancel} />,
      <ModalButton
        key="submit"
        label="Submit"
        type="primary"
        onClick={onCancel}
      />,
    ];

    const options = {
      id: modalId,
      title: (
        <ModalTitle success>
          <h3>Review and Dismiss</h3>
        </ModalTitle>
      ),
      children: BodyReviewDismiss,
      footer: Footer,
      onOk: () => {
        dispatch(hideModal(this.id));
      },
    };

    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  onRecognize = values => {
    const { dispatch } = this.props;
    const modalId = 'iacommander-recognize';
    const successId = 'iacommander-recognize-success';

    let submitRef = null;
    let resetRef = null;

    const onCancel = () => {
      resetRef.click();
      dispatch(hideModal(modalId));
    };
    const onSubmit = (e, formValues) => {
      e.preventDefault();
      const checkedActions = recognizeActions.filter(action =>
        Object.keys(formValues).find(
          k => k === action.name && formValues[action.name] === true
        )
      );

      const Footer = [
        <ModalButton key="cancel" label="Cancel" disabled />,
        <ModalButton
          key="submit"
          label="Submit"
          loading
          disabled
          type="primary"
        />,
      ];

      this.setState({ loading: true }, () => {
        dispatch(
          updateModal({
            id: modalId,
            footer: Footer,
          })
        );
      });

      setTimeout(() => {
        const successOptions = {
          id: successId,
          title: (
            <ModalTitle success>
              <Icon type="check-circle" /> <span>Success!</span>
            </ModalTitle>
          ),
          children: <SuccessRecognize successfulActions={checkedActions} />,
        };

        dispatch(createModal(successOptions));
        dispatch(hideModal(modalId));
        dispatch(showModal(successId));
        resetRef.click();
        this.setState({ loading: false });
      }, 1000);
    };

    const Footer = [
      <ModalButton key="cancel" label="Cancel" onClick={onCancel} />,
      <ModalButton
        key="submit"
        label="Submit"
        type="primary"
        onClick={() => {
          submitRef.click();
        }}
      />,
    ];

    const options = {
      id: modalId,
      title: (
        <ModalTitle success>
          <h3>Recognize</h3>
        </ModalTitle>
      ),
      children: (
        <BodyRecognize
          onSubmit={onSubmit}
          possibleActions={recognizeActions}
          submitRef={el => (submitRef = el)}
          resetRef={el => (resetRef = el)}
        />
      ),
      footer: Footer,
    };

    dispatch(createModal(options));
    dispatch(showModal(modalId));
  };

  render() {
    const { isNeedingReview } = this.props;

    return (
      <div>
        <Breadcrumb separator=">">
          <BreadcrumbItem>Home</BreadcrumbItem>
          <BreadcrumbItem>Exceptional Performance</BreadcrumbItem>
          <BreadcrumbItem>Office Reuben Vasquez</BreadcrumbItem>
        </Breadcrumb>
        <CustomRow type="flex" align="middle">
          <Col span={22} className="text-left">
            <SubTitle>Officer Reuben Vasquez</SubTitle>
          </Col>
          <Col span={2} className="text-right">
            <img
              src={isNeedingReview ? redFlag : greenFlag}
              alt="Flag"
              className="officer-flag"
            />
          </Col>
        </CustomRow>

        <CollapsePanel title="2 Positive Acknowledgements" />
        <CollapsePanel title="2 Complementary Letters from citizens" />
        <CollapsePanel title="1 Department Award Recognition" />
        <CollapsePanel title="22 Arrests" subtitle="Within 100 days">
          <Divider />
          <PanelSection>
            <Row type="flex" align="middle">
              <Col span={21}>
                <p>17 Misdemeanor</p>
              </Col>
              <Col span={3} className="text-right">
                <Link to="#">
                  <Icon type="arrow-right" />
                </Link>
              </Col>
            </Row>
          </PanelSection>
          <Divider />
          <PanelSection>
            <Row type="flex" align="middle">
              <Col span={21}>
                <p>5 Felonies</p>
              </Col>
              <Col span={3} className="text-right">
                <Link to="#">
                  <Icon type="arrow-right" />
                </Link>
              </Col>
            </Row>
          </PanelSection>
        </CollapsePanel>
        <CollapsePanel title="0 Citizen Complaints" />

        <ButtonsContainer>
          <Row type="flex" gutter={16}>
            <Col xs={24} sm={12} className="text-right">
              <BigButton
                type="primary"
                size="default"
                ghost
                icon="check-square-o"
                onClick={this.onReviewDismiss}
              >
                Review & Dismiss
              </BigButton>
            </Col>
            <Col xs={24} sm={12}>
              <BigButton
                ghost
                type="primary"
                size="default"
                icon="star-o"
                onClick={this.onRecognize}
              >
                Recognize
              </BigButton>
            </Col>
          </Row>
        </ButtonsContainer>
      </div>
    );
  }
}

const CustomRow = styled(Row)`
  margin: 24px 0 16px 0;
`;

const mapState = () => ({});

export default withRouter(
  DashboardPage(mapState, null)(ExceptionalPerformance)
);
