import React, { Component } from 'react';
import { Tag, Row, Col } from 'antd';
import { get, omit } from 'lodash';
import { connect } from 'react-redux';
import { DragSource } from 'react-dnd';
import cx from 'classnames';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import withModal from 'APP_COMPONENTS/common/modal/base';

import Styled from './InjuriesBodyEditor.styled';
import PickableZone from '../PickableZone';
import IconButton from '../../../../components/common/buttons/icon-button';
import componentsManager from '../../services/componentsManager';
import { INJURIES_BODY } from 'APP_ROOT/constants/layoutComponentTypes';
import getFieldLabel from '../../utils/getFieldLabel';

import buildOptionsModalContent from '../../utils/buildOptionsModalContent';
import getTitle from '../../utils/getTitle';
import setTitle from '../../utils/setTitle';

import {
  TAB_VALUES,
  TAB_CONDITIONAL,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
} from '../OptionsModalBody/OptionsModalBody';

class InjuriesBodyEditor extends withModal(Component) {
  constructor(props) {
    super(props);

    this.state = {
      values: props.field,
    };
  }

  componentDidMount() {
    this.createModal();
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(this.props, nextProps);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      values: props.field,
    };
  }

  remove = () => {
    const { id } = this.props;
    componentsManager.removeComponent(id);
  };

  getValue = (name, defaultValue) =>
    get(this, `state.values.${name}`, defaultValue) || defaultValue;

  showOptionsModal = () => {
    const { values } = this.state;
    const { field } = this.props;

    const fieldsOptions = {
      id: values.id,
      fieldKey: values.key,
      type: values.type,
      label: getTitle(values.title, ''),
      conditions: values.conditions,
      disableTab: [TAB_VALUES, TAB_CONDITIONAL, TAB_SHARE_KEY, TAB_INFORMATION],
    };

    const modalData = buildOptionsModalContent({
      ...fieldsOptions,
      onCancel: this.deleteModal,
      onDelete: () => {
        this.remove();
        this.deleteModal();
      },
      onSave: params => {
        const modifiedValues = Object.assign(
          {},
          omit(values, ['tile']),
          omit(params, ['label']),
          {
            title: setTitle(values.title, params.label),
          }
        );

        this.setState({
          values: omit(modifiedValues, ['validations']),
        });

        componentsManager.editComponent(field.id, modifiedValues);

        this.deleteModal();
      },
    });

    this.updateModal(modalData);

    this.showModal();
  };

  render() {
    const {
      children,
      type,
      id,
      connectDragSource,
      connectDragPreview,
      isDragging,
    } = this.props;

    return connectDragPreview(
      <div>
        <Styled className={cx({ 'is-dragging': isDragging })}>
          <PickableZone type={type} id={id}>
            {connectDragSource(
              <div className="injuries--header">
                <Row type="flex" align="middle">
                  <Col>
                    <Tag className="uppercase">
                      {getFieldLabel(INJURIES_BODY)}
                    </Tag>
                  </Col>
                  <Col className="margin-left">
                    <IconButton
                      icon="setting"
                      onClick={this.showOptionsModal}
                      className="is-big is-focusable"
                    />
                  </Col>
                </Row>
                <hr />
              </div>
            )}
            <Row className="read-only injuries--detail">{children}</Row>
          </PickableZone>
        </Styled>
      </div>
    );
  }
}

InjuriesBodyEditor.displayName = 'FieldEditor.InjuriesBody';

export default connect()(
  DragSource(
    params => params.type,
    {
      beginDrag: param => param.field,
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    })
  )(InjuriesBodyEditor)
);
