import { camelCase } from 'lodash';
import { PERMISSIONS } from '../../../utils/admin';
import AgencyAuditViewLogs from './AgencyAuditViewLogs';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/view-audit-logs/:tenantId`,
    name: camelCase(`${name}-view-audit-logs`),
    component: AgencyAuditViewLogs,
    protectedWith: [
      PERMISSIONS.viewAgencyAuditLogs,
      PERMISSIONS.viewAllAuditLogs,
    ],
    childRoutes: [],
  },
];
