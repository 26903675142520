import CommentEndpoints from '../../../../api/organization/commentEndpoints';
import parseDate from '../../../../utils/parse-date';

export default (ouId, getSuppress = false, timezone = '') => {
  return new Promise((resolve, reject) => {
    CommentEndpoints.getOrganizationalUnitComments(ouId, getSuppress)
      .then(response => {
        const list = response.content?.map(ou => {
          const {
            id,
            comment,
            createdBy,
            createdAt,
            updatedAt,
            updatedBy,
            suppress,
          } = ou;

          let formattedCreatedBy =
            parseDate(createdAt, timezone) + ' by ' + createdBy;
          let formattedUpdatedAt = updatedAt
            ? parseDate(updatedAt, timezone) + ' by ' + updatedBy
            : '';

          return {
            id,
            comment,
            createdBy: createdBy,
            created: formattedCreatedBy,
            updated: formattedUpdatedAt,
            suppress,
          };
        });
        resolve({ data: list || [], error: null });
      })
      .catch(error => {
        return reject(error.message);
      });
  });
};
