import React from 'react';
import ModalBody from 'APP_ROOT/components/common/modal/body';

const getMoreInfoLinked = (
  activeReviewerRank,
  activeReviewerfullName,
  linkedSubmission,
  formType
) => {
  const {
    formType: linkedFormType,
    formNumber: linkedFormNumber,
  } = linkedSubmission;

  return (
    <ModalBody>
      <div className="linked-content">
        <p>
          You have Requested More Info for{' '}
          <span
            dangerouslySetInnerHTML={{
              __html: `${activeReviewerRank} ${activeReviewerfullName} ${formType}`,
            }}
          />{' '}
          report. This report involves a {linkedFormType} {linkedFormNumber}.
        </p>
        <p>
          Both reports will be returned to{' '}
          <span
            dangerouslySetInnerHTML={{
              __html: `${activeReviewerRank} ${activeReviewerfullName}`,
            }}
          />
        </p>
      </div>
    </ModalBody>
  );
};

export default getMoreInfoLinked;
