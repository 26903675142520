import { isEmpty, omit } from 'lodash';

const removeEnumRef = (enums, enumRef, parentEnum) => {
  if (enumRef) {
    if (parentEnum) {
      let [parent] = enums[parentEnum];
      if (parent[enumRef]) {
        parent = omit(parent, [enumRef]);
        if (isEmpty(parent)) {
          return omit(enums, [parentEnum]);
        } else {
          return {
            ...enums,
            [parentEnum]: [{ ...parent }],
          };
        }
      }
    } else {
      if (enums[enumRef]) {
        return omit(enums, [enumRef]);
      }
    }
  }
  return enums;
};

export default removeEnumRef;
