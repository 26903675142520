import { notification } from 'antd';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';

const deleteEHRecord = (userId, historyId, employmentHistoryId, filters) => {
  return new Promise((resolve, reject) => {
    if (!userId || !historyId || !employmentHistoryId)
      reject({ data: null, error: 'Missing required data' });

    organizationEndpoints
      .deleteUserEmploymentHistory(
        userId,
        historyId,
        employmentHistoryId,
        filters
      )
      .then(() => {
        resolve({ data: 'ok', error: null });
        notification.success({
          message: 'Success',
          description: `The employment record was removed successfully.`,
        });
      })
      .catch(err => {
        reject({ data: null, error: err });
        notification.error({
          message: 'Heads up!',
          description: 'The employment record was not possible to remove.',
        });
      });
  });
};

export default deleteEHRecord;
