import { get, has } from 'lodash';

export const mapExact = (result, item) => [...result, item];

export const mapToString = item => {
  if (has(item, 'value')) {
    return get(item, 'value', '');
  }

  return item;
};

export const mapAsPlain = (result, item) => {
  if (has(item, 'enums')) {
    return [...result, ...get(item, 'enums', []).map(mapToString)];
  }

  return [...result, mapToString(item)];
};
