import React, { useCallback, useEffect, useState } from 'react';
import getTenantCategories from '../../../agency-equipment/actions/get-tenant-categories';

const useCategoriesData = ({ tenantId, equipmentRecord = {}, dispatch }) => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const { equipment: { id } = {} } = equipmentRecord;

  const fetchCategories = () => {
    setLoading(true);
    dispatch(
      getTenantCategories({
        tenantId: tenantId,
        next: callbackCategories,
      })
    );
  };

  const callbackCategories = useCallback((error, data) => {
    setLoading(false);
    if (error || !data || data['success'] !== true) {
      return;
    }
    setCategories(data.content.categories);
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [tenantId, id]);

  return { loading, categories, fetchData: fetchCategories };
};

export default useCategoriesData;
