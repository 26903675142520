const sortTabs = (tabs, order, key = 'key') => {
  return tabs.slice().sort((a, b) => {
    const orderA = order.indexOf(a[key]);
    const orderB = order.indexOf(b[key]);

    if (orderA !== -1 && orderB !== -1) {
      return orderA - orderB;
    }
    if (orderA !== -1) {
      return -1;
    }
    if (orderB !== -1) {
      return 1;
    }

    const ia = tabs.indexOf(a);
    const ib = tabs.indexOf(b);

    return ia - ib;
  });
};

export default sortTabs;
