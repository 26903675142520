import React, { useState } from 'react';
import { notification } from 'antd';
import { get } from 'lodash';
import { hasPermissions, PERMISSIONS } from '../../../../../utils/admin';
import CustomSectionTable from '../../../../../components/custom-sections/tables/CustomSectionTable';
import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from '../../../../../components/custom-sections/sections/Sections.styled';
import {
  ATTRIBUTE_TYPE_STRING,
  ATTRIBUTE_TYPE_LINK,
} from '../UserProfile.constants';

const UserReports = props => {
  const [recordId, setRecordId] = useState(0);
  const [data, setData] = useState({});

  const sectionAttributes = [
    {
      attributeName: 'reportName',
      type: ATTRIBUTE_TYPE_LINK,
      title: 'Report Name',
      settings: { tableColumn: 2 },
    },
    {
      attributeName: 'submittedDate',
      type: ATTRIBUTE_TYPE_STRING,
      title: 'Date',
      settings: { tableColumn: 3 },
    },
    {
      attributeName: 'status',
      type: ATTRIBUTE_TYPE_STRING,
      title: 'Review Status',
      settings: { tableColumn: 4, defaultValue: null },
    },
  ];

  const {
    profileForm,
    editSection,
    updateSectionField,
    addSectionFieldValue,
    removeSectionFieldValue,
    setEditSectionTable,
    userProfile,
    isSectionReadOnly = false,
  } = props;

  const onActionCallback = (record, _, __, callback) => {
    const { key } = record;
    const data = get(profileForm, `values.userReportsData[${key}]`);

    setRecordId(record.key);
    setData(data);
    callback();
  };

  const onCancelCallback = () => {
    updateSectionField(`userReportsData[${recordId}]`, data);
  };

  const gotoReport = (e, index) => {
    e.preventDefault();
    const { history, currentUser } = props;
    const { permissions = [] } = currentUser;

    // The report page is restricted by these permissions see /src/router/Router.js
    const canViewReportPage = hasPermissions(permissions, [
      PERMISSIONS.submitUOFReport,
      PERMISSIONS.submitVPReport,
      PERMISSIONS.submitDORReport,
      PERMISSIONS.submitIACReport,
      PERMISSIONS.submitIAIReport,
      PERMISSIONS.reviewUOFReport,
      PERMISSIONS.reviewVPReport,
      PERMISSIONS.reviewDORReport,
      PERMISSIONS.reviewIAIReport,
      PERMISSIONS.reviewIACReport,
      PERMISSIONS.viewAllUOFReports,
      PERMISSIONS.viewAllVPReports,
      PERMISSIONS.viewAllDailyObservationReports,
      PERMISSIONS.viewOwnDailyObservationReports,
      PERMISSIONS.viewAllInvestigations,
      PERMISSIONS.viewAllIntakes,
      PERMISSIONS.viewCaseLoad,
      PERMISSIONS.viewAllReports,
      PERMISSIONS.submitReport,
      PERMISSIONS.reviewReport,
      PERMISSIONS.viewReports,
      PERMISSIONS.listOUReportsByHK,
    ]);

    if (canViewReportPage) {
      const reportId = get(
        profileForm,
        `values.userReportsData.reports.reportId`
      )[index];
      const reportUrl = `/reports/${reportId}`;
      history.push(reportUrl);
    } else {
      notification.warning({
        message: 'Missing permissions!',
        description:
          "Sorry you currently don't have permission to access the report.",
      });
    }
  };

  return (
    <StyledCollapse
      bordered={false}
      defaultActiveKey="reports"
      expandIconPosition="right"
    >
      <StyledPanel
        header={<StyledPanelTitle size="large">Reports</StyledPanelTitle>}
        key="reports"
        className="section-panel"
      >
        <hr></hr>
        <CustomSectionTable
          key="reports"
          modalTitle=""
          sectionId="reports"
          prefix="userReportsData"
          attributeId="reportId"
          attributes={sectionAttributes}
          mainForm={profileForm}
          editSection={editSection}
          addSectionFieldValue={addSectionFieldValue}
          updateSectionField={updateSectionField}
          removeSectionFieldValue={removeSectionFieldValue}
          setEditSectionTable={setEditSectionTable}
          modalFor=""
          onAction={onActionCallback}
          hasAction={false}
          onCancel={onCancelCallback}
          userProfile={userProfile}
          linkAction={gotoReport}
          isSectionReadOnly={isSectionReadOnly}
        />
      </StyledPanel>
    </StyledCollapse>
  );
};

export default UserReports;
