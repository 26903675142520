import { genOptions, route, fetchRequest } from '../utils/request';
import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';

export const registerNewDocument = (organizationalUnitId = '') => {
  const url = route(urlBuilder('/OrganizationalUnitDocuments'));
  const options = genOptions(
    'POST',
    { organizationalUnitId },
    storage.get('token')
  );

  return fetchRequest(url, options);
};

export const deleteDocument = (id = '', attachmentId = '') => {
  const url = route(
    urlBuilder('/OrganizationalUnitDocuments/:id/attachments/:attachmentId', {
      id,
      attachmentId,
    })
  );
  const options = genOptions('DELETE', null, storage.get('token'));
  return fetchRequest(url, options);
};
