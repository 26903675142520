import React from 'react';
import { hasPermissions, PERMISSIONS } from '../../../../../utils/admin';
import { FEATURES, hasFeatures } from '../../../../../utils/features';
import { userEquipmentTableColumns } from './UserEquipmentTableColumns';

export const userEquipmentConfigs = session => {
  const { featureFlags = [], permissions = [] } = session.currentUser || {};

  const manageEquipment = hasPermissions(permissions, [
    PERMISSIONS.manageEquipment,
  ]);

  const agencyFeature = hasFeatures(
    featureFlags,
    FEATURES.userEquipmentSection
  );

  if (!agencyFeature) return null;

  return {
    isEditable: manageEquipment,
  };
};

export const prepareEquipmentColumns = (configs, onClick) => {
  const columns = [
    ...userEquipmentTableColumns,
    {
      title: 'Details',
      key: 'action',
      render: (text, record) => (
        <span>
          <a onClick={() => onClick(text, record)}>Detail</a>
        </span>
      ),
    },
  ];

  return {
    ...configs,
    columns,
  };
};
