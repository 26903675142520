import React from 'react';

import DashboardPage from 'APP_COMPONENTS/dashboard';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import getRoute from 'APP_ROOT/utils/get-route';
import AdministratorWrapper from '../Administrator.styled';
import withOwnershipGuard from '../components/agency-ownership-guard';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import CasefileAuditLogs from './components/casefileAuditLogs/CasefileAuditLogs';
import { PageBody } from './AgencyCasefileAuditLogs.styled';

const AgencyCasefileAuditLogs = ({ match, timezone }) => {
  const { params } = match;
  const { agencyId } = params;
  return (
    <AdministratorWrapper>
      <PageHeader
        title="Case Files Audit Logs"
        goBackTo={getRoute('administratorAgency', { agencyId })}
      ></PageHeader>
      <PageBody>
        <CasefileAuditLogs timezone={timezone} agencyId={agencyId} />
      </PageBody>
    </AdministratorWrapper>
  );
};

export default withoutClutter(
  DashboardPage()(
    withOwnershipGuard(
      AgencyCasefileAuditLogs,
      'administratorAgencyCasefileAuditLogs'
    )
  )
);
