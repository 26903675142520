import { get } from 'lodash';

const errorMessages = {
  fields: {
    email: 'Email',
    employeeId: 'Employee ID',
    sworn: 'Sworn',
    appointmentDate: 'Date of Appointment',
    agencyId: 'Agency',
    rankId: 'Rank',
    gender: 'Gender',
    race: 'Race',
    username: 'Username',
  },
  codes: {
    presence: 'is required',
    uniqueness: 'should be unique',
    inclusion: 'is invalid',
    'custom.email': 'is invalid',
    'unknown-property': 'is not part of the model',
  },
};

const getFieldErrorMessage = (fieldName, code) =>
  [
    get(errorMessages, `fields.${fieldName}`),
    get(errorMessages, `codes.${code}`),
  ].join(' ');

export default getFieldErrorMessage;
