import { get } from 'lodash';

import {
  FIELD,
  REPEATER,
  UPLOAD,
} from 'APP_ROOT/constants/layoutComponentTypes';

import verifyCalculated from './verifyCalculated';
import verifyStringType from './verifyStringType';
import verifyFieldRule from './verifyFieldRule';

const pushFixed = (array, fixed, key) => {
  if (fixed) {
    array.push(key);
  }
  return array;
};

const validateComponentsTree = (
  fields,
  validations,
  isNote,
  allAutoCompleteFieldsByKey,
  parentKey
) =>
  fields.reduce(
    (allFields, currentField) => {
      const { type, key } = currentField;
      if (type === FIELD) {
        const { calculated, stringType, added } = allFields;
        let fixedCalculated;
        let [newField, fixedString] = verifyStringType(currentField);
        [newField, fixedCalculated] = verifyCalculated(
          newField,
          allAutoCompleteFieldsByKey
        );
        const properties = allFields.properties.concat(newField);
        const [newValidations, fixedRule] = verifyFieldRule(
          newField,
          allFields.validations,
          isNote,
          parentKey
        );
        return {
          properties,
          validations: newValidations,
          calculated: pushFixed(calculated, fixedCalculated, key),
          stringType: pushFixed(stringType, fixedString, key),
          added: pushFixed(added, fixedRule, key),
        };
      } else {
        const pKey = [REPEATER, UPLOAD].includes(type)
          ? { type, key }
          : parentKey;
        const properties = get(currentField, 'properties', []);
        const newFields = validateComponentsTree(
          properties,
          allFields.validations,
          isNote,
          allAutoCompleteFieldsByKey,
          pKey
        );
        const field = {
          ...currentField,
          properties: newFields.properties,
        };
        return {
          properties: allFields.properties.concat(field),
          validations: newFields.validations,
          calculated: allFields.calculated.concat(newFields.calculated),
          stringType: allFields.stringType.concat(newFields.stringType),
          added: allFields.added.concat(newFields.added),
        };
      }
    },
    { validations, properties: [], calculated: [], stringType: [], added: [] }
  );

export default validateComponentsTree;
