import { FETCH_FORGOT_PASSWORD_REQUEST } from './';

import forgotPasswordSuccess from './forgot-password-success';
import forgotPasswordError from './forgot-password-error';

import { forgotPassword } from '../api/session';

export default (values, next) => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_FORGOT_PASSWORD_REQUEST,
      payload: values,
    });
    return forgotPassword({ values })
      .then(response => {
        dispatch(forgotPasswordSuccess({ success: response }));
        next(null, response);
      })
      .catch(error => {
        if (error.response) {
          error.response
            .json()
            .then(body => dispatch(forgotPasswordError(body)));
          next(error);
        }
      });
  };
};
