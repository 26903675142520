export const PHONE_NUMBER = 'phone-number';
export const EMAIL = 'email';
export const PHONE_NUMBER_LABEL = 'Phone number';
export const EMAIL_LABEL = 'Email';
export const SSN_TYPE = 'ssn';
export const SSN_LABEL = 'SSN';
export const MASK_TYPE = 'mask_type';
export const MASK_TYPE_LABEL = 'Mask Type';
export const OPTIONS_TYPE = 'options';
export const SIMPLE_TYPE = 'simple';
export const PATTERN = 'pattern';
export const STRING = 'string';
export const REGEX_TYPE = 'regex';
export const REGEX_LABEL = 'Regular Expression';
export const INVALID_REGEX_MESSAGE = 'Invalid regular expression.';
export const REGEX_FLAGS = 'gm';
