import React from 'react';

import SliderPanel from '../form-slider/index';
import ActivityInfoPanel from './activity-info-panel';
import ActivityGraphPanel from './activity-graph-panel';

import data from '../../mockup_data/activity';

export default ({ timezone }) => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <SliderPanel title="Activity" data={data} timezone={timezone}>
        <ActivityInfoPanel />
        <ActivityGraphPanel />
      </SliderPanel>
    </div>
  );
};
