import React from 'react';
import { Button } from 'antd';
import uuid from 'uuid/v4';

import getAttributeDefaultValue from './getAttributeDefaultValue';
import { filter, get, isEmpty } from 'lodash';
import getEHRecords from '../../../../components/custom-sections/tables/getEHRecords';

const SectionAddButton = ({
  sectionId,
  attributeId,
  attributes,
  profileForm,
  addSectionFieldValue,
  setEditSectionTable,
  buttonTitle = null,
  prefix,
  disabled = false,
  mainForm,
}) => {
  const addRow = event => {
    event.stopPropagation();

    const generateDefaultValue = (
      attributeName,
      type,
      validValues,
      settings = {}
    ) => {
      const { defaultValue, unique = false } = settings;
      let takenValues;
      if (unique) {
        takenValues = get(profileForm, `values.${section}[${attributeName}]`);
      }
      const value =
        defaultValue !== undefined
          ? defaultValue
          : getAttributeDefaultValue(type, validValues, takenValues);
      addSectionFieldValue(`${section}[${attributeName}]`, value);
    };

    const key = uuid();
    const section = `${prefix}[${sectionId}]`;

    if (!isEmpty(mainForm)) {
      const { dataSource } = getEHRecords(
        mainForm,
        prefix,
        sectionId,
        attributeId,
        attributes
      );
      const lastRecord = dataSource.at(0);

      if (!isEmpty(lastRecord)) {
        attributes.forEach(
          ({ attributeName, type, validValues, settings = {} }) => {
            if (
              ![
                'updated',
                'employmentAction',
                'comment',
                'effectiveDate',
                'role',
              ].includes(attributeName)
            ) {
              addSectionFieldValue(
                `${section}[${attributeName}]`,
                lastRecord[attributeName]
              );
            } else if (attributeName === 'role') {
              if (lastRecord[attributeName]) {
                const foundRoles = filter(
                  lastRecord[attributeName].split(';'),
                  r => r
                ); // EM-981: remove empty and invalid strings
                addSectionFieldValue(
                  `${section}[${attributeName}]`,
                  foundRoles
                );
              } else {
                generateDefaultValue(
                  attributeName,
                  type,
                  validValues,
                  settings
                );
              }
            } else if (attributeName !== 'effectiveDate') {
              generateDefaultValue(attributeName, type, validValues, settings);
            }
          }
        );
      } else {
        attributes.forEach(
          ({ attributeName, type, validValues, settings = {} }) => {
            generateDefaultValue(attributeName, type, validValues, settings);
          }
        );
      }
    } else {
      attributes.forEach(
        ({ attributeName, type, validValues, settings = {} }) => {
          generateDefaultValue(attributeName, type, validValues, settings);
        }
      );
    }

    addSectionFieldValue(`${section}[${attributeId}]`, key);
    setEditSectionTable(sectionId, key);
  };

  return (
    <Button
      type="primary"
      size="default"
      disabled={disabled}
      ghost
      onClick={addRow}
    >
      {buttonTitle || 'Add Record'}
    </Button>
  );
};

export default SectionAddButton;
