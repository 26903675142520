import React from 'react';
import ModalBody from 'APP_ROOT/components/common/modal/body';

const getModalBody = (props = {}, message = '') => (
  <ModalBody {...props}>
    <div
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    />
  </ModalBody>
);

export default getModalBody;
