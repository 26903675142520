import styled from 'styled-components';

import { DIVIDER } from '../../../config/theme';

const Repeater = styled.div.attrs({
  className: 'bdm-form-repeater',
})`
  .disable {
    pointer-events: none;
    opacity: 0.4;
    cursor: not-allowed;
  }

  @media screen {
    .bdm-form-repeater-item-section,
    .bdm-form-repeater-item-section-wrapper,
    .bdm-form-repeater-item-row {
      position: relative;

      .repeater-remove-item {
        height: 20px;
        text-align: right;

        .btn-repeater-delete {
          padding: 3px 0;
          z-index: 1;
        }
      }
    }

    .bdm-form-repeater-item-row:first-child {
      .bdm-form-section {
        border-bottom: 1px solid ${DIVIDER};

        &.top-bordered {
          border-bottom: none;
        }
      }
    }

    .bdm-form-repeater-item-section {
      &:last-child {
        .bdm-form-section {
          border-bottom: none;
        }
      }
    }

    .bdm-section-wrapper-last {
      .bdm-form-section:last-child {
        border-bottom: none;
      }
    }

    .bdm-form-repeater-item-row:last-child {
      .bdm-form-section {
        border-bottom: none;
      }
    }

    .bdm-section-wrapper-last:not(..bdm-section-wrapper-first) {
      margin: 0;
    }

    /* Override remove button top
    TODO: refactor button style outside repeater wrapper
  */
    .bdm-form-repeater-item-section-wrapper {
      .btn-repeater-delete {
        top: 15px;
      }
    }

    .bdm-repeater-footer {
      padding: 0 16px;
    }
  }
`;

export default Repeater;
