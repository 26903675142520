import { flatMap, compact, unionWith } from 'lodash';
import {
  CHECKBOX,
  NUMBER,
  TEXT,
  MULTISELECT,
} from 'APP_ROOT/constants/fieldTypes';
import {
  FIELD,
  REPEATER,
  SECTION,
} from 'APP_ROOT/constants/layoutComponentTypes';

// get all the fields in a flat structure and by tabs
// when addExtra is true, isReviewer and isHidden are
// added to fieldsByKey
const getAllFieldsFlat = (fields, currentFieldId, addExtra = false) => {
  const getField = f => {
    if (f.type === FIELD) {
      return f.id === currentFieldId ? null : f;
    } else if (
      (f.type === REPEATER || f.type === SECTION) &&
      f.id === currentFieldId
    ) {
      return null;
    } else if (f.properties) {
      return flatMap(f.properties, getField);
    }
    return null;
  };

  // to check if prop is equal
  const isPropEqual = (p1, p2) => p1.key === p2.key;
  const allTabs = flatMap(fields, t => ({
    title: t.title,
    key: t.key,
    properties: unionWith(
      compact(flatMap(t.properties, getField)),
      isPropEqual
    ),
  }));
  let fieldsByKey = {};

  if (addExtra) {
    allTabs.push({
      title: 'Global variable',
      key: 'global-key',
      properties: [
        {
          field_type: CHECKBOX,
          title: 'Is Reviewer',
          id: 'isReviewer',
          key: 'isReviewer',
        },
        {
          field_type: CHECKBOX,
          title: 'Is Hidden',
          id: 'isHidden',
          key: 'isHidden',
        },
        {
          field_type: MULTISELECT,
          title: 'Action',
          id: 'action',
          key: 'action',
        },
        {
          field_type: NUMBER,
          title: 'Index',
          id: 'index',
          key: 'index',
        },
      ],
    });

    // for now this should be ok
    fieldsByKey = {
      isReviewer: {
        field_type: CHECKBOX,
        id: 'isReviewer',
        key: 'isReviewer',
      },
      isHidden: {
        field_type: CHECKBOX,
        id: 'isHidden',
        key: 'isHidden',
      },
      action: {
        field_type: TEXT,
        id: 'action',
        key: 'action',
      },
    };
  }

  allTabs.forEach(tab => {
    if (tab.properties) {
      fieldsByKey = tab.properties.reduce((hash, obj) => {
        hash[obj.key] = obj;
        return hash;
      }, fieldsByKey);
    }
  });

  return { allTabs, fieldsByKey };
};

export default getAllFieldsFlat;
