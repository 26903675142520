import { encodeFilter } from '../utils/request';

export const firearmCertificationFilter = encodeFilter({
  fields: ['formatName', 'name', 'description', 'id'],
  where: {
    and: [
      { formatName: { ilike: encodeURIComponent('%Certification%') } },
      { name: { ilike: encodeURIComponent('%firearm%') } },
    ],
  },
});

export const nonFirearmCertificationFilter = encodeFilter({
  fields: ['formatName', 'name', 'description', 'id'],
  where: {
    and: [
      { formatName: { ilike: encodeURIComponent('%Certification%') } },
      { name: { nilike: encodeURIComponent('%firearm%') } },
    ],
  },
});

export const certificationFilterByStatus = encodeFilter({
  where: {
    or: [
      {
        statusName: {
          inq: ['Completed', 'Withdrawn'],
        },
      },
      { completionDate: { neq: null } },
    ],
  },
  fields: {
    userId: true,
    courseId: true,
    initialLaunchDate: true,
    lastLaunchDate: true,
    statusName: true,
    postAssessmentPercentage: true,
    id: true,
    completionDate: true,
    certificateType: true,
  },
  include: {
    relation: 'course',
    scope: {
      fields: [
        'formatName',
        'name',
        'description',
        'id',
        'courseLink',
        'kmiId',
        'completionDate',
      ],
    },
  },
});

export const classesFilter = encodeFilter({
  fields: ['formatName', 'name', 'description', 'id'],
  where: { formatName: { nilike: encodeURIComponent('%Certification%') } },
});

export const defaultTemplatesFilter = {
  where: {
    hasWorkflow: { neq: false },
    isEnable: { neq: false },
    isNote: { neq: true },
    isPresentation: { neq: true },
  },
};

export const officeProfileTemplatesFilter = {
  isSystem: { neq: false },
  hasWorkflow: { neq: true },
  isNote: { neq: true },
  isPresentation: { neq: false },
};
