import scrollToComponent from 'react-scroll-to-component';
import {
  get,
  set,
  keys,
  pick,
  isEmpty,
  isArray,
  last,
  isPlainObject,
  has,
  cloneDeep,
} from 'lodash';

import store from 'APP_ROOT/store';
import submitForm from 'APP_ROOT/actions/submit-form';
import requestFormSubmit from 'APP_ROOT/actions/events/request-form-submit';
import syncFormValidation from 'APP_ROOT/actions/sync-form-validations';
import {
  getTabFieldsError,
  getRequirementComplimentFieldsError,
} from 'APP_ROOT/utils/validations';

import getAllFieldsFlat from '../../../../modules/FormBuilder/utils/getAllFieldsFlat';
import { MATH } from '../../../../constants/fieldTypes';
import computeMathFieldValue from '../../field-types/computeMathFieldValue';
import saveFieldDataValue from '../../field-types/saveFieldDataValue';
import { getErrors } from './get-errors-utils';

const takeAction = props => {
  const { scrollTo } = props;
  scrollToComponent(scrollTo);
};

const handleErrors = ({
  props,
  errors,
  getRequirementComplimentErrors,
  fieldsByKey,
  showErrorConfirmation,
}) => {
  const { dispatch, selectedForm, form } = props;
  const requirementErrorsByTab = getRequirementComplimentErrors.reduce(
    (errors, error) => ({
      ...(errors || {}),
      [error.tabIndex]: [...(errors[error.tabIndex] || []), error],
    }),
    {}
  );
  const bulkErrors = errors.map((tab, tabIndex) => [
    ...tab.errors,
    ...(requirementErrorsByTab[tabIndex] || []),
  ]);

  const allErrors = errors
    .map(tab => tab.fields)
    .reduce((result, tab, index) => {
      return { ...result, ...tab };
    }, {});

  // since we have errors and they weren't detected in the form
  // validation method, we need to tell the form about them, this
  // way the form will show up the error messages.
  // First create the object with the field values and errors
  const keys = Object.keys(allErrors);
  const { data = {} } = selectedForm;
  const values = pick(data, keys);
  const allFields = keys.reduce(
    (result, key) => ({
      ...result,
      [key]: { value: values[key] || '', ...allErrors[key] },
    }),
    {}
  );
  // now update the form fields
  form.setFields(allFields);
  const errorList = getErrors(allErrors, fieldsByKey);

  dispatch(syncFormValidation(allErrors, bulkErrors));
  showErrorConfirmation(errorList);
  return false;
};

const validate = async (props, showErrorConfirmation, validateFields) => {
  const { onSubmit, dispatch, selectedForm } = props;
  const { presentation = {} } = selectedForm;

  const {
    template: {
      formSchema: {
        form: { properties = [] },
      },
    },
  } = selectedForm;
  const { fieldsByKey = {} } = getAllFieldsFlat(properties);
  // clear previous errors first
  dispatch(syncFormValidation(null, null, true));

  validateFields();
  const preparedFieldsValidation = await getTabFieldsError(props);
  const getRequirementComplimentErrors = getRequirementComplimentFieldsError({
    ...props,
    forceValidate: true,
  });
  dispatch(requestFormSubmit());

  const {
    form: {
      selectedForm: { state = {} },
    },
  } = store.getState();
  // in case validateFields had found an error, the state should
  // have it, then check that first
  let hasErrors = Object.values(state).some(s => s.errors);
  if (hasErrors) {
    showErrorConfirmation(getErrors(state, fieldsByKey));
    return false;
  }

  const errors = await Promise.all(
    keys(presentation.fields).map(async tab => preparedFieldsValidation(tab))
  );

  hasErrors = errors.some(e => !isEmpty(e.errors));
  if (hasErrors || getRequirementComplimentErrors.length) {
    return handleErrors({
      props,
      errors,
      getRequirementComplimentErrors,
      fieldsByKey,
      showErrorConfirmation,
    });
  }

  dispatch(syncFormValidation(null, null, true));
  dispatch(submitForm());
  onSubmit();
};

const computeMathValue = ({
  data,
  key,
  mathFields,
  parentKey = '',
  parentIndex = 0,
}) => {
  const field = mathFields[key];
  let value;
  if (field) {
    value = computeMathFieldValue({
      data,
      options: field.options,
      parentKey,
      parentIndex,
    });
    const dataKey = parentKey ? `${parentKey}[${parentIndex}].${key}` : key;
    const oldValue = get(data, dataKey);
    if (oldValue !== value) {
      set(data, dataKey, value);
      saveFieldDataValue(
        {
          parentIndex,
          parentKey,
          settings: { formName: 'form' },
          dataKey: key,
        },
        value,
        false
      );
    }
  }
  return value;
};

const getParentKey = (data, key) => {
  let parentKey = '';
  Object.keys(data).some(dataKey => {
    if (isArray(data[dataKey])) {
      const [item] = data[dataKey];
      parentKey = isPlainObject(item) && has(item, key) ? dataKey : '';
      return parentKey !== '';
    }
    return false;
  });
  return parentKey;
};

const computeMath = async (data, mathKeys, mathFields) => {
  mathKeys.forEach(async key => {
    if (!has(data, key)) {
      const parentKey = getParentKey(data, key);
      const repeater = get(data, parentKey, []);
      repeater.forEach(async (rep, parentIndex) => {
        await computeMathValue({
          data,
          key,
          mathFields,
          parentKey,
          parentIndex,
        });
      });
    } else {
      await computeMathValue({
        data,
        key,
        mathFields,
      });
    }
  });
};

const getMathFieldKeys = mathFields => {
  let pos = 0;
  const keys = Object.keys(mathFields);
  const len = keys.length;

  while (pos < len) {
    const key = keys[pos];
    const {
      options: { operands: operands = [] },
    } = mathFields[key];
    const mathOperands = operands
      .map(o => last(o.split('.')))
      .filter(o => keys.includes(o));
    const newPos = mathOperands.reduce((i, o) => {
      const index = keys.indexOf(o);
      return i < index ? index : i;
    }, -1);
    if (newPos > pos) {
      keys.splice(pos, 1);
      keys.splice(newPos, 0, key);
    } else {
      pos++;
    }
  }
  return keys;
};

const validateMathFields = async props => {
  const {
    selectedForm: {
      data,
      template: {
        formSchema: {
          form: { properties = [] },
        },
      },
    },
  } = props;
  const { fieldsByKey = {} } = getAllFieldsFlat(properties);
  const mathFields = Object.values(fieldsByKey)
    .filter(f => f.field_type === MATH)
    .reduce(
      (byKey, field) => ({
        ...byKey,
        [field.key]: field,
      }),
      {}
    );

  if (!isEmpty(mathFields)) {
    const mathKeys = getMathFieldKeys(mathFields);
    await computeMath(cloneDeep(data), mathKeys, mathFields);
  }
};

const onConfirmSubmit = async (
  props,
  showErrorConfirmation,
  validateFields
) => {
  const {
    onSubmit,
    dispatch,
    isReviewer = false,
    shouldValidate = true,
  } = props;

  if (isReviewer) {
    takeAction(props);

    return false;
  }

  if (shouldValidate) {
    await validateMathFields(props);
    await validate(props, showErrorConfirmation, validateFields);
  } else {
    dispatch(submitForm());
    onSubmit();
  }
};

export default onConfirmSubmit;
