import getFieldValue from './getFieldValue';

const getFieldValues = (props, fieldKey, defaultValue) => {
  const [parentKey, key] = fieldKey.split('.');
  let values = [];
  if (!key || parentKey === 'data') {
    values.push(getFieldValue(props, fieldKey, defaultValue));
  } else {
    const repeater = getFieldValue(props, parentKey, defaultValue);
    values = repeater.map((rep = {}) => rep[key] || defaultValue);
  }

  return values;
};

export default getFieldValues;
