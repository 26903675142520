import React from 'react';

/**
 * Used to render and stylished div to display text.
 * Intended to be used to display field values in it's `isReviewer` mode.
 *
 * @param {object} props
 * @param {string} props.value
 * @return {function}
 */
const ReviewerField = ({ value, hide, addClass }) => {
  let ClassNames = 'reviewer-field text-field textarea-field ';
  if (addClass) {
    ClassNames += addClass;
  }
  return (
    <div
      dangerouslySetInnerHTML={{ __html: value || '&nbsp;' }}
      className={ClassNames}
    />
  );
};

export default ReviewerField;
