import { encodeFilter, fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';

class templateEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'https://localhost:3000';
    const apiPrefix = '';
    const baseURL = (
      process.env.REACT_APP_FORMS_SERVICE_URI || DEV_URI
    ).replace(/\/+$/, ''); // strip trailing slash
    super(
      baseURL
      // apiPrefix
    );
    this.apiPrefix = apiPrefix;
  }

  async getTemplateList(agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: `Templates`,
        method: 'GET',
      },
      {},
      {
        agencyId,
        includeCanCreate: false,
        filter: encodeFilter({
          where: {
            hasWorkflow: { neq: false },
            hasReports: { neq: false },
            isNote: { neq: true },
            isPresentation: { neq: true },
          },
        }),
      }
    );

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }
}

export default new templateEndpoints();
