import { persistCombineReducers, purgeStoredState } from 'redux-persist';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import { LOGOUT, LOGOUT_ERROR } from '../actions';
import * as localforage from 'localforage';
import utilsStorage from '../utils/storage';

import app from './app';
import session from './session';
import form from './form';
import modals from './modals';
import report from './report';
import attachments from './attachments';
import activity from './activity';
import users from './users';
import selectedUser from './user';
import forgotPassword from './forgot-password';
import formLinks from './form-links';
import systemUpdates from './system-updates';
import reviewForm from './review-form';
import internalAffairCaseLoad from './internal-affair-case-load';
import administrator from '../containers/administrator/Administrator.reducer';
import organization from './organization';
import reportListFilters from './report-list-filters';
import supportedAgency from './supported-agency';

const persistConfig = {
  key: 'root',
  storage: process.env.NODE_ENV === 'test' ? utilsStorage : localforage,
  whitelist: ['app', 'session', 'report', 'supportedAgency'],
  timeout: null,
};

const appReducer = persistCombineReducers(persistConfig, {
  routing: routerReducer,
  app,
  session,
  form,
  modals,
  report,
  attachments,
  activity,
  users,
  selectedUser,
  forgotPassword,
  formLinks,
  systemUpdates,
  reviewForm,
  internalAffairCaseLoad,
  forms: formReducer,
  ...administrator,
  organization,
  reportListFilters,
  supportedAgency,
});

export default (state, action) => {
  if ([LOGOUT, LOGOUT_ERROR].includes(action.type)) {
    state = undefined;
    if (utilsStorage.get('token', false)) utilsStorage.set('token', undefined);
    purgeStoredState(persistConfig);
  }

  return appReducer(state, action);
};
