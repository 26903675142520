import styled from 'styled-components';
import { Row } from 'antd';

const StyledRow = styled(Row)`
  margin-top: 16px;

  .ant-table table {
    border: 1px solid #e3e3e3;
    border-bottom: none;
    background-color: #fff;

    thead th {
      border: 1px solid #f1f1f1;
    }
  }
`;

export default StyledRow;
