import { get, camelCase, has, castArray, some, find } from 'lodash';

export const SCOPE_TYPES = { ANY: 'any', OWN: 'own' };

export default class CabalitiesManager {
  constructor(permissions, userAgencyId) {
    this.permissions = permissions;
    this.agencyId = Number(userAgencyId);
    this.capabilities = this.getCapabilities(permissions);
    this.capabilityMethods = this.getCapabilityMethods();

    this.generateCapabilitiesByResource();
  }

  generateCapabilityByScope(resource, action, capability) {
    const found = find(resource, { action });
    if (found && found.isAllowed) return resource;
    if (found && !found.isAllowed) {
      return resource.map(item => {
        if (item === found) return capability;
        return item;
      });
    }
    return resource.concat([capability]);
  }

  getCapabilities(permissions) {
    return permissions.reduce((capabilities, permission) => {
      const permissionCapabilities = String(permission).split('.');

      const withPermissionCapabilities = {
        ...capabilities,
      };

      if (permissionCapabilities.length === 3) {
        const [resource, capabilityScope, action] = permissionCapabilities;
        withPermissionCapabilities[resource] = {
          ...get(capabilities, resource, {}),
          ...Object.values(SCOPE_TYPES).reduce(
            (capabilitiesByScope, scope) => ({
              ...capabilitiesByScope,
              [scope]: this.generateCapabilityByScope(
                get(capabilities, `${resource}.${scope}`, []),
                action,
                { action, isAllowed: capabilityScope === scope }
              ),
            }),
            {}
          ),
        };
      } else {
        withPermissionCapabilities[permission] = {
          ...get(capabilities, permission, {}),
          ...Object.values(SCOPE_TYPES).reduce(
            (capabilitiesByScope, scope) => ({
              ...capabilitiesByScope,
              [scope]: get(capabilities, `${permission}.${scope}`, []).concat([
                { action: permission, isAllowed: true, permission },
              ]),
            }),
            {}
          ),
        };
      }

      return withPermissionCapabilities;
    }, {});
  }

  getCapabilityMethods() {
    return Object.entries(this.capabilities).map(
      ([resourceName, capabilityScopes]) => [
        resourceName,
        Object.values(SCOPE_TYPES)
          .map(scope =>
            get(capabilityScopes, scope, []).map(
              ({ action, isAllowed = true, permission }) => ({
                scope,
                method: camelCase(
                  action === resourceName
                    ? `can ${action}`
                    : `can ${action} ${scope} ${resourceName}`
                ),
                isAllowed,
                permission,
              })
            )
          )
          .reduce(
            (allCapabilities, capabilities) =>
              allCapabilities.concat(capabilities),
            []
          ),
      ]
    );
  }

  generateCapabilitiesByResource() {
    this.capabilityMethods.forEach(([_, capabilitiesByScope]) => {
      capabilitiesByScope.forEach(
        ({ scope, method: capabilityMethodName, isAllowed }) => {
          this.capabilitiesByName = {
            ...get(this, 'capabilitiesByName', {}),
            [capabilityMethodName]: validatorPasses => {
              if (scope === SCOPE_TYPES.ANY) {
                return isAllowed;
              }

              return validatorPasses && isAllowed;
            },
          };
        }
      );
    });
  }

  has = permission => this.permissions.includes(permission);

  can = (capability, validatorPasses) => {
    const capabilities = castArray(capability);
    return some(capabilities, capabilityName => {
      if (has(this.capabilitiesByName, capabilityName)) {
        return this.capabilitiesByName[capabilityName](validatorPasses);
      }

      return false;
    });
  };
}
