import React from 'react';
import { Select } from 'antd';
import classNames from 'classnames';
import InputWrapper from './InputWrapper.styled';
import Label from './Label.styled';

const MultipleSelect = props => {
  const {
    mode,
    type,
    size,
    input,
    placeholder,
    meta: { touched, error, form },
    label,
    options = {},
    disabled,
    dropdownOptions = [],
    defaultValue,
    optionFilterProp,
    filterOption,
  } = props;

  const { name, value, onChange } = input;
  const id = `${form}_${name}`;
  const { labelCol = 'ant-col-6', inputCol = 'ant-col-16' } = options;
  const fieldValue = !value ? undefined : value;

  const onFieldValueChange = (...args) => {
    const performChange = callback => {
      onChange(...args);
      callback && callback();
    };
    performChange();
  };

  return (
    <InputWrapper
      className={classNames('ant-row ant-form-item ant-form-item-no-colon', {
        'ant-form-item-with-help': touched && error,
      })}
    >
      {label && (
        <div className={classNames('ant-form-item-label text-left', labelCol)}>
          <Label htmlFor={id}>{label}</Label>
        </div>
      )}
      <div
        className={classNames(
          'ant-form-item-control-wrapper',
          label ? inputCol : 'ant-col-24'
        )}
      >
        <div
          className={classNames('ant-form-item-control', {
            'has-error': touched && error,
          })}
        >
          <Select
            {...input}
            onChange={onFieldValueChange}
            placeholder={placeholder}
            type={type}
            id={id}
            size={size}
            disabled={disabled}
            value={fieldValue || defaultValue}
            mode={mode}
            optionFilterProp={optionFilterProp ? optionFilterProp : ''}
            filterOption={filterOption ? filterOption : false}
          >
            {dropdownOptions}
          </Select>
          {touched && error && <div className="ant-form-explain">{error}</div>}
        </div>
      </div>
    </InputWrapper>
  );
};

export default MultipleSelect;
