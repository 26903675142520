import { get } from 'lodash';
import { notification } from 'antd';

import postReportSuccess from './post-report-success';
import postReportError from './post-report-error';
import { updateReportByAdmin } from '../api/reports';
import syncFromHistoryAdminEditReport from './sync-form-history-admin-edit-report';
import removeSelectedFormCopy from './remove-selected-form-copy';

export default function postReportAdminEdit(
  reportData,
  updateModal,
  callback,
  enableConfirmButton
) {
  return async dispatch => {
    try {
      const response = await updateReportByAdmin(reportData);
      const { transactionHistory } = response;
      dispatch(postReportSuccess(response));
      dispatch(removeSelectedFormCopy());
      dispatch(syncFromHistoryAdminEditReport(transactionHistory));
      notification.success({
        message: 'Success!',
        description: 'The report was modified successfully',
      });
      updateModal({
        visible: false,
      });
      callback();
      enableConfirmButton();
    } catch (e) {
      dispatch(postReportError(get(e, ['response', 'status'])));
      notification.error({
        message: 'Sorry something went wrong!',
        description: e.message,
      });
      enableConfirmButton();
    }
  };
}
