import { genOptions, route, fetchRequest } from '../utils/request';
import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';

export const getTemplateVisibility = (templateId, agencyId) => {
  const token = storage.get('token');
  const url = route(
    urlBuilder(
      '/Templates/:templateId/getVisibilityConstraints',
      { templateId },
      { agencyId: agencyId }
    )
  );
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

export const setTemplateVisibility = (
  templateId,
  agencyId,
  roleConstraints
) => {
  const token = storage.get('token');
  const url = route(
    urlBuilder(
      '/Templates/:templateId/setVisibilityConstraints',
      { templateId },
      { agencyId: agencyId, roleConstraints: JSON.stringify(roleConstraints) }
    )
  );
  const options = genOptions('POST', null, token);

  return fetchRequest(url, options);
};

export const getTemplateSumitterVisibility = (id, agencyId) => {
  const token = storage.get('token');
  const url = route(
    urlBuilder(
      '/Templates/:id/getSubmitterVisibility',
      { id },
      { agencyId: agencyId }
    )
  );
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

export const setTemplateSumitterVisibility = (
  id,
  agencyId,
  submitterVisibilityValue
) => {
  const token = storage.get('token');
  const url = route(
    urlBuilder(
      '/Templates/:id/toggleSubmitterVisibility',
      { id },
      { agencyId: agencyId }
    )
  );
  const data = {
    templateId: id,
    agencyId: agencyId,
    submitterVisibility: submitterVisibilityValue,
  };
  const options = genOptions('PUT', data, token);

  return fetchRequest(url, options);
};
