import styled from 'styled-components';

import { ACCENT, DIVIDER, GRAY_3 } from '../../../../config/theme';

const Panel = styled.div.attrs({
  className: 'bdm-panel',
})`
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 16px;

  @media screen {
    padding: 24px 16px;
  }

  &.is-transparent {
    background-color: transparent;
  }

  &.is-paddingless-vertical {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.is-paddingless-horizontal {
    padding-left: 0;
    padding-right: 0;
  }

  &.is-marginless-horizontal {
    margin-left: 0;
    margin-right: 0;
  }

  &.is-marginless-vertical {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.is-paddingless {
    padding: 0;
  }

  &.is-marginless {
    margin: 0;
  }

  &.has-table {
    border: 1px solid ${DIVIDER};
    padding-bottom: 0;
  }

  &.is-table {
    padding: 0;
    border: 1px solid ${DIVIDER};
  }

  &.is-bordered {
    border: 1px solid ${GRAY_3};
  }

  a {
    color: ${ACCENT};

    &.stroked,
    .stroked {
      text-decoration: underline;
    }

    &.bold,
    .bold {
      font-weight: bold;
    }
  }

  &.bdm-panel-activity-list {
    padding: 0;

    .bdm-panel-title {
      padding: 20px 30px 20px 20px;
    }

    .bdm-panel-section {
      padding: 0;

      a {
        display: block;
        border-bottom: 1px solid ${GRAY_3};
        padding: 10px 0 2px 20px;
        color: #000;

        &:last-child {
          border-bottom: none;
        }
      }

      .ant-tag,
      .meta-updated,
      .icon-btn {
        vertical-align: middle;
      }

      .ant-tag {
        transition: all 0s;
      }
    }
  }
`;

export default Panel;
export { default as Divider } from './divider';
export { default as PanelRow } from './panel-row';
export { default as PanelSection } from './panel-section';
export { default as PanelTitle } from './panel-title';
export { default as PanelPaginationRow } from './panel-pagination-row';
export { default as PanelSectionTable } from './panel-table-section';
export { default as PanelAvatarSection } from './panel-avatar-section';
export { default as PanelTableRow } from './panel-table-row';
export { default as CollapsePanel } from './collapse-panel';
export { default as PanelTable } from './panel-table';
export { default as TablePanel } from './table-panel';
