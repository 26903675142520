import styled from 'styled-components';

import { DIVIDER } from '../../../config/theme';

const ReviewListItem = styled.div.attrs({
  className: 'reviews-list-item',
})`
  border-radius: 4px;

  @media screen {
    padding: 16px;
    margin-bottom: 16px;
    background: ${props => (props.isHighlighted ? '#FDF9E9' : '#FFF')};
    border: 1px solid
      ${props => (props.isHighlighted ? '#F1C40F' : 'transparent')};
  }

  @media print {
    ${props =>
      props.isHighlighted
        ? ` border: 1px solid #000; padding: 0 10px 10px; `
        : ''};
  }

  .review-item-header {
    @media screen {
      padding: 16px 0;
      border-bottom: 1px solid ${DIVIDER};
    }

    @media print {
      border-bottom: 1px solid
        ${props => (props.isHighlighted ? '#000' : DIVIDER)};
    }
  }

  .review-item-body {
    padding-top: 16px;
  }
`;

export default ReviewListItem;
