import React from 'react';
import get from 'lodash/get';
import { Button, Icon, Alert } from 'antd';
import { submit, initialize } from 'redux-form';
import styled from 'styled-components';
import { find, castArray, first, has } from 'lodash';
import uuid from 'uuid/v4';
import cx from 'classnames';

import withoutClutter from 'APP_COMPONENTS/without-clutter';
import DashboardPage from 'APP_COMPONENTS/dashboard';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import IconButton from 'APP_COMPONENTS/common/buttons/icon-button';

import AdministratorWrapper from '../../Administrator.styled';

import getRoute from 'APP_ROOT/utils/get-route';

import { getTemplate, getTemplates } from '../ConfigureWorkflows.selectors';
import { getFormValues } from '../../agency-users/AgencyUsers.selectors';
import { selectAgency } from '../../agency-profile/AgencyProfile.selectors';

import getAgencyTemplates from '../actions/get-agency-templates';
import getWorkflow from '../actions/get-workflow';
import Workflow from './Workflow';
import connectForm from '../../components/connected-forms';
import withOwnershipGuard from '../../components/agency-ownership-guard';

import createModal from 'APP_ROOT/actions/create-modal';
import showModal from 'APP_ROOT/actions/show-modal';
import updateModal from 'APP_ROOT/actions/update-modal';
import createWorkflow from '../actions/create-workflow';
import updateWorkflow from '../actions/update-workflow';
import deleteWorkflow from '../actions/delete-workflow';

const formName = 'workflow';
const modalId = 'workflow-modal';

const asyncAction = dispatch => (fn, ...args) =>
  new Promise((resolve, reject) => {
    try {
      dispatch(
        fn(...args, (error, response) => {
          if (error) {
            reject(error);
            return;
          }

          resolve(response);
        })
      );
    } catch (error) {
      reject(error);
    }
  });

export class Component extends React.Component {
  state = {
    isLoading: true,
    workflowName: '',
    workflow: {},
  };

  async componentDidMount() {
    const {
      dispatch,
      agencyId,
      templatesConfig,
      templateId,
      workflowId,
      agencyProfile,
    } = this.props;
    const asyncDispatcher = asyncAction(dispatch);
    await asyncDispatcher(getAgencyTemplates, agencyId, templatesConfig);
    const isNew = isNaN(Number(workflowId));

    if (workflowId && !isNew) {
      const workflow = await asyncDispatcher(getWorkflow, {
        agencyId,
        templateId,
        workflowId,
      });

      this.setWorkflowTransitions(workflow, agencyProfile.securityRoles);
      this.setState({ workflow });
    } else {
      this.setState({ isLoading: false });
    }

    dispatch(
      createModal({
        id: modalId,
        title: (
          <ModalTitle>
            <Icon type="check-circle" /> Success!
          </ModalTitle>
        ),
        children: <p>The information has been saved successfully!</p>,
      })
    );
  }

  pageActions = () => {
    const { isLoading } = this.state;
    const { workflowId } = this.props;

    const isNew = isNaN(Number(workflowId));

    const isCustomWorkflow = this.isCustomWorkflow();

    if (isCustomWorkflow) {
      return [];
    }

    const saveButton = (
      <Button
        type="primary"
        size="default"
        key="save-btn"
        className={cx({ disabled: isLoading })}
        disabled={isLoading}
        onClick={this.requestSubmit}
      >
        Save
      </Button>
    );

    if (isNew) {
      return [saveButton];
    }

    return [
      <IconButton
        type="primary"
        size="default"
        key="delete-btn"
        icon="delete"
        disabled={isLoading}
        onClick={this.deleteWorkflow}
      />,
      saveButton,
    ];
  };

  getConnectedForm = () => {
    if (!this.connectedForm) {
      this.connectedForm = connectForm(formName, this.onSubmit);
    }

    return this.connectedForm;
  };

  onSubmit = values => {
    const { dispatch, agencyId, templateId, history, workflowId } = this.props;
    const isCustomWorkflow = this.isCustomWorkflow();

    if (isCustomWorkflow) {
      return;
    }

    const isNew = isNaN(Number(workflowId));

    let transitions = [];
    let roles = [];
    if (this.workflow) {
      const [_transitions, _roles] = this.workflow.getTransitions();
      transitions = _transitions.map(({ id }) => id);
      roles = _roles;
    }

    const workflow = transitions
      .concat('final')
      .reduce((_workflow, transitionId, currentIndex) => {
        const prevTransitionId =
          currentIndex > 0 ? transitions[currentIndex - 1] : null;
        const prevReviewer = prevTransitionId
          ? get(values, `reviewer.${prevTransitionId}`)
          : null;
        const transitionParticipants = castArray(
          currentIndex > 0
            ? prevReviewer
            : get(values, `submitter.${transitionId}`)
        );

        return [
          ..._workflow,
          {
            roles: transitionParticipants.reduce((_roles, participantId) => {
              const _role = find(roles, item => item.value === participantId);

              if (_role) {
                return _roles.concat(_role.label);
              }

              return _roles;
            }, []),
          },
        ];
      }, []);

    dispatch(
      updateModal({
        id: modalId,
        children: <p>The information has been saved successfully!</p>,
      })
    );

    const onServerResponds = error => {
      if (error) {
        alert('The workflow cannot be saved');
        return;
      }

      dispatch(showModal(modalId));
      history.push(
        getRoute('administratorAgencyWorkflowsTemplateWorkflows', {
          agencyId,
          templateId,
        })
      );
    };

    if (isNew) {
      dispatch(
        createWorkflow(
          {
            agencyId,
            templateId,
            workflow,
          },
          onServerResponds
        )
      );

      return;
    }

    dispatch(
      updateWorkflow(
        {
          agencyId,
          templateId,
          workflow,
          workflowId,
        },
        onServerResponds
      )
    );
  };

  requestSubmit = () => {
    const { dispatch } = this.props;
    const isCustomWorkflow = this.isCustomWorkflow();

    if (isCustomWorkflow) {
      return;
    }

    dispatch(submit('workflow'));
  };

  getWorkflowRef = el => {
    this.workflow = el;
  };

  setWorkflowTransitions = ({ transitions, name: workflowName }, roles) => {
    const { dispatch } = this.props;

    if (this.workflow) {
      const roleList = roles.reduce(
        (_roles, role) => ({ ..._roles, [role.name]: role.id + [] }),
        {}
      );
      const roleListById = Object.entries(roleList).reduce(
        (_roles, [roleName, roleValue]) => ({
          ..._roles,
          [roleValue]: roleName,
        }),
        {}
      );

      const normalizedTransitions = castArray(transitions).map(transition => ({
        id: uuid(),
        reviewer: [],
        submitter: null,
        transition: Object.assign({}, transition, {
          roles: castArray(get(transition, 'roles', [])).map(
            roleName => roleList[roleName]
          ),
        }),
      }));

      let firstSubmitter = null;

      const formData = normalizedTransitions.reduce(
        (data, { id, transition }, currentIndex) => {
          if (currentIndex === 0) {
            firstSubmitter = get(
              roleListById,
              first(get(transition, 'roles', []))
            );
          }

          const nextSubmitter = get(
            normalizedTransitions,
            `${currentIndex + 1}.transition.roles`,
            []
          );

          return Object.assign({}, data, {
            submitter: Object.assign(
              {},
              get(data, 'submitter', {}),
              currentIndex === 0
                ? {
                    [id]: get(transition, 'roles', []),
                  }
                : {}
            ),
            reviewer: Object.assign({}, get(data, 'reviewer', {}), {
              [id]: nextSubmitter,
            }),
          });
        },
        {}
      );

      normalizedTransitions.pop();

      this.setState({
        workflowName: workflowName || `${firstSubmitter} Workflow`,
      });

      this.workflow.setTransitions(normalizedTransitions, () => {
        dispatch(initialize(formName, formData));
      });
    }

    this.setState({ isLoading: false });
  };

  deleteWorkflow = () => {
    const { dispatch, agencyId, templateId, workflowId, history } = this.props;
    const isCustomWorkflow = this.isCustomWorkflow();

    if (isCustomWorkflow) {
      return;
    }

    dispatch(
      updateModal({
        id: modalId,
        children: <p>The Workflow was deleted successfully!</p>,
      })
    );

    const onServerResponds = (error, response) => {
      if (error) {
        alert(error);
        // eslint-disable-next-line no-console
        console.log(error);
        return;
      }

      if (!response?.success && response?.message) {
        alert(response.message);
        return;
      }

      dispatch(showModal(modalId));
      history.push(
        getRoute('administratorAgencyWorkflowsTemplateWorkflows', {
          agencyId,
          templateId,
        })
      );
    };

    dispatch(
      deleteWorkflow({ agencyId, templateId, workflowId }, onServerResponds)
    );
  };

  isCustomWorkflow = () => {
    const { workflowId } = this.props;
    const { workflow } = this.state;
    const isNew = isNaN(Number(workflowId));

    return (
      !isNew &&
      has(workflow, 'selfServiceCreated') &&
      !workflow.selfServiceCreated
    );
  };

  render() {
    const {
      agencyTemplates,
      templateId,
      agencyId,
      agencyProfile,
      workflowData,
      workflowId,
    } = this.props;
    const { isLoading, workflowName } = this.state;
    const isNew = isNaN(Number(workflowId));
    const isCustomWorkflow = this.isCustomWorkflow();

    const template = get(agencyTemplates, ['entries', templateId], {});
    const templateAbbr = (template.abbreviation || '') + ' ';

    const WorkflowForm = this.getConnectedForm();

    return (
      <AdministratorWrapper>
        <WorkflowForm
          render={({ handleSubmit }) => (
            <div>
              <PageHeader
                title={
                  isNew ? (
                    `Add ${templateAbbr}Workflow`
                  ) : (
                    <div>
                      <span>{templateAbbr}</span>{' '}
                      <Icon type="right" className="title-separator" />{' '}
                      <span className="header-title__subtitle">
                        {workflowName}
                      </span>
                    </div>
                  )
                }
                goBackTo={getRoute(
                  'administratorAgencyWorkflowsTemplateWorkflows',
                  {
                    agencyId,
                    templateId,
                  }
                )}
                actions={this.pageActions()}
                loading={agencyTemplates.loading || isLoading}
                alert={
                  isCustomWorkflow && (
                    <Alert
                      message="Warning"
                      description="This Workflow cannot be updated."
                      type="warning"
                      banner
                      showIcon
                    />
                  )
                }
              />
              <div className="administrator-content">
                <form
                  className="ant-form ant-form-inline"
                  onSubmit={handleSubmit(this.onSubmit)}
                >
                  <Workflow
                    roles={agencyProfile.securityRoles}
                    workflowForm={workflowData}
                    innerRef={this.getWorkflowRef}
                    isCustomWorkflow={isCustomWorkflow}
                    isLoading={isLoading}
                  />
                </form>
              </div>
            </div>
          )}
        />
      </AdministratorWrapper>
    );
  }
}

const ModalTitle = styled.div`
  .anticon {
    color: #f1c40f;

    &.anticon-check-circle {
      color: #2ecc71;
    }

    &.anticon-close-circle {
      color: #e74c3c;
    }
  }
`;

const mapStateToProps = (state, props) => {
  const agencyId = get(props, 'match.params.agencyId');
  const templateId = get(props, 'match.params.templateId');
  const workflowId = get(props, 'match.params.workflowId');

  return {
    agencyTemplates: getTemplates(state),
    currentTemplate: getTemplate(state, agencyId, templateId),
    agencyId,
    templateId,
    workflowData: getFormValues(state, formName),
    agencyProfile: selectAgency(state, agencyId),
    workflowId,
  };
};

export default withoutClutter(
  DashboardPage(mapStateToProps)(
    withOwnershipGuard(Component, 'administratorAgencyWorkflows')
  )
);
