import { camelCase } from 'lodash';
import { PERMISSIONS } from '../../../../utils/admin';
import AgencyLiveEventCheckIn from './AgencyLiveEventCheckIn';
import AgencyLiveEventCheckInScan from './AgencyLiveEventCheckInScan';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/live-event-check-in/scan`,
    name: camelCase(`${name}-live-event-check-in-scan`),
    component: AgencyLiveEventCheckInScan,
    protectedWith: [PERMISSIONS.manageTMSLiveEvents],
    childRoutes: [],
  },
  {
    path: `${path}/live-event-check-in`,
    name: camelCase(`${name}-live-event-check-in`),
    component: AgencyLiveEventCheckIn,
    protectedWith: [PERMISSIONS.manageTMSLiveEvents],
    childRoutes: [],
  },
];
