import { parseInt, first } from 'lodash';

export const getReviewerFromActions = (actions, reviewer) => {
  return actions
    .map(({ reviewer }) => reviewer.list)
    .reduce((acc, reviewer) => [...acc, ...reviewer], [])
    .find(({ id }) => id === reviewer);
};

export const filterReviewerById = (reviewers, reviewer) => {
  return reviewers.filter(
    item =>
      item.id === first(reviewer) ||
      parseInt(item.id) === parseInt(first(reviewer))
  );
};
