import React, { Component } from 'react';
import { Row } from 'antd';
import { omit } from 'lodash';

import { mapProperties } from '../../../utils/form';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';

class Grid extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  render() {
    const props = this.props;

    const { form = {}, properties = [], gutter, ...rest } = props;
    const total = properties.length;

    return (
      <Row gutter={gutter}>
        {properties.map(
          mapProperties({
            ...omit(rest, ['conditions', 'options', 'nodeId']),
            form,
            total,
            title: null,
          })
        )}
      </Row>
    );
  }
}

export default Grid;
