import React from 'react';
import ContentLoader from 'react-content-loader';

const actionsLoader = ({ length = 4 }) => (
  <div style={{ display: 'flex' }}>
    {Array(length)
      .fill(0)
      .map((_i, index) => (
        <ContentLoader
          key={index}
          height={22}
          width={60}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
          style={{ width: 60, height: '22px', padding: '0 5px' }}
        >
          <rect x="0" y="0" rx="5" ry="5" width="60" height="22" />
        </ContentLoader>
      ))}
  </div>
);

actionsLoader.metadata = {
  description: 'Actions buttons loader',
  filename: 'actionsLoader',
};

export default actionsLoader;
