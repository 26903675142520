import React from 'react';
import ContentLoader from 'react-content-loader';

const workspaceLoader = props => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      margin: '10px 20px 0 20px',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '10px',
      }}
    >
      <ContentLoader
        key="tab"
        height={50}
        width={600}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        style={{ display: 'flex', width: '600px', height: '46px' }}
      >
        <rect x="0" y="0" rx="0" ry="0" width="120" height="46px" />
        <rect x="123" y="0" rx="0" ry="0" width="120" height="46px" />
        <rect x="246" y="0" rx="0" ry="0" width="120" height="46px" />
        <rect x="369" y="0" rx="0" ry="0" width="120" height="46px" />
        <rect x="492" y="0" rx="0" ry="0" width="120" height="46px" />
        <rect x="615" y="0" rx="0" ry="0" width="120" height="46px" />
        <rect x="738" y="0" rx="0" ry="0" width="120" height="46px" />
      </ContentLoader>
      <ContentLoader
        key="add"
        height={50}
        width={300}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        style={{
          display: 'flex',
          width: '300px',
          height: '40px',
          marginLeft: 'auto',
        }}
      >
        <rect x="200" y="0" rx="0" ry="0" width="120" height="40px" />
      </ContentLoader>
    </div>
    <div style={{ display: 'flex' }}>
      <ContentLoader
        key="continer_1"
        height={400}
        width={300}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          borderRadius: '15px',
        }}
      >
        <rect x="0" y="5" rx="0" ry="0" width="300" height="600px" />
      </ContentLoader>
    </div>
  </div>
);

workspaceLoader.metadata = {
  description: 'Workspace loader',
  filename: 'workspaceLoader',
};

export default workspaceLoader;
