import styled from 'styled-components';
import { GRAY_1, GRAY_2, GRAY_3 } from '../../../../config/theme';

export const PanelSection = styled.div.attrs({
  className: 'bdm-panel-section',
})`
  padding: 16px 0;
  position: relative;

  &:last-child {
    padding-bottom: 0;
  }

  .block-section .block-link,
  .block-section .block-share {
    width: 100%;
    padding: 10px 30px 2px 20px !important;
  }

  .block-share {
    pointer-events: none;
    border-bottom: 0 !important;
    margin-right: 0 !important;
  }

  .block-share .block-dismiss {
    border-bottom: 0;
    pointer-events: auto;

    .anticon {
      color: ${GRAY_1};
    }
  }

  .block-section {
    border-bottom: 1px solid ${GRAY_3};
    overflow: auto;
  }

  div[class^='ant-row'] div[class^='ant-col'] {
    .anticon {
      font-size: 18px;
    }
  }

  .ant-timeline {
    p {
      font-size: 12px;
      color: ${GRAY_2};
      line-height: 18px;
    }

    .ant-timeline-item {
      padding: 0;
    }
  }
`;

export default PanelSection;
