import styled from 'styled-components';

import { ACCENT } from 'APP_ROOT/config/theme';
import TrainingTaskTimeline from '../object-types/TrainingTaskTimeline';

const StyledTrainingTaskTimeline = styled(TrainingTaskTimeline)`
  .link-timeline {
    float: right;
    color: ${ACCENT};
    text-decoration: underline;
  }
`;

export default StyledTrainingTaskTimeline;
