import { FILTER_ARRAY_SEPARATOR } from './constants';

const getReportSubmitterFilter = (reportSubmitter = '') => {
  if (reportSubmitter) {
    const items = reportSubmitter.split(FILTER_ARRAY_SEPARATOR);
    return [
      {
        column: 'submittedBy',
        operator: 'inq',
        value: items,
      },
    ];
  }
  return [];
};

export default getReportSubmitterFilter;
