import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Pagination, Icon, Table, Input } from 'antd';
import styled from 'styled-components';
import { get, isEmpty } from 'lodash';
import moment from 'moment';

// Actions
import getTrainingUsers from '../../actions/get-training-users';

// Containers
import DashboardPage from '../../components/dashboard';

// Components
import ReportCard from '../../components/report-card';
import MainTitle from '../../components/common/main-title';
import IconButton from '../../components/common/buttons/icon-button';

const { Column } = Table;

class FieldTraining extends Component {
  state = {
    keywords: '',
    clear: false,
    delayCompleted: false,
  };

  static getPageConfig() {
    return {
      title: 'Field Training',
    };
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;

    dispatch(
      getTrainingUsers(1, {}, () => {
        this.setState({ delayCompleted: true });
      })
    );
  }

  onSearchSubmit = value => {
    const { dispatch } = this.props;
    const {
      target: { value: searchValue = '' },
    } = value;
    dispatch(
      getTrainingUsers(1, {
        where: {
          fullName: {
            ilike: `%${searchValue}%`,
          },
        },
      })
    );
  };

  onSearchChange = value => {
    const {
      target: { value: searchValue = '' },
    } = value;

    this.setState({
      keywords: searchValue,
      clear: !!searchValue,
    });
  };

  onSearchClear = () => {
    const { dispatch } = this.props;
    this.setState(
      {
        keywords: '',
        clear: false,
      },
      dispatch(getTrainingUsers(1))
    );
  };

  handleChange = (pagination, filters, sorter) => {};

  render() {
    const {
      total = 0,
      per_page = 8,
      page = 1,
      users = [],
      loading = false,
      loadingTrainees,
    } = this.props;
    const { keywords, clear, delayCompleted } = this.state;

    const searchSuffix = clear ? (
      <Icon type="close-circle" onClick={this.onSearchClear} />
    ) : (
      <Icon type="search" />
    );

    return (
      <div>
        <Row>
          <Col span={6} className="text-left">
            <MainTitle>
              Field Training{' '}
              {(loadingTrainees || !delayCompleted) && <Icon type="loading" />}
            </MainTitle>
          </Col>
          <Col span={18} className="text-right">
            <Input
              placeholder="Type Trainee Name"
              style={{ width: '300px' }}
              size="default"
              onChange={this.onSearchChange}
              onPressEnter={this.onSearchSubmit}
              suffix={searchSuffix}
              value={keywords}
            />
          </Col>
        </Row>
        <StyledRow>
          <Col span={24}>
            <div className="responsive-table">
              <Table
                dataSource={users}
                onChange={this.handleChange}
                loading={loading}
                pagination={false}
              >
                <Column
                  title="Name"
                  key="name"
                  render={(text, record) => (
                    <div>
                      {record.lastName}, {record.firstName}
                    </div>
                  )}
                />
                <Column
                  title="Start Date"
                  dataIndex="startDate"
                  key="startDate"
                  render={(text, record) => {
                    const startDate = get(record, 'trainingProgramStart');
                    const momentifiedStartDate = startDate
                      ? moment(startDate).utc()
                      : moment();
                    return (
                      <div>
                        {startDate && momentifiedStartDate.format('M/D/Y')}
                      </div>
                    );
                  }}
                />
                <Column
                  title="Completed Date"
                  dataIndex="completedDate"
                  key="completedDate"
                  render={(text, record) => {
                    const completedDate = get(
                      record,
                      'trainingProgramCompleted'
                    );
                    const momentifiedCompletedDate = !isEmpty(completedDate)
                      ? moment(completedDate).utc()
                      : moment();
                    return (
                      <div>
                        {!isEmpty(completedDate)
                          ? momentifiedCompletedDate.format('M/D/Y')
                          : ''}
                      </div>
                    );
                  }}
                />
                <Column
                  title="Days in Training"
                  dataIndex="daysInTraining"
                  key="daysInTraining"
                  render={(text, record) => {
                    const daysInTraining = get(record, 'daysInTraining');
                    return <div>{daysInTraining}</div>;
                  }}
                />
                <Column
                  title="Training Tasks Progress"
                  dataIndex="trainingTasksProgress"
                  key="trainingTasksProgress"
                  render={(text, record) => {
                    const userId = get(record, 'id');
                    const completedTasks = get(record, 'completedTasks', {});
                    const { completed, of } = completedTasks;
                    return (
                      <Row type="flex" justify="space-between" align="middle">
                        <Col>{`${completed} out of ${of}`}</Col>
                        <Col>
                          <Link to={`/field-training/${userId}`}>
                            <IconButton icon="arrow-right" className="arrow" />
                          </Link>
                        </Col>
                      </Row>
                    );
                  }}
                />
              </Table>
            </div>
          </Col>
        </StyledRow>

        {total > 0 && (
          <StyledRowPagination>
            <Col span={24} className="text-right">
              <Pagination
                defaultCurrent={1}
                current={page}
                total={total}
                pageSize={per_page}
                onChange={this.changePage}
              />
            </Col>
          </StyledRowPagination>
        )}
      </div>
    );
  }
}

export const StyledReportCard = styled(ReportCard)`
  margin-bottom: 8px;
  /* stylelint-disable */
  &:nth-child-last(1) {
    margin-bottom: 0;
  }
`;

const StyledRow = styled(Row)`
  margin-top: 16px;

  .arrow {
    margin: 0 10px;
  }
`;
const StyledRowPagination = styled(Row)`
  margin-top: 16px;
`;

const mapState = ({
  users: { data, entries, total, per_page, page, loading },
}) => ({
  users: data.map(id => ({ ...entries[id], key: id })),
  total,
  per_page,
  page,
  loading,
});

export default DashboardPage(mapState, null)(FieldTraining);
