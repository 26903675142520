import { createReducer } from 'redux-create-reducer';
import { get } from 'lodash';

import {
  GET_AGENCY_ERROR,
  GET_AGENCY_REQUEST,
  GET_AGENCY_SECURITY_ROLES_REQUEST,
  GET_AGENCY_SECURITY_ROLES_FULFILLED,
  GET_AGENCY_SECURITY_ROLES_REJECTED,
  GET_AGENCY_SUCCESS,
  GET_RANKS_FULLFILLED,
  GET_UNIT_ASSIGNMENTS_FULLFILLED,
} from './AgencyProfile.actionTypes';

import {
  GET_USER_FULLFILLED,
  GET_USER_REJECTED,
  GET_USER_REQUEST,
} from '../agency-users/AgencyUsers.actionTypes';

const initialState = {};
const initialAgencyState = {
  loading: true,
  error: null,
  data: {},
  unitAssignments: [],
  ranks: [],
  securityRoles: [],
  configurations: null,
};

const events = {
  [GET_AGENCY_REQUEST]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...get(state, [payload.agencyId], {}),
    },
  }),
  [GET_AGENCY_SUCCESS]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...state[payload.agencyId],
      loading: false,
      data: payload.response,
      configurations: get(payload, 'configurations', {}),
    },
  }),
  [GET_AGENCY_ERROR]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...state[payload.agencyId],
      loading: false,
      error: payload.error,
    },
  }),
  [GET_UNIT_ASSIGNMENTS_FULLFILLED]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...state[payload.agencyId],
      unitAssignments: payload.response,
    },
  }),
  [GET_RANKS_FULLFILLED]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...state[payload.agencyId],
      ranks: payload.response,
    },
  }),
  [GET_AGENCY_SECURITY_ROLES_REQUEST]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...state[payload.agencyId],
      loading: true,
    },
  }),
  [GET_AGENCY_SECURITY_ROLES_FULFILLED]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...state[payload.agencyId],
      securityRoles: payload.response,
      loading: false,
    },
  }),
  [GET_AGENCY_SECURITY_ROLES_REJECTED]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...state[payload.agencyId],
      loading: false,
      error: payload.error,
    },
  }),
  [GET_USER_REQUEST]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...get(state, [payload.agencyId], {}),
      loading: true,
    },
  }),
  [GET_USER_FULLFILLED]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...state[payload.agencyId],
      loading: false,
    },
  }),
  [GET_USER_REJECTED]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...state[payload.agencyId],
      loading: false,
    },
  }),
};

export default createReducer(initialState, events);
