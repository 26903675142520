import React, { Fragment } from 'react';
import {
  get,
  pick,
  upperFirst,
  uniq,
  concat,
  isEmpty,
  capitalize,
} from 'lodash';
import { Button, Icon, notification, Modal, Badge, Tabs, Upload } from 'antd';
import { mapToTemplateEnumBy } from '../utils/transformChanges.js';
import { isReviewNote } from '../../../modules/FormBuilder/utils/general-utilities';

import withoutClutter from 'APP_COMPONENTS/without-clutter';
import DashboardPage from 'APP_COMPONENTS/dashboard';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import getRoute from 'APP_ROOT/utils/get-route';
import { sidebarIsCollapsed } from 'APP_ROOT/selectors/application';
import { FORM_DRAFT } from 'APP_ROOT/constants/formStatus';
import IconButton from 'APP_COMPONENTS/common/buttons/icon-button';
import withModal from 'APP_COMPONENTS/common/modal/base';
import CustomDrawer from 'APP_COMPONENTS/common/custom-drawer';
import getBadgeStatus from 'APP_COMPONENTS/common/custom-drawer/changelog-drawer-helpers/badgeStatusses';
import withOwnershipGuard from '../components/agency-ownership-guard';
import FormBuilder from '../../../modules/FormBuilder';
import AdministratorWrapper from '../Administrator.styled';
import componentsManager from '../../../modules/FormBuilder/services/componentsManager';
import calculatedFieldsConfig from '../../../modules/FormBuilder/services/calculatedFieldsConfig';
import apiEndpoints from '../../../modules/FormBuilder/services/apiEndpoints';
import getCustomFields from '../../../modules/FormBuilder/utils/getCustomFields';
import buildOptionsModalContent from '../../../modules/FormBuilder/utils/buildOptionsModalContent';
import displayErrorMessage from '../../../modules/FormBuilder/utils/displayErrorMessage';
import SecuritySettingsDrawer from './security-settings-drawer/security-settings-drawer.component';
import onIdleStateChange from '../../../actions/on-idle-state-change.js';
import {
  TAB_VALUES,
  TAB_CONDITIONAL,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
} from '../../../modules/FormBuilder/components/OptionsModalBody/OptionsModalBody.js';
import { FORM_HEADER } from '../../../modules/FormBuilder/constants/constants.js';

import canPublish from './canPublish.js';

const { confirm, error } = Modal;
const { TabPane } = Tabs;

const NOTIFICATION_SUCCESS = 'success';
const NOTIFICATION_ERROR = 'error';

/**
 * To show up messages to the user using notification component from antd
 * @param {string} type success, error, info, warning
 * @param {object} params object with message (if no message, use same as type),
 *                       description (required) and any other option allowed
 *                       by notifications component
 */
const notificationMessage = (type, params) => {
  notification[type]({
    message: `${capitalize(type)}!`,
    ...params,
  });
};
class Component extends withModal(React.Component) {
  state = {
    headerHeight: 166,
    executedAction: false,
    changelog: [],
    openDrawer: false,
    isSecuritySettingsDrawerOpen: false,
    initialTab: 'all',
  };

  // this is a flag to make sure when component is mounted,
  // after componentWillUnmount, could still occurs one more
  // render, which could be a problem for FormBuilder
  // component when the page is being refreshed, it gets
  // cranky and start yelling for drag and drop backend
  // lib double instance, so, this way we can prevent that
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    const { isLoading } = componentsManager.flags;
    const { id } = componentsManager.header;
    if (isLoading && id) {
      componentsManager.header.name = 'Form Builder';
      componentsManager.header.description = 'Description';
    } else {
      componentsManager.flags.isLoading = false;
    }

    const { location } = this.props;

    this.updateHeaderHeight();

    this.listener('resize', this.updateHeaderHeight).add();

    if (this.hasImportStateProp(location)) {
      this.finalizeJSONImport(location, false);
    }
    this.createModal();

    componentsManager.changelog$.subscribe(value => {
      this.setState({ changelog: value });
    });
    componentsManager.header$.subscribe(value => {
      const { header } = this.state;
      this.setState({ header: { ...header, ...value } });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.listener('resize', this.updateHeaderHeight).remove();
    componentsManager.initData();
  }

  updateHeaderHeight = () => {
    const { headerHeight: oldHeaderHeight } = this.state;
    const element = this.pageHeader;

    let headerHeight = oldHeaderHeight;

    if (element) {
      const { height } = element.getBoundingClientRect();

      headerHeight = height;
    }

    this.setState({ headerHeight });
  };

  listener = (event, callback) => ({
    add() {
      window.addEventListener(event, callback);
    },
    remove() {
      window.removeEventListener(event, callback);
    },
  });

  onSave = () => {
    const { agencyId } = this.props;
    const {
      id,
      name,
      description,
      abbreviation,
      category,
      fromTemplateId,
    } = componentsManager.header;

    // first update block condition references,
    // to replicate conditions instead of required fields
    let formSchema = this.setUpSchema(isReviewNote({ category }));
    const changelog = this.setUpChangelog();

    const templatePayload = {
      name: name ? name.trim() : '',
      description,
      abbreviation: abbreviation ? abbreviation.trim() : '',
      jsonTemplate: JSON.stringify(formSchema),
      changelog,
    };

    const isNameEmpty = isEmpty(templatePayload.name);
    const isAbbrEmpty = isEmpty(templatePayload.abbreviation);
    if (isNameEmpty || isAbbrEmpty) {
      const message =
        isNameEmpty && isAbbrEmpty
          ? 'Form name and abbreviation are required'
          : isNameEmpty
          ? 'Form name is required'
          : 'Form abbreviation is required';
      notificationMessage(NOTIFICATION_ERROR, { description: message });
      this.setState({ saving: false, executedAction: false });
    } else {
      this.saveTemplate(
        id,
        agencyId,
        templatePayload,
        formSchema,
        fromTemplateId,
        mapToTemplateEnumBy(category)
      );
    }
    componentsManager.flags.isLoading = false;
  };

  onClone = () => {
    const { history, agencyId } = this.props;
    const {
      id,
      name = '',
      description,
      abbreviation,
    } = componentsManager.header;
    const newName = `${name.trim()} (Cloned)`;

    // first update block condition references,
    // to replicate conditions instead of required fields
    let { fields, validations } = componentsManager.updateWrapperConditions(
      true,
      componentsManager.fields,
      componentsManager.validations
    );
    validations = componentsManager.getValidationsStructure(false, validations);

    const formSchema = {
      formSchema: {
        validations,
        form: { type: 'tabs', properties: fields },
        enums: componentsManager.enums,
      },
    };
    const templateSchema = {
      agencyId,
      description,
      abbreviation,
      name: newName,
      sourceTemplateId: id,
      jsonTemplate: JSON.stringify(formSchema),
    };

    this.setState({ cloning: true, executedAction: true });

    if (id) {
      apiEndpoints
        .saveForm(templateSchema)
        .then(id => {
          this.setState({ cloning: false, executedAction: false });
          componentsManager.header = {
            id,
            name: newName,
            fromTemplateId: undefined,
          };
          history.replace(
            getRoute('administratorAgencyFormBuilder', {
              agencyId,
              id,
            })
          );
          notificationMessage(NOTIFICATION_SUCCESS, {
            description: 'Form successfully cloned.',
          });
        })
        .catch(e => {
          this.setState({ cloning: false, executedAction: false });
          displayErrorMessage(`Something went wrong`, e);
        });
    }
    componentsManager.flags.isLoading = false;
  };

  onDelete = () => {
    const { history, agencyId } = this.props;
    const { id, name } = componentsManager.header;
    if (id) {
      confirm({
        title: 'Do you want to delete this form?',
        content: name,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          this.setState({ deleting: true });
          apiEndpoints
            .deleteForm(agencyId, id)
            .then(deleted => {
              this.setState({ deleting: false });
              history.push(
                getRoute('administratorAgencyFormBuilderList', {
                  agencyId,
                })
              );
              notificationMessage(NOTIFICATION_SUCCESS, {
                description: 'Form successfully deleted.',
              });
            })
            .catch(e => {
              this.setState({ deleting: false });
              displayErrorMessage(`Something went wrong`, e);
            });
        },
      });
    }
  };

  showPublishConfirm = name =>
    confirm({
      title: `Publish '${name}' template`,
      content: `This action will save & publish your draft. Any additional \
      changes will require a new draft to be made.  \
      Are you ready to publish?`,
      onOk: () => {
        const { agencyId } = this.props;
        const {
          id,
          name,
          description,
          abbreviation,
          category,
        } = componentsManager.header;

        let formSchema = this.setUpSchema(isReviewNote({ category }));
        const changelog = this.setUpChangelog();

        const templatePayload = {
          name,
          description,
          abbreviation,
          jsonTemplate: JSON.stringify(formSchema),
          changelog,
        };

        this.publishTemplate(id, agencyId, templatePayload);

        componentsManager.flags.isLoading = false;
      },
    });

  showPublishError = errors =>
    error({
      title: 'Heads up!',
      content: (
        <div>
          {errors.map((e, i) => (
            <p key={`err-${i}`} dangerouslySetInnerHTML={{ __html: e }} />
          ))}
        </div>
      ),
    });

  onPublish = () => {
    const { name } = componentsManager.header;
    const publish = canPublish();
    if (publish === true) {
      this.showPublishConfirm(name);
    } else {
      this.showPublishError(publish);
    }
  };

  saveTemplate(
    id,
    agencyId,
    templatePayload,
    formSchema,
    fromTemplateId,
    category
  ) {
    if (id) {
      this.updateTemplateBy(agencyId, id, templatePayload);
    } else {
      this.createTemplateBy(
        formSchema,
        templatePayload,
        fromTemplateId,
        agencyId,
        category
      );
    }
  }

  publishTemplate(id, agencyId, templatePayload) {
    if (id) {
      this.publishTemplateBy(id, agencyId, templatePayload);
    }
  }

  createTemplateBy(
    formSchema,
    templatePayload,
    fromTemplateId,
    agencyId,
    category
  ) {
    const configs = componentsManager.templateConfigs;
    templatePayload.category = category;
    if (configs) {
      formSchema.formSchema.configs = configs.configs;
      formSchema.formSchema.sourceTemplateType = configs.sourceTemplateType;
      formSchema.formSchema.environment = configs.environment;
      templatePayload.jsonTemplate = JSON.stringify(formSchema);
    }
    if (fromTemplateId) {
      templatePayload.sourceTemplateId = fromTemplateId;
    }
    templatePayload.agencyId = agencyId;
    apiEndpoints
      .saveForm(templatePayload)
      .then(id => {
        componentsManager.header = { id, fromTemplateId: undefined };
        this.setState({
          saving: false,
          executedAction: false,
          justSaveTemplate: true,
        });
        this.goBackToFormBuilder(id, isReviewNote(templatePayload));
        notificationMessage(NOTIFICATION_SUCCESS, {
          description: 'Form successfully saved.',
        });
        componentsManager.resetChangelog();
      })
      .catch(e => {
        this.setState({ saving: false, executedAction: false });
        displayErrorMessage(`Something went wrong`, e);
      });
  }

  goBackToFormBuilder(id, isNote) {
    const { history, agencyId } = this.props;
    history.replace(
      getRoute(
        'administratorAgencyFormBuilder',
        { agencyId, id },
        { isReviewNote: isNote }
      )
    );
  }

  updateTemplateBy(agencyId, id, templatePayload) {
    apiEndpoints
      .updateForm(agencyId, id, templatePayload)
      .then(success => {
        this.setState({ saving: false, executedAction: false });
        if (success) {
          notificationMessage(NOTIFICATION_SUCCESS, {
            description: 'Form successfully updated.',
          });
          componentsManager.resetChangelog();
          componentsManager.notify(`Template by id ${id} was updated`);
        } else {
          notificationMessage(NOTIFICATION_ERROR, {
            description: 'Could not update form.',
          });
        }
      })
      .catch(e => {
        this.setState({ saving: false, executedAction: false });
        displayErrorMessage(`Something went wrong`, e);
      });
  }

  publishTemplateBy(id, agencyId, templatePayload) {
    apiEndpoints
      .publishForm(id, agencyId, templatePayload)
      .then(success => {
        this.setState({
          saving: false,
          executedAction: false,
        });
        if (success) {
          notificationMessage(NOTIFICATION_SUCCESS, {
            description: 'Form successfully published.',
          });
          componentsManager.resetChangelog();
          componentsManager.notify(`Template by id ${id} and name \
          ${templatePayload.name} was published`);
        } else {
          notificationMessage(NOTIFICATION_ERROR, {
            description: 'Could not publish form.',
          });
        }
      })
      .catch(e => {
        this.setState({
          saving: false,
          executedAction: false,
        });
        displayErrorMessage(`Something went wrong`, e);
      });
  }

  setUpSchema(isReviewNote = false) {
    const shouldAdd = true;
    const isFlat = false;

    let { fields, validations } = componentsManager.updateWrapperConditions(
      shouldAdd,
      componentsManager.fields,
      componentsManager.validations
    );
    validations = componentsManager.getValidationsStructure(
      isFlat,
      validations
    );
    const formSchema = {
      formSchema: {
        validations,
        form: { type: 'tabs', properties: fields },
        enums: componentsManager.enums,
      },
    };
    if (isReviewNote) {
      formSchema.formSchema.propagateAction = componentsManager.propagateAction;
      formSchema.formSchema.propagateFields = componentsManager.propagateFields;
    }
    this.setState({ saving: true, executedAction: true });
    return formSchema;
  }

  setUpChangelog() {
    const changelogData = componentsManager.getChangelog();
    return changelogData.length ? JSON.stringify(changelogData) : '';
  }

  importOptions = {
    accept: 'application/json',
    beforeUpload: file => {
      this.onImportButtonClick(file);

      return false;
    },
    fileList: [],
  };

  get pageActions() {
    const { location } = this.props;
    const { saving, deleting, cloning, changelog, reading } = this.state;
    const { id, updatable, status } = componentsManager.header;
    const disabled = saving || deleting || cloning;
    const enableChangelog = changelog.length > 0 ? false : true;
    const params = new URLSearchParams(location.search);
    const isNote = params.get('isReviewNote') === 'true';
    const isDraft = status ? status.toLowerCase() == FORM_DRAFT : false;
    return [
      <Button
        icon="file-text"
        size="small"
        key="Current changelog"
        disabled={enableChangelog || disabled}
        onClick={this.toggleDrawer}
      >
        Changelog
      </Button>,
      <Upload {...this.importOptions} key="import">
        <Button
          icon="upload"
          size="small"
          type="upload"
          loading={reading}
          disabled={disabled}
          onClick={this.loadCalculatedFieldsConfig}
        >
          Import
        </Button>
      </Upload>,
      <Button
        icon="download"
        size="small"
        key="export"
        disabled={disabled}
        onClick={this.onExportButtonClick}
      >
        Export
      </Button>,
      <Button
        type="primary"
        size="small"
        icon={saving ? 'loading' : 'save'}
        key="save"
        disabled={disabled}
        onClick={this.onSave}
      >
        Save
      </Button>,
      <Button
        type="primary"
        size="small"
        icon={'cloud'}
        key="publish"
        disabled={!(isDraft && id) || disabled}
        onClick={this.onPublish}
      >
        Publish
      </Button>,
      <Button
        type="default"
        size="small"
        icon={cloning ? 'loading' : 'copy'}
        key="copy"
        disabled={disabled || !id}
        onClick={this.onClone}
      >
        Clone
      </Button>,
      <Button
        type="default"
        size="small"
        icon="delete"
        key="delete"
        disabled={disabled || (id && !updatable) || !id}
        onClick={this.onDelete}
      >
        Delete
      </Button>,
      <Button
        icon="security-scan"
        size="small"
        key="Security Settings"
        disabled={disabled || isNote}
        onClick={this.toggleSecuritySettingsDrawer}
      >
        Security Settings
      </Button>,
    ];
  }

  saveRef = name => el => {
    this[name] = el;
  };

  toggleDrawer = () => {
    const currentState = this.state.openDrawer;
    this.setState({ openDrawer: !currentState });
  };

  toggleSecuritySettingsDrawer = () => {
    const currentState = this.state.isSecuritySettingsDrawerOpen;
    this.setState({ isSecuritySettingsDrawerOpen: !currentState });
  };

  showOptionsModal = () => {
    const { version = 0, status = FORM_DRAFT } = componentsManager.header;

    const customFields = getCustomFields(
      FORM_HEADER,
      undefined,
      componentsManager.header
    );

    const customFieldsInitialValue = customFields.reduce(
      (allInitialValues, { initialValue, name }) => ({
        ...allInitialValues,
        [name]: initialValue,
      }),
      {}
    );
    const customFieldNames = customFields.map(({ name }) => name);

    const modalData = buildOptionsModalContent({
      type: 'form',
      version,
      status,
      disableTab: [TAB_VALUES, TAB_CONDITIONAL, TAB_SHARE_KEY, TAB_INFORMATION],
      ...customFieldsInitialValue,
      customFields,
      onCancel: this.deleteModal,
      onSave: params => {
        let mutations = [];
        customFields.forEach(customField => {
          mutations.push(customField.onSave);
        });

        const modifiedValues = mutations.reduce(
          (allChanges, mutation) => mutation(allChanges),
          Object.assign({}, pick(params, customFieldNames))
        );
        componentsManager.header = modifiedValues;
        this.deleteModal();
      },
    });

    this.updateModal(modalData);

    this.showModal();
  };

  loadCalculatedFieldsConfig = () => {
    const { reading } = this.state;
    const { agencyId } = this.props;
    if (reading) return;

    calculatedFieldsConfig.loadCalculatedFieldsConfig(agencyId);
  };
  onImportButtonClick = file => {
    const { reading } = this.state;
    if (reading) return;

    componentsManager.importJSON(
      file,
      this.updateState,
      (schema, isReviewNote) => {
        const loc = {
          state: {
            import: true,
            schema: schema,
          },
        };
        this.finalizeJSONImport(loc, true);

        componentsManager.notify(`Template ${schema.name} was imported.`);
      }
    );
  };

  updateState = (state, callback) => {
    const { reading } = state;
    const { dispatch } = this.props;
    this.setState(state, callback);
    dispatch(onIdleStateChange(reading));
  };

  onExportButtonClick = async () => {
    const {
      id,
      fromTemplateId,
      name,
      description,
      abbreviation,
      category,
    } = componentsManager.header;
    const enumValue = mapToTemplateEnumBy(category);
    // call api to get configs
    if (id || fromTemplateId) {
      const { agencyId } = this.props;
      apiEndpoints
        .getConfig(agencyId, id || fromTemplateId)
        .then(configs => {
          componentsManager.exportJSON({
            name,
            description,
            abbreviation,
            category: enumValue,
            configs,
          });
        })
        .catch(e => {
          displayErrorMessage(`Something went wrong getting config`, e);
        });
    } else {
      componentsManager.exportJSON({
        name,
        description,
        abbreviation,
        category: enumValue,
        configs: componentsManager.configs,
      });
    }
  };

  hasImportStateProp = location => {
    return (
      get(location, 'state.import', false) &&
      get(location, 'state.schema', null)
    );
  };

  finalizeJSONImport = (location, innerImport) => {
    const {
      id,
      name,
      abbreviation,
      description,
      formSchema,
      jsonSchema,
    } = Object.assign(
      {},
      { name: '', abbreviation: '', description: '' },
      get(location, 'state.schema', {})
    );

    //innerImport is the action of importing a template
    //from FormBuilder and not from the list of templates.
    if (innerImport) {
      componentsManager.header = {
        name: name ? name.trim() : '',
        abbreviation: abbreviation ? abbreviation.trim() : '',
        description,
      };
    } else {
      componentsManager.header = {
        name: name ? name.trim() : '',
        abbreviation: abbreviation ? abbreviation.trim() : '',
        description,
        default: false,
        updatable: true,
        id: undefined,
        isLoading: false,
        fromTemplateId: id,
      };
    }
    componentsManager.flags.isLoading = false;
    componentsManager.uploadFormSchema(
      isEmpty(formSchema) ? jsonSchema : formSchema
    );
  };

  drawChangelogOnTab(tabKey) {
    const { initialTab } = this.state;
    const changelogData = componentsManager.getChangelog();
    if (tabKey === initialTab) {
      return this.setState({ changelog: changelogData });
    }
    const filteredChangelog = changelogData.filter(
      field => field.changeType === tabKey
    );
    this.setState({ changelog: filteredChangelog });
  }

  getChangelog() {
    return this.state.changelog.map((field, index) => {
      const { componentId, changeType, fieldType, title, reportingKey } = field;
      const fieldName = <b> {` (${reportingKey})`}</b>;
      return (
        <div key={`${componentId}${index}`}>
          <Badge
            status={getBadgeStatus(changeType)}
            text={
              <Fragment>
                {changeType} {fieldType} {title} {fieldName}
              </Fragment>
            }
          />
          <br />
        </div>
      );
    });
  }

  renderTabsContent() {
    const { initialTab } = this.state;
    const changelogData = componentsManager.getChangelog();
    const changelogTabs = changelogData.map(field => field.changeType);
    const tabs = concat(initialTab, uniq(changelogTabs));
    return tabs.map(change => {
      return (
        <TabPane tab={upperFirst(change)} key={change}>
          {this.getChangelog()}
        </TabPane>
      );
    });
  }

  render() {
    const { agencyId, sidebarIsCollapsed } = this.props;
    const {
      headerHeight,
      executedAction,
      openDrawer,
      header = {},
    } = this.state;
    const {
      name,
      description,
      abbreviation,
      shareable,
      version = 0,
      status = FORM_DRAFT,
    } = header;
    const { isLoading } = componentsManager.flags;
    return (
      this._isMounted && (
        <AdministratorWrapper>
          <PageHeader
            isLoading={isLoading}
            className="form-builder-header"
            title={
              <div>
                <span>{name}</span>{' '}
                <Icon type="right" className="title-separator" />{' '}
                {!isLoading && (
                  <span className="header-title__subtitle">
                    <span className="abbreviation">{abbreviation}</span>
                    <IconButton
                      icon="setting"
                      onClick={() => this.showOptionsModal()}
                      disabled={executedAction}
                      className="is-big is-focusable"
                    />
                  </span>
                )}
                <span className="version">v. {version}</span>
                <span className="status">&nbsp;{status}</span>
              </div>
            }
            goBackTo={getRoute('administratorAgencyFormBuilderList', {
              agencyId,
            })}
            actions={this.pageActions}
            saveRef={this.saveRef('pageHeader')}
            sidebarIsCollapsed={sidebarIsCollapsed}
            stickToTop
          >
            <div className="description">
              <div>
                <span className="subtitle">Description:&nbsp;</span>
                <span className="content">{isLoading ? '' : description}</span>
              </div>
            </div>
          </PageHeader>

          {openDrawer && (
            <CustomDrawer
              title="Current changelog"
              placement="right"
              width={350}
              closable={false}
              onClose={this.toggleDrawer}
              visible={openDrawer}
            >
              <Tabs onChange={tabKey => this.drawChangelogOnTab(tabKey)}>
                {this.renderTabsContent()}
              </Tabs>
            </CustomDrawer>
          )}
          {
            <SecuritySettingsDrawer
              visible={this.state.isSecuritySettingsDrawerOpen}
              shareable={shareable}
              onClose={this.toggleSecuritySettingsDrawer}
            ></SecuritySettingsDrawer>
          }
          <FormBuilder
            executedAction={executedAction}
            headerHeight={headerHeight}
          />
        </AdministratorWrapper>
      )
    );
  }
}

const mapStateToProps = (state, props) => ({
  agencyId: get(props, 'match.params.agencyId'),
  sidebarIsCollapsed: sidebarIsCollapsed(state),
});

export default withoutClutter(
  DashboardPage(mapStateToProps, null, { sidebarCollapsed: true })(
    withOwnershipGuard(Component, 'administratorAgencyFormBuilderList')
  )
);
