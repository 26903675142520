import { get } from 'lodash';
import { POST_EMPTY_DRAFT_REPORT_REQUEST } from './';
import { postDraftReport } from '../api/reports';
import postEmptyDraftReportSuccess from './post-empty-draft-report-success';
import postEmptyDraftReportError from './post-empty-draft-report-error';

export default ({ templateId = '', data = {} }, next = () => {}) => async (
  dispatch,
  getState
) => {
  const { session: { currentUser: { agencyId } = {} } = {} } = getState();
  dispatch({ type: POST_EMPTY_DRAFT_REPORT_REQUEST });

  return new Promise(async (resolve, reject) => {
    try {
      const report = await postDraftReport({
        agencyId,
        templateId,
        data,
      });
      dispatch(postEmptyDraftReportSuccess({ report }));
      next(false, report);
      resolve(report);
    } catch (e) {
      dispatch(postEmptyDraftReportError(get(e, ['response', 'status'])));
      next(e);
      reject(e);
    }
  });
};
