import { get, first } from 'lodash';
import FieldSelect from '../../components/FieldSelect';

import componentsManager from '../../services/componentsManager';
import tabsManager from '../../services/tabsManager';
import getCustomFieldOptionsWithFallback from './getCustomFieldOptionsWithFallback';

export default class removeRefKeys {
  constructor(values, value = '') {
    this.currentFieldId = values.id;
    const fields = componentsManager.fields;
    const repeaters = componentsManager.repeatersReference;

    this.name = 'removeRefKeys';
    this.label = 'Remove Ref keys';
    this.component = FieldSelect;
    this.defaultValue = '';
    this.defaultOptions = get(values, ['options', 'removeRefKeys']);
    this.value = value;
    this.selectOptions = fields.map((tab, i) => ({
      label: tab.title,
      enums: (repeaters[i] || [])
        .filter(r => !r.fields[this.currentFieldId])
        .map(r => {
          const [rep] = componentsManager.findContainersByKey(
            fields[i].properties,
            r.repeaterKey
          );
          return {
            label: rep.reportingKey,
            value: r.repeaterKey,
          };
        }),
    }));
    // if no activeTabIndex then we are in review note mode
    if (tabsManager.activeTabIndex < 0) {
      const option = this.selectOptions.pop();
      this.selectOptions = [...this.selectOptions, ...option.enums];
    }
  }

  onSave = values => {
    const selectedOption = get(values, this.name, this.value);
    const { options } = values;

    const savedOptions = selectedOption
      ? [
          {
            ...first(this.defaultOptions),
            source: selectedOption,
          },
        ]
      : undefined;

    return {
      ...values,
      [this.name]: undefined,
      options: {
        ...options,
        [this.name]: savedOptions,
      },
    };
  };
  getInitialValue = values => {
    const { options } = values;
    if (options === undefined) {
      return this.defaultValue;
    }

    const removeKeys = get(options, this.name);
    const valueRemoveKeys = get(first(removeKeys), 'source');

    return valueRemoveKeys ? valueRemoveKeys : this.defaultValue;
  };

  beforeOnChange = (value, values) => {
    const current = values.customFields.find(cf => cf.name === this.name);
    const currentValues = { ...values, id: current.currentFieldId };
    const field = new removeRefKeys(currentValues, value);

    return {
      customFields: values.customFields.map(cf =>
        cf.name !== this.name
          ? cf
          : getCustomFieldOptionsWithFallback(values)(field)
      ),
    };
  };
}
