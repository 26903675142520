import { createReducer } from 'redux-create-reducer';

import {
  ORGANIZATION_GET_COMMUNITY_LIST_REQUEST,
  ORGANIZATION_GET_COMMUNITY_LIST_SUCCESS,
  ORGANIZATION_GET_COMMUNITY_LIST_ERROR,
} from '../../actions/organizations/get-community-list';

const initialState = {
  total: 0,
  totalPages: 0,
  pageSize: 50,
  pageNumber: 0,
  error: false,
  loading: false,
  data: [],
};

const events = {
  [ORGANIZATION_GET_COMMUNITY_LIST_REQUEST]: (state, { payload }) => ({
    ...state,
    pageNumber: payload.pageNumber,
    pageSize: payload.pageSize,
    loading: true,
  }),
  [ORGANIZATION_GET_COMMUNITY_LIST_SUCCESS]: (state, { payload }) => {
    const {
      content: { total, totalPages, communityMembers },
    } = payload;
    return {
      ...state,
      total: total,
      totalPages: totalPages,
      data: communityMembers,
      loading: false,
    };
  },
  [ORGANIZATION_GET_COMMUNITY_LIST_ERROR]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: true,
  }),
};

export default createReducer(initialState, events);
