import { get } from 'lodash';
import updateFieldValidation from '../utils/updateFieldValidation';
import getComponentData from '../utils/getComponentData';

const verifyFieldRule = (field, validations, isNote, parentKey = {}) => {
  const { key: pKey } = parentKey;
  const { key } = field;
  if (pKey) {
    const fields = get(validations, `rules.${pKey}[0].item[0].fields`, {});
    const [newFields, fixedRule] = verifyFieldRule(
      field,
      { rules: fields },
      isNote
    );
    validations.rules[pKey] = [
      {
        type: 'array',
        item: [
          {
            type: 'object',
            fields: newFields.rules,
          },
        ],
      },
    ];
    return [validations, fixedRule];
  } else if (!validations.rules[key]) {
    const [, , fieldValidations, ,] = getComponentData(field, key, () => {}, 2);
    const newValidations = updateFieldValidation(
      field,
      {
        required: false,
        validations: fieldValidations,
      },
      validations,
      isNote
    );
    return [newValidations, true];
  } else if (!Array.isArray(validations.rules[key])) {
    const { rules = {} } = validations;
    const newValidations = {
      ...validations,
      rules: {
        ...rules,
        [key]: [rules[key]],
      },
    };
    return [newValidations, true];
  }
  return [validations, false];
};

export default verifyFieldRule;
