import {
  genOptions,
  route,
  fetchRequest,
  encodeFilter,
} from '../utils/request';
import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';

export const getAgencyTemplates = agencyId => {
  const token = storage.get('token');
  const url = route(urlBuilder(`/templates`, null, { agencyId }));
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

export const getFormTemplates = ({ filter: filters = {}, agencyId = '' }) => {
  const filter = encodeFilter(filters);
  const url = route(urlBuilder(`/templates`, null, { filter, agencyId }));
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const createWorkflow = ({ workflow, templateId, agencyId = '' }) => {
  const url = route(
    urlBuilder(`/Agencies/:agencyId/templates/:templateId/workflows`, {
      agencyId,
      templateId,
    })
  );
  const options = genOptions(
    'POST',
    { transitions: workflow },
    storage.get('token')
  );

  return fetchRequest(url, options);
};

export const updateWorkflow = ({
  workflow,
  templateId,
  agencyId = '',
  workflowId,
}) => {
  const url = route(
    urlBuilder(
      `/Agencies/:agencyId/templates/:templateId/workflows/:workflowId`,
      {
        agencyId,
        templateId,
        workflowId,
      }
    )
  );
  const options = genOptions(
    'PUT',
    { transitions: workflow },
    storage.get('token')
  );

  return fetchRequest(url, options);
};

export const deleteWorkflow = ({ templateId, agencyId = '', workflowId }) => {
  const url = route(
    urlBuilder(
      `/Agencies/:agencyId/templates/:templateId/workflows/:workflowId`,
      {
        agencyId,
        templateId,
        workflowId,
      }
    )
  );
  const options = genOptions('DELETE', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const getWorkflows = ({ templateId, agencyId = '' }) => {
  const url = route(
    urlBuilder(`/Agencies/:agencyId/templates/:templateId/workflows`, {
      agencyId,
      templateId,
    })
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const getWorkflow = ({ templateId, agencyId = '', workflowId }) => {
  const url = route(
    urlBuilder(
      `/Agencies/:agencyId/templates/:templateId/workflows/:workflowId`,
      {
        agencyId,
        templateId,
        workflowId,
      }
    )
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};
