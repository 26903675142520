import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import { v4 as uuid } from 'uuid';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { mapRepeaterActions } from 'APP_ROOT/utils/form';
import getOverrides from 'APP_ROOT/utils/get-field-overrides';

import RepeaterActionWrapper from '../styled/repeater-action-wrapper';
import ConditionalAssert from './conditional';

import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';
import { getFormTemplate, getDataEnums } from '../../../selectors/form';
import { getDataSelector } from 'APP_ROOT/utils/renderSchema';

const onAddButtonClick = (formName, ...args) => () =>
  emitFormEvent('addRepeaterItem', formName, ...args);

const getGroupData = (hasParent, data, parentKey, index, isReviewer) => ({
  ...(hasParent ? get(data, [parentKey, index], {}) : data),
  isReviewer,
});

class AddButton extends Component {
  getRenderButton = (assert = true, behavior = 'hide') => {
    const {
      keys = [],
      templates = [],
      title = '',
      data = {},
      parentKey = '',
      syncKeys,
      seedKeys,
      overrides = {},
      symlinkPath,
      settings,
    } = this.props;

    const fieldOverrides = getOverrides(this.props, overrides);
    const options = fieldOverrides('options', {});

    const { actions = [] } = options;
    return (
      <RepeaterActionWrapper>
        {assert && actions.map(mapRepeaterActions(data, parentKey, this.props))}

        <Button
          ghost
          type="primary"
          disabled={!assert && behavior === 'disable' ? true : false}
          onClick={onAddButtonClick(
            settings.formName,
            keys,
            templates,
            uuid(),
            syncKeys,
            {},
            seedKeys,
            symlinkPath
          )}
        >
          <Icon type="plus" /> {title || 'Add Item'}
        </Button>
      </RepeaterActionWrapper>
    );
  };

  render() {
    const {
      conditions = {},
      data = {},
      parentIndex = 0,
      parentKey = '',
      isReviewer = false,
      type,
      field_type,
      settings,
      properties,
      autoResetFieldValues = false,
    } = this.props;

    const index = parentIndex > 0 ? parentIndex - 1 : parentIndex;
    const hasParent = !!parentKey;
    const groupData = getGroupData(
      hasParent,
      data,
      parentKey,
      index,
      isReviewer
    );

    return (
      <ConditionalAssert
        type={type}
        field_type={field_type}
        settings={settings}
        properties={properties}
        forcedUpdatesOn={data[parentKey]}
        conditions={conditions}
        data={groupData}
        wholeData={data}
        render={this.getRenderButton}
        autoResetFieldValues={autoResetFieldValues}
      />
    );
  }
}

const mapState = (state, props) => {
  const dataSelector = getDataSelector(props);
  const formTemplate = getFormTemplate(state, props);

  return {
    data: dataSelector(state, props),
    dataEnums: getDataEnums(state, props, formTemplate),
  };
};

export default connect(mapState)(AddButton);
