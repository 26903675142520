import { PERMISSIONS } from '../../../../utils/admin';

export const VIEW_OPTION_MY_WORKLOAD = 'my-workload';
export const VIEW_OPTION_ADMIN_ALL = 'admin-all';

export const reportViews = [
  {
    title: 'Admin All',
    value: VIEW_OPTION_ADMIN_ALL,
    permissions: [PERMISSIONS.viewReports, PERMISSIONS.viewAllReports],
  },
  {
    title: 'My Workload',
    value: VIEW_OPTION_MY_WORKLOAD,
    permissions: [],
  },
  {
    title: 'Organization',
    value: 'organization',
    permissions: [PERMISSIONS.listOUReportsByHK],
  },
  {
    title: 'Shared',
    value: 'shared',
    permissions: [],
  },
];
