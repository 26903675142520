import React from 'react';

import SortAndFilterTable from '../../../components/filterable-sortable-table/SortAndFilterTable';
import { communityColumns } from './CommunityTableColumns';
import { StyledTable } from './CommunityTable.styled';
import { normalizeData } from './communityUtils';

const CommunityTable = ({ communityList }) => {
  const data = normalizeData(communityList.data);
  return (
    <StyledTable>
      <SortAndFilterTable
        columns={communityColumns}
        data={data}
        pagination={false}
        rowKey={'id'}
        scroll={true}
      />
    </StyledTable>
  );
};

export default CommunityTable;
