import { transformToConfig } from '../components/route-render';
import adminRoutes from '../containers/administrator/Administrator.routes';

export default [
  {
    path: '/login',
    name: 'login',
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
  },
  {
    path: '/forgot-password-confirmation',
    name: 'forgotPasswordConfirmation',
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
  },
  {
    path: '/',
    name: 'dashboard',
    childRoutes: [
      {
        path: '/use-of-force/new',
        name: 'newUseOfForce',
      },
      {
        path: '/intake/new',
        name: 'newIntakeForm',
      },
      {
        path: '/vehicle-pursuit/new',
        name: 'newPursuitForm',
      },
      {
        path: '/reports/create-new',
        name: 'createNewReport',
      },
      {
        path: '/reports',
        name: 'reports',
      },
      {
        path: '/organizations',
        name: 'OuList',
      },
      {
        path: '/reports/:id',
        name: 'report',
      },
      {
        path: '/reports/:id/:viewAs',
        name: 'reportsViewAs',
      },
      {
        path: '/reports/uof-report',
        name: 'izendaReportsUOF',
      },
      {
        path: '/report-designer',
        name: 'izendaReportDesigner',
      },
      {
        path: '/canned-reports',
        name: 'izendaReportViewer',
      },
      {
        path: '/canned-reports/view/:reportId',
        name: 'izendaReportViewerSingle',
      },
      {
        path: '/files',
        name: 'caseFileList',
      },
      {
        path: '/files/:id',
        name: 'openCaseFile',
      },
      {
        path: '/files/create-new',
        name: 'createNewCaseFile',
      },
      {
        path: '/dashboard',
        name: 'izendaDashboardViewer',
      },
      {
        path: '/dashboard/edit/:dashboardId',
        name: 'izendaDashboardViewerSingle',
      },
      {
        path: '/admin-reporting',
        name: 'izendaReportSettings',
      },
      {
        path: '/viewer/reportpart/:reportPartId',
        name: 'izendaReportPartViewer',
      },
      {
        path: '/activity',
        name: 'activity',
      },
      {
        path: '/performance-evaluation',
        name: 'performanceEvaluation',
      },
      {
        path: '/field-training',
        name: 'fieldTraining',
      },
      {
        path: '/field-training/:id',
        name: 'fieldTrainingDetails',
      },
      {
        path: '/officers',
        name: 'officers',
      },
      {
        path: '/officers/complaints',
        name: 'complaints',
      },
      {
        path: '/officers/complaints/details',
        name: 'complaintDetails',
      },
      {
        path: '/officers/complaints',
        name: 'complaints',
      },
      {
        path: '/people/:id/profile',
        name: 'officerProfile',
      },
      {
        path: '/people/:id/use-of-force',
        name: 'officerUseOfForce',
      },
      {
        path: '/people/:id/internal-affairs',
        name: 'officerInternalAffairs',
      },
      {
        path: '/people/:id/activity',
        name: 'officerActivity',
      },
      {
        path: '/people/:id/training',
        name: 'officerTraining',
      },
      {
        path: '/people/:id/transcripts',
        name: 'officerTranscripts',
      },
      {
        path: '/people/:id/community-engagement',
        name: 'officerCommunityEngagement',
      },
      {
        path: '/people/:id/performance-evaluation',
        name: 'officerPerformanceEvaluation',
      },
      {
        path: '/exceptional-performance/:id',
        name: 'exceptionalPerformance',
      },
      {
        path: '/needing-review/:id',
        name: 'needingReview',
      },
      {
        path: '/under-care/:id',
        name: 'underCare',
      },
      {
        path: '/activity-feed',
        name: 'activityFeed',
      },
      {
        path: '/action-item-feed',
        name: 'actionItemsFeed',
      },
      {
        path: '/people',
        name: 'people',
      },
      {
        path: '/people-ou',
        name: 'peopleOu',
      },
      {
        path: '/settings',
        name: 'settings',
      },
      {
        path: '/organization-profile/:organizationalUnitId',
        name: 'organizationProfile',
      },
      {
        path: '/internal-affairs',
        name: 'internalAffairs',
      },
      {
        path: '/agency/:agencyId/user-profile/:userId',
        name: 'userProfile',
      },
      {
        path: '/agency/:agencyId/export-reports',
        name: 'exportReports',
      },
      {
        path: '/agency/:agencyId/export-rule-28',
        name: 'exportRule28',
      },
      {
        path: '/audit-logs',
        name: 'auditLogs',
      },
      {
        path: '/community',
        name: 'community',
      },
      {
        path: '/community/:id',
        name: 'communityDetail',
      },
      {
        path: '/community/create-new',
        name: 'createCommunityDetail',
      },
      ...transformToConfig(adminRoutes),
    ],
  },
];
