import styled from 'styled-components';

import { ACCENT } from 'APP_ROOT/config/theme';
import TrainingTaskDocument from '../object-types/TrainingTaskDocument';

const StyledTrainingTaskDocument = styled(TrainingTaskDocument)`
  margin-bottom: 30px;
  font-size: 1.2em;

  &,
  a {
    color: ${ACCENT};
  }
`;

export default StyledTrainingTaskDocument;
