import { isArray } from 'lodash';

import { VALIDATION_TYPE_ARRAY } from './constants';
import verifyRequired from './verifyRequired';
import verifyType from './verifyType';

const verifyRepeaterRule = (rules, fieldsByKey, enums, isNote, repeaterKey) => {
  const [rule] = rules[repeaterKey];
  const {
    item: [{ type, fields }],
  } = rule;
  const fieldKeys = Object.keys(fields);
  let obj = { enums, valid: {}, removed: [], required: [], type: [] };

  if (fieldsByKey[repeaterKey]) {
    const newRules = fieldKeys.reduce(
      verifyRule(fields, fieldsByKey[repeaterKey], isNote, repeaterKey),
      obj
    );
    obj = {
      valid: {
        [repeaterKey]: [
          {
            type: VALIDATION_TYPE_ARRAY,
            item: [
              {
                type,
                fields: newRules.valid,
              },
            ],
          },
        ],
      },
      enums: newRules.enums,
      removed: newRules.removed,
      required: newRules.required,
      type: newRules.type,
    };
  } else {
    obj.removed = fieldKeys.map(f => `${repeaterKey}.${f}`);
  }

  return obj;
};

const getRule = (rules, fieldKey) => {
  const isArrayRule = isArray(rules[fieldKey]);
  let rule;
  if (isArrayRule) {
    [rule] = rules[fieldKey];
  } else {
    // upload children are the only case where rule is not an array,
    // pointing directly to an object, but it should be an array
    rule = rules[fieldKey];
  }
  return rule;
};

/**
 * To verify a validation rule, this function returns a function
 * to be used as parameter for a reducer
 * @param {Object} rules set of rules from validation section
 * @param {Object} fieldsByKey object with all the fields { fieldKey: field }
 * @returns function to be used as reducer paramenter
 */
const verifyRule = (rules, fieldsByKey, isNote, parentKey) => (
  obj,
  fieldKey
) => {
  const { valid, enums, removed, required, type } = obj;
  const isArrayRule = isArray(rules[fieldKey]);
  const key = parentKey ? `${parentKey}.${fieldKey}` : fieldKey;
  const rule = getRule(rules, fieldKey);
  if (rule.type === VALIDATION_TYPE_ARRAY) {
    const repeaterRules = verifyRepeaterRule(
      rules,
      fieldsByKey,
      enums,
      isNote,
      fieldKey
    );
    obj = {
      valid: {
        ...valid,
        ...repeaterRules.valid,
      },
      enums: repeaterRules.enums,
      removed: removed.concat(repeaterRules.removed),
      required: required.concat(repeaterRules.required),
      type: type.concat(repeaterRules.type),
    };
  } else if (fieldsByKey[fieldKey]) {
    let fixedType;
    let [newRule, fixedRequired] = verifyRequired(
      rule,
      fieldKey,
      isNote,
      parentKey
    );
    [newRule, obj.enums, fixedType] = verifyType(
      newRule,
      fieldsByKey,
      enums,
      fieldKey
    );
    // all the fields should be in array, however, this
    // is going to be fixed in verifyFieldRule
    valid[fieldKey] = isArrayRule ? [newRule] : newRule;
    if (fixedRequired) {
      required.push(key);
    }
    if (fixedType) {
      type.push(key);
    }
  } else {
    removed.push(key);
  }
  return obj;
};

export default verifyRule;
