import React from 'react';
import { camelCase } from 'lodash';
import { Redirect } from 'react-router-dom';

import TemplateWorkflows from './TemplateWorkflows';
import { routes as workflowBuilder } from '../workflow-builder';
import { PERMISSIONS } from '../../../../utils/admin';

export default ({ path = '', name = '' }) => {
  const routeConfig = {
    path: `${path}/:templateId/workflows`,
    name: camelCase(`${name}-template`),
  };

  return [
    {
      path: `${path}/:templateId`,
      name: camelCase(`${name}-template-workflows`),
      component: ({ match }) => <Redirect to={`${match.url}/workflows`} />,
      protectedWith: [PERMISSIONS.manageWorkflows],
      childRoutes: [
        {
          ...routeConfig,
          protectedWith: [PERMISSIONS.manageWorkflows],
          component: TemplateWorkflows,
          childRoutes: workflowBuilder(routeConfig),
        },
      ],
    },
  ];
};
