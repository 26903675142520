import styled from 'styled-components';
import { Card } from 'antd';

const StatusCardWrapper = styled(Card)`
  width: 160px;
  border: 2px solid transparent;
  transition: all 0.33s ease-in-out;
  border-radius: 4px;

  &.case-file-status-card-open {
    .ant-card-head-title {
      color: #161eb5;
    }
  }

  &.case-file-status-card-paused {
    .ant-card-head-title {
      color: #f0b513;
    }
  }

  &.case-file-status-card-overdue {
    .ant-card-head-title {
      color: #d42020;
    }
  }

  &.case-file-status-card-closed {
    .ant-card-head-title {
      color: #238a1d;
    }
  }

  &.case-file-status-card-without_status {
    .ant-card-head-title {
      color: #2b303a;
    }
  }

  .ant-card-head-title {
    font-size: 32px;
    text-align: center;
  }

  .ant-card-head {
    border-bottom: none;
  }

  .ant-card-body {
    text-align: center;
    padding: 24px 8px;

    b {
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    .ant-skeleton-content {
      padding: 0 16px;
    }
  }
`;

const StatusGroup = styled.div.attrs({
  className: 'case-file-status-group',
})`
  display: grid;
  grid-template-columns: auto;
  gap: 5px;
  margin-top: 8px;

  > span {
    display: flex;
    align-items: center;
    text-align: left;

    > strong {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }
`;

export { StatusCardWrapper, StatusGroup };
