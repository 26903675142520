import React, { useState } from 'react';
import { Button } from 'antd';

import PageHeader from 'APP_COMPONENTS/PageHeader';
import getRoute from 'APP_ROOT/utils/get-route';
import RolesModal from '../RolesModal';

export default ({
  isSidebarIsCollapsed,
  agencyName,
  agencyId,
  agencyProfile,
  getRoles = () => {},
}) => {
  const [showModal, setShowModal] = useState(false);

  const onModalVisibleAction = value => setShowModal(value);
  const title = `${agencyName} - Roles and Permissions`;
  const actions = [
    <Button
      key="0"
      type="primary"
      icon="plus"
      onClick={() => onModalVisibleAction(true)}
    >
      Create Role
    </Button>,
  ];
  return (
    <>
      <PageHeader
        title={title}
        goBackTo={getRoute('administratorAgency', { agencyId })}
        sidebarIsCollapsed={isSidebarIsCollapsed}
        actions={actions}
      />
      <RolesModal
        isModalVisible={showModal}
        onModalVisibleAction={onModalVisibleAction}
        agencyProfile={agencyProfile}
        agencyId={agencyId}
        getRoles={getRoles}
      />
    </>
  );
};
