import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Icon } from 'antd';
import postUserEquipment from '../../../agency-equipment/actions/post-user-equipment';
import encodeEquipmentData from './utils/encodeEquipmentData';
import validateFormModal from './utils/validateFormModal';
import fetchReadableStream from '../../../../caseFile/utils/fetchReadableStream';

import ModalTitle from '../../../../../components/common/modal/title';
import ModalBody from '../../../../../components/common/modal/body';
import createModal from '../../../../../actions/create-modal';
import showModal from '../../../../../actions/show-modal';
import updateModal from '../../../../../actions/update-modal';
import deleteModal from '../../../../../actions/delete-modal';

const SUCCESS_MESSAGE = 'Equipment successfully created.';
const ERROR_MESSAGE = 'Error creating equipment.';
const ERROR_MESSAGE_NON_UNIQUE_EXTERNAL_ID =
  'This External ID is already in use on another item.';

const useUserEquipmentNew = ({
  equipmentModalFields,
  tenantId,
  userId,
  selectedCategory = '',
  equipment,
  onSave,
  dispatch,
}) => {
  const [validationMessages, setValidationMessages] = useState({});
  const [isValid, setIsValid] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [modalId, setModalId] = useState(null);

  useEffect(() => {
    const id = createModal({ visible: false });
    setModalId(id);

    return () => {
      deleteModal(id);
    };
  }, []);

  const memoizedEquipmentModalFields = useMemo(() => equipmentModalFields, [
    equipmentModalFields,
  ]);
  const memoizedEquipment = useMemo(() => equipment, [equipment]);

  const showErrorModal = useCallback(
    message => {
      dispatch(
        updateModal({
          id: modalId,
          title: (
            <ModalTitle error>
              <Icon type="exclamation-circle" /> <span>Heads up!</span>
            </ModalTitle>
          ),
          children: <ModalBody>{message}</ModalBody>,
        })
      );
      dispatch(showModal({ id: modalId }));
    },
    [dispatch, modalId]
  );

  const callback = useCallback(
    (error, data) => {
      setIsProcessing(false);
      if (error && error.response && error.response.body) {
        fetchReadableStream(error.response.body, result => {
          if (result.userMessage === ERROR_MESSAGE_NON_UNIQUE_EXTERNAL_ID) {
            showErrorModal(result.userMessage);
          } else {
            onSave(
              { success: SUCCESS_MESSAGE, error: ERROR_MESSAGE },
              error,
              data
            );
          }
        });
      } else {
        onSave({ success: SUCCESS_MESSAGE, error: ERROR_MESSAGE }, error, data);
      }
    },
    [onSave, showErrorModal]
  );

  const onSaveEquipment = useCallback(() => {
    setIsProcessing(true);
    const { isValid, validationMessages } = validateFormModal({
      equipment: memoizedEquipment,
      equipmentModalFields: memoizedEquipmentModalFields,
    });
    setIsValid(isValid);
    setValidationMessages(validationMessages);

    if (!isValid) {
      setIsProcessing(false);
      return;
    }

    dispatch(
      postUserEquipment({
        tenantId,
        userId,
        body: {
          category: { id: selectedCategory },
          fieldsValues: memoizedEquipment.map(({ fieldId, type, value }) => ({
            fieldId,
            value: encodeEquipmentData(type, value),
          })),
        },
        next: callback,
      })
    );
  }, [
    dispatch,
    memoizedEquipment,
    memoizedEquipmentModalFields,
    tenantId,
    userId,
    selectedCategory,
    callback,
  ]);

  return {
    onSaveEquipment,
    isValid,
    isProcessing,
    validationMessages,
  };
};

export default useUserEquipmentNew;
