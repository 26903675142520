import React, { PureComponent } from 'react';
import { unescape as htmlUnescape } from 'html-escaper';

import { StyledErrorMessage } from './styled';
import FILE_STATUSES from './file-statuses.constants';
import { Text } from '../';

const [DONE, ERROR, UPLOADING] = FILE_STATUSES;

class AttachmentNarrative extends PureComponent {
  onBlur = evt => {
    const { attachment, onTextFieldChange, onBlur } = this.props;

    onBlur(evt);
    onTextFieldChange(attachment.id, evt);
  };

  render() {
    const {
      attachment,
      onTextFieldChange,
      onBlur,
      value = '',
      ...rest
    } = this.props;

    switch (attachment.status) {
      case UPLOADING:
        return <span />;
      case ERROR:
        return <StyledErrorMessage>Wrong file format</StyledErrorMessage>;
      case DONE:
      default:
        return (
          <Text
            placeholder="Add note"
            onBlur={this.onBlur}
            value={htmlUnescape(value)}
            {...rest}
          />
        );
    }
  }
}

export default AttachmentNarrative;
