import _, { isEmpty, get, first, find, isString } from 'lodash';
import isUUID from 'is-uuid';
import getOfficerName from './get-officer-name';

export const toArray = (dataObject = {}) => {
  return (dataObject ? Object.keys(dataObject) : []).map(
    key => dataObject[key]
  );
};

export const mapLabel = (label = '', data = {}) => {
  if (Array.isArray(label)) {
    return label.map(item => _.get(data, item, '')).join(' ');
  }

  return label;
};

export const assertCondition = (toBe, itemValue, expect) => {
  switch (toBe) {
    case 'neq':
      return itemValue !== expect;
    default:
      return itemValue === expect;
  }
};

const allowedAsserts = ['eq', 'neq'];

const withPrefix = (label = '', prefix = '') =>
  prefix ? [prefix, label].join(' ') : label;

export const mapData = (
  data = {},
  conditions = {},
  formData = {},
  enums = {}
) => {
  const users = get(formData, ['__users'], {});
  const normalizedData = Array.isArray(data) ? data : toArray(data);
  const normalizedConditions = Array.isArray(conditions)
    ? conditions
    : [conditions];

  const getSourcefromEnum = ({ item, label, enums, users }) => {
    const value = get(item, [label]);
    const sourceEnumKey = first(
      Object.keys(enums).filter(key => key.includes(label))
    );
    const sourceEnumData = _.find(get(enums, [sourceEnumKey]), {
      value,
    });

    if (sourceEnumData) {
      return sourceEnumData;
    } else {
      const foundData = find(users, { id: value });
      return {
        value: get(foundData, 'id'),
        label: get(foundData, 'fullName'),
      };
    }
  };

  return normalizedData.reduce((res, item, index) => {
    const assertConditions = normalizedConditions.reduce(
      (result, condition) => {
        const {
          key = '',
          expect = '',
          map = {},
          value = '',
          label = '',
          prefix = '',
          find = {},
          defaultLabel: defaultLabelFallback = '',
          toBe = 'eq',
          isAutocomplete = false,
        } = condition;

        const {
          label: labelInMap = '',
          value: valueInMap = '',
          defaultLabel = '',
          prefix: mapPrefix = '',
        } = map;

        const labelPrefix = mapPrefix || prefix || '';

        const mapDefaultAs = defaultLabel || defaultLabelFallback || expect;

        if (key && expect) {
          const itemValue = _.get(item, [key], '');
          const asserts = allowedAsserts.reduce((complies, method) => {
            if (complies) {
              return complies;
            }

            if (method === toBe) {
              return assertCondition(toBe, itemValue, expect);
            }

            return false;
          }, false);

          if (asserts) {
            const findValue = () => {
              const { key, source } = find;
              const s = _.get(formData, _.toPath(source), {});
              const itemV = _.get(item, _.toPath(key));
              return _.get(s, _.toPath(itemV || key));
            };
            const labelSource = findValue() || item;
            const assertLabel = mapLabel(
              labelInMap,
              Object.assign({}, labelSource, { index: index + 1 })
            );

            const assertValue = _.get(item, [valueInMap], '');
            const title =
              assertLabel.trim().length > 0 ? assertLabel : mapDefaultAs;

            if (isAutocomplete) {
              const assertFromAutocomplete = getSourcefromEnum({
                item,
                label: first(labelInMap),
                enums,
                users,
              });
              return assertFromAutocomplete;
            }

            let optionValue = assertValue || _.get(item, value, value);
            let optionLabel = withPrefix(label || title, labelPrefix);

            // For implementation reasons, if the source is not from users, a value of uuid is received
            // so the option must have the same value of the label as the value
            // Besides validates if the find prop is empty it means is an unknown entry.
            if (
              (isUUID.v4(optionValue) || isEmpty(optionValue)) &&
              isEmpty(find)
            ) {
              // Validate a valid value for a label, otherwise fallback to default
              optionLabel = isString(optionLabel)
                ? optionLabel
                : defaultLabelFallback;
              // unKnownValue get the value to know if the labelPrefix includes Unknown
              // and in that way assign the value or the label.
              const unKnownValue = labelPrefix.includes('Unknown')
                ? optionValue
                : optionLabel;
              // Here is return the value and label for the unknown entry
              return {
                value: unKnownValue,
                label: optionLabel,
              };
            }

            return {
              value: optionValue,
              label: optionLabel,
            };
          }
          return result;
        } else if (isAutocomplete) {
          const resultFromAutocomplete = getSourcefromEnum({
            item,
            label,
            enums,
            users,
          });
          return resultFromAutocomplete;
        } else if (value && label) {
          const title = mapLabel(
            label,
            Object.assign({}, item, { index: index + 1 })
          );

          return {
            value: _.get(item, [value]),
            label: withPrefix(title, labelPrefix),
          };
        }

        return result;
      },
      null
    );
    return _.uniqBy(
      [...res, assertConditions].filter(item => _.isPlainObject(item)),
      'value'
    );
  }, []);
};

export const mapTitleData = (
  data = {},
  conditions = {},
  defaultTitle = '',
  sourceData = {},
  groupData = {},
  map = [],
  users = {}
) => {
  if (isEmpty(conditions) && !isEmpty(map)) {
    let sourceTitleData = '';
    map.forEach(source => {
      const value = _.get(data, source, '');
      if (!isEmpty(value)) {
        sourceTitleData = sourceTitleData + value + ' ';
      }
    });
    if (isEmpty(sourceTitleData)) {
      return defaultTitle;
    }

    // Due to time constraints on BTD-495, we assume that in the case of a number value we would be working with a value that comes
    // from an autocomplete, in that case we show the value of the corresponding users.
    // TODO: Change the implementation to detect if it is an autocomplete depending on a specific option of the field.
    if (!isNaN(sourceTitleData)) {
      sourceTitleData = get(users, [+sourceTitleData, 'fullName']);
    }

    return sourceTitleData;
  }

  const normalizedConditions = Array.isArray(conditions)
    ? conditions
    : [conditions];

  return normalizedConditions.reduce((result, condition) => {
    const {
      key = '',
      expect = '',
      prefix = '',
      map = [],
      find = '',
    } = condition;

    if (find) {
      const sourceDataTransformed = Array.isArray(sourceData)
        ? sourceData.reduce((result, item, index) => {
            return {
              ...result,
              [item.id || index]: item,
            };
          }, {})
        : sourceData;
      const assertValue = _.get(groupData, _.toPath(find), '');
      const found = _.get(sourceDataTransformed, _.toPath(assertValue), null);

      if (found) {
        if (key && expect) {
          const itemValue = _.get(found, _.toPath(key), '');
          const asserts = itemValue === expect;

          if (asserts) {
            const mappedTitle = map.map(item =>
              _.get(found, _.toPath(item), '')
            );
            const reducedTitle =
              mappedTitle.join('').length > 0 ? mappedTitle : [expect];

            return (prefix ? [prefix].concat(reducedTitle) : reducedTitle).join(
              ' '
            );
          }
        }

        const mappedTitle = map.map(item =>
          _.get(found, _.toPath(item), _.get(groupData, _.toPath(item), ''))
        );
        const reducedTitle =
          mappedTitle.join('').length > 0 ? mappedTitle : [expect];

        return (prefix ? [prefix].concat(reducedTitle) : reducedTitle).join(
          ' '
        );
      }

      return result;
    } else if (key && expect) {
      const itemValue = _.get(
        data,
        _.toPath(key),
        _.get(groupData, _.toPath(key), '')
      );
      const asserts = itemValue === expect;

      if (asserts) {
        const mappedTitle = map.map(item =>
          _.get(data, _.toPath(item), _.get(groupData, _.toPath(item), ''))
        );

        return (prefix ? [prefix].concat(mappedTitle) : mappedTitle).join(' ');
      }

      return result;
    }

    return result;
  }, defaultTitle);
};

export const mapTitle = (title = {}, index = 0, data = {}, groupData) => {
  if (_.isPlainObject(title)) {
    const { source = '', conditions = [], defaultTitle = '', map } = title;
    const sourceGroupData = _.get(data, [source, index], {});
    const sourceData = _.get(data, [source], {});
    const users = get(data, '__users');

    return mapTitleData(
      sourceGroupData,
      conditions,
      defaultTitle,
      sourceData,
      groupData,
      map,
      users
    );
  }

  return title;
};

export const getValueFromPopulatedSource = (
  sources = [],
  populateFrom = '',
  dataKey = '',
  data = {},
  groupData = {},
  fromField = null
) => {
  const users = get(data, ['__users']);
  const usersData = Object.values(users);
  const sourcesData = sources.reduce((result, sourceName) => {
    return {
      ...result,
      [sourceName]: _.get(data, [sourceName], {}),
    };
  }, {});

  const validEnums = sources.reduce((result, sourceName) => {
    return {
      ...result,
      __users: usersData.map(user => ({
        value: user.id,
        label: getOfficerName(user),
      })),
      [sourceName]: mapData(
        sourcesData[sourceName],
        populateFrom[sourceName],
        data
      ),
    };
  }, {});

  const fieldKey = fromField || dataKey;
  const fieldValueInForm = _.get(data, fieldKey, '');
  const fieldValue = _.get(
    groupData,
    dataKey,
    !!fromField ? fieldValueInForm : ''
  );

  return Object.values(validEnums).reduce((result, enums) => {
    if (result) {
      return result;
    }

    return enums.reduce((res, item) => {
      if (res) {
        return res;
      }

      return item &&
        _.isPlainObject(item) &&
        _.has(item, 'value') &&
        isEquivalentValue(_.get(item, 'value'), fieldValue)
        ? _.get(item, 'label', '')
        : res;
    }, '');
  }, '');
};

const valuesAreValidNumbers = (left, right) =>
  (typeof left === 'number' || !isNaN(Number(left))) && !isNaN(Number(right));

const isEquivalentValue = (left, right) => {
  return valuesAreValidNumbers(left, right)
    ? Number(left) === Number(right)
    : String(left) === String(right);
};
