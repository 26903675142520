import { get, first } from 'lodash';

export default templates => {
  return templates.map(template => ({
    ...template,
    linkedMessageFormat: template.linkedMessageFormat || undefined,
    linkedFillMessageFormat: template.linkedFillMessageFormat || undefined,
    shortName: template.shortName || undefined,
    templateType: get(template, 'templateType.value'),
    type: get(template, 'templateType.value'),
    typeName: get(template, 'templateType.name'),
    versionId: get(first(get(template, 'templateVersions', [])), 'id', 1),
  }));
};
