import storage from './storage';

const websiteLogsMessage = (message, fromFile) => {
  const isThereAToken = storage.get('token') ? true : false;
  const data = {
    message: `${message} [TOKEN:${isThereAToken}]`,
    fromFile,
  };
  return data;
};

export default websiteLogsMessage;
