import React, { Component } from 'react';
import { Form, Modal, Select, Input } from 'antd';

import getAllegationColumnText from './get-allegation-column-text';

const TextArea = Input.TextArea;
const Option = Select.Option;
const FormItem = Form.Item;

const MODAL_TITLE = 'Pause on Investigation';

const lineBreak = '&#013;&#010;';

/**
 * @param {object[]} reasons
 * @return {function[]} A list of options component.
 */
function getReasonOptions(reasons) {
  return reasons.map(reason => (
    <Option value={reason.id + []} key={reason.id}>
      {reason.name}
    </Option>
  ));
}

function getSanitizedNotes(note) {
  return note.replace(/\n/g, lineBreak);
}

class PauseResumeCaseLoadModal extends Component {
  handleSubmit = e => {
    e.preventDefault();
    const {
      formattedCaseLoad,
      form: { validateFields },
      onSubmit,
    } = this.props;
    validateFields((err, values) => {
      const { resetFields } = this.props.form;
      const { notes = '' } = values;
      const sanitizedValues = {
        ...values,
        notes: notes ? getSanitizedNotes(notes) : '',
      };

      onSubmit(err, sanitizedValues, formattedCaseLoad);
      resetFields();
    });
  };

  handleSelectChange = value => {
    this.props.form.setFieldsValue({
      reason: value,
    });
  };

  handleCancel = () => {
    const {
      onCancel,
      form: { resetFields },
    } = this.props;
    resetFields();
    onCancel();
  };

  render() {
    const {
      formattedCaseLoad,
      agencyReasons = [],
      form: { getFieldDecorator },
      onFormSubmission,
      onCancel,
      confirmLoading = false,
      ...props
    } = this.props;
    const allegationsText = getAllegationColumnText(
      formattedCaseLoad.allegations,
      undefined,
      {
        style: { display: 'inline' },
      }
    );
    const reasonOptions = getReasonOptions(agencyReasons);
    const selectContainerStyle = { margin: '10px 0' };
    const selectStyle = { width: '100%' };
    return (
      <Modal
        title={MODAL_TITLE}
        {...props}
        onCancel={this.handleCancel}
        onOk={this.handleSubmit}
        confirmLoading={confirmLoading}
      >
        <div>Date Opened: {formattedCaseLoad.opened}</div>
        <div>Days Opened: {formattedCaseLoad.daysOpen}</div>
        <div>Allegations: {allegationsText}</div>
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('formSubmissionId', {
              initialValue: formattedCaseLoad.formSubmissionId,
            })(<Input type="hidden" />)}
          </FormItem>
          <div style={selectContainerStyle}>
            <FormItem>
              {getFieldDecorator('reason', {
                rules: [{ required: true, message: 'Pease select a reason' }],
              })(
                <Select
                  placeholder="Select a reason to pause this investigation"
                  style={selectStyle}
                  onChange={this.handleSelectChange}
                >
                  {reasonOptions}
                </Select>
              )}
            </FormItem>
          </div>
          <div>
            <FormItem>
              {getFieldDecorator(
                'notes',
                {}
              )(<TextArea placeholder="Notes" rows={5} />)}
            </FormItem>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(PauseResumeCaseLoadModal);
