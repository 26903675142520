import styled from 'styled-components';

import { DIVIDER, GRAY_1 } from '../../../config/theme';

const FormWrapper = styled.div.attrs({
  className: 'bdm-form-wrapper',
})`
  .header-wrapper {
    display: none;
    padding: 5px;
  }

  .footer-wrapper {
    display: none;
    padding: 5px;
  }

  .steps-navigation {
    margin: 40px 0 40px;
    position: relative;
    z-index: 9;

    .bdm-form-steps {
      position: absolute;
      top: -99999px;
      opacity: 0;
      width: 100%;
    }

    .bdm-form-steps-select {
      position: relative;
      top: 0;
      opacity: 1;
      width: 100%;
    }

    @media screen and (min-width: 680px) {
      .bdm-form-steps {
        position: relative;
        top: 0;
        opacity: 1;
      }

      .bdm-form-steps-select {
        position: absolute;
        top: -99999px;
        opacity: 0;
      }
    }
  }

  .steps-actions {
    margin-bottom: 20px;

    [class*='ant-col-'] {
      text-align: right;

      * {
        text-align: inherit;
      }

      @media screen and (max-width: 680px) {
        text-align: center;
      }
    }
  }

  .steps-content {
    padding: 20px;
    margin-bottom: 30px;

    &.is-reviewer {
      margin-bottom: 0;
    }

    .ql-editor {
      h1 {
        font-size: 2em;
      }
      p {
        overflow-wrap: anywhere;
      }
    }
  }

  .steps-tab-content {
    @media screen {
      margin-top: 24px;
      border-top: 1px solid ${GRAY_1};

      &:first-of-type {
        margin-top: 0;
        border-top: none !important;
      }

      .steps-tab-title {
        padding: 16px 0;
        font-size: 18px;
        font-weight: 800;
      }
    }

    &.enhanced {
      border-top: none;
      margin-top: 60px;

      &:first-child {
        margin-top: 25px;
      }

      .steps-tab-title {
        position: relative;
        text-align: center;
        font-size: 20px;

        span {
          position: relative;
          padding: 5px 25px;
          z-index: 1;
          display: inline-block;
          background-color: #fbfbfc;
        }

        &::before {
          content: '';
          position: absolute;
          border-top: 1px solid ${DIVIDER};
          width: 100%;
          left: 0;
          top: 50%;
        }
      }
    }
  }
`;

export default FormWrapper;
