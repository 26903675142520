import parseDate from '../../../utils/parse-date';
export const reportTimelineAuditLogsColumns = timezone => {
  return [
    {
      title: 'Report Name',
      dataIndex: 'resourceInstance',
      width: '25',
      align: 'right',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.resourceInstance.localeCompare(b.resourceInstance),
    },
    {
      title: 'Executed By',
      dataIndex: 'executedByFullname',
      width: '25',
      align: 'right',
      sorter: (a, b) =>
        a.executedByFullname.localeCompare(b.executedByFullname),
    },
    {
      title: 'Event Type',
      dataIndex: 'eventType',
      width: '25',
      align: 'right',
      sorter: (a, b) => a.eventType.localeCompare(b.eventType),
    },
    {
      title: 'Timestamp',
      render: date => parseDate(date, timezone),
      dataIndex: 'date',
      width: '25',
      align: 'right',
      sorter: (a, b) => a.date.localeCompare(b.date),
    },
  ];
};
