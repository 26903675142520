import React, { PureComponent } from 'react';
import { Tabs } from 'antd';

import TabTitle from '../TabTitle';
import TabContent from '../TabContent';
import tabsManager from '../../services/tabsManager';
import AddTabButton from '../AddTabButton';
import DraggableTabs from './DraggableTabs';

const TabPane = Tabs.TabPane;

class TabList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: tabsManager.activeTab,
    };
  }

  componentDidMount() {
    this.tabsManagerSubscription = tabsManager
      .getActiveTab()
      .subscribe(this.onTabChanged);
  }

  componentWillUnmount() {
    this.tabsManagerSubscription.unsubscribe();
  }

  onTabChanged = () => {
    this.setState({
      currentTab: tabsManager.activeTab,
    });
  };

  onChangeTab = tabId => {
    tabsManager.setActiveTab(tabId);
  };

  onChangeTabOrder = order => {
    tabsManager.sortTabs(order);
  };

  onAddTabClick = () => {
    tabsManager.addTab();
  };

  onDeleteTabClick = tab => {
    tabsManager.deleteTab(tab);
  };

  render() {
    const { tabs } = this.props;
    const { currentTab } = this.state;

    return (
      <DraggableTabs
        animated={false}
        onChange={this.onChangeTab}
        activeKey={currentTab}
        onChangeTabOrder={this.onChangeTabOrder}
        tabBarExtraContent={<AddTabButton onClick={this.onAddTabClick} />}
      >
        {tabs.map(tab => (
          <TabPane
            key={tab.id}
            tab={
              <TabTitle
                tab={tab}
                deleteTabMethod={() => this.onDeleteTabClick(tab)}
                isVisible={tabsManager.tabsSize() > 1}
              />
            }
          >
            <TabContent tab={tab} />
          </TabPane>
        ))}
      </DraggableTabs>
    );
  }
}

export default TabList;
