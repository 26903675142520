export const GET_AGENCY_TEMPLATES_REQUEST = 'GET_AGENCY_TEMPLATES_REQUEST';
export const GET_AGENCY_TEMPLATES_FULLFILLED =
  'GET_AGENCY_TEMPLATES_FULLFILLED';
export const GET_AGENCY_TEMPLATES_REJECTED = 'GET_AGENCY_TEMPLATES_REJECTED';

export const CREATE_WORKFLOW_REQUEST = 'CREATE_WORKFLOW_REQUEST';
export const CREATE_WORKFLOW_FULLFILLED = 'CREATE_WORKFLOW_FULLFILLED';
export const CREATE_WORKFLOW_REJECTED = 'CREATE_WORKFLOW_REJECTED';

export const UPDATE_WORKFLOW_REQUEST = 'UPDATE_WORKFLOW_REQUEST';
export const UPDATE_WORKFLOW_FULLFILLED = 'UPDATE_WORKFLOW_FULLFILLED';
export const UPDATE_WORKFLOW_REJECTED = 'UPDATE_WORKFLOW_REJECTED';

export const DELETE_WORKFLOW_REQUEST = 'DELETE_WORKFLOW_REQUEST';
export const DELETE_WORKFLOW_FULLFILLED = 'DELETE_WOFRKFLOW_FULLFILLED';
export const DELETE_WORKFLOW_REJECTED = 'DELETE_WORKFLOW_REJECTED';

export const GET_TEMPLATE_WORKFLOWS_REQUEST = 'GET_TEMPLATE_WORKFLOWS_REQUEST';
export const GET_TEMPLATE_WORKFLOWS_FULLFILLED =
  'GET_TEMPLATE_WORKFLOWS_FULLFILLED';
export const GET_TEMPLATE_WORKFLOWS_REJECTED =
  'GET_TEMPLATE_WORKFLOWS_REJECTED';

export const GET_WORKFLOW_REQUEST = 'GET_WORKFLOW_REQUEST';
export const GET_WORKFLOW_FULLFILLED = 'GET_WORKFLOW_FULLFILLED';
export const GET_WORKFLOW_REJECTED = 'GET_WORKFLOW_REJECTED';
