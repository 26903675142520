import React, { Component } from 'react';
import { Input as InputField, Tooltip } from 'antd';

class TextAreaTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showToolTip: false,
    };
  }

  showToolTip = () => {
    this.setState({ showToolTip: true });
  };

  hideToolTip = () => {
    this.setState({ showToolTip: false });
  };

  render = () => {
    const { showToolTip } = this.state;
    const { title, ...props } = this.props;
    return (
      <Tooltip placement="topLeft" title={title} visible={showToolTip}>
        <InputField.TextArea
          {...props}
          onMouseOver={this.showToolTip}
          onKeyPress={this.hideToolTip}
          onMouseLeave={this.hideToolTip}
          onFocus={this.hideToolTip}
          onClick={this.hideToolTip}
        />
      </Tooltip>
    );
  };
}

export default TextAreaTooltip;
