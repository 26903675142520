import styled from 'styled-components';
import { Table, Icon } from 'antd';

const StyledTable = styled(Table)`
  .ant-table table {
    border-bottom: none;
    background-color: #fff;
  }

  .ant-table-tbody > tr,
  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > td .text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.employment-table {
    table {
      table-layout: fixed;
    }

    .ant-table-tbody > tr,
    .ant-table-tbody > tr > td,
    .ant-table-tbody > tr > td .text {
      white-space: normal;
    }
  }

  .ant-table-footer {
    border-top: none;
    background-color: #fff;
  }

  .ant-spin-text {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 700;
  }
`;

const RemoveIcon = styled(Icon)`
  font-size: 16px;
  margin-left: 15px;
  color: #d42020;
`;

export { StyledTable, RemoveIcon };
