import * as Rx from 'rxjs';
import { get, isPlainObject, isEmpty } from 'lodash';
import uuid from 'uuid/v4';

import syncFormData from 'APP_ROOT/actions/sync-form-data';
import addRepeaterItem from 'APP_ROOT/actions/add-repeater-item';
import { SET_SOURCE_SELECTION } from '../../actions';

import getAllFieldsFlat from '../../modules/FormBuilder/utils/getAllFieldsFlat';
import findContainersByType from '../../modules/FormBuilder/services/utils/findContainersByType';
import { REPEATER } from '../../constants/layoutComponentTypes';

const integrationData$ = new Rx.BehaviorSubject({});

const setIntegrationData = data => {
  integrationData$.next(data);
};

const getIntegrationData = () => {
  return integrationData$.value;
};

const hasIntegrationData = () => {
  return !isEmpty(getIntegrationData());
};

const getRepeaterFields = (acc, repeater) => {
  const fieldsFlat = getAllFieldsFlat(repeater.properties);
  acc[repeater.key] = fieldsFlat.fieldsByKey;
  return acc;
};

const buildFieldsMap = (fieldsByKey, repeaterFields) => {
  const repeaterKeys = Object.keys(repeaterFields);
  return Object.keys(fieldsByKey).reduce((acc, key) => {
    const { options: { integrated } = {} } = fieldsByKey[key];
    if (integrated) {
      const repeaterKey = repeaterKeys.find(
        repKey => repeaterFields[repKey][key]
      );
      if (repeaterKey) {
        acc = {
          ...acc,
          [repeaterKey]: {
            ...acc[repeaterKey],
            [key]: integrated,
          },
        };
      } else {
        acc[key] = integrated;
      }
    }
    return acc;
  }, {});
};

const repeatedDataReducer = ({ repeatedData, repAcc, key, fieldKey, attr }) =>
  repeatedData.reduce((acc, repData, i) => {
    return repData[attr] === null
      ? acc
      : {
          ...acc,
          [`${key}[${i}].${fieldKey}`]: repData[attr],
        };
  }, repAcc);

const syncData = ({ repeaters, repeatedData, key, dispatch }) => {
  const repeater = repeaters.find(r => r.key === key);
  const properties = get(repeater, 'properties', []);
  const syncTo = get(repeater, 'options.syncTo', []);
  for (let i = 1; i < repeatedData.length; i++) {
    dispatch(
      addRepeaterItem([key].concat(syncTo), properties, uuid(), undefined, {})
    );
  }
};

const getMappedData = ({
  allData,
  fieldsMap,
  key,
  data,
  repeaters,
  dispatch,
}) => {
  let addRepItem = true;
  return Object.keys(fieldsMap[key]).reduce((repAcc, fieldKey) => {
    const path = fieldsMap[key][fieldKey].split('.');
    const attr = path.pop();
    const attrPath = path.join('.');
    const repeatedData = get(data, attrPath);
    if (addRepItem && repeatedData.length > 1) {
      addRepItem = false;
      syncData({ repeaters, repeatedData, key, dispatch });
    }
    return repeatedDataReducer({
      repeatedData,
      repAcc,
      key,
      fieldKey,
      attr,
    });
  }, allData);
};

const applyMapping = (props, fieldsMap, data, repeaters) => {
  const { dispatch } = props;
  return Object.keys(fieldsMap).reduce((allData, key) => {
    if (isPlainObject(fieldsMap[key])) {
      allData = getMappedData({
        allData,
        fieldsMap,
        key,
        data,
        repeaters,
        dispatch,
      });
    } else {
      const value = get(data, fieldsMap[key]);
      if (value !== null) allData[key] = value;
    }
    return allData;
  }, {});
};

const mapData = (props, integrationData) => {
  const fields = get(
    props,
    'selectedForm.template.formSchema.form.properties',
    []
  );
  const allFields = getAllFieldsFlat(fields);
  const { fieldsByKey } = allFields;
  // this is not checking nested repeaters
  const repeaterTemplates = findContainersByType(fields, REPEATER, true);
  const repeaterFields = repeaterTemplates.reduce(getRepeaterFields, {});
  const fieldsMap = buildFieldsMap(fieldsByKey, repeaterFields);

  const data = applyMapping(
    props,
    fieldsMap,
    integrationData,
    repeaterTemplates
  );

  return data;
};

const populateIntegration = async (props, integrationId) => {
  const { dispatch } = props;

  if (hasIntegrationData()) {
    const { users = {}, ...integrationData } = getIntegrationData(
      integrationId
    );

    // hack to wait for form-presentation to finish
    await new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 200);
    });

    const mappedData = mapData(props, integrationData);

    // to populate data into the report fields
    Object.keys(mappedData).forEach(key => {
      dispatch(syncFormData({ [key]: mappedData[key] }, {}));
    });

    // to populate user data into the __users collection, for the snapshot later on
    Object.keys(users).forEach(key => {
      dispatch({
        type: SET_SOURCE_SELECTION,
        payload: { item: users[key], sourceKey: '__users', formName: 'form' },
      });
    });
  }
};

export default populateIntegration;
export { setIntegrationData };
