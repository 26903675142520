import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getAgencyTZ } from 'APP_ROOT/selectors/session';

import { LOADER_COMPONENTS } from 'APP_ROOT/constants/renderSchema';
import getFormTemplate from 'APP_ROOT/actions/get-form-template';
import getAgencyTemplates from 'APP_ROOT/actions/get-agency-templates';
import transformTemplate from 'APP_ROOT/utils/transformTemplates';
import ObjectField from 'APP_ROOT/components/form-viewer/object-types/object-field';

import { emptyObject } from 'APP_ROOT/constants/form';
import getFormProps from 'APP_ROOT/utils/getFormProps.memoized';
import { get } from 'lodash';
import { officeProfileTemplatesFilter } from '../../constants/filters';

class RenderSchema extends Component {
  state = { template: {}, loading: true };

  componentDidMount() {
    const {
      dispatch,
      user: { agencyId },
      templatesMeta,
      templates,
    } = this.props;

    const systemTemplates = Object.values(templates).filter(
      template => template.isSystem
    );

    if (!templatesMeta.fetched || !systemTemplates.length) {
      dispatch(
        getAgencyTemplates(
          officeProfileTemplatesFilter,
          this.normalize,
          agencyId,
          false
        )
      );
    } else {
      this.getTemplate(null, templates);
    }
  }

  normalize = async (err, response) => {
    const templates = transformTemplate(response);
    this.getTemplate(null, templates);
  };

  getTemplate = async (err, templates) => {
    const { dispatch, schema } = this.props;
    const { id } = templates[schema];
    dispatch(getFormTemplate({ templateId: id }, this.persistTemplate));
  };

  persistTemplate = async (err, template) => {
    this.setState({ loading: false });
    this.setState({ template: template });
  };

  getLoaderComponent = () => {
    const { loader } = this.props;
    return LOADER_COMPONENTS[loader];
  };

  render() {
    const { timezone, user, dataSelector } = this.props;
    const { template, validations, overrides, loading } = this.state;
    const LoaderComponent = this.getLoaderComponent();

    if (loading || user.loading) {
      return React.createElement(LoaderComponent);
    }

    const form = get(template, 'formSchema.form');

    const formProps = getFormProps(
      emptyObject,
      form,
      overrides,
      emptyObject,
      validations,
      0,
      null,
      null,
      false,
      false,
      null,
      emptyObject,
      timezone
    );

    return <ObjectField {...formProps} {...form} dataSelector={dataSelector} />;
  }
}

const mapState = state => {
  const { selectedUser: user = {} } = state;
  const {
    form: { templates, templatesMeta },
  } = state;

  return {
    user,
    timezone: getAgencyTZ(state),
    templates,
    templatesMeta,
  };
};

export default connect(mapState)(RenderSchema);
