import styled from 'styled-components';

const SecurityBasicContent = styled.div`
  margin: 28px;

  h1 {
    font-family: Lato, sans-serif;
    font-size: 24px;
    line-height: 29px;
    color: #000;
  }
`;

export default SecurityBasicContent;
