import React from 'react';

import MainTitle from '../../components/common/main-title';
import GeneralInformationPanel from '../../components/dashboard/general-information-panel';
import EvaluationScorePanel from '../../components/dashboard/evaluation-score-panel';

const PerformanceEvaluation = props => (
  <div>
    <MainTitle>Performance Evaluation</MainTitle>
    <GeneralInformationPanel />
    <EvaluationScorePanel />
  </div>
);

export default PerformanceEvaluation;
