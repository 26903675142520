import React, { Component } from 'react';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import { Select, Switch } from 'antd';
const Option = Select.Option;

class RepeaterNavigation extends Component {
  render() {
    const {
      options = [],
      onChangeCollapse = () => {},
      onChangeNavigation = () => {},
    } = this.props;
    return (
      <RepeaterNavigationWrapper>
        <CollapseSwitchWrapper>
          <span>Expand All</span>
          <Switch onChange={onChangeCollapse} />
        </CollapseSwitchWrapper>
        <Select
          style={{ display: 'inline-block', width: 300 }}
          size="default"
          value={undefined}
          onChange={onChangeNavigation}
          placeholder="Please select"
        >
          {options &&
            sortBy(options, option => option.index).map((option, index) => {
              const { label } = option;
              return (
                <Option key={index} value={index + []}>
                  {label}
                </Option>
              );
            })}
        </Select>
      </RepeaterNavigationWrapper>
    );
  }
}

const CollapseSwitchWrapper = styled.label`
  margin: 0 20px;

  span {
    margin-right: 10px;
  }
`;

const RepeaterNavigationWrapper = styled.div`
  text-align: right;
  padding: 10px 0;
`;

export default RepeaterNavigation;
