import selectedFormInitialState from './selected-form';

export const templatesMeta = {
  loading: false,
  error: null,
  fetched: false,
};

export default {
  selectedForm: selectedFormInitialState,
  templateList: [],
  templates: {},
  presentations: {},
  isLoading: false,
  error: null,
  templatesMeta,
  analyticsReports: {
    loading: false,
    list: [],
  },
  fileUploaded: false,
  __usersOnBehalf: {},
};
