import { concat, get } from 'lodash';

import { CREATE_DYNAMIC_ENUM_REF, CREATE_DYNAMIC_ENUM_REF_REVIEW } from './';
import { mapData } from '../utils/conditionals';

export default (populateFrom = null, enumRef = '', formData = {}) => (
  dispatch,
  getState
) => {
  const {
    form: { selectedForm: { data = {}, enums = {}, isActive } = {} } = {},
  } = getState();

  const sources = Object.keys(populateFrom);

  const sourcesData = sources.reduce((result, sourceName) => {
    return {
      ...result,
      [sourceName]: get(data, [sourceName], {}),
    };
  }, {});

  const mappedData = sources.reduce((result, sourceName) => {
    return {
      ...result,
      [sourceName]: mapData(
        sourcesData[sourceName],
        populateFrom[sourceName],
        formData,
        enums
      ),
    };
  }, {});

  const mergedData = concat(
    ...sources
      .map(sourceName => get(mappedData, [sourceName], []))
      .reduce((result, source) => [...result, ...source], [])
  ).filter(item => item !== null && item !== undefined);

  if (populateFrom) {
    dispatch({
      type: isActive ? CREATE_DYNAMIC_ENUM_REF : CREATE_DYNAMIC_ENUM_REF_REVIEW,
      payload: { populateFrom, values: mergedData, enumRef },
    });
  }
};
