import React from 'react';

import Panel, { PanelTitle, PanelSection, Divider } from '../common/panel';
import { Row, Col } from 'antd';

import { BLACK, GRAY_2 } from '../../config/theme';

import json from '../../mockup_data/evaluations';

const GeneralInformationPanel = props => (
  <Panel>
    <PanelTitle>
      <h2>General Information</h2>
    </PanelTitle>
    <Divider />
    <PanelSection>
      <Row>
        <Col xs={12} sm={6} lg={4} xl={3}>
          <div style={{ fontSize: 12, color: BLACK }}>Last evaluation:</div>
        </Col>
        <Col xs={12} sm={18} lg={20} xl={21}>
          <div style={{ fontSize: 12, color: GRAY_2 }}>
            {json.lastEvaluation}
          </div>
        </Col>
      </Row>
    </PanelSection>
  </Panel>
);

export default GeneralInformationPanel;
