import { CAPTURE_TEXT } from './constants';

export default function createCaptureDef(type, label, value, options = {}) {
  const {
    maxLength,
    placeholder: _placeholder,
    help: _help,
    ...rest
  } = options;
  let placeholder;
  let help;
  if (type === CAPTURE_TEXT) {
    placeholder = _placeholder || label;
    help =
      _help ||
      (maxLength !== undefined
        ? `Maximum ${maxLength} characters.`
        : undefined);
  }

  return {
    type,
    label,
    value,
    placeholder,
    help,
    maxLength,
    ...rest,
  };
}
