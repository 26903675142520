import styled from 'styled-components';
import Row from 'antd/lib/row';

const StyledRow = styled(Row)`
  margin-top: 16px;

  .ant-table table {
    border: 1px solid #e3e3e3;
    border-bottom: none;
    background-color: #fff;
  }
`;

export default StyledRow;
