/**
 * Contains all the functions to control props related to the contribute to report feature
 * specifically used in the top level object-types during rendering.
 */
import { some } from 'lodash';
import { hasPermissions, PERMISSIONS } from '../../../../utils/admin';

/**
 * Check if the form is on the RMI initial state or DRAFT
 * meaning: the start of the form
 * @param {Object} selectedForm redux form
 * @returns {Boolean}
 */
export function isInitialState(selectedForm) {
  const { submittedAt, isInitial = false } = selectedForm.meta;
  return !submittedAt || isInitial;
}

/**
 * Returns true if the user as at least one admin permission that allows to see the form.
 * note: draft vs non draft is not evaluated, that should be prevented by the BE
 * @param {Array} permissions string form of the permission
 * @returns {Boolean}
 */
export function hasAdminViewPermissions(permissions = []) {
  const adminViewPermissions = [
    PERMISSIONS.viewDraftReports,
    PERMISSIONS.viewAllReports,
    PERMISSIONS.viewReports,
  ];
  return hasPermissions(permissions, adminViewPermissions);
}

/**
 * Returns true if the user is a participant
 * @param {Object} selectedForm redux form
 * @param {Number} userId
 * @returns {Boolean}
 */
export function isParticipant(selectedForm, userId) {
  const { meta: { participantsId = [] } = {} } = selectedForm;
  return participantsId.includes(userId);
}

/**
 * Returns true if:
 * - the user is shared the report and is not a contributor
 * @param {Object} selectedForm redux obj
 * @param {Number} currentUserId evaluated user
 * @returns {Boolean}
 */
export function isSharedNonContributor(selectedForm, currentUserId) {
  const {
    meta: { share: { shares = [] } = {} },
    data: { __assignedSections = [] },
  } = selectedForm;
  const activeShare = some(
    shares,
    share =>
      Array.isArray(share.shareToId) && share.shareToId.includes(currentUserId)
  );
  if (!activeShare) return false;
  const isAssigned = __assignedSections.some(
    section => Number(section.userId) === currentUserId
  );
  return !isAssigned;
}

/**
 * Computes all possible visibility scenarios for the contribute to report feature
 * It by default will return no extra visibility to normal forms.
 * @param {Object} props field props - must be section wrapper props
 * @returns {Object} visibility options:
 *    isContributorAssign,
      contributorAssignmentCanEdit,
      contributorAssignmentCanView,
      contributorAssignmentHideSection,
      contributorAssignmentSource,
      showCompleteIncompleteButton,
      contributorAssignmentIsComplete,
 */
export function getContributeToReportVisibilityLevels(props) {
  const { selectedForm, activeUser, parentKey, parentIndex } = props;
  const submitterId = Number(selectedForm.meta.submitterId || activeUser);
  let isContributorAssign = false;
  let contributorAssignmentCanEdit = false;
  let contributorAssignmentCanView = false;
  let contributorAssignmentHideSection = false;
  let contributorAssignmentIsComplete = false;
  let contributorAssignmentIsAssigned = false;
  let showCompleteIncompleteButton = false;
  let contributorAssignmentSource = props.options?.contributorAssignmentSource;

  // Return default negative values when the report is not
  // a contribute to report instance
  if (!selectedForm.isContributeReport) {
    return {
      isContributorAssign,
      contributorAssignmentCanEdit,
      contributorAssignmentCanView,
      contributorAssignmentHideSection,
      contributorAssignmentSource,
      showCompleteIncompleteButton,
      contributorAssignmentIsComplete,
      contributorAssignmentIsAssigned,
    };
  }

  // Case 0: By default, not allowed to see anything
  contributorAssignmentHideSection = true;
  // Case 1: User is submitter
  if (Number(props.activeUser) === submitterId) {
    contributorAssignmentCanView = true;
    contributorAssignmentHideSection = false;
  }
  // Case 2: User has been assigned to a section
  let section = {};
  if (parentKey) {
    const repeater = selectedForm.data[parentKey][parentIndex];
    section = selectedForm.data.__assignedSections.find(
      section => section.repeaterItemId === repeater.id
    );
  } else {
    section = selectedForm.data.__assignedSections.find(
      section => section.sectionId === props.id
    );
  }
  const currentUserId = Number(props.activeUser);
  if (section) {
    contributorAssignmentIsComplete = section.complete;
    contributorAssignmentIsAssigned = !!section.userId;
    if (Number(section.userId) === currentUserId) {
      isContributorAssign = true;
      contributorAssignmentCanView = true;
      contributorAssignmentHideSection = false;
      // Case 3: User has been assigned BUT section is complete
      if (!section.complete) {
        contributorAssignmentCanEdit = true;
      }
    } else {
      // Case 4: Section is assigned, but not to user, hide except you are the submitter
      contributorAssignmentHideSection = true;
      if (submitterId === currentUserId) {
        contributorAssignmentHideSection = false;
      }
    }
  } else {
    // Case 5: It's non assignment section, user is submitter on initial state
    if (submitterId === currentUserId && isInitialState(selectedForm)) {
      contributorAssignmentCanEdit = true;
      contributorAssignmentHideSection = false;
    }
  }
  // TOP LEVEL OVERRIDES - These override existing logic computed before.
  // Case 6: Section set to visible to all contributors
  if (props.options?.visibleToAllContributors) {
    const contributors =
      selectedForm.data.__assignedSections
        .map(section => section.userId)
        .filter(v => v) || [];
    if (contributors.includes(props.activeUser)) {
      contributorAssignmentCanView = true;
      contributorAssignmentHideSection = false;
    }
  }
  // Case 7: User see all when assigned to review
  if (props.meta.activeReviewerId === currentUserId) {
    contributorAssignmentCanView = true;
    contributorAssignmentHideSection = false;
  }
  // Case 8: User is admin, can see the report
  if (hasAdminViewPermissions(props.currentUser.permissions)) {
    contributorAssignmentCanView = true;
    contributorAssignmentHideSection = false;
  }
  // Case 9: Report is share while NOT a contributor
  if (isSharedNonContributor(selectedForm, currentUserId)) {
    contributorAssignmentCanView = true;
    contributorAssignmentHideSection = false;
  }
  // Case 10: Is an admin edit report and non contributor section
  if (selectedForm.isAdminEdit && !section) {
    contributorAssignmentCanEdit = true;
  }
  // Case 11: Is a participant, allowed to view
  if (isParticipant(selectedForm, currentUserId)) {
    contributorAssignmentCanView = true;
    contributorAssignmentHideSection = false;
  }
  if (isContributorAssign && isInitialState(selectedForm)) {
    showCompleteIncompleteButton = true;
  }

  return {
    isContributorAssign,
    contributorAssignmentCanEdit,
    contributorAssignmentCanView,
    contributorAssignmentHideSection,
    contributorAssignmentSource,
    showCompleteIncompleteButton,
    contributorAssignmentIsComplete,
    contributorAssignmentIsAssigned,
  };
}
