import { UPDATE_SHARE, UPDATE_TIMELINE } from './actions/index';

const events = {
  [UPDATE_SHARE]: (state, { payload }) => {
    const { selectedForm: currenSelectedForm } = state;
    const { meta: currentMeta } = currenSelectedForm;
    const { share: currentShare } = currentMeta;

    const share = {
      ...currentShare,
      shares: payload,
    };
    const meta = {
      ...currentMeta,
      share,
    };
    const selectedForm = {
      ...currenSelectedForm,
      meta,
    };
    return { ...state, selectedForm };
  },
  [UPDATE_TIMELINE]: (state, { payload }) => {
    const { selectedForm: currenSelectedForm } = state;
    const { meta: currentMeta } = currenSelectedForm;
    const { transactionHistory: currentTransactionHistory = [] } = currentMeta;

    const transactionHistory = [...currentTransactionHistory, payload];
    const meta = {
      ...currentMeta,
      transactionHistory,
    };
    const selectedForm = {
      ...currenSelectedForm,
      meta,
    };
    return { ...state, selectedForm };
  },
};

export default events;
