import styled from 'styled-components';
import { Input } from 'antd';

const WrapperInput = styled(Input)`
  overflow: ${props => (props.ellipsis ? 'hidden' : '')};
  text-overflow: ${props => (props.ellipsis ? 'ellipsis' : '')};
  white-space: ${props => (props.ellipsis ? 'nowrap' : '')};
  border-top: ${props => (props.readOnly ? 'none' : '')};
  border-left: ${props => (props.readOnly ? 'none' : '')};
  border-right: ${props => (props.readOnly ? 'none' : '')};
  border-radius: ${props => (props.readOnly ? '0px' : '4px')};
`;

export default WrapperInput;
