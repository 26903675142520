import { get, isEmpty } from 'lodash';
import componentsManager from '../../services/componentsManager';
import { getCurrentRepeaterFromField } from '../../../../utils/form';
import { SOURCE_FIELD } from 'APP_ROOT/constants/fieldTypes';
import CascaderSelectionBox from '../../components/CascaderSelectionBox';
import {
  CASE_NUMBER,
  SOURCE_FIELD_DEFAULT_VALUE,
} from '../../constants/constants';
export default class sourceFieldCascader {
  constructor(values) {
    const fields = componentsManager.fields;
    const repeaters = componentsManager.repeatersReference;
    this.name = SOURCE_FIELD;
    this.label = 'Source Field';
    this.popoverContent =
      'List all fields found in the repeating element selected as source.';
    this.component = CascaderSelectionBox;
    const emptyValue = { label: '--Empty--', value: 'none' };
    const currentField = get(values, 'key');
    const currentRepeater = getCurrentRepeaterFromField(
      fields,
      repeaters,
      currentField
    );
    componentsManager.find;
    const fieldsTarget = fields
      .map((tab, i) => {
        return {
          value: i,
          label: tab.title,
          children: (repeaters[i] || [])
            .filter(repeater => {
              if (repeater.repeaterKey !== currentRepeater) {
                return true;
              } else {
                return false;
              }
            })
            .map(repeater => {
              const [repeaterItem] = componentsManager.findContainersByKey(
                fields[i].properties,
                repeater.repeaterKey
              );
              return {
                label: get(repeaterItem, 'reportingKey'),
                value: repeater.repeaterKey,
                children: Object.keys(repeater.fields).map(field => {
                  const [fieldItem] = componentsManager.findFieldsByKey(
                    fields[i].properties,
                    field
                  );
                  return {
                    label: fieldItem.title || fieldItem.reportingKey,
                    value: fieldItem.key,
                  };
                }),
              };
            }),
        };
      })
      .filter(tab => tab.children.length); // Return only tabs with repeaters

    if (!isEmpty(fieldsTarget)) {
      this.selectOptions = fieldsTarget;
      this.selectOptions.push(emptyValue);
    } else {
      this.selectOptions = [];
      this.selectOptions.push(emptyValue);
    }
  }

  onSave = values => {
    const sourceField = get(values, `${SOURCE_FIELD}`);
    const finderNone = function(el) {
      return el !== 'none';
    };
    let result = {
      ...values,
      [this.name]: undefined,
      readOnly:
        !isEmpty(sourceField) && sourceField.find(finderNone)
          ? true
          : values.readOnly,
      options: {
        ...values.options,
        sourceField,
      },
    };
    return result;
  };

  getInitialValue = values => {
    const valueSourceField = get(values, `options.${SOURCE_FIELD}`);
    if (valueSourceField) {
      return valueSourceField;
    } else {
      return SOURCE_FIELD_DEFAULT_VALUE;
    }
  };

  isHidden = values => {
    return get(values, CASE_NUMBER, false);
  };
}
