import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import { SIDEBAR_BG, SIDEBAR_ACTIVE } from '../../config/theme';

const CollapseSidebarButton = styled(Button)`
  &.ant-btn {
    display: block;
    width: 100%;
    height: 50px;
    padding: 6px 16px;
    text-align: right;
    font-size: 18px;
    line-height: 38px;
    border: none;
    border-radius: 0;
    /* stylelint-disable */
    &,
    &:active {
      color: ${SIDEBAR_ACTIVE};
    }

    img {
      float: left;
      max-width: 108px;
    }

    &.is-collapsed {
      text-align: center;
      img {
        display: none;
      }
    }

    &,
    &:hover,
    &:focus {
      background-color: transparent;
    }

    &:hover {
      color: #fff;
    }
  }
`;

const Wrapper = styled.div`
  height: 50px;
  background-color: ${SIDEBAR_BG};
  z-index: 2;
`;

const CollapseButtonWrapper = props => {
  return (
    <Wrapper className={props.className || ''}>
      <CollapseSidebarButton {...props} />
    </Wrapper>
  );
};

export default CollapseButtonWrapper;
