import moment from 'moment';

import {
  DISMISS_NOTIFICATION,
  FETCH_ACTION_ITEMS_FEED_REQUEST,
  FETCH_ACTION_ITEMS_FEED_SUCCESS,
  FETCH_ACTION_ITEMS_FEED_ERROR,
  FETCH_ACTION_ITEMS_FEED_COUNT_SUCCESS,
} from '../../../actions';

export default (initialState = {}) => ({
  [FETCH_ACTION_ITEMS_FEED_REQUEST]: (state, { payload: { page } }) => ({
    ...state,
    actionItemsFeed: {
      ...state.actionItemsFeed,
      page,
      loading: true,
    },
  }),
  [FETCH_ACTION_ITEMS_FEED_SUCCESS]: (
    state,
    { payload: { data, count: total } }
  ) => ({
    ...state,
    actionItemsFeed: {
      ...state.actionItemsFeed,
      loading: false,
      error: null,
      fetched_at: moment().unix(),
      total,
      data: data.map(item => item.id),
      entries: data.reduce(
        (result, item) => ({
          ...result,
          [item.id]: item,
        }),
        {}
      ),
    },
  }),
  [FETCH_ACTION_ITEMS_FEED_ERROR]: (state, { payload: error }) => ({
    ...state,
    actionItemsFeed: {
      ...state.actionItemsFeed,
      loading: false,
      error,
    },
  }),
  [FETCH_ACTION_ITEMS_FEED_COUNT_SUCCESS]: (state, { payload: { count } }) => ({
    ...state,
    actionItemsFeed: {
      ...state.actionItemsFeed,
      unread_count: count,
    },
  }),
  /* 
    After dismissing a notification, we need to decrease the total count by 1
    so the `antd` pagination component will be able to render the correct amount
    of pages in the `Action Items Feed` page.
  */
  [DISMISS_NOTIFICATION]: (state, { payload: notificationId }) => {
    let { entries, total } = state.actionItemsFeed;
    const notification = state.actionItemsFeed.entries[notificationId];
    // This makes sure we will only decrease the count one time per notification
    if (notification && !notification.REDUX_DISMISSED) {
      notification.REDUX_DISMISSED = true;
      entries[notificationId] = notification;
      total--;
    }
    return {
      ...state,
      actionItemsFeed: {
        ...state.actionItemsFeed,
        entries,
        total,
      },
    };
  },
});
