import { createElement, Component } from 'react';
import get from 'lodash/get';

import { FIELD_TYPES } from 'APP_ROOT/constants/form';
import hasPattern from './getHasPattern';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';

class FieldInput extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  render() {
    const type = get(this.props, 'field_type');
    const DefaultFieldInput = FIELD_TYPES['text'];
    const fieldType = hasPattern(this.props) ? 'masked' : type;
    const RenderComponent =
      type && FIELD_TYPES[fieldType]
        ? FIELD_TYPES[fieldType]
        : DefaultFieldInput;
    return createElement(RenderComponent, this.props);
  }
}

export default FieldInput;
