import React from 'react';

import ActivityReport from '../../../components/dashboard/activity-report';
import DashboardPage from '../../../components/dashboard';

import { getAgencyTZ } from '../../../selectors/session';

const TrainingPage = props => (
  <div>
    <ActivityReport />
  </div>
);

const mapState = state => ({
  timezone: getAgencyTZ(state),
});

export default DashboardPage(mapState, null)(TrainingPage);
