import React from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

class FieldTextarea extends React.Component {
  onBlur = e => {
    const { onBlur } = this.props;

    onBlur && onBlur(e.target.value);
  };

  onChange = e => {
    const { onChange } = this.props;

    onChange && onChange(e.target.value);
  };

  render() {
    const {
      defaultValue,
      value,
      placeholder = '',
      style = { width: 200 },
      maxLength,
      rows,
      'data-test': dataTest = '',
    } = this.props;

    return (
      <TextArea
        className="edit-textarea"
        defaultValue={defaultValue}
        style={style}
        value={value}
        onBlur={this.onBlur}
        onChange={this.onChange}
        placeholder={placeholder}
        data-test={dataTest}
        maxLength={maxLength}
        rows={rows}
      />
    );
  }
}

export default FieldTextarea;
