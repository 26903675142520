import get from 'lodash/get';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import DashboardPage from 'APP_COMPONENTS/dashboard';

import withOwnershipGuard from '../components/agency-ownership-guard';
import FormBuilderListHeader from './FormBuilderHeader';

const mapStateToProps = (_, props) => ({
  agencyId: get(props, 'match.params.agencyId'),
});

export default withoutClutter(
  DashboardPage(mapStateToProps)(
    withOwnershipGuard(FormBuilderListHeader, 'administratorAgency')
  )
);
