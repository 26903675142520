import React, { Component } from 'react';
import get from 'lodash/get';

// Containers
import DashboardPage from '../../components/dashboard';
import withoutClutter from '../../components/without-clutter';

import InsertEmbed from '../../components/izenda-embed/InsertEmbed';
import withFlexibleContainer from '../../components/izenda-embed/WithFlexibleLayout';

class EmbediZendaViewer extends Component {
  componentWillUnmount() {
    if (this.izenda) {
      this.izenda.destroy();
    }
  }

  loadDashboardViewer = (izenda, target) => {
    const { match } = this.props;
    const dashboardId = get(match, 'params.dashboardId');

    this.izenda = izenda.renderDashboardViewerPage(target, dashboardId);
  };

  render() {
    return <InsertEmbed onReady={this.loadDashboardViewer} />;
  }
}

const mapState = () => ({});

export default withoutClutter(
  DashboardPage(mapState, null)(withFlexibleContainer(EmbediZendaViewer))
);
