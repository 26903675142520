import storage from '../utils/storage';

import { COLLAPSE_SIDEBAR } from './';

export default payload => {
  return (dispatch, getState) => {
    storage.set('sidebar_collapsed', payload);

    dispatch({ type: COLLAPSE_SIDEBAR, payload });
  };
};
