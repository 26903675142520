/**
 * The branch stores internal affair cases, these items may be fetched with
 * `src/actions/fetch-caseload` and shown in the
 * `src/containers/internal-affairs` container, and are used to simply show the
 * list of open/closed internal affairs.
 */

import { createReducer } from 'redux-create-reducer';
import findIndex from 'lodash/findIndex';
import slice from 'lodash/slice';
import get from 'lodash/get';

import {
  FETCH_CASELOAD_REQUEST,
  FETCH_CASELOAD_SUCCESS,
  FETCH_CASELOAD_ERROR,
  FETCH_USER_CASELOAD_REQUEST,
  FETCH_USER_CASELOAD_SUCCESS,
  FETCH_USER_CASELOAD_ERROR,
  FETCH_REASONS_REQUEST,
  FETCH_REASONS_SUCCESS,
  FETCH_REASONS_FAILURE,
  POST_FORM_TOGGLE_TIMEOUT_REQUEST,
  POST_FORM_TOGGLE_TIMEOUT_SUCCESS,
  POST_FORM_TOGGLE_TIMEOUT_ERROR,
} from '../actions';

const initialState = {
  entities: [],
  loading: false,
  error: null,
  perPage: 10,
  page: 1,
  total: null,
  agencyReasons: {
    data: [],
    loading: false,
    error: null,
  },
  pendingToggle: {},
};

function assignToProperty(state, payload, additionalProps = {}) {
  return {
    ...state,
    ...payload,
    ...additionalProps,
  };
}

function internalAffairCaseLoadReducerFor(actions) {
  return {
    [actions.request]: (state, { payload }) =>
      assignToProperty(state, payload, {
        loading: true,
      }),
    [actions.success]: (state, { payload: { results, total } }) =>
      assignToProperty(
        state,
        {},
        {
          loading: false,
          error: null,
          entities: results,
          total,
        }
      ),
    [actions.error]: (state, { payload }) =>
      assignToProperty(
        state,
        {},
        {
          loading: false,
          error: payload,
        }
      ),
  };
}

function updateCaseLoadEntity(entities, formSubmissionId, isPaused) {
  const indexFound = findIndex(
    entities,
    obj => obj.formSubmissionId === formSubmissionId
  );
  if (indexFound !== -1) {
    const lastIndex = entities.length - 1;
    const start = slice(entities, 0, indexFound);
    const updatedEntity = { ...entities[indexFound], isPaused };
    const result = start.concat(updatedEntity);
    if (indexFound !== lastIndex) {
      return result.concat(slice(entities, indexFound + 1, entities.length));
    }
    return result;
  }
  return entities;
}

export default createReducer(initialState, {
  ...internalAffairCaseLoadReducerFor({
    request: FETCH_CASELOAD_REQUEST,
    success: FETCH_CASELOAD_SUCCESS,
    error: FETCH_CASELOAD_ERROR,
  }),
  ...internalAffairCaseLoadReducerFor({
    request: FETCH_USER_CASELOAD_REQUEST,
    success: FETCH_USER_CASELOAD_SUCCESS,
    error: FETCH_USER_CASELOAD_ERROR,
  }),
  [FETCH_REASONS_REQUEST]: state => ({
    ...state,
    agencyReasons: {
      ...state.agencyReasons,
      loading: true,
    },
  }),
  [FETCH_REASONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    agencyReasons: {
      ...state.agencyReasons,
      loading: false,
      data: payload,
    },
  }),
  [FETCH_REASONS_FAILURE]: (state, { payload }) => ({
    ...state,
    agencyReasons: {
      ...state.agencyReasons,
      loading: false,
      error: payload,
    },
  }),
  [POST_FORM_TOGGLE_TIMEOUT_REQUEST]: (
    state,
    { payload: { formSubmissionId } }
  ) => ({
    ...state,
    pendingToggle: Object.assign({}, get(state, 'pendingState'), {
      [formSubmissionId]: true,
    }),
  }),
  [POST_FORM_TOGGLE_TIMEOUT_SUCCESS]: (
    state,
    { payload: { formSubmissionId, isPaused } }
  ) => ({
    ...state,
    entities: updateCaseLoadEntity(state.entities, formSubmissionId, isPaused),
    pendingToggle: Object.assign({}, get(state, 'pendingState'), {
      [formSubmissionId]: false,
    }),
  }),
  [POST_FORM_TOGGLE_TIMEOUT_ERROR]: (
    state,
    { payload: { formSubmissionId } }
  ) => ({
    ...state,
    pendingToggle: Object.assign({}, get(state, 'pendingState'), {
      [formSubmissionId]: false,
    }),
  }),
});
