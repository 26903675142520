import React from 'react';
import classnames from 'classnames';
import { isPlainObject, get } from 'lodash';
import { connect } from 'react-redux';

import FormLabelEditor from './field-editor-label';
import { mapProperties } from '../../../utils/form';
import { mapTitle } from '../../../utils/conditionals';
import templateString from '../../../utils/template-string';

import getOverrides from '../../../utils/get-field-overrides';
import emitter, { EventTypes } from 'APP_ROOT/utils/eventEmitter';

import FormItem from '../styled/form-item';
import ConditionsAssert from './conditional';
import { getFormTemplate, getDataEnums } from '../../../selectors/form';
import { getDataSelector } from 'APP_ROOT/utils/renderSchema';

const formInterface = {
  getFieldDecorator: name => child => child,
  getFieldValue: name => '',
  setFieldsValue: fields => fields,
};

const onFieldEditorClick = options => {
  emitter.emit(EventTypes.OPEN_FIELD_EDITOR, options);
};

const FieldGroupItem = props => {
  const {
    data = {},
    parentKey = '',
    parentIndex = 0,
    form = formInterface,
    overrides = {},
    properties = [],
    title = '',
    tab = 0,
    isReviewer = false,
    disable: turnedOff = false,
    id,
  } = props;

  const getFieldOverrides = getOverrides(props, overrides);
  const fieldOverrides = getFieldOverrides();
  const options = getFieldOverrides('options', {});
  const conditions = getFieldOverrides('conditions', {});

  const {
    labelCol = { md: 8, sm: 10, xs: 24 },
    mode = 'horizontal',
    showTitle = true,
    wrapperCol = { md: 16, sm: 14, xs: 24 },
    inline = true,
    wrapperHalf = false,
    className: optionClassName,
  } = options;

  const formItemLayout =
    mode === 'horizontal'
      ? {
          labelCol,
          wrapperCol,
        }
      : null;

  const hasParent = !!parentKey;

  const groupData = {
    ...(hasParent ? data[parentKey][parentIndex] : data),
    isReviewer,
  };

  const label = showTitle ? fieldOverrides.title || title || ' ' : null;
  const total = properties.length;
  const className = classnames('bdm-form-field-group', {
    'ant-form-inline': inline,
    [optionClassName]: !!optionClassName,
  });

  const valueFromEnumRef = props.loopFromEnumRef
    ? get(props.dataEnums, `${props.loopFromEnumRef}.${parentIndex}`, '')
    : '';
  const interpolateLabel = label
    ? isPlainObject(label)
      ? mapTitle(label, parentIndex, data, groupData)
      : templateString(label, { index: parentIndex + 1, valueFromEnumRef })
    : '';

  return (
    <ConditionsAssert
      conditions={conditions}
      data={groupData}
      wholeData={data}
      render={() => (
        <FormItem
          label={
            <FormLabelEditor
              onFieldEditorClick={() => onFieldEditorClick({ title })}
              disabled={turnedOff}
              id={id}
            >
              {interpolateLabel}
            </FormLabelEditor>
          }
          className={className}
          colon={false}
          wrapperHalf={wrapperHalf}
          {...formItemLayout}
        >
          {properties.map(
            mapProperties({
              ...props,
              form,
              total,
              tab,
              isReviewer,
              parentIndex,
              parentKey,
              isEditor: true,
            })
          )}
        </FormItem>
      )}
    />
  );
};
const mapState = (state, props) => {
  const dataSelector = getDataSelector(props);
  const formTemplate = getFormTemplate(state, props);

  return {
    data: dataSelector(state, props),
    dataEnums: getDataEnums(state, props, formTemplate),
  };
};

export default connect(mapState)(FieldGroupItem);
