import { notification } from 'antd';

export const NOTIFICATION_ERROR = 'error';
export const ACTION_FETCH = 'fetch';
export const ACTION_APPEND = 'append';

export function showError(err) {
  let message = err.message || 'API response is not ok';
  err.response
    .json()
    .then(errorJSON => {
      message = errorJSON.errors.map(error => error.message).join(', ');
    })
    .finally(() => {
      showNotification(NOTIFICATION_ERROR, 'Error', message);
    });
}

export function showNotification(type, message, description) {
  return notification[type]({ message, description });
}
