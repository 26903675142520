import { get } from 'lodash';

import { ADD_ONBEHALF } from './';
import organizationEndpoints from '../api/organization/organizationEndpoints';
import getOfficersError from './get-officers-error';

export default (selection, source = '', formName = 'form') => (
  dispatch,
  getState = {}
) => {
  const sourceKey = source.startsWith('__') ? source : `__${source}`;
  const {
    session: {
      currentUser: { agencyId },
    },
  } = getState();
  const filter = {
    id: selection,
  };
  organizationEndpoints
    .getUsersSearch(agencyId, filter)
    .then(response => {
      const [item] = response;
      dispatch({
        type: ADD_ONBEHALF,
        payload: {
          item,
          sourceKey,
          formName,
        },
      });
    })
    .catch(error => {
      dispatch(getOfficersError(get(error, ['response', 'status'])));
    });
};
