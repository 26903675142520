import { DELETE_REPORT_SUCCESS } from './';

/**
 * @param {object} response
 * @return {object} An action of type @@action/DELETE_REPORT_SUCCESS
 */
export default function deleteReportSuccess(reportId, response) {
  return {
    type: DELETE_REPORT_SUCCESS,
    payload: { reportId, response },
  };
}
