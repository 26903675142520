import get from 'lodash/get';
import { REHYDRATE, PURGE } from 'redux-persist';
import { createReducer } from 'redux-create-reducer';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  LOAD_USER_AGENCY_CONFIGURATIONS,
} from '../actions';

const initialState = {
  isLoggedIn: false,
  isLoading: false,
  token: '',
  currentUser: {
    fetched: false,
    loading: false,
    error: null,
  },
};

const events = {
  [REHYDRATE]: (state, { payload }) => ({
    ...state,
    ...get(payload, 'session', {}),
    error: null,
    isLoading: false,
  }),
  [PURGE]: () => {
    return {};
  },
  [LOGIN_REQUEST]: (state, { payload }) => {
    return {
      ...initialState,
      isLoading: true,
    };
  },
  [LOGIN_SUCCESS]: (state, { payload: { token, ...currentUser } }) => {
    const agencyId = get(currentUser, ['agencyId'], null);
    const {
      authorities,
      sub,
      featureFlags,
      ...currentUserNoAuth
    } = currentUser;
    return {
      ...initialState,
      isLoggedIn: true,
      isLoading: false,
      token,
      currentUser: {
        ...currentUserNoAuth,
        userId: currentUser.sub,
        id: currentUser.sub,
        permissions: currentUser.authorities,
        agencyId,
        featureFlags,
      },
    };
  },
  [LOGIN_ERROR]: (state, { payload }) => {
    return {
      ...initialState,
      isLoading: false,
      error: payload || 401,
    };
  },
  [LOCATION_CHANGE]: state => {
    const currentUser = {
      ...state.currentUser,
      error: null,
      loading: false,
    };

    return {
      ...initialState,
      ...state,
      isLoading: false,
      error: null,
      currentUser,
    };
  },
  [GET_USER_REQUEST]: (state, { payload }) => {
    const currentUser = {
      ...state.currentUser,
      loading: true,
    };

    return {
      ...initialState,
      currentUser,
    };
  },
  [LOAD_USER_AGENCY_CONFIGURATIONS]: (
    state,
    { payload: { id, data = {} } }
  ) => {
    const { configurations = {} } = data;
    const currentUser = {
      ...state.currentUser,
      agency: {
        ...state.currentUser.agency,
        configurations,
      },
      id: id,
      loading: false,
      fetched: true,
    };

    return {
      ...initialState,
      currentUser,
      isLoggedIn: true,
    };
  },
  [GET_USER_SUCCESS]: (state, { payload: { id, data = {} } }) => {
    const currentUser = {
      ...state.currentUser,
      ...data,
      agency: {
        ...state.currentUser.agency,
      },
      id: id,
      loading: false,
      fetched: true,
      //features
    };

    return {
      ...initialState,
      currentUser,
      isLoggedIn: true,
    };
  },
  [GET_USER_ERROR]: (state, { payload: { error } }) => {
    const currentUser = {
      ...state.currentUser,
      error,
      loading: false,
      fetched: true,
    };

    return {
      ...initialState,
      currentUser,
    };
  },
};

export default createReducer(initialState, events);
