import { getEnvironmentVariable } from 'APP_ROOT/appVersion';
import { hasPermissions, PERMISSIONS } from 'APP_ROOT/utils/admin';

const ADMIN_EDIT_FLAG = 'REACT_APP_FEATURE_FLAG_ADMIN_EDIT_IN_REVIEW';
export default ({
  statusIsClosed,
  isActiveReviewer,
  isTemporalActiveReviewer,
  isEditMode,
  permissions,
}) => {
  const FEATURE_FLAG_ADMIN_EDIT_IN_REVIEW = getEnvironmentVariable(
    ADMIN_EDIT_FLAG,
    false
  );
  const isEditAdminReportAllow = hasPermissions(
    permissions,
    PERMISSIONS.adminReportEdit
  );

  return (
    isEditAdminReportAllow &&
    (statusIsClosed ||
      (FEATURE_FLAG_ADMIN_EDIT_IN_REVIEW &&
        !isEditMode &&
        (isActiveReviewer || isTemporalActiveReviewer)))
  );
};
