import get from 'lodash/get';

import { GET_FORM_TEMPLATE } from './';
import getFormTemplateSuccess from './get-form-template-success';
import getFormTemplateError from './get-form-template-error';
import apiEndpoints from 'APP_ROOT/modules/FormBuilder/services/apiEndpoints';

export default (
  { templateId, agencyIdFromOptions, templateVersionId },
  next = e => {}
) => {
  return (dispatch, getState) => {
    const {
      session: { currentUser: { agencyId = '' } = {} },
    } = getState();
    const currentAgencyId = agencyIdFromOptions || agencyId;
    dispatch({ type: GET_FORM_TEMPLATE, payload: templateId });

    return apiEndpoints
      .getForm(currentAgencyId, templateId, false, true, templateVersionId)
      .then(response => {
        dispatch(getFormTemplateSuccess(response));
        next(null, response);
      })
      .catch(error => {
        dispatch(getFormTemplateError(get(error, ['response', 'status'])));
        next(error);
      });
  };
};
