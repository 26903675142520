import verifyValidations from './verifyValidations';
import verifyFields from './verifyFields';

import {
  ERROR_ORPHAN_RULE,
  ERROR_REQUIRED_RULE,
  ERROR_TYPE_RULE,
  ERROR_TYPE_CALCULATED,
  ERROR_STRING_TYPE,
  ERROR_MISSING_RULE,
  FORM_SCHEMA_NAME,
  REVIEW_NOTE_SCHEMA_NAME,
  CATEGORY_FORM,
} from './constants';

const errorsMeta = {
  removed: ERROR_ORPHAN_RULE,
  required: ERROR_REQUIRED_RULE,
  type: ERROR_TYPE_RULE,
  calculated: ERROR_TYPE_CALCULATED,
  stringType: ERROR_STRING_TYPE,
  added: ERROR_MISSING_RULE,
};

const getError = data => (errors, key) => {
  const code = errorsMeta[key];
  if (code && data[key].length) {
    errors[code] = data[key];
  }
  return errors;
};

const getErrors = (data, currentErrors) => {
  const errors = currentErrors || {};

  return Object.keys(data).reduce(getError(data), errors);
};
/**
 * To validate field references in the json form structure are correct
 * @param {Object} jsonForm form structure used as input in FB
 * @returns validated structure { form, errors }
 */
const formValidator = jsonForm => {
  const schemaKey = jsonForm[FORM_SCHEMA_NAME]
    ? FORM_SCHEMA_NAME
    : REVIEW_NOTE_SCHEMA_NAME;
  const {
    [schemaKey]: { enums, form, validations, ...rest },
    category = CATEGORY_FORM,
    ...schema
  } = jsonForm;
  const isNote = category !== CATEGORY_FORM;

  const newValidations = verifyValidations(validations, form, enums, isNote);
  let errors = getErrors(newValidations);

  const newFields = verifyFields(
    newValidations.validations,
    form,
    enums,
    isNote
  );
  errors = getErrors(newFields, errors);

  return {
    form: {
      category,
      ...schema,
      [schemaKey]: {
        enums,
        form: newFields.form,
        validations: newFields.validations,
        ...rest,
      },
    },
    errors,
  };
};

export default formValidator;
