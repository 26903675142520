import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';

import route from '../../utils/get-route';

import forgotPassword from '../../actions/forgot-password';

import { getSession } from '../../selectors/session';
import { getForgotPassword } from '../../selectors/forgot-password';

import DashboardPage from '../../components/dashboard';
import Header from '../../components/layout/header';
import ForgotPasswordForm from '../../components/password/forgot';
import StyledFooter from '../../components/layout/styled-footer';
import StyledLayout from '../../components/layout/styled-layout';

class ForgotPasswordPage extends PureComponent {
  static getPageConfig() {
    return {
      guestAccess: true,
    };
  }

  handleForgotPasswordFormSubmit = (values, form) => {
    const {
      dispatch,
      history: { push },
    } = this.props;
    dispatch(
      forgotPassword(values, (error, response) => {
        if (error) {
          form.resetFields(['username']);
          return false;
        }
        push(route('forgotPasswordConfirmation', null, values));
      })
    );
  };

  render() {
    const { forgotPassword } = this.props;
    const { isLoading, error } = forgotPassword;

    return (
      <Layout>
        <Helmet title="Find Your Account" />
        <Header />
        <StyledLayout>
          <StyledForgotPasswordForm className="login-form">
            <img
              className="logo"
              src={require('../../benchmark-logo-full.png')}
              alt="Benchmark Analytics"
            />
            <ForgotPasswordForm
              error={error}
              isLoading={isLoading}
              onSubmit={this.handleForgotPasswordFormSubmit}
            />
          </StyledForgotPasswordForm>
        </StyledLayout>
        <StyledFooter />
      </Layout>
    );
  }
}

ForgotPasswordPage.propTypes = {
  form: PropTypes.object,
  login: PropTypes.object,
  dispatch: PropTypes.func,
};

const StyledForgotPasswordForm = styled.div`
  background-color: #fff;
  max-width: 420px;
  padding: 50px 0;
  margin: 0 auto;

  .logo {
    width: 100%;
    max-width: 300px;
    display: block;
    margin: 0 auto 25px;
  }
`;

const mapState = state => ({
  session: getSession(state),
  forgotPassword: getForgotPassword(state),
});

export default DashboardPage(mapState, null)(ForgotPasswordPage);
