import React from 'react';
import { Field } from 'redux-form';
import Input from '../../../components/input';
import { Input as InputField, Select } from 'antd';

const TextField = Input(InputField);
const SelectField = Input(Select);
const Option = Select.Option;

const commonFieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
  inputCol: 'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
};

export default class EquipmentSectionBuilder {
  constructor({
    fetchingAgency,
    shouldBeReadOnlyField,
    shouldSeeAnyField,
    getFieldLabel,
  }) {
    this.fetchingAgency = fetchingAgency;
    this.shouldBeReadOnlyField = shouldBeReadOnlyField;
    this.shouldSeeAnyField = shouldSeeAnyField;
    this.getFieldLabel = getFieldLabel;
    this.fields = new Map();
    this.registerBuilders();
  }

  createSelect(name, label, dropdownOptions, plainText) {
    return (
      <Field
        type="select"
        name={name}
        data-test={name}
        label={label}
        placeholder="Please Select"
        component={SelectField}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        dropdownOptions={dropdownOptions}
        plainText={plainText}
      />
    );
  }

  createText(name, label, plainText) {
    return (
      <Field
        type="text"
        name={name}
        data-test={name}
        label={label}
        component={TextField}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText={plainText}
      />
    );
  }

  buildEquipmentType = index => {
    const equipmentOptions = [
      <Option key="firearm" value="firearm">
        Firearm
      </Option>,
    ];
    return this.createSelect(
      `equipment[${index}].equipmentType`,
      this.getFieldLabel('equipmentType'),
      equipmentOptions,
      this.shouldBeReadOnlyField(['equipmentType'])
    );
  };

  buildEquipmentTypeType = index => {
    const equipmentTypeOptions = [
      <Option key="handgun" value="handgun">
        Handgun
      </Option>,
      <Option key="rifle" value="rifle">
        Rifle
      </Option>,
      <Option key="shotgun" value="shotgun">
        Shotgun
      </Option>,
    ];
    return this.createSelect(
      `equipment[${index}].equipmentTypeType`,
      this.getFieldLabel('equipmentTypeType'),
      equipmentTypeOptions,
      this.shouldBeReadOnlyField(['equipmentTypeType'])
    );
  };

  buildEquipmentTypeSubtype = index => {
    const equipmentSubtypeOptions = [
      <Option key="semi-automatic" value="semi-automatic">
        Semi-Automatic
      </Option>,
    ];
    return this.createSelect(
      `equipment[${index}].equipmentTypeSubtype`,
      this.getFieldLabel('equipmentTypeSubtype'),
      equipmentSubtypeOptions,
      this.shouldBeReadOnlyField(['equipmentTypeSubtype'])
    );
  };

  buildEquipmentMake = index => {
    return this.createText(
      `equipment[${index}].equipmentMake`,
      this.getFieldLabel('equipmentMake'),
      this.shouldBeReadOnlyField(['equipmentMake'])
    );
  };

  buildEquipmentModel = index => {
    return this.createText(
      `equipment[${index}].equipmentModel`,
      this.getFieldLabel('equipmentModel'),
      this.shouldBeReadOnlyField(['equipmentModel'])
    );
  };

  buildEquipmentCaliberClass = index => {
    return this.createText(
      `equipment[${index}].equipmentCaliberClass`,
      this.getFieldLabel('equipmentCaliberClass'),
      this.shouldBeReadOnlyField(['equipmentCaliberClass'])
    );
  };

  buildEquipmentImporter = index => {
    return this.createText(
      `equipment[${index}].equipmentImporter`,
      this.getFieldLabel('equipmentImporter'),
      this.shouldBeReadOnlyField(['equipmentImporter'])
    );
  };

  buildEquipmentSerialNumber = index => {
    return this.createText(
      `equipment[${index}].equipmentSerialNumber`,
      this.getFieldLabel('equipmentSerialNumber'),
      this.shouldBeReadOnlyField(['equipmentSerialNumber'])
    );
  };

  buildEquipmentRegistrationNumber = index => {
    return this.createText(
      `equipment[${index}].equipmentRegistrationNumber`,
      this.getFieldLabel('equipmentRegistrationNumber'),
      this.shouldBeReadOnlyField(['equipmentRegistrationNumber'])
    );
  };

  registerBuilders() {
    this.fields.set('equipmentType', this.buildEquipmentType);
    this.fields.set('equipmentTypeSubtype', this.buildEquipmentTypeSubtype);
    this.fields.set('equipmentTypeType', this.buildEquipmentTypeType);
    this.fields.set('equipmentMake', this.buildEquipmentMake);
    this.fields.set('equipmentModel', this.buildEquipmentModel);
    this.fields.set('equipmentCaliberClass', this.buildEquipmentCaliberClass);
    this.fields.set('equipmentImporter', this.buildEquipmentImporter);
    this.fields.set('equipmentSerialNumber', this.buildEquipmentSerialNumber);
    this.fields.set(
      'equipmentRegistrationNumber',
      this.buildEquipmentRegistrationNumber
    );
  }

  get(field, index) {
    const builderFunction = this.fields.get(field);
    return builderFunction ? builderFunction(index) : null;
  }
}
