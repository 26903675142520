import { hasPermissions, PERMISSIONS } from '../../../../../utils/admin';
import { ATTRIBUTE_TYPE_BUTTON } from '../../../../../components/custom-sections/sections/constants';

const addOrganizationHistoryActions = session => {
  const { currentUser = {} } = session;
  const { permissions = [] } = currentUser;
  const finalActionAttributes = [];

  // Add new button here
  const hasTransferEmployment = hasPermissions(
    permissions,
    PERMISSIONS.transferEmployment
  );
  if (hasTransferEmployment) {
    finalActionAttributes.push({
      field: PERMISSIONS.transferEmployment,
      label: 'Transfer Employment',
      type: ATTRIBUTE_TYPE_BUTTON,
      editable: false,
      visible: true,
      values: null,
      source: 'organization',
      table: null,
      tableFields: null,
      displayOrder: 10,
    });
  }

  return finalActionAttributes;
};

export default addOrganizationHistoryActions;
