import { Steps } from 'antd';
import styled from 'styled-components';

import {
  ACCENT,
  DIVIDER,
  INACTIVE,
  SUCCESS,
  ERROR,
} from '../../../config/theme';

const NavigationSteps = styled(Steps).attrs({
  className: 'bdm-form-steps',
})`
  &.ant-steps-dot {
    @media screen and (max-width: 991px) {
      margin-bottom: 10px;
    }

    .ant-steps-head {
      top: -1px;
    }

    .ant-steps-icon {
      width: 8px;
      height: 8px;

      .ant-steps-icon-dot {
        border-radius: 4px;
      }
    }
  }

  &.ant-steps {
    .ant-steps-head {
      background-color: transparent;
    }

    .ant-steps-item-tail {
      &::after {
        height: 2px;
        top: 1px;
      }
    }

    .ant-steps-item-title {
      font-size: 16px;
      font-weight: 500;
      color: ${INACTIVE};
    }

    .ant-steps-item.ant-steps-item-process {
      .ant-steps-item-title {
        color: ${ACCENT};
      }
    }

    .ant-steps-item.ant-steps-item-finish {
      .ant-steps-item-tail {
        &::after {
          background-color: ${DIVIDER};
          box-shadow: 0 0 0 ${DIVIDER};
        }
      }
    }

    .ant-steps-item.ant-steps-item-wait {
      .ant-steps-item-title {
        color: ${INACTIVE};
      }

      .ant-steps-item-tail {
        &::after {
          background-color: ${DIVIDER};
        }
      }

      .ant-steps-icon {
        .ant-steps-icon-dot {
          background-color: ${INACTIVE};
        }
      }
    }
    /* stylelint-disable */
    .ant-steps-item.ant-steps-item-process {
      .ant-steps-item-tail {
        &::after {
          background-color: ${DIVIDER};
        }
      }
    }
  }

  .ant-steps-item {
    cursor: pointer;
  }

  .ant-steps-item.ant-steps-item-finish.has-errors,
  .ant-steps-item.ant-steps-item-wait.has-errors {
    .ant-steps-item-title {
      color: ${ERROR};
    }

    .ant-steps-icon {
      .ant-steps-icon-dot {
        background-color: ${ERROR};
      }
    }
  }

  .ant-steps-item.ant-steps-item-wait.no-errors,
  .ant-steps-item.ant-steps-item-finish.no-errors {
    .ant-steps-item-title {
      color: ${SUCCESS};
    }

    .ant-steps-icon {
      .ant-steps-icon-dot {
        background-color: ${SUCCESS};
      }
    }
  }

  .ant-steps-item.ant-steps-item-finish.is-inactive {
    .ant-steps-item-title {
      color: ${INACTIVE};
    }

    .ant-steps-icon {
      .ant-steps-icon-dot {
        background-color: ${INACTIVE};
      }
    }
  }
`;

export default NavigationSteps;
