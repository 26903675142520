import React from 'react';

import createModal from 'APP_ROOT/actions/create-modal';
import showModal from 'APP_ROOT/actions/show-modal';
import { modalId } from '../constants/modal';
import Modal from '../components/Modal';
import ModalHeader from '../components/ModalHeader';
import ModalFooter from '../components/ModalFooter';
import ChangesReportReassign from '../services/changesReportReassign';

export default () => {
  return (dispatch, getState) => {
    const title = <ModalHeader />;
    const body = <Modal />;
    const footer = <ModalFooter />;
    ChangesReportReassign.setIsOpenModal(true);
    dispatch(
      createModal({
        id: modalId,
        visible: true,
        title,
        children: body,
        footer,
      })
    );
    dispatch(showModal(modalId));
  };
};
