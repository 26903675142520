import React, { Fragment } from 'react';

import FieldText from '../../components/FieldText';
import FieldCheckbox from '../../components/FieldCheckbox';

import { Row, Form } from 'antd';

const { Item } = Form;

export default class HyperlinkFieldOption extends React.Component {
  onChangeUrl = url => {
    const { onChange, value } = this.props;

    onChange && onChange({ ...value, url });
  };

  onChangeUrlText = urlText => {
    const { onChange, value } = this.props;

    onChange && onChange({ ...value, urlText });
  };

  onChangeStaticContent = staticContent => {
    const { onChange, value } = this.props;

    onChange && onChange({ ...value, staticContent });
  };

  render = () => {
    const itemLayoutOptions = {
      colon: false,
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
    };
    const {
      value: { url, urlText, staticContent = true },
    } = this.props;

    const urlLabel = staticContent ? (
      <div>
        Address&nbsp;<span style={{ color: 'red' }}>*</span>
      </div>
    ) : (
      'Root Location'
    );
    const urlPlaceholder = staticContent ? 'Address' : 'Root Location';

    return (
      <Fragment>
        <Row>
          <Item
            key="staticContent"
            label="Static Content"
            {...itemLayoutOptions}
          >
            <FieldCheckbox
              value={staticContent}
              onChange={this.onChangeStaticContent}
              data-test="link-checkbox"
            ></FieldCheckbox>
          </Item>
        </Row>
        {staticContent && (
          <Row>
            <Item
              key="textToDisplay"
              label="Text to Display"
              {...itemLayoutOptions}
              help="Maximum 100 characters"
            >
              <FieldText
                value={urlText}
                onChange={this.onChangeUrlText}
                style={{ width: '100%' }}
                placeholder="Display text"
                maxLength={100}
                data-test="link-text"
              ></FieldText>
            </Item>
          </Row>
        )}
        <Row>
          <Item
            key="address"
            label={urlLabel}
            {...itemLayoutOptions}
            help="Maximum 2048 characters"
          >
            <FieldText
              value={url}
              onChange={this.onChangeUrl}
              style={{ width: '100%' }}
              placeholder={urlPlaceholder}
              maxLength={2048}
              data-test="link-url"
            ></FieldText>
          </Item>
        </Row>
      </Fragment>
    );
  };
}
