import React, { Fragment } from 'react';
import { get, has } from 'lodash';
import { Input, InputNumber } from 'antd';

import FieldSelect from '../../components/FieldSelect';
import FieldText from '../../components/FieldText';

import componentsManager from '../../services/componentsManager';
import tabsManager from '../../services/tabsManager';
import getAllFieldsFlat from '../../utils/getAllFieldsFlat';
import {
  SELECT,
  MULTISELECT,
  AUTOCOMPLETE,
  CHECKBOX,
  DATE,
  DATE_TIME,
  TIME,
  NUMBER,
  SWITCH,
  TEXT,
  TEXTAREA,
} from 'APP_ROOT/constants/fieldTypes';

// DEFAULT_VALUE represents `key` for the fields dropdown and `value` for value dropdown, initially empty in both cases
const DEFAULT_VALUE = { '': '' };
const EMPTY_OPTION = { label: '-- Empty --', value: '' };
const STYLE_OPTIONS = { width: 180 };

class FieldsOnChange extends React.Component {
  constructor(props) {
    super(props);
    const { onChange, value, defaultValue } = this.props;

    if (value === undefined && defaultValue !== undefined) {
      onChange(defaultValue);
    }
  }
  onKeyChange = index => newKey => {
    const { onChange, value = DEFAULT_VALUE, fieldsByKey = {} } = this.props;
    const newValue = Object.entries(value).reduce((acc, [key, value], j) => {
      if (index === j) {
        const { field_type } = fieldsByKey[newKey] || {};
        acc[newKey] = this.getDefaultValue(field_type);
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
    onChange && onChange(newValue);
  };

  onValueChange = (i, isBool = false) => newVal => {
    const { onChange, value = DEFAULT_VALUE } = this.props;
    // comparing to 'true' since the dropdown converts value to string
    // instead of keep the boolean value
    const val = isBool ? newVal === 'true' : newVal;
    const newValue = Object.entries(value).reduce((acc, [key, value], j) => {
      acc[key] = i === j ? val : value;
      return acc;
    }, {});
    onChange && onChange(newValue);
  };

  getDefaultValue = field_type => {
    switch (field_type) {
      case TEXT:
      case TEXTAREA:
      case SELECT:
      case MULTISELECT: {
        return '';
      }
      case SWITCH:
      case CHECKBOX:
        return false;
      case NUMBER:
        return 0;
      default: {
        return '';
      }
    }
  };

  renderInput = (key, value, index) => {
    const { fieldsByKey = {} } = this.props;
    const { field_type, enumRef, parentEnum } = fieldsByKey[key] || {};
    let toRender;
    switch (field_type) {
      case SELECT:
      case MULTISELECT: {
        const enums = componentsManager.getEnum(enumRef, parentEnum);
        const options = [EMPTY_OPTION, ...enums];
        toRender = (
          <FieldSelect
            value={value}
            style={STYLE_OPTIONS}
            onChange={this.onValueChange(index)}
            selectOptions={options}
          />
        );
        break;
      }
      case SWITCH:
      case CHECKBOX:
        toRender = (
          <FieldSelect
            value={`${value}`}
            style={STYLE_OPTIONS}
            onChange={this.onValueChange(index, true)}
            selectOptions={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
          />
        );
        break;
      case TEXT:
      case TEXTAREA:
        toRender = (
          <FieldText
            value={value}
            defaultValue={value}
            style={STYLE_OPTIONS}
            onChange={this.onValueChange(index)}
          />
        );
        break;
      case NUMBER:
        toRender = (
          <InputNumber value={value} onChange={this.onValueChange(index)} />
        );
        break;
      default: {
        toRender = (
          <Input
            type="text"
            value={value}
            style={STYLE_OPTIONS}
            disabled={true}
          />
        );
        break;
      }
    }
    return toRender;
  };

  render() {
    const { selectOptions, value = DEFAULT_VALUE } = this.props;
    return Object.entries(value).map(([key, value], i) => (
      <Fragment key={key}>
        <FieldSelect
          value={key}
          style={STYLE_OPTIONS}
          onChange={this.onKeyChange(i)}
          selectOptions={selectOptions}
        />
        {this.renderInput(key, value, i)}
      </Fragment>
    ));
  }
}

export default class setFieldsOnChange {
  constructor(values, value = '') {
    this.currentFieldId = values.id;
    // includes currentField
    const { allTabs, fieldsByKey } = getAllFieldsFlat(componentsManager.fields);
    const { activeTabIndex } = tabsManager;
    // if activeTabIndex < 0, there is no tab, ReviewNote mode
    const tabIndex = activeTabIndex < 0 ? 0 : activeTabIndex;
    const { properties } = allTabs[tabIndex];
    const { key } = properties.find(p => p.id === this.currentFieldId) || {};

    const repeatersReference = componentsManager.repeatersReference;
    const repeaterRef = repeatersReference[tabIndex] || [];
    const repeater = repeaterRef.find(rep => rep.fields[key]);
    this.fieldsByKey = fieldsByKey;
    this.name = 'setFieldsOnChange';
    this.label = 'Set on change';
    this.component = FieldsOnChange;
    this.defaultValue = DEFAULT_VALUE;
    this.value = value;
    // seems that we can only set value to a select
    this.selectOptions = [EMPTY_OPTION].concat(
      allTabs.map(tab => ({
        label: tab.title,
        enums: tab.properties
          .filter(p => {
            let keepIt = [
              SELECT,
              MULTISELECT,
              AUTOCOMPLETE,
              CHECKBOX,
              DATE,
              DATE_TIME,
              TIME,
              NUMBER,
              SWITCH,
              TEXT,
              TEXTAREA,
            ].includes(p.field_type);
            if (keepIt && p.id !== this.currentFieldId) {
              if (repeater) {
                keepIt = repeater.fields[p.key];
              } else {
                keepIt = !Object.values(repeatersReference).some(ref =>
                  ref.find(rep => rep.fields[p.key])
                );
              }
              return keepIt;
            }
            return false;
          })
          .map(p => ({
            label: p.title || p.reportingKey || p.field_type,
            value: p.key,
          })),
      }))
    );
    if (activeTabIndex < 0) {
      const option = this.selectOptions.pop();
      this.selectOptions = [...this.selectOptions, ...option.enums];
    }
  }

  onChange = (value, values) => {
    return {
      ...values,
      [this.name]: value,
    };
  };

  onSave = values => {
    const selectedOptions = get(values, this.name);
    const newValues = { ...values, [this.name]: undefined };
    // to avoid saving an empty key
    if (!has(selectedOptions, '')) {
      newValues.options = { [this.name]: selectedOptions };
    }
    return newValues;
  };

  getInitialValue = values => {
    const { options } = values;
    if (options === undefined) {
      return this.defaultValue;
    }
    const setFieldsOnChange = get(options, this.name);

    return setFieldsOnChange ? setFieldsOnChange : this.defaultValue;
  };
}
