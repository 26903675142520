export default (name = '', glue = true) => {
  const initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);

  if (name.trim().length === 0) {
    return name;
  }

  if (glue) {
    return initials.join('');
  }

  return initials;
};
