import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import getOverrides from 'APP_ROOT/utils/get-field-overrides';
import ConditionsAssert from './conditional';
import Field from './FormField';
import {
  getSelectedFormChangedFields,
  getFormData,
  getFormValidationState,
  getFormTemplate,
  getDataEnums,
} from 'APP_ROOT/selectors/form';
import emitter, { EventTypes } from 'APP_ROOT/utils/eventEmitter';
import FormLabelEditor from './field-editor-label';

import ChangesTracker, {
  TrackingChangeTypes,
} from '../../../containers/administrator/services/changesTracker';

class EditableFieldWrapper extends Component {
  state = {
    isEnable: true,
    isRequired: true,
    hasVisibilityChanges: false,
    hasValidationsChanges: false,
  };

  componentDidMount() {
    this.onPublishChangeSuscription = ChangesTracker.getPublishedChanges().subscribe(
      this.updateEditor
    );
  }

  componentWillUnmount() {
    this.onPublishChangeSuscription.unsubscribe();
  }

  updateEditor = () => {
    const { id } = this.props;

    // Check visibility subscription
    const hasVisibilityChanges = ChangesTracker.hasPublishedFieldChanges(
      id,
      TrackingChangeTypes.VISIBILITY_CHANGE
    );

    // Check validations subscription
    const hasValidationsChanges = ChangesTracker.hasPublishedFieldChanges(
      id,
      TrackingChangeTypes.VALIDATION_CHANGE
    );

    // Set field state
    const nextVisibility = this.getVisibility();
    const nextRequired = this.getRequerible();

    if (hasVisibilityChanges) {
      this.setState({ isEnable: nextVisibility });
    }

    if (hasValidationsChanges) {
      this.setState({ isRequired: nextRequired });
    }

    this.setState({ hasVisibilityChanges, hasValidationsChanges });
  };

  getChanges = type => {
    const { id } = this.props;

    const fieldPublishedChanges = ChangesTracker.getPublishedFieldChanges(
      id,
      type
    );

    return fieldPublishedChanges;
  };

  getVisibility = () => {
    const fieldChanges = this.getChanges(TrackingChangeTypes.VISIBILITY_CHANGE);

    return get(fieldChanges, 'data.turnedOn', false);
  };

  getRequerible = () => {
    const fieldChanges = this.getChanges(TrackingChangeTypes.VALIDATION_CHANGE);

    return get(fieldChanges, 'data.required', false);
  };

  getGroupData = () => {
    const {
      data = {},
      parentIndex = 0,
      parentKey = '',
      isReviewer = false,
      syncFrom,
    } = this.props;

    const hasParent = !!parentKey;

    return {
      ...(hasParent ? data[parentKey][parentIndex] : data),
      syncFrom: get(data, [syncFrom, parentIndex]),
      index: parentIndex,
      humanReadableIndex: parentIndex + 1,
      isReviewer,
    };
  };

  getFieldValidations = () => {
    const {
      id,
      dataKey,
      parentKey,
      validations: { rules = {} } = {},
    } = this.props;
    return {
      id,
      rules: get(
        rules,
        parentKey ? `${parentKey}[0].item[0].fields.${dataKey}` : dataKey,
        []
      ),
    };
  };

  isFieldRequired = validations => {
    const { rules = [] } = validations;
    return rules.some(rule => rule.required || !!rule.mustExist);
  };

  onFieldEditorClick = options => e => {
    e.stopPropagation();
    e.preventDefault();

    emitter.emit(EventTypes.OPEN_FIELD_EDITOR, options);
  };

  labelComponent = props => {
    const {
      hasVisibilityChanges,
      hasValidationsChanges,
      isEnable,
      isRequired,
    } = this.state;
    const { disable: turnedOff = false, id } = this.props;
    const { children, fieldProps } = props;
    const fieldValidations = this.getFieldValidations();
    const required = hasValidationsChanges
      ? isRequired
      : this.isFieldRequired(fieldValidations);
    const isDisable = hasVisibilityChanges ? !isEnable : turnedOff;
    const field = Object.assign({}, fieldProps, {
      id,
      turnedOn: !isDisable,
      required,
      validations: fieldValidations,
    });

    return children ? (
      <FormLabelEditor
        disabled={isDisable}
        onFieldEditorClick={this.onFieldEditorClick(field)}
        id={id}
      >
        {children}
      </FormLabelEditor>
    ) : null;
  };

  render() {
    const { hasVisibilityChanges, isEnable } = this.state;
    const {
      data = {},
      dataEnums,
      dataKey = '',
      overrides = {},
      parentIndex = 0,
      parentKey = '',
      type,
      field_type,
      settings,
      disable: turnedOff = false,
    } = this.props;

    const hasParent = !!parentKey;
    const getFieldOverrides = getOverrides(this.props, overrides);
    const conditions = getFieldOverrides('conditions', {});
    const contextPath = hasParent ? `${parentKey}[${parentIndex}].` : '';
    const fieldDataKey = `${contextPath}${dataKey}`;
    const groupData = this.getGroupData();
    const isDisable = hasVisibilityChanges ? !isEnable : turnedOff;

    return (
      <ConditionsAssert
        conditions={conditions}
        data={groupData}
        wholeData={data}
        field={fieldDataKey}
        type={type}
        field_type={field_type}
        settings={settings}
        dataEnums={dataEnums}
        disabled={isDisable}
        render={(shouldShow = false, behavior = 'hide') => (
          <Field
            {...this.props}
            disable={isDisable}
            shouldShow={shouldShow}
            behavior={behavior}
            labelComponent={this.labelComponent}
            isEditor
          />
        )}
      />
    );
  }
}

const mapState = (state, props) => {
  const formTemplate = getFormTemplate(state, props);

  return {
    data: getFormData(state, props),
    changedFields: getSelectedFormChangedFields(state, props),
    validationState: getFormValidationState(state, props),
    dataEnums: getDataEnums(state, props, formTemplate),
  };
};

export default connect(mapState)(EditableFieldWrapper);
