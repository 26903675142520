import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { get, capitalize, last } from 'lodash';

import { Divider } from '../../components/common/panel';

import DashboardPage from '../../components/dashboard';
import WheelNavigation from '../../components/common/wheel';
import wheelNavigationItems from '../../constants/wheel-navigation';
import setWheelCategory from '../../actions/set-wheel-category';
import { getUserProfile } from '../../actions/get-user';
import { getAgencyTZ, getModulesConfiguration } from '../../selectors/session';
import RenderSchema from '../../components/RenderSchema';
import FORM_TYPES from 'APP_ROOT/constants/form-types';
import { LOADER_TYPES } from 'APP_ROOT/constants/renderSchema';
import { getFormFieldProfileData } from 'APP_ROOT/selectors/selected-users';

class Officers extends Component {
  static getPageConfig() {
    const { location, user } = this.props;
    const fullName = get(user, 'data.fullName');
    const officerName = String(fullName)
      .split(' ')
      .map(capitalize)
      .join(' ');
    const pageName = last(String(location.pathname).split('/'))
      .split('-')
      .map(capitalize)
      .join(' ');
    const title = fullName ? `${officerName} - ${pageName}` : pageName;

    return {
      title,
    };
  }

  UNSAFE_componentWillMount() {
    const { match: { params: { id } = {} } = {}, dispatch } = this.props;

    dispatch(getUserProfile(id));
  }

  componentDidMount() {
    const { location } = this.props;
    const segments = location.pathname.split('/');
    const section = segments[segments.length - 1];
    const foundIndex = wheelNavigationItems.findIndex(
      item => item.route.replace('/', '') === section
    );
    const id = foundIndex > -1 ? foundIndex : 0;

    this.selectCategory({ id });
    this.redirectToCategory(id, !segments[3]);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { location, history } = props;

    if (location.pathname !== this.props.location.pathname) {
      const segments = location.pathname.split('/');
      const section = segments[segments.length - 1];
      const foundIndex = wheelNavigationItems.findIndex(
        item => item.route.replace('/', '') === section
      );
      const id = foundIndex > -1 ? foundIndex : 0;
      this.selectCategory({
        id,
        action: history.action,
      });
    }
  }

  selectCategory = data => {
    const { dispatch } = this.props;

    dispatch(setWheelCategory(data.id));
  };

  redirectToCategory = (category, replace = false) => {
    const {
      location,
      history,
      match: { params: { id } = {} } = {},
    } = this.props;

    const route = wheelNavigationItems[category].route;
    const goTo = `/people/${id}${route}`;

    if (location.pathname !== goTo) {
      if (replace) {
        history.replace(goTo);
      } else {
        history.push(goTo);
      }
    }
  };

  nextCategory = e => {
    const { app } = this.props;
    const { selectedCategory } = app;
    const max = wheelNavigationItems.length - 1;
    const current = selectedCategory[1];

    if (current === max) {
      this.selectCategory({ id: 0 });
      this.redirectToCategory(0);
    } else {
      this.selectCategory({ id: current + 1 });
      this.redirectToCategory(current + 1);
    }
  };

  prevCategory = e => {
    const { app } = this.props;
    const { selectedCategory } = app;
    const max = wheelNavigationItems.length - 1;
    const current = selectedCategory[1];

    if (current === 0) {
      this.selectCategory({ id: max });
      this.redirectToCategory(max);
    } else {
      this.selectCategory({ id: current - 1 });
      this.redirectToCategory(current - 1);
    }
  };

  onCategoryClick = ({ id = 0 }) => {
    this.redirectToCategory(id);
  };

  render() {
    const {
      app,
      user: { loading } = {},
      session: {
        currentUser: { permissions = [] },
      },
      location: { pathname },
      modules = [],
      match: { url },
    } = this.props;
    const { selectedCategory } = app;

    const category = pathname.replace(url, '').substr(1);
    const hasTrainingAccess =
      permissions.includes('accessAssignTrainingPage') &&
      modules.includes('training');
    const whitelisted = {
      'use-of-force': true,
      'internal-affairs': true,
      training: hasTrainingAccess,
    };
    const whitelistedProfile = {
      profile: true,
    };
    const isWhitelisted = get(whitelisted, category, false);
    const isWhitelistedProfile = get(whitelistedProfile, category, false);

    return (
      <div className="dashboard-container">
        <WheelNavigation
          active={selectedCategory}
          items={wheelNavigationItems}
          onClick={this.onCategoryClick}
          onNext={this.nextCategory}
          onPrev={this.prevCategory}
        />

        <RenderSchema
          schema={FORM_TYPES.OFFICER_BIO}
          loader={LOADER_TYPES.LOADER_OFFICER_BIO}
          dataSelector={getFormFieldProfileData}
        />

        {((isWhitelisted && !loading) || isWhitelistedProfile) && (
          <div>
            <CustomDivider />
            {this.props.children}
          </div>
        )}

        {!isWhitelisted && !isWhitelistedProfile && (
          <div>
            <MarginDivider />

            <ComingSoon>
              <h2>Coming Soon</h2>
            </ComingSoon>
          </div>
        )}
      </div>
    );
  }
}

const CustomDivider = styled(Divider)`
  margin: 24px 0;
`;

const MarginDivider = styled.div`
  margin: 24px 0;
`;

const ComingSoon = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 100px 50px;
  text-align: center;
  min-height: 300px;

  h2 {
    color: #c7c7c7;
    font-size: 2em;
  }
`;

const mapState = state => {
  const { selectedUser: user } = state;
  return {
    user,
    timezone: getAgencyTZ(state),
    modules: getModulesConfiguration(state),
  };
};
const RoutedDashboardPage = withRouter(DashboardPage(mapState, null)(Officers));
export default RoutedDashboardPage;
export { default as OfficerProfile } from './profile';
export { default as OfficerUseOfForce } from './use-of-force';
export { default as OfficerCommunityEngagement } from './community-engagement';
export { default as OfficerPerformanceEvaluation } from './performance-evaluation';
export { default as InternalAffairs } from './internal-affairs';
export { default as OfficerTraining } from './training';
export { default as OfficerActivity } from './activity';
export { default as OfficerTrainingTranscript } from './training-transcript';
