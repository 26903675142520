import { Menu } from 'antd';
import styled from 'styled-components';
import { SIDEBAR_HOVER, SIDEBAR_HOVER_BG } from '../../../config/theme';

const SubMenu = styled(Menu.SubMenu)`
  padding: 5px !important;

  &:hover {
    background-color: ${SIDEBAR_HOVER_BG};
    color: ${SIDEBAR_HOVER} !important;
  }

  & .ant-menu-submenu-title {
    padding-left: ${props => (props.hasItems ? '30px' : 'inherit')};
    padding-right: ${props => (props.hasItems ? '30px' : 'inherit')};
    text-align: center;
  }

  &.ant-menu-submenu-open,
  &.ant-menu-submenu-active,
  & .ant-menu-submenu-title:hover {
    color: ${SIDEBAR_HOVER} !important;
  }

  &.ant-menu-submenu-active {
    & .ant-menu-submenu-arrow {
      display: block !important;
      visibility: visible !important;
    }
  }
`;

export default SubMenu;
