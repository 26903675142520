import styled from 'styled-components';
import { PanelSection } from './';
import { GRAY_2, DIVIDER, ACCENT } from '../../../../config/theme';

export const PanelAvatarSection = styled(PanelSection)`
  text-align: center;

  &,
  &:last-child {
    padding: 10px 0 5px 0;
  }

  @media screen and (min-width: 992px) {
    border-right: 1px solid ${DIVIDER};
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 15px;
  }

  .ant-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-bottom: 16px;
    background-color: ${ACCENT};

    .ant-avatar-string {
      font-size: 20px;
      line-height: 60px;
    }
  }

  p.title {
    font-weight: bold;
    color: #000;
    font-size: 14px;
    line-height: 17px;
  }

  p {
    font-size: 12px;
    color: ${GRAY_2};
    margin: 0 0 8px 0;
    margin-bottom: 8px;
    line-height: 14px;
  }

  p:nth-last-child(1) {
    margin: 0;
  }
`;

export default PanelAvatarSection;
