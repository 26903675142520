import callback from '../../utils/callback';
import OrganizationEndpoints from '../../../../api/organization/organizationEndpoints';

export default (agencyId, next) => _dispatch => {
  return OrganizationEndpoints.getAgencyById(agencyId)
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
