// to call the API, for instance
// https://{organization-service}:3003/api/organizations/v1/agencies/ranks/9001

import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';

class communityMembersEndPoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'http://localhost:3003';
    const apiPrefix = 'organizations/v1';
    super(
      process.env.REACT_APP_ORGANIZATIONS_SERVICE_URI || DEV_URI,
      apiPrefix
    );
  }

  /**
   * Fetches the comment list for an organizational unit
   * @returns Promise that resolves with a list of comments
   */

  getCommunityList(tenantId, integrationId, pageNumber, pageSize) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/community-members'),
        method: 'GET',
      },
      { tenantId },
      {
        pageNumber,
        pageSize,
        tenantId,
        integrationId,
      }
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  createCommunityMember(tenantId, body) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/community-members'),
        method: 'POST',
      },
      { tenantId },
      {},
      body
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  updateCommunityMember(tenantId, integrationId, body) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/tenants/:tenantId/community-members/:integrationId'
        ),
        method: 'PATCH',
      },
      { tenantId, integrationId },
      {},
      body
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }
}

export default new communityMembersEndPoints();
