import { createSelector } from 'reselect';
import {
  has,
  get,
  omit,
  find,
  first,
  isPlainObject,
  castArray,
  isEmpty,
} from 'lodash';
import { emptyObject, emptyArray } from '../constants/form';

import { getCurrentUser } from './session';
import { repeaterKeyDivider } from '../utils/constants';

const getFormNameFromProps = props => get(props, 'settings.formName', 'form');
const getNoteIdFromProps = props => get(props, 'settings.noteId');

const getDataKeysUsedByTitle = title =>
  [].concat(isPlainObject(title) ? get(title, 'source') : []);

const getPresenceUsageKeys = options => {
  const presenceUsageFrom = get(options, 'presenceUsageFrom');

  if (presenceUsageFrom) {
    return [presenceUsageFrom];
  }

  return [];
};

const getFieldDataKey = (parentKey, dataKey) =>
  dataKey.includes('data.')
    ? dataKey
    : parentKey
    ? parentKey
    : first(dataKey.split('.'));

const getDurationDateKeys = (parentKey, options) => {
  const { initial = '', final = '' } = options;
  if (initial && final) {
    return [
      getFieldDataKey(parentKey, initial),
      getFieldDataKey(parentKey, final),
    ];
  }
  return [];
};

const getMathNumberKeys = (parentKey, options) => {
  const { operands = [] } = options;
  return operands.map(op => getFieldDataKey(parentKey, op));
};

export const getFieldDataOnly = (state, props) => {
  const dataKey = get(props, 'dataKey', '');
  const parentKey = get(props, 'parentKey', '');
  const fromSource = get(props, ['fromSource', 'source'], '');
  const fromSourceField = get(props, ['fromSource', 'field'], '');
  const options = get(props, 'options', {});
  const conditions = get(props, 'conditions', {});
  const rules = get(conditions, 'rules', []);
  const dependsOn = castArray(get(props, 'dependsOn', ''));
  const populateFrom = get(options, 'populateFrom', {});
  const title = get(props, 'title');

  const keysFromRules = rules.map(rule => {
    if (has(rule, 'fromSource')) {
      return get(rule, ['fromSource', 'source']);
    }
    return rule.key;
  });

  const keysFromPopulate = Object.keys(populateFrom).reduce((result, item) => {
    let findKeys = [];
    if (populateFrom[item].length) {
      findKeys = populateFrom[item].reduce((result, item) => {
        if (has(item, 'find')) {
          return [...result, get(item, 'find.source')];
        }
        return [...result];
      }, []);
    }
    return [...result, ...findKeys, item];
  }, []);

  const formName = getFormNameFromProps(props);
  const formData = get(state, `${formName}.selectedForm`);

  const sourcesFromTitle = getDataKeysUsedByTitle(title);

  const presenceUsageKeys = getPresenceUsageKeys(options);

  const durationDateKeys = getDurationDateKeys(parentKey, options);

  const mathNumberKeys = getMathNumberKeys(parentKey, options);
  /**
   * Array with all the keys required by conditions in each field.
   * Each source has to be an array of strings.
   * keysFromRules -> Keys from rules
   * keysFromPopulate -> Keys from populate from
   * parentKey ? parentKey : dataKey -> Key from single field or repeater
   * fromSource.includes('__') ? fromSource : `__${fromSource}` -> Key fromSource
   * dependsOn -> Key from dependsOn
   */
  const pickedKeys = [
    ...keysFromRules,
    ...keysFromPopulate,
    parentKey ? parentKey : dataKey,
    fromSource.includes('__') ? fromSource : `__${fromSource}`,
    fromSourceField,
    ...dependsOn,
    ...sourcesFromTitle,
    ...presenceUsageKeys,
    ...durationDateKeys,
    ...mathNumberKeys,
    '__users',
  ];

  const fieldData = pickedKeys.reduce((result, item) => {
    let path;
    const newItem = String(item);
    switch (true) {
      case newItem.includes('data.') || newItem.includes('meta.'):
        path = item;
        break;
      default:
        path = `data.${item}`;
        break;
    }

    const key = path.replace('data.', '');

    if (newItem.includes(repeaterKeyDivider)) {
      const newKey = first(key.split(repeaterKeyDivider));
      const newPath = first(path.split(repeaterKeyDivider));
      return {
        ...result,
        [newKey]: get(formData, newPath),
      };
    }

    return {
      ...result,
      [key]: get(formData, path),
    };
  }, {});

  return fieldData;
};

const getSelectedFormFromState = (state, props) => {
  const formName = getFormNameFromProps(props);
  const noteId = getNoteIdFromProps(props);

  if (['reviewNotes'].includes(formName) && noteId) {
    const notes = get(state, `form.selectedForm.meta.notes`, emptyArray);
    const extraData = get(state, `form.selectedForm.data`, emptyObject);

    const note = find(notes, item => item.id === noteId) || emptyObject;

    return {
      data: get(note, 'content', emptyObject),
      extraData,
    };
  }

  return get(state, `${formName}.selectedForm`, emptyObject);
};

const getSelectedFieldChangedFromState = (state, props) => {
  const formName = getFormNameFromProps(props);
  const noteId = getNoteIdFromProps(props);
  const formData = get(state, `${formName}.selectedForm`, emptyObject);
  const fieldData = getFieldDataOnly(state, props);

  const customData = {
    ...formData,
    changedFields: fieldData,
  };

  if (['reviewNotes'].includes(formName) && noteId) {
    const notes = get(state, `form.selectedForm.meta.notes`, emptyArray);
    const extraData = get(state, `form.selectedForm.data`, emptyObject);

    const note = find(notes, item => item.id === noteId) || emptyObject;

    return {
      data: get(note, 'content', emptyObject),
      extraData,
    };
  }

  return { ...customData, meta: formData.meta };
};

const getSelectedFieldFromState = (state, props) => {
  const formName = getFormNameFromProps(props);
  const noteId = getNoteIdFromProps(props);
  const formData = get(state, `${formName}.selectedForm`);
  const fieldData = getFieldDataOnly(state, props);
  const submitterId = get(formData, ['data', 'submitterId']);

  const customData = {
    ...formData,
    data: { ...fieldData, submitterId },
  };

  if (['reviewNotes'].includes(formName) && noteId) {
    const notes = get(state, `form.selectedForm.meta.notes`, emptyArray);
    const extraData = get(state, `form.selectedForm.data`, emptyObject);

    const note = find(notes, item => item.id === noteId) || emptyObject;

    return {
      data: get(note, 'content', emptyObject),
      extraData,
    };
  }

  return { ...customData, meta: { ...formData.meta, submitterId } };
};

export const getFormTemplate = (state, props) => {
  const formName = getFormNameFromProps(props);

  if (['reviewForm'].includes(formName)) {
    const form = get(state, `form.selectedForm`);
    const reviewForm = get(state, formName);

    const { templateId: reviewPanelTemplateId } = first(
      get(form, 'meta.reviewNoteTemplateId', [])
    );
    return get(reviewForm, ['entries', reviewPanelTemplateId], {});
  }

  const form = state.form;
  const selectedForm = getSelectedFormFromState(state, props);
  const templates = form.templates;

  return templates[selectedForm.template];
};

export const getForm = createSelector(
  state => state.form,
  getForm => getForm
);

export const getReportLoadingState = createSelector(
  state => state.report,
  report => report.loading
);

export const getFormTemplates = createSelector(
  state => state.form,
  form => form.templates
);

export const getTemplateType = createSelector(
  state => state.form,
  form => {
    const selectedForm = form.selectedForm;
    const formType = selectedForm.template;
    return formType;
  }
);

export const getSelectedForm = createSelector(
  [
    state => {
      const form = state.form.selectedForm;
      const presentations = state.form.presentations;
      const templates = state.form.templates;
      const selectedForm = {
        ...form,
        template: templates[form.template],
        presentation: presentations[form.presentation],
      };

      return selectedForm;
    },
    omitKeys => omitKeys || emptyArray,
  ],
  (selectedForm, omitKeys) => omit(selectedForm, omitKeys)
);

export const selectFormTemplate = createSelector(
  [state => state.form, getSelectedFormFromState],
  (form, selectedForm) => {
    const templates = form.templates;

    return templates[selectedForm.template];
  }
);

export const getSelectedFormChangedFields = createSelector(
  [getSelectedFieldChangedFromState],
  selectedForm => selectedForm.changedFields || emptyObject
);

export const getDataEnums = createSelector(
  [getSelectedFormFromState, (_, props) => props, (_, __, c) => c],
  (selectedForm, _, template) => ({
    ...get(selectedForm, 'enums', {}),
    ...get(template, 'formSchema.enums', {}),
  })
);

export const getSelectedFormData = createSelector(
  state => state.form.selectedForm,
  selectedForm => selectedForm.data || emptyObject
);

export const getFormValidationState = createSelector(
  [getSelectedFormFromState],
  selectedForm => selectedForm.validations || emptyObject
);

export const getFormData = createSelector(
  [
    getSelectedFormFromState,
    (_, props) => props,
    state => getCurrentUser(state),
  ],
  (selectedForm, { isReviewer, ...rest }, user) => {
    return {
      ...selectedForm.data,
      meta: selectedForm.meta,
      isReviewer,
      activeUser: user.id + emptyArray,
    };
  }
);

export const getFormMeta = createSelector(
  getSelectedFormFromState,
  selectedForm => {
    return selectedForm.meta;
  }
);

export const getFormFieldData = createSelector(
  [
    getSelectedFieldFromState,
    (_, props) => props,
    state => getCurrentUser(state),
  ],
  (selectedForm, { isReviewer }, user) => ({
    ...selectedForm.data,
    meta: selectedForm.meta,
    isReviewer,
    activeUser: user.id + emptyArray,
  })
);

export const getSelectedReviewForm = createSelector(
  state => {
    const form = state.reviewForm.selectedForm;
    const presentations = state.reviewForm.presentations;
    const templates = state.reviewForm.templates;
    const selectedForm = {
      ...form,
      template: templates[form.template],
      presentation: presentations[form.presentation],
    };

    return selectedForm;
  },
  selectedForm => selectedForm
);

export const getSelectedFormLink = createSelector(
  state => {
    const form = state.form.selectedForm;
    const selectedFormLink = {
      formId: form.id,
      formType: get(form, 'meta.formType'),
      linkedFormsId: get(form, 'meta.formLinks', []).map(
        link => link.linkedFormId
      ),
      linkedSubmissions: get(form, 'meta.linkedSubmissions'),
    };

    return selectedFormLink;
  },
  formLink => formLink
);

export const getTemplatesLoading = createSelector(
  state => {
    const meta = state.form.templatesMeta;
    return meta.loading;
  },
  isLoading => isLoading
);

export const getTemplates = createSelector(
  state => {
    return state.form.templates;
  },
  templates => templates
);

export const getFormSubmissionId = createSelector(
  [
    getSelectedFormFromState,
    (_, props) => props,
    state => getCurrentUser(state),
  ],
  selectedForm => get(selectedForm, 'meta.id')
);

export const getFormParticipants = createSelector(
  [
    getSelectedFormFromState,
    (_, props) => props,
    state => getCurrentUser(state),
  ],
  selectedForm => get(selectedForm, 'meta.participants')
);

export const getTaskName = createSelector(
  [
    getSelectedFormFromState,
    (_, props) => props,
    state => getCurrentUser(state),
  ],
  (selectedForm, props) =>
    get(selectedForm, `data.${props.parentKey}[${props.parentIndex}].taskName`)
);

const getSourceFieldValueFromState = (state, props) => {
  const sourceField = get(props, 'options.sourceField');
  const parentIndex = get(props, 'parentIndex');
  if (
    Array.isArray(sourceField) &&
    parentIndex >= 0 &&
    sourceField.length == 3
  ) {
    const sourceFieldObject = sourceField;
    const fieldKey = sourceFieldObject[2];
    const repeaterKey = sourceFieldObject[1];
    if (fieldKey && repeaterKey) {
      if (
        !isEmpty(
          get(
            state,
            `form.selectedForm.data.${repeaterKey}[${parentIndex}].${fieldKey}`
          )
        )
      ) {
        return {
          sourceFieldValue: {
            value: get(
              state,
              `form.selectedForm.data.${repeaterKey}[${parentIndex}].${fieldKey}`
            ),
          },
        };
      } else {
        return {
          sourceFieldValue: { value: '' },
        };
      }
    }
  } else {
    return undefined;
  }
};

export const getSourceFieldValue = createSelector(
  [getSourceFieldValueFromState],
  obj => {
    return get(obj, 'sourceFieldValue');
  }
);

const getSectionTitleFromSourceField = (state, props) => {
  const titleFromSource = get(props, 'title');
  const parentIndex = get(props, 'parentIndex');

  if (!isEmpty(titleFromSource)) {
    let title = titleFromSource;
    if (title) {
      const objTitle = title;
      if (get(objTitle, 'map[0]')) {
        const fieldKey = get(objTitle, 'map[0]');
        const repeaterKey = get(objTitle, 'source');
        if (fieldKey && repeaterKey) {
          let sourceFieldValue = get(
            state,
            `form.selectedForm.data.${repeaterKey}[${parentIndex}].${fieldKey}`
          );
          if (isEmpty(sourceFieldValue)) {
            sourceFieldValue = get(title, 'defaultTitle');
          }
          return {
            sourceFieldValue: sourceFieldValue,
          };
        }
      } else if (!isEmpty(objTitle)) {
        return {
          sourceFieldValue: objTitle,
        };
      }
    }
    return undefined;
  } else {
    return undefined;
  }
};

export const getSectionTitleFromSourceFieldValue = createSelector(
  [getSectionTitleFromSourceField],
  obj => {
    return get(obj, 'sourceFieldSectionValue');
  }
);
