import React from 'react';
import { Avatar } from 'antd';

const UserPicture = ({ src }) => {
  return (
    <Avatar
      shape="square"
      src={src}
      style={{ width: '180px', height: '210px' }}
    />
  );
};

export default UserPicture;
