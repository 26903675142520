import React from 'react';
import ModalBody from 'APP_ROOT/components/common/modal/body';
import { isEmpty } from 'lodash';
export default props => {
  const { namesToShare, reportName, usersToUnshare } = props;

  const shares = [];
  if (!isEmpty(namesToShare)) {
    shares.push(<strong key="shared">shared</strong>, ` with ${namesToShare}`);
    if (!isEmpty(usersToUnshare)) {
      shares.push(' and ');
    }
  }
  if (!isEmpty(usersToUnshare)) {
    shares.push(
      <strong key="unshared">unshared</strong>,
      ` with ${usersToUnshare}`
    );
  }
  return (
    <ModalBody>
      <p>
        {reportName} report has been&nbsp; {shares.map(s => s)}
      </p>
    </ModalBody>
  );
};
