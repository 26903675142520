import { pick, get } from 'lodash';

export const getParsedResolutions = (actions, resolution) => {
  return actions
    .filter(item => item.id === resolution)
    .map(item => {
      const id = item.id;
      const label = item.label;
      const requires = Object.keys(item.required).filter(
        key => item.required[key]
      );
      const requiresEvidence = item.required.evidence;
      const reviewerLabel = item.reviewer.label;
      const reviewers = item.reviewer.list;
      return {
        id,
        label,
        requires,
        requiresEvidence,
        reviewerLabel,
        reviewers,
      };
    });
};

export const getResolutionsOptions = (
  formActions,
  { isLinked, isFinalLinked, hasDefaultReviewer, hasLockableEnable }
) => {
  return formActions
    .filter(({ reviewer }) =>
      hasDefaultReviewer ? reviewer.list.length : true
    )
    .filter(({ canCloseFromCurrentState: isLockable = false }) => {
      return !(isLinked && isFinalLinked)
        ? isLockable === hasLockableEnable
        : true;
    })
    .map(action => {
      const { label, id } = action;
      return {
        id,
        label,
      };
    });
};

export const pickValuesFromActions = (report, actions, path, values) => {
  return pick(
    actions.find(action => {
      return action.id === get(report, path);
    }) || {},
    values
  );
};

export const getRequires = (requires = [], hasDefaultReviewer) => {
  if (hasDefaultReviewer) return requires.filter(item => item !== 'reviewer');
  return requires;
};
