import { assign, find, isEmpty, map, orderBy } from 'lodash';
import moment from 'moment/moment';
import { BENCHMARK_DATE_FORMAT } from '../../../../../utils/parse-date';

function getUserEmploymentValues(
  { organizationalUnitHistoryData = {} },
  state
) {
  const { employmentHistoryData } = state;
  const { employment } = organizationalUnitHistoryData;
  const data = [];

  if (employment && employment.id) {
    employment.id.forEach((historyId, index) => {
      if (!isEmpty(organizationalUnitHistoryData[historyId])) {
        let employmentHistory = [];
        let changedRecords = [];
        let newRecords = [];
        const savedEmploymentHistory = employmentHistoryData.find(
          ou => ou.historyId == historyId
        );

        map(organizationalUnitHistoryData[historyId], (value, key) => {
          let employmentHistoryRecord = {};
          value.forEach((value, index) => {
            if (savedEmploymentHistory) {
              let detailIndex = savedEmploymentHistory.detail.id.findIndex(
                id => id == organizationalUnitHistoryData[historyId].id[index]
              );
              if (detailIndex > -1) {
                if (savedEmploymentHistory.detail[key][detailIndex] != value) {
                  changedRecords.push(
                    organizationalUnitHistoryData[historyId].id[index]
                  );
                }
              } else {
                changedRecords.push(
                  organizationalUnitHistoryData[historyId].id[index]
                );
                newRecords.push(
                  organizationalUnitHistoryData[historyId].id[index]
                );
              }
            }
            employmentHistoryRecord[key] = value;
            employmentHistory[index] = assign(
              employmentHistory[index],
              employmentHistoryRecord
            );
          });
        });

        const sanitizeEmploymentHistory = record => {
          delete record.updated;

          if (record.effectiveDate && moment.isMoment(record.effectiveDate)) {
            return {
              ...record,
              effectiveDate: record.effectiveDate.format(BENCHMARK_DATE_FORMAT),
            };
          }

          return record;
        };

        //Remove items that were not changed when the record was edited
        //and remove the id from new records
        const finalEmploymentHistory = savedEmploymentHistory
          ? employmentHistory
              .filter(employmentRecord =>
                find(changedRecords, id => id == employmentRecord.id)
              )
              .map(record => {
                return sanitizeEmploymentHistory(
                  find(newRecords, id => id == record.id)
                    ? delete record.id && record
                    : record
                );
              })
          : employmentHistory.map(record => {
              delete record.id;

              return sanitizeEmploymentHistory(record);
            });

        const sortedEmploymentHistoryDesc = orderBy(
          finalEmploymentHistory,
          ['effectiveDate'],
          'desc'
        );

        if (finalEmploymentHistory.length > 0) {
          data.push({
            organizationalUnitId: employment.organizationalUnitId[index],
            // There is no previous record for this history record,
            // that means it's new and the id is set null
            id: savedEmploymentHistory ? historyId : null,
            employment: finalEmploymentHistory,
            primary:
              sortedEmploymentHistoryDesc[0].primary == null
                ? false
                : sortedEmploymentHistoryDesc[0].primary,
          });
        }
      }
    });
  }
  return data.length ? { organizationalUnitHistory: data } : null;
}

export default getUserEmploymentValues;
