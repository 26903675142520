// to call the API, for instance
// https://{organization-service}:3003/api/organizations/v1/agencies/ranks/9001

import { fetchRequestFile } from '../../utils/request';
import ServiceClient from '../serviceClient';

class logActionsEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'http://localhost:3003';
    const apiPrefix = 'organizations/v1';
    super(
      process.env.REACT_APP_ORGANIZATIONS_SERVICE_URI || DEV_URI,
      apiPrefix
    );
  }

  getLogActionsAsCsv(
    auditPageNumber,
    auditPageSize,
    endDate,
    executedBy,
    initialDate,
    tableNames,
    tenantId,
    userId
  ) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/audit-logs/csv'),
        method: 'GET',
      },
      { tenantId },
      {
        auditPageNumber,
        auditPageSize,
        endDate,
        executedBy,
        initialDate,
        tableNames,
        tenantId,
        userId,
      }
    );
    return fetchRequestFile(fetchParams.URI, fetchParams.request);
  }
}

export default new logActionsEndpoints();
