import React, { Fragment, useEffect, useState } from 'react';
import { get } from 'lodash';
import { Row, Col, DatePicker, List, Spin } from 'antd';

import DashboardPage from 'APP_COMPONENTS/dashboard';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import PageHeader from 'APP_COMPONENTS/PageHeader';

import getRoute from 'APP_ROOT/utils/get-route';

import withOwnershipGuard from '../../components/agency-ownership-guard';
import AdministratorWrapper from '../../Administrator.styled';

import moment from 'moment-timezone';
import { isMoment } from 'moment';

import { LiveEventCheckInWrapper } from './AgencyLiveEventCheckIn.styled';
import getKMICourses from './getKMICourses';

const renderListItem = agencyId => item => (
  <List.Item key={item.sessionId}>
    <List.Item.Meta
      title={
        <a
          href={getRoute(
            'administratorAgencyLiveEventCheckInScan',
            { agencyId },
            { sessionId: item.sessionId, name: item.name }
          )}
        >
          <div className="list-title">
            <span>{item.startTime}</span>&nbsp;&nbsp;
            <strong dangerouslySetInnerHTML={{ __html: item.name }} />
          </div>
        </a>
      }
    />
  </List.Item>
);

const LiveEventCheckIn = ({ agencyId, tenantId, timezone }) => {
  const todayMoment = moment().tz(timezone);
  const [date, setDate] = useState(todayMoment);
  const [lastDate, setLastDate] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const onChange = (date, dateString) => {
    setDate(date);
  };

  useEffect(() => {
    if (isMoment(date) && !date.isSame(lastDate)) {
      setLoading(true);
      setLastDate(date);
      getKMICourses({
        tenantId,
        date,
        timezone,
        next: (success, data) => {
          success && setData(data);
          setLoading(false);
        },
      });
    }
  });

  return (
    <Fragment>
      <Row type="flex" justify="end">
        <Col>
          <DatePicker onChange={onChange} disabled={loading} value={date} />
        </Col>
      </Row>
      <Row>
        <List
          header={<h2>Select a Session</h2>}
          dataSource={data}
          renderItem={renderListItem(agencyId)}
          size="large"
        >
          {loading && (
            <Row type="flex" justify="center">
              <Spin />
            </Row>
          )}
        </List>
      </Row>
    </Fragment>
  );
};

const AgencyLiveEventCheckIn = ({ agencyId, dispatch, ...props }) => {
  return (
    <AdministratorWrapper>
      <PageHeader
        title="Live Event Check In"
        goBackTo={getRoute('administratorAgency', { agencyId })}
      />
      <LiveEventCheckInWrapper>
        <LiveEventCheckIn agencyId={agencyId} {...props} />
      </LiveEventCheckInWrapper>
    </AdministratorWrapper>
  );
};

const mapStateToProps = (state, props) => ({
  agencyId: get(props, 'match.params.agencyId'),
  tenantId: get(state, 'session.currentUser.agency.integrationId'),
  timezone: get(state, 'session.currentUser.agency.timezone'),
});

export default withoutClutter(
  DashboardPage(
    mapStateToProps,
    null,
    {}
  )(
    withOwnershipGuard(
      AgencyLiveEventCheckIn,
      'administratorAgencyEventCheckIn'
    )
  )
);
