import React, { Component } from 'react';
import classnames from 'classnames';
import { isPlainObject, get } from 'lodash';
import { connect } from 'react-redux';

import { mapProperties } from '../../../utils/form';
import { mapTitle } from '../../../utils/conditionals';
import templateString from '../../../utils/template-string';

import getOverrides from '../../../utils/get-field-overrides';

import FormItem from '../styled/form-item';
import ConditionsAssert from './conditional';
import { getFormTemplate, getDataEnums } from '../../../selectors/form';
import { getDataSelector } from 'APP_ROOT/utils/renderSchema';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';

const formInterface = {
  getFieldDecorator: name => child => child,
  getFieldValue: name => '',
  setFieldsValue: fields => fields,
};

class FieldGroupItem extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  render() {
    const props = this.props;
    const {
      data = {},
      parentKey = '',
      parentIndex = 0,
      form = formInterface,
      overrides = {},
      properties = [],
      title = '',
      tab = 0,
      isReviewer = false,
      type,
      field_type,
      settings,
      dataKey = '',
      autoResetFieldValues = true,
    } = props;

    const hasParent = !!parentKey;
    const getFieldOverrides = getOverrides(props, overrides);
    const fieldOverrides = getFieldOverrides();
    const options = getFieldOverrides('options', {});
    const conditions = getFieldOverrides('conditions', {});
    const contextPath = hasParent ? `${parentKey}[${parentIndex}].` : '';
    const fieldDataKey = `${contextPath}${dataKey}`;
    const {
      labelCol = { md: 8, sm: 10, xs: 8 },
      mode = 'horizontal',
      showTitle = true,
      wrapperCol = { md: 16, sm: 14, xs: 16 },
      inline = true,
      wrapperHalf = false,
      className: optionClassName,
    } = options;

    const formItemLayout =
      mode === 'horizontal'
        ? {
            labelCol,
            wrapperCol,
          }
        : null;

    const groupData = {
      ...(hasParent ? data[parentKey][parentIndex] : data),
      isReviewer,
    };

    const label = showTitle ? fieldOverrides.title || title || ' ' : null;
    const total = properties.length;
    const className = classnames('bdm-form-field-group', {
      'ant-form-inline': inline,
      [optionClassName]: !!optionClassName,
    });

    const valueFromEnumRef = props.loopFromEnumRef
      ? get(props.dataEnums, `${props.loopFromEnumRef}.${parentIndex}`, '')
      : '';
    const interpolateLabel = label
      ? isPlainObject(label)
        ? mapTitle(label, parentIndex, data, groupData)
        : templateString(label, { index: parentIndex + 1, valueFromEnumRef })
      : '';

    return (
      <ConditionsAssert
        properties={properties}
        type={type}
        field_type={field_type}
        settings={settings}
        conditions={conditions}
        data={groupData}
        wholeData={data}
        parentIndex={parentIndex}
        parentKey={parentKey}
        field={fieldDataKey}
        autoResetFieldValues={autoResetFieldValues}
        render={shouldRender => (
          <FormItem
            className={className}
            colon={false}
            label={interpolateLabel}
            wrapperHalf={wrapperHalf}
            {...formItemLayout}
          >
            {properties.map(
              mapProperties({
                ...props,
                form,
                total,
                tab,
                isReviewer,
                parentIndex,
                parentKey,
              })
            )}
          </FormItem>
        )}
      />
    );
  }
}

const mapState = (state, props) => {
  const dataSelector = getDataSelector(props);
  const formTemplate = getFormTemplate(state, props);

  return {
    data: dataSelector(state, props),
    dataEnums: getDataEnums(state, props, formTemplate),
  };
};

export default connect(mapState)(FieldGroupItem);
