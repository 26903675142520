import styled from 'styled-components';
import { BASE } from '../../config/theme.js';

const Header = styled.div.attrs({
  className: 'bdm-header',
})`
  height: ${props => (props.hasNavidation ? 'auto' : '30px')};
  background-color: ${BASE};
`;

export default Header;
