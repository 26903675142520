import React, { PureComponent } from 'react';
import { Prompt } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import styled from 'styled-components';

import Header from '../../layout/header';
import StyledFooter from '../../layout/styled-footer';

class UpdatingScreen extends PureComponent {
  UNSAFE_componentWillMount() {
    document.body.className = `${document.body.className} no-flow`;
  }

  componentWillUnmount() {
    document.body.className = document.body.className
      .split(' ')
      .filter(cname => cname !== 'no-flow')
      .join(' ');
  }

  render() {
    const { version } = this.props;

    return (
      <FullPage>
        <Layout>
          <Header />
          <StyledLayout>
            <StyledContainer>
              <img
                className="logo"
                src={require('../../../benchmark-logo-full.png')}
                alt="Benchmark Analytics"
              />
              <Prompt
                message={() => `Do not use the system when the update is in progress.
          Do you still want to leave this page?`}
              />
              <Spin size="default" />
              <h2>Updating Benchmark Management System V{version.version}</h2>
              <p>This update may take some time to complete.</p>
              <p>Do not use the system when the update is in progress.</p>
            </StyledContainer>
          </StyledLayout>
          <StyledFooter />
        </Layout>
      </FullPage>
    );
  }
}

const FullPage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 999;
`;

const StyledLayout = styled(Layout)`
  &.ant-layout {
    width: 100%;
    background-color: #fff;
    min-height: auto;
    align-items: center;
    flex-direction: row;
  }
`;

const StyledContainer = styled.div`
  background-color: #fff;
  max-width: 550px;
  padding: 50px 0;
  margin: 0 auto;
  text-align: center;

  .logo {
    width: 100%;
    max-width: 300px;
    display: block;
    margin: 0 auto 30px;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
  }
`;

export default UpdatingScreen;
