import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Icon, Button, Spin, Select, Cascader } from 'antd';
import {
  set,
  get,
  has,
  isEmpty,
  filter,
  isArray,
  uniqBy,
  first,
  debounce,
  findIndex,
} from 'lodash';
import trap from 'mousetrap';
import URLSearchParams from 'url-search-params';

import FormViewer from '../../components/form-viewer';
import ModalTitle from '../../components/common/modal/title';
import ModalBody from '../../components/common/modal/body';
import createModal from '../../actions/create-modal';
import showModal from '../../actions/show-modal';
import hideModal from '../../actions/hide-modal';
import { FORM_TEMPLATES } from '../../constants/form-types';
import postDraftReport from '../../actions/post-draft-report';
import updateDraftReport from '../../actions/update-draft-report';
import getFormActions from '../../actions/get-form-actions';
import postReport from '../../actions/post-report';
import deleteReport from '../../actions/delete-report';
import syncFormValidation from '../../actions/sync-form-validations';
import deleteAllAtachments from '../../actions/delete-all-attachments';
import deleteFormLink from '../../actions/delete-form-link';
import addUserReportSuccess from '../../actions/add-user-report-success';
import withModal from '../../components/common/modal/base';
import FormHistoryTimeline from '../../components/form-history-timeline';
import flattenErrors from '../../utils/flatten-errors';
import transformFormToNormalizedTimeline from '../../utils/transform-form-to-normalized-timeline';
import getNormalizedReviewer from '../../utils/get-reviewer-cascader';
import groupReviewers from '../../utils/group-reviewers';
import {
  getParticipant,
  transformParticipants,
  getDataFromConditions,
  isFinalLinkedSubmission,
  pendingLinkedSubmissions,
} from '../../utils/form';
import route from '../../utils/get-route';

import { GRAY_2 } from '../../config/theme';

import { REQUEST_MORE_INFO } from '../../constants/submit-actions';
import { caseSensitive } from 'APP_ROOT/utils/filters';
import { hasPermissions, PERMISSIONS } from '../../utils/admin';
import generateReportPrintWithAttachments from '../report-type/report-container/generateReportPrintWithAttachments';

import {
  LABEL_DELETE_REPORT_CONTENT,
  LABEL_DELETE_REPORT_TITLE,
} from '../container-labels';

const Option = Select.Option;

// eslint-disable-next-line no-console
const log = type => console.log.bind(console, type);

const deleteReportConfirmModalID = 'vp-delete-request';

const deleteConfirmationModalOptions = {
  title: (
    <ModalTitle warning>
      <Icon type="exclamation-circle" />
      <span> {LABEL_DELETE_REPORT_TITLE} </span>
    </ModalTitle>
  ),
  text: (
    <ModalBody>
      <p> {LABEL_DELETE_REPORT_CONTENT} </p>
    </ModalBody>
  ),
  /**
   * @param {function} onCancelButtonClick
   * @param {function} onConfirmButtonClick
   * @return {function[]}
   */
  buildFooter: (onCancelButtonClick, onConfirmButtonClick) => [
    <Button key="delete-report-button-cancel" onClick={onCancelButtonClick}>
      Cancel
    </Button>,
    <Button
      key="delete-report-button-accept"
      type="primary"
      onClick={onConfirmButtonClick}
    >
      Delete
    </Button>,
  ],
};

class VehiclePursuit extends withModal(PureComponent) {
  static getPageConfig() {
    return {
      title: 'Vehicle Pursuits',
    };
  }

  state = {
    shouldValidate: true,
    selectedReviewer: null,
  };

  whiteListKeys = [
    '__users',
    'currentUserId',
    'submitterId',
    '__reports',
    '__assignedSections',
    '__assignedSectionsHistory',
  ];

  printTriggered = false;
  hasAttachments = false;
  hasNotes = false;

  componentWillMount() {
    const { location } = this.props;

    const shouldValidateFromQuery = () => {
      const query = new URLSearchParams(location.search);
      const validate = query.get('validate');
      return validate !== null ? JSON.parse(validate) : true;
    };

    this.setState({ shouldValidate: shouldValidateFromQuery() });

    this.createModal();

    trap.bind(...this.onPrintEvent());
  }

  componentDidMount() {
    const { selectedForm } = this.props;
    const isPrinting = this.isPrinting();

    if (isPrinting) {
      const template = get(selectedForm, 'template.formSchema.form', {});

      const hasAttachments = obj => {
        const cb = o => o.type === 'upload';

        const asserts = cb(obj);

        if (asserts) {
          return true;
        }

        if (has(obj, 'properties')) {
          return obj.properties.reduce((res, cur) => {
            if (res) {
              return res;
            }

            return hasAttachments(cur);
          }, null);
        }

        return null;
      };

      const hasReviewNotes =
        has(selectedForm, 'meta.notes') &&
        get(selectedForm, 'meta.notes', []).length;
      const hasFormAttachments = hasAttachments(template);

      if (hasFormAttachments || hasReviewNotes) {
        this.hasAttachments = hasFormAttachments;
        this.hasNotes = hasReviewNotes;
      } else {
        this.printTriggered = true;
        window.print();
      }
    }
  }

  componentWillUnmount() {
    this.hideModal();
    this.deleteModal();

    trap.reset();
  }

  printWithAttachments = generateReportPrintWithAttachments(this);

  onPrintEvent = () => {
    const { selectedForm = {} } = this.props;
    const { meta: { id = '', workFlowData = {} } = {} } = selectedForm;

    return [
      ['command+p', 'ctrl+p'],
      e => {
        if (!this.isPrinting() && id && workFlowData.state !== 'initial') {
          e.preventDefault();

          if (this.props.canPrintWithAttachments === true) {
            this.printWithAttachments();
          } else {
            window.open(this.getPrintLink());
          }
          return false;
        }
      },
      'keydown',
    ];
  };

  isPrinting = () => this.props.isPrinting;

  isDraft() {
    const {
      selectedForm: { meta },
    } = this.props;
    return !!meta.draftDate;
  }

  isPersisted() {
    const {
      selectedForm: { meta },
    } = this.props;
    return !!meta.id;
  }

  saveDraft = (cb = () => {}) => {
    const {
      dispatch,
      selectedForm: { meta = {}, data, formFields },
    } = this.props;
    const allFields = formFields.fields.reduce(
      (acc, tab) => ({ ...acc, ...tab }),
      {}
    );
    const transformedData = getDataFromConditions(
      data,
      allFields,
      this.whiteListKeys
    );
    if (meta.id) {
      dispatch(
        updateDraftReport(
          {
            id: meta.id,
            data: { ...meta, data: transformedData },
          },
          cb
        )
      );
    } else {
      dispatch(postDraftReport(transformedData, cb));
    }
  };

  setReviewer = value => {
    const {
      form: { actions },
    } = this.props;
    const allReviewers = uniqBy(
      actions.reduce((res, action) => [...res, ...action.reviewer.list], []),
      'id'
    );
    let reviewerId = isArray(value) ? value[0] : value;
    if (actions.length) {
      this.setState({
        selectedReviewer: allReviewers.find(({ id }) => id + [] === reviewerId),
      });
    }
  };

  setResolution = value => {
    const {
      form: { actions },
    } = this.props;

    if (actions.length) {
      this.setState({
        selectedResolution: actions.find(
          ({ id: actionValue }) => actionValue === value
        ),
        isFinal: true,
      });
    }
  };

  onReview = (
    selectedResolution = {},
    selectedReviewer = {},
    notes,
    fields = {}
  ) => {
    const {
      dispatch,
      history: { push },
      selectedForm: {
        meta: {
          id,
          agencyId,
          formType,
          formNumber,
          linkedSubmissions = [],
        } = {},
        formFields,
        data,
      },
    } = this.props;
    const { label: actionLabel = '', id: action = '' } = selectedResolution;
    const { id: reviewer } = selectedReviewer;
    let transformedData;

    const isLinked = !isEmpty(linkedSubmissions);
    const isFinalLinked = isFinalLinkedSubmission(id, linkedSubmissions);
    const linkedSubmission = linkedSubmissions[0] || {};
    const sendingMoreInfo = action.includes(REQUEST_MORE_INFO);

    const {
      formType: linkedFormType,
      formNumber: linkedFormNumber,
    } = linkedSubmission;

    if (Object.keys(fields).length) {
      const allFields = formFields.fields.reduce(
        (acc, tab) => ({ ...acc, ...tab }),
        {}
      );
      transformedData = {
        ...getDataFromConditions(data, allFields, this.whiteListKeys),
        ...fields,
      };
    }

    dispatch(
      postReport(
        { agencyId, id, reviewer, action, notes, data: transformedData },
        (error, report) => {
          if (error) {
            this.showErrorConfirmation();
            return false;
          }

          const { activeReviewerId = '', participants = [] } = report;

          const transformedParticipants = transformParticipants(participants);
          const activeReviewer =
            getParticipant(activeReviewerId, transformedParticipants) || {};
          const {
            rank: { name: activeReviewerRank = '' } = {},
            fullName: activeReviewerfullName = '',
          } = activeReviewer;

          const [Title, TextSubmit] = (() => {
            if (isLinked) {
              if (isFinalLinked) {
                if (activeReviewerId) {
                  return [
                    <ModalTitle
                      key="7472f470-d8e5-11e9-8a34-2a2ae2dbcce4"
                      success
                    >
                      <Icon type="check-circle" /> <span>Success!</span>
                    </ModalTitle>,
                    <ModalBody key="7472f740-d8e5-11e9-8a34-2a2ae2dbcce4">
                      <p>
                        {`This ${formType} involves a ${linkedFormType} incident ${linkedFormNumber}. Both reports have been submitted to ${activeReviewerRank} ${activeReviewerfullName} for review.`}
                      </p>
                    </ModalBody>,
                  ];
                } else {
                  return [
                    <ModalTitle
                      key="7472f89e-d8e5-11e9-8a34-2a2ae2dbcce4"
                      success
                    >
                      <Icon type="check-circle" /> <span>Success!</span>
                    </ModalTitle>,
                    <ModalBody key="7472fcae-d8e5-11e9-8a34-2a2ae2dbcce4">
                      <p>
                        {`You have reviewed ${formType} ${formNumber} and ${linkedFormType} incident ${linkedFormNumber} that's involved. Both reports are now closed`}
                      </p>
                    </ModalBody>,
                  ];
                }
              } else {
                if (sendingMoreInfo) {
                  return [
                    <ModalTitle
                      key="7472fe02-d8e5-11e9-8a34-2a2ae2dbcce4"
                      success
                    >
                      <Icon type="check-circle" /> <span>Success!</span>
                    </ModalTitle>,
                    <ModalBody key="98cde780-d8e5-11e9-8a34-2a2ae2dbcce4">
                      <p>
                        {`You have reviewed ${formType} ${formNumber}. Please also review the ${linkedFormType} incident ${linkedFormNumber} that's involved.`}
                      </p>
                    </ModalBody>,
                  ];
                } else {
                  return [
                    <ModalTitle key="98cde9f6-d8e5-11e9-8a34-2a2ae2dbcce4">
                      <span>Continue Review</span>
                    </ModalTitle>,
                    <ModalBody key="98cdeb4a-d8e5-11e9-8a34-2a2ae2dbcce4">
                      <p>
                        {`You have reviewed ${formType} ${formNumber}. Please also review the ${linkedFormType} incident ${linkedFormNumber} that's involved.`}
                      </p>
                    </ModalBody>,
                  ];
                }
              }
            } else {
              if (activeReviewerId) {
                return [
                  <ModalTitle
                    key="98cdee7e-d8e5-11e9-8a34-2a2ae2dbcce4"
                    success
                  >
                    <Icon type="check-circle" /> <span>Success!</span>
                  </ModalTitle>,
                  <ModalBody key="98cdefd2-d8e5-11e9-8a34-2a2ae2dbcce4">
                    <p>
                      {`You have reviewed this report as ${actionLabel} and has been sent to ${activeReviewerRank} ${activeReviewerfullName}`}
                    </p>
                  </ModalBody>,
                ];
              } else {
                return [
                  <ModalTitle
                    key="b7ebf8d2-d8e5-11e9-8a34-2a2ae2dbcce4"
                    success
                  >
                    <Icon type="check-circle" /> <span>Success!</span>
                  </ModalTitle>,
                  <ModalBody key="b7ebfb3e-d8e5-11e9-8a34-2a2ae2dbcce4">
                    <p>
                      {`You have reviewed this report as ${actionLabel} and the report is now closed`}
                    </p>
                  </ModalBody>,
                ];
              }
            }
          })();

          const TextRequest = (
            <ModalBody>
              <p>
                You have sent this report back to{' '}
                {`${activeReviewerRank} ${activeReviewerfullName}`} for more
                information
              </p>
            </ModalBody>
          );

          const MoreInfoLinked = (
            <ModalBody>
              <div className="linked-content">
                <p>
                  You have Requested More Info for{' '}
                  {`${activeReviewerRank} ${activeReviewerfullName} ${formType}`}{' '}
                  report. This report involves a {linkedFormType}{' '}
                  {linkedFormNumber}.
                </p>
                <p>
                  Both reports will be returned to{' '}
                  {`${activeReviewerRank} ${activeReviewerfullName}`}.
                </p>
              </div>
            </ModalBody>
          );

          const MoreInfo = isLinked ? MoreInfoLinked : TextRequest;

          const options = {
            id: 'vp-success',
            title: Title,
            children: !sendingMoreInfo ? TextSubmit : MoreInfo,
            hideAll: true,
          };

          dispatch(createModal(options));

          setTimeout(() => {
            if (sendingMoreInfo) {
              push('/reports');
              dispatch(showModal(options.id));
            } else {
              if (isLinked && !isFinalLinked) {
                const { id: linkedFormId } = linkedSubmission;
                push(`/reports/${linkedFormId}`);
                this.updateModal(options);
                this.showModal(options.id);
              } else {
                push('/reports');
                dispatch(showModal(options.id));
              }
            }
          }, 500);
        }
      )
    );
  };

  setErrorState = async errors => {
    const { dispatch } = this.props;
    const errs = Object.keys(errors).reduce(
      (acc, k) => set(acc, k, { errors: errors[k] }),
      {}
    );
    const flattenedErrors = flattenErrors(errs);
    dispatch(syncFormValidation(flattenedErrors));
  };

  showErrorConfirmation = (message = '') => {
    const ErrorTitle = (
      <ModalTitle error>
        <Icon type="exclamation-circle" /> <span>Heads up!</span>
      </ModalTitle>
    );

    const ErrorText = (
      <ModalBody>
        {message ||
          `Some of the required fields are empty or were not answered correctly.
        Please double-check and submit again.`}
      </ModalBody>
    );

    this.createModal({
      visible: true,
      title: ErrorTitle,
      children: ErrorText,
    });

    this.showModal();
  };

  getSuccessLinkedContent = (
    currentForm = {},
    linkedForm = {},
    reviewer = {}
  ) => {
    const { formType: currentFormType } = currentForm;
    const {
      formType: linkedFormType,
      formNumber: linkedFormNumer,
    } = linkedForm;
    const rankName = get(reviewer, ['rank', 'name'], '');
    const fullName = get(reviewer, ['fullName'], '');
    return (
      <ModalBody>
        <p>
          {`This ${currentFormType} involves a ${linkedFormType} incident ${linkedFormNumer}.`}
        </p>
        <p>
          Both reports have been submitted to{' '}
          <strong>{`${rankName} ${fullName}`}</strong> for review.
        </p>
      </ModalBody>
    );
  };

  onSave = debounce(() => {
    const {
      dispatch,
      history: { push },
      selectedForm: {
        meta: { agencyId, id, linkedSubmissions = [] },
        data,
        formFields,
      },
      currentUser: { id: currentuserId = '' } = {},
    } = this.props;
    const {
      selectedReviewer = {},
      isFinal = false,
      selectedResolution = {},
      actions = [],
    } = this.state;

    dispatch(addUserReportSuccess(selectedReviewer));
    this.updateModal({
      footer: this.modalButtons({ isLoading: true }),
    });
    const users = {
      ...get(data, '__users', {}),
      [selectedReviewer.id]: {
        ...selectedReviewer,
      },
    };

    const finalData = {
      ...data,
      __users: users,
    };

    const isLinked = !isEmpty(linkedSubmissions);
    const isFinalLinked = isFinalLinkedSubmission(id, linkedSubmissions);
    const linkedSubmission = linkedSubmissions[0] || {};

    const { id: reviewerId, firstName, lastName } = selectedReviewer || {};
    const { id: resolutionValue, label: resolutionText } =
      selectedResolution || {};

    const allFields = formFields.fields.reduce(
      (acc, tab) => ({ ...acc, ...tab }),
      {}
    );

    let reviewer = reviewerId;
    let action;

    if (isEmpty(selectedReviewer) && !isFinalLinked) {
      return;
    }

    if (isLinked && isFinalLinked) {
      const { actions = [] } = linkedSubmission;
      reviewer = get(first(actions), 'metadata.workflowData.reviewers');
      action = get(first(actions), 'metadata.workflowData.action.value');
    } else if (isFinal) {
      reviewer = currentuserId;
      action = resolutionValue;
    } else {
      action = actions.reduce((res, _action) => {
        if (_action.reviewers.includes(reviewer)) {
          return _action.id;
        }

        return res;
      }, action);
    }

    const transformedData = getDataFromConditions(
      {
        ...finalData,
        isReviewer: !this.isEditable(),
      },
      allFields,
      this.whiteListKeys
    );

    dispatch(
      postReport(
        { agencyId, id, reviewer, action, data: transformedData },
        async (error, report) => {
          const { response } = error;
          if (error) {
            const errorJson = await response.json();
            const editedErrors = get(
              errorJson,
              'error.errors',
              get(errorJson, 'error.message', '')
            );
            await this.setErrorState(editedErrors);
            this.hideModal();
            this.showErrorConfirmation();
            return false;
          }
          const isLinked = !isEmpty(linkedSubmissions);
          const { activeReviewerId, participants = [] } = report;
          const reviewer = participants.length
            ? participants.filter(
                participant => participant.id === activeReviewerId
              )
            : participants[0];
          const activeReviewer = reviewer[0];

          const Title = (
            <ModalTitle success>
              <Icon type="check-circle" /> <span>Success!</span>
            </ModalTitle>
          );

          let Text = (
            <ModalBody>{`Your Vehicle Pursuit report has been submitted to ${get(
              selectedReviewer,
              'rank.name',
              ''
            )} ${firstName} ${lastName} for review`}</ModalBody>
          );
          if (isLinked && isFinalLinked && activeReviewerId) {
            Text = this.getSuccessLinkedContent(
              report,
              linkedSubmission,
              activeReviewer
            );
          } else if (isFinalLinked && !activeReviewerId) {
            const {
              formType: linkedFormType,
              formNumber: linkedFormNumber,
            } = linkedSubmission;
            const {
              selectedForm: { meta: { formType } = {} },
            } = this.props;
            Text = (
              <ModalBody>
                <p>
                  This {formType} involves a {linkedFormType} {linkedFormNumber}
                  .
                </p>
                <p>
                  You have submitted both reports. Both reports are now closed
                </p>
              </ModalBody>
            );
          } else if (isFinal) {
            Text = (
              <ModalBody>{`You have submitted this report as ${resolutionText} and the report is now closed`}</ModalBody>
            );
          }

          const options = {
            id: 'vp-success',
            title: Title,
            children: Text,
          };

          dispatch(createModal(options));
          dispatch(
            deleteAllAtachments({
              kind: FORM_TEMPLATES['vehicle-pursuits'],
              formId: id,
            })
          );

          setTimeout(() => {
            if (isLinked && !isFinalLinked) {
              const { id: linkedFormId } = linkedSubmissions[0] || {};
              push(`/reports/${linkedFormId}`);
            } else {
              push('/reports');
              dispatch(showModal(options.id));
            }
          }, 500);
        }
      )
    );
  }, 500);

  onCancel = () => {
    this.setState({ selectedReviewerId: '' });
    this.hideModal();
  };

  modalButtons = ({
    isDisable = false,
    isLoading = false,
    cancelLabel = 'Cancel',
    submitLabel = 'Submit',
  } = {}) => [
    <Button
      key="cancel"
      size="default"
      onClick={this.onCancel}
      disabled={isDisable}
    >
      {cancelLabel}
    </Button>,
    <Button
      key="submit"
      type="primary"
      size="default"
      disabled={isDisable}
      loading={isLoading}
      onClick={this.onSave}
    >
      {submitLabel}
    </Button>,
  ];

  /**
   * Triggers on cancel delete confirmation modal.
   */
  onCancelDeleteConfirmationModalClick = () => {
    const { dispatch } = this.props;
    dispatch(hideModal(deleteReportConfirmModalID));
  };

  /**
   * Triggers on confirmation modal click.
   */
  onAcceptConfirmationModalClick = () => {
    const {
      dispatch,
      history: { push },
      selectedForm: { meta },
    } = this.props;
    const { linkedSubmissions = [] } = meta;
    const { id: linkedSubmissionId } = linkedSubmissions[0] || {};
    const resetKeys = [
      'isVehiclePursuitInvolved',
      'vehiclePursuitTrackingNumber',
    ];
    dispatch(
      deleteFormLink(
        {
          formId: linkedSubmissionId,
          resetRemoteKeys: resetKeys,
          saveLocal: false,
        },
        () => {
          dispatch(
            deleteReport(meta.agencyId, meta.id, () => {
              dispatch(hideModal(deleteReportConfirmModalID));
              // This timeout is used here, to prevent a flick effect when the modal
              // animation starts.
              setTimeout(() => push('/reports'), 300);
            })
          );
        }
      )
    );
  };

  /**
   * On Header Toolbar Delete Button Click.
   * Shows the delete report modal.
   */
  onDeleteHeaderButtonClick = () => {
    const { dispatch } = this.props;
    const options = {
      id: deleteReportConfirmModalID,
      title: deleteConfirmationModalOptions.title,
      children: deleteConfirmationModalOptions.text,
      footer: deleteConfirmationModalOptions.buildFooter(
        this.onCancelDeleteConfirmationModalClick,
        this.onAcceptConfirmationModalClick
      ),
    };
    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  updateDraft = cb => {
    this.saveDraft((error, report) => {
      if (error) {
        this.showErrorConfirmation(
          `Something went wrong. We cannot process your request right now; please try again later.`
        );
      }

      cb && typeof cb === 'function' && cb(error, report);
    });
  };

  formButtons = () => {
    const { selectedForm } = this.props;
    const { meta: { id, isDeletable = false } = {} } = selectedForm;

    const deleteDraft = {
      icon: require('../../icons/bt_delete.svg'),
      title: 'Delete',
      onButtonClick: () => this.onDeleteHeaderButtonClick,
    };
    const print = {
      icon: require('../../icons/bt_print.svg'),
      title: 'print',
      onButtonClick: () => this.printWithAttachments,
    };

    const printSelf = {
      icon: require('../../icons/bt_print.svg'),
      title: 'print',
      onButtonClick: () => this.printWithAttachments,
    };

    if (this.isPrinting()) {
      return [printSelf];
    }

    if (!id) {
      return [];
    }

    if (isDeletable) {
      if (pendingLinkedSubmissions(selectedForm)) {
        return [];
      }

      return [deleteDraft];
    }

    return [print];
  };

  reviewFormButtons = () => {
    const history = {
      icon: require('../../icons/ic_history.svg'),
      title: 'History',
      onButtonClick: () => this.viewHistory,
    };

    const print = {
      icon: require('../../icons/bt_print.svg'),
      title: 'print',
      onButtonClick: () => this.printWithAttachments,
    };

    const printSelf = {
      icon: require('../../icons/bt_print.svg'),
      title: 'print',
      onButtonClick: () => this.printWithAttachments,
    };

    if (this.isPrinting()) {
      return [printSelf];
    }

    return [history, print];
  };

  getReviewersModalOptions = () => ({
    title: 'Select Reviewer',
    children: (
      <div className="text-center">
        <Spin />
      </div>
    ),
    footer: this.modalButtons(),
  });

  getLinkedContent = (currentForm = {}, linkedForm = {}) => {
    const { formType: currentFormType } = currentForm;
    const {
      formType: linkedFormType,
      formNumber: linkedFormNumber,
    } = linkedForm;
    return (
      <div className="linked-content">
        <p>
          This {currentFormType} involves a {linkedFormType} {linkedFormNumber}.
        </p>
        <p>Both reports will be sent for review together.</p>
        <p>Please select reviewer to proceed.</p>
      </div>
    );
  };

  onSubmit = async values => {
    const {
      dispatch,
      history: { push },
      selectedForm: { meta, data, state, formFields },
    } = this.props;
    const { shouldValidate } = this.state;
    const { linkedSubmissions = [] } = meta;

    let currentFormSubmission = {};
    const allFields = formFields.fields.reduce(
      (acc, tab) => ({ ...acc, ...tab }),
      {}
    );
    const transformedData = getDataFromConditions(
      data,
      allFields,
      this.whiteListKeys
    );
    let serverError;

    if (meta.id) {
      Object.assign(currentFormSubmission, meta);
    } else {
      await dispatch(
        postDraftReport(transformedData, (error, result) => {
          serverError = error;

          if (!error) {
            Object.assign(currentFormSubmission, result);
          }
        })
      );
    }

    if (serverError) {
      this.showErrorConfirmation(
        `Something went wrong. We cannot process your request right now; please try again later.`
      );
      return false;
    }

    const { id, agencyId } = currentFormSubmission;

    const hasErrors = !!Object.entries(state).filter(
      ([k, v]) => v.errors && v.errors.length
    ).length;
    if (hasErrors && shouldValidate) {
      this.showErrorConfirmation(
        `Something went wrong. We cannot process your request right now; please try again later.`
      );
      return false;
    }

    dispatch(
      getFormActions(
        {
          agencyId,
          formSubmissionId: id,
        },
        (error, reviewActions = {}) => {
          const { actions = [], resolutionLabel } = reviewActions;
          const sendingMoreInfo = actions.every(
            a => a.reviewer.list.length === 0
          );
          const isFinal = actions.some(a => a.isFinal);
          const isLinked = !isEmpty(linkedSubmissions);
          const isFinalLinked = isFinalLinkedSubmission(id, linkedSubmissions);
          const linkedSubmission = linkedSubmissions[0] || {};
          const allReviewers = actions.reduce(
            (res, action) => [...res, ...action.reviewer.list],
            []
          );
          const groupedReviewers = groupReviewers(uniqBy(allReviewers, 'id'));

          if (isLinked && isFinalLinked) {
            this.onSave();
          } else if (isFinal) {
            this.updateModal({
              ...this.getReviewersModalOptions(),
              title: `Select a ${resolutionLabel || 'Resolution'}`,
              children: (
                <div className="text-center">
                  <Select
                    style={{ width: '80%' }}
                    onChange={this.setResolution}
                    placeholder="Please Select"
                  >
                    {actions.map(action => (
                      <Option key={action.id} value={action.id}>
                        {action.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              ),
              footer: this.modalButtons(),
            });
            this.showModal();
          } else if (sendingMoreInfo) {
            dispatch(
              postReport(
                { agencyId, id, data: transformedData },
                async (error, report) => {
                  const { response } = error;
                  if (error) {
                    const errorJson = await response.json();
                    const editedErrors = get(
                      errorJson,
                      'error.errors',
                      get(errorJson, 'error.message', '')
                    );
                    await this.setErrorState(editedErrors);
                    this.hideModal();
                    this.showErrorConfirmation();
                    return false;
                  }

                  const { activeReviewerId, participants = [] } = report;
                  const reviewer = participants.length
                    ? participants.filter(
                        participant => participant.id === activeReviewerId
                      )
                    : participants[0];
                  const {
                    firstName: reviewerFirstName,
                    lastName: reviewerLastName,
                    rank: { name: rankName = '' } = {},
                  } = reviewer[0] || {};

                  const Title = (
                    <ModalTitle success>
                      <Icon type="check-circle" /> <span>Success!</span>
                    </ModalTitle>
                  );

                  let Text = (
                    <ModalBody>
                      <p>
                        Your Vehicle Pursuits report has been submitted to{' '}
                        {`${rankName} ${reviewerFirstName} ${reviewerLastName}`}{' '}
                        for review.
                      </p>
                    </ModalBody>
                  );

                  const options = {
                    id: 'vp-submit-success',
                    title: Title,
                    children: Text,
                  };

                  dispatch(createModal(options));

                  setTimeout(() => {
                    if (isLinked && !isFinalLinked) {
                      const { id: linkedFormId } = linkedSubmission;
                      push(`/reports/${linkedFormId}`);
                    } else {
                      push('/reports');
                      dispatch(showModal(options.id));
                    }
                  }, 500);
                }
              )
            );
          } else {
            this.updateModal({
              ...this.getReviewersModalOptions(),
              children: (
                <ReviewerWrapper>
                  {isLinked &&
                    this.getLinkedContent(meta, linkedSubmissions[0])}
                  <div className="reviewer-select">
                    {!isLinked && <p>You must select one reviewer to Submit</p>}
                    <Cascader
                      style={{ width: '300px', margin: '0 auto' }}
                      options={groupedReviewers}
                      onChange={(value, options) => {
                        if (value.length) {
                          this.setReviewer(
                            getNormalizedReviewer(value, options)
                          );
                          this.updateModal({
                            footer: this.modalButtons({ isDisable: false }),
                          });
                        } else {
                          this.updateModal({
                            footer: this.modalButtons({ isDisable: true }),
                          });
                        }
                      }}
                      placeholder="Please select"
                      displayRender={label => label.join(' > ')}
                      showSearch={{ filter: caseSensitive }}
                    />
                  </div>
                </ReviewerWrapper>
              ),
              footer: isLinked
                ? this.modalButtons({
                    submitLabel: 'Continue',
                    isDisable: true,
                  })
                : this.modalButtons({ isDisable: true }),
            });

            this.setState(
              {
                actions: actions.reduce(
                  (res, action) => [
                    ...res,
                    {
                      ...action,
                      reviewers: action.reviewer.list.map(
                        reviewer => reviewer.id
                      ),
                    },
                  ],
                  []
                ),
              },
              () => {
                this.showModal();
              }
            );
          }
        }
      )
    );
  };

  viewHistory = () => {
    const { selectedForm, timezone } = this.props;
    const {
      meta: { participants: metaParticipants },
    } = selectedForm;
    let snapshotUsers = [];
    let metaCustom = {};
    JSON.stringify(get(selectedForm, 'data.__users', {}), function(key, value) {
      snapshotUsers = Object.values(value);
    });
    // The User data from the snapshot table will overwrite the current user data
    // from the benchmark user takble, only if there is id match
    // Data from cache is used prioritized
    for (const metaParticipant of metaParticipants) {
      if (findIndex(snapshotUsers, { id: metaParticipant.id }) === -1) {
        snapshotUsers.push(metaParticipant);
      }
    }
    metaCustom = {
      ...get(selectedForm, 'meta', {}),
      participants: snapshotUsers,
    };

    const normalizedRecord = transformFormToNormalizedTimeline(
      metaCustom,
      timezone
    );

    const Title = (
      <ModalTitle>
        <span>Timeline</span>
      </ModalTitle>
    );

    const Body = (
      <ModalBody>
        <FormHistoryTimeline {...normalizedRecord} />
      </ModalBody>
    );

    this.createModal({
      visible: true,
      title: Title,
      children: Body,
    });

    this.showModal();
  };

  getPrintLink = () => {
    const { selectedForm } = this.props;

    return route('reportsViewAs', {
      id: get(selectedForm, ['meta', 'id'], '404'),
      viewAs: 'print',
    });
  };

  isEditable = () => {
    const {
      selectedForm,
      currentUser: { permissions },
    } = this.props;
    const isReadOnly = hasPermissions(permissions, PERMISSIONS.viewAllReports);
    const { meta: { isEditMode = true } = {}, actions = [] } = selectedForm;

    return (
      !isReadOnly &&
      isEditMode &&
      filter(actions, action => action.status === 'pending').length === 0
    );
  };

  onAttachmentsFetched = () => {
    if (!this.isPrinting()) {
      return false;
    }

    if (!this.printTriggered) {
      this.attachmentsLoaded = true;

      if ((this.hasNotes && this.reviewNotesLoaded) || !this.hasNotes) {
        this.printTriggered = true;
        window.print();
      }
    }
  };

  onNotesFetched = () => {
    if (!this.isPrinting()) {
      return false;
    }

    if (!this.printTriggered) {
      this.reviewNotesLoaded = true;

      if (
        (this.hasAttachments && this.attachmentsLoaded) ||
        !this.hasAttachments
      ) {
        this.printTriggered = true;
        window.print();
      }
    }
  };

  render() {
    const {
      selectedForm,
      dispatch,
      error,
      session: { currentUser = {} } = {},
      app,
      attachments,
      loading,
      timezone,
      form: { templates = [] } = {},
    } = this.props;

    const { shouldValidate } = this.state;

    const { permissions = [] } = currentUser;
    const canSubmit = permissions.includes('submitVPReport');
    if (error) {
      return <h3>Server error</h3>;
    }

    const { id: currentUserId = '' } = currentUser;
    const {
      meta: { activeReviewerId = '', id = '', isEditMode = true } = {},
    } = selectedForm;

    const isEditable = this.isEditable();

    const canReview = !isEditMode && currentUserId === activeReviewerId;

    const isPrinting = this.isPrinting();

    const formViewerProps = {
      app,
      attachments,
      selectedForm,
      dispatch,
      user: currentUser,
      canSubmit,
      shouldValidate,
      onError: log('errors'),
      onSaveDraft: this.updateDraft,
      isPrinting,
      loading,
      timezone,
      onAttachmentsFetched: this.onAttachmentsFetched,
      onNotesFetched: this.onNotesFetched,
      ...(id
        ? {
            isReviewer: !isEditable,
            canReview,
            actions: !isEditable
              ? this.reviewFormButtons()
              : this.formButtons(),
            onSubmit: isEditable ? this.onSubmit : this.onReview,
          }
        : {
            actions: this.formButtons(),
            onSubmit: this.onSubmit,
          }),
      ...(isPrinting
        ? {
            canSubmit: false,
            canReview: false,
            isReviewer: true,
            onSubmit: () => {},
          }
        : {}),
      templates,
    };

    return (
      <PageWrapper>
        <FormViewer {...formViewerProps} />
      </PageWrapper>
    );
  }
}

const PageWrapper = styled.div`
  position: inherit;
`;

const ReviewerWrapper = styled.div`
  padding: 10px 0;
  width: 400px;
  margin: 0 auto;

  .linked-content {
    color: ${GRAY_2};
    padding-bottom: 20px;
  }

  .reviewer-select {
    width: 300px;
    margin: 0 auto;

    p {
      margin-bottom: 15px;
    }
  }
`;

export default VehiclePursuit;
