import React, { Component } from 'react';

import { Button, Row, Col, Pagination, Icon } from 'antd';
import styled from 'styled-components';
import {
  has,
  kebabCase,
  uniqBy,
  find,
  reverse,
  get,
  castArray,
  uniq,
} from 'lodash';

// Actions
import getReports from '../../actions/get-reports';
// Containers
import DashboardPage from '../../components/dashboard';
// Components
import ReportCard from '../../components/report-card';
import MainTitle from '../../components/common/main-title';
import FormFilter from '../../components/common/form-filter';
import setPageRetention from './utils/setPageRetention';
import getPageRetention from './utils/getPageRetention';

import { getAgencyTZ } from '../../selectors/session';
import emitter, { EventTypes } from '../../utils/eventEmitter';
import retrieveAgencyTemplates from '../../actions/utils/get-form/retrieveAgencyTemplates';
import getReportsTemplates from '../../actions/get-reports-templates';
import { hasPermissions } from 'APP_ROOT/utils/admin';
import { Link } from 'react-router-dom';
import { compileFilterForIntakeStatus } from 'APP_ROOT/components/common/form-filter/filters/intake-status';
import { PERMISSIONS } from '../../utils/admin';
import resetOnBehalf from '../../actions/reset-onBehalf';
import setReportListFilters from '../../actions/set-report-list-filters';

const analyticsIcon = require('../../icons/ic_analytics_reports.svg');

const analyticsIconStyle = {
  width: 18,
  display: 'inline-block',
  verticalAlign: 'middle',
  marginLeft: 8,
};

// IMPORTANT:
// This page code is TRIPLICATED, any change here should be
// done in this replicated in the 3 pages:
// src/containers/reports/index.js
// src/containers/administrator/agency-reports/Reports/ListOfReports.js
// src/containers/reports/uiWithRefactoredEndpoint.js

class ReportsRefactored extends Component {
  state = {
    formType: '',
    mainFilter: [],
    dataFilter: [],
    keywords: '',
    delayCompleted: false,
    loadingTemplates: true,
  };

  static getPageConfig() {
    return {
      title: 'Reports',
    };
  }

  async componentDidMount() {
    const { dispatch, templates } = this.props;

    await retrieveAgencyTemplates({
      dispatch,
      fallback: templates,
      shouldFetch: () => true,
    });

    this.setState({ loadingTemplates: false });

    await this.waitAtLeastPromise();
    this.setState({ delayCompleted: true });
  }

  waitAtLeastPromise = (ms = 1000) =>
    new Promise(resolve => {
      global.setTimeout(resolve, ms);
    });

  onNewReportClick = () => {
    const { dispatch } = this.props;
    dispatch(resetOnBehalf());
    emitter.emit(EventTypes.CREATE_REPORT);
  };

  getReportsTemplate = (error, reports) => {
    const { templates, dispatch } = this.props;
    const templatesArray = Object.values(templates);

    if (!error) {
      const templateList = reports.map(
        ({ formType, templateId, templateVersionId }) => ({
          formType,
          templateId,
          templateVersionId,
          exists:
            has(templates, kebabCase(formType)) &&
            find(templatesArray, item => item.versionId === templateVersionId),
        })
      );

      const nonExistentTemplates = templateList
        .filter(templateItem => !templateItem.exists)
        .map(({ templateId }) => templateId);

      if (nonExistentTemplates.length) {
        const filteredTemplates = templatesArray.filter(({ id: templateId }) =>
          nonExistentTemplates.includes(templateId)
        );

        dispatch(
          getReportsTemplates({
            getSchemaFor: filteredTemplates,
            withTemplates: templatesArray,
          })
        );
      }
    }
  };

  updateStateFilters = (
    mainFilters = [],
    dataFilters = [],
    keywords,
    clearDataFilters = false,
    view
  ) => {
    const {
      mainFilter,
      dataFilter,
      keywords: keywordsFilter,
      view: viewFilter,
    } = this.state;

    const uniqMainFilter = uniqBy(
      reverse([...mainFilter, ...mainFilters]),
      'column'
    );
    const uniqDataFilter = uniqBy(
      reverse([...dataFilter, ...dataFilters]),
      'column'
    );
    const { type: formTypeValue = '' } =
      find(uniqMainFilter, ['column', 'formType']) || {};

    this.setState(
      {
        formType: formTypeValue,
        mainFilter: uniqMainFilter,
        dataFilter: clearDataFilters
          ? uniqDataFilter.filter(x => x.column === 'submitted_at')
          : uniqDataFilter,
        keywords: keywords !== undefined ? keywords : keywordsFilter,
        view: view !== undefined ? view : viewFilter,
      },
      this.updateFilters
    );
  };

  clearFilterColumn = (filterName, columnNames) => {
    const { formType } = this.state;

    const columns = castArray(columnNames);
    const currentFilter = castArray(get(this.state, filterName, []));

    const cleanedFilter = currentFilter.filter(
      filter => !columns.includes(filter.column)
    );

    this.setState(
      {
        [filterName]: cleanedFilter,
        formType: columns.includes('formType') ? '' : formType,
      },
      this.updateFilters
    );
  };

  clearKeywords = () => {
    this.setState(
      {
        keywords: '',
      },
      this.updateFilters
    );
  };

  updateFilters = async () => {
    const { dispatch, history } = this.props;
    const {
      mainFilter: uncompileMainFilter,
      dataFilter,
      keywords = '',
      view = '',
    } = this.state;

    const currentPage = getPageRetention(history);
    const mainFilter = compileFilterForIntakeStatus(uncompileMainFilter);
    dispatch(
      setReportListFilters({
        mainFilter: mainFilter,
        dataFilter: dataFilter,
        keywords: keywords,
        view: view,
        page: currentPage,
      })
    );
    this.setState({ delayCompleted: false });
    dispatch(
      getReports(
        currentPage,
        mainFilter,
        dataFilter,
        keywords,
        '',
        async () => {
          await this.waitAtLeastPromise(500);
          this.setState({ delayCompleted: true });
        },
        view
      )
    );
  };

  getReportCards = () => {
    const {
      reportList = {},
      session: {
        currentUser: {
          userId,
          agency: { timezone },
        },
      } = {},
      total = 0,
      history,
      templates,
      officers = [],
      reportOrderedList = [],
      loadingReports,
    } = this.props;
    const { delayCompleted } = this.state;

    if (loadingReports || !delayCompleted) {
      return <ReportCard loading showSecondLoadingLine />;
    }

    return total > 0 ? (
      reportOrderedList.map(reportId => {
        const report = get(reportList, reportId, null);

        if (!report) {
          return null;
        }

        return (
          <ReportCard
            key={reportId}
            history={history}
            report={report}
            currentUser={userId}
            timezone={timezone}
            templates={templates}
            officers={officers}
          />
        );
      })
    ) : (
      <StyledNoResults>0 results</StyledNoResults>
    );
  };

  createReport = e => {
    const {
      history: { push },
    } = this.props;

    push('/use-of-force/new');
  };

  changePage = page => {
    const { dispatch, history } = this.props;
    const { mainFilter, dataFilter, keywords } = this.state;

    setPageRetention(page, history);
    window.scrollTo(0, 0);

    dispatch(setReportListFilters({ page: page }));
    dispatch(
      getReports(
        page,
        mainFilter,
        dataFilter,
        keywords,
        '',
        this.getReportsTemplate
      )
    );
  };

  render() {
    const {
      session: {
        currentUser: { permissions = [] },
      },
      total = 0,
      per_page = 8,
      page = 1,
      dispatch,
      templates,
      loadingReports,
      timezone,
      history,
    } = this.props;
    const {
      formType,
      mainFilter,
      dataFilter,
      loadingTemplates,
      delayCompleted,
    } = this.state;
    const canSubmit = hasPermissions(permissions, [
      'submitForm',
      PERMISSIONS.submitUOFReport,
      PERMISSIONS.submitVPReport,
      PERMISSIONS.submitDORReport,
      PERMISSIONS.submitIACReport,
      PERMISSIONS.submitIAIReport,
      PERMISSIONS.submitReport,
    ]);

    const canSeeIzenda = hasPermissions(permissions, [
      PERMISSIONS.accessAnalyticsReports,
    ]);

    const canSeeIzendaReportDesigner = hasPermissions(permissions, [
      'accessAnalyticsDesigner',
      PERMISSIONS.accessAnalyticsDesign,
    ]);

    return (
      <div>
        <Row>
          <Col span={12} className="text-left">
            <MainTitle>
              Reports{' '}
              {(loadingReports || !delayCompleted) && <Icon type="loading" />}
            </MainTitle>
          </Col>
          <Col span={12} className="text-right">
            {canSeeIzenda && (
              <Link to="/canned-reports">
                <StyledButton type="primary" size="default" ghost>
                  Analytics Viewer{' '}
                  <img
                    src={analyticsIcon}
                    alt="Analytics Viewer"
                    style={analyticsIconStyle}
                  />
                </StyledButton>
              </Link>
            )}{' '}
            {canSeeIzendaReportDesigner && (
              <Link to="/report-designer">
                <StyledButton type="primary" size="default" ghost>
                  Analytics Designer{' '}
                  <img
                    src={analyticsIcon}
                    alt="Analytics Designer"
                    style={analyticsIconStyle}
                  />
                </StyledButton>
              </Link>
            )}{' '}
            {canSubmit && (
              <Button
                type="primary"
                size="default"
                icon="plus"
                disabled={loadingTemplates}
                loading={loadingTemplates}
                onClick={this.onNewReportClick}
              >
                Create New Report
              </Button>
            )}
          </Col>
        </Row>

        <FormFilter
          action={dispatch}
          mainFilter={mainFilter}
          dataFilter={dataFilter}
          formType={formType}
          formTemplates={templates}
          onChangeFilter={this.updateStateFilters}
          clearFilterColumn={this.clearFilterColumn}
          clearKeywords={this.clearKeywords}
          timezone={timezone}
          loadingReports={loadingReports}
          history={history}
        />

        <StyledRow>
          <Col span={24}>{this.getReportCards()}</Col>
        </StyledRow>

        {total > 0 && (
          <StyledRowPagination>
            <Col span={24} className="text-right">
              <Pagination
                defaultCurrent={1}
                current={page}
                total={total}
                pageSize={per_page}
                onChange={this.changePage}
              />
            </Col>
          </StyledRowPagination>
        )}
      </div>
    );
  }
}

const StyledNoResults = styled.div`
  text-align: center;
  padding: 50px 0;
  color: #c7c7c7;
  font-size: 2em;
`;
const StyledRow = styled(Row)`
  margin-top: 16px;
`;
const StyledRowPagination = styled(Row)`
  margin-top: 16px;
`;
const StyledButton = styled(Button)`
  margin-right: 5px;
`;

const mapState = state => {
  const {
    report: {
      data: reportList,
      fetched,
      total,
      per_page,
      page,
      officers,
      list = [],
      loading,
    },
    form,
  } = state;
  const templatesFetched = get(form, 'templatesMeta.fetched', false);

  return {
    fetched,
    reportList,
    reportOrderedList: uniq(list),
    total,
    per_page,
    page,
    officers,
    timezone: getAgencyTZ(state),
    loadingReports: loading,
    templatesFetched,
  };
};

export default DashboardPage(mapState, null)(ReportsRefactored);
