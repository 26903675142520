import React, { Component } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import parseDate from '../../utils/parse-date';
import MainTitle from '../../components/common/main-title';
import TrainingReport from '../../components/dashboard/training-report-panel';

class Training extends Component {
  getCertificationTitles = (courses = {}) => {
    const nonFirearmCertifications = get(
      courses,
      'nonFirearmCertifications',
      []
    );

    return nonFirearmCertifications
      .reduce((prev, nxt) => [...prev, get(nxt, 'course', 'name', '')], [])
      .filter(v => !!v);
  };

  getLatestUpcomingCourse = (courses = {}) => {
    const latestUpcomingCourse = get(courses, 'latestUpcomingCourse', null);

    if (!latestUpcomingCourse) return {};

    return {
      id: latestUpcomingCourse.id,
      status: latestUpcomingCourse.statusName,
      name: latestUpcomingCourse.course.name,
    };
  };

  getLastFirearmCertification = (courses = {}, timezone = '') => {
    const lastFirearmCertification = get(
      courses,
      'lastFirearmCertification',
      null
    );

    if (!lastFirearmCertification) return {};

    return {
      id: lastFirearmCertification.id,
      date: parseDate(
        lastFirearmCertification.lastLaunchDate,
        timezone,
        'MM/DD/YYYY'
      ),
      status: lastFirearmCertification.status,
      title: lastFirearmCertification.course.name,
    };
  };

  render() {
    const {
      selectedUser: { courses = [], data = {} } = {},
      permissions = [],
    } = this.props;
    return (
      <div>
        <MainTitle>Training</MainTitle>
        <TrainingReport
          user={data}
          permissions={permissions}
          nonFirearmCertifications={this.getCertificationTitles(courses)}
          latestUpcomingCourse={this.getLatestUpcomingCourse(courses)}
          lastFiremarmCertification={this.getLastFirearmCertification(
            courses,
            data.agency.timezone
          )}
        />
      </div>
    );
  }
}

const mapState = state => {
  const {
    selectedUser,
    session: { currentUser: { permissions = [] } = {} } = {},
  } = state;
  return {
    selectedUser,
    permissions,
  };
};

export default connect(mapState)(Training);
