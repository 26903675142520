import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Icon, Modal } from 'antd';
import get from 'lodash/get';
import has from 'lodash/has';
import kebabCase from 'lodash/kebabCase';
import first from 'lodash/first';
import keys from 'lodash/keys';
import isPlainObject from 'lodash/isPlainObject';
import template from 'lodash/template';
import unescape from 'lodash/unescape';
import castArray from 'lodash/castArray';

import syncFormReviewData from 'APP_ROOT/actions/sync-form-review-data';
import addRepeaterItem from 'APP_ROOT/actions/add-repeater-item';
import removeRepeaterItem from 'APP_ROOT/actions/remove-repeater-item';
import syncFormValidation from 'APP_ROOT/actions/sync-form-validations';
import removeSourceSelection from 'APP_ROOT/actions/remove-source-selection';
import removeReferenceSelection from 'APP_ROOT/actions/remove-reference-selection';
import requestFormSubmit from 'APP_ROOT/actions/events/request-form-submit';
import submitForm from 'APP_ROOT/actions/submit-form';

import ModalTitle from '../../common/modal/title';
import ModalBody from '../../common/modal/body';
import withModal from '../../common/modal/base';

import ReviewPanel from '../../form/review-panel';

import Conditional from '../../utils/ConditionalRender';
import {
  mapFormChanges,
  transformData,
  getDataFromConditions,
} from 'APP_ROOT/utils/form';
import flattenErrors from 'APP_ROOT/utils/flatten-errors';
import {
  getTabFieldsError,
  getRequirementComplimentFieldsError,
} from 'APP_ROOT/utils/validations';
import mapPropsToFields, {
  getFormAttachments,
} from 'APP_ROOT/utils/map-props-to-fields';
import getLastStatus from 'APP_ROOT/utils/get-report-status';
import { getSelectedReviewForm } from 'APP_ROOT/selectors/form';
import emitter, { EventTypes } from 'APP_ROOT/utils/eventEmitter';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';

const confirm = Modal.confirm;

const formName = 'reviewForm';

class FormViewerWrapper extends withModal(Component) {
  componentWillMount() {
    emitter.on(EventTypes.DISPATCH_IN_FORM, this.onDispatch);
    emitter.on(EventTypes.CALL_FORM_METHOD, this.onCallFormMethod);
  }

  componentDidMount() {
    this.createModal();
  }

  componentWillUnmount() {
    this.hideModal();
    this.deleteModal();

    emitter.off(EventTypes.DISPATCH_IN_FORM, this.onDispatch);
    emitter.off(EventTypes.CALL_FORM_METHOD, this.onCallFormMethod);
  }

  houldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  onCallFormMethod = (name, _formName, ...args) => {
    if (_formName !== formName) return;

    if (this[name]) {
      if (typeof this[name] !== 'function') {
        // eslint-disable-next-line no-console
        console.warn(
          `The method you are trying to call does not exist. Method "${name}" with args "${JSON.stringify(
            args
          )}"`
        );
        return;
      }

      this[name](...args);
    }
  };

  onSaveDraft = onDone => {
    const { onSaveDraft } = this.props;
    onSaveDraft && typeof onSaveDraft === 'function' && onSaveDraft(onDone);
  };

  onDispatch = (_formName, fn) => {
    const { dispatch } = this.props;

    if (_formName !== formName) return;

    dispatch(fn);
  };

  whiteListKeys = [
    '__users',
    '__formParent',
    'action',
    'notes',
    'reviewer',
    '__assignedSections',
    '__assignedSectionsHistory',
  ];

  addRepeaterItem = (keys, ...args) => {
    const { dispatch } = this.props;

    dispatch(addRepeaterItem(keys, ...args));
  };

  removeRepeaterItem = (
    keys,
    index = 0,
    itemData,
    removeKeys,
    removeRefKeys = [],
    deleteModalOptions = {},
    showConfirmation = true
  ) => {
    const mergedDeleteModalOptions =
      isPlainObject(deleteModalOptions) && showConfirmation
        ? Object.assign(
            {},
            {
              title: 'Heads Up!',
              okText: 'Yes',
              cancelText: 'Cancel',
              hideCounter: false,
              content: `
        <p>
          By removing <strong>this section</strong>, all the information related to it will be removed from the entire form. <br />
          Do you want to remove it?
        </p>
      `,
            },
            deleteModalOptions
          )
        : {};

    const { dispatch } = this.props;
    const content = {
      __html: unescape(
        template(mergedDeleteModalOptions.content)({
          index: mergedDeleteModalOptions.hideCounter ? false : index + 1,
        })
      ),
    };

    const onConfirm = () => {
      keys.forEach(key => {
        dispatch(removeRepeaterItem(key, index));
      });
      removeKeys.forEach(item => {
        const { key, source } = item;
        const value = get(itemData, key, '');
        dispatch(removeSourceSelection(value, source));
      });
      removeRefKeys.forEach(item => {
        const { source = '', field = 'officerId', fields = [] } = item;
        const value = get(itemData, [field], '');
        dispatch(removeReferenceSelection(value, source, fields));
      });
    };

    if (showConfirmation) {
      confirm({
        title: mergedDeleteModalOptions.title,
        okText: mergedDeleteModalOptions.okText,
        cancelText: mergedDeleteModalOptions.cancelText,
        content: <div dangerouslySetInnerHTML={content} />,
        onOk() {
          onConfirm();
        },
      });
    } else {
      onConfirm();
    }
  };

  cleanupField = ({ data = {}, fieldKey = 'reviewer', exclude = [] }) =>
    Object.keys(data).reduce((output, key) => {
      if (key === fieldKey) {
        const currentValue = castArray(get(output, key, []));
        const nextValue = currentValue.filter(item => !exclude.includes(item));
        return Object.assign({}, output, {
          [key]: nextValue,
        });
      }
      return output;
    }, data);

  injectListData = (fromData, toData) => (output, key) => {
    const fieldValueFromData = get(fromData, key);
    const fieldValueToData = get(toData, key);

    if (Array.isArray(fieldValueFromData)) {
      const firstItemFromSourceDataIsFromRepeater = isPlainObject(
        first(fieldValueFromData)
      );
      const firstItemFromTargetDataIsFromRepeater = isPlainObject(
        first(fieldValueToData)
      );
      if (
        firstItemFromSourceDataIsFromRepeater ||
        firstItemFromTargetDataIsFromRepeater
      ) {
        return Object.assign({}, output, {
          [key]: fieldValueFromData.map((item, index) =>
            Object.assign({}, item, get(fieldValueToData, index, {}))
          ),
        });
      }
    }
    return output;
  };

  onSubmit = (
    selectedResolution,
    selectedReviewer,
    notes,
    fields,
    reviewers,
    showLoader = () => {}
  ) => {
    const {
      selectedReviewForm,
      app: { blockNavigation = false } = {},
      reviewForm,
      reviewTemplate,
      shouldValidate = true,
    } = this.props;

    const { presentation = {}, formFields = {} } = selectedReviewForm;

    const allFields = formFields.fields.reduce(
      (acc, tab) => ({ ...acc, ...tab }),
      {}
    );

    const { formData, reviewFormData } = this.processFormData(
      reviewForm,
      reviewers,
      reviewTemplate,
      notes
    );

    const transformedNotes = getDataFromConditions(
      { ...formData, isReviewer: false },
      allFields,
      this.whiteListKeys
    );

    const wholeTransformedNotes = getDataFromConditions(
      { ...reviewFormData, isReviewer: false },
      allFields,
      this.whiteListKeys
    );

    const injectedListData = Object.keys(wholeTransformedNotes).reduce(
      this.injectListData(notes, wholeTransformedNotes),
      {}
    );

    const wholeFormData = Object.assign(
      {},
      notes,
      wholeTransformedNotes,
      injectedListData
    );

    if (blockNavigation) {
      this.showErrorConfirmation(
        'One or more files are still being uploaded. Please wait for them to finish and submit again.'
      );
      return false;
    }

    if (shouldValidate) {
      return this.confirmSubmit(
        wholeFormData,
        presentation,
        selectedResolution,
        selectedReviewer,
        transformedNotes,
        fields,
        showLoader
      );
    }
    return this.forceSubmition(
      selectedResolution,
      selectedReviewer,
      transformedNotes,
      fields
    );
  };

  async confirmSubmit(
    wholeFormData,
    presentation,
    selectedResolution,
    selectedReviewer,
    transformedNotes,
    fields,
    showLoader
  ) {
    const { dispatch, selectedReviewForm } = this.props;

    this.validateFields();

    const tabFields = {
      ...this.props,
      selectedForm: {
        ...selectedReviewForm,
        data: wholeFormData,
      },
      forceValidate: true,
    };

    const preparedFieldsValidation = await getTabFieldsError(tabFields);
    const getRequirementComplimentErrors = getRequirementComplimentFieldsError(
      tabFields
    );

    const requirementErrorsByTab = getRequirementComplimentErrors.reduce(
      (errors, error) => ({
        ...(errors || {}),
        [error.tabIndex]: [...(errors[error.tabIndex] || []), error],
      }),
      {}
    );

    dispatch(requestFormSubmit());

    const errors = await Promise.all(
      keys(presentation.fields).map(async tab => preparedFieldsValidation(tab))
    );

    const allErrors = errors
      .map(tab => tab.fields)
      .reduce((result, tab, index) => {
        return { ...result, ...tab };
      }, {});

    const bulkErrors = errors.map((tab, tabIndex) => [
      ...tab.errors,
      ...(requirementErrorsByTab[tabIndex] || []),
    ]);

    const fieldsWithErrors = Object.keys(allErrors);

    if (fieldsWithErrors.length || getRequirementComplimentErrors.length) {
      return this.processError(allErrors, bulkErrors, showLoader);
    }
    return this.processSubmition(
      selectedResolution,
      selectedReviewer,
      transformedNotes,
      fields
    );
  }

  forceSubmition(
    selectedResolution,
    selectedReviewer,
    transformedNotes,
    fields
  ) {
    const { onSubmit, dispatch } = this.props;
    dispatch(submitForm());
    onSubmit(selectedResolution, selectedReviewer, transformedNotes, fields);
    return true;
  }

  validateFields = (current = null, next = () => {}) => {
    const { dispatch, form } = this.props;
    const tabFields = form.getFieldsValue();

    const tabKeys = keys(tabFields).reduce((result, key) => {
      const item = tabFields[key];

      if (Array.isArray(item)) {
        if (isPlainObject(first(item))) {
          return item.reduce((res, i, index) => {
            return Object.keys(i).reduce((resi, ci) => {
              if (ci === 'id') {
                return resi;
              }
              return [...resi, `${key}[${index}].${ci}`];
            }, res);
          }, result);
        }
      }

      return [...result, key];
    }, []);

    form.validateFields(tabKeys, (errors, values) => {
      const flattenedErrors = flattenErrors(errors);
      dispatch(syncFormValidation(flattenedErrors));
      next();
    });
  };

  validateFormFields = async () => {
    const { dispatch, selectedForm, shouldValidate = true } = this.props;
    const { presentation = {} } = selectedForm;

    if (shouldValidate) {
      const preparedFieldsValidation = await getTabFieldsError(this.props);
      const getRequirementComplimentErrors = getRequirementComplimentFieldsError(
        { ...this.props, forceValidate: true }
      );
      const requirementErrorsByTab = getRequirementComplimentErrors.reduce(
        (errors, error) => ({
          ...(errors || {}),
          [error.tabIndex]: [...(errors[error.tabIndex] || []), error],
        }),
        {}
      );

      const errors = await Promise.all(
        keys(presentation.fields).map(async tab =>
          preparedFieldsValidation(tab)
        )
      );

      const allErrors = errors
        .map(tab => tab.fields)
        .reduce((result, tab, index) => {
          return { ...result, ...tab };
        }, {});

      const bulkErrors = errors.map((tab, tabIndex) => [
        ...tab.errors,
        ...(requirementErrorsByTab[tabIndex] || []),
      ]);

      const fieldsWithErrors = Object.keys(allErrors);

      if (fieldsWithErrors.length || getRequirementComplimentErrors.length) {
        dispatch(syncFormValidation(allErrors, bulkErrors));
        return false;
      }

      dispatch(syncFormValidation(null, null, true));
    }
  };

  showErrorConfirmation = (errorMessage = '') => {
    const ErrorTitle = (
      <ModalTitle error>
        <Icon type="exclamation-circle" /> <span>Heads up!</span>
      </ModalTitle>
    );

    const ErrorText = (
      <ModalBody>
        {errorMessage && errorMessage}
        {!errorMessage && (
          <span>
            Some of the required fields are empty or were not answered
            correctly. Please double-check and submit again.
          </span>
        )}
      </ModalBody>
    );

    this.updateModal({
      visible: true,
      title: ErrorTitle,
      children: ErrorText,
    });

    this.showModal();
  };

  getTabFieldsError = getTabFieldsError(this.props);

  removedUndefinedValuesIn(notes) {
    return Object.keys(notes).reduce((res, key) => {
      if (notes[key] !== undefined) {
        return { ...res, [key]: notes[key] };
      }
      return res;
    }, {});
  }

  processFormData(reviewForm, reviewers, reviewTemplate, notes) {
    if (has(notes, 'action')) {
      return this.processDefaultReviewNote(
        reviewForm,
        reviewers,
        reviewTemplate,
        notes
      );
    }
    return this.processCustomReviewNote(
      reviewForm,
      reviewers,
      reviewTemplate,
      notes
    );
  }

  processDefaultReviewNote(reviewForm, reviewers, reviewTemplate, notes) {
    const reviewFormData = this.cleanupField({
      data: get(reviewForm, 'selectedForm.data', {}),
      exclude: reviewers,
    });
    const cleanedNotes = this.removedUndefinedValuesIn(notes);
    const curatedReviewFormData = transformData(reviewFormData, reviewTemplate);
    const formData = Object.assign(
      {},
      curatedReviewFormData,
      cleanedNotes,
      reviewFormData
    );
    return { formData, reviewFormData };
  }

  processCustomReviewNote(reviewForm, reviewers, reviewTemplate, notes) {
    const reviewFormData = this.cleanupField({
      data: get(reviewForm, 'selectedForm.data', {}),
      exclude: reviewers,
    });

    const cleanedNotes = this.removedUndefinedValuesIn(notes);

    const curatedReviewFormData = transformData(reviewFormData, reviewTemplate);

    const formData = Object.assign(
      {},
      curatedReviewFormData,
      cleanedNotes,
      reviewFormData
    );
    return { formData, reviewFormData };
  }

  processError(allErrors, bulkErrors, showLoader) {
    const { dispatch } = this.props;
    dispatch(syncFormValidation(allErrors, bulkErrors));
    this.showErrorConfirmation();
    showLoader(false);
    return false;
  }

  processSubmition(
    selectedResolution,
    selectedReviewer,
    transformedNotes,
    fields
  ) {
    const { onSubmit, dispatch } = this.props;
    dispatch(syncFormValidation(null, null, true));
    dispatch(submitForm());
    onSubmit(selectedResolution, selectedReviewer, transformedNotes, fields);
    return true;
  }

  render() {
    const {
      selectedForm = {},
      form,
      canReview = false,
      user: { id, agencyId } = {},
      isPrinting = false,
      reviewForm = {},
      reviewTemplate,
      submissionForm,
      template,
      saveScrollToComponent,
    } = this.props;

    const { selectedForm: selectedFormReview = {} } = reviewForm;
    const { meta = {} } = selectedForm;
    const { validations: validationState } = selectedFormReview;
    const {
      workFlowData: { status = {} } = {},
      isEditMode = true,
      viewerIds = [],
    } = meta;
    const { entries = {} } = reviewForm;
    const isReviewer = false;
    const { templateId: reviewPanelTemplateId } =
      first(get(meta, 'reviewNoteTemplateId', [])) || {};
    const templateEntry = get(entries, reviewPanelTemplateId, {});
    const { formSchema: uiSchema = {} } = templateEntry;

    const { form: formSchema } = uiSchema;

    const formNumber = get(selectedForm, ['meta', 'formNumber'], '');

    const { key: statusKey = 'draft' } = getLastStatus(
      { actions: selectedForm.actions, ...meta },
      id
    );

    const settings = {
      formSchema,
      validateFields: this.validateFields,
      validateFormFields: this.validateFormFields,
      getTabFieldsError: this.getTabFieldsError,
      formName,
      agencyId,
    };

    const isPending = statusKey === 'pending';
    const isViewer = viewerIds.includes(id);
    const isTemporalActiveReviewer =
      has(status, id) && get(status, [id, 'labelIdentifier']) === 'TAKE_ACTION';

    return (
      <Form className="bdm-form" hideRequiredMark onSubmit={this.onSubmit}>
        <Conditional
          condition={
            !isPrinting &&
            !isPending &&
            !isEditMode &&
            !isViewer &&
            (isReviewer || isTemporalActiveReviewer) &&
            (canReview || isTemporalActiveReviewer) &&
            formNumber
          }
        >
          <ReviewPanel
            form={form}
            settings={settings}
            onSubmit={this.onSubmit}
            validationState={validationState}
            reviewForm={reviewForm}
            reviewTemplate={reviewTemplate}
            submissionForm={submissionForm}
            template={template}
            saveScrollToComponent={saveScrollToComponent}
          />
        </Conditional>
      </Form>
    );
  }
}

const options = {
  onFieldsChange: (props, form) => {
    const {
      dispatch,
      selectedReviewForm: {
        data = {},
        presentation = {},
        state: formState = {},
      },
    } = props;
    const { changes, state } = mapFormChanges(
      data,
      form,
      presentation,
      formState
    );

    dispatch(syncFormReviewData(changes, state));
  },
  mapPropsToFields: (props, form) => {
    const {
      selectedForm = {},
      selectedReviewForm = {},
      attachments = {},
    } = props;
    const { data = {}, state = {}, presentation = {} } = selectedReviewForm;
    const template = kebabCase(
      get(selectedReviewForm, ['template', 'type'], '')
    );
    const extraFields = getFormAttachments(
      attachments,
      template,
      selectedForm.id
    );

    return mapPropsToFields(data, state, presentation, extraFields);
  },
};

const FormEngine = Form.create(options)(FormViewerWrapper);

const mapState = state => {
  const { reviewForm = {}, form: submissionForm = {} } = state;
  const { templates = {}, selectedForm = {} } = submissionForm;
  const { template: templateType = '' } = selectedForm;
  const template = get(templates, [templateType], {});
  const { templateId } =
    first(get(selectedForm, 'meta.reviewNoteTemplateId', [])) || {};
  const reviewTemplate = get(reviewForm, `entries.${templateId}`, {});

  return {
    submissionForm,
    reviewForm,
    template,
    reviewTemplate,
    selectedReviewForm: getSelectedReviewForm(state),
  };
};

export default connect(mapState)(FormEngine);
