import { createSelector } from 'reselect';
import { get } from 'lodash';

const getAgencyProfile = state => state.agencyProfile;

const getAgencyUsers = state => state.manageUsers;

export const passAgencyId = (state, agencyId) => agencyId;

export const selectAgency = createSelector(
  [getAgencyProfile, passAgencyId],
  (agencyProfile, agencyId) => get(agencyProfile, [agencyId], {})
);

export const selectAgencyUserSubmissionState = createSelector(
  [getAgencyUsers, passAgencyId],
  (agencyProfile, agencyId) =>
    get(agencyProfile, [agencyId, 'submitting'], false)
);

export const selectUnitAssignments = createSelector(
  [getAgencyProfile, passAgencyId],
  (manageUsers, agencyId) => get(manageUsers, `${agencyId}.unitAssignments`, [])
);
