import { createReducer } from 'redux-create-reducer';

import {
  GET_ALL_AGENCIES_REQUEST,
  GET_ALL_AGENCIES_FULLFILLED,
  GET_ALL_AGENCIES_REJECTED,
} from './Agencies.actionTypes';

const initialState = {
  loading: true,
  list: [],
  entries: {},
  error: null,
  page: 1,
  per_page: 10,
  total: 0,
};

const events = {
  [GET_ALL_AGENCIES_REQUEST]: (state, { payload }) => ({
    ...initialState,
    ...state,
    loading: true,
    error: null,
    page: payload.page,
  }),
  [GET_ALL_AGENCIES_FULLFILLED]: (state, { payload }) => ({
    ...state,
    loading: false,
    list: payload.data.map(user => user.id),
    total: payload.count,
    entries: payload.data.reduce(
      (agencies, agency) => ({ ...agencies, [agency.id]: agency }),
      {}
    ),
  }),
  [GET_ALL_AGENCIES_REJECTED]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload.error,
  }),
};

export default createReducer(initialState, events);
