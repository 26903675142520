import React from 'react';
import openModal from '../utils/onShareButtonClick';
import { ButtonActionsWrapper } from '../CaseFile.styled';

const ShareButton = props => {
  const { isCasefileClosed } = props;
  return (
    <ButtonActionsWrapper
      icon="user-add"
      type="icon"
      shape="circle"
      title="Share"
      tooltipMessage="Share File"
      onClick={() => openModal(isCasefileClosed)}
    />
  );
};

export default ShareButton;
