import styled from 'styled-components';

import TabTitleComponent from './TabTitle';

const TabTitle = styled(TabTitleComponent)`
  .ant-btn {
    margin-left: 10px;
    font-size: 15px;
  }
`;

export default TabTitle;
