import React, { PureComponent } from 'react';
import { keys } from 'lodash';
import cx from 'classnames';
import { Button } from 'antd';
import { Prompt } from 'react-router-dom';

import ChangesTracker, {
  TrackingChangeTypes,
} from '../../services/changesTracker';

class EditorSaveButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disabled: !this.hasChanges(),
    };
  }

  hasChanges() {
    return keys(TrackingChangeTypes).some(key =>
      ChangesTracker.hasUnpublishedChanges(key)
    );
  }

  componentDidMount() {
    this.changesTrackerSuscription = ChangesTracker.getUnpublishedChanges().subscribe(
      this.onChangesReceived
    );
  }

  componentWillUnmount() {
    this.changesTrackerSuscription.unsubscribe();
  }

  onChangesReceived = () => {
    this.setState({
      disabled: !this.hasChanges(),
    });
  };

  onClick = e => {
    e.preventDefault();
    ChangesTracker.onSave();
  };

  render() {
    const { className, loading = false } = this.props;
    const { disabled = false } = this.state;
    const message =
      'You have unsaved changes, if you continue these changes will get lost. Do you want to continue?';

    return (
      <div>
        <Prompt when={!disabled || loading} message={message} />
        <Button
          className={cx(className)}
          type="primary"
          htmlType="button"
          disabled={loading || disabled}
          loading={loading}
          onClick={this.onClick}
        >
          Save
        </Button>
      </div>
    );
  }
}

export default EditorSaveButton;
