import styled from 'styled-components';

import Avatar from '../object-types/Avatar';

const StyledAvatar = styled(Avatar)`
  text-align: center;

  span {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    background-color: #3772ff;

    span {
      transform: scale(1);
      display: inline-block;
      font-size: 20px;
      line-height: 60px;
      text-transform: uppercase;
    }
  }
`;

export default StyledAvatar;
