import styled from 'styled-components';
import { Modal } from 'antd';

const StyledModal = styled(Modal)`
  &.user-profile-modal {
    max-width: 1000px;
    width: 100% !important;
  }
`;

export default StyledModal;
