import styled from 'styled-components';

export const EditReportWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;

  @media print {
    display: none;
  }
`;

export const CancelEditBottomWrapper = styled.div`
  margin-left: 10px;
`;

export const UpdateModalContinueButtomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;
