import { camelCase } from 'lodash';

import AgencyReports from './AgencyReports';
import ReportType from '../../../containers/report-type';
import { PERMISSIONS } from '../../../utils/admin';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/reports/:id`,
    name: camelCase(`${name}-report`),
    component: ReportType,
    protectedWith: [PERMISSIONS.viewAllReports],
    childRoutes: [],
  },
  {
    path: `${path}/reports`,
    name: camelCase(`${name}-reports`),
    component: AgencyReports,
    protectedWith: [PERMISSIONS.viewAllReports],
    childRoutes: [],
  },
];
