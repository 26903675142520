import uuid from 'uuid/v4';
import { omit, isPlainObject, camelCase, get } from 'lodash';

import * as ValidLayoutTypes from 'APP_ROOT/constants/layoutComponentTypes';

export default class FormComponent {
  constructor(type, options, properties) {
    const formComponent = {};

    formComponent.id = uuid();

    if (ValidLayoutTypes.SECTION === type) {
      formComponent.columns = 2;
    }

    if (isPlainObject(options)) {
      Object.entries(omit(options, ['properties', 'type'])).forEach(
        ([key, value]) => {
          formComponent[key] = value;
        }
      );
    }

    const rnd = Math.trunc(Math.random() * 1000);
    const title = get(options, 'title', '');
    const reporting = get(options, 'field_type', type);
    const reportingKey = get(
      options,
      'reportingKey',
      `${camelCase(title || reporting) + rnd}`
    );
    formComponent.reportingKey = reportingKey;

    if (Object.values(ValidLayoutTypes).includes(type)) {
      formComponent.type = type;
    }

    if (Array.isArray(properties)) {
      formComponent.properties = properties;
    }

    return formComponent;
  }
}
