import { ATTRIBUTE_TYPE_DATE } from '../../UserProfile.constants';
import { BENCHMARK_DATE_FORMAT } from '../../../../../../utils/parse-date';

const encodeEquipmentData = (type, data) => {
  switch (type) {
    case ATTRIBUTE_TYPE_DATE:
      return encodeURIComponent(data.format(BENCHMARK_DATE_FORMAT));
    default:
      return encodeURIComponent(data);
  }
};

export default encodeEquipmentData;
