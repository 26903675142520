import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Input,
  InputNumber,
  Button,
  Checkbox,
  Form,
  Tabs,
  Alert,
  Col,
  Icon,
  Popover,
  Row,
} from 'antd';
import {
  isEmpty,
  isBoolean,
  isString,
  isPlainObject,
  startCase,
  get,
  omit,
} from 'lodash';

import RowWrapper from './OptionsModalBody.styled';
import './OptionsModalBody.css';
import UpdateSelectOptions from '../UpdateSelectOptions/UpdateSelectOptions';
import DefineConditional from '../DefineConditional/DefineConditional';
import ShareKey from '../ShareRef/ShareRef';

import ModalBody from 'APP_COMPONENTS/common/modal/body';
import getFieldLabel from '../../utils/getFieldLabel';

import * as FIELD_TYPES from 'APP_ROOT/constants/fieldTypes';
import BLACKLISTED_FIELDS from 'APP_ROOT/constants/formFieldTypesBlacklist';

import UpdatePopulateFrom from '../UpdatePopulateFrom/UpdatePopulateFrom';

import componentsManager from '../../services/componentsManager';
import removeMathOperand from '../../services/utils/removeMathOperand';
import OptionsModalConfirm from '../OptionsModalConfirm/OptionsModalConfirm';
import FieldSelect from '../FieldSelect';

const { TextArea } = Input;
const { Item } = Form;
const { TabPane } = Tabs;

const ableToShareKey = Object.values(FIELD_TYPES).filter(
  fieldType => !BLACKLISTED_FIELDS.includes(fieldType)
);

export const TAB_OPTIONS = 1;
export const TAB_VALUES = 2;
export const TAB_CONDITIONAL = 3;
export const TAB_SHARE_KEY = 4;
export const TAB_INFORMATION = 5;

const ALL_TABS = [
  TAB_OPTIONS,
  TAB_VALUES,
  TAB_CONDITIONAL,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
];
export default class OptionsModalBody extends Component {
  constructor(props) {
    super(props);

    const {
      select,
      populateFrom,
      conditions = [],
      information = '',
      optionsModalUpdateFlag,
      ...values
    } = props;

    this.state = {
      optionsModalUpdateFlag,
      validations: [],
      select,
      populateFrom,
      conditions,
      information,
      values: omit(values, [
        'id',
        'fieldKey',
        'fieldRef',
        'onSave',
        'onChange',
        'onCancel',
        'onDelete',
        'activeTab',
        'disableTab',
      ]),
      showConfirmation: false,
      placement: 'right',
      affectedFields: {},
      messageType: 'error',
      message: '',
    };
  }
  static getDerivedStateFromProps(props, state) {
    const {
      select,
      populateFrom,
      conditions = [],
      optionsModalUpdateFlag,
      ..._values
    } = props;
    const values = omit(_values, [
      'id',
      'fieldKey',
      'fieldRef',
      'onSave',
      'onChange',
      'onCancel',
      'onDelete',
      'activeTab',
      'disableTab',
      'information',
    ]);
    if (optionsModalUpdateFlag !== state.optionsModalUpdateFlag) {
      return { select, conditions, values, optionsModalUpdateFlag };
    }
    return null;
  }

  onClose = () => {
    this.setState({
      showConfirmation: false,
    });
  };

  itemLayoutOptions = {
    colon: false,
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  itemLayoutOptionsPopover = {
    colon: false,
    labelCol: { span: 7 },
    wrapperCol: { span: 16 },
  };

  itemLayoutOptionsNoLabel = {
    colon: false,
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };

  onFieldChange = (onChange, values) => value => {
    const changes = onChange(value, values);
    const newChanges = omit(changes, ['customFields']);
    const newValues = omit(values, ['customFields']);
    if (isPlainObject(changes)) {
      const newCustomFields = Object.assign([], {
        ...changes.customFields,
      });
      this.setState({
        values: Object.assign(
          {},
          {
            ...newValues,
            ...newChanges,
            customFields: newCustomFields,
            options: Object.assign(
              {},
              {
                ...get(values, 'options', {}),
                ...get(changes, 'options', {}),
              }
            ),
          }
        ),
      });
    }
  };

  customFieldsMap = values => customField => {
    const {
      component: Component,
      name,
      label,
      popoverContent,
      onChange,
      alert,
      isDisabled = () => false,
    } = customField;

    const iconStyle = {
      fontSize: 13,
      marginTop: 13,
    };
    const hasPopover = !isEmpty(popoverContent);

    return (
      <Row key={`custom-field-row-${name}`}>
        {hasPopover && (
          <Col span={1}>
            <Popover
              className="massagePopOver"
              key={name}
              content={popoverContent}
              title={label}
            >
              <Icon type="question-circle" style={iconStyle} />
            </Popover>
          </Col>
        )}
        <Col span={hasPopover ? 23 : 24}>
          <Item
            key={name}
            label={label}
            {...(label === ''
              ? this.itemLayoutOptionsNoLabel
              : hasPopover
              ? this.itemLayoutOptionsPopover
              : this.itemLayoutOptions)}
          >
            <Component
              {...customField}
              onChange={this.onFieldChange(onChange, values)}
              state={this.state}
              value={values[name]}
              disabled={isDisabled(values)}
            />
            {alert && (
              <Alert
                message={alert.message}
                type={alert.type}
                showIcon
                closable
              />
            )}
          </Item>
        </Col>
      </Row>
    );
  };

  renderCustomFields = () => {
    const {
      values,
      values: { customFields = [] },
    } = this.state;
    const customFieldsFiltered = customFields.filter(
      cf => cf.isHidden === undefined || !cf.isHidden(values)
    );

    return customFieldsFiltered.map(this.customFieldsMap(values));
  };

  get textFieldsFilter() {
    return ([key, value]) => isString(value) && key !== 'type';
  }

  get advancedFieldsFilter() {
    return ([, value]) => isPlainObject(value) && value['type'] !== undefined;
  }

  onTextBlur = key => e => {
    const { values } = this.state;
    const value = e.target.value;

    this.setState({
      values: {
        ...values,
        [key]: value,
      },
    });
  };

  onMultiselectChange = key => value => {
    const { values } = this.state;
    const multiSelectValue = values[key];
    this.setState({
      values: {
        ...values,
        [key]: {
          ...multiSelectValue,
          value,
        },
      },
    });
  };

  onObjectNumberChange = key => value => {
    const { values } = this.state;
    const objectValue = values[key];

    this.setState({
      values: {
        ...values,
        [key]: {
          ...objectValue,
          value,
        },
      },
    });
  };

  onObjectCheckboxChange = key => e => {
    const { values } = this.state;
    const objectValue = values[key];
    const value = e.target.checked;

    this.setState({
      values: {
        ...values,
        [key]: {
          ...objectValue,
          value,
        },
      },
    });
  };

  onObjectTextBlur = key => e => {
    const { values } = this.state;
    const objectValue = values[key];
    const value = e.target.value;

    this.setState({
      values: {
        ...values,
        [key]: {
          ...objectValue,
          value,
        },
      },
    });
  };

  onBoolChange = key => () => {
    const { values } = this.state;

    this.setState({
      values: {
        ...values,
        [key]: !values[key],
      },
    });
  };

  verifyEmpySelectOptions = select => {
    return select.some(
      ({ label, value, enums }) =>
        isEmpty(label) ||
        (enums ? this.verifyEmpySelectOptions(enums) : isEmpty(value))
    );
  };

  onSave = () => {
    const {
      values,
      conditions,
      select,
      validations,
      sharedRef,
      sharedKey,
      populateFrom,
      information,
    } = this.state;
    const { onSave } = this.props;
    if (select && select.length && this.verifyEmpySelectOptions(select)) {
      this.setState({
        messageType: 'error',
        message: 'Cannot save select options with empty values',
      });
    } else {
      onSave &&
        onSave({
          ...values,
          sharedRef,
          sharedKey,
          select,
          validations,
          conditions: isEmpty(conditions.rules) ? undefined : conditions,
          populateFrom: isEmpty(populateFrom) ? undefined : populateFrom,
          information: isEmpty(information) ? undefined : information,
        });
    }
  };

  onCancel = () => {
    const { onCancel } = this.props;

    onCancel && onCancel();
  };

  onDelete = () => {
    const { onDelete, fieldKey } = this.props;
    let {
      fieldsToRemove = [],
      fieldsToUpdate = [],
    } = componentsManager.getConditionalAffectedFields(fieldKey);
    const durationFields = componentsManager.getDurationAffectedFields(
      fieldsToRemove.concat({ key: fieldKey })
    );
    const mathFields = componentsManager.getMathAffectedFields(
      fieldsToRemove.concat({ key: fieldKey })
    );
    fieldsToRemove = fieldsToRemove.concat(durationFields);
    fieldsToUpdate = fieldsToUpdate.concat(
      removeMathOperand(fieldKey, mathFields)
    );

    isEmpty(fieldsToRemove) && isEmpty(fieldsToUpdate)
      ? onDelete && onDelete()
      : this.showConfirmation({
          fieldsToUpdate,
          fieldsToRemove,
        });
  };

  showConfirmation(affectedFields) {
    this.setState({
      showConfirmation: true,
      affectedFields,
    });
  }

  async deleteAffectedFields(affectedFields) {
    const { onDelete } = this.props;
    await affectedFields.fieldsToUpdate.forEach(affectedField => {
      componentsManager.editComponent(affectedField.id, affectedField);
    });
    await affectedFields.fieldsToRemove.forEach(affectedField => {
      componentsManager.removeComponent(affectedField.id);
    });
    this.setState(
      {
        showConfirmation: false,
        affectedFields: {},
      },
      () => onDelete()
    );
  }

  onChangeComponent = values => this.setState({ ...values });

  notCustomValuesFilter = ([key, value]) => {
    const {
      values: { customFields = [] },
    } = this.state;
    const customValues = customFields.map(({ name }) => name);

    return !customValues.includes(key);
  };

  geekClick = e => {
    if (e.shiftKey) {
      this.setState({ showGeek: !this.state.showGeek });
    }
  };

  onChangeInformation = information => {
    this.onChangeComponent({ information });
  };

  geekClick = e => {
    if (e.shiftKey) {
      this.setState({ showGeek: !this.state.showGeek });
    }
  };

  showMessage = (messageType, message) => {
    this.setState({ messageType, message });
  };

  render() {
    const {
      id,
      fieldKey,
      fieldRef,
      reportingKey,
      type,
      version,
      status,
      activeTab = 1,
      disableTab = [],
      onDelete,
      onSave,
    } = this.props;
    const {
      select,
      populateFrom,
      conditions,
      information,
      placement,
      showConfirmation,
      affectedFields,
      values: { customFields = [] },
      showGeek,
      message,
      messageType,
    } = this.state;

    const values = omit(this.state.values, ['dependsOn', 'version', 'status']);
    const properties = Object.entries(values).filter(
      this.notCustomValuesFilter
    );
    const booleanOptions = properties.filter(([, val]) => isBoolean(val));
    // disableTab could be a number or array
    const _disableTabs = [].concat(disableTab);
    if (!ableToShareKey.includes(type)) {
      _disableTabs.push(4);
    }
    //Disabled Apply button according the validation status for each custom field.
    const disabledApply =
      customFields
        .filter(cf => cf.isHidden === undefined || !cf.isHidden(values))
        .filter(cf => cf.isValid !== undefined && !cf.isValid(values)).length >
      0;
    // now we have just 2 tabs but we can have more later
    const _availTabs = ALL_TABS.filter(t => !_disableTabs.includes(t));
    const _activeTab = _availTabs.includes(activeTab)
      ? activeTab
      : _availTabs[0];

    return (
      <ModalBody key="body">
        <RowWrapper className="modal-title">Field configuration</RowWrapper>
        <Tabs defaultActiveKey={`TabPane${_activeTab}`}>
          {!_disableTabs.includes(TAB_OPTIONS) && (
            <TabPane
              tab="Options"
              key="TabPane1"
              disabled={_disableTabs.includes(TAB_OPTIONS)}
            >
              <RowWrapper>
                {type && (
                  <Item label="Type" {...this.itemLayoutOptions}>
                    <span className="ant-tag" onClick={this.geekClick}>
                      {getFieldLabel(type)}
                    </span>
                    {version && (
                      <span>
                        Version {version}, {getFieldLabel(status)}
                      </span>
                    )}
                  </Item>
                )}
              </RowWrapper>
              {showGeek && (
                <RowWrapper>
                  <Item label="ID" {...this.itemLayoutOptions}>
                    <span>{id}</span>
                  </Item>
                  <Item label="Key" {...this.itemLayoutOptions}>
                    <span>{fieldKey}</span>
                  </Item>
                </RowWrapper>
              )}

              {properties.filter(this.textFieldsFilter).map(([key, value]) => (
                <RowWrapper key={key}>
                  {key === 'subtitle' ? (
                    <Item label=" " {...this.itemLayoutOptions}>
                      <label className="title">{value}</label>
                    </Item>
                  ) : (
                    <Item label={startCase(key)} {...this.itemLayoutOptions}>
                      <Input
                        type="text"
                        className="edit-input"
                        maxLength={50}
                        defaultValue={value}
                        placeholder="Enter a value"
                        onBlur={this.onTextBlur(key)}
                      />
                    </Item>
                  )}
                </RowWrapper>
              ))}

              {properties.filter(this.advancedFieldsFilter).map(
                ([key, value]) =>
                  (!value.isHidden || !value.isHidden(values)) && (
                    <RowWrapper key={key}>
                      <Item
                        label={startCase(value.label)}
                        help={value.help}
                        {...this.itemLayoutOptions}
                      >
                        {value.type === 'textarea' && (
                          <TextArea
                            rows={3}
                            className={value.className || ''}
                            maxLength={value.maxLength}
                            defaultValue={value.value}
                            placeholder={value.placeholder}
                            onBlur={this.onObjectTextBlur(key)}
                          />
                        )}
                        {value.type === 'text' && (
                          <Input
                            type="text"
                            className={`edit-input ${value.className || ''}`}
                            defaultValue={value.value}
                            placeholder={value.placeholder}
                            maxLength={value.maxLength}
                            onBlur={this.onObjectTextBlur(key)}
                          />
                        )}
                        {value.type === 'multiselect' && (
                          <FieldSelect
                            value={value.value}
                            style={{ width: 260 }}
                            onChange={this.onMultiselectChange(key)}
                            placeholder={value.placeholder}
                            selectOptions={value.values}
                          />
                        )}
                        {value.type === 'number' && (
                          <InputNumber
                            value={value.value}
                            onChange={this.onObjectNumberChange(key)}
                          />
                        )}
                        {value.type === 'checkbox' && (
                          <Checkbox
                            checked={value.value}
                            onChange={this.onObjectCheckboxChange(key)}
                            disabled={
                              value.isDisabled && value.isDisabled(values)
                            }
                          ></Checkbox>
                        )}
                      </Item>
                    </RowWrapper>
                  )
              )}

              {this.renderCustomFields()}

              {booleanOptions.length > 0 && (
                <Item label="Options" {...this.itemLayoutOptions}>
                  {booleanOptions.map(([key, value]) => (
                    <span className="property" key={key}>
                      <Checkbox
                        defaultChecked={value}
                        onChange={this.onBoolChange(key)}
                      >
                        {startCase(key)}
                      </Checkbox>
                    </span>
                  ))}
                </Item>
              )}
            </TabPane>
          )}
          {!_disableTabs.includes(TAB_VALUES) && (
            <TabPane
              tab="Values"
              key="TabPane2"
              disabled={_disableTabs.includes(TAB_VALUES)}
            >
              {select && select.length > 0 && (
                <RowWrapper className="update-select-values" key="select">
                  <UpdateSelectOptions
                    options={select}
                    fieldType={type}
                    updateParent={this.onChangeComponent}
                    showMessage={this.showMessage}
                  />
                </RowWrapper>
              )}

              {populateFrom && (
                <RowWrapper className="update-select-values" key="populateFrom">
                  <UpdatePopulateFrom
                    options={populateFrom}
                    fieldType={type}
                    updateParent={this.onChangeComponent}
                  />
                </RowWrapper>
              )}
            </TabPane>
          )}
          {!_disableTabs.includes(TAB_CONDITIONAL) && (
            <TabPane
              tab="Conditional"
              key="TabPane3"
              disabled={_disableTabs.includes(TAB_CONDITIONAL)}
            >
              <RowWrapper>
                <DefineConditional
                  {...conditions}
                  currentFieldId={id}
                  currentFieldKey={fieldKey}
                  updateParent={this.onChangeComponent}
                />
              </RowWrapper>
            </TabPane>
          )}
          {!_disableTabs.includes(TAB_SHARE_KEY) && (
            <TabPane
              tab="Share key"
              key="TabPane4"
              disabled={_disableTabs.includes(TAB_SHARE_KEY)}
            >
              <RowWrapper>
                <ShareKey
                  type={type}
                  currentFieldId={id}
                  currentFieldKey={fieldKey}
                  currentFieldRef={fieldRef}
                  reportingKey={reportingKey}
                  updateParent={this.onChangeComponent}
                />
              </RowWrapper>
            </TabPane>
          )}
          {!_disableTabs.includes(TAB_INFORMATION) && (
            <TabPane
              tab="Information"
              key="TabPane5"
              disabled={_disableTabs.includes(TAB_INFORMATION)}
            >
              <RowWrapper className="information" key="information">
                <ReactQuill
                  theme="snow"
                  value={information}
                  onChange={this.onChangeInformation}
                />
              </RowWrapper>
            </TabPane>
          )}
        </Tabs>

        <RowWrapper>
          {message && (
            <Alert
              message={message}
              type={messageType}
              showIcon
              closable
              onClose={() => this.setState({ message: '' })}
            />
          )}
        </RowWrapper>

        <RowWrapper className="options-footer" key="footer">
          <Col span={4}>
            <Button
              type="danger"
              size="default"
              icon="delete"
              disabled={onDelete === undefined}
              onClick={this.onDelete}
            >
              Delete
            </Button>
          </Col>
          <Col span={20}>
            <Button
              type="default"
              size="default"
              icon="close"
              onClick={this.onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="default"
              icon="save"
              disabled={disabledApply || onSave === undefined}
              onClick={this.onSave}
            >
              Apply
            </Button>
          </Col>
        </RowWrapper>
        {showConfirmation && (
          <OptionsModalConfirm
            placement={placement}
            showConfirmation={showConfirmation}
            affectedFields={affectedFields}
            title={'Please confirm to continue'}
            width={300}
            disabled={onDelete === undefined}
            deleteAffectedFields={() =>
              this.deleteAffectedFields(this.state.affectedFields)
            }
            onCancel={this.onCancel}
          />
        )}
      </ModalBody>
    );
  }
}
