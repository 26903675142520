import styled from 'styled-components';

const Transition = styled.div`
  position: relative;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 6px;
  padding: 15px;

  .transition-content {
    padding-left: 35px;
    padding-right: 35px;
  }

  .remove-transition-btn,
  .transition-number {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .remove-transition-btn {
    font-size: 2em;
    border: none;
    color: #ccc;
    right: 15px;
  }

  .transition-number {
    left: 15px;
    font-size: 1.8em;
  }

  .inline-field-wrapper {
    min-width: 250px;
    margin: 0 8px;

    > .ant-row {
      width: 100%;
    }

    .ant-form-item-label {
      display: none;
    }
  }

  .transition-inline-text,
  .inline-field-wrapper,
  .transition-number {
    display: inline-block;
    vertical-align: middle;
  }

  .transition-inline-text {
    padding: 5px 0;
    font-size: 1.4em;
  }

  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder,
  .ant-form-text-field {
    font-size: 1.2em;
  }
`;

export default Transition;
