import get from 'lodash';
import communityMembersEndpoints from '../../api/organization/communityMembersEndpoints';

export const ORGANIZATION_GET_COMMUNITY_LIST_REQUEST =
  'ORGANIZATION_GET_COMMUNITY_LIST_REQUEST';
export const ORGANIZATION_GET_COMMUNITY_LIST_SUCCESS =
  'ORGANIZATION_GET_COMMUNITY_LIST_SUCCESS';
export const ORGANIZATION_GET_COMMUNITY_LIST_ERROR =
  'ORGANIZATION_GET_COMMUNITY_LIST_ERROR';

const organizationGetCommunityListSuccess = response => {
  return { type: ORGANIZATION_GET_COMMUNITY_LIST_SUCCESS, payload: response };
};

const organizationGetCommunityListError = error => {
  return { type: ORGANIZATION_GET_COMMUNITY_LIST_ERROR, error };
};

export default (tenantId, integrationId, pageNumber, pageSize) => {
  return async dispatch => {
    dispatch({
      type: ORGANIZATION_GET_COMMUNITY_LIST_REQUEST,
      payload: { pageNumber, pageSize },
    });

    try {
      const response = await communityMembersEndpoints.getCommunityList(
        tenantId,
        integrationId,
        pageNumber,
        pageSize
      );
      dispatch(organizationGetCommunityListSuccess(response));
    } catch (e) {
      dispatch(
        organizationGetCommunityListError(get(e, ['response', 'status']))
      );
    }
  };
};
