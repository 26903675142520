import styled from 'styled-components';

import { ERROR, SUCCESS, YELLOW } from '../../../config/theme';

const ModalTitle = styled.div`
  /* stylelint-disable */
  .anticon {
    color: ${props => {
      const { type } = props;
      if (props.error || type === 'error') {
        return ERROR;
      } else if (props.success || type === 'success') {
        return SUCCESS;
      } else if (props.warning || type === 'warning') {
        return YELLOW;
      }
      return '#000';
    }};
  }

  span {
    font-size: 18px;
  }
`;

export default ModalTitle;
