import { get } from 'lodash';

import { GET_FORM_TEMPLATES } from './';
import { getAgencyTemplates } from '../api/reports';
import getFormTemplatesSuccess from './get-form-templates-success';
import getFormTemplatesError from './get-form-templates-error';
import normalizeTemplates from '../utils/normalizeTemplates';
import { defaultTemplatesFilter } from '../constants/filters';

export default (
  customFilters,
  next = e => {},
  agencyIdFromOptions,
  canCreate
) => {
  const filter = customFilters || defaultTemplatesFilter;
  return (dispatch, getState) => {
    const {
      session: { currentUser },
    } = getState();

    const agencyId = agencyIdFromOptions || get(currentUser, 'agencyId');

    dispatch({
      type: GET_FORM_TEMPLATES,
      payload: { agencyId, filter },
    });

    getAgencyTemplates({ filter, agencyId, canCreate })
      .then(response => {
        const templates = normalizeTemplates(response);
        dispatch(getFormTemplatesSuccess(templates));
        next(null, templates);
      })
      .catch(error => {
        dispatch(getFormTemplatesError(get(error, ['response', 'status'])));
        next(error);
      });
  };
};
