import { SET_IS_CONTRIBUTE_REPORT } from '../../actions';
import { hasFeatures, FEATURES } from '../../utils/features';

export default props => {
  const {
    dispatch,
    currentUser: { featureFlags = [] },
  } = props;
  const contributeToReportEnabled = hasFeatures(
    featureFlags,
    FEATURES.enableContributeToReport
  );
  // Stops functional logic of feature from working
  if (!contributeToReportEnabled) return;
  dispatch({
    type: SET_IS_CONTRIBUTE_REPORT,
    payload: {},
  });
};
