import syncRepeaters from './sync-repeaters';
import seedRepeaters from './seed-repeaters';
import { ADD_REPEATER_ITEM, ADD_REPEATER_ITEM_REVIEW } from './';

export default (
  keys,
  templates,
  id = '',
  syncKeys,
  data,
  seedKeys = {},
  symlinkPath,
  fileUploaded = false
) => (dispatch, getState) => {
  const [parent, ...children] = keys;
  const { form: { selectedForm: { isActive } = {} } = {} } = getState();
  dispatch({
    type: isActive ? ADD_REPEATER_ITEM : ADD_REPEATER_ITEM_REVIEW,
    payload: {
      keys,
      templates,
      id,
      syncKeys,
      data,
      symlinkPath,
      fileUploaded,
    },
  });
  dispatch(syncRepeaters(parent, children, syncKeys, isActive));
  if (Object.keys(seedKeys).length) {
    dispatch(seedRepeaters(parent, id, seedKeys));
  }
};
