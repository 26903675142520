import _ from 'lodash';

const recursive_rendering = (string = '', context, stack) => {
  if (!string || _.isPlainObject(string)) {
    return string;
  }

  return Object.keys(context).reduce(function(accumulator, key) {
    const newStack = stack ? stack + '.' : '';
    const find = '\\$\\{\\s*' + newStack + key + '\\s*\\}';
    const re = new RegExp(find, 'g');

    if (typeof context[key] === 'object') {
      return recursive_rendering(accumulator, context[key], newStack + key);
    }
    return accumulator.replace(re, context[key]);
  }, string);
};

export default recursive_rendering;
