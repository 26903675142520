export const GET_AGENCY_REQUEST = 'GET_AGENCY_REQUEST';
export const GET_AGENCY_SUCCESS = 'GET_AGENCY_SUCCESS';
export const GET_AGENCY_ERROR = 'GET_AGENCY_ERROR';

export const GET_UNIT_ASSIGNMENTS_REQUEST = 'GET_UNIT_ASSIGNMENTS_REQUEST';
export const GET_UNIT_ASSIGNMENTS_FULLFILLED =
  'GET_UNIT_ASSIGNMENTS_FULLFILLED';
export const GET_UNIT_ASSIGNMENTS_REJECTED = 'GET_UNIT_ASSIGNMENTS_REJECTED';

export const GET_RANKS_REQUEST = 'GET_RANKS_REQUEST';
export const GET_RANKS_FULLFILLED = 'GET_RANKS_FULLFILLED';
export const GET_RANKS_REJECTED = 'GET_RANKS_REJECTED';

export const GET_AGENCY_SECURITY_ROLES_REQUEST =
  'GET_AGENCY_SECURITY_ROLES_REQUEST';
export const GET_AGENCY_SECURITY_ROLES_FULFILLED =
  'GET_AGENCY_SECURITY_ROLES_FULFILLED';
export const GET_AGENCY_SECURITY_ROLES_REJECTED =
  'GET_AGENCY_SECURITY_ROLES_REJECTED';
