import { camelCase } from 'lodash';
import { PERMISSIONS } from '../../../utils/admin';
import AgencyMigratedFiles from './AgencyMigratedFiles';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/migrated-files/:tenantId`,
    name: camelCase(`${name}-migrated-files`),
    component: AgencyMigratedFiles,
    protectedWith: [PERMISSIONS.searchDocumentMetadata],
    childRoutes: [],
  },
];
