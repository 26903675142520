import isFunction from 'lodash/isFunction';

const defaultOptions = {
  onComplete: () => {},
  onSuccess: () => {},
  onFail: () => {},
};

export default (
  { onSuccess, onFail, onComplete },
  onFailOrSuccessExecutesComplete = true
) => {
  const config = Object.assign({}, defaultOptions, {
    onSuccess,
    onFail,
    onComplete,
  });

  return {
    onSuccess: (...args) => {
      isFunction(config.onSuccess) && onSuccess(...args);
      onFailOrSuccessExecutesComplete &&
        isFunction(config.onComplete) &&
        onComplete(...args);
    },
    onFail: (...args) => {
      isFunction(config.onFail) && onFail(...args);
      onFailOrSuccessExecutesComplete &&
        isFunction(config.onComplete) &&
        onComplete(...args);
    },
    onComplete: (...args) =>
      isFunction(config.onComplete) && onComplete(...args),
  };
};
