import styled from 'styled-components';

import { GRAY_4 } from 'APP_ROOT/config/theme';

const StyledAdministrator = styled.div.attrs({
  className: 'administrator-wrapper',
})`
  .page-header-content {
    .version,
    .status {
      color: ${GRAY_4};
      font-size: 12px;
      vertical-align: sub;
    }

    .status {
      text-transform: capitalize;
    }

    .abbreviation {
      font-size: 0.8em;
    }

    .description {
      width: 92%;

      .subtitle {
        font-weight: 600;
        display: inline-block;
        overflow: hidden;
      }

      .content {
        width: 80%;
        text-overflow: ellipsis;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .administrator-content {
    padding: 6px 16px;
  }

  .empty-state {
    padding: 150px 10px;
    text-align: center;

    @media screen and (max-width: 768px) {
      padding: 50px 10px;
    }

    &,
    h1 {
      color: ${GRAY_4};
    }

    h1 {
      font-size: 3.5em;

      @media screen and (max-width: 768px) {
        font-size: 2.2em;
      }

      @media screen and (max-width: 480px) {
        font-size: 1.8em;
      }
    }
  }
`;

export default StyledAdministrator;
