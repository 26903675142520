import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Panel from './index';
import PanelTitle from './panel-title';
import { GRAY_2 } from '../../../../config/theme';
import { xs } from '../../../../config/breakpoints';

class CollapsePanel extends Component {
  state = {
    isCollapsed: false,
  };

  static propTypes = {
    isCollapsed: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isCollapsed: false,
  };

  UNSAFE_componentWillMount() {
    const { isCollapsed = false } = this.props;
    this.setState({ isCollapsed });
  }

  toggle = e => {
    e.stopPropagation();
    const { isCollapsed } = this.state;
    this.setState({
      isCollapsed: !isCollapsed,
    });
  };

  render() {
    const { children, title = '', subtitle = '' } = this.props;
    const { isCollapsed } = this.state;

    return (
      <Panel
        style={{
          padding: '0 16px',
        }}
      >
        <StyledPanelTitle onClick={this.toggle}>
          <Row type="flex">
            <Col xs={24} sm={12}>
              <h2>{title}</h2>
            </Col>
            <Col xs={24} sm={12} className="text-xs-left text-right">
              <h3>{subtitle}</h3>
            </Col>
          </Row>
        </StyledPanelTitle>

        {!isCollapsed && <StyledContainer>{children}</StyledContainer>}
      </Panel>
    );
  }
}

const StyledPanelTitle = styled(PanelTitle)`
  padding: 16px 0;
  cursor: pointer;

  h2 {
    font-size: 14px;
    line-height: 17px;
  }

  h3 {
    font-size: 12px;
    line-height: 14px;
    color: ${GRAY_2};
  }

  @media screen and (max-width: ${xs}) {
    h3 {
      padding: 8px 0 0 0;
    }
  }
`;

const StyledContainer = styled.div`
  .bdm-panel-section {
    padding: 8px 0;

    p {
      margin: 0;
      font-size: 12px;
      line-height: 14px;
      color: ${GRAY_2};
    }
  }
`;

export default CollapsePanel;
