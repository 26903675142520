import { omit } from 'lodash';

import {
  GET_USER_REQUEST,
  GET_USER_FULLFILLED,
  GET_USER_REJECTED,
} from '../AgencyUsers.actionTypes';
import callback from '../../utils/callback';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';

export default ({ agencyId, userId }, next) => dispatch => {
  dispatch({ type: GET_USER_REQUEST, payload: { userId, agencyId } });

  return new Promise(async (resolve, reject) => {
    organizationEndpoints
      .getUsersSearch(
        agencyId,
        { id: userId, projection: 'DecryptedValue' },
        false
      )
      .then(data => {
        //Using 'omit' from lodash to remove th roles property from the endpoint response because these were causing issues at the moment of click on save button on user profile page.
        //Added rankId to the principal properties of the response from the rank object property due this property was not inside an object on the previous response that was replaced.
        const { rank: { id: rankId = '' } = {} } = data[0];
        const response = { ...omit(data[0], ['roles']), rankId };
        const payload = { agencyId, userId, response };

        dispatch({
          type: GET_USER_FULLFILLED,
          payload,
        });

        callback(next, null, response);
        resolve(payload);
      })
      .catch(error => {
        dispatch({
          type: GET_USER_REJECTED,
          payload: { userId, agencyId, error },
        });

        callback(next, error);
        reject(error);
      });
  });
};
