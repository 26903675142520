import styled from 'styled-components';
import { Modal, Row, Col, Input } from 'antd';

export const StyledModal = styled(Modal)`
  &.ant-modal {
    max-width: 800px;
    width: 100% !important;
    max-height: 800px;
    height: 100% !important;
  }

  &.ant-modal-body {
    padding: 12px 12px 0 12px;
  }
`;

export const StyledRow = styled(Row)`
  &.ant-row {
    padding-top: 5px;
  }
`;

export const StyledCol = styled(Col)`
  &.ant-col {
    padding: 0 5px 0 5px;
  }
`;

export const StyledInput = styled(Input)`
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const StyledLabel = styled.label`
  padding-right: 5px;
`;
