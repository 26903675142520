import React, { Component } from 'react';
import { Form, Input, Button, Icon } from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { updateUserPassword } from '../../../api/session';

import Panel, {
  PanelTitle,
  PanelSection,
  PanelRow,
  Divider,
} from '../../common/panel';

import withModal from '../../common/modal/base';
import ModalTitle from '../../common/modal/title';
import ModalBody from '../../common/modal/body';

const FormItem = Form.Item;

class ChangePassword extends withModal(Component) {
  state = {
    confirmDirty: false,
  };

  componentDidMount() {
    this.createModal();
  }

  componentWillUnmount() {
    this.hideModal();
    this.deleteModal();
  }

  showModalMessage = (message, success) => {
    const Header = success ? (
      <ModalTitle success>
        <Icon type="check-circle" /> <span>Success!</span>
      </ModalTitle>
    ) : (
      <ModalTitle error>
        <Icon type="exclamation-circle" /> <span>Heads up!</span>
      </ModalTitle>
    );
    const Body = (
      <ModalBody>
        <span>{message}</span>
      </ModalBody>
    );

    this.updateModal({
      visible: true,
      title: Header,
      children: Body,
    });

    this.showModal();
  };

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        updateUserPassword(values)
          .then(() => {
            form.resetFields();
            this.showModalMessage('Your password has been updated!', true);
          })
          .catch(() => {
            this.showModalMessage(
              'There is an error with the request. Please check your info again',
              false
            );
          });
      }
    });
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('The two passwords have to match');
    } else {
      callback();
    }
  };

  checkConfirm = (rule, value, callback) => {
    const { form, session } = this.props;
    const {
      currentUser: { email = '' },
    } = session;
    const userID = email.split('@')[0];

    if (value === email || value === userID) {
      callback("Your password can't be the same as your user");
    } else if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 12 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 6 },
        sm: { span: 6 },
      },
    };

    return (
      <Panel>
        <PanelTitle>
          <h2>Change Password</h2>
        </PanelTitle>
        <Divider />
        <PanelSection>
          <PanelRow>
            <Form onSubmit={this.handleSubmit} hideRequiredMark>
              <FormItemStyled
                {...formItemLayout}
                label="Current Password"
                colon={false}
                hasFeedback
              >
                {getFieldDecorator('oldPassword', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ],
                })(<Input type="password" />)}
              </FormItemStyled>
              <FormItemStyled
                {...formItemLayout}
                label="New Password"
                colon={false}
                hasFeedback
              >
                {getFieldDecorator('newPassword', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      min: 8,
                      message: 'Minimum length of eight characters',
                    },
                    {
                      validator: this.checkConfirm,
                    },
                  ],
                })(<Input type="password" />)}
              </FormItemStyled>
              <FormItemStyled
                {...formItemLayout}
                label="Confirm Password"
                colon={false}
                hasFeedback
              >
                {getFieldDecorator('confirmPassword', {
                  rules: [
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    {
                      validator: this.checkPassword,
                    },
                  ],
                })(<Input type="password" onBlur={this.handleConfirmBlur} />)}
              </FormItemStyled>

              <FormItem {...formItemLayout}>
                <Button type="primary" htmlType="submit">
                  Save changes
                </Button>
              </FormItem>
            </Form>
          </PanelRow>
        </PanelSection>
      </Panel>
    );
  }
}

const FormItemStyled = styled(FormItem)`
  .ant-form-item-label {
    text-align: left;
  }
`;

const WrappedChangePassword = Form.create()(ChangePassword);

const mapState = state => ({
  session: state.session,
});

export default connect(mapState, null)(WrappedChangePassword);
