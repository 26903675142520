import NumberFieldOptions from './getNumberFieldOptions';

export default class {
  constructor() {
    this.name = 'number';
    this.label = '';
    this.component = NumberFieldOptions;
    this.defaultValue = { defaultValue: 0, min: 0, max: undefined };
    this.popoverContent = 'Min value must be less than or equal to Max value.';
  }

  isValid = values => {
    const { min = 0, max } = values[this.name];

    return max !== undefined ? min <= max : true;
  };

  onChange = (value, values) => {
    return {
      ...values,
      [this.name]: value,
    };
  };

  onSave = values => {
    const { defaultValue, min, max } = values[this.name];

    return {
      ...values,
      [this.name]: undefined,
      options: {
        defaultValue,
        min,
        max,
      },
    };
  };

  getInitialValue = values => {
    if (values.options === undefined) {
      return this.defaultValue;
    }
    const { defaultValue: _default, min: _min, max: _max } = this.defaultValue;
    const { defaultValue = _default, min = _min, max = _max } = values.options;

    return {
      defaultValue: defaultValue < min ? min : defaultValue,
      min,
      max,
    };
  };
}
