import React, { Component } from 'react';
import { get, first } from 'lodash';
import { connect } from 'react-redux';

import { getFormTemplate, getDataEnums } from 'APP_ROOT/selectors/form';
import { getDataSelector } from 'APP_ROOT/utils/renderSchema';

import ConditionalRender from '../conditional';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';
import { SectionChildren } from './SectionChildren';

class SectionWithConditions extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  childrenSection = (assert, behavior) => {
    const conditionalAssertProps = { assert, behavior };
    const props = Object.assign({}, this.props, conditionalAssertProps);

    return React.createElement(SectionChildren, props);
  };

  render() {
    const {
      conditions = {},
      data = {},
      parentIndex = 0,
      parentKey = '',
      presentation = {},
      tab = 0,
      type,
      properties = [],
    } = this.props;
    const hasParent = !!parentKey;
    const template = first(get(presentation, [tab, parentKey]));
    const groupData = hasParent
      ? get(data, [parentKey, parentIndex], template)
      : data;

    return (
      <ConditionalRender
        type={type}
        properties={properties}
        conditions={conditions}
        data={groupData}
        wholeData={data}
        parentIndex={parentIndex}
        parentKey={parentKey}
        render={this.childrenSection}
      />
    );
  }
}

const mapState = (state, props) => {
  const dataSelector = getDataSelector(props);
  const formTemplate = getFormTemplate(state, props);

  return {
    data: dataSelector(state, props),
    dataEnums: getDataEnums(state, props, formTemplate),
  };
};

export default connect(mapState)(SectionWithConditions);
