import { set } from 'lodash';

import formActionDispatcher from 'APP_ROOT/utils/formDispatchEmitter';
import syncFormData from 'APP_ROOT/actions/sync-form-data';
import syncFormValueChanged from 'APP_ROOT/actions/sync-form-value-changed-boolean';

const saveFieldDataValue = async (props, newValue, autoSave = true) => {
  const { options = {}, parentIndex, parentKey, dataKey } = props;
  const settings = options.settings || props.settings || {};
  const { formName } = settings;

  if (newValue !== props.value) {
    const value = { [dataKey]: newValue };
    let dataValue;
    if (parentKey) {
      dataValue = { [parentKey]: [] };
      set(dataValue, [parentKey, parentIndex], value);
    } else {
      dataValue = value;
    }

    // using setTimeout to avoid problems with race conditions
    setTimeout(() => {
      formActionDispatcher(formName, syncFormData(dataValue));
      autoSave && formActionDispatcher(formName, syncFormValueChanged(true));
    }, 100);
  }
};

export default saveFieldDataValue;
