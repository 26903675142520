import { isObject } from 'lodash';

// for sections and fields, title could be an string or
// {
//   conditions:[],
//   defaultTitle,
//   source: '',
// }

const setTitle = (title, value) =>
  isObject(title) ? { ...title, defaultTitle: value } : value;

export default setTitle;
