import React from 'react';

import MainTitle from '../../../components/common/main-title';
import GeneralInformationPanel from '../../../components/dashboard/general-information-panel';
import EvaluationScorePanel from '../../../components/dashboard/evaluation-score-panel';
import DashboardPage from '../../../components/dashboard';

const PerformanceEvaluationPage = props => (
  <div>
    <MainTitle>Performance Evaluation</MainTitle>
    <GeneralInformationPanel />
    <EvaluationScorePanel />
  </div>
);

const mapState = () => ({});

export default DashboardPage(mapState, null)(PerformanceEvaluationPage);
