import {
  FETCH_FORGOT_PASSWORD_REQUEST,
  FETCH_FORGOT_PASSWORD_SUCCESS,
  FETCH_FORGOT_PASSWORD_ERROR,
} from '../actions';

import { createReducer } from 'redux-create-reducer';

const initialState = {
  username: {
    value: null,
    error: null,
    isLoading: false,
  },
};

const forgotPassword = {
  [FETCH_FORGOT_PASSWORD_REQUEST]: (state, { payload }) => ({
    ...state,
    username: { value: payload.username },
    error: null,
    isLoading: true,
  }),
  [FETCH_FORGOT_PASSWORD_SUCCESS]: (state, { payload }) => ({
    ...state,
    ...payload,
    error: null,
    isLoading: false,
  }),
  [FETCH_FORGOT_PASSWORD_ERROR]: (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }),
};

export default createReducer(initialState, forgotPassword);
