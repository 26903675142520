import urlBuilder from 'APP_ROOT/utils/url-builder';
import { genOptions, route, fetchRequest } from 'APP_ROOT/utils/request';
import storage from 'APP_ROOT/utils/storage';

/**
 * Requests node-api a download link.
 * It will be an S3 link for S3 uploaded attachments
 * and node-api link for old-stored attachments.
 * @returns {Promise<request>}
 * @param attachmentId
 */
const getDownloadURL = (props, attachmentId) => {
  const { meta: { id = '' } = {} } = props;
  const options = genOptions('GET', null, storage.get('token'));
  const request = route(
    urlBuilder('/Forms/:id/download/request-url?id=:attachmentId', {
      id,
      attachmentId,
    })
  );
  return fetchRequest(request, options);
};

export default getDownloadURL;
