import React, { Component } from 'react';
import bigDecimal from 'js-big-decimal';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';

import saveFieldDataValue from './saveFieldDataValue';
import ReviewerField from '../reviewer-field';

import computeMathFieldValue, { applyRounding } from './computeMathFieldValue';

class MathField extends Component {
  constructor(props) {
    super(props);
    const number = applyRounding(props, new bigDecimal(0));
    this.defaultValue = number.getValue();
  }

  componentDidMount() {
    this.computeMath();
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    this.computeMath();
  }

  computeMath = () => {
    const { value = this.defaultValue } = this.props;
    const newValue = computeMathFieldValue(this.props);
    if (value !== newValue) {
      saveFieldDataValue(this.props, newValue);
    }
  };

  render() {
    const { calculatedValue } = this.props;
    const evaluateValue =
      calculatedValue || this.props.value || this.defaultValue;
    return <ReviewerField value={evaluateValue} />;
  }
}

export default MathField;
