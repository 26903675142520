import {
  has,
  get,
  set as setObject,
  first,
  isPlainObject,
  keys,
  some,
  merge,
  orderBy,
  omit,
  cloneDeep,
  isEmpty,
  pick,
} from 'lodash';
import { v4 as uuid } from 'uuid';

import { mapFormData, mapFormState } from '../../utils/form';

import {
  GET_FORM_ACTIONS_ERROR,
  GET_FORM_ACTIONS_REQUEST,
  GET_FORM_ACTIONS_SUCCESS,
  POST_DRAFT_REPORT_SUCCESS,
  POST_REPORT_REQUEST,
  SYNC_FORM_DATA,
  SYNC_FORM_VALIDATION,
  SYNC_FORM_VALUE_CHANGED_BOOLEAN,
  SYNC_FORM_HISTORY_ADMIN_EDIT_REPORT,
  SET_FORM_VALUES,
  UPDATE_DRAFT_REPORT_SUCCESS,
  REQUEST_FORM_SUBMIT,
  SUBMIT_FORM,
  POST_FORM_LINK_SUCCESS,
  DELETE_FORM_LINK_SUCCESS,
  POST_REPORT_SUCCESS,
  ADD_USER_REPORT_SUCCESS,
  POST_REPORT_ERROR,
  UPDATE_ATTACHMENT,
  SET_ENCRYPTED_KEY,
  ADD_SELECTED_FORM_COPY,
  ADD_EXTERNAL_ID_TO_FORM_DATA,
  REPLACE_SELECTED_FORM_COPY,
  REMOVE_SELECTED_FORM_COPY,
} from '../../actions';

import selectedFormInitialState from './state/selected-form';
import formSectionIntialState from './state/form-section';

const events = {
  [SYNC_FORM_DATA]: (state, { payload }) => {
    const { selectedForm: currentForm, presentations = {} } = state;

    const {
      data: currentFormData,
      state: currentState,
      presentationType = '',
      sources: currentSources = {},
      syncs = {},
    } = currentForm;

    const presentation = presentations[presentationType];

    const mappedData = mapFormData(payload.data, currentForm.data);

    const data = {
      ...presentation,
      ...currentFormData,
      ...mappedData,
    };

    const syncedRepeaters = Object.keys(syncs).reduce((acc, syncerKey) => {
      const { syncTo, syncKeys } = syncs[syncerKey];
      const parent = get(data, [syncerKey]);
      const reducedData = syncTo.reduce((result, key, index) => {
        const childData = get(data, [key]);
        const syncedChild = childData.map(childItem => {
          const parentItem =
            parent.find(parentItem => parentItem.id === childItem.__parentId) ||
            first(parent);
          const parentIndex =
            parent.findIndex(
              parentItem => parentItem.id === childItem.__parentId
            ) || 0;
          const syncedKeys = !syncKeys
            ? {}
            : Object.keys(syncKeys[key] || {}).reduce(
                (acc, k) => ({
                  ...acc,
                  [syncKeys[key][k]]: parentItem[k],
                }),
                {}
              );
          return {
            ...childItem,
            ...syncedKeys,
            __parentId: parentItem.id,
            __parentIndex: parentIndex,
            __parentHumanIndex: parentIndex + 1,
          };
        });
        result[key] = orderBy(syncedChild, ['__parentIndex']);
        return result;
      }, {});
      return {
        ...acc,
        ...reducedData,
      };
    }, {});
    const syncedData = {
      ...data,
      ...syncedRepeaters,
    };

    const formState = {
      ...currentState,
      ...mapFormState(payload.state, currentForm.state),
    };

    const validations = {
      ...currentForm.validations,
      form: {
        ...currentForm.validations.form,
        touched: true,
        submitting: false,
      },
    };

    const sources = {
      ...currentSources,
      ...Object.keys(payload.data).reduce((result, key) => {
        const itemData = get(payload, ['data', key], '');
        const firstItem = Array.isArray(itemData)
          ? first(itemData.filter(item => item))
          : '';
        const isObject = isPlainObject(firstItem);
        const newId = uuid();

        if (isObject) {
          const index = first(Object.keys(itemData));
          const parentKey = currentSources[key];
          const hasParent = has(currentSources, parentKey);

          if (hasParent) {
            const id = get(currentSources, [parentKey, index, 'id'], newId);

            return {
              ...result,
              [parentKey]: {
                ...get(currentSources, [parentKey], {}),
                [index]: {
                  ...get(currentSources, [parentKey, index], {}),
                  ...get(data, [key, index], {}),
                  ...firstItem,
                  id,
                },
              },
            };
          }
        }

        return {};
      }, {}),
    };

    const selectedForm = {
      ...currentForm,
      data: syncedData,
      state: formState,
      validations,
      sources,
      changedFields: payload.data,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SYNC_FORM_VALIDATION]: (
    state,
    { payload: { errors, bulk, reset = false } }
  ) => {
    let sections = {
      ...state.selectedForm.validations.sections,
    };
    let tabsWithErrors;

    const fstate = errors
      ? merge(cloneDeep(state.selectedForm.state), cloneDeep(errors))
      : state.selectedForm.state;

    if (!!bulk) {
      tabsWithErrors = [];
      sections = keys(bulk).reduce((result, tab, index) => {
        if (bulk[tab].length > 0) {
          tabsWithErrors.push(index);
        }
        return {
          ...result,
          [tab]: {
            ...sections[tab],
            dirty: !!bulk[tab].length > 0,
            errors: bulk[tab],
          },
        };
      }, {});
    } else {
      tabsWithErrors = state.selectedForm.validations.tabsWithErrors;
      sections = {
        ...sections,
        [state.selectedForm.tab]: {
          ...formSectionIntialState,
          dirty: !!errors,
          touched: true,
          errors: errors || [],
        },
      };
    }

    const formDirty = some(
      keys(sections).map(section => sections[section].dirty),
      Boolean
    );

    const form = {
      ...state.selectedForm.validations.form,
      dirty: formDirty,
      submitting: false,
    };

    const validations = {
      ...state.selectedForm.validations,
      form,
      sections,
      tabsWithErrors,
    };

    const selectedForm = {
      ...state.selectedForm,
      state: reset ? {} : fstate,
      validations,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_FORM_VALUES]: (state, { payload: fields }) => {
    const currentSelectedForm = Object.assign({}, state.selectedForm);
    const currentData = cloneDeep(currentSelectedForm.data);
    Object.keys(fields).forEach(field => {
      setObject(currentData, field, fields[field]);
    });

    const selectedForm = {
      ...currentSelectedForm,
      data: currentData,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [POST_DRAFT_REPORT_SUCCESS]: (
    { selectedForm, ...state },
    { payload: { report } }
  ) => ({
    selectedForm: {
      ...selectedForm,
      data: {
        ...selectedForm.data,
        ...pick(report.data, [
          '__assignedSections',
          '__visibleToAllContributorSections',
        ]),
      },
      meta: report,
    },
    ...state,
  }),
  [UPDATE_DRAFT_REPORT_SUCCESS]: (
    { selectedForm, ...state },
    { payload: { report } }
  ) => ({
    selectedForm: {
      ...selectedForm,
      meta: {
        ...selectedForm.meta,
        ...omit(report, 'notes'),
      },
    },
    ...state,
  }),
  [GET_FORM_ACTIONS_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [GET_FORM_ACTIONS_SUCCESS]: (
    state,
    { payload: { actions = [], resolutionLabel = '', behaviours = [] } }
  ) => ({
    ...state,
    loading: false,
    actions,
    resolutionLabel,
    behaviours,
  }),
  [GET_FORM_ACTIONS_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [POST_REPORT_REQUEST]: state => {
    const form = {
      ...get(state, ['selectedForm', 'validations', 'form'], {}),
      submitRequested: true,
    };

    const validations = {
      ...get(state, ['selectedForm', 'validations'], {}),
      form,
    };

    const selectedForm = {
      ...get(state, ['selectedForm'], selectedFormInitialState),
      validations,
    };

    return {
      ...state,
      loading: true,
      selectedForm,
    };
  },
  [POST_REPORT_SUCCESS]: state => ({
    ...state,
    loading: false,
  }),
  [ADD_USER_REPORT_SUCCESS]: (state, { payload }) => {
    const users = {
      ...get(state, 'selectedForm.data.__users', {}),
      [payload.id]: {
        ...payload,
      },
    };

    const data = {
      ...get(state, 'selectedForm.data', {}),
      __users: users,
    };

    const selectedForm = {
      ...get(state, 'selectedForm', {}),
      data,
    };

    return {
      ...state,
      selectedForm,
    };
  },

  [POST_REPORT_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [REQUEST_FORM_SUBMIT]: state => {
    const form = {
      ...get(state, ['selectedForm', 'validations', 'form'], {}),
      submitRequested: true,
    };

    const validations = {
      ...get(state, ['selectedForm', 'validations'], {}),
      form,
    };

    const selectedForm = {
      ...get(state, ['selectedForm'], selectedFormInitialState),
      validations,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SUBMIT_FORM]: state => {
    const form = {
      ...get(state, ['selectedForm', 'validations', 'form'], {}),
      submitting: true,
    };

    const validations = {
      ...get(state, ['selectedForm', 'validations'], {}),
      form,
    };

    const selectedForm = {
      ...get(state, ['selectedForm'], selectedFormInitialState),
      validations,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [POST_FORM_LINK_SUCCESS]: (
    { selectedForm, selectedForm: { meta }, ...state },
    { payload: { formLink } }
  ) => {
    return {
      ...state,
      selectedForm: {
        ...selectedForm,
        meta: {
          ...meta,
          linkedSubmissions: [
            ...get(meta, 'linkedSubmissions', []),
            formLink.end,
          ],
          formLinks: [...get(meta, 'linkedSubmissions', []), formLink],
          formLinksId: [...get(meta, 'formLinksId', []), formLink.id],
        },
      },
    };
  },
  [DELETE_FORM_LINK_SUCCESS]: (
    { selectedForm, selectedForm: { meta }, ...state },
    {
      payload: {
        formLink,
        formLink: { start, end },
      },
    }
  ) => {
    const { linkedSubmissions = [], formLinksId = [], formLinks = [] } = meta;

    return {
      ...state,
      selectedForm: {
        ...selectedForm,
        meta: {
          ...meta,
          linkedSubmissions: linkedSubmissions.filter(
            s => s.id !== start.id && s.id !== end.id
          ),
          formLinks: formLinks.filter(fl => fl.id !== formLink.id),
          formLinksId: formLinksId.filter(fli => fli !== formLink.id),
        },
      },
    };
  },
  [UPDATE_ATTACHMENT]: (state, { payload: { attachment, field } }) => {
    const currentSelectedForm = state.selectedForm;
    const currentData = currentSelectedForm.data;

    const replacedAttachments = get(currentData, field, []).map(file => {
      if (file.id === attachment.id) {
        return {
          ...file,
          ...attachment,
        };
      }

      return file;
    });

    const selectedForm = isEmpty(replacedAttachments)
      ? currentSelectedForm
      : {
          ...currentSelectedForm,
          data: {
            ...currentData,
            [field]: replacedAttachments,
          },
        };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_ENCRYPTED_KEY]: (state, { payload: { key } }) => {
    const {
      selectedForm: {
        data: { encrypted: encryptedData = [], ...data },
        ...selectedForm
      },
    } = state;

    const encrypted = encryptedData.includes(key)
      ? encryptedData
      : encryptedData.concat(key);

    return {
      ...state,
      selectedForm: {
        ...selectedForm,
        data: {
          ...data,
          encrypted,
        },
      },
    };
  },
  [SYNC_FORM_VALUE_CHANGED_BOOLEAN]: (state, { payload = false }) => {
    const { selectedForm: selected } = state;

    const selectedForm = {
      ...selected,
      isValuechanged: payload,
    };
    return {
      ...state,
      selectedForm,
    };
  },
  [SYNC_FORM_HISTORY_ADMIN_EDIT_REPORT]: (state, history = []) => {
    const { payload } = history;
    const { selectedForm } = state;
    const { meta } = selectedForm;
    return {
      ...state,
      selectedForm: {
        ...selectedForm,
        meta: {
          ...meta,
          history: payload,
          transactionHistory: payload,
        },
      },
    };
  },
  [ADD_SELECTED_FORM_COPY]: state => {
    const { selectedForm } = state;
    return {
      selectedFormCopy: selectedForm,
      ...state,
      selectedForm: {
        ...selectedForm,
        isAdminEdit: true,
        isActive: true,
      },
    };
  },
  [ADD_EXTERNAL_ID_TO_FORM_DATA]: (state, { payload: externalId = null }) => {
    const { selectedForm } = state;
    const { data: formData } = selectedForm;
    return {
      ...state,
      selectedForm: {
        ...selectedForm,
        data: {
          ...formData,
          externalId,
        },
      },
    };
  },
  [REPLACE_SELECTED_FORM_COPY]: state => {
    const { selectedFormCopy, selectedForm, ...stateProps } = state;
    const form = selectedFormCopy ? selectedFormCopy : selectedForm;
    return {
      selectedForm: form,
      ...stateProps,
    };
  },
  [REMOVE_SELECTED_FORM_COPY]: state => {
    const { selectedFormCopy, selectedForm, ...stateWithoutFormCopy } = state;
    return {
      ...stateWithoutFormCopy,
      selectedForm: {
        ...selectedForm,
        isAdminEdit: false,
        isActive: selectedFormCopy.isActive,
      },
    };
  },
};

export default events;
