import * as Rx from 'rxjs';
import { get, merge } from 'lodash';

export const TrackingChangeTypes = {
  PROPERTY_CHANGE: 'PROPERTY_CHANGE',
  VALIDATION_CHANGE: 'VALIDATION_CHANGE',
  VISIBILITY_CHANGE: 'VISIBILITY_CHANGE',
  EDIT_TOGGLE: 'EDIT_TOGGLE',
};

class ChangesTracker {
  constructor() {
    this.unpublishedChanges$ = new Rx.BehaviorSubject({});
    this.publishedChanges$ = new Rx.BehaviorSubject({});
    this.activeField$ = new Rx.BehaviorSubject(null);
    this.saveChanges$ = new Rx.Subject();
  }

  push({
    type,
    id,
    data: changedData,
    validations = [],
    removeProperty = false,
  }) {
    const changes = this.unpublishedChanges;

    if (!TrackingChangeTypes[type] || id === undefined) {
      return this.unpublishedChanges;
    }

    const data = Object.entries(
      Object.assign({}, get(changes, `${type}.${id}.data`, {}), changedData)
    ).reduce((dataChanges, [propertyName, propertyValue]) => {
      if (removeProperty) {
        return dataChanges;
      }

      return Object.assign({}, dataChanges, {
        [propertyName]: propertyValue,
      });
    }, {});

    const typeChanges = Object.entries(
      Object.assign({}, get(this.unpublishedChanges, type, {}), {
        [id]: {
          id,
          data,
          meta: {
            validations,
          },
        },
      })
    ).reduce((_typeChanges, [id, fieldChanges]) => {
      if (Object.keys(get(fieldChanges, 'data', {})).length === 0) {
        return _typeChanges;
      }

      return Object.assign({}, _typeChanges, {
        [id]: fieldChanges,
      });
    }, {});

    this.unpublishedChanges$.next(
      Object.assign({}, this.unpublishedChanges, {
        [type]: typeChanges,
      })
    );

    return this.unpublishedChanges;
  }

  setActiveFieldEditor(id) {
    this.activeField$.next(id);
  }

  getPublishedFieldChanges(fieldId, type) {
    const changes = this.publishedChanges;
    const field = get(changes, `${type}.${fieldId}`, {});

    return Object.assign({}, field, { data: get(field, 'data', {}) });
  }

  getUnpublishedFieldChanges(fieldId, type) {
    const changes = this.unpublishedChanges;
    const field = get(changes, `${type}.${fieldId}`, {});

    return Object.assign({}, field, { data: get(field, 'data', {}) });
  }

  getPublishedTypeChanges(type) {
    const changes = this.publishedChanges;

    return get(changes, type, {});
  }

  getUnpublishedTypeChanges(type) {
    const changes = this.unpublishedChanges;

    return get(changes, type, {});
  }

  hasPublishedChanges(type) {
    const changes = this.publishedChanges;

    return Object.keys(get(changes, type, {})).length > 0;
  }

  hasUnpublishedChanges(type) {
    const changes = this.unpublishedChanges;

    return Object.keys(get(changes, type, {})).length > 0;
  }

  hasPublishedFieldChanges(fieldId, type) {
    const changes = this.getPublishedTypeChanges(type);

    return Object.keys(get(changes, fieldId, {})).length > 0;
  }

  hasUnpublishedFieldChanges(fieldId, type) {
    const changes = this.getUnpublishedTypeChanges(type);

    return Object.keys(get(changes, fieldId, {})).length > 0;
  }

  onSave() {
    this.saveChanges$.next(new Date());
  }

  publishChanges() {
    this.publishedChanges$.next(
      merge(this.publishedChanges, this.unpublishedChanges)
    );
  }

  clear() {
    this.unpublishedChanges$.next({});
    this.publishedChanges$.next({});
    this.activeField$.next(null);
  }

  resetUnpublishedChanges() {
    this.unpublishedChanges$.next({});
  }

  getPublishedChanges() {
    return this.publishedChanges$.asObservable();
  }

  getUnpublishedChanges() {
    return this.unpublishedChanges$.asObservable();
  }

  getActiveField() {
    return this.activeField$.asObservable();
  }

  getSaveChanges() {
    return this.saveChanges$.asObservable();
  }

  get lastSavedAt() {
    return this.saveChanges$.value;
  }

  get publishedChanges() {
    return this.publishedChanges$.value;
  }

  get unpublishedChanges() {
    return this.unpublishedChanges$.value;
  }

  get activeField() {
    return this.activeField$.value;
  }
}

export default new ChangesTracker();
