import styled from 'styled-components';
import { Modal } from 'antd';

const StyledModal = styled(Modal)`
  .ant-modal-title {
    font-size: 16px;
    font-weight: 600;
  }

  .ant-form-item {
    margin-top: 24px;
  }
`;

export default StyledModal;
