import React from 'react';

import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';

import IconButton from '../../common/buttons/icon-button';

const onRemoveClick = (formName, ...args) => e =>
  emitFormEvent('removeRepeaterItem', formName, ...args);

const RemoveButton = ({
  keys = [],
  removeKeys = [],
  removeRefKeys = [],
  item,
  itemData = {},
  show = true,
  deleteModalOptions,
  showConfirmation = true,
  formName,
}) => {
  if (!show) {
    return null;
  }

  return (
    <IconButton
      className="btn-repeater-delete"
      onClick={onRemoveClick(
        formName,
        keys,
        item,
        itemData,
        removeKeys,
        removeRefKeys,
        deleteModalOptions,
        showConfirmation
      )}
    >
      <img src={require('APP_ROOT/icons/bt_delete_x.svg')} alt="Remove Item" />
    </IconButton>
  );
};

export default RemoveButton;
