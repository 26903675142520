import each from 'lodash/each';

export default ({
  fields,
  property,
  index,
  getPresentation,
  withProfileData,
}) => {
  fields['hasTabs'] = true;
  fields['fields'][index] = {};

  each(
    property.properties,
    getPresentation({
      parent: fields['fields'][index],
      parentKey: null,
      getPresentation,
      withProfileData,
    })
  );
};
