import { get } from 'lodash';

import {
  CALCULATED_FIELD_NAME,
  CALCULATED_FIELD_LABEL,
  CALCULATED_DEFAULT_VALUE,
  EMPTY_OPTION,
} from '../../constants/calculated';
import { CASE_NUMBER, EVENT_DATE } from '../../constants/constants';
import CalculatedFieldOptions from './getCalculatedFieldOptions';

import componentsManager from '../../services/componentsManager';
import calculatedFieldsConfig from '../../services/calculatedFieldsConfig';
import tabsManager from '../../services/tabsManager';
import getAllFieldsFlat from '../getAllFieldsFlat';
import { AUTOCOMPLETE } from 'APP_ROOT/constants/fieldTypes';

const getUserProfileListByTpe = type => {
  const userProfileList = calculatedFieldsConfig.calculatedFieldConfig || [];
  return userProfileList.filter(p => p.type === type);
};

export default class calculatedField {
  constructor(type) {
    this.name = CALCULATED_FIELD_NAME;
    this.label = CALCULATED_FIELD_LABEL;
    this.component = CalculatedFieldOptions;
    this.defaultValue = CALCULATED_DEFAULT_VALUE;

    // includes currentField
    const { allTabs } = getAllFieldsFlat(componentsManager.fields);
    // seems that we can only set value to a select
    this.autocompleteFields = [EMPTY_OPTION].concat(
      allTabs.map(tab => ({
        label: tab.title,
        enums: tab.properties
          .filter(p => p.field_type === AUTOCOMPLETE)
          .map(p => ({
            label: p.title || p.reportingKey || p.field_type,
            value: p.key,
          })),
      }))
    );

    if (tabsManager.activeTabIndex < 0) {
      const option = this.autocompleteFields.pop();
      this.autocompleteFields = [...this.autocompleteFields, ...option.enums];
    }

    this.userProfileKeys = getUserProfileListByTpe(type);
  }

  getInitialValue = values => {
    const value = get(values, this.name);

    return value || this.defaultValue;
  };

  onChange = (value, values) => {
    const { readOnly, required: req } = values;
    const hasKey = value.key !== CALCULATED_DEFAULT_VALUE.key;

    const required = req ? { ...req, value: false } : undefined;
    const changed = {
      ...values,
      [this.name]: value,
      readOnly: {
        ...readOnly,
        value: hasKey,
      },
      required,
    };

    return changed;
  };

  onSave = values => {
    const fromSource = values[this.name];

    // set undefined value to make sure it is removed
    // when saved in the main structure
    return fromSource.key === CALCULATED_DEFAULT_VALUE.key
      ? {
          ...values,
          dependsOn: undefined,
          [this.name]: undefined,
          asReviewer: undefined,
        }
      : {
          ...values,
          [this.name]: { ...fromSource },
          dependsOn: fromSource.field,
          asReviewer: true,
        };
  };

  isHidden = values => {
    return get(values, CASE_NUMBER, false) || get(values, EVENT_DATE, false);
  };
}
