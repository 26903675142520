import { get } from 'lodash';
import { GET_FORM_ACTIONS_REQUEST } from './';
import { getActions } from '../api/reports';
import getFormActionsSuccess from './get-form-actions-success';
import getFormActionsError from './get-form-actions-error';

export default (
  { agencyId, formSubmissionId = '', templateId },
  next = () => {}
) => async dispatch => {
  dispatch({ type: GET_FORM_ACTIONS_REQUEST });

  return new Promise(async resolve => {
    try {
      const actions = await getActions({
        agencyId,
        formSubmissionId,
        templateId,
      });
      dispatch(getFormActionsSuccess(actions));
      next(false, actions);
      resolve(actions);
    } catch (e) {
      dispatch(getFormActionsError(get(e, ['response', 'status'])));
      next(e);
    }
  });
};
