import React, { useState, useEffect } from 'react';
import { debounce, isEmpty } from 'lodash';
import { Select, DatePicker, Icon } from 'antd';
import moment from 'moment';

import {
  StyledFilterCol,
  ValidationLabel,
} from '../../audit-logs/audit-logs-table.styled';

import { BENCHMARK_DATE_FORMAT } from '../../../utils/parse-date';
import { tablesNames } from './auditViewLogs.constants';
import { getOfficersAndCivilians } from '../../../api/users';
import getAuditLogs from '../../../actions/organizations/get-view-audit-logs';

const AuditViewLogsSearchFilters = ({
  agencyId,
  tenantId,
  dispatch,
  auditPageNumber,
  auditPageSize,
}) => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [userSelectOptions, setUserSelectOptions] = useState([]);
  const [executedBySelectOptions, setExecutedBySelectOptions] = useState([]);
  const [onChangeSelectUserValue, setOnChangeSelectUserValue] = useState('');
  const [onChangeExecutedByValue, setOnChangeExecutedByValue] = useState('');
  const [onChangeTablesSelectValue, setOnChangeTablesSelectValue] = useState(
    []
  );
  const [
    onChangeDateRangeDatePickerValue,
    setOnChangeDateRangeDatePickerValue,
  ] = useState([]);

  const useEffectDependencies = [
    onChangeTablesSelectValue,
    onChangeSelectUserValue,
    onChangeExecutedByValue,
    onChangeDateRangeDatePickerValue,
    auditPageNumber,
  ];

  useEffect(() => {
    const areFiltersFill =
      !isEmpty(onChangeDateRangeDatePickerValue) &&
      (!isEmpty(onChangeTablesSelectValue) ||
        onChangeSelectUserValue ||
        onChangeExecutedByValue)
        ? true
        : false;

    if (areFiltersFill) {
      dispatch(
        getAuditLogs(
          auditPageNumber - 1,
          auditPageSize,
          tenantId,
          onChangeSelectUserValue,
          onChangeExecutedByValue,
          onChangeDateRangeDatePickerValue[0],
          onChangeDateRangeDatePickerValue[1],
          onChangeTablesSelectValue
        )
      );
    }
  }, useEffectDependencies);

  const fillInputsMessage = (
    <ValidationLabel>
      <Icon type="warning" theme="twoTone" />
      Select a date range and at least one other filter to view audit logs.
    </ValidationLabel>
  );

  const selectUsersOptionsBuilder = users => {
    return users.map(({ integrationId, fullName }) => {
      return (
        <Option key={integrationId} value={integrationId}>
          {fullName}
        </Option>
      );
    });
  };

  const multiSelectTablesOptionBuilder = tablesNames.map(name => {
    return <Option key={name}>{name}</Option>;
  });

  const disabledDate = current => current && current.valueOf() > Date.now();

  const onChangeTablesSelect = value => {
    setOnChangeTablesSelectValue(value);
  };

  const onUserSearch = debounce(async value => {
    if (value) {
      const users = await getOfficersAndCivilians(agencyId, {
        fields: { fullName: value },
      });
      const userOptions = await selectUsersOptionsBuilder(users);
      setUserSelectOptions(userOptions);
    }
  }, 800);

  const onExecutedBySearch = debounce(async value => {
    if (value) {
      const users = await getOfficersAndCivilians(agencyId, {
        fields: { fullName: value },
      });
      const userOptions = await selectUsersOptionsBuilder(users);
      setExecutedBySelectOptions(userOptions);
    }
  }, 800);

  const onChangeUserSelect = value => {
    setOnChangeSelectUserValue(value);
  };

  const onSelectUserSelect = value => {};

  const onChangeExecutedBySelect = value => {
    setOnChangeExecutedByValue(value);
  };

  const onSelectExecutedBySelect = value => {};

  const onChangeDatePickerChange = value => {
    const dateTranformed = [
      moment(value[0]).format(BENCHMARK_DATE_FORMAT),
      moment(value[1])
        .add(1, 'days')
        .format(BENCHMARK_DATE_FORMAT),
    ];
    setOnChangeDateRangeDatePickerValue(dateTranformed);
  };

  return (
    <>
      <StyledFilterCol md={6} sm={8} xs={24}>
        {fillInputsMessage}
      </StyledFilterCol>
      <StyledFilterCol md={4} sm={8} xs={24}>
        <Select
          style={{ width: '100%' }}
          size="default"
          mode="multiple"
          aria-label="Select Tables"
          placeholder="Select Tables"
          onChange={onChangeTablesSelect}
        >
          {multiSelectTablesOptionBuilder}
        </Select>
      </StyledFilterCol>
      <StyledFilterCol md={4} sm={8} xs={24}>
        <Select
          showSearch
          aria-label="Select User"
          placeholder="Select User"
          style={{ width: '100%' }}
          size="default"
          filterOption={false}
          onSearch={onUserSearch}
          onChange={onChangeUserSelect}
          onSelect={onSelectUserSelect}
          allowClear
        >
          {userSelectOptions}
        </Select>
      </StyledFilterCol>
      <StyledFilterCol md={4} sm={8} xs={24}>
        <Select
          showSearch
          aria-label="Executed By"
          placeholder="Executed By"
          style={{ width: '100%' }}
          size="default"
          filterOption={false}
          onSearch={onExecutedBySearch}
          onChange={onChangeExecutedBySelect}
          onSelect={onSelectExecutedBySelect}
          allowClear
        >
          {executedBySelectOptions}
        </Select>
      </StyledFilterCol>
      <StyledFilterCol md={8} xl={6} sm={8} xs={24}>
        <RangePicker
          format={BENCHMARK_DATE_FORMAT}
          size="default"
          className="full-width"
          disabledDate={disabledDate}
          onChange={onChangeDatePickerChange}
        />
      </StyledFilterCol>
    </>
  );
};

export default AuditViewLogsSearchFilters;
