import React from 'react';
import cx from 'classnames';

import mapFields from '../../utils/mapFields';
import PickableZone from '../PickableZone';

const TabContent = ({ tab }) => (
  <div className={cx('form-builder__tab-content')}>
    <PickableZone type={tab.type} id={tab.id}>
      {mapFields(tab)}
    </PickableZone>
  </div>
);

export default TabContent;
