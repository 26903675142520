import get from 'lodash/get';

import shouldExecuteEffect from './shouldExecuteEffect';
import onItemsChangeEffect from './onItemsChangeEffect';

export default (items, prevItems, props) => {
  const formName = get(props, 'settings.formName', 'form');
  const options = get(props, 'options', {});
  const onItemsChange = get(options, 'onItemsChange', []);
  const parentKey = get(props, 'dataKey', '');
  const data = get(props, 'data', {});

  onItemsChange.forEach(effectDescription => {
    const type = get(effectDescription, 'type');
    const effect = get(effectDescription, 'effect');

    shouldExecuteEffect(type, items, prevItems) &&
      onItemsChangeEffect(type, {
        effect,
        items,
        prevItems,
        formName,
        parentKey,
        data,
        props,
      });
  });
};
