import styled from 'styled-components';

const className = 'form-builder';

const offsetTop = 40;

const FormBuilderWrapper = styled.div.attrs({
  className: `${className} ant-row-flex`,
})`
  .${className}__sidebar {
    width: 300px;
    overflow: auto;
    background-color: white;

    .${className}__sidebar__content {
      box-shadow: 1px 0 0 rgba(0, 0, 0, 0.1);

      .divider {
        margin-top: 50px;
        margin-bottom: 25px;
      }

      .controls {
        .label {
          margin-right: 10px;
        }

        .select-layout {
          width: 100px;
        }
      }
    }
  }

  .${className}__workspace {
    flex: 1;
    overflow: auto;
    padding-bottom: ${offsetTop}px;
  }
`;

export default FormBuilderWrapper;
