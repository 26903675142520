import React, { Component } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  AutoComplete,
  Select,
  Tooltip,
  Icon,
  Divider,
  notification,
  Modal,
} from 'antd';
import { debounce, isEmpty, get, omit } from 'lodash';

import organizationEndpoints from 'APP_ROOT/api/organization/organizationEndpoints';
import {
  ContentWrapper,
  StyleInputReadOnly,
  StyledPhoneNumberReadOnly,
  formItemLayout,
  inputTextAreaLayout,
  ButtonPrimaryWrapper,
  ButtonRemoveWrapper,
} from './PointOfContactModal.styled';
import { PhoneNumberField } from 'APP_COMPONENTS/form-viewer/field-types/input-masked';
import { EMAIL } from 'APP_ROOT/modules/FormBuilder/constants/fieldMasked';
import { REG_EXP_PATTERNS_STRING } from 'APP_ROOT/modules/FormBuilder/constants/fieldPatterns';

class PointOfContactForm extends Component {
  state = {
    isAddButtonDisabled: true,
    isApplyChangesButtonDisabled: false,
    userSelectOptions: [],
    isValidEmail: false,
    isEmailValueEmpty: true,
    userIntegrationId: '',
    areAdditionalFieldsReadOnly: false,
    isButtonLoading: false,
    isDeleteButtonLoading: false,
  };

  componentDidMount() {
    const {
      contactData = {},
      viewPointOfContact,
      managePointContact,
    } = this.props;
    const isAddingContactModal = isEmpty(contactData); //Is verify if the modal is for add or edit
    if (!isAddingContactModal) this.setFormValuesForUpdate(contactData); //If is edit modal the inputs values are set when the component just mount
    this.setState({
      areAdditionalFieldsReadOnly: viewPointOfContact && !managePointContact,
    }); //Set the fields to be read only on the form base on the permissions
  }

  componentDidUpdate(prevState) {
    const { contactData = {} } = this.props;
    if (prevState.contactData !== contactData) {
      //Evaluates if contacData prop changes
      const isAddingContactModal = isEmpty(contactData);
      if (!isAddingContactModal) this.setFormValuesForUpdate(contactData); //If is edit modal the inputs values are set when the component just update
    }
  }

  onSavedSuccess = action => {
    //This function is trigger if add or edit were successful, closes the modal, reset the table to show new data,
    //and displays success notification.
    const { handleCancel } = this.props;
    handleCancel();
    this.props.resetTableData();
    notification.success({
      message: 'Success',
      description: `The point of contact was ${action} successfully.`,
    });
  };

  onSavedFailed = error => {
    //This function is trigger if add or edit were not successful, displays error notification, and enables the add or edit button,
    //and disables the loading on the button
    notification.error({
      message: 'Heads up!',
      description: error,
    });
    this.setState({
      isAddButtonDisabled: false,
      isApplyChangesButtonDisabled: false,
      isButtonLoading: false,
      isDeleteButtonLoading: false,
    });
  };

  savePointOfContact = async (organizationalUnitId, createContactRequest) => {
    //This function calls the enpoint to add the point of contact, enables the loading on the button.
    try {
      const ADDED = 'added';
      this.setState({ isButtonLoading: true });
      await organizationEndpoints.postPointOfContacs(
        organizationalUnitId,
        createContactRequest
      );
      this.onSavedSuccess(ADDED);
    } catch (e) {
      this.onSavedFailed(get(e, ['message']));
    }
  };

  editPointOfContact = async (
    organizationalUnitId,
    contactId,
    createContactRequest
  ) => {
    //This function calls the enpoint to edit the point of contact, enables the loading on the button.
    try {
      const EDITED = 'edited';
      this.setState({ isButtonLoading: true });
      await organizationEndpoints.updatePointOfContacs(
        organizationalUnitId,
        contactId,
        createContactRequest
      );
      this.onSavedSuccess(EDITED);
    } catch (e) {
      this.onSavedFailed(get(e, ['message']));
    }
  };

  removePointOfContact = async () => {
    const {
      agencyData: { orgUnitId: organizationalUnitId },
      contactData: { key = '' },
    } = this.props;
    try {
      const DELETED = 'deleted';
      this.setState({
        isDeleteButtonLoading: true,
        isApplyChangesButtonDisabled: true,
      });
      await organizationEndpoints.deletePointOfContacs(
        organizationalUnitId,
        key
      );
      this.onSavedSuccess(DELETED);
    } catch (e) {
      this.onSavedFailed(get(e, ['message']));
    }
  };

  confirmationModal = () => {
    const deleteModalMessage =
      'Are you sure that you want to delete the Point of Contact?';
    Modal.confirm({
      title: 'Remove point of contact',
      content: deleteModalMessage,
      okText: 'Yes',
      cancelText: 'No',
      onOk: this.removePointOfContact,
    });
  };

  handleSubmit = e => {
    const {
      form,
      agencyData: { orgUnitId: organizationalUnitId },
      contactData = {},
      contactData: { key = '' },
    } = this.props;
    const { getFieldsValue } = form;
    const { userIntegrationId } = this.state;
    const isAddingContactModal = isEmpty(contactData);
    //preventDefault() and stopPropagation() are use to avoid trigger the handles of others forms.
    e.preventDefault();
    e.stopPropagation();
    //The form values are getting and convert to be sent to their respective function.
    const fieldValues = getFieldsValue([
      'poc_contactType',
      'poc_additionalPhoneNumber',
      'poc_additionalEmail',
      'poc_notes',
    ]);
    const {
      poc_contactType: contactType,
      poc_additionalPhoneNumber: additionalPhoneNumber,
      poc_additionalEmail: additionalEmail,
      poc_notes: notes,
    } = fieldValues;
    const createContactRequest = {
      contactType,
      additionalPhoneNumber,
      additionalEmail,
      notes,
      userId: userIntegrationId,
    };
    //The type of the modal is evaluated to call the respective function (add or edit).
    const fetchMethod = isAddingContactModal =>
      isAddingContactModal
        ? this.savePointOfContact(organizationalUnitId, createContactRequest)
        : this.editPointOfContact(
            organizationalUnitId,
            key,
            omit(createContactRequest, 'userId')
          );
    fetchMethod(isAddingContactModal);
    this.setState({
      isAddButtonDisabled: true,
      isApplyChangesButtonDisabled: true,
    }); //Add or edit button is disabled just after click it.
  };

  selectUsersOptionsBuilder = users => {
    const { Option } = Select;
    return users.map(({ integrationId, fullName, email, title, homePhone }) => {
      //<Option> element does not accept objects on the value property, so the data is transform and pass as an string.
      const userDataToString = [integrationId, title, homePhone, email].join();
      return (
        <Option key={integrationId} value={userDataToString}>
          {fullName}
        </Option>
      );
    });
  };

  getUser = debounce(async value => {
    const {
      page = 0,
      size = 10,
      agencyData: { agencyId, orgUnitId },
    } = this.props;
    //The user is get from the endpoint, and transform to display on the autocomplete component.
    try {
      const usersData = await organizationEndpoints.getOuUsers({
        page,
        size,
        filter: value,
        customField: '',
        agencyId,
        orgUnitId,
      });
      const userOptions = await this.selectUsersOptionsBuilder(usersData.users);
      this.setState({ userSelectOptions: userOptions });
    } catch (e) {
      notification.error({
        message: 'Was not possible to get the users!',
        description: get(e, ['message']),
      });
    }
  }, 800);

  onUserSearch = value => {
    const { resetFields } = this.props.form;
    //This function is trigger when typing on autocomplete component and calls the getUser function
    //If the autocomplete field is empty fields are reset
    if (value) {
      this.getUser(value);
    } else {
      this.setState({ isAddButtonDisabled: true, selectedUserData: {} });
      const resetFieldsValues = ['poc_title', 'poc_phone_number', 'poc_email'];
      resetFields(resetFieldsValues);
    }
  };

  onUserSelect = value => {
    //This function is trigger when a user is selected from the autocomplete field, sets the values to the selected fields,
    //enables the add button and Intedration ID is assigned.
    const { setFieldsValue, getFieldsValue } = this.props.form;
    const getEmailValue = getFieldsValue(['poc_additionalEmail']);
    const userDataToArray = value.split(',');
    const userInformation = {
      ['poc_title']: userDataToArray[1] || 'No title',
      ['poc_phone_number']: userDataToArray[2] || 'No phone',
      ['poc_email']: userDataToArray[3] || 'No email',
    };
    const isButtonDisabled =
      getEmailValue.poc_additionalEmail && !this.state.isValidEmail;
    setFieldsValue(userInformation);
    this.setState({ userIntegrationId: userDataToArray[0] });
    this.setState({ isAddButtonDisabled: isButtonDisabled });
  };

  onChangeEmail = e => {
    //This function evaluates if the email field format is correct
    const {
      target: { value },
    } = e;
    const emailValidation = REG_EXP_PATTERNS_STRING[EMAIL];
    const isValidEmail = value.match(emailValidation);
    const emailStringToArray = value.split('');
    this.setState({
      isValidEmail: !isEmpty(isValidEmail),
      isEmailValueEmpty: isEmpty(emailStringToArray),
    });
  };

  onBlurEmail = e => {
    //This function evaluates in the onBlur event if the value for email field is correct and enable or disable the button
    const {
      target: { value },
    } = e;
    const {
      form: { getFieldsValue },
    } = this.props;
    const { isEmailValueEmpty, isValidEmail } = this.state;
    const getNameValue = getFieldsValue(['poc_name']);
    const shouldAddButtonBeEnable =
      (!isEmailValueEmpty && isValidEmail && getNameValue.poc_name) ||
      (getNameValue.poc_name && !value);
    const shouldApplyChangesButtonBeEnable = !isEmailValueEmpty && isValidEmail;
    this.setState({
      isAddButtonDisabled: !shouldAddButtonBeEnable,
      isApplyChangesButtonDisabled: !shouldApplyChangesButtonBeEnable,
    });
  };

  getEmailStatus = () => {
    //This function return the icon for the email field depended of the state
    const { isEmailValueEmpty, isValidEmail } = this.state;
    if (isEmailValueEmpty) return <Icon />;

    if (isValidEmail)
      return (
        <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
      );

    return (
      <Tooltip title="Email is invalid" defaultVisible={true}>
        <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
      </Tooltip>
    );
  };

  setFormValuesForUpdate = contactData => {
    //If it's edit modal values are set for all the fields.
    const { setFieldsValue } = this.props.form;
    const {
      name,
      title,
      phone,
      email,
      contactType,
      additionalPhoneNumber,
      addittionalEmail,
      notes,
    } = contactData;
    const userInformation = {
      ['poc_name']: name || 'No name',
      ['poc_title']: title || 'No title',
      ['poc_phone_number']: phone || 'No phone',
      ['poc_email']: email || 'No email',
      ['poc_contactType']: contactType,
      ['poc_additionalPhoneNumber']: additionalPhoneNumber,
      ['poc_additionalEmail']: addittionalEmail,
      ['poc_notes']: notes,
    };
    setFieldsValue(userInformation);
  };

  getInputsTypes = areAdditionalFieldsReadOnly => {
    //If the user only has viewPointOfContact permission returns the custom read only field, if has managePointContact returns editable field.
    return {
      contactInput: areAdditionalFieldsReadOnly ? (
        <StyleInputReadOnly disabled={true} />
      ) : (
        <Input />
      ),
      additionalPhoneInput: areAdditionalFieldsReadOnly ? (
        <PhoneNumberField readOnly={true} style={StyledPhoneNumberReadOnly} />
      ) : (
        <PhoneNumberField />
      ),
      additionalEmailInput: areAdditionalFieldsReadOnly ? (
        <StyleInputReadOnly disabled={true} />
      ) : (
        <Input
          onChange={this.onChangeEmail}
          onBlur={this.onBlurEmail}
          suffix={this.getEmailStatus()}
        />
      ),
    };
  };

  render() {
    const { contactData = {}, form } = this.props;
    const {
      userSelectOptions,
      isAddButtonDisabled,
      areAdditionalFieldsReadOnly,
      isApplyChangesButtonDisabled,
      isButtonLoading,
      isDeleteButtonLoading,
    } = this.state;
    const { getFieldDecorator } = form;
    const isAddingContactModal = isEmpty(contactData);
    const buttonText = isAddingContactModal ? 'Add' : 'Apply Changes';
    const modalButtonState = isAddingContactModal
      ? isAddButtonDisabled
      : isApplyChangesButtonDisabled;
    const inputsTypes = this.getInputsTypes(areAdditionalFieldsReadOnly);
    return (
      <Form onSubmit={this.handleSubmit}>
        <ContentWrapper>
          <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label="Name" labelAlign="left" {...formItemLayout}>
                {getFieldDecorator('poc_name', {
                  rules: [{ require: false }],
                })(
                  <AutoComplete
                    dataSource={userSelectOptions}
                    onSearch={this.onUserSearch}
                    onSelect={this.onUserSelect}
                    disabled={!isAddingContactModal}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label="Title" labelAlign="left" {...formItemLayout}>
                {getFieldDecorator('poc_title', {
                  rules: [{ require: false }],
                })(<StyleInputReadOnly disabled={true} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item
                label="Contact Type"
                labelAlign="left"
                {...formItemLayout}
              >
                {getFieldDecorator('poc_contactType', {
                  rules: [{ require: false }],
                })(inputsTypes.contactInput)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item
                label="Phone Number"
                labelAlign="left"
                {...formItemLayout}
              >
                {getFieldDecorator('poc_phone_number', {
                  rules: [{ require: false }],
                })(
                  <PhoneNumberField
                    readOnly={true}
                    style={StyledPhoneNumberReadOnly}
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item
                label="Additional Phone Number"
                labelAlign="left"
                {...formItemLayout}
              >
                {getFieldDecorator('poc_additionalPhoneNumber', {
                  rules: [{ require: false }],
                })(inputsTypes.additionalPhoneInput)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item label="Email" labelAlign="left" {...formItemLayout}>
                {getFieldDecorator('poc_email', {
                  rules: [{ require: false }],
                })(<StyleInputReadOnly disabled={true} />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Form.Item
                label="Additional Email"
                labelAlign="left"
                {...formItemLayout}
              >
                {getFieldDecorator('poc_additionalEmail', {
                  rules: [{ require: false }],
                })(inputsTypes.additionalEmailInput)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                label="Notes"
                labelAlign="left"
                {...inputTextAreaLayout}
              >
                {getFieldDecorator('poc_notes', {
                  rules: [{ require: false }],
                })(
                  <Input.TextArea
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    readOnly={areAdditionalFieldsReadOnly}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Divider style={{ margin: 5 }} />
          {!areAdditionalFieldsReadOnly && (
            <Row>
              <Col xs={24} sm={12} md={12} lg={12}>
                {!isAddingContactModal && (
                  <Form.Item>
                    <ButtonRemoveWrapper>
                      <Button
                        type="danger"
                        onClick={this.confirmationModal}
                        loading={isDeleteButtonLoading}
                        disabled={modalButtonState}
                        ghost
                      >
                        Remove as Contact
                      </Button>
                    </ButtonRemoveWrapper>
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} sm={12} md={12} lg={12}>
                <Form.Item>
                  <ButtonPrimaryWrapper>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isButtonLoading}
                      disabled={modalButtonState}
                    >
                      {buttonText}
                    </Button>
                  </ButtonPrimaryWrapper>
                </Form.Item>
              </Col>
            </Row>
          )}
        </ContentWrapper>
      </Form>
    );
  }
}

export default Form.create({ name: 'addPointOfContact' })(PointOfContactForm);
