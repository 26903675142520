import getOverrides from 'APP_ROOT/utils/get-field-overrides';

const hasPattern = props => {
  const { overrides = {} } = props;

  const getFieldOverrides = getOverrides(props, overrides);
  const options = getFieldOverrides('options', {});
  return options.pattern && Array.isArray(options.pattern);
};

export default hasPattern;
