import styled from 'styled-components';

export const BodyWrapper = styled.div`
  padding: 16px;
`;

export const HeaderWrapper = styled.div`
  .page-header-content {
    padding: 20px 20px 3px 20px !important;
  }
`;

export const ImportantInfoWrapper = styled.div`
  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

  ul li ul {
    list-style-type: circle;
    padding-left: 10px;
  }
`;

export const TableWrapper = styled.div`
  width: 30%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  float: left;
  margin-bottom: 10px;
`;

export default BodyWrapper;
