import React, { useState } from 'react';
import { Button, notification } from 'antd';
import ContentEditable from 'react-contenteditable';

import caseFileEndpoints from '../../../../api/caseFileEndpoints/caseFileEndpoints';
import { isEmpty } from 'lodash';

const AddPredefinedTaskButton = ({
  caseFileId,
  loadingTask,
  hasEditPermission,
  reloadTaskTable = () => {},
  setOutterPredefinedTaskLoadingFlag = () => {},
  setPredefinedTasksCount = () => {},
  predefinedTaskList = [],
}) => {
  const [loadingPredefinedTasks, setLoadingPredefinedTasks] = useState(false);
  const [predefinedTasks, setPredefinedTasks] = useState(null);

  const showNotification = (type, message, description) =>
    notification[type]({
      message: <ContentEditable html={message} disabled={true} />,
      description: <ContentEditable html={description} disabled={true} />,
    });

  const proccesDefinedTask = async casefileId => {
    try {
      const total = predefinedTaskList.length;
      setPredefinedTasks(total);
      setPredefinedTasksCount({ current: 1, total });

      for (let i = 0; i < total; i++) {
        const index = total - (i + 1);
        await caseFileEndpoints.addTaskToCasefile(casefileId, {
          description: predefinedTaskList[index].description,
        });
        const current = i + 2;
        if (current > total) return;
        setPredefinedTasksCount({ current, total });
      }
    } catch (error) {
      showNotification(
        'error',
        'Could not create predefined tasks',
        error?.message
      );
    }
  };

  const handleClickButton = async event => {
    event.stopPropagation();
    setLoadingPredefinedTasks(true);
    setOutterPredefinedTaskLoadingFlag(true);

    try {
      await proccesDefinedTask(caseFileId);
    } catch (error) {
      showNotification(
        'error',
        'Could not add predefined tasks',
        error?.message
      );
    } finally {
      reloadTaskTable().then(() => {
        finishLoadingAfterDelay(400);
      });
    }
  };

  const finishLoadingAfterDelay = ms => {
    setTimeout(() => {
      setPredefinedTasks(0);
      setPredefinedTasksCount(null);
      setLoadingPredefinedTasks(false);
      setOutterPredefinedTaskLoadingFlag(false);
    }, ms);
  };

  return (
    <Button
      type="primary"
      onClick={handleClickButton}
      loading={loadingPredefinedTasks}
      disabled={
        loadingPredefinedTasks ||
        loadingTask ||
        hasEditPermission ||
        !predefinedTaskList ||
        isEmpty(predefinedTaskList)
      }
      style={{ marginRight: '16px' }}
    >
      {predefinedTasks > 0
        ? `Adding ${predefinedTasks} default tasks, please wait...`
        : 'Add Default Tasks'}
    </Button>
  );
};

export default AddPredefinedTaskButton;
