import { getFormFieldData, getFormData } from 'APP_ROOT/selectors/form';

export const getFieldSelector = ({ dataSelector }) => {
  if (
    dataSelector === undefined ||
    dataSelector === null ||
    dataSelector === ''
  ) {
    dataSelector = getFormFieldData;
  }
  return dataSelector;
};

export const getDataSelector = ({ dataSelector }) => {
  if (
    dataSelector === undefined ||
    dataSelector === null ||
    dataSelector === ''
  ) {
    dataSelector = getFormData;
  }
  return dataSelector;
};
