import { fetchRequest, fetchRequestBlob } from '../../utils/request';
import ServiceClient from '../serviceClient';

class trainingEndpoints extends ServiceClient {
  _defaultTrainingFilters = {
    // query params:
    // categoryNames: '',
    // completionDate: '',
    // courseName: '',
    // registrationId: '',
    // source: 'kmi',
    // status: '',
    // createdDate: '',
    // documentCategory: '',
    // documentDescription: '',
    // documentName: '',
    // documentSubject: '',
    // documentType: '',
    // uploadedBy: '',
    // uploadedDate: '',
  };

  constructor() {
    const DEV_URI = 'https://localhost:3003';
    const apiPrefix = 'organizations/v1/tenants/:tenantId/users/:userId';
    super(
      process.env.REACT_APP_ORGANIZATIONS_SERVICE_URI || DEV_URI,
      apiPrefix
    );
  }

  parseTrainingFilters(filters) {
    let parsedFilters = { ...this._defaultTrainingFilters };

    filters.kmiRegistrationId &&
      (filters['registrationId'] = filters['kmiRegistrationId']) &&
      delete filters.kmiRegistrationId;

    return {
      ...parsedFilters,
      ...filters,
    };
  }

  async getUserTrainingData(
    tenantId,
    userId,
    pageNumber,
    pageSize,
    sort,
    filters
  ) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/trainings'),
        method: 'GET',
      },
      {
        tenantId,
        userId,
      },
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        sort: sort,
        ...this.parseTrainingFilters(filters || {}),
      }
    );

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }

  async getUserTrainingDataAsCsv(tenantId, userId, source) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/trainings/csv'),
        method: 'GET',
      },
      {
        tenantId,
        userId,
      },
      {
        source: source,
      }
    );

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequestBlob(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }
}

export default new trainingEndpoints();
