import styled from 'styled-components';

const StyledLayoutGuestContainer = styled.div`
  background-color: #fff;
  max-width: 420px;
  padding: 50px 0;
  margin: 0 auto;

  .logo {
    width: 100%;
    max-width: 300px;
    display: block;
    margin: 0 auto 25px;
  }
`;

export default StyledLayoutGuestContainer;
