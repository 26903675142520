import React from 'react';
import { Table, Row, Col } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { GRAY_3, GRAY_2 } from '../../../../config/theme';

const TablePanel = ({
  showFooter,
  footer: { title, value },
  pagination,
  ...props
}) => (
  <StyledTable
    {...props}
    footer={
      showFooter
        ? () => (
            <Row>
              <Col span={10}>{title}</Col>
              <Col span={12}>{value}</Col>
            </Row>
          )
        : null
    }
    pagination={pagination}
  />
);

TablePanel.propTypes = {
  pagination: PropTypes.bool.isRequired,
  showFooter: PropTypes.bool.isRequired,
  footer: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

TablePanel.defaultProps = {
  pagination: false,
  showFooter: false,
  footer: {
    title: '',
    value: '',
  },
};

const StyledTable = styled(Table)`
  .ant-table {
    border-radius: 4px;

    table {
      border-radius: 0;
    }
  }

  .ant-table-thead > tr > th {
    word-break: break-word;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    padding: 16px 16px;
    color: #000;
  }

  .ant-table-tbody > tr > td {
    font-size: 12px;
    line-height: 14px;
    padding: 8px 16px;
    color: ${GRAY_2};
    word-break: break-word;
  }

  .ant-table-tbody > tr:hover > td {
    background: transparent;
  }

  .ant-table-tbody > tr > td:first-child {
    text-align: left;
    color: #000;
  }
  /* stylelint-disable */
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    text-align: center;
    background: #fff;
    border-right: 1px solid ${GRAY_3};
    border-bottom: 1px solid ${GRAY_3};

    &:last-child {
      border-right: none;
    }
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }

  .ant-table-footer {
    padding: 16px;
    background: #fff;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid ${GRAY_3};

    &::before {
      background: ${GRAY_3};
    }

    div[class^='ant-col']:first-child {
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      color: #000;
    }

    div[class^='ant-col'] {
      font-size: 12px;
      line-height: 14px;
      color: ${GRAY_2};
    }
  }
`;

export default TablePanel;
