import React from 'react';
import { Row, Col, Icon } from 'antd';
import { get } from 'lodash';
import parseDate, {
  BENCHMARK_DATE_TIMEZ_FORMAT_WITH_PREPOSITION,
} from 'APP_ROOT/utils/parse-date';

import {
  StyledAttachmentInfo,
  StyledCol,
  StyledDownloadButton,
} from '../styled';
import handleDownloadClickS3 from './handleDownloadClickS3';
import ReviewerField from '../../../reviewer-field';
import { decodeFileName } from 'src/utils/form/utils.js';

const renderAttachmentAsReviewer = ({
  props,
  setState,
  fieldDataKey,
  attachment = {},
  timezone = null,
}) => {
  const {
    form: { getFieldDecorator },
  } = props;

  getFieldDecorator(fieldDataKey);

  return (
    <Row gutter={8} key={attachment.id}>
      <StyledCol span={8}>
        <div className={`ant-upload-list-item-done`}>
          <div className="ant-upload-list-item-info">
            <span>
              <Icon type="paper-clip" />
              <a
                rel="noopener noreferrer"
                className="ant-upload-list-item-name"
                title={decodeFileName(attachment.name)}
                href="#"
                onClick={e =>
                  handleDownloadClickS3(
                    props,
                    setState,
                    e,
                    attachment.id,
                    attachment.name
                  )
                }
              >
                {decodeFileName(attachment.name)}
              </a>
            </span>
          </div>
        </div>
      </StyledCol>
      <Col span={14}>
        <StyledAttachmentInfo>
          <ReviewerField value={attachment.notes} />
          <strong>
            Uploaded by {get(attachment, `fileUploadedBy.rank.name`, '')}{' '}
            {get(attachment, `fileUploadedBy.fullName`, '')} {' '}
            {parseDate(
              attachment.created,
              timezone,
              BENCHMARK_DATE_TIMEZ_FORMAT_WITH_PREPOSITION
            )}
          </strong>
        </StyledAttachmentInfo>
      </Col>
      <Col span={2} className="text-right">
        <a
          alt="download"
          href="#"
          rel="noopener noreferrer"
          onClick={e =>
            handleDownloadClickS3(
              props,
              setState,
              e,
              attachment.id,
              attachment.name
            )
          }
        >
          <StyledDownloadButton shape="circle" icon="download" />
        </a>
      </Col>
    </Row>
  );
};

export default renderAttachmentAsReviewer;
