import { formatDate, formatDateString } from '../../../utils/parse-date';
import { BENCHMARK_DATE_FORMAT } from '../../../utils/parse-date';

const formattedBithday = value =>
  value ? formatDateString(value, BENCHMARK_DATE_FORMAT) : '';

export const normalizeData = data => {
  return data.map(memberInfo => {
    const {
      lastName,
      firstName,
      middleName,
      phoneNumber,
      address,
      birthday,
      race,
      id,
    } = memberInfo;
    //Adding the || operator and assigning '' to avoid nulls values, due to localecompare function on communityTableColumns.js only accept string and null values trigger that error.
    //Fix for PPL-841
    return {
      ...memberInfo,
      integrationId: id || '',
      lastName: lastName || '',
      firstName: firstName || '',
      middleName: middleName || '',
      phoneNumber: phoneNumber || '',
      address: address.address1 || '',
      race: race || '',
      birthday: formattedBithday(birthday),
    };
  });
};

export const mapDataForCreate = data => {
  return {
    address: {
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      county: data.county,
      state: data.state,
      zip: data.zip,
    },
    alternateName: data.alternateName,
    birthday: data.birthday
      ? formatDateString(data.birthday, BENCHMARK_DATE_FORMAT)
      : '',
    emailAddress: data.emailAddress,
    ethnicity: data.ethnicity,
    documentId: data.driversLicenceNo,
    firstName: data.firstName,
    gender: data.gender,
    lastName: data.lastName,
    middleName: data.middleName,
    notes: data.notes,
    phoneNumber: data.phoneNumber,
    preferredLanguage: data.preferredLanguage,
    race: data.race,
    veteranStatus: data.veteranStatus,
  };
};

export const mapDataForView = (data = { address: {} }) => {
  return {
    address1: { value: data.address.address1 },
    address2: { value: data.address.address2 },
    city: { value: data.address.city },
    county: { value: data.address.county },
    state: { value: data.address.state },
    zip: { value: data.address.zip },
    alternateName: { value: data.alternateName },
    driversLicenceNo: { value: data.documentId },
    birthday: {
      value: data.birthday
        ? formatDate(data.birthday, BENCHMARK_DATE_FORMAT)
        : null,
    },
    emailAddress: { value: data.emailAddress },
    ethnicity: { value: data.ethnicity },
    firstName: { value: data.firstName },
    gender: { value: data.gender },
    lastName: { value: data.lastName },
    middleName: { value: data.middleName },
    notes: { value: data.notes },
    phoneNumber: { value: data.phoneNumber },
    preferredLanguage: { value: data.preferredLanguage },
    race: { value: data.race },
    veteranStatus: { value: data.veteranStatus },
  };
};
