import ReactGA from 'react-ga';

const ENV = process.env.NODE_ENV;

export const GA_ACCOUNT =
  ENV === 'development'
    ? process.env.REACT_APP_DEV_GA_ACCOUNT
    : process.env.REACT_APP_GA_ACCOUNT;

const InitializeGoogleAnalytics = () => {
  if (ENV === 'test' || !GA_ACCOUNT) {
    return false;
  }

  ReactGA.initialize(GA_ACCOUNT, {
    gaOptions: {
      alwaysSendReferrer: true,
    },
  });

  ReactGA.ga('set', {
    dimension3: global.location.origin,
    cd3: global.location.origin,
    referrer: global.location.origin,
  });

  ReactGA.pageview(window.location.pathname);

  ReactGA.event({
    category: 'Navigation',
    action: 'App Loaded',
    label: `Loaded on: ${global.location.href}`,
  });
};

export default InitializeGoogleAnalytics;
