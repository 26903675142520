import styled from 'styled-components';

import { GRAY_1, ACCENT } from 'APP_ROOT/config/theme';

const StyledPageHeader = styled.div`
  background-color: #fff;
  padding: 0;
  box-shadow: 0 1px 5px 0 ${GRAY_1};
  z-index: 99;
  margin-bottom: 10px;

  .page-header-content {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 1px 5px 0 ${GRAY_1};
  }

  &.sidebar-is-collapsed,
  &.is-fixed.sidebar-is-collapsed {
    width: calc(100% - 73px);

    @media screen and (max-width: 480px), (max-width: 992px) {
      width: 100%;
    }
  }

  &.is-fixed {
    position: fixed;
    top: 0;
    width: calc(100% - 200px);
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);

    .page-header-content {
      box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    }
  }

  @media print {
    box-shadow: none;
  }

  .page-header-alert {
    border-top: 1px solid #ececec;
  }

  .header-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    width: 70%;

    @media (max-width: 480px) {
      width: unset;
    }

    @media print {
      > a {
        display: none;
      }
    }

    &__text {
      font-size: 1.5em;
      font-weight: 800;

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    &__back {
      color: ${ACCENT};
      font-size: 1.5em;
      border: none;
      margin: 0 5px;
    }

    .title-separator {
      font-size: 0.5em;
      vertical-align: middle;
      color: #e0e0e0;
    }

    &__subtitle {
      font-weight: 500;
      color: #252525;
    }

    .title {
      font-size: 0.9em;
    }

    .subtitle {
      font-size: 0.5em;
      color: darkgray;
    }
  }

  .description {
    margin-top: 10px;
  }

  .header-actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 30%;
    justify-content: right;

    @media (max-width: 480px) {
      width: unset;
    }

    & .form-actions {
      display: flex;
    }

    @media print {
      display: none;
    }
  }

  &.form-builder-header {
    .header-title,
    .header-actions {
      width: unset;
    }
  }

  .ant-tooltip.page-header-tooltip .ant-tooltip-content .ant-tooltip-inner {
    white-space: nowrap;
  }
`;

export default StyledPageHeader;
