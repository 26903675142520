import { v4 as uuid } from 'uuid';
import get from 'lodash';

import { mapFormKeys } from './form';
import FieldTemplate from 'APP_ROOT/utils/fieldTemplate';
import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';

export default (props, getItems) => {
  const {
    isReviewer = false,
    options = {},
    data,
    dataKey: parentKey,
    properties = [],
    settings = {},
  } = props;
  const {
    showAddButton = true,
    maxItems = null,
    syncTo = [],
    syncKeys,
    seedKeys,
  } = options;

  const itemIdField = new FieldTemplate('text', {
    key: 'id',
    conditions: {
      rules: [
        {
          key: 'isHidden',
          expect: true,
        },
      ],
    },
  });

  const repeaterData = data[parentKey] || [];
  const sourceData = maxItems ? data[maxItems] : 0;
  const sourceDataLength =
    typeof sourceData === 'object' ? sourceData.length : sourceData;
  const canAddMore = maxItems ? sourceDataLength > repeaterData.length : true;

  const { formSchema, formName } = settings;

  const template = {
    ...(get(properties, [0], {}) || { type: 'row' }),
    properties: [
      itemIdField.toJSON(),
      ...get(properties, [0, 'properties'], []),
    ],
  };
  const syncToTemplates = syncTo.length ? mapFormKeys(formSchema, syncTo) : [];

  if (
    !isReviewer &&
    showAddButton &&
    canAddMore &&
    getItems(props).length === 0
  ) {
    const keys = [parentKey, ...syncTo];
    const templates = [template, ...syncToTemplates];

    emitFormEvent(
      'addRepeaterItem',
      formName,
      keys,
      templates,
      uuid(),
      syncKeys,
      {},
      seedKeys
    );
  }
};
