import React, { Fragment } from 'react';
import { Row, Col, Input as InputField } from 'antd';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';

import Section from 'APP_COMPONENTS/form-viewer/styled/section';
import Title from 'APP_COMPONENTS/form-viewer/styled/section-title';
import Input from '../../../containers/administrator/components/input';
import { PhoneNumberField as PhoneNumberInput } from 'APP_COMPONENTS/form-viewer/field-types/input-masked';
import OrganizationInformationWrapper from './OrganizationInformationWrapper.styled';
import If from 'APP_COMPONENTS/utils/ConditionalRender';
import OrganizationInformationFormSkeleton from '../common/OrganizationInformationFormSkeleton';
import Label from '../../../containers/administrator/components/input/Label.styled';
import getRoute from 'APP_ROOT/utils/get-route';

const TextField = Input(InputField);
const PhoneNumberField = Input(PhoneNumberInput);
const commonFieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
  inputCol: 'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
};

const OrganizationInformation = ({
  organizationProfileForm = {},
  isLoading,
  canManageAttributes,
}) => {
  const parentOUName = !isLoading && organizationProfileForm.values.parentOU;
  const parentOUId = !isLoading && organizationProfileForm.values.parentOUId;
  const shouldFieldBeEditable = !canManageAttributes;
  return (
    <OrganizationInformationWrapper>
      <Section>
        <Row>
          <Col className="gutter-row" span={24}>
            <Title size="large">Organization Information</Title>
            <div className="divider" />
          </Col>
        </Row>
        <If condition={isLoading}>
          <OrganizationInformationFormSkeleton />
        </If>
        <If condition={!isLoading}>
          <div>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12}>
                <Field
                  type="text"
                  name="organizationName"
                  data-test="organizationName"
                  label="Organization Name"
                  component={TextField}
                  options={commonFieldOptions}
                  disabled={true}
                  plainText={true}
                />
              </Col>
              <If condition={parentOUId}>
                <Fragment>
                  <Col xs={24} sm={4} md={3} lg={3}>
                    <Label htmlFor="parentOU">Parent OU:</Label>
                  </Col>
                  <Col xs={24} sm={6} md={4} lg={6}>
                    <Link
                      to={getRoute('organizationProfile', {
                        organizationalUnitId: parentOUId,
                      })}
                      disabled={isLoading}
                      name="parentOU"
                    >
                      {' '}
                      {parentOUName}
                    </Link>
                  </Col>
                </Fragment>
              </If>
            </Row>
            <Row>
              <Col xs={24} sm={12} md={12} lg={12}>
                <Field
                  type="text"
                  name="phoneNumber"
                  data-test="phoneNumber"
                  label="Phone Number"
                  component={PhoneNumberField}
                  options={commonFieldOptions}
                  disabled={shouldFieldBeEditable}
                  plainText={shouldFieldBeEditable}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={12}>
                <Field
                  type="text"
                  name="website"
                  data-test="website"
                  label="Website"
                  component={TextField}
                  options={commonFieldOptions}
                  disabled={shouldFieldBeEditable}
                  plainText={shouldFieldBeEditable}
                />
              </Col>
            </Row>
          </div>
        </If>
      </Section>
    </OrganizationInformationWrapper>
  );
};

export default OrganizationInformation;
