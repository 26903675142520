import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import get from 'lodash/get';

import { visibleOnlyForSandboxFromSession } from '../utils/visible-only-for-sandbox';
import getRoute from '../utils/get-route';

// Guest routes don't require authentication
import GuestRoutes from './guest';

// Containers (Must be authenticated)
import Dashboard from '../containers/dashboard';
import IzendaRoutes from './izenda';
import ReportsRoutes from './reports';
import OfficerRoutes from './officer';
import ActionsRoutes from './actions';

import ActivityPage from '../containers/dashboard/officer/activity';
import PerformanceEvaluationPage from '../containers/dashboard/officer/performance-evaluation';
import Complaints from '../containers/dashboard/commander/complaints';
import Complaint from '../containers/dashboard/commander/complaint';
import ActivityFeed from '../containers/activity-feed';
import ActionItemsFeed from '../containers/action-items-feed';

import Organizations from '../containers/organizations';
import People from '../containers/people';
import PeopleOu from '../containers/people-ou';
import OrganizationProfile from '../containers/organization-profile/OrganizationProfile';
import Settings from '../containers/settings';
import InternalAffairsList from '../containers/internal-affairs';
import Training from '../containers/training';
import TrainingDetails from '../containers/training/field-training';
import UserProfile from '../containers/administrator/agency-users/AgencyUsers';
import AuditLogs from '../containers/audit-logs';
import ExportReports from '../containers/administrator/agency-export-reports/index';
import ExportRule28 from '../containers/administrator/agency-export-rule-28/index';
import SalesforceSupport from '../containers/salesforce-support';
import Logout from '../containers/logout';

import CaseFile from '../containers/caseFile';
import CaseFileList from '../containers/caseFile/CaseFileList';

// Not Found
import NotFound from '../containers/not-found';
import Protected from '../containers/protected';

import Container from '../app-container';

import { transformToRoutes } from '../components/route-render';
import adminRoutes from '../containers/administrator/Administrator.routes';
import { PERMISSIONS } from '../utils/admin';
import Community from '../containers/administrator/community/Community';
import CommunityDetail from '../containers/administrator/community/CommunityDetail';

export default () => (
  <Switch>
    {GuestRoutes}
    <Container>
      <Route
        path="/"
        name="dashboard"
        render={() => {
          return (
            <Switch>
              <Route
                path="/:type/new"
                name="newForm"
                render={props => (
                  <Redirect
                    from={get(props, 'match.path')}
                    to={getRoute('createNewReport', null, {
                      reportType: get(props, 'match.params.type'),
                    })}
                  />
                )}
              />
              {ReportsRoutes}
              {IzendaRoutes}
              <Route
                path="/files"
                exact={true}
                name="caseFileList"
                component={Protected(CaseFileList, [
                  PERMISSIONS.manageAllCasefiles,
                  PERMISSIONS.manageMyCasefiles,
                  PERMISSIONS.accessCasefiles,
                ])}
              />
              <Route
                path="/files/:id"
                exact={true}
                name="openCaseFile"
                component={Protected(CaseFile, [
                  PERMISSIONS.manageAllCasefiles,
                  PERMISSIONS.manageMyCasefiles,
                  PERMISSIONS.accessCasefiles,
                ])}
              />
              <Route
                path="/files/create-new"
                exact={true}
                name="createNewCaseFile"
                component={Protected(CaseFile, [
                  PERMISSIONS.manageAllCasefiles,
                  PERMISSIONS.manageMyCasefiles,
                ])}
              />
              <Route
                path="/activity"
                exact={true}
                name="activity"
                component={Protected(ActivityPage, [
                  PERMISSIONS.viewAllUserProfiles,
                ])}
              />
              <Route
                exact
                path="/get-support"
                name="Support"
                component={Protected(SalesforceSupport, [
                  PERMISSIONS.viewAllUserProfiles,
                ])}
              />
              <Route
                path="/performance-evaluation"
                exact={true}
                name="performanceEvaluation"
                component={Protected(PerformanceEvaluationPage, [
                  PERMISSIONS.viewAllUserProfiles,
                ])}
              />
              <Route
                path="/officers/complaints"
                exact={true}
                name="complaints"
                component={Protected(Complaints, [
                  PERMISSIONS.viewAllUserProfiles,
                ])}
              />
              <Route
                path="/officers/complaints/details"
                exact={true}
                name="complaintDetails"
                component={Protected(Complaint, [
                  PERMISSIONS.viewAllUserProfiles,
                ])}
              />
              {OfficerRoutes}
              {ActionsRoutes}
              <Route
                path="/activity-feed"
                name="activityFeed"
                component={Protected(ActivityFeed, ['everybody'])}
              />
              <Route
                path="/action-item-feed"
                name="actionItemsFeed"
                component={Protected(ActionItemsFeed, ['everybody'])}
              />
              <Route
                path="/people"
                name="people"
                component={Protected(People, [
                  PERMISSIONS.configureDataImport,
                  PERMISSIONS.viewAllUserProfiles,
                ])}
              />
              <Route
                path="/organizations"
                name="OuList"
                component={Protected(Organizations, [
                  PERMISSIONS.listOrganizationsByHK,
                ])}
              />
              <Route
                path="/people-ou"
                name="peopleOu"
                component={Protected(PeopleOu, [
                  PERMISSIONS.configureDataImport,
                  PERMISSIONS.listUsersByHK,
                  PERMISSIONS.listAllOUHierarchyUsers,
                ])}
              />
              <Route
                path="/audit-logs"
                name="auditLogs"
                component={Protected(AuditLogs, [
                  PERMISSIONS.viewAllAuditLogs,
                  PERMISSIONS.viewAgencyAuditLogs,
                ])}
              />
              <Route
                path="/agency/:agencyId/export-rule-28"
                name="exportRule28"
                component={Protected(ExportRule28, [
                  PERMISSIONS.manageRule28DataExport,
                ])}
              />
              <Route
                path="/agency/:agencyId/export-reports"
                name="exportReports"
                component={Protected(ExportReports, [
                  PERMISSIONS.manageReportDataExport,
                  PERMISSIONS.manageRule28DataExport,
                ])}
              />
              <Route
                path="/agency/:agencyId/user-profile/:userId"
                name="userProfile"
                component={Protected(UserProfile, ['everybody'])}
              />
              <Route
                path="/organization-profile/:organizationalUnitId"
                name="organizationProfile"
                component={Protected(OrganizationProfile, [
                  PERMISSIONS.listOrganizationsByHK,
                ])}
              />
              <Route
                path="/settings"
                name="settings"
                component={Protected(Settings, ['everybody'])}
              />
              <Route
                path="/internal-affairs"
                name="internalAffairs"
                component={Protected(
                  InternalAffairsList,
                  visibleOnlyForSandboxFromSession([
                    'viewIACaseLoad',
                    'manageIACaseLoad',
                  ])
                )}
              />
              <Route
                path="/field-training"
                exact={true}
                name="fieldTraining"
                component={Protected(Training, {
                  permissions: [PERMISSIONS.accessTraining],
                  modules: ['training'],
                })}
              />
              <Route
                path="/field-training/:id"
                exact={true}
                name="fieldTrainingDetails"
                component={Protected(TrainingDetails, {
                  permissions: [PERMISSIONS.accessTraining],
                  modules: ['training'],
                })}
              />
              {transformToRoutes(adminRoutes)}
              <Route
                path="/logout"
                name="logout"
                key="logout"
                exact
                component={Logout}
              />
              <Route
                path="/community"
                name="community"
                exact={true}
                component={Protected(Community, [
                  PERMISSIONS.manageCommunityMember,
                  PERMISSIONS.viewCommunityMember,
                ])}
              />
              <Route
                path="/community/view/:communityMemberId"
                name="community"
                component={Protected(CommunityDetail, [
                  PERMISSIONS.manageCommunityMember,
                  PERMISSIONS.viewCommunityMember,
                ])}
              />
              <Route
                path="/community/create-new"
                name="createCommunityDetail"
                component={Protected(CommunityDetail, [
                  PERMISSIONS.manageCommunityMember,
                ])}
              />
              <Route
                render={props => {
                  if (props.location.pathname === '/') {
                    return (
                      <Route path="/" name="dashboard" component={Dashboard} />
                    );
                  }

                  return <Route name="404" component={NotFound} />;
                }}
              />
            </Switch>
          );
        }}
      />
    </Container>
  </Switch>
);
