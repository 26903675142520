import communityMembersEndPoints from '../../../../api/organization/communityMembersEndpoints';

export default (tenantId, data) => {
  return new Promise((resolve, reject) => {
    communityMembersEndPoints
      .createCommunityMember(tenantId, data)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        return reject(error.message);
      });
  });
};
