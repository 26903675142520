import React, { PureComponent } from 'react';
import { findDOMNode } from 'react-dom';
import styled from 'styled-components';

class AdaptableSVG extends PureComponent {
  state = {
    visible: false,
  };

  componentDidMount() {
    this.resetSizeAttrs();
    this.calculateSize();
    this.showSVG();

    global.addEventListener('resize', this.calculateSize);
  }

  componentWillUnmount() {
    global.addEventListener('resize', this.calculateSize);
  }

  saveRef = name => el => {
    this[name] = el;
  };

  getViewBoxBounds = () => {
    const { viewBox } = this.props;

    const [, , width, height] = viewBox.split(' ');

    return {
      width: Number(width),
      height: Number(height),
    };
  };

  resetSizeAttrs = () => {
    if (!this.svg) {
      return null;
    }

    const svgElement = findDOMNode(this.svg);
    const { width, height } = this.getViewBoxBounds();

    svgElement.setAttribute('width', `${width}px`);
    svgElement.setAttribute('height', `${height}px`);
  };

  calculateSize = () => {
    if (!this.parentWrapper) {
      return null;
    }

    const parentWrapper = findDOMNode(this.parentWrapper);
    const svgElement = findDOMNode(this.svg);
    const { width } = parentWrapper.getBoundingClientRect();
    const { width: svgWidth, height: svgHeight } = this.getViewBoxBounds();
    const ratio = this.ratio || svgHeight / svgWidth;
    const height = ratio * width;

    this.ratio = ratio;

    svgElement.style.width = `${width}px`;
    svgElement.style.height = `${height}px`;
    svgElement.removeAttribute('width');
    svgElement.removeAttribute('height');
  };

  showSVG = () => {
    this.setState({ visible: true });
  };

  render() {
    const { visible } = this.state;

    return (
      <div ref={this.saveRef('parentWrapper')}>
        <Fader visible={visible}>
          <svg {...this.props} ref={this.saveRef('svg')} />
        </Fader>
      </div>
    );
  }
}

const Fader = styled.div`
  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.3s 0.1s;
`;

export default AdaptableSVG;
