import React, { Component } from 'react';
import { omit, get, debounce, has, isEmpty } from 'lodash';
import { Button, Input, Tooltip, Modal } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import { getEnvironmentVariable } from 'APP_ROOT/appVersion';
import getLastStatus from 'APP_ROOT/utils/get-report-status';
import ReviewerField from '../reviewer-field';
import { connect } from 'react-redux';
import { getSourceFieldValue } from '../../../selectors/form';
import { autoSaveDraftReport } from '../../utils/auto-save';
import getRepeaterPropsForAutoSave from '../forms/getRepeaterPropsForAutoSave';
import { TEXT } from '../../../constants/fieldTypes';

import MapComponent from '../mapComponent';
import { hasFeatures, FEATURES } from '../../../utils/features';
import StyledInputWrapper from '../styled/input-text';
import StyledIcon from '../styled/icon';

import {
  CATEGORIES,
  CATEGORIES_MAP,
  CUSTOM_LATITUDE,
  CUSTOM_LONGITUDE,
  CUSTOM_ADDRESS_LEVEL1,
  CUSTOM_ADDRESS_LEVEL2,
  CUSTOM_COUNTY_CITY,
  CUSTOM_CITY_STATE_ZIP,
  CUSTOM_POSTAL_CODE,
} from './constants';
import isMobileDevice from '../../../utils/isMobileDevice';

const isMobile = isMobileDevice();
const AUTO_SAVE_TIME = getEnvironmentVariable('AUTO_SAVE_TIME', 30);

function setNativeValue(element, value = '') {
  setTimeout(() => {
    element.value = value;
    const event = new Event('blur', { bubbles: true });
    element.dispatchEvent(event);
  }, 350);
}

const addressMatcher = (searchTerm, searchGroups) => {
  if (searchGroups.length === 0) return [];
  searchTerm = searchTerm
    .toLowerCase()
    .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    .trim();

  return searchGroups.find(({ terms }) => {
    return terms.find(value => {
      const { exactMatch = true, term = value } = value;
      if (exactMatch) {
        return term === searchTerm;
      } else {
        return searchTerm.split(' ')?.includes(term);
      }
    });
  });
};

class InputText extends Component {
  constructor(props) {
    super(props);
    let autocomplete;
    let renderMap = false;
    let disableInputAddress = false;
    const { currentUser: { featureFlags = [] } = {}, field_type } = props;
    // make sure we are rendering a text field
    const isTextField = field_type === TEXT;

    // sometimes masked field uses this component
    this.enableAddressVerification =
      isTextField && hasFeatures(featureFlags, FEATURES.addressVerification);

    if (this.enableAddressVerification && props.title) {
      const matches = addressMatcher(props.title, CATEGORIES);
      if (!isEmpty(matches)) {
        const { label } = matches;
        const sectionKey = this.getParentNodeSectionKey();
        autocomplete = `${sectionKey} ${label}`;
        renderMap = !isEmpty(addressMatcher(props.title, CATEGORIES_MAP));
        disableInputAddress = true;
      }
    }
    this.state = {
      autocomplete,
      renderMap,
      disableInputAddress,
      showMap: false,
      mapData: {},
      prevMapData: {},
      clearMap: false,
      shouldClearMap: false,
    };
  }

  componentDidMount() {
    const {
      calculatedValue,
      onChange,
      value,
      isOwnChange,
      dependsOnChange,
      isReviewer,
      sourceFieldValue,
    } = this.props;

    if (!isReviewer) {
      if (
        calculatedValue &&
        ((!isOwnChange && dependsOnChange) || value === undefined)
      ) {
        onChange(calculatedValue);
      }
      if (sourceFieldValue) {
        onChange(sourceFieldValue);
      }
    }
  }

  queryCustomFields = targetInput => {
    const dataKey = this.getParentNodeSectionKey(targetInput);
    return document.querySelectorAll(
      `[data-key="${dataKey}"] input[autocomplete]`
    );
  };

  getParentNodeSectionKey = parentNode => {
    parentNode = parentNode || this.input?.input?.parentElement;
    if (!parentNode) return '';

    while (parentNode) {
      parentNode = parentNode.parentElement;
      if (
        parentNode.classList.contains('bdm-form-section') ||
        parentNode.nodeName === 'BODY'
      ) {
        break;
      }
    }
    return parentNode ? parentNode.getAttribute('data-key') : '';
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      propsHasChanged(nextProps, this.props) ||
      nextState.showMap !== this.state.showMap ||
      nextState.clearMap !== this.state.clearMap
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = prevProps;
    const { value: currentValue, dependsOnChange, isReviewer } = this.props;
    if (!isReviewer && value !== currentValue && !dependsOnChange) {
      // we need to sync text input internal component state
      if (this.input) {
        this.input.setState({ value: this.props.value });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      calculatedValue,
      isOwnChange,
      onChange,
      dependsOnChange,
      isReviewer,
      ...props
    } = nextProps;
    const { value: currentValue } = this.props;

    let realValue = props.value;
    if (!isReviewer) {
      if (calculatedValue !== realValue && !isOwnChange && dependsOnChange) {
        if (
          currentValue !== props.value ||
          (calculatedValue === undefined && currentValue === undefined) ||
          calculatedValue !== currentValue
        ) {
          onChange(calculatedValue);
        }
      }
    }
  }

  onChange = event => {
    const { onChange, onBlur } = this.props;
    const {
      target: { value },
    } = event;
    if (this.input?.input === document.activeElement) {
      // current focused element, user is typing
      onChange && onChange(value);
    } else {
      // not currently focused element, data is probably
      // coming from mapbox plugin, call onBlur to trigger auto-save
      onBlur && onBlur(value);
    }
  };

  getLatLng = (coords, cField) => {
    const mbKey = cField.getAttribute('autocomplete').trim();
    if (!cField.value) return coords;
    switch (mbKey) {
      case CUSTOM_LATITUDE:
        coords.lat = +cField.value;
        break;
      case CUSTOM_LONGITUDE:
        coords.lng = +cField.value;
        break;
      default:
        break;
    }
    return coords;
  };

  onClickShowMap = event => {
    const { showMap = false, mapData = {} } = this.state;
    const { value } = this.props;
    const customFields = this.queryCustomFields(this.input.input);
    let coords = Object.values(customFields).reduce(this.getLatLng, {});
    if (isEmpty(coords) && mapData.center) {
      const { center } = mapData;
      const [lng, lat] = center;
      coords = { lng, lat };
    }
    this.setState({
      showMap: !showMap,
      coords,
      shouldClearMap: false,
      prevMapData: mapData,
      hasAddress: !!value,
    });
  };

  getMapIcon = () => {
    const { renderMap } = this.state;

    return renderMap ? (
      <Tooltip
        title="Click this button to look up your address on the map."
        zIndex="1"
      >
        <span onClick={this.onClickShowMap}>
          <StyledIcon type="global" />
        </span>
      </Tooltip>
    ) : null;
  };

  eachMapCustomField = ({ context = [], center = [] }) => cField => {
    const { id } = this.props;
    const obj = { text: '' };
    const mbKey = cField.getAttribute('autocomplete').trim();
    const fieldId = cField.getAttribute('id')?.trim();
    if (id === fieldId || !fieldId) return;
    switch (mbKey) {
      case CUSTOM_ADDRESS_LEVEL1: {
        // state
        const state = context.find(c => c.id.startsWith('region')) || obj;
        setNativeValue(cField, state.text);
        break;
      }
      case CUSTOM_ADDRESS_LEVEL2: {
        // city
        const city = context.find(c => c.id.startsWith('place')) || obj;
        setNativeValue(cField, city.text);
        break;
      }
      case CUSTOM_COUNTY_CITY: {
        const city = context.find(c => c.id.startsWith('place')) || obj;
        const county = context.find(c => c.id.startsWith('district')) || obj;
        const place = [city.text || '', county.text || ''];
        setNativeValue(cField, place.join(', '));
        break;
      }
      case CUSTOM_CITY_STATE_ZIP: {
        const city = context.find(c => c.id.startsWith('place')) || obj;
        const state = context.find(c => c.id.startsWith('region')) || obj;
        const postalCode =
          context.find(c => c.id.startsWith('postcode')) || obj;
        const place = [
          city.text || '',
          state.text || '',
          postalCode.text || '',
        ];
        setNativeValue(cField, place.join(', '));
        break;
      }
      case CUSTOM_POSTAL_CODE: {
        const postalCode =
          context.find(c => c.id.startsWith('postcode')) || obj;
        setNativeValue(cField, postalCode.text);
        break;
      }
      case CUSTOM_LATITUDE: {
        const [, lat] = center;
        setNativeValue(cField, lat);
        break;
      }
      case CUSTOM_LONGITUDE: {
        const [lng] = center;
        setNativeValue(cField, lng);
        break;
      }
    }
  };

  handleOk = () => {
    const { mapData, clearMap } = this.state;
    this.setState({
      showMap: false,
      clearMap: false,
      shouldClearMap: clearMap,
    });
    if (!clearMap && isEmpty(mapData)) return;
    const { place_name = '' } = mapData;
    const { input } = this.input;
    // update current field
    setNativeValue(input, place_name);

    const customFields = this.queryCustomFields(input);
    customFields.forEach(this.eachMapCustomField(mapData));
  };

  handleCancel = () => {
    const { prevMapData, hasAddress } = this.state;
    this.setState({
      showMap: false,
      mapData: prevMapData,
      clearMap: false,
      shouldClearMap: isEmpty(prevMapData) && !hasAddress,
    });
  };

  handleClear = () => {
    this.setState({ mapData: {}, clearMap: true });
  };

  saveMapData = mapData => {
    if (isEmpty(mapData)) {
      this.handleClear();
    } else {
      this.setState({ mapData, clearMap: false });
    }
  };

  renderMapModal = () => {
    const { title, value } = this.props;
    const { renderMap, showMap, coords, clearMap, shouldClearMap } = this.state;
    const okType = clearMap ? 'danger' : 'primary';
    const width = isMobile ? '95%' : '70%';

    return renderMap ? (
      <Modal
        title={title}
        visible={showMap}
        width={width}
        centered={true}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        footer={[
          <Button key="clear" type="danger" ghost onClick={this.handleClear}>
            Clear
          </Button>,
          <Button key="cancel" onClick={this.handleCancel}>
            Cancel
          </Button>,
          <Button key="ok" type={okType} onClick={this.handleOk}>
            Ok
          </Button>,
        ]}
      >
        <MapComponent
          saveData={this.saveMapData}
          coords={coords}
          place={value}
          shouldClearMap={shouldClearMap}
          showMap={showMap}
        />
      </Modal>
    ) : null;
  };

  render() {
    const {
      enums,
      options,
      isReviewer,
      parentKey,
      parentIndex,
      dataKey,
      calculatedValue,
      data,
      isOwnChange,
      dependsOnChange,
      timezone,
      value = '',
      field_type,
      sourceFieldValue,
      ...props
    } = this.props;
    let overrideEditRights = false;
    if (this.props.isContributeReport && !this.props.dependsOn) {
      if (this.props.contributorAssignmentCanEdit) {
        // I am assigned to this section
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        // I am the submitter OR have access to see drafts, so I can see all the content
        return <ReviewerField value={calculatedValue || value} />;
      } else {
        // I have no special rights for this section, hide
        return <ReviewerField />;
      }
    }
    const { autocomplete, disableInputAddress } = this.state;
    // normal reviewer-submitter piece
    if (isReviewer && !overrideEditRights) {
      return <ReviewerField value={calculatedValue || value} />;
    }

    if (sourceFieldValue && !overrideEditRights) {
      return (
        <Input
          bordered="false"
          className="read-only"
          size="default"
          value={htmlUnescape(get(sourceFieldValue, 'value', ''))}
          {...props}
          exref={ref => (this.input = ref)}
        />
      );
    }

    return (
      <StyledInputWrapper>
        <Input
          size="default"
          {...omit(props, ['dispatch'])}
          defaultValue={htmlUnescape(value)}
          ref={ref => (this.input = ref)}
          autocomplete={autocomplete}
          onChange={this.onChange}
          addonBefore={this.getMapIcon()}
          disabled={disableInputAddress || props.disabled}
        />
        {this.renderMapModal()}
      </StyledInputWrapper>
    );
  }
}

const lineBreak = '&#013;&#010;';
const lineBreakRegex = /&#013;&#010;/g;
const escapedLineBreakRegex = /&amp;#013;&amp;#010;/g;

class TextareaInput extends Component {
  state = {
    fieldName: '',
    fieldValue: '',
    fieldParentName: '',
    statusDraft: '',
    isDraftJustSaved: false,
    hasValueChanged: false,
  };

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = prevProps;
    const { value: currentValue, dependsOnChange, isReviewer } = this.props;
    if (!isReviewer && value !== currentValue && !dependsOnChange) {
      if (this.textArea) {
        this.textArea.setState({ value: this.props.value });
      }
    }
  }

  formatData = (fieldParentName, fieldName, fieldValue) => {
    const {
      selectedForm: { data },
      parentIndex,
    } = this.props;
    const changedField = { [fieldName]: fieldValue };
    const changedFields = fieldParentName
      ? { [fieldParentName]: changedField }
      : changedField;
    let dataValue = getRepeaterPropsForAutoSave(changedFields, data);
    if (fieldParentName) {
      dataValue = {
        ...dataValue,
        [fieldParentName]: get(dataValue, fieldParentName).map((d, i) =>
          i === parentIndex ? { ...d, ...changedField } : d
        ),
      };
    }
    return dataValue;
  };

  statusIsDraft = () => {
    const {
      selectedForm,
      selectedForm: { meta },
      currentUser: { id },
    } = this.props;
    const statusInfo = getLastStatus(
      { actions: selectedForm.actions, ...meta },
      id
    );
    const { key: statusKey = 'draft' } = statusInfo;
    const isDraft = statusKey === 'draft' || !selectedForm.meta.id;
    return isDraft;
  };

  triggerAutoSave = debounce(() => {
    const {
      fieldName,
      fieldValue,
      fieldParentName,
      statusDraft,
      isDraftJustSaved,
      hasValueChanged,
    } = this.state;

    const {
      selectedForm,
      dispatch,
      data: {
        activeUser: id,
        meta: { workFlowData: { status = {} } = {} } = {},
      },
    } = this.props;

    const statusIsDraft = statusDraft
      ? statusDraft
      : has(status, id) && get(status, [id, 'labelIdentifier']) === 'DRAFT';

    if (hasValueChanged) {
      const dataValue = this.formatData(fieldParentName, fieldName, fieldValue);
      autoSaveDraftReport(
        statusIsDraft,
        selectedForm,
        dataValue,
        isDraftJustSaved,
        dispatch
      );
      this.setState({ isDraftJustSaved: true });
      this.setState({ hasValueChanged: false });
    }
  }, 500);

  setStateValuesToBeSave = debounce(
    (parentName, name, value, statusIsDraft) => {
      this.setState({ fieldParentName: parentName });
      this.setState({ fieldName: name });
      this.setState({ fieldValue: value });
      this.setState({ statusDraft: statusIsDraft });
      this.setState({ hasValueChanged: true });
    },
    500
  );

  onChange = e => {
    const {
      onChange,
      parentKey,
      dataKey,
      data: {
        activeUser: id,
        meta: { workFlowData: { status = {} } = {} } = {},
      },
    } = this.props;
    const { target: { value = '' } = {} } = e;

    const statusIsDraft =
      has(status, id) && get(status, [id, 'labelIdentifier']) === 'DRAFT';

    const sanitized = value.replace(/\n/g, lineBreak);
    onChange(sanitized);

    this.setStateValuesToBeSave(parentKey, dataKey, sanitized, statusIsDraft);
  };

  onKeyDown = e => {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      if (this.statusIsDraft()) this.triggerAutoSave();
      clearInterval(this.timer);
    }, AUTO_SAVE_TIME * 1000);
  };

  render() {
    const {
      enums,
      options,
      isReviewer,
      dataKey,
      parentKey,
      parentIndex,
      onChange,
      calculatedValue,
      isOwnChange,
      dependsOnChange,
      timezone,
      field_type,
      value: currentValue = '',
      ...props
    } = this.props;
    const { minRows: rows = 4 } = options;
    let overrideEditRights = false;
    let overrideReviewer = isReviewer;

    if (this.props.isContributeReport && !this.props.dependsOn) {
      if (this.props.contributorAssignmentCanEdit) {
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        overrideReviewer = true;
      } else {
        <ReviewerField></ReviewerField>;
      }
    }
    if (overrideReviewer && !overrideEditRights) {
      const value = (currentValue || calculatedValue || '')
        .replace(escapedLineBreakRegex, '<br />')
        .replace(/\n/g, '<br />');
      return <ReviewerField value={value} />;
    }

    const value = (htmlUnescape(currentValue) || '').replace(
      lineBreakRegex,
      '\n'
    );
    return (
      <Input.TextArea
        spellCheck
        rows={rows}
        {...props}
        ref={ref => (this.textArea = ref)}
        defaultValue={value}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const { form: { selectedForm } = {}, session: { currentUser } = {} } = state;
  return {
    sourceFieldValue: getSourceFieldValue(state, props),
    selectedForm,
    currentUser,
  };
};

export default connect(mapStateToProps)(InputText);
export const Textarea = connect(mapStateToProps)(TextareaInput);
