import React, { Component } from 'react';
import { Collapse } from 'antd';
import Tag from '../../common/tag';
import { omit, get, isPlainObject } from 'lodash';

import emitter, { EventTypes } from 'APP_ROOT/utils/eventEmitter';
import { mapProperties } from 'APP_ROOT/utils/form';
import SectionCollapseWarpper from '../styled/section-collapse';

const Panel = Collapse.Panel;

class SectionCollapse extends Component {
  state = {
    activePanel: '',
  };

  componentDidMount() {
    emitter.on(EventTypes.OPEN_TRAINING_TASK, this.setActivePanel);
  }

  componentWillUnmount() {
    emitter.off(EventTypes.OPEN_TRAINING_TASK, this.setActivePanel);
  }

  setActivePanel = async ({
    activeItem = '',
    activeAll = false,
    onDone,
  } = {}) => {
    const { parentIndex } = this.props;
    let activePanel = '';

    if (activeItem === String(parentIndex)) {
      activePanel = String(activeItem);
    }

    this.setState(
      {
        activePanel: activeAll ? String(parentIndex) : activePanel,
      },
      () =>
        setTimeout(() => {
          onDone && onDone();
        }, 800)
    );
  };

  onCollapseChange(item) {
    const { activePanel } = this.state;
    this.setState({
      activePanel: activePanel ? '' : String(item),
    });
  }

  render() {
    const props = this.props;
    const { activePanel } = this.state;

    const {
      data = {},
      form = {},
      parentIndex = 0,
      parentKey = '',
      properties = [],
      dataEnums,
      loopFromEnumRef,
      ...rest
    } = props;

    const total = properties.length;
    const sectionTitle = get(dataEnums, [loopFromEnumRef, parentIndex]);
    const sectionCompleted = get(
      data,
      [parentKey, parentIndex, 'taskCompleted'],
      false
    );
    const plainSectionTitle = isPlainObject(sectionTitle)
      ? get(sectionTitle, 'label', ' ')
      : sectionTitle;

    return (
      <SectionCollapseWarpper>
        <Tag
          size="default"
          className="collapse-status"
          type={sectionCompleted ? 'done' : 'take-action'}
          statusTag
        >
          {sectionCompleted ? 'Completed' : 'Incomplete'}
        </Tag>
        <Collapse
          bordered={false}
          activeKey={activePanel}
          onChange={() => this.onCollapseChange(parentIndex)}
        >
          <Panel
            className="collapse-header"
            header={<span>{plainSectionTitle}&nbsp;</span>}
            key={parentIndex}
          >
            {properties.map(
              mapProperties({
                ...omit(rest, ['conditions', 'options']),
                form,
                parentKey,
                parentIndex,
                total,
                title: null,
              })
            )}
          </Panel>
        </Collapse>
      </SectionCollapseWarpper>
    );
  }
}

export default SectionCollapse;
