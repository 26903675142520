import { isEmpty, capitalize } from 'lodash';

import fetchReadableStream from '../../../caseFile/utils/fetchReadableStream';

import showNotification, { NOTIFICATION_ERROR } from './showNotification';

export const processErrorResponse = response => {
  // 404 could return an object like
  // {
  //   error: 'Not Found',
  //   message: 'No message available',
  //   path: '/api/v1/csf/casefile/f3d4cbbd-01c8-483c-8488-ff38c10b2385',
  //   status: 404,
  //   timestamp: '2022-03-30T23:33:52.476+00:00',
  // }
  // instead of the expected object
  const { errors, error, message } = response;
  const errorMessage = isEmpty(errors)
    ? error || message
    : errors.map(e => capitalize(e.message)).join(', ');
  showNotification(NOTIFICATION_ERROR, 'Heads up!', errorMessage);
};

export const catchError = error => {
  if (error?.response?.body instanceof ReadableStream) {
    fetchReadableStream(error.response.body, processErrorResponse);
  } else {
    showNotification(NOTIFICATION_ERROR, 'Heads up!', error?.message);
  }
};
