import React, { useState } from 'react';
import { Input, Icon } from 'antd';
import WrapperEncryptedInput from './EncryptedInput.styled';
import MaskedInput from 'react-text-mask';

const EncryptedInput = props => {
  const {
    canEdit,
    canView,
    mask,
    value = '',
    visible: isVisible = false,
    searchable = false,
  } = props;
  const [visible, setVisible] = useState(isVisible);
  const onVisible = e => {
    setVisible(!visible);
    const { onSearch } = props;
    if (visible && searchable) {
      onSearch();
    }
  };

  const onKeyPress = e => {
    const { onPressEnter } = props;
    if (searchable) {
      if (e.key === 'Enter') {
        setVisible(false);
        onPressEnter();
      }
    }
  };

  const input = visible ? (
    <MaskedInput
      className="ant-input"
      mask={mask}
      type="text"
      onKeyPress={onKeyPress}
      readOnly={!canEdit}
      {...props}
    />
  ) : (
    <Input value={value.replace(/./g, '*')} readOnly={true} type={'password'} />
  );
  return (
    <WrapperEncryptedInput>
      {canView || canEdit ? (
        <span className="ant-input-group-wrapper">
          <span className="ant-input-wrapper ant-input-group">
            {input}
            <span className="ant-input-group-addon">
              {canView ? (
                <Icon
                  type={
                    visible ? (searchable ? 'search' : 'eye-invisible') : 'eye'
                  }
                  onClick={onVisible}
                />
              ) : (
                <></>
              )}
            </span>
          </span>
        </span>
      ) : (
        input
      )}
    </WrapperEncryptedInput>
  );
};

export const MASK_TYPES = {
  SSN: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
};

export default EncryptedInput;
