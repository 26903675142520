import { get, isPlainObject } from 'lodash';

import { mapExact, mapAsPlain, mapToString } from './enumOptionsMapper';
import { repeaterKeyDivider } from './constants';

const defaultOptions = {
  mapAs: mapExact,
  dataEnums: {},
  enumRef: '',
  parentEnum: null,
  combine: false,
};

const getEnumOptions = (
  fromEnumRef = null,
  defaultValue = [],
  options = defaultOptions
) => {
  const {
    dataEnums = {},
    enumRef = '',
    parentEnum = null,
    mapAs = mapExact,
    combine = false,
  } = Object.assign({}, defaultOptions, options);

  let enums = [];

  if (fromEnumRef) {
    enums = get(dataEnums, fromEnumRef, defaultValue);
  } else if (parentEnum) {
    if (combine) {
      enums = get(dataEnums, `${parentEnum}.0`, defaultValue);
      enums = isPlainObject(enums)
        ? Object.keys(enums).reduce((res, key) => [...res, ...enums[key]], [])
        : enums;
    } else {
      enums = get(
        dataEnums,
        `${parentEnum}${repeaterKeyDivider}${enumRef}`,
        defaultValue
      );
    }
  } else {
    enums = get(dataEnums, enumRef, defaultValue);
  }

  if (mapAs === mapExact) {
    return enums;
  } else {
    return enums.reduce(mapAs, []);
  }
};

getEnumOptions.mapExact = mapExact;
getEnumOptions.mapAsPlain = mapAsPlain;
getEnumOptions.mapToString = mapToString;

export default getEnumOptions;

export { mapExact, mapAsPlain, mapToString } from './enumOptionsMapper';
