import { Select } from 'antd';
import styled from 'styled-components';

const NavigationSelect = styled(Select).attrs({
  className: 'bdm-form-steps-select',
})`
  width: 100%;
  margin-bottom: 10px;
`;

export default NavigationSelect;
