import errorTitle from 'APP_COMPONENTS/common/modal/message/title/error-title';
import successTitle from 'APP_COMPONENTS/common/modal/message/title/success-title';

import openReportsUser from 'APP_COMPONENTS/common/modal/message/open-report-user';
import requiredFields from 'APP_COMPONENTS/common/modal/message/required-field';
import successFields from 'APP_COMPONENTS/common/modal/message/success-fields';

import setApiResponse from 'APP_COMPONENTS/common/modal/message/set-api-response';
import createModal from '../../../../../actions/create-modal';
import showModal from '../../../../../actions/show-modal';

const showErrorMessageModal = (props, error = {}, body) => {
  const { dispatch } = props;
  const { status = '', message } = error;
  let modalBody;

  switch (status) {
    case 412:
      modalBody = openReportsUser;
      break;
    case 'form-error':
      modalBody = requiredFields(message);
      break;
    default:
      modalBody = setApiResponse(message);
      break;
  }
  const option = {
    id: 'create-user-modal-error',
    title: errorTitle,
    children: body || modalBody,
  };

  dispatch(createModal(option));
  dispatch(showModal(option.id));
};

export default {
  errorTitle,
  successTitle,
  successFields,
  requiredFields,
  openReportsUser,
  setApiResponse,
  showErrorMessageModal,
};
