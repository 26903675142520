const isTesting = process.env.NODE_ENV === 'test';

const ifTesting = cb => {
  if (cb && typeof cb === 'function' && !isTesting) {
    return cb();
  }

  return () => null;
};

export default ifTesting;
