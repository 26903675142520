import { every, get, replace, some, includes, isArray } from 'lodash';

const conditionsComplies = (props, rule, data) => {
  const { parentIndex = 0, parentKey = '' } = props;
  const hasParent = !!parentKey;

  if (rule.conditions && rule.conditions.length) {
    return every(rule.conditions, condition => {
      const conditionKey = hasParent
        ? replace(condition.key, '.item.', `.${parentIndex}.`)
        : condition.key;
      const conditionFieldData = get(data, conditionKey, '') || '';

      const conditionIncludes = Array.isArray(condition.includes)
        ? some(condition.includes, expect =>
            includes(conditionFieldData, expect)
          )
        : includes(conditionFieldData, condition.includes);

      return condition.includes
        ? conditionIncludes
        : isArray(condition.expected)
        ? condition.expected.includes(conditionFieldData)
        : conditionFieldData === condition.expected;
    });
  }

  return true;
};

export default conditionsComplies;
