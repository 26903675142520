import { authorizedRequest } from 'APP_ROOT/api/utils';
import { route } from 'APP_ROOT/utils/request';
import urlBuilder from 'APP_ROOT/utils/url-builder';
import { REACT_APP_FORMS_SERVICE_URI } from 'src/utils/request.js';

/**
 * Request to /Agencies/:agencyId/BenchmarkUsers.
 * @param {string} agencyId
 * @param {object} filter
 * @return {Promise}
 */
export const getUsers = (agencyId = '', filters = {}) => {
  const filter = encodeURIComponent(JSON.stringify(filters));
  const url = route(
    urlBuilder(`/Agencies/:agencyId/users`, { agencyId }, { filter })
  );
  return authorizedRequest('GET', url);
};

/**
 * Request to /Forms/:fsId/share.
 * @param {string} formSubmitionId (formSubmitionId)
 * @param {array} shareTo
 * @return {Promise}
 */
export const shareReport = (
  formSubmitionId = '',
  shareTo = [],
  standardShare = true
) => {
  const url = route(
    urlBuilder(`${REACT_APP_FORMS_SERVICE_URI}Forms/:fsId/share`, {
      fsId: formSubmitionId,
    })
  );
  return authorizedRequest('POST', url, { shareTo, standardShare }, '', true);
};

/**
 * Request to /Forms/:fsId/unshare.
 * @param {string} formSubmitionId (formSubmitionId)
 * @param {array} filter
 * @return {Promise}
 */
export const unshareReport = (
  formSubmitionId = '',
  filters = [],
  standardShare = true
) => {
  const unshareTo = encodeURIComponent(JSON.stringify(filters));
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Forms/:fsId/unshare`,
      { fsId: formSubmitionId },
      { unshareTo, standardShare }
    )
  );
  return authorizedRequest('DELETE', url, {}, '', true);
};
