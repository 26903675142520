import { createReducer } from 'redux-create-reducer';

import { SET_SUPPORTED_AGENCY } from '../actions';

const initialState = {
  isAgencyNewWorkFlowActive: false,
  agencyWorkFlowInformationLoaded: false,
};

const events = {
  [SET_SUPPORTED_AGENCY]: (state, { payload: { data = {} } }) => {
    const { isAgencyNewWorkFlowActive, agencyWorkFlowInformationLoaded } = data;
    return {
      ...initialState,
      isAgencyNewWorkFlowActive,
      agencyWorkFlowInformationLoaded,
    };
  },
};

export default createReducer(initialState, events);
