import React from 'react';
import Status from './status';
import { pull, concat } from 'lodash';

export const INTAKE_SUBMIT_CUSTOM_STATUS = 'INTAKE_CUSTOM_STATUS';
export const INTAKE_SUBMIT_REVIEW_STATUS = 'IN_REVIEW';
export const INTAKE_SUBMIT_COMPLETED_STATUS = 'COMPLETED';

export const compileFilterForIntakeStatus = (filters = []) => {
  const mainFilters = filters.map(item => {
    if (
      /**
       * Previously expressed as !item.column === 'status', this would raise a sonarqube error because the ! before
       * the left operand would turn it into a boolean value, making the comparison always false.
       * I assume the original intention was to compare if the item's column was different than 'status'.
       */
      item.column !== 'status' ||
      !item.value.includes(INTAKE_SUBMIT_CUSTOM_STATUS)
    )
      return item;
    const newValue = concat(pull(item.value, INTAKE_SUBMIT_CUSTOM_STATUS), [
      INTAKE_SUBMIT_REVIEW_STATUS,
      INTAKE_SUBMIT_COMPLETED_STATUS,
    ]);
    return {
      ...item,
      value: newValue,
    };
  });
  return mainFilters;
};

function transformStatusFilter(status) {
  if (status && Array.isArray(status.inq) && status.inq.length) {
    return status.inq.reduce((result, value) => {
      if (value === INTAKE_SUBMIT_CUSTOM_STATUS) {
        return result.concat([
          INTAKE_SUBMIT_REVIEW_STATUS,
          INTAKE_SUBMIT_COMPLETED_STATUS,
        ]);
      }
      return result.concat(value);
    }, []);
  }
  return status;
}

const IntakeStatus = ({ label = '', template = {}, onChange, clearFilter }) => {
  const { statuses = [] } = template;

  const intakeTemplateStatuses = {
    statuses: statuses
      .filter(
        ({ id }) =>
          id !== INTAKE_SUBMIT_REVIEW_STATUS &&
          id !== INTAKE_SUBMIT_COMPLETED_STATUS
      )
      .concat({ id: INTAKE_SUBMIT_CUSTOM_STATUS, label: 'Submitted' }),
  };

  const handleStatusChange = status => {
    const transformedStatus = transformStatusFilter(status);

    onChange([
      {
        column: 'status',
        operator: 'inq',
        value: transformedStatus,
      },
    ]);
  };

  return (
    <Status
      template={intakeTemplateStatuses}
      label={label}
      onChange={handleStatusChange}
      clearFilter={clearFilter}
      propagateDirectValue
    />
  );
};

export default IntakeStatus;
