export const EVENT_TYPE_LIST = [
  { label: 'View', value: 'view' },
  { label: 'Created', value: 'created' },
  { label: 'Update', value: 'update' },
  { label: 'Deleted', value: 'deleted' },
  { label: 'Assigned', value: 'assigned' },
  { label: 'Unassigned', value: 'unassigned' },
  { label: 'Shared', value: 'shared' },
  { label: 'Unshared', value: 'unshared' },
  { label: 'Status Changed', value: 'statuschanged' },
  { label: 'Downloaded', value: 'downloaded' },
  { label: 'Removed', value: 'removed' },
];

export const TABLE_LIST = [
  { label: 'Task', value: 'task' },
  { label: 'Report', value: 'report' },
  { label: 'Document', value: 'document' },
  { label: 'Note', value: 'note' },
];
