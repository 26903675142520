import { get } from 'lodash';

export const isProductionEnv = process.env.NODE_ENV === 'production';
export const isDevelopmentEnv = process.env.NODE_ENV === 'development';
export const isTestingEnv = process.env.NODE_ENV === 'test';
export const currentEnv = process.env.NODE_ENV;
export const selectByEnv = options =>
  get(options, currentEnv, get(options, '__'));
export const getEnvironmentVariable = (name, defaultValue = '') => {
  const VAR_NAME = String(name).toUpperCase();
  const variableValue =
    process.env[VAR_NAME] || process.env[`REACT_APP_${VAR_NAME}`];

  if (variableValue !== undefined) {
    return variableValue;
  }

  return defaultValue;
};

export default () => {
  global.VERSION = {
    API_URL: process.env.REACT_APP_API_URL,
    API_VERSION: process.env.REACT_APP_API_VERSION || 'unknown',
    CLIENT_VERSION: process.env.REACT_APP_CLIENT_VERSION || 'unknown',
    VERSION: process.env.REACT_APP_VERSION || 'unknown',
    ENV: process.env.REACT_APP_RUNTIME_ENV || 'no specified',
  };
};
