import { v4 as uuid } from 'uuid';
import { find, get } from 'lodash';

import {
  symlinkFromPropertyName,
  symlinkToPropertyName,
  symlinkIndexPropertyName,
  symlinkIdPropertyName,
} from './create-symlink';

let _symlinksCache = {};

export const clearSymlinksCache = () => {
  _symlinksCache = {};
  return _symlinksCache;
};

export const updateSymlinkCacheFor = (repeater, nestedRepeater, index, id) => {
  const path = `${repeater}.${nestedRepeater}.${index}`;
  _symlinksCache[path] = id;
  return id;
};

export default (repeater, nestedRepeater, index, symlinks) => {
  const exists = find(
    symlinks,
    item =>
      get(item, symlinkFromPropertyName) === repeater &&
      get(item, symlinkToPropertyName) === nestedRepeater &&
      get(item, symlinkIndexPropertyName) === index
  );

  const path = `${repeater}.${nestedRepeater}.${index}`;

  if (exists) {
    const id = get(exists, symlinkIdPropertyName);

    return updateSymlinkCacheFor(repeater, nestedRepeater, index, id);
  }

  if (!_symlinksCache[path]) {
    const symlinkId = uuid();
    _symlinksCache[path] = symlinkId;
  }

  return _symlinksCache[path];
};
