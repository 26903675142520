import React from 'react';
import { connect } from 'react-redux';
import { last } from 'lodash';
import { Timeline } from 'antd';
import moment from 'moment-timezone';

import { BENCHMARK_DATE_FORMAT, momentWithTZ } from 'APP_ROOT/utils/parse-date';
import { getCompleteSelectedUser } from 'APP_ROOT/selectors/selected-users';

const HistoricalUnitAssignment = props => {
  const {
    timezone,
    selectedUser: { history = [] },
    options,
  } = props;

  const normalizedHistory = history.reduce((res, item) => {
    if (!item.division && !item.unitAssignment) {
      return res;
    }
    const momentifiedVersionDate = momentWithTZ(item.versionDate, timezone);

    const formattedMomentifiedVersionDate = momentifiedVersionDate.format(
      'MM/DD/YYYY'
    );
    let timelineItemTitle = formattedMomentifiedVersionDate;

    if (res.length > 0) {
      const _last = last(res);
      const momentifiedLastVersionDate = momentWithTZ(
        _last.versionDate,
        timezone
      );
      const diff = momentifiedLastVersionDate.diff(
        momentifiedVersionDate,
        'days'
      );

      if (diff > 1) {
        timelineItemTitle = `${formattedMomentifiedVersionDate} - ${momentifiedLastVersionDate
          .subtract(1, 'day')
          .format(BENCHMARK_DATE_FORMAT)}`;
      }

      return [
        ...res,
        {
          ...item,
          timelineItemTitle,
        },
      ];
    } else {
      const todayMoment = moment()
        .tz(timezone)
        .format(BENCHMARK_DATE_FORMAT);
      const diff = momentifiedVersionDate.diff(moment(), 'days');

      if (diff > 1) {
        timelineItemTitle = `${formattedMomentifiedVersionDate} - ${todayMoment}`;
      }

      return [
        {
          ...item,
          timelineItemTitle,
        },
      ];
    }
  }, []);

  return (
    <Timeline {...options}>
      {normalizedHistory.map(item => {
        return (
          <Timeline.Item key={item.versionDate}>
            <p>{item.timelineItemTitle}</p>
            <ul>
              {item.unitAssignment && (
                <li>
                  <p>- {item.unitAssignment}</p>
                </li>
              )}
              {item.division && item.unitAssignment !== item.division && (
                <li>
                  <p>- {item.division}</p>
                </li>
              )}
            </ul>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

const mapState = state => ({
  selectedUser: getCompleteSelectedUser(state),
});

export default connect(mapState)(HistoricalUnitAssignment);
