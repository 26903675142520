import React from 'react';

import createModal from 'APP_ROOT/actions/create-modal';
import showModal from 'APP_ROOT/actions/show-modal';
import { SHARE_CONFIRM_ID } from '../constants/share';
import ConfirmShareBody from '../components/ConfirmShareBody';
import ConfirmShareHeader from '../components/ConfirmShareHeader';

export default props => {
  return (dispatch, getState) => {
    const title = <ConfirmShareHeader />;
    const body = <ConfirmShareBody {...props} />;
    dispatch(
      createModal({
        id: SHARE_CONFIRM_ID,
        visible: true,
        title,
        children: body,
      })
    );
    dispatch(showModal(SHARE_CONFIRM_ID));
  };
};
