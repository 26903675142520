import React from 'react';
import { isEmpty, uniq } from 'lodash';
import { Collapse, List } from 'antd';

import ModalBody from '../components/common/modal/body';

const { Panel } = Collapse;

const getErrorMessageModalBody = errorMessage => {
  const isErrorArray = !isEmpty(errorMessage) && Array.isArray(errorMessage);
  return (
    <ModalBody>
      {errorMessage && !isErrorArray && <p>{errorMessage}</p>}
      {(!errorMessage || isErrorArray) && (
        <p>
          Some of the required fields are empty or were not answered correctly.
          Please double-check and try again.
        </p>
      )}
      <br />
      {isErrorArray && (
        <Collapse bordered={false}>
          <Panel header="Details" key="1">
            <List
              size="small"
              bordered={false}
              dataSource={uniq(errorMessage)}
              renderItem={item => <List.Item>{item}</List.Item>}
            />
          </Panel>
        </Collapse>
      )}
    </ModalBody>
  );
};

export default getErrorMessageModalBody;
