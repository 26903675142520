import { repeaterKeyDivider } from '../constants';

const replaceLastIndex = (str, index) => {
  // data structure does not have nested repeaters,
  // because of that we need to remove nesting from the key
  const key = str
    .split('.')
    .slice(-3)
    .join('.');
  // Check if key is from a repeater
  if (key.indexOf(repeaterKeyDivider) !== -1) {
    return key.replace(repeaterKeyDivider, `.${index}.`);
  }
  return key;
};

export default replaceLastIndex;
