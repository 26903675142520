import organizationEndpoints from '../../../api/organization/organizationEndpoints';

export default (
  page,
  size,
  filter = '',
  customField = '',
  agencyId = '',
  orgUnitId = '',
  includeHierarchy = true
) => {
  return new Promise((resolve, reject) => {
    organizationEndpoints
      .getOuUsers({
        page,
        size,
        filter,
        customField,
        agencyId,
        orgUnitId,
        includeHierarchy,
      })
      .then(response => {
        const ouList = response.users.map(ou => {
          ou.certified === true
            ? (ou.certified = 'yes')
            : (ou.certified = 'no');
          ou.ouNames = ou.ouNames.join(', ');
          return { ...ou };
        });
        resolve({
          data: ouList,
          total: response.total,
          totalPages: response.totalPages,
          error: null,
        });
      })
      .catch(error => {
        reject({ data: null, error });
      });
  });
};
