import { isEmpty } from 'lodash';
import {
  SELECT,
  NUMBER,
  SWITCH,
  TEXTAREA,
  MASKED,
  AUTOCOMPLETE,
  HYPERLINK,
  STATIC_CONTENT,
  DATE,
  DATE_TIME,
  DURATION,
  MATH,
} from 'APP_ROOT/constants/fieldTypes';
import { CAPTURE_TEXT, CAPTURE_CHECKBOX } from './constants';
import { MASK_TYPE, SSN_TYPE } from '../../constants/fieldMasked';
import { CASE_NUMBER, EVENT_DATE } from '../../constants/constants';
import componentsManager from '../../services/componentsManager';

import getTitle from '../../utils/getTitle';

import createCaptureDef from './createCaptureDef';

export function getNumberOptions(values, props) {
  const { extra = '', options = {} } = values;
  const { fieldGroup } = props;
  const { showTitle = true, mode } = options;
  let fieldOptions = {};
  if (fieldGroup) {
    fieldOptions = {
      ...fieldOptions,
      extra: createCaptureDef(CAPTURE_TEXT, 'Extra title', extra, {
        maxLength: 50,
      }),
      showTitle: createCaptureDef(CAPTURE_CHECKBOX, 'Show Title', showTitle),
      titleInline: mode === 'inline',
    };
  }
  return fieldOptions;
}

export function getTextareaOptions(values) {
  const { options = {} } = values;
  const { showTitle = true } = options;
  return {
    showTitle: createCaptureDef(CAPTURE_CHECKBOX, 'Show Title', showTitle, {
      isDisabled: val => val.wrapperCol,
    }),
  };
}

export function getSelectOptions(values) {
  const { options = {} } = values;
  const { showTitle = true, populateFrom } = options;
  return {
    showTitle: createCaptureDef(CAPTURE_CHECKBOX, 'Show Title', showTitle),
    populateFrom,
  };
}

export function getAutocompleteOptions(values) {
  const { options = {} } = values;
  const { userMention = true } = options;
  return {
    userMention: createCaptureDef(
      CAPTURE_CHECKBOX,
      'Mentioned User',
      userMention
    ),
  };
}

export function getHyperlinkOptions(values) {
  const { options = {} } = values;
  const { showTitle = true } = options;
  return {
    showTitle: createCaptureDef(CAPTURE_CHECKBOX, 'Show Title', showTitle),
  };
}

export function getDateOptions(values) {
  const { options = {} } = values;
  const { allowFuture = false } = options;
  return {
    allowFuture: createCaptureDef(
      CAPTURE_CHECKBOX,
      'Allow Future Date',
      allowFuture
    ),
  };
}

function getRequiredAndEncryptOptions(values) {
  const [
    { required = false, message = '', mustExist, encrypt = false },
  ] = componentsManager.getValidationRules(values.key);
  let requiredOption = {
    encrypt: createCaptureDef(CAPTURE_CHECKBOX, 'Encrypt', encrypt, {
      isHidden: (
        val // for now have it hidden all the time
      ) =>
        (val.type === MASKED && (val[MASK_TYPE] || {}).option === SSN_TYPE) ||
        true,
    }),
  };

  if (
    ![SWITCH, HYPERLINK, STATIC_CONTENT, DURATION, MATH].includes(
      values.field_type
    )
  ) {
    requiredOption = {
      ...requiredOption,
      required: createCaptureDef(
        CAPTURE_CHECKBOX,
        'Required',
        required || !!mustExist,
        { isHidden: val => val.readOnly.value }
      ),
      message: createCaptureDef(CAPTURE_TEXT, 'Req. message', message, {
        maxLength: 50,
        isHidden: val => !val.required.value,
      }),
    };
  }
  return requiredOption;
}

const isHiddenTitle = field_type =>
  field_type === STATIC_CONTENT
    ? () => true
    : val => (val.showTitle === undefined ? false : !val.showTitle.value);

const getTooltip = (field_type, options) =>
  [STATIC_CONTENT, DURATION].includes(field_type)
    ? {}
    : createCaptureDef(CAPTURE_TEXT, 'Tooltip', options.tooltipText || '', {
        maxLength: 200,
      });

const getReadOnly = (field_type, values) =>
  [HYPERLINK, STATIC_CONTENT, DURATION, MATH].includes(field_type)
    ? {}
    : createCaptureDef(
        CAPTURE_CHECKBOX,
        'Read only',
        values.readOnly || false,
        {
          isHidden: isHiddenReadOnly(field_type),
          isDisabled: val => !isEmpty((val.fromSource || {}).key),
        }
      );

const isHiddenReadOnly = val =>
  (val.required && val.required.value) || val[CASE_NUMBER] || val[EVENT_DATE];

export function getCommonOptions(values) {
  const { options = {}, field_type } = values;
  const requiredOption = getRequiredAndEncryptOptions(values);
  const tooltip = getTooltip(field_type, options);
  const readOnly = getReadOnly(field_type, values);

  return {
    reportingKey: createCaptureDef(
      CAPTURE_TEXT,
      'Reporting Key',
      values.reportingKey,
      { maxLength: 100 }
    ),
    title: createCaptureDef(CAPTURE_TEXT, 'Title', getTitle(values.title, ''), {
      maxLength: 100,
      isHidden: isHiddenTitle(field_type),
    }),
    tooltip,
    readOnly,
    information: options.information,
    ...requiredOption,
  };
}

export function setRequiredOptions(values) {
  const { type, required = {} } = values;
  return [SWITCH, HYPERLINK, STATIC_CONTENT, DURATION, MATH].includes(type)
    ? false
    : required.value;
}

export function setReadOnlyOptions(values, isRequired = false) {
  const { readOnly = {} } = values;
  return isRequired ? false : readOnly.value || false;
}

export function setNumberOptions(values, props) {
  const { showTitle = {}, titleInline } = values;
  const { fieldGroup } = props;
  let options = {};

  if (fieldGroup) {
    options = {
      ...options,
      showTitle: showTitle.value,
      mode: titleInline ? 'inline' : undefined,
    };
  }
  return options;
}

export function setTextareaOptions(values) {
  const { showTitle = {} } = values;
  // by default the title is shown, then save it when
  // it is false, undefined to use the default
  const options = {
    showTitle: showTitle.value === false ? false : undefined,
  };
  return options;
}

export function setSelectOptions(values) {
  const { showTitle = {}, populateFrom } = values;
  const options = {
    showTitle: showTitle.value === false ? false : undefined,
    populateFrom: populateFrom ? populateFrom : undefined,
  };
  return options;
}

export function setMaskedOptions(values, isRequired, _isEncrypt) {
  const { message = {} } = values;
  let { value: messageText = '' } = message;
  if (!isRequired || messageText === '') {
    // if not required customize message for regex possible error
    // otherwise keep whatever the user defined
    messageText = 'Input data does not match format.';
  }
  // always encrypt for SSN
  const isEncrypt = _isEncrypt || values[MASK_TYPE].option === SSN_TYPE;
  return [isEncrypt, messageText];
}

export function setAutocompleteOptions(values) {
  const { userMention = {} } = values;
  const options = {
    userMention: userMention.value,
  };
  return options;
}

export function setHyperlinkOptions(values) {
  const { showTitle = {} } = values;
  return {
    showTitle: showTitle.value === false ? false : undefined,
  };
}

export function setDateOptions(values) {
  const { allowFuture = {} } = values;
  return {
    allowFuture: allowFuture.value === true ? true : undefined,
  };
}

export function setOptionsByType(values, props) {
  const { type } = values;
  let options = {};
  switch (type) {
    case NUMBER: {
      options = setNumberOptions(values, props);
      break;
    }
    case TEXTAREA: {
      options = setTextareaOptions(values);
      break;
    }
    case SELECT: {
      options = setSelectOptions(values);
      break;
    }
    case AUTOCOMPLETE: {
      options = setAutocompleteOptions(values);
      break;
    }
    case HYPERLINK: {
      options = setHyperlinkOptions(values);
      break;
    }
    case DATE:
    case DATE_TIME:
      options = setDateOptions(values);
      break;
    default:
      break;
  }
  return options;
}
