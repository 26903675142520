import get from 'lodash/get';

import { getCaseload } from '../api/reports';
import { getInternalAffairCaseLoadPerPage } from '../selectors/internal-affair-case-load';

import { FETCH_CASELOAD_REQUEST } from './';
import fetchCaseloadSuccess from './fetch-caseload-success';
import fetchCaseloadError from './fetch-caseload-error';
import { getReqPaginationParams } from './utils';

export default (page = 1, filters = {}, next = () => {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const { session: { currentUser: { agencyId } = {} } = {} } = state;
    const perPage = getInternalAffairCaseLoadPerPage(state);
    dispatch({ type: FETCH_CASELOAD_REQUEST, payload: { perPage, page } });
    return getCaseload({
      agencyId,
      filters: {
        ...filters,
        ...getReqPaginationParams(perPage, page),
      },
    })
      .then(response => {
        dispatch(fetchCaseloadSuccess(response));
        next(null, response);
      })
      .catch(error => {
        dispatch(fetchCaseloadError(get(error, ['response', 'status'])));
        next(error);
      });
  };
};
