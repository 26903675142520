const profileIcon = require('../icons/ic_officer_profile.svg');
const trainingIcon = require('../icons/ic_officer_training.svg');
const activityIcon = require('../icons/ic_officer_activity.svg');
const performanceEvaluationIcon = require('../icons/ic_officer_performance_evaluation.svg');
const useOfForceIcon = require('../icons/ic_officer_use_of_force.svg');
const internalAffairsIcon = require('../icons/ic_officer_internal_affairs.svg');
const communityEngagementIcon = require('../icons/ic_officer_community_engagement.svg');

const wheelNavItems = [
  {
    id: 0,
    text: 'Officer Profile',
    icon: profileIcon,
    route: '/profile',
  },
  {
    id: 1,
    text: 'Training',
    icon: trainingIcon,
    route: '/training',
  },
  {
    id: 2,
    text: 'Activity',
    icon: activityIcon,
    route: '/activity',
  },
  {
    id: 3,
    text: 'Use Of Force',
    icon: useOfForceIcon,
    route: '/use-of-force',
  },
  {
    id: 4,
    text: 'Internal Affairs',
    icon: internalAffairsIcon,
    route: '/internal-affairs',
  },
  {
    id: 5,
    text: 'Community Engagement',
    icon: communityEngagementIcon,
    route: '/community-engagement',
  },
  {
    id: 6,
    text: 'Performance Evaluation',
    icon: performanceEvaluationIcon,
    route: '/performance-evaluation',
  },
];

export default wheelNavItems;
