import React from 'react';
import { Select } from 'antd';
import _get from 'lodash/get';
import castArray from 'lodash/castArray';

const Option = Select.Option;

const ALLEGATION_TYPES_GROUP_ENUM = 'allegationTypesGroup';
const IA_INVESTIGATION_FORM_TYPE = 'Internal Affairs Investigation';

/**
 * @param {object} template
 * @param {string} ENUM_NAME
 * @return {string[]} The contents of the found enum, will return empty even if
 *                    nothing is found.
 */
function getTemplateFormSchemaEnum(template, ENUM_NAME) {
  return _get(template, `formSchema.enums.${ENUM_NAME}`, []);
}

/**
 * @param {string[]} A list of the avialbale allegation values.
 * @return {function[]} A list of "antd Option" components.
 */
function getAllegationOptions(allegationValues) {
  return []
    .concat(castArray(allegationValues))
    .sort()
    .filter(allegation => allegation !== 'Other')
    .map(allegation => (
      <Option key={allegation} value={allegation}>
        {allegation}
      </Option>
    ))
    .concat(
      <Option key="Other" value="Other">
        Other
      </Option>
    );
}

/**
 * Using the template type property determines if it's of type IA Investigation
 * @param {object} template
 * @return {boolean}
 */
function isTemplateTypeInvestigation(template) {
  return template.type === IA_INVESTIGATION_FORM_TYPE;
}

/**
 * Returns an "antd.Select" for multiple selection over the avialbale
 * configuration types.
 * The avialbale configuration types are extracte from the following path in the store state:
 * - `form > templates > internal-affairs-complaint > formSchema > enums > allegationsOfficer`
 * - `form > templates > internal-affairs-complaint > formSchema > enums > allegationsUnknown`
 *
 * @param {object} configuration The filter configuration.
 * @param {string} configuration.label This value may be used as a label for the input.
 * @param {string} configuration.template An object containing the "formType" template.
 * @param {function} configuration.onChange A callback for the input "onChange" event.
 *
 * @return {function} A react component.
 */
export class AllegationsFilter extends React.PureComponent {
  render() {
    const {
      label = '',
      template = {},
      setFilters,
      filterType,
      filterValues,
      ...props
    } = this.props;
    const [, ...value] = filterValues[filterType];

    const templateTypeIsInvestigation = isTemplateTypeInvestigation(template);
    const allegationTypes = getTemplateFormSchemaEnum(
      template,
      ALLEGATION_TYPES_GROUP_ENUM
    );
    const allegationOptions = getAllegationOptions(allegationTypes);
    const onSelectChange = values => {
      const items = values.length
        ? [templateTypeIsInvestigation, ...values]
        : [];

      setFilters(filterType, items);
    };
    return (
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder={label}
        size="default"
        onChange={onSelectChange}
        value={value}
        allowClear
        {...props}
      >
        {allegationOptions}
      </Select>
    );
  }
}

export default AllegationsFilter;
