import React from 'react';
import { Icon } from 'antd';

export const userEquipmentTableColumns = [
  {
    title: 'Category',
    dataIndex: 'category',
  },
  {
    title: 'Item Description',
    dataIndex: 'itemDescription',
  },
  {
    title: 'ID Number',
    dataIndex: 'idNumber',
  },
  {
    title: 'Date Issued',
    dataIndex: 'issueDate',
  },
  {
    title: 'Expiration Date',
    dataIndex: 'expiration',
    render: expirationDate => {
      const expirationDateObj = new Date(expirationDate);
      const currentDate = new Date();
      const isExpired = expirationDateObj < currentDate;

      return (
        <span
          className={
            isExpired
              ? 'expiration-date-expired'
              : 'expiration-date-not-expired'
          }
        >
          {expirationDate}
          {isExpired && (
            <Icon type="exclamation-circle" className={'expiration-icon'} />
          )}
        </span>
      );
    },
  },
];
