import getComponentChildren from '../utils/getComponentChildren';
import getComponentOptions from '../utils/getComponentOptions';

import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes';

const getComponentData = (field, key, updateEnum, columns, parentRepeater) => {
  const { type, properties } = field;
  let children = properties;
  let fieldOptions;
  let enumRef;
  let fieldValidations;
  if (!Array.isArray(properties)) {
    if (type === FIELD) {
      [fieldOptions, enumRef, fieldValidations] = getComponentOptions(
        field,
        key,
        updateEnum
      );
    } else {
      [children, fieldOptions, fieldValidations] = getComponentChildren(
        type,
        updateEnum,
        columns,
        parentRepeater
      );
    }
  }

  return [fieldOptions, enumRef, fieldValidations, children];
};

export default getComponentData;
