import equipmentEndpoints from '../../../../api/equipment/equipmentEndpoints';
import callback from '../../utils/callback';

export default ({ tenantId, equipmentId, next }) => _dispatch => {
  return equipmentEndpoints
    .deleteUserEquipment({
      tenantId,
      equipmentId,
    })
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
