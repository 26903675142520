import {
  FETCH_OUTCOMES_LOADING,
  FETCH_OUTCOMES_LOADING_SUCCESSFUL,
  FETCH_OUTCOMES_LOADING_ERROR,
  FETCH_REASONS_LOADING,
  FETCH_REASONS_LOADING_SUCCESSFUL,
  FETCH_REASONS_LOADING_ERROR,
  FETCH_STATUSES_LOADING,
  FETCH_STATUSES_LOADING_SUCCESSFUL,
  FETCH_STATUSES_LOADING_ERROR,
} from './actions';

export const initialState = {
  statuses: { value: [], loading: false },
  reasons: { value: [], loading: false },
  outcomes: { value: [], loading: false },
};

export const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_STATUSES_LOADING:
      return { ...state, statuses: { ...state.statuses, loading: true } };
    case FETCH_STATUSES_LOADING_SUCCESSFUL:
      return {
        ...state,
        statuses: {
          loading: false,
          value: action.payload,
        },
      };
    case FETCH_STATUSES_LOADING_ERROR:
      return { ...state, statuses: { ...state.statuses, loading: false } };
    case FETCH_OUTCOMES_LOADING:
      return { ...state, outcomes: { ...state.outcomes, loading: true } };
    case FETCH_OUTCOMES_LOADING_SUCCESSFUL:
      return {
        ...state,
        outcomes: {
          loading: false,
          value: action.payload,
        },
      };
    case FETCH_OUTCOMES_LOADING_ERROR:
      return { ...state, outcomes: { ...state.outcomes, loading: false } };
    case FETCH_REASONS_LOADING:
      return { ...state, reasons: { ...state.reasons, loading: true } };
    case FETCH_REASONS_LOADING_SUCCESSFUL:
      return {
        ...state,
        reasons: {
          loading: false,
          value: action.payload,
        },
      };
    case FETCH_REASONS_LOADING_ERROR:
      return { ...state, reasons: { ...state.reasons, loading: false } };
    default:
      return { ...state };
  }
};
