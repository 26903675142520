import { find, get, isEmpty, omit } from 'lodash';
import getUserEmploymentHistoryById from '../../actions/get-user-employment-id';
import { message } from 'antd';
import { change } from 'redux-form';
import parseDate from '../../../../../utils/parse-date';
import { userProfileFormName } from '../constants/form-values.constants';
const formName = userProfileFormName;

export const getLoadUserEmploymentDetail = object => {
  return (historyId, callback) => {
    const { dispatch, profileForm } = object.props;
    const userIntegrationId = get(profileForm, `values.integrationId`);
    const { employmentHistoryData } = object.state;
    if (isEmpty(profileForm.values.organizationalUnitHistoryData[historyId])) {
      dispatch(
        getUserEmploymentHistoryById(
          {
            historyId,
            userId: userIntegrationId,
          },
          (error, { employment } = []) => {
            if (error) {
              message.error(
                'Something wrong occurred, loading user employment history please try again later.'
              );
              return false;
            }
            const employmentData = mapUserEmploymentData(
              employment,
              object.props
            );
            if (
              !find(
                employmentHistoryData,
                value => value.historyId === historyId
              )
            ) {
              employmentHistoryData.push({ historyId, detail: employmentData });
            }
            object.setState({
              employmentHistoryData: employmentHistoryData,
            });
            dispatch(
              change(
                formName,
                `organizationalUnitHistoryData[${historyId}]`,
                employmentData
              )
            );
          }
        )
      );
    }

    callback && callback();
  };
};

const mapUserEmploymentData = (empData = [], props) => {
  const {
    session: {
      currentUser: {
        agency: { timezone = '' },
      },
    },
  } = props;

  const formatedData = empData.map(employment => {
    const updatedAt = parseDate(
      employment.updatedAt ? employment.updatedAt : employment.createdAt,
      timezone,
      'MM/DD/YYYY, HH:mm'
    );
    return {
      ...employment,
      updated: employment.lastModifiedBy
        ? `${updatedAt} by ${employment.lastModifiedBy} `
        : `${updatedAt} by ${employment.createdBy} `,
    };
  });

  const keys = Object.keys(
    omit(formatedData[0], [
      'createdAt',
      'updatedAt',
      'createdBy',
      'lastModifiedBy',
    ])
  );
  return formatedData.reduce(
    (allData, employment) => {
      return keys.reduce((data, key) => {
        data[key].push(employment[key]);
        return data;
      }, allData);
    },
    keys.reduce((o, key) => ({ ...o, [key]: [] }), {})
  );
};

export const getUserEmploymentData = mapUserEmploymentData;
