import React from 'react';
import { Form } from 'antd';

import withoutClutter from '../../../components/without-clutter';
import DashboardPage from '../../../components/dashboard';
import CommunityMemberForm from './CommunityMemberForm';
import { get } from 'lodash';
import { hasPermissions } from '../../../utils/admin';

class CommunityDetail extends React.Component {
  WrappedHorizontalLoginForm = Form.create()(CommunityMemberForm);

  render() {
    const {
      manageCommunityMember,
      communityList,
      dispatch,
      tenantId,
      history,
      match: {
        params: { communityMemberId },
      },
    } = this.props;
    return (
      <this.WrappedHorizontalLoginForm
        manageCommunityMember={manageCommunityMember}
        communityList={communityList}
        dispatch={dispatch}
        communityMemberId={communityMemberId}
        history={history}
        tenantId={tenantId}
      ></this.WrappedHorizontalLoginForm>
    );
  }
}
const mapState = (state, props) => ({
  communityList: get(state, ['organization', 'community']),
  communityMemberId: props.match.params.communityMemberId,
  dispatch: props.dispatch,
  manageCommunityMember: hasPermissions(
    props.permissions,
    'manageCommunityMember'
  ),
  tenantId: props.agency.integrationId,
});

export default withoutClutter(DashboardPage(mapState, null)(CommunityDetail));
