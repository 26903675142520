import styled from 'styled-components';

const FormHeader = styled.div`
  display: flex;
  width: 100%;

  .steps-navigation {
    margin-top: 30px;
  }

  .btm-form-steps-navigation {
    padding-right: 16px;
  }

  .bdm-form-steps-select {
    padding-right: 14px;

    @media (max-width: 480px) {
      padding-right: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .ant-steps {
      display: none;
    }

    .header-info {
      padding-left: 45px;

      .steps-navigation {
        margin-top: 0;
      }

      .btm-form-steps-navigation {
        .ant-btn {
          margin: 0 !important;
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .ant-steps {
      display: none;
    }

    .header-info {
      padding-left: 40px;

      @media (max-width: 480px) {
        padding-left: 16px;
      }

      .steps-navigation {
        margin-top: 10px;
      }
    }
  }

  @media screen and (min-width: 480px) {
    .bdm-form-steps-select {
      padding-right: 0;
      position: absolute;
      top: -99999px;
      opacity: 0;
    }
  }

  @media screen and (min-width: 769px) {
    .bdm-form-steps-select {
      position: absolute;
      top: -99999px;
      opacity: 0;
    }
  }

  @media print {
    position: relative;
    font-family: 'Lato', Arial, serif;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 15px;
    box-shadow: none;
    background-color: transparent;

    .form-info,
    .form-actions {
      font-size: 14px;
    }

    .form-actions__meta {
      display: ${props => (props.isPrintingConfirmation ? 'none' : 'block')};
    }

    .form-info__back,
    .form-info__status,
    .form-actions__buttons,
    .form-info__type,
    .header-info {
      display: none;
      visibility: hidden;
    }

    [class^='ant-col'] {
      width: 100%;
    }

    .form-actions,
    .form-actions__meta {
      margin-left: 0;
      text-align: left !important;
      justify-content: flex-start;
    }
  }

  &.loading-placeholder {
    .svg-loading-animation {
      max-width: 100%;

      &.is-form-name {
        width: 250px;
      }

      &.is-form-tabs {
        width: 350px;
      }
    }
  }
`;

export default FormHeader;
