import styled from 'styled-components';

const MenuDivider = styled.li.attrs({
  role: 'separator',
  className: 'ant-menu-item-divider',
})`
  && {
    background-color: #283544 !important;
    margin: 5px 0 !important;
  }
`;

export default MenuDivider;
