export const ALERT_MESSAGE = 'alert';
export const COLUMN = 'column';
export const DIVIDER = 'divider';
export const FIELD = 'field';
export const FIELD_GROUP = 'field-group';
export const GOTO_BLOCK = 'goto-block';
export const GROUP = 'object';
export const HISTORICAL_UNIT_ASSIGNMENT = 'historical-unit-assignment';
export const INJURIES_BODY = 'injuries-body';
export const PLAN_TEXT = 'plain-text';
export const REPEATER = 'repeater';
export const REPEATER_ACTION = 'repeater-action';
export const REQUIREMENT_COMPLIANT = 'requirement-compliant';
export const ROW = 'row';
export const SECTION = 'section';
export const SECTION_COLLAPSE = 'section-collapse';
export const SECTION_WRAPPER = 'section-wrapper';
export const SINGLE_UPLOAD = 'single-upload';
export const TAB = 'tab';
export const TABS = 'tabs';
export const TRAINING_TASK_DOCUMENT = 'training-task-document';
export const TRAINING_TASK_TIMELIME = 'training-task-timeline';
export const UPLOAD = 'upload';
