import callback from '../../../administrator/utils/callback';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';

export default (organizationalUnitId, next) => _dispatch => {
  return organizationEndpoints
    .getOUAddressesByOuId(organizationalUnitId)
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
