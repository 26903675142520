import React from 'react';
import styled from 'styled-components';

const NoClutter = styled.div`
  &,
  .no-clutter-wrapper {
    margin: 0;
  }

  .content-wrapper {
    max-width: 100%;
  }

  .izenda-embed {
    &,
    .izenda-root {
      height: 100%;
    }

    .svg-loading-animation {
      width: 200px;
      max-width: 100%;
    }

    .izenda-header-placeholder {
      height: 40px;
      background-color: #fff;
    }
  }
`;

const WithoutClutter = WrappedComponent => props => (
  <NoClutter>
    <WrappedComponent {...props} className="no-clutter-wrapper" />
  </NoClutter>
);

export default WithoutClutter;
