import styled from 'styled-components';
import { Row } from 'antd';

const FieldGroupEditor = styled(Row)`
  padding: 2px;

  &.is-dragging {
    opacity: 0.3;
  }

  .field-group--type {
    .ant-tag {
      font-size: 9px;
      margin: 2px 0 0 2px;
    }

    margin-bottom: 0;
  }

  .field-group--header {
    border: 1px solid lightgray;
    border-bottom: none;

    &:hover {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
    }

    .field-group--title {
      padding-bottom: 0;
    }

    .field-group--actions {
      .ant-btn {
        font-size: 12px;
      }
    }
  }

  .field-group--detail {
    border: 1px solid lightgray;
    border-top: none;
  }

  .field-group--header:hover,
  .field-group--header:hover + .field-group--detail {
    border-color: blue;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid lightgray;
    margin: 0;
    padding: 0;
  }
`;

export default FieldGroupEditor;
