import styled from 'styled-components';

const InjuriesBodyEditor = styled.div`
  padding: 5px 5px 25px 5px;

  .uppercase {
    text-transform: uppercase;
  }

  .box-card {
    position: relative;
  }

  .margin-left {
    margin-left: auto;
  }

  .read-only {
    pointer-events: none;
    background-color: #f7f7f7;

    .field-editor__btns {
      display: none;
    }
  }

  .injuries--header {
    border: 1px solid lightgray;
    border-bottom: none;

    &:hover {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
    }
  }

  .injuries--detail {
    border: 1px solid lightgray;
    border-top: none;
  }

  .injuries--header:hover,
  .injuries--header:hover + .injuries--detail {
    border-color: blue;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid lightgray;
    margin: 0;
    padding: 0;
  }
`;

export default InjuriesBodyEditor;
