import React from 'react';

import styled from 'styled-components';
import { Row, Col } from 'antd';
import Panel, { PanelTitle, Divider } from '../common/panel';
import IconButton from '../common/buttons/icon-button';
import { BLACK, GRAY_2 } from '../../config/theme';

const rows = [
  {
    title: 'Letters of Commendation',
    value: 4,
  },
  {
    title: 'Positive Community Acknowledgement',
    value: 15,
  },
  {
    title: 'Supervisor Acknowledgement',
    value: 4,
  },
  {
    title: 'Incident Follow-up Survey',
    value: 'Not Activated',
  },
];

const CustomRow = styled(Row)`
  min-height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #e9e9e9;

  &:last-child {
    border-bottom: 0;
  }

  .title {
    color: ${BLACK};
  }

  .value {
    color: ${GRAY_2};
  }

  .icon {
    text-align: right;
    display: table-cell;

    img {
      vertical-align: middle;
    }
  }
`;

const CommunityEngagementPanel = props => (
  <Panel>
    <PanelTitle>
      <h2>General Information</h2>
    </PanelTitle>
    <Divider />
    {rows.map((r, index) => (
      <CustomRow key={index}>
        <Col className="title" xs={24} sm={10}>
          {r.title}:
        </Col>
        <Col className="value" xs={22} sm={13}>
          {r.value}
        </Col>
        <Col className="icon" xs={2} sm={1}>
          <IconButton icon="arrow-right" />
        </Col>
      </CustomRow>
    ))}
  </Panel>
);
export default CommunityEngagementPanel;
