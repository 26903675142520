import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';

class configurationEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'http://localhost:3004';
    super(
      process.env.REACT_APP_CONFIGURATION_SERVICE_URI || DEV_URI,
      'configuration/v1'
    );
  }

  getCalculatedConfig(agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/userConfigs/calculatedConfig/agencies/:agencyId'
        ),
        method: 'GET',
      },
      { agencyId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getAgencyProfileConfiguration(agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/userConfigs/agencies/:agencyId'),
        method: 'GET',
      },
      { agencyId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  createAgencyProfileConfiguration(agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/userConfigs/agencies/:agencyId'),
        method: 'POST',
      },
      { agencyId },
      {}
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  updateAgencyProfileConfiguration() {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/userConfigs'),
        method: 'PUT',
      },
      {},
      {}
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  deleteAgencyProfileConfiguration(configId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/userConfigs/{userConfigId}'),
        method: 'DELETE',
      },
      { configId },
      {}
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getTenantFeaturesSlim(tenantId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/features/slim'),
        method: 'GET',
      },
      { tenantId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getFeatureStatus(featureName) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/features/feature/:featureName'),
        method: 'GET',
      },
      { featureName },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }
}

export default new configurationEndpoints();
