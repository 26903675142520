import React from 'react';
import { Button, Modal } from 'antd';

export const AddEquipmentButton = ({ isEnabled, onClick }) => {
  if (isEnabled) {
    return (
      <Button type="primary" ghost onClick={onClick}>
        Add Equipment
      </Button>
    );
  }
  return null;
};

const confirmationModal = ({ onDelete }) => {
  const deleteModalMessage =
    'You cannot undo deleting this record. Do you want to continue?';
  Modal.confirm({
    title: 'Remove equipment',
    content: deleteModalMessage,
    okText: 'Yes',
    cancelText: 'Cancel',
    onOk: () => onDelete(),
  });
};

export const ModalFooterButtons = ({
  isEditable,
  isDeletable,
  loading,
  onCancel,
  onSave,
  onDelete,
}) => {
  const classes = isDeletable ? 'pull-left' : 'pull-left bdm-no-display-field';

  return isEditable
    ? [
        <Button
          key="deleteEquipment"
          className={classes}
          type="danger"
          onClick={() => confirmationModal({ onDelete })}
          loading={loading}
        >
          Delete
        </Button>,
        <Button key="Cancel" type="secondary" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="Done"
          type="primary"
          loading={loading}
          onClick={() => onSave()}
        >
          Apply changes
        </Button>,
      ]
    : [
        <Button key="Cancel" type="secondary" onClick={onCancel}>
          Cancel
        </Button>,
        null,
      ];
};
