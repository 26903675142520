import equipmentEndpoints from '../../../../api/equipment/equipmentEndpoints';
import callback from '../../utils/callback';

export default ({ tenantId, next }) => _dispatch => {
  return equipmentEndpoints
    .getEquipmentCategories({
      tenantId,
    })
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
