import styled from 'styled-components';

const SectionWrapperEditor = styled.div`
  padding-bottom: 25px;

  .show-reviewer {
    background-color: hsla(180, 100%, 25%, 0.1);
  }

  .hide-reviewer {
    background-color: hsla(0, 100%, 50%, 0.1);
  }

  .box-card {
    position: relative;
  }

  .margin-left {
    margin-left: auto;
  }
`;

export default SectionWrapperEditor;
