import React, { Component } from 'react';

import fetchCaseLoad from '../../actions/fetch-caseload';
import fetchUserCaseLoad from '../../actions/fetch-user-caseload';
import fetchAgencyReasons from '../../actions/fetch-agency-reasons';
import postCaseLoadToggleTimeout from '../../actions/post-case-load-toggle-timeout';
import getReportHistory from '../../actions/get-report-history';

import { getCurrentUserPermissions } from '../../selectors/session';

import {
  getInternalAffairCaseLoadEntities,
  getInternalAffairCaseLoadPage,
  getInternalAffairCaseLoadPerPage,
  getInternalAffairCaseLoadTotal,
  getInternalAffairCaseLoadReasonsData,
  getInternalAffairCaseLoadPending,
  getInternalAffairCaseLoadIsLoading,
} from '../../selectors/internal-affair-case-load';

import { getAgencyTZ } from '../../selectors/session';

import DashboardPage from '../../components/dashboard';
import InternalAffairCaseLoad from '../../components/internal-affairs/internal-affair-case-load';

/**
 * @param {object} props
 * @param {boolean} props.hasViewOwnCaseLoadPermission true if the user has the 'viewOwnCaseLoad' permission.
 * @return {function} A react function.
 */
class InternalAffairsWrapper extends Component {
  state = {
    selectedCaseLoad: {},
  };

  static getPageConfig() {
    return {
      title: 'Internal Affairs',
    };
  }

  UNSAFE_componentWillMount() {
    this.dispatchFetchOfCaseLoad(1);
    this.dispatchFetchAgencyReasons();
  }

  dispatchPostCaseLoadToggleTimeout = (
    formSubmissionId,
    reason,
    notes,
    onComplete
  ) => {
    const { dispatch } = this.props;
    return dispatch(
      postCaseLoadToggleTimeout(formSubmissionId, reason, notes, onComplete)
    );
  };

  dispatchFetchAgencyReasons = () => {
    const { dispatch } = this.props;
    return dispatch(fetchAgencyReasons());
  };

  dispatchFetchOfCaseLoad = (page, filter) => {
    const { dispatch, hasViewOwnCaseLoadPermission } = this.props;
    return hasViewOwnCaseLoadPermission
      ? dispatch(fetchUserCaseLoad(page, filter))
      : dispatch(fetchCaseLoad(page, filter));
  };

  onChangePage = page => {
    const { filter } = this.state;
    this.dispatchFetchOfCaseLoad(page, filter);

    global.scrollTo(0, 0);
  };

  onFilterChange = filterValue => {
    this.setState({ filter: filterValue }, () =>
      this.dispatchFetchOfCaseLoad(1, filterValue)
    );
  };

  handlePauseResumeModalSubmit = (err, values) => {
    const { page } = this.props;

    if (!err) {
      const { notes, reason, formSubmissionId } = values;
      this.dispatchPostCaseLoadToggleTimeout(
        formSubmissionId,
        reason,
        notes,
        () => {
          this.onChangePage(page);

          this.setState({ selectedCaseLoad: {} });
        }
      );
    }
  };

  /**
   * @param {object} record
   * @return {boolean}
   */
  handleActionPauseClick = record => {
    if (record.isPaused) {
      this.handlePauseResumeModalSubmit(undefined, record);
    } else {
      this.setState({ selectedCaseLoad: record });
    }

    return false;
  };

  handleCancel = () => this.setState({ selectedCaseLoad: {} });

  getReportHistory = (...args) => {
    const { dispatch } = this.props;

    dispatch(getReportHistory(...args));
  };

  render() {
    const {
      hasViewOwnCaseLoadPermission,
      pendingToggle,
      ...props
    } = this.props;
    return (
      <InternalAffairCaseLoad
        {...props}
        confirmLoading={pendingToggle}
        selectedCaseLoad={this.state.selectedCaseLoad}
        onChangePage={this.onChangePage}
        onFilterChange={this.onFilterChange}
        onPauseResumeModalSubmit={this.handlePauseResumeModalSubmit}
        onCancel={this.handleCancel}
        onActionPauseClick={this.handleActionPauseClick}
        getReportHistory={this.getReportHistory}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    agencyReasons: getInternalAffairCaseLoadReasonsData(state),
    hasViewOwnCaseLoadPermission: getCurrentUserPermissions(state).includes(
      'viewOwnCaseLoad'
    ),
    dataSource: getInternalAffairCaseLoadEntities(state),
    perPage: getInternalAffairCaseLoadPerPage(state),
    page: getInternalAffairCaseLoadPage(state),
    agencyTZ: getAgencyTZ(state),
    total: getInternalAffairCaseLoadTotal(state),
    pendingToggle: getInternalAffairCaseLoadPending(state),
    loading: getInternalAffairCaseLoadIsLoading(state),
  };
}

export default DashboardPage(mapStateToProps, null)(InternalAffairsWrapper);
