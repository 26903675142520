export const formatFeet = (value = 1) => {
  const { feet, inches } = formatInchesToFtInch(value);
  return `${feet}'${inches}"`;
};

export const formatInchesToFtInch = (value = 1) => {
  const height = Number(value);
  return {
    feet: Math.trunc(height / 12),
    inches: Math.round(height % 12),
  };
};

export const formatGeneric = (quantity = 1, unit = '') => {
  return `${quantity} ${unit}`;
};

export const isMeasuringUnit = number_type => number_type === 'measuring-unit';

export default (quantity = 1, unit = '') => {
  switch (unit) {
    case 'ft':
      return formatFeet(quantity);
    case 'lb':
    case 'min':
      return formatGeneric(quantity, unit);
    default:
      return quantity;
  }
};
