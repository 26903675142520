import { createReducer } from 'redux-create-reducer';
import {
  GET_USERS_REQUEST,
  GET_TRAINING_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_USERS_RESET_FILTERS,
} from '../actions';

const initialState = {
  loading: false,
  per_page: 10,
  page: 1,
  total: 0,
  filters: null,
  sort: null,
  error: null,
  data: [],
  entries: {},
};

const events = {
  [GET_USERS_REQUEST]: (state, { payload: { page = 0 } }) => {
    return {
      ...state,
      page,
      loading: true,
    };
  },
  [GET_TRAINING_USERS_REQUEST]: (state, { payload: { page = 1 } }) => {
    return {
      ...state,
      page,
      loading: true,
    };
  },
  [GET_USERS_SUCCESS]: (
    state,
    { payload: { count: total, data, filter, page } }
  ) => {
    return {
      ...state,
      total,
      loading: false,
      filter,
      page,
      data: data.map(item => item.id),
      entries: data.reduce(
        (res, item) => ({
          ...res,
          [item.id]: item,
        }),
        {}
      ),
    };
  },
  [GET_USERS_RESET_FILTERS]: state => {
    return {
      ...state,
      filter: '',
      page: 1,
      searchClear: false,
    };
  },
  [GET_USERS_ERROR]: (state, { payload: { error } }) => {
    return {
      ...state,
      error,
      loading: false,
    };
  },
};

export default createReducer(initialState, events);
