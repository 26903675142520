import { camelCase } from 'lodash';

import AgencyUsers from './AgencyUsers';
import { PERMISSIONS } from '../../../utils/admin';
import {
  CREATE_PERMISSIONS,
  UPDATE_PERMISSIONS,
  VIEW_PERMISSIONS,
} from './AgencyUsers.constants';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/users/:userId`,
    name: camelCase(`${name}-user`),
    component: AgencyUsers,
    protectedWith: [
      ...CREATE_PERMISSIONS,
      ...UPDATE_PERMISSIONS,
      ...VIEW_PERMISSIONS,
      PERMISSIONS.manageAgencies,
    ],
    childRoutes: [],
  },
  {
    path: `${path}/users`,
    name: camelCase(`${name}-users`),
    component: AgencyUsers,
    protectedWith: [
      ...CREATE_PERMISSIONS,
      ...UPDATE_PERMISSIONS,
      PERMISSIONS.manageAgencies,
    ],
    childRoutes: [],
  },
];
