import { Component } from 'react';
import { pullAll, get, clone } from 'lodash';
import store from 'APP_ROOT/store';
import updateGivenDataKeys from '../../../actions/update-given-data-keys.js';
import resetRepeaterKeys from 'APP_ROOT/actions/reset-repeater-keys';
import { connect } from 'react-redux';

import {
  getKeysFromSchema,
  isProtectedField,
  getDefaultFieldValueByType,
} from '../../../utils/form';
import validateConditions from '../../../utils/validateConditions';
import propsHasChangedForConditions from 'APP_ROOT/utils/propsHasChangedForConditions';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';
import { getFormMeta } from '../../../selectors/form';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';

import syncFormData from 'APP_ROOT/actions/sync-form-data';
import formActionDispatcher from 'APP_ROOT/utils/formDispatchEmitter';
import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes';
import { SWITCH, CHECKBOX } from 'APP_ROOT/constants/fieldTypes';

import validateBooleanValue from './FormField/validateBooleanValue';

export const protectedFields = [
  'currentUserId',
  'officerId',
  'doneWithAction',
  'interactionWhat',
  'allegationAccusedMemberId',
  'incidentNumber',
  'findingAccusedMemberId',
  'overallFindingAccusedId',
  'overallFindingAccusedMemberId',
  'interactionInjuriesFront',
  'interactionInjuriesBack',
  'taskName',
];

class ConditionalAssert extends Component {
  visible = true;
  updated_prevented = true;
  mounted_at;
  updated_at;
  updated_reason;
  state = { shouldShow: false };

  componentDidMount() {
    this.mounted_at = new Date();
  }

  shouldComponentUpdate(nextProps) {
    const nextShouldShow = this.conditionsAsserted(nextProps);
    const prevShouldShow = this.conditionsAsserted(this.props);
    const prevDisabled = this.props.disabled;
    const nextDisabled = nextProps.disabled;
    const nextNeedRefresh = nextProps.needRefresh;
    const prevNeedRefresh = this.props.needRefresh;

    const enableStatusChanged = nextDisabled !== prevDisabled;
    const refreshStatusChanged = nextNeedRefresh !== prevNeedRefresh;
    const visbilityChange = nextShouldShow !== prevShouldShow;
    const shouldUpdate = propsHasChangedForConditions(nextProps, this.props, [
      'render',
    ]);
    const currentAssignedSections = this.props.__assignedSections;
    const nextAssignedSections = nextProps.__assignedSections;
    const propsChanged = propsHasChanged(
      currentAssignedSections,
      nextAssignedSections
    );
    const shouldUpdateFromProps = this.shouldUpdateFromProps(
      this.props,
      nextProps
    );

    this.updated_prevented = !shouldUpdate;

    if (shouldUpdate) {
      let updated_reason = [];

      if (visbilityChange) {
        updated_reason.push('conditions_assertion');
        validateBooleanValue(this.props);
      }

      updated_reason.push('data_change');
      this.updated_reason = updated_reason.join(',');
    }

    return (
      shouldUpdate ||
      visbilityChange ||
      enableStatusChanged ||
      shouldUpdateFromProps ||
      refreshStatusChanged ||
      propsChanged
    );
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);

    this.updated_at = new Date();
  }

  validateFieldValue = () => {
    const {
      parentKey,
      parentIndex,
      field,
      field_type,
      type,
      data,
      settings: { formName = 'form' } = {},
    } = this.props;
    const key = parentKey ? `${parentKey}[${parentIndex}].${field}` : field;
    const value = get(data, key);
    if (
      value === undefined &&
      type === FIELD &&
      [SWITCH, CHECKBOX].includes(field_type)
    ) {
      formActionDispatcher(
        formName,
        syncFormData({
          [key]: getDefaultFieldValueByType(field_type, false),
        })
      );
    }
  };

  conditionsAsserted(props) {
    const {
      conditions = {},
      data = {},
      wholeData = {},
      parentIndex = 0,
    } = props;

    return validateConditions(conditions, data, wholeData, parentIndex);
  }

  reviewerAsserted(props) {
    const {
      conditions: { rules = [] },
    } = props;
    return rules.some(
      rule => rule.key === 'isReviewer' && rule.expect === true
    );
  }

  shouldUpdateFromProps = (prevProps, nextProps) => {
    const { shouldComponentUpdate } = nextProps;

    if (typeof shouldComponentUpdate !== 'function') {
      return false;
    }

    return shouldComponentUpdate(prevProps, nextProps);
  };
  /**
   * Function used to reset any Report of a Repeater and get field names.
   * @param {Object} repeater - if field is a repeater true/false
   * @param {Object} keys - all keys inside of the complex object
   * @param {Object} protectedFields - if field is protected true/false
   */
  clearComplexObjecs = (repeater, keys, protectedFields) => {
    const { isEditMode } = this.props;
    if (repeater && isEditMode) {
      store.dispatch(resetRepeaterKeys([{ key: repeater }]));
      return;
    }

    return pullAll(keys, [...protectedFields, 'id']);
  };
  /**
   * Function used to reset any Report field with a show/hide conditional .
   * @param {Object} props - All properties of a field
   */
  autoResetFieldValue = props => {
    const { type, properties = [], field = '', parentKey, parentIndex } = props;
    let parent = parentKey;
    let childs = [];
    const { repeater, keys } = getKeysFromSchema(properties);

    switch (type) {
      case 'field':
        if (!isProtectedField(protectedFields, field)) {
          childs = [field];
        }
        break;
      case 'field-group':
      case 'section':
      case 'object':
        childs = this.clearComplexObjecs(repeater, keys, protectedFields);
        break;
      default:
        childs = this.clearComplexObjecs(repeater, keys, protectedFields);
        break;
    }
    const keysToReset = this.resetOwnKeysCalculation(
      childs,
      parent,
      parentIndex
    );
    if (Object.keys(keysToReset).length !== 0) {
      store.dispatch(updateGivenDataKeys(keysToReset));
    }
  };

  /**
   * Returns only the fields that need to be set to `undefined`
   * @returns {Object}
   */
  resetOwnKeysCalculation = (keys = [], parentKey, parentIndex) => {
    const resetKeys = {};
    const {
      form: { selectedForm },
    } = store.getState();
    keys.reduce((_, key) => {
      const data = get(selectedForm.data, [parentKey]);
      const parsedKey = key.includes('.') ? key.split('.')[1] : key;
      if (Array.isArray(data)) {
        const baseParentElement = clone(selectedForm.data[parentKey]);
        let changes = false;
        selectedForm.data[parentKey].map((item, index) => {
          if (index === parentIndex && item[parsedKey] !== undefined) {
            const update = {
              ...item,
              [parsedKey]: undefined,
            };
            baseParentElement[index] = update;
            changes = true;
          }
        });
        if (changes) {
          resetKeys[parentKey] = baseParentElement;
        }
      } else {
        if (selectedForm.data[parsedKey] !== undefined) {
          return (resetKeys[parsedKey] = undefined);
        }
        return;
      }
    }, {});
    return resetKeys;
  };

  render() {
    const {
      conditions = {},
      render,
      autoResetFieldValues = true,
      data: { isReviewer },
    } = this.props;
    const { behavior = 'hide' } = conditions;
    const hasConditionals = Object.keys(conditions).length;
    const shouldShow = this.conditionsAsserted(this.props);
    const forReviewers = this.reviewerAsserted(this.props) || isReviewer;
    const visibilityBehavior = behavior === 'hide';
    const enableBehavior = behavior === 'disable';

    this.visible = shouldShow;

    if (hasConditionals) {
      if (!shouldShow && visibilityBehavior) {
        if (autoResetFieldValues && !forReviewers) {
          this.autoResetFieldValue(this.props);
        }
        return null;
      } else if (!shouldShow && enableBehavior) {
        return render(shouldShow, behavior);
      }
    }

    return render(shouldShow, 'none');
  }
}

const mapStateToProps = state => {
  const { isEditMode } = getFormMeta(state);
  const {
    form: {
      selectedForm: {
        isAdminEdit = false,
        data: { __assignedSections = [] } = {},
      } = {},
    } = {},
  } = state;

  return {
    __assignedSections,
    isEditMode: isEditMode || isAdminEdit,
  };
};

export default connect(mapStateToProps, null)(ConditionalAssert);
