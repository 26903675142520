export const [DONE, ERROR, UPLOADING] = ['done', 'error', 'uploading'];

export const ATTACHMENTS_FIELD = 'review-attachments';

export const halfColumn = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
};

export const formItemLayout = {
  labelCol: { md: 8, sm: 10, xs: 24 },
  wrapperCol: { md: 16, sm: 14, xs: 24 },
};

export const initialState = {
  attachments: {},
  reviewers: [],
  selectedResolution: {},
  selectedReviewer: {},
  hasDefaultReviewer: false,
  hasDefaultResolution: false,
  isLoading: true,
  reviewNotesFetchStarted: false,
  hasLockableEnable: false,
};
