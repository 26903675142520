import React, { useState } from 'react';
import { Icon } from 'antd';

import {
  HelpButtonContainer,
  HelpButton,
  HelpButtonPopOverTitle,
  PopOverContainer,
  HelpButtonTextContainer,
} from './assistant-button.styled';

import SupportForm from '../../containers/salesforce-support';

const AssistantButton = () => {
  const [isHelpButtonVisible, setIsHelpButtonVisible] = useState(false);

  return (
    <>
      {!isHelpButtonVisible && (
        <HelpButton
          style={{ bottom: '12px', right: '12px' }}
          className="help-button"
          onClick={() => setIsHelpButtonVisible(!isHelpButtonVisible)}
        >
          <HelpButtonTextContainer>
            <Icon style={{ padding: '5px' }} type="question-circle" />
            <div>Help</div>
          </HelpButtonTextContainer>
        </HelpButton>
      )}
      {isHelpButtonVisible && (
        <PopOverContainer>
          <HelpButtonPopOverTitle>
            <div />
            <div>Help</div>
            <a onClick={() => setIsHelpButtonVisible(false)}>
              <Icon
                style={{ height: '30px', width: '30px', color: '#fff' }}
                type="minus"
              />
            </a>
          </HelpButtonPopOverTitle>
          <HelpButtonContainer>
            <SupportForm controlPopup={setIsHelpButtonVisible} />
          </HelpButtonContainer>
        </PopOverContainer>
      )}
    </>
  );
};

export default AssistantButton;
