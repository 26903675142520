import React from 'react';

import { BLACK, DIVIDER, GRAY_2 } from '../../config/theme';

import Panel, { PanelTable, PanelTitle } from '../common/panel';
import { Row } from 'antd';

import styled from 'styled-components';

import json from '../../mockup_data/evaluations';

const evaluations = json.evaluations;

const totalRow = {
  skillOrActivity: 'Total',
  score: evaluations.reduce((acc, item) => acc + Number(item.score), 0),
  className: 'total',
};

const averageRow = {
  skillOrActivity: 'Evaluation Average',
  score: (totalRow.score / evaluations.length).toFixed(2),
  className: 'total',
};

const data = [...evaluations, totalRow, averageRow];

const evaluationScoreData = data.map((skillWithScore, index) => ({
  key: index,
  ...skillWithScore,
}));

const columns = [
  {
    key: 'skillOrActivity',
    title: <div className="header">Skills/Activities</div>,
    dataIndex: 'skillOrActivity',
    className: 'skill-or-activity',
    width: '70%',
    render: (text, record, index) => {
      const className = record.className
        ? `table-cell ${record.className}`
        : 'table-cell';
      return <div className={className}>{text}</div>;
    },
  },
  {
    key: 'score',
    title: <div className="header">Score</div>,
    dataIndex: 'score',
    className: 'score',
    width: '30%',
    render: (text, record, index) => {
      const className = `table-cell center-text ${
        record.className ? `${record.className}` : ''
      }`;
      return <div className={className}>{text}</div>;
    },
  },
];

const CustomTable = styled(PanelTable)`
  border-top: 1px solid ${DIVIDER};

  .skill-or-activity {
    color: ${BLACK};
  }

  .score {
    text-align: center;
    color: ${GRAY_2};
  }

  .table-cell {
    min-height: 44px;
    line-height: 44px;

    &.total {
      min-height: 60px;
      line-height: 60px;
      font-size: 14px !important;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
`;

const EvaluationScorePanel = props => (
  <Panel hasTable>
    <PanelTitle>Overall Evaluation Score</PanelTitle>
    <Row style={{ marginLeft: -16, marginRight: -16 }}>
      <CustomTable columns={columns} dataSource={evaluationScoreData} />
    </Row>
  </Panel>
);

export default EvaluationScorePanel;
