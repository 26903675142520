export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_FULLFILLED = 'CREATE_USER_FULLFILLED';
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_FULLFILLED = 'UPDATE_USER_FULLFILLED';
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_FULLFILLED = 'GET_USER_FULLFILLED';
export const GET_USER_REJECTED = 'GET_USER_REJECTED';

export const GET_USER_SECURITY_ROLES_REQUEST =
  'GET_USER_SECURITY_ROLES_REQUEST';
export const GET_USER_SECURITY_ROLES_FULFILLED =
  'GET_USER_SECURITY_ROLES_FULFILLED';
export const GET_USER_SECURITY_ROLES_REJECTED =
  'GET_USER_SECURITY_ROLES_REJECTED';

export const POST_USER_SECURITY_ROLES_REQUEST =
  'POST_USER_SECURITY_ROLES_REQUEST';
export const POST_USER_SECURITY_ROLES_FULFILLED =
  'POST_USER_SECURITY_ROLES_FULFILLED';
export const POST_USER_SECURITY_ROLES_REJECTED =
  'GET_USER_SECURITY_ROLES_REJECTED';

export const POST_USER_SECTIONS_REQUEST = 'POST_USER_SECTIONS_REQUEST';
export const POST_USER_SECTIONS_FULFILLED = 'POST_USER_SECTIONS_FULFILLED';
export const POST_USER_SECTIONS_REJECTED = 'GET_USER_SECTIONS_REJECTED';

export const POST_USER_EMPLOYMENT_HISTORY_REQUEST =
  'POST_USER_EMPLOYMENT_HISTORY_REQUEST';
export const POST_USER_EMPLOYMENT_HISTORY_FULFILLED =
  'POST_USER_EMPLOYMENT_HISTORY_FULFILLED';
export const POST_USER_EMPLOYMENT_HISTORY_REJECTED =
  'POST_USER_EMPLOYMENT_HISTORY_REJECTED';

export const POST_USER_DOCUMENTS_REQUEST = 'POST_USER_DOCUMENTS_REQUEST';
export const POST_USER_DOCUMENTS_FULFILLED = 'POST_USER_DOCUMENTS_FULFILLED';
export const POST_USER_DOCUMENTS_REJECTED = 'POST_USER_DOCUMENTS_REJECTED';

export const POST_USER_CREATE_IN_OU_FULFILLED =
  'POST_USER_CREATE_IN_OU_FULFILLED';
export const POST_USER_CREATE_IN_OU_REJECTED =
  'POST_USER_CREATE_IN_OU_REJECTED';
