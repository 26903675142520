import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { castArray, last, get, parseInt, isNil } from 'lodash';
import ContentLoader from 'react-content-loader';

import { getAgencyTZ } from '../../selectors/session';

import { getParticipant } from '../../utils/form';
import parseDate from '../../utils/parse-date';

import getFormTemplate from '../../actions/get-form-template';

import withModal from '../common/modal/base';
import Panel, { PanelTitle } from '../common/panel/index';
import ReviewList from './styled/review-list';
import ReviewListItem from './styled/review-list-item';
import ObjectField from '../form-viewer/object-types/object-field';
import FormItem from '../form-viewer/styled/form-item';
import { Loader } from './review-panel';
import { REQUEST_MORE_INFO } from '../../constants/submit-actions';
import { emptyObject } from '../../constants/form';

class ReviewListWrapper extends withModal(PureComponent) {
  state = {
    isLoading: true,
    reviewNotesFetchStarted: false,
  };

  async componentDidMount() {
    const { submissionForm } = this.props;

    this.createModal();

    if (!submissionForm.isLoading) {
      await this.getReviewNotesTemplate();
    }
  }

  componentDidReceiveProps() {
    const { submissionForm } = this.props;

    if (!submissionForm.isLoading) {
      this.getReviewNotesTemplate();
    }
  }

  componentWillUnmount() {
    this.hideModal();
    this.deleteModal();
  }

  getReviewNotesTemplate = async () => {
    if (!this.state.reviewNotesFetchStarted) {
      await this.fetchReviewNotes();
    }
  };

  async fetchReviewNotes() {
    const { reviews = [] } = this.props; //HERE IS THE ISSUE!!!
    this.setState({ reviewNotesFetchStarted: true });
    await Promise.all(
      castArray(reviews).map(review =>
        this.fetchReviewNoteTemplate(
          review.templateId,
          review.templateVersionId
        )
      )
    );
    this.setState({ isLoading: false }, this.onNotesFetched);
  }

  fetchReviewNoteTemplate(templateId, templateVersionId) {
    const { dispatch } = this.props;
    return new Promise(resolve =>
      dispatch(getFormTemplate({ templateId, templateVersionId }, resolve))
    );
  }

  onNotesFetched = () => {
    const { onNotesFetched } = this.props;
    onNotesFetched && typeof onNotesFetched === 'function' && onNotesFetched();
  };

  getSettingsWithNoteId = ({ id: noteId }) => ({
    formName: 'reviewNotes',
    noteId,
  });

  render() {
    const {
      title = '',
      onlyLastItem = false,
      activeUser = '',
      activeReviewer = '',
      reviews = [],
      participants = {},
      withBorder = false,
      timezone = null,
      form,
      reviewForm,
    } = this.props;

    const { isLoading } = this.state;

    const formItemLayout = {
      labelCol: { md: 8, sm: 10, xs: 24 },
      wrapperCol: { md: 16, sm: 14, xs: 24 },
    };

    const filteredReviews = onlyLastItem ? last(reviews) : reviews;
    const listReviews = castArray(filteredReviews);
    const lastIndex = listReviews.length ? listReviews.length - 1 : 0;

    const { enums: fromSourceEnums, loading, entries = {} } = reviewForm;
    const isReviewer = true;
    const isReviewList = true;
    const canSubmit = false;

    return (
      <Panel
        transparent
        paddinglessHorizontal
        marginlessVertical
        className="review-list"
      >
        {title && <PanelTitle>{title}</PanelTitle>}
        <ReviewList withBorder={withBorder}>
          {listReviews.map((review, index) => {
            const {
              id,
              content,
              created,
              historyStep = {},
              templateId,
            } = review;

            const {
              actionLabel = '',
              submitter = '',
              target = [],
              action = '',
              toState = '',
              targetStateIsReqMoreInfo,
              fromStateIsReqMoreInfo = false,
            } = historyStep;

            const reviewAction = action.substr(action.indexOf('.') + 1); // Subtraction the action from string

            const isActiveReviewer =
              activeUser === activeReviewer ||
              parseInt(activeUser) === parseInt(activeReviewer);

            const isActiveTarget =
              target.includes(activeUser) ||
              target.includes(parseInt(activeUser));

            const isLastReview = lastIndex === index;

            const isDoneState = toState === 'done';

            const isHighlighted =
              isActiveReviewer &&
              isActiveTarget &&
              (reviewAction.includes(REQUEST_MORE_INFO) ||
                targetStateIsReqMoreInfo ||
                fromStateIsReqMoreInfo) &&
              isLastReview;

            const userSubmitter = getParticipant(submitter, participants) || {};

            const {
              rank: { name: submitterRank = '' } = {},
              fullName: submitterfullName = '',
            } = userSubmitter;

            const userTarget = getParticipant(target, participants) || {};
            const {
              rank: { name: targetRank = '' } = {},
              fullName: targetfullName = '',
            } = userTarget;

            const membersFromNotes = this.fetchMembers(content);

            const targetMembers = [`${targetRank} ${targetfullName}`]
              .concat(membersFromNotes)
              .join(', ');

            const formatedCreatedDateTime = parseDate(created, timezone);

            const templateEntry = get(entries, templateId, {});

            const { formSchema: uiSchema = {} } = templateEntry;

            const {
              form: formSchema,
              enums,
              validations,
              overrides,
            } = uiSchema;

            const formProps = {
              presentation: emptyObject,
              dataEnums: { ...enums, ...fromSourceEnums },
              form,
              overrides,
              settings: this.getSettingsWithNoteId(review),
              validations,
              tab: 0,
              onSubmit: this.onSubmit,
              isReviewer,
              isReviewList,
              canSubmit,
              loading,
              timezone,
            };

            const hasTarget =
              target &&
              Array.isArray(target) &&
              target.filter(_target => !isNil(_target)).length > 0;

            const reviewerText = `${submitterRank} ${submitterfullName}'s Review `;
            const targetText =
              hasTarget && !isDoneState && `to ${targetMembers}`;

            return (
              <ReviewListItem
                isHighlighted={isHighlighted}
                key={`${id}${index}`}
              >
                <div className="review-item-header">
                  {this.drawReviewHeader(
                    reviewerText,
                    targetText,
                    formatedCreatedDateTime
                  )}
                </div>
                <div className="review-item-body">
                  {!isLoading &&
                    this.drawReviewContent(
                      formItemLayout,
                      actionLabel,
                      formProps,
                      formSchema
                    )}
                  {isLoading && this.drawReviewLoader()}
                </div>
              </ReviewListItem>
            );
          })}
        </ReviewList>
      </Panel>
    );
  }

  fetchMembers(content) {
    return get(content, 'drpMembers', [])
      .map(userId => get(content, `__users.${userId}`, null))
      .filter(user => user !== null)
      .map(user =>
        [`${get(user, 'rank.name', '')} `, user.fullName]
          .filter(item => item !== ' ')
          .join(' ')
      );
  }

  drawReviewHeader(reviewerText, targetText, formatedCreatedDateTime) {
    return (
      <Row gutter={32}>
        <Col xs={24} sm={12}>
          <h3>
            <b>
              <span dangerouslySetInnerHTML={{ __html: reviewerText }} />
            </b>
            {targetText && (
              <span dangerouslySetInnerHTML={{ __html: targetText }} />
            )}
          </h3>
        </Col>
        <Col xs={24} sm={12} className="text-right text-xs-left">
          Submitted at {formatedCreatedDateTime}
        </Col>
      </Row>
    );
  }

  drawReviewContent(formItemLayout, actionLabel, formProps, formSchema) {
    return (
      <div>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <FormItem label="Recommendation" colon={false} {...formItemLayout}>
              <div
                className="reviewer-field select-field"
                dangerouslySetInnerHTML={{
                  __html: actionLabel,
                }}
              />
            </FormItem>
          </Col>
        </Row>
        <ObjectField type="object" {...formProps} {...formSchema} />
      </div>
    );
  }

  drawReviewLoader() {
    return (
      <Loader>
        <ContentLoader
          height={80}
          width={500}
          speed={2}
          primaryColor={'#f3f3f3'}
          secondaryColor={'#ecebeb'}
        >
          <rect x="15" y="5" rx="4" ry="4" width="110" height="20" />
          <rect x="170" y="5" rx="4" ry="4" width="225" height="25" />
          <rect x="15" y="45" rx="4" ry="4" width="110" height="20" />
          <rect x="170" y="45" rx="4" ry="4" width="340" height="25" />
        </ContentLoader>
      </Loader>
    );
  }
}

const mapState = state => {
  const { form = {}, reviewForm } = state;

  return {
    submissionForm: form,
    selectedForm: form.selectedForm,
    template: form.templates[form.selectedForm.template],
    reviewForm,
    timezone: getAgencyTZ(state),
  };
};

export default connect(mapState)(ReviewListWrapper);
