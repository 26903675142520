import callback from '../../utils/callback';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';

export default ({ userId }, next) => dispatch => {
  return organizationEndpoints
    .getUserEmploymentHistory(userId)
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
