import get from 'lodash/get';
import pick from 'lodash/pick';
import kebabCase from 'lodash/kebabCase';

export default config =>
  Object.keys(config).reduce(
    (res, key) => ({ ...res, [key]: get(config, [key, 'value'], false) }),
    {}
  );

export const getTemplateType = (template, valueKey = 'type') =>
  kebabCase(get(template, valueKey, 'Use of Force'));

export const getTemplateOptions = (templateType, options = []) =>
  options.reduce((prev, nxt) => {
    const value = nxt.values.find(v => v.scope === templateType);

    if (value) {
      return {
        ...prev,
        [nxt.name]: pick(value, ['value', 'enable', 'groups']),
      };
    } else {
      return { ...prev };
    }
  }, {});
