import React from 'react';
import parsedDate from '../../utils/parse-date';

const AdminEditItem = ({ item, index, timezone }) => {
  const submissionMessage = `${item.actor || ''} Edited This Report`;

  return (
    <div>
      <p className={`timeline-item-date-${index}`}>
        {parsedDate(item.created, timezone)}
      </p>
      <p>
        <b>
          {' '}
          {item.formType} {item.formNumber}{' '}
        </b>
      </p>
      <p
        className={`timeline-item-message-${index}`}
        dangerouslySetInnerHTML={{ __html: submissionMessage }}
      />
    </div>
  );
};

export default AdminEditItem;
