import React from 'react';
import styled from 'styled-components';

import { Row, Col } from 'antd';

import Panel, { PanelTitle, PanelRow, Divider } from '../common/panel';

import GroupedChart from '../common/charts/grouped-chart';

export default ({ results }) => {
  const {
    data: { activityGraph: values },
  } = results;

  const maxValue = values.reduce((accumulated, dataItem) => {
    return dataItem.data.reduce((dataItemAccumulated, dataItemData) => {
      return Object.keys(dataItemData).reduce(
        (dataItemDataAccumulated, dataItemDataItem) => {
          return dataItemData[dataItemDataItem].reduce(
            (accumulatedValue, currentvalue) => {
              return Math.max(currentvalue, accumulatedValue);
            },
            dataItemDataAccumulated
          );
        },
        dataItemAccumulated
      );
    }, accumulated);
  }, 0);

  return (
    <Panel>
      <PanelTitle>
        <h2>Activity Graph</h2>
      </PanelTitle>
      <Divider />
      <StyledRow>
        {values &&
          values.map((item, index) => {
            return (
              <Col key={index} span={6}>
                <GroupedChart items={item} maxValue={maxValue} />
              </Col>
            );
          })}
      </StyledRow>
      <PanelRow style={{ textAlign: 'center' }}>
        <StyledLegend color="#000000">
          <div className="block" />
          <span>Officer</span>
        </StyledLegend>
        <StyledLegend color="#FF7070">
          <div className="block" />
          <span>Watch Average for Patrol, District 2</span>
        </StyledLegend>
        <StyledLegend color="#5E8DFF">
          <div className="block" />
          <span>Department Overall Average</span>
        </StyledLegend>
      </PanelRow>
    </Panel>
  );
};

const StyledRow = styled(Row)`
  max-width: 800px;
  margin: 20px auto 35px;
  text-align: center;
`;

const StyledLegend = styled.div`
  display: inline-block;
  color: ${props => props.color};
  vertical-align: middle;
  margin: 10px 30px;
  font-size: 16px;

  .block {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    background-color: ${props => props.color};
  }
`;
