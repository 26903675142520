import styled from 'styled-components';

const FooterActions = styled.div`
  margin: 45px 0 100px;
  padding: 0 15px;

  .footer-actions-col {
    text-align: right;

    .ant-btn {
      margin-left: 15px;
    }
  }
`;

export default FooterActions;
