import React from 'react';

import PageHeader from 'APP_COMPONENTS/PageHeader';
import getRoute from 'APP_ROOT/utils/get-route';

export default ({ isSidebarIsCollapsed, agencyName, agencyId }) => {
  const title = `${agencyName} - Create New Role`;

  return (
    <PageHeader
      title={title}
      goBackTo={
        getRoute('administratorAgency', { agencyId }) + '/roles-permissions'
      }
      sidebarIsCollapsed={isSidebarIsCollapsed}
    />
  );
};
