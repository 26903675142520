import castArray from 'lodash/castArray';
import get from 'lodash/get';

export default attachments =>
  castArray(attachments).map(attachment =>
    Object.assign({}, attachment, {
      ownerId: get(attachment, 'attachable.submitterId'),
      owner: get(attachment, 'attachable.submitter'),
      name: get(
        attachment,
        'name',
        get(
          attachment,
          'originalFilename',
          `Evidence ${attachment.id} (${attachment.mimeType})`
        )
      ),
    })
  );
