import React from 'react';
import cx from 'classnames';
import get from 'lodash/get';
import styled from 'styled-components';

const Divider = props => (
  <div
    className={cx(
      'divider',
      get(props, 'options.className', ''),
      props.className
    )}
  />
);

export default styled(Divider)`
  &.thick-divider {
    border-top: none;
    margin-top: 10px;
  }
`;
