import React from 'react';
import { Link } from 'react-router-dom';

import {
  StyledTenantLabel,
  StyledSwitch,
  StyledButton,
} from './Reports.styled';
import { PERMISSIONS, hasPermissions } from '../../utils/admin';

import isMobileDevice from '../../utils/isMobileDevice';
import PageHeader from '../../components/PageHeader/PageHeader';
import { Button } from 'antd';

const isMobile = isMobileDevice();
const ReportsHeaderUtilities = ({
  permissions,
  isLoadingTemplates,
  onViewChildTenantReports,
  onNewReportClick,
}) => {
  const canSubmit = hasPermissions(permissions, [
    'submitForm',
    PERMISSIONS.submitUOFReport,
    PERMISSIONS.submitVPReport,
    PERMISSIONS.submitDORReport,
    PERMISSIONS.submitIACReport,
    PERMISSIONS.submitIAIReport,
    PERMISSIONS.submitReport,
  ]);

  const canSeeIzenda = hasPermissions(permissions, [
    PERMISSIONS.accessAnalyticsReports,
  ]);

  const canSeeIzendaReportDesigner = hasPermissions(permissions, [
    'accessAnalyticsDesigner',
    PERMISSIONS.accessAnalyticsDesign,
  ]);

  //Here we check if the user has the permission to see child tenant reports.
  const canSeeChildTenantReports = hasPermissions(permissions, [
    PERMISSIONS.childTenantView,
  ]);

  const actions = [];

  if (canSeeChildTenantReports) {
    actions.push(
      <>
        <StyledTenantLabel>
          {isMobile ? 'Child tenant' : 'View child tenant reports'}
        </StyledTenantLabel>
        <StyledSwitch
          size="small"
          onChange={() => onViewChildTenantReports()}
        />
      </>
    );
  }

  if (canSeeIzenda) {
    actions.push(
      <Link to="/canned-reports">
        <StyledButton type="primary" icon="line-chart" size="default" ghost>
          Analytics Viewer
        </StyledButton>
      </Link>
    );
  }

  if (canSeeIzendaReportDesigner) {
    actions.push(
      <Link to="/report-designer">
        <StyledButton type="primary" icon="line-chart" size="default" ghost>
          Analytics Designer
        </StyledButton>
      </Link>
    );
  }

  if (canSubmit) {
    actions.push(
      <Button
        type="primary"
        size="default"
        icon="plus"
        disabled={isLoadingTemplates}
        loading={isLoadingTemplates}
        onClick={() => onNewReportClick()}
      >
        {isMobile ? 'New Report' : 'Create New Report'}
      </Button>
    );
  }

  return <PageHeader title="Reports" actions={actions} />;
};

export default ReportsHeaderUtilities;
