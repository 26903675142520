import { ADD_SOURCE } from './';

export default ({
  enumRef = '',
  sourceKey = '',
  enumItem = {},
  sourceItem = {},
}) => dispatch => {
  dispatch({
    type: ADD_SOURCE,
    payload: { enumRef, enumItem, sourceItem, sourceKey },
  });
};
