import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Icon, notification, Row, Modal } from 'antd';
import { get, isEmpty, isEqual, map } from 'lodash';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import { BENCHMARK_DATE_FORMAT, momentWithTZ } from 'APP_ROOT/utils/parse-date';

import Section from '../../../../components/form-viewer/styled/section';
import SectionAttribute from './SectionAttribute';
import {
  ATTRIBUTE_TYPE_DATE,
  ATTRIBUTE_TYPE_TREE,
  SaveSectionWarning,
} from './UserProfile.constants';

import {
  StyledCollapse,
  StyledPanel,
} from './UserCustomSections/UserProfileSections.styled';
import UserProfileSectionForm from './UserCustomSections/UserProfileSectionForm';
import SectionAddButton from './SectionAddButton';
import sectionModalCancel from './sectionModalCancel';
import moment from 'moment';
import StyledModal from './UserEmploymentModal.styled';
import setAllowedAttributes from './userEmploymentAllowedFields';

import {
  EMPLOYMENT_ACTION_HIRE,
  EMPLOYMENT_ACTION_SEPARATION,
  EMPLOYMENT_PREFIX,
} from './userEmployment.constants';
import CustomSectionTable from '../../../../components/custom-sections/tables/CustomSectionTable';
import deleteEHRecord from '../actions/delete-user-employment-history';

const ATTRIBUTE_ID = 'id';
const NOTIFICATION_WARNING = {
  message: 'Finish current changes',
  description: 'You have to "Add" or "Cancel" current record before.',
};

const confirm = Modal.confirm;

class UserEmploymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editForm: null,
      isNew: false,
      formConfiguration: [],
      selectedRecordType: '',
      shouldDeleteButtonBeEnable: false,
    };
  }

  forceReloadPage = () => {
    // Temporary solution
    if (window) window.location?.reload();
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      propsHasChanged(nextProps, this.props) ||
      propsHasChanged(nextState, this.state)
    );
  }

  updateRankOptions = () => {
    const { formConfiguration } = this.state;

    const employmentRankIndex = formConfiguration.findIndex(
      f => f.attributeName === 'rank'
    );

    if (employmentRankIndex >= 0) {
      const employmentRank = formConfiguration[employmentRankIndex];
      const optionList = this.props.employmentRankOptions
        .filter(o => o.isChoosable)
        .map(o => o.name);

      const newConfiguration = [...formConfiguration];
      newConfiguration[employmentRankIndex] = {
        ...employmentRank,
        validValues: optionList,
      };

      this.setState({ formConfiguration: newConfiguration });
    }
  };

  componentDidUpdate(previousProps, previousState) {
    const {
      editSection,
      sectionId,
      setEditSectionTable,
      attributes,
    } = this.props;
    const { editForm } = this.state;
    this.setState({ formConfiguration: attributes });
    if (
      !isEqual(
        this.props.employmentRankOptions,
        previousProps.employmentRankOptions
      )
    ) {
      this.updateRankOptions();
    }

    if (!isEmpty(editSection)) {
      const { sectionId: section_id, key } = editSection;
      if (section_id === sectionId) {
        if (isEmpty(editForm) || !editForm.isNew) {
          this.setEditForm(this.getRowIndex(key), { key }, true);
          setEditSectionTable();
        } else {
          const props = {
            updateSectionField: this.props.updateSectionField,
            removeSectionFieldValue: this.props.removeSectionFieldValue,
            attributes: this.state.formConfiguration,
            profileForm: this.props.profileForm,
            prefix: EMPLOYMENT_PREFIX,
            sectionId: sectionId,
            attributeId: ATTRIBUTE_ID,
          };

          // delete new row here
          notification.warning(NOTIFICATION_WARNING);
          sectionModalCancel(true, { key }, props);
        }
      } else if (section_id === 'employment') {
        this.setState({ isNew: true, newSection: section_id });
      }
    }
  }

  enableEditForm = (record, isNew) => {
    const { editForm } = this.state;
    if (!editForm || record.key !== editForm.record.key) {
      if (editForm && editForm.isNew) {
        // warning message
        notification.warning(NOTIFICATION_WARNING);
      } else {
        this.setState({
          editForm: {
            rowIndex: this.getRowIndex(record.key),
            record,
            isNew,
          },
          selectedRecordType: record.employmentAction,
          shouldDeleteButtonBeEnable: record.updated ? true : false,
        });
      }
    }
  };

  // called on row click to enable edit form
  setEditForm = (rowIndex, record, isNew = false) => {
    this.enableEditForm(record, isNew);
  };

  // called on radio button click, and enables edit form
  onSelectRow = record => {
    this.enableEditForm(record, false);
  };

  getRowIndex = key => {
    const { profileForm, sectionId } = this.props;
    const keys = get(
      profileForm,
      `values.${EMPLOYMENT_PREFIX}.${sectionId}.${ATTRIBUTE_ID}`,
      []
    );
    return keys.findIndex(k => k === key);
  };

  onCancelEdit = () => {
    const { editForm, formConfiguration } = this.state;
    const props = {
      updateSectionField: this.props.updateSectionField,
      removeSectionFieldValue: this.props.removeSectionFieldValue,
      attributes: formConfiguration,
      profileForm: this.props.profileForm,
      prefix: EMPLOYMENT_PREFIX,
      sectionId: this.props.sectionId,
      attributeId: ATTRIBUTE_ID,
    };

    sectionModalCancel(editForm.isNew, editForm.record, props);
    this.setState({ editForm: null });
  };

  onOkEdit = () => {
    this.setState({ editForm: null });
  };

  onModalOk = () => {
    const { onOk, profileForm, sectionId } = this.props;
    const { employment: rawRecords, [sectionId]: currentRecords = {} } = get(
      profileForm,
      `values.${EMPLOYMENT_PREFIX}`,
      {}
    );

    const isOUValid = this.checkOURules(rawRecords, sectionId);
    const areDatesValid = this.checkDatesRules(currentRecords);

    if (isOUValid && areDatesValid) {
      this.setState({ editForm: null, isNew: false });
      onOk && onOk();
      return true;
    }
    return false;
  };

  checkOURules(rawRecords, sectionId) {
    const message = 'Organizational Unit Error';
    const organizationalUnitId = this.findOUById(rawRecords, sectionId);
    if (!organizationalUnitId) {
      const description = 'The organizational unit may not be empty.';
      notification.error({ message, description });
      return false;
    }
    return true;
  }

  checkDatesRules({ employmentAction = [], effectiveDate = [] }) {
    const appointmentDate = this.findDateByAction(
      EMPLOYMENT_ACTION_HIRE,
      employmentAction,
      effectiveDate
    );
    const separationDate = this.findDateByAction(
      EMPLOYMENT_ACTION_SEPARATION,
      employmentAction,
      effectiveDate
    );
    const message = 'Date Error';
    if (
      this.isSeparationDateBeforeAppointmentDate(
        appointmentDate,
        separationDate
      )
    ) {
      const description =
        'Separation record cannot have a date earlier than the Appointment date.';
      notification.error({ message, description });
      return false;
    }
    const areDatesBetweenAppointmentAndSeparations = effectiveDate.every(
      (date, i) => {
        if (!this.isHireOrSeparation(employmentAction, employmentAction[i])) {
          if (this.isDateBeforeAppointmentDate(appointmentDate, date)) {
            const description =
              'No record may have an earlier date than the Appointment date.';
            notification.error({ message, description });
            return false;
          }
          if (this.isDateAfterSeparationDate(separationDate, date)) {
            const description =
              'No record may have a later date than the Separation date.';
            notification.error({ message, description });
            return false;
          }
        }
        return true;
      }
    );
    return areDatesBetweenAppointmentAndSeparations;
  }

  isHireOrSeparation(action) {
    return [EMPLOYMENT_ACTION_HIRE, EMPLOYMENT_ACTION_SEPARATION].includes(
      action
    );
  }

  isSeparationDateBeforeAppointmentDate(appointmentDate, separationDate) {
    return (
      separationDate &&
      appointmentDate &&
      moment(separationDate, BENCHMARK_DATE_FORMAT).isBefore(
        moment(appointmentDate, BENCHMARK_DATE_FORMAT)
      )
    );
  }

  isDateBeforeAppointmentDate(appointmentDate, date) {
    return moment(date, BENCHMARK_DATE_FORMAT).isBefore(
      moment(appointmentDate, BENCHMARK_DATE_FORMAT)
    );
  }

  isDateAfterSeparationDate(separationDate, date) {
    return (
      separationDate &&
      moment(date, BENCHMARK_DATE_FORMAT).isAfter(
        moment(separationDate, BENCHMARK_DATE_FORMAT)
      )
    );
  }

  findDateByAction(action, employmentAction, effectiveDate) {
    const index = employmentAction.findIndex(
      userAction => userAction === action
    );
    return index === -1 ? undefined : effectiveDate[index];
  }

  findOUById(rawRecords, sectionId) {
    const index = rawRecords.id.findIndex(id => id === sectionId);
    return rawRecords.organizationalUnitId[index];
  }

  onModalCancel = () => {
    const { onCancel } = this.props;
    this.setState({ editForm: null, isNew: false, selectedRecordType: '' });
    onCancel && onCancel();
  };

  deleteEmploymentHistory = async () => {
    const {
      sectionId: historyId,
      profileForm: { values: { integrationId: userId } } = {},
    } = this.props;
    const recordId = this.state.editForm?.record?.key;

    if (!recordId || !historyId || !userId) return;

    const filters = {
      softDelete: true,
    };

    const { error } = await deleteEHRecord(
      userId,
      historyId,
      recordId,
      filters
    );

    if (error) this.onModalCancel();
    else {
      this.onModalOk();
    }

    this.forceReloadPage();
  };

  getConfirmationDialogContent = () => {
    const { selectedRecordType: recordType } = this.state;

    if (!recordType) return <></>;

    const content = `You are about to delete a ${this.state.selectedRecordType} record; you cannot undo this action. Do you want to proceed?`;
    return <p>{content}</p>;
  };

  openDeleteConfirmation = () => {
    confirm({
      title: 'Confirm Action',
      okText: 'Ok',
      cancelText: 'Cancel',
      content: this.getConfirmationDialogContent(),
      onOk: this.deleteEmploymentHistory,
    });
  };

  showDeleteButton = () => {
    const { selectedRecordType, shouldDeleteButtonBeEnable } = this.state;
    const NON_DELETABLE = ['Hire', 'Separation'];
    const displayDelete =
      selectedRecordType &&
      shouldDeleteButtonBeEnable &&
      NON_DELETABLE.indexOf(selectedRecordType) == -1;
    if (displayDelete) {
      return (
        <Button
          key="delete"
          type="danger"
          style={{ marginRight: 'auto' }}
          onClick={this.openDeleteConfirmation}
        >
          Delete
        </Button>
      );
    }

    return <></>;
  };

  render = () => {
    const {
      title,
      visible,
      sectionId,
      profileForm,
      rowIndex,
      readOnly,
      addSectionFieldValue,
      updateSectionField,
      removeSectionFieldValue,
      setEditSectionTable,
      treeDropdownOptions = [],
      plainValidValues = [],
      disabledValues = [],
      timezone,
    } = this.props;

    const { editForm, isNew } = this.state;

    let rowSelection = { type: 'radio', onSelect: this.onSelectRow };

    if (editForm) {
      rowSelection = { ...rowSelection, selectedRowKeys: editForm.record.key };
    }

    // to get current records in table
    const currentRecords = get(
      profileForm,
      `values.${EMPLOYMENT_PREFIX}[${sectionId}]`,
      {}
    );

    const { employmentAction = [] } = currentRecords;

    //Checks if this user already has records linked to the current Organizational Unit
    //If only a single record is present and it has the Hire value it must be the empty record inserted by default
    const hasPreviousRecords = employmentAction.includes(
      EMPLOYMENT_ACTION_HIRE
    );

    const employmentActionIndex = this.state.formConfiguration.findIndex(
      f => f.attributeName === 'employmentAction'
    );
    const newSectionConfig = [...this.state.formConfiguration];

    if (employmentActionIndex >= 0) {
      const employmentActionConfig = this.state.formConfiguration[
        employmentActionIndex
      ];

      newSectionConfig[employmentActionIndex] = {
        ...employmentActionConfig,
        settings: {
          ...employmentActionConfig.settings,
          defaultValue: !hasPreviousRecords ? EMPLOYMENT_ACTION_HIRE : null,
        },
        disabled: hasPreviousRecords && employmentAction.length === 1,
        validValues: employmentActionConfig.validValues.map(value => ({
          label: value,
          value: value,
          disabled:
            value === EMPLOYMENT_ACTION_HIRE && hasPreviousRecords
              ? true
              : false,
        })),
      };
    }

    //Checks if a separation record already exists. If so, disables the Add Record button
    const hasSeparationRecord = employmentAction.includes(
      EMPLOYMENT_ACTION_SEPARATION
    );

    // Checks the state for record's action. If it's either hire or separation and not a new record, it must become
    // readonly as per the business rule stated in EM-12.
    const restrictedRecord = this.isRestrictedRecord(hasSeparationRecord);

    const allowedAttributesInRestrictedRecords = {};

    if (restrictedRecord && !readOnly) {
      setAllowedAttributes(
        allowedAttributesInRestrictedRecords,
        this.state.formConfiguration
      );
    }

    const finalFormAttributes = newSectionConfig
      .filter(
        value =>
          value.attributeName !== 'updatedAt' &&
          value.attributeName !== 'updatedBy'
      )
      .map(attribute => {
        if (attribute.type === 'date') {
          attribute.settings.defaultValue = momentWithTZ(
            new Date(),
            timezone,
            BENCHMARK_DATE_FORMAT,
            false
          );
        } else if (
          attribute.type == 'string' &&
          attribute.validValues &&
          attribute.attributeName !== 'employmentAction'
        ) {
          attribute.settings.defaultValue = null;
          attribute.settings.showSearch = true;
        }
        return attribute;
      });
    const finalIndex = finalFormAttributes.length;
    finalFormAttributes.unshift({
      attributeName: 'updated',
      type: 'string',
      title: 'Updated',
      settings: {
        tableColumn: finalIndex + 1,
        defaultValue: null,
      },
      editable: false,
      validValues: null,
    });
    const tableAttributes = finalFormAttributes.filter(
      value => value.attributeName != 'comment'
    );

    return (
      <StyledModal
        title={title}
        centered
        visible={visible}
        onOk={this.onModalOk}
        onCancel={this.onModalCancel}
        className="user-profile-modal"
        footer={
          readOnly
            ? [
                <Button key="Done" type="primary" onClick={this.onModalOk}>
                  Done
                </Button>,
              ]
            : [
                this.showDeleteButton(),
                <Button key="cancel" onClick={this.onModalCancel}>
                  Cancel
                </Button>,
                <Button key="Save" type="primary" onClick={this.onModalOk}>
                  Apply changes
                </Button>,
              ]
        }
      >
        <Section>
          <Row>
            <Col key={`col-organization`} xs={24} sm={12} md={12} lg={12}>
              <SectionAttribute
                key="key-organization"
                name={`organizationalUnitHistoryData.employment.organizationalUnitId[${rowIndex}]`}
                title="Organization"
                type={ATTRIBUTE_TYPE_TREE}
                validValues={treeDropdownOptions}
                plainValidValues={plainValidValues}
                disabledValues={disabledValues}
                profileForm={profileForm}
                isArray={false}
                readOnly={!isNew || readOnly} // plainText
                data-test="employment-organization"
                showSearch={true}
              />
            </Col>
          </Row>
          <Row>
            <Col key={`col-appointmentDate`} xs={24} sm={12} md={12} lg={12}>
              <SectionAttribute
                key="key-appointmentDate"
                name={`organizationalUnitHistoryData.employment.appointmentDate[${rowIndex}]`}
                title="Appointment Date"
                type={ATTRIBUTE_TYPE_DATE}
                validValues={null}
                profileForm={profileForm}
                isArray={false}
                readOnly={true}
                data-test="employment-appointmentDate"
              />
            </Col>
            <Col key={`col-separationDate`} xs={24} sm={12} md={12} lg={12}>
              <SectionAttribute
                key="key-separationDate"
                name={`organizationalUnitHistoryData.employment.separationDate[${rowIndex}]`}
                title="Separation Date"
                type={ATTRIBUTE_TYPE_DATE}
                validValues={null}
                profileForm={profileForm}
                isArray={false}
                readOnly={true}
                data-test="employment-separationDate"
              />
            </Col>
          </Row>

          <Row>
            <StyledCollapse
              bordered={false}
              defaultActiveKey={['history', 'details']}
              expandIconPosition="right"
            >
              <StyledPanel
                header="Employment History"
                key="history"
                className="section-panel"
                extra={
                  <SectionAddButton
                    sectionId={sectionId}
                    attributeId={ATTRIBUTE_ID}
                    prefix={EMPLOYMENT_PREFIX}
                    attributes={finalFormAttributes}
                    addSectionFieldValue={addSectionFieldValue}
                    setEditSectionTable={setEditSectionTable}
                    disabled={hasSeparationRecord || readOnly}
                    mainForm={profileForm}
                  ></SectionAddButton>
                }
              >
                <hr></hr>
                <CustomSectionTable
                  className="employment-table"
                  key="organization-history"
                  modalTitle="Employment History"
                  sectionId={sectionId}
                  prefix={EMPLOYMENT_PREFIX}
                  attributeId={ATTRIBUTE_ID}
                  attributes={tableAttributes}
                  mainForm={profileForm}
                  addSectionFieldValue={addSectionFieldValue}
                  updateSectionField={updateSectionField}
                  removeSectionFieldValue={removeSectionFieldValue}
                  setEditSectionTable={setEditSectionTable}
                  modalFor="employment-history"
                  hasAction={false}
                  onClickRow={this.setEditForm}
                  rowSelection={rowSelection}
                  maxRows={3}
                  maxColumns={50}
                  ellipsis={false}
                  isSectionReadOnly={readOnly}
                />
              </StyledPanel>

              <StyledPanel
                header="Details"
                key="details"
                className="section-panel"
              >
                {editForm && (
                  <UserProfileSectionForm
                    key={`employment-history-${editForm.record.key}`}
                    sectionId={sectionId}
                    prefix={EMPLOYMENT_PREFIX}
                    profileForm={profileForm}
                    isModal={true}
                    rowIndex={editForm.rowIndex}
                    attributes={finalFormAttributes}
                    allowedAttributes={allowedAttributesInRestrictedRecords}
                    isSectionReadOnly={restrictedRecord || readOnly}
                  />
                )}
                {editForm && editForm.isNew && (
                  <Row type="flex" justify="end">
                    <Col>
                      <Button
                        key="cancel-edit"
                        onClick={this.onCancelEdit}
                        className="button-margin-right"
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button key="Save" type="primary" onClick={this.onOkEdit}>
                        Add
                      </Button>
                    </Col>
                  </Row>
                )}
              </StyledPanel>
            </StyledCollapse>
          </Row>
          <Row type="flex" justify="center">
            <Col>
              <Icon type="exclamation-circle" />
              <SaveSectionWarning></SaveSectionWarning>
            </Col>
          </Row>
        </Section>
      </StyledModal>
    );
  };

  /*
   New: the record was created but not added to the records. (use the add or apply button in the model to loss the state)
   Fresh: the record was created and added but not stored in the database. (use any of the save buttons in the user profile to loss the state)
   Restricted: the business rules indicates that hire and separation are protected actions therefore those records with
    either of those actions are restricted.
  */
  isRestrictedRecord(hasSeparationRecord) {
    const { editForm, isNew } = this.state;
    const { storedInDatabase } = this.props;
    if (editForm) {
      const entryId = get(editForm, 'record.key');
      const storedIds = [].concat.apply(
        [],
        map(storedInDatabase, 'detail.id', [])
      );
      const isFresh = !storedIds.includes(entryId);
      const isRestrictedAction = hasSeparationRecord
        ? true
        : [EMPLOYMENT_ACTION_HIRE, EMPLOYMENT_ACTION_SEPARATION].includes(
            editForm.record.employmentAction
          );
      return !isNew && !isFresh && isRestrictedAction;
    }
    return false;
  }
}

const mapStateToProps = state => {
  return {
    currentUserId: state?.session?.currentUser?.userIntegrationId || 0,
  };
};

export default connect(mapStateToProps)(UserEmploymentModal);
