import { isPlainObject } from 'lodash';

const getParentRules = (props, key = '') => {
  const { validations = {} } = props;
  const { rules: formRules = {}, types: ruleTypes = {} } = validations;

  return (formRules[key] || []).reduce((result, rule) => {
    if (rule.item) {
      return (Array.isArray(rule.item) ? rule.item : [rule.item]).reduce(
        (_, ruleItem) => {
          if (ruleItem.type === 'object' && isPlainObject(ruleItem.fields)) {
            return {
              ...result,
              ...ruleItem.fields,
            };
          }
          if (ruleTypes[ruleItem.type]) {
            if (ruleTypes[ruleItem.type].fields) {
              return {
                ...result,
                ...ruleTypes[ruleItem.type].fields,
              };
            }

            return {
              ...result,
              ...ruleItem,
            };
          }

          return {
            ...result,
            ...ruleItem,
          };
        },
        result
      );
    }

    return {
      ...result,
      ...rule,
    };
  }, {});
};

export default getParentRules;
