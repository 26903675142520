import styled from 'styled-components';
import { Tabs } from 'antd';

const TabList = styled(Tabs)`
  .ant-tabs-bar {
    margin: 0;
  }

  .ant-tabs-extra-content {
    margin-top: -2px;
  }

  .ant-tabs-nav-container {
    clear: inherit;
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      border-left: 1px solid #dadada;
      margin-right: 0;

      &.ant-tabs-tab-active {
        background-color: #e8edf0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid #cecece;

        & + .ant-tabs-tab {
          border-left-color: transparent;
        }
      }
    }

    &:first-child,
    .ant-tabs-ink-bar + .ant-tabs-tab:not(.ant-tabs-tab-active) {
      border-left-color: transparent;
    }
  }

  .ant-tabs-ink-bar {
    opacity: 0;
  }
`;

export default TabList;
