import React, { Component } from 'react';
import { connect } from 'react-redux';

class AppContainer extends Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children, ...props } = this.props;

    if (props.hydrated) {
      return <div>{children}</div>;
    }

    return null;
  }
}

const mapState = ({ app: { hydrated = false } }) => ({
  hydrated,
});

export default connect(mapState)(AppContainer);
