/**
 * holds utility functions commonly used by actions.
 */
import get from 'lodash/get';

import storage from '../utils/storage';
import { getSupportedAgency } from '../api/reports';
/**
 * @param {object} responseError A "fetch" error object.
 * @return {string} The status of the response in the error, undefined if not found.
 */
export function getErrorResponseStatus(responseError) {
  return get(responseError, ['response', 'status']);
}

/**
 * @param {number} perPage
 * @param {number} page
 * #return {object} An object with { limit, skip }
 */
export function getReqPaginationParams(perPage, page) {
  return {
    limit: perPage,
    skip: (page - 1) * perPage,
  };
}

export const getDataFromToken = () => {
  const token = storage.get('token');
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const supportedAgency = async agencyId => {
  try {
    const supportedAgenyResponse = await getSupportedAgency(agencyId);
    return supportedAgenyResponse;
  } catch (e) {
    return { content: false };
  }
};
