import React, { Component } from 'react';
import Table from 'antd/lib/table';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import savePreviousPath from 'APP_ROOT/utils/savePreviousPath';

// Actions
import getOuUsers from '../../actions/organizations/get-ou-users';

// Containers
import DashboardPage from '../../components/dashboard';
import PeopleTable from '../../containers/people/people-table';
import parseDate, { BENCHMARK_DATE_TIMEZ_FORMAT } from '../../utils/parse-date';
import { getAgencyTZ } from '../../selectors/session';
import { hasPermissions, PERMISSIONS } from '../../utils/admin';
import { connect } from 'react-redux';
import withoutClutter from '../../components/without-clutter';

const { Column } = Table;

class PeopleOu extends Component {
  onSearch = (props, state) => {
    const { dispatch } = props;
    const { page, size = 50, search: filter, ssn = '', agencyId } = state;
    const ssnValue = ssn.replace(/[-|_]/g, '');
    dispatch(getOuUsers(page, size, filter, ssnValue, agencyId));
  };

  render() {
    const {
      route = () => {},
      timezone,
      ouUsers,
      users,
      currentUser,
      ...rest
    } = this.props;

    const agencyId = this.props.agencyId ?? currentUser.agencyId;

    const canCreateUsers = hasPermissions(currentUser.permissions, [
      PERMISSIONS.createAnyUser,
      PERMISSIONS.createAgencyUser,
    ]);

    const canSearchBySSN = hasPermissions(currentUser.permissions, [
      PERMISSIONS.viewUnMaskedSSN,
      PERMISSIONS.updateSSN,
    ]);

    return (
      <PeopleTable
        onSearch={this.onSearch}
        users={ouUsers}
        route={route}
        timezone={timezone}
        agencyId={agencyId}
        canCreateUsers={canCreateUsers}
        canSearchBySSN={canSearchBySSN}
        {...rest}
      >
        <Column
          title="Name"
          key="name"
          render={(text, record) => (
            <Link
              to={savePreviousPath(
                route('administratorAgencyUser', {
                  agencyId: record.agencyId,
                  userId: record.id,
                })
              )}
            >
              {record.lastName}, {record.firstName}
            </Link>
          )}
        />
        <Column
          title="Title"
          dataIndex="title"
          key="title"
          render={(text, record) => (
            <span>
              {record.title ? record.title : get(record, 'rank.name', '')}
            </span>
          )}
        />
        <Column
          title="Organizational Unit Name(s)"
          dataIndex="ouNames"
          key="ouNames"
          render={(text, record) =>
            record.ouNames
              ? record.ouNames.map((ouName, index) => (
                  <>
                    {index ? ', ' : ''}
                    <Link
                      key={record.ouIds[index]}
                      to={route('organizationProfile', {
                        organizationalUnitId: record.ouIds[index],
                      })}
                    >
                      {ouName}
                    </Link>
                  </>
                ))
              : 'None'
          }
        />
        <Column title="Employee Id" dataIndex="employeeId" key="employeeId" />
        <Column
          title="Benchmark Id"
          dataIndex="benchmarkId"
          key="benchmarkId"
        />
        <Column
          title="Last Updated"
          dataIndex="updatedAt"
          key="updatedAt"
          render={(text, record) =>
            parseDate(record.updatedAt, timezone, BENCHMARK_DATE_TIMEZ_FORMAT)
          }
        />
      </PeopleTable>
    );
  }
}

const mapState = state => ({
  ouUsers: get(state, ['organization', 'ouUsers', 'ids'], []).map(id => ({
    key: id,
    ...get(state, ['organization', 'ouUsers', 'entries', id], {}),
  })),
  total: get(state, ['organization', 'ouUsers', 'total']),
  per_page: get(state, ['organization', 'ouUsers', 'lastPerPage']),
  page: get(state, ['organization', 'ouUsers', 'lastPage']),
  loading: get(state, ['organization', 'ouUsers', 'loading']),
  timezone: getAgencyTZ(state),
});

export const UnwrappedPeopleOu = connect(mapState)(PeopleOu);
export default withoutClutter(DashboardPage(mapState, null)(PeopleOu));
