import { getEnvironmentVariable } from 'APP_ROOT/appVersion';

const BUSINESS_VIEWS_FLAG = 'REACT_APP_FEATURE_FLAG_BUSINESS_VIEWS_ENABLED';
export default props => {
  const { isAgencyNewWorkFlowActive } = props;
  const FEATURE_FLAG_BUSINESS_VIEWS_ENABLED = getEnvironmentVariable(
    BUSINESS_VIEWS_FLAG,
    false
  );

  return (
    FEATURE_FLAG_BUSINESS_VIEWS_ENABLED || isAgencyNewWorkFlowActive || false
  );
};
