import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';

// Actions
import fetchDORFORM from 'APP_ROOT/actions/fetch-dor-form';

// Containers
import DashboardPage from 'APP_COMPONENTS/dashboard';
import withoutClutter from 'APP_COMPONENTS/without-clutter';

// Components
import PageHeader from 'APP_COMPONENTS/PageHeader';

// Utils
import { GRAY_4, BLACK } from '../../../config/theme';

class FieldTrainingDetails extends Component {
  UNSAFE_componentWillMount() {
    const {
      match: {
        params: { id },
      },
      dispatch,
    } = this.props;
    dispatch(fetchDORFORM(id, false));
  }

  render() {
    const { formData = {} } = this.props;
    const {
      userData = {},
      numberOfDaysInTraining,
      tasksInfo = {},
      assessmentStartDate,
      trainingProgramEndDate,
    } = formData;
    const { firstName, lastName } = userData;
    const { completed = 0, of = 0 } = tasksInfo;
    const momentifiedStartDate = assessmentStartDate
      ? moment(assessmentStartDate)
          .utc()
          .format('M/D/Y')
      : '';
    const momentifiedCompletedDate = !isEmpty(trainingProgramEndDate)
      ? moment(trainingProgramEndDate)
          .utc()
          .format('M/D/Y')
      : '';
    return (
      <div>
        <PageHeader
          title={`${firstName} ${lastName}`}
          showBackButton={true}
          goBackTo="/field-training"
        >
          <StyledHeaderContent>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <p>
                  Training Program Start Date:{' '}
                  <span>{momentifiedStartDate}</span>
                </p>
              </Col>
              <Col span={12} className="text-right">
                <p>
                  Training Program Completed Date:{' '}
                  <span>{momentifiedCompletedDate}</span>
                </p>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <p>
                  Number of Days in Training:{' '}
                  <span>{numberOfDaysInTraining}</span>
                </p>
              </Col>
              <Col span={12} className="text-right">
                <p>
                  Training Tasks Progress:{' '}
                  <span>{`${completed} out of ${of}`}</span>
                </p>
              </Col>
            </Row>
          </StyledHeaderContent>
        </PageHeader>
      </div>
    );
  }
}

const StyledHeaderContent = styled.div`
  width: 100%;
  padding: 10px 10px 0;

  p {
    font-size: 14px;
    color: ${GRAY_4};
    margin-bottom: 10px;

    span {
      display: inline-block;
      color: ${BLACK};
      margin: 0 10px;
    }
  }
`;

const mapState = state => {
  const {
    form: {
      selectedForm: { data },
    },
  } = state;
  return {
    formData: data,
  };
};

export default withoutClutter(
  DashboardPage(mapState, null)(FieldTrainingDetails)
);
