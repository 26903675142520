import React from 'react';

import DashboardPage from 'APP_COMPONENTS/dashboard';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import getRoute from 'APP_ROOT/utils/get-route';
import AdministratorWrapper from '../Administrator.styled';
import withOwnershipGuard from '../components/agency-ownership-guard';
import AuditLogsPage from '../../../containers/audit-logs/audit-logs-page';
import { AuditLogWrapper } from './AgencyAuditLogs.styled';
import PageHeader from 'APP_COMPONENTS/PageHeader';

const AgencyAuditLogs = props => {
  const { match, session, history, route, location } = props;
  const { params } = match;
  const { agencyId } = params;
  return (
    <AdministratorWrapper>
      <PageHeader
        title="SSN Audit Logs"
        goBackTo={getRoute('administratorAgency', { agencyId })}
      ></PageHeader>
      <AuditLogWrapper>
        <AuditLogsPage
          showTitle={false}
          session={session}
          history={history}
          route={route}
          location={location}
          agencyId={agencyId}
        ></AuditLogsPage>
      </AuditLogWrapper>
    </AdministratorWrapper>
  );
};

export default withoutClutter(
  DashboardPage()(
    withOwnershipGuard(AgencyAuditLogs, 'administratorAgencyAuditLogs')
  )
);
