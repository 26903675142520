import _get from 'lodash/get';
import { DELETE_REPORT_REQUEST } from './';
import deleteReportError from './delete-report-error';
import deleteReportSuccess from './delete-report-success';
import { deleteReport as deleteReportRequest } from '../api/reports';

/**
 * @param {number} agencyId
 * @param {number} reportId
 * @param {function} next A callback function, called one of the last dispatch is called.
 * @return {Promise} A Thunk function.
 */
export default function deleteReport(agencyId, reportId, next = () => {}) {
  return async dispatch => {
    dispatch({
      type: DELETE_REPORT_REQUEST,
      payload: { reportId, agencyId },
    });
    try {
      const response = await deleteReportRequest(agencyId, reportId);
      dispatch(deleteReportSuccess(reportId, response));
      next(response);
    } catch (e) {
      dispatch(deleteReportError(_get(e, ['response', 'status'])));
      next(e);
    }
  };
}
