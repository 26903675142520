import styled from 'styled-components';

import { Row, Col } from 'antd';

export const StyledRow = styled(Row)`
  margin-top: 16px;
  gap: 10px;

  @media (max-width: 992px) {
    margin-top: 8px;
  }
`;

export const StyledRowAdvance = styled(Row)`
  margin-top: 0;

  @media (max-width: 480px) {
    margin-top: 5px;
  }
`;

export const StyledFilterCol = styled(Col)`
  margin-bottom: 10px;
  text-align: right;

  @media (max-width: 992px) {
    flex: 0 1 20%;
    margin-bottom: 0;

    &.filter-search {
      flex: 0 1 44%;
    }

    &.filter-advanced {
      flex: 0 1 36%;
    }
  }

  @media (max-width: 480px) {
    flex: 0 1 80%;
    margin-bottom: 0;

    &.filter-search {
      flex: 0 1 60%;
    }

    &.filter-advanced {
      flex: 0 1 36%;
    }
  }
`;
