const ACCENT = '#0E4C87';
const BASE = '#61B5EB';
const FONT_FAMILY = '"AvenirNext", sans-serif';
const GRAY_1 = '#9EB3C2';
const GRAY_2 = '#595959';
const GRAY_3 = '#e9e9e9';
const GRAY_4 = '#afafaf';
const GRAY_5 = '#929292';
const DIVIDER = GRAY_3;
const INACTIVE = 'rgba(78, 78, 78, .8)';
const SUCCESS = '#2ECC71';
const ERROR = '#E74C3C';
const PURPLE = '#E1C8F0';
const ORANGE = '#FB8D63';
const GREEN = '#93DBA9';
const YELLOW = '#FAD74A';
const SILVER = '#D4DCE2';
const PINK = '#F17E94';
const ANCHORS = '#60C0EC';
const BLUE = '#3772FF';
const DARK_GRAY = '#001529';
const WHEEL_BACKGROUND_COLOR = '#C8D8FF';
const WHEEL_ACTIVE_COLOR = '#3772FF';
const WHEEL_INACTIVE_COLOR = '#767A84';
const BLACK = '#000';
const SIDEBAR_BG = '#041528';
const SIDEBAR_INACTIVE = '#FFFFFF';
const SIDEBAR_DIVIDER = '#13151D';
const SIDEBAR_DIVIDER_2 = '#1E222E';
const SIDEBAR_ICON = '#6ED960';
const SIDEBAR_HOVER = '#FFFFFF';
const SIDEBAR_HOVER_BG = '#393939';
const SIDEBAR_FOOTER_BG = DARK_GRAY;
const SIDEBAR_QUICKLINK_COLOR = '#9B9EA5';
const SIDEBAR_DISCLAIMER_COLOR = SIDEBAR_INACTIVE;
const SIDEBAR_ACTIVE = '#FFFFFF';
const SIDEBAR_ACTIVE_BG = 'rgba(12, 71, 213, 0.25)';
const SIDEBAR_ACTIVE_STROKE = '#E4E0DF';
const AVATAR_BG = '#0040DD';
const AVATAR_BORDER = '#0040DD';
const AVATAR_COLOR = '#FFF';
const BG = '#E8EDF1';

module.exports = {
  '@font-family': FONT_FAMILY,
  'primary-color': "#0040DD",
  'btn-primary-color': "#FFF",
  'btn-default-bg': "#FFF",
  'btn-default-color': "#0E4C87",
  'btn-default-border': "#0E4C87",
  ACCENT,
  BASE,
  DIVIDER,
  FONT_FAMILY,
  GRAY_1,
  GRAY_2,
  GRAY_3,
  GRAY_4,
  GRAY_5,
  INACTIVE,
  SUCCESS,
  ERROR,
  PURPLE,
  ORANGE,
  GREEN,
  YELLOW,
  SILVER,
  ANCHORS,
  PINK,
  WHEEL_BACKGROUND_COLOR,
  WHEEL_ACTIVE_COLOR,
  WHEEL_INACTIVE_COLOR,
  BLACK,
  BLUE,
  DARK_GRAY,
  SIDEBAR_BG,
  SIDEBAR_INACTIVE,
  SIDEBAR_DIVIDER,
  SIDEBAR_DIVIDER_2,
  SIDEBAR_ICON,
  SIDEBAR_HOVER,
  SIDEBAR_HOVER_BG,
  SIDEBAR_FOOTER_BG,
  AVATAR_BG,
  AVATAR_BORDER,
  AVATAR_COLOR,
  SIDEBAR_QUICKLINK_COLOR,
  SIDEBAR_DISCLAIMER_COLOR,
  SIDEBAR_ACTIVE,
  SIDEBAR_ACTIVE_BG,
  SIDEBAR_ACTIVE_STROKE,
  BG,
};
