import FormComponent from '../../models/FormComponent';
import getColumnOptionsByColumnNumber from '../../utils/getColumnOptionsByColumnNumber';
import getValidationsDefault from './getValidationsDefault';
import { UPLOAD, FIELD } from 'APP_ROOT/constants/layoutComponentTypes';
import { TEXT, NUMBER } from 'APP_ROOT/constants/fieldTypes';
import generateFieldKey from '../../utils/generateFieldKey';
import componentsManager from '../componentsManager';
import transformConditions from './transformConditions';

// keys has to be id and notes, since they are
// already hardcoded in render engine
const createKeyId = type =>
  type === UPLOAD
    ? 'id'
    : generateFieldKey({
        fields: componentsManager.fields,
        prefix: `${type} id`,
      });

const createKeyNotes = type =>
  type === UPLOAD
    ? 'notes'
    : generateFieldKey({
        fields: componentsManager.fields,
        prefix: `${type} notes`,
      });

const createNotesConditions = (type, keyId) =>
  type === UPLOAD
    ? undefined
    : {
        every: true,
        behavior: 'hide',
        rules: [
          {
            key: keyId,
            toBe: 'neq',
            expect: '',
          },
        ],
      };

const getComponentUploadChildren = type => {
  const { isNote } = componentsManager.header;
  const columnOptions = getColumnOptionsByColumnNumber(2);
  let fieldOptions;
  let fieldValidations;
  let children;
  let conditions;

  fieldOptions = {
    showTitle: false,
  };
  const keyId = createKeyId(type);
  const keyNotes = createKeyNotes(type);

  conditions = createNotesConditions(type, keyId);

  children = [
    new FormComponent(FIELD, {
      ...columnOptions,
      field_type: NUMBER,
      key: keyId,
    }),
    new FormComponent(FIELD, {
      ...columnOptions,
      field_type: TEXT,
      key: keyNotes,
      conditions,
    }),
  ];
  fieldValidations = {};
  children.forEach(child => {
    fieldValidations[child.key] = [
      {
        ...getValidationsDefault(child),
        ...transformConditions(
          child.key === keyNotes ? conditions : undefined,
          {
            fieldKey: child.key,
            required: ['id', 'notes', keyNotes].includes(child.key),
            encrypt: false,
          },
          isNote
        ),
        message: ['id', keyId].includes(child.key)
          ? 'Evidence Id is required'
          : 'Note is required',
      },
    ];
  });
  return [children, fieldOptions, fieldValidations, conditions];
};

export default getComponentUploadChildren;
