import React from 'react';

const StatusLabel = ({
  isPaused,
  statusKey = '',
  isAdministravelyUnfounded,
  transactionLabel,
  lastReviewDate,
  stateLabel,
  isIntake,
  className = 'status-badge',
}) => {
  const isCompleted =
    statusKey === 'completed' &&
    !isPaused &&
    isAdministravelyUnfounded &&
    !!transactionLabel;
  const hasReviews = !isAdministravelyUnfounded && !!lastReviewDate;
  const isIntakePaused = isPaused && isIntake;

  const withBadge = content => <p className={className}>{content}</p>;

  switch (true) {
    case isIntakePaused:
      return withBadge(<span>Investigation Paused</span>);
    case !!stateLabel:
      return withBadge(stateLabel);
    case isCompleted:
      return withBadge(transactionLabel);
    case hasReviews:
      return withBadge(
        <span>
          {statusKey === 'completed' ? 'Closed' : 'Last reviewed'} at{' '}
          {lastReviewDate}
        </span>
      );
    default:
      return null;
  }
};

export default StatusLabel;
