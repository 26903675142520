import React, { Fragment, useEffect, useState } from 'react';
import { get } from 'lodash';
import { Row, Col, Button, Icon } from 'antd';

import DashboardPage from 'APP_COMPONENTS/dashboard';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import PageHeader from 'APP_COMPONENTS/PageHeader';

import getRoute from 'APP_ROOT/utils/get-route';

import withOwnershipGuard from '../../components/agency-ownership-guard';
import AdministratorWrapper from '../../Administrator.styled';

import scanQRCode from './scanQRCode';
import { LiveEventCheckInScanWrapper } from './AgencyLiveEventCheckInScan.styled';

const LiveEventCheckInScan = ({ sessionId, name }) => {
  const [loading, setLoading] = useState(false);
  const [readyToScan, setReadyToScan] = useState(true);
  const [scannedValue, setScannedValue] = useState('');
  const [registrationResult, setRegistrationResult] = useState(false);

  useEffect(() => {
    if (readyToScan) {
      scanQRCode({
        sessionId,
        setReadyToScan,
        setScannedValue,
        setLoading,
        setRegistrationResult,
      });
    }
  });

  const onNextScan = event => {
    setReadyToScan(true);
  };

  const registrationResultClassName = [];
  if (!loading) {
    registrationResultClassName.push(registrationResult ? 'success' : 'error');
  }

  const registrationLabel = registrationResult
    ? 'Registration Confirmed'
    : 'No Registration Found';

  return (
    <Fragment>
      <Row type="flex" justify="center">
        <Col>
          <h2>{name}</h2>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col>
          {readyToScan && <div id="reader" className="scanner"></div>}
          {!readyToScan && (
            <div
              className={`scanner result ${registrationResultClassName.join(
                ' '
              )}`}
            >
              <Icon type="qrcode" />
            </div>
          )}
        </Col>
      </Row>
      {!readyToScan && (
        <Fragment>
          <Row type="flex" justify="center">
            <Col>ID: {scannedValue}</Col>
          </Row>
          <Row type="flex" justify="center">
            <Col>{registrationLabel}</Col>
          </Row>
          <Row type="flex" justify="center">
            <Col>
              <Button
                onClick={onNextScan}
                className={registrationResultClassName.join(' ')}
                loading={loading}
              >
                Next Scan
              </Button>
            </Col>
          </Row>
        </Fragment>
      )}
      <Row type="flex" justify="center">
        <Col>
          {readyToScan && <span className="ready-to-scan">Ready to scan</span>}
        </Col>
      </Row>
    </Fragment>
  );
};

const AgencyLiveEventCheckInScan = ({ agencyId, dispatch, ...props }) => {
  const params = new URLSearchParams(props.location.search);
  const name = params.get('name');
  const sessionId = params.get('sessionId');
  return (
    <AdministratorWrapper>
      <PageHeader
        title="Registration Search"
        goBackTo={getRoute('administratorAgencyLiveEventCheckIn', { agencyId })}
      />
      <LiveEventCheckInScanWrapper>
        <LiveEventCheckInScan sessionId={sessionId} name={name} />
      </LiveEventCheckInScanWrapper>
    </AdministratorWrapper>
  );
};

const mapStateToProps = (state, props) => ({
  agencyId: get(props, 'match.params.agencyId'),
});

export default withoutClutter(
  DashboardPage(
    mapStateToProps,
    null,
    {}
  )(
    withOwnershipGuard(
      AgencyLiveEventCheckInScan,
      'administratorAgencyEventCheckInScan'
    )
  )
);
