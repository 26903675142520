import styled from 'styled-components';
import { xs } from '../../../config/breakpoints';

export default styled.div`
  margin-top: 24px;

  button[class^='ant-btn'] {
    margin-right: 16px;

    img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  button[class^='ant-btn']:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: ${xs}) {
    button[class^='ant-btn'] {
      width: 100%;
      display: block;
      margin: 16px 0 0 0;
    }

    button[class^='ant-btn']:first-child {
      margin-top: 0;
    }
  }
`;
