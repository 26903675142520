import { isEmpty } from 'lodash';
import { SUBMITTER_ID, ACTION } from '../../constants/conditions';

const checkRequired = (rule, key) => {
  const {
    conditions,
    mustExist,
    required,
    optional,
    keepInData,
    ...rest
  } = rule;
  let newRule;

  // optional must be false and keepInData undefined/empty
  let fixedRequired = optional || !isEmpty(keepInData);

  if (isEmpty(conditions)) {
    fixedRequired = fixedRequired || !!mustExist;
    newRule = {
      ...rest,
      required: true,
      optional: false,
    };
  } else {
    fixedRequired = true;
    newRule = {
      ...rest,
      conditions,
      mustExist: key,
    };
  }
  return [newRule, fixedRequired];
};

const checkMustExist = (rule, key) => {
  const {
    conditions,
    mustExist,
    required,
    optional,
    keepInData,
    ...rest
  } = rule;
  let newRule;

  // optional must be false and keepInData undefined/empty
  let fixedRequired = optional || !isEmpty(keepInData);
  if (isEmpty(conditions)) {
    fixedRequired = true;
    newRule = {
      ...rest,
      required: true,
      optional: false,
    };
  } else {
    fixedRequired = fixedRequired || mustExist !== key;
    newRule = {
      ...rest,
      conditions,
      mustExist: key,
    };
  }
  return [newRule, fixedRequired];
};

const verifyRequired = (rule, fieldKey, isNote, parentKey) => {
  const KEEP_IN_DATA = {
    key: isNote ? ACTION : SUBMITTER_ID,
    expect: '',
    toBe: 'neq',
  };
  const {
    conditions,
    required,
    mustExist,
    optional,
    keepInData,
    ...rest
  } = rule;
  const key = parentKey ? `${parentKey}.item.${fieldKey}` : fieldKey;

  if (required) {
    return checkRequired(rule, key);
  } else if (mustExist) {
    return checkMustExist(rule, key);
  }
  const fixedRequired = isEmpty(keepInData);
  const newRule = {
    ...rest,
    required: false,
    optional: true,
    keepInData: KEEP_IN_DATA,
  };

  return [newRule, fixedRequired];
};

export default verifyRequired;
