import { isEmpty } from 'lodash';
import FormHeaderOptions from './getFormHeaderOptions';

export default class {
  constructor() {
    this.name = 'formHeader';
    this.label = '';
    this.component = FormHeaderOptions;
    this.defaultValue = { name: '', description: '', abbreviation: '' };
  }

  isValid = values => {
    const content = values[this.name];

    return !isEmpty(content.name) && !isEmpty(content.abbreviation);
  };

  onChange = (value, values) => {
    return {
      ...values,
      [this.name]: value,
    };
  };

  onSave = values => {
    const content = values[this.name];

    return {
      ...values,
      ...content,
      [this.name]: undefined,
    };
  };

  getInitialValue = values => {
    const {
      name: _name,
      description: _desc,
      abbreviation: _abbr,
    } = this.defaultValue;
    const { name = _name, description = _desc, abbreviation = _abbr } = values;

    return {
      name,
      description,
      abbreviation,
    };
  };
}
