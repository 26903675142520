const savePreviousPath = (pathname, state = {}) => {
  const { location } = window;
  return {
    pathname,
    state: {
      ...state,
      previousPath: location.pathname,
      search: location.search,
    },
  };
};

export default savePreviousPath;
