import styled from 'styled-components';

import { ACCENT } from '../../../../config/theme';

const PickableZone = styled.div`
  position: relative;
  min-height: 60px;

  &.can-drop {
    border: 2px dashed #187dde;
    background-color: rgba(239, 244, 255, 0.9);
    border-radius: 2px;

    &.is-over {
      border: 2px dashed #2ecc71;
      background-color: rgba(246, 255, 237, 0.9);
    }
  }

  &.cannot-drop {
    color: #ccc;
    border: 1px solid #ccc;
    border-radius: 2px;
  }

  > .pickable-zone--action {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 2;
    box-shadow: inset 0 0 0 2px ${ACCENT};
    outline: none;
    border: none;
    background: rgba(100%, 100%, 100%, 0.8);
    width: 100%;
    border-radius: 5px;

    &:hover,
    &:focus {
      background: rgba(182, 210, 249, 0.8);
      color: #3672ff;
    }

    &::after {
      content: 'Click to add here';
    }
  }
`;

export default PickableZone;
