import React, { PureComponent } from 'react';

const activeColor = '#FF725C';
const inactiveColor = '#F2F2F2';

class BodySide extends PureComponent {
  onHover = () => {
    const { isReviewer = false } = this.props;

    if (this.path && !isReviewer) {
      this.path.setAttribute('fill', activeColor);
    }
  };

  onOut = () => {
    const { selected } = this.props;
    if (this.path && !selected) {
      this.path.setAttribute('fill', inactiveColor);
    }
  };

  render() {
    const { path, onClick, selected } = this.props;
    const { attrs, name } = path;
    const { d } = attrs;

    return (
      <path
        d={d}
        onClick={() => onClick(name, selected)}
        onMouseOver={this.onHover}
        onMouseOut={this.onOut}
        ref={element => {
          this.path = element;
        }}
        fill={selected ? activeColor : inactiveColor}
      />
    );
  }
}

export default BodySide;
