import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import isPlainObject from 'lodash/isPlainObject';
import isEmpty from 'lodash/isEmpty';
import MaskedInput from 'react-text-mask';
import cx from 'classnames';
import { Input, Tooltip, Icon } from 'antd';

import ReviewerField from '../reviewer-field';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import encryptedField from '../encryptedField';
import { EMAIL } from 'APP_ROOT/modules/FormBuilder/constants/fieldMasked';
import { REG_EXP_PATTERNS_STRING } from 'APP_ROOT/modules/FormBuilder/constants/fieldPatterns';

const MASK_TYPES = {
  EMAIL: 'email',
  SIMPLE: 'simple',
};

/**
 * Transforms the following array:
 *    [ '(', { regExp: '\d' } ]
 * To:
 *    [ '(', /\d+/ ]
 * @param {object[]} pattern
 * @return {object[]}
 */
function transformMaskPatternToMaskedPattern(pattern) {
  if (Array.isArray(pattern)) {
    return pattern.map(item => {
      if (isPlainObject(item)) {
        return new RegExp(item.regExp);
      }
      return item;
    });
  }
  return null;
}

/**
 * @param {string} maskType
 * @param {object[]} pattern
 * @return {object[]}
 */
function getMask(maskType, pattern) {
  switch (maskType) {
    case MASK_TYPES.SIMPLE:
      return transformMaskPatternToMaskedPattern(pattern);
    default:
      return null;
  }
}

/**
 * @param {string} mask_type
 * @param {object[]} pattern
 * @return {object}
 */
function generateMaskedInputProps(mask_type, pattern) {
  return {
    mask: getMask(mask_type, pattern),
    placeholderChar: '\u2000',
  };
}

/**
 * MaskedInputField
 *
 * This field is used to draw propertypes of type "field" with a field_type
 * value equal to "masked".
 *
 * This property makes use of the following options:
 *
 * - "mask_type": Dictates which kind of mask should be used by the client.
 *   Accepts the following values:
 *
 *   - "simple": The mask must be declared in the value of the key "pattern".
 *   - "email": Will render a dynamic mask that accepts an email pattern.
 *
 * - "pattern": An array, in which each index declare how each character should
 * render. In order to declare a character that accepts a fixed input, it's
 * neccessary to declare an object that must have a key "regExp" being its value
 * a valid regular expression.
 *
 * Example:
 *
 * ```
 * {
 *  "key": "humanPhone",
 *  "type": "field",
 *  "field_type": "masked",
 *  "title": "Phone",
 *  "options": {
 *    "mask_type": "simple",
 *    "pattern": [
 *      "(",
 *      { "regExp": "[1-9]" },
 *      { "regExp": "\\d" },
 *      { "regExp": "\\d" },
 *      ")",
 *      " ",
 *      { "regExp": "\\d" },
 *      { "regExp": "\\d" },
 *      { "regExp": "\\d" },
 *      "-",
 *      { "regExp": "\\d" },
 *      { "regExp": "\\d" },
 *      { "regExp": "\\d" },
 *      { "regExp": "\\d" }
 *    ]
 *  }
 * }
 * ```
 */
class InputMasked extends Component {
  static propTypes = {
    isReviewer: PropTypes.bool,
  };

  static defaultProps = {
    isReviewer: false,
  };

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  render() {
    const {
      isReviewer,
      value = '',
      options: { mask_type, pattern } = {},
      enums,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      calculatedValue,
      dependsOnChange,
      isOwnChange,
      field_type,
      ...props
    } = this.props;
    const maskedInputProps = generateMaskedInputProps(mask_type, pattern);
    let overrideReviewer = isReviewer;
    let overrideEditRights = false;

    if (this.props.isContributeReport) {
      if (this.props.contributorAssignmentCanEdit) {
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        overrideReviewer = true;
      } else {
        <ReviewerField></ReviewerField>;
      }
    }

    if (overrideReviewer && !overrideEditRights) {
      return <ReviewerField value={calculatedValue || value} />;
    }
    return (
      <MaskedInput
        className={cx('ant-input', {
          'ant-input-disabled': props.disabled,
        })}
        {...maskedInputProps}
        {...props}
        value={value}
      />
    );
  }
}

export default encryptedField(InputMasked);

const defaultPhonePattern = [
  '(',
  { regExp: '[1-9]' },
  { regExp: '\\d' },
  { regExp: '\\d' },
  ')',
  ' ',
  { regExp: '\\d' },
  { regExp: '\\d' },
  { regExp: '\\d' },
  '-',
  { regExp: '\\d' },
  { regExp: '\\d' },
  { regExp: '\\d' },
  { regExp: '\\d' },
];

export class PhoneNumberField extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  render() {
    const {
      value = '',
      mask_type = 'simple',
      pattern = defaultPhonePattern,
      ...props
    } = this.props;
    const maskedInputProps = generateMaskedInputProps(
      mask_type,
      pattern || defaultPhonePattern
    );
    return (
      <MaskedInput
        className="ant-input"
        {...maskedInputProps}
        {...props}
        value={value}
      />
    );
  }
}

export const EmailInput = () => {
  const [validEmail, setValidEmail] = useState({
    isValidEmail: false,
    isEmailValueEmpty: true,
  });
  const onChangeEmail = e => {
    const {
      target: { value },
    } = e;
    const emailValidation = REG_EXP_PATTERNS_STRING[EMAIL];
    const isValidEmail = value.match(emailValidation);
    const emailStringToArray = value.split('');
    setValidEmail({
      ...validEmail,
      isValidEmail: !isEmpty(isValidEmail),
      isEmailValueEmpty: isEmpty(emailStringToArray),
    });
  };
  const getEmailStatus = () => {
    if (validEmail.isEmailValueEmpty) return <Icon />;

    if (validEmail.isValidEmail)
      return (
        <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
      );

    return (
      <Tooltip title="Email is invalid" defaultVisible={true}>
        <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" />
      </Tooltip>
    );
  };

  return <Input onChange={onChangeEmail} suffix={getEmailStatus()} />;
};
