import styled from 'styled-components';
import { Row } from 'antd';

const FromTemplateWrapper = styled(Row)`
  i {
    font-size: 24px;
  }

  span {
    vertical-align: unset !important;
  }
`;

export default FromTemplateWrapper;
