import styled from 'styled-components';
import { GRAY_3 } from '../../config/theme';

const CaseFileListSection = styled.div.attrs({
  className: 'case-file-list-section',
})`
  margin: 15px;

  .ant-table table {
    background-color: #fff;
  }

  .ant-table-thead > tr > th {
    background: ${GRAY_3};
    color: #001529;
    font-weight: 600;
  }
`;

const CaseFileStatusSection = styled.div.attrs({
  className: 'case-file-status-section',
})`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
  margin: 15px;
`;

export { CaseFileListSection, CaseFileStatusSection };
