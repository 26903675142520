import React from 'react';
import { Form } from 'antd';

import { FILTER_LAYOUT_OPTIONS } from './utils/constants';

const { Item } = Form;

class AdvanceFilter extends React.PureComponent {
  render() {
    const {
      formType = {},
      template = {},
      onChangeFilter,
      clearFilterColumn,
      setFilters,
      filterValues,
      ...props
    } = this.props;

    const { filters = {} } = formType;
    return filters.map((filter, index) => {
      const { label = '', component: Component, filterType } = filter;
      return (
        <Item key={index} label={label} {...FILTER_LAYOUT_OPTIONS}>
          <Component
            label={label}
            template={template}
            onChange={onChangeFilter}
            clearFilter={clearFilterColumn}
            setFilters={setFilters}
            filterType={filterType}
            filterValues={filterValues}
            {...props}
          />
        </Item>
      );
    });
  }
}

export default AdvanceFilter;
