import React from 'react';
import { Route } from 'react-router';

import Officers, {
  OfficerProfile,
  OfficerCommunityEngagement,
  OfficerPerformanceEvaluation,
  OfficerUseOfForce,
  InternalAffairs,
  OfficerTraining,
  OfficerActivity,
  OfficerTrainingTranscript,
} from '../../containers/officers';
import Protected from '../../containers/protected';
import { PERMISSIONS } from '../../utils/admin';

const officerRoutes = [
  <Route
    path="/people/:id/transcripts"
    component={Protected(OfficerTrainingTranscript, {
      permissions: [PERMISSIONS.accessTraining],
      modules: ['training'],
    })}
    name="officerTranscripts"
    key="officerTranscripts"
  />,
  <Route
    path="/people/:id"
    name="officerCategories"
    key="officerCategories"
    render={props => (
      <Officers>
        <Route
          path={`${props.match.url}/profile`}
          component={Protected(OfficerProfile, [
            PERMISSIONS.viewAllUserProfiles,
            PERMISSIONS.viewOwnOfficerProfile,
          ])}
          name="officerProfile"
        />
        <Route
          path={`${props.match.url}/use-of-force`}
          component={Protected(OfficerUseOfForce, [
            PERMISSIONS.viewAllUserProfiles,
            PERMISSIONS.viewOwnOfficerProfile,
          ])}
          name="officerUseOfForce"
        />
        <Route
          path={`${props.match.url}/internal-affairs`}
          component={Protected(InternalAffairs, [
            PERMISSIONS.viewAllUserProfiles,
            PERMISSIONS.viewOwnOfficerProfile,
          ])}
          name="officerInternalAffairs"
        />
        <Route
          path={`${props.match.url}/activity`}
          component={Protected(OfficerActivity, [
            PERMISSIONS.viewAllUserProfiles,
            PERMISSIONS.viewOwnOfficerProfile,
          ])}
          name="officerActivity"
        />
        <Route
          path={`${props.match.url}/training`}
          component={Protected(OfficerTraining, {
            permissions: [
              PERMISSIONS.accessTraining,
              PERMISSIONS.accessAssignTrainingPage,
            ],
            modules: ['training'],
          })}
          name="officerTraining"
        />
        <Route
          path={`${props.match.url}/community-engagement`}
          component={Protected(OfficerCommunityEngagement, [
            PERMISSIONS.viewAllUserProfiles,
            PERMISSIONS.viewOwnOfficerProfile,
          ])}
          name="officerCommunityEngagement"
        />
        <Route
          path={`${props.match.url}/performance-evaluation`}
          component={Protected(OfficerPerformanceEvaluation, [
            PERMISSIONS.viewAllUserProfiles,
            PERMISSIONS.viewOwnOfficerProfile,
          ])}
          name="officerPerformanceEvaluation"
        />
      </Officers>
    )}
  />,
];

export default officerRoutes;
