import getContextPath from './getContextPath';

const getFieldDateKey = props => {
  const { dataKey = '' } = props;

  const contextPath = getContextPath(props);
  return `${contextPath}${dataKey}`;
};

export default getFieldDateKey;
