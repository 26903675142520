import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Row,
  Col,
  Input,
  Button,
  Select,
  Alert,
  Cascader,
  InputNumber,
} from 'antd';
import { isEmpty, has, get } from 'lodash';

import {
  SELECT,
  MULTISELECT,
  SWITCH,
  CHECKBOX,
  STRING,
  NUMBER,
} from 'APP_ROOT/constants/fieldTypes';

import RowWrapper from '../OptionsModalBody/OptionsModalBody.styled';
import componentsManager from '../../services/componentsManager';
import {
  JOIN_TYPE_ALL,
  JOIN_TYPE_SOME,
  OPERATOR_EQUAL,
  OPERATOR_NOT_EQUAL,
  OPERATOR_GREATER_THAN,
  OPERATOR_LESS_THAN,
  OPERATOR_GREATHER_EQUAL,
  OPERATOR_LESS_EQUAL,
  OPERATOR_IN,
  OPERATOR_NOT_IN,
} from '../../constants/conditionalOperators';
import { isBoolean } from 'util';
import getAllFieldsFlat from '../../utils/getAllFieldsFlat';

import {
  repeaterKeyDivider,
  repeaterItemDivider,
} from '../../../../utils/constants';
import { getPropagationActions } from '../../../../api/agencies';
import showToastMessage from '../../utils/showToastMessage';
import getCascaderOptionsFromFields from '../../utils/getCascaderOptionsFromFields';

const { Option, OptGroup } = Select;

const OPERATOR_LABEL = {
  [OPERATOR_EQUAL]: '=',
  [OPERATOR_NOT_EQUAL]: '!=',
  [OPERATOR_GREATER_THAN]: '>',
  [OPERATOR_LESS_THAN]: '<',
  [OPERATOR_GREATHER_EQUAL]: '>=',
  [OPERATOR_LESS_EQUAL]: '<=',
  [OPERATOR_IN]: 'in',
  [OPERATOR_NOT_IN]: 'not in',
};

class DefineConditional extends Component {
  componentDidMount() {
    this.fetchAction();
  }

  componentDidUpdate() {
    if (!isEmpty(get(this.state, 'rules'))) {
      const actionSelected = get(this.state, 'rules').filter(function(el) {
        if (get(el, 'key') === 'action') {
          return true;
        } else {
          return false;
        }
      });
      if (!isEmpty(actionSelected)) {
        if (isEmpty(get(this.state, 'actions', []))) {
          showToastMessage(
            'warning',
            `Please configure a workflow before actions`,
            5
          );
        }
      }
    }
  }

  constructor(props) {
    super(props);
    const {
      every = true,
      behavior = 'hide',
      rules = [],
      currentFieldId,
    } = props;
    this.state = {
      every: every === JOIN_TYPE_ALL || every === true,
      behavior,
      rules,
      messageType: 'error',
      message: '',
    };
    this.allFields = getAllFieldsFlat(
      componentsManager.fields,
      currentFieldId,
      true
    );

    // to set cascader options, doing this here instead of doing it in the render
    // because options won't change between each render
    this.cascaderOptions = getCascaderOptionsFromFields(currentFieldId, true);
  }

  onAddRule = () => {
    const { rules } = this.state;
    const { allTabs, fieldsByKey } = this.allFields;
    if (this.canAddRule()) {
      const [{ properties }] = allTabs;
      if (properties.length) {
        const firstKey = properties[0].key;
        const operators = this.getOperatorList(firstKey);
        let expect = [SWITCH, CHECKBOX].includes(
          fieldsByKey[firstKey].field_type
        )
          ? true
          : [];

        /**
         * Removed redundant assignment. The following condition will always
         * evaluate to false and reassign 'expect' with the same value,
         * because 'expect' is always a truthy value.
         */
        // expect =
        //   [SELECT].includes(fieldsByKey[firstKey].field_type) && !expect
        //     ? null
        //     : expect;
        const newRules = rules.concat([
          { key: firstKey, toBe: operators[0], expect },
        ]);
        this.updateRules(newRules);
      } else {
        this.setState({
          message: 'Please add more fields to the form.',
        });
      }
    } else {
      this.setState({
        message: 'Please complete current condition before add a new one.',
      });
    }
  };

  onDeleteRule = index => () => {
    const { rules } = this.state;
    this.updateRules(rules.filter((c, i) => i !== index));
  };

  getFieldKeyFromList = key => {
    // validate .item. to get the key from a repeater field
    // however for conditions the field will nevet meet the
    // condition outside the repeater because it doesn't
    // know the value of item
    if (key.includes(repeaterKeyDivider) || key.includes(repeaterItemDivider)) {
      const fieldKeyStructure = key.split('.');
      return fieldKeyStructure[2];
    } else {
      return key;
    }
  };

  getOperatorList = fieldKey => {
    const { fieldsByKey } = this.allFields;
    const operators = [];
    const fieldKeyName = this.getFieldKeyFromList(fieldKey);
    switch (fieldsByKey[fieldKeyName].field_type) {
      case SELECT:
      case MULTISELECT:
        operators.push(OPERATOR_IN, OPERATOR_NOT_IN);
        break;
      case SWITCH:
      case CHECKBOX:
        operators.push(OPERATOR_EQUAL, OPERATOR_NOT_EQUAL);
        break;
      default:
        operators.push(
          OPERATOR_EQUAL,
          OPERATOR_NOT_EQUAL,
          OPERATOR_IN,
          OPERATOR_NOT_IN
        );
    }
    return operators;
  };

  selectField = (fieldKey, index) => {
    const { rules } = this.state;
    const [op] = this.getOperatorList(fieldKey);
    let newRules;
    if (!rules[index]) {
      newRules = rules.concat([{ key: fieldKey, expect: [] }]);
    } else {
      const { fieldsByKey } = this.allFields;
      const { field_type } = fieldsByKey[fieldKey];
      newRules = this.rulesUpdater(index, 'key', fieldKey);
      newRules = this.rulesUpdater(index, 'toBe', op, newRules);
      newRules = this.rulesUpdater(
        index,
        'expect',
        [SWITCH, CHECKBOX].includes(field_type) ? true : [],
        newRules
      );
    }
    this.updateRules(newRules);
  };

  rulesUpdater = (index, name, value, rulesToUpdate = null) => {
    const rules = rulesToUpdate || this.state.rules;
    return rules.map((o, i) => {
      if (i === index) {
        return { ...o, [name]: value };
      } else {
        return o;
      }
    });
  };

  canAddRule = () => {
    const { rules } = this.state;
    const { fieldsByKey } = this.allFields;
    const allowedEmptyFields = [STRING, NUMBER];
    return (
      !rules.length ||
      !rules.some(rule => {
        const fieldKeyName = this.getFieldKeyFromList(rule.key);
        if (allowedEmptyFields.includes(fieldsByKey[fieldKeyName].field_type)) {
          return;
        }
        if (rule.key) {
          return [SWITCH, CHECKBOX].includes(
            fieldsByKey[fieldKeyName].field_type
          )
            ? !isBoolean(rule.expect)
            : isEmpty(rule.expect);
        }
        return true;
      })
    );
  };

  updateJoinType = every => {
    // required to avoid set every as string, it must be boolean
    const sanitizeEvery = every === JOIN_TYPE_ALL || every === true;
    const { updateParent } = this.props;
    const { behavior, rules } = this.state;
    this.setState({ every: sanitizeEvery });
    updateParent && updateParent({ conditions: { every, behavior, rules } });
  };

  updateBehavior = behavior => {
    const { updateParent } = this.props;
    const { every, rules } = this.state;
    this.setState({ behavior });
    updateParent && updateParent({ conditions: { every, behavior, rules } });
  };

  updateRules = rules => {
    const { updateParent, currentFieldKey } = this.props;
    const { every, behavior } = this.state;
    this.setState({ rules, message: '' });
    updateParent &&
      updateParent({
        conditions: { every, behavior, rules },
        validations: { mustExist: `${currentFieldKey}` },
      });
  };

  updateGeneric = (index, name, value) => {
    const rules = this.rulesUpdater(index, name, value);
    this.updateRules(rules);
  };

  onChangeGeneric = (index, name) => value =>
    this.updateGeneric(index, name, value);
  onChangeYesNo = (index, name) => value =>
    this.updateGeneric(index, name, value === 'yes');
  onBlurInput = (index, name) => e =>
    this.updateGeneric(index, name, e.target.value);

  isInDisableEditionList = key => {
    const disableFieldList = [];
    if (disableFieldList.includes(key)) {
      return true;
    }
    return false;
  };

  renderCompareTo = (index, fieldsByKey) => {
    const { disabled = false } = this.props;
    const { key, toBe, expect } = this.state.rules[index];
    const fieldKeyName = this.getFieldKeyFromList(key);
    const field = fieldsByKey[fieldKeyName] || {};
    let toRender;
    const renderOptions = ({ label, value, enums }, key) => {
      return value === undefined ? (
        <OptGroup key={key} label={label}>
          {enums.map((e, i) => renderOptions(e, `${key}-${i}`))}
        </OptGroup>
      ) : (
        <Option key={key} value={value}>
          {label}
        </Option>
      );
    };
    const renderOption = ({ label, value }) => (
      <Option key={value} value={String(value)}>
        {label}
      </Option>
    );
    switch (field.field_type) {
      case SELECT:
      case MULTISELECT:
        if (!isEmpty(field.key) && field.key === 'action') {
          toRender = (
            <Select
              size="default"
              mode="multiple"
              placeholder="Please select"
              className="cond-compare-to"
              defaultValue={expect || null}
              onChange={this.onChangeGeneric(index, 'expect')}
              disabled={this.isInDisableEditionList(key) ? true : disabled}
            >
              {get(this.state, 'actions', []).map(option => {
                return renderOption(option);
              })}
              <Option key="empty" value={null}>
                Empty
              </Option>
            </Select>
          );
        } else {
          const enums = componentsManager.getEnum(
            field.enumRef,
            field.parentEnum
          );
          toRender = (
            <Select
              size="default"
              mode="multiple"
              placeholder="Please select"
              className="cond-compare-to"
              defaultValue={expect || null}
              onChange={this.onChangeGeneric(index, 'expect')}
              disabled={this.isInDisableEditionList(key) ? true : disabled}
            >
              {enums.map((e, i) => renderOptions(e, `${field.key}-${i}`))}
              <Option key="empty" value={null}>
                Empty
              </Option>
            </Select>
          );
        }
        break;
      case SWITCH:
      case CHECKBOX:
        toRender = (
          <Select
            size="default"
            className="cond-compare-to"
            defaultValue={expect ? 'yes' : 'no'}
            onChange={this.onChangeYesNo(index, 'expect')}
            disabled={this.isInDisableEditionList(key) ? true : disabled}
          >
            <Option key="yes" value="yes">
              Yes
            </Option>
            <Option key="no" value="no">
              No
            </Option>
          </Select>
        );
        break;
      case NUMBER:
        toRender = (
          <InputNumber
            type="text"
            className="edit-input cond-compare-to"
            defaultValue={expect || 0}
            onChange={this.onChangeGeneric(index, 'expect')}
            disabled={this.isInDisableEditionList(key) ? true : disabled}
          />
        );
        break;
      case undefined:
        toRender = (
          <Input
            type="text"
            className="edit-input cond-compare-to"
            placeholder="Add value"
            disabled={false}
          />
        );
        break;
      default:
        if ([OPERATOR_EQUAL, OPERATOR_NOT_EQUAL].includes(toBe)) {
          toRender = (
            <Input
              type="text"
              className="edit-input cond-compare-to"
              defaultValue={expect || ''}
              placeholder="Add value"
              onBlur={this.onBlurInput(index, 'expect')}
              disabled={this.isInDisableEditionList(key) ? true : disabled}
            />
          );
        } else {
          toRender = (
            <Select
              mode="tags"
              className="edit-input cond-compare-to"
              defaultValue={expect || []}
              placeholder="Add value"
              onBlur={this.onChangeGeneric(index, 'expect')}
              disabled={this.isInDisableEditionList(key) ? true : disabled}
            />
          );
        }
        break;
    }

    return toRender;
  };

  displayCascaderLabel = label => (label ? label[label.length - 1] : '');

  findCascaderKey = (options, key) => {
    let resp = [];
    const fieldKeyName = this.getFieldKeyFromList(key);
    options.some(option => {
      if (option.children) {
        const value = this.findCascaderKey(option.children, fieldKeyName);
        if (value.length) {
          resp = [option.value].concat(value);
        }
        return resp.length;
      } else {
        if (option.value === fieldKeyName) resp.push(option.value);
        return resp.length;
      }
    });
    return resp;
  };

  async fetchAction() {
    const templateId = get(this.props, 'match.params.id');
    const agencyId = get(this.props, 'match.params.agencyId');
    try {
      if (!templateId || !agencyId || templateId === 'create') {
        this.setState({ actions: [] });
        return;
      }
      const actions = await getPropagationActions(agencyId, templateId);
      this.setState({ actions });
    } catch (error) {
      this.setState({ actions: [] });
    }
  }

  render() {
    const { disabled = false } = this.props;
    const { every, behavior, rules, message, messageType } = this.state;
    const { fieldsByKey } = this.allFields;

    return (
      <RowWrapper
        className={`conditional ${disabled ? 'disabled' : ''}`}
        key="cond"
      >
        <Row className="cond-row" key="cond-title">
          <span>When </span>
          <Select
            defaultValue={every ? JOIN_TYPE_ALL : JOIN_TYPE_SOME}
            className="cond-join-type"
            onChange={this.updateJoinType}
            disabled={disabled}
          >
            <Option key={JOIN_TYPE_ALL}>All</Option>
            <Option key={JOIN_TYPE_SOME}>Some</Option>
          </Select>
          <span className="">
            &nbsp;of the conditions are met, field will turn from &nbsp;
          </span>
          <Select
            defaultValue={behavior}
            className="cond-behavior"
            onChange={this.updateBehavior}
            disabled={disabled}
          >
            <Option key="hide">Hide to Show</Option>
            <Option key="disable">Disable to Enable</Option>
          </Select>
        </Row>

        <Row className="scrollable">
          {rules.map((rule, index) => {
            const { key, toBe } = rule;
            const hasToBe = has(rule, 'toBe', false);
            return (
              <Row className="cond-row" key={`${key}-${toBe}-${index}`}>
                <Col sm={9}>
                  <Cascader
                    className="cond-select-field"
                    defaultValue={this.findCascaderKey(
                      this.cascaderOptions,
                      key
                    )}
                    options={this.cascaderOptions}
                    expandTrigger="hover"
                    displayRender={this.displayCascaderLabel}
                    allowClear={false}
                    onChange={value =>
                      this.selectField(value[value.length - 1], index)
                    }
                    disabled={
                      this.isInDisableEditionList(key) ? true : disabled
                    }
                  />
                </Col>
                <Col sm={4}>
                  <Select
                    className="cond-comparition-type"
                    defaultValue={hasToBe ? toBe : 'inq'}
                    onChange={this.onChangeGeneric(index, 'toBe')}
                    disabled={
                      this.isInDisableEditionList(key) ? true : disabled
                    }
                  >
                    {this.getOperatorList(key).map(op => (
                      <Option value={op} key={op}>
                        {OPERATOR_LABEL[op]}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col sm={9}>{this.renderCompareTo(index, fieldsByKey)}</Col>
                <Col sm={2}>
                  <Button
                    className="button-delete ant-btn-circle"
                    icon="minus"
                    onClick={this.onDeleteRule(index)}
                    disabled={
                      this.isInDisableEditionList(key) ? true : disabled
                    }
                  />
                </Col>
              </Row>
            );
          })}
        </Row>

        <Row className="cond-footer cond-row" key="cond-footer">
          <Button
            className="button-add"
            type="default"
            size="small"
            icon="plus"
            onClick={this.onAddRule}
            disabled={disabled}
          >
            Add condition
          </Button>
        </Row>
        <Row className="cond-row">
          {message && (
            <Alert message={message} type={messageType} showIcon closable />
          )}
        </Row>
      </RowWrapper>
    );
  }
}

export default withRouter(DefineConditional);
