import { camelCase } from 'lodash';
import { PERMISSIONS } from '../../../utils/admin';
import AgencyAuditLogsReportTimeline from './AgencyAuditLogsReportTimeline';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/report-timeline-audit-logs/:tenantId`,
    name: camelCase(`${name}-report-timeline-audit-logs`),
    component: AgencyAuditLogsReportTimeline,
    protectedWith: [
      PERMISSIONS.viewAgencyAuditLogs,
      PERMISSIONS.viewAllAuditLogs,
    ],
    childRoutes: [],
  },
];
