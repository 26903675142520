import uuid from 'uuid/v4';

import createModal from '../../../actions/create-modal';
import showModal from '../../../actions/show-modal';
import hideModal from '../../../actions/hide-modal';
import updateModal from '../../../actions/update-modal';
import deleteModal from '../../../actions/delete-modal';

const BaseModalWrapper = Base =>
  class WrappedComponent extends Base {
    modalId = null;

    createModal = options => {
      const { dispatch } = this.props;

      this.modalId = uuid();

      const modal = {
        ...options,
        id: this.modalId,
      };

      dispatch(createModal(modal));
    };

    updateModal = options => {
      const { dispatch } = this.props;

      dispatch(updateModal({ ...options, id: this.modalId }));
    };

    showModal = () => {
      const { dispatch } = this.props;

      dispatch(showModal(this.modalId));
    };

    hideModal = (all = false, done) => {
      const { dispatch } = this.props;

      dispatch(hideModal(this.modalId, all));

      done && typeof done === 'function' && done();
    };

    deleteModal = () => {
      const { dispatch } = this.props;

      dispatch(deleteModal(this.modalId));
    };
  };

export default BaseModalWrapper;
