import React from 'react';
import { Tooltip } from 'antd';
import { kebabCase } from 'lodash';

// Components
import Tag from '../../components/common/tag';
import StatusAvatar from '../../components/common/status-avatar';
import ReportMetaWrapper from './ReportMetaWrapper.styled';
import getStatus from '../../utils/get-report-status';

const ReportMeta = ({ lastStatus, report, showParticipants, currentUser }) => {
  const {
    activeReviewerId,
    bubbleParticipants: reportParticipantIds = [],
    participants = [],
    viewerIds = [],
  } = report;
  const { label: statusLabel = '', key: statusKey = '' } = lastStatus;

  const reportParticipants = participants.reduce(
    (entries, participant) => ({
      ...entries,
      [participant.id]: participant,
    }),
    {}
  );

  const getParticipantStatus = participantId =>
    getStatus(report, participantId);

  const currentUserWorkflowStatus = getParticipantStatus(currentUser);

  const hasViewers =
    viewerIds && Array.isArray(viewerIds) && viewerIds.length > 0;

  return (
    <ReportMetaWrapper>
      {showParticipants &&
        reportParticipantIds.map(participantId => {
          const participant = reportParticipants[participantId];

          if (!participant) {
            return null;
          }

          const {
            id,
            firstName,
            lastName,
            starNumber,
            rank: { abbreviation = '', name = '' } = {},
          } = participant;

          const participantIsTemporalViewer =
            hasViewers && viewerIds.includes(id);

          return (
            abbreviation && (
              <Tooltip
                placement="top"
                key={id}
                overlayClassName="ant-custom-tooltip"
                getPopupContainer={trigger => trigger.parentNode}
                title={
                  <p className="text-center">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${name} ${firstName} ${lastName}`,
                      }}
                    />
                    <br />
                    {`${starNumber}`}
                  </p>
                }
              >
                <StatusAvatar
                  type={
                    participantIsTemporalViewer
                      ? kebabCase(currentUserWorkflowStatus.label)
                      : kebabCase(statusLabel)
                  }
                  active={
                    id === activeReviewerId || participantIsTemporalViewer
                  }
                  className="status-indicator"
                  key={id}
                >
                  {abbreviation}
                </StatusAvatar>
              </Tooltip>
            )
          );
        })}

      <Tag
        size="default"
        className="status-indicator"
        statusTag
        type={statusKey}
      >
        {statusLabel}
      </Tag>
    </ReportMetaWrapper>
  );
};

export default ReportMeta;
