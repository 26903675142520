import React from 'react';
import { Checkbox } from 'antd';

class FieldCheckbox extends React.Component {
  onChange = e => {
    const { onChange } = this.props;

    onChange && onChange(e.target.checked);
  };

  render() {
    const {
      checkboxLabel = '',
      value,
      style = { width: 200 },
      disabled = false,
      'data-test': dataTest,
    } = this.props;

    return (
      <Checkbox
        style={style}
        checked={value}
        onChange={this.onChange}
        disabled={disabled}
        data-test={dataTest}
      >
        <span className="label">{checkboxLabel}</span>
      </Checkbox>
    );
  }
}

export default FieldCheckbox;
