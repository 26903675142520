import { get, isEmpty, isString } from 'lodash';
import componentsManager from '../../services/componentsManager';
import { getCurrentRepeaterFromField } from '../../../../utils/form';
import CascaderSelectionBox from '../../components/CascaderSelectionBox';
import { Cascader } from 'antd';
import React from 'react';
export default class sourceSectionFieldCascader {
  constructor(values) {
    const fields = componentsManager.fields;
    const repeaters = componentsManager.repeatersReference;
    this.name = 'sourceFieldSection';
    this.label = '';
    this.component = CascaderSelectionBox;
    this.style = { left: '8em', display: 'none' };
    this.onRender = this.onRenderComponent;
    const emptyValue = { label: '--Empty--', value: 'none' };
    const currentField = get(values, 'key');
    const currentRepeater = getCurrentRepeaterFromField(
      fields,
      repeaters,
      currentField
    );
    componentsManager.find;
    const fieldsTarget = fields
      .map((tab, i) => {
        return {
          value: i,
          label: tab.title,
          children: (repeaters[i] || [])
            .filter(repeater => {
              if (repeater.repeaterKey !== currentRepeater) {
                return true;
              } else {
                return false;
              }
            })
            .map(repeater => {
              const [repeaterItem] = componentsManager.findContainersByKey(
                fields[i].properties,
                repeater.repeaterKey
              );
              return {
                label: get(
                  repeaterItem,
                  ['options', 'name'],
                  repeaterItem.reportingKey
                ),
                value: repeater.repeaterKey,
                children: Object.keys(repeater.fields).map(field => {
                  const [fieldItem] = componentsManager.findFieldsByKey(
                    fields[i].properties,
                    field
                  );
                  return {
                    label: fieldItem.title || fieldItem.reportingKey,
                    value: fieldItem.key,
                  };
                }),
              };
            }),
        };
      })
      .filter(tab => tab.children.length); // Return only tabs with repeaters

    if (!isEmpty(fieldsTarget)) {
      this.selectOptions = fieldsTarget;
      this.selectOptions.push(emptyValue);
    } else {
      this.selectOptions = [];
      this.selectOptions.push(emptyValue);
    }
  }

  onRenderComponent = props => {
    const { defaultValue, selectOptions, onChange, value, state } = props;
    const isSourceRequired = get(state, 'values.isSourceRequired');
    let style = { width: 300, left: '8em', display: 'block' };
    if (!isSourceRequired) {
      style = { width: 300, left: '8em', display: 'none' };
    }
    return (
      <Cascader
        defaultValue={defaultValue}
        style={style}
        options={selectOptions}
        value={value}
        onChange={onChange}
      ></Cascader>
    );
  };

  onSave = values => {
    let sourceFieldSection = get(values, `sourceFieldSection`);
    const isSourceRequired = get(values, 'isSourceRequired');
    if (!isSourceRequired) sourceFieldSection = ['none'];
    let result = { ...values, sourceFieldSection };
    if (isSourceRequired) {
      if (Array.isArray(sourceFieldSection) && sourceFieldSection.length > 1) {
        const sourceFieldObject = sourceFieldSection;
        const fieldKey = sourceFieldObject[2];
        const repeaterKey = sourceFieldObject[1];
        let defaulTitle = '';

        if (get(get(values, 'title'), 'defaultTitle')) {
          defaulTitle = get(get(values, 'title'), 'defaultTitle');
        } else {
          defaulTitle = get(values, 'title');
        }

        if (!isString(defaulTitle) || defaulTitle === '') {
          defaulTitle = ' ';
        }

        result = {
          ...result,
          title: {
            source: repeaterKey,
            defaultTitle: defaulTitle,
            map: [fieldKey],
          },
        };
      }
    } else {
      let defaulTitle = '';

      if (get(get(values, 'title'), 'defaultTitle')) {
        defaulTitle = get(get(values, 'title'), 'defaultTitle');
      } else {
        defaulTitle = get(values, 'title');
      }

      if (!isString(defaulTitle) || defaulTitle === '') {
        defaulTitle = ' ';
      }

      result = {
        ...result,
        title: defaulTitle,
      };
    }
    return result;
  };

  getInitialValue = values => {
    const valueSourceField = get(values, `sourceFieldSection`);
    if (valueSourceField) {
      return valueSourceField;
    } else {
      return ['none'];
    }
  };
}
