import React, { Component } from 'react';

class StaticContent extends Component {
  render() {
    const {
      enums,
      options,
      isReviewer,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      field_type,
      value,
      ...props
    } = this.props;

    const { content = '' } = options;

    return (
      <div
        {...props}
        className="ql-editor"
        dangerouslySetInnerHTML={{ __html: content || '&nbsp;' }}
      />
    );
  }
}

export default StaticContent;
