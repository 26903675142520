import React from 'react';
import { Cascader } from 'antd';

class CascaderSelectionBox extends React.Component {
  constructor(props) {
    super(props);
    const { onChange, defaultValue, value } = this.props;
    if (value === undefined && defaultValue !== undefined) {
      onChange(defaultValue);
    }
  }

  render() {
    const {
      defaultValue,
      selectOptions,
      onChange,
      onRender,
      value,
      style = { width: 260 },
    } = this.props;
    if (onRender) {
      return onRender(this.props);
    } else {
      return (
        <Cascader
          defaultValue={defaultValue}
          style={style}
          options={selectOptions}
          value={value}
          onChange={onChange}
        ></Cascader>
      );
    }
  }
}

export default CascaderSelectionBox;
