import {
  FETCH_USER_CASELOAD_REQUEST,
  FETCH_USER_CASELOAD_SUCCESS,
  FETCH_USER_CASELOAD_ERROR,
} from './';

import { getInternalAffairCaseLoadPerPage } from '../selectors/internal-affair-case-load';

import { getErrorResponseStatus, getReqPaginationParams } from './utils';

import { getUserCaseLoad } from '../api/reports';

export default (page, filter, next = () => {}) => (dispatch, getState) => {
  const state = getState();
  const {
    session: {
      currentUser: { userId },
    },
  } = state;
  const perPage = getInternalAffairCaseLoadPerPage(state);
  dispatch({
    type: FETCH_USER_CASELOAD_REQUEST,
    payload: { page, perPage },
  });
  const reqParams = {
    userId,
    filters: {
      ...filter,
      ...getReqPaginationParams(perPage, page),
    },
  };
  return getUserCaseLoad(reqParams)
    .then(response =>
      dispatch({
        type: FETCH_USER_CASELOAD_SUCCESS,
        payload: response,
      })
    )
    .catch(responseError =>
      dispatch({
        type: FETCH_USER_CASELOAD_ERROR,
        payload: getErrorResponseStatus(responseError),
      })
    );
};
