import React from 'react';
import classNames from 'classnames';

import Panel from './styled';
import Title from './styled/panel-title';

export default ({
  bordered = false,
  transparent = false,
  marginless = false,
  paddingless = false,
  paddinglessVertical = false,
  paddinglessHorizontal = false,
  hasTable = false,
  isTable = false,
  marginlessHorizontal = false,
  marginlessVertical = false,
  children,
  ...props
}) => {
  const className = classNames({
    'has-table': hasTable,
    'is-bordered': bordered,
    'is-table': isTable,
    'is-transparent': transparent,
    'is-paddingless': paddingless,
    'is-marginless': marginless,
    'is-paddingless-vertical': paddinglessVertical,
    'is-paddingless-horizontal': paddinglessHorizontal,
    'is-marginless-horizontal': marginlessHorizontal,
    'is-marginless-vertical': marginlessVertical,
    [props.className]: !!props.className,
  });

  return <Panel className={className}>{children}</Panel>;
};

export const PanelTitle = ({ right, children, className }) => {
  const heading = typeof children === 'string' ? <h2>{children}</h2> : children;

  return (
    <Title className={className}>
      {right && <div className="panel-title-action">{right}</div>}
      {heading}
    </Title>
  );
};

export {
  Divider,
  PanelRow,
  PanelSection,
  PanelTableRow,
  PanelPaginationRow,
  PanelSectionTable,
  PanelAvatarSection,
  PanelTable,
  CollapsePanel,
  TablePanel,
} from './styled';
