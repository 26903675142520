import { groupBy, kebabCase, toString, get } from 'lodash';

const groupByKey = 'title';
const defaultCategory = 'Other';

const groupReviewers = (
  reviewers = [],
  key = groupByKey,
  uncategorizedGroup = defaultCategory
) => {
  const groupedByKey = groupBy(
    reviewers,
    item => item[key] || uncategorizedGroup
  );

  const groupLabels = Object.keys(groupedByKey).reduce((output, groupKey) => {
    return {
      ...output,
      [kebabCase(groupKey)]: groupKey,
    };
  }, {});

  const groupBySlug = groupBy(reviewers, reviewer =>
    kebabCase(reviewer[key] || uncategorizedGroup)
  );

  const prepareLabel = user => {
    if (user.middleName) {
      return user.firstName + ' ' + user.middleName + ' ' + user.lastName;
    }
    return user.firstName + ' ' + user.lastName;
  };

  return Object.keys(groupLabels).reduce((res, item) => {
    const label = groupLabels[item];
    const groupItems = get(groupBySlug, item, []);

    return [
      ...res,
      {
        value: kebabCase(label),
        label: label,
        children: groupItems.map(i => ({
          value: toString(i.id),
          label: prepareLabel(i),
          isChoosable: true,
          benchmarkId: i.benchmarkId,
          employeeId: i.employeeId,
          starNumber: i.starNumber,
        })),
      },
    ];
  }, []);
};

export default groupReviewers;
