const decodeEquipmentTableRows = data =>
  data.flatMap(item => {
    const { category: { label: categoryLabel } = {} } = item;
    const {
      equipment: { id },
    } = item;
    const values = item.fieldsValues.reduce((acc, field) => {
      acc[field.name] = decodeURIComponent(field.fieldValue);
      field.fieldValue = acc[field.name];
      return acc;
    }, {});
    return [{ id, category: categoryLabel, item, ...values }];
  });

export default decodeEquipmentTableRows;
