import {
  FETCH_USERS_LOADING,
  FETCH_USERS_LOADING_SUCCESSFUL,
  FETCH_USERS_LOADING_ERROR,
} from './actions';

export const initialState = { data: [], loading: false };

export const reducer = (state, action) => {
  switch (action.type) {
    case FETCH_USERS_LOADING:
      return { ...state, loading: true };
    case FETCH_USERS_LOADING_SUCCESSFUL:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_USERS_LOADING_ERROR:
      return { ...state.users, loading: false };
    default:
      return { ...state };
  }
};
