import getLmsUrl from '../../../../../utils/getLmsUrl';
import React from 'react';

export const hyperlinkRenderCourseTitle = (text, record) => (
  <a
    href={getLmsUrl(`/lms/course/${record.kmiCourseId}`)}
    target={'_blank'}
    rel="noreferrer"
  >
    {text}
  </a>
);

export const hyperLinkRenderRegistration = (text, record) => (
  <a
    href={getLmsUrl(`/lms/admin/registrations/${record.kmiRegistrationId}`)}
    target={'_blank'}
    rel="noreferrer"
  >
    {text}
  </a>
);

export const noHyperLink = text => text;
