import resource from './resource';

export default {
  ...resource,
  per_page: 5,
  filter: {
    where: { actionItem: false },
    order: 'created_at DESC',
    limit: 10,
  },
};
