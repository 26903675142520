import CommentEndpoints from '../../../../api/organization/commentEndpoints';

export default (ouId, comment) => {
  return new Promise((resolve, reject) => {
    CommentEndpoints.addOrganizationalUnitComment(ouId, comment)
      .then(response => {
        resolve({ data: response.success, error: null });
      })
      .catch(error => {
        return reject(error.message);
      });
  });
};
