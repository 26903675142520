import React from 'react';
import get from 'lodash/get';
import { Button } from 'antd';

import withoutClutter from 'APP_COMPONENTS/without-clutter';
import DashboardPage from 'APP_COMPONENTS/dashboard';
import PageHeader from 'APP_COMPONENTS/PageHeader';

import AdministratorWrapper from '../../Administrator.styled';

import getRoute from 'APP_ROOT/utils/get-route';

import { getTemplate, getTemplates } from '../ConfigureWorkflows.selectors';
import getAgencyTemplates from '../actions/get-agency-templates';
import getSecurityRoles from '../../agency-profile/actions/get-security-roles';

import getTemplateWorkflows from '../actions/get-template-workflows';
import withOwnershipGuard from '../../components/agency-ownership-guard';

import WorkflowList from './WorkflowList';

export class Component extends React.Component {
  componentDidMount() {
    const { dispatch, agencyId, templatesConfig, templateId } = this.props;

    dispatch(
      getAgencyTemplates(agencyId, templatesConfig, () => {
        dispatch(getTemplateWorkflows({ agencyId, templateId }));
        dispatch(getSecurityRoles(agencyId, false));
      })
    );
  }

  pageActions = () => {
    const { history, agencyId, templateId } = this.props;

    return [
      <Button
        type="primary"
        size="default"
        key="add-btn"
        onClick={() =>
          history.push(
            getRoute('administratorAgencyWorkflowsTemplateWorkflow', {
              agencyId,
              templateId,
              workflowId: 'create',
            })
          )
        }
      >
        + Add Workflow
      </Button>,
    ];
  };

  getWorkflowLink = workflowId => {
    const { agencyId, templateId } = this.props;

    return getRoute('administratorAgencyWorkflowsTemplateWorkflow', {
      agencyId,
      templateId,
      workflowId,
    });
  };

  render() {
    const {
      templateId,
      agencyId,
      currentTemplate,
      agencyTemplates,
    } = this.props;

    const template = get(agencyTemplates, ['entries', templateId], {});
    const isLoading = agencyTemplates.loading || currentTemplate.loading;

    return (
      <AdministratorWrapper>
        <PageHeader
          title={`${template.name} Workflows`}
          goBackTo={getRoute('administratorAgencyWorkflows', {
            agencyId,
          })}
          actions={this.pageActions()}
          loading={isLoading}
        />
        <div className="administrator-content">
          {((currentTemplate.workflows.length > 0 && !isLoading) ||
            isLoading) && (
            <WorkflowList
              getWorkflowLink={this.getWorkflowLink}
              isLoading={isLoading}
              workflows={currentTemplate.workflows}
            />
          )}
          {!isLoading && currentTemplate.workflows.length === 0 && (
            <div className="empty-state">
              <h1>No Workflows Available</h1>
            </div>
          )}
        </div>
      </AdministratorWrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  const agencyId = get(props, 'match.params.agencyId');
  const templateId = get(props, 'match.params.templateId');

  return {
    agencyTemplates: getTemplates(state),
    currentTemplate: getTemplate(state, agencyId, templateId),
    agencyId,
    templateId,
  };
};

export default withoutClutter(
  DashboardPage(mapStateToProps)(
    withOwnershipGuard(Component, 'administratorAgencyWorkflows')
  )
);
