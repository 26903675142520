import { createReducer } from 'redux-create-reducer';
import {
  POST_FORM_LINK_ERROR,
  POST_FORM_LINK_SUCCESS,
  POST_FORM_LINK_REQUEST,
  FETCH_FORM_LINKS_REQUEST,
  FETCH_FORM_LINKS_SUCCESS,
  FETCH_FORM_LINKS_ERROR,
  DELETE_FORM_LINK_ERROR,
  DELETE_FORM_LINK_SUCCESS,
  DELETE_FORM_LINK_REQUEST,
} from '../actions';

const initialState = {
  data: {},
  list: [],
  fetched: false,
  loading: false,
  error: null,
};

const events = {
  [FETCH_FORM_LINKS_REQUEST]: (state, { payload: { page } }) => ({
    ...state,
    loading: true,
  }),

  [FETCH_FORM_LINKS_SUCCESS]: (state, { payload: { formLinks, count } }) => ({
    ...state,
    loading: false,
    fetched: true,
    list: [...formLinks.map(({ id }) => id)],
    data: formLinks.reduce((acc, value) => ({ ...acc, [value.id]: value }), {}),
  }),
  [FETCH_FORM_LINKS_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [POST_FORM_LINK_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [POST_FORM_LINK_SUCCESS]: (
    { list, data, ...state },
    { payload: { formLink } }
  ) => ({
    ...state,
    loading: false,
    fetched: true,
    list: [formLink.id, ...list.filter(id => id !== formLink.id)],
    data: { ...data, [formLink.id]: formLink },
  }),
  [POST_FORM_LINK_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [DELETE_FORM_LINK_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [DELETE_FORM_LINK_SUCCESS]: (state, { payload }) => ({
    ...state,
    loading: false,
    fetched: true,
  }),
  [DELETE_FORM_LINK_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
};

export default createReducer(initialState, events);
