import ReactGA from 'react-ga';
import { LOCATION_CHANGE } from 'react-router-redux';
import get from 'lodash/get';

import { GET_USER_SUCCESS } from '../actions';
import { GA_ACCOUNT } from '../googleAnalytics';

const trackActions = [
  {
    actionType: LOCATION_CHANGE,
    trackEvent: (action, store) => {
      const url = global.location.pathname;

      const state = store.getState();
      const agencyName = get(
        state,
        'session.currentUser.agency.name',
        '(Agency Name Not Available)'
      );
      const userTitle = get(action, 'session.currentUser.rank.name', 'Guest');

      ReactGA.ga('set', {
        dimension1: agencyName,
        cd1: agencyName,
        dimension2: userTitle,
        cd2: userTitle,
      });

      ReactGA.event({
        category: 'Navigation',
        action: 'Navigate',
        label: `${userTitle} from ${agencyName} navigates to ${url}`,
      });

      ReactGA.pageview(url);
    },
  },
  {
    actionType: GET_USER_SUCCESS,
    trackEvent: action => {
      const agencyName = get(
        action,
        'payload.data.agency.name',
        '(Agency Name Not Available)'
      );
      const userTitle = get(action, 'payload.data.rank.name', 'Guest');

      ReactGA.ga('set', {
        dimension1: agencyName,
        cd1: agencyName,
        dimension2: userTitle,
        cd2: userTitle,
      });

      ReactGA.event({
        category: 'User Data',
        action: 'Capture Agency Name',
        label: agencyName,
      });

      ReactGA.event({
        category: 'User Data',
        action: 'Capture User Title',
        label: userTitle,
      });
    },
  },
];

const trackActionTypes = trackActions.map(action => action.actionType);

export default store => next => action => {
  if (trackActionTypes.includes(action.type) && GA_ACCOUNT) {
    trackActions
      .filter(trackAction => trackAction.actionType === action.type)
      .forEach(trackAction => trackAction.trackEvent(action, store));
  }

  let result = next(action);
  return result;
};
