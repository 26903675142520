import { get, isFunction } from 'lodash';

import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes';

const mutateComponentsTree = (fields, componentId, mutation) =>
  fields.reduce((allFields, currentField) => {
    const properties = get(currentField, 'properties', []);

    if (currentField.id === componentId) {
      if (isFunction(mutation)) {
        return mutation(allFields, currentField);
      }
    } else if (currentField.type !== FIELD) {
      return allFields.concat({
        ...currentField,
        properties: mutateComponentsTree(properties, componentId, mutation),
      });
    }

    return allFields.concat(currentField);
  }, []);

export default mutateComponentsTree;
