import get from 'lodash/get';
import DashboardPage from '../../components/dashboard';
import AuditLogsPage from './audit-logs-page';
import { getAgencyTZ } from '../../selectors/session';

const mapState = state => ({
  ssnLogs: get(state, ['organization', 'ssnLogs', 'entries']),
  total: get(state, ['organization', 'ssnLogs', 'total']),
  pageSize: get(state, ['organization', 'ssnLogs', 'pageSize']),
  page: get(state, ['organization', 'ssnLogs', 'page']),
  loading: get(state, ['organization', 'ssnLogs', 'loading']),
  timezone: getAgencyTZ(state),
});

export default DashboardPage(mapState, null)(AuditLogsPage);
