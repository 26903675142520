import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { intersection, isEqual, isPlainObject, isEmpty, get } from 'lodash';

import ErrorScreen from '../../components/common/error-screen';
import {
  getAgencyTZ,
  getAgency,
  getCurrentUserRoles,
  getCurrentUserPermissions,
  getModulesConfiguration,
} from '../../selectors/session';

const mapState = state => ({
  permissions: getCurrentUserPermissions(state),
  agency: getAgency(state),
  roles: getCurrentUserRoles(state),
  timezone: getAgencyTZ(state),
  modules: getModulesConfiguration(state),
});

class AuthorizationProtected extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  getIntersectWith = (target = 'permissions') => {
    const { permissions = [], modules = [], roles = [] } = this.props;

    switch (target) {
      case 'permissions':
        const userPermissions = [...permissions, 'everybody'];
        return userPermissions;

      case 'modules':
        const userModules = modules;
        return userModules;
      case 'roles':
        const userRoles = [...roles, 'everybody'];
        return userRoles;
      default:
        return [];
    }
  };

  render() {
    {
      const {
        history: { replace },
        component: Component,
        allowedFor = [],
        agency,
      } = this.props;

      const back = () => {
        replace('/');
      };

      let allowed = true;

      if (isPlainObject(allowedFor)) {
        allowed = Object.keys(allowedFor).every(item => {
          const allowedFrom = get(allowedFor, item);
          const intersects = intersection(
            this.getIntersectWith(item),
            allowedFrom
          );
          return !isEmpty(intersects);
        });
      } else {
        const allowedFrom =
          typeof allowedFor === 'function' ? allowedFor(agency) : allowedFor;
        const intersectWith = this.getIntersectWith();
        const intersects = intersection(intersectWith, allowedFrom);
        allowed = !isEmpty(intersects);
      }

      if (!allowed) {
        return (
          <div style={{ textAlign: 'center', padding: 20 }}>
            <Helmet title="Unauthorized" />

            <ErrorScreen
              errorCode={401}
              title="Unauthorized"
              message="You are not authorized to see this page."
              onClick={back}
            />
          </div>
        );
      }

      return <Component {...this.props} />;
    }
  }
}

const WithState = connect(mapState)(AuthorizationProtected);

const Protected = (Component, allowedFor = []) => props => (
  <WithState component={Component} allowedFor={allowedFor} {...props} />
);

export default Protected;
