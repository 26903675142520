const setHeader = (currentHeader, newHeader) => {
  return Object.keys(newHeader).reduce(
    (header, key) => {
      if (['name', 'abbreviation'].includes(key)) {
        header[key] = newHeader[key]?.trim() ?? '';
      } else {
        header[key] = newHeader[key];
      }
      return header;
    },
    { ...currentHeader }
  );
};

export default setHeader;
