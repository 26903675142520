import styled, { css } from 'styled-components';
import { Switch } from 'antd';

const bigSwitch = css`
  height: 28px;
  min-width: 72px;
  border-radius: 30px;
  line-height: 25px;

  .ant-switch-inner {
    margin-left: 32px;
    margin-right: 10px;
    text-align: right;
  }

  &::after {
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }

  &.ant-switch-checked {
    .ant-switch-inner {
      margin-left: 10px;
      text-align: left;
    }
  }
`;

const StyledSwitch = styled(Switch).attrs({
  className: 'bdm-input-switch',
})`
  &.ant-switch {
    ${({ size = '' }) => {
      return size === 'large' ? bigSwitch : '';
    }}
  }
`;

export const StyledCompoundSwitch = styled.div`
  display: flex;
  align-items: center;

  span {
    margin: 0 10px;
  }
`;

export default StyledSwitch;
