import equipmentEndpoints from '../../../../api/equipment/equipmentEndpoints';
import callback from '../../utils/callback';

export default ({
  tenantId,
  userId,
  pageNumber,
  pageSize,
  sort,
  filters,
  next,
}) => _dispatch => {
  return equipmentEndpoints
    .getUserEquipments({
      tenantId,
      userId,
      pageNumber,
      pageSize,
      sort,
      filters,
    })
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
