import React from 'react';
import { Icon } from 'antd';

import ModalTitle from '../../common/modal/title';
import ModalBody from '../../common/modal/body';
import ConfirmButton from './confirm-update-button';

export const showUpdateConfirmationModal = (
  reportData,
  showModal,
  updateModal,
  dispatch,
  callback
) => {
  const updateTitle = (
    <ModalTitle error>
      <Icon type="exclamation-circle" /> <span>Update Report!</span>
    </ModalTitle>
  );

  const updateText = (
    <ModalBody>
      <span>
        You are about to update this report, the information about the changes
        will be saved and shown in the timeline.
      </span>
      <ConfirmButton
        dispatch={dispatch}
        reportData={reportData}
        updateModal={updateModal}
        callback={callback}
      />
    </ModalBody>
  );

  updateModal({
    visible: true,
    title: updateTitle,
    children: updateText,
  });

  showModal();
};
