import React, { Component } from 'react';
import Select from 'antd/lib/select';
import {
  isPlainObject,
  isEmpty,
  isEqual,
  isArray,
  has,
  get,
  cond,
  matches,
  uniqBy,
  debounce,
} from 'lodash';
import isUUID from 'is-uuid';
import { unescape as htmlUnescape } from 'html-escaper';

import removeReferenceSelection from 'APP_ROOT/actions/remove-reference-selection';
import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';
import formActionDispatcher from 'APP_ROOT/utils/formDispatchEmitter';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import ReviewerField from '../reviewer-field';

import compareEnumOptions from '../../utils/compareEnumOptions';
import StyledSelect from '../styled/input-select';
import fuzzySearch from '../forms/utils/fuzzySearch';

const { Option, OptGroup } = Select;
// Select elements greater than this will use fuzzy search
const SELECT_LIST_DEFAULT_LIMIT = 100;

const filterOption = (input, option) => {
  const {
    props: { children },
  } = option;
  if (typeof children === 'string') {
    return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  } else if (isPlainObject(children)) {
    const value = htmlUnescape(
      get(children, 'props.dangerouslySetInnerHTML.__html', '')
    );
    return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
};

const mapOption = (item = '', index = 0) => {
  if (isPlainObject(item) && has(item, 'label') && has(item, 'value')) {
    return (
      <Option key={index} value={item.value + []}>
        <div dangerouslySetInnerHTML={{ __html: item.label }} />
      </Option>
    );
  }

  return (
    <Option key={index} value={item + []}>
      <div dangerouslySetInnerHTML={{ __html: item }} />
    </Option>
  );
};

const uniquenessBy = item =>
  isPlainObject(item) && has(item, 'value') ? item.value : item;

const renderSelectOptions = enums => {
  return enums.map((item, index) => {
    if (isPlainObject(item)) {
      if (has(item, 'enums')) {
        return (
          <OptGroup key={index} label={item.label}>
            {uniqBy(get(item, 'enums', []), uniquenessBy)
              .sort(compareEnumOptions)
              .map(mapOption)}
          </OptGroup>
        );
      }
    }
    return mapOption(item, index);
  });
};

export const evaluateSingleLabelValue = (obj, value, response) => {
  if (isPlainObject(obj)) {
    if (obj.value && obj.value === value) {
      return [true, obj.label];
    }
  } else if (obj === value) {
    return [true, obj];
  }
  return [false, response];
};

export const evaluateMultiLabelValue = (obj, value, response) => {
  if (isPlainObject(obj)) {
    if (obj.value && value.includes(obj.value)) {
      response.push(obj.label);
    }
  } else if (value.includes(obj)) {
    response.push(obj);
  }
  return [value.length === response.length, response];
};

export const findLabelValue = (enums, value, evaluate) => {
  let labelValue = isArray(value) ? [] : null;
  const findLabel = obj => {
    let result;
    if (isPlainObject(obj) && obj.enums) {
      return obj.enums.some(findLabel);
    } else {
      if (value) {
        value = isArray(value) ? value.map(htmlUnescape) : htmlUnescape(value);
      }
      [result, labelValue] = evaluate(obj, value, labelValue);
      return result;
    }
  };
  enums.some(findLabel);
  return labelValue;
};

const getReducedList = (list, isNested) => {
  if (!isNested) return list.slice(0, SELECT_LIST_DEFAULT_LIMIT);
  let counter = 0;
  const limitedSet = [];
  for (let parent = 0; parent < list.length; parent++) {
    if (counter >= SELECT_LIST_DEFAULT_LIMIT) break;
    if (!list[parent].enums) {
      limitedSet.push(list[parent]);
      counter++;
      continue;
    }
    const childrenSet = [];
    limitedSet.push({ label: list[parent].label, enums: childrenSet });
    for (let children = 0; children < list[parent].enums.length; children++) {
      if (counter >= SELECT_LIST_DEFAULT_LIMIT) break;
      childrenSet.push(list[parent].enums[children]);
      counter++;
    }
  }
  return limitedSet;
};

const getSelectValue = props => {
  const { enums = [], value } = props;
  //The current value is capture from the select.
  let selectValue = value;
  //isUnknown verify if every value of the array is different between the select value and array value.
  const isUnknown = enums.every(enu => enu.value !== selectValue);
  //The next validation is verifying if the value is an UUID and if isUnknown is true.
  if (isUUID.v4(selectValue) && isUnknown) {
    //Get the value if value and label from the array are the same.
    const { value } = enums.find(enu => enu.value == enu.label) || {};
    //Finally the value for the select is reassigned, with the value from the array.
    selectValue = value;
  }
  return selectValue;
};

const setupState = props => {
  const enums = props.enums || [];
  const sortEnum = props?.options?.sortEnum || false;
  const enumFiltered =
    sortEnum || isPlainObject(enums[0])
      ? uniqBy(enums, uniquenessBy).sort(compareEnumOptions)
      : uniqBy(enums, uniquenessBy);
  const selectValue = getSelectValue(props);
  const isNested = enumFiltered.some(v => v.enums);
  const size = isNested
    ? enumFiltered.reduce(
        (sum, curr) => sum + (curr.enums?.length ? curr.enums.length : 1),
        0
      )
    : enumFiltered.length;
  const limitedSet = getReducedList(enumFiltered, isNested);
  // props.options.populateFrom means data from a repeater
  const isPopulateFrom = props.options?.populateFrom;
  const useFuzzySearch = !isPopulateFrom && size > SELECT_LIST_DEFAULT_LIMIT;

  return {
    options: limitedSet,
    fullList: enumFiltered,
    selectValue,
    limitedSet,
    useFuzzySearch,
    searchValue: '',
  };
};

const MODE_TAGS = 'tags';
const MODE_MULTIPLE = 'multiple';

class InputSelect extends Component {
  constructor(props) {
    super(props);
    this.validateSelectedOption();

    this.state = setupState(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      propsHasChanged(nextProps, this.props) ||
      propsHasChanged(this.state, nextState)
    );
  }

  /**
   * This method overrides the whole state of the field
   * This only works for fields that their values come
   * from a populateFrom field e.g Used on UOF interactions
   * @param {Object} props new props
   * @param {Object} state current state
   * @returns Overwritten state
   */
  static getDerivedStateFromProps(props, state) {
    // in case the field value change outside the component
    // for instance when it is reset in CRN when resolution
    // dropdown changes
    if (state.selectValue !== props.value) {
      return { selectValue: props.value };
    }
    // props.options.populateFrom means data from a repeater
    if (!props.options?.populateFrom) {
      return;
    }
    return setupState(props);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.enums, this.props.enums)) {
      this.validateSelectedOption();
    }
  }

  onRemoveParentRefKeys = ({
    identifier = '',
    source = '',
    fields = [],
    options,
  }) => {
    formActionDispatcher(
      get(options, 'settings.formName'),
      removeReferenceSelection(identifier, source, fields)
    );
  };

  onChange = value => {
    const {
      parentKey,
      parentIndex,
      onChange,
      data,
      options: { removeParentRefKeys = [], createDraftOnChange, settings = {} },
    } = this.props;

    const { formExist, supportsDraft } = settings;

    removeParentRefKeys.forEach(({ matchesValues = [], ...item }) => {
      const identifier = get(data, [parentKey, parentIndex, item.field], '');
      cond(
        matchesValues.map(rule => [
          matches({ value: rule }),
          () => this.onRemoveParentRefKeys({ identifier, ...item }),
        ])
      )({ value });
    });

    onChange(value);

    if (createDraftOnChange && supportsDraft && !formExist) {
      emitFormEvent('onSaveDraft', settings.formName);
    }
  };

  validateSelectedOption = () => {
    const {
      value,
      enums,
      dataKey,
      parentIndex,
      parentKey,
      form: { resetFields = () => {} } = {},
    } = this.props;
    if (value !== undefined && !isEmpty(enums)) {
      //isUnknown verify if every value of the array is
      // different between the select value and array value.
      const isUnknown = this.isUnknownValue(enums, value);
      // there is already a validation for UUID in render method
      if (isUnknown && !isUUID.v4(value)) {
        // enums has probably changed so is better
        // to reset the field to keep consistency
        const key = parentKey
          ? `${parentKey}[${parentIndex}].${dataKey}`
          : dataKey;
        resetFields(key);
      }
    }
  };

  isUnknownValue = (enums, value) => {
    // Verify has a value.
    return enums.every(enu => {
      if (enu.enums) {
        return this.isUnknownValue(enu.enums, value);
      } else {
        return enu.value === undefined || enu.value.toString() !== value;
      }
    });
  };

  renderReviewer = () => {
    const { enums = [], calculatedValue, value } = this.props;

    const evaluateValue = calculatedValue || value;
    const finalValue = findLabelValue(
      enums,
      evaluateValue,
      evaluateSingleLabelValue
    );

    return (
      <ReviewerField
        className="reviewer-field select-field"
        value={finalValue || calculatedValue || evaluateValue}
      />
    );
  };

  search = inputText => {
    if (!inputText) {
      this.setState({ options: this.state.limitedSet, searchValue: '' });
      return;
    }
    const result = getReducedList(
      fuzzySearch(inputText, this.state.fullList),
      this.state.isNested
    );
    this.setState({ options: result, searchValue: inputText });
  };

  handleChange = value => {
    this.setState({ selectValue: value, options: this.state.limitedSet });
    this.onChange(value);
  };

  // Prevents to render new list for each keystroke on search, but
  debounceRender = debounce(this.render, 240);

  render() {
    const {
      enums,
      options,
      id,
      placeholder,
      isReviewer,
      isDraft,
      behavior,
      dataKey,
      parentKey,
      parentIndex,
      calculatedValue,
      isOwnChange,
      dependsOnChange,
      timezone,
      field_type,
      ...props
    } = this.props;

    let overrideReviewer = isReviewer;
    let overrideEditRights = false;
    if (this.props.isContributeReport) {
      if (this.props.contributorAssignmentCanEdit) {
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        overrideReviewer = true;
      }
    }
    if (overrideReviewer && !overrideEditRights) {
      return this.renderReviewer();
    }

    return (
      <StyledSelect
        {...props}
        allowClear={true}
        value={
          this.state.selectValue ? htmlUnescape(this.state.selectValue) : ''
        }
        filterOption={this.state.useFuzzySearch ? undefined : filterOption}
        optionFilterProp="children"
        placeholder={'Please Select'}
        size="default"
        showSearch
        onSearch={this.state.useFuzzySearch ? this.search : undefined}
        onChange={this.handleChange}
        ref={ref => (this.input = ref)}
      >
        {renderSelectOptions(this.state.options)}
      </StyledSelect>
    );
  }
}

class InputMultiSelect extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  getMode = () => {
    const { options } = this.props;

    return options.tagMode ? MODE_TAGS : MODE_MULTIPLE;
  };

  renderReviewer = () => {
    const { enums = [], ...props } = this.props;

    const mode = this.getMode();
    const isTagMode = mode === MODE_TAGS;

    const value =
      (isTagMode || !isEmpty(props.calculatedValue)
        ? props.calculatedValue
        : props.value) || [];
    const evaluateValue = Array.isArray(value) ? value : [value];

    let finalValue = [];
    if (enums.length) {
      finalValue = findLabelValue(
        enums,
        evaluateValue,
        evaluateMultiLabelValue
      );
    } else {
      finalValue = evaluateValue;
    }

    return (
      <div className="reviewer-field select-field">
        {finalValue.join(', ')}
        &nbsp;
      </div>
    );
  };

  render() {
    const {
      enums = [],
      options,
      placeholder,
      isReviewer,
      isDraft,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      field_type,
      ...props
    } = this.props;

    const mode = this.getMode();
    const isTagMode = mode === MODE_TAGS;
    const notFoundContent = isTagMode ? 'Start typing to add' : 'Not Found';

    let overrideReviewer = isReviewer;
    let overrideEditRights = false;
    if (this.props.isContributeReport) {
      if (this.props.contributorAssignmentCanEdit) {
        overrideEditRights = true;
      } else if (this.props.contributorAssignmentCanView) {
        overrideReviewer = true;
      } else {
        <ReviewerField></ReviewerField>;
      }
    }
    if (overrideReviewer && !overrideEditRights) {
      return this.renderReviewer();
    }
    const enumFiltered = uniqBy(enums, uniquenessBy).sort(compareEnumOptions);

    return (
      <StyledSelect
        mode={mode}
        notFoundContent={notFoundContent}
        allowClear={true}
        tokenSeparators={[',']}
        placeholder={placeholder || 'Please select all that apply'}
        size="default"
        {...props}
        value={Array.isArray(props.value) ? props.value.map(htmlUnescape) : []}
        filterOption={filterOption}
        ref={ref => (this.input = ref)}
      >
        {renderSelectOptions(enumFiltered)}
      </StyledSelect>
    );
  }
}

export default InputSelect;
export const MultiSelect = InputMultiSelect;
