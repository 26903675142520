import { OPERATOR_EQUAL } from '../constants/conditionalOperators';
// the idea here is to verify if the conditions includes 'isReviewer'
// and if so, return a css class name to change background color
// as a visual help to the user to know about it

const getReviewerCssClass = (conditions = {}) => {
  const { rules = [] } = conditions;
  const rule = rules.find(r => r.key === 'isReviewer');
  if (rule) {
    const toShow = rule.toBe === OPERATOR_EQUAL ? rule.expect : !rule.expect;
    return toShow ? 'show-reviewer' : 'hide-reviewer';
  }
  return '';
};

export default getReviewerCssClass;
