import React from 'react';
import { isEqual, get, isEmpty } from 'lodash';
import { Icon } from 'antd';
import store from 'APP_ROOT/store';

import { MASKED } from 'APP_ROOT/constants/fieldTypes';
import { SSN_TYPE } from 'APP_ROOT/modules/FormBuilder/constants/fieldMasked';
import { REG_EXP_PATTERNS } from 'APP_ROOT/modules/FormBuilder/constants/fieldPatterns';
import { hasPermissions, PERMISSIONS } from 'APP_ROOT/utils/admin';

import setEncryptedKey from 'APP_ROOT/actions/set-encrypted-key';

const STATE_CREATED = 'created';
const STATE_DRAFT = 'draft';
const STATE_SUBMITTED = 'submitted';
const STATE_CLOSED = 'closed';

const encryptedField = Component => {
  return class EncryptedField extends React.Component {
    constructor(props) {
      super(props);

      const draftDate = get(props, 'data.meta.draftDate');
      const reportState = EncryptedField.getReportState(props);
      let canView = true;
      let canEdit = true;
      let isSSN = false;
      const isEncryptedField = this.isEncryptedField();

      if (isEncryptedField) {
        const { parentKey, dataKey, isReportField = true } = props;
        const fieldKey = parentKey ? `${parentKey}.${dataKey}` : dataKey;

        canEdit = EncryptedField.canEdit(props, reportState);
        canView = EncryptedField.canView(props, reportState);

        // set encrypted key only in reports
        isReportField && store.dispatch(setEncryptedKey(fieldKey));

        // check if is SSN
        isSSN = this.isSSNField();

        // if we need to check other permission, it should be here
      }

      // when in create state, value is visible by default
      this.state = {
        reportState,
        isEncryptedField,
        isSSN,
        canView,
        canEdit,
        draftDate,
        visible: EncryptedField.isVisible(props, reportState),
      };
    }

    static getDerivedStateFromProps(props, state) {
      const draftDate = get(props, 'data.meta.draftDate');
      if (
        state.isEncryptedField &&
        [STATE_CREATED, STATE_DRAFT].includes(state.reportState) &&
        state.draftDate !== draftDate
      ) {
        const reportState = EncryptedField.getReportState(props);
        const canEdit = EncryptedField.canEdit(props, reportState);
        const canView = EncryptedField.canView(props, reportState);

        return {
          reportState,
          canEdit,
          canView,
          draftDate,
          visible: state.visible ? canView : false,
        };
      }
      return null;
    }

    static getReportState(props) {
      // if in create state (brand new report, no state),
      // state attribute will be undefined
      const state = get(props, 'data.meta.workFlowData.state');
      if (state === STATE_CLOSED) {
        return STATE_CLOSED;
      }

      const activeStateReviewers = get(
        props,
        'data.meta.workFlowData.activeStateReviewers',
        {}
      );
      const states = Object.keys(activeStateReviewers);
      if (states.length === 0) {
        return STATE_CREATED;
      } else if (states.length === 1) {
        return STATE_DRAFT;
      }
      return STATE_SUBMITTED;
    }

    static canView(props, reportState) {
      const {
        session: { currentUser: { permissions = [] } = {} } = {},
      } = store.getState();
      // can view when is created or in draft with value empty
      // or has permissions
      // same as isVisible
      return (
        EncryptedField.isVisible(props, reportState) ||
        hasPermissions(permissions, PERMISSIONS.viewEncryptedData)
      );
    }

    static canEdit(props, reportState) {
      // can edit when is created or in draft with value empty
      // same as isVisible
      return EncryptedField.isVisible(props, reportState);
    }

    static isValueEmpty(props) {
      const { calculatedValue, value } = props;
      const fieldValue = calculatedValue || value;
      return isEmpty(fieldValue);
    }

    static isVisible(props, reportState) {
      return (
        reportState === STATE_CREATED ||
        (reportState === STATE_DRAFT && EncryptedField.isValueEmpty(props))
      );
    }
    isEncryptedField = () => {
      const encrypt = get(
        this.props,
        'data-__meta.validate[0].rules[0].encrypt',
        false
      );
      return encrypt;
    };

    isSSNField = () => {
      const { field_type, options: { pattern = '' } = {} } = this.props;
      let isSSN = false;
      if (field_type === MASKED) {
        isSSN = isEqual(pattern, REG_EXP_PATTERNS[SSN_TYPE]);
      }
      return isSSN;
    };

    onVisible = e => {
      this.setState(prevState => ({
        visible: !prevState.visible,
      }));
    };

    renderEncrypt = () => {
      const { canView, canEdit, visible } = this.state;
      const { isReviewer } = this.props;
      return (
        <div>
          {canView ? (
            <span className="ant-input-group-wrapper">
              <span className="ant-input-wrapper ant-input-group">
                {isReviewer && !visible ? (
                  <div className="reviewer-field text-field">*************</div>
                ) : (
                  <Component
                    type={visible ? '' : 'password'}
                    {...this.props}
                    disabled={!canEdit || this.props.disabled}
                  />
                )}
                <span className="ant-input-group-addon">
                  {canView ? (
                    <Icon
                      type={visible ? 'eye-invisible' : 'eye'}
                      onClick={this.onVisible}
                    />
                  ) : (
                    <></>
                  )}
                </span>
              </span>
            </span>
          ) : (
            '*************'
          )}
        </div>
      );
    };

    render() {
      return this.state.isEncryptedField ? (
        this.renderEncrypt()
      ) : (
        <Component {...this.props} {...this.state} />
      );
    }
  };
};

export default encryptedField;
