import { isPlainObject } from 'lodash';

import {
  ATTRIBUTE_TYPE_STRING,
  ATTRIBUTE_TYPE_TEXTAREA,
  ATTRIBUTE_TYPE_DATE,
  ATTRIBUTE_TYPE_DATETIME,
  ATTRIBUTE_TYPE_TIME,
  ATTRIBUTE_TYPE_NUMBER,
  ATTRIBUTE_TYPE_SWITCH,
} from './UserProfile.constants';
import {
  momentWithTZ,
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_TIME_FORMAT,
  BENCHMARK_DATE_TIME_FORMAT,
} from '../../../../utils/parse-date';

const getAattributeDefaultValue = (
  type,
  validValues,
  takenValues = [],
  timezone = 'UTF'
) => {
  let value;
  switch (type) {
    case ATTRIBUTE_TYPE_STRING:
      if (validValues) {
        value = isPlainObject(validValues[0])
          ? (validValues.find(v => !takenValues.includes(v.value)) || {}).value
          : validValues.find(v => !takenValues.includes(v));
      } else {
        value = '';
      }
      break;
    case ATTRIBUTE_TYPE_TEXTAREA:
      value = '';
      break;
    case ATTRIBUTE_TYPE_DATE:
      value = momentWithTZ(new Date(), timezone, BENCHMARK_DATE_FORMAT, false);
      break;
    case ATTRIBUTE_TYPE_DATETIME:
      value = momentWithTZ(
        new Date(),
        timezone,
        BENCHMARK_DATE_TIME_FORMAT,
        false
      );
      break;
    case ATTRIBUTE_TYPE_TIME:
      value = momentWithTZ(new Date(), timezone, BENCHMARK_TIME_FORMAT, false);
      break;
    case ATTRIBUTE_TYPE_NUMBER:
      value = 0;
      break;
    case ATTRIBUTE_TYPE_SWITCH:
      value = false;
      break;
    default:
      value = '';
  }
  return value;
};

export default getAattributeDefaultValue;
