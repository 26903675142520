import React, { Component } from 'react';
import waterfall from 'async/waterfall';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import cx from 'classnames';

import addScriptInWaterfall from './add-script-tag';

import './izenda-assets/izenda-ui.css';
import storage from '../../utils/storage';

const IZENDA_HOST = process.env.REACT_APP_IZENDA_API;

const CONFIG_JSON = {
  WebApiUrl: IZENDA_HOST,
  TimeOut: 3600,
  BaseUrl: '/',
  UIPreferences: {
    ReportFilterSectionExpanded: false,
  },
  Routes: {
    ReportDesigner: 'report-designer', // rename default path to match url
    Settings: 'admin-reporting',
    New: 'new',
    Dashboard: 'dashboard',
    Report: 'canned-reports',
    ReportViewer: 'reports/uof-report',
    ReportViewerPopup: 'reportviewerpopup',
    Viewer: 'viewer',
  },
  Integration: false, // remove #?souce=path from url
};

class EmbedReport extends Component {
  state = {
    addingScripts: true,
  };

  componentDidMount() {
    const { onReady } = this.props;
    const token = storage.get('token');

    waterfall(
      [
        addScriptInWaterfall('izenda-common', {
          src: '/izenda/izenda_common.js',
          type: 'text/javascript',
          async: null,
        }),
        addScriptInWaterfall('izenda-locales', {
          src: '/izenda/izenda_locales.js',
          type: 'text/javascript',
          async: null,
        }),
        addScriptInWaterfall('izenda-vendors', {
          src: '/izenda/izenda_vendors.js',
          type: 'text/javascript',
          async: null,
        }),
        addScriptInWaterfall('izenda-ui', {
          src: '/izenda/izenda_ui.js',
          type: 'text/javascript',
          async: null,
        }),
      ],
      err => {
        if (err) {
          // do nothing
        }
        this.setState({ addingScripts: false }, () => {
          global.IzendaSynergy.config(CONFIG_JSON);
          global.IzendaSynergy.setCurrentUserContext({ token });

          if (onReady && typeof onReady === 'function') {
            onReady(global.IzendaSynergy, this.el);
          }
        });
      }
    );
  }

  componentWillUnmount() {
    if (global.IzendaSynergy) {
      global.IzendaSynergy.unmountComponent(this.el);
    }
  }

  render() {
    const { className } = this.props;
    const { addingScripts } = this.state;

    return (
      <div
        className={cx('izenda-embed', className)}
        ref={el => {
          this.el = el;
        }}
      >
        {addingScripts && (
          <div className="izenda-header-placeholder">
            <ContentLoader
              className="svg-loading-animation"
              height={40}
              width={200}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="10" y="5" rx="5" ry="5" width="60" height="30" />
              <rect x="80" y="5" rx="5" ry="5" width="100" height="30" />
            </ContentLoader>
          </div>
        )}
      </div>
    );
  }
}

export default styled(EmbedReport)`
  &,
  .izenda-embed,
  .izenda-root {
    height: 100%;
  }

  .svg-loading-animation {
    width: 200px;
    max-width: 100%;
  }

  .izenda-header-placeholder {
    height: 40px;
    background-color: #fff;
  }

  .content-wrapper {
    max-width: none;
    max-width: inherit !important;
  }
`;
