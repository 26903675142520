import React from 'react';
import cx from 'classnames';

const Box = ({
  className,
  children,
  hasWhiteBackground,
  isWhite,
  isRounded,
}) => (
  <div
    className={cx(className, {
      'is-white': hasWhiteBackground || isWhite,
      'is-rounded': isRounded,
    })}
  >
    {children}
  </div>
);

export default Box;
