import {
  ATTRIBUTE_TYPE_BOOL,
  ATTRIBUTE_TYPE_BOOL_FALSE,
} from '../../UserProfile/UserProfile.constants';

const changeFieldValuesUpdate = ({ equipment, value, field }) => {
  const existingFieldIndex = equipment.findIndex(
    item => item.fieldId === field.id
  );

  if (!value || value === '') {
    return removeField({ equipment, existingFieldIndex, field });
  } else {
    return updateOrCreateField({ equipment, existingFieldIndex, value, field });
  }
};

const removeField = ({ equipment, existingFieldIndex, field }) => {
  const value =
    field.type === ATTRIBUTE_TYPE_BOOL ? ATTRIBUTE_TYPE_BOOL_FALSE : '';
  return updateField({ equipment, existingFieldIndex, value, field });
};

const updateField = ({ equipment, existingFieldIndex, value, field }) => {
  if (existingFieldIndex !== -1) {
    equipment[existingFieldIndex] = {
      ...equipment[existingFieldIndex],
      value,
      type: field.type,
    };
  }
  return equipment;
};

const updateOrCreateField = ({
  equipment,
  existingFieldIndex,
  value,
  field,
}) => {
  const updatedFieldValues =
    existingFieldIndex !== -1
      ? [...equipment]
      : [
          ...equipment,
          {
            fieldId: field.id,
            value,
            type: field.type,
            fieldValueId: field.fieldValueId,
          },
        ];
  return updateField({
    equipment: updatedFieldValues,
    existingFieldIndex,
    value,
    field,
  });
};
export default changeFieldValuesUpdate;
