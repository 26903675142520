import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button, Icon } from 'antd';
import DashboardPage from '../../components/dashboard';
import Tag from '../../components/common/tag';
import Dot from '../../components/common/dot';
import styled from 'styled-components';

// Modal
import withModal from '../../components/common/modal/base';
import showModal from '../../actions/show-modal';
import hideModal from '../../actions/hide-modal';
import createModal from '../../actions/create-modal';
import ModalTitle from '../../components/common/modal/title';

import {
  ModalButton,
  ModalSuccess,
  BodyTakeCourse,
  BodyAssignMentoring,
  BodyVerbalWarning,
} from '../../components/common/modal/partials/under-care';

import Panel, {
  PanelSection,
  PanelTitle,
  Divider,
} from '../../components/common/panel';

import Breadcrumb, {
  Item as BreadcrumbItem,
} from '../../components/common/breadcrumb';

class UnderCare extends withModal(Component) {
  UNSAFE_componentWillMount() {
    this.createModal();
  }

  componentWillUnmount() {
    this.hideModal();
    this.deleteModal();
  }

  state = {
    activeCourse: false,
    activeMentoring: false,
    activeWarning: false,
  };

  // Take Tactical Communication Course Modal
  onTakeCourse = values => {
    const { dispatch } = this.props;

    const onOk = () => {
      dispatch(hideModal('undercare-course'));
      dispatch(createModal(optionSuccess));
      dispatch(showModal(optionSuccess.id));
      this.setState({ activeCourse: true });
    };

    const onCancel = () => {
      dispatch(hideModal('undercare-course'));
    };

    const Footer = [
      <ModalButton key="cancel" label="Cancel" onClick={onCancel} />,
      <ModalButton key="submit" label="Submit" type="primary" onClick={onOk} />,
    ];

    const options = {
      id: 'undercare-course',
      title: (
        <ModalTitle>
          <h3>Take Tactical Communication Course</h3>
        </ModalTitle>
      ),
      children: BodyTakeCourse,
      footer: Footer,
      onOk: () => {
        dispatch(hideModal(this.id));
      },
    };

    const optionSuccess = {
      id: 'undercare-course-success',
      title: (
        <ModalTitle success>
          <Icon type="check-circle" /> <span>Success!</span>
        </ModalTitle>
      ),
      children: (
        <ModalSuccess text="Forwarded to Training Section Sergeant Jones for scheduling" />
      ),
    };

    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  // Assign Sergeant for oversight / mentoring
  onAssignMentoring = values => {
    const { dispatch } = this.props;

    const onOk = () => {
      dispatch(hideModal('undercare-mentoring'));
      dispatch(createModal(optionSuccess));
      dispatch(showModal(optionSuccess.id));
      this.setState({ activeMentoring: true });
    };

    const onCancel = () => {
      dispatch(hideModal('undercare-mentoring'));
    };

    const Footer = [
      <ModalButton key="cancel" label="Cancel" onClick={onCancel} />,
      <ModalButton key="submit" label="Submit" type="primary" onClick={onOk} />,
    ];

    const options = {
      id: 'undercare-mentoring',
      title: (
        <ModalTitle>
          <h3>Assign Sergeant for oversight / mentoring </h3>
        </ModalTitle>
      ),
      children: BodyAssignMentoring,
      footer: Footer,
      onOk: () => {
        dispatch(hideModal(this.id));
      },
    };

    const optionSuccess = {
      id: 'undercare-mentoring-success',
      title: (
        <ModalTitle success>
          <Icon type="check-circle" /> <span>Success!</span>
        </ModalTitle>
      ),
      children: (
        <ModalSuccess text="Sergeant notified to provide oversight and mentoring to Officer" />
      ),
    };

    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  // Complete documented Verbal Warning for missed court appearances
  onVerbalWarning = values => {
    const { dispatch } = this.props;

    const onOk = () => {
      dispatch(hideModal('undercare-warning'));
      dispatch(createModal(optionSuccess));
      dispatch(showModal(optionSuccess.id));
      this.setState({ activeWarning: true });
    };

    const onCancel = () => {
      dispatch(hideModal('undercare-warning'));
    };

    const Footer = [
      <ModalButton key="cancel" label="Cancel" onClick={onCancel} />,
      <ModalButton key="submit" label="Submit" type="primary" onClick={onOk} />,
    ];

    const options = {
      id: 'undercare-warning',
      title: (
        <ModalTitle>
          <h3>
            Complete documented Verbal Warning for missed court appearances
          </h3>
        </ModalTitle>
      ),
      children: BodyVerbalWarning,
      footer: Footer,
      onOk: () => {
        dispatch(hideModal(this.id));
      },
    };

    const optionSuccess = {
      id: 'undercare-warning-success',
      title: (
        <ModalTitle success>
          <Icon type="check-circle" /> <span>Success!</span>
        </ModalTitle>
      ),
      children: (
        <ModalSuccess text="Verbal Warning form sent to Sergeant’s inbox to complete" />
      ),
    };

    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  render() {
    return (
      <div>
        <Breadcrumb separator=">">
          <BreadcrumbItem>Home</BreadcrumbItem>
          <BreadcrumbItem>Under C.A.R.E.</BreadcrumbItem>
          <BreadcrumbItem>Officer Ross Evers</BreadcrumbItem>
        </Breadcrumb>
        <Panel transparent paddinglessHorizontal marginless>
          <PanelTitle>Officer Ross Evers</PanelTitle>
        </Panel>
        <StyledPanel>
          <PanelSection>
            <Row align="middle">
              <Col span={12}>
                <StyledTitleContainer>
                  <Dot
                    color={this.state.activeCourse ? '#F79671' : '#E74C3C'}
                  />
                  <StyledTitle>Take Tactical Communication Course</StyledTitle>
                </StyledTitleContainer>
              </Col>
              <Col span={12}>
                <Row type="flex" gutter={16} justify="end">
                  <Col span={24}>
                    <StyledButtonsContainer>
                      {this.state.activeCourse ? (
                        <Tag
                          size="default"
                          className="status-in-progress"
                          type="in-progress"
                          statusTag
                        >
                          In Progress
                        </Tag>
                      ) : (
                        <Button type="primary" onClick={this.onTakeCourse}>
                          Assign
                        </Button>
                      )}
                    </StyledButtonsContainer>
                  </Col>
                </Row>
              </Col>
            </Row>
          </PanelSection>
          {this.state.activeCourse ? (
            <div>
              <Divider />
              <PanelSection>
                <StyledTitle>
                  Forwarded to Training Section Sergeant Jones for scheduling
                </StyledTitle>
              </PanelSection>
            </div>
          ) : null}
        </StyledPanel>
        <StyledPanel>
          <PanelSection>
            <Row align="middle">
              <Col span={12}>
                <StyledTitleContainer>
                  <Dot
                    color={this.state.activeMentoring ? '#F79671' : '#E74C3C'}
                  />
                  <StyledTitle>
                    Assign Sergeant for oversight / mentoring
                  </StyledTitle>
                </StyledTitleContainer>
              </Col>
              <Col span={12}>
                <Row type="flex" gutter={16} justify="end">
                  <Col span={24}>
                    <StyledButtonsContainer>
                      {this.state.activeMentoring ? (
                        <Tag
                          size="default"
                          className="status-in-progress"
                          type="in-progress"
                          statusTag
                        >
                          In Progress
                        </Tag>
                      ) : (
                        <Button type="primary" onClick={this.onAssignMentoring}>
                          Assign
                        </Button>
                      )}
                    </StyledButtonsContainer>
                  </Col>
                </Row>
              </Col>
            </Row>
          </PanelSection>
          {this.state.activeMentoring ? (
            <div>
              <Divider />
              <PanelSection>
                <StyledTitle>
                  Sergeant notified to provide oversight and mentoring to
                  Officer
                </StyledTitle>
              </PanelSection>
            </div>
          ) : null}
        </StyledPanel>
        <StyledPanel>
          <PanelSection>
            <Row align="middle">
              <Col span={12}>
                <StyledTitleContainer>
                  <Dot
                    color={this.state.activeWarning ? '#F79671' : '#E74C3C'}
                  />
                  <StyledTitle>
                    Complete documented Verbal Warning for missed court
                    appearances
                  </StyledTitle>
                </StyledTitleContainer>
              </Col>
              <Col span={12}>
                <Row type="flex" gutter={16} justify="end">
                  <Col span={24}>
                    <StyledButtonsContainer>
                      {this.state.activeWarning ? (
                        <Tag
                          size="default"
                          className="status-in-progress"
                          type="in-progress"
                          statusTag
                        >
                          In Progress
                        </Tag>
                      ) : (
                        <Button type="primary" onClick={this.onVerbalWarning}>
                          Assign
                        </Button>
                      )}
                    </StyledButtonsContainer>
                  </Col>
                </Row>
              </Col>
            </Row>
          </PanelSection>
          {this.state.activeWarning ? (
            <div>
              <Divider />
              <PanelSection>
                <StyledTitle>
                  Verbal Warning form sent to Sergeant’s inbox to complete
                </StyledTitle>
              </PanelSection>
            </div>
          ) : null}
        </StyledPanel>
      </div>
    );
  }
}

const StyledPanel = styled(Panel)`
  padding: 0 16px 16px;
`;

const StyledTitleContainer = styled.div`
  position: relative;
  padding: 5px 0;

  .bdm-dot {
    position: absolute;
  }
`;

const StyledTitle = styled.h3`
  padding-left: 60px;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;

  .ant-tag {
    margin: 0;
  }
`;

const mapState = () => ({});

export default withRouter(DashboardPage(mapState, null)(UnderCare));
