const onItemsLengthReachZero = 'onItemsLengthReachZero';
const onTaskMarkedCompleted = 'onTaskMarkedCompleted';

export default (type, items, prevItems) => {
  if (type === onItemsLengthReachZero) {
    return items.length === 0;
  }
  if (type === onTaskMarkedCompleted) {
    return items.some((item, index) => {
      const currentCompleted = !!item.taskCompleted;
      const prevCompleted = !!prevItems[index].taskCompleted;

      return currentCompleted !== prevCompleted;
    });
  }

  return false;
};
