import styled from 'styled-components';

import { DIVIDER } from '../../../config/theme';

const gutter = 50;
const halfGutter = gutter / 2;

const Section = styled.div.attrs({
  className: 'bdm-form-section',
})`
  @media screen {
    margin: 0 0 10px;
    padding: 5px 0;
    border-bottom: 1px solid
      ${props => (props.isLast ? 'transparent' : DIVIDER)};

    &:last-child {
      border-bottom: none;
    }

    &.keep-border {
      &,
      &:last-child {
        border-bottom: 1px solid ${DIVIDER};
      }
    }

    &.bdm-form-section--no-padding-top {
      padding-top: 0;
    }

    &.bdm-form-section--no-padding-bottom {
      padding-bottom: 0;
    }

    &.bdm-form-section--no-margin-bottom {
      margin-bottom: 0;
    }

    &.bdm-form-section--no-padding-horizontal {
      padding-left: 0;
      padding-right: 0;
    }

    .padding-row-bottom {
      padding-bottom: 10px;
    }
  }

  &.compact-fields {
    .ant-form-item {
      margin-bottom: 15px;
    }
  }

  .ant-row {
    margin-left: -${halfGutter}px;
    margin-right: -${halfGutter}px;

    .section-title {
      display: inline;
    }

    @media (max-width: 480px) {
      .section-title {
        display: inline;
        line-heigh: 40px;
        heigh: 40px;
      }
    }

    @media screen and (max-width: 992px) {
      margin-left: -16px;
      margin-right: -16px;
    }

    @media screen and (max-width: 768px) {
      margin-left: -8px;
      margin-right: -8px;
    }

    > [class*='ant-col'] {
      padding: 0 ${halfGutter}px;

      @media screen and (max-width: 992px) {
        padding: 0 16px;
      }

      @media screen and (max-width: 768px) {
        padding: 0 8px;
      }

      &.ant-form-item-label {
        padding-right: 0;

        @media screen and (max-width: 768px) {
          transform: none;
          top: 0;
          padding-bottom: 18px;
        }
      }
    }

    .ant-col-xs-24 {
      @media screen and (max-width: 768px) {
        .ant-form-item-label {
          padding-bottom: 15px;
        }
      }
    }

    .ant-col-sm-24 {
      @media screen and (max-width: 992px) {
        .ant-form-item-label {
          padding-bottom: 10px;
        }
      }
    }
  }

  &.section-has-top-border {
    @media screen {
      border-top: 1px solid ${DIVIDER};
      padding-top: 10px;
    }
  }

  &.bdm-form-repeater-item,
  &.bdm-form-repeater-item:first-child,
  & {
    &.section-no-border {
      border-bottom: none;
      /* stylelint-disable */
      &.bdm-form-section,
      &.bdm-form-section {
        &:last-child {
          border-bottom: none;
        }

        &.keep-border {
          &,
          &:last-child {
            border-bottom: 1px solid ${DIVIDER};
          }
        }
      }

      .bdm-form-object {
        &:last-child {
          .bdm-form-section {
            &:last-child {
              border-bottom: none;

              &.keep-border {
                border-bottom: 1px solid ${DIVIDER};
              }
            }
          }
        }

        &.keep-border {
          &,
          &:last-child {
            border-bottom: 1px solid ${DIVIDER};
          }
        }
      }
    }
  }

  &.top-bordered {
    border-bottom: none;
    padding: 15px 15px;
    margin: 0 -15px;

    @media screen {
      border-top: 1px solid ${DIVIDER};
    }
  }

  &.has-top-border {
    padding-top: 15px;

    @media screen {
      border-top: 1px solid ${DIVIDER};
    }
  }

  &.no-fields-margin {
    .ant-form-item {
      @media screen and (min-width: 768px) {
        margin-bottom: 0;
      }
    }
  }
`;

export default Section;
