import FormComponent from '../../models/FormComponent';
import getColumnOptionsByColumnNumber from '../../utils/getColumnOptionsByColumnNumber';
import getBodyDiagramChildrenDefault from '../../utils/getBodyDiagramChildrenDefault';
import getBodyDiagramChildEnumDefault from '../../utils/getBodyDiagramChildEnumDefault';
import {
  SECTION_WRAPPER,
  SECTION,
  UPLOAD,
  SINGLE_UPLOAD,
  ROW,
  COLUMN,
  INJURIES_BODY,
} from 'APP_ROOT/constants/layoutComponentTypes';
import getComponentUploadChildren from './getComponentUploadChildren';

const getComponentChildren = (type, updateEnum, columns, parentId) => {
  // using 2 columns by default, comlumns param is used for ROW component
  const columnOptions = getColumnOptionsByColumnNumber(columns || 2);
  let fieldOptions;
  let fieldValidations;
  let children;
  let conditions;
  switch (type) {
    case SECTION:
      // Adding 3 only when constructor has 3
      const baseSections = [];
      for (let counter = 0; counter < columns; counter++) {
        baseSections.push(new FormComponent(COLUMN, columnOptions));
      }
      children = [new FormComponent(ROW, null, baseSections)];
      break;
    case SECTION_WRAPPER:
      children = [
        new FormComponent(SECTION, { columns: 2 }, [
          new FormComponent(ROW, null, [
            new FormComponent(COLUMN, columnOptions),
            new FormComponent(COLUMN, columnOptions),
          ]),
        ]),
      ];
      break;
    case ROW:
      children = Array.from(Array(columns), (_, idx) => idx).map(
        () => new FormComponent(COLUMN, getColumnOptionsByColumnNumber(columns))
      );
      break;
    case INJURIES_BODY: {
      const rand = Math.trunc(Math.random() * 1000);
      const postfix = String(rand).padStart(4, '0');
      children = getBodyDiagramChildrenDefault(postfix);
      children.forEach(child => {
        updateEnum(
          child.enumRef,
          undefined,
          getBodyDiagramChildEnumDefault(child.enumRef.slice(0, -4))
        );
      });
      break;
    }
    case UPLOAD:
    case SINGLE_UPLOAD:
      [
        children,
        fieldOptions,
        fieldValidations,
        conditions,
      ] = getComponentUploadChildren(type);
      break;
    default:
      break;
  }
  return [children, fieldOptions, fieldValidations, conditions];
};

export default getComponentChildren;
