import React, { useCallback, useEffect, useState } from 'react';
import deleteUserEquipment from '../../../agency-equipment/actions/delete-user-equipment';

const SUCCESS_MESSAGE = 'Equipment successfully deleted.';
const ERROR_MESSAGE = 'Error deleting equipment.';

const useEquipmentDelete = ({
  tenantId,
  equipmentRecord,
  onDelete,
  dispatch,
}) => {
  const { equipment: { id } = {} } = equipmentRecord;

  const onDeleteEquipment = () => {
    dispatch(
      deleteUserEquipment({
        tenantId: tenantId,
        equipmentId: id,
        next: callback,
      })
    );
  };

  const callback = useCallback(
    (error, data) =>
      onDelete({ success: SUCCESS_MESSAGE, error: ERROR_MESSAGE }, error, data),
    [onDelete]
  );

  return { onDeleteEquipment };
};

export default useEquipmentDelete;
