import { has } from 'lodash';
import { phonePatternRule } from '../../../../../utils/form';

const validatorDescriptor = registeredFields => {
  return {
    firstName: [
      { required: true, type: 'string', message: 'First Name is required' },
    ],
    lastName: [
      { required: true, type: 'string', message: 'Last Name is required' },
    ],
    email: [
      {
        required: has(registeredFields, 'email'),
        message: 'Email is required',
      },
      {
        type: 'email',
        message: 'Email is invalid',
      },
    ],
    rank: [
      {
        required: has(registeredFields, 'rank'),
        message: 'Official Title is required',
      },
    ],
    homePhone: [phonePatternRule],
    mobilePhone: [phonePatternRule],
    emergencyHomePhone: [phonePatternRule],
    emergencyMobilePhone: [phonePatternRule],
  };
};

export default validatorDescriptor;
