import styled from 'styled-components';

import { DIVIDER } from 'APP_ROOT/config/theme';

const RepeaterWrapper = styled.div`
  position: relative;

  .repeater-item {
    position: relative;
  }

  .repeater-delete {
    position: absolute;
    right: 0;
    top: 1em;
    border: none;
    color: ${DIVIDER};
    z-index: 2;
  }
`;

export default RepeaterWrapper;
