import React from 'react';
import { Icon } from 'antd';

const FileSizeLimit = ({ limit = '5 GB' }) => {
  return (
    <p className="info">
      <Icon type="info-circle" />{' '}
      <em>
        File Size Limit is <strong>{limit}</strong>
      </em>
    </p>
  );
};

export default FileSizeLimit;
