import React from 'react';
import { Row, Col, Checkbox, Button, Select } from 'antd';
import styled from 'styled-components';
import ModalBody from '../../modal/body';

const Option = Select.Option;

const CustomCheckbox = styled(Checkbox)`
  padding: 10px 0;
`;

const ModalButton = ({ label, onClick, ...props }) => (
  <Button onClick={onClick} size="default" {...props}>
    {label}
  </Button>
);

const ModalSuccess = props => (
  <ModalBody>
    <div className="inner-body">
      <p>{props.text}</p>
    </div>
  </ModalBody>
);

const BodyTakeCourse = (
  <ModalBody>
    <div className="inner-body">
      <CustomCheckbox>Schedule Tactical Communication Course</CustomCheckbox>
    </div>
  </ModalBody>
);

const BodyAssignMentoring = (
  <ModalBody>
    <div className="inner-body">
      <Row>
        <Col span={8}>Select Sergeant</Col>
        <Col span={16}>
          <Select style={{ width: '100%' }} placeholder="Please select">
            <Option value="steven">Steven Winters</Option>
            <Option value="robert">Robert Smalls </Option>
            <Option value="melissa">Melissa Shessler</Option>
            <Option value="jordan">Jordan Ellis</Option>
          </Select>
        </Col>
      </Row>
    </div>
  </ModalBody>
);
const BodyVerbalWarning = (
  <ModalBody>
    <div className="inner-body">
      <CustomCheckbox>
        Send Verbal Warning form to Sergeant’s inbox
      </CustomCheckbox>
    </div>
  </ModalBody>
);

export {
  ModalButton,
  ModalSuccess,
  BodyTakeCourse,
  BodyAssignMentoring,
  BodyVerbalWarning,
};
