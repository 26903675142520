import styled from 'styled-components';

import { GRAY_2 } from '../../../../config/theme';

export const PanelRow = styled.div.attrs({
  className: 'bdm-panel-row',
})`
  font-size: 12px;
  padding: 0;
  line-height: 28px;
  margin-bottom: 8px;

  &.row-item-is-stale {
    line-height: inherit;
  }

  &.marginless {
    margin: 0;
  }

  .meta {
    color: ${GRAY_2};
    text-decoration: none;

    a {
      text-decoration: underline;
    }

    .meta-content {
      display: inline-block;
    }
  }

  .stroked {
    text-decoration: underline;
  }

  .meta-updated {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  .meta-icon {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
  }

  .data {
    color: #000;
  }
`;

export default PanelRow;
