import { get, some, every } from 'lodash';

import { expectations } from './constants';
import assertedCondition from './assertedCondition';

const findInSource = (collection = {}, { toBe, expectations, expect, key }) => {
  const found = Object.keys(collection).reduce((result, id) => {
    const item = get(collection, id, {});

    const asserts = assertedCondition({
      toBe,
      expectations,
      dataInKey: get(item, key, null),
      expect,
    });

    if (asserts) {
      return item;
    }

    return result;
  }, null);

  return found;
};

/*
   function to determine if a element should be shown
   used in conditional component
 */
const validateConditions = (
  conditions = {},
  data = {},
  wholeData = {},
  parentIndex = 0
) => {
  const { rules = [], every: everyCondition = false, source = '' } = conditions;
  const sourceData = get(wholeData, [source, parentIndex], {});
  const fieldsData = {
    ...(data ? data : {}),
    isHidden: false,
    data: wholeData,
    sourceData: sourceData,
  };

  const assertMethod = everyCondition ? every : some; // shortcircuit evaluation of conditions: every=and, some=or

  if (rules.length === 0) {
    return true;
  }

  const asserts = rules.map(
    ({
      key = '',
      expect = '',
      expectFrom = '',
      toBe = '',
      fromSource = '',
    }) => {
      const dataInKey = get(fieldsData, key, '');

      if (fromSource) {
        const {
          source = '',
          key: keyInSource = '',
          expect: expectInSource = '',
          expectFrom: expectFromInSource = '',
          toBe: toBeInSource = '',
        } = fromSource;

        const sources = Array.isArray(source) ? source : [source];

        const item = sources.reduce((result, sourceName) => {
          const sourceData = get(wholeData, sourceName, {});
          const transformedData = Array.isArray(sourceData)
            ? sourceData.reduce(
                (res, item, index) => ({
                  ...res,
                  [item[keyInSource] || index]: item,
                }),
                {}
              )
            : sourceData;

          const expectedFromValue =
            expectInSource !== undefined && expectInSource !== ''
              ? expectInSource
              : get(data, expectFromInSource, '');
          const found = findInSource(transformedData, {
            toBe: toBeInSource,
            expectations,
            expect: expectedFromValue,
            key: keyInSource || 'id',
          });
          const reduced = found ? found : result;

          return reduced;
        }, null);

        if (item) {
          const expectFromValue = expectFrom
            ? get(fieldsData, expectFrom, '')
            : expect;

          const itemData = get(item, key, '');

          return assertedCondition({
            toBe,
            expectations,
            dataInKey: itemData,
            expect: expectFromValue,
          });
        }

        return false;
      }

      const expectFromValue = expect
        ? expect
        : get(fieldsData, expectFrom, expect);

      return assertedCondition({
        toBe,
        expectations,
        dataInKey,
        expect: expectFromValue,
      });
    }
  );

  return assertMethod(asserts, Boolean);
};

export default validateConditions;
