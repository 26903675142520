import React from 'react';
import Section from '../../../../../components/form-viewer/styled/section';
import StyledModal from './UserEquipmentModal.styled';

import useCategoriesData from './useCategoriesData';
import useFieldValuesData from './useFieldValueData';
import useSelectedCategoryData from './useSelectedCategoryData';
import useUserEquipment from './useUserEquipment';
import useEquipmentDelete from './useEquipmentDelete';

import './UserEquipmentField.css';
import mapDefaultFieldValues from './utils/mapDefaultFieldValues';
import mapEquipmentFieldValues from './utils/mapEquipmentFieldValues';
import useEquipmentDefault from './useEquipmentDefault';
import UserEquipmentModalCategoryInput from './UserEquipmentModalCategoryInput';
import UserEquipmentModalFields from './UserEquipmentModalFields';
import UserEquipmentModalFooter from './UserEquipmentModalFooter';

const UserEquipmentModal = ({
  tenantId,
  userId,
  visible,
  onCancel = () => {},
  onSave = (message, error, data) => {},
  onDelete = (message, error, data) => {},
  configs,
  dispatch,
  equipmentRecord = {},
  isEditable,
}) => {
  if (!visible) return <></>;

  const { loading, categories } = useCategoriesData({
    tenantId,
    equipmentRecord,
    dispatch,
  });

  const { selectedCategory, onChangeSelected } = useSelectedCategoryData({
    loading,
    categories,
    equipmentRecord,
  });

  const { defaultedModalFields } = mapDefaultFieldValues({
    selectedCategory,
    categories,
  });

  const equipmentModalFields = mapEquipmentFieldValues({
    defaultedModalFields,
    equipmentRecord,
  });

  const { equipmentForm } = useEquipmentDefault({
    loading,
    selectedCategory,
    equipmentModalFields,
  });

  const { onChangeFieldValues, equipment } = useFieldValuesData({
    equipmentRecord,
    equipmentForm,
  });

  const {
    onSaveEquipment,
    isValid,
    isProcessing,
    validationMessages,
  } = useUserEquipment({
    equipmentModalFields,
    tenantId,
    userId,
    selectedCategory,
    equipment,
    onSave,
    dispatch,
    equipmentRecord,
  });

  const { onDeleteEquipment } = useEquipmentDelete({
    tenantId,
    equipmentRecord,
    onDelete,
    dispatch,
  });

  return (
    <StyledModal
      title={'Equipment Details'}
      visible={visible}
      width={1000}
      className="user-equipment-modal"
      onCancel={onCancel}
      centered
      footer={
        <UserEquipmentModalFooter
          configs={configs}
          equipmentRecord={equipmentRecord}
          loading={loading || isProcessing}
          onCancel={onCancel}
          onSaveEquipment={onSaveEquipment}
          onDeleteEquipment={onDeleteEquipment}
        />
      }
    >
      <Section>
        <UserEquipmentModalCategoryInput
          selectedCategory={selectedCategory}
          categories={categories}
          equipmentRecord={equipmentRecord}
          loading={loading}
          onChangeSelected={onChangeSelected}
        />
        <UserEquipmentModalFields
          equipmentModalFields={equipmentModalFields}
          onChangeFieldValues={onChangeFieldValues}
          isValid={isValid}
          validationMessages={validationMessages}
          isDisabled={!isEditable}
        />
      </Section>
    </StyledModal>
  );
};

export default UserEquipmentModal;
