import React, { Component } from 'react';
import { Form, Input, Button, message } from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';

import ContentBlock from './content-block';

import route from '../../utils/get-route';
import { updateUserPassword } from '../../api/session';

const FormItem = Form.Item;

class ChangePassword extends Component {
  state = {
    confirmDirty: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      history: { push },
    } = this.props;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        updateUserPassword(values)
          .then(response => {
            this.props.form.resetFields();
            push(route('login'));
            message.success('Your password has been updated!');
          })
          .catch(error => {
            message.error(
              'There is an error with the request. Please check your info again'
            );
          });
      }
    });
    return false;
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  checkPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('The two passwords have to match');
    } else {
      callback();
    }
  };

  render() {
    const { accessToken, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ContentBlock
        title="Reset Password"
        content="Please enter your new password"
      >
        <Form onSubmit={this.handleSubmit} hideRequiredMark>
          <FormItem colon={false}>
            {getFieldDecorator('accessToken', { initialValue: accessToken })(
              <Input type="hidden" />
            )}
          </FormItem>
          <FormItemStyled colon={false} hasFeedback>
            {getFieldDecorator('newPassword', {
              rules: [
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  min: 8,
                  message: 'Minimum length of eight characters',
                },
              ],
            })(<Input placeholder="New Password" type="password" />)}
          </FormItemStyled>
          <FormItemStyled colon={false} hasFeedback>
            {getFieldDecorator('confirmPassword', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                {
                  validator: this.checkPassword,
                },
              ],
            })(
              <Input
                placeholder="Confirm Password"
                type="password"
                onBlur={this.handleConfirmBlur}
              />
            )}
          </FormItemStyled>

          <FormItem>
            <Button type="primary" htmlType="submit">
              Save changes
            </Button>
          </FormItem>
        </Form>
      </ContentBlock>
    );
  }
}

const FormItemStyled = styled(FormItem)`
  .ant-form-item-label {
    text-align: left;
  }
`;

const WrappedChangePassword = Form.create()(ChangePassword);

const mapState = state => ({
  session: state.session,
});

export default connect(mapState, null)(WrappedChangePassword);
