import CommentEndpoints from '../../../../api/organization/commentEndpoints';

export default (ouId, commentId) => {
  return new Promise((resolve, reject) => {
    CommentEndpoints.deleteOrganizationalUnitComment(ouId, commentId)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        return reject(error.message);
      });
  });
};
