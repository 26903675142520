import { camelCase } from 'lodash';

import ConfigureTemplates from './ConfigureTemplates';
import TemplateEditor from './TemplateEditor';
import { PERMISSIONS } from '../../../utils/admin';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/configure-templates/:templateId`,
    name: camelCase(`${name}-template`),
    component: TemplateEditor,
    protectedWith: [PERMISSIONS.manageAgencies],
    childRoutes: [],
  },
  {
    path: `${path}/configure-templates`,
    name: camelCase(`${name}-templates`),
    component: ConfigureTemplates,
    protectedWith: [PERMISSIONS.manageAgencies],
    childRoutes: [],
  },
];
