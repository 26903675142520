import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import InsertEmbed from '../../components/izenda-embed/InsertEmbed';
import withFlexibleContainer from '../../components/izenda-embed/WithFlexibleLayout';

class EmbediZendaReportPartViewer extends Component {
  static getPageConfig() {
    return {
      guestAccess: true,
    };
  }

  componentWillUnmount() {
    if (this.izenda) {
      this.izenda.destroy();
    }
  }

  onReady = (izenda, target) => {
    const { match } = this.props;
    const reportPartId = get(match, 'params.reportPartId');

    this.izenda = izenda.renderReportPart(target, {
      id: reportPartId,
      useQueryParam: true,
      useHash: false,
    });
  };

  render() {
    return <InsertEmbed onReady={this.onReady} />;
  }
}

const mapState = () => ({});

export default connect(
  mapState,
  null
)(withFlexibleContainer(EmbediZendaReportPartViewer));
