import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import isMobile from 'ismobilejs';

import GroupedChartItem from './styled/grouped-chart';
import StackedChartPopover from '../stacked-chart/styled/stacked-chart-popover';

export default ({ item, maxValue = 0, label = '' }) => {
  const values = Object.values(item);
  const slice = 100 / maxValue;
  const scale = 5;

  const trigger = isMobile.phone || isMobile.tablet ? 'click' : 'hover';

  return (
    <div style={{ width: '100%' }}>
      {values &&
        values.map((value, i) => {
          const sortedValues = _.sortBy(value).reverse();
          return (
            <GroupedChartItem key={i}>
              {sortedValues &&
                sortedValues.map((value, index) => (
                  <StackedChartPopover
                    key={index}
                    content={index === 0 ? `${value} ${label}` : `${value} DUI`}
                    trigger={trigger}
                  >
                    <StyledItem
                      index={i}
                      value={value}
                      height={value * slice * scale}
                    />
                  </StackedChartPopover>
                ))}
            </GroupedChartItem>
          );
        })}
    </div>
  );
};

const colors = ['#000000', '#FF7070', '#5E8DFF'];

const StyledItem = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 35px;
  height: ${props => (props.height ? props.height + 'px' : '20px')};
  background-color: ${props => colors[props.index]};
  border-radius: 2px;
  opacity: 0.8;
  transition: height 0.2s;

  &::after {
    position: absolute;
    content: "${props => (props.height > 18 ? props.value : '')}";
    font-size: 12px;
    top: 0;
    left: 0;
    right: 0;
  }
`;
