import {
  START_SYSTEM_UPDATE_REQUEST,
  START_SYSTEM_UPDATE_SUCCESS,
  START_SYSTEM_UPDATE_ERROR,
} from './';
import get from 'lodash/get';

import { startSystemUpdate } from '../api/system-updates';

export default (next = () => {}) => async (dispatch, getState) => {
  const { systemUpdates: { lookingUp, updating } = {} } = getState();

  const callback = (...args) => {
    next && typeof next === 'function' && next(...args);
  };

  if (!lookingUp && !updating) {
    dispatch({ type: START_SYSTEM_UPDATE_REQUEST });

    try {
      const payload = await startSystemUpdate();

      dispatch({ type: START_SYSTEM_UPDATE_SUCCESS, payload });
      callback();
    } catch (err) {
      dispatch({
        type: START_SYSTEM_UPDATE_ERROR,
        payload: get(err, 'response.status'),
      });
      callback(err);
    }
  }
};
