import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';
import cx from 'classnames';
import actionsLoader from './actionsLoader';
import PageHeaderWrapper from './PageHeader.styled';

const PageHeader = ({
  title = '',
  className = '',
  goBackTo = '/',
  goBackToKmi = '/',
  showTitle = true,
  showBackButton = true,
  showBackToKmiButton = false,
  actions = [],
  children = [],
  alert = null,
  saveRef,
  stickToTop = false,
  sidebarIsCollapsed = false,
  isLoading,
  onClick = () => {},
}) => {
  return (
    <PageHeaderWrapper
      innerRef={saveRef}
      className={cx(className, {
        'is-fixed': stickToTop,
        'sidebar-is-collapsed': sidebarIsCollapsed,
      })}
    >
      <div className="page-header-content">
        <Row
          type="flex"
          justify="space-between"
          align="top"
          className="header-info"
        >
          <Col className="header-title">
            {showBackButton &&
              (isLoading ? (
                <Icon type="loading" className="header-title__back" />
              ) : (
                <Link to={goBackTo} className="back-button" onClick={onClick}>
                  <Icon type={isLoading ? 'loading' : 'left'} />
                </Link>
              ))}
            {!showBackButton && showBackToKmiButton && (
              <a href={goBackToKmi} className="back-button" onClick={onClick}>
                <Icon type={isLoading ? 'loading' : 'left'} />
              </a>
            )}
            {showTitle && <h1 className="header-title__text">{title}</h1>}
          </Col>
          <Col className="header-actions">
            {isLoading
              ? React.createElement(actionsLoader, { length: actions.length })
              : actions}
          </Col>
        </Row>
        <Row type="flex" justify="center" className="header-content">
          {children}
        </Row>
      </div>
      {alert && <div className="page-header-alert">{alert}</div>}
    </PageHeaderWrapper>
  );
};

export default PageHeader;
