import { get, first } from 'lodash';
import seedRepeaters from './seed-repeaters';

export default (fields = [], parent = '', seedKeys = {}) => (
  dispatch,
  getState
) => {
  const {
    form: { selectedForm = {} },
  } = getState();
  const { data: currentFormData = {} } = selectedForm;

  const seedIds = fields.map(field => field.id);
  const firstSeedId = first(seedIds);

  const seedNotExist = Object.keys(seedKeys).reduce((result, key) => {
    const fisrtChildData = first(get(currentFormData, [key], []));
    const notExist = get(fisrtChildData, seedKeys[key]) === undefined;
    return result && notExist;
  }, true);

  if (seedNotExist) {
    dispatch(seedRepeaters(parent, firstSeedId, seedKeys, seedNotExist));
  }
};
