import { getAgencyId } from '../../selectors/session';
import { getParticipantsId } from '../../modules/ReportSharing/ReportSharing.selector';

export const isAgencyOwner = ({ state, agencyId }) => {
  const currentUserAgencyId = getAgencyId(state);

  return Number(currentUserAgencyId) === Number(agencyId);
};

export const isParticipant = ({ state, userId }) => {
  const participantsId = getParticipantsId(state);
  return participantsId.includes(Number(userId));
};
