import React, { PureComponent } from 'react';

import FormViewer from './styled/form-viewer';

import MainForm from './forms/main-form';
import ReviewForm from './forms/review-form';
import If from '../utils/ConditionalRender';

class FormViewerWrapper extends PureComponent {
  saveScrollToComponent = ref => {
    this.scrollToComponent = ref;
  };

  render() {
    const { showReviewPanel = true, ...props } = this.props;

    return (
      <div className="print-frame">
        <table width="100%">
          <thead>
            <tr>
              <td>
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <FormViewer>
                  <MainForm scrollTo={this.scrollToComponent} {...props} />
                  <If condition={showReviewPanel}>
                    <ReviewForm
                      saveScrollToComponent={this.saveScrollToComponent}
                      {...props}
                    />
                  </If>
                </FormViewer>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

export default FormViewerWrapper;
