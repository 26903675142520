import styled from 'styled-components';
import { Layout } from 'antd';

const ContentWrapper = styled(Layout)`
  &.ant-layout {
    background-color: #e8edf1;
  }
`;

export default ContentWrapper;
