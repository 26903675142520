import { get, first } from 'lodash';

export const transformPropertyChanges = changes =>
  Object.entries(changes).map(([screenComponentId, { data }]) => ({
    screenComponentId,
    data,
  }));

export const transformVisibilityChanges = changes => {
  return Object.keys(changes).reduce(
    (result, item) => {
      const { id, data: { turnedOn } = {} } = get(changes, item, {});
      if (turnedOn) {
        return {
          ...result,
          enable: [...result.enable, id],
        };
      } else {
        return {
          ...result,
          disable: [...result.disable, id],
        };
      }
    },
    {
      enable: [],
      disable: [],
    }
  );
};

export const transformValidationChanges = (agencyId, changes) => {
  return Object.entries(changes).map(
    ([
      screenComponentId,
      {
        data,
        meta: { validations },
      },
    ]) => {
      const { required } = data;
      const { id, rules } = validations;

      if (!required) {
        return {
          screenComponentId: id,
          data: {
            agencyId,
            helper: 'presence',
            option: 'presence',
            metadata: {
              required: false,
              optional: true,
              keepInData: {
                key: 'submitterId',
                expect: '',
                toBe: 'neq',
              },
            },
            value: {},
            conditions: [],
          },
        };
      } else {
        const { message = 'This field is required', conditions = [] } = first(
          rules
        );
        return {
          screenComponentId: id,
          data: {
            agencyId,
            helper: 'presence',
            option: 'presence',
            message,
            metadata: {
              optional: false,
              keepInData: null,
            },
            value: { content: true },
            conditions,
          },
        };
      }
    }
  );
};

export function mapToTemplateEnumBy(category) {
  const lowerCaseCategory = category ? category.toLowerCase() : undefined;
  switch (lowerCaseCategory) {
    case 'review note':
    case 'review notes':
    case 'note':
      return 'NOTE';
    default:
      return 'FORM';
  }
}
