import React from 'react';
import { has } from 'lodash';

import showToastMessage from '../../../utils/showToastMessage';

import { getPropagationActions } from '../../../../../api/agencies.js';
import { Form, Input, Col, Row, Select } from 'antd';
import { withRouter } from 'react-router-dom';

import { toCapitalizedWords } from '../../../../../modules/FormBuilder/utils/general-utilities';

const { Option } = Select;
const { Item } = Form;

class PropagateAction extends React.Component {
  state = { actions: [], isloading: true };

  componentDidMount() {
    this.fetchAction();
  }

  render() {
    return (
      <Row gutter={16}>
        <Col span={24}>{this.drawPropagateActionsSection()}</Col>
      </Row>
    );
  }

  drawPropagateActionsSection() {
    if (this.state.isloading) {
      return this.drawInputLoader(
        'Loading actions...',
        'Loading propagateActions...'
      );
    }
    return this.drawActionSelect(this.state.actions);
  }

  drawActionSelect(actions) {
    return this.drawMultiSelect(
      actions,
      this.props.itemKey,
      'Please select actions',
      this.handleSelectPropagateActionsChange,
      this.props.propagateActions
    );
  }

  drawMultiSelect(list, title, placeholder, operation, startingValues = []) {
    const { getFieldDecorator } = this.props;
    const fieldSettings = {
      initialValue: startingValues,
      rules: [],
    };
    const options = this.matchActionToOptionFrom(list);
    return (
      <Item colon={false} label={toCapitalizedWords(title)}>
        {' '}
        {getFieldDecorator(
          title,
          fieldSettings
        )(
          <Select
            mode="multiple"
            placeholder={placeholder}
            onChange={operation}
          >
            {options}
          </Select>
        )}
      </Item>
    );
  }

  drawInputLoader(
    label = 'Loading...',
    help = 'Content is loading...',
    placeholder = "I'm loading the content"
  ) {
    return (
      <Item
        colon={false}
        label={label}
        hasFeedback
        validateStatus="validating"
        help={help}
      >
        <Input placeholder={placeholder} />
      </Item>
    );
  }

  matchActionToOptionFrom(list) {
    return list
      .filter(element => has(element, 'label') && has(element, 'value'))
      .map(element => (
        <Option key={list.indexOf(element)} value={element.value}>
          {element.label}
        </Option>
      ));
  }

  async fetchAction() {
    const {
      match: {
        params: { agencyId, id },
      },
    } = this.props;
    try {
      const actions = await getPropagationActions(agencyId, id);
      this.setState({ actions, isloading: false });
    } catch (error) {
      showToastMessage(
        'error',
        `Error, something went wrong because: ${error.message}`,
        5
      );
      this.setState({ isloading: false });
    }
  }
}

// "withRouter" enables the component to access URL variables, location and history.
export default withRouter(PropagateAction);
