import { genOptions } from '../utils/request';
import urlBuilder from '../utils/url-builder';
import storage from '../utils/storage';

/**
 * This class is intended to be used as a base to build clients for the
 * benchmark services.
 */
class ServiceClient {
  constructor(uri, apiPrefix = '') {
    this.uri = uri;
    this.apiPrefix = apiPrefix;
  }

  endpointDefinition(path, method, options = {}) {
    return { path, method, ...options };
  }

  /**
   * Some services define suffix string, which may not be present in all
   * requests use this method to add it.
   */
  withApiPrefix(path) {
    return this.apiPrefix + path;
  }

  /**
   * Builds the fetch request parameters, prevents you from writing the entire
   * URL for a resource or writing all fetch options.
   */
  buildFetchParams(
    action,
    params = {},
    queryParams = {},
    body = null,
    authorization = `bearer ${storage.get('token')}`,
    extraHeaders = {}
  ) {
    // lets create the URI
    let URI = `${this.uri}/${action.path}`;

    const url = urlBuilder(URI, params, queryParams);

    const options = genOptions(
      action.method,
      body,
      authorization,
      extraHeaders
    );

    // TODO: remove this line, this is a patch for now since we are not using credentials yet
    delete options.credentials;

    const fetchParams = {
      URI: url,
      request: {
        ...options,
      },
    };

    return fetchParams;
  }
}

export default ServiceClient;
