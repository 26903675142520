import { some } from 'lodash';

const normalizeConditionValue = value => {
  if (value === '' || value === null || typeof value === 'undefined') return '';
  else return value;
};

/*
   Function to evaluate a single condition used when showing/hiding conditional components
 */
const assertedCondition = ({
  toBe = '',
  expectations = {},
  dataInKey = '',
  expect = '',
}) => {
  const data = dataInKey === undefined ? '' : dataInKey;

  switch (toBe) {
    case expectations.neq:
      return normalizeConditionValue(data) !== normalizeConditionValue(expect);
    case expectations.lt:
      return data < expect;
    case expectations.lte:
      return data <= expect;
    case expectations.gt:
      return data > expect;
    case expectations.gte:
      return data >= expect;
    case expectations.ninq:
    case expectations.inq: //why q?
      //checks if any value from a set of expected values exists(or not) in the source data field
      const dataArray = Array.isArray(data) ? data : [data];
      const expectArray = Array.isArray(expect) ? expect : [expect];
      const includesMapped = expectArray.map(item => dataArray.includes(item));
      const includes = some(includesMapped, Boolean);

      if (toBe === expectations.ninq) {
        return !includes;
      }

      return includes;
    default:
      return (
        normalizeConditionValue(dataInKey) === normalizeConditionValue(expect)
      );
  }
};

export default assertedCondition;
