import { get, omit } from 'lodash';
import FieldCheckbox from '../../components/FieldCheckbox';

export default class fullWidthOption {
  constructor() {
    this.name = 'wrapperCol';
    this.label = 'Full width';
    this.component = FieldCheckbox;
    this.defaultValue = false;
  }

  onChange = (value, values) => {
    const { title, showTitle } = values;
    let newTitle;
    let newShowTitle;
    if (value) {
      newTitle = {
        ...title,
        value: '',
      };
      newShowTitle = {
        ...showTitle,
        value: false,
      };
    } else {
      newTitle = title;
      newShowTitle = showTitle;
    }

    const changed = {
      ...values,
      [this.name]: value,
      title: newTitle,
      showTitle: newShowTitle,
    };

    return changed;
  };

  onSave = values => {
    const wrapperCol = get(values, this.name);
    const { options } = values;

    return {
      ...omit(values, this.name),
      options: {
        ...options,
        [this.name]: wrapperCol ? { md: 24, sm: 24, xs: 24 } : undefined,
      },
    };
  };

  getInitialValue = values => {
    const { options } = values;
    if (options === undefined) {
      return this.defaultValue;
    }
    const wrapperCol = get(options, this.name);

    return wrapperCol ? true : this.defaultValue;
  };
}
