import { createReducer } from 'redux-create-reducer';
import _ from 'lodash';

import {
  CREATE_MODAL,
  SHOW_MODAL,
  DELETE_MODAL,
  HIDE_MODAL,
  UPDATE_MODAL,
  HIDE_ALL_MODALS,
} from '../actions';

const modalOptions = id => ({
  visible: false,
  title: '',
  children: null,
  closable: true,
  id,
});

const initialState = {};

const events = {
  [CREATE_MODAL]: (state, { payload: { id, ...options } }) => {
    return {
      ...state,
      [id]: {
        ..._.get(state, [id]),
        ...modalOptions(id),
        ...options,
        visible: false,
      },
    };
  },
  [SHOW_MODAL]: (state, { payload: id }) => {
    return {
      ...state,
      [id]: {
        ..._.get(state, [id]),
        visible: true,
      },
    };
  },
  [UPDATE_MODAL]: (state, { payload: { id, ...options } }) => {
    return {
      ...state,
      [id]: {
        ..._.get(state, [id]),
        ...options,
      },
    };
  },
  [HIDE_MODAL]: (state, { payload: id }) => {
    return {
      ...state,
      [id]: {
        ..._.get(state, [id]),
        visible: false,
      },
    };
  },
  [DELETE_MODAL]: (state, { payload: id }) => {
    const currentModals = {
      ...state,
    };

    const modals = _.keys(currentModals).reduce((result, modalId, index) => {
      if (modalId !== id) {
        return {
          ...result,
          [modalId]: currentModals[modalId],
        };
      }

      return result;
    }, {});

    return modals;
  },
  [HIDE_ALL_MODALS]: () => initialState,
};

export default createReducer(initialState, events);
