export const filterByIntegrationIds = ids => ({
  where: { integrationId: { inq: ids } },
  include: [
    {
      relation: 'rank',
      scope: {
        fields: { name: true, abbreviation: true, id: true },
      },
    },
  ],
});
