import React, { Component } from 'react';

import { Row, Col, Select, Input, Alert, Divider } from 'antd';

import RowWrapper from '../OptionsModalBody/OptionsModalBody.styled';
import componentsManager from '../../services/componentsManager';
import { REPEATER } from 'APP_ROOT/constants/layoutComponentTypes';
import getAllFieldsFlat from '../../utils/getAllFieldsFlat';

import getFieldLabel from '../../utils/getFieldLabel';

export default class UpdatePopulateFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      messageType: 'error',
      message: '',
    };
  }

  render() {
    const { options, message, messageType } = this.state;
    const rows = this.getOptionsRows(options);
    return (
      <RowWrapper className="select-values">
        <Row>
          <span className="title">Populate from</span>
        </Row>
        <Row className="scrollable">{rows}</Row>
        <Row>
          {message && (
            <Alert message={message} type={messageType} showIcon closable />
          )}
        </Row>
      </RowWrapper>
    );
  }

  updateOptions = newOptions => {
    const { updateParent } = this.props;
    this.setState({ options: newOptions, message: '' });
    updateParent && updateParent({ populateFrom: newOptions });
  };

  onBlur = (dataKey, index) => e => {
    const { updateParent } = this.props;
    const { options } = this.state;
    const {
      target: { value },
    } = e;
    const data = options[dataKey];
    let newData;
    if (index !== undefined) {
      newData = data.map((d, i) => {
        if (i === index) {
          return {
            ...d,
            prefix: value,
          };
        }
        return d;
      });
    } else {
      newData = { ...data, prefix: value };
    }
    const newOptions = { ...options, [dataKey]: newData };

    this.setState({ options: newOptions });
    updateParent && updateParent({ populateFrom: newOptions });
  };

  onChange = (dataKey, index) => value => {
    const { updateParent } = this.props;
    const { options } = this.state;
    const data = options[dataKey];
    let newData;
    if (index !== undefined) {
      newData = data.map((d, i) => {
        if (i === index) {
          const { map, ...rest } = d;
          return {
            ...rest,
            map: {
              ...map,
              label: value,
            },
          };
        }
        return d;
      });
    } else {
      newData = { ...data, label: value };
    }
    const newOptions = { ...options, [dataKey]: newData };

    this.setState({ options: newOptions });
    updateParent && updateParent({ populateFrom: newOptions });
  };

  getOptionsRows = options => {
    const { repeatersReference } = componentsManager;
    const { fieldsByKey } = getAllFieldsFlat(
      componentsManager.fields,
      null,
      true
    );
    const rows = [];
    Object.entries(options).forEach(([dataKey, data], idx) => {
      const repeaterRef = Object.values(repeatersReference).find(ref =>
        ref.find(rep => rep.repeaterKey === dataKey)
      );
      if (repeaterRef && repeaterRef.length) {
        const repeater = repeaterRef
          .filter(r => r.repeaterKey === dataKey)
          .pop();
        // all the repeater fields are possible options for the select
        const selectOptions = Object.keys(repeater.fields).map(field => ({
          key: fieldsByKey[field].key,
          title: fieldsByKey[field].title || fieldsByKey[field].key,
        }));
        // we are getting values from a repeater block
        // then, first put some titles
        rows.push(
          <Divider key={`divider-rep-${idx}`} orientation="left">
            Referencing {getFieldLabel(REPEATER)} <strong>{dataKey}</strong>
          </Divider>
        );

        // now we need to put options detail
        if (Array.isArray(data)) {
          // more titles
          rows.push(
            <Row key={`rep-arr-title-${idx}`} className="title">
              <Col sm={4}>Prefix</Col>
              <Col sm={4}>Field</Col>
              <Col sm={4}>Expect</Col>
              <Col sm={4}>Link key</Col>
              <Col sm={8}>Label</Col>
            </Row>
          );

          data.forEach(({ prefix, key, expect, map }, index) => {
            rows.push(
              <Row
                key={`rep-arr-data-${idx}-${key}-${index}`}
                type="flex"
                align="middle"
              >
                <Col sm={4}>
                  <Input
                    className="edit-input"
                    defaultValue={prefix}
                    maxLength={50}
                    onBlur={this.onBlur(dataKey, index)}
                  />
                </Col>
                <Col sm={4}>{key}</Col>
                <Col sm={4}>{expect}</Col>
                <Col sm={4}>{map.value}</Col>
                <Col sm={8}>
                  <Select
                    mode="multiple"
                    placeholder="Inserted are removed"
                    value={map.label}
                    onChange={this.onChange(dataKey, index)}
                    style={{ width: '100%' }}
                  >
                    {selectOptions
                      .filter(o => !map.label.includes(o.key))
                      .map((o, i) => (
                        <Select.Option key={`${o.key}-${i}`} value={o.key}>
                          {o.title}
                        </Select.Option>
                      ))}
                  </Select>
                </Col>
              </Row>
            );
          });
        } else {
          rows.push(
            <Row key={`rep-title-${idx}`} className="title">
              <Col sm={4}>Link key</Col>
              <Col sm={8}>Label</Col>
            </Row>,
            <Row key={`rep-row-${idx}`}>
              <Col sm={4}>{data.value}</Col>
              <Col sm={8}>
                <Select
                  mode="multiple"
                  placeholder="Inserted are removed"
                  value={data.label}
                  onChange={this.onChange(dataKey)}
                  style={{ width: '100%' }}
                >
                  {selectOptions
                    .filter(o => !data.label.includes(o.key))
                    .map((o, i) => (
                      <Select.Option key={`${o.key}-${i}`} value={o.key}>
                        {o.title}
                      </Select.Option>
                    ))}
                </Select>
              </Col>
            </Row>
          );
        }
      } else {
        rows.push(
          <Divider key={`div-op-${idx}`} orientation="left">
            <strong>{dataKey}</strong> collection
          </Divider>
        );
        rows.push(
          <Row key={`title-op-${idx}`} className="title">
            <Col sm={4}>Prefix</Col>
            <Col sm={4}>Link key</Col>
            <Col sm={12}>Label</Col>
          </Row>
        );
        rows.push(
          <Row key={`data-op-${idx}`}>
            <Col sm={4}>
              <Input
                className="edit-input"
                defaultValue={data.prefix}
                maxLength={50}
                onBlur={this.onBlur(dataKey)}
              />
            </Col>
            <Col sm={4}>{data.value}</Col>
            <Col sm={12}>{data.label.join(', ')}</Col>
          </Row>
        );
      }
    });
    return rows;
  };
}
