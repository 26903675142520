import React from 'react';
import { get, findIndex } from 'lodash';

import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';
import { Timeline } from 'antd';

import parsedDate from '../../utils/parse-date';

import {
  PAUSE_CASES,
  RESUME_CASES,
} from '../../constants/internal-affair-case-load-filter';

momentDurationFormat(moment);
const Item = Timeline.Item;

const lineBreakRegex = /&#013;&#010;/g;

moment.updateLocale('en', {
  relativeTime: {
    d: '1 day',
  },
});

export const getItemIndex = currentIndex => item => item.index === currentIndex;

export const getLastPausedTime = (index, history) => {
  const lastPauseTime = get(history[index - 1], 'ellapsedTime', 0);
  const lastPauseDuration = moment.duration(lastPauseTime);
  // TODO: Refactor. Just to test label.
  if (lastPauseDuration.asSeconds() < 60) {
    return lastPauseDuration.format('s [seconds]');
  } else if (lastPauseDuration.asMinutes() < 60) {
    return lastPauseDuration.format('m [minutes] s [seconds]');
  } else if (lastPauseDuration.asHours() < 24) {
    return lastPauseDuration.format('h [hours] m [minutes]');
  }
  return lastPauseDuration.format('d [days] h [hours]');
};

export const timelineItemMessage = (action = '', item = {}, index, history) => {
  const { author = {}, reason = {} } = item;
  const { fullName, rank: { name: rankName = '' } = {} } = author;
  const { name: reasonName = '' } = reason;
  let message;

  switch (action) {
    case PAUSE_CASES:
      message = `${rankName} ${fullName} paused on this investigation because of ${reasonName}.`;
      break;

    case RESUME_CASES:
      const itemIndex = findIndex(history, getItemIndex(index));
      const pausedDays = getLastPausedTime(itemIndex, history);
      message = `${rankName} ${fullName} resumed this investigation. This investigation has been paused for ${pausedDays}.`;
      break;

    default:
      message = 'Default message';
      break;
  }
  return message;
};

export const timeoutHistory = (history = []) =>
  history
    .map((item, index) => ({ ...item, index }))
    .filter(item => item.type === 'timeout');

export const workflowHistory = (history = []) =>
  history
    .map((item, index) => ({ ...item, index }))
    .filter(item => item.type === 'workflow');

export default ({ history = [], timezone }) => {
  return (
    <Timeline>
      {history.map((item, index) => {
        const { action, fromDate, notes = '' } = item;
        const note = notes
          .replace(lineBreakRegex, '<br />')
          .replace(/\n/g, '<br />');
        return (
          <Item key={index}>
            <p>{parsedDate(fromDate, timezone)}</p>
            <p>{timelineItemMessage(action, item, index, history)}</p>
            {notes && <div dangerouslySetInnerHTML={{ __html: note }} />}
          </Item>
        );
      })}
    </Timeline>
  );
};
