import styled from 'styled-components';

import { DIVIDER, FONT_FAMILY } from 'APP_ROOT/config/theme';

const InputWrapper = styled.div`
  .ant-form-item-label {
    white-space: normal;
    line-height: 1.4em;
    transform: translateY(-50%);
    top: 1.4em;
  }

  .ant-form-text-field {
    display: block;
    padding: 0 10px;
    min-height: 28px;
    line-height: 28px;
    border-bottom: 1px solid ${DIVIDER};
    font-size: 12px;
    font-family: ${FONT_FAMILY};
    cursor: default;
  }

  @media print {
    .ant-form-item-label,
    .ant-form-text-field {
      font-size: 1.2em;
      font-family: inherit;
    }
  }
`;

export default InputWrapper;
