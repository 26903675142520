import React from 'react';
import { orderBy, get } from 'lodash';
import { change, arrayPush, arrayRemove } from 'redux-form';

import UserProfileSectionForm from './UserProfileSectionForm';
import UserProfileSectionModal from './UserProfileSectionModal';
import CustomSectionTable from '../../../../../components/custom-sections/tables/CustomSectionTable';

import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from '../../../../../components/custom-sections/sections/Sections.styled';

import {
  SECTION_TYPE_FORM,
  SECTION_TYPE_TABLE,
} from '../UserProfile.constants';

import SectionAddButton from '../../../../../components/custom-sections/sections/SectionAddButton';

const UserProfileSections = ({
  profileForm,
  timezone,
  sectionsDefinition,
  editSection: pEditSection,
  updateSectionField: pUpdateSectionField,
  addSectionFieldValue: pAddSectionFieldValue,
  removeSectionFieldValue: pRemoveSectionFieldValue,
  setEditSectionTable: pSetEditSectionTable,
  isSectionReadOnly = false,
  prefix = 'sectionsData',
  parentContent,
  session,
  hasAction,
  onAction,
  linkAction,
  showIconAction,
  iconAction,
  actionLabel,
  addButtonTitle,
  buildModal,
  onModalOkCallback,
  rowSelection,
  loading = false,
  secondaryButton,
  isPredefinedTaskLoading = false,
  showSecondaryButton,
  children,
  disableAction = false,
  isContributor = false,
  currentUser = {},
  renderOwnerWarningIcon = false,
}) => {
  const sections = orderBy(sectionsDefinition, ['settings.order']);
  const defaultActiveKey = sections
    .map(section => {
      const collapsedByDefault = get(
        section,
        'settings.collapsedByDefault',
        false
      );
      return collapsedByDefault ? null : section.sectionId;
    })
    .filter(s => s !== null);

  const _updateSectionField = (field, value) => {
    const { dispatch } = parentContent.props;
    dispatch(change(parentContent.formName, field, value));
  };

  const _addSectionFieldValue = (field, value) => {
    const { dispatch } = parentContent.props;
    dispatch(arrayPush(parentContent.formName, field, value));
  };

  const _removeSectionFieldValue = (field, index) => {
    const { dispatch } = parentContent.props;
    dispatch(arrayRemove(parentContent.formName, field, index));
    parentContent.setState({ editUserSection: null });
  };

  const _setEditSectionTable = (sectionId, key) => {
    if (sectionId) {
      parentContent.setState({ editUserSection: { sectionId, key } });
    } else {
      parentContent.setState({ editUserSection: null });
    }
  };

  const editSection =
    pEditSection || get(parentContent, 'state.editUserSection');
  const updateSectionField = pUpdateSectionField || _updateSectionField;
  const addSectionFieldValue = pAddSectionFieldValue || _addSectionFieldValue;
  const removeSectionFieldValue =
    pRemoveSectionFieldValue || _removeSectionFieldValue;
  const setEditSectionTable = pSetEditSectionTable || _setEditSectionTable;
  const userId = get(profileForm, 'values.integrationId', '');
  const tenantId = get(session, 'currentUser.agency.integrationId', null);

  function buildUserProfileSectionModal(attributes, modalTitle) {
    if (buildModal) {
      return buildModal(attributes, modalTitle);
    }

    return function(
      readOnly,
      showModal,
      sectionId,
      rowIndex,
      handleModalCancel,
      handleModalOk,
      isNew
    ) {
      return (
        <UserProfileSectionModal
          title={modalTitle}
          attributes={attributes}
          profileForm={profileForm}
          readOnly={readOnly}
          visible={showModal}
          sectionId={sectionId}
          rowIndex={rowIndex}
          onCancel={handleModalCancel}
          onOk={handleModalOk}
          userId={userId}
          tenantId={tenantId}
          sectionsDefinition={sectionsDefinition}
          isNew={isNew}
        />
      );
    };
  }
  return sectionsDefinition.length ? (
    <StyledCollapse
      bordered={false}
      defaultActiveKey={defaultActiveKey}
      expandIconPosition="right"
    >
      {sections.map(
        ({ sectionId, type, title, readOnly, sectionAttributes }) => (
          <StyledPanel
            header={<StyledPanelTitle size="large">{title}</StyledPanelTitle>}
            key={sectionId}
            className="section-panel"
            extra={
              type === SECTION_TYPE_TABLE ? (
                <>
                  {showSecondaryButton && secondaryButton}
                  <SectionAddButton
                    sectionId={sectionId}
                    attributeId="objectValueId"
                    prefix={prefix}
                    attributes={sectionAttributes}
                    addSectionFieldValue={addSectionFieldValue}
                    setEditSectionTable={setEditSectionTable}
                    disabled={
                      readOnly ||
                      isSectionReadOnly ||
                      loading ||
                      isPredefinedTaskLoading
                    }
                    buttonTitle={addButtonTitle}
                  />
                </>
              ) : (
                <></>
              )
            }
          >
            <hr></hr>
            {type === SECTION_TYPE_FORM ? (
              <UserProfileSectionForm
                key={sectionId}
                sectionId={sectionId}
                prefix={prefix}
                attributes={sectionAttributes}
                profileForm={profileForm}
                timezone={timezone}
                isSectionReadOnly={readOnly || isSectionReadOnly}
                loading={loading}
                renderOwnerWarningIcon={
                  renderOwnerWarningIcon
                }
              >
                {children}
              </UserProfileSectionForm>
            ) : (
              <CustomSectionTable
                key={sectionId}
                prefix={prefix}
                attributeId="objectValueId"
                modalTitle={title}
                sectionId={sectionId}
                attributes={sectionAttributes}
                mainForm={profileForm}
                editSection={
                  editSection && editSection.sectionId === sectionId
                    ? editSection
                    : null
                }
                updateSectionField={updateSectionField}
                addSectionFieldValue={addSectionFieldValue}
                removeSectionFieldValue={removeSectionFieldValue}
                setEditSectionTable={setEditSectionTable}
                modalFor="sections"
                timezone={timezone}
                isSectionReadOnly={readOnly || isSectionReadOnly}
                linkAction={linkAction}
                hasAction={hasAction}
                onAction={onAction}
                showIconAction={showIconAction}
                iconAction={iconAction}
                actionLabel={actionLabel}
                buildModal={buildUserProfileSectionModal(
                  sectionAttributes,
                  title
                )}
                onModalOkCallback={onModalOkCallback}
                rowSelection={rowSelection}
                loading={loading}
                disableAction={disableAction || isPredefinedTaskLoading}
                isContributor={isContributor}
                currentUser={currentUser}
              />
            )}
          </StyledPanel>
        )
      )}
    </StyledCollapse>
  ) : null;
};

export default UserProfileSections;
