export const language = [
  { value: 'English', name: 'English' },
  { value: 'Spanish', name: 'Spanish' },
  { value: 'Chinese', name: 'Chinese' },
  { value: 'Tagalog', name: 'Tagalog' },
  { value: 'Vietnamese', name: 'Vietnamese' },
  { value: 'French/Creole', name: 'French/Creole' },
  { value: 'Arabic', name: 'Arabic' },
  { value: 'Korean', name: 'Korean' },
  { value: 'Russian', name: 'Russian' },
  { value: 'German', name: 'German' },
  { value: 'Other', name: 'Other' },
];
