import getTemplatesById from '../shared/getTemplatesById';
import getTemplatesByType from '../shared/getTemplatesByType';
import getAgencyTemplates from '../../get-agency-templates';

const retrieveAgencyTemplates = async ({
  fallback,
  filter,
  dispatch,
  shouldFetch: evalShouldFetch,
  useCache = true,
  agencyId,
  canCreate = false,
}) =>
  new Promise(resolve => {
    const dictionaryById = getTemplatesById(fallback);
    const dictionaryByType = getTemplatesByType(fallback);
    const shouldFetch =
      typeof evalShouldFetch === 'boolean'
        ? evalShouldFetch
        : evalShouldFetch({ dictionaryById, dictionaryByType });

    if (shouldFetch || !useCache) {
      try {
        return dispatch(
          getAgencyTemplates(
            filter,
            (error, templates) => {
              resolve([error, templates]);
            },
            agencyId,
            canCreate
          )
        );
      } catch (error) {
        return resolve([error]);
      }
    }

    return resolve([null, fallback]);
  });

export default retrieveAgencyTemplates;
