import React, { PureComponent } from 'react';
import { get, some } from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import FormHeader from '../form/header';
import FormViewer from './styled/form-viewer';

import getLastStatus from '../../utils/get-report-status';
import parseDate, {
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_TIMEZ_FORMAT,
} from '../../utils/parse-date';
import route from '../../utils/get-route';
import { GRAY_2 } from '../../config/theme';

class FormViewerWrapper extends PureComponent {
  getLinkingFormsDescription = () => {
    const {
      isReviewer,
      selectedForm: {
        actions,
        meta: { linkedSubmissions = [], formType = '' } = {},
      },
    } = this.props;
    var involvedForms = null;
    const waitingForSubmission = some(
      actions,
      action => action.status === 'pending'
    );

    if (!!linkedSubmissions.length) {
      if (linkedSubmissions.length === 1) {
        const linkedForm = linkedSubmissions[0];

        involvedForms = (
          <span>
            This {formType} involves a {linkedForm.formType} incident{' '}
            <Link to={route('report', { id: linkedForm.id })}>
              {linkedForm.formNumber}
            </Link>
            <br />
          </span>
        );
      } else {
        involvedForms = (
          <span>
            This {formType} involves the following{' '}
            {linkedSubmissions.map((form, index) => {
              return (
                <span key={index}>
                  <Link key={form.id} to={route('report', { id: form.id })}>
                    {form.formNumber}
                  </Link>
                  {', '}
                </span>
              );
            })}
            <br />
          </span>
        );
      }
    }

    return (
      <span>
        {involvedForms}
        {isReviewer && waitingForSubmission && (
          <span>
            Waiting for the submission related to the{' '}
            {linkedSubmissions[0].formType}
          </span>
        )}
      </span>
    );
  };

  render() {
    const {
      app: { sidebarCollapsed = false },
      selectedForm = {},
      actions,
      user: { id, agency = {} } = {},
      isPrinting = false,
      loading = false,
      timezone,
      isReviewer,
    } = this.props;

    const { meta = {} } = selectedForm;
    const { submitterId = '', participants = [] } = meta;

    const formNumber = get(selectedForm, ['meta', 'formNumber'], '');
    const templateType = get(selectedForm, ['template', 'type'], '');
    const formType = get(selectedForm, ['meta', 'formType'], templateType);
    const formSubmittedDate = get(selectedForm, ['meta', 'submittedAt'], '');
    const formUpdatedDate = get(selectedForm, ['meta', 'updatedAt'], '');
    const submittedAt = parseDate(formSubmittedDate, timezone);
    const formDraftDate = get(
      selectedForm,
      ['meta', 'draftDate'],
      formUpdatedDate
    );
    const draftDate = parseDate(formDraftDate, timezone);
    const formExist = !!formNumber;
    const incidentDate = get(selectedForm, 'data.incidentDate');
    const incidentDateParsed = incidentDate
      ? parseDate(incidentDate, timezone, BENCHMARK_DATE_FORMAT)
      : '';
    const incidentTime = get(selectedForm, 'data.incidentDate');
    const incidentTimeParsed = incidentDate
      ? parseDate(incidentTime, timezone, BENCHMARK_TIMEZ_FORMAT)
      : '';

    const { key: statusKey = 'draft' } = getLastStatus(
      { actions: selectedForm.actions, ...meta },
      id
    );

    const isDraft = statusKey === 'draft' || !selectedForm.meta.id;
    const isSubmitter = parseInt(id) === submitterId;
    const submittedBy = participants.reduce((result, item) => {
      const { id, fullName, rank = {} } = item;
      if (parseInt(id) === submitterId) {
        return {
          rank,
          fullName,
        };
      }
      return {
        ...result,
      };
    }, {});

    const submittedByLabel = !isSubmitter
      ? `by ${submittedBy.rank} ${submittedBy.firstName} ${submittedBy.lastName}`
      : '';

    const reviewStatusLabel = isDraft
      ? `Last updated at ${draftDate}`
      : `Submitted ${submittedByLabel} at ${submittedAt}`;

    return (
      <ConfirmationWrapper>
        <FormHeader
          canSubmit={false}
          actions={actions}
          title={`${formType} - Confirmation`}
          isPrinting={isPrinting}
          loading={loading}
          isSubmitter={isSubmitter}
          submittedBy={submittedBy}
          statusSubmittedByDate={submittedAt}
          isDraft={isDraft}
          agency={agency.name}
          type={formType}
          showType={false}
          isReviewer={isReviewer}
          isActiveReviewer={false}
          formExist={formExist}
          saveRef={this.saveFormHeaderRef}
          sidebarCollapsed={sidebarCollapsed}
          isPrintingConfirmation
        />
        <Confirmation>
          <p>{this.getLinkingFormsDescription()}</p>
          {formNumber && <p>{reviewStatusLabel}</p>}
          {incidentDateParsed && incidentTimeParsed && (
            <p>
              Incident Date/Time: {incidentDateParsed}, {incidentTimeParsed}
            </p>
          )}
          <div className="box">
            <p>
              The {formType} incident is submitted. Its incident number is{' '}
              {formNumber}. Please keep this for your record.
            </p>
          </div>
        </Confirmation>
      </ConfirmationWrapper>
    );
  }
}

const Confirmation = styled.div`
  padding: 24px 16px;
  margin-top: 200px;
  font-family: 'Lato', Arial, serif;
  background-color: #fff;
  border-radius: 6px;

  .box {
    margin-top: 20px;
    padding: 20px 0;
    border: 1px solid #e9e9e9;
    border-left: none;
    border-right: none;
  }

  @media print {
    margin-top: 100px;
    border-color: #ccc;
    color: #000;
    padding: 24px 8px;

    p {
      color: #000 !important;
    }
  }

  p {
    font-size: 12px;
    color: ${GRAY_2};
    line-height: 1.5em;
  }
`;

const ConfirmationWrapper = FormViewer.extend`
  margin: 0 auto;

  @media screen {
    max-width: 900px;
  }
`;

export default FormViewerWrapper;
