import React, { Component } from 'react';

import { castArray, isPlainObject } from 'lodash';
import { withRouter } from 'react-router-dom';

import Box from '../../../../components/Box';
import TabList from '../TabList';
import TabContent from '../TabContent/TabContent';
import componentsManager from '../../services/componentsManager';
import calculatedFieldsConfig from '../../services/calculatedFieldsConfig';
import getSafeViewHeight from '../../utils/getSafeViewHeight';
import workspaceLoader from './workspaceLoader';

import { TAB } from 'APP_ROOT/constants/layoutComponentTypes';

class Workspace extends Component {
  state = {
    tabs: [],
    visible: false,
    placement: 'right',
  };

  agencyId = undefined;
  reportId = undefined;
  selectedtemplateId = undefined;
  reviewNoteListIds = [];

  componentDidMount() {
    this.componentsManagerSuscription = componentsManager
      .getComponents()
      .subscribe(this.syncFields);

    const {
      match: {
        params: { agencyId },
      },
    } = this.props;

    calculatedFieldsConfig.loadCalculatedFieldsConfig(agencyId);
  }

  componentWillUnmount() {
    this.componentsManagerSuscription.unsubscribe();
    componentsManager.header.id = undefined;
  }

  render() {
    const { className, offsetTop } = this.props;
    const { isLoading } = componentsManager.flags;
    const [content = {}] = this.tabs;
    const hasTabs = content.type === TAB;
    return (
      <div
        className={className}
        style={{ height: getSafeViewHeight(offsetTop) }}
      >
        {isLoading ? (
          React.createElement(workspaceLoader)
        ) : hasTabs ? (
          <Box>
            <TabList tabs={this.tabs} />
          </Box>
        ) : (
          <TabContent tab={{ properties: this.tabs }} />
        )}
      </div>
    );
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  syncFields = tabs => {
    this.setState({ tabs });
  };

  get tabs() {
    const { tabs } = this.state;
    return castArray(tabs).filter(isPlainObject);
  }
}

export default withRouter(Workspace);
