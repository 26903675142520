import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { has, isEmpty, isPlainObject } from 'lodash';

import { fetchRequest } from '../../utils/request';

// TODO: read token from environment variable
const MAPBOX_TOKEN =
  'pk.eyJ1IjoiYmVuY2htYXJrYW5hbHl0aWNzLXJpc2tzb2x1dGlvbnMiLCJhIjoiY2wyZXdia2w2MDRybzNjcGdwdXc2MnNldSJ9.FTsl1gl0SqZDJvMC4mLePg';

const MARKER_COLOR = 'rgb(70, 104, 242)';

// MapComponent is a component that renders a map using Mapbox
const MapComponent = props => {
  const { saveData, coords = {}, place = '', shouldClearMap, showMap } = props;

  const mapContainer = useRef(null);
  const map = useRef(null);
  const geocoder = useRef(null);
  const prevShowMap = useRef(showMap);
  const emptyCoords = !has(coords, 'lat') || !has(coords, 'lng');
  // default coords to New York City
  const coordsNY = { lng: -74.0059945, lat: 40.7127492 };
  const { lng, lat } = emptyCoords ? coordsNY : coords;
  const center = [lng, lat];
  const zoom = emptyCoords ? 8.8 : 16;

  // set place in the marker and in the map input
  const setPlace = place => {
    // set place in the marker
    geocoder.current.mapMarker.setPopup(
      new mapboxgl.Popup().setHTML(`${place}`)
    );
    geocoder.current.mapMarker.togglePopup();
    // set place in the map input
    geocoder.current.setInput(place);
  };

  const buildURI = params => {
    let lng;
    let lat;
    let query;
    if (isPlainObject(params)) {
      ({ lng, lat } = params);
      query = `${lng},${lat}`;
    } else {
      query = params;
    }
    // reverse geocoding for places
    // doc: https://docs.mapbox.com/api/search/geocoding-v5/#mapboxplaces
    return `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_TOKEN}`;
  };

  // get place name from coords
  const getPlace = (lng, lat) => {
    const URI = buildURI({ lng, lat });
    const result = fetchRequest(URI, {});
    result.then(response => {
      const {
        features: [feature],
      } = response;
      const { place_name } = feature;
      setPlace(place_name);
      saveData(feature);
    });
  };

  const getCoordsFromPlace = place => {
    const URI = buildURI(place);
    const result = fetchRequest(URI, {});
    result.then(response => {
      const {
        features: [feature],
      } = response;
      const { place_name, center } = feature;
      map.current.jumpTo({
        center,
        zoom: 16,
        // bearing: 90,
      });
      newMarker(center);
      setPlace(place_name);
      saveData(feature);
      geocoder.current.mapMarker?.on('dragend', dragendEvent);
    });
  };

  // create a new marker
  const newMarker = coords => {
    geocoder.current.mapMarker?.remove();
    geocoder.current.mapMarker = new mapboxgl.Marker({ color: MARKER_COLOR })
      .setLngLat(coords)
      .setDraggable(true)
      .addTo(map.current);
  };

  const dragendEvent = () => {
    const { lng, lat } = geocoder.current.mapMarker.getLngLat();
    getPlace(lng, lat);
  };

  // add a marker on click
  const setMarkerOnClickedPlace = () => {
    map.current.once('click', e => {
      const { lng, lat } = e.lngLat;
      newMarker([lng, lat]);
      getPlace(lng, lat);
      geocoder.current.mapMarker.on('dragend', dragendEvent);
    });
  };

  const setInitialMarkerPlace = () => {
    newMarker(center);
    setPlace(place);
    geocoder.current.mapMarker.on('dragend', dragendEvent);
  };

  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_TOKEN;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center,
      zoom,
    });

    geocoder.current = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      marker: {
        draggable: true,
      },
      mapboxgl: mapboxgl,
    });
    map.current.addControl(geocoder.current, 'top-left');

    emptyCoords && !place && setMarkerOnClickedPlace();

    map.current.on('load', () => {
      if (emptyCoords) {
        place && getCoordsFromPlace(place);
      } else {
        // if we have coords already, set the marker
        setInitialMarkerPlace();
      }

      // Listen for the `geocoder.input` event that is triggered when a user
      // makes a selection
      geocoder.current.on('result', ({ result }) => {
        saveData(result);
        geocoder.current.mapMarker?.on('dragend', dragendEvent);
      });

      // Clear results container when search is cleared.
      geocoder.current.on('clear', () => {
        saveData();
        setMarkerOnClickedPlace();
      });
    });
  }, []);

  useEffect(() => {
    const shouldResize =
      map.current.painter.height > mapContainer.current.clientHeight ||
      map.current.painter.width > mapContainer.current.clientWidth;
    showMap && shouldResize && map.current.resize();
    if (shouldClearMap) {
      geocoder.current.mapMarker?.remove();
      geocoder.current.setInput(''); // clear input
      // add a marker on click
      setMarkerOnClickedPlace();
    } else if (showMap !== prevShowMap.current && !emptyCoords) {
      prevShowMap.current = showMap;
      const lastCoords = geocoder.current.mapMarker?.getLngLat() || {};
      const sameCoords = Object.keys(lastCoords).reduce(
        (acc, key) => acc && lastCoords[key] === coords[key],
        !isEmpty(lastCoords)
      );
      // update coords
      if (showMap && place && !sameCoords) {
        setInitialMarkerPlace();
      }
      showMap && map.current.setCenter(center);
    } else if (showMap !== prevShowMap.current && emptyCoords) {
      prevShowMap.current = showMap;
      if (showMap && geocoder.current.mapMarker) {
        map.current.setCenter(geocoder.current.mapMarker.getLngLat());
      }
      if (showMap && place) {
        getCoordsFromPlace(place);
      }
    }
  }, [shouldClearMap, showMap]);

  return <div ref={mapContainer} style={{ height: '60vh' }} />;
};

export default MapComponent;
