import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import first from 'lodash/first';
import kebabCase from 'lodash/kebabCase';

import getFormActions from '../../actions/get-form-actions';
import getFormTemplate from '../../actions/get-form-template';
import getFormReviewPresentation from '../../actions/get-form-review-presentation';
import SchemaDrawer from '../form-viewer/object-types/object-field';

import {
  getAgencyTZ,
  getTemplateConfiguration,
  getCurrentUser,
} from '../../selectors/session';
import { emptyObject } from '../../constants/form';
import getFormProps from '../../utils/getFormProps.memoized';

import ReviewSchemaWrapper from './ReviewSchemaWrapper.styled';
import { initialState } from './ReviewSchema.constants';
import { shouldBlocknavigation } from '../../selectors/application';

export class ReviewNotes extends PureComponent {
  state = initialState;
  presenation = { hasTabs: false, fields: [] };
  presentationRules = {};
  formFields = [];

  componentDidMount() {
    const {
      dispatch,
      submissionForm: {
        selectedForm: {
          meta: { agencyId = '', id: formSubmissionId = '' } = {},
        } = {},
      } = {},
    } = this.props;

    dispatch(
      getFormActions({ agencyId, formSubmissionId }, err => {
        if (!err) {
          this.getReviewNotesTemplate(this.props);
        }
      })
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getReviewNotesTemplate(nextProps);
  }

  getReviewNotesTemplate = props => {
    const {
      dispatch,
      selectedForm: {
        data: formData = {},
        meta,
        meta: { agencyId = '' },
      },
    } = props;
    const { reviewNotesFetchStarted } = this.state;
    if (!reviewNotesFetchStarted) {
      this.setState({ reviewNotesFetchStarted: true });
      this.processReviewNote(meta, dispatch, agencyId, formData);
    }
  };

  processReviewNote(meta, dispatch, agencyId, formData) {
    const { templateId, id: templateVersionId } = first(
      get(meta, 'reviewNoteTemplateId', [])
    );
    dispatch(
      getFormTemplate(
        { templateId, templateVersionId, agencyIdFromOptions: agencyId },
        async (err, template) => {
          if (!err) {
            const {
              formSchema: {
                form: schema = {},
                validations,
                enums,
                overrides,
                propagateAction = '',
              } = {},
              type,
            } = template;

            dispatch(
              getFormReviewPresentation({
                schema,
                type,
                validations,
                enums,
                formData,
                overrides,
                propagateAction,
                templateId,
              })
            );
          }
        }
      )
    );
  }

  render() {
    const {
      form,
      submissionForm,
      reviewForm,
      timezone,
      settings,
      onSubmit,
      isReviewPanel = false,
      disabled,
    } = this.props;
    const { entries = {} } = reviewForm;
    const { meta } = submissionForm.selectedForm;
    const isReviewer = false;
    const { templateId: reviewPanelTemplateId } = first(
      get(meta, 'reviewNoteTemplateId', [])
    );
    const templateEntry = get(entries, reviewPanelTemplateId, {});
    const { formSchema: uiSchema = {} } = templateEntry;

    const { form: formSchema, validations, overrides } = uiSchema;

    const canSubmit = true;
    const submissionIsLoading = submissionForm.loading;

    const reviewPanelSettings = {
      ...settings,
      disableAllFields: disabled,
    };

    const formProps = getFormProps(
      emptyObject,
      form,
      overrides,
      reviewPanelSettings,
      validations,
      0,
      onSubmit,
      isReviewer,
      false,
      canSubmit,
      submissionIsLoading,
      [],
      timezone
    );

    const schemaDrawerProps = Object.assign({}, formProps, formSchema, {
      isReviewPanel,
    });
    return (
      <ReviewSchemaWrapper>
        {createElement(SchemaDrawer, schemaDrawerProps)}
      </ReviewSchemaWrapper>
    );
  }
}

const mapState = (state, props) => {
  const { submissionForm = {} } = props;
  const { selectedForm = {} } = submissionForm;
  const templateType = get(selectedForm, 'meta.formType', null);
  const templateConfiguration = getTemplateConfiguration(
    kebabCase(templateType)
  );

  return {
    user: getCurrentUser(state),
    selectedForm,
    blockNavigation: shouldBlocknavigation(state),
    timezone: getAgencyTZ(state),
    templateConfiguration: templateConfiguration(state),
  };
};

export default connect(mapState)(ReviewNotes);
