import React from 'react';

import DashboardPage from '../../../components/dashboard';
import withoutClutter from 'APP_COMPONENTS/without-clutter';

import AdministratorWrapper from '../Administrator.styled';
import ListOfAgencies from './ListOfAgencies';

export class Component extends React.Component {
  render() {
    return (
      <AdministratorWrapper>
        <ListOfAgencies />
      </AdministratorWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withoutClutter(DashboardPage(mapStateToProps)(Component));
