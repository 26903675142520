import React from 'react';
import RenderSchema from '../../components/RenderSchema';
import FORM_TYPES from 'APP_ROOT/constants/form-types';
import { LOADER_TYPES } from 'APP_ROOT/constants/renderSchema';
import { getFormFieldProfileData } from 'APP_ROOT/selectors/selected-users';

const OfficerProfile = () => (
  <RenderSchema
    schema={FORM_TYPES.OFFICER_PROFILE}
    loader={LOADER_TYPES.LOADER_OFFICER_PROFILE}
    dataSelector={getFormFieldProfileData}
  />
);

export default OfficerProfile;
