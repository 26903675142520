import styled from 'styled-components';
import { Icon } from 'antd';

const StyledIcon = styled(Icon)`
  font-size: 20px;
  color: #0040dd;
  transition: color 0.3s;
  &:hover {
    color: #41669f;
  }
`;

export default StyledIcon;
