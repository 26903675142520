import openModal from '../utils/onShareButtonClick';

const ReassignButton = {
  icon: 'export',
  type: 'icon',
  title: 'Reassign',
  tooltipMessage: 'Reassign Report',
  onButtonClick: () => openModal,
};

export default ReassignButton;
