import keys from 'lodash/keys';
import isPlainObject from 'lodash/isPlainObject';

export default error => {
  if (!error) return error;
  let acc = [];
  const flatErrorsRec = (error, parentKey) => {
    if (Array.isArray(error)) {
      error.forEach((e, index) => {
        const key = parentKey ? `${parentKey}[${index}]` : index;
        flatErrorsRec(e, key);
      });
    } else if (isPlainObject(error) && !error.errors) {
      keys(error).forEach(k => {
        const key = parentKey ? `${parentKey}.${k}` : k;
        flatErrorsRec(error[k], key);
      });
    } else {
      acc.push({ key: parentKey, errors: error });
    }
  };
  flatErrorsRec(error);
  return acc.reduce(
    (result, { key, errors }) => ({ ...result, [key]: errors }),
    {}
  );
};
