import styled from 'styled-components';
import { Pagination, Row } from 'antd';

export const StyledPagination = styled(Pagination)`
  &.ant-pagination {
    margin: 0 16px 65px 16px;
    float: right;
  }
`;

export const StyledRowPagination = styled(Row)`
  margin-top: 16px;
`;

export const StyledTable = styled.div`
  margin: 15px 15px 0 15px;
`;
