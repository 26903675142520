import React, { Fragment } from 'react';
import { Alert, Button, Cascader, Form, Row } from 'antd';
import { isEmpty } from 'lodash';

import FieldSelect from '../../components/FieldSelect';
import { MATH_OPERATIONS, MATH_ROUNDING } from '../../constants/constants';

import disableCascaderOptions from './disableCascaderOptions';

const STYLE_OPTIONS = { width: 260 };
const { Item } = Form;
const itemLayoutOptions = {
  colon: false,
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default class MathFieldOptions extends React.Component {
  constructor(props) {
    super(props);
  }

  onSelectChange = key => data => {
    const { onChange, value } = this.props;
    const newValue = { [key]: data };
    onChange && onChange({ ...value, ...newValue });
  };

  onSelectOperand = index => data => {
    const { onChange, value } = this.props;
    const { operands = [] } = value;
    const newValue =
      operands.length === 0
        ? [data]
        : operands.map((o, i) => (i === index ? data : o));

    onChange && onChange({ ...value, operands: newValue });
  };

  disableAddOperand = () => {
    const { value = {} } = this.props;
    const { operands = [] } = value;
    return isEmpty(operands) || operands.some(o => isEmpty(o));
  };

  addOperand = () => {
    const { onChange, value = {} } = this.props;
    const { operands = [] } = value;
    const newValue = { ...value, operands: [...operands, ''] };

    onChange && onChange(newValue);
  };

  removeOperand = index => () => {
    const { onChange, value = {} } = this.props;
    const { operands = [] } = value;
    const len = operands.length;

    if (len > 0) {
      const newValue = {
        ...value,
        operands: len === 1 ? [] : operands.filter((o, i) => i !== index),
      };

      onChange && onChange(newValue);
    }
  };

  renderOperand = (value, index, options) => (
    <Cascader
      value={value}
      options={options}
      style={STYLE_OPTIONS}
      onChange={this.onSelectOperand(index)}
      allowClear={false}
      placeholder="Please select"
      data-test="operand"
    />
  );

  renderOperandOptions = () => {
    const { value = {}, cascaderOptions, parentRepeater } = this.props;
    const { operands = [] } = value;
    const options = disableCascaderOptions(
      cascaderOptions,
      parentRepeater,
      operands,
      false
    );
    const len = operands.length;
    return len
      ? operands.map((op, i) => (
          <Fragment key={`operand-${i}`}>
            {this.renderOperand(op, i, options)}
            {len > 1 && (
              <Button
                shape="circle"
                icon="minus"
                onClick={this.removeOperand(i)}
              />
            )}
          </Fragment>
        ))
      : this.renderOperand('', 0, options);
  };

  renderOperands = () => {
    return (
      <Item key="operands" label="Operand(s)" {...itemLayoutOptions}>
        {this.renderOperandOptions()}
        <Button
          icon="plus-circle"
          onClick={this.addOperand}
          disabled={this.disableAddOperand()}
        >
          Add Operand
        </Button>
      </Item>
    );
  };

  render = () => {
    const { value = {} } = this.props;
    const { operation, rounding } = value;
    return (
      <Fragment>
        <Row>
          <Item key="operation" label="Operation" {...itemLayoutOptions}>
            <FieldSelect
              value={operation}
              // style={STYLE_OPTIONS}
              onChange={this.onSelectChange('operation')}
              selectOptions={MATH_OPERATIONS}
              data-test="operation"
            />
          </Item>
        </Row>
        <Row>
          <Item key="rounding" label="Rounding" {...itemLayoutOptions}>
            <FieldSelect
              value={rounding}
              selectOptions={MATH_ROUNDING}
              // style={STYLE_OPTIONS}
              onChange={this.onSelectChange('rounding')}
              placeholder="Please select"
              data-test="rounding"
            />
          </Item>
        </Row>
        <Row>{this.renderOperands()}</Row>
        <Row>
          <Alert
            message="Only Number and Select fields can be used as operands. For Select fields, all of the values must be set as numbers for the field to be used as an operand."
            type="info"
          />
        </Row>
      </Fragment>
    );
  };
}
