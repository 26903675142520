import React, { Component } from 'react';
import get from 'lodash/get';

import { hasPermissions } from 'APP_ROOT/utils/admin';
import { PERMISSIONS } from '../../../../utils/admin';

export default (WrappedComponent, redirectTo = 'administratorAgency') =>
  class AgencyOwnerGuard extends Component {
    constructor(props) {
      super(props);

      const hasOwnership = this.checkAgencyVisibilityAuthorization();

      this.state = {
        hasOwnership,
      };
    }

    componentDidUpdate() {
      this.checkAgencyVisibilityAuthorization();
    }

    checkAgencyVisibilityAuthorization = () => {
      const { session, match, history, route } = this.props;
      const agencyId = get(match, 'params.agencyId');
      const permissions = get(session, 'currentUser.permissions', []);
      const currentUserAgencyId = get(session, 'currentUser.agencyId');
      const isSuperAdmin = hasPermissions(
        permissions,
        PERMISSIONS.manageAgencies
      );
      const isNotAgencyOwner = Number(agencyId) !== Number(currentUserAgencyId);

      if (!isSuperAdmin && isNotAgencyOwner) {
        history.replace(route(redirectTo, { agencyId: currentUserAgencyId }));

        return false;
      }

      return true;
    };

    getOwnershipProps = () => {
      const { hasOwnership } = this.state;

      return Object.assign({}, this.props, { hasOwnership });
    };

    render() {
      if (WrappedComponent) {
        return React.createElement(WrappedComponent, this.getOwnershipProps());
      }

      return null;
    }
  };
