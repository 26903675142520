import styled from 'styled-components';

const StyledInput = styled.input`
  width: 100%;
  height: 35px;
  padding: 0 5px;
  border: 1px solid lightgray;
  box-sizing: border-box;
`;

export const StyledDiv = styled.div`
  color: #ddd;
  line-height: 18px;
  width: 175px;
  white-space: nowrap;
  text-align: right;
  margin-right: 12px;

  .label {
    font-weight: bold;
    font-size: 14px;
  }
`;

export default StyledInput;
