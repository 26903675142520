import LogActionsEndpoints from '../../../api/organization/logActionsEndpoints';

export default (
  auditPageNumber,
  auditPageSize,
  endDate,
  executedBy,
  initialDate,
  tableNames,
  tenantId,
  userId
) => {
  return new Promise((resolve, reject) => {
    LogActionsEndpoints.getLogActionsAsCsv(
      auditPageNumber,
      auditPageSize,
      endDate,
      executedBy,
      initialDate,
      tableNames,
      tenantId,
      userId
    )
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        return reject(error.message);
      });
  });
};
