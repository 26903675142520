import React, { Component } from 'react';

import { get } from 'lodash';
import applyReadOnlyTransform from 'APP_ROOT/utils/applyReadOnlyTransform';

class VerticalInput extends Component {
  getValue() {
    const { options, data, dataKey } = this.props;
    const { readOnlyTransform } = options;
    const value = get(data, dataKey);
    let transformValue = value;
    if (readOnlyTransform && readOnlyTransform.format) {
      transformValue = applyReadOnlyTransform(value, readOnlyTransform);
    }

    return transformValue;
  }

  render() {
    const { title, className } = this.props;

    return (
      <div className={className}>
        <p className="value">{this.getValue()}</p>
        <p className="label">{title}</p>
      </div>
    );
  }
}
export default VerticalInput;
