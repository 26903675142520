import styled from 'styled-components';
import { Slider } from 'antd';

import { ACCENT } from '../../../config/theme';

export const StyledSlider = styled(Slider).attrs({
  className: 'bdm-panel-slider',
})`
  .ant-slider-handle {
    border-color: ${ACCENT};
    background-color: ${ACCENT};
  }

  .ant-slider-mark-text {
    font-size: 14px;
    padding: 16px 0;
  }
`;

export default StyledSlider;
