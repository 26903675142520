import React, { Component, Fragment } from 'react';
import ContentEditable from 'react-contenteditable';

import { Button, Icon, Row, Col, Spin, notification, Modal } from 'antd';
import { pick, get, kebabCase, toPath, isEmpty, set } from 'lodash';
import { connect } from 'react-redux';

import storage from 'APP_ROOT/utils/storage';
import removeAttachment from 'APP_ROOT/actions/remove-attachment';
import fetchFormAttachments from 'APP_ROOT/actions/fetch-form-attachments';
import deleteFormAttachment from 'APP_ROOT/actions/delete-form-attachment';
import { FORM_IDENTIFIERS } from 'APP_ROOT/constants/form-types';
import AttachmentsWarning from '../../../common/attachments-warning';
import onStateChange from 'APP_ROOT/actions/on-upload-state-change';
import { getAgencyTZ } from 'APP_ROOT/selectors/session';
import { StyledLineDivide, StyledUpload } from '../input-upload/styled';
import FILE_STATUSES from '../input-upload/file-statuses.constants';
import getURL from '../input-upload/utils/getURL';
import uploadOverride from '../input-upload/utils/uploadOverride';
import onBeforeUpload from '../input-upload/utils/onBeforeUpload';
import setFileUploaded from '../input-upload/utils/setFileUploaded';
import saveDraft from '../input-upload/utils/saveDraft';
import isEditableStatus from '../input-upload/utils/isEditableStatus';
import renderAttachmentAsOwner from '../input-upload/utils/renderAttachmentAsOwner';
import renderAttachmentAsReviewer from '../input-upload/utils/renderAttachmentAsReviewer';

import saveFieldDataValue from '../saveFieldDataValue';

import formActionDispatcher from 'APP_ROOT/utils/formDispatchEmitter';
import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';

import { getDataSelector } from 'APP_ROOT/utils/renderSchema';

import { NUMBER, TEXT } from '../../../../constants/fieldTypes';

import FileSizeLimit from '../../forms/utils/fileSizeLimit';

import { manualAutoSave } from '../../../utils/auto-save';

const [DONE, ERROR, UPLOADING] = FILE_STATUSES;

class InputSingleUpload extends Component {
  state = {};

  componentDidMount() {
    this.fetchAttachments(this.props);
  }

  componentDidUpdate(prevProps) {
    const { fileUploaded } = this.props;
    if (prevProps.fileUploaded !== fileUploaded && fileUploaded) {
      saveDraft(this.props);
    }
  }

  onRemoveFile = attachment => {
    const {
      form: { resetFields },
    } = this.props;
    const fieldId = this.getFieldId();
    const fieldNotes = this.getFieldNotes();

    if (attachment.status === UPLOADING) {
      this.uploader.handleManualRemove(attachment.originFileObj);
    } else {
      this.onRemoveFileOnStorage();
    }
    resetFields([fieldId.key, fieldNotes.key]);
  };

  onRemoveFileOnStorage = () => {
    const {
      meta: { id = '', formType = '' } = {},
      dataKey,
      settings,
    } = this.props;
    const { attachment } = this.state;

    const callback = () => {
      this.setState(
        {
          attachment: {},
        },
        () => {
          const fieldId = this.getFieldId();
          const fieldNotes = this.getFieldNotes();
          saveFieldDataValue(
            { ...this.props, dataKey: fieldId.key, value: 0 },
            undefined
          );
          saveFieldDataValue(
            { ...this.props, dataKey: fieldNotes.key, value: '' },
            undefined
          );

          this.onStateChange();
          saveDraft(this.props);
        }
      );
    };

    const actionParams = {
      kind: FORM_IDENTIFIERS[kebabCase(formType)],
      formId: id,
      field: dataKey,
      attachmentId: attachment.id,
      singleUpload: true,
    };
    if (attachment.status === DONE) {
      formActionDispatcher(
        settings.formName,
        deleteFormAttachment(actionParams, callback)
      );
    } else {
      formActionDispatcher(
        settings.formName,
        removeAttachment(actionParams, callback)
      );
    }
  };

  createDoneState = file => {
    const {
      data: { activeUser },
    } = this.props;
    const { status, response = {}, originFileObj = {} } = file;

    return {
      uploaded: true,
      attachment: {
        id: response.id,
        status,
        progress: file.percent,
        name: file.name,
        originFileObj,
        ownerId: activeUser,
      },
    };
  };

  getFieldId = () => {
    const { properties = [] } = this.props;
    return properties.find(p => p.field_type === NUMBER);
  };

  getFieldNotes = () => {
    const { properties = [] } = this.props;
    return properties.find(p => p.field_type === TEXT);
  };

  getFieldKey = field => {
    const { parentKey, parentIndex } = this.props;
    const { key } = field;
    return parentKey ? `${parentKey}[${parentIndex}].${key}` : key;
  };

  updateFormValues = () => {
    const { isReviewer, settings } = this.props;
    const { attachment } = this.state;
    const fieldId = this.getFieldId();

    if (attachment && !isReviewer) {
      saveFieldDataValue(
        { ...this.props, dataKey: fieldId.key },
        attachment.id
      );
    }
    emitFormEvent('onAttachmentsFetched', settings.formName);
  };

  getPayload = () => {
    const { attachment } = this.state;

    return attachment.status === DONE
      ? [pick(attachment, ['id', 'notes'])]
      : [{}];
  };

  triggerAutoSave = fileId => {
    const {
      parentKey,
      selectedForm: { id },
      settings: { agencyId },
    } = this.props;
    const fieldId = this.getFieldId();
    const key = this.getFieldKey(fieldId);
    let toSave = {};
    if (parentKey) {
      const { data } = this.props;
      set(toSave, parentKey, data[parentKey]);
    }
    set(toSave, key, fileId);
    manualAutoSave(id, agencyId, toSave);
  };

  onChangeDoneState = file => {
    notification.success({
      message: 'Uploaded successfully!',
      description: `File: ${file.name}`,
    });

    this.setState(this.createDoneState(file), () => {
      this.updateFormValues();
      this.onStateChange();
      this.triggerAutoSave(file?.response?.id);
    });
  };

  onChangeErrorState = file => {
    const {
      data: { activeUser },
    } = this.props;
    const { status } = file;

    const { error } = file.response || file;
    notification.error({
      message: `Uploaded "${file.name}" failed!`,
      description: error.message,
    });
    const newAttachment = {
      id: file.uid,
      status,
      error: file.error,
      name: file.name,
      ownerId: activeUser,
    };

    this.setState(
      {
        uploaded: true,
        attachment: newAttachment,
      },
      () => {
        this.updateFormValues();
        this.onStateChange();
      }
    );
  };

  onChangeUploadingState = file => {
    const {
      data: { activeUser },
    } = this.props;
    const { originFileObj = {} } = file;

    this.setState(
      {
        uploaded: false,
        attachment: {
          id: originFileObj.uid,
          status: file.status,
          progress: file.percent,
          name: file.name,
          originFileObj,
          ownerId: activeUser,
        },
      },
      () => {
        this.onStateChange();
        setFileUploaded(this.props);
      }
    );
  };

  onChange = ({ file = {} }) => {
    const { status } = file;

    switch (status) {
      case DONE:
        this.onChangeDoneState(file);
        break;
      case ERROR:
        this.onChangeErrorState(file);
        break;
      case UPLOADING:
        this.onChangeUploadingState(file);
        break;
      default:
        break;
    }

    return this.getPayload();
  };

  isReviewer = () => {
    if (this.props.isContributeReport) {
      if (this.props.contributorAssignmentCanEdit) {
        return false;
      } else if (this.props.contributorAssignmentCanView) {
        return true;
      }
    }
    return !!this.props.isReviewer;
  };

  onStateChange = () => {
    const { dispatch } = this.props;
    const { attachment = {} } = this.state;
    dispatch(onStateChange([attachment]));
  };

  saveUpload = node => {
    if (this.uploader) return null;
    this.uploader = node;
  };

  getUploadedFileUser = userId => {
    const {
      data: { __users: users },
    } = this.props;

    const { fullName, rank } = get(users, userId, {});
    return { fullName, rank };
  };

  fetchAttachments = props => {
    const {
      dataKey,
      meta: { id = '' } = {},
      settings,
      parentKey,
      parentIndex,
      data,
    } = props;
    const fieldId = this.getFieldId();
    const fieldNotes = this.getFieldNotes();
    const fieldIdKey = this.getFieldKey(fieldId);
    const fieldNotesKey = this.getFieldKey(fieldNotes);

    const idData = get(data, fieldIdKey, '');
    const notesData = get(data, fieldNotesKey, '');
    let singleUpload;
    if (fieldId) {
      singleUpload = {
        parentKey,
        parentIndex,
        id: fieldId.key,
        notes: fieldNotes.key,
        key: dataKey,
      };
    }
    if (!id) {
      return;
    }

    // if the form is closed, there is no 'reviewNotes' form, to see the attachment
    // we need to use 'form' as formName, keeping settings.formName for all the
    // other cases... you never know :)
    formActionDispatcher(
      settings.formName === 'reviewNotes' ? 'form' : settings.formName,
      fetchFormAttachments(
        {
          id: id,
          field: dataKey,
          filters: {
            include: {
              attachable: [{ submitter: ['rank'] }],
            },
            where: {
              cancelled: false,
            },
          },
          settings,
          singleUpload,
        },
        async (error, att) => {
          if (error) {
            return false;
          }
          const [, attachments] = att;
          const attch = attachments.find(
            attachment => attachment.id === idData
          );
          const attachment = attch
            ? {
                id: attch.id,
                status: DONE,
                notes: notesData || '',
                owner: attch.owner,
                name: attch.originalFilename,
                updated: attch.updated,
                fileUploadedBy: this.getUploadedFileUser(attch.benchmarkUserId),
                attachable: attch.attachable,
              }
            : undefined;

          this.setState(
            {
              attachment,
            },
            () => {
              this.updateFormValues();
            }
          );
        }
      )
    );
  };

  onTextFieldChange = (key, evt) => {
    const notes = evt.target.value;

    const fieldNotes = this.getFieldNotes();
    saveFieldDataValue({ ...this.props, dataKey: fieldNotes.key }, notes);
  };

  getFieldRulesValidation = field => {
    const { parentKey, validations: { rules = {} } = {} } = this.props;
    const { key } = field;
    let fieldRules;
    if (parentKey) {
      const uploadRules = rules[parentKey] || [];
      fieldRules = get(uploadRules, toPath(`0.item.0.fields.${key}`), []);
    } else {
      fieldRules = rules[key] || [];
    }
    return fieldRules;
  };

  // information modal data
  showModal = (title, information) => () => {
    Modal.info({
      title: title,
      content: (
        <ContentEditable
          className="ql-editor"
          html={information}
          disabled={true}
        />
      ),
    });
  };

  renderInformation = () => {
    const { options } = this.props;
    const { information } = options;

    return information ? (
      <div className="info-tooltip-wrapper info-tooltip-wrapper-formfield">
        {information && (
          <Icon
            type="info-circle"
            onClick={this.showModal('Important', information)}
          />
        )}
      </div>
    ) : (
      <Fragment></Fragment>
    );
  };

  renderAsOwner = attachment => {
    const fieldNotes = this.getFieldNotes();
    const fieldDataKey = this.getFieldKey(fieldNotes);
    const notesRules = this.getFieldRulesValidation(fieldNotes);
    const allowRemove = attachment.status === DONE;
    return renderAttachmentAsOwner({
      props: this.props,
      setState: (...params) => this.setState(...params),
      attachment,
      fieldDataKey,
      fieldDataOriginalKey: fieldDataKey,
      notesRules,
      allowRemove,
      onRemoveFile: () => this.onRemoveFile(attachment),
      onTextFieldChange: (...params) => this.onTextFieldChange(...params),
    });
  };

  renderAsReviewer = (attachment = {}, timezone = null) => {
    const fieldNotes = this.getFieldNotes();
    const fieldDataKey = fieldNotes.key;

    return renderAttachmentAsReviewer({
      props: this.props,
      setState: (...params) => this.setState(...params),
      fieldDataKey,
      attachment,
      timezone,
    });
  };

  renderAttachment = () => {
    const { attachment = {} } = this.state;
    const { timezone, isPrinting } = this.props;
    const hasAttachment = !!attachment.id;

    return hasAttachment ? (
      <Row gutter={50}>
        {[ERROR, UPLOADING].includes(attachment.status) ||
        (isEditableStatus(this.props) && !isPrinting && !this.isReviewer())
          ? this.renderAsOwner(attachment)
          : this.renderAsReviewer(attachment, timezone)}
      </Row>
    ) : (
      <Fragment></Fragment>
    );
  };

  render() {
    const { attachments: { loading } = {} } = this.props;
    const { attachment = {} } = this.state;
    const hasAttachment = !!attachment.id;

    return (
      <Fragment>
        {!this.isReviewer() && <AttachmentsWarning />}
        {this.renderInformation()}
        <Spin spinning={loading}>
          <Row gutter={50}>
            <Col span={24}>{this.renderAttachment()}</Col>
            {loading && hasAttachment && (
              <Col span={24}>
                <h4>Retrieving attachment&hellip;</h4>
              </Col>
            )}
          </Row>
        </Spin>

        {!this.isReviewer() && !hasAttachment && (
          <Row gutter={50}>
            <Col span={24} className="text-right">
              <StyledLineDivide />
              <StyledUpload
                showUploadList={false}
                beforeUpload={onBeforeUpload(this.props)}
                name="singleUpload"
                maxCount={1}
                headers={{
                  Authorization: storage.get('token'),
                }}
                action={getURL(this.props)}
                customRequest={uploadOverride(this.props)}
                onChange={this.onChange}
                onRemove={this.onRemoveFileOnStorage}
                ref={this.saveUpload}
              >
                <Button>
                  <Icon type="upload" />
                  Single Upload
                </Button>
                <FileSizeLimit />
              </StyledUpload>
            </Col>
          </Row>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    attachments,
    form: {
      selectedForm: {
        meta: { workFlowData = {} } = {},
        isAdminEdit = false,
      } = {},
      fileUploaded,
    } = {},
  } = state;
  const dataSelector = getDataSelector(props);
  const data = dataSelector(state, props);
  const meta = (isEmpty(props.meta) ? data.meta : props.meta) || {};

  return {
    timezone: getAgencyTZ(state),
    attachments,
    workFlowData,
    data,
    meta,
    fileUploaded,
    isAdminEdit,
  };
};

export default connect(mapStateToProps, null)(InputSingleUpload);
