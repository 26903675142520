import React, { Fragment } from 'react';
import { Row, Form, Cascader, Alert } from 'antd';
import { isEmpty } from 'lodash';

import FieldSelect from '../../components/FieldSelect';
import { TIME_UNITS } from '../../constants/constants';

import disableCascaderOptions from './disableCascaderOptions';

const STYLE_OPTIONS = { width: 260 };
const { Item } = Form;
const itemLayoutOptions = {
  colon: false,
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default class DurationFieldOptions extends React.Component {
  constructor(props) {
    super(props);
  }

  onSelectChange = key => date => {
    const { onChange, value } = this.props;
    const newValue = { [key]: date };
    let change;

    switch (key) {
      case 'units':
        change = newValue;
        break;
      case 'initial':
        change = { ...value, ...newValue, final: undefined };
        break;
      default:
        change = { ...value, ...newValue };
        break;
    }

    onChange && onChange(change);
  };

  getChildOption = (child, omitKey, disabled = false) =>
    omitKey === child.value
      ? { ...child, disabled: true }
      : { ...child, disabled };

  filterOptions = (omitKey = '', onlyRepeater = false) => {
    const repeater = this.props.parentRepeater;
    const cascaderOptions = this.props.getCascaderOptions();
    return cascaderOptions.map(o => ({
      ...o,
      children: o.children.map(c => {
        const { value, children } = c;
        if (children !== undefined) {
          const disabled = value !== repeater;
          return {
            ...c,
            children: children.map(child =>
              this.getChildOption(child, omitKey, disabled)
            ),
            disabled,
          };
        }
        return this.getChildOption(c, omitKey, repeater && onlyRepeater);
      }),
    }));
  };

  render = () => {
    const { value = {}, parentRepeater } = this.props;
    const { initial, final, units } = value;
    const cascaderOptions = this.props.getCascaderOptions();
    const initialOptions = disableCascaderOptions(
      cascaderOptions,
      parentRepeater
    );
    const finalOptions = disableCascaderOptions(
      cascaderOptions,
      parentRepeater,
      [initial]
    );
    return (
      <Fragment>
        <Row>
          <Item key="unit" label="Units" {...itemLayoutOptions}>
            <FieldSelect
              value={units}
              style={STYLE_OPTIONS}
              onChange={this.onSelectChange('units')}
              selectOptions={TIME_UNITS}
              data-test="duration-units"
            />
          </Item>
        </Row>
        <Row>
          <Item key="initial-date" label="Initial date" {...itemLayoutOptions}>
            <Cascader
              value={initial}
              options={initialOptions}
              style={STYLE_OPTIONS}
              onChange={this.onSelectChange('initial')}
              placeholder="Please select"
              data-test="duration-initial"
            />
          </Item>
        </Row>
        <Row>
          <Item key="final-date" label="Final date" {...itemLayoutOptions}>
            <Cascader
              value={final}
              options={finalOptions}
              style={STYLE_OPTIONS}
              onChange={this.onSelectChange('final')}
              placeholder="Please select"
              disabled={isEmpty(initial)}
              data-test="duration-final"
            />
          </Item>
        </Row>
        <Row>
          <Alert
            message="Final date must be greater than initial date, Example: Initial date = '03/06/2000' and final date = '08/27/2020', duration is 20 years"
            type="info"
          />
        </Row>
      </Fragment>
    );
  };
}
