import { sortBy } from 'lodash';
import { momentWithTZ } from './parse-date';
import FORM_TYPES from '../constants/form-types';

function normalizeHistoryArray(
  result,
  { id, history, participantsId, formType, formNumber },
  currentIndex,
  array
) {
  result.history = [
    ...result.history,
    ...history.map(historyItem => {
      const transformedHistoryItem = { ...historyItem, id };
      if (array.length > 1) {
        transformedHistoryItem['formType'] = formType;
        transformedHistoryItem['formNumber'] = formNumber;
      }
      return transformedHistoryItem;
    }),
  ];
  result.participantsIds = {
    ...result.participantsIds,
    [id]: participantsId,
  };
  return result;
}

const getOrderFieldByType = item => {
  switch (item.type) {
    case 'timeout':
      return item.fromDate;
    case 'workflow':
    default:
      return item.created;
  }
};

const filterValidHistoryItems = item => {
  if (item.type === 'timeout') {
    return !['start', 'stop'].includes(item.action);
  }

  return true;
};

/**
 * Function intends to filter history items according to arbritary rules.
 * @param {object} normalizedRecords
 * @return {object}
 */
function filterNormalizedRecordHistoryItems(normalizedRecords) {
  const intakeInvestigationDone = 'investigate incident.done';
  switch (normalizedRecords.formType) {
    case FORM_TYPES.intake:
      /**
       * Intake form types shouldn't show investigation done actions.
       */
      return {
        ...normalizedRecords,
        history: normalizedRecords.history.filter(
          item => item.action !== intakeInvestigationDone
        ),
      };
    default:
      return normalizedRecords;
  }
}

/**
 * @param {object} form
 * @param {string} timezone Eg. America/Chicago
 * @param {boolean} mergeLinkedSubmissions If true, will merge the form history with the linkedSubmissions history.
 * @return {Object}
 */
export default function transformFormToNormalizedTimeline(
  form,
  timezone,
  mergeLinkedSubmissions = true
) {
  const {
    id,
    formType,
    formNumber,
    transactionHistory: history = [],
    participants = [],
    participantsId = [],
    linkedSubmissions = [],
    notes = [],
    number,
    agencyId,
    usersOnBehalf = [],
    __assignedSectionsHistory = [],
  } = form;
  let normalizedRecords = [
    {
      id,
      history: [...history, ...__assignedSectionsHistory],
      participants,
      participantsId,
      formType,
      formNumber,
      usersOnBehalf,
    },
  ];
  if (mergeLinkedSubmissions) {
    normalizedRecords = normalizedRecords.concat(linkedSubmissions);
  }
  normalizedRecords = normalizedRecords.reduce(normalizeHistoryArray, {
    participantsIds: {},
    history: [],
    formType,
  });

  normalizedRecords.usersOnBehalf = usersOnBehalf;
  normalizedRecords.participants = participants.reduce(
    (result, participant) => ({
      ...result,
      [participant.id]: participant,
    }),
    {}
  );

  normalizedRecords.history = sortBy(normalizedRecords.history, item =>
    momentWithTZ(getOrderFieldByType(item), timezone)
  ).filter(filterValidHistoryItems);
  return {
    timezone,
    notes,
    number,
    agencyId,
    ...filterNormalizedRecordHistoryItems(normalizedRecords),
  };
}
