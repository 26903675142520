import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import StyledModal from './IntegrationModal.styled';

const IntegrationModal = ({
  onCancel = null,
  onFetch = null,
  visible = false,
  title = '',
  okText = '',
  cancelText = '',
  content = null,
  readOnly = false,
  integratedWith = '',
}) => {
  return (
    <StyledModal
      className={'integration-picker-modal'}
      title={title}
      visible={visible}
      okText={okText}
      onCancel={onCancel}
      cancelText={cancelText}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {cancelText}
        </Button>,
        <Button
          key="fetch"
          disabled={readOnly}
          type="primary"
          onClick={onFetch}
        >
          {okText}
        </Button>,
      ]}
    >
      {content(integratedWith)}
    </StyledModal>
  );
};

IntegrationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onFetch: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  okText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  content: PropTypes.elementType.isRequired,
  readOnly: PropTypes.bool.isRequired,
  integratedWith: PropTypes.string.isRequired,
};

export default IntegrationModal;
