import React from 'react';
import Table from 'antd/lib/table';
import { Link } from 'react-router-dom';
import Col from 'antd/lib/col';

import route from 'APP_ROOT/utils/get-route';

import Row from './Row.styled';

const { Column } = Table;

const AgenciesTable = ({ dataSource = [], onChange, loading }) => (
  <Row>
    <Col span={24}>
      <div className="responsive-table">
        <Table
          dataSource={dataSource}
          onChange={onChange}
          loading={loading}
          rowKey={record => record.id}
          pagination={false}
        >
          <Column
            title="Name"
            key="name"
            render={(text, record) => (
              <Link
                to={route('administratorAgency', {
                  agencyId: record.id,
                })}
              >
                {record.name}
              </Link>
            )}
          />
          <Column title="Address" dataIndex="address" key="address" />
          <Column
            title="Number of Users"
            dataIndex="usersCount"
            key="numberOfUsers"
          />
        </Table>
      </div>
    </Col>
  </Row>
);

export default AgenciesTable;
