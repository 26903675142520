import { camelCase } from 'lodash';

import RolesList from './components/RolesList/RolesList';
import Role from './components/Role/Role';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/roles-permissions/new-role`,
    name: camelCase(`${name}-roles-permissions-new-role`),
    component: Role,
    protectedWith: ['createRole', 'assignRole'],
  },
  {
    path: `${path}/roles-permissions`,
    name: camelCase(`${name}-roles-permissions`),
    component: RolesList,
    protectedWith: ['createRole', 'assignRole'],
    childRoutes: [
      {
        path: `${path}/roles-permissions/:permission/:roleId/:agencyName`,
        name: 'permissionAttributes',
      },
    ],
  },
];
