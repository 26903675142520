import React, { useRef } from 'react';
import { Table, Input, Button, Icon } from 'antd';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  thead[class*='ant-table-thead'] th {
    background-color: lightgray;
  }

  .ant-table-row {
    background: white;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: unset;
  }
`;

/**
 * Callback function used to fetch table data.
 * @callback tableFetchDataCallback
 * @param {Object} pagination - Object containing pagination information.
 * @param {string} pagination.currentPage - Current page number.
 * @param {string} pagination.pageSize - Number of items per page.
 * @param {Object} filters - Key-value pairs of columns and filtering criteria.
 * @param {Object} sorter - Sorting configuration.
 * @param {string} sorter.column - Column to sort by.
 * @param {string} sorter.order - Sorting order, either 'ascend' or 'descend'.
 */

/**
 * A filterable table component with customizable columns and pagination.
 * @param {Object[]} columns - Columns to be displayed in the table.
 * @param {string} columns[].dataIndex - The field name of the column in the data array.
 * @param {string} columns[].key - Unique key for the column.
 * @param {string} columns[].title - Title/header of the column.
 * @param {Object[]} data - Array of data objects to be displayed as rows in the table.
 * @param {tableFetchDataCallback} fetchDataCallback - Callback function to request data.
 * @param {string} rowKey - Key in the data object to be used as the unique identifier for each row.
 * @param {number} [pageSize=10] - Number of items per page.
 * @param {number} [totalRows=0] - Total number of rows of data in the backend.
 * @param {Function} [recordRenderCallback] - Callback function to customize the rendering of table cell data.
 * @param {boolean} [loading=false] - Indicates whether the table is in a loading state.
 * @param {boolean} [sorter=true] - Indicates whether column sorting is enabled.
 * @param {boolean} [filterToggle=true] - Indicates whether column filtering is enabled.
 */
function FilterableColumnTable({
  columns,
  data,
  fetchDataCallback,
  rowKey,
  loading = false,
  sorter = true,
  pageSize = 10,
  totalRows = 0,
  recordRenderCallback,
  filterToggle = true,
}) {
  const searchInput = useRef(null);

  const handleTableChange = (pagination, filters, sorter) => {
    const parsedPagination = {
      currentPage: pagination.current,
      pageSize: pageSize,
    };

    // antd stores filters as arrays of a single value in this case
    // this code transforms from { field: value[] } to { field: value }
    const parsedFilters = {};
    for (const key in filters) {
      parsedFilters[key] = filters[key].join();
    }

    const parsedSort = {
      // a condition could be added here to select between the column's dataIndex (field) and key
      column: sorter.field ?? '',
      order: sorter.order ?? '',
    };

    fetchDataCallback(parsedPagination, parsedFilters, parsedSort);
  };

  const getColumnSearchProps = column => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput.current = node;
          }}
          placeholder={`Search ${column.dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={confirm}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={confirm}
          icon="search"
          size="small"
          style={{ marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: recordRenderCallback
      ? (text, record) => recordRenderCallback(text, record, column.dataIndex)
      : text => text,
  });

  const processedColumns = columns.map(column => {
    // If the toggle for filters exist for table and or a render method is
    const condition =
      filterToggle &&
      !column.hasOwnProperty('filters') &&
      !column.hasOwnProperty('notFilterable');
    const defaultAttributes = (condition && getColumnSearchProps(column)) || {};
    return {
      ...column,
      ...defaultAttributes,
      sorter: sorter,
    };
  });
  const footer = () => `Total number of records: ${totalRows}`;

  return (
    <StyledTable
      columns={processedColumns}
      dataSource={data}
      onChange={handleTableChange}
      rowKey={rowKey}
      loading={loading}
      pagination={{
        pageSize: pageSize,
        total: totalRows,
      }}
      footer={footer}
    />
  );
}

export default FilterableColumnTable;
