import { kebabCase } from 'lodash';
import LoaderOfficerBio from '../components/RenderSchema/loaders/LoaderOfficerBio';
import LoaderOfficerProfile from '../components/RenderSchema/loaders/LoaderOfficerProfile';

export const LOADER_TYPES = {
  LOADER_OFFICER_BIO: kebabCase('Officer Information'),
  LOADER_OFFICER_PROFILE: kebabCase('Officer Profile'),
};

export const LOADER_COMPONENTS = {
  [LOADER_TYPES.LOADER_OFFICER_BIO]: LoaderOfficerBio,
  [LOADER_TYPES.LOADER_OFFICER_PROFILE]: LoaderOfficerProfile,
};
