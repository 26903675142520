import _ from 'lodash';

import { FETCH_ACTIONS_REQUEST } from './';

import { fetchNotifications } from '../api/activity';
import fetchActionsSuccess from './fetch-action-items-success';
import fetchActionsError from './fetch-action-items-error';
import fetchActionItemsCount from './fetch-action-items-count';

export default (next = () => {}) => {
  return (dispatch, getState) => {
    const {
      session: { currentUser: { userId } = {} } = {},
      activity: { actions: { filter = {} } = {} } = {},
    } = getState();
    dispatch({ type: FETCH_ACTIONS_REQUEST });

    return fetchNotifications(userId, filter)
      .then(async response => {
        await Promise.all([
          dispatch(fetchActionsSuccess(response)),
          dispatch(fetchActionItemsCount()),
        ]);
        next(null, response);
      })
      .catch(error => {
        dispatch(fetchActionsError(_.get(error, ['response', 'status'])));
        next(error);
      });
  };
};
