import styled from 'styled-components';
import { Menu } from 'antd';
import {
  SIDEBAR_HOVER,
  SIDEBAR_INACTIVE,
  SIDEBAR_ACTIVE_BG,
  SIDEBAR_ACTIVE_STROKE,
  SIDEBAR_HOVER_BG,
  SIDEBAR_ICON,
} from '../../../config/theme';

const MenuItem = styled(Menu.Item)`
  && {
    padding-left: 20px !important;
  }

  color: ${SIDEBAR_INACTIVE};

  &.ant-menu-item,
  &.ant-menu-item:not(:last-child) {
    margin-bottom: 0;
    margin-top: 0;
    height: 42px;
    line-height: 42px;
  }

  .anticon {
    font-size: 20px;
    vertical-align: middle;
    color: ${SIDEBAR_ICON};
  }

  .bdm-icon {
    font-size: 20px;
    color: ${SIDEBAR_ICON};
  }

  .nav-text {
    margin-top: 4px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  img {
    display: inline-block;
    width: 18px;
    vertical-align: middle;
    color: ${SIDEBAR_ICON};
  }

  &:hover {
    background-color: ${SIDEBAR_HOVER_BG};
    color: ${SIDEBAR_HOVER};
  }

  &.ant-menu-item-selected {
    padding-left: 15px !important;
    background-color: ${SIDEBAR_ACTIVE_BG};
    border-left: solid 5px ${SIDEBAR_ACTIVE_STROKE};
  }

  &:active {
    background-color: ${SIDEBAR_ACTIVE_BG};
  }
`;

export const LogoutButton = styled(MenuItem)`
  & .anticon {
    color: red;
  }
`;

export default MenuItem;
