import { genOptions, route, fetchRequest } from '../utils/request';
import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';

export const UOF_STATS_CATEGORY = 'use-of-force';
export const IA_STATS_CATEGORY = 'internal-affairs';

export const getStats = (userId = '', statsCategory, filters = {}) => {
  const token = storage.get('token');
  const url = route(
    urlBuilder(
      `/BenchmarkUsers/:userId/stats/:statsCategory`,
      { userId, statsCategory },
      filters
    )
  );
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

export const getStatsUOF = (userId = '', filters = {}) =>
  getStats(userId, UOF_STATS_CATEGORY, filters);
export const getStatsIA = (userId = '', filters = {}) =>
  getStats(userId, IA_STATS_CATEGORY, filters);
