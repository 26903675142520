export const onChangeTriggerTypes = [
  'switch',
  'multiselect',
  'select',
  'time',
  'date',
  'date-time',
  'masked',
  'dynamic-select',
  'autocomplete',
  'number',
  'checkbox',
];

const getValidateTrigger = props => {
  const { field_type: type = '' } = props;

  const validateOnChange = onChangeTriggerTypes.includes(type);

  if (validateOnChange) {
    return 'onChange';
  }

  return 'onBlur';
};

export default getValidateTrigger;
