import React from 'react';
import styled from 'styled-components';
import { Tag as AntTag } from 'antd';
import classNames from 'classnames';

import {
  PURPLE,
  ORANGE,
  GREEN,
  YELLOW,
  SILVER,
  PINK,
  DARK_GRAY,
} from '../../config/theme';

const statusTagClass = 'is-status-tag';

const getClasses = (isStatusTag, otherClassNames) =>
  classNames({
    [statusTagClass]: isStatusTag,
    ...otherClassNames,
  });

const Tag = ({
  statusTag = false,
  size = 'default',
  type = 'draft',
  className = null,
  ...props
}) => (
  <StyledTag
    className={getClasses(statusTag, {
      'tag-large': size === 'large',
      [`status-${type || 'draft'}`]: true,
      [className]: !!className,
    })}
    {...props}
  />
);

const StyledTag = styled(AntTag)`
  /* stylelint-disable */
  ${props => {
    if (props.className.indexOf(statusTagClass) !== -1) {
      return `
        &.ant-tag {
          border-radius: 24px;
          padding: 8px 16px;
          font-size: 16px;
          font-weight: 600;
          height: 32px;
          color: ${DARK_GRAY};
        }
      `;
    }

    return '';
  }} &.ant-tag {
    &.status-approved,
    &.status-in-review,
    &.status-in-progress,
    &.status-draft,
    &.status-take-action,
    &.status-closed,
    &.status-done,
    &.status-completed,
    &.status-pending,
    &.status-command-review,
    &.status-voided {
      color: #fff;
      // min-width: 86px;
      text-align: center;
    }

    &.status-draft {
      background-color: ${PURPLE};
      border-color: ${PURPLE};
    }

    &.status-pending {
      background-color: ${PINK};
      border-color: ${PINK};
    }

    &.status-in-review,
    &.status-command-review {
      background-color: ${ORANGE};
      border-color: ${ORANGE};
    }

    &.status-in-progress {
      background-color: ${ORANGE};
      border-color: ${ORANGE};
    }

    &.status-approved,
    &.status-done,
    &.status-closed,
    &.status-completed {
      background-color: ${GREEN};
      border-color: ${GREEN};
    }

    &.status-voided {
      background-color: ${SILVER};
      border-color: ${SILVER};
    }

    &.status-take-action {
      background-color: ${YELLOW};
      border-color: ${YELLOW};
    }
  }
`;

export default Tag;
