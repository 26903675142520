import { genOptions, route, fetchRequest } from '../utils/request';
import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';

export const registerNewDocument = (userId = '') => {
  const url = route(urlBuilder('/UserDocuments'));
  const options = genOptions('POST', { userId }, storage.get('token'));

  return fetchRequest(url, options);
};
