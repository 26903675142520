import { get, has } from 'lodash';

import { GET_OFFICERS } from './';

import organizationEndpoints from '../api/organization/organizationEndpoints';
import getOfficersSuccess from './get-officers-success';
import getOfficersError from './get-officers-error';

export default (payload = {}, next = () => {}) => {
  return (dispatch, getState) => {
    const {
      session: { currentUser: { agencyId = '' } = {} },
    } = getState();
    dispatch({ type: GET_OFFICERS, payload });

    const officersFilter = {
      where: { and: [{ includeNoSworn: true }] },
      include: 'rank',
    };
    let { filter = officersFilter } = payload;
    const { options } = payload;
    const {
      tenantUsers = false,
      orCriteria,
      filterNonOpUsers = false,
    } = options;
    filter = extractSworn(filter, options);
    organizationEndpoints
      .getUsersSearch(
        agencyId,
        filter,
        !tenantUsers,
        false,
        orCriteria,
        filterNonOpUsers
      )
      .then(response => {
        const officers = response.map(officer => ({
          ...officer,
          ...get(officer, 'person', {}),
          id: get(officer, 'id') + [],
        }));
        dispatch(getOfficersSuccess(officers, payload));
        next(null, officers);
      })
      .catch(error => {
        dispatch(getOfficersError(get(error, ['response', 'status'])));
        next(error);
      });
  };
};

function extractSworn(filter, options) {
  if (has(options, 'filter.where.sworn')) {
    filter.sworn = get(options, 'filter.where.sworn');
  }
  return filter;
}
