import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import ContentLoader from 'react-content-loader';

const WorkflowList = ({
  className,
  workflows = [],
  getWorkflowLink,
  isLoading,
}) => (
  <div className={cx(className)}>
    {!isLoading &&
      workflows.map(workflow =>
        workflow.selfServiceCreated && !workflow.sealed ? (
          <Link
            className="workflow-item"
            key={workflow.id}
            to={getWorkflowLink(workflow.id)}
          >
            <Icon type="arrow-right" />
            <div className="workflow-item-name">{workflow.name}</div>
          </Link>
        ) : (
          <div className="workflow-item is-disabled" key={workflow.id}>
            <div className="workflow-item-name">{workflow.name}</div>
          </div>
        )
      )}

    {isLoading && (
      <div className="workflow-item">
        <Icon type="loading" />
        <div className="workflow-item-name">
          <ContentLoader
            height={16}
            width={100}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <rect x="1" y="0" rx="4" ry="4" width="100" height="14" />
          </ContentLoader>
        </div>
      </div>
    )}
  </div>
);

export default WorkflowList;
