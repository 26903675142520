import styled from 'styled-components';
import { DARK_GRAY } from '../../config/theme';

export const PopOverContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 10px;
  width: 350px;
  height: 350px;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: 0 0 6px -1px #000;
`;

export const HelpButtonContainer = styled.div`
  height: 430px;
  width: 100%;
`;

export const HelpButton = styled.button`
  background-color: #bed2e6;
  color: ${DARK_GRAY};
  cursor: pointer;
  width: 120px;
  height: 46px;
  border-radius: 999rem;
  font-size: 18px;
  font-weight: 500;
  border: none;
  z-index: 999;
  position: fixed;
  bottom: 20px;
  right: 20px;

  & i {
    font-size: 24px;
  }
`;

export const HelpButtonPopOverTitle = styled.div`
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #187ddd;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height: 50px;
  font-weight: 700;
  font-size: 15px;
`;

export const HelpButtonTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
