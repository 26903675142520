import React, { Component } from 'react';
import { Row, Col, Input as InputField } from 'antd';
import { Field } from 'redux-form';

import Section from 'APP_COMPONENTS/form-viewer/styled/section';
import ProfileCardWrapper from '../styled/ProfileCardWrapper';
import LabelInRow from '../styled/LabelInRow';
import Title from 'APP_COMPONENTS/form-viewer/styled/section-title';
import Input from '../../../administrator/components/input';
import getStatusDate from '../helpers';

const TextField = Input(InputField);

class NonFirearm extends Component {
  getCertificationItems(listItems, timezone) {
    return listItems.map(item => {
      return (
        <Row key={item.id}>
          <Col xs={24} sm={8} md={8} lg={8}>
            <LabelInRow>
              {item.course.courseLink ? (
                <a href={item.course.courseLink}>{item.course.name}</a>
              ) : (
                item.course.name
              )}
            </LabelInRow>
          </Col>
          <Col xs={24} sm={8} md={8} lg={8}>
            <Field
              type="text"
              name="completionDate"
              component={TextField}
              defaultValue={getStatusDate(
                item.statusName,
                item.completionDate,
                timezone
              )}
              disabled={true}
              plainText={true}
            />
          </Col>
        </Row>
      );
    });
  }

  render() {
    const { nonFirearmCertifications, timezone } = this.props;
    return (
      <ProfileCardWrapper>
        <Row>
          <Section className="bdm-form-section--no-padding-bottom bdm-form-section--no-margin-bottom">
            <Row>
              <Col className="gutter-row" span={24}>
                <Title size="default">Certifications (non-Firearm)</Title>
              </Col>
            </Row>
            {this.getCertificationItems(nonFirearmCertifications, timezone)}
          </Section>
        </Row>
      </ProfileCardWrapper>
    );
  }
}

export default NonFirearm;
