import { createSelector } from 'reselect';

export const getApplicationState = createSelector(
  state => state.app,
  application => application
);

export const shouldBlocknavigation = createSelector(
  state => state.app,
  application => application.blockNavigation
);

export const sidebarIsCollapsed = createSelector(
  state => state.app,
  application => application.sidebarCollapsed
);
