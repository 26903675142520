import React, { Component } from 'react';
import { Tag, Row, Col } from 'antd';
import { castArray, get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { DragSource } from 'react-dnd';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import withModal from 'APP_COMPONENTS/common/modal/base';

import Styled from './UploadEditor.styled';
import IconButton from '../../../../components/common/buttons/icon-button';
import componentsManager from '../../services/componentsManager';
import buildOptionsModalContent from '../../utils/buildOptionsModalContent';
import getFieldLabel from '../../utils/getFieldLabel';
import getTitle from '../../utils/getTitle';
import getReviewerCssClass from '../../utils/getReviewerCssClass';
import {
  TAB_VALUES,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
} from '../OptionsModalBody/OptionsModalBody';

class UploadEditor extends withModal(Component) {
  constructor(props) {
    super(props);

    this.state = {
      values: props.field,
    };
  }

  componentDidMount() {
    this.createModal();
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(this.props, nextProps);
  }

  remove = () => {
    const { id } = this.props;

    componentsManager.removeComponent(id);
  };

  getValue = (name, defaultValue) =>
    get(this, `state.values.${name}`, defaultValue) || defaultValue;

  showOptionsModal = () => {
    const { values } = this.state;
    const { field } = this.props;

    const fieldsOptions = {
      id: values.id,
      type: values.type,
      reportingKey: {
        type: 'text',
        label: 'Reporting Key',
        value: values.reportingKey,
        maxLength: 100,
        placeholder: 'Reporting key',
      },
      conditions: values.conditions,
      disableTab: [TAB_VALUES, TAB_SHARE_KEY, TAB_INFORMATION],
    };

    const onDelete = () => {
      this.remove();
      this.deleteModal();
    };

    const modalData = buildOptionsModalContent({
      ...fieldsOptions,
      onCancel: this.deleteModal,
      onDelete,
      onSave: ({ conditions, reportingKey }) => {
        const modifiedValues = Object.assign({}, values, {
          reportingKey: reportingKey.value,
          conditions,
          options: { showTitle: false },
        });

        this.setState({ values: modifiedValues });

        componentsManager.editComponent(field.id, modifiedValues);

        this.deleteModal();
      },
    });

    this.updateModal(modalData);

    this.showModal();
  };

  render() {
    const {
      children = [],
      type,
      field: { reportingKey },
      connectDragSource,
      connectDragPreview,
      isDragging,
    } = this.props;
    const {
      values: { conditions },
    } = this.state;
    const reviewerCss = getReviewerCssClass(conditions);
    const childList = castArray(children);

    return connectDragPreview(
      <div data-test="field-upload">
        <Styled className={`${reviewerCss} ${isDragging ? 'is-dragging' : ''}`}>
          {connectDragSource(
            <div className="upload--header">
              <Row className="type">
                <Tag>{getFieldLabel(type).toUpperCase()}</Tag>
              </Row>
              <Row>
                {!isEmpty(conditions) && <Tag color="gold">Conditional</Tag>}
                <span className="actions">
                  <IconButton
                    icon="setting"
                    onClick={this.showOptionsModal}
                    className="is-big is-focusable"
                  />
                </span>
                <span className="title">
                  <span>{getTitle(this.getValue('title'), reportingKey)}</span>
                </span>
              </Row>
              <hr />
            </div>
          )}

          <Row className="upload--detail">
            {childList.map((child, idx) => (
              <Col span={12} key={idx}>
                {React.cloneElement(child, {
                  compact: true,
                })}
              </Col>
            ))}
          </Row>
        </Styled>
      </div>
    );
  }
}

UploadEditor.displayName = 'FieldEditor.UploadEditor';

export default connect()(
  DragSource(
    params => params.type,
    {
      beginDrag: param => param.field,
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    })
  )(UploadEditor)
);
