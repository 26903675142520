import React from 'react';
import { Select } from 'antd';

const { Option, OptGroup } = Select;

class FieldSelect extends React.Component {
  constructor(props) {
    super(props);
    const { onChange, defaultValue, value } = this.props;

    if (value === undefined && defaultValue !== undefined) {
      onChange(defaultValue);
    }
  }

  render() {
    const {
      defaultValue,
      selectOptions,
      onChange,
      value,
      mode,
      style = { width: 257 },
      'data-test': dataTest = '',
    } = this.props;
    const renderOption = ({ label, value }) => (
      <Option key={value} value={String(value)}>
        {label}
      </Option>
    );
    const renderOptGroup = ({ label, enums }) => (
      <OptGroup key={label} label={label}>
        {enums.map(renderOption)}
      </OptGroup>
    );
    return (
      <Select
        defaultValue={defaultValue}
        mode={mode}
        value={value}
        style={style}
        onChange={onChange}
        placeholder={'Please select'}
        data-test={dataTest}
      >
        {selectOptions.map(option => {
          return option.enums ? renderOptGroup(option) : renderOption(option);
        })}
      </Select>
    );
  }
}

export default FieldSelect;
