import styled from 'styled-components';
import { Popover } from 'antd';

export const StackedChartPopover = styled(Popover).attrs({
  className: 'bdm-stacked-chart-popover',
})`
  .ant-popover-content {
    text-align: center;
  }
`;

export default StackedChartPopover;
