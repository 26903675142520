import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';

import SectionWrapper from '../../form-viewer/styled/section-wrapper';

export class LoaderOfficerBio extends Component {
  render() {
    return (
      <SectionWrapper>
        <ContentLoader
          height={133}
          width={1200}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <circle cx="140" cy="33" r="33" />
          <rect x="82" y="80" rx="0" ry="0" width="123" height="24" />
          <rect x="82" y="115" rx="0" ry="0" width="123" height="24" />
          <rect x="529" y="17" rx="0" ry="0" width="450" height="20" />
          <rect x="320" y="70" rx="0" ry="0" width="180" height="24" />
          <rect x="320" y="105" rx="0" ry="0" width="180" height="24" />
          <rect x="630" y="70" rx="0" ry="0" width="180" height="24" />
          <rect x="630" y="105" rx="0" ry="0" width="180" height="24" />
          <rect x="980" y="70" rx="0" ry="0" width="180" height="24" />
          <rect x="980" y="105" rx="0" ry="0" width="180" height="24" />
        </ContentLoader>
      </SectionWrapper>
    );
  }
}
export default LoaderOfficerBio;
