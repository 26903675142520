import React, { PureComponent } from 'react';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';

import DashboardPage from '../../components/dashboard';
import Header from '../../components/layout/header';
import StyledLayout from '../../components/layout/styled-layout';
import StyledFooter from '../../components/layout/styled-footer';
import StyledLayoutGuestContainer from '../../components/layout/styled-layout-guest-container';
import ResetPasswordForm from '../../components/password/reset';

import URLSearchParams from 'url-search-params';

class ResetPasswordPage extends PureComponent {
  static getPageConfig() {
    return { guestAccess: true };
  }

  render() {
    const { history } = this.props;
    const qParams = new URLSearchParams(history.location.search);
    return (
      <Layout>
        <Helmet title="Reset password." />
        <Header />
        <StyledLayout>
          <StyledLayoutGuestContainer>
            <img
              className="logo"
              src={require('../../benchmark-logo-full.png')}
              alt="Benchmark Analytics"
            />
            <ResetPasswordForm
              accessToken={qParams.get('access_token')}
              history={history}
            />
          </StyledLayoutGuestContainer>
        </StyledLayout>
        <StyledFooter />
      </Layout>
    );
  }
}

export default DashboardPage(() => ({}), null)(ResetPasswordPage);
