import getModalData from './getModalData';
import { MODAL_TYPE_SUCCESS, MODAL_TYPE_CONTINUE_REVIEW } from './constants';

const activeReviewer = ({
  activeReviewerId,
  activeReviewerRank,
  activeReviewerfullName,
  formType,
  formNumber,
  linkedFormType,
  linkedFormNumber,
}) => {
  if (activeReviewerId) {
    return getModalData(
      MODAL_TYPE_SUCCESS,
      '6346caae-d8e6-11e9-8a34-2a2ae2dbcce4',
      `This ${formType} involves a ${linkedFormType} incident ${linkedFormNumber}. Both reports have been submitted to ${activeReviewerRank} ${activeReviewerfullName} for review.`
    );
  } else {
    return getModalData(
      MODAL_TYPE_SUCCESS,
      '6346cd42-d8e6-11e9-8a34-2a2ae2dbcce4',
      `You have reviewed ${formType} ${formNumber} and ${linkedFormType} incident ${linkedFormNumber} that's involved. Both reports are now closed`
    );
  }
};

const sendMoreInfo = ({
  sendingMoreInfo,
  formType,
  formNumber,
  linkedFormType,
  linkedFormNumber,
}) => {
  if (sendingMoreInfo) {
    return getModalData(
      MODAL_TYPE_SUCCESS,
      '8a9d599c-d8e6-11e9-9d36-2a2ae2dbcce4',
      `You have reviewed ${formType} ${formNumber}. Please also review the ${linkedFormType} incident ${linkedFormNumber} that's involved.`
    );
  } else {
    return getModalData(
      MODAL_TYPE_CONTINUE_REVIEW,
      '8a9d5c3a-d8e6-11e9-9d36-2a2ae2dbcce4',
      `You have reviewed ${formType} ${formNumber}. Please also review the ${linkedFormType} incident ${linkedFormNumber} that's involved.`
    );
  }
};

const finalLinked = ({
  isFinalLinked,
  activeReviewerId,
  linkedSubmission,
  sendingMoreInfo,
  activeReviewerRank,
  activeReviewerfullName,
  formType,
  formNumber,
}) => {
  const {
    formType: linkedFormType,
    formNumber: linkedFormNumber,
  } = linkedSubmission;

  if (isFinalLinked) {
    return activeReviewer({
      activeReviewerId,
      activeReviewerRank,
      activeReviewerfullName,
      formType,
      formNumber,
      linkedFormType,
      linkedFormNumber,
    });
  } else {
    return sendMoreInfo({
      sendingMoreInfo,
      formType,
      formNumber,
      linkedFormType,
      linkedFormNumber,
    });
  }
};

const getModalTitleAndTextSubmit = ({
  isBlendLink,
  isFinalLinked,
  activeReviewerId,
  linkedSubmission,
  sendingMoreInfo,
  activeReviewerRank,
  activeReviewerfullName,
  actionLabel,
  formType,
  formNumber,
}) => {
  if (isBlendLink) {
    return finalLinked({
      isFinalLinked,
      activeReviewerId,
      linkedSubmission,
      sendingMoreInfo,
      activeReviewerRank,
      activeReviewerfullName,
      formType,
      formNumber,
    });
  } else {
    if (activeReviewerId) {
      return getModalData(
        MODAL_TYPE_SUCCESS,
        'a2641ff2-d8e6-11e9-8a34-2a2ae2dbcce4',
        `You have reviewed this report as ${actionLabel} and has been sent to ${activeReviewerRank} ${activeReviewerfullName}`
      );
    } else {
      return getModalData(
        MODAL_TYPE_SUCCESS,
        'a264251a-d8e6-11e9-8a34-2a2ae2dbcce4',
        `You have reviewed this report as ${actionLabel} and the report is now closed`
      );
    }
  }
};

export default getModalTitleAndTextSubmit;
