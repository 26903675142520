import styled from 'styled-components';

import Workflow from './Workflow';

const WorkflowStyled = styled(Workflow)`
  margin-top: 15px;

  .new-level-action {
    margin-bottom: 0;
    text-align: right;
  }
`;

export default WorkflowStyled;
