import styled from 'styled-components';

import { GRAY_2 } from '../../../config/theme';

const ModalBody = styled.div`
  padding: 0 15px;
  color: ${GRAY_2};

  .loader-animation {
    width: 400px;
    max-width: 100%;
  }

  .inner-body {
    padding: 20px 15px;
    color: #000;
    font-size: 12px;

    p {
      margin: 0 0 16px;
    }

    .anticon {
      color: #2ecc71;
      margin-right: 10px;
    }
  }

  .loader-share-item {
    width: 20rem;
  }
`;

export default ModalBody;
