import get from 'lodash/get';
import each from 'lodash/each';
import first from 'lodash/first';
import set from 'lodash/set';
import { v4 as uuid } from 'uuid';

import createSymlink, {
  symlinkPathPropertyName,
  isSymlinked,
} from '../create-symlink';
import { repeaterKeyDivider } from '../constants';

export default ({
  fields,
  property,
  key,
  index,
  defaultValue,
  properties,
  loopFromEnumRef,
  fromSourceValue,
  parent,
  parentKey,
  data,
  getPresentation,
  withProfileData,
}) => {
  const isNestedRepeater = !!parentKey;
  const isSymlinkedRepeater = isSymlinked(get(parent, `${key}.0`, {}));
  const symlinkPath = get(
    parent,
    `${key}${repeaterKeyDivider}${symlinkPathPropertyName}`
  );

  if (isNestedRepeater) {
    return createSymlink({
      fields,
      property,
      key,
      index,
      defaultValue,
      properties,
      loopFromEnumRef,
      fromSourceValue,
      parent,
      parentKey,
      data,
      getPresentation,
      withProfileData,
    });
  } else {
    parent[key] = defaultValue || [
      {
        id: uuid(),
        [symlinkPathPropertyName]: isSymlinkedRepeater
          ? symlinkPath
          : undefined,
      },
    ];

    if (loopFromEnumRef) {
      const repeaterData = get(data, key, []);
      parent[key] = fromSourceValue.map((item, _index) => {
        const itemData = get(repeaterData, _index, {});

        return {
          ...itemData,
          ...item,
          id: itemData.id || uuid(),
          [symlinkPathPropertyName]: itemData[symlinkPathPropertyName],
        };
      });
    }

    // Custom check for repeaters firts item
    if (parent[key].length) {
      if (loopFromEnumRef) {
        fromSourceValue.forEach((_, idx) => {
          each(
            properties,
            getPresentation({
              parent: parent[key][idx],
              parentKey: property.key,
              getPresentation,
              withProfileData,
            })
          );
        });
      } else {
        each(
          properties,
          getPresentation({
            parent: parent[key][0],
            parentKey: property.key,
            getPresentation,
            withProfileData,
          })
        );
      }
    }

    if (get(first(parent[key]), 'id') === undefined) {
      set(first(parent[key]), 'id', uuid());
    }

    if (
      isSymlinkedRepeater &&
      get(first(parent[key]), symlinkPathPropertyName) === undefined
    ) {
      set(first(parent[key]), symlinkPathPropertyName, symlinkPath);
    }
  }
};
