import React from 'react';

import ModalBody from 'APP_COMPONENTS/common/modal/body';

const openReportsUser = (
  <ModalBody>
    <div>
      <p>
        This user can&apos;t be updated at the moment. This might be because he
        has open reports and changing his information could impact them. Please
        try again later.
      </p>
    </div>
  </ModalBody>
);

export default openReportsUser;
