import { get, find } from 'lodash';
import { DELETE_FORM_LINK_REQUEST } from './';
import { unLinkingReports } from '../api/form-links';
import { getReport, updateDraftReport } from '../api/reports';
import deleteFormLinkSuccess from './delete-form-link-success';
import deleteFormLinkError from './delete-form-link-error';

export default (
  { formId = '', resetRemoteKeys = {}, saveLocal = true },
  next = () => {}
) => async (dispatch, getState) => {
  const { form: { selectedForm: { meta } = {} } = {} } = getState();
  const { agencyId = '', formLinks = [] } = meta;

  const formLink = find(
    formLinks,
    ({ end = {}, start = {} }) =>
      (end && end.id === parseInt(formId, 10)) ||
      (start && start.id === parseInt(formId, 10))
  );
  const { id: formLinkId = '' } = formLink || {};

  const resetKeys = resetRemoteKeys.reduce(
    (acc, key) => ({ ...acc, [key]: null }),
    {}
  );

  try {
    // Unlinking report
    if (saveLocal) {
      dispatch({ type: DELETE_FORM_LINK_REQUEST });

      await unLinkingReports(formLinkId);
      dispatch(deleteFormLinkSuccess({ formLink }));
    }

    if (formLink) {
      // Get remote report
      getReport({ id: formId, agencyId }).then(report => {
        const { data } = report;

        // Save unlinked remote report
        updateDraftReport({
          id: formId,
          agencyId,
          data: { data: { ...data, ...resetKeys } },
        });
      });
    }

    next(false, formLink);
  } catch (e) {
    dispatch(deleteFormLinkError(get(e, ['response', 'status'])));
    next(e);
  }
};
