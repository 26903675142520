import styled from 'styled-components';

const FileTableWrapper = styled.div`
  padding: 16px;

  .ant-pagination {
    margin-bottom: 55px;
  }
`;

export default FileTableWrapper;
