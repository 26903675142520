import { createReducer } from 'redux-create-reducer';
import { get } from 'lodash';

import {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_ERROR,
  GET_USER_REQUEST,
  GET_USER_FULLFILLED,
  GET_USER_REJECTED,
  CREATE_USER_REQUEST,
  CREATE_USER_REJECTED,
  CREATE_USER_FULLFILLED,
  UPDATE_USER_REQUEST,
  UPDATE_USER_REJECTED,
  UPDATE_USER_FULLFILLED,
  GET_USER_SECURITY_ROLES_FULFILLED,
  GET_USER_SECURITY_ROLES_REJECTED,
} from './AgencyUsers.actionTypes';

const initialState = {};
const initialCurrentUserState = {
  loading: true,
  error: null,
  data: {},
};
const initialAgencyState = {
  loading: true,
  list: [],
  entries: {},
  error: null,
  page: 1,
  per_page: 10,
  total: 0,
  currentUser: {},
  submitting: false,
};

const events = {
  [GET_ALL_USERS_REQUEST]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...state[payload.agencyId],
      loading: true,
      error: null,
      page: payload.page,
    },
  }),
  [GET_ALL_USERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...state[payload.agencyId],
      loading: false,
      list: payload.data.map(user => user.id),
      total: payload.count,
      entries: payload.data.reduce(
        (users, user) => ({ ...users, [user.id]: user }),
        {}
      ),
    },
  }),
  [GET_ALL_USERS_ERROR]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...state[payload.agencyId],
      loading: false,
      error: payload.error,
    },
  }),
  [GET_USER_REQUEST]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...get(state, payload.agencyId, {}),
      currentUser: {
        ...get(state, `${payload.agencyId}.currentUser`, {}),
        [payload.userId]: {
          ...initialCurrentUserState,
          ...get(
            state,
            `${payload.agencyId}.currentUser.${payload.userId}`,
            {}
          ),
          error: null,
          loading: true,
        },
      },
    },
  }),
  [GET_USER_SECURITY_ROLES_FULFILLED]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...get(state, payload.agencyId, {}),
      currentUser: {
        ...get(state, `${payload.agencyId}.currentUser`, {}),
        [payload.userId]: {
          ...initialCurrentUserState,
          data: {
            ...get(
              state,
              `${payload.agencyId}.currentUser.${payload.userId}.data`,
              {}
            ),
            userSecurityRoles: payload.response,
          },
          error: null,
          loading: false,
        },
      },
    },
  }),
  [GET_USER_SECURITY_ROLES_REJECTED]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...get(state, payload.agencyId, {}),
      currentUser: {
        ...get(state, `${payload.agencyId}.currentUser`, {}),
        [payload.userId]: {
          ...initialCurrentUserState,
          error: payload.error,
          loading: false,
        },
      },
    },
  }),
  [GET_USER_FULLFILLED]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...get(state, payload.agencyId, {}),
      currentUser: {
        ...get(state, `${payload.agencyId}.currentUser`, {}),
        [payload.userId]: {
          ...initialCurrentUserState,
          ...get(
            state,
            `${payload.agencyId}.currentUser.${payload.userId}`,
            {}
          ),
          loading: false,
          data: {
            ...get(
              state,
              `${payload.agencyId}.currentUser.${payload.userId}.data`,
              {}
            ),
            ...get(payload, 'response', {}),
          },
        },
      },
    },
  }),
  [GET_USER_REJECTED]: (state, { payload }) => ({
    ...state,
    [payload.agencyId]: {
      ...initialAgencyState,
      ...get(state, payload.agencyId, {}),
      currentUser: {
        ...get(state, `${payload.agencyId}.currentUser`, {}),
        [payload.userId]: {
          ...initialCurrentUserState,
          ...get(
            state,
            `${payload.agencyId}.currentUser.${payload.userId}`,
            {}
          ),
          loading: false,
          data: get(payload, 'response', {}),
        },
      },
    },
  }),
  ...[CREATE_USER_REQUEST, UPDATE_USER_REQUEST].reduce(
    (result, actionName) => ({
      ...result,
      [actionName]: (state, { agencyId }) => ({
        ...state,
        [agencyId]: {
          ...state[agencyId],
          submitting: true,
        },
      }),
    }),
    {}
  ),
  ...[
    CREATE_USER_REJECTED,
    CREATE_USER_FULLFILLED,
    UPDATE_USER_REJECTED,
    UPDATE_USER_FULLFILLED,
  ].reduce(
    (result, actionName) => ({
      ...result,
      [actionName]: (state, { agencyId }) => ({
        ...state,
        [agencyId]: {
          ...state[agencyId],
          submitting: false,
        },
      }),
    }),
    {}
  ),
};

export default createReducer(initialState, events);
