import _ from 'lodash';
import organizationEndpoints from '../../api/organization/organizationEndpoints';

export const ORGANIZATION_GET_VIEW_LOGS_SUCCESS =
  'ORGANIZATION_GET_VIEW_LOGS_SUCCESS';
export const ORGANIZATION_GET_VIEW_LOGS_ERROR =
  'ORGANIZATION_GET_VIEW_LOGS_ERROR';
export const ORGANIZATION_GET_VIEW_LOGS_REQUEST =
  'ORGANIZATION_GET_VIEW_LOGS_REQUEST';
export const ORGANIZATION_CLEAR_VIEW_LOGS = 'ORGANIZATION_CLEAR_VIEW_LOGS';

export const organizationGetViewLogsSuccess = response => {
  return { type: ORGANIZATION_GET_VIEW_LOGS_SUCCESS, payload: response };
};

export const organizationGetViewError = error => {
  return { type: ORGANIZATION_GET_VIEW_LOGS_ERROR, error };
};

export const organizationClearViewLogs = () => {
  return dispatch => {
    dispatch({ type: ORGANIZATION_CLEAR_VIEW_LOGS });
  };
};

export default (
  auditPageNumber,
  auditPageSize,
  tenantId,
  userId = '',
  executedBy = '',
  initialDate,
  endDate,
  tableNames
) => {
  return dispatch => {
    dispatch({
      type: ORGANIZATION_GET_VIEW_LOGS_REQUEST,
      payload: {
        auditPageNumber,
        auditPageSize,
        tenantId,
        userId,
        executedBy,
        initialDate,
        endDate,
        tableNames,
      },
    });

    return organizationEndpoints
      .getViewLogs(
        auditPageNumber,
        auditPageSize,
        tenantId,
        userId,
        executedBy,
        initialDate,
        endDate,
        tableNames
      )
      .then(response => {
        dispatch(organizationGetViewLogsSuccess(response));
      })
      .catch(error => {
        dispatch(
          organizationGetViewError(_.get(error, ['response', 'status']))
        );
      });
  };
};
