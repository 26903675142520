import React, { Component } from 'react';
import { omit } from 'lodash';

import { mapProperties } from 'APP_ROOT/utils/form';

const withObjectFieldChildren = parentProps =>
  class ObjectField extends Component {
    render() {
      const {
        isReviewer = false,
        properties = [],
        isDraft = false,
        assert,
        behavior,
      } = parentProps;
      const total = properties.length;

      return (
        <div className="bdm-form-object">
          {properties.map(
            mapProperties({
              ...omit(parentProps, [
                'conditions',
                'options',
                'submitDisable',
                'data',
                'dataEnums',
              ]),
              total,
              isReviewer,
              isDraft,
              title: null,
              ...(!assert ? { behavior } : {}),
            })
          )}
        </div>
      );
    }
  };

export default withObjectFieldChildren;
