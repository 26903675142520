import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import { PanelTitle } from '../common/panel';
import StyledSlider from './styled/slider';

import parseDate, { BENCHMARK_DATE_FORMAT } from '../../utils/parse-date';

class FormSlider extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: props.defaultValue || props.value,
    };
  }

  componentDidMount() {
    const { currentValue } = this.state;

    this.onChange(currentValue);
  }

  onChange = (value, publishUpdate = true) => {
    const { dates, onChange, timezone } = this.props;
    const {
      from: { value: dateFrom },
      to: { value: dateTo },
    } = dates;

    if (publishUpdate) {
      const valueFrom = parseDate(dateFrom, timezone, BENCHMARK_DATE_FORMAT);
      const valueTo = parseDate(dateTo, timezone, BENCHMARK_DATE_FORMAT);
      const toDateParsed = value === 0 ? valueFrom : valueTo;
      const datesRange = {
        fromDate: encodeURIComponent(valueFrom),
        toDate: encodeURIComponent(toDateParsed),
      };

      onChange(datesRange);
    }

    this.setState({
      currentValue: value,
    });
  };

  render() {
    const { dates, title } = this.props;
    const { currentValue } = this.state;
    const marks = {
      0: dates.from.title,
      100: dates.to.title,
    };
    return (
      <div>
        <Row style={{ marginBottom: '32px' }}>
          {title && (
            <Col xs={24} sm={6}>
              <PanelTitle>
                <h1>{title || 'Panel'}</h1>
              </PanelTitle>
            </Col>
          )}
          <Col xs={24} sm={title ? 18 : 24} style={{ padding: '0 40px' }}>
            <StyledSlider
              marks={marks}
              step={null}
              onChange={this.onChange}
              value={currentValue}
              tipFormatter={null}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default FormSlider;
