// to call the API, for instance
// https://{organization-service}:3003/api/organizations/v1/agencies/ranks/9001

import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';

class commentEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'http://localhost:3003';
    const apiPrefix = 'organizations/v1';
    super(
      process.env.REACT_APP_ORGANIZATIONS_SERVICE_URI || DEV_URI,
      apiPrefix
    );
  }

  /**
   * Fetches the comment list for an organizational unit
   * @returns Promise that resolves with a list of comments
   */
  getOrganizationalUnitComments(ouId, getSuppress = false) {
    const getParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/organizational-units/:ouId/comments'),
        method: 'GET',
      },
      { ouId },
      { getSuppress }
    );

    return fetchRequest(getParams.URI, getParams.request);
  }

  addOrganizationalUnitComment(ouId, comment = '') {
    const addParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/organizational-units/:ouId/comments'),
        method: 'POST',
      },
      { ouId },
      {},
      { comment: comment }
    );

    return fetchRequest(addParams.URI, addParams.request);
  }

  deleteOrganizationalUnitComment(ouId, commentId) {
    const getParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/organizational-units/:ouId/comments/:commentId'
        ),
        method: 'DELETE',
      },
      { ouId, commentId },
      {}
    );

    return fetchRequest(getParams.URI, getParams.request);
  }

  patchOrganizationalUnitComment(ouId, commentId, comment = '') {
    const patchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/organizational-units/:ouId/comments/:commentId'
        ),
        method: 'PATCH',
      },
      { ouId, commentId },
      {},
      { comment: comment }
    );

    return fetchRequest(patchParams.URI, patchParams.request);
  }
}
export default new commentEndpoints();
