export const race = [
  {
    value: 'American Indian or Native Alaskan',
    name: 'American Indian or Native Alaskan',
  },
  { value: 'Asian', name: 'Asian' },
  { value: 'Black / African American', name: 'Black / African American' },
  { value: 'Hispanic', name: 'Hispanic' },
  {
    value: 'Native Hawaiin or Pacific Islander',
    name: 'Native Hawaiin or Pacific Islander',
  },
  { value: 'Other', name: 'Other' },
  { value: 'White / European', name: 'White / European' },
];
