import styled from 'styled-components';
import { Row } from 'antd';

const RowWrapper = styled(Row)`
  &.static-content {
    height: 340px;

    .ql-editor {
      height: 260px;
    }
  }
`;

export default RowWrapper;
