import React from 'react';
import { get } from 'lodash';

export const getErrorMessage = fieldsByKey => (e, error) => {
  const fieldSplited = error.field.split('.');
  const length = fieldSplited.length;
  let index = '';
  let key;
  if (length > 1) {
    // if length === 2 means something like key[0].key
    // if length === 3 means something like key.0.key
    const pos =
      length === 2 ? fieldSplited[0].match(/\[([0-9]+)\]/)[1] : fieldSplited[1];
    index = `[${Number(pos) + 1}]`;
    key = fieldSplited[1];
  } else {
    key = fieldSplited[0];
  }
  const { title = 'No title' } = get(fieldsByKey, key, {});
  if (!e.some(value => value.key === error.field)) {
    e.push(
      <span key={error.field}>
        <strong data-key={error.field}>{title + index}</strong>: {error.message}
      </span>
    );
  }
  return e;
};

export const getErrors = (data, fieldsByKey) => {
  return Object.values(data).reduce((errs, value) => {
    if (Array.isArray(value)) {
      return value.reduce((e, val) => {
        return e.concat(getErrors(val, fieldsByKey));
      }, errs);
    } else {
      const { errors = [] } = value;
      return errors.reduce(getErrorMessage(fieldsByKey), errs);
    }
  }, []);
};
