import React from 'react';
import { Row, Col } from 'antd';
import FieldComponent from './UserEquipmentField';
import { InlineInputWrapper } from '../../../components/input';

const UserEquipmentModalFields = ({
  equipmentModalFields,
  onChangeFieldValues,
  isValid,
  validationMessages,
  isDisabled,
}) => {
  return (
    <Row>
      {equipmentModalFields.map(field => (
        <Col key={field.id} xs={24} sm={12} md={12} lg={12}>
          <InlineInputWrapper
            key={field.id}
            label={field.label}
            options={{
              labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
              inputCol:
                'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
            }}
            suffix={field.required && <span className="red-asterisk">*</span>}
          >
            <div className={'equipment-field-type'}>
              <FieldComponent
                field={field}
                onChange={onChangeFieldValues}
                isValid={isValid}
                validationMessages={validationMessages}
                isDisabled={isDisabled}
              />
              {!isValid && validationMessages[field.id] && (
                <div className={'equipment-error-message'}>
                  <span>{validationMessages[field.id]}</span>
                </div>
              )}
            </div>
          </InlineInputWrapper>
        </Col>
      ))}
    </Row>
  );
};

export default UserEquipmentModalFields;
