import { message } from 'antd';

const showToastMessage = (type = 'success', ...args) => {
  if (message[type]) {
    return message[type].apply(null, args);
  }

  return () => null;
};

export default showToastMessage;
