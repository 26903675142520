import {
  GET_TEMPLATE_WORKFLOWS_REQUEST,
  GET_TEMPLATE_WORKFLOWS_FULLFILLED,
  GET_TEMPLATE_WORKFLOWS_REJECTED,
} from '../ConfigureWorkflows.actionTypes';

import { getWorkflows } from 'APP_ROOT/api/manageWorkflows';

export default (options, callback) => dispatch => {
  dispatch({ type: GET_TEMPLATE_WORKFLOWS_REQUEST, payload: options });

  const next = (...args) =>
    callback && typeof callback === 'function' && callback(...args);

  return new Promise(async (resolve, reject) => {
    try {
      const entries = await getWorkflows(options);
      dispatch({
        type: GET_TEMPLATE_WORKFLOWS_FULLFILLED,
        payload: Object.assign({}, options, { entries }),
      });
      resolve(entries);
      next(null, entries);
    } catch (error) {
      dispatch({
        type: GET_TEMPLATE_WORKFLOWS_REJECTED,
        payload: Object.assign({}, options, { error }),
      });
      reject(error);
      next(error);
    }
  });
};
