import { FILTER_ARRAY_SEPARATOR } from './constants';

const getMentionedUsersFilter = (mentionedUsers = '') => {
  if (mentionedUsers) {
    const items = mentionedUsers.split(FILTER_ARRAY_SEPARATOR);
    return [
      {
        column: 'mentionedUsers',
        operator: 'inq',
        value: items,
      },
    ];
  }
  return [];
};

export default getMentionedUsersFilter;
