import React, { Component } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

import Title from '../../common/main-title';
import { GRAY_2 } from '../../../config/theme';

const Wrapper = styled.div`
  padding: 0 0 50px;
  color: ${GRAY_2};

  .error-wrapper {
    max-width: 500px;
    margin: 30px auto 0;
    text-align: center;

    @media (max-width: 767px) {
      margin-top: 10px;
    }

    h2 {
      display: inline-block;
      font-size: 4em;
      color: #dadada;
      margin-bottom: 10px;

      @media (max-width: 767px) {
        font-size: 3em;
      }

      @media (max-width: 480px) {
        font-size: 2em;
      }

      &::after {
        content: '';
        display: block;
        border-bottom: 2px solid #dadada;
        position: relative;
        top: -15px;

        @media (max-width: 480px) {
          top: 0;
        }
      }
    }

    p {
      font-size: 2em;
      margin-bottom: 50px;

      @media (max-width: 480px) {
        font-size: 1em;
      }
    }
  }
`;

class ErrorScreen extends Component {
  UNSAFE_componentWillMount() {
    const { errorCode = 'unknown', onClick = e => {} } = this.props;

    if (errorCode === 401) {
      onClick();
    }
  }

  render() {
    const {
      errorCode = 'unknown',
      title = '',
      message = '',
      pageTitle = '',
      children = null,
      showTitle = true,
      buttonText = 'Back to home',
      buttonSize = 'default',
      onClick = e => {},
    } = this.props;

    const defaultMessage = 'Something went wrong.';

    return (
      <Wrapper>
        {pageTitle && <Title>{pageTitle}</Title>}
        {children && children}
        {!children && (
          <div className="error-wrapper">
            {showTitle && <h2>{title || errorCode}</h2>}
            <p>{message || defaultMessage}</p>
            <Button type="primary" size={buttonSize} onClick={onClick}>
              {buttonText}
            </Button>
          </div>
        )}
      </Wrapper>
    );
  }
}

export default ErrorScreen;
