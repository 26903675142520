export const FORM_NAME = 'manageCaseFile';

export const SHARE_MODAL_ID = 'casefile-sharing';
export const SHARE_CONFIRM_ID = 'casefile-confirm';

export const SHARE_EDIT = 'edit';
export const SHARE_VIEW = 'view';

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 },
  },
};
