import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';
import { escapeInput } from '../../utils/request';

class trainingServiceEndpoints extends ServiceClient {
  constructor() {
    const protocol = location.protocol || 'http:';
    const DEV_URI = `${protocol}//localhost:3012`;
    super(process.env.REACT_APP_TRAINING_SERVICE_URI || DEV_URI, 'api/v1');
  }
  getKMICoursesSessions = (tenantId, date, formatId = 106) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/kmi-courses/tenants/:tenantId/courses-sessions'
        ),
        method: 'GET',
      },
      { tenantId },
      { date, formatId }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };

  updateUserRegistration = (sessionId, userId, payload) => {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/kmi-courses/users/:userId/courses-sessions/:sessionId/registrations'
        ),
        method: 'PUT',
      },
      { sessionId, userId },
      {},
      escapeInput(payload)
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  };
}

export default new trainingServiceEndpoints();
