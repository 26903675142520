import React from 'react';
import ModalTitle from 'APP_ROOT/components/common/modal/title';
import { Icon } from 'antd';

export default () => (
  <ModalTitle warning>
    <Icon type="exclamation-circle" />{' '}
    <span>Do you want to delete this Case File?</span>
  </ModalTitle>
);
