import { castArray, some } from 'lodash';

export const shouldSeeField = usingConfig => withFieldName => {
  const { visible = false } = usingConfig[withFieldName] || {};

  return visible;
};

export const shouldSeeAnyField = (fromFieldList, usingConfig) => {
  const fields = castArray(fromFieldList);

  return some(fields, shouldSeeField(usingConfig));
};
