import _ from 'lodash';

import { LOGIN_REQUEST } from './';
import storage from '../utils/storage';

import { loginSSO } from '../api/session';
import getUser from './get-user';
import loginSuccess from './login-success';
import loginError from './login-error';

export default (token, next = () => {}) => {
  return dispatch => {
    dispatch({ type: LOGIN_REQUEST });

    return loginSSO(token)
      .then(async response => {
        const { sub: userId, organizationUnit = {} } = response;
        storage.set('token', token);
        storage.set('minified-token', token);
        dispatch(loginSuccess(response));
        dispatch(getUser(userId, organizationUnit, next));
      })
      .catch(error => {
        dispatch(loginError(_.get(error, ['response', 'status'])));
        next(error);
      });
  };
};
