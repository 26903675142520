import {
  DELETE_WORKFLOW_REQUEST,
  DELETE_WORKFLOW_FULLFILLED,
  DELETE_WORKFLOW_REJECTED,
} from '../ConfigureWorkflows.actionTypes';

import { deleteWorkflow } from 'APP_ROOT/api/manageWorkflows';

export default (options, callback) => dispatch => {
  dispatch({ type: DELETE_WORKFLOW_REQUEST, payload: options });

  const next = (...args) =>
    callback && typeof callback === 'function' && callback(...args);

  return new Promise(async (resolve, reject) => {
    try {
      const workflow = await deleteWorkflow(options);

      dispatch({
        type: DELETE_WORKFLOW_FULLFILLED,
        payload: Object.assign({}, options),
      });

      /**
       * The API will respond with code 200 but a payload of "false" if the workflow
       * is associated with a form and can't be deleted.
       * This condition prevents the message of "deleted successfully" when the workflow
       * could not be deleted, even if the request has code 200.
       */
      if (!workflow) {
        const apiMessage =
          'The Workflow cannot be deleted because it is associated to a form.';
        resolve(workflow);
        next(null, { success: false, message: apiMessage });
      } else {
        resolve(workflow);
        next(null, workflow);
      }
    } catch (error) {
      dispatch({
        type: DELETE_WORKFLOW_REJECTED,
        payload: Object.assign({}, options, { error }),
      });
      reject(error);
      next(error);
    }
  });
};
