import { authorizedRequest } from './utils';
import { genOptions, route, fetchRequest } from '../utils/request';

import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';

/**
 * Request to /Agencies/:agencyId/reasons.
 * @param {string} agencyId
 * @return {Promise}
 */
export const getAgencyReasons = agencyId => {
  const url = route(`/Agencies/${agencyId}/reasons`);
  return authorizedRequest('GET', url, null);
};

/**
 * Request to /Agencies/:agencyId/reasons.
 * @param {string} agencyId
 * @return {Promise}
 */
export const getAgencyRanks = (agencyId, options = null) => {
  const url = route(`/Agencies/${agencyId}/ranks`);
  return authorizedRequest('GET', url, options);
};
/**
 * Request to '/organization/agencies/ranks/:agencyId'.
 * @param {string} agencyId
 * @return {Promise}
 */
export const getOrgAgencyRanks = (agencyId, options = null) => {
  const url = route(`/organizations/v1/agencies/ranks/${agencyId}`);
  return authorizedRequest('GET', url, options, 'bearer');
};

/**
 * Request to /Agencies/:agencyId/formSubmissions/:formSubmissionsId/trainingTaskHistory
 * @param {string} agencyId
 * @param {string} formSubmissionsId
 * @param {object} options
 * @return {Promise}
 */
export const getTrainingTaskTimeline = (
  agencyId,
  formSubmissionsId,
  options
) => {
  const filter = JSON.stringify(options);
  const url = route(
    urlBuilder(
      `/Agencies/${agencyId}/formSubmissions/${formSubmissionsId}/trainingTaskHistory`,
      null,
      { filter }
    )
  );
  return authorizedRequest('GET', url);
};

export const getPropagationActions = (id, templateId) => {
  const token = storage.get('token');
  const url = route(
    urlBuilder('/Agencies/:id/template/:templateId/actions', { id, templateId })
  );
  const options = genOptions('GET', null, token);
  return fetchRequest(url, options);
};
