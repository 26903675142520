import React, { Component } from 'react';
import store from 'APP_ROOT/store';
import { Button } from 'antd';
import hideModal from '../../../actions/hide-modal';
import { modalId } from '../constants/modal';
import ChangesReportSharing from '../services/changesReportSharing';

class ModalFooter extends Component {
  state = { loading: false };

  componentDidMount() {
    this.closeModalSuscription = ChangesReportSharing.getSubmit().subscribe(
      data => data && this.closeModal()
    );
  }

  componentWillUnmount() {
    this.closeModalSuscription.unsubscribe();
  }

  closeModal = () => {
    const { dispatch } = store;
    this.setState({ loading: false });
    dispatch(hideModal(modalId));
    ChangesReportSharing.setIsOpenModal(false);
  };

  submit = () => {
    this.setState({ loading: true });
    ChangesReportSharing.setSubmit(true);
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <Button onClick={this.closeModal} disabled={loading}>
          Cancel
        </Button>
        <Button type="primary" onClick={this.submit} loading={loading}>
          Ok
        </Button>
      </div>
    );
  }
}

export default ModalFooter;
