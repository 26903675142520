import { createReducer } from 'redux-create-reducer';

import {
  LOOKUP_SYSTEM_UPDATES_REQUEST,
  LOOKUP_SYSTEM_UPDATES_SUCCESS,
  LOOKUP_SYSTEM_UPDATES_ERROR,
  START_SYSTEM_UPDATE_SUCCESS,
  START_SYSTEM_UPDATE_ERROR,
  GET_SYSTEM_UPDATE_STATUS,
  RESET_SYSTEM_UPDATE_STATE,
} from '../actions';

const initialState = {
  lookingUp: false,
  updating: false,
  lookedUp: false,
  status: null,
  updateId: null,
  versions: [],
};

const events = {
  [LOOKUP_SYSTEM_UPDATES_REQUEST]: state => ({
    ...state,
    lookingUp: true,
    status: null,
    updateId: null,
  }),
  [LOOKUP_SYSTEM_UPDATES_SUCCESS]: (state, { payload: versions }) => ({
    ...state,
    lookingUp: false,
    lookedUp: true,
    versions,
  }),
  [LOOKUP_SYSTEM_UPDATES_ERROR]: state => ({
    ...state,
    lookingUp: false,
  }),
  [START_SYSTEM_UPDATE_SUCCESS]: (
    state,
    { payload: { id: updateId = null } }
  ) => ({
    ...state,
    updating: true,
    status: 'pending',
    updateId,
  }),
  [START_SYSTEM_UPDATE_ERROR]: state => ({
    ...state,
    updating: false,
  }),
  [GET_SYSTEM_UPDATE_STATUS]: (state, { payload: { status } = {} }) => ({
    ...state,
    updating: !['success', 'failure'].includes(status),
    status,
  }),
  [RESET_SYSTEM_UPDATE_STATE]: () => initialState,
};

export default createReducer(initialState, events);
