export const ERROR_ORPHAN_RULE = 'EV10';
export const ERROR_REQUIRED_RULE = 'EV20';
export const ERROR_TYPE_RULE = 'EV30';
export const ERROR_TYPE_CALCULATED = 'EV40';
export const ERROR_STRING_TYPE = 'EV50';
export const ERROR_MISSING_RULE = 'EV60';

export const ERROR_MESSAGES = {
  [ERROR_ORPHAN_RULE]: 'Orphan Key error(s) were found and removed.',
  [ERROR_REQUIRED_RULE]: 'Required Field error(s) were found and fixed.',
  [ERROR_TYPE_RULE]:
    '(Non-calculated) Mismatched Field Type error(s) were found and fixed.',
  [ERROR_TYPE_CALCULATED]:
    '(Calculated) Mismatched Field Type error(s) were found and fixed.',
  [ERROR_STRING_TYPE]: 'String type found and converted to Text.',
  [ERROR_MISSING_RULE]: 'Missing rules found and added to validations.',
};

export const ERROR_DESCRIPTION = {
  [ERROR_ORPHAN_RULE]: `In validation section, some keys where found without a
    corresponding key in field section, this could lead into an error when the report
    is running. The resolution is to remove these keys.`,
  [ERROR_REQUIRED_RULE]: `Required Field definition was wrong, could be a conditional
    display field marked as required or mustExist wrongly defined or keepInData object
    missing. Validation rule fixed`,
  [ERROR_TYPE_RULE]: `Mismatched Field Type between field definition
    and validation definition. Validation rule fixed`,
  [ERROR_TYPE_CALCULATED]: `Mismatched Field Type between field definition
    and calculated definition.`,
  [ERROR_STRING_TYPE]: `String type is deprecated, all the fields using it were converted
    to Text.`,
  [ERROR_MISSING_RULE]: 'Field missing rules were added to validations.',
};

export const VALIDATION_TYPE_ARRAY = 'array';
export const FORM_SCHEMA_NAME = 'jsonSchema';
export const REVIEW_NOTE_SCHEMA_NAME = 'formSchema';
export const CATEGORY_FORM = 'FORM';

export const LEGACY_FIELD_WL = [
  { key: 'rank.name', type: 'text' },
  { key: 'height', type: 'number' },
];
