import React from 'react';

export const ResultsContainer = ({
  title = '',
  loading = false,
  message = 'No Results',
  debugMessage = '',
  errors = [],
  createdUserDetails = [],
}) => {
  if (loading) {
    return null;
  }

  return (
    <div>
      <h3>{title}</h3>
      <p>{message}</p>
      <p>{debugMessage}</p>
      {errors.map((error, index) => {
        return <p key={index}>{error}</p>;
      })}
      {createdUserDetails.map((userDetails, index) => {
        return <p key={index}>{userDetails}</p>;
      })}
      <br />
    </div>
  );
};

export default ResultsContainer;
