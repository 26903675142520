const validateFormModal = ({ equipment, equipmentModalFields = [] }) => {
  let isValid = true;
  const validationMessages = {};

  equipmentModalFields.forEach(field => {
    if (field.required && !equipment.some(f => f.fieldId === field.id)) {
      isValid = false;
      validationMessages[field.id] = `The ${field.label} is required.`;
    }
  });

  return { isValid, validationMessages };
};

export default validateFormModal;
