import { GET_STATS_REQUEST } from './';
import _ from 'lodash';
import { getStatsUOF, getStatsIA } from '../api/user';
import getStatsSuccess from './get-stats-success';
import getStatsError from './get-stats-error';

export default (statsCategory, filter, next = () => {}) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_STATS_REQUEST, payload: { category: statsCategory } });

    const { selectedUser: { userId } = {} } = getState();
    const statMapping = {
      'use-of-force': getStatsUOF,
      'internal-affairs': getStatsIA,
    };
    return new Promise((resolve, reject) => {
      try {
        const getStatsFunc = statMapping[statsCategory];
        getStatsFunc(userId, filter)
          .then(response => {
            dispatch(getStatsSuccess(response, statsCategory));
            resolve(response);
            next(null, response);
          })
          .catch(error => {
            dispatch(
              getStatsError(_.get(error, ['response', 'status']), statsCategory)
            );
            reject(error);
            next(error);
          });
      } catch (error) {
        reject(error);
        next(error);
      }
    });
  };
};
