import { createReducer } from 'redux-create-reducer';
import { last, omit } from 'lodash';
import { REHYDRATE, PURGE } from 'redux-persist';

import {
  TOGGLE_SIDEBAR,
  COLLAPSE_SIDEBAR,
  LOGIN_REQUEST,
  SET_WHEEL_CATEGORY,
  BLOCK_NAVIGATION,
  UNBLOCK_NAVIGATION,
  PAUSE_IDLE_TIMER,
  RESUME_IDLE_TIMER,
} from '../actions';

import loadingState from './loading-state';

const initialState = {
  sidebarCollapsed: false,
  selectedCategory: [0, 1],
  hydrated: false,
  blockNavigation: false,
  loading: true,
  pauseIdleTimer: false,
};

export default loadingState(
  createReducer(initialState, {
    [TOGGLE_SIDEBAR]: state => {
      return {
        ...state,
        sidebarCollapsed: !state.sidebarCollapsed,
      };
    },
    [COLLAPSE_SIDEBAR]: (state, { payload: collapsed }) => {
      return {
        ...state,
        sidebarCollapsed: collapsed,
      };
    },
    [LOGIN_REQUEST]: state => {
      return {
        ...state,
        sidebarCollapsed: false,
        hydrated: true,
      };
    },
    [SET_WHEEL_CATEGORY]: (state, { payload }) => {
      return {
        ...state,
        selectedCategory: [last(state.selectedCategory), payload],
      };
    },
    [REHYDRATE]: (state, { payload = {} }) => {
      const { app = {} } = payload;
      const incoming = omit(app, ['selectedCategory']);

      return {
        ...state,
        ...incoming,
        hydrated: true,
      };
    },
    [PURGE]: () => {
      return {};
    },
    [BLOCK_NAVIGATION]: state => ({
      ...state,
      blockNavigation: true,
    }),
    [UNBLOCK_NAVIGATION]: state => ({
      ...state,
      blockNavigation: false,
    }),
    [PAUSE_IDLE_TIMER]: state => ({
      ...state,
      pauseIdleTimer: true,
    }),
    [RESUME_IDLE_TIMER]: state => ({
      ...state,
      pauseIdleTimer: false,
    }),
  })
);
