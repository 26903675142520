import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from 'antd/lib/table';
import get from 'lodash/get';

// Actions
import getSSNLogs from '../../actions/organizations/get-ssn-audit-logs';

// Containers
import AuditLogsTable from './audit-logs-table';
import parseDate, { BENCHMARK_DATE_TIMEZ_FORMAT } from '../../utils/parse-date';
import { getAgencyTZ } from '../../selectors/session';

const { Column } = Table;

class AuditPage extends Component {
  onSearch = (props, state) => {
    const { agencyId, tenantId = '' } = this.props;
    const { dispatch } = props;
    const { page, dateFrom, dateTo } = state;
    dispatch(
      getSSNLogs(page, 10, tenantId, agencyId, 'INSERT', dateFrom, dateTo)
    );
  };

  render() {
    const {
      route = () => {},
      timezone,
      ssnLogs,
      session,
      agencyId,
      showTitle = true,
      ...rest
    } = this.props;
    return (
      <AuditLogsTable
        showTitle={showTitle}
        onSearch={this.onSearch}
        data={ssnLogs}
        route={route}
        timezone={timezone}
        agencyId={agencyId}
        {...rest}
      >
        <Column title="Updated By" key="createdBy" dataIndex="createdBy" />
        <Column title="Employee" dataIndex="userName" key="userName" />
        <Column
          title="Changed"
          dataIndex="createdAt"
          key="createdAt"
          render={(text, record) =>
            parseDate(record.createdAt, timezone, BENCHMARK_DATE_TIMEZ_FORMAT)
          }
        />
      </AuditLogsTable>
    );
  }
}

const mapState = state => ({
  ssnLogs: get(state, ['organization', 'ssnLogs', 'entries']),
  total: get(state, ['organization', 'ssnLogs', 'total']),
  pageSize: get(state, ['organization', 'ssnLogs', 'pageSize']),
  page: get(state, ['organization', 'ssnLogs', 'page']),
  loading: get(state, ['organization', 'ssnLogs', 'loading']),
  timezone: getAgencyTZ(state),
});

export default connect(mapState)(AuditPage);
