import styled from 'styled-components';

const LabelInRow = styled.div`
  display: block;

  @media print {
    margin-bottom: 10px;
  }

  a {
    display: block;
    padding-top: 10px;
    text-decoration: underline;
  }
`;

export default LabelInRow;
