import React from 'react';
import { isArray } from 'lodash';
import { Dropdown, Icon } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';
import { Container, MenuItem, DropdownMenu } from './EllipsisMenu.styled';

const EllipsisMenu = ({ elements }) => {
  const formattedElements = isArray(elements) ? elements : [elements];
  const firstElement = formattedElements[0];
  const menuElements = formattedElements.filter(
    element => element !== firstElement
  );

  const menu = (
    <DropdownMenu>
      {menuElements.map((element, index) => (
        <MenuItem
          key={`ellipsis-menu-item-${index}-${element}`}
          selectable={false}
          onClick={e => e.preventDefault()}
        >
          <span>{htmlUnescape(element)}</span>
        </MenuItem>
      ))}
    </DropdownMenu>
  );

  return (
    <Container>
      <span>{htmlUnescape(firstElement)}</span>
      <Dropdown overlay={menu} trigger={['click']}>
        <Icon type="ellipsis" />
      </Dropdown>
    </Container>
  );
};

export default EllipsisMenu;
