import { get, isEmpty } from 'lodash';
import componentsManager from '../../services/componentsManager';
import FieldSelect from '../../components/FieldSelect';
import { SYNC_TO } from 'APP_ROOT/constants/fieldTypes';
export default class syncTo {
  constructor(values) {
    const fields = componentsManager.fields;
    const repeaters = componentsManager.repeatersReference;
    this.name = SYNC_TO;
    this.label = 'Repeaters Sync To';
    this.component = FieldSelect;
    this.defaultValue = [];
    this.mode = 'multiple';
    let repeaterArray = [];
    fields.map((tab, i) => {
      return (repeaters[i] || []).map(repeater => {
        const [repeaterItem] = componentsManager.findContainersByKey(
          fields[i].properties,
          repeater.repeaterKey
        );
        const label = get(repeaterItem, 'reportingKey');
        const currentRepeaterKey = get(values, 'key');
        if (!isEmpty(label) && currentRepeaterKey !== repeater.repeaterKey) {
          repeaterArray.push({
            value: repeater.repeaterKey,
            label: label,
          });
        }
        return;
      });
    });
    this.selectOptions = repeaterArray;
  }

  onSave = values => {
    const syncTo = get(values, `options.${SYNC_TO}`);

    return {
      ...values,
      [this.name]: undefined,

      options: {
        ...values.options,
        syncTo,
      },
    };
  };

  getInitialValue = values => {
    const valueRepeaterSyncTo = get(values, `options.${SYNC_TO}`);
    if (valueRepeaterSyncTo && valueRepeaterSyncTo.length > 0) {
      return valueRepeaterSyncTo;
    } else {
      return [];
    }
  };
}
