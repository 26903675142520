import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

const Option = Select.Option;

const ForceApplied = ({
  label = '',
  template = {},
  onChange,
  clearFilter,
  selectValues,
  fromReport,
  setFilters,
  filterType,
  filterValues,
  ...props
}) => {
  const { formSchema = {} } = template;
  const { enums: { incidentOfficerForceApplied = [] } = {} } = formSchema;

  const options = incidentOfficerForceApplied.map((item, index) => (
    <Option key={index} value={item.value}>
      {item.label}
    </Option>
  ));

  const onSelectChange = values => {
    const items = values.length ? values : [];
    setFilters(filterType, items);
  };

  return (
    <StyledSelect
      mode="multiple"
      style={{ width: '100%' }}
      placeholder={label}
      size="default"
      onChange={onSelectChange}
      allowClear
      value={filterValues[filterType]}
      {...props}
    >
      {options}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  /* Style for custom Select (Enable if necessary)
  height: 32px;
  overflow: hidden;
  .ant-select-selection__rendered {
    margin-right: 0 !important;
    ul {
      white-space: nowrap;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 30px;
        width: 100px;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 76%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
  .ant-select-selection {
    height: 32px;
  }
  .ant-select-selection__choice {
    float: none;
    display: inline-block;
    width: auto;
  }
  .ant-select-search {
    position: absolute;
    top: 0;
    width: 100%;
  }
  .ant-select-search__field {
    position: absolute;
    top: 0;
    opacity: 0;
    width: 100% !important;
    background-color: #fff;
    color: #000;
    &:focus {
      opacity: 1;
    }
  } */
`;

export default ForceApplied;
