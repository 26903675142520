import {
  get,
  first,
  omit,
  last,
  orderBy,
  pick,
  isPlainObject,
  uniqBy,
  cloneDeep,
} from 'lodash';

import {
  ADD_REPEATER_ITEM,
  REMOVE_REPEATER_ITEM,
  CHANGE_FORM_TAB,
  SYNC_TAB_NUMBER,
  GET_OFFICERS_SUCCESS,
  SET_SOURCE_SELECTION,
  SET_IS_CONTRIBUTE_REPORT,
  UPDATE_ASSIGNED_SECTIONS,
  REMOVE_SOURCE_SELECTION,
  REMOVE_REFERENCE_SELECTION,
  CREATE_DYNAMIC_ENUM_REF,
  SET_DONE_WITH_ACTIONS,
  SET_TASK_NAME,
  SET_PERFORMANCE_ASSESSMENT_NAME,
  SET_COMPLAINT_NUMBER,
  SET_REPORT_NUMBER,
  REMOVE_OWN_KEYS,
  UPDATE_GIVEN_DATA_KEYS,
  RESET_OWN_KEYS,
  RESET_REPEATER_KEYS,
  RESET_ONBEHALF,
  SYNC_REPEATERS,
  SEED_REPEATERS,
  GET_ALL_REPORTS_SUCCESS,
  ADD_SOURCE,
  SET_FORM_STATUS,
  GET_RANKS_SUCCESS,
  SET_FILE_UPLOADED,
  ADD_ONBEHALF,
  UPDATE_TAB_WITH_ERRORS,
} from '../../actions';

import { REPEATER } from '../../constants/layoutComponentTypes.js';

import {
  CONTRIBUTOR_ASSIGNMENT,
  CONTRIBUTOR_ASSIGNMENT_DATE,
  CONTRIBUTOR_SECTION_COMPLETE,
  CONTRIBUTOR_SECTION_COMPLETE_DATE,
  CONTRIBUTOR_ASSIGNMENT_SECTION,
} from '../../../src/constants/contributeToReport.js';

import selectedFormInitialState from './state/selected-form';
import formSectionIntialState from './state/form-section';

import {
  hasSymlinks,
  createSymlink,
  symlinksPropName,
  removeSymlink,
  symlinkPathPropertyName,
} from '../../utils/create-symlink';
import applyReadOnlyTransform from '../../utils/applyReadOnlyTransform';

const findInProperties = (properties, key, fields) => {
  if (Array.isArray(properties)) {
    properties.map(property => findInProperties(property, key, fields));
  } else if (properties?.properties) {
    if (properties?.reportingKey === key) {
      fields[key] = properties.key;
    } else {
      findInProperties(properties.properties, key, fields);
    }
  } else if (properties.reportingKey === key) {
    fields[key] = properties.key;
  }
};

// This receives section by section
const captureContributorFields = (reviewSection, contributorFields) => {
  if (reviewSection) {
    if (reviewSection.reportingKey === CONTRIBUTOR_ASSIGNMENT_SECTION) {
      // This section has a predictable reporting key:
      findInProperties(
        reviewSection.properties,
        CONTRIBUTOR_ASSIGNMENT,
        contributorFields
      );
      findInProperties(
        reviewSection.properties,
        CONTRIBUTOR_ASSIGNMENT_DATE,
        contributorFields
      );
      findInProperties(
        reviewSection.properties,
        CONTRIBUTOR_SECTION_COMPLETE,
        contributorFields
      );
      findInProperties(
        reviewSection.properties,
        CONTRIBUTOR_SECTION_COMPLETE_DATE,
        contributorFields
      );
    }
  }
};
// Move and capture each review section wrapper
const recurseBuild = (
  jsonTemplate,
  contributorAssignmentSection,
  parentKey
) => {
  if (jsonTemplate) {
    if (jsonTemplate.options?.isContributorSection) {
      const contributorFields = {};
      contributorAssignmentSection.push({
        sectionId: jsonTemplate.id,
        userId: null,
        complete: false,
        contributorFields,
        isRepeater: !!parentKey,
        repeaterKey: parentKey,
        repeaterItemId: 0,
        fields: [],
      });
      jsonTemplate.properties.forEach(val =>
        captureContributorFields(val, contributorFields, jsonTemplate)
      );
    } else if (jsonTemplate.properties) {
      jsonTemplate.properties.forEach(val => {
        const repeaterKey = parentKey || (val.type === REPEATER ? val.key : '');
        recurseBuild(val, contributorAssignmentSection, repeaterKey);
      });
    }
  }
  return;
};
const events = {
  [SYNC_REPEATERS]: (
    state,
    { payload: { parentKey, childrenKeys, syncKeys } }
  ) => {
    const { selectedForm: currentForm } = state;

    const syncs = {
      ...currentForm.syncs,
      [parentKey]: {
        syncTo: childrenKeys,
        syncKeys,
      },
    };

    const { data: currentFormData = {} } = currentForm;
    const parent = get(currentFormData, [parentKey]);
    const reducedData = childrenKeys.reduce((result, key, index) => {
      const childData = get(currentFormData, [key], []);
      const syncedChild = childData.map((childItem, childIndex) => {
        const parentItem =
          parent.find(parentItem => parentItem.id === childItem.__parentId) ||
          parent[childIndex] ||
          last(parent);
        const foundIndex = parent.findIndex(
          parentItem => parentItem.id === childItem.__parentId
        );
        const parentIndex = foundIndex > 0 ? foundIndex : 0;

        return {
          ...childItem,
          __parentId: parentItem.id,
          __parentIndex: parentIndex,
          __parentHumanIndex: parentIndex + 1,
        };
      });
      result[key] = orderBy(syncedChild, ['__parentIndex']);
      return result;
    }, {});

    const data = {
      ...currentFormData,
      ...reducedData,
    };

    const selectedForm = {
      ...currentForm,
      data,
      syncs,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SEED_REPEATERS]: (
    state,
    { payload: { parentKey, childId, seedKeys, purge } }
  ) => {
    const { selectedForm: currentForm, presentations = {} } = state;
    const {
      data: currentFormData = {},
      presentation: presentationType,
    } = currentForm;
    const presentation = get(presentations, [presentationType], {});
    const presentationFields = get(presentation, 'fields', []).reduce(
      (res, tabFields) => ({ ...res, ...tabFields }),
      {}
    );

    const initialTemplate = key => first(get(presentationFields, key, []));

    const reducedData = Object.keys(seedKeys).reduce((result, key) => {
      const childData = purge ? [] : get(currentFormData, [key], []);
      const seedData = seedKeys[key].reduce((result, k) => {
        return {
          ...result,
          [k]: childId,
        };
      }, initialTemplate(seedKeys[key]));

      return {
        ...result,
        [key]: [...childData, seedData],
      };
    }, {});

    const data = {
      ...currentFormData,
      ...reducedData,
    };

    const selectedForm = {
      ...currentForm,
      data,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [ADD_REPEATER_ITEM]: (
    state,
    { payload: { keys, id, data: extraData, symlinkPath, fileUploaded } }
  ) => {
    const { selectedForm: currentForm, presentations } = state;
    const {
      data: currentFormData = {},
      presentation: presentationType,
    } = currentForm;
    const presentation = get(presentations, [presentationType], {});
    const presentationFields = get(presentation, 'fields', []).reduce(
      (res, tabFields) => ({ ...res, ...tabFields }),
      {}
    );

    let currentSymlinks = [].concat(get(currentFormData, symlinksPropName, []));
    let dataWithSymlinks = {};

    const initialTemplate = (key, repeaterData) => {
      const newItemTemplate = first(get(presentationFields, key, []));

      if (hasSymlinks(newItemTemplate)) {
        const { symlinks, newItem, data } = createSymlink(
          newItemTemplate,
          key,
          repeaterData,
          currentSymlinks,
          Object.assign({}, currentFormData, dataWithSymlinks),
          presentationFields,
          symlinkPath
        );

        currentSymlinks = symlinks;
        dataWithSymlinks = data;

        return newItem;
      }

      const itemTemplate = Object.assign({}, newItemTemplate, {
        [symlinkPathPropertyName]: symlinkPath,
      });

      return itemTemplate;
    };
    const checkDefault = (v, def) => (v === null ? def : v);
    const { __assignedSections = [] } = currentFormData;
    const repeaterAssignedSection = __assignedSections.filter(
      v => v.isRepeater
    );
    const newSections = [];
    const reducedData = keys.reduce((result, key, index) => {
      const currentRepeaterData = checkDefault(
        get(currentFormData, [key], []),
        []
      );
      result[key] = orderBy(
        [
          ...currentRepeaterData,
          {
            ...initialTemplate(key, currentRepeaterData),
            ...extraData,
            id,
          },
        ],
        ['__parentIndex']
      );
      // Update __assignedSections with new repeater data if available
      const section = repeaterAssignedSection.find(v => v.repeaterKey === key);
      if (section) {
        newSections.push({
          ...cloneDeep(section),
          userId: null,
          complete: false,
          repeaterItemId: id,
        });
      }
      return result;
    }, {});

    const data = {
      ...currentFormData,
      __assignedSections:
        __assignedSections.length > 0
          ? __assignedSections.concat(newSections)
          : undefined,
      ...reducedData,
      ...dataWithSymlinks,
      [symlinksPropName]: currentSymlinks,
    };

    const selectedForm = {
      ...currentForm,
      data,
    };

    return {
      ...state,
      selectedForm,
      fileUploaded,
    };
  },
  [REMOVE_REPEATER_ITEM]: (state, { payload: { key, index } }) => {
    const { selectedForm: currentForm, presentations } = state;
    const {
      data: currentFormData,
      state: currentState,
      presentation: presentationType,
    } = currentForm;
    const presentation = get(presentations, [presentationType], {});
    const presentationFields = get(presentation, 'fields', []).reduce(
      (res, tabFields) => ({ ...res, ...tabFields }),
      {}
    );

    const dataWithSymlinks = removeSymlink(
      key,
      index,
      currentFormData,
      presentationFields
    );
    // Update __assignedSections to remove repeater data if available
    const { __assignedSections = [] } = currentFormData;
    const existingIds = dataWithSymlinks[key].map(v => v.id);
    const updatedContributorSections = __assignedSections.filter(v =>
      v.repeaterKey === key ? existingIds.includes(v.repeaterItemId) : true
    );

    const currentFieldState = cloneDeep(currentState[key]) || [];
    const reducedState = {
      [key]: currentFieldState.filter((item, i) => index !== i),
    };

    const selectedForm = {
      ...currentForm,
      data: {
        ...dataWithSymlinks,
        __assignedSections:
          __assignedSections.length > 0
            ? updatedContributorSections
            : undefined,
      },
      state: {
        ...currentState,
        ...reducedState,
      },
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [CHANGE_FORM_TAB]: (state, { payload: tab }) => {
    const sections = {
      ...state.selectedForm.validations.sections,
      [tab]: {
        ...state.selectedForm.validations.sections[tab],
        touched: true,
      },
    };

    const form = {
      ...state.selectedForm.validations.form,
    };

    const validations = {
      ...state.selectedForm.validations,
      form,
      sections,
    };

    const selectedForm = {
      ...state.selectedForm,
      tab,
      validations,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [UPDATE_TAB_WITH_ERRORS]: (state, { payload: tabsWithErrors }) => {
    const validations = {
      ...state.selectedForm.validations,
      tabsWithErrors,
    };

    const selectedForm = {
      ...state.selectedForm,
      validations,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SYNC_TAB_NUMBER]: (state, { payload }) => {
    const currentSections = {
      ...get(state, ['selectedForm', 'validations', 'sections'], {}),
    };

    const sectionsArray = Array(payload).fill(formSectionIntialState);

    const sections = sectionsArray.reduce((result, section, index) => {
      result[index] = {
        ...section,
        ...get(currentSections, [index], {}),
        touched: index === state.selectedForm.tab,
        dirty: get(currentSections, [index, 'dirty'], true),
      };

      return result;
    }, currentSections);

    const validations = {
      ...get(state, ['selectedForm', 'validations'], {}),
      sections,
    };

    const selectedForm = {
      ...get(state, ['selectedForm'], selectedFormInitialState),
      validations,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [GET_OFFICERS_SUCCESS]: (state, { payload: actionPayload = {} }) => {
    const { formName = 'form', ...payload } = actionPayload;

    if (formName !== 'form') {
      return state;
    }

    const currentSources = get(state, 'selectedForm.sources', {});

    const defaultOptions = {
      mapEnumValue: 'id',
      mapEnumLabel: ['firstName', 'middleName', 'lastName'],
    };
    const defaultSourceName = 'users';
    const options = Object.assign({}, defaultOptions, payload.options || {});
    const usersSource = `__${options.source || defaultSourceName}`;
    const mapLabelParts = Array.isArray(options.mapEnumLabel)
      ? options.mapEnumLabel
      : [options.mapEnumLabel];

    const enums = {
      ...get(state, `selectedForm.enums`, []),
      [payload.enumRef]: uniqBy(
        [
          ...get(state, `selectedForm.enums.${payload.enumRef}`, []),
          ...get(payload, 'data', []).map(officer => ({
            value: get(officer, options.mapEnumValue, officer.id),
            label: mapLabelParts
              .map(part => get(officer, part, ''))
              .filter(Boolean)
              .join(' '),
            data: officer,
          })),
        ],
        'value'
      ),
    };

    const mappedUsers = get(payload, 'data', []).reduce(
      (result, officer) => ({
        ...result,
        [officer.id]: officer,
      }),
      {}
    );

    const sources = {
      ...currentSources,
      [usersSource]: {
        ...get(currentSources, [usersSource], {}),
        ...mappedUsers,
      },
    };

    const selectedForm = {
      ...get(state, 'selectedForm', {}),
      enums,
      sources,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [GET_ALL_REPORTS_SUCCESS]: (
    state,
    { payload: { enumRef, data: reports, value } }
  ) => {
    const {
      selectedForm: { sources: currentSources = {}, data: currentData } = {},
    } = state;
    let accReports = reports;
    const reportsSource = '__reports';
    if (value) {
      const report = currentData[reportsSource][value];
      accReports = reports.concat(report);
    }

    const enums = {
      ...state.selectedForm.enums,
      [enumRef]: accReports.map(report => ({
        value: report.id,
        label: report.formNumber,
        data: report,
      })),
    };

    const mappedReports = reports.reduce(
      (acc, report) => ({
        ...acc,
        [report.id]: report,
      }),
      {}
    );

    const sources = {
      ...currentSources,
      [reportsSource]: {
        ...get(currentSources, [reportsSource], {}),
        ...mappedReports,
      },
    };

    const selectedForm = {
      ...state.selectedForm,
      enums,
      sources,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_SOURCE_SELECTION]: (
    state,
    { payload: { item = {}, sourceKey = '', formName = 'form' } }
  ) => {
    if (formName !== 'form') {
      return state;
    }

    const {
      selectedForm: currentSelectedForm = {},
      selectedForm: { data: currentData = {} } = {},
    } = state;

    return {
      ...state,
      selectedForm: {
        ...currentSelectedForm,
        data: {
          ...currentData,
          [sourceKey]: {
            ...get(currentData, [sourceKey], {}),
            [item.id]:
              sourceKey === '__reports'
                ? pick(item, [
                    'id',
                    'formType',
                    'number',
                    'formNumber',
                    'number',
                    'formCategory',
                    'draftDate',
                  ])
                : item,
          },
        },
      },
    };
  },
  [SET_IS_CONTRIBUTE_REPORT]: state => {
    let costumePerformanceReviewData = {};
    const template =
      state.templates[state.selectedForm.template].formSchema.form;

    // Loop though all properties:
    const contributorAssignmentSectionBasedOnTemplate = [];
    recurseBuild(template, contributorAssignmentSectionBasedOnTemplate);

    // If the template is not a performance review form, ignore this step
    if (contributorAssignmentSectionBasedOnTemplate.length === 0) {
      return {
        ...state,
      };
    }
    // If there is already a set of assignedSections
    // ensure the report is set to performance review and leave assigned sections as they are
    if (state.selectedForm.data.__assignedSections) {
      costumePerformanceReviewData.isContributeReport = true;
    } else {
      // If not assigned sections are found, add computed mapping
      costumePerformanceReviewData = {
        isContributeReport: true,
        data: {
          ...state.selectedForm.data,
          __assignedSections: contributorAssignmentSectionBasedOnTemplate,
          __assignedSectionsHistory: [],
        },
      };
    }
    return {
      ...state,
      selectedForm: {
        ...state.selectedForm,
        ...costumePerformanceReviewData,
      },
    };
  },
  [UPDATE_ASSIGNED_SECTIONS]: (
    state,
    { payload: { __assignedSections, __assignedSectionsHistory } }
  ) => {
    const { selectedForm } = state;
    return {
      ...state,
      selectedForm: {
        ...selectedForm,
        data: {
          ...selectedForm.data,
          __assignedSections,
          __assignedSectionsHistory,
        },
      },
    };
  },
  [ADD_SOURCE]: (
    {
      selectedForm: { sources = {}, enums = {} } = {},
      selectedForm = {},
      ...state
    },
    {
      payload: { enumRef = '', sourceKey = '', enumItem = {}, sourceItem = {} },
    }
  ) => {
    const newEnums = {
      ...enums,
      [enumRef]: [...get(enums, [enumRef], []), enumItem],
    };

    const newSources = {
      ...sources,
      [sourceKey]: {
        ...get(sources, [sourceKey], {}),
        [sourceItem.id]: sourceItem,
      },
    };

    return {
      ...state,
      selectedForm: {
        ...selectedForm,
        enums: newEnums,
        sources: newSources,
      },
    };
  },
  [REMOVE_SOURCE_SELECTION]: (
    {
      selectedForm: currentSelectedForm = {},
      selectedForm: { data: currentData } = {},
      ...state
    },
    { payload: { id, sourceKey } }
  ) => {
    const usersData = currentData[sourceKey];

    const data = {
      ...currentData,
      [sourceKey]: {
        ...omit(usersData, [id], {}),
      },
    };

    const selectedForm = {
      ...currentSelectedForm,
      data,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [REMOVE_OWN_KEYS]: (state, { payload }) => {
    const { selectedForm } = state;
    const { resetOwnKeys, parentKey, parentIndex } = payload;
    const restartedData = resetOwnKeys.reduce((result, ownKey) => {
      const value = get(result, [parentKey]);
      if (Array.isArray(value)) {
        return {
          ...result,
          [parentKey]: result[parentKey].map((item, i) => {
            if (i === parentIndex) {
              return { ...item, [ownKey]: undefined };
            } else {
              return item;
            }
          }),
        };
      } else {
        return {
          ...result,
          [ownKey]: undefined,
        };
      }
    }, selectedForm.data);
    return {
      ...state,
      selectedForm: { ...selectedForm, data: restartedData },
    };
  },
  [RESET_OWN_KEYS]: (state, { payload }) => {
    const { selectedForm } = state;
    const { keys = [], parentKey, parentIndex } = payload;
    const resetedData = keys.reduce((result, key) => {
      const data = get(result, [parentKey]);
      const parsedKey = key.includes('.') ? key.split('.')[1] : key;
      if (Array.isArray(data)) {
        return {
          ...result,
          [parentKey]: result[parentKey].map((item, index) => {
            if (index === parentIndex) {
              return {
                ...item,
                [parsedKey]: undefined,
              };
            } else {
              return { ...item };
            }
          }),
        };
      } else {
        return {
          ...result,
          [parsedKey]: undefined,
        };
      }
    }, selectedForm.data);

    return {
      ...state,
      selectedForm: { ...selectedForm, data: resetedData },
    };
  },
  [UPDATE_GIVEN_DATA_KEYS]: (state, { payload }) => {
    const { partialData } = payload;
    return {
      ...state,
      selectedForm: {
        ...state.selectedForm,
        data: { ...state.selectedForm.data, ...partialData },
      },
    };
  },
  [RESET_ONBEHALF]: state => {
    return {
      ...state,
      __usersOnBehalf: {},
    };
  },
  [RESET_REPEATER_KEYS]: (state, { payload }) => {
    const { selectedForm = { formFields: {} } } = state;
    const { resetRepeaters = [] } = payload;
    const resetedData = resetRepeaters.reduce((result, repeater) => {
      let { key, fields } = repeater;
      if (!fields) {
        const { formFields, tab } = selectedForm;
        // [0] because we only want to get the keys so 0 is ok
        fields = Object.keys(get(formFields, `fields[${tab}][${key}][0]`, {}));
      }
      const repeaterData = get(result, [key], []);
      return {
        ...result,
        [key]: repeaterData.map((item, index) => {
          return Object.keys(item).reduce((res, k) => {
            const isIncluded = fields.some(element => {
              if (isPlainObject(element)) {
                return element.key === k && element.value === item[k];
              }

              return element === k;
            });

            if (isIncluded) {
              return res;
            }

            return { ...res, [k]: item[k] };
          }, {});
        }),
      };
    }, selectedForm.data);

    return {
      ...state,
      selectedForm: { ...selectedForm, data: resetedData },
    };
  },
  [REMOVE_REFERENCE_SELECTION]: (state, { payload }) => {
    const { officerId, source, fields } = payload;

    const { selectedForm: currentSelectedForm = {} } = state;

    const { data: currentData = {} } = currentSelectedForm;

    const sourceData = get(currentData, source, []);

    const data = {
      ...currentData,
      [source]: sourceData.reduce((result, item) => {
        return [
          ...result,
          fields.reduce((acc, field) => {
            if (item[field] === officerId) {
              return {
                ...acc,
                [field]: '',
              };
            }
            return acc;
          }, item),
        ];
      }, []),
    };

    const selectedForm = {
      ...currentSelectedForm,
      data,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [CREATE_DYNAMIC_ENUM_REF]: (state, { payload: { enumRef, values } }) => {
    const selectedForm = {
      ...state.selectedForm,
      enums: {
        ...get(state, ['selectedForm', 'enums'], {}),
        [enumRef]: values,
      },
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_DONE_WITH_ACTIONS]: (state, { payload }) => {
    const data = {
      ...get(state, 'selectedForm.data', {}),
      [payload]: get(state, ['selectedForm', 'data', payload], []).map(
        item => ({
          ...item,
          doneWithAction: true,
        })
      ),
    };

    const selectedForm = {
      ...get(state, 'selectedForm', {}),
      data,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_TASK_NAME]: (state, { payload }) => {
    const data = {
      ...get(state, 'selectedForm.data', {}),
      [payload]: get(state, ['selectedForm', 'data', payload], []).map(
        item => ({
          ...item,
          taskName: item.value.value,
        })
      ),
    };

    const selectedForm = {
      ...get(state, 'selectedForm', {}),
      data,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_COMPLAINT_NUMBER]: (state, { payload }) => {
    const formNumber = get(state, 'selectedForm.meta.formNumber', '');
    const complaintType = get(state, 'selectedForm.data.complaintType', '');
    const complaintKey = get(payload, 'dataKey');
    const complaintTransform = get(payload, 'options.readOnlyTransform', {});

    // Prefix text needs to be overwritten by the current complaintType selected
    const complaintTransformParsed = {
      ...complaintTransform,
      prefix: {
        ...complaintTransform.prefix,
        text: complaintType,
      },
    };

    const complaintValue = applyReadOnlyTransform(
      formNumber,
      complaintTransformParsed
    );

    const data = {
      ...get(state, 'selectedForm.data', {}),
      [complaintKey]: complaintValue,
    };

    const selectedForm = {
      ...get(state, 'selectedForm', {}),
      data,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_REPORT_NUMBER]: (state, { payload }) => {
    const formNumber = get(state, 'selectedForm.meta.formNumber', '');
    const fieldKey = get(payload, 'dataKey');
    const fieldTransform = get(payload, 'options.readOnlyTransform', {});
    const fieldValue = applyReadOnlyTransform(formNumber, fieldTransform);

    const data = {
      ...get(state, 'selectedForm.data', {}),
      [fieldKey]: fieldValue,
    };

    const selectedForm = {
      ...get(state, 'selectedForm', {}),
      data,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_PERFORMANCE_ASSESSMENT_NAME]: (state, { payload }) => {
    const data = {
      ...get(state, 'selectedForm.data', {}),
      [payload]: get(state, ['selectedForm', 'data', payload], []).map(item => {
        return {
          ...item,
          categoryName: item.value,
        };
      }),
    };

    const selectedForm = {
      ...get(state, 'selectedForm', {}),
      data,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_FORM_STATUS]: (state, { payload: status }) => ({
    ...state,
    selectedForm: {
      ...state.selectedForm,
      isActive: status,
    },
  }),
  [GET_RANKS_SUCCESS]: (state, { payload: actionPayload = {} }) => {
    const { formName = 'form', ...payload } = actionPayload;

    if (formName !== 'form') {
      return state;
    }

    const currentSources = get(state, 'selectedForm.sources', {});

    const defaultOptions = {
      mapEnumValue: 'id',
      mapEnumLabel: ['name'],
    };
    const defaultSourceName = 'ranks';
    const options = Object.assign({}, defaultOptions, payload.options || {});
    const rankSource = `__${options.source || defaultSourceName}`;
    const mapLabelParts = Array.isArray(options.mapEnumLabel)
      ? options.mapEnumLabel
      : [options.mapEnumLabel];

    const enums = {
      ...get(state, 'selectedForm.enums', []),
      [payload.enumRef]: get(payload, 'data', []).map(rank => ({
        value: get(rank, options.mapEnumValue, rank.id),
        label: mapLabelParts.map(part => get(rank, part, '')).join(' '),
        data: rank,
      })),
    };

    const mappedRanks = get(payload, 'data', []).reduce(
      (result, rank) => ({
        ...result,
        [rank.id]: rank,
      }),
      {}
    );

    const sources = {
      ...currentSources,
      [rankSource]: mappedRanks,
    };

    const selectedForm = {
      ...get(state, 'selectedForm', {}),
      enums,
      sources,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_FILE_UPLOADED]: state => {
    return {
      ...state,
      fileUploaded: false,
    };
  },
  [ADD_ONBEHALF]: (
    state,
    { payload: { item = {}, sourceKey = '', formName = 'reviewForm' } }
  ) => {
    return {
      ...state,
      [sourceKey]: {
        [item.id]: item,
      },
    };
  },
};

export default events;
