import React, { PureComponent } from 'react';
import { Col } from 'antd';
import classNames from 'classnames';
import { omit } from 'lodash';

import { mapProperties } from '../../../utils/form';

class Column extends PureComponent {
  render() {
    const props = this.props;
    const {
      form = {},
      isFirst = false,
      isLast = false,
      options = {},
      properties = [],
      ...rest
    } = props;
    const { span, className = '', ...restOptions } = options;
    const total = properties.length;
    const classes = classNames(className, 'gutter-row', {
      'bdm-col-first': isFirst,
      'bdm-col-last': isLast,
      'bdm-col-not-first': !isFirst,
      'bdm-col-not-last': !isLast,
    });

    return (
      <Col className={classes} span={Number(span)} {...restOptions}>
        {properties.map(
          mapProperties({
            ...omit(rest, ['conditions', 'options']),
            form,
            total,
            title: null,
          })
        )}
      </Col>
    );
  }
}

export default Column;
