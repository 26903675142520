import React, { PureComponent } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import classNames from 'classnames';

import isMobileDevice from '../../utils/isMobileDevice';
const isMobile = isMobileDevice();

class ScrollToTopButton extends PureComponent {
  state = {
    pageY: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.spyScrolling);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.spyScrolling);
  }

  spyScrolling = () => {
    this.setState(state => {
      const pageY = window.pageYOffset;

      if (state.pageY !== pageY) {
        return {
          pageY,
        };
      }

      return null;
    });
  };

  render() {
    const { className, onClick } = this.props;
    const { pageY } = this.state;
    const buttonClassNames = classNames(className, {
      'is-visible': isMobile ? false : pageY > 100,
    });

    return (
      <div className={buttonClassNames}>
        <Button
          icon="double-right"
          shape="circle"
          type="primary"
          className={buttonClassNames}
          onClick={onClick}
        />
      </div>
    );
  }
}

const ScrollToTop = styled(ScrollToTopButton)`
  padding-bottom: 45px;

  @media (min-width: 769px) {
    display: none;
  }

  button {
    position: fixed;
    bottom: 64px;
    right: 16px;
    width: 56px;
    height: 56px;
    line-height: 56px;
    border: none;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.15), 0 0 8px rgba(0, 0, 0, 0.12);
    z-index: 1000;
    transform: translateY(200px);

    .anticon {
      position: relative;
      font-size: 2em;
      transform: rotate(-90deg) translateX(0);
    }

    &:active {
      .anticon {
        transition: transform 0.4s;
        transform: rotate(-90deg) translateX(2px);
      }
    }
  }

  &.is-visible {
    button {
      transform: translateY(0);
    }
  }

  @media print {
    display: none;
  }
`;

export default ScrollToTop;
