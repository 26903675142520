import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import { get } from 'lodash';
import cx from 'classnames';

import FieldEditorWrapper from '../FieldEditor/FieldEditor.styled';
import IconButton from '../../../../components/common/buttons/icon-button';
import componentsManager from '../../services/componentsManager';
import getFieldLabel from '../../utils/getFieldLabel';

class UnsupportedField extends PureComponent {
  getValue = (name, defaultValue) =>
    get(this, `props.values.${name}`, defaultValue) || defaultValue;

  remove = () => {
    const { field } = this.props;

    componentsManager.removeComponent(field.id);
  };

  render() {
    const { compact, field, children } = this.props;
    const fieldType = [
      getFieldLabel(field.type),
      getFieldLabel(field.field_type),
    ]
      .filter(f => f)
      .join(' | ');

    return (
      <FieldEditorWrapper
        className={cx([{ 'is-compact': compact }, 'unsupported'])}
      >
        <Row>
          <Row>
            <Col className="field-editor__actions">
              <span className="field-editor__type">{fieldType}</span>
              <span className="field-editor__btns">
                <IconButton
                  icon="delete"
                  className="is-focusable"
                  onClick={this.remove}
                />
              </span>
            </Col>
          </Row>
          <Row>
            <Col className="field-editor__input">
              {this.getValue('title', field.field_type)}
            </Col>
          </Row>
        </Row>
        <Row className="disabled">{children}</Row>
      </FieldEditorWrapper>
    );
  }
}

export default UnsupportedField;
