import get from 'lodash/get';

import capabilities from 'APP_ROOT/utils/capabilities';
import { isParticipant } from 'APP_ROOT/utils/capabilities/capabilitiesValidators';

export default props => {
  const template = get(props, 'selectedForm.template', {});
  const userId = get(props, 'user.id');
  return (
    template.shareable &&
    // if the user logged with "canShareOwnReports" permission
    // is participant of the report, should be able to share a report
    (capabilities(['canShareOwnReports'], {
      params: { userId },
      validator: isParticipant,
    }) ||
      // if the logged user has the "canShareAnyReports" permission,
      // should be able to share a report
      capabilities(['canShareAnyReports'], {
        params: {},
        validator: () => true,
      }))
  );
};
