import _ from 'lodash';
import organizationEndpoints from '../../api/organization/organizationEndpoints';

export const ORGANIZATION_OU_USERS_SUCCESS = 'ORGANIZATION_OU_USERS_SUCCESS';

export function organizationOuGetUsersSuccess(response) {
  return { type: ORGANIZATION_OU_USERS_SUCCESS, payload: response };
}

export const ORGANIZATION_OU_USERS_ERROR = 'ORGANIZATION_OU_USERS_ERROR';

export function organizationOuGetUsersError(error) {
  return { type: ORGANIZATION_OU_USERS_ERROR, error };
}

export const ORGANIZATION_OU_USERS_REQUEST = 'ORGANIZATION_OU_USERS_REQUEST';

export function organizationOuGetUsersRequest(page, size, filter) {
  return {
    type: ORGANIZATION_OU_USERS_REQUEST,
    payload: { page, size, filter },
  };
}

export default (page, size, filter, ssn, agencyId, next = () => {}) => {
  return dispatch => {
    dispatch({
      type: ORGANIZATION_OU_USERS_REQUEST,
      payload: { page, size, filter, ssn, agencyId },
    });

    return organizationEndpoints
      .getOuUsers({ page: page - 1, size, filter, customField: ssn, agencyId })
      .then(response => {
        dispatch(organizationOuGetUsersSuccess(response));
        next(null, response);
      })
      .catch(error => {
        dispatch(
          organizationOuGetUsersError(_.get(error, ['response', 'status']))
        );
        next(error);
      });
  };
};
