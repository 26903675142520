/**
 * Contains all the functions to build the timeline items for all the contribute to report actions
 */
import React from 'react';
import parsedDate from '../../utils/parse-date';
import {
  SECTION_ASSIGN_ACTION,
  SECTION_UNASSIGN_ACTION,
  SECTION_COMPLETE_ACTION,
  SECTION_INCOMPLETE_ACTION,
} from '../../constants/contributeToReport';

/**
 * Returns the appropriate wording action based on timeline action
 * @param {String} action
 * @returns {String}
 */
const getContributeAction = action => {
  switch (action) {
    case SECTION_ASSIGN_ACTION:
      return 'assigned a section to';
    case SECTION_UNASSIGN_ACTION:
      return 'unassigned a section from';
    case SECTION_COMPLETE_ACTION:
      return 'marked their assigned section complete';
    case SECTION_INCOMPLETE_ACTION:
      return 'marked their assigned section incomplete';
    default:
      return '';
  }
};

const getSubmissionMessage = (action, actor, subject) => {
  const messageBaseText = getContributeAction(action);
  let message = '';
  switch (action) {
    case SECTION_ASSIGN_ACTION:
      message = `${actor.rankName} ${actor.fullName} ${messageBaseText} ${subject.rankName} ${subject.fullName}`;
      break;
    case SECTION_UNASSIGN_ACTION:
      message = `${actor.rankName} ${actor.fullName} ${messageBaseText} ${subject.rankName} ${subject.fullName}`;
      break;
    case SECTION_COMPLETE_ACTION:
      message = `${subject.rankName} ${subject.fullName} ${messageBaseText}`;
      break;
    case SECTION_INCOMPLETE_ACTION:
      message = `${subject.rankName} ${subject.fullName} ${messageBaseText}`;
      break;
    default:
      message = '';
  }
  return message.trim();
};

const getParticipantDetails = (participants, id) => {
  const participant = participants[id] || {};
  return {
    fullName: participant.fullName || '',
    rankName: participant.rank?.name || '',
  };
};

const contributeReportItem = ({ item, index, timezone, participants }) => {
  const actor = getParticipantDetails(participants, item.assignatId);
  const subject = getParticipantDetails(participants, item.assignedId);
  const submissionMessage = getSubmissionMessage(item.action, actor, subject);
  return (
    <div>
      <p className={`timeline-item-date-${index}`}>
        {parsedDate(item.createdAt, timezone)}
      </p>
      <p>
        <b>{` ${item.formType || ''} ${item.formNumber || ''} `}</b>
      </p>
      <p
        className={`timeline-item-message-${index}`}
        dangerouslySetInnerHTML={{ __html: submissionMessage }}
      />
    </div>
  );
};

export default contributeReportItem;
