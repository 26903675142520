import getRoute from 'APP_ROOT/utils/get-route';
import apiEndpoints from '../../services/apiEndpoints';
import { message } from 'antd';

export default ({ template, closeModal, push, agencyId }) => () => {
  apiEndpoints.getForm(agencyId, template.id, template.default).then(schema => {
    push(
      getRoute(
        'administratorAgencyFormBuilder',
        {
          agencyId,
          id: 'create',
        },
        { isDefault: false }
      ),
      {
        import: true,
        schema,
      }
    );
    message.success('Form successfully loaded.', 10);
  });
  closeModal();
  message.loading('Loading..');
};
