const KEYWORDS = [
  'eq',
  'and',
  'or',
  'gt',
  'gte',
  'lt',
  'lte',
  'between',
  'inq',
  'nin',
  'near',
  'neq',
  'like',
  'nlike',
  'ilike',
  'nilike',
  'where',
  'limit',
  'skip',
  'order',
  'regexp',
];

function isKeyword(name) {
  return KEYWORDS.includes(name.toLowerCase());
}

function invert(name) {
  return name
    .toLowerCase()
    .split('')
    .reverse()
    .join('');
}

function mangle(value) {
  if (!value) return value;

  if (Array.isArray(value)) {
    return value.map(item => mangle(item));
  } else if (typeof value === 'object') {
    const mangled = {};
    Object.keys(value).forEach(key => {
      const name = isKeyword(key) ? invert(key) : key;
      mangled[name] = mangle(value[key]);
    });
    return mangled;
  }
  return value;
}

module.exports = {
  mangle,
};
