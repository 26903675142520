import { kebabCase } from 'lodash';

const getTemplatesByType = (templates, formatKeyWith = kebabCase) => {
  const formatKey = key => (formatKeyWith ? formatKeyWith(key) : key);

  return Object.values(templates).reduce(
    (_output, template) => ({
      ..._output,
      [formatKey(template.type)]: template,
    }),
    {}
  );
};

export default getTemplatesByType;
