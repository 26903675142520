import { isNaN, isNumber } from 'lodash';
import { URL_SEARCH_PARAM_PAGE } from './constants';

const getPageRetention = history => {
  const {
    location: { search },
  } = history;
  const params = new URLSearchParams(search);
  const page = parseInt(params.get(URL_SEARCH_PARAM_PAGE), 10);
  return !isNaN(page) && isNumber(page) ? page : 1;
};

export default getPageRetention;
