import styled from 'styled-components';
import { PanelSection } from './';
import { GRAY_2 } from '../../../../config/theme';

export const PanelTableSection = styled(PanelSection)`
  p.title {
    font-weight: regular;
    color: #000;
    font-size: 12px;
  }

  p {
    font-size: 12px;
    color: ${GRAY_2};
  }
`;

export default PanelTableSection;
