import { camelCase } from 'lodash';

import FormBuilderList from './FormBuilderList';
import FormBuilder from './FormBuilder';
import TemplateChangelog from './template-changelog/TemplateChangelog';
import { PERMISSIONS } from '../../../utils/admin';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/form-builder/:id`,
    name: camelCase(`${name}-form-builder`),
    component: FormBuilder,
    protectedWith: [PERMISSIONS.manageAgencies, PERMISSIONS.manageForms],
  },
  {
    path: `${path}/form-builder`,
    name: camelCase(`${name}-form-builder-list`),
    component: FormBuilderList,
    protectedWith: [PERMISSIONS.manageAgencies, PERMISSIONS.manageForms],
  },
  {
    path: `${path}/changelog/:templateId/:templateName`,
    name: camelCase(`${name}-changelog`),
    component: TemplateChangelog,
    protectedWith: [PERMISSIONS.manageAgencies, PERMISSIONS.manageForms],
  },
];
