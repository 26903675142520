import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Icon, Button } from 'antd';

import DashboardPage from '../../components/dashboard';
// import OfficerDashboard from './officer';
// import SergeantDashboard from './sergeant';
// import CommanderDashboard from './commander';
// import IACommanderDashboard from './ia-commander';
// import CareAdminDashboard from './care-admin';
// import AdminDashboard from './admin';
import withActivites from './activities';

import ActivitiesPanel from '../../components/dashboard/activities-panel';
import markNotificationSeen from '../../actions/mark-seen-notification';
import markNotificationDismiss from '../../actions/mark-dismiss-notification';

// import Authorization from '../../components/authorization';

// import ROLES from '../../config/role-mapping';

import getUser from '../../actions/get-user';
import { getAgencyTZ } from '../../selectors/session';
import parseDate from '../../utils/parse-date';
import { supportedAgency } from '../../actions/utils';
import getSupportedAgency from '../../actions/get-supported-agency';

class Dashboard extends Component {
  static proptTypes = {
    currentUser: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static getPageConfig() {
    return {
      title: 'Home',
    };
  }

  getSupportedAgency = async (agencyId, dispatch) => {
    const { content } = await supportedAgency(agencyId);
    dispatch(
      getSupportedAgency({
        isAgencyNewWorkFlowActive: content,
        agencyWorkFlowInformationLoaded: true,
      })
    );
  };

  UNSAFE_componentWillMount() {
    const {
      currentUser,
      dispatch,
      currentUser: { agency: { id = '' } = {} } = {},
      agencyWorkFlowInformationLoaded,
    } = this.props;

    if (!currentUser.email) {
      dispatch(getUser());
    }

    if (!agencyWorkFlowInformationLoaded) {
      this.getSupportedAgency(id, dispatch);
    }
  }

  sortByStale = (a, b) => {
    const itemA = get(a, 'isStale', false);
    const itemB = get(b, 'isStale', false);
    if (!itemB && itemA) {
      return -1;
    }
    if (!itemA && itemB) {
      return 1;
    }
    return 0;
  };

  getTitle = () => {
    const {
      currentUser: { roles },
    } = this.props;
    return Array.isArray(roles) && !!roles.length ? `BDM ${roles[0]}` : 'Home';
  };

  getActionItems = () => {
    const {
      actionItems: { data = [], entries = {} } = {},
      timezone,
    } = this.props;

    return Object.values(data)
      .map(itemId => {
        const {
          title,
          created_at,
          data: {
            formId,
            casefileId,
            isStale = false,
            notificationLabel = 'Take Action',
            url,
          } = {},
          id,
          new: bold,
          isDismissable,
          provider = 'bms',
          type,
          action,
        } = entries[itemId];

        const status = {
          label: notificationLabel,
          key: 'take-action',
        };

        return {
          message: title,
          meta: parseDate(created_at, timezone),
          status,
          id,
          formId,
          casefileId,
          bold,
          isStale,
          isDismissable,
          type,
          action,
          provider,
          url,
        };
      })
      .sort(this.sortByStale);
  };

  getActivityItems = () => {
    const {
      activities: { data = [], entries = {} } = {},
      timezone,
    } = this.props;

    return Object.values(data).map(itemId => {
      const {
        title,
        created_at,
        data: { formId } = {},
        id,
        new: bold,
      } = entries[itemId];

      return {
        message: title,
        meta: parseDate(created_at, timezone),
        id,
        formId,
        bold,
      };
    });
  };

  markActionAsDismiss = (id, e) => {
    const { dispatch } = this.props;
    dispatch(markNotificationDismiss(id));
    if (e) {
      e.preventDefault();
    }
  };

  markActivityAsRead = id => {
    const { dispatch } = this.props;

    dispatch(markNotificationSeen(id));
  };

  viewActionItems = () => {
    const {
      history: { push },
    } = this.props;
    push('action-item-feed');
  };

  viewActivities = () => {
    const {
      history: { push },
    } = this.props;
    push('activity-feed');
  };

  renderRightActions = (loading, refreshing, ActionsListCount = 0) => {
    return (
      <>
        {loading || refreshing ? <Icon type="loading" /> : null}
        {ActionsListCount > 10 ? (
          <Button type="primary" ghost onClick={this.viewActionItems}>
            View All Action Items
          </Button>
        ) : null}
      </>
    );
  };

  renderRightActivites = (loading_activities, refreshing_activities) => {
    return (
      <>
        {loading_activities || refreshing_activities ? (
          <Icon type="loading" />
        ) : null}
        <Button type="primary" ghost onClick={this.viewActivities}>
          View All Activity Feed
        </Button>
      </>
    );
  };

  render() {
    const {
      actionItems: { loading, total: actionItemsTotal, refreshing } = {},
      activities: {
        loading: loading_activities,
        refreshing: refreshing_activities,
      } = {},
      session,
    } = this.props;
    return (
      session.isLoggedIn && (
        <div>
          <div className="dashboard-container">
            <ActivitiesPanel
              data={this.getActionItems()}
              showArrow={false}
              right={this.renderRightActions(
                loading,
                refreshing,
                actionItemsTotal
              )}
              title={
                <div>
                  <h2>Action Items</h2>
                  <h3 style={{ fontSize: 15 }}>
                    You have {actionItemsTotal} total Action Items
                  </h3>
                </div>
              }
              onItemClick={this.markActivityAsRead}
              onItemDismiss={this.markActionAsDismiss}
            />

            <ActivitiesPanel
              data={this.getActivityItems()}
              right={this.renderRightActivites(
                loading_activities,
                refreshing_activities
              )}
              title={
                <div>
                  <h2>Current Activities</h2>
                </div>
              }
              onItemClick={this.markActivityAsRead}
            />
          </div>

          {/* <Authorization
            roles={[ROLES.policeOfficer]}
            render={<OfficerDashboard />}
            />

            <Authorization
            roles={[ROLES.commander]}
            render={<CommanderDashboard />}
            />

            <Authorization
            roles={[ROLES.iaCommander]}
            render={<IACommanderDashboard />}
            />

            <Authorization
            roles={[ROLES.careAdmin]}
            render={<CareAdminDashboard />}
            />

            <Authorization
            roles={[ROLES.sergeant]}
            render={<SergeantDashboard />}
            />

            <Authorization roles={[ROLES.admin]} render={<AdminDashboard />} />

            <Authorization
            roles={[ROLES.admin, ROLES.superAdmin]}
            render={props => <h2>{this.getTitle()}</h2>}
            /> */}
        </div>
      )
    );
  }
}

const mapState = state => {
  const {
    session: { currentUser },
    supportedAgency: { agencyWorkFlowInformationLoaded },
  } = state;

  return {
    currentUser,
    timezone: getAgencyTZ(state),
    agencyWorkFlowInformationLoaded,
  };
};

export default withActivites(DashboardPage(mapState, null)(Dashboard));
