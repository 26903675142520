const updateComponent = field => (allFields, currentField) =>
  allFields.concat({
    ...currentField,
    ...field,
    options: {
      ...currentField.options,
      ...field.options,
    },
  });

export default updateComponent;
