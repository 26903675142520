import OrganizationEndpoints from '../../../api/organization/organizationEndpoints';

export default (pageData = {}) => {
  return new Promise((resolve, reject) => {
    OrganizationEndpoints.getOrganizationalUnits(pageData)
      .then(response => {
        const list = response.organizationalUnits.map(ou => {
          const {
            id,
            displayName: name,
            phoneNumber: phone,
            website,
            ouParentId = '',
            ouParentDisplayName = '',
          } = ou;
          return {
            id,
            name,
            phone,
            website,
            parentId: ouParentId,
            parentName: ouParentDisplayName,
          };
        });
        resolve({ data: list, total: response.total, error: null });
      })
      .catch(error => {
        reject({ data: null, error });
      });
  });
};
