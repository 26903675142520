import { isEmpty, has } from 'lodash';

import MathFieldOptions from './getMathFieldOptions';
import { SELECT, NUMBER, MATH } from '../../../../constants/fieldTypes';
import { MATH_OPERATION_SUM, MATH_ROUND } from '../../constants/constants';

import componentsManager from '../../services/componentsManager';
import getCascaderOptionsFromFields from '../getCascaderOptionsFromFields';
import getCascaderOption, { saveValue } from './getCascaderOption';

export default class {
  constructor(values) {
    this.name = 'math';
    this.label = '';
    this.fieldKey = values.key;
    this.fieldId = values.id;
    this.component = MathFieldOptions;
    this.defaultValue = {
      operands: [],
      operation: MATH_OPERATION_SUM,
      rounding: MATH_ROUND,
    };
    this.filterTypes = [NUMBER, SELECT, MATH];

    this.getCascaderOptions();
    this.parentRepeater =
      componentsManager.findItemInRepeatersReference({ key: this.fieldKey }) ||
      '';
  }

  selectValuesNumeric = data => {
    if (has(data, 'enums')) {
      return data.enums.every(value => this.selectValuesNumeric(value));
    } else {
      return !isNaN(data.value) && !isNaN(parseFloat(data.value));
    }
  };
  filterBy = prop => {
    if (this.filterTypes.includes(prop.field_type)) {
      // TODO: validate select options are numeric
      if (prop.field_type === SELECT) {
        const enumData = componentsManager.getEnum(
          prop.enumRef,
          prop.parentEnum
        );
        return enumData.every(value => this.selectValuesNumeric(value));
      }
      return true;
    }
    return false;
  };

  getCascaderOptions = () => {
    this.cascaderOptions = getCascaderOptionsFromFields(
      this.fieldId,
      false,
      this.filterBy
    );

    return this.cascaderOptions;
  };

  isValid = values => {
    const { operands } = values[this.name];

    return !isEmpty(operands);
  };

  onChange = (value, values) => {
    return {
      ...values,
      [this.name]: value,
    };
  };

  onSave = values => {
    const { operands, operation, rounding } = values[this.name];
    const { options = {} } = values;

    return {
      ...values,
      [this.name]: undefined,
      options: {
        ...options,
        operands: operands
          .filter(op => !isEmpty(op))
          .map(op => saveValue(this.parentRepeater, op)),
        operation,
        rounding,
      },
    };
  };

  getInitialValue = values => {
    if (values.options === undefined) {
      return this.defaultValue;
    }
    const {
      operands: _operands,
      operation: _operation,
      rounding: _rounding,
    } = this.defaultValue;

    const {
      operands = _operands,
      operation = _operation,
      rounding = _rounding,
    } = values.options;

    // operands could have data.key, meaning field is in a repeater,
    // but operands is not
    return {
      operands: operands.map(op =>
        getCascaderOption(this.cascaderOptions, this.parentRepeater, op)
      ),
      operation,
      rounding,
    };
  };
}
