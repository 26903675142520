import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import Panel, {
  PanelTitle,
  Divider,
  PanelSection,
  PanelRow,
} from '../common/panel';
import Tag from '../common/tag';
import IconButton from '../common/buttons/icon-button';
import { Row, Col } from 'antd';
import { SECTION_COMPLETE_ACTION } from '../../constants/contributeToReport';
const alert = require('../../icons/ic_alert_triangle.svg');

const ActivitiesPanel = ({
  data = [],
  header = '',
  footer = '',
  title = '',
  showArrow = true,
  right,
  onItemClick,
  onItemDismiss,
  onArrowClick,
  showTitle = true,
}) => {
  const voidData = !data || !data.length;
  return (
    <Panel className="bdm-panel-activity-list">
      {showTitle && (
        <PanelTitle
          className="activities-panel-title"
          right={
            right
              ? right
              : !voidData &&
                showArrow && (
                  <IconButton
                    icon="arrow-right"
                    onClick={e => onArrowClick()}
                  />
                )
          }
        >
          {!title && <h2>Messages</h2>}
          {title && title}
        </PanelTitle>
      )}

      {header && (
        <div>
          <PanelSection>
            <PanelRow className="marginless bdm-activities-panel-header">
              {header}
            </PanelRow>
          </PanelSection>
          <Divider />
        </div>
      )}

      {data.length > 0 && showTitle && <Divider />}

      <PanelSection>
        {data.map((item, messageIndex) => {
          const isAction = item.isAction || item.isActionItem;
          const { isStale, isDismissable, type } = item;
          const linkDismissable =
            (isDismissable && ['share', 'reassign'].includes(type)) ||
            (item.casefileId && type === 'unshare' && isDismissable) ||
            (item.casefileId && type === 'unassigned' && isDismissable);
          const rowClassNames = classNames({
            'row-item-is-action': isAction,
            'row-item-is-stale': isStale,
          });
          const allowDismissableIcon =
            (type === 'share' && !item.casefileId) ||
            item.action === SECTION_COMPLETE_ACTION;
          const getItemUrl = () => {
            switch (item.provider) {
              case 'kmi':
                return { external: true, url: get(item, 'url') };
              default:
                if (item.casefileId) {
                  return {
                    external: false,
                    casefile: true,
                    url:
                      item.urlBlocked || linkDismissable
                        ? '#'
                        : `/files/${item.casefileId}`,
                  };
                }
                return {
                  external: false,
                  url:
                    item.urlBlocked || linkDismissable
                      ? '#'
                      : `/reports/${item.formId}`,
                };
            }
          };

          const onClickRenderFunction = (
            allowDismissableIcon,
            linkDismissable
          ) => {
            if (allowDismissableIcon) {
              return onItemDismiss(item.id);
            }
            if (!linkDismissable) {
              return onItemClick(item.id);
            }
            return;
          };

          const Wrapper = ({ children }) => {
            if (!isAction) {
              const { url, external, casefile = false } = getItemUrl();
              const LinkComponent = ({ to, children, ...linkProps }) =>
                external ? (
                  <a href={to} {...linkProps} alt={to}>
                    {children}
                  </a>
                ) : (
                  <Link to={to} {...linkProps}>
                    {children}
                  </Link>
                );

              return (
                <div className="block-section">
                  <LinkComponent
                    to={url}
                    className={!linkDismissable ? 'block-link' : 'block-share'}
                    onClick={e =>
                      onClickRenderFunction(
                        casefile ? true : allowDismissableIcon,
                        linkDismissable
                      )
                    }
                  >
                    {children}
                  </LinkComponent>
                </div>
              );
            }

            return <div>{children}</div>;
          };

          const dataClassNames = classNames('pull-left', 'pull-sm-none', {
            stroked: !isAction && !!item.stroked,
            bold: item.bold,
          });

          const renderDismissIcon = (
            isDismissable,
            linkDismissable,
            allowDismissableIcon,
            id
          ) => {
            if (isDismissable || linkDismissable || allowDismissableIcon) {
              return (
                <IconButton
                  className="block-dismiss"
                  icon="close"
                  onClick={e => onItemDismiss(id, e)}
                />
              );
            }
            return;
          };

          return (
            <Wrapper key={item.id ? `id-${item.id}` : `idx-${messageIndex}`}>
              <PanelRow
                className={rowClassNames}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Row style={{ flexGrow: 1 }}>
                  <Col xs={24} md={12}>
                    <div
                      className={dataClassNames}
                      dangerouslySetInnerHTML={{ __html: item.message }}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="meta text-right text-xs-left">
                      <span className="meta-content">
                        {item.isStale && (
                          <img src={alert} className="meta-icon" alt="Alert" />
                        )}
                        {item.status && !linkDismissable && !item.casefileId && (
                          <Tag statusTag size="default" type={item.status.key}>
                            {item.status.label}
                          </Tag>
                        )}
                        {item.meta && (
                          <span className="meta-updated">{item.meta}</span>
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
                <div
                  style={{
                    minWidth: '50px',
                    maxWidth: '50px',
                    textAlign: 'right',
                  }}
                >
                  {!linkDismissable && <IconButton icon="right" />}
                  {renderDismissIcon(
                    isDismissable,
                    linkDismissable,
                    allowDismissableIcon,
                    item.id
                  )}
                </div>
              </PanelRow>
            </Wrapper>
          );
        })}
      </PanelSection>

      {footer && (
        <div>
          <Divider />
          <PanelSection>
            <PanelRow className="marginless bdm-activities-panel-footer">
              {footer}
            </PanelRow>
          </PanelSection>
        </div>
      )}
    </Panel>
  );
};

export default ActivitiesPanel;
