import { assign, find, omit } from 'lodash';

function getUserDocumentsValues({ userDocumentsData = {} }, state) {
  const { documentsData } = state;
  const { documents } = userDocumentsData;
  let finalUserDocuments = [];
  let userDocuments = [];
  let changedRecords = [];
  let newRecords = [];

  if (documents) {
    const keys = Object.keys(omit(documents, ['uploaded']));
    keys.forEach(key => {
      let documentRecord = {};
      documents[key].forEach((value, index) => {
        const savedDocument = documentsData.find(
          doc => doc.id == documents.id[index]
        );
        if (savedDocument) {
          if (savedDocument[key] !== value) {
            changedRecords.push(documents.id[index]);
          }
        } else {
          changedRecords.push(documents.id[index]);
          newRecords.push(documents.id[index]);
        }
        documentRecord[key] = value;
        userDocuments[index] = assign(userDocuments[index], documentRecord);
      });
    });

    //Remove items that were not changed when the record was edited
    //and remove the id from new records
    finalUserDocuments = userDocuments
      .filter(doc => find(changedRecords, id => id == doc.id))
      .map(record =>
        find(newRecords, id => id == record.id) ? omit(record, ['id']) : record
      );
  }

  return finalUserDocuments.length ? { documents: finalUserDocuments } : null;
}

export default getUserDocumentsValues;
