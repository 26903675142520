import React from 'react';
import {
  Row,
  Col,
  Input as InputField,
  Select,
  InputNumber,
  DatePicker,
} from 'antd';
import { Field } from 'redux-form';
import { uniq, kebabCase, get, orderBy, isEmpty } from 'lodash';
import Switch from '../../../../../components/form-viewer/styled/input-switch';
import Input, { InlineInputWrapper } from '../../../components/input';
import If from '../../../../../components/utils/ConditionalRender';
import {
  formatDate,
  readOnlyFormatDateNoTimeZone,
  BENCHMARK_COMPLETE_BIRTHDAY_FORMAT,
  momentWithTZ,
} from '../../../../../utils/parse-date';

import UserPicture from '../UserPicture';
import EncryptedInput, {
  MASK_TYPES,
} from '../../../../../components/common/encrypted-input/EncryptedInput';
import { WrappedSupervisorField } from './SupervisorField';
import {
  commonFieldOptions,
  noLabelFieldOptions,
  smallFieldOptions,
  genderOptions,
  raceOptions,
  ethnicityOptions,
  dominantHandOptions,
  dateOptionsBeforeNow,
  dateOptions,
  coverToNumber,
} from './FieldBuilderOptions';

const TextField = Input(InputField);
const NumberField = Input(InputNumber);
const DateField = Input(DatePicker);
const SwitchField = Input(Switch);
const SSNField = Input(EncryptedInput);
const SelectField = Input(Select);
const Option = Select.Option;
export class FieldBuilder {
  constructor({
    profileForm,
    fetchingAgency,
    shouldBeReadOnlyField,
    shouldSeeAnyField,
    getFieldLabel,
    organizationalUnitsAssignedToUserList = [],
    isNewUser,
    userPictureSource,
    ranks,
    timezone,
    agencySecurityRoles,
    unitAssignments,
    canUpdateSSN,
    canViewUnMaskedSSN,
    canModifyRoles,
    hireDate,
  }) {
    this.profileForm = profileForm;
    this.fetchingAgency = fetchingAgency;
    this.shouldBeReadOnlyField = shouldBeReadOnlyField;
    this.shouldSeeAnyField = shouldSeeAnyField;
    this.getFieldLabel = getFieldLabel;
    this.organizationalUnitsAssignedToUserList = organizationalUnitsAssignedToUserList;
    this.isNewUser = isNewUser;
    this.userPictureSource = userPictureSource;
    this.ranks = ranks;
    this.timezone = timezone;
    this.agencySecurityRoles = agencySecurityRoles;
    this.unitAssignments = unitAssignments;
    this.canUpdateSSN = canUpdateSSN;
    this.canViewUnMaskedSSN = canViewUnMaskedSSN;
    this.canModifyRoles = canModifyRoles;
    this.hireDate = hireDate;
    this.fields = new Map();
    this.initFields();
  }

  buildAgencyField() {
    const agencyField =
      this.organizationalUnitsAssignedToUserList.length > 0 ? (
        <If condition={this.shouldSeeAnyField('agency')}>
          <Field
            type="text"
            data-test="agency"
            label={this.getFieldLabel('agency')}
            component={TextField}
            options={commonFieldOptions}
            disabled={this.fetchingAgency}
            defaultValue={this.organizationalUnitsAssignedToUserList
              .map(ou => ou.label)
              .join(', ')}
            plainText
          />
        </If>
      ) : (
        <If condition={this.shouldSeeAnyField('agency')}>
          <Field
            type="text"
            name="agency"
            data-test="agency"
            label={this.getFieldLabel('agency')}
            component={TextField}
            options={commonFieldOptions}
            disabled={this.fetchingAgency}
            plainText
          />
        </If>
      );
    this.fields.set('agency', <div>{agencyField}</div>);
  }

  buildUserPicture() {
    this.fields.set(
      'userPicture',
      <UserPicture src={this.userPictureSource} />
    );
  }

  buildHideProfilePicture() {
    this.fields.set(
      'hideProfilePicture',
      <Field
        type="boolean"
        name="hideProfilePicture"
        data-test="hideProfilePicture"
        label={this.getFieldLabel('hideProfilePicture')}
        component={SwitchField}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        inputProps={{
          checked: get(this.profileForm, 'values.hideProfilePicture'),
          size: 'large',
          checkedChildren: 'Yes',
          unCheckedChildren: 'No',
        }}
        plainText={this.shouldBeReadOnlyField(['hideProfilePicture'])}
      />
    );
  }

  buildBenchmarkId() {
    this.fields.set(
      'benchmarkId',
      <Field
        type="text"
        name="benchmarkId"
        data-test="benchmarkId"
        label={this.getFieldLabel('benchmarkId')}
        component={TextField}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText
      />
    );
  }

  buildEmployeeId() {
    this.fields.set(
      'employeeId',
      <Field
        type="text"
        name="employeeId"
        data-test="employeeId"
        label={this.getFieldLabel('employeeId')}
        component={TextField}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText={
          !this.isNewUser && this.shouldBeReadOnlyField(['employeeId'])
        }
      />
    );
  }

  buildPostId() {
    this.fields.set(
      'postId',
      <Field
        type="text"
        name="postId"
        data-test="postId"
        label={this.getFieldLabel('postId')}
        component={TextField}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText={!this.isNewUser && this.shouldBeReadOnlyField(['postId'])}
      />
    );
  }

  buildStarNumber() {
    this.fields.set(
      'starNumber',
      <Field
        type="text"
        name="starNumber"
        data-test="starNumber"
        label={this.getFieldLabel('starNumber')}
        component={TextField}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['starNumber'])}
      />
    );
  }

  buildFirstName() {
    this.fields.set(
      'firstName',
      <Field
        type="text"
        name="firstName"
        data-test="firstName"
        label={this.getFieldLabel('firstName')}
        component={TextField}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['firstName'])}
      />
    );
  }

  buildMiddleName() {
    this.fields.set(
      'middleName',
      <Field
        type="text"
        name="middleName"
        data-test="middleName"
        label={this.getFieldLabel('middleName')}
        component={TextField}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['middleName'])}
      />
    );
  }

  buildLastName() {
    this.fields.set(
      'lastName',
      <Field
        type="text"
        name="lastName"
        data-test="lastName"
        label={this.getFieldLabel('lastName')}
        component={TextField}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['lastName'])}
      />
    );
  }

  buildSuffix() {
    this.fields.set(
      'suffix',
      <Field
        type="text"
        name="suffix"
        data-test="suffix"
        label={this.getFieldLabel('suffix')}
        component={TextField}
        options={smallFieldOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['suffix'])}
      />
    );
  }

  buildRank() {
    this.fields.set(
      'rank',
      <Field
        type="select"
        name="rank"
        data-test="rank"
        label={this.getFieldLabel('rank')}
        placeholder="Please Select"
        component={SelectField}
        options={commonFieldOptions}
        inputProps={{ showSearch: true }}
        disabled={this.fetchingAgency}
        dropdownOptions={orderBy(uniq(this.ranks), ['name'], ['asc']).map(
          ({ name, id }) => (
            <Option key={id} value={name}>
              {name}
            </Option>
          )
        )}
        plainText={this.shouldBeReadOnlyField(['rank'])}
      />
    );
  }

  buildBirthday() {
    this.fields.set(
      'birthday',
      <Field
        type="datepicker"
        name="birthday"
        data-test="birthday"
        label={this.getFieldLabel('birthday')}
        placeholder="Please Select"
        component={DateField}
        inputProps={dateOptionsBeforeNow}
        options={commonFieldOptions}
        format={value => {
          // return empty to avoid the anoying `Invalid date` message
          if (isEmpty(value)) return '';
          if (this.shouldBeReadOnlyField(['birthday'])) {
            return momentWithTZ(value, null, null, false).format(
              BENCHMARK_COMPLETE_BIRTHDAY_FORMAT
            );
          }
          return momentWithTZ(value, null, null, false);
        }}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['birthday'])}
      />
    );
  }

  buildGender() {
    this.fields.set(
      'gender',
      <Field
        type="select"
        name="gender"
        data-test="gender"
        label={this.getFieldLabel('gender')}
        placeholder="Please Select"
        component={SelectField}
        inputProps={{ showSearch: true }}
        options={commonFieldOptions}
        dropdownOptions={genderOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['gender'])}
      />
    );
  }

  buildReferencingGender() {
    this.fields.set(
      'referencingGender',
      <Field
        type="select"
        name="referencingGender"
        data-test="referencingGender"
        label={this.getFieldLabel('referencingGender')}
        placeholder="Please Select"
        component={SelectField}
        inputProps={{ showSearch: true }}
        options={commonFieldOptions}
        dropdownOptions={genderOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['referencingGender'])}
      />
    );
  }

  buildRace() {
    this.fields.set(
      'race',
      <Row>
        <Col span={24}>
          <Field
            type="select"
            name="race"
            data-test="race"
            label={this.getFieldLabel('race')}
            placeholder="Please Select"
            component={SelectField}
            inputProps={{ showSearch: true }}
            options={commonFieldOptions}
            dropdownOptions={raceOptions}
            disabled={this.fetchingAgency}
            plainText={this.shouldBeReadOnlyField(['race'])}
          />
        </Col>
        <Col span={24}>
          {get(this.profileForm, 'values.race') === 'Other' && (
            <Field
              type="text"
              name="raceOther"
              data-test="raceOther"
              label={this.getFieldLabel('raceOther')}
              placeholder="Specify"
              component={TextField}
              options={commonFieldOptions}
              disabled={this.fetchingAgency}
              plainText={this.shouldBeReadOnlyField(['raceOther'])}
            />
          )}
        </Col>
      </Row>
    );
  }

  buildEthnicity() {
    this.fields.set(
      'ethnicity',
      <Row>
        <Col span={24}>
          <Field
            type="select"
            name="ethnicity"
            data-test="ethnicity"
            label={this.getFieldLabel('ethnicity')}
            placeholder="N/A"
            component={SelectField}
            options={commonFieldOptions}
            dropdownOptions={ethnicityOptions}
            disabled={this.fetchingAgency}
            plainText={this.shouldBeReadOnlyField(['ethnicity'])}
          />
        </Col>
      </Row>
    );
  }

  buildRoles() {
    const filterOption = (input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    const editRoles =
      !this.shouldBeReadOnlyField(['roles']) && this.canModifyRoles;

    this.fields.set(
      'roles',
      <Field
        mode="multiple"
        type="select"
        name="role"
        data-test="roles"
        label={this.getFieldLabel('roles')}
        placeholder="Please Select"
        component={SelectField}
        options={commonFieldOptions}
        optionFilterProp="children"
        disabled={this.fetchingAgency}
        size="default"
        dropdownOptions={this.agencySecurityRoles.map((role, index) => (
          <Option key={index} value={role.id + []}>
            {role.name}
          </Option>
        ))}
        plainDropdownOptions={this.agencySecurityRoles.map(role => ({
          label: role.name,
          value: role.id.toString(),
        }))}
        plainText={!editRoles}
        inputProps={{
          filterOption,
          showSearch: true,
        }}
      />
    );
  }

  buildSworn() {
    this.fields.set(
      'sworn',
      <Field
        type="boolean"
        name="sworn"
        data-test="sworn"
        label={this.getFieldLabel('sworn')}
        component={SwitchField}
        inputProps={{
          checked: get(this.profileForm, 'values.sworn'),
          size: 'large',
          checkedChildren: 'Yes',
          unCheckedChildren: 'No',
        }}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['sworn'])}
      />
    );
  }

  buildHeight() {
    this.fields.set(
      'height',
      <InlineInputWrapper
        data-test="height"
        label={this.getFieldLabel('height')}
        options={commonFieldOptions}
      >
        <Row>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Field
              type="number"
              name="feet"
              data-test="height feet"
              label=""
              component={NumberField}
              options={noLabelFieldOptions}
              addon="ft"
              inputProps={{ min: 0 }}
              defaultValue={0}
              normalize={coverToNumber}
              disabled={this.fetchingAgency}
              plainText={this.shouldBeReadOnlyField(['height'])}
            />
            <div className="clearfix" />
          </Col>
          <Col xs={24} sm={12} md={12} lg={12}>
            <Field
              type="number"
              name="inches"
              data-test="height inches"
              label=""
              component={NumberField}
              options={noLabelFieldOptions}
              addon="in"
              inputProps={{ max: 12, min: 0 }}
              defaultValue={0}
              normalize={coverToNumber}
              disabled={this.fetchingAgency}
              plainText={this.shouldBeReadOnlyField(['height'])}
            />
            <div className="clearfix" />
          </Col>
        </Row>
      </InlineInputWrapper>
    );
  }

  buildWeight() {
    this.fields.set(
      'weight',
      <Field
        type="number"
        name="weight"
        data-test="weight"
        label={this.getFieldLabel('weight')}
        component={NumberField}
        options={commonFieldOptions}
        addon="lbs"
        defaultValue={0}
        inputProps={{ min: 0 }}
        normalize={coverToNumber}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['weight'])}
      />
    );
  }

  buildDominantHand() {
    this.fields.set(
      'dominantHand',
      <Field
        type="select"
        name="dominantHand"
        data-test="dominantHand"
        label={this.getFieldLabel('dominantHand')}
        placeholder="Please Select"
        component={SelectField}
        inputProps={{ showSearch: true }}
        options={commonFieldOptions}
        dropdownOptions={dominantHandOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['dominantHand'])}
      />
    );
  }

  buildAppointmentDate() {
    this.fields.set(
      'appointmentDate',
      <Field
        type="datepicker"
        name="appointmentDate"
        data-test="appointmentDate"
        label={this.getFieldLabel('appointmentDate')}
        placeholder="Please Select"
        component={DateField}
        inputProps={dateOptions}
        options={commonFieldOptions}
        format={value => {
          // return empty to avoid the anoying `Invalid date` message
          if (isEmpty(value)) return '';
          if (this.shouldBeReadOnlyField(['appointmentDate'])) {
            return readOnlyFormatDateNoTimeZone(value, 'MM/DD/YYYY');
          }
          return formatDate(value, this.timezone);
        }}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['appointmentDate'])}
      />
    );
  }

  buildUnitAssignment() {
    this.fields.set(
      'unitAssignment',
      <Field
        type="select"
        name="unitAssignment"
        data-test="unitAssignment"
        label={this.getFieldLabel('unitAssignment')}
        placeholder="Please Select"
        component={SelectField}
        inputProps={{ showSearch: true }}
        options={commonFieldOptions}
        plainText={this.shouldBeReadOnlyField(['unitAssignment'])}
        dropdownOptions={this.unitAssignments.map(unitAssignment => (
          <Option key={kebabCase(unitAssignment)} value={unitAssignment}>
            {unitAssignment}
          </Option>
        ))}
      />
    );
  }

  buildCertified() {
    this.fields.set(
      'certified',
      <Field
        type="boolean"
        name="certified"
        data-test="certified"
        label={this.getFieldLabel('certified')}
        component={SwitchField}
        inputProps={{
          checked: get(this.profileForm, 'values.certified'),
          size: 'large',
          checkedChildren: 'Yes',
          unCheckedChildren: 'No',
        }}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['certified'])}
      />
    );
  }

  buildcustomField1() {
    this.fields.set(
      'customField1',
      <Field
        type="text"
        name="customField1.value"
        data-test="customField1"
        label={this.getFieldLabel('customField1')}
        component={SSNField}
        inputProps={{
          canEdit: this.canUpdateSSN,
          canView: this.canViewUnMaskedSSN,
          mask: MASK_TYPES.SSN,
        }}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['customField1'])}
      />
    );
  }

  buildSupervisor() {
    this.fields.set(
      'supervisor',
      this.shouldBeReadOnlyField(['supervisor']) ? (
        <Field
          type="text"
          name="supervisor"
          data-test="supervisor"
          label={this.getFieldLabel('supervisor')}
          component={TextField}
          options={commonFieldOptions}
          disabled={this.fetchingAgency}
          plainText
        />
      ) : (
        <Field
          type="text"
          name="supervisor"
          data-test="supervisor"
          label={this.getFieldLabel('supervisor')}
          component={props => (
            <WrappedSupervisorField
              agencyId={get(this.profileForm, 'values.agencyId')}
              {...props}
            />
          )}
          options={commonFieldOptions}
          disabled={this.fetchingAgency}
        />
      )
    );
  }

  buildContEducation() {
    this.fields.set(
      'contEducation',
      <Field
        type="boolean"
        name="contEducation"
        data-test="contEducation"
        label={this.getFieldLabel('contEducation')}
        component={SwitchField}
        inputProps={{
          checked: get(this.profileForm, 'values.contEducation'),
          size: 'large',
          checkedChildren: 'Yes',
          unCheckedChildren: 'No',
        }}
        options={commonFieldOptions}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['contEducation'])}
      />
    );
  }

  buildHireDate() {
    this.fields.set(
      'hireDate',
      <Field
        type="datepicker"
        name="hireDate"
        data-test="hireDate"
        label={this.getFieldLabel('hireDate')}
        placeholder="Please Select"
        component={DateField}
        inputProps={dateOptions}
        options={commonFieldOptions}
        format={value => {
          // return empty to avoid the anoying `Invalid date` message
          if (isEmpty(value)) return '';
          if (this.shouldBeReadOnlyField(['hireDate'])) {
            return readOnlyFormatDateNoTimeZone(value, 'MM/DD/YYYY');
          }
          return formatDate(value, this.hireDate);
        }}
        disabled={this.fetchingAgency}
        plainText={this.shouldBeReadOnlyField(['hireDate'])}
      />
    );
  }

  initFields() {
    //Execute all the build functions
    const protectedFunctions = ['constructor', 'initFields', 'get'];
    Object.getOwnPropertyNames(FieldBuilder.prototype)
      .filter(f => !protectedFunctions.includes(f))
      .map(functionName => this[functionName]());
  }

  get(field) {
    return this.fields.get(field);
  }
}
