import React from 'react';
import { ModalFooterButtons } from './EquipmentButtons';

const ModalFooter = ({
  configs,
  equipmentRecord,
  loading,
  onCancel,
  onSaveEquipment,
  onDeleteEquipment,
}) => {
  const { equipment: { id } = {} } = equipmentRecord;

  return ModalFooterButtons({
    isEditable: configs.isEditable,
    isDeletable: !!id,
    loading,
    onCancel,
    onSave: onSaveEquipment,
    onDelete: onDeleteEquipment,
  });
};

export default ModalFooter;
