import { get, find } from 'lodash';

export const getShare = props => get(props, 'meta.share', {});

export const getReportId = props => get(props, 'meta.id', '');

export const getAgencyId = props => get(props, 'user.agencyId', '');

export const getRank = user => get(user, 'rank.name', '');

export const getFullName = user =>
  `${get(user, 'firstName', '')} ${get(user, 'lastName', '')}`;

export const getReportName = props => {
  const { meta } = props;
  const templateName = get(meta, 'templateName', '');

  return get(meta, 'formNumber', templateName);
};

export const getListOfNames = (officcers, origin) => {
  const listOfOfficersToShare = officcers
    .map(user => find(origin, { key: user }))
    .map(user => `${user.rank} ${user.label}`);
  return listOfOfficersToShare.join(', ');
};

export const getListToShareUnshare = (users, origin) => {
  const usersToShare = users
    .filter(user => !find(origin, { key: user.key }))
    .map(({ key }) => Number(key));
  return usersToShare;
};

export const getListNamesToShareUnshare = (users, origin) => {
  const usersToShare = users
    .filter(user => !find(origin, { key: user.key }))
    .map(user => user.label || 'undefined');
  return usersToShare.join(', ');
};
