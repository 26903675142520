import { get, has } from 'lodash';

import { repeaterActions } from '../../utils/form';

/*
Avoid to dispatch the setDoneWithActions event 
even though this is configured as beforeSubmit event in the template.
By dispatching doneWithAction before submitting, 
It causes to the doneWithAction value  always is stored as true, 
even if the user didn't click on the Done With Action button.

"events": [
          {
            "event": "beforeSubmit",
            "trigger": "setDoneWithActions"
          }
        ]
*/
const protectedEvents = ['setDoneWithActions'];

export default (eventListener, dispatch, getState) => {
  const { form: { selectedForm: { events = {} } = {} } = {} } = getState();

  const eventListeners = get(events, eventListener, {}) || {};

  Object.keys(eventListeners).forEach(eventName => {
    if (
      has(repeaterActions, eventName) &&
      !protectedEvents.includes(eventName)
    ) {
      const action = get(repeaterActions, eventName, () => {});
      const props = get(eventListeners, eventName, {});

      dispatch(action(props));
    }
  });
};
