import omit from 'lodash/omit';
import filter from 'lodash/filter';
import { createReducer } from 'redux-create-reducer';
import {
  POST_REPORT_ERROR,
  POST_REPORT_SUCCESS,
  POST_REPORT_REQUEST,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_REQUEST,
  GET_REPORTS_ERROR,
  GET_OFFICERS_REPORTS_SUCCESS,
  GET_OFFICERS_REPORTS_ERROR,
  UPDATE_REPORT_REQUEST,
  UPDATE_REPORT_SUCCESS,
  UPDATE_REPORT_ERROR,
  POST_DRAFT_REPORT_ERROR,
  POST_DRAFT_REPORT_SUCCESS,
  POST_DRAFT_REPORT_REQUEST,
  UPDATE_DRAFT_REPORT_ERROR,
  UPDATE_DRAFT_REPORT_REQUEST,
  UPDATE_DRAFT_REPORT_SUCCESS,
  DELETE_REPORT_ERROR,
  DELETE_REPORT_REQUEST,
  DELETE_REPORT_SUCCESS,
  GET_FORM_PRESENTATION,
  GET_FORM_PRESENTATION_ONBEHALF_PICKER,
  POST_EMPTY_DRAFT_REPORT_REQUEST,
  POST_EMPTY_DRAFT_REPORT_SUCCESS,
  POST_EMPTY_DRAFT_REPORT_ERROR,
} from '../actions';

const initialState = {
  data: {},
  list: [],
  page: 1,
  total: 0,
  per_page: 10,
  fetched: false,
  loading: false,
  error: null,
};

const events = {
  [GET_REPORTS_REQUEST]: (state, { payload: { page } }) => ({
    ...state,
    loading: true,
    page,
  }),
  [GET_REPORTS_SUCCESS]: (state, { payload: { reports, count } }) => ({
    ...state,
    loading: false,
    fetched: true,
    total: count === undefined ? state.total : count,
    list: [...reports.map(({ id }) => id)],
    data: reports.reduce(
      (reportList, value) => ({ ...reportList, [value.id]: value }),
      {}
    ),
  }),
  [GET_REPORTS_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [GET_OFFICERS_REPORTS_SUCCESS]: (state, { payload }) => {
    const { data = [] } = payload;

    return {
      ...state,
      officers: data,
    };
  },
  [GET_OFFICERS_REPORTS_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [POST_REPORT_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [POST_REPORT_SUCCESS]: (
    { list, data, ...state },
    { payload: { report } }
  ) => ({
    ...state,
    loading: false,
    fetched: true,
    list: [report.id, ...list.filter(id => id !== report.id)],
    data: { ...data, [report.id]: report },
  }),
  [POST_REPORT_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [UPDATE_REPORT_REQUEST]: state => ({ ...state, loading: true }),
  [UPDATE_REPORT_SUCCESS]: (state, { payload: { report } }) => ({
    ...state,
    loading: false,
    data: {
      ...state.data,
      [report.id]: {
        ...state.data[report.id],
        status: report.status,
        data: report.data,
        reviewers: report.reviewers,
        createdAt: report.createdAt,
      },
    },
  }),
  [UPDATE_REPORT_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [POST_DRAFT_REPORT_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [POST_DRAFT_REPORT_SUCCESS]: (
    { list, data, ...state },
    { payload: { report } }
  ) => ({
    ...state,
    loading: false,
    fetched: true,
    list: [report.id, ...list],
    data: { [report.id]: report, ...data },
  }),
  [POST_DRAFT_REPORT_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [UPDATE_DRAFT_REPORT_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [UPDATE_DRAFT_REPORT_SUCCESS]: (
    { list, data, ...state },
    { payload: { report } }
  ) => ({
    ...state,
    loading: false,
    list: [report.id, ...list.filter(id => id !== report.id)],
    data: { ...data, [report.id]: report },
  }),
  [UPDATE_DRAFT_REPORT_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [DELETE_REPORT_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [DELETE_REPORT_SUCCESS]: (state, { payload: { reportId } }) => ({
    ...state,
    list: filter(state.list, itemId => itemId !== reportId),
    data: omit(state.data, [reportId]),
    loading: false,
  }),
  [DELETE_REPORT_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [GET_FORM_PRESENTATION]: state => ({
    ...state,
    loading: false,
  }),
  [GET_FORM_PRESENTATION_ONBEHALF_PICKER]: state => ({
    ...state,
    loading: false,
  }),
  [POST_EMPTY_DRAFT_REPORT_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [POST_EMPTY_DRAFT_REPORT_SUCCESS]: (
    { list, data, ...state },
    { payload: { report } }
  ) => ({
    ...state,
    loading: false,
    fetched: true,
    list: [report.id, ...list.filter(id => id !== report.id)],
    data: { ...data, [report.id]: report },
  }),
  [POST_EMPTY_DRAFT_REPORT_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
};

export default createReducer(initialState, events);
