import React from 'react';

export const SECTION_TYPE_FORM = 'form';
export const SECTION_TYPE_TABLE = 'table';

export const ATTRIBUTE_TYPE_STRING = 'string';
export const ATTRIBUTE_TYPE_TEXTAREA = 'text';
export const ATTRIBUTE_TYPE_DATE = 'date';
export const ATTRIBUTE_TYPE_DATETIME = 'datetime';
export const ATTRIBUTE_TYPE_TIME = 'time';
export const ATTRIBUTE_TYPE_NUMBER = 'number';
export const ATTRIBUTE_TYPE_SWITCH = 'boolean';
export const ATTRIBUTE_TYPE_LINK = 'link';
export const ATTRIBUTE_TYPE_BUTTON = 'button';
export const ATTRIBUTE_TYPE_CUSTOM = 'custom';

export function SaveSectionWarning() {
  return (
    <em>
      {' '}
      Changes to this section <strong>are not saved</strong> until you &#32;
      <strong>Save</strong>&#32; on the main page.{' '}
    </em>
  );
}
