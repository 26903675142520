import React from 'react';
import ModalBody from 'APP_COMPONENTS/common/modal/body';

const requiredFields = message => (
  <ModalBody>
    <div>
      <p>
        Some of the required fields are empty or were not answered correctly.
      </p>
      <p>{message}</p>
      <p>Please double-check and submit again.</p>
    </div>
  </ModalBody>
);

export default requiredFields;
