import {
  GET_AGENCY_REQUEST,
  GET_AGENCY_SUCCESS,
  GET_AGENCY_ERROR,
} from '../AgencyProfile.actionTypes';

import { getAgency } from 'APP_ROOT/api/manageUsers';

export default (agencyId, next) => dispatch => {
  dispatch({ type: GET_AGENCY_REQUEST, payload: { agencyId } });

  return new Promise((resolve, reject) => {
    try {
      getAgency(agencyId)
        .then(async response => {
          const payload = { agencyId, response };

          dispatch({
            type: GET_AGENCY_SUCCESS,
            payload,
          });

          next && next(null, payload);
          resolve(payload);
        })
        .catch(error => {
          dispatch({ type: GET_AGENCY_ERROR, payload: { agencyId, error } });
          next && next(error);
          reject(error);
        });
    } catch (error) {
      dispatch({ type: GET_AGENCY_ERROR, payload: { agencyId, error } });
      next && next(error);
      reject(error);
    }
  });
};
