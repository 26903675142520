import styled from 'styled-components';

import { DIVIDER } from '../../../config/theme';

const ReviewList = styled.div.attrs({
  className: 'reviews-list',
})`
  padding-bottom: ${props => (props.withBorder ? '16px' : '0px')};
  border-bottom: 1px solid
    ${props => (props.withBorder ? DIVIDER : 'transparent')};
`;

export default ReviewList;
