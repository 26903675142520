import { get } from 'lodash';
import { getDefaultFieldValueByType } from 'APP_ROOT/utils/form';

import syncFormData from 'APP_ROOT/actions/sync-form-data';
import formActionDispatcher from 'APP_ROOT/utils/formDispatchEmitter';
import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes';
import { SWITCH, CHECKBOX } from 'APP_ROOT/constants/fieldTypes';

// validates boolean values and make sure it has a value in the store
const validateBooleanValue = props => {
  const {
    parentKey,
    parentIndex,
    dataKey,
    field,
    field_type,
    type,
    data,
    settings: { formName = 'form' } = {},
  } = props;
  const fieldKey = dataKey || field;
  const key = parentKey ? `${parentKey}[${parentIndex}].${fieldKey}` : fieldKey;
  const value = get(data, key);
  if (
    value === undefined &&
    type === FIELD &&
    [SWITCH, CHECKBOX].includes(field_type)
  ) {
    formActionDispatcher(
      formName,
      syncFormData({
        [key]: getDefaultFieldValueByType(field_type, false),
      })
    );
  }
};

export default validateBooleanValue;
