import styled from 'styled-components';

import WorkflowListComponent from './WorkflowList';
import { ACCENT } from 'APP_ROOT/config/theme';

const WorkflowList = styled(WorkflowListComponent)`
  padding: 40px 15px 50px;

  .workflow-item {
    position: relative;
    display: block;
    padding: 20px 45px 20px 20px;
    border-radius: 5px;
    background-color: #fff;
    font-size: 1.2em;
    box-shadow: 0 0 0 0 ${ACCENT}, 0 0 0 rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s;
    text-decoration: none;
    margin-bottom: 15px;

    .workflow-item-name {
      color: #000;

      svg {
        width: 100px;
        max-width: 100%;
        margin-top: 5px;
      }
    }

    &.is-disabled {
      .workflow-item-name {
        opacity: 0.5;
      }
    }

    .anticon {
      color: ${ACCENT};
      position: absolute;
      top: 50%;
      margin-top: -11px;
      right: 20px;
      font-size: 1.6em;
    }

    &[href] {
      &:hover,
      &:focus {
        .workflow-item-name {
          color: ${ACCENT};
        }
      }

      &:hover {
        box-shadow: 0 0 0 0 ${ACCENT}, 0 0 5px rgba(0, 0, 0, 0.1);
      }

      &:focus {
        box-shadow: 0 0 0 2px ${ACCENT}, 0 0 0 rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

export default WorkflowList;
