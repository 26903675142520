import createModal from '../../../../../actions/create-modal';
import showModal from '../../../../../actions/show-modal';

import errorTitle from 'APP_COMPONENTS/common/modal/message/title/error-title';
const defaultError = 'Something wrong occurred, please try again later.';
import setApiResponse from 'APP_COMPONENTS/common/modal/message/set-api-response';

const showErrorMessages = (dispatch, error = {}) => {
  const { message } = error;

  const option = {
    id: 'ou-error-modal',
    title: errorTitle,
    children: setApiResponse(message || defaultError),
  };
  dispatch(createModal(option));
  dispatch(showModal(option.id));
};

export default { showErrorMessages };
