import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';

class dataExportEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'https://localhost:3000';
    const apiPrefix = 'Agencies/0'; // agency ID is not currently used.  Reserved for Superuser in the future
    super(process.env.REACT_APP_API_URL || DEV_URI, apiPrefix);
    this.apiPrefix = apiPrefix;
  }

  async getDownloadLink(exportId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(`/downloadDataExport/${exportId}`),
        method: 'GET',
      },
      {},
      {}
    );

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }

  async getDataExportList() {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/listDataExports'),
        method: 'GET',
      },
      {
        // tenantId, // path params
        // userId,
      },
      {
        // pageNumber: pageNumber, // query params
        // pageSize: pageSize,
        // sort: sort,
        // ...this.parseTrainingFilters(filters || {}),
      }
    );

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }

  async submitRule28ExportRequest() {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/submitRule28ExportRequest'),
        method: 'POST',
      },
      {},
      {},
      {}
    );

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }

  async submitDataExportRequest(exportType, templateId, dateFrom, dateTo) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/submitDataExportRequest'),
        method: 'POST',
      },
      {},
      {},
      {
        dateFrom,
        dateTo,
        exportType,
        templateId,
      }
    );

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }
}

export default new dataExportEndpoints();
