import parseDate, {
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_TIMEA_FORMAT,
} from '../../../../utils/parse-date';
import trainingServiceEndpoints from '../../../../api/trainingServiceEndpoints/trainingServiceEndpoints';

import { processErrorResponse, catchError } from './processErrorResponse';
import { unEscapeInput } from '../../../../utils/request';

//  example response
// {
//   content: [
//     {
//       courseId: 53,
//       courseSessionId: 2467,
//       courseName: 'Conducting Professional Interviews',
//       scheduleDate: '2020-09-22',
//       startTime: '08:00:00',
//       endTime: '09:00:00',
//       courseSessionDescription:
//         'This training program will teach you all the necessary skills for conducting professional interviews. ',
//     },
//   ],
//   errors: [],
//   service: 'training',
//   statusCode: 400,
//   success: true,
//   traceId: 'd64d221c-a3bf-4435-8df4-468732c973ea',
// };

const FORMAT_ID = 106;

const getCoursesData = timezone => ({
  courseSessionId,
  courseName,
  startTime,
  scheduleDate,
}) => ({
  sessionId: courseSessionId,
  name: courseName,
  startTime: parseDate(
    `${scheduleDate} ${startTime}`,
    timezone,
    BENCHMARK_TIMEA_FORMAT,
    false
  ),
});

const getKMICourses = ({ tenantId, date, timezone, next }) => {
  const dateStr = parseDate(date, timezone, BENCHMARK_DATE_FORMAT, false);
  trainingServiceEndpoints
    .getKMICoursesSessions(tenantId, dateStr, FORMAT_ID)
    .then(response => {
      const { success = true, content = [] } = unEscapeInput(response);
      let data;
      if (success) {
        data = content.map(getCoursesData(timezone));
      } else {
        processErrorResponse(response);
      }
      next && next(success, data);
    })
    .catch(error => {
      catchError(error);
      next && next(false);
    });
};

export default getKMICourses;
