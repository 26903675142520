import store from 'APP_ROOT/store';
import { get, first, flatten } from 'lodash';

import { getLinkConfigurations } from '../../../../selectors/session';
import { getTemplateType, getFormTemplates } from '../../../../selectors/form';

const getFormLinkConfiguration = ({ scope }) => {
  const linkConfigurations = getLinkConfigurations(store.getState());
  const formLinkConfiguration = flatten(
    linkConfigurations
      .filter(item => item.name === 'targetFormType')
      .map(item => item.values)
  ).filter(item => item.scope === scope);
  const link = first(formLinkConfiguration);
  return get(link, 'value');
};

export const getSelectedTemplateType = () => getTemplateType(store.getState());

const getTemplates = () => getFormTemplates(store.getState());

export const getTemplatesNames = formTypes => {
  const templates = getTemplates();
  const names = formTypes
    .filter(item => templates[item].canCreate)
    .map(item => templates[item].name);
  return names;
};

export default getFormLinkConfiguration;
