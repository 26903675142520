import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Panel, { PanelTable } from '../../../components/common/panel';
import MainTitle from '../../../components/common/main-title';
import DashboardPage from '../../../components/dashboard';

const data = [
  {
    officer: 'Ross Evers',
    complaintCount: 3,
    complaint: {
      count: 1,
      type: 'citizen complaint',
      category: 'Excessive Use of Force',
    },
    datetime: {
      date: '06/15/2017 CST',
      time: '15:00',
    },
    status: 'Under Review',
  },
  {
    officer: 'Ross Evers',
    complaint: {
      count: 3,
      type: 'citizen complaints',
      category: 'Verbal Abuse',
    },
    datetime: {
      date: '07/03/2017 CST',
      time: '18:00',
    },
    status: 'Under Review',
  },
  {
    officer: 'Ross Evers',
    complaint: {
      count: 1,
      type: 'department complaint',
      category: 'Insubordination',
    },
    datetime: {
      date: '07/27/2017 CST',
      time: '23:30',
    },
    status: 'Under Review',
  },
  {
    officer: 'Martha Elders',
    complaintCount: 2,
    complaint: {
      count: 1,
      type: 'citizen complaint',
      category: 'Verbal Abuse',
    },
    datetime: {
      date: '05/15/2017 CST',
      time: '17:45',
    },
    status: 'Under Review',
  },
  {
    officer: 'Martha Elders',
    complaint: {
      count: 1,
      type: 'department complaint',
      category: 'Inattention to Duty',
    },
    datetime: {
      date: '06/01/2017 CST',
      time: '19:00',
    },
    status: 'Under Review',
  },
  {
    officer: 'Travis Sherman',
    complaintCount: 1,
    complaint: {
      count: 1,
      type: 'citizen complaint',
      category: 'Excessive Force',
    },
    datetime: {
      date: '06/16/2017 CST',
      time: '15:00',
    },
    status: 'Under Review',
  },
];

const tableData = data.map((item, index) => ({ key: index, ...item }));

const renderCell = (text, record, index) => {
  const className = record.className
    ? `table-cell ${record.className}`
    : 'table-cell';
  return <div className={className}>{text}</div>;
};

const columns = [
  {
    key: 'officer',
    title: <div className="header">Officer</div>,
    dataIndex: 'officer',
    render: (text, record, index) => {
      const className = record.className
        ? `table-cell ${record.className}`
        : 'table-cell';
      const children = (
        <div className={className}>
          <Link to="/officers/complaints/details">{text}</Link>
        </div>
      );
      return {
        props: { rowSpan: record.complaintCount || 0 },
        children,
      };
    },
  },
  {
    key: 'complaint',
    title: <div className="header">Complaint</div>,
    dataIndex: 'complaint',
    render: (dataIndexValue, record, index) => {
      const className = record.className
        ? `table-cell ${record.className}`
        : 'table-cell';
      return (
        <div className={className}>
          {`${dataIndexValue.count} ${dataIndexValue.type} for `}
          <a href="#home">{dataIndexValue.category}</a>
        </div>
      );
    },
  },
  {
    key: 'datetime',
    title: <div className="header">Date & Time</div>,
    dataIndex: 'datetime',
    className: 'datetime',
    render: (text, record, index) => {
      const className = record.className
        ? `table-cell ${record.className}`
        : 'table-cell';
      const children = (
        <div className={className}>
          <div>{text.date}</div>
          <div>{text.time}</div>
        </div>
      );
      return children;
    },
  },
  {
    key: 'status',
    title: <div className="header">Status</div>,
    dataIndex: 'status',
    className: 'status',
    render: renderCell,
  },
];

const ComplaintsTable = styled(PanelTable)`
  @media (max-width: 767px) {
    table {
      min-width: 768px;
    }
  }

  .table-cell {
    min-height: 60px;
    line-height: 60px;
  }

  & a {
    text-decoration: underline;
  }

  .datetime,
  .status {
    text-align: center;
  }

  .datetime {
    .table-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      div {
        line-height: 18px;
      }
    }
  }
`;

const Complaints = () => {
  return (
    <div className="dashboard-container">
      <MainTitle>Home &gt; Squad 1: Complaints</MainTitle>
      <Panel isTable>
        <ComplaintsTable columns={columns} dataSource={tableData} />
      </Panel>
    </div>
  );
};

const mapState = state => ({
  session: state.session,
});

export default DashboardPage(mapState, null)(Complaints);
