import React from 'react';
import { Table } from 'antd';
import styled from 'styled-components';

import { DIVIDER, BLACK } from '../../../../config/theme';

const CustomTable = props => (
  <Table rowClassName={() => 'no-hover'} pagination={false} {...props} />
);

const PanelTable = styled(CustomTable)`
  table,
  .ant-table {
    border-radius: 0 !important;
  }

  .ant-table {
    @media (max-width: 767px) {
      & .ant-table-body {
        overflow-x: auto;
      }
    }
  }

  th {
    background-color: transparent !important;
    padding: 0 !important;
    border-top: 0 !important;
    border-right: 1px solid ${DIVIDER};
    border-bottom: 1px solid ${DIVIDER};

    &:last-child {
      border-right: 0 solid ${DIVIDER};
    }

    .header {
      min-height: 60px;
      line-height: 60px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: ${BLACK};
    }
  }

  tr {
    &:last-child {
      border-bottom: 0 !important;

      td {
        border-bottom: 0 !important;
      }
    }
    /* stylelint-disable */
    td {
      border-right: 1px solid ${DIVIDER};
      padding: 0px 16px !important;

      &:last-child {
        border-right: 0px !important;
      }
    }
    /* stylelint-enable */
  }

  .table-cell {
    word-break: break-word;
  }
`;

export default PanelTable;
