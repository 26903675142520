import { invert, kebabCase } from 'lodash';

const FORM_TYPES = {
  'use-of-force': kebabCase('Use of Force'),
  'vehicle-pursuit': kebabCase('Vehicle Pursuit'),
  'daily-observation-report': kebabCase('Daily Observation Report'),
  intake: kebabCase('Internal Affairs Complaint'),
  investigation: kebabCase('Internal Affairs Investigation'),
  VP: kebabCase('Vehicle Pursuit'),
  UOF: kebabCase('Use of Force'),
  RA: kebabCase('Resisting Arrest'),
  IOD: kebabCase('Injury on Duty'),
  TC: kebabCase('Traffic Collisions'),
  IAC: kebabCase('Internal Affairs Complaint'),
  IAI: kebabCase('Internal Affairs Investigation'),
  DOR: kebabCase('Daily Observation Report'),
  OFFICER_BIO: kebabCase('Officer Information'),
  OFFICER_PROFILE: kebabCase('Officer Profile'),
};

export default FORM_TYPES;
export const FORM_IDENTIFIERS = invert(FORM_TYPES);
export const FORM_TEMPLATES = {
  'use-of-force': 'UOF',
  'internal-affairs-complaint': 'IA',
  'internal-affairs-investigation': 'IAI',
  'vehicle-pursuit': 'VP',
  'daily-observation-report': 'DOR',
};
export const FORM_TEMPLATE_IDENTIFIERS = {
  VP: kebabCase('Vehicle Pursuit'),
  UOF: kebabCase('Use of Force'),
  IAC: kebabCase('Internal Affairs Complaint'),
  IAI: kebabCase('Internal Affairs Investigation'),
  DOR: kebabCase('Daily Observation Report'),
};
