import React from 'react';
import { Button, Icon } from 'antd';
import styled from 'styled-components';

const IconButton = ({ label, icon, className, onClick = () => {} }) => (
  <Button className={className} onClick={() => onClick()}>
    <Icon type={icon} />
    <span>{label}</span>
  </Button>
);

const AdminMenuButton = styled(IconButton)`
  width: 100%;
  min-width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 12px 20px 12px 12px;

  .anticon {
    transform: translateY(1px);
  }

  span {
    word-wrap: break-word;
    white-space: normal;
  }
`;

export default AdminMenuButton;
