// WRAPPER FOR DECORATION
export const action = name => `@@ACTION/${name}`;

// STORAGE
export const LOAD_STORAGE = action('LOAD_STORAGE');
export const LOAD_USER_AGENCY_CONFIGURATIONS = action(
  'LOAD_USER_AGENCY_CONFIGURATIONS'
);

// Application
export const TOGGLE_SIDEBAR = action('TOGGLE_SIDEBAR');
export const COLLAPSE_SIDEBAR = action('COLLAPSE_SIDEBAR');
export const BLOCK_NAVIGATION = action('BLOCK_NAVIGATION');
export const UNBLOCK_NAVIGATION = action('UNBLOCK_NAVIGATION');
export const SERVER_ERROR = action('SERVER_ERROR');
export const PAUSE_IDLE_TIMER = action('PAUSE_IDLE_TIMER');
export const RESUME_IDLE_TIMER = action('RESUME_IDLE_TIMER');

// FORM
export const FETCH_FORM = action('FETCH_FORM');
export const FETCH_FORM_SUCCESS = action('FETCH_FORM_SUCCESS');
export const FETCH_FORM_ERROR = action('FETCH_FORM_ERROR');
export const SYNC_FORM_DATA = action('SYNC_FORM_DATA');
export const SYNC_FORM_REVIEW_DATA = action('SYNC_FORM_REVIEW_DATA');
export const SYNC_FORM_VALUE_CHANGED_BOOLEAN = action(
  'SYNC_FORM_VALUE_CHANGED_BOOLEAN'
);
export const SYNC_FORM_HISTORY_ADMIN_EDIT_REPORT = action(
  'SYNC_FORM_HISTORY_ADMIN_EDIT_REPORT'
);
export const ADD_SELECTED_FORM_COPY = action('ADD_SELECTED_FORM_COPY');
export const ADD_EXTERNAL_ID_TO_FORM_DATA = action(
  'ADD_EXTERNAL_ID_TO_FORM_DATA'
);
export const REPLACE_SELECTED_FORM_COPY = action('REPLACE_SELECTED_FORM_COPY');
export const REMOVE_SELECTED_FORM_COPY = action('REMOVE_SELECTED_FORM_COPY');
export const SET_FORM_VALUES = action('SET_FORM_VALUES');
export const ADD_REPEATER_ITEM = action('ADD_REPEATER_ITEM');
export const REMOVE_REPEATER_ITEM = action('REMOVE_REPEATER_ITEM');
export const CHANGE_FORM_TAB = action('CHANGE_FORM_TAB');
export const SYNC_FORM_VALIDATION = action('SYNC_FORM_VALIDATION');
export const SYNC_TAB_NUMBER = action('SYNC_TAB_NUMBER');
export const SUBMIT_FORM = action('SUBMIT_FORM');
export const GET_FORM_PRESENTATION = action('GET_FORM_PRESENTATION');
export const GET_FORM_PRESENTATION_ONBEHALF_PICKER = action(
  'GET_FORM_PRESENTATION_ONBEHALF_PICKER'
);
export const GET_FORM_REVIEW_PRESENTATION = action(
  'GET_FORM_REVIEW_PRESENTATION'
);
export const GET_FORM_TEMPLATES = action('GET_FORM_TEMPLATES');
export const GET_FORM_TEMPLATES_SUCCESS = action('GET_FORM_TEMPLATES_SUCCESS');
export const GET_FORM_TEMPLATES_ERROR = action('GET_FORM_TEMPLATES_ERROR');
export const GET_FORM_TEMPLATE = action('GET_FORM_TEMPLATE');
export const GET_FORM_TEMPLATE_SUCCESS = action('GET_FORM_TEMPLATE_SUCCESS');
export const GET_FORM_TEMPLATE_ERROR = action('GET_FORM_TEMPLATE_ERROR');
export const GET_FORM = action('GET_FORM');
export const GET_FORM_SUCCESS = action('GET_FORM_SUCCESS');
export const GET_FORM_ERROR = action('GET_FORM_ERROR');
export const GET_FORM_ACTIONS_REQUEST = action('GET_FORM_ACTIONS_REQUEST');
export const GET_FORM_ACTIONS_SUCCESS = action('GET_FORM_ACTIONS_SUCCESS');
export const GET_FORM_ACTIONS_ERROR = action('GET_FORM_ACTIONS_ERROR');
export const CREATE_NEW_FORM = action('CREATE_NEW_FORM');
export const SET_SOURCE_SELECTION = action('SET_SOURCE_SELECTION');
export const SET_IS_CONTRIBUTE_REPORT = action('SET_IS_CONTRIBUTE_REPORT');
export const UPDATE_ASSIGNED_SECTIONS = action('UPDATE_ASSIGNED_SECTIONS');
export const REMOVE_SOURCE_SELECTION = action('REMOVE_SOURCE_SELECTION');
export const REMOVE_REFERENCE_SELECTION = action('REMOVE_REFERENCE_SELECTION');
export const REMOVE_OWN_KEYS = action('REMOVE_OWN_KEYS');
export const RESET_OWN_KEYS = action('RESET_OWN_KEYS');
export const UPDATE_GIVEN_DATA_KEYS = action('UPDATE_GIVEN_DATA_KEYS');
export const RESET_REPEATER_KEYS = action('RESET_REPEATER_KEYS');
export const CREATE_REPEATER_SOURCE = action('CREATE_REPEATER_SOURCE');
export const CREATE_DYNAMIC_ENUM_REF = action('CREATE_DYNAMIC_ENUM_REF');
export const SET_DONE_WITH_ACTIONS = action(
  'INTERACTIONS/SET_DONE_WITH_ACTIONS'
);
export const SET_TASK_NAME = action('SET_TASK_NAME');
export const SET_PERFORMANCE_ASSESSMENT_NAME = action(
  'SET_PERFORMANCE_ASSESSMENT_NAME'
);
export const SET_COMPLAINT_NUMBER = action('SET_COMPLAINT_NUMBER');
export const SET_REPORT_NUMBER = action('SET_REPORT_NUMBER');
export const FETCH_FORM_ATTACHMENTS = action('FETCH_FORM_ATTACHMENTS');
export const FETCH_FORM_ATTACHMENTS_SUCCESS = action(
  'FETCH_FORM_ATTACHMENTS_SUCCESS'
);
export const FETCH_FORM_ATTACHMENTS_ERROR = action(
  'FETCH_FORM_ATTACHMENTS_ERROR'
);
export const DELETE_FORM_ATTACHMENT = action('DELETE_FORM_ATTACHMENT');
export const DELETE_FORM_ATTACHMENT_SUCCESS = action(
  'DELETE_FORM_ATTACHMENT_SUCCESS'
);
export const DELETE_FORM_ATTACHMENT_ERROR = action(
  'DELETE_FORM_ATTACHMENT_ERROR'
);
export const ADD_SOURCE = action('ADD_SOURCE');
export const SET_FORM_STATUS = action('SET_FORM_STATUS');
export const GET_FORM_HISTORY = action('GET_FORM_HISTORY');
export const GET_FORM_HISTORY_SUCCESS = action('GET_FORM_HISTORY_SUCCESS');
export const GET_FORM_HISTORY_ERROR = action('GET_FORM_HISTORY_ERROR');
export const ADD_ONBEHALF = action('ADD_ONBEHALF');
export const RESET_ONBEHALF = action('RESET_ONBEHALF');

// FORM EVENTS
export const REQUEST_FORM_SUBMIT = action('REQUEST_FORM_SUBMIT');
export const CREATE_FORM_AFTER = action('CREATE_FORM_AFTER');

// FORM REVIEW
export const CREATE_DYNAMIC_ENUM_REF_REVIEW = action(
  'CREATE_DYNAMIC_ENUM_REF_REVIEW'
);
export const ADD_REPEATER_ITEM_REVIEW = action('ADD_REPEATER_ITEM_REVIEW');
export const REMOVE_REPEATER_ITEM_REVIEW = action(
  'REMOVE_REPEATER_ITEM_REVIEW'
);
export const SYNC_REPEATERS_REVIEW = action('SYNC_REPEATERS_REVIEW');
export const SYNC_FORM_VALIDATION_REVIEW = action(
  'SYNC_FORM_VALIDATION_REVIEW'
);
export const SET_REVIEWER_ID = action('SET_REVIEWER_ID');
export const SET_RESOLUTION = action('SET_RESOLUTION');

// FORM DOR
export const FETCH_DOR_FORM_REQUEST = action('FETCH_DOR_FORM_REQUEST');

// Report
export const POST_DRAFT_REPORT_REQUEST = action('POST_DRAFT_REPORT_REQUEST');
export const POST_DRAFT_REPORT_SUCCESS = action('POST_DRAFT_REPORT_SUCCESS');
export const POST_DRAFT_REPORT_ERROR = action('POST_DRAFT_REPORT_ERROR');
export const POST_REPORT_REQUEST = action('POST_REPORT_REQUEST');
export const POST_REPORT_SUCCESS = action('POST_REPORT_SUCCESS');
export const POST_REPORT_ERROR = action('POST_REPORT_ERROR');
export const UPDATE_REPORT_REQUEST = action('UPDATE_REPORT_REQUEST');
export const UPDATE_REPORT_SUCCESS = action('UPDATE_REPORT_SUCCESS');
export const UPDATE_REPORT_ERROR = action('UPDATE_REPORT_ERROR');
export const DELETE_REPORT_REQUEST = action('DELETE_REPORT_REQUEST');
export const DELETE_REPORT_SUCCESS = action('DELETE_REPORT_SUCCESS');
export const DELETE_REPORT_ERROR = action('DELETE_REPORT_ERROR');
export const GET_REPORTS_REQUEST = action('GET_REPORTS_REQUEST');
export const GET_REPORTS_SUCCESS = action('GET_REPORTS_SUCCESS');
export const GET_REPORTS_ERROR = action('GET_REPORTS_ERROR');
export const GET_ALL_REPORTS_REQUEST = action('GET_ALL_REPORTS');
export const GET_ALL_REPORTS_SUCCESS = action('GET_ALL_REPORTS_SUCCESS');
export const GET_ALL_REPORTS_ERROR = action('GET_ALL_REPORTS_ERROR');
export const EDIT_FORM = action('EDIT_FORM');
export const EDIT_FORM_SUCCESS = action('EDIT_FORM_SUCCESS');
export const EDIT_FORM_ERROR = action('EDIT_FORM_ERROR');
export const UPDATE_DRAFT_REPORT_REQUEST = action(
  'UPDATE_DRAFT_REPORT_REQUEST'
);
export const UPDATE_DRAFT_REPORT_SUCCESS = action(
  'UPDATE_DRAFT_REPORT_SUCCESS'
);
export const UPDATE_DRAFT_REPORT_ERROR = action('UPDATE_DRAFT_REPORT_ERROR');
export const POST_EMPTY_DRAFT_REPORT_REQUEST = action(
  'POST_EMPTY_DRAFT_REPORT_REQUEST'
);
export const POST_EMPTY_DRAFT_REPORT_SUCCESS = action(
  'POST_EMPTY_DRAFT_REPORT_SUCCESS'
);
export const POST_EMPTY_DRAFT_REPORT_ERROR = action(
  'POST_EMPTY_DRAFT_REPORT_ERROR'
);
export const FETCH_CASELOAD_REQUEST = action('FETCH_CASELOAD_REQUEST');
export const FETCH_CASELOAD_SUCCESS = action('FETCH_CASELOAD_SUCCESS');
export const FETCH_CASELOAD_ERROR = action('FETCH_CASELOAD_ERROR');

export const FETCH_USER_CASELOAD_SUCCESS = action(
  'FETCH_USER_CASELOAD_SUCCESS'
);
export const FETCH_USER_CASELOAD_ERROR = action('FETCH_USER_CASELOAD_ERROR');
export const FETCH_USER_CASELOAD_REQUEST = action(
  'FETCH_USER_CASELOAD_REQUEST'
);
export const ADD_USER_REPORT_SUCCESS = action('ADD_USER_REPORT_SUCCESS');

// Session
export const LOGIN_REQUEST = action('LOGIN_REQUEST');
export const LOGIN_SUCCESS = action('LOGIN_SUCCESS');
export const LOGIN_ERROR = action('LOGIN_ERROR');
export const LOGOUT_REQUEST = action('LOGOUT_REQUEST');
export const LOGOUT = action('LOGOUT');
export const LOGOUT_ERROR = action('LOGOUT_ERROR');

// user
export const GET_USER_REQUEST = action('GET_USER_REQUEST');
export const GET_USER_SUCCESS = action('GET_USER_SUCCESS');
export const GET_USER_ERROR = action('GET_USER_ERROR');
export const GET_USER_PROFILE_REQUEST = action('GET_USER_PROFILE_REQUEST');
export const GET_USER_PROFILE_SUCCESS = action('GET_USER_PROFILE_SUCCESS');
export const GET_USER_PROFILE_ERROR = action('GET_USER_PROFILE_ERROR');

export const GET_USERS_REQUEST = action('GET_USERS_REQUEST');
export const GET_USERS_SEARCH_REQUEST = action('GET_USERS_SEARCH_REQUEST');
export const GET_USERS_RESET_FILTERS = action('GET_USERS_RESET_FILTERS');
export const GET_USERS_SUCCESS = action('GET_USERS_SUCCESS');
export const GET_USERS_ERROR = action('GET_USERS_ERROR');

export const GET_TRAINING_USERS_REQUEST = action('GET_TRAINING_USERS_REQUEST');

export const GET_OFFICERS = action('GET_OFFICERS');
export const GET_OFFICERS_SUCCESS = action('GET_OFFICERS_SUCCESS');
export const GET_OFFICERS_ERROR = action('GET_OFFICERS_ERROR');

export const GET_OFFICERS_REPORTS = action('GET_OFFICERS_REPORTS');
export const GET_OFFICERS_REPORTS_SUCCESS = action(
  'GET_OFFICERS_REPORTS_SUCCESS'
);
export const GET_OFFICERS_REPORTS_ERROR = action('GET_OFFICERS_REPORTS_ERROR');

//stats
export const GET_STATS_REQUEST = action('GET_STATS_REQUEST');
export const GET_STATS_SUCCESS = action('GET_STATS_SUCCESS');
export const GET_STATS_ERROR = action('GET_STATS_ERROR');
export const CHANGE_STATS_UOF_DATE = action('CHANGE_STATS_UOF_DATE');

// modals
export const CREATE_MODAL = action('CREATE_MODAL');
export const SHOW_MODAL = action('SHOW_MODAL');
export const DELETE_MODAL = action('DELETE_MODAL');
export const HIDE_MODAL = action('HIDE_MODAL');
export const UPDATE_MODAL = action('UPDATE_MODAL');
export const HIDE_ALL_MODALS = action('HIDE_ALL_MODALS');

// wheel navigation
export const SET_WHEEL_CATEGORY = action('SET_WHEEL_CATEGORY');

// Attachments
export const ADD_ATTACHMENT = action('ADD_ATTACHMENT');
export const UPDATE_ATTACHMENT = action('UPDATE_ATTACHMENT');
export const REMOVE_ATTACHMENT = action('REMOVE_ATTACHMENT');
export const DELETE_ALL_ATTACHMENTS = action('DELETE_ALL_ATTACHMENTS');

// activity stream -> actions
export const FETCH_ACTIONS_REQUEST = action(
  'ACTIVITY_STREAM/FETCH_ACTIONS_REQUEST'
);
export const FETCH_ACTIONS_SUCCESS = action(
  'ACTIVITY_STREAM/FETCH_ACTIONS_SUCCESS'
);
export const FETCH_ACTIONS_ERROR = action(
  'ACTIVITY_STREAM/FETCH_ACTIONS_ERROR'
);
export const REFRESH_ACTIONS_REQUEST = action(
  'ACTIVITY_STREAM/REFRESH_ACTIONS_REQUEST'
);
export const REFRESH_ACTIONS_SUCCESS = action(
  'ACTIVITY_STREAM/REFRESH_ACTIONS_SUCCESS'
);
export const REFRESH_ACTIONS_ERROR = action(
  'ACTIVITY_STREAM/REFRESH_ACTIONS_ERROR'
);
export const FETCH_ACTIONS_COUNT_REQUEST = action(
  'ACTIVITY_STREAM/FETCH_ACTIONS_COUNT_REQUEST'
);
export const FETCH_ACTIONS_COUNT_SUCCESS = action(
  'ACTIVITY_STREAM/FETCH_ACTIONS_COUNT_SUCCESS'
);
export const FETCH_ACTIONS_COUNT_ERROR = action(
  'ACTIVITY_STREAM/FETCH_ACTIONS_COUNT_ERROR'
);
export const FETCH_ACTION_ITEMS_FEED_REQUEST = action(
  'ACTIVITY_STREAM/FETCH_ACTION_ITEMS_FEED_REQUEST'
);
export const FETCH_ACTION_ITEMS_FEED_SUCCESS = action(
  'ACTIVITY_STREAM/FETCH_ACTION_ITEMS_FEED_SUCCESS'
);
export const FETCH_ACTION_ITEMS_FEED_ERROR = action(
  'ACTIVITY_STREAM/FETCH_ACTION_ITEMS_FEED_ERROR'
);
export const FETCH_ACTION_ITEMS_FEED_COUNT_REQUEST = action(
  'ACTIVITY_STREAM/FETCH_ACTION_ITEMS_FEED_COUNT_REQUEST'
);
export const FETCH_ACTION_ITEMS_FEED_COUNT_SUCCESS = action(
  'ACTIVITY_STREAM/FETCH_ACTION_ITEMS_FEED_COUNT_SUCCESS'
);
export const FETCH_ACTION_ITEMS_FEED_COUNT_ERROR = action(
  'ACTIVITY_STREAM/FETCH_ACTION_ITEMS_FEED_COUNT_ERROR'
);

// activity stream -> activities
export const FETCH_ACTIVITIES_REQUEST = action(
  'ACTIVITY_STREAM/FETCH_ACTIVITIES_REQUEST'
);
export const FETCH_ACTIVITIES_SUCCESS = action(
  'ACTIVITY_STREAM/FETCH_ACTIVITIES_SUCCESS'
);
export const FETCH_ACTIVITIES_ERROR = action(
  'ACTIVITY_STREAM/FETCH_ACTIVITIES_ERROR'
);
export const REFRESH_ACTIVITIES_REQUEST = action(
  'ACTIVITY_STREAM/REFRESH_ACTIVITIES_REQUEST'
);
export const REFRESH_ACTIVITIES_SUCCESS = action(
  'ACTIVITY_STREAM/REFRESH_ACTIVITIES_SUCCESS'
);
export const REFRESH_ACTIVITIES_ERROR = action(
  'ACTIVITY_STREAM/REFRESH_ACTIVITIES_ERROR'
);

// activity stream
export const MARK_NOTIFICATION_SEEN = action(
  'ACTIVITY_STREAM/MARK_NOTIFICATION_SEEN'
);
export const DISMISS_NOTIFICATION = action(
  'ACTIVITY_STREAM/DISMISS_NOTIFICATION'
);
export const FETCH_ACTIVITY_FEED_REQUEST = action(
  'ACTIVITY_STREAM/FETCH_ACTIVITY_FEED_REQUEST'
);
export const FETCH_ACTIVITY_FEED_SUCCESS = action(
  'ACTIVITY_STREAM/FETCH_ACTIVITY_FEED_SUCCESS'
);
export const FETCH_ACTIVITY_FEED_ERROR = action(
  'ACTIVITY_STREAM/FETCH_ACTIVITY_FEED_ERROR'
);
export const FETCH_ACTIVITY_FEED_COUNT_REQUEST = action(
  'ACTIVITY_STREAM/FETCH_ACTIVITY_FEED_COUNT_REQUEST'
);
export const FETCH_ACTIVITY_FEED_COUNT_SUCCESS = action(
  'ACTIVITY_STREAM/FETCH_ACTIVITY_FEED_COUNT_SUCCESS'
);
export const FETCH_ACTIVITY_FEED_COUNT_ERROR = action(
  'ACTIVITY_STREAM/FETCH_ACTIVITY_FEED_COUNT_ERROR'
);

export const FETCH_FORM_PARENT_REQUEST = action('FETCH_FORM_PARENT_REQUEST');
export const FETCH_FORM_PARENT_SUCCESS = action('FETCH_FORM_PARENT_SUCCESS');
export const FETCH_FORM_PARENT_ERROR = action('FETCH_FORM_PARENT_ERROR');

export const SYNC_REPEATERS = action('SYNC_REPEATERS');
export const SEED_REPEATERS = action('SEED_REPEATERS');

export const FETCH_FORGOT_PASSWORD_REQUEST = action(
  'FETCH_FORGOT_PASSWORD_REQUEST'
);
export const FETCH_FORGOT_PASSWORD_SUCCESS = action(
  'FETCH_FORGOT_PASSWORD_SUCCESS'
);
export const FETCH_FORGOT_PASSWORD_ERROR = action(
  'FETCH_FORGOT_PASSWORD_ERROR'
);

// FORM LINKS
export const FETCH_FORM_LINKS_REQUEST = action('FETCH_FORM_LINKS_REQUEST');
export const FETCH_FORM_LINKS_SUCCESS = action('FETCH_FORM_LINKS_SUCCESS');
export const FETCH_FORM_LINKS_ERROR = action('FETCH_FORM_LINKS_ERROR');
export const POST_FORM_LINK_REQUEST = action('POST_FORM_LINK_REQUEST');
export const POST_FORM_LINK_SUCCESS = action('POST_FORM_LINK_SUCCESS');
export const POST_FORM_LINK_ERROR = action('POST_FORM_LINK_ERROR');
export const DELETE_FORM_LINK_REQUEST = action('DELETE_FORM_LINK_REQUEST');
export const DELETE_FORM_LINK_SUCCESS = action('DELETE_FORM_LINK_SUCCESS');
export const DELETE_FORM_LINK_ERROR = action('DELETE_FORM_LINK_ERROR');

// SYSTEM
export const LOOKUP_SYSTEM_UPDATES_REQUEST = action(
  'LOOKUP_SYSTEM_UPDATES_REQUEST'
);
export const LOOKUP_SYSTEM_UPDATES_SUCCESS = action(
  'LOOKUP_SYSTEM_UPDATES_SUCCESS'
);
export const LOOKUP_SYSTEM_UPDATES_ERROR = action(
  'LOOKUP_SYSTEM_UPDATES_ERROR'
);
export const START_SYSTEM_UPDATE_REQUEST = action(
  'START_SYSTEM_UPDATE_REQUEST'
);
export const START_SYSTEM_UPDATE_SUCCESS = action(
  'START_SYSTEM_UPDATE_SUCCESS'
);
export const START_SYSTEM_UPDATE_ERROR = action('START_SYSTEM_UPDATE_ERROR');
export const GET_SYSTEM_UPDATE_STATUS = action('GET_SYSTEM_UPDATE_STATUS');
export const RESET_SYSTEM_UPDATE_STATE = action('RESET_SYSTEM_UPDATE_STATE');

export const FETCH_REASONS_REQUEST = action('FETCH_REASONS_REQUEST');
export const FETCH_REASONS_SUCCESS = action('FETCH_REASONS_SUCCESS');
export const FETCH_REASONS_FAILURE = action('FETCH_REASONS_FAILURE');

export const POST_FORM_TOGGLE_TIMEOUT_REQUEST = action(
  'POST_FORM_TOGGLE_TIMEOUT_REQUEST'
);
export const POST_FORM_TOGGLE_TIMEOUT_SUCCESS = action(
  'POST_FORM_TOGGLE_TIMEOUT_SUCCESS'
);
export const POST_FORM_TOGGLE_TIMEOUT_ERROR = action(
  'POST_FORM_TOGGLE_TIMEOUT_ERROR'
);

export const GET_RANKS_REQUEST = action('GET_RANKS_REQUEST');
export const GET_RANKS_SUCCESS = action('GET_RANKS_SUCCESS');
export const GET_RANKS_ERROR = action('GET_RANKS_ERROR');

export const GET_IZENDA_REPORTS_REQUEST = action('GET_IZENDA_REPORTS_REQUEST');
export const GET_IZENDA_REPORTS_SUCCESS = action('GET_IZENDA_REPORTS_SUCCESS');
export const GET_IZENDA_REPORTS_ERROR = action('GET_IZENDA_REPORTS_ERROR');

export const SET_FILE_UPLOADED = action('SET_FILE_UPLOADED');
export const UPDATE_TAB_WITH_ERRORS = action('UPDATE_TAB_WITH_ERRORS');

// Encrypt
export const SET_ENCRYPTED_KEY = action('SET_ENCRYPTED_KEY');

// Website Logs
export const POST_WEBSITELOG_REQUEST = action('POST_WEBSITELOG_REQUEST');
export const POST_WEBSITELOG_SUCCESS = action('POST_WEBSITELOG_SUCCESS');
export const POST_WEBSITELOG_ERROR = action('POST_WEBSITELOG_ERROR');

// Report list
export const SET_REPORT_LIST_FILTERS = action('SET_REPORT_LIST_FILTERS');

// Agency Supported
export const SET_SUPPORTED_AGENCY = action('SET_SUPPORTED_AGENCY');
