import React from 'react';
import { Button } from 'antd';

import getRoute from 'APP_ROOT/utils/get-route';

const CreateCaseFileButton = history => {
  const openCaseFilePage = () => {
    history.push(getRoute('createNewCaseFile'));
  };

  return (
    <Button
      type="primary"
      tooltipMessage="Create New Case File"
      onClick={openCaseFilePage}
    >
      + Create New Case File
    </Button>
  );
};

export default CreateCaseFileButton;
