import React from 'react';

import DashboardPage from 'APP_COMPONENTS/dashboard';
import withoutClutter from 'APP_COMPONENTS/without-clutter';

import AdministratorWrapper from '../Administrator.styled';
import withOwnershipGuard from '../components/agency-ownership-guard';
import ListOfReports from './Reports/ListOfReports';

const AgencyReports = props => {
  const { match, session, history, route } = props;
  const { params } = match;
  const { agencyId } = params;
  return (
    <AdministratorWrapper>
      <ListOfReports
        agencyId={agencyId}
        session={session}
        history={history}
        route={route}
      />
    </AdministratorWrapper>
  );
};

export default withoutClutter(
  DashboardPage()(
    withOwnershipGuard(AgencyReports, 'administratorAgencyReports')
  )
);
