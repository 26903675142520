import storage from '../utils/storage';

import { TOGGLE_SIDEBAR } from './';

export default () => {
  return (dispatch, getState) => {
    const {
      app: { sidebarCollapsed },
    } = getState();

    storage.set('sidebar_collapsed', !sidebarCollapsed);

    dispatch({ type: TOGGLE_SIDEBAR });
  };
};
