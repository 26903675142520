import styled from 'styled-components';

const Label = styled.label`
  font-size: 12px;

  @media print {
    font-size: 1em;
    font-family: inherit;
  }
`;

export default Label;
