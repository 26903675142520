import React, { Component } from 'react';
import { Modal, Button, message, Menu } from 'antd';
import WrapperContextSwitch from './ContextSwitch.styled';
import loginSSO from '../../../actions/loginSSO';
import postWebsiteLogs from '../../../actions/post-website-logs';
import storage from '../../../utils/storage';
import route from '../../../utils/get-route';
import websiteLogsMessage from '../../../utils/websiteLogsMessage';
import securityEndpoints from '../../../api/security/securityEndpoints';
import { DISPATCHER_LOADING_EVENT } from '../../loading-modal/LoadingModal';
import MenuItem from '../../common/menu/menu-item';
import MenuButton from '../../common/menu/MenuButton';
import SubMenu from '../../common/menu/sub-menu';
import styled from 'styled-components';
const { Dispatcher } = require('@aux4/dispatcher.js');

const CurrentOrganizationPanel = styled.div`
  text-align: center;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
`;

class ContextSwitch extends Component {
  state = {
    showMessage: false,
    currentOU: '',
  };

  handleOUChange = (value, option) => {
    const { organizationalUnits } = this.props;
    const organizationalUnit = organizationalUnits.find(ou => ou.id == value);
    this.showModal(organizationalUnit);
  };

  showModal = organizationalUnit => {
    this.setState({
      showMessage: true,
      showList: false,
      currentOU: organizationalUnit,
    });
  };

  handleOk = e => {
    const { currentOU } = this.state;
    this.setState({
      showMessage: false,
      showList: false,
    });
    this.switchContext(currentOU);
  };

  handleCancel = e => {
    this.setState({
      showMessage: false,
      showList: false,
    });
  };

  switchContext = currentOU => {
    const { history, dispatch, logoutTrigger } = this.props;
    const { push } = history;

    Dispatcher.dispatch(DISPATCHER_LOADING_EVENT, {
      name: 'SWITCH_CONTEXT',
      enable: true,
      title: 'Switching Organizations',
      message: 'Please wait while we retrieve data...',
    });

    const loadingComplete = () => {
      Dispatcher.dispatch(DISPATCHER_LOADING_EVENT, {
        name: 'SWITCH_CONTEXT',
        enable: false,
      });
    };

    securityEndpoints
      .refreshUserToken(currentOU.id)
      .then(({ token }) => {
        dispatch(
          loginSSO(token, (error, response) => {
            loadingComplete();
            if (error) {
              message.error('Something went wrong.', 5);
              //This dispatch is sending information to an enpoint on the node api to get information about
              //forced logout - for more information about the issue review SW-1059
              dispatch(
                postWebsiteLogs(
                  websiteLogsMessage(
                    'Logout executed from switchContext',
                    'ContextSwitch'
                  )
                )
              );
              setTimeout(() => logoutTrigger(), 500);
            }
            storage.set('token', token);
            message.success(
              `You have successfully switched to ${currentOU.displayName}`,
              5
            );
            push(route('dashboard'));
          })
        );
      })
      .catch(error => {
        loadingComplete();
        message.error('Something went wrong.', 5);
        //This dispatch is sending information to an enpoint on the node api to get information about
        //forced logout - for more information about the issue review SW-1059
        dispatch(
          postWebsiteLogs(
            websiteLogsMessage(
              'Logout executed from catch(error)',
              'ContextSwitch'
            )
          )
        );
        setTimeout(() => logoutTrigger(), 500);
      });
  };

  render() {
    const { organizationalUnits = [], organizationalUnitId } = this.props;
    const organizationalUnit = organizationalUnits.find(
      ou => ou.id == organizationalUnitId
    ) || { displayName: '' };
    const ousAvailable = organizationalUnits.filter(
      ou => ou.id != organizationalUnitId
    );
    const { currentOU, showMessage } = this.state;
    return (
      <WrapperContextSwitch>
        {ousAvailable.length === 0 && (
          <CurrentOrganizationPanel>
            {organizationalUnit.displayName}
          </CurrentOrganizationPanel>
        )}
        {ousAvailable.length !== 0 && (
          <Menu mode="vertical">
            <SubMenu title={organizationalUnit.displayName} hasItems={true}>
              {ousAvailable.map(unit => (
                <MenuItem key={unit.id}>
                  <MenuButton
                    onClick={() => this.handleOUChange(unit.id)}
                    text={unit.displayName}
                  />
                </MenuItem>
              ))}
            </SubMenu>
          </Menu>
        )}
        <Modal
          title="Switch Organization"
          visible={showMessage}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button key="Save" type="primary" onClick={this.handleOk}>
              Apply changes
            </Button>,
          ]}
        >
          <h3>Do you want to switch to {currentOU.displayName}?</h3>
          <br />
          <p>
            Switching organizations will refresh your Benchmark session with new
            roles and permissions. Make sure you save your work before
            confirming.
          </p>
        </Modal>
      </WrapperContextSwitch>
    );
  }
}

export default ContextSwitch;
