import React from 'react';
export const communityColumns = [
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    render: (text, row) => (
      <a href={`/community/view/${row.integrationId}`}>{text}</a>
    ),
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.firstName.localeCompare(b.firstName),
  },
  {
    title: 'Middle Name',
    dataIndex: 'middleName',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.middleName.localeCompare(b.middleName),
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.address.localeCompare(b.address),
  },
  {
    title: 'DOB',
    dataIndex: 'birthday',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.birthday.localeCompare(b.birthday),
  },
  {
    title: 'Race',
    dataIndex: 'race',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.ethnicity.localeCompare(b.ethnicity),
  },
];
