import { get } from 'lodash';

const sectionModalCancel = (
  isNew,
  row,
  {
    updateSectionField,
    removeSectionFieldValue,
    attributes = [],
    profileForm,
    prefix,
    sectionId,
    attributeId,
    onCancel,
  }
) => {
  const sectionData = get(profileForm, `values.${prefix}[${sectionId}]`, {});
  const keys = get(sectionData, attributeId, []);
  const index = keys.findIndex(k => k === row.key);
  if (isNew) {
    if (removeSectionFieldValue) {
      attributes.forEach(({ attributeName }) => {
        removeSectionFieldValue(
          `${prefix}[${sectionId}][${attributeName}]`,
          index
        );
      });
      removeSectionFieldValue(`${prefix}[${sectionId}][${attributeId}]`, index);
    }
  } else {
    if (updateSectionField && index !== -1) {
      attributes.forEach(({ attributeName }) => {
        if (sectionData[attributeName][index] !== row[attributeName]) {
          updateSectionField(
            `${prefix}[${sectionId}][${attributeName}][${index}]`,
            row[attributeName]
          );
        }
      });
    }
  }
  onCancel && onCancel(row, isNew);
};

export default sectionModalCancel;
