import React from 'react';
import { get } from 'lodash';

import compareEnumOptions from './compareEnumOptions';

export default props => {
  const { dataEnums, options } = props;
  const tooltipFrom = get(options, 'tooltipFromEnum');
  const tooltipSize = get(options, 'tooltipSize');

  const hasTooltip = !!tooltipFrom;
  let tooltipTitle = get(dataEnums, tooltipFrom, []);

  const getOption = (text = {}, idx) => (
    <div key={`${idx}-${text.value || text}`} className="info-tooltip-line">
      {text.label ? text.label : text}
      {text.enums ? text.enums.sort(compareEnumOptions).map(getOption) : null}
    </div>
  );

  if (hasTooltip && Array.isArray(tooltipTitle)) {
    tooltipTitle = [...tooltipTitle].sort(compareEnumOptions).map(getOption);
  }

  return {
    hasTooltip,
    tooltipTitle,
    tooltipSize,
  };
};
