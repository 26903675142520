import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  overflow: auto;

  svg {
    width: 500px;
  }
`;

export default LoaderWrapper;
