import React from 'react';
import { connect } from 'react-redux';
import { Button, Dropdown, Menu, Icon, Table, Upload } from 'antd';
import { Link } from 'react-router-dom';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import getRoute from 'APP_ROOT/utils/get-route';
import { get, isEmpty } from 'lodash';

import AdministratorWrapper from '../Administrator.styled';
import MenuButtonWrapper from './FormBuilderList.styled';
import FormTemplate from '../../../modules/FormBuilder/components/FormTemplate/FormTemplate';
import { securitySettingsStorageKey } from './form-builder-constants/security-settings-drawer-constants';

//modal
import createModal from '../../../actions/create-modal';
import showModal from '../../../actions/show-modal';
import hideModal from '../../../actions/hide-modal';
import updateModal from '../../../actions/update-modal';
import componentsManager from '../../../modules/FormBuilder/services/componentsManager';
import calculatedFieldsConfig from '../../../modules/FormBuilder/services/calculatedFieldsConfig';
import apiEndpoints from '../../../modules/FormBuilder/services/apiEndpoints';
import onIdleStateChange from '../../../actions/on-idle-state-change';

import StyledRow from './FormBuilderHeader.styled';

import parseDate, { BENCHMARK_DATE_FORMAT } from '../../../utils/parse-date';

import {
  TEMPLATE_NAME,
  TEMPLATE_NAME_LINK,
  TEMPLATE_CREATED,
  TEMPLATE_UPDATED,
  TEMPLATE_CHANGELOG,
  NO_TITLE,
  PAGE_SIZE,
  TABLE_ASC,
  TABLE_DESC,
  ASC_PARAM,
  DESC_PARAM,
} from './constants';

import sortString from './utils/sortString';
import sortDates from './utils/sortDates';

class FormBuilderHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reading: false,
      loading: false,
      templateList: [],
      pagination: { pageSize: PAGE_SIZE, current: 1 },
    };

    this.COLUMNS = [
      {
        title: 'Name',
        dataIndex: TEMPLATE_NAME_LINK,
        key: 'nameLink',
        sorter: sortString,
      },
      {
        title: 'Created',
        dataIndex: TEMPLATE_CREATED,
        key: 'created',
        sorter: sortDates,
        render: this.renderDate,
      },
      {
        title: 'Last Updated',
        dataIndex: TEMPLATE_UPDATED,
        key: 'updated',
        sorter: sortDates,
        defaultSortOrder: 'descend',
        render: this.renderDate,
      },
      {
        title: 'Changelog',
        dataIndex: TEMPLATE_CHANGELOG,
        key: 'changelog',
      },
    ];
  }

  componentDidMount() {
    this.fetchData(1, TEMPLATE_UPDATED, TABLE_DESC);
    localStorage.removeItem(securitySettingsStorageKey);
  }

  renderDate = date => {
    const { timezone } = this.props;
    return parseDate(date, timezone, BENCHMARK_DATE_FORMAT, false);
  };

  selectTemplateClick = (agencyId, templateId, defaultTemplate) => {
    componentsManager.flags.isLoading = true;
    componentsManager.header = {
      name: 'Form Builder',
      fromTemplateId: undefined,
    };

    return getRoute(
      'administratorAgencyFormBuilder',
      { agencyId, id: templateId },
      { isDefault: defaultTemplate }
    );
  };

  createReviewNoteTemplateFor = (agencyId, templateId) => {
    componentsManager.flags.isLoading = true;
    componentsManager.header = {
      name: 'Form Builder',
      fromTemplateId: undefined,
    };

    return getRoute(
      'administratorAgencyFormBuilder',
      { agencyId, id: templateId },
      { isDefault: false, isReviewNote: true }
    );
  };

  getTemplateChangelogClick = (agencyId, templateId, templateName) => {
    return getRoute('administratorAgencyChangelog', {
      agencyId,
      templateId,
      templateName,
    });
  };

  loadCalculatedFieldsConfig = () => {
    const { reading } = this.state;
    const { agencyId } = this.props;
    if (reading) return;

    calculatedFieldsConfig.loadCalculatedFieldsConfig(agencyId);
  };

  get pageActions() {
    const { agencyId } = this.props;
    const { reading, templateList } = this.state;

    const NEW_TEMPLATE_ID = 'form-template';

    const handleMenuClick = key => {
      key === 'template' && fromTemplateAction();
    };

    const fromTemplateAction = () => {
      openFormTemplate(getTemplateOptions(NEW_TEMPLATE_ID));
    };

    const getTemplateOptions = templateId => {
      const NEW_TEMPLATE_OPTIONS = {
        id: NEW_TEMPLATE_ID,
        identifierName: 'templateId',
        title: 'Create From Template',
        callback: onTemplatesLoaded,
        templates: templateList,
      };

      const templateOptions = {
        [NEW_TEMPLATE_ID]: NEW_TEMPLATE_OPTIONS,
      };

      return templateOptions[templateId] || {};
    };

    const onTemplatesLoaded = (error, templates) => {
      const {
        dispatch,
        history: { push },
      } = this.props;
      if (error) {
        throw new Error('Form template request error.');
      }
      const modalOptions = {
        id: NEW_TEMPLATE_ID,
        title: 'Create From Template',
        children: (
          <FormTemplate
            templates={templates}
            push={push}
            closeModal={() => dispatch(hideModal(NEW_TEMPLATE_ID))}
            agencyId={agencyId}
          />
        ),
      };

      dispatch(updateModal(modalOptions));
    };

    const openFormTemplate = ({
      id,
      title,
      callback,
      requiresTemplates = true,
      templates,
    }) => {
      const { dispatch, templatesLoading } = this.props;

      const modalOptions = {
        id,
        title: (
          <span>
            {title} <Icon type="loading" />
          </span>
        ),
        children: (
          <FormTemplate
            agencyId={agencyId}
            templates={[]}
            closeModal={() => dispatch(hideModal(id))}
            isLoading
          />
        ),
      };

      dispatch(createModal(modalOptions));
      dispatch(showModal(modalOptions.id));

      if (requiresTemplates) {
        if (!templatesLoading) {
          callback(null, templates);
        }
      }
    };

    const LinkToFormBuilder = ({ child, isReviewNote = false }) => {
      return (
        <Link
          to={getRoute(
            'administratorAgencyFormBuilder',
            { agencyId, id: 'create' },
            { isReviewNote }
          )}
        >
          {child}
        </Link>
      );
    };

    const menu = (
      <Menu onClick={e => handleMenuClick(e.key)}>
        <Menu.Item key="scratch">
          <LinkToFormBuilder child="From scratch" />
        </Menu.Item>
        <Menu.Item key="template">From template</Menu.Item>
        <Menu.Item key="reviewNote">
          <LinkToFormBuilder child="Custom Review note" isReviewNote />
        </Menu.Item>
      </Menu>
    );

    const importOptions = {
      accept: 'application/json',
      beforeUpload: file => {
        this.onImportButtonClick(file);

        return false;
      },
      fileList: [],
    };

    return [
      <Upload {...importOptions} key="import">
        <Button
          icon="upload"
          size="default"
          type="upload"
          loading={reading}
          onClick={this.loadCalculatedFieldsConfig}
        >
          Import
        </Button>
      </Upload>,
      <MenuButtonWrapper key="open-builder">
        <LinkToFormBuilder
          child={
            <Button
              className="first-button"
              type="primary"
              size="default"
              icon="plus"
            >
              Create New
            </Button>
          }
        />
        <Dropdown overlay={menu}>
          <Button
            className="second-button"
            type="primary"
            size="default"
            icon="ellipsis"
          />
        </Dropdown>
      </MenuButtonWrapper>,
    ];
  }

  onImportButtonClick = file => {
    const { reading } = this.state;
    const { history, agencyId } = this.props;
    if (reading) return;

    componentsManager.importJSON(
      file,
      this.updateState,
      (schema, isReviewNote) => {
        history.push(
          getRoute(
            'administratorAgencyFormBuilder',
            { agencyId, id: 'create' },
            { isReviewNote }
          ),
          { import: true, schema }
        );
      }
    );
  };

  updateState = (state, callback) => {
    const { reading } = state;
    const { dispatch } = this.props;
    this.setState(state, callback);
    dispatch(onIdleStateChange(reading));
  };

  getTemplateLink = (template, agencyId) => (
    <Link
      to={this.selectTemplateClick(agencyId, template.id, template.default)}
    >
      {template.name}
    </Link>
  );

  getChangeLogLink = (template, agencyId) =>
    template.hasChangelog && (
      <Link
        to={this.getTemplateChangelogClick(
          agencyId,
          template.id,
          template.name
        )}
      >
        Changelog
      </Link>
    );

  getTableData = () => {
    const { agencyId } = this.props;
    const { templateList } = this.state;
    return templateList
      .filter(t => !t.default)
      .map(t => ({
        key: t.id,
        agencyId,
        [TEMPLATE_NAME]: t[TEMPLATE_NAME],
        [TEMPLATE_NAME_LINK]: this.getTemplateLink(t, agencyId),
        [TEMPLATE_CREATED]: t[TEMPLATE_CREATED],
        [TEMPLATE_UPDATED]: t[TEMPLATE_UPDATED],
        [TEMPLATE_CHANGELOG]: this.getChangeLogLink(t, agencyId),
      }));
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.fetchData(pagination.current, sorter.field, sorter.order);
  };

  fetchData = (currentPage = 1, sortBy, sortDirection) => {
    const { agencyId } = this.props;
    this.setState({ loading: true });

    apiEndpoints
      .getTemplateList({
        agencyId,
        pageNumber: currentPage,
        pageSize: PAGE_SIZE,
        sortBy: sortBy === TEMPLATE_NAME_LINK ? TEMPLATE_NAME : sortBy,
        sortDirection: sortDirection === TABLE_ASC ? ASC_PARAM : DESC_PARAM,
      })
      .then(({ data, page }) => {
        const pagination = { ...this.state.pagination };
        pagination.total = page.totalRecords;
        const templateList = data.map(d => ({
          ...d,
          name: isEmpty(d.name) ? NO_TITLE : d.name,
        }));
        this.setState({ templateList, loading: false, pagination });
      });
  };

  render() {
    const { agencyId } = this.props;
    const { loading, pagination } = this.state;
    const data = this.getTableData();

    return (
      <AdministratorWrapper>
        <PageHeader
          title="Form Builder"
          goBackTo={getRoute('administratorAgency', {
            agencyId,
          })}
          actions={this.pageActions}
        />
        <div className="administrator-content">
          <div className="responsive-table">
            <StyledRow>
              <Table
                columns={this.COLUMNS}
                dataSource={data}
                loading={loading}
                pagination={pagination}
                onChange={this.handleTableChange}
              />
            </StyledRow>
          </div>
        </div>
      </AdministratorWrapper>
    );
  }
}

const mapState = (state, props) => {
  const timezone = get(props, 'agency.timezone');
  return {
    timezone,
  };
};

export default connect(mapState)(FormBuilderHeader);
