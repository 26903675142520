import React from 'react';
import { notification } from 'antd';
import { isEmpty, isArray } from 'lodash';

import StepsComponent from './StepsComponent';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';
import securityV2Endpoints from '../../../../api/security/securityV2Endpoints';

const SOMETHING_BAD_HAPPEN = 'Something bad happened';
const YOU_MUST_INCLUDE_A_VALID = 'You must include a valid';

export const stepsArray = [
  {
    status: 'finish',
    title: 'Step 1',
    description: 'Paste the JSON from ‘copy to clipboard’ of the model role.',
  },
  {
    status: 'finish',
    title: 'Step 2',
    description:
      'Then copy and paste the agencyId and tenantId into the corresponding fields.',
  },
  {
    status: 'finish',
    title: 'Step 3',
    description: 'Make any other adjustments before clicking [Create].',
  },
];

export const createCustomNotificacionMessageObject = (
  message = '',
  description = ''
) => {
  if (message) {
    return {
      message: `${message} successfully copied!`,
      description: `Json for ${description} was copied to the clipboard successfully!`,
    };
  }
  return {
    message: SOMETHING_BAD_HAPPEN,
    description: 'Something bad occurred when copying to the clipboard',
  };
};

export const notificationSuccess = (message, description, duration = 4.5) => {
  notification.success({
    message,
    description,
    duration,
  });
};

export const notificationError = (message, description, duration = 4.5) => {
  notification.error({
    message,
    description,
    duration,
  });
};

export const getTenant = async agencyId => {
  try {
    return await organizationEndpoints.getAgencyById(agencyId);
  } catch (e) {
    notificationError(SOMETHING_BAD_HAPPEN, e.message);
  }
};

export const copyRoleToClipboard = (
  onSuccessMessageObject,
  onFailMessageObject,
  DataToClipboard
) => {
  try {
    const { message, description } = onSuccessMessageObject;
    navigator.clipboard.writeText(DataToClipboard);
    notificationSuccess(message, description);
  } catch (error) {
    const { message, description } = onFailMessageObject;
    notificationError(message, description);
  }
};

export const pasteFromClipboard = () => {
  return navigator.clipboard.readText();
};

const returnErrorsForArray = (data, agencyId) => {
  const setError = new Set();
  data.map(role => {
    if (role.agencyId !== +agencyId)
      setError.add("agencyId can't be diffetent to current agency Id");
    if (role.rankNumber < 0)
      setError.add(`${YOU_MUST_INCLUDE_A_VALID} rankNumber`);
    if (role.tenantId === '')
      setError.add(`${YOU_MUST_INCLUDE_A_VALID} tenantId`);
  });
  return Array.from(setError);
};

const returnErrors = (data, agencyId) => {
  let errors = [];
  if (data.agencyId !== +agencyId)
    errors.push("agencyId can't be diffetent to current agency Id");
  if (data.rankNumber === 0)
    errors.push(`${YOU_MUST_INCLUDE_A_VALID} rankNumber`);
  if (data.tenantId === '') errors.push(`${YOU_MUST_INCLUDE_A_VALID} tenantId`);
  return errors;
};

const validateData = (data, agencyId) => {
  if (isArray(data)) return returnErrorsForArray(data, agencyId);
  return returnErrors(data, agencyId);
};

const getListByStepOfErrors = listOfErrors => {
  const errorsObjectArray = listOfErrors.map(error => {
    return {
      title: error,
      status: 'error',
    };
  });
  return (
    <StepsComponent
      direction="vertical"
      size="small"
      stepsArray={errorsObjectArray}
      progressDot
    />
  );
};

const showErrors = listOfErrors => {
  const description = getListByStepOfErrors(listOfErrors);
  notificationError(SOMETHING_BAD_HAPPEN, description, 0);
};

const postNewRole = async (newRoles, onCreatingRole, onCreatingRoleError) => {
  try {
    onCreatingRole();
    const roles = isArray(newRoles) ? newRoles : [newRoles];
    await securityV2Endpoints.postSecurityNewRolesByJsonFormat(roles);
    onCreatingRole(false);
    notificationSuccess('The Role was created Successfully!');
  } catch (e) {
    notificationError(SOMETHING_BAD_HAPPEN, e.message);
    onCreatingRoleError();
  }
};

export const createRole = ({
  inputRoleValue,
  inputAreaRef,
  onCreatingRole,
  onCreatingRoleError,
  agencyId,
}) => {
  try {
    const stringToObject = JSON.parse(inputRoleValue);
    const listOfErrors = validateData(stringToObject, agencyId);
    if (isEmpty(listOfErrors)) {
      postNewRole(stringToObject, onCreatingRole, onCreatingRoleError);
    } else {
      showErrors(listOfErrors);
    }
  } catch (e) {
    inputAreaRef.current.focus();
    notificationError(SOMETHING_BAD_HAPPEN, e.message);
  }
};
