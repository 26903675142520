import React, { Component } from 'react';
import { isPlainObject, get, includes } from 'lodash';

import DashboardPage from 'APP_COMPONENTS/dashboard';
import {
  getSelectedForm,
  getForm,
  getReportLoadingState,
} from 'APP_ROOT/selectors/form';
import { getSession } from 'APP_ROOT/selectors/session';
import { getModals } from 'APP_ROOT/selectors/modals';
import { getAttachments } from 'APP_ROOT/selectors/attachments';
import { getAgencyTZ } from 'APP_ROOT/selectors/session';
import getFormTemplate from 'APP_ROOT/actions/get-form';

import ErrorScreen from 'APP_COMPONENTS/common/error-screen';
import LoadingReport from 'APP_COMPONENTS/common/loading-report-screen';
import withOwnershipGuard from '../../components/agency-ownership-guard';

const errorMessage = {
  500: 'This page could not be loaded right now, please try again later.',
  404: 'This page could not be loaded right now, please try again later.',
  401: 'You have no permissions to see this page. You will be redirected to the home page in 5 seconds.',
  1: 'Please select a template type to start editing the data fields',
  unknown: '',
};

const errorTitle = {
  500: 'We are sorry',
  404: 'We are sorry',
  401: 'Unauthorized',
  1: '',
  unknown: '',
};

class ReportTypeLoader extends Component {
  state = {
    error: false,
    stateIsReady: false,
  };

  reportContainersCache = {};

  timer;

  static getPageConfig() {
    const { selectedForm } = this.props;
    const formNumber = get(selectedForm, 'meta.formNumber');
    const formType = get(selectedForm, 'template.typeName');
    const newReportTitle = formType ? `${formType} Data Fields` : 'Data Fields';

    const title = formNumber || newReportTitle;

    return {
      title,
    };
  }

  componentDidMount() {
    this.refreshTemplate();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const asString = JSON.stringify;
    const propsAreDifferent = asString(this.props) !== asString(nextProps);
    const stateIsDifferent = asString(this.state) !== asString(nextState);
    const shouldUpdate = propsAreDifferent || stateIsDifferent;

    return shouldUpdate;
  }

  refreshTemplate = async callback => {
    const {
      dispatch,
      match: {
        params: { templateId, agencyId },
      },
    } = this.props;

    this.setState({ stateIsReady: false });

    await Promise.all([
      this.waitAtLeastPromise,
      new Promise(resolve => {
        if (templateId) {
          try {
            dispatch(
              getFormTemplate(
                {
                  templateId: Number(templateId),
                  agencyId,
                  useCache: false,
                  removeOffFields: false,
                },
                err => {
                  this.setErrorState(err);
                  resolve();
                  callback && callback();
                }
              )
            );
          } catch (err) {
            this.setErrorState('unknown');
            resolve();
            callback && callback(err);
          }
        } else {
          this.setErrorState(1);
          resolve();
          callback && callback();
        }
      }),
    ]);

    this.setState({ stateIsReady: true });
  };

  waitAtLeastPromise = (ms = 0) =>
    new Promise(resolve => {
      global.setTimeout(resolve, ms);
    });

  setErrorState = (error, response) => {
    if (error) {
      this.setState({
        error:
          isPlainObject(error) || typeof error === 'object'
            ? get(error, ['response', 'status'], 'unknown')
            : error,
      });
    }
  };

  onErrorClick = e => {
    const {
      history: { replace },
    } = this.props;
    replace('/');
  };

  goBack = e => {
    const { history } = this.props;

    history.goBack();
  };

  getReportContainerPath = () => import('./TemplateEditor');

  getReportContainerComponent = type => {
    const reportContainer = this.getReportContainerPath(type);

    if (this.reportContainersCache[type]) {
      return this.reportContainersCache[type];
    }

    this.reportContainersCache[type] = reportContainer;

    return this.reportContainersCache[type];
  };

  render() {
    const { error } = this.state;
    const {
      selectedForm: { template: { type = '' } = {} } = {},
      form: { isLoading = false },
      match = {},
      app,
      templatesLoading,
    } = this.props;

    const { stateIsReady } = this.state;

    const viewAs = get(match, 'params.viewAs', 'form');
    const isPrinting = includes(['print', 'print-confirmation'], viewAs);

    const extraProps = Object.assign({}, this.props, {
      isPrinting,
      viewAs,
      refreshTemplate: this.refreshTemplate,
    });

    const showNoTemplateSpecifiedMessage = error === 1;
    const NoTemplateSpecified = (
      <span>
        <i className="bdm-icon bdm-icon-new-report" /> Go Back
      </span>
    );

    if (error) {
      return (
        <ErrorScreen
          errorCode={error}
          showTitle={!showNoTemplateSpecifiedMessage}
          pageTitle="Data Fields"
          buttonSize={showNoTemplateSpecifiedMessage ? 'large' : 'default'}
          buttonText={
            showNoTemplateSpecifiedMessage ? NoTemplateSpecified : undefined
          }
          title={errorTitle[error]}
          message={errorMessage[error]}
          onClick={
            showNoTemplateSpecifiedMessage ? this.goBack : this.onErrorClick
          }
        />
      );
    }

    if (isLoading || !stateIsReady || templatesLoading) {
      return (
        <LoadingReport
          key="loading-screen"
          sidebarCollapsed={app.sidebarCollapsed}
        />
      );
    }

    const ReportContainer = this.getReportContainerComponent(type);

    return <ReportContainer {...extraProps} />;
  }
}

const mapState = state => ({
  selectedForm: getSelectedForm(state),
  form: getForm(state),
  session: getSession(state),
  modals: getModals(state),
  attachments: getAttachments(state),
  loading: getReportLoadingState(state),
  timezone: getAgencyTZ(state),
});

export default DashboardPage(
  mapState,
  null
)(withOwnershipGuard(ReportTypeLoader, 'administratorAgencyTemplates'));
