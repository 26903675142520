import React from 'react';
import { get } from 'lodash';
import FieldCheckbox from '../../components/FieldCheckbox';
import { Checkbox } from 'antd';
export default class sectionSourceFieldCheckBox {
  constructor(values) {
    this.name = 'isSourceRequired';
    this.label = 'Title from Source';
    this.component = FieldSectionCheckbox;
    this.checked = false;
    this.onChange = function(e, v) {
      if (v) {
        v.isSourceRequired = e.target.checked;
      }
      return v;
    };
  }

  onSave = values => {
    let result = {
      ...values,
    };
    return result;
  };

  getInitialValue = values => {
    const value = get(values, 'isSourceRequired');
    if (value) {
      return true;
    } else {
      return false;
    }
  };
}
class FieldSectionCheckbox extends FieldCheckbox {
  render() {
    const {
      checkboxLabel = '',
      value,
      onChange,
      style = { left: '0.5em', width: 200 },
      'data-test': dataTest,
    } = this.props;

    return (
      <Checkbox
        style={style}
        checked={value}
        onChange={onChange}
        data-test={dataTest}
      >
        <span className="label">{checkboxLabel}</span>
      </Checkbox>
    );
  }
}
