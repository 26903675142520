import React, { Component } from 'react';
import classNames from 'classnames';
import { omit } from 'lodash';
import { connect } from 'react-redux';

import { mapProperties } from '../../../utils/form';
import compliesToRequirements from '../../../utils/complies-to-requirements';
import RequirementCompliantDiv from '../styled/requirement-compliant';
import { getFormTemplate, getDataEnums } from '../../../selectors/form';
import { getDataSelector } from 'APP_ROOT/utils/renderSchema';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';

class RequirementCompliant extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  render() {
    const {
      form = {},
      isFirst = false,
      isLast = false,
      options = {},
      parentIndex = 0,
      parentKey = '',
      properties = [],
      ...rest
    } = this.props;

    const total = properties.length;
    const { className = '' } = options;
    const complies = compliesToRequirements(this.props);
    const classes = classNames(className, {
      'bdm-requirement-compliant-first': isFirst,
      'bdm-requirement-compliant-last': isLast,
      'bdm-requirement-compliant-not-first': !isFirst,
      'bdm-requirement-compliant-not-last': !isLast,
      'bdm-requirement-compliant-has-errors': !complies,
    });

    return (
      <RequirementCompliantDiv className={classes}>
        {properties.map(
          mapProperties({
            ...omit(rest, ['conditions', 'options']),
            form,
            parentKey,
            parentIndex,
            total,
            title: null,
          })
        )}
      </RequirementCompliantDiv>
    );
  }
}

const mapState = (state, props) => {
  const dataSelector = getDataSelector(props);
  const formTemplate = getFormTemplate(state, props);

  return {
    data: dataSelector(state, props),
    dataEnums: getDataEnums(state, props, formTemplate),
  };
};

export default connect(mapState)(RequirementCompliant);
