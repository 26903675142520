import {
  COMPLAINT_ALLEGATION_TYPE_FILTER_KEY,
  INVESTIGATION_ALLEGATION_TYPE_FILTER_KEY,
  FILTER_ARRAY_SEPARATOR,
} from './constants';

const getAllegationFilter = (allegation = '') => {
  if (allegation.length) {
    const [isIAInvestigation, ...values] = allegation.split(
      FILTER_ARRAY_SEPARATOR
    );
    const filterKey =
      isIAInvestigation.toLowerCase() === 'true'
        ? INVESTIGATION_ALLEGATION_TYPE_FILTER_KEY
        : COMPLAINT_ALLEGATION_TYPE_FILTER_KEY;
    return [
      {
        column: filterKey,
        operator: 'inq',
        value: values,
      },
    ];
  }
  return [];
};

export default getAllegationFilter;
