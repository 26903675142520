import React, { Fragment } from 'react';

import FieldNumber from '../../components/FieldNumber';

import { Row, Col, Form } from 'antd';

const { Item } = Form;

export default class NumberFieldOption extends React.Component {
  onChangeMin = _min => {
    const { onChange, value } = this.props;
    const { defaultValue: _defaultValue = 0, max } = value;

    const min = max === undefined ? _min : _min <= max ? _min : max;
    const defaultValue = _defaultValue < min ? min : _defaultValue;

    onChange && onChange({ ...value, defaultValue, min });
  };

  onChangeMax = _max => {
    const { onChange, value } = this.props;
    const { min } = value;
    const max = _max < min ? min : _max;

    onChange && onChange({ ...value, max });
  };

  onChangeDefaultVaue = _defaultValue => {
    const { onChange, value } = this.props;
    const { min, max } = value;
    const defaultValue =
      _defaultValue < min ? min : _defaultValue > max ? max : _defaultValue;

    onChange && onChange({ ...value, defaultValue });
  };

  render = () => {
    const itemLayoutOptions = {
      colon: false,
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
    };
    const {
      value: { defaultValue, min, max },
    } = this.props;

    return (
      <Fragment>
        <Row gutter={16}>
          <Col span={7}>
            <Item
              key="value-range"
              label="Value Range"
              colon={false}
              labelCol={{ offset: 12 }}
            />
          </Col>
          <Col span={6}>
            <Item key="min-value" help="Min Value">
              <FieldNumber
                value={min}
                onChange={this.onChangeMin}
                style={{ width: '100%' }}
                min={0}
                max={max}
                data-test="number-min"
              ></FieldNumber>
            </Item>
          </Col>
          <Col span={6}>
            <Item key="max-value" help="Max Value">
              <FieldNumber
                value={max}
                onChange={this.onChangeMax}
                style={{ width: '100%' }}
                min={min}
                data-test="number-max"
              ></FieldNumber>
            </Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Item
            key="default-value"
            label="Default value"
            {...itemLayoutOptions}
          >
            <FieldNumber
              value={defaultValue}
              onChange={this.onChangeDefaultVaue}
              style={{ width: '40%' }}
              min={min}
              max={max}
              data-test="number-default"
            ></FieldNumber>
          </Item>
        </Row>
      </Fragment>
    );
  };
}
