import { isEmpty, get } from 'lodash';

import componentsManager from '../../../modules/FormBuilder/services/componentsManager';
import getAllFieldsFlat from '../../../modules/FormBuilder/utils/getAllFieldsFlat';
import { DURATION, MATH } from '../../../constants/fieldTypes';

const FIELDS_TO_VERIFY = {
  [DURATION]: {
    properties: ['initial', 'final'],
    message:
      'Duration field <strong>$fieldTitle $reportingKey</strong> in tab <strong>$tabTitle</strong> is missing a date.',
  },
  [MATH]: {
    properties: ['operands'],
    message:
      'Math field <strong>$fieldTitle $reportingKey</strong> in tab <strong>$tabTitle</strong> is missing an operand.',
  },
};

const getTabTitle = (allFields, field) => {
  const { allTabs } = allFields;
  let title;
  allTabs.some(t => {
    const prop = t.properties.find(p => p.key === field.key);
    if (prop) {
      title = t.title;
    }
    return !!title;
  });
  return title;
};

const getMessage = allFields => field => {
  const { field_type, reportingKey, title = '' } = field;
  let {
    [field_type]: { message: msg },
  } = FIELDS_TO_VERIFY;
  let fieldReportingKey;
  msg = msg.replace('$fieldTitle', title);
  // find repeater key here
  const repeaterKey = componentsManager.findItemInRepeatersReference(field);
  if (repeaterKey) {
    const [repeater] = componentsManager.findContainersByKey(
      componentsManager.fields,
      repeaterKey
    );
    const repKey = repeater.reportingKey || repeater.key;
    fieldReportingKey = `${repKey} > ${reportingKey}`;
  } else {
    fieldReportingKey = reportingKey;
  }
  msg = msg.replace('$reportingKey', fieldReportingKey);
  // find tab title
  const tabTitle = getTabTitle(allFields, field);
  return msg.replace('$tabTitle', tabTitle);
};

const canPublish = () => {
  const allFields = getAllFieldsFlat(componentsManager.fields);
  const fieldTypes = Object.keys(FIELDS_TO_VERIFY);

  const fieldList = Object.values(allFields.fieldsByKey).filter(
    f =>
      fieldTypes.includes(f.field_type) &&
      FIELDS_TO_VERIFY[f.field_type].properties.some(prop =>
        isEmpty(get(f, ['options', prop]))
      )
  );

  const errors = fieldList.map(getMessage(allFields));

  return isEmpty(errors) ? true : errors;
};

export default canPublish;
