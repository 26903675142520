import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import {
  Modal,
  Button,
  Form,
  Icon,
  Row,
  Col,
  Upload,
  Input,
  notification,
} from 'antd';
import { unescape as htmlUnescape, escape as htmlEscape } from 'html-escaper';

import { getAgencyTZ } from '../../../selectors/session';

import parseDate, { BENCHMARK_DATE_FORMAT } from 'APP_ROOT/utils/parse-date';
import storage from 'APP_ROOT/utils/storage';
import sectionModalCancel from 'APP_ROOT/containers/administrator/agency-users/UserProfile/sectionModalCancel';
import showErrorMessages from 'APP_ROOT/containers/administrator/agency-users/UserProfile/errors/OuErrorMessages';

import caseFileEndpoints from '../../../api/caseFileEndpoints/caseFileEndpoints';

import StyledModal from './CaseFileDocumentModal.style';

import FileSizeLimit from '../../../components/form-viewer/forms/utils/fileSizeLimit';
import AttachmentsWarning from '../../../components/common/attachments-warning';

const PREFIX = 'userDocumentsData';
const ATTRIBUTE_ID = 'id';
const uploadErrorMessage = 'Something went wrong uploading the file.';
const NOTIFICATION_SUCCESS = 'success';

const { TextArea } = Input;
const FormItem = Form.Item;
const descriptionLayout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 22,
  },
};

class CaseFileDocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editForm: null,
      isNew: false,
      description: '',
      prevDescription: undefined,
      currentRecords: [],
      uploadInputKey: 'uploadInput',
      hasUploadedFile: false,
      fileList: [],
      loading: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { visible, isNew } = nextProps;
    const { prevDescription } = prevState;
    if (visible && !isNew && prevDescription === undefined) {
      const { rowIndex } = nextProps;
      const nextDescription = get(nextProps, [
        'profileForm',
        'values',
        'userDocumentsData',
        'documents',
        'description',
        rowIndex,
      ]);
      const _nextDescription = nextDescription
        ? htmlUnescape(nextDescription)
        : nextDescription;

      return {
        prevDescription: _nextDescription || '',
        description: _nextDescription || '',
      };
    }
    return null;
  }

  initData = () => {
    this.setState({
      editForm: null,
      isNew: false,
      description: '',
      prevDescription: undefined,
      currentRecords: [],
      uploadInputKey: 'uploadInput',
      hasUploadedFile: false,
      fileList: [],
      loading: false,
    });
  };

  onBeforeUpload = file => {
    this.setState({ fileList: [file] });
  };

  uploadOverride = async file => {
    const { description } = this.state;
    const { profileForm: { initial: { id } = {} } = {} } = this.props;
    const {
      success,
      errors,
      content,
    } = await caseFileEndpoints.addDocumentCaseFile(
      id,
      file.name,
      file.type,
      file.size,
      description
    );
    const formattedContent = content
      ? {
          id: content.id,
          name: content.name,
          uploadURL: content.url,
          description,
        }
      : null;
    return {
      success,
      errors,
      content: formattedContent,
    };
  };

  componentDidUpdate(prevProps) {
    if (!this.props.visible && this.props.visible !== prevProps.visible) {
      this.setState({ hasUploadedFile: false }, this.resetUploadInput);
    }
  }

  resetUploadInput = () => {
    const newKey = `uploadInput-${Math.random() * 10}`;
    this.setState({ uploadInputKey: newKey });
  };

  getURL = () => {
    const { uploadURL } = this.props;
    return uploadURL;
  };

  showNotification = (type, message, description) =>
    notification[type]({ message, description });

  uploadDocument = async (file, url, documentId) => {
    const xhr = new XMLHttpRequest();
    const response = await new Promise(resolve => {
      xhr.addEventListener('loadend', () => {
        resolve(xhr.readyState === 4 && xhr.status === 200);
      });
      xhr.open('PUT', url, true);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file.originFileObj);
    });
    if (response) {
      await caseFileEndpoints.completeUpload(documentId);
      return true;
    }
    return false;
  };

  updateSectionFields = (documentId, fileName, description) => {
    const {
      rowIndex,
      updateSectionField,
      timezone,
      casefileId,
      userIntegrationId,
      currentUserFullName,
    } = this.props;
    const locationUrl = caseFileEndpoints.getDocumentDownloadParams(
      casefileId,
      documentId
    );
    const now = new Date();
    const createdAt = parseDate(now, timezone, BENCHMARK_DATE_FORMAT);
    const uploadedAt = parseDate(now, timezone, 'LLL');

    updateSectionField(
      `userDocumentsData.documents.id[${rowIndex}]`,
      documentId
    );

    updateSectionField(
      `userDocumentsData.documents.locationUrl[${rowIndex}]`,
      locationUrl
    );

    updateSectionField(
      `userDocumentsData.documents.fileName[${rowIndex}]`,
      fileName
    );

    updateSectionField(
      `userDocumentsData.documents.description[${rowIndex}]`,
      htmlEscape(decodeURIComponent(description))
    );

    updateSectionField(
      `userDocumentsData.documents.createdBy[${rowIndex}]`,
      userIntegrationId
    );

    updateSectionField(
      `userDocumentsData.documents.createdAt[${rowIndex}]`,
      createdAt
    );

    updateSectionField(`userDocumentsData.documents.uploaded[${rowIndex}]`, {
      createdAt,
      uploaded: `Uploaded ${uploadedAt} by ${currentUserFullName}`,
    });
  };

  completeUpload = async () => {
    const { fileList } = this.state;
    const file = fileList[0];

    const { success, content, errors } = await this.uploadOverride(file);
    if (success && (!errors || isEmpty(errors))) {
      const completed = await this.uploadDocument(
        file,
        content.uploadURL,
        content.id
      );
      if (completed) {
        this.updateSectionFields(content.id, content.name, content.description);
        return true;
      }
      return false;
    }
    notification.error({
      message: `Error uploading file ${content.name}`,
      description: uploadErrorMessage,
    });
    return false;
  };

  onChange = ({ _, fileList }) => {
    // always sync `fileList` state for controlled `fileList`
    // for more info, check this issue: https://github.com/ant-design/ant-design/issues/2423
    this.setState({ hasUploadedFile: true, fileList: fileList.slice() });
  };

  onChangeDescription = e => {
    const { value } = e.target;
    this.setState({ description: value });
  };

  onRemoveFileOnStorage = () => {
    this.setState({ fileList: [] });
  };

  saveUpload = () => {
    //No action
  };

  getRowIndex = key => {
    const { profileForm, sectionId } = this.props;
    const keys = get(
      profileForm,
      `values.${PREFIX}.${sectionId}.${ATTRIBUTE_ID}`,
      []
    );
    return keys.findIndex(k => k === key);
  };

  onCancelEdit = () => {
    const { editForm } = this.state;
    const props = {
      updateSectionField: this.props.updateSectionField,
      removeSectionFieldValue: this.props.removeSectionFieldValue,
      attributes: this.props.sectionAttributes,
      profileForm: this.props.profileForm,
      prefix: PREFIX,
      sectionId: this.props.sectionId,
      attributeId: ATTRIBUTE_ID,
    };

    sectionModalCancel(editForm.isNew, editForm.record, props);
    this.setState({ editForm: null });
  };

  onOkEdit = () => {
    this.setState({ editForm: null });
  };

  onModalOk = e => {
    const {
      onOk,
      isNew,
      profileForm,
      rowIndex,
      casefileId,
      updateSectionField,
    } = this.props;

    if (isNew) {
      const { hasUploadedFile } = this.state;

      this.setState({ loading: true });
      this.completeUpload()
        .then(uploadCompleted => {
          if (hasUploadedFile && uploadCompleted) {
            this.setState({ editForm: null, isNew: false });
            onOk && onOk(e, { rowIndex, methodType: 'save' });
            this.showNotification(
              NOTIFICATION_SUCCESS,
              'Success',
              'File uploaded successfully'
            );
            this.initData();
          } else {
            notification.error({
              message: 'Upload error',
              description: uploadErrorMessage,
            });
          }
        })
        .catch(err => {
          notification.error({
            message: 'Upload error',
            description: err?.message || 'API response is not ok',
          });
        })
        .finally(() => this.setState({ loading: false }));
    } else {
      const documentId = get(
        profileForm,
        `values.userDocumentsData.documents.id[${rowIndex}]`
      );
      const { description } = this.state;

      this.setState({ loading: true });
      caseFileEndpoints
        .updateDocument(casefileId, documentId, description)
        .then(({ success }) => {
          if (success) {
            updateSectionField(
              `userDocumentsData.documents.description[${rowIndex}]`,
              htmlEscape(decodeURIComponent(description))
            );
            this.setState({ editForm: null, isNew: false });
            onOk && onOk(e, { rowIndex, methodType: 'update' });
            this.initData();
            this.showNotification(
              NOTIFICATION_SUCCESS,
              'Success',
              'File updated successfully'
            );
          } else {
            notification.error({
              message: 'Something went wrong',
              description: 'Error updating the selected document',
            });
          }
        })
        .catch(err => {
          notification.error({
            message: 'Something went wrong',
            description: err?.message || 'API response is not ok',
          });
        })
        .finally(() => this.setState({ loading: false }));
    }
  };

  onModalCancel = () => {
    const { onCancel } = this.props;
    this.setState({ editForm: null, isNew: false });
    onCancel && onCancel();
    this.initData();
  };

  softDeleteCurrentDocument = async e => {
    const {
      dispatch,
      casefileId,
      sectionId: documentId,
      onOk,
      rowIndex,
    } = this.props;

    try {
      caseFileEndpoints
        .deleteDocumentCaseFile(casefileId, documentId)
        .then(response => {
          const {
            success = false,
            errors: { message = '' },
          } = response;
          if (success) {
            this.setState({ editForm: null, isNew: false });
            onOk && onOk(e, { rowIndex, methodType: 'remove' });
            this.showNotification(
              NOTIFICATION_SUCCESS,
              'Success',
              'Document removed successfully'
            );
            this.initData();
          } else {
            this.showNotification(
              NOTIFICATION_SUCCESS,
              'Something went wrong',
              message?.message
            );
          }
        });
    } catch (err) {
      showErrorMessages(dispatch, err);
    }
  };

  createDeleteModal = async e => {
    const { profileForm, rowIndex } = this.props;
    const fileName = get(
      profileForm,
      `values.userDocumentsData.documents.fileName[${rowIndex}]`
    );

    Modal.confirm({
      title:
        'Are you sure you want to remove the document from this case file?!',
      content: fileName,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => this.softDeleteCurrentDocument(e),
    });
  };

  generateButtonDefinitions = okLabel => ({
    readOnly: {
      buttons: [
        { key: 'done', text: 'Done', type: 'primary', onClick: this.onModalOk },
      ],
    },
    isNew: {
      buttons: [
        { key: 'cancel', text: 'Cancel', onClick: this.onModalCancel },
        {
          key: 'save',
          type: 'primary',
          text: okLabel,
          onClick: this.onModalOk,
          disabled: !this.state.hasUploadedFile,
        },
      ],
    },
    regular: {
      buttons: [
        {
          key: 'delete',
          type: 'danger',
          text: 'Delete',
          onClick: this.createDeleteModal,
        },
        {
          key: 'cancel',
          text: 'Cancel',
          style: { marginLeft: 'auto' },
          onClick: this.onModalCancel,
        },
        {
          key: 'Save',
          type: 'primary',
          text: okLabel,
          onClick: this.onModalOk,
        },
      ],
      style: {
        display: 'flex',
      },
    },
  });

  getModalFooterButtons = (readOnly, isNew, okLabel) => {
    const { loading } = this.state;
    const type = readOnly ? 'readOnly' : isNew ? 'isNew' : 'regular';
    const buttonSet = this.generateButtonDefinitions(okLabel)[type];

    return (
      <div style={buttonSet.style}>
        {buttonSet.buttons.map(button => (
          <Button
            key={button.key}
            type={button.type}
            onClick={button.onClick}
            style={button.style}
            disabled={button.disabled || loading}
          >
            {button.text}
          </Button>
        ))}
      </div>
    );
  };

  getModalFooterMessages = () => (
    <Fragment>
      <Row type="flex" justify="center">
        <Col>
          <FileSizeLimit />
        </Col>
      </Row>
      <Row className="row-important">
        <Col>
          <AttachmentsWarning />
        </Col>
      </Row>
    </Fragment>
  );

  render = () => {
    const {
      title,
      visible,
      readOnly,
      isNew,
      attributes,
      okLabel = 'Apply changes',
    } = this.props;
    const { fileList, description = '' } = this.state;
    const {
      settings: { maxLength },
    } = attributes.find(a => a.attributeName === 'description');

    return (
      <StyledModal
        title={title}
        width={1000}
        visible={visible}
        onOk={this.onModalOk}
        onCancel={this.onModalCancel}
        footer={this.getModalFooterButtons(readOnly, isNew, okLabel)}
      >
        {isNew && (
          <Row type="flex" justify="center" className="row-upload">
            <Col>
              <Upload
                showUploadList={true}
                beforeUpload={this.onBeforeUpload}
                name="document"
                headers={{
                  Authorization: `bearer ${storage.get('token')}`,
                }}
                action={this.getURL()}
                onChange={this.onChange}
                onRemove={this.onRemoveFileOnStorage}
                customRequest={async ({ file, onProgress, onSuccess }) => {
                  onProgress({ percent: 0 });
                  // `customRequest` function needs a delay to resolve
                  // or else it will behave as if the file is still uploading (status = 'uploading')
                  const uploadedFile = await new Promise(resolve => {
                    setTimeout(() => {
                      onProgress({ percent: 100 });
                      resolve({ ...file, status: 'done' });
                    }, 400);
                  });
                  onSuccess({ success: true }, uploadedFile);
                }}
                ref={this.saveUpload}
                multiple={false}
                key={this.state.uploadInputKey}
                disabled={readOnly}
                fileList={fileList}
                maxCount={1}
              >
                <Button>
                  Select File <Icon type="upload" />
                </Button>
              </Upload>
            </Col>
          </Row>
        )}
        <Row style={{ paddingBottom: '22px', paddingTop: '22px' }}>
          <FormItem {...descriptionLayout} label="Description" colon={false}>
            <TextArea
              key="key-user-document"
              rows={5}
              maxLength={maxLength}
              readOnly={readOnly}
              value={htmlUnescape(description)}
              onChange={this.onChangeDescription}
              data-test="user-document"
            />
          </FormItem>
        </Row>
        {!readOnly && this.getModalFooterMessages()}
      </StyledModal>
    );
  };
}

const mapState = state => {
  const userIntegrationId = get(
    state,
    'session.currentUser.userIntegrationId',
    ''
  );
  const currentUserFullName = get(state, 'session.currentUser.fullName', '');
  return {
    userIntegrationId,
    currentUserFullName,
    timezone: getAgencyTZ(state),
  };
};

export default connect(mapState)(CaseFileDocumentModal);
