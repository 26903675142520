function addScript(name = '', attribute, text, callback) {
  const onLoaded = (injected = true) => {
    if (callback && typeof callback === 'function') {
      callback(injected);
    }
  };

  const uniqName = `dynamic-script-${name}`;

  if (global[uniqName]) {
    return callback(false);
  }

  var s = document.createElement('script');
  for (var attr in attribute) {
    s.setAttribute(attr, attribute[attr] ? attribute[attr] : null);
  }

  s.id = uniqName;
  s.innerHTML = text;
  s.onload = () => {
    global[uniqName] = uniqName;

    onLoaded();
  };

  document.body.appendChild(s);
}

export default (...scriptArgs) => cb => {
  addScript(...scriptArgs, null, () => cb(null));
};
