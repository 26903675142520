import some from 'lodash/some';
import castArray from 'lodash/castArray';

export const hasFeatures = (agencyFeatures, featuresToFind) =>
  some(castArray(agencyFeatures), feature =>
    castArray(featuresToFind).includes(feature)
  );

export const FEATURES = {
  userTrainingSection: 'userTrainingSection::1.0',
  userMergeTrainingSection: 'userMergeTrainingSection::1.0',
  userTrainingSectionHyperlinks: 'userTrainingSectionHyperlinks::1.0',
  userEquipmentSection: 'userEquipmentSection::1.0',
  enableCaseFileTaskListPanel: 'enableCasefileTaskListPanel::1.0',
  enableContributeToReport: 'enableContributeToReport::1.0',
  addressVerification: 'addressVerification::1.0',
  reportUserSelectionFuzzySearch: 'reportUserSelectionFuzzySearch::1.0',
  enableCasefileKeyDetails: 'enableCasefileKeyDetails::1.0',
};
