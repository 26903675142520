import React, { Component } from 'react';
import moment from 'moment-timezone';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';

import saveFieldDataValue from './saveFieldDataValue';
import getFieldValue from './getFieldValue';
import ReviewerField from '../reviewer-field';

import {
  TIME_UNITS_CONVERSION,
  TIME_UNITS_YEARS,
  TIME_UNITS_MINUTES,
} from '../../../modules/FormBuilder/constants/constants';

class Duration extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  getMomentDate = value => {
    const date = value ? moment(value) : false;
    if (date && date.isValid()) {
      return date;
    }
    return false;
  };

  computeDates = () => {
    const {
      options: { initial, final, units },
    } = this.props;
    const initialValue = getFieldValue(this.props, initial);
    const initialDate = this.getMomentDate(initialValue);
    const finalValue = getFieldValue(this.props, final);
    const finalDate = this.getMomentDate(finalValue);

    const applyUnits = TIME_UNITS_CONVERSION[units];

    if (initialDate && finalDate) {
      const newValue = finalDate.diff(initialDate, applyUnits, false);
      return newValue > 0 ? newValue : 0;
    }
    return undefined;
  };

  computeDuration = () => {
    const {
      calculatedValue,
      options: { units },
    } = this.props;
    const value = calculatedValue || this.props.value || 0;

    let newValue;
    switch (units) {
      case TIME_UNITS_YEARS:
      case TIME_UNITS_MINUTES:
        newValue = this.computeDates();
        break;
      default:
        newValue = undefined;
        break;
    }

    if (value !== newValue) {
      saveFieldDataValue(this.props, newValue);
    }
  };

  componentDidUpdate(prevProps) {
    this.computeDuration();
  }

  render() {
    const { calculatedValue } = this.props;
    const evaluateValue = calculatedValue || this.props.value || 0;
    const formattedValue = evaluateValue > 0 ? evaluateValue : '';
    return <ReviewerField value={formattedValue} />;
  }
}

export default Duration;
