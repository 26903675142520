import styled from 'styled-components';

export const GroupedChartItem = styled.div.attrs({
  className: 'bdm-grouped-chart-item',
})`
  width: 15%;
  position: relative;
  display: inline-block;
  text-align: center;
  color: #fff;

  @media (max-width: 668px) {
    width: 33%;
  }
`;

export default GroupedChartItem;
