import styled from 'styled-components';

export const LiveEventCheckInWrapper = styled.div.attrs({
  className: 'live-event-check-in-wrapper',
})`
  padding: 6px 16px;

  .ant-list {
    background-color: white;
    padding: 5px;
    margin-top: 20px;
    border-radius: 5px;

    .ant-list-item-meta-content {
      margin: 0 10px;
    }
  }
`;
