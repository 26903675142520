import React from 'react';

import ModalTitle from '../../components/common/modal/title';
import AuditLogsOptions from './agency-audit-view-logs/components/AuditLogsOptions';

const selectModalOptions = (modalMethods, push, agencyData) => {
  const { updateModal, showModal, hideModal } = modalMethods;
  const title = (
    <ModalTitle>Select which audit logs you want to view:</ModalTitle>
  );
  const body = (
    <AuditLogsOptions
      hideModal={hideModal}
      agencyData={agencyData}
      push={push}
    />
  );
  updateModal({
    visible: true,
    title: title,
    children: body,
    destroyOnClose: true,
  });
  showModal();
};

export default selectModalOptions;
