import React from 'react';
import { Row, Col, Button } from 'antd';

import { get } from 'lodash';
import { connect } from 'react-redux';
import If from '../../../../../components/utils/ConditionalRender';
import FormSkeleton from '../FormSkeleton';
import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from '../../../../../components/custom-sections/sections/Sections.styled';
import UserAssignment from '../components/UserAssignment';
import { FieldBuilder } from './FieldBuilder';
import EquipmentSection from './EquipmentSection';
import storage from '../../../../../utils/storage';
import { allStandardFields } from '../constants/form-values.constants';

const buildColumns = (rows, amountOfColumns, fieldBuilder) => {
  const columns = [];
  for (let i = 0; i < amountOfColumns; i++) {
    columns.push(
      <Col
        key={`col-user-info-${i}`}
        xs={24}
        sm={24 / amountOfColumns}
        md={24 / amountOfColumns}
        lg={24 / amountOfColumns}
      >
        {fieldBuilder.get(rows[i])}
      </Col>
    );
  }
  return columns;
};

const buildTopFields = (fieldBuilder, topFields) => {
  const rows = [];
  for (let i = 0; i < topFields.length; i += 2) {
    rows.push(
      <Row key={`row-user-info-top${i}`}>
        <Col key={`col-${topFields[i]}`} xs={24} sm={12} md={12} lg={12}>
          {fieldBuilder.get(topFields[i])}
        </Col>
        <Col key={`col-${topFields[i + 1]}`} xs={24} sm={12} md={12} lg={12}>
          {fieldBuilder.get(topFields[i + 1])}
        </Col>
      </Row>
    );
  }
  return rows;
};

const buildAssignment = (
  organizationalUnitsAssignedToUserList,
  organizationalUnitTreeList,
  timezone
) => {
  const searchUnitTree = (ouId, parentOu) => {
    const stack = [[parentOu, []]];
    while (stack.length) {
      const [unit, path] = stack.pop();
      if (unit.value === ouId) {
        return path;
      }
      if (unit.children) {
        stack.push(...unit.children.map(unit => [unit, [...path, unit]]));
      }
    }
  };

  if (
    organizationalUnitsAssignedToUserList.length > 0 &&
    organizationalUnitTreeList.length > 0
  ) {
    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          {organizationalUnitsAssignedToUserList.map((oUAssigned, i) => {
            const assignment = searchUnitTree(
              oUAssigned.value,
              organizationalUnitTreeList[0]
            );
            return (
              <UserAssignment
                key={`userAssignment-${i}`}
                employmentType={oUAssigned.employmentType}
                assignment={assignment}
                hireDate={oUAssigned.hireDate}
                endOfDetailDate={oUAssigned.endOfDetailDate}
                isDetail={oUAssigned.isDetail}
                tourOfDuty={oUAssigned.tourOfDuty}
                timezone={timezone}
              />
            );
          })}
        </Col>
      </Row>
    );
  }
  return <div></div>;
};

const buildViewStatsPersonnelURL = profileForm => {
  const userId = get(profileForm, `values.id`);
  const agencyId = get(profileForm, `values.agencyId`);
  const token = storage.get('token');

  return `${process.env.REACT_APP_STATS_WEBSITE_URI}/officers/overview?organization=${agencyId}&person=${userId}&external=true#access_token="${token}"`;
};

const UserInformation = ({
  formName,
  profileForm,
  fetchingAgency = false,
  unitAssignments = [],
  ranks = [],
  agencySecurityRoles = [],
  isCreatingUser: isNewUser = false,
  saveRef,
  shouldSeeAnyField,
  shouldBeReadOnlyField,
  getFieldLabel,
  timezone,
  organizationalUnitsAssignedToUserList = [],
  canViewStatsPersonnel = false,
  userPictureSource,
  organizationalUnitTreeList,
  canViewUserAssignment = false,
  canViewUnMaskedSSN = false,
  canUpdateSSN = false,
  canModifyRoles = false,
  hireDate,
}) => {
  const shouldSeeUserAssignment =
    shouldSeeAnyField('activeAssignment') && canViewUserAssignment;
  const activeAssignmentField =
    shouldSeeUserAssignment &&
    buildAssignment(
      organizationalUnitsAssignedToUserList,
      organizationalUnitTreeList,
      timezone
    );
  const viewStatsPersonnelURL = buildViewStatsPersonnelURL(profileForm);

  const fieldBuilder = new FieldBuilder({
    profileForm,
    fetchingAgency,
    shouldBeReadOnlyField,
    shouldSeeAnyField,
    getFieldLabel,
    organizationalUnitsAssignedToUserList,
    isNewUser,
    userPictureSource,
    ranks,
    timezone,
    agencySecurityRoles,
    unitAssignments,
    canUpdateSSN,
    canViewUnMaskedSSN,
    canModifyRoles,
    hireDate,
  });

  const fieldNames = allStandardFields.filter(field =>
    shouldSeeAnyField(field)
  );
  const amountOfTopFields = shouldSeeUserAssignment
    ? 9 - organizationalUnitsAssignedToUserList.length * 2
    : 9;
  const topFields = shouldSeeAnyField('userPicture')
    ? fieldNames.slice(1, amountOfTopFields)
    : [];
  const rows = [];
  const amountOfColumns = shouldSeeAnyField('userPicture') ? 3 : 2;
  let i = shouldSeeAnyField('userPicture') ? topFields.length + 1 : 0;
  const length = fieldNames.length;
  while (i < length) {
    let count = amountOfColumns;
    let cols = [];
    while (count > 0 && i < length) {
      cols.push(fieldNames[i]);
      i++;
      count--;
    }
    rows.push(cols);
  }

  const ViewStatsButton = props => {
    return !props.fetchingAgency &&
      !props.isNewUser &&
      props.canViewStatsPersonnel ? (
      <Col>
        <Button
          id="viewStatsButton"
          type="primary"
          size="default"
          ghost
          href={props.viewStatsPersonnelURL}
        >
          View Personnel Statistics
        </Button>
      </Col>
    ) : null;
  };

  const AssignmentField = props => {
    return props.visible ? (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          {activeAssignmentField}
        </Col>
      </Row>
    ) : null;
  };

  return (
    <StyledCollapse
      bordered={false}
      defaultActiveKey="user-Info"
      expandIconPosition="right"
    >
      <StyledPanel
        header={
          <StyledPanelTitle size="large">User Information</StyledPanelTitle>
        }
        key="user-Info"
        className="section-panel"
        extra={
          <ViewStatsButton
            viewStatsPersonnelURL={viewStatsPersonnelURL}
            fetchingAgency={fetchingAgency}
            isNewUser={isNewUser}
            canViewStatsPersonnel={canViewStatsPersonnel}
          />
        }
      >
        <hr></hr>
        <If condition={fetchingAgency}>
          <FormSkeleton />
        </If>
        <If condition={!fetchingAgency}>
          <If condition={shouldSeeAnyField('userPicture')}>
            <Row>
              <Col key={`col-userPicture`} xs={24} sm={8} md={8} lg={8}>
                <Row justify="space-around" align="middle">
                  <Col back xs={8} sm={8} md={8} lg={8}></Col>
                  <Col xs={8} sm={4} md={4} lg={4}>
                    {fieldBuilder.get('userPicture')}
                  </Col>
                  <Col back xs={8} sm={8} md={8} lg={8}></Col>
                </Row>
              </Col>
              <Col key={`col-left`} xs={24} sm={16} md={16} lg={16}>
                <If condition={shouldSeeUserAssignment}>
                  {activeAssignmentField}
                </If>
                {buildTopFields(fieldBuilder, topFields)}
              </Col>
            </Row>
          </If>
        </If>
        <If condition={!fetchingAgency}>
          <>
            {rows.map((row, i) => (
              <Row key={`row-user-info-${i}`}>
                {buildColumns(row, amountOfColumns, fieldBuilder)}
              </Row>
            ))}
            <AssignmentField
              visible={
                shouldSeeUserAssignment && !shouldSeeAnyField('userPicture')
              }
            />
            <EquipmentSection
              formName={formName}
              profileForm={profileForm}
              saveRef={saveRef}
              shouldSeeAnyField={shouldSeeAnyField}
              shouldBeReadOnlyField={shouldBeReadOnlyField}
              getFieldLabel={getFieldLabel}
              fetchingAgency={fetchingAgency}
            ></EquipmentSection>
          </>
        </If>
      </StyledPanel>
    </StyledCollapse>
  );
};
const mapState = ({
  session: {
    currentUser: {
      agency: { timezone },
    },
  },
}) => ({
  timezone,
});

export default connect(mapState)(UserInformation);
