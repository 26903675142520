import React, { Fragment } from 'react';

import FieldText from '../../components/FieldText';
import FieldTextarea from '../../components/FieldTextarea';

import { Row, Form } from 'antd';

const { Item } = Form;
const itemLayoutOptions = {
  colon: false,
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default class FormHeaderOptions extends React.Component {
  onChangeName = name => {
    const { onChange, value } = this.props;

    onChange && onChange({ ...value, name });
  };

  onBlurName = name => {
    const { onChange, value } = this.props;

    onChange && onChange({ ...value, name: name.trim() });
  };

  onChangeDescription = description => {
    const { onChange, value } = this.props;

    onChange && onChange({ ...value, description });
  };

  onChangeAbbreviation = (abbr = '') => {
    const { onChange, value } = this.props;

    onChange &&
      onChange({
        ...value,
        abbreviation: abbr.replace(/\s+/g, '').toUpperCase(),
      });
  };

  onBlurAbbreviation = (abbr = '') => {
    const { onChange, value } = this.props;

    onChange &&
      onChange({
        ...value,
        abbreviation: abbr.replace(/\s+/g, '').toUpperCase(),
      });
  };
  render = () => {
    const {
      value: { name, description, abbreviation },
    } = this.props;
    const titleLabel = (
      <div>
        Title&nbsp;<span style={{ color: 'red' }}>*</span>&nbsp;
      </div>
    );
    const abbrLabel = (
      <div>
        Abbreviation&nbsp;<span style={{ color: 'red' }}>*</span>&nbsp;
      </div>
    );

    return (
      <Fragment>
        <Row>
          <Item key="title" label={titleLabel} {...itemLayoutOptions}>
            <FieldText
              value={name}
              onChange={this.onChangeName}
              onBlur={this.onBlurName}
              placeholder="Title"
              style={{ width: '100%' }}
              data-test="header-name"
            ></FieldText>
          </Item>
        </Row>
        <Row>
          <Item key="description" label="Description" {...itemLayoutOptions}>
            <FieldTextarea
              value={description}
              onChange={this.onChangeDescription}
              placeholder="Description"
              style={{ width: '100%' }}
              maxLength={250}
              rows={4}
              data-test="header-desc"
            ></FieldTextarea>
          </Item>
        </Row>
        <Row>
          <Item key="abbreviation" label={abbrLabel} {...itemLayoutOptions}>
            <FieldText
              value={abbreviation}
              onChange={this.onChangeAbbreviation}
              onBlur={this.onBlurAbbreviation}
              placeholder="Abbreviation"
              style={{ width: '100%' }}
              maxLength={15}
              data-test="header-abbr"
            ></FieldText>
          </Item>
        </Row>
      </Fragment>
    );
  };
}
