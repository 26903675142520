import callback from '../../administrator/utils/callback';
import organizationEndpoints from '../../../api/organization/organizationEndpoints';

export default (organizationalUnitId, attributes, next) => _dispatch => {
  return organizationEndpoints
    .patchOrganizationalUnit(organizationalUnitId, attributes)
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
