import { get } from 'lodash';
import { hasPermissions, PERMISSIONS } from '../../../utils/admin';

const STATE_CLOSED = 'closed';

export default props => {
  const permissions = get(props, 'user.permissions', []);
  const state = get(
    props,
    'selectedForm.meta.workFlowData.state',
    STATE_CLOSED
  );
  const activeStateReviewers = get(
    props,
    `selectedForm.meta.workFlowData.activeStateReviewers['${state}']`,
    []
  ).map(Number);
  const userId = get(props, 'user.id', -1);

  // only active reviewer and users with reassignReports permissions can reassign reports
  return (
    state !== STATE_CLOSED &&
    (activeStateReviewers.includes(Number(userId)) ||
      hasPermissions(permissions, [PERMISSIONS.reassignReports]))
  );
};
