import React from 'react';
import { Button, Icon } from 'antd';

import { EditReportWrapper, CancelEditBottomWrapper } from './main-form.styled';

const editAdminButtom = ({
  enableEditReport,
  disableEditReport,
  isEditAllow,
  editButtonProps,
}) => {
  const { type, text } = editButtonProps;
  return (
    <EditReportWrapper>
      <Button
        type="primary"
        onClick={() => enableEditReport()}
        ghost={isEditAllow ? '' : 'ghost'}
      >
        <Icon type={type} />
        {text}
      </Button>
      {isEditAllow && (
        <CancelEditBottomWrapper>
          <Button type="primary" onClick={() => disableEditReport()} ghost>
            Cancel
          </Button>
        </CancelEditBottomWrapper>
      )}
    </EditReportWrapper>
  );
};

export default editAdminButtom;
