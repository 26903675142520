import getFieldDefaultValue from './getDefaultFieldData';

const mapDefaultFieldValues = ({ selectedCategory = '', categories = [] }) => {
  const category = categories.find(element => element.id === selectedCategory);
  const defaultedModalFields = category
    ? category.fields.map(
        ({ id, label, type, options, defaultValue, required }) => ({
          id,
          label,
          type,
          options,
          defaultValue:
            defaultValue === 'null'
              ? null
              : getFieldDefaultValue({ defaultValue, type }),
          required,
        })
      )
    : [];

  return { defaultedModalFields };
};

export default mapDefaultFieldValues;
