import React from 'react';
import { Col, Row, Table } from 'antd';
import TableWrapper from './Table.styled';
import { get } from 'lodash';
import moment from 'moment';
import { dateFormat } from '../../../../utils/constants';

const { Column } = Table;

const ChangelogTable = ({ dataSource = [], loading, onClickRow }) => (
  <Row key={dataSource.id}>
    <Col span={24}>
      <div className="responsive-table">
        <TableWrapper
          className="white"
          dataSource={dataSource}
          loading={loading}
          pagination={false}
          rowKey={record => record.id}
        >
          <Column
            title="Creation Date"
            key="createdAt"
            dataIndex="createdAt"
            render={(_text, record) => {
              const date = get(record, 'createdAt');
              const parsedDate = moment(date).format(dateFormat);
              return <span>{parsedDate}</span>;
            }}
          />
          <Column
            title="Version"
            key="version"
            dataIndex="version"
            render={(_text, _record, index) => {
              return <span>Version {`${index + 1}`}</span>;
            }}
          />
          <Column
            title="Actions"
            key="data"
            dataIndex="data"
            className="cursor-pointer"
            render={(_text, record) => (
              <span onClick={() => onClickRow(record.changelog)}>
                See changelog
              </span>
            )}
          />
        </TableWrapper>
      </div>
    </Col>
  </Row>
);

export default ChangelogTable;
