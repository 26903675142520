import { TEMPLATE_NAME } from '../constants';

const sortString = (a, b) => {
  const strA = (a[TEMPLATE_NAME] || '').toLowerCase();
  const strB = (b[TEMPLATE_NAME] || '').toLowerCase();
  if (strA < strB) {
    return -1;
  }
  if (strA > strB) {
    return 1;
  }
  return 0;
};

export default sortString;
