import { Html5QrcodeScanner } from 'html5-qrcode';

import updateRegistrationUser from './updateUserRegistration';

let html5QrcodeScanner;

const onScanSuccess = ({
  sessionId,
  setReadyToScan,
  setScannedValue,
  setLoading,
  setRegistrationResult,
}) => (decodedText, decodedResult) => {
  setReadyToScan(false);
  setScannedValue(decodedText);
  updateRegistrationUser({
    sessionId,
    userId: decodedText,
    setLoading,
    setRegistrationResult,
  });

  html5QrcodeScanner.clear();
};

const scanQRCode = ({
  sessionId,
  setReadyToScan,
  setScannedValue,
  setLoading,
  setRegistrationResult,
}) => {
  html5QrcodeScanner = new Html5QrcodeScanner('reader', {
    fps: 10,
    qrbox: 250,
  });
  html5QrcodeScanner.render(
    onScanSuccess({
      sessionId,
      setReadyToScan,
      setScannedValue,
      setLoading,
      setRegistrationResult,
    })
  );
};

export default scanQRCode;
