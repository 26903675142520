export const reportReassignFilter = value => ({
  where: {
    or: [
      { fullName: { ilike: `%${value}%` } },
      { lastName: { ilike: `%${value}%` } },
      { employeeId: { ilike: `%${value}%` } },
      { benchmarkId: { ilike: `%${value}%` } },
      { starNumber: { ilike: `%${value}%` } },
    ],
  },
  skip: 0,
  include: [
    {
      relation: 'rank',
      scope: {
        fields: { name: true, abbreviation: true, id: true },
      },
    },
  ],
});

export const filterByIds = ids => ({
  where: { id: { inq: ids } },
  include: [
    {
      relation: 'rank',
      scope: {
        fields: { name: true, abbreviation: true, id: true },
      },
    },
  ],
});
