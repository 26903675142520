import { REMOVE_SOURCE_SELECTION } from './';

export default (selectionId = '', source = '') => (dispatch, getState) => {
  const sourceKey = source.startsWith('__') ? source : `__${source}`;

  const {
    form: {
      selectedForm: { sources: { [sourceKey]: sourceData = {} } = {} } = {},
    } = {},
  } = getState();

  const { [selectionId]: item = null } = sourceData;

  if (selectionId && item) {
    dispatch({
      type: REMOVE_SOURCE_SELECTION,
      payload: { id: item.id, sourceKey },
    });
  }
};
