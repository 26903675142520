const getColumnOptionsByColumnNumber = (number = 2) => {
  const numberOfColumns = 24 / Math.min(number, 4);

  return {
    options: {
      xs: 24,
      sm: numberOfColumns,
      md: numberOfColumns,
      lg: numberOfColumns,
    },
  };
};

export default getColumnOptionsByColumnNumber;
