import { PERMISSIONS } from '../../../../../utils/admin';

const PROTECTED_FIELDS_CREATE = ['agency'];
const PROTECTED_FIELDS_EDIT = ['agency'];
const FIELD_EMAIL = 'email';
const FIELD_EMPLOYEE_ID = 'employeeId';
const FIELD_ROLES = 'roles';
const FIELD_SSN = 'customField1';
const FIELD_HIDE_PROFILE_PICTURE = 'hideProfilePicture';

const FIELDS_SPECIAL_PERMISSIONS_SEE = {
  [FIELD_SSN]: [PERMISSIONS.viewUnMaskedSSN, PERMISSIONS.updateSSN],
  [FIELD_HIDE_PROFILE_PICTURE]: [PERMISSIONS.viewSecureProfilePicture],
};

const FIELDS_SPECIAL_PERMISSIONS = {
  [FIELD_EMAIL]: [PERMISSIONS.editEmail],
  [FIELD_EMPLOYEE_ID]: [PERMISSIONS.editEmployeeId],
  [FIELD_SSN]: [PERMISSIONS.viewUnMaskedSSN, PERMISSIONS.updateSSN],
  [FIELD_HIDE_PROFILE_PICTURE]: [PERMISSIONS.viewSecureProfilePicture],
};

const FIELDS_SPECIAL_PERMISSIONS_EDITABLE = {
  [FIELD_ROLES]: [PERMISSIONS.assignRole],
};

export {
  PROTECTED_FIELDS_CREATE,
  PROTECTED_FIELDS_EDIT,
  FIELDS_SPECIAL_PERMISSIONS_SEE,
  FIELDS_SPECIAL_PERMISSIONS,
  FIELDS_SPECIAL_PERMISSIONS_EDITABLE,
};
