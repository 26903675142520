import React from 'react';
import { Helmet } from 'react-helmet';

import ErrorScreen from '../../components/common/error-screen';

export default ({ history: { push } }) => {
  const back = () => {
    push('/');
  };

  return (
    <div style={{ textAlign: 'center', padding: 20 }}>
      <Helmet title="Not Found" />

      <ErrorScreen errorCode={404} message="" onClick={back} />
    </div>
  );
};
