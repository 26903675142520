import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import ContentLoader from 'react-content-loader';

import {
  AVATAR_BG,
  AVATAR_BORDER,
  AVATAR_COLOR,
  SIDEBAR_INACTIVE,
} from '../../config/theme';

import getInitials from '../../utils/get-initials';
import ConditionalRender from '../utils/ConditionalRender';
import ContextSwitch from './context/ContextSwitch';
import { LoadingModal } from '../loading-modal/LoadingModal';

const UserCard = ({
  collapsed = false,
  history,
  dispatch,
  logoutTrigger,
  user: {
    fetched = false,
    firstName = '',
    lastName = '',
    loading = false,
    rank: { name: rankName = ' ' } = {},
    title,
    organizationalUnitId,
    organizationalUnits,
  } = {},
}) => {
  const classes = classNames({
    'is-collapsed': collapsed,
  });

  const avatarClasses = classNames({
    'ant-avatar-lg': !collapsed,
  });

  return (
    <Wrapper className={classes}>
      <UserPanel>
        <UserAvatar className={avatarClasses}>
          <span className="ant-avatar-string">
            {!loading && fetched ? getInitials(`${firstName} ${lastName}`) : ''}
          </span>
        </UserAvatar>

        <LoadingModal />

        <ConditionalRender condition={!collapsed}>
          <div>
            <ConditionalRender condition={!loading && fetched}>
              <UserData>
                <h2 className="user-name">{`${firstName}  ${lastName}`}</h2>
                <h2 className="user-role">{title || rankName}</h2>
              </UserData>
            </ConditionalRender>

            <ConditionalRender condition={loading || !fetched}>
              <UserData>
                <ContentLoader
                  height={13}
                  width={100}
                  speed={2}
                  primaryColor="#394050"
                  secondaryColor="#556077"
                  style={{ width: 100 }}
                  className="user-name"
                >
                  <rect x="0" y="0" rx="4" ry="4" width="100" height="13" />
                </ContentLoader>
                <ContentLoader
                  height={13}
                  width={80}
                  speed={2}
                  primaryColor="#394050"
                  secondaryColor="#556077"
                  style={{ width: 80 }}
                  className="user-role"
                >
                  <rect x="0" y="0" rx="4" ry="4" width="80" height="13" />
                </ContentLoader>
              </UserData>
            </ConditionalRender>
          </div>
        </ConditionalRender>
      </UserPanel>

      <ConditionalRender condition={!collapsed}>
        <ContextSwitch
          history={history}
          dispatch={dispatch}
          organizationalUnits={organizationalUnits}
          organizationalUnitId={organizationalUnitId}
          logoutTrigger={logoutTrigger}
        />
      </ConditionalRender>
    </Wrapper>
  );
};

const Wrapper = styled.div.attrs({
  className: 'navigation-user-card--wrapper',
})`
  position: relative;
  min-height: 149px;
  ${props => (props.className.includes('is-collapsed') ? 40 : 10)}px;
`;

const UserPanel = styled.div`
  margin: 20px 10px;
`;

const UserAvatar = styled.div.attrs({
  className: 'ant-avatar ant-avatar-circle',
})`
  &.ant-avatar {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    background-color: ${AVATAR_BG};
    color: #fff;
    text-align: center;
    border: 2px solid ${AVATAR_BORDER};
    margin: 0 auto;
    line-height: 50px;

    > * {
      line-height: 50px;
    }

    .ant-avatar-string {
      position: relative;
      left: 0;
      font-size: 24px;
      font-weight: 600;
      text-transform: uppercase;
    }

    &.is-collapsed {
      width: 40px;
      height: 40px;
      line-height: 38px;

      > * {
        line-height: 28px;
      }

      .ant-avatar-string {
        font-size: 14px;
      }
    }
  }
`;

const UserData = styled.div.attrs({
  className: 'navigation-user-card--data',
})`
  padding-top: 10px;
  text-align: center;

  svg {
    display: block;
    margin: 0 auto;

    &.user-name,
    &.user-role {
      margin-top: 5px;
    }
  }

  .user-name {
    font-weight: 600;
    font-size: 18px;
    color: ${AVATAR_COLOR};
  }

  .user-role {
    font-weight: 200;
    font-size: 14px;
    font-style: italic;
    color: ${AVATAR_COLOR};
  }

  .user-name,
  .user-role,
  .user-company {
    line-height: 20px;
    margin-bottom: 0;
  }

  .user-company {
    color: ${SIDEBAR_INACTIVE};
    font-size: 12px;
    line-height: 16px;
    margin-top: 12px;
  }
`;

export default UserCard;
