import React, { Component } from 'react';

import PickableZone from '../PickableZone';

import Styled from './ColumnEditor.styled';

class ColumnEditor extends Component {
  render() {
    const { children, type, id, style, total } = this.props;
    const hidUnselectableHighlight = children[0]
      ? children[0].props.type === 'field-group'
      : false;

    return (
      <Styled span={24 / total} style={style}>
        <PickableZone
          type={type}
          id={id}
          allowSingleChildrenOnly
          hidUnselectableHighlight={hidUnselectableHighlight}
        >
          {children}
        </PickableZone>
      </Styled>
    );
  }
}

ColumnEditor.displayName = 'FieldEditor.ColumnEditor';

export default ColumnEditor;
