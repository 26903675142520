import React from 'react';
import { Row } from 'antd';

import Box from '../../../../components/Box';
import ComponentButton from './ComponentButton';

import COMPONENT_LIST from '../../constants/componentsList';
import getSafeViewHeight from '../../utils/getSafeViewHeight';
import {
  filterOnlyFields,
  filterOnlyLayout,
} from '../../utils/componentListFilters';

const Sidebar = ({ className, offsetTop }) => (
  <div className={className} style={{ height: getSafeViewHeight(offsetTop) }}>
    <Box className={`${className}__content`}>
      <h2>Fields</h2>
      <Row className="sidebar-row">
        {COMPONENT_LIST.filter(filterOnlyFields).map(component => (
          <ComponentButton
            key={`${component.type}.${component.field_type}`}
            component={component}
            icon="database"
          />
        ))}
      </Row>
      <div className="divider" />
      <h2>Elements</h2>
      <Row className="sidebar-row">
        {COMPONENT_LIST.filter(filterOnlyLayout).map(component => (
          <ComponentButton
            key={`${component.type}.${component.type}`}
            component={component}
            icon="layout"
          />
        ))}
      </Row>
    </Box>
  </div>
);

export default Sidebar;
