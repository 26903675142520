import React, { Component } from 'react';
import { omit, get } from 'lodash';
import { connect } from 'react-redux';

import { mapProperties } from 'APP_ROOT/utils/form';
import ConditionalAssert from './conditional';
import { getFormTemplate, getDataEnums } from '../../../selectors/form';
import { getDataSelector } from 'APP_ROOT/utils/renderSchema';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';
import withObjectFieldChildren from './withObjectFieldChildren';
import getDisableAllFields from './FormField/getDisableAllFields';

class ObjectFieldWithConditions extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  getRenderField = (assert = true, behavior = 'hide') => {
    const { properties = [], isReviewer = false, isDraft = false } = this.props;

    const total = properties.length;
    return (
      <div className="bdm-form-object">
        {properties.map(
          mapProperties({
            ...omit(this.props, [
              'conditions',
              'options',
              'submitDisable',
              'data',
              'dataEnums',
            ]),
            total, //passed down to check if element is the last child of current element
            isReviewer,
            isDraft,
            title: null,
            ...(!assert ? { behavior } : {}),
          })
        )}
      </div>
    );
  };

  childrenObjectField = (assert, behavior) => {
    const conditionalAssertProps = { assert, behavior };
    const ChildComponent = withObjectFieldChildren(
      Object.assign({}, this.props, conditionalAssertProps)
    );
    const props = Object.assign({}, this.props, conditionalAssertProps);

    return React.createElement(ChildComponent, props);
  };

  render() {
    const {
      conditions = {},
      data = {},
      parentIndex = 0,
      parentKey = '',
      isReviewer = false,
      disable: turnedOff = false,
      type,
      field_type,
      settings,
      properties = [],
      autoResetFieldValues = true,
    } = this.props;
    const hasParent = !!parentKey;
    const groupData = {
      ...(hasParent ? data[parentKey][parentIndex] : data),
      index: parentIndex,
      isReviewer,
    };
    const allFieldsDisabled = getDisableAllFields(this.props);

    const rules = get(conditions, 'rules');
    const hasRules = rules && Array.isArray(rules) && rules.length > 0;

    if (!hasRules) {
      return this.getRenderField(true);
    }

    return (
      <ConditionalAssert
        type={type}
        field_type={field_type}
        settings={settings}
        properties={properties}
        conditions={conditions}
        data={groupData}
        wholeData={data}
        parentKey={parentKey}
        parentIndex={parentIndex}
        disabled={turnedOff || allFieldsDisabled}
        render={this.childrenObjectField}
        autoResetFieldValues={autoResetFieldValues}
      />
    );
  }
}

const mapState = (state, props) => {
  const dataSelector = getDataSelector(props);
  const formTemplate = getFormTemplate(state, props);

  return {
    data: dataSelector(state, props),
    dataEnums: getDataEnums(state, props, formTemplate),
  };
};

export default connect(mapState)(ObjectFieldWithConditions);
