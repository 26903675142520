import { camelCase } from 'lodash';

import AgencyAuditLogs from './AgencyAuditLogs';
import { PERMISSIONS } from '../../../utils/admin';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/audit-logs`,
    name: camelCase(`${name}-audit-logs`),
    component: AgencyAuditLogs,
    protectedWith: [
      PERMISSIONS.viewAgencyAuditLogs,
      PERMISSIONS.viewAllAuditLogs,
    ],
    childRoutes: [],
  },
];
