import { get, some } from 'lodash';

export const getSharesIds = (shares = []) =>
  shares.map(({ userIntegrationId }) => userIntegrationId);

export const getSharesById = (shares = [], id) =>
  shares.find(share => share.userIntegrationId === id);

export const getSharesByMode = (shares = [], mode) =>
  shares.map(share => share.mode === mode);

export const getAgencyId = props => get(props, 'user.agencyId', '');

export const getRank = user => get(user, 'rank.name', '');

export const getFullName = user =>
  `${get(user, 'firstName', '')} ${get(user, 'lastName', '')}`;

export const getUserData = user => ({
  label: getFullName(user),
  key: user.integrationId,
  rank: getRank(user),
});

export const getShareDifferece = (initial, current) =>
  current.filter(share => !some(initial, { key: share.key }));

export const getShareList = (users, mode) =>
  users.map(u => ({ fullName: u.label, userIntegrationId: u.key, mode }));

export const getListOfNames = users => users.map(u => u.fullName);
