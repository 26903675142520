import React, { useEffect, useState } from 'react';
import FilterableColumnTable from '../../components/filterable-column-table/FilterableColumnTable';
import {
  constants,
  employeeTableDataCol,
  employeeDownloadHeader,
  getEmployeeHeaderColumnsBaseOnTenantSlug,
} from './OrganizationProfileTableColumns';
import CsvExportLink from '../../components/csv-export-link';
import getOuUsers from './actions/get-active-employees';
import { yyyymmdd } from '../../utils/date/dateUtil';
import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from 'APP_COMPONENTS/custom-sections/sections/Sections.styled';

function OrganizationProfileEmployeeTable({ orgUnitId, tenantSlug }) {
  const currentDate = yyyymmdd(new Date());
  const downloadFileName = `active_employees_${currentDate}`;
  const tableSize = 10;
  const downloadSize = 100000;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [employeeTableData, setemployeeTableData] = useState([]);
  let downloadPageCount = 0;
  let employeeDownloadData = [];

  useEffect(() => {
    getOuUsers(currentPage, tableSize, '', '', '', orgUnitId, false)
      .then(data => {
        setemployeeTableData(data.data);
        setTotalElements(data.total);
      })
      .catch(error => {
        alert('There was an error retrieving table data');
      });
  }, [currentPage, orgUnitId]);

  const getDataForExport = async (conclude, reset) => {
    await getOuUsers(downloadPageCount, downloadSize, '', '', '', orgUnitId)
      .then(data => {
        employeeDownloadData = employeeDownloadData.concat(data.data);
        if (++downloadPageCount >= data.totalPages) {
          conclude();
          downloadPageCount = 0;
          employeeDownloadData = [];
        } else {
          getDataForExport(conclude, reset);
        }
      })
      .catch(error => {
        downloadPageCount = 0;
        employeeDownloadData = [];
        alert('There was an error retrieving data for download');
        reset();
      });
  };

  const fetchTableDataCallback = (pagination, filters, sort) => {
    setCurrentPage(pagination.currentPage - 1);
  };

  const returnData = () => {
    return employeeDownloadData;
  };

  const getTableColumns = getEmployeeHeaderColumnsBaseOnTenantSlug(
    employeeTableDataCol,
    tenantSlug,
    constants.removeOnDataIndex,
    constants.certified
  );
  const getDownLoadHeader = getEmployeeHeaderColumnsBaseOnTenantSlug(
    employeeDownloadHeader,
    tenantSlug,
    constants.removeOnKey,
    constants.certified
  );

  return (
    <StyledCollapse
      bordered={false}
      defaultActiveKey=""
      expandIconPosition="right"
    >
      <StyledPanel
        header={
          <StyledPanelTitle size="large">Active Employees</StyledPanelTitle>
        }
        key="Active_Employees"
        className="section-panel"
      >
        <FilterableColumnTable
          columns={getTableColumns}
          data={employeeTableData}
          fetchDataCallback={fetchTableDataCallback}
          pageSize={tableSize}
          sorter={false}
          totalRows={totalElements}
          rowKey="fullName"
          filterToggle={false}
        />
        <CsvExportLink
          headers={getDownLoadHeader}
          filename={downloadFileName}
          useAsync
          asyncFetchData={getDataForExport}
          getProcessedData={returnData}
        />
      </StyledPanel>
    </StyledCollapse>
  );
}

export default OrganizationProfileEmployeeTable;
