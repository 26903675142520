import React from 'react';
import { notification } from 'antd';
import ContentEditable from 'react-contenteditable';

export const NOTIFICATION_SUCCESS = 'success';
export const NOTIFICATION_ERROR = 'error';

const showNotification = (type, message, description) =>
  notification[type]({
    message: <ContentEditable html={message} disabled={true} />,
    description: <ContentEditable html={description} disabled={true} />,
  });

export default showNotification;
