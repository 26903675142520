import React, { PureComponent } from 'react';
import { Layout, Button, message } from 'antd';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import forgotPassword from '../../actions/forgot-password';

import DashboardPage from '../../components/dashboard';
import Header from '../../components/layout/header';
import StyledLayout from '../../components/layout/styled-layout';
import StyledFooter from '../../components/layout/styled-footer';
import PasswordContentBlock from '../../components/password/content-block';
import StyledLayoutGuestContainer from '../../components/layout/styled-layout-guest-container';
import StyledAlert from '../../components/layout/styled-alert';

import URLSearchParams from 'url-search-params';

class ForgotPasswordConfirmationPage extends PureComponent {
  static getPageConfig() {
    return { guestAccess: true };
  }

  onResendEmailClick = () => {
    const {
      dispatch,
      history: { location },
    } = this.props;
    this.setState({ success: false });
    const qParams = new URLSearchParams(location.search);
    dispatch(
      forgotPassword(
        {
          username: qParams.get('username'),
        },
        (error, response) => {
          if (error) {
            return false;
          }
          message.success('Email has been resent');
        }
      )
    );
  };

  render() {
    const { error } = this.props;
    return (
      <Layout>
        <Helmet title="We just emailed you a link" />
        <Header />
        <StyledLayout>
          <StyledLayoutGuestContainer>
            <img
              className="logo"
              src={require('../../benchmark-logo-full.png')}
              alt="Benchmark Analytics"
            />
            {error && (
              <StyledAlert
                message="Sorry!"
                description={error.message}
                type="error"
                showIcon
              />
            )}
            <PasswordContentBlock
              title="We just emailed you a link"
              content="Please check your email and follow the instructions to reset your
                       password."
            />
            <StyledConfirmationBlock>
              <Button
                type="primary"
                ghost
                size="default"
                onClick={this.onResendEmailClick}
              >
                <span>Resend Email</span>
              </Button>{' '}
              <Link
                className="ant-btn ant-btn-primary ant-btn-lg ant-btn-background-ghost"
                to="/forgot-password"
              >
                <span>Try Another Username</span>
              </Link>{' '}
            </StyledConfirmationBlock>
          </StyledLayoutGuestContainer>
        </StyledLayout>
        <StyledFooter />
      </Layout>
    );
  }
}

const StyledConfirmationBlock = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-between;

  .ant-btn {
    display: inline-block;
    line-height: 30px;
    padding: 0 40px;
  }
`;

export default DashboardPage(
  ({
    forgotPassword: {
      username: { value, error },
    },
  }) => {
    return {
      value,
      error,
    };
  },
  null
)(ForgotPasswordConfirmationPage);
