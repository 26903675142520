import { has, isEmpty } from 'lodash';

import { SOURCE_FIELD, MASKED } from 'APP_ROOT/constants/fieldTypes';

import FieldCheckbox from '../../components/FieldCheckbox/FieldCheckbox';
import {
  CALCULATED_FIELD_NAME,
  CALCULATED_DEFAULT_VALUE,
} from '../../constants/calculated';
import {
  CASE_NUMBER,
  CASE_NUMBER_LABEL,
  SEARCHABLE_BY,
  SOURCE_FIELD_DEFAULT_VALUE,
} from '../../constants/constants';
import { MASK_TYPE, REGEX_TYPE } from '../../constants/fieldMasked';

export default class {
  constructor() {
    this.name = CASE_NUMBER;
    this.label = CASE_NUMBER_LABEL;
    this.component = FieldCheckbox;
    this.defaultValue = false;
  }

  onChange = (value, values) => {
    const { readOnly } = values;
    let otherValues = {};
    if (value && has(values, CALCULATED_FIELD_NAME)) {
      otherValues[CALCULATED_FIELD_NAME] = CALCULATED_DEFAULT_VALUE;
    }
    if (value && has(values, SOURCE_FIELD)) {
      otherValues[SOURCE_FIELD] = SOURCE_FIELD_DEFAULT_VALUE;
    }
    if (value && !isEmpty(readOnly)) {
      otherValues = {
        ...otherValues,
        readOnly: {
          ...readOnly,
          value: value ? false : readOnly.value,
        },
      };
    }
    return {
      ...values,
      [this.name]: value,
      ...otherValues,
    };
  };

  onSave = values => {
    const searchable = values[this.name];
    const { options = {} } = values;

    return {
      ...values,
      [this.name]: undefined,
      options: {
        ...options,
        [SEARCHABLE_BY]: searchable ? CASE_NUMBER : undefined,
      },
    };
  };

  getInitialValue = values => {
    if (values.options === undefined) {
      return this.defaultValue;
    }

    const { [SEARCHABLE_BY]: searchable = this.defaultValue } = values.options;

    return searchable === CASE_NUMBER;
  };

  isHidden = values => {
    const { type, [MASK_TYPE]: { option } = {} } = values;

    return type === MASKED ? option !== REGEX_TYPE : false;
  };
}
