import React, { Component, Fragment } from 'react';
import { Select, Row, Tag } from 'antd';
import { uniqWith } from 'lodash';

import RowWrapper from '../OptionsModalBody/OptionsModalBody.styled';
import componentsManager from '../../services/componentsManager';
import getAllFieldsFlat from '../../utils/getAllFieldsFlat';

const { Option, OptGroup } = Select;

export default class ShareRef extends Component {
  constructor(props) {
    super(props);

    const { currentFieldId, type } = props;
    this.state = {
      sharedRef: undefined,
      sharedKey: undefined,
      reportingKey: undefined,
    };
    this.allFields = getAllFieldsFlat(componentsManager.fields, currentFieldId);
    const { allTabs } = this.allFields;
    this.tabsByType = allTabs
      .map(tab => ({
        ...tab,
        properties: uniqWith(
          tab.properties.filter(p => p.field_type === type),
          (p1, p2) => p1.key === p2.key
        ),
      }))
      .filter(tab => tab.properties.length);
  }

  onChange = eventKey => {
    const [, key] = eventKey.split('|');
    const { updateParent } = this.props;
    const { fieldsByKey } = this.allFields;
    const sharedKey = key === 'none' ? undefined : key;
    const sharedRef = sharedKey ? fieldsByKey[sharedKey].enumRef : undefined;
    const reportingKey = sharedKey
      ? fieldsByKey[sharedKey].reportingKey
      : undefined;
    this.setState({ sharedRef, sharedKey, reportingKey });
    updateParent && updateParent({ sharedRef, sharedKey });
  };

  render() {
    const { currentFieldKey, currentFieldRef, reportingKey } = this.props;
    const {
      sharedKey,
      sharedRef,
      reportingKey: sharedReportingKey,
    } = this.state;
    return (
      <RowWrapper className="share">
        <Row>
          Share Reference key only if you know what you are doing.{' '}
          <strong>Once applied it cannot be undone.</strong>
        </Row>
        <Row>
          Current reference will be replaced with <em>Share with</em> reference.
        </Row>
        <Row>
          <span>Current field key &nbsp;</span>
          <Tag>{currentFieldKey}</Tag>
        </Row>
        <Row>
          <span>Current field ref &nbsp;</span>
          <Tag>{currentFieldRef}</Tag>
        </Row>
        <Row>
          <span>Current field reportingKey &nbsp;</span>
          <Tag>{reportingKey.value}</Tag>
        </Row>
        <Row>
          <span>Share with </span>
          <Select
            defaultValue="none"
            className="field-list"
            onChange={this.onChange}
          >
            <Option key="none">None</Option>
            {this.tabsByType.map(tab => (
              <OptGroup label={tab.title} key={`share-${tab.key}`}>
                {tab.properties.map(prop => (
                  <Option
                    value={`${prop.id}|${prop.key}`}
                    key={`share-${prop.id}-${prop.key}`}
                    title={prop.reportingKey}
                  >
                    {prop.title ? (
                      `${prop.title}`
                    ) : (
                      <Fragment>
                        <span style={{ color: 'darkgrey', fontSize: '10px' }}>
                          Reporting Key{' '}
                        </span>{' '}
                        {`${prop.reportingKey}`}
                      </Fragment>
                    )}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Row>
        <Row>
          <span>Selected key:</span>
          <Tag>{`${sharedKey ? sharedKey : 'None'}`}</Tag>
        </Row>
        <Row>
          <span>Selected ref:</span>
          <Tag>{`${sharedRef ? sharedRef : 'None'}`}</Tag>
        </Row>
        <Row>
          <span>Selected reportingKey:</span>
          <Tag>{`${sharedReportingKey ? sharedReportingKey : 'None'}`}</Tag>
        </Row>
        <Row>
          When you share a reference key you are sharing part of the field
          definition, data and validations, for instance, in a Select you will
          share all the select options, then if you change the options in one of
          the fields, the other field will also have the change
        </Row>
      </RowWrapper>
    );
  }
}
