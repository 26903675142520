import React, { Fragment } from 'react';
import {
  Input as InputField,
  Select,
  InputNumber,
  DatePicker,
  TimePicker,
} from 'antd';
import { Field } from 'redux-form';
import {
  isEmpty,
  isPlainObject,
  kebabCase,
  get,
  isString,
  union,
} from 'lodash';
import moment from 'moment-timezone';

import Input from '../input/Input';
import Switch from '../../form-viewer/styled/input-switch';
import MultipleSelect from '../input/MultipleSelect';
import EllipsisInput from '../input/EllipsisInput';
import {
  momentWithTZ,
  formatDate,
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_TIME_FORMAT,
  BENCHMARK_DATE_TIME_FORMAT,
} from '../../../utils/parse-date';

import {
  ATTRIBUTE_TYPE_STRING,
  ATTRIBUTE_TYPE_TEXTAREA,
  ATTRIBUTE_TYPE_DATE,
  ATTRIBUTE_TYPE_DATETIME,
  ATTRIBUTE_TYPE_TIME,
  ATTRIBUTE_TYPE_NUMBER,
  ATTRIBUTE_TYPE_SWITCH,
} from './constants';

const TextField = Input(EllipsisInput);
const NumberField = Input(InputNumber);
const DateField = Input(DatePicker);
const TimeField = Input(TimePicker);
const SwitchField = Input(Switch);
const SelectField = Input(Select);
const TextAreaField = Input(InputField.TextArea);
// const PhoneNumberField = Input(PhoneNumberInput);

const Option = Select.Option;
const commonFieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
  inputCol: 'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
};
const MAX_LENGTH = 40;
const TEXT_MAX_LENGTH = 1000;

const noLabelFieldOptions = {
  labelCol: 'ant-col-24',
  inputCol: 'ant-col-24',
};

const disabledDate = current => {
  // Can not select future dates
  return current && current.valueOf() >= Date.now();
};

const dateProps = {
  format: BENCHMARK_DATE_FORMAT,
  disabledDate: disabledDate,
  style: { width: '100%' },
};
const timeProps = {
  format: BENCHMARK_TIME_FORMAT,
  style: { width: '100%' },
};
const datetimeProps = {
  ...dateProps,
  ...timeProps,
  format: BENCHMARK_DATE_TIME_FORMAT,
  showTime: {
    format: BENCHMARK_TIME_FORMAT,
    defaultValue: moment('00:00', BENCHMARK_TIME_FORMAT),
  },
};
const selectProps = {
  style: { width: '100%' },
};

const coverToNumber = value => (!isNaN(value) ? +value : value);
const numberProps = {
  min: 0,
};

const convertToBoolean = value => (isString(value) ? value === 'true' : value);
const textareaProps = {
  rows: 4,
  style: { resize: 'none' },
};

const SectionAttribute = props => {
  const {
    profileForm,
    timezone = 'UTC',
    name,
    title,
    type,
    disabledValues = [],
    validValues = null,
    unique = false,
    isArray = false,
    readOnly = false,
    disabled = false,
    onChange = () => {},
    showSearch = false,
    'data-test': dataTest,
  } = props;

  const textProps = {
    readOnly: readOnly,
    ellipsislength: MAX_LENGTH,
    maxLength: TEXT_MAX_LENGTH,
  };

  const fieldOptions = isEmpty(title)
    ? noLabelFieldOptions
    : commonFieldOptions;

  let takenValues = [];
  if (unique && validValues) {
    // we need to remove the index reference in case it has it
    // from 'section.attribute[1]' to 'section.attribute' in order
    // to get all the values already used
    let splitedName = name.split('.');
    const [val] = splitedName.splice(-1, 1);
    const plainName = [
      'values',
      ...splitedName,
      val.replace(/\[[^\][]*\]/, ''),
    ];
    takenValues = get(profileForm, plainName.join('.'), []);
  }

  takenValues = union(takenValues, disabledValues);

  const plainSelectOptions =
    validValues &&
    validValues.map(val =>
      isPlainObject(val) ? val : { label: val, value: val, ...val }
    );

  const selectOptions =
    plainSelectOptions &&
    plainSelectOptions.map(({ label, value, disabled = false }) => (
      <Option
        key={kebabCase(value)}
        value={value}
        disabled={takenValues.includes(value) || disabled}
      >
        {label}
      </Option>
    ));

  const getSelectConfig = () => {
    if (!showSearch) {
      return selectProps;
    }

    return {
      ...selectProps,
      showSearch: true,
      filterOption: (input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    };
  };

  switch (type) {
    case ATTRIBUTE_TYPE_DATE:
      return (
        <Field
          type="date"
          name={name}
          data-test={dataTest || 'section-attribute-date'}
          label={title}
          placeholder="Please Select"
          component={DateField}
          inputProps={dateProps}
          options={fieldOptions}
          format={value => value && formatDate(value, timezone)}
          disabled={disabled}
          plainText={readOnly}
          onChange={onChange}
        />
      );

    case ATTRIBUTE_TYPE_DATETIME:
      return (
        <Field
          type="datetime"
          mode="date"
          name={name}
          data-test={dataTest || 'section-attribute-datetime'}
          label={title}
          placeholder="Please Select"
          component={DateField}
          inputProps={datetimeProps}
          options={fieldOptions}
          format={value =>
            value &&
            momentWithTZ(value, timezone, BENCHMARK_DATE_TIME_FORMAT, false)
          }
          normalize={value =>
            value &&
            momentWithTZ(value, timezone, BENCHMARK_DATE_TIME_FORMAT, false)
          }
          disabled={disabled}
          plainText={readOnly}
        />
      );

    case ATTRIBUTE_TYPE_TIME:
      return (
        <Field
          type="time"
          name={name}
          data-test={dataTest || 'section-attribute-time'}
          label={title}
          placeholder="Please Select"
          component={TimeField}
          inputProps={timeProps}
          options={fieldOptions}
          format={value =>
            value && momentWithTZ(value, timezone, BENCHMARK_TIME_FORMAT, false)
          }
          disabled={disabled}
          plainText={readOnly}
        />
      );

    case ATTRIBUTE_TYPE_NUMBER:
      return (
        <Field
          type="number"
          name={name}
          data-test={dataTest || 'section-attribute-number'}
          label={title}
          component={NumberField}
          options={fieldOptions}
          inputProps={numberProps}
          normalize={coverToNumber}
          disabled={disabled}
          plainText={readOnly}
        />
      );

    case ATTRIBUTE_TYPE_SWITCH:
      return (
        <Field
          type="boolean"
          name={name}
          data-test={dataTest || 'section-attribute-switch'}
          label={title}
          component={SwitchField}
          inputProps={{
            checked: get(profileForm, `values.${name}`, false),
            size: 'large',
            checkedChildren: 'Yes',
            unCheckedChildren: 'No',
          }}
          normalize={convertToBoolean}
          options={fieldOptions}
          disabled={disabled}
          plainText={readOnly}
        />
      );

    case ATTRIBUTE_TYPE_TEXTAREA:
      return (
        <Field
          type="text"
          name={name}
          data-test={dataTest || 'section-attribute-textarea'}
          label={title}
          component={TextAreaField}
          options={fieldOptions}
          inputProps={textareaProps}
          disabled={disabled}
          plainText={readOnly}
        />
      );

    case ATTRIBUTE_TYPE_STRING:
      const selectInputConfig = getSelectConfig();
      if (selectOptions) {
        return isArray ? (
          <Field
            type="select"
            mode="multiple"
            name={name}
            data-test={dataTest || 'section-attribute-multiselect'}
            label={title}
            component={MultipleSelect}
            options={fieldOptions}
            dropdownOptions={selectOptions}
            plainDropdownOptions={plainSelectOptions}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            disabled={disabled}
            plainText={readOnly}
          />
        ) : (
          <Fragment>
            <Field
              type="select"
              name={name}
              data-test={dataTest || 'section-attribute-select'}
              label={title}
              inputProps={selectInputConfig}
              component={SelectField}
              options={fieldOptions}
              dropdownOptions={selectOptions}
              plainDropdownOptions={plainSelectOptions}
              disabled={disabled}
              plainText={readOnly}
              optionFilterProp="children"
            />
          </Fragment>
        );
      }
      return (
        <Field
          type="text"
          name={name}
          data-test={dataTest || 'section-attribute-string'}
          label={title}
          component={TextField}
          options={fieldOptions}
          disabled={disabled}
          readOnly={readOnly}
          inputProps={textProps}
        />
      );

    default:
      return (
        <Field
          type="text"
          name={name}
          data-test={dataTest || 'section-attribute-string'}
          label={title}
          component={TextField}
          options={fieldOptions}
          disabled={disabled}
          readOnly={readOnly}
          inputProps={textProps}
        />
      );
  }
};

export default SectionAttribute;
