import { get, isPlainObject, omit, has, castArray } from 'lodash';

const objectFilters = (filters, data) =>
  Object.keys(filters).reduce((acc, item) => {
    if (item === '$inject') {
      let key = filters['$inject'];
      let value = get(data, key);
      return { ...acc, [key]: value };
    }
    return {
      ...acc,
      [item]: filters[item],
    };
  }, {});

const arrayFilters = (filters, data) =>
  filters.reduce((acc, item) => {
    if (has(item, '$inject')) {
      let key = item['$inject'];
      let value = get(data, key);
      return { ...acc, [key]: value };
    }
    return { ...acc, ...item };
  }, {});

const parseInjectFilters = (props, filters) => {
  const { data = {} } = props;
  let parsedFilters;
  if (isPlainObject(filters)) {
    parsedFilters = objectFilters(filters, data);
  } else if (Array.isArray(filters)) {
    parsedFilters = arrayFilters(filters, data);
  } else {
    parsedFilters = {};
  }
  return parsedFilters;
};

// Return Options
const normalizeFilter = (props, options, combinedEnumRef, agencyId = '') => {
  const filter = get(options, 'filter', {});
  let where = get(filter, 'where', {});
  const hasAnd = has(where, 'and');
  const hasOr = has(where, 'or');
  const hasCombinedEnumRef = !!combinedEnumRef;

  let output = {
    ...options,
    filter: {
      ...parseInjectFilters(props, filter),
      where: {
        and: [parseInjectFilters(props, get(where, 'and')), { agencyId }],
      },
    },
  };
  if (hasAnd && hasOr) {
    const andFilter = get(where, 'and', []);
    const orFilter = get(where, 'or', []);

    output = {
      ...options,
      filter: {
        ...parseInjectFilters(props, filter),
        where: {
          ...omit(where, 'or'),
          and: [...andFilter, { agencyId }, { or: orFilter }],
        },
      },
    };
  }

  if (!hasAnd && !hasOr) {
    output = {
      ...options,
      filter: {
        ...filter,
        where: {
          and: castArray(where),
        },
      },
    };

    where = get(output, 'filter.where', {});
  }

  if (hasCombinedEnumRef) {
    const whereWithNotNullIds = Object.keys(where)
      .filter(whereKey => Array.isArray(get(where, whereKey)))
      .reduce(
        (result, whereKey) => ({
          ...result,
          [whereKey]: get(where, whereKey, []).reduce(
            (conditions, condition) => {
              if (has(condition, 'id')) {
                return conditions;
              }

              return [...conditions, condition];
            },
            []
          ),
        }),
        {}
      );

    output = {
      ...options,
      filter: {
        ...filter,
        where: whereWithNotNullIds,
      },
    };
  }
  return output;
};

export default normalizeFilter;
