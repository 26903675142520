import { last } from 'lodash';

const removeMathOperand = (fieldKey = '', mathFields = []) =>
  mathFields.map(field => {
    const { options = {} } = field;
    const { operands = [] } = options;
    // split in case key is like 'data.key' || 'repeater.key'
    const key = last(fieldKey.split('.'));
    return {
      ...field,
      options: {
        ...options,
        operands: operands.filter(o => last(o.split('.')) !== key),
      },
    };
  });

export default removeMathOperand;
