import React from 'react';
import { Form, Row, Col } from 'antd';
import { get } from 'lodash';
import { unescape as htmlUnescape } from 'html-escaper';

import { StyledCol, StyledCloseButton } from '../styled';
import AttachmentNarrative from '../narrative-field';
import FILE_STATUSES from '../file-statuses.constants';
import ownerFileOrProgress from './ownerFileOrProgress';

const [, ERROR] = FILE_STATUSES;

const renderFileNameOrProgress = ({ props, setState, attachment }) => {
  const OwnerFileNameOrProgress = ownerFileOrProgress;
  return (
    <StyledCol span={8}>
      <OwnerFileNameOrProgress
        props={props}
        setState={setState}
        attachment={attachment}
      />
    </StyledCol>
  );
};

const renderRemoveFile = ({ onRemoveFile, attachment }) => {
  return (
    <StyledCloseButton
      shape="circle"
      icon="close"
      onClick={onRemoveFile}
      className={`${attachment.status === ERROR ? ERROR : ''} hide-print`}
    />
  );
};

const renderAttachmentAsOwner = ({
  props,
  setState,
  attachment,
  fieldDataKey,
  fieldDataOriginalKey,
  notesRules,
  allowRemove,
  onRemoveFile,
  onTextFieldChange,
}) => {
  const {
    form: { getFieldDecorator },
    validationState: { form: { submitRequested } = {} } = {},
    data,
  } = props;
  const wrappedNotesRules = Array.isArray(notesRules)
    ? notesRules
    : [notesRules];
  const validateTrigger = submitRequested ? 'onBlur' : 'onSubmit';
  const initialValue = htmlUnescape(
    get(data, fieldDataKey, get(data, fieldDataOriginalKey, ''))
  );
  return (
    <Row gutter={8} key={attachment.id}>
      {renderFileNameOrProgress({ props, setState, attachment })}
      <Col span={14}>
        <Form.Item>
          {getFieldDecorator(fieldDataKey, {
            rules: wrappedNotesRules,
            validateTrigger,
            initialValue,
            trigger: 'onBlur',
          })(
            <AttachmentNarrative
              attachment={attachment}
              onTextFieldChange={onTextFieldChange}
              dataKey={fieldDataKey}
            />
          )}
        </Form.Item>
      </Col>
      <Col span={2} className="text-right">
        {allowRemove && renderRemoveFile({ onRemoveFile, attachment })}
      </Col>
    </Row>
  );
};

export default renderAttachmentAsOwner;
