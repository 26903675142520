import React, { useCallback, useState } from 'react';
import validateFormModal from './utils/validateFormModal';
import encodeEquipmentData from './utils/encodeEquipmentData';
import updateUserEquipment from '../../../agency-equipment/actions/update-user-equipment';

const SUCCESS_MESSAGE = 'Equipment successfully updated.';
const ERROR_MESSAGE = 'Error updating equipment.';

const useUserEquipmentUpdate = ({
  equipmentModalFields,
  tenantId,
  equipment,
  onSave,
  dispatch,
  equipmentRecord,
}) => {
  const [validationMessages, setValidationMessages] = useState({});
  const [isValid, setIsValid] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const onSaveEquipment = () => {
    setIsProcessing(true);
    const { isValid, validationMessages } = validateFormModal({
      equipment,
      equipmentModalFields,
    });
    setIsValid(isValid);
    setValidationMessages(validationMessages);

    if (!isValid) {
      setIsProcessing(false);
      return;
    }

    dispatch(
      updateUserEquipment({
        tenantId,
        equipmentId: equipmentRecord.equipment.id,
        body: equipment.map(({ fieldId, type, fieldValueId, value }) => ({
          fieldId,
          fieldValueId,
          value: encodeEquipmentData(type, value),
        })),
        next: callback,
      })
    );
  };

  const callback = useCallback((error, data) => {
    onSave({ success: SUCCESS_MESSAGE, error: ERROR_MESSAGE }, error, data),
      [onSave];
    setIsProcessing(false);
  });

  return { onSaveEquipment, isValid, isProcessing, validationMessages };
};

export default useUserEquipmentUpdate;
