import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon, Button, Spin, Cascader } from 'antd';
import {
  set,
  get,
  isArray,
  isEmpty,
  uniqBy,
  debounce,
  findIndex,
} from 'lodash';
import URLSearchParams from 'url-search-params';
import trap from 'mousetrap';
import has from 'lodash/has';

import FormViewer from '../../components/form-viewer';
import ModalTitle from '../../components/common/modal/title';
import ModalBody from '../../components/common/modal/body';
import FormHistoryTimeline from '../../components/form-history-timeline';
import createModal from '../../actions/create-modal';
import showModal from '../../actions/show-modal';
import hideModal from '../../actions/hide-modal';
import FORM_TYPES from '../../constants/form-types';
import postDraftReport from '../../actions/post-draft-report';
import updateDraftReport from '../../actions/update-draft-report';
import getFormActions from '../../actions/get-form-actions';
import postReport from '../../actions/post-report';
import deleteReport from '../../actions/delete-report';
import syncFormValidation from '../../actions/sync-form-validations';
import deleteAllAtachments from '../../actions/delete-all-attachments';
import addUserReportSuccess from '../../actions/add-user-report-success';
import withModal from '../../components/common/modal/base';

import flattenErrors from '../../utils/flatten-errors';
import {
  getDataFromConditions,
  transformParticipants,
  getParticipant,
} from '../../utils/form';
import route from '../../utils/get-route';
import transformFormToNormalizedTimeline from '../../utils/transform-form-to-normalized-timeline';
import getNormalizedReviewer from '../../utils/get-reviewer-cascader';
import groupReviewers from '../../utils/group-reviewers';

import ParentSummary from '../../components/form/parent-summary';

import { REQUEST_MORE_INFO } from '../../constants/submit-actions';

import { caseSensitive } from 'APP_ROOT/utils/filters';
import { hasPermissions, PERMISSIONS } from '../../utils/admin';
import generateReportPrintWithAttachments from '../report-type/report-container/generateReportPrintWithAttachments';

import {
  LABEL_DELETE_REPORT_CONTENT,
  LABEL_DELETE_REPORT_TITLE,
} from '../container-labels';

// eslint-disable-next-line no-console
const log = type => console.log.bind(console, type);

const deleteReportConfirmModalID = 'investigation-delete-request';

const deleteConfirmationModalOptions = {
  title: (
    <ModalTitle warning>
      <Icon type="exclamation-circle" />
      <span> {LABEL_DELETE_REPORT_TITLE} </span>
    </ModalTitle>
  ),
  text: (
    <ModalBody>
      <p> {LABEL_DELETE_REPORT_CONTENT} </p>
    </ModalBody>
  ),
  /**
   * @param {function} onCancelButtonClick
   * @param {function} onConfirmButtonClick
   * @return {function[]}
   */
  buildFooter: (onCancelButtonClick, onConfirmButtonClick) => [
    <Button key="delete-report-button-cancel" onClick={onCancelButtonClick}>
      Cancel
    </Button>,
    <Button
      key="delete-report-button-accept"
      type="primary"
      onClick={onConfirmButtonClick}
    >
      Delete
    </Button>,
  ],
};

class InvestigationForm extends withModal(Component) {
  static getPageConfig() {
    return {
      title: 'Investigation',
    };
  }

  state = {
    shouldValidate: true,
    selectedReviewer: null,
  };

  whiteListKeys = [
    '__users',
    'currentUserId',
    'submitterId',
    '__reports',
    '__formParent',
    '__assignedSections',
    '__assignedSectionsHistory',
  ];

  printTriggered = false;
  hasAttachments = false;
  hasNotes = false;

  isDraft() {
    const {
      selectedForm: { meta },
    } = this.props;
    return !!meta.draftDate;
  }

  isPersisted() {
    const {
      selectedForm: { meta },
    } = this.props;
    return !!meta.id;
  }

  isPrinting = () => this.props.isPrinting;

  componentWillMount() {
    const { location } = this.props;

    const shouldValidateFromQuery = () => {
      const query = new URLSearchParams(location.search);
      const validate = query.get('validate');
      return validate !== null ? JSON.parse(validate) : true;
    };

    this.setState({ shouldValidate: shouldValidateFromQuery() });

    this.createModal();
    trap.bind(...this.onPrintEvent());
  }

  componentDidMount() {
    const { selectedForm } = this.props;

    const isPrinting = this.isPrinting();

    if (isPrinting) {
      const template = get(selectedForm, 'template.formSchema.form', {});

      const hasAttachments = obj => {
        const cb = o => o.type === 'upload';

        const asserts = cb(obj);

        if (asserts) {
          return true;
        }

        if (has(obj, 'properties')) {
          return obj.properties.reduce((res, cur) => {
            if (res) {
              return res;
            }

            return hasAttachments(cur);
          }, null);
        }

        return null;
      };

      const hasReviewNotes =
        has(selectedForm, 'meta.notes') &&
        get(selectedForm, 'meta.notes', []).length;
      const hasFormAttachments = hasAttachments(template);

      if (hasFormAttachments || hasReviewNotes) {
        this.hasAttachments = hasFormAttachments;
        this.hasNotes = hasReviewNotes;
      } else {
        this.printTriggered = true;
        window.print();
      }
    }
  }

  componentWillUnmount() {
    this.hideModal();
    this.deleteModal();
    trap.reset();
  }

  printWithAttachments = generateReportPrintWithAttachments(this);

  onPrintEvent = () => {
    const { selectedForm = {} } = this.props;
    const { meta: { id = '', workFlowData = {} } = {} } = selectedForm;

    return [
      ['command+p', 'ctrl+p'],
      e => {
        if (!this.isPrinting() && id && workFlowData.state !== 'initial') {
          e.preventDefault();

          if (this.props.canPrintWithAttachments === true) {
            this.printWithAttachments();
          } else {
            window.open(this.getPrintLink());
          }
          return false;
        }
      },
      'keydown',
    ];
  };

  saveDraft = (cb = () => {}) => {
    const {
      dispatch,
      selectedForm: { meta = {}, data, formFields },
    } = this.props;
    const allFields = formFields.fields.reduce(
      (acc, tab) => ({ ...acc, ...tab }),
      {}
    );
    const transformedData = getDataFromConditions(
      data,
      allFields,
      this.whiteListKeys
    );
    if (meta.id) {
      dispatch(
        updateDraftReport(
          {
            id: meta.id,
            data: { ...meta, data: transformedData },
          },
          cb
        )
      );
    } else {
      dispatch(postDraftReport(transformedData, cb));
    }
  };

  setReviewer = value => {
    const {
      form: { actions },
    } = this.props;
    const allReviewers = uniqBy(
      actions.reduce((res, action) => [...res, ...action.reviewer.list], []),
      'id'
    );
    let reviewerId = isArray(value) ? value[0] : value;
    if (actions.length) {
      this.setState({
        selectedReviewer: allReviewers.find(({ id }) => id + [] === reviewerId),
      });
    }
  };

  onReview = (selectedResolution = {}, selectedReviewer = {}, notes) => {
    const {
      dispatch,
      history: { push },
      selectedForm: { meta },
    } = this.props;

    const { id, agencyId } = meta;
    const { label: actionLabel = '', id: action = '' } = selectedResolution;
    const { id: reviewer } = selectedReviewer;

    dispatch(
      postReport({ agencyId, id, reviewer, action, notes }, (error, report) => {
        if (error) {
          this.showErrorConfirmation();
          return false;
        }

        const { activeReviewerId = '', participants = [] } = report;

        const transformedParticipants = transformParticipants(participants);
        const activeReviewer =
          getParticipant(activeReviewerId, transformedParticipants) || {};
        const {
          rank: { name: activeReviewerRank = '' } = {},
          fullName: activeReviewerfullName = '',
        } = activeReviewer;

        const Title = (
          <ModalTitle success>
            <Icon type="check-circle" /> <span>Success!</span>
          </ModalTitle>
        );

        const textResolution = activeReviewerId
          ? `has been sent to ${activeReviewerRank} ${activeReviewerfullName}`
          : `the report is now closed`;

        const TextSubmit = (
          <ModalBody>
            <p>
              You have reviewed this report as {`${actionLabel}`} and{' '}
              {`${textResolution}`}
            </p>
          </ModalBody>
        );

        const TextRequest = (
          <ModalBody>
            <p>
              You have sent this report back to{' '}
              {`${activeReviewerRank} ${activeReviewerfullName}`} for more
              information
            </p>
          </ModalBody>
        );

        const options = {
          id: 'iai-success',
          title: Title,
          children: action.includes(REQUEST_MORE_INFO)
            ? TextRequest
            : TextSubmit,
        };

        dispatch(createModal(options));

        setTimeout(() => {
          push('/reports');
          dispatch(showModal(options.id));
        }, 500);
      })
    );
  };

  setErrorState = async errors => {
    const { dispatch } = this.props;
    const errs = Object.keys(errors).reduce(
      (acc, k) => set(acc, k, { errors: errors[k] }),
      {}
    );
    const flattenedErrors = flattenErrors(errs);
    dispatch(syncFormValidation(flattenedErrors));
  };

  showErrorConfirmation = (message = '') => {
    const ErrorTitle = (
      <ModalTitle error>
        <Icon type="exclamation-circle" /> <span>Heads up!</span>
      </ModalTitle>
    );

    const ErrorText = (
      <ModalBody>
        {message ||
          `Some of the required fields are empty or were not answered correctly.
        Please double-check and submit again.`}
      </ModalBody>
    );

    this.createModal({
      visible: true,
      title: ErrorTitle,
      children: ErrorText,
    });

    this.showModal();
  };

  onSave = debounce(() => {
    const {
      dispatch,
      history: { push },
      selectedForm: {
        meta: { agencyId, id },
        data,
        formFields,
      },
      form: { actions = [] },
    } = this.props;
    const { selectedReviewer } = this.state;
    const { id: reviewer, firstName, lastName } = selectedReviewer;

    dispatch(addUserReportSuccess(selectedReviewer));
    this.updateModal({
      footer: this.modalButtons({ isLoading: true }),
    });
    const users = {
      ...get(data, '__users', {}),
      [selectedReviewer.id]: {
        ...selectedReviewer,
      },
    };

    const finalData = {
      ...data,
      __users: users,
    };

    const allFields = formFields.fields.reduce(
      (acc, tab) => ({ ...acc, ...tab }),
      {}
    );
    const transformedData = getDataFromConditions(
      {
        ...finalData,
        isReviewer: !this.isEditable,
      },
      allFields,
      this.whiteListKeys
    );

    const action = actions.reduce((res, _action) => {
      if (_action.reviewer.list.includes(selectedReviewer)) {
        return _action.id;
      }
      return res;
    }, null);

    if (isEmpty(selectedReviewer)) {
      return;
    }

    dispatch(
      postReport(
        { agencyId, id, reviewer, action, data: transformedData },
        async (error, report) => {
          const { response } = error;
          if (error) {
            const errorJson = await response.json();
            const editedErrors = get(errorJson, 'error.errors', []);
            const serverError = get(errorJson, 'error.message', '');

            if (editedErrors.length === 0 && serverError) {
              this.hideModal();
              this.showErrorConfirmation(
                `Something went wrong. We cannot process your request right now; please try again later.`
              );

              return false;
            }

            await this.setErrorState(editedErrors);
            this.hideModal();
            this.showErrorConfirmation();
            return false;
          }

          const Title = (
            <ModalTitle success>
              <Icon type="check-circle" /> <span>Success!</span>
            </ModalTitle>
          );

          const Text = (
            <ModalBody>
              Your IA Investigation report has been submitted to{' '}
              {`${firstName} ${lastName}`} for review
            </ModalBody>
          );

          const options = {
            id: 'iai-success',
            title: Title,
            children: Text,
          };

          dispatch(createModal(options));
          dispatch(
            deleteAllAtachments({ kind: FORM_TYPES['investigation'], id })
          );

          setTimeout(() => {
            push('/reports');
            dispatch(showModal(options.id));
          }, 500);
        }
      )
    );
  }, 500);

  onCancel = () => {
    this.setState({ selectedReviewerId: '' });
    this.hideModal();
  };

  modalButtons = ({ isDisable = false, isLoading = false } = {}) => [
    <Button
      key="cancel"
      size="default"
      onClick={this.onCancel}
      disabled={isDisable}
    >
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      size="default"
      disabled={isDisable}
      loading={isLoading}
      onClick={this.onSave}
    >
      Submit
    </Button>,
  ];

  /**
   * Triggers on cancel delete confirmation modal.
   */
  onCancelDeleteConfirmationModalClick = () => {
    const { dispatch } = this.props;
    dispatch(hideModal(deleteReportConfirmModalID));
  };

  /**
   * Triggers on confirmation modal click.
   */
  onAcceptConfirmationModalClick = () => {
    const {
      dispatch,
      history: { push },
      selectedForm: { meta },
    } = this.props;
    dispatch(
      deleteReport(meta.agencyId, meta.id, () => {
        dispatch(hideModal(deleteReportConfirmModalID));
        // This timeout is used here, to prevent a flick effect when the modal
        // animation starts.
        setTimeout(() => push('/reports'), 300);
      })
    );
  };

  /**
   * On Header Toolbar Delete Button Click.
   * Shows the delete report modal.
   */
  onDeleteHeaderButtonClick = () => {
    const { dispatch } = this.props;
    const options = {
      id: deleteReportConfirmModalID,
      title: deleteConfirmationModalOptions.title,
      children: deleteConfirmationModalOptions.text,
      footer: deleteConfirmationModalOptions.buildFooter(
        this.onCancelDeleteConfirmationModalClick,
        this.onAcceptConfirmationModalClick
      ),
    };
    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  updateDraft = cb => {
    this.saveDraft((error, report) => {
      if (error) {
        this.showErrorConfirmation(
          `Something went wrong. We cannot process your request right now; please try again later.`
        );
      }

      cb && typeof cb === 'function' && cb(error, report);
    });
  };

  formButtons = () => {
    const print = {
      icon: require('../../icons/bt_print.svg'),
      title: 'print',
      onButtonClick: () => this.printWithAttachments,
    };

    const printSelf = {
      icon: require('../../icons/bt_print.svg'),
      title: 'print',
      onButtonClick: () => this.printWithAttachments,
    };

    if (this.isPrinting()) {
      return [printSelf];
    }

    return [print];
  };

  reviewFormButtons = () => {
    const history = {
      icon: require('../../icons/ic_history.svg'),
      title: 'History',
      onButtonClick: () => this.viewHistory,
    };

    const print = {
      icon: require('../../icons/bt_print.svg'),
      title: 'print',
      onButtonClick: () => this.printWithAttachments,
    };

    const printSelf = {
      icon: require('../../icons/bt_print.svg'),
      title: 'print',
      onButtonClick: () => this.printWithAttachments,
    };

    if (this.isPrinting()) {
      return [printSelf];
    }

    return [history, print];
  };

  getReviewersModalOptions = () => ({
    title: 'Select Reviewer',
    children: (
      <div className="text-center">
        <Spin />
      </div>
    ),
    footer: this.modalButtons(),
  });

  onSubmit = async values => {
    const {
      dispatch,
      history: { push },
      selectedForm: { meta, data, state, formFields },
    } = this.props;
    const { shouldValidate } = this.state;

    let currentFormSubmission = {};
    const allFields = formFields.fields.reduce(
      (acc, tab) => ({ ...acc, ...tab }),
      {}
    );
    const transformedData = getDataFromConditions(
      data,
      allFields,
      this.whiteListKeys
    );
    let serverError;

    if (meta.id) {
      Object.assign(currentFormSubmission, meta);
    } else {
      await dispatch(
        postDraftReport(transformedData, (error, result) => {
          if (!error) {
            Object.assign(currentFormSubmission, result);
          }
        })
      );
    }

    if (serverError) {
      this.showErrorConfirmation(
        `Something went wrong. We cannot process your request right now; please try again later.`
      );
      return false;
    }

    const { id, agencyId } = currentFormSubmission;

    const hasErrors = !!Object.entries(state).filter(
      ([k, v]) => v.errors && v.errors.length
    ).length;
    if (hasErrors && shouldValidate) return false;

    dispatch(
      getFormActions(
        {
          agencyId,
          formSubmissionId: id,
        },
        (error, reviewActions) => {
          const { actions = [] } = reviewActions;
          const sendingMoreInfo = actions.every(
            a => a.reviewer.list.length === 0
          );
          const allReviewers = actions.reduce(
            (res, action) => [...res, ...action.reviewer.list],
            []
          );
          const groupedReviewers = groupReviewers(uniqBy(allReviewers, 'id'));
          if (sendingMoreInfo) {
            dispatch(
              postReport(
                { agencyId, id, data: transformedData },
                async (error, report) => {
                  const { response } = error;
                  if (error) {
                    const errorJson = await response.json();
                    const editedErrors = get(
                      errorJson,
                      'error.errors',
                      get(errorJson, 'error.message', '')
                    );
                    await this.setErrorState(editedErrors);
                    this.hideModal();
                    this.showErrorConfirmation();
                    return false;
                  }

                  const { activeReviewerId, participants = [] } = report;
                  const reviewer = participants.length
                    ? participants.filter(
                        participant => participant.id === activeReviewerId
                      )
                    : participants[0];
                  const {
                    firstName: reviewerFirstName,
                    lastName: reviewerLastName,
                    rank: { name: rankName = '' } = {},
                  } = reviewer[0];

                  const Title = (
                    <ModalTitle success>
                      <Icon type="check-circle" /> <span>Success!</span>
                    </ModalTitle>
                  );

                  const Text = (
                    <ModalBody>
                      <p>
                        Your IA Investigation report has been submitted to{' '}
                        {`${rankName} ${reviewerFirstName} ${reviewerLastName}`}{' '}
                        for review.
                      </p>
                    </ModalBody>
                  );

                  const options = {
                    id: 'iai-submit-success',
                    title: Title,
                    children: Text,
                  };

                  dispatch(createModal(options));

                  setTimeout(() => {
                    push('/reports');
                    dispatch(showModal(options.id));
                  }, 500);
                }
              )
            );
          } else {
            this.updateModal({
              ...this.getReviewersModalOptions(),
              children: (
                <ReviewerWrapper>
                  <div className="reviewer-select">
                    <p>You must select one reviewer to Submit</p>
                    <Cascader
                      style={{ width: '300px', margin: '0 auto' }}
                      options={groupedReviewers}
                      onChange={(value, options) => {
                        if (value.length) {
                          this.setReviewer(
                            getNormalizedReviewer(value, options)
                          );
                          this.updateModal({
                            footer: this.modalButtons({ isDisable: false }),
                          });
                        } else {
                          this.updateModal({
                            footer: this.modalButtons({ isDisable: true }),
                          });
                        }
                      }}
                      placeholder="Please select"
                      displayRender={label => label.join(' > ')}
                      showSearch={{ filter: caseSensitive }}
                    />
                  </div>
                </ReviewerWrapper>
              ),
              footer: this.modalButtons({ isDisable: true }),
            });
            this.showModal();
          }
        }
      )
    );
  };

  viewHistory = () => {
    const { selectedForm, timezone } = this.props;
    const {
      meta: { participants: metaParticipants },
    } = selectedForm;
    let snapshotUsers = [];
    let metaCustom = {};
    JSON.stringify(get(selectedForm, 'data.__users', {}), function(key, value) {
      snapshotUsers = Object.values(value);
    });
    // The User data from the snapshot table will overwrite the current user data
    // from the benchmark user takble, only if there is id match
    // Data from cache is used prioritized
    for (const metaParticipant of metaParticipants) {
      if (findIndex(snapshotUsers, { id: metaParticipant.id }) === -1) {
        snapshotUsers.push(metaParticipant);
      }
    }
    metaCustom = {
      ...get(selectedForm, 'meta', {}),
      participants: snapshotUsers,
    };

    const normalizedRecord = transformFormToNormalizedTimeline(
      metaCustom,
      timezone,
      false
    );

    const Title = (
      <ModalTitle>
        <span>Timeline</span>
      </ModalTitle>
    );

    const Body = (
      <ModalBody>
        <FormHistoryTimeline {...normalizedRecord} />
      </ModalBody>
    );

    this.createModal({
      visible: true,
      title: Title,
      children: Body,
    });

    this.showModal();
  };

  getPrintLink = () => {
    const { selectedForm } = this.props;

    return route('reportsViewAs', {
      id: get(selectedForm, ['meta', 'id'], '404'),
      viewAs: 'print',
    });
  };

  onAttachmentsFetched = () => {
    if (!this.isPrinting()) {
      return false;
    }

    if (!this.printTriggered) {
      this.attachmentsLoaded = true;

      if ((this.hasNotes && this.reviewNotesLoaded) || !this.hasNotes) {
        this.printTriggered = true;
        window.print();
      }
    }
  };

  onNotesFetched = () => {
    if (!this.isPrinting()) {
      return false;
    }

    if (!this.printTriggered) {
      this.reviewNotesLoaded = true;

      if (
        (this.hasAttachments && this.attachmentsLoaded) ||
        !this.hasAttachments
      ) {
        this.printTriggered = true;
        window.print();
      }
    }
  };

  render() {
    const {
      selectedForm,
      dispatch,
      error,
      session: { currentUser = {} } = {},
      app,
      loading,
      form: { templates = [] } = {},
      timezone,
    } = this.props;

    const { shouldValidate } = this.state;

    const { permissions = [] } = currentUser;
    const canSubmit = hasPermissions(permissions, PERMISSIONS.submitIAIReport);

    if (error) {
      return <h3>Server error</h3>;
    }

    const { id: currentUserId = '' } = currentUser;
    const {
      meta: { activeReviewerId = '', id = '', isEditMode = true } = {},
    } = selectedForm;

    const isEditable = isEditMode;

    const canReview = !isEditMode && currentUserId === activeReviewerId;

    const isPrinting = this.isPrinting();

    const parentSummary = isEditable ? <ParentSummary /> : null;

    const formViewerProps = {
      app,
      selectedForm,
      dispatch,
      user: currentUser,
      canSubmit,
      shouldValidate,
      onError: log('errors'),
      onSaveDraft: this.updateDraft,
      parentSummary,
      showLinkedFormSummary: true,
      loading,
      timezone,
      isPrinting,
      onAttachmentsFetched: this.onAttachmentsFetched,
      onNotesFetched: this.onNotesFetched,
      ...(id
        ? {
            isReviewer: !isEditable,
            canReview,
            actions: !isEditable
              ? this.reviewFormButtons()
              : this.formButtons(),
            onSubmit: isEditable ? this.onSubmit : this.onReview,
          }
        : {
            actions: this.formButtons(),
            onSubmit: this.onSubmit,
          }),
      ...(isPrinting
        ? {
            canSubmit: false,
            canReview: false,
            isReviewer: true,
            onSubmit: () => {},
          }
        : {}),
      templates,
    };

    return (
      <PageWrapper>
        <FormViewer {...formViewerProps} />
      </PageWrapper>
    );
  }
}

const PageWrapper = styled.div`
  position: inherit;
`;

const ReviewerWrapper = styled.div`
  padding: 10px 0;
  width: 400px;
  margin: 0 auto;

  .reviewer-select {
    width: 300px;
    margin: 0 auto;

    p {
      margin-bottom: 15px;
    }
  }
`;

export default InvestigationForm;
