import { kebabCase, get, find } from 'lodash';

import { GET_FORM } from './';
import onGetFormError from './utils/get-form/onGetFormError';
import retrieveAgencyTemplates from './utils/get-form/retrieveAgencyTemplates';
import getTemplateAtVersionBeforeCreateReport from './utils/get-form/getTemplateAtVersionBeforeCreateReport';
import createNewForm from './utils/get-form/createNewForm';
import getFormTemplatesSuccess from './get-form-templates-success';

const getFormWith = (
  {
    templateType,
    templateId,
    agencyId: agencyIdFromOptions,
    useCache = true,
    templateVersionId,
  },
  next
) =>
  async function withChunk(dispatch, getState) {
    const { form, session } = getState();
    const currentUser = get(session, 'currentUser');
    const agencyId = agencyIdFromOptions || get(currentUser, 'agencyId');
    const { templatesMeta: { fetched = false } = {} } = form;

    const searchByTemplateId = !!templateId;

    const type = !searchByTemplateId ? kebabCase(templateType) : null;

    dispatch({
      type: GET_FORM,
      payload: { templateId, templateType },
    });

    const filter = {
      where: {
        type: !searchByTemplateId ? templateType : undefined,
        id: searchByTemplateId
          ? {
              inq: [templateId],
            }
          : undefined,
      },
    };
    const [errors, templates] = await retrieveAgencyTemplates({
      fallback: Object.values(get(form, 'templates', {})),
      filter,
      dispatch,
      useCache,
      shouldFetch: ({ dictionaryById, dictionaryByType }) =>
        !fetched ||
        !(searchByTemplateId
          ? dictionaryById[templateId]
          : dictionaryByType[type]),
      agencyId,
    });

    if (errors) {
      return onGetFormError({
        errors,
        templateType,
        templateId,
        dispatch,
        next,
      });
    }

    if (templates.length === 0) {
      return onGetFormError({
        dispatch,
        errors: 404,
        template: { templateType, templateId },
        next,
      });
    }

    const templateMetadata = find(templates, item =>
      searchByTemplateId ? item.id === templateId : item.type === type
    );

    if (!templateMetadata) {
      return onGetFormError({
        dispatch,
        errors: 404,
        template: { templateType, templateId },
        next,
      });
    }

    let newFormMeta = {
      next,
      dispatch,
      currentUser,
      templateType: templateMetadata.type,
      template: templateMetadata,
    };

    if (templateVersionId !== templateMetadata.versionId) {
      const [error, template] = await getTemplateAtVersionBeforeCreateReport({
        template: templateMetadata,
        agencyId,
        templateVersionId,
      });

      if (error) {
        return onGetFormError({
          dispatch,
          errors: 404,
          template: { templateType, templateId, error },
          next,
        });
      }

      newFormMeta = Object.assign({}, newFormMeta, {
        templateType: template.type,
        template,
      });

      dispatch(
        getFormTemplatesSuccess(
          templates.map(item => (item.id === template.id ? template : item))
        )
      );
    }

    createNewForm(newFormMeta);
  };

export default getFormWith;
