import React, { useState } from 'react';
import { Button } from 'antd';

import PageHeader from 'APP_COMPONENTS/PageHeader';
import getRoute from 'APP_ROOT/utils/get-route';
import PermissionModal from './permissions-modal/index';

export default ({
  isSidebarIsCollapsed,
  agencyId,
  title,
  roleId,
  getListOfRoles,
}) => {
  const [isModalshown, setIsModalshown] = useState(false);

  const onModalVisibleAction = value => setIsModalshown(value);
  const actions = [
    <Button
      key="0"
      type="primary"
      icon="edit"
      onClick={() => onModalVisibleAction(true)}
    >
      Update Role
    </Button>,
  ];

  return (
    <>
      <PageHeader
        title={title}
        goBackTo={getRoute('administratorAgencyRolesPermissions', {
          agencyId,
        })}
        sidebarIsCollapsed={isSidebarIsCollapsed}
        actions={actions}
      />
      <PermissionModal
        isModalVisible={isModalshown}
        onModalVisibleAction={onModalVisibleAction}
        roleId={roleId}
        getListOfRoles={getListOfRoles}
      />
    </>
  );
};
