import { get, find, pick, template } from 'lodash';
import { getParticipant } from './form';

const getTransitionMessageFromtemplate = (
  timelineMessageTemplate,
  historyStep,
  participants,
  notes
) => {
  const submitter = getParticipant(historyStep.submitter, participants);
  const target = getParticipant(historyStep.target, participants);
  const getCurrentStepNote = get(
    find(notes, note => {
      const noteHistoryStep = get(note, 'historyStep', {});

      return (
        noteHistoryStep.fromState === historyStep.fromState &&
        noteHistoryStep.toState === historyStep.toState &&
        noteHistoryStep.date === historyStep.date
      );
    }),
    'content',
    null
  );
  const targetsFromNotes = get(getCurrentStepNote, 'drpMembers', [])
    .map(userId => get(getCurrentStepNote, `__users.${userId}`, null))
    .filter(user => user !== null);

  const targetMembers = [target].concat(targetsFromNotes).map(user => ({
    ...pick(user, ['fullName']),
    rank: get(user, 'rank.name', ''),
  }));

  const context = {
    submitter: {
      fullName: get(submitter, 'fullName'),
      rank: get(submitter, 'rank.name'),
    },
    target: {
      fullName: get(target, 'fullName'),
      rank: get(target, 'rank.name'),
    },
    targetMembers,
  };

  const interpolate = template(timelineMessageTemplate);
  return interpolate(context);
};

export default getTransitionMessageFromtemplate;
