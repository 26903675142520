import styled from 'styled-components';

export const PanelTitle = styled.div.attrs({
  className: 'bdm-panel-title',
})`
  padding: 0 0 16px;

  &.has-padding-horizontal {
    padding-left: 16px;
    padding-right: 16px;
  }

  &.has-padding-vertical {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &.is-paddingless {
    padding: 0;
  }

  a {
    font-size: 18px;
  }

  .panel-search {
    text-align: right;

    .ant-input-search {
      max-width: 224px;
    }

    @media screen and (max-width: 768px) {
      text-align: left;
      padding-top: 8px;

      .ant-input-search {
        max-width: 100%;
      }
    }
  }

  .panel-title-action {
    float: right;
  }

  &.activities-panel-title {
    h2 + h4 {
      margin-top: 10px;
    }
  }
`;

export default PanelTitle;
