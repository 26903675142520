import { GET_SYSTEM_UPDATE_STATUS } from './';

import { getSystemUpdateStatus } from '../api/system-updates';

export default (next = () => {}, onStop = () => {}) => async (
  dispatch,
  getState
) => {
  const {
    systemUpdates: { updating, updateId },
  } = getState();

  const callback = (done, ...args) => {
    done && typeof done === 'function' && done(...args);
  };

  if (!updating) {
    return callback(onStop);
  }

  try {
    const payload = await getSystemUpdateStatus(updateId);

    dispatch({ type: GET_SYSTEM_UPDATE_STATUS, payload });
    callback(next, payload);
  } catch (err) {
    callback(next, err);
  }
};
