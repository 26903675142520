import {
  GET_UNIT_ASSIGNMENTS_REQUEST,
  GET_UNIT_ASSIGNMENTS_FULLFILLED,
  GET_UNIT_ASSIGNMENTS_REJECTED,
} from '../AgencyProfile.actionTypes';

import { getUnitAssignments } from 'APP_ROOT/api/manageUsers';

export default agencyId => dispatch => {
  dispatch({ type: GET_UNIT_ASSIGNMENTS_REQUEST });

  dispatch({
    type: GET_UNIT_ASSIGNMENTS_FULLFILLED,
    payload: { agencyId, response: [] },
  });

  return getUnitAssignments(agencyId)
    .then(response =>
      dispatch({
        type: GET_UNIT_ASSIGNMENTS_FULLFILLED,
        payload: { agencyId, response },
      })
    )
    .catch(error =>
      dispatch({ type: GET_UNIT_ASSIGNMENTS_REJECTED, payload: error })
    );
};
