import React, { Component, Fragment } from 'react';
import get from 'lodash/get';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import DashboardPage from 'APP_COMPONENTS/dashboard';
import withOwnershipGuard from 'APP_ROOT/containers/administrator/components/agency-ownership-guard';
import PermissionAccessViewHeader from './PermissionAccessViewHeader';
import PermissionAccessViewTable from './PermissionAccessViewTable';
import PermissionWrapper from './PermissionAccessViews.styled';
import securityEndpoints from 'APP_ROOT/api/security/securityEndpoints';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'Unknown';
export class PermissionAccessView extends Component {
  state = {
    rolesList: [],
    loading: true,
  };

  getListOfRoles = async () => {
    const roleId = get(this.props, 'match.params.roleId');
    const rolesList = await securityEndpoints.getRolePermissions(roleId);
    const arrayAddingEnviroment = rolesList.map(role => {
      return { ...role, environment: ENVIRONMENT };
    });
    this.setState({ rolesList: arrayAddingEnviroment, loading: false });
  };

  componentDidMount() {
    this.getListOfRoles();
  }

  render() {
    const {
      isSidebarIsCollapsed,
      match: {
        params: {
          agencyId = '',
          agencyName = '',
          permission = '',
          roleId,
        } = {},
      } = {},
    } = this.props;
    const { rolesList, loading } = this.state;
    const title = `${agencyName} - ${permission} Permissions`;
    return (
      <Fragment>
        <PermissionAccessViewHeader
          title={title}
          agencyId={agencyId}
          sidebarIsCollapsed={isSidebarIsCollapsed}
          roleId={roleId}
          getListOfRoles={this.getListOfRoles}
        />
        <PermissionWrapper>
          <h1>{agencyName} Permissions</h1>
          <PermissionAccessViewTable roles={rolesList} loading={loading} />
        </PermissionWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = () => {};

export default withoutClutter(
  DashboardPage(
    mapStateToProps,
    null,
    {}
  )(withOwnershipGuard(PermissionAccessView, 'permissionAttributes'))
);
