import _ from 'lodash';

import { GET_USERS_REQUEST } from './';

import organizationEndpoints from '../api/organization/organizationEndpoints';
import getUsersSuccess from './get-users-success';
import getUsersError from './get-users-error';

export default (filters = {}, next = () => {}) => {
  return dispatch => {
    const {
      includeDisabled = false,
      pageSize = 10,
      sortBy = 'lastName',
      searchTerm,
      agencyId,
      page,
    } = filters;

    dispatch({
      type: GET_USERS_REQUEST,
      payload: { filters, page },
    });

    organizationEndpoints
      .getUsers(includeDisabled, page, pageSize, sortBy, searchTerm, agencyId)
      .then(({ users, total }) => {
        dispatch(
          getUsersSuccess({
            data: users,
            count: total,
            filter: searchTerm,
            page,
          })
        );
        next(users);
      })
      .catch(error => {
        dispatch(getUsersError(_.get(error, ['response', 'status'])));
        next(error);
      });
  };
};
