export const FETCH_USERS_LOADING = 'FETCH_USERS_LOADING';
export const FETCH_USERS_LOADING_SUCCESSFUL = 'FETCH_USERS_LOADING_SUCCESSFUL';
export const FETCH_USERS_LOADING_ERROR = 'FETCH_USERS_LOADING_ERROR';

export const setUsersLoading = () => ({ type: FETCH_USERS_LOADING });

export const setUsersLoadingError = () => ({
  type: FETCH_USERS_LOADING_ERROR,
});

export const setUsersLoadingSuccessful = users => ({
  type: FETCH_USERS_LOADING_SUCCESSFUL,
  payload: users,
});
