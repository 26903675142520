import { createReducer } from 'redux-create-reducer';

import activityFeed from './state/activity-feed';
import actionItemsFeed from './state/action-items-feed';
import actions from './state/action-items';
import activities from './state/activities';

import actionItemsEvents from './reducer-pieces/action-items';
import activityItemsEvents from './reducer-pieces/activity-items';
import activityFeedEvents from './reducer-pieces/activity-feed';
import actionItemsFeedEvents from './reducer-pieces/action-items-feed';

const initialState = {
  activityFeed,
  actionItemsFeed,
  actions,
  activities,
};

const withState = (state, events) => events(state);

const events = {
  ...withState(actions, actionItemsEvents),
  ...withState(activities, activityItemsEvents),
  ...withState(activityFeed, activityFeedEvents),
  ...withState(actionItemsFeed, actionItemsFeedEvents),
};

export default createReducer(initialState, events);
