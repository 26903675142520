/**
 * Calls exclusively used for the contribute to report feature
 */
import {
  addContributeToReportNotification,
  deleteContributeNotifications,
} from '../../../../api/activity';
import {
  SECTION_ASSIGN_ACTION,
  SECTION_COMPLETE_ACTION,
  CONTRIBUTE_REPORT_NOTIFICATION_TYPE,
} from '../../../../constants/contributeToReport';
import { ctrContributorMarkSection } from '../../../../api/reports';

/**
 * Sends a custom notification to the user assigned as task
 * @param {Object} selectedForm session form
 * @param {Number} senderId active submitterId
 * @param {Number} receiverId user assigned a section
 */
export function sendAssignTaskNotification(selectedForm, senderId, receiverId) {
  const {
    meta: { formNumber, formType },
    data: { __users: users },
  } = selectedForm;
  const submitterName = users[senderId].fullName;
  addContributeToReportNotification(senderId, {
    title: `${submitterName} assigned you as a contributor of ${formNumber}. Please complete your section`,
    body: `${submitterName} assigned you as a contributor of ${formNumber}. Please complete your section`,
    data: { formId: selectedForm.meta.id, submitterId: senderId },
    action: SECTION_ASSIGN_ACTION,
    new: true,
    seen: null,
    actionItem: true,
    userId: receiverId,
    agencyId: null,
    isDismissable: false,
    type: CONTRIBUTE_REPORT_NOTIFICATION_TYPE,
  });
}

/**
 * Deletes all the notifications for assignation for the user
 * @param {Number} receiverId user which notifications will be updated
 */
export function removeAssignTaskNotification(receiverId) {
  return deleteContributeNotifications(receiverId);
}

/**
 * Sends a custom notification to submitter when a section is completed
 * @param {Object} selectedForm session form
 * @param {Number} completedById user that completed the section
 */
export function sendSectionCompleteNotification(selectedForm, completedById) {
  const {
    meta: { formNumber, formType, submitterId },
    data: { __users: users },
  } = selectedForm;
  const contributorName = users[completedById].fullName;
  addContributeToReportNotification(submitterId, {
    title: `${contributorName} completed their section of ${formType} ${formNumber}`,
    body: `${contributorName} completed their section of ${formType} ${formNumber}`,
    data: { formId: selectedForm.meta.id, submitterId },
    action: SECTION_COMPLETE_ACTION,
    new: true,
    seen: null,
    actionItem: true,
    userId: submitterId,
    agencyId: null,
    isDismissable: false,
    type: CONTRIBUTE_REPORT_NOTIFICATION_TYPE,
  });
}

/**
 * Sends an attempt to set a section complete/incomplete
 * It will return true when the requests ends successfully false otherwise
 * @param {Object} requestProps
 * @param {Object} requestData
 * @param {Object} errCb
 * @returns {Boolean}
 */
export async function sendCompleteOrIncompleteRequest(
  requestProps,
  requestData,
  errCb
) {
  try {
    await ctrContributorMarkSection(requestProps, requestData);
    return true;
  } catch (e) {
    let message =
      'This report has already been submitted. You are no longer able to edit this report.';
    if (e && e.message && e.message.json) {
      message = await e.response.json().message;
    }
    errCb(message);
    return false;
  }
}
