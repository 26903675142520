import getDownloadURL from './getDownloadURL';

/**
 * Override for S3 and backwards compatible downloads
 * @param e event object
 * @param attachmentId id of the attachment to download
 *  the non-override version receives the URL
 * @param fileName used to compute download link
 */
const handleDownloadClickS3 = (props, setState, e, attachmentId, fileName) => {
  e.preventDefault();
  getDownloadURL(props, attachmentId)
    .then(response => {
      const { authorization, type } = response;
      let { url } = response;
      if (type !== 'S3') {
        url = url + '?access_token=' + authorization;
      }
      setState({ test: 'test' });
      const element = document.createElement('a');
      element.href = url;
      element.download = '';
      element.click();
    })
    .catch(error =>
      error.json().then(json => {
        setState({
          errors: json,
          loading: false,
        });
      })
    );
};

export default handleDownloadClickS3;
