import React from 'react';

import MainTitle from '../../components/common/main-title';
import CommunityEngagementPanel from '../../components/dashboard/community-engagement-panel';

const CommunityEngagement = props => (
  <div>
    <MainTitle>Community Engagement</MainTitle>
    <CommunityEngagementPanel />
  </div>
);

export default CommunityEngagement;
