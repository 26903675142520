import styled from 'styled-components';

export const TableWrapper = styled.div`
  input {
    float: left;
    padding-right: 10%;
  }

  label {
    text-align: left;
    float: left;
  }

  #state {
    width: 40%;
  }

  .ant-select {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }

  .ant-btn {
    margin: 5px;
  }
`;
