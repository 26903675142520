import React from 'react';
import cx from 'classnames';

const PageLoader = ({
  loading = false,
  intermitent = false,
  value = 100,
  className,
}) => (
  <div
    className={cx(className, {
      'is-loading': loading,
      'is-intermitent': intermitent,
    })}
  >
    <div className="progress-wrapper" style={{ width: `${value}%` }}>
      <svg viewBox="0 0 800 4" preserveAspectRatio="xMidYMid meet">
        <rect
          clipPath="url(#oqedh8b4fab)"
          x="0"
          y="0"
          width="800"
          height="4"
          style={{ fill: 'url(#9g9b7l16267)' }}
        />
        <defs>
          <clipPath id="oqedh8b4fab">
            <rect x="0" y="0" rx="0" ry="0" width="100%" height="2" />
          </clipPath>
          <linearGradient id="9g9b7l16267">
            <stop offset="-0.223632" stopColor="#3772FF" stopOpacity="1">
              <animate
                attributeName="offset"
                values="-3; 1"
                dur="1s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset="0.776368" stopColor="#c0e7ff" stopOpacity="1">
              <animate
                attributeName="offset"
                values="-2; 2"
                dur="1s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset="1.77637" stopColor="#3772FF" stopOpacity="1">
              <animate
                attributeName="offset"
                values="-1; 3"
                dur="1s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
);

export default PageLoader;
