import styled from 'styled-components';

const Content = styled.div`
  width: 96.5%;
  background: #fff;
  margin: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const Title = styled.h2`
  font-weight: 600;
`;

export { InputContainer, Content, Title };
