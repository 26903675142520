import { has } from 'lodash';
import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';

import { SET_SOURCE_SELECTION } from '../../../../../actions/index';

const saveUploadedBy = props => {
  const {
    dispatch,
    currentUser,
    data: { __users: users },
  } = props;

  if (!has(users, currentUser.id)) {
    dispatch({
      type: SET_SOURCE_SELECTION,
      payload: { item: currentUser, sourceKey: '__users', formName: 'form' },
    });
  }
};

const onBeforeUpload = props => file => {
  const { meta: { id = '' } = {}, settings } = props;
  saveUploadedBy(props);
  if (!id) {
    return new Promise((resolve, reject) => {
      emitFormEvent('onSaveDraft', settings.formName, (error, response) => {
        const { creationInProgress = false } = response || {};
        if (error) {
          reject(error);
        } else if (creationInProgress) {
          const msg =
            'Report creation in progress, please upload the file later.';
          alert(msg);
          reject(msg);
        } else {
          resolve(response);
        }
      });
    });
  }
  return true;
};

export default onBeforeUpload;
