import React, { Component } from 'react';
import { Col, Icon } from 'antd';
import cx from 'classnames';
import { DragSource } from 'react-dnd';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';

import { ButtonWrapper } from './Sidebar.styled';

class ComponentButton extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(this.props, nextProps);
  }

  render() {
    const { component, icon, connectDragSource, isDragging } = this.props;

    return (
      <Col span={12}>
        <div ref={connectDragSource}>
          <ButtonWrapper
            className={cx('button-wrapper', { 'is-dragging': isDragging })}
          >
            <div>{component.label}</div>
            <Icon type={icon} />
          </ButtonWrapper>
        </div>
      </Col>
    );
  }
}

export default DragSource(
  params => params.component.type,
  {
    beginDrag: param => ({ ...param.component, toAdd: true }),
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  })
)(ComponentButton);
