import styled from 'styled-components';

const StyledRolesTable = styled.div`
  margin-top: 16px;
  overflow: auto;

  .ant-table table {
    tr td,
    tr th {
      word-break: inherit;
    }
  }
`;

export default StyledRolesTable;
