import styled from 'styled-components';

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 15px;
`;

export { PageBody };
