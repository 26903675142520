import {
  UPDATE_USER_REQUEST,
  UPDATE_USER_FULLFILLED,
  UPDATE_USER_REJECTED,
  POST_USER_SECURITY_ROLES_FULFILLED,
  POST_USER_SECURITY_ROLES_REJECTED,
  POST_USER_SECTIONS_FULFILLED,
  POST_USER_SECTIONS_REJECTED,
  POST_USER_EMPLOYMENT_HISTORY_FULFILLED,
  POST_USER_EMPLOYMENT_HISTORY_REJECTED,
  POST_USER_DOCUMENTS_FULFILLED,
  POST_USER_DOCUMENTS_REJECTED,
} from '../AgencyUsers.actionTypes';

import { updateUser } from 'APP_ROOT/api/manageUsers';
import { enableUser, disableUser } from 'APP_ROOT/api/manageUsers';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';
import { change } from 'redux-form';
import { userProfileFormName } from '../UserProfile/constants/form-values.constants';

const formName = userProfileFormName;

export default ({
  userId,
  userIntegrationId,
  data,
  agencyId,
  tenantId,
  updateSecurity,
  sectionsData,
  employmentHistory,
  userDocuments,
  enableOrDisable = undefined,
  showMessageCb = () => {},
  callback = () => {},
}) => async dispatch => {
  dispatch({ type: UPDATE_USER_REQUEST, agencyId, payload: data });

  let { securityRolesId = [] } = data;
  let mainAttributesResponse;

  // Main Attributes Section
  try {
    mainAttributesResponse = await updateUser(userId, data);
    dispatch({
      type: UPDATE_USER_FULLFILLED,
      agencyId,
      payload: { response: mainAttributesResponse },
    });
  } catch (error) {
    dispatch({ type: UPDATE_USER_REJECTED, agencyId, payload: error });
    showMessageCb(error);
    return;
  }

  // Role Update Section
  try {
    if (updateSecurity) {
      const securityRolesResponse = await organizationEndpoints.updateRolesToOrganizationalUser(
        mainAttributesResponse.integrationId,
        securityRolesId
      );
      dispatch({
        type: POST_USER_SECURITY_ROLES_FULFILLED,
        agencyId,
        payload: securityRolesResponse,
      });
    }
  } catch (error) {
    dispatch({
      type: POST_USER_SECURITY_ROLES_REJECTED,
      agencyId,
      payload: error,
    });
    showMessageCb(error);
    return;
  }

  // Update User Data Store
  try {
    if (sectionsData) {
      const sectionsResponse = await organizationEndpoints.updateTenantDatastoreUsers(
        tenantId,
        userIntegrationId,
        sectionsData
      );
      dispatch({
        type: POST_USER_SECTIONS_FULFILLED,
        agencyId,
        payload: sectionsResponse,
      });
    }
  } catch (error) {
    dispatch({ type: POST_USER_SECTIONS_REJECTED, agencyId, payload: error });
    showMessageCb(error);
    return;
  }

  // Update Employment History
  try {
    if (employmentHistory) {
      const employmentHistoryResponse = await organizationEndpoints.postUserEmploymentHistory(
        userIntegrationId,
        employmentHistory,
        tenantId
      );
      dispatch({
        type: POST_USER_EMPLOYMENT_HISTORY_FULFILLED,
        agencyId,
        payload: employmentHistoryResponse,
      });
      showMessageCb(false, employmentHistoryResponse);
    }
  } catch (error) {
    dispatch({
      type: POST_USER_EMPLOYMENT_HISTORY_REJECTED,
      agencyId,
      payload: error,
    });
    showMessageCb(error);
    return;
  }

  // Update User Documents
  try {
    if (userDocuments) {
      const userDocumentsResponse = await organizationEndpoints.postUserDocuments(
        userIntegrationId,
        userDocuments
      );
      dispatch({
        type: POST_USER_DOCUMENTS_FULFILLED,
        agencyId,
        payload: userDocumentsResponse,
      });
      showMessageCb(false, userDocumentsResponse);
    }
  } catch (error) {
    dispatch({ type: POST_USER_DOCUMENTS_REJECTED, agencyId, payload: error });
    showMessageCb(error);
    return;
  }

  try {
    if (enableOrDisable !== undefined) {
      dispatch({
        type: UPDATE_USER_REQUEST,
        agencyId,
        payload: { isActive: !enableOrDisable },
      });

      const action = enableOrDisable ? disableUser : enableUser;
      const response = await action(userId);

      dispatch({
        type: UPDATE_USER_FULLFILLED,
        agencyId,
        payload: { response },
      });
      showMessageCb(false, response);
    }
  } catch (error) {
    dispatch({ type: UPDATE_USER_REJECTED, agencyId, payload: error });
    dispatch(change(formName, 'isDeleted', !enableOrDisable));
    showMessageCb(error);
  }

  showMessageCb(false, mainAttributesResponse);
  callback({ id: userId, agencyId });
};
