import { genOptions, route, fetchRequest } from '../utils/request';
import storage from '../utils/storage';

export const lookupForUpdates = () => {
  const url = route('/SystemVersions/updates');
  const token = storage.get('token');
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

export const startSystemUpdate = () => {
  const url = route('/SystemVersions/updates');
  const token = storage.get('token');
  const options = genOptions('POST', null, token);

  return fetchRequest(url, options);
};

export const getSystemUpdateStatus = updateId => {
  const url = route(`/SystemVersions/updates/${updateId}/status`);
  const token = storage.get('token');
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};
