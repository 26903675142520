import React, { Component } from 'react';
import { isPlainObject, get, includes } from 'lodash';
import URLSearchParams from 'url-search-params';
import { connect } from 'react-redux';

import DashboardPage from '../../components/dashboard';
import {
  getSelectedForm,
  getForm,
  getReportLoadingState,
} from '../../selectors/form';
import { getSession } from '../../selectors/session';
import { getModals } from '../../selectors/modals';
import { getAttachments } from '../../selectors/attachments';
import { getAgencyTZ, getTemplateConfiguration } from '../../selectors/session';
import getFormTemplate from '../../actions/get-form';
import editForm from '../../actions/edit-form';
import FORM_TYPES from '../../constants/form-types';
import emitter, { EventTypes } from 'APP_ROOT/utils/eventEmitter';

import ErrorScreen from '../../components/common/error-screen';
import LoadingReport from '../../components/common/loading-report-screen';

import UOF from '../use-of-force';
import IAC from '../intake';
import IAI from '../investigation';
import VP from '../vehicle';
import Container from './report-container/index';

import { NEW_REPORT_PICKER_ID } from 'APP_ROOT/components/dashboard/page-wrapper.js';
import populateIntegration from './populateIntegration';
import populateIsPerformanceReview from './populateIsPerformanceReview';
import withoutClutter from '../../components/without-clutter';
import propsHasChanged from '../../utils/propsHasChanged.js';

const errorMessage = {
  500: 'This page could not be loaded right now, please try again later.',
  404: 'This page could not be loaded right now, please try again later.',
  403: 'Resource not found within your agency',
  401: 'You have no permissions to see this page. You will be redirected to the home page in 5 seconds.',
  1: 'Pick a report type to start filling',
  unknown: '',
};

const errorTitle = {
  500: 'We are sorry',
  404: 'We are sorry',
  403: 'Unauthorized',
  401: 'Unauthorized',
  1: '',
  unknown: '',
};

const FEATUREFLAG_PRINTWITHATTACHMENTS = 'printWithAttachments::1.0';

class ReportType extends Component {
  state = {
    error: false,
    stateIsReady: false,
    loading: false,
    reportPicker: false,
  };

  reportContainersCache = {};

  static getPageConfig(props) {
    const { selectedForm } = this.props;
    const formNumber = get(selectedForm, 'meta.formNumber');
    const formType = get(selectedForm, 'template.typeName');
    const newReportTitle = formType ? `New ${formType} Report` : 'New Report';

    const title = formNumber || newReportTitle;

    return {
      title,
    };
  }

  async componentDidMount() {
    await this.loadReportSchema();
  }

  async componentDidUpdate(previousProps, previousState) {
    const {
      modals: {
        [NEW_REPORT_PICKER_ID]: { visible: reportPickerVisible = false } = {},
      },
      form: { selectedForm: { template } = {} } = {},
      selectedForm: { template: { formSchema } = {} },
      history: {
        location: { pathname },
      },
    } = this.props;

    const { loading, reportPicker, error } = this.state;

    // when the report picker modal opens, formSchema is deleted
    if (reportPickerVisible && !reportPicker) {
      this.setState({ reportPicker: true });
    }

    // if the user close/cancel report picker we should reload
    // fromSchema to be able to render de report
    const shouldLoadSchema =
      !formSchema && !reportPickerVisible && reportPicker;

    // if the user accesses a report within a referencing report(e.g IAC IAI)
    const hasReportHistory =
      this.hasReportHistory(pathname) &&
      !formSchema &&
      !reportPickerVisible &&
      !reportPicker;

    // if the user select a new report in the report picker
    // template is null
    if (
      ((!template && reportPicker) || shouldLoadSchema || hasReportHistory) &&
      !loading &&
      !error
    ) {
      await this.loadReportSchema();
    }
  }

  hasReportHistory(pathName) {
    const expression = '/reports/[0-9]';
    return pathName.search(expression) !== -1;
  }

  async loadReportSchema() {
    const {
      dispatch,
      match: {
        params: { id, type: reportType, agencyId, templateVersionId },
      },
    } = this.props;

    const type = this.getReportType() || reportType;
    const templateId = this.getReportTemplateId();

    this.setState({ loading: true });

    await Promise.all([
      this.waitAtLeastPromise,
      new Promise(resolve => {
        if (id) {
          dispatch(
            editForm(id, agencyId, err => {
              this.setErrorState(err);
              populateIsPerformanceReview(this.props);
              resolve();
            })
          );
        } else if (type) {
          try {
            dispatch(
              getFormTemplate(
                { templateType: this.getFormType(type), templateVersionId },
                async err => {
                  this.setErrorState(err);
                  await populateIntegration(this.props);
                  populateIsPerformanceReview(this.props);
                  resolve();
                }
              )
            );
          } catch (err) {
            this.setErrorState('unknown');
            resolve();
          }
        } else if (templateId) {
          try {
            dispatch(
              getFormTemplate(
                {
                  templateId: templateId,
                  agencyIdFromOptions: agencyId,
                  templateVersionId: templateVersionId,
                },
                err => {
                  this.setErrorState(err);
                  resolve();
                }
              )
            );
          } catch (err) {
            this.setErrorState('unknown');
            resolve();
          }
        } else {
          this.setErrorState(1);
          resolve();
          emitter.emit(EventTypes.CREATE_REPORT);
        }
      }),
    ]);

    this.setState({ stateIsReady: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      form: { selectedForm: nextForm, actions: nextActions = {} } = {},
      app: { sidebarCollapsed: nextSidebarCollapsed } = {},
    } = nextProps;
    const {
      form: { selectedForm, actions = {} } = {},
      app: { sidebarCollapsed } = {},
    } = this.props;

    return (
      propsHasChanged(nextForm, selectedForm) ||
      propsHasChanged(nextActions, actions) ||
      propsHasChanged(nextState, this.state) ||
      nextProps.loading !== this.props.loading ||
      nextSidebarCollapsed !== sidebarCollapsed ||
      nextProps.templatesLoading !== this.props.templatesLoading ||
      nextProps.modals?.[NEW_REPORT_PICKER_ID]?.visible !==
        this.props.modals?.[NEW_REPORT_PICKER_ID]?.visible
    );
  }

  waitAtLeastPromise = (ms = 0) =>
    new Promise(resolve => {
      global.setTimeout(resolve, ms);
    });

  getReportType = () => {
    const search = new URLSearchParams(global.location.search);

    return search.get('templateType') || search.get('reportType');
  };

  getReportTemplateId = () => {
    const search = new URLSearchParams(global.location.search);

    return search.get('templateId');
  };

  setErrorState = (error, response) => {
    if (error) {
      this.setState({
        error:
          isPlainObject(error) || typeof error === 'object'
            ? get(error, ['response', 'status'], 'unknown')
            : error,
      });
    }
    this.setState({ loading: false, reportPicker: false });
  };

  onErrorClick = e => {
    const {
      history: { replace },
    } = this.props;
    replace('/');
  };

  createNewReportClick = e => {
    emitter.emit(EventTypes.CREATE_REPORT);
  };

  getReportContainerPath = type => {
    switch (type) {
      case FORM_TYPES.UOF:
        return UOF;
      case FORM_TYPES.IAC:
        return IAC;
      case FORM_TYPES.IAI:
        return IAI;
      case FORM_TYPES.VP:
        return VP;
      default:
        return Container;
    }
  };

  getReportContainerComponent = type => {
    const reportContainer = this.getReportContainerPath(type);

    if (this.reportContainersCache[type]) {
      return this.reportContainersCache[type];
    }

    this.reportContainersCache[type] = reportContainer;

    return this.reportContainersCache[type];
  };

  getFormType(type) {
    return get(FORM_TYPES, type, type);
  }

  render() {
    const { error } = this.state;
    const {
      selectedForm: { template: { type = '' } = {} } = {},
      form: { isLoading = false },
      match = {},
      app,
      templatesLoading,
      canPrintWithAttachments,
    } = this.props;

    const { stateIsReady } = this.state;

    const viewAs = get(match, 'params.viewAs', 'form');
    const isPrinting = includes(['print', 'print-confirmation'], viewAs);

    const extraProps = Object.assign({}, this.props, {
      isPrinting,
      viewAs,
      canPrintWithAttachments,
    });

    const showNewReportFallback = error === 1;
    const NewReportButtonText = (
      <span>
        <i className="bdm-icon bdm-icon-new-report" /> New Report
      </span>
    );

    if (error) {
      return (
        <ErrorScreen
          errorCode={error}
          showTitle={!showNewReportFallback}
          pageTitle={showNewReportFallback ? 'New Report' : 'Reports'}
          buttonSize={showNewReportFallback ? 'large' : 'default'}
          buttonText={showNewReportFallback ? NewReportButtonText : undefined}
          title={errorTitle[error]}
          message={errorMessage[error]}
          onClick={
            showNewReportFallback
              ? this.createNewReportClick
              : this.onErrorClick
          }
        />
      );
    }

    if (isLoading || !stateIsReady || templatesLoading) {
      return (
        <LoadingReport
          key="loading-screen"
          sidebarCollapsed={app.sidebarCollapsed}
        />
      );
    }

    const ReportContainer = this.getReportContainerComponent(type);

    return <ReportContainer {...extraProps} />;
  }
}

const mapState = (state, props) => {
  const selectedForm = getSelectedForm(state, ['changedFields']);
  const templateType = get(selectedForm, 'template.type', null);
  const templateConfiguration = getTemplateConfiguration(templateType);

  const {
    currentUser: { featureFlags },
  } = props;

  const canPrintWithAttachments = featureFlags.some(element => {
    if (element.includes(FEATUREFLAG_PRINTWITHATTACHMENTS)) {
      return true;
    }
  });

  return {
    selectedForm,
    canPrintWithAttachments,
    form: getForm(state),
    session: getSession(state),
    modals: getModals(state),
    attachments: getAttachments(state),
    loading: getReportLoadingState(state),
    timezone: getAgencyTZ(state),
    templateConfig: templateConfiguration(state),
  };
};

export default withoutClutter(DashboardPage()(connect(mapState)(ReportType)));
