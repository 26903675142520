import React, { Component } from 'react';
import { Button } from 'antd';

import EditorSaveButton from '../../containers/administrator/components/EditorSaveButton';

export default class TabNavigation extends Component {
  shouldComponentUpdate(props) {
    const { next, prev, ...rest } = props;
    const { next: oldNext, prev: oldPrev, ...oldRest } = this.props;
    return JSON.stringify(rest) !== JSON.stringify(oldRest);
  }

  render() {
    const {
      current = 0,
      showSubmitButton = false,
      submitButtonLabel = 'Submit',
      showNavigation = true,
      submitDisable = true,
      isViewer = false,
      onNext = () => {},
      onPrev = () => {},
      totalSteps = 1,
      loading = false,
      canSubmit = true,
      isEditMode = false,
      isEditor,
    } = this.props;

    return (
      <div className="btm-form-steps-navigation">
        {current > 0 && showNavigation && (
          <Button type="primary" ghost onClick={onPrev}>
            Previous
          </Button>
        )}

        {current < totalSteps - 1 && showNavigation && (
          <Button
            type="primary"
            ghost
            style={{ marginLeft: 8 }}
            onClick={onNext}
          >
            Next
          </Button>
        )}

        {showSubmitButton && !isEditor && (canSubmit || isViewer) && (
          <Button
            type="primary"
            style={{ marginLeft: 8 }}
            htmlType="submit"
            disabled={loading || submitDisable}
            loading={loading}
          >
            {isViewer && !isEditMode ? 'View Report' : submitButtonLabel}
          </Button>
        )}

        {isEditor && <EditorSaveButton loading={loading} />}
      </div>
    );
  }
}
