import styled from 'styled-components';

const Repeater = styled.div.attrs({
  className: 'bdm-form-repeater-action-wrapper text-right',
})`
  padding: 10px 0;

  .bdm-section-wrapper-last {
    margin: 0;
  }

  .ant-btn {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default Repeater;
