import styled from 'styled-components';

import { Collapse } from 'antd';

const { Panel } = Collapse;

export const StyledCollapse = styled(Collapse)`
  margin: 25px 15px;
  background-color: transparent;

  &.ant-collapse.ant-collapse-borderless {
    padding: 0;
  }

  .section-panel {
    background-color: #fff;
    border-radius: 6px !important;
    margin-bottom: 25px;

    hr {
      border-color: #e8edf1;
      border-style: solid;
      margin-bottom: 20px;
    }

    .ant-collapse-header {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const StyledPanel = styled(Panel)`
  padding: 5px 5px;
`;

export const StyledPageTitle = styled.h1.attrs({
  className: 'user-profile-page-title',
})`
  h1 {
    font-family: Lato-Medium, sans-serif;
    font-size: 24px;
    color: #000;
  }
`;

export const StyledPanelTitle = styled.h2.attrs({
  className: 'user-profile-panel-title',
})`
  font-family: Lato-Medium, sans-serif;
  font-size: 16px;
  color: #000;
`;
