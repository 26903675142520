import { get } from 'lodash';
import { POST_FORM_LINK_REQUEST } from './';
import { linkingReports } from '../api/form-links';

import { getReport, updateDraftReport } from '../api/reports';

import postFormLinkSuccess from './post-form-link-success';
import postFormLinkError from './post-form-link-error';

export default (
  {
    currentReportId = '',
    targetReportId = '',
    newReport = null,
    resetRemoteKeys = [],
    existingValue,
  },
  next = () => {}
) => async (dispatch, getState) => {
  const {
    session: { currentUser: { agencyId = '' } = {} } = {},
    form: {
      selectedForm: {
        meta: { id, formType, formNumber, formCategory, draftDate },
      },
    },
  } = getState();

  dispatch({ type: POST_FORM_LINK_REQUEST });

  try {
    //create the report relation
    const formLink = await linkingReports({ currentReportId, targetReportId });

    //update the linking fields in target report when is not a new report
    if (!newReport) {
      const targetReport = await getReport({ id: targetReportId, agencyId });

      let trackingNumberKey = '';
      let isInvolvedKey = '';

      if (targetReport.formType.includes('use-of-force')) {
        trackingNumberKey = 'vehiclePursuitTrackingNumber';
        isInvolvedKey = 'isVehiclePursuitInvolved';
      } else {
        trackingNumberKey = 'useOfForceTrackingNumber';
        isInvolvedKey = 'isUseOfForceInvolved';
      }

      const { data } = targetReport;
      const keysToUpdate = {
        [trackingNumberKey]: currentReportId + [],
        [isInvolvedKey]: true,
        __reports: {
          [id]: {
            id,
            formType,
            formNumber,
            formCategory,
            draftDate,
            number: formNumber,
          },
        },
      };
      await updateDraftReport({
        agencyId,
        id: targetReportId,
        data: { data: { ...data, ...keysToUpdate } },
      });
    }

    dispatch(postFormLinkSuccess({ formLink }));

    next(false, formLink);
  } catch (e) {
    dispatch(postFormLinkError(get(e, ['response', 'status'])));
    next(e);
  }
};
