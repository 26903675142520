import { isEmpty } from 'lodash';
import {
  FILTER_TYPE,
  FILTER_RANGE,
  FILTER_EVENT_DATE,
  FILTER_STATUS,
  FILTER_FORCE,
  FILTER_ALLEGATION,
  FILTER_MENTIONED_USERS,
  FILTER_REPORT_SUBMITTER,
  INCIDENT_OFFICER_FORCE_APPLIED,
  COMPLAINT_ALLEGATION_TYPE_FILTER_KEY,
  INVESTIGATION_ALLEGATION_TYPE_FILTER_KEY,
} from './constants';

const TYPE_DATA = 'data';
const TYPE_MAIN = 'main';
const FILTER_COLUMN = {
  [FILTER_RANGE]: { column: ['submitted_at'], type: TYPE_DATA },
  [FILTER_EVENT_DATE]: { column: ['eventDate'], type: TYPE_DATA },
  [FILTER_TYPE]: { column: ['formType'], type: TYPE_MAIN },
  [FILTER_STATUS]: { column: ['status'], type: TYPE_MAIN },
  [FILTER_FORCE]: { column: [INCIDENT_OFFICER_FORCE_APPLIED], type: TYPE_DATA },
  [FILTER_ALLEGATION]: {
    column: [
      COMPLAINT_ALLEGATION_TYPE_FILTER_KEY,
      INVESTIGATION_ALLEGATION_TYPE_FILTER_KEY,
    ],
    type: TYPE_DATA,
  },
  [FILTER_MENTIONED_USERS]: { column: ['mentionedUsers'], type: TYPE_DATA },
  [FILTER_REPORT_SUBMITTER]: { column: ['submittedBy'], type: TYPE_DATA },
};
const isEmptyFilter = (filters, oldFilters, filterType) =>
  isEmpty(filters[filterType]) && !isEmpty(oldFilters[filterType]);

const canApplyFilters = (filters, oldFilters, clearFilterColumn) => {
  let clearData = [];
  let clearMain = [];

  Object.keys(FILTER_COLUMN).forEach(key => {
    if (isEmptyFilter(filters, oldFilters, key)) {
      if (FILTER_COLUMN[key].type === TYPE_DATA) {
        clearData = clearData.concat(FILTER_COLUMN[key].column);
      } else {
        clearMain = clearMain.concat(FILTER_COLUMN[key].column);
      }
    }
  });
  if (clearData.length) clearFilterColumn('dataFilter', clearData);
  if (clearMain.length) clearFilterColumn('mainFilter', clearMain);

  return clearData.length === 0 && clearMain.length === 0;
};

export default canApplyFilters;
