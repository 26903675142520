import React from 'react';
import FormPickerWrapper from '../../../../components/common/form-picker/FormPickerWrapper.styled';
import Loader from '../../../../components/common/form-picker/Loader';
import fromTemplateClick from './FromTemplateClick';
import FromTemplateButton from './FromTemplateButton';

const FormTemplate = ({
  push,
  closeModal,
  templates = [],
  isLoading = false,
  agencyId,
}) => {
  const defaultTemplatesFilter = template => template.default === true;
  const formShortcuts = templates.filter(defaultTemplatesFilter);
  const commonClickOptions = {
    closeModal,
    push,
  };

  return (
    <FormPickerWrapper>
      {isLoading && <Loader />}
      {!isLoading &&
        formShortcuts &&
        formShortcuts.map((shortcut, index) => (
          <FromTemplateButton
            key={index}
            template={shortcut}
            onClick={fromTemplateClick({
              template: shortcut,
              agencyId,
              ...commonClickOptions,
            })}
          />
        ))}
    </FormPickerWrapper>
  );
};

export default FormTemplate;
