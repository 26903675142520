import { some } from 'lodash';

import getContextPath from './getContextPath';
import getFieldDataKey from './getFieldDataKey';

const getDependsOnChange = props => {
  const { fromSource = null, changedFields = {}, dependsOn } = props;

  const contextPath = getContextPath(props);
  const fieldDataKey = getFieldDataKey(props);
  const isOwnChange = fieldDataKey in changedFields;
  let dependsOnChange = false;

  if (fromSource) {
    //check if field depends on the changed property
    if (!isOwnChange && dependsOn) {
      if (Array.isArray(dependsOn)) {
        //any of dependencies changed
        dependsOnChange = some(
          dependsOn,
          f => `${contextPath}${f}` in changedFields
        );
      } else {
        dependsOnChange = `${contextPath}${dependsOn}` in changedFields;
      }
    }
  }

  return dependsOnChange;
};

export default getDependsOnChange;
