import {
  ATTRIBUTE_TYPE_BOOL,
  ATTRIBUTE_TYPE_DATE,
} from '../../UserProfile.constants';
import moment from 'moment';
import { BENCHMARK_DATE_FORMAT } from '../../../../../../utils/parse-date';

const getFieldData = field => {
  switch (field.type) {
    case ATTRIBUTE_TYPE_DATE:
      return moment.utc(field.fieldValue, BENCHMARK_DATE_FORMAT);
    case ATTRIBUTE_TYPE_BOOL:
      return field.fieldValue === 'true';
    default:
      return field.fieldValue;
  }
};

export default getFieldData;
