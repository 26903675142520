import { flatMap, compact, has } from 'lodash';
import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes';

const getFieldsWithConditions = (fields, currentFieldKey) => {
  let allFieldsByKey = {};
  const getField = field => {
    if (field.type === FIELD) {
      if (field.key === currentFieldKey) {
        allFieldsByKey[currentFieldKey] = field;
      } else {
        has(field, 'conditions.rules')
          ? (allFieldsByKey[field.key] = field)
          : null;
      }
    } else if (field.properties) {
      has(field, 'conditions.rules')
        ? (allFieldsByKey[field.key] = field)
        : null;
      flatMap(field.properties, getField);
    }
  };

  const allTabs = flatMap(fields, tab => ({
    title: tab.title,
    key: tab.key,
    properties: compact(flatMap(tab.properties, getField)),
  }));
  let fieldsByKey = {};

  allTabs.forEach(tab => {
    if (tab.properties) {
      fieldsByKey = tab.properties.reduce((hash, obj) => {
        hash[obj.key] = obj;
        return hash;
      }, fieldsByKey);
    }
  });

  return allFieldsByKey;
};

export default getFieldsWithConditions;
