import styled from 'styled-components';
import { BLUE, GRAY_2, GRAY_5 } from '../../../../config/theme';
import { Button, Col, Upload } from 'antd';

export const StyledErrorMessage = styled.span`
  color: #e74c3c;
  font-size: 12px;
  line-height: 14px;
`;

export const StyledCloseButton = styled(Button)`
  &:hover {
    color: ${GRAY_5};
    border-color: none;
  }

  &.error,
  &.error:hover {
    color: #e74c3c;
  }

  border: none;
  border-color: none;
  color: ${GRAY_5};

  i {
    font-size: 20px;
  }
`;

export const StyledDownloadButton = styled(Button)`
  &:hover {
    border-color: none;
    color: ${BLUE};
  }

  border: none;
  border-color: none;
  color: ${BLUE};

  i {
    font-size: 20px;
  }
`;

export const StyledLineDivide = styled.div`
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 16px;
`;

export const StyledCol = styled(Col)`
  margin-bottom: 16px;

  a.no-link {
    color: #000;
    cursor: default;
  }

  .ant-progress {
    height: 0;
    line-height: 0px;
    vertical-align: top;
    position: relative;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
`;

export const StyledAttachmentInfo = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: ${GRAY_2};
  margin: 0;

  .reviewer-field.textarea-field.textarea-field {
    padding: 0 0 5px 0;
  }
`;

export const StyledUpload = styled(Upload)`
  .info {
    margin-top: 10px;
  }
`;
