import React from 'react';
import { Modal } from 'antd';
import { last, get } from 'lodash';

import componentsManager from '../../services/componentsManager';
import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes';
import { DURATION, MATH } from '../../../../constants/fieldTypes';

import { DURATION_FIELDS_RELATED } from '../../constants/constants';
import getAllFieldsFlat from '../../utils/getAllFieldsFlat';

const { info } = Modal;

export const findDurationFieldOwner = key => field => {
  const {
    options: { initial, final },
  } = field;
  return last(initial.split('.')) === key || last(final.split('.')) === key;
};

export const updateDurationFieldReference = (ref, key) =>
  ref.includes(key)
    ? ref.includes('data.')
      ? ref.substring(5)
      : `data.${key}`
    : ref;

export const updateDurationField = (field, key) => {
  const { options } = field;
  const { initial, final } = options;
  return {
    ...field,
    options: {
      ...options,
      initial: updateDurationFieldReference(initial, key),
      final: updateDurationFieldReference(final, key),
    },
  };
};

export const getComponentName = component =>
  component.title || component.reportingKey || component.key;

export const moveDurationField = (component, props, position, duration) => {
  const targetRep = componentsManager.findItemInRepeatersFields(props.id);
  const durationRep = componentsManager.findItemInRepeatersFields(duration.id);
  if (targetRep !== durationRep && targetRep) {
    info({
      title: 'Action not allowed',
      content: (
        <div>
          <p>
            Field <strong>{getComponentName(component)}</strong> cannot be moved
            into a repeater, because it is related to{' '}
            <strong>{getComponentName(duration)}</strong>
          </p>
          <p>Remove relation with {getComponentName(duration)} first</p>
        </div>
      ),
      onOk() {},
    });
    return false;
  }
  const sourcetRep = componentsManager.findItemInRepeatersFields(component.id);
  if (durationRep !== sourcetRep) {
    componentsManager.editComponent(
      duration.id,
      updateDurationField(duration, component.key)
    );
  }
  componentsManager.moveComponent(component, props.id, position);
  return true;
};

export const isMathRelated = component => {
  const { fieldsByKey } = getAllFieldsFlat(
    componentsManager.fields,
    component.id
  );
  return Object.values(fieldsByKey)
    .filter(f => f.field_type === MATH)
    .find(f => {
      const operands = get(f, 'options.operands', []);
      return operands.some(o => last(o.split('.')) === component.key);
    });
};

const moveComponentDurationRelated = (component, props, position) => {
  if (DURATION_FIELDS_RELATED.includes(component.field_type)) {
    const durationFields = componentsManager.getFieldsByType(FIELD, DURATION);
    const duration = durationFields.find(findDurationFieldOwner(component.key));
    if (duration) {
      moveDurationField(component, props, position, duration);
    } else {
      componentsManager.moveComponent(component, props.id, position);
    }
  } else {
    componentsManager.moveComponent(component, props.id, position);
  }
};

export default moveComponentDurationRelated;
