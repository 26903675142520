import React, { Component } from 'react';

import DashboardPage from '../../components/dashboard';
import { Redirect } from 'react-router';

import { hasPermissions } from '../../utils/admin';
import {
  listAllAgenciesPermissions,
  displayAgencyPermissions,
} from '../../utils/administratorPermission';

export class Container extends Component {
  render() {
    const { route, session = {} } = this.props;
    const { currentUser = {} } = session;
    const { agencyId, permissions = [] } = currentUser;

    const includeAllAgencies = hasPermissions(
      permissions,
      listAllAgenciesPermissions
    );

    const includeOneAgency = hasPermissions(
      permissions,
      displayAgencyPermissions
    );

    const redirectTo = includeAllAgencies
      ? 'administratorAgencies'
      : 'administratorAgency';

    if (!includeOneAgency && !includeAllAgencies) {
      return <Redirect to={route('dashboard')} />;
    }

    return <Redirect to={route(redirectTo, { agencyId })} />;
  }
}

const mapStateToProps = state => {
  return {};
};

export default DashboardPage(mapStateToProps)(Container);
