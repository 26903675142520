import callback from '../../utils/callback';
import OrganizationEndpoints from '../../../../api/organization/organizationEndpoints';

export default ({ userIntegrationId }, next) => dispatch => {
  return OrganizationEndpoints.getOrganizationalUnitsByUserIntegrationId(
    userIntegrationId
  )
    .then(response => {
      const ouList = response.map(ou => {
        return { value: ou.id, label: ou.displayName };
      });

      callback(next, null, ouList);
    })
    .catch(error => {
      callback(next, error);
    });
};
