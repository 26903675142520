import React from 'react';

import { AddEquipmentButton } from './EquipmentButtons';
import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from 'APP_COMPONENTS/custom-sections/sections/Sections.styled';
import FilterableColumnTable from 'APP_COMPONENTS/filterable-column-table/FilterableColumnTable';

const EquipmentPanel = ({
  configs,
  equipmentRows,
  fetchDataCallback,
  pageSize,
  loading,
  totalElements,
  newEquipment,
  onActive,
}) => (
  <StyledCollapse
    bordered={false}
    defaultActiveKey={[]}
    expandIconPosition="right"
    onChange={() => {
      onActive(true);
    }}
  >
    <StyledPanel
      header={<StyledPanelTitle size="large">Equipment</StyledPanelTitle>}
      key="userEquipmentRecord"
      className="section-panel"
      extra={
        <AddEquipmentButton
          isEnabled={configs.isEditable}
          onClick={newEquipment}
        />
      }
    >
      <FilterableColumnTable
        columns={configs.columns}
        data={equipmentRows}
        fetchDataCallback={fetchDataCallback}
        rowKey="id"
        pageSize={pageSize}
        loading={loading}
        totalRows={totalElements}
        filterToggle={false}
      />
    </StyledPanel>
  </StyledCollapse>
);

export default EquipmentPanel;
