import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Button, message } from 'antd';

import {
  StyledRow,
  StyledRowPagination,
  StyledPagination,
} from '../../../audit-logs/audit-logs-table.styled';
import AuditViewLogsSearchFilters from '../AgencyAuditViewLogsSearchFilters';
import SortAndFilterTable from '../../../../components/filterable-sortable-table/SortAndFilterTable';
import { auditLogsColums } from '../AgencyAuditViewLogsTableColumns';
import parseDate from '../../../../utils/parse-date';
import { download } from '../../../../utils/download';
import exportAuditCsv from '../../actions/export-audit-csv';

const StyledTable = styled.div`
  margin: 12px 7px 0 0;
`;

const AuditViewLogsPage = ({
  agencyId,
  tenantId,
  dispatch,
  auditLogs,
  timezone,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: { content },
    total,
    pageSize,
  } = auditLogs;
  const currentDefaultForPagination = 1;

  const changePage = page => {
    setCurrentPage(page);
  };

  const actionTypes = {
    U: 'Updated',
    I: 'Inserted',
    D: 'Deleted',
  };

  const auditLogsFormatted = content?.map(log => {
    return {
      ...log,
      actionType: actionTypes[log.actionType],
      timestamp: parseDate(log.timestamp, timezone),
    };
  });

  const handleExport = () => {
    const {
      auditPageNumber,
      endDate,
      executedBy,
      initialDate,
      tableNames,
      tenantId,
      userId,
    } = auditLogs.payload;

    exportAuditCsv(
      auditPageNumber,
      total,
      endDate,
      executedBy,
      initialDate,
      tableNames,
      tenantId,
      userId
    )
      .then(response => {
        response.blob().then(blob => download(blob, 'user_profile_audit.csv'));
      })
      .catch(() => {
        message.error('There was an issue exporting CSV');
      });
  };

  return (
    <div>
      <StyledRow type="flex" gutter={16} justify="end">
        <AuditViewLogsSearchFilters
          agencyId={agencyId}
          tenantId={tenantId}
          dispatch={dispatch}
          auditPageNumber={currentPage}
          auditPageSize={pageSize}
        />
        <StyledTable>
          <SortAndFilterTable
            columns={auditLogsColums}
            data={auditLogsFormatted}
            agencyId={agencyId}
            pagination={false}
            rowKey={'name'}
            scroll={true}
          />
        </StyledTable>
      </StyledRow>
      {total > 0 && (
        <StyledRowPagination>
          <Col span={12}>
            <Button onClick={() => handleExport()}>Export CSV</Button>
          </Col>
          <Col span={12}>
            <StyledPagination
              defaultCurrent={currentDefaultForPagination}
              current={currentPage}
              total={total}
              pageSize={pageSize}
              onChange={changePage}
            />
          </Col>
        </StyledRowPagination>
      )}
    </div>
  );
};

export default AuditViewLogsPage;
