import { authorizedRequest } from 'APP_ROOT/api/utils';
import { route } from 'APP_ROOT/utils/request';
import urlBuilder from 'APP_ROOT/utils/url-builder';

const BASE_URI = process.env.REACT_APP_SSO_URI + '/auth/v1';

export const getRoles = agencyId => {
  const url = route(urlBuilder(`${BASE_URI}/agencies/${agencyId}/roles`));
  return authorizedRequest('GET', url, null, 'Bearer');
};
