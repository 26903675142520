import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';

class pageViewEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'http://localhost:3003';
    const apiPrefix = 'organizations/v1';
    super(
      process.env.REACT_APP_ORGANIZATIONS_SERVICE_URI || DEV_URI,
      apiPrefix
    );
  }

  createUserProfileLog(tenantId, userId) {
    const returnResponse = true;
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/tenants/:tenantId/views/user-profile/:userId/failure'
        ),
        method: 'POST',
      },
      { tenantId, userId },
      {},
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request, returnResponse);
  }

  updateUserProfileLog(tenantId, auditId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/views/:auditId/success'),
        method: 'PATCH',
      },
      { tenantId, auditId },
      {},
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }
}

export default new pageViewEndpoints();
