import callback from '../../administrator/utils/callback';
import organizationEndpoints from '../../../api/organization/organizationEndpoints';

export default (
  organizationalUnitId,
  documents,
  attributes,
  next
) => dispatch => {
  return organizationEndpoints
    .postOrganizationalUnitDocuments(organizationalUnitId, documents)
    .then(() =>
      organizationEndpoints
        .patchOrganizationalUnit(organizationalUnitId, attributes)
        .then(response => {
          callback(next, null, response);
        })
        .catch(error => {
          callback(next, error);
        })
    )
    .catch(error => {
      callback(next, error);
    });
};
