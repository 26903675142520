import { get, set, isPlainObject, isEmpty, some, isNull } from 'lodash';

import {
  symlinkFromPropertyName,
  symlinkToPropertyName,
  symlinkIndexPropertyName,
  symlinksPropName,
} from 'APP_ROOT/utils/create-symlink';

import { setNestedRepeaterReference, shouldBeInData } from './utils';

const getDataByConditions = (
  data,
  formFields,
  obj,
  formData,
  acc,
  parentKey,
  arrayItem,
  arrayIndex
) => {
  const fieldMetadata = get(formFields, parentKey);
  if (Array.isArray(obj) && !isPlainObject(fieldMetadata)) {
    obj.forEach((arrItem, arrIndex) => {
      const k = parentKey ? `${parentKey}.${0}` : 0;
      getDataByConditions(
        data,
        formFields,
        arrItem,
        formData,
        acc,
        k,
        arrItem,
        arrIndex
      );
      setNestedRepeaterReference(formData, parentKey, acc, arrItem, arrIndex);
    });
  } else if (isPlainObject(obj)) {
    const objectStructure = fieldMetadata || obj;
    Object.keys(objectStructure).forEach(key => {
      if (
        key === symlinksPropName &&
        !isEmpty(obj[key]) &&
        !some(obj[key], isNull)
      ) {
        // for nested repeaters to control who is nested where
        // is used a special data structure (name stored in
        // symlinksPropName), is important to save this information
        // to keep data integrity
        set(acc, symlinksPropName, data[symlinksPropName]);
        // nested repeaters
        return obj[key].reduce((result, r) => {
          const {
            [symlinkFromPropertyName]: from,
            [symlinkToPropertyName]: to,
            [symlinkIndexPropertyName]: index,
          } = r;
          const mappedKey = getDataByConditions(
            data,
            formFields,
            obj[to],
            formData,
            result,
            `${from}.${index}.${to}`,
            arrayItem,
            arrayIndex
          );
          return mappedKey ? { ...result, [to]: mappedKey } : result;
        }, acc);
      } else {
        const k = parentKey ? `${parentKey}.${key}` : key;
        getDataByConditions(
          data,
          formFields,
          obj[k],
          formData,
          acc,
          k,
          arrayItem,
          arrayIndex
        );
      }
    });
  } else {
    if (fieldMetadata === undefined) return;
    shouldBeInData(
      data,
      fieldMetadata,
      formData,
      parentKey,
      arrayItem,
      arrayIndex,
      acc
    );
  }
};

export default getDataByConditions;
