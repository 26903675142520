import React from 'react';

import DashboardPage from 'APP_COMPONENTS/dashboard';
import withoutClutter from 'APP_COMPONENTS/without-clutter';

import getRoute from 'APP_ROOT/utils/get-route';
import {
  VIEW_ALL,
  VIEW_USER,
  ADD_USER,
  VIEW_PERMISSIONS,
  CREATE_PERMISSIONS,
  UPDATE_PERMISSIONS,
  CREATE_FROM_OU,
  VIEW_ALL_USERS,
  CREATE_DEFAULT,
} from './AgencyUsers.constants';
import ListOfUsers from './ListOfUsers';
import UserProfile from './UserProfile';

import AdministratorWrapper from '../Administrator.styled';
import withOwnershipGuard from '../components/agency-ownership-guard';

import { hasPermissions } from '../../../utils/admin';

export class Component extends React.Component {
  getActionFromProps = (
    userIdOrCreate = VIEW_ALL_USERS,
    permissions,
    sameUser
  ) => {
    let canDo;
    switch (userIdOrCreate) {
      case '':
      case VIEW_ALL_USERS:
        canDo = hasPermissions(permissions, VIEW_PERMISSIONS);
        return canDo ? VIEW_ALL : '';
      case CREATE_FROM_OU:
      case CREATE_DEFAULT:
        canDo = hasPermissions(permissions, CREATE_PERMISSIONS);
        return canDo ? ADD_USER : '';
      default:
        // if we need to check for user permissions, we should validate
        // sameUser && PERMISSIONS.viewOwnUserProfile
        canDo =
          hasPermissions(permissions, [
            ...VIEW_PERMISSIONS,
            ...UPDATE_PERMISSIONS,
          ]) || sameUser;
        return canDo ? VIEW_USER : '';
    }
  };

  render() {
    const {
      match,
      dispatch,
      session,
      history,
      route,
      hasOwnership,
    } = this.props;
    const { params } = match;
    const { userId = '', agencyId } = params;

    const { currentUser = {} } = session;
    const { permissions = [] } = currentUser;

    const sameUser =
      params.agencyId == currentUser.agencyId &&
      params.userId == currentUser.id;
    const currentAction = this.getActionFromProps(
      userId,
      permissions,
      sameUser
    );

    if (userId === CREATE_FROM_OU) {
      params.from = 'peopleOu';
    }

    if (currentAction === '') {
      history.push(getRoute('dashboard'));
    }

    return (
      <AdministratorWrapper>
        {currentAction === VIEW_ALL && (
          <ListOfUsers
            agencyId={agencyId}
            session={session}
            history={history}
            route={route}
            hasOwnership={hasOwnership}
          />
        )}
        {[VIEW_USER, ADD_USER].includes(currentAction) && (
          <UserProfile
            userId={userId}
            agencyId={agencyId}
            action={currentAction}
            dispatch={dispatch}
            session={session}
            params={params}
            history={history}
            hasOwnership={hasOwnership}
          />
        )}
      </AdministratorWrapper>
    );
  }
}

export default withoutClutter(
  DashboardPage()(withOwnershipGuard(Component, 'administratorAgencyUsers'))
);
