import { Icon } from 'antd';
import React from 'react';

import ModalTitle from 'APP_COMPONENTS/common/modal/title';

const successTitle = (
  <ModalTitle type={'success'}>
    <Icon type="check-circle" /> <span>Success!</span>
  </ModalTitle>
);

export default successTitle;
