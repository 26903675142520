import { createSelector } from 'reselect';
import { get, find, first } from 'lodash';

import { emptyObject } from '../constants/form';

export const getSession = createSelector(
  state => state.session,
  session => session
);

export const getSessionWithAuthState = createSelector(
  [state => state.session, (_, storage) => storage],
  (session, storage) =>
    Object.assign({}, session, {
      loggedIn: !!storage.get('token', null),
    })
);

export const getAgency = createSelector(getSession, session =>
  get(session, ['currentUser', 'agency'], {})
);

export const getCurrentUser = createSelector(getSession, session =>
  get(session, ['currentUser'], {})
);

export const getActiveUser = createSelector(getSession, session =>
  String(get(session, 'currentUser.id', ''))
);

export const getAgencyId = createSelector(getAgency, agency =>
  get(agency, ['id'], null)
);

export const getAgencyTZ = createSelector(getAgency, agency =>
  get(agency, ['timezone'], null)
);

export const getAgencyReasons = createSelector(getSession, session =>
  get(session, ['currentUser', 'agencyReasons'], {})
);

export const getAgencyReasonsData = createSelector(
  getAgencyReasons,
  agencyReasons => agencyReasons.data
);

export const getCurrentUserPermissions = createSelector(getSession, session =>
  get(session, ['currentUser', 'permissions'], [])
);

export const getCurrentUserRoles = createSelector(getSession, session =>
  get(session, ['currentUser', 'roles'], []).map(role => role.name)
);

export const getModulesConfiguration = createSelector(getSession, session => {
  const modules = get(session, ['currentUser', 'agency', 'configurations'], []);
  const enabledModules = modules.filter(item => {
    const options = get(item, 'options');
    const enable = find(options, ['name', 'enable']);
    const enableValues = get(enable, 'values');
    const isEnabled = get(first(enableValues), 'enable', true);
    return isEnabled;
  });
  return enabledModules.map(value => value.entity);
});

export const getLinkConfigurations = createSelector(getSession, session => {
  const configurations = get(
    session,
    ['currentUser', 'agency', 'configurations'],
    []
  );
  const filterLink = configurations.filter(item => item.entity === 'link');
  const options = get(filterLink[0], 'options', []);
  return options;
});

export const getTemplatesConfiguration = createSelector(getSession, session =>
  find(
    get(session, ['currentUser', 'agency', 'configurations'], []),
    item => item.entity === 'template'
  )
);

export const getTemplateConfiguration = templateType =>
  createSelector(getSession, session => {
    const configs = find(
      get(session, ['currentUser', 'agency', 'configurations'], []),
      item => item.entity === 'template'
    );

    if (configs) {
      return get(configs, 'options', [])
        .filter(config =>
          get(config, 'values', [])
            .map(valueItem => valueItem.scope)
            .includes(templateType)
        )
        .map(config => {
          const configValue =
            find(config.values, item => item.scope === templateType) || {};

          return {
            name: config.name,
            ...configValue,
          };
        })
        .reduce((res, config) => ({ ...res, [config.name]: config }), {});
    }

    return emptyObject;
  });
