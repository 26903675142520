import styled from 'styled-components';
import Layout from 'antd/lib/layout';

import {
  GRAY_1,
  SIDEBAR_ACTIVE,
  SIDEBAR_ACTIVE_BG,
  SIDEBAR_BG,
  SIDEBAR_DIVIDER,
  SIDEBAR_DIVIDER_2,
} from '../../config/theme';

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  &.ant-layout-sider {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0;
    background-color: ${SIDEBAR_BG};
    overflow-x: hidden;
    transition: none;
    z-index: 990;
    @media screen and (max-width: 480px), (max-width: 992px) {
      overscroll-behavior: contain;
    }
  }

  .logo {
    margin: 0;
    padding: 15px;

    img {
      max-width: 100%;
    }
  }

  .ant-menu {
    background-color: transparent;
    color: ${GRAY_1};
    border-top: 1px solid ${SIDEBAR_DIVIDER};
  }

  .ant-menu-inline .ant-menu-item::after,
  .ant-menu-vertical .ant-menu-item::after {
    right: auto;
    left: 0;
    border-right-width: 5px;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: ${SIDEBAR_ACTIVE};
    background-color: ${SIDEBAR_ACTIVE_BG};
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .anticon,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .bdm-icon {
    color: ${SIDEBAR_ACTIVE};
  }

  .ant-menu-inline,
  .ant-menu-vertical {
    border-right: none;
  }

  .ant-menu-item {
    .ant-badge {
      width: 100%;

      .ant-badge-count {
        right: -15px;
        top: 0;
      }
    }
  }

  .ant-menu > .ant-menu-item-divider {
    background-color: ${SIDEBAR_DIVIDER_2};
    margin: 5px ${props => (props.collapsed ? 10 : 15)}px;
  }
  /* stylelint-disable */
  .ant-menu-vertical.ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu-inline-collapsed > .ant-menu-item {
    line-height: 42px;
    height: 43px;
  }

  .ant-menu-inline-collapsed .ant-menu-selected,
  .ant-menu-inline-collapsed .ant-menu-item-selected {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: auto;
      bottom: 0;
      border-right: 8px solid #fff;
    }
  }

  &.ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout-sider-collapsed
    .ant-menu-submenu-vertical
    > .ant-menu-submenu-title::after {
    display: none;
  }

  .ant-menu-inline-collapsed {
    .ant-menu-item {
      .anticon,
      .bdm-icon {
        font-size: 26px;
      }

      .ant-badge-count {
        opacity: 0;
      }
    }
  }

  .ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-item,
  .ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
  .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 16px !important;
  }
`;

export default StyledSider;
