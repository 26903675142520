import React, { useState, useEffect } from 'react';

import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from '../../components/custom-sections/sections/Sections.styled';
import { StyledRow } from '../people/People.styled';
import Col from 'antd/lib/col';
import Table from 'antd/lib/table';
import { message } from 'antd';
import getOuComments from './actions/comments/get-ou-comments';
import OrganizationCommentModal from '../../components/organization-profile/organization-comments/OrganizationCommentModal';
import addOuComments from './actions/comments/add-ou-comments';
import updateOuComments from './actions/comments/update-ou-comments';
import deleteOuComment from './actions/comments/delete-ou-comments';
import SectionAddButton from '../../components/custom-sections/sections/SectionAddButton';

const { Column } = Table;

const OrgProfileCommentTable = ({ ouId, manageComments = false, timezone }) => {
  const VERTICAL_SCROLL_SIZE = 350;
  const MODAL_TITLE = 'Comment';

  const [commentList, setCommentList] = useState([]);
  const [loadingTableData, setLoadingTableData] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalComment, setModalComment] = useState('');
  const [modalId, setModalId] = useState('');
  const [addFlag, setAddFlag] = useState(false);

  useEffect(() => {
    fetchCommentList();
  }, []);

  const fetchCommentList = async () => {
    await getOuComments(ouId, false, timezone)
      .then(data => {
        setCommentList(data.data);
      })
      .catch(e => {
        message.error('There was an problem loading comments section.');
        setCommentList([]);
      })
      .finally(() => {
        setLoadingTableData(false);
      });
  };

  const renderCommentButton = () => {
    if (manageComments)
      return (
        <SectionAddButton
          onClick={() => openDetail('', null, true)}
          buttonTitle="Add Comment"
        />
      );
  };

  const renderActionColumn = (_text, _record) => {
    return (
      <a
        href={void 0}
        onClick={() => openDetail(_record.comment, _record.id, false)}
      >
        Details
      </a>
    );
  };

  const openDetail = (comment = '', commentId, addFlag = false) => {
    setModalState(true, comment, commentId, addFlag);
  };

  const onCancel = () => {
    setModalState(false, '', '', false);
  };

  const onDelete = async () => {
    setLoadingSave(true);
    await deleteOuComment(ouId, modalId)
      .then(() => {
        setModalState(false, '', '', false);
        message.success('Deleted comment successfully');
        fetchCommentList();
      })
      .catch(error => {
        message.error('There was an issue deleting comment. Please try again.');
      });

    setLoadingSave(false);
  };

  const setModalState = async (visible, comment, modalId, addFlag) => {
    setVisible(visible);
    setModalComment(comment);
    setModalId(modalId);
    setAddFlag(addFlag);
  };

  const onSave = async () => {
    setLoadingSave(true);
    if (addFlag == true) {
      if (modalComment == '') {
        setLoadingSave(false);
        return;
      }
      await addOuComments(ouId, modalComment)
        .then(() => {
          setModalState(false, '', '', false);
          message.success('Added comment successfully');
          fetchCommentList();
        })
        .catch(e => {
          message.error('There was an issue adding comment. Please try again.');
        });
    } else {
      await updateOuComments(ouId, modalId, modalComment)
        .then(() => {
          setModalState(false, '', '', false);
          message.success('Updated comment successfully');
          fetchCommentList();
        })
        .catch(e => {
          message.error(
            'There was an issue updating comment. Please try again.'
          );
        });
    }
    setLoadingSave(false);
  };

  const onModalCommentChange = e => {
    setModalComment(e.target.value);
  };

  return (
    <StyledCollapse
      bordered={false}
      defaultActiveKey=""
      expandIconPosition="right"
    >
      <StyledPanel
        header={<StyledPanelTitle size="large">Comments</StyledPanelTitle>}
        key="Comments"
        className="section-panel extra-margin-bottom-75"
        extra={renderCommentButton()}
      >
        <StyledRow>
          <Col span={24}>
            <div className="responsive-table">
              <Table
                dataSource={commentList}
                rowKey="created"
                loading={loadingTableData}
                scroll={{ y: VERTICAL_SCROLL_SIZE }}
                pagination={false}
              >
                <Column
                  title="Comment"
                  width="50%"
                  dataIndex="comment"
                  key="comment"
                />
                <Column
                  title="Created"
                  defaultSortOrder="descend"
                  sorter={(a, b) => a.created.localeCompare(b.created)}
                  dataIndex="created"
                  key="created"
                />
                <Column title="Updated" dataIndex="updated" key="updated" />
                <Column
                  title="Actions"
                  key="actions"
                  render={renderActionColumn}
                />
              </Table>
            </div>
          </Col>
          <OrganizationCommentModal
            addFlag={addFlag}
            comment={modalComment}
            commentId={modalId}
            loading={loadingSave}
            manageOuPermission={manageComments}
            onDelete={() => onDelete()}
            onCancel={() => onCancel()}
            onSave={() => onSave()}
            onChange={e => onModalCommentChange(e)}
            title={MODAL_TITLE}
            visible={visible}
          ></OrganizationCommentModal>
        </StyledRow>
      </StyledPanel>
    </StyledCollapse>
  );
};

export default OrgProfileCommentTable;
