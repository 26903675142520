import { createReducer } from 'redux-create-reducer';
import get from 'lodash/get';

import {
  GET_AGENCY_TEMPLATES_REQUEST,
  GET_AGENCY_TEMPLATES_FULLFILLED,
  GET_AGENCY_TEMPLATES_REJECTED,
  GET_TEMPLATE_WORKFLOWS_REQUEST,
  GET_TEMPLATE_WORKFLOWS_FULLFILLED,
  GET_TEMPLATE_WORKFLOWS_REJECTED,
} from './ConfigureWorkflows.actionTypes';

const initialState = {
  templates: {},
  workflows: {},
  templateWorkflows: {},
};

export const initialAgencyTemplateListState = {
  loading: true,
  entries: {},
  error: null,
};

export const initialTemplateState = {
  loading: false, // FIXME: should be true, implement with ticket to get WFs
  error: false,
  workflows: [],
};

const events = {
  [GET_AGENCY_TEMPLATES_REQUEST]: state => ({
    ...state,
    templates: {
      ...initialAgencyTemplateListState,
      ...state.templates,
      loading: true,
      error: null,
    },
  }),
  [GET_AGENCY_TEMPLATES_FULLFILLED]: (state, { payload }) => ({
    ...state,
    templates: {
      ...state.templates,
      loading: false,
      entries: payload.entries.reduce(
        (items, item) => ({ ...items, [item.id]: item }),
        {}
      ),
    },
    templateWorkflows: {
      ...state.templateWorkflows,
      ...payload.entries.reduce(
        (items, item) => ({
          ...items,
          [`${payload.agencyId}.${item.id}`]: {
            ...initialTemplateState,
            ...get(state, `templateWorkflows.${payload.agencyId}.${item.id}`),
            templateId: item.id,
            agencyId: payload.agencyId,
          },
        }),
        {}
      ),
    },
  }),
  [GET_AGENCY_TEMPLATES_REJECTED]: (state, { payload }) => ({
    ...state,
    templates: {
      ...state.templates,
      loading: false,
      error: payload.error,
    },
  }),
  [GET_TEMPLATE_WORKFLOWS_REQUEST]: (state, { payload }) => {
    const _path = `${payload.agencyId}.${payload.templateId}`;
    const templateWorkflows = get(state, 'templateWorkflows', {});

    return {
      ...state,
      templateWorkflows: {
        ...templateWorkflows,
        [_path]: {
          ...get(templateWorkflows, _path, {}),
          loading: true,
        },
      },
    };
  },
  [GET_TEMPLATE_WORKFLOWS_FULLFILLED]: (state, { payload }) => {
    const _path = `${payload.agencyId}.${payload.templateId}`;
    const templateWorkflows = get(state, 'templateWorkflows', {});

    return {
      ...state,
      templateWorkflows: {
        ...templateWorkflows,
        [_path]: {
          ...get(templateWorkflows, _path, {}),
          loading: false,
          workflows: payload.entries,
        },
      },
    };
  },
  [GET_TEMPLATE_WORKFLOWS_REJECTED]: (state, { payload }) => {
    const _path = `${payload.agencyId}.${payload.templateId}`;
    const templateWorkflows = get(state, 'templateWorkflows', {});

    return {
      ...state,
      templateWorkflows: {
        ...templateWorkflows,
        [_path]: {
          ...get(templateWorkflows, _path, {}),
          loading: false,
          error: payload.error,
        },
      },
    };
  },
};

export default createReducer(initialState, events);
