import { createReducer } from 'redux-create-reducer';

import { SET_REPORT_LIST_FILTERS } from '../actions';

const initialState = {};

export default createReducer(initialState, {
  [SET_REPORT_LIST_FILTERS]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
    };
  },
});
