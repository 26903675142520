import React from 'react';
import { Route, Switch } from 'react-router';

import Protected from '../../containers/protected';

const transformToRoutes = (routes = []) => {
  return routes.map((route, idx) => {
    const {
      childRoutes = [],
      component,
      render,
      protectedWith = [],
      protectedWithKey,
      ...opts
    } = route;
    const config = { component };

    if (protectedWith.length) {
      config.component = Protected(component, protectedWith, protectedWithKey);
    }

    if (childRoutes.length) {
      return (
        <Route
          key={`${route.name}${idx}`}
          {...opts}
          render={props => (
            <Switch>
              {transformToRoutes([
                ...childRoutes,
                {
                  ...opts,
                  ...config,
                },
              ])}
            </Switch>
          )}
        />
      );
    }

    return <Route key={`${route.name}${idx}`} {...opts} {...config} />;
  });
};

export default transformToRoutes;
