import styled from 'styled-components';
import { Modal } from 'antd';

const StyledModal = styled(Modal)`
  .row-upload {
    margin-bottom: 22px;
    margin-top: 22px;
  }

  .row-important {
    margin-top: 20px;
  }
`;

export default StyledModal;
