import React, { Fragment } from 'react';
import { Form, Row, Cascader } from 'antd';
import FieldSelect from '../../components/FieldSelect';
import FieldCheckbox from '../../components/FieldCheckbox';

import {
  REPEATING_VALUE,
  STAR_NUMBER,
} from '../../constants/fieldAutocomplete';

const STYLE_OPTIONS = { width: 260 };
const { Item } = Form;
const itemLayoutOptions = {
  colon: false,
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};
const itemLayoutContributorOptions = {
  colon: false,
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default class AutocompleteFieldOptions extends React.Component {
  onSelectChange = source => {
    const { onChange, value } = this.props;
    const { filterNonOpUsers } = value;

    onChange &&
      onChange({
        ...value,
        source,
        fromRepeating: false,
        filterNonOpUsers: source === REPEATING_VALUE ? false : filterNonOpUsers,
      });
  };

  onMultiselectChange = values => {
    const { onChange, value } = this.props;

    onChange &&
      onChange({ source: value.source, repeater: values, fromRepeating: true });
  };

  onSelectOrCriteriaChange = orCriteria => {
    const { onChange, value } = this.props;

    onChange &&
      onChange({ ...value, orCriteria: [orCriteria], fromRepeating: false });
  };

  onCheckboxChange = filter => {
    const { onChange, value } = this.props;

    onChange &&
      onChange({ ...value, filterNonOpUsers: filter, fromRepeating: false });
  };

  onCheckboxIsContributor = isContributorSection => {
    const { onChange, value } = this.props;

    onChange && onChange({ ...value, isContributorSection });
  };

  renderAutocompleteOptions = () => {
    const {
      selectOptions,
      multiselectOptions,
      value,
      orCriteriaOptions,
      contributorSection,
    } = this.props;
    const {
      source,
      repeater = [],
      orCriteria,
      filterNonOpUsers = false,
    } = value;

    const showCascader = source == REPEATING_VALUE;
    const sourceLabel = contributorSection ? 'Contributors Source' : 'Source';
    const layoutOptions = contributorSection
      ? itemLayoutContributorOptions
      : itemLayoutOptions;

    return (
      <Fragment>
        <Row>
          <Item key="source" label={sourceLabel} {...layoutOptions}>
            <FieldSelect
              value={source}
              style={STYLE_OPTIONS}
              onChange={this.onSelectChange}
              selectOptions={selectOptions}
            />
          </Item>
        </Row>
        <Row>
          {showCascader && (
            <Item
              className="search-criteria"
              key="repeating"
              label="Repeating elements"
              {...layoutOptions}
            >
              <Cascader
                value={repeater}
                options={multiselectOptions}
                style={STYLE_OPTIONS}
                onChange={this.onMultiselectChange}
                placeholder="Please select"
              />
            </Item>
          )}
          {!showCascader && (
            <Item
              className="search-criteria"
              key="orCriteria"
              label="Search Criteria"
              {...layoutOptions}
            >
              <FieldSelect
                defaultValue={STAR_NUMBER}
                value={orCriteria ? orCriteria[0] : STAR_NUMBER}
                style={STYLE_OPTIONS}
                onChange={this.onSelectOrCriteriaChange}
                selectOptions={orCriteriaOptions}
              />
            </Item>
          )}
        </Row>
        {!showCascader && (
          <Row>
            <Item
              key="filter-non-op"
              label="Exclude Non-Op OU Users"
              {...layoutOptions}
            >
              <FieldCheckbox
                value={filterNonOpUsers}
                onChange={this.onCheckboxChange}
              />
            </Item>
          </Row>
        )}
      </Fragment>
    );
  };

  render() {
    const { value, contributorSection } = this.props;
    const { isContributorSection = false } = value;

    const renderOptions = contributorSection ? isContributorSection : true;

    return (
      <Fragment>
        {contributorSection && (
          <Row>
            <Item
              key="source"
              label="Is Contributor Section"
              {...itemLayoutContributorOptions}
            >
              <FieldCheckbox
                value={isContributorSection}
                onChange={this.onCheckboxIsContributor}
              />
            </Item>
          </Row>
        )}
        {renderOptions && this.renderAutocompleteOptions()}
      </Fragment>
    );
  }
}
