import { get } from 'lodash';
import { GET_REPORTS_REQUEST } from './';
import { getReports } from '../api/reports';
import getReportsSuccess from './get-reports-success';
import getReportsError from './get-reports-error';

export default (
  page,
  filters = [],
  dataFilters = [],
  keywords = '',
  agencyIdFromOptions,
  next = () => {},
  view = ''
) => (dispatch, getState) => {
  const {
    session: {
      currentUser: {
        agencyId,
        organizationalUnitId = '',
        hierarchyKey = '',
      } = {},
    } = {},
    report: { per_page } = {},
  } = getState();
  const currentAgencyId = agencyIdFromOptions || agencyId;

  const filter = [...filters];

  const dataFilter = [...dataFilters];

  const filterConfig = {
    limit: per_page,
    skip: (page - 1) * per_page,
  };

  dispatch({ type: GET_REPORTS_REQUEST, payload: { filter, page } });

  const cb = (...args) =>
    next && typeof next === 'function' ? next(...args) : null;

  if (currentAgencyId) {
    return new Promise((resolve, reject) => {
      getReports({
        agencyId: currentAgencyId,
        filter,
        dataFilter,
        filterConfig,
        keywords,
        hierarchyKey,
        organizationalUnitId,
        view,
      })
        .then(response => {
          const { results: reports, total: count } = response;
          dispatch(getReportsSuccess(reports, { count }));
          cb(null, reports);
          resolve(reports);
        })
        .catch(error => {
          dispatch(getReportsError(get(error, ['response', 'status'])));
          cb(error);
          reject(error);
        });
    });
  }

  cb(null, []);
  return Promise.resolve([]);
};
