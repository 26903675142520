import React, { useState, useEffect } from 'react';
import { Alert, Divider, Button, Icon, Popconfirm } from 'antd';

import StepsComponent from './StepsComponent';
import { StyledRow, StyledInput, StyledCol } from './index.styled';
import {
  getTenant,
  stepsArray,
  copyRoleToClipboard,
  createCustomNotificacionMessageObject,
} from './utils';
import { getDataFromToken } from '../../../../actions/utils';
const ModalContent = ({
  agencyId,
  agencyProfile,
  isTenantLoading,
  onClickPasteButton = () => {},
  pasteButtonState,
  onConfirmClean = () => {},
  onCancelClean = () => {},
  getTenantIdStatus = () => {},
}) => {
  const [tenantId, setTenantID] = useState('');
  const { name } = agencyProfile;

  const setTenantIdFrom = async (
    agencyId,
    agencyIdFromToken,
    integrationIdFromToken
  ) => {
    if (+agencyId === agencyIdFromToken) {
      setTenantID(integrationIdFromToken);
    } else {
      const result = await getTenant(agencyId);
      const { integrationId } = result;
      if (result) setTenantID(integrationId);
    }
  };

  const getTenantId = async agencyId => {
    const {
      agencyId: agencyIdFromToken,
      agency: { integrationId },
    } = getDataFromToken();
    setTenantIdFrom(agencyId, agencyIdFromToken, integrationId);
    getTenantIdStatus(false);
  };

  useEffect(() => {
    getTenantId(agencyId);
  }, []);

  const getAlertDescription = stepsArray => {
    return (
      <StepsComponent
        direction="horizontal"
        size="small"
        stepsArray={stepsArray}
        progressDot
      />
    );
  };
  const renderCopyButton = (
    onClick = () => {},
    functionValue,
    isLoading = false
  ) =>
    isLoading ? (
      <Icon type="loading" />
    ) : (
      <Button
        type="link"
        icon="copy"
        size="small"
        onClick={() => onClick(functionValue)}
      />
    );
  const onAgencyIdButtonClick = id => {
    const successMessage = createCustomNotificacionMessageObject(
      id,
      'Agency Id'
    );
    const failureMessage = createCustomNotificacionMessageObject();
    copyRoleToClipboard(successMessage, failureMessage, `"agencyId":${id}`);
  };
  const onTenantIdButtonClick = tenantId => {
    const successMessage = createCustomNotificacionMessageObject(
      tenantId,
      'Tenant Id'
    );
    const failureMessage = createCustomNotificacionMessageObject();
    copyRoleToClipboard(
      successMessage,
      failureMessage,
      `"tenantId":"${tenantId}"`
    );
  };
  const renderPasteAndClearButton = (buttonObject, pasteButtonState) => {
    if (pasteButtonState) {
      return (
        <Popconfirm
          title="Are you sure to remove the data?"
          onConfirm={onConfirmClean}
          onCancel={onCancelClean}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            icon={buttonObject.icon}
            block
            ghost={pasteButtonState}
          >
            {buttonObject.label}
          </Button>
        </Popconfirm>
      );
    }
    return (
      <Button
        type="primary"
        icon={buttonObject.icon}
        block
        onClick={() => onClickPasteButton()}
        ghost={pasteButtonState}
      >
        {buttonObject.label}
      </Button>
    );
  };
  const buttonObject = {
    icon: pasteButtonState ? 'delete' : 'form',
    label: pasteButtonState ? 'Remove data' : 'Paste',
  };

  return (
    <>
      <StyledRow>
        <StyledCol>
          <Alert
            message="How to create a new role"
            description={getAlertDescription(stepsArray)}
            type="info"
            showIcon
          />
        </StyledCol>
      </StyledRow>
      <Divider orientation="left">Agency Information</Divider>
      <StyledRow>
        <StyledCol xs={24} sm={12} md={12} lg={12}>
          <StyledInput addonBefore="Agency" value={name} disabled={true} />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol xs={24} sm={12} md={12} lg={12}>
          <StyledInput
            addonBefore="Agency Id"
            value={agencyId}
            disabled={true}
            addonAfter={renderCopyButton(onAgencyIdButtonClick, agencyId)}
          />
        </StyledCol>
        <StyledCol xs={24} sm={12} md={12} lg={12}>
          <StyledInput
            addonBefore="Tenant Id"
            value={tenantId}
            disabled={true}
            addonAfter={renderCopyButton(
              onTenantIdButtonClick,
              tenantId,
              isTenantLoading
            )}
          />
        </StyledCol>
      </StyledRow>
      <Divider />
      <StyledRow>
        <StyledCol xs={24} sm={24} md={24} lg={24}>
          {renderPasteAndClearButton(buttonObject, pasteButtonState)}
        </StyledCol>
      </StyledRow>
    </>
  );
};

export default ModalContent;
