import get from 'lodash/get';
import has from 'lodash/has';
import isPlainObject from 'lodash/isPlainObject';

import getOverrides from '../get-field-overrides';
import { getDefaultFieldValueByType } from '../form';
import getRepeaterPresentation from './get-repeater-presentation';
import { isSymlinked, symlinkPathPropertyName } from '../create-symlink';

export default ({
  fields,
  property,
  index,
  parent,
  parentKey,
  overrides,
  data,
  enums,
  getPresentation,
  withProfileData = false,
}) => {
  const fieldScheme = getOverrides(
    { ...property, dataKey: property.key, parentKey },
    overrides
  )();
  const {
    key = '',
    properties = [],
    field_type = 'string',
    options: { defaultValue = null, loopFromEnumRef } = {},
    fromSource = null,
    type,
  } = fieldScheme;
  const isSymlinkedRepeater = isSymlinked(parent);
  let fromSourceValue =
    isSymlinkedRepeater && key === symlinkPathPropertyName
      ? get(parent, key, null)
      : null;

  if (fromSource && isPlainObject(fromSource)) {
    const { source = '', key: getKey = '', field = '' } = fromSource;

    const fieldKey = parentKey ? [parentKey, index, field] : field;
    const fieldValue = get(data, fieldKey, '__');
    const sourceResult = get(data, [`__${source}`, fieldValue, getKey], null);

    if (withProfileData && sourceResult) {
      fromSourceValue = sourceResult;
    }
  } else if (loopFromEnumRef) {
    const loopData = get(enums, loopFromEnumRef, []).map(item => ({
      value: item,
    }));

    fromSourceValue = loopData;
  }

  if (has(property, 'properties') && type === 'repeater')
    getRepeaterPresentation({
      fields,
      property,
      key,
      index,
      defaultValue,
      properties,
      loopFromEnumRef,
      fromSourceValue,
      parent,
      parentKey,
      data,
      getPresentation,
      withProfileData,
    });
  else {
    parent[key] = getDefaultFieldValueByType(
      field_type,
      fromSourceValue !== null ? fromSourceValue : defaultValue
    );
  }
};
