const userProfileFormName = 'manageUserProfile';

export { userProfileFormName };

export const allStandardFields = [
  'userPicture',
  'agency',
  'benchmarkId',
  'employeeId',
  'postId',
  'starNumber',
  'firstName',
  'middleName',
  'lastName',
  'suffix',
  'rank',
  'birthday',
  'gender',
  'referencingGender',
  'race',
  'ethnicity',
  'height',
  'weight',
  'dominantHand',
  'roles',
  'certified',
  'sworn',
  'customField1',
  'hireDate',
  'unitAssignment',
  'appointmentDate',
  'supervisor',
  'contEducation',
  'hideProfilePicture',
];
