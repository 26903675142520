import { createReducer } from 'redux-create-reducer';
import { v4 as uuid } from 'uuid';
import {
  has,
  get,
  first,
  isPlainObject,
  keys,
  orderBy,
  pick,
  mergeWith,
  last,
  merge,
  some,
  find,
  cloneDeep,
  isEmpty,
} from 'lodash';
import { LOCATION_CHANGE } from 'react-router-redux';

import {
  SYNC_FORM_REVIEW_DATA,
  GET_FORM_REVIEW_PRESENTATION,
  GET_FORM_ACTIONS_REQUEST,
  GET_FORM_ACTIONS_SUCCESS,
  GET_FORM_ACTIONS_ERROR,
  GET_FORM_TEMPLATE,
  GET_FORM_TEMPLATE_SUCCESS,
  GET_FORM_TEMPLATE_ERROR,
  REQUEST_FORM_SUBMIT,
  CREATE_DYNAMIC_ENUM_REF_REVIEW,
  ADD_REPEATER_ITEM_REVIEW,
  REMOVE_REPEATER_ITEM_REVIEW,
  SYNC_REPEATERS_REVIEW,
  SYNC_FORM_VALIDATION_REVIEW,
  SET_REVIEWER_ID,
  SET_RESOLUTION,
  GET_OFFICERS_SUCCESS,
  GET_RANKS_SUCCESS,
  SET_SOURCE_SELECTION,
  GET_FORM_TEMPLATES_SUCCESS,
  UPDATE_ATTACHMENT,
} from '../actions';
import { mapFormData, mapFormState, transformData } from '../utils/form';
import {
  hasSymlinks,
  createSymlink,
  symlinksPropName,
  removeSymlink,
  symlinkPathPropertyName,
} from '../utils/create-symlink';

const selectedFormReviewInitialState = {
  data: {},
  actions: [],
  template: null,
  fetched: false,
  state: {},
  validations: {
    form: {
      touched: false,
      dirty: false,
      submitRequested: false,
      submitting: false,
    },
    sections: {},
  },
  presentation: null,
  tab: 0,
  enums: {},
  sources: {},
  selectedReviewerId: null,
  selectedResolution: null,
};

const initialState = {
  selectedForm: selectedFormReviewInitialState,
  templates: {},
  presentations: {},
  list: [],
  entries: {},
  loading: false,
  error: null,
  submitRequested: false,
};

const getEvents = (schema = {}, context) => {
  const getCustomEvents = (item = {}) => {
    const { options: { events = null } = {}, type = '' } = item;
    const { key = '', ...rest } = item;
    const fieldKey = type === 'repeater' ? 'parentKey' : 'dataKey';

    if (key && events) {
      return events.reduce((result, listener) => {
        if (has(listener, 'event') && has(listener, 'trigger')) {
          return {
            ...result,
            [listener.event]: {
              ...get(result, listener.event, {}),
              [listener.trigger]: {
                [fieldKey]: key,
                ...rest,
              },
            },
          };
        }

        return result;
      }, context);
    }

    return {};
  };

  const mapFields = (context = {}, accumulated = {}) => {
    const { properties = [] } = context;

    return properties.reduce((result, property, index) => {
      const combinedResult = {
        ...result,
        ...getCustomEvents(properties[index], result),
      };

      return {
        ...combinedResult,
        ...mapFields(properties[index], combinedResult),
      };
    }, accumulated);
  };

  return mapFields(get(schema, ['formSchema', 'form'], {}));
};

const events = {
  [SYNC_FORM_REVIEW_DATA]: (state, { payload }) => {
    const { selectedForm: currentForm, presentations = {} } = state;

    const {
      data: currentFormData,
      state: currentState,
      presentationType = '',
      sources: currentSources = {},
      syncs = {},
    } = currentForm;

    const presentation = presentations[presentationType];

    const data = {
      ...presentation,
      ...currentFormData,
      ...mapFormData(payload.data, currentForm.data),
    };

    const syncedRepeaters = Object.keys(syncs).reduce((acc, syncerKey) => {
      const { syncTo, syncKeys } = syncs[syncerKey];
      const parent = get(data, [syncerKey]);
      const reducedData = syncTo.reduce((result, key, index) => {
        const childData = get(data, [key]);
        const syncedChild = childData.map(childItem => {
          const parentItem =
            parent.find(parentItem => parentItem.id === childItem.__parentId) ||
            first(parent);
          const parentIndex =
            parent.findIndex(
              parentItem => parentItem.id === childItem.__parentId
            ) || 0;
          const syncedKeys = !syncKeys
            ? {}
            : Object.keys(syncKeys[key] || {}).reduce(
                (acc, k) => ({
                  ...acc,
                  [syncKeys[key][k]]: parentItem[k],
                }),
                {}
              );
          return {
            ...childItem,
            ...syncedKeys,
            __parentId: parentItem.id,
            __parentIndex: parentIndex,
            __parentHumanIndex: parentIndex + 1,
          };
        });
        result[key] = orderBy(syncedChild, ['__parentIndex']);
        return result;
      }, {});
      return {
        ...acc,
        ...reducedData,
      };
    }, {});
    const syncedData = {
      ...data,
      ...syncedRepeaters,
    };

    const formState = {
      ...currentState,
      ...mapFormState(payload.state, currentForm.state),
    };

    const validations = {
      ...currentForm.validations,
      form: {
        ...currentForm.validations.form,
        touched: true,
        submitting: false,
      },
    };

    const sources = {
      ...currentSources,
      ...Object.keys(payload.data).reduce((result, key) => {
        const itemData = get(payload, ['data', key], '');
        const firstItem = Array.isArray(itemData)
          ? first(itemData.filter(item => item))
          : '';
        const isObject = isPlainObject(firstItem);
        const newId = uuid();

        if (isObject) {
          const index = first(Object.keys(itemData));
          const parentKey = currentSources[key];
          const hasParent = has(currentSources, parentKey);

          if (hasParent) {
            const id = get(currentSources, [parentKey, index, 'id'], newId);

            return {
              ...result,
              [parentKey]: {
                ...get(currentSources, [parentKey], {}),
                [index]: {
                  ...get(currentSources, [parentKey, index], {}),
                  ...get(data, [key, index], {}),
                  ...firstItem,
                  id,
                },
              },
            };
          }
        }

        return {};
      }, {}),
    };

    const selectedForm = {
      ...currentForm,
      data: syncedData,
      state: formState,
      validations,
      sources,
      changedFields: payload.data,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SYNC_FORM_VALIDATION_REVIEW]: (
    state,
    { payload: { errors, bulk, reset = false } }
  ) => {
    let sections = {
      ...state.selectedForm.validations.sections,
    };

    const fstate = errors
      ? merge({}, state.selectedForm.state, errors)
      : state.selectedForm.state;

    if (!!bulk) {
      sections = keys(bulk).reduce((result, tab, index) => {
        return {
          ...result,
          [tab]: {
            ...sections[tab],
            dirty: !!bulk[tab].length > 0,
            errors: bulk[tab],
          },
        };
      }, {});
    } else {
      sections = {
        ...sections,
        [state.selectedForm.tab]: {
          dirty: !!errors,
          touched: true,
          errors: errors || [],
        },
      };
    }

    const formDirty = some(
      keys(sections).map(section => sections[section].dirty),
      Boolean
    );

    const form = {
      ...state.selectedForm.validations.form,
      dirty: formDirty,
      submitting: false,
    };

    const validations = {
      ...state.selectedForm.validations,
      form,
      sections,
    };

    const selectedForm = {
      ...state.selectedForm,
      state: reset ? {} : fstate,
      validations,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [UPDATE_ATTACHMENT]: (state, { payload: { attachment, field } }) => {
    const currentSelectedForm = state.selectedForm;
    const currentData = currentSelectedForm.data;

    const replacedAttachments = get(currentData, field, []).map(file => {
      if (file.id === attachment.id) {
        return {
          ...file,
          ...attachment,
        };
      }

      return file;
    });

    const selectedForm = isEmpty(replacedAttachments)
      ? currentSelectedForm
      : {
          ...currentSelectedForm,
          data: {
            ...currentData,
            [field]: replacedAttachments,
          },
        };

    return {
      ...state,
      selectedForm,
    };
  },
  [GET_FORM_REVIEW_PRESENTATION]: (
    state,
    {
      payload: {
        schema,
        parentSchema: parentTemplateSchema = {},
        type,
        formFields,
        formData,
        requirementCompliantFields,
        templateId,
        noteData = {},
      },
    }
  ) => {
    const presentations = {
      ...get(state, ['presentations'], {}),
      [type]: schema,
    };

    const getDefaultData = fields =>
      keys(fields).reduce((result, tab, index) => {
        return {
          ...result,
          ...keys(schema.fields[tab]).reduce((res, field, fieldIndex) => {
            res[field] = schema.fields[tab][field];

            return res;
          }, {}),
        };
      }, {});

    const defaultData = getDefaultData(schema.fields);
    const withStructure = (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        const structure = first(objValue);
        if (typeof structure === 'string') {
          // if we call mergeWith, it converts the string into
          // an array of letters, which is wrong
          return merge(objValue, srcValue);
        } else {
          const srcValueLength =
            srcValue && srcValue.length ? srcValue.length : 1;
          const size =
            objValue.length >= srcValueLength
              ? objValue.length
              : srcValueLength;
          const arrayStructure = Array(size).fill(structure);
          return arrayStructure.map((i, index) =>
            mergeWith({}, i, srcValue[index], withStructure)
          );
        }
      }
      if (isPlainObject(objValue))
        return mergeWith({}, objValue, srcValue, withStructure);
      return objValue !== undefined ? srcValue : objValue;
    };
    const currentFormData = get(state, ['selectedForm', 'data'], {});
    const whitelistedKeys = [
      '__users',
      'currentUserId',
      '__reports',
      'linkedFormSubmitted',
    ];

    const templateSchema = get(state.entries, templateId, {});
    const selectedReviewerId = get(
      state,
      'selectedForm.selectedReviewerId',
      null
    );
    const selectedResolution = get(
      state,
      'selectedForm.selectedResolution',
      null
    );

    const data = transformData(
      mergeWith(
        pick(currentFormData, whitelistedKeys),
        defaultData,
        formData,
        withStructure
      ),
      templateSchema,
      parentTemplateSchema
    );

    const events = {
      ...getEvents(templateSchema),
    };

    const reviewFormData = {
      ...data,
      ...noteData,
    };

    const selectedForm = {
      ...get(state, ['selectedForm'], initialState),
      data: Object.assign({}, reviewFormData, {
        reviewer: selectedReviewerId
          ? selectedReviewerId
          : get(reviewFormData, 'reviewer', undefined),
        action: selectedResolution
          ? selectedResolution.value
          : get(reviewFormData, 'action', undefined),
        requires: selectedResolution
          ? selectedResolution.requires
          : get(reviewFormData, 'requires', []),
      }),
      events,
      presentation: type,
      formFields: {
        ...formFields,
        requirementCompliantFields,
      },
    };

    return {
      ...state,
      presentations,
      selectedForm,
    };
  },
  [GET_FORM_TEMPLATE]: state => ({
    ...state,
    loading: true,
    error: null,
    submitRequested: false,
  }),
  [GET_FORM_TEMPLATES_SUCCESS]: (state, { payload }) => {
    const templates = payload.filter(template => template.isNote);
    const list = templates.map(template => template.id);
    const entries = templates.reduce((result, template) => {
      const type = template.id;

      return {
        ...result,
        [type]: template,
      };
    }, {});

    return {
      ...state,
      list,
      entries,
      loading: false,
      error: null,
    };
  },
  [GET_FORM_TEMPLATE_SUCCESS]: (state, { payload: template }) => {
    const existent =
      find(Object.values(state.entries), item => item.id === template.id) || {};

    return {
      ...state,
      list: [
        ...state.list.filter(templateId => templateId !== template.id),
        template.id,
      ],
      entries: {
        ...state.entries,
        [template.id]: Object.assign({}, existent, template),
      },
      loading: false,
      error: null,
    };
  },
  [GET_FORM_TEMPLATE_ERROR]: (state, { payload: error }) => ({
    ...state,
    loading: false,
    error,
  }),
  [REQUEST_FORM_SUBMIT]: state => {
    const form = {
      ...get(state, ['selectedForm', 'validations', 'form'], {}),
      submitRequested: true,
    };

    const validations = {
      ...get(state, ['selectedForm', 'validations'], {}),
      form,
    };

    const selectedForm = {
      ...get(state, ['selectedForm'], selectedFormReviewInitialState),
      validations,
    };

    return {
      ...state,
      submitRequested: true,
      selectedForm,
    };
  },
  [CREATE_DYNAMIC_ENUM_REF_REVIEW]: (
    state,
    { payload: { enumRef, values } }
  ) => {
    const selectedForm = {
      ...state.selectedForm,
      enums: {
        ...get(state, ['selectedForm', 'enums'], {}),
        [enumRef]: values,
      },
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [ADD_REPEATER_ITEM_REVIEW]: (
    state,
    { payload: { keys, id, data: extraData, symlinkPath } }
  ) => {
    const { selectedForm: currentForm, presentations } = state;
    const {
      data: currentFormData = {},
      presentation: presentationType,
    } = currentForm;
    const presentation = get(presentations, [presentationType], {});
    const presentationFields = get(presentation, 'fields', []).reduce(
      (res, tabFields) => ({ ...res, ...tabFields }),
      {}
    );

    let currentSymlinks = [].concat(get(currentFormData, symlinksPropName, []));
    let dataWithSymlinks = {};

    const initialTemplate = (key, repeaterData) => {
      const newItemTemplate = first(get(presentationFields, key, []));

      if (hasSymlinks(newItemTemplate)) {
        const { symlinks, newItem, data } = createSymlink(
          newItemTemplate,
          key,
          repeaterData,
          currentSymlinks,
          Object.assign({}, currentFormData, dataWithSymlinks),
          presentationFields,
          symlinkPath
        );

        currentSymlinks = symlinks;
        dataWithSymlinks = data;

        return newItem;
      }

      const itemTemplate = Object.assign({}, newItemTemplate, {
        [symlinkPathPropertyName]: symlinkPath,
      });

      return itemTemplate;
    };

    const reducedData = keys.reduce((result, key, index) => {
      const currentRepeaterData = get(currentFormData, [key], []);
      result[key] = orderBy(
        [
          ...currentRepeaterData,
          {
            ...initialTemplate(key, currentRepeaterData),
            ...extraData,
            id,
          },
        ],
        ['__parentIndex']
      );
      return result;
    }, {});

    const data = {
      ...currentFormData,
      ...reducedData,
      ...dataWithSymlinks,
      [symlinksPropName]: currentSymlinks,
    };

    const selectedForm = {
      ...currentForm,
      data,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [REMOVE_REPEATER_ITEM_REVIEW]: (state, { payload: { key, index } }) => {
    const { selectedForm: currentForm, presentations } = state;
    const {
      data: currentFormData,
      state: currentState,
      presentation: presentationType,
    } = currentForm;

    const presentation = get(presentations, [presentationType], {});
    const presentationFields = get(presentation, 'fields', []).reduce(
      (res, tabFields) => ({ ...res, ...tabFields }),
      {}
    );

    const dataWithSymlinks = removeSymlink(
      key,
      index,
      currentFormData,
      presentationFields
    );

    const currentFieldState = cloneDeep(currentState[key]) || [];
    const reducedState = {
      [key]: currentFieldState.filter((item, i) => index !== i),
    };

    const selectedForm = {
      ...currentForm,
      data: dataWithSymlinks,
      state: {
        ...currentState,
        ...reducedState,
      },
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SYNC_REPEATERS_REVIEW]: (
    state,
    { payload: { parentKey, childrenKeys, syncKeys } }
  ) => {
    const { selectedForm: currentForm } = state;

    const syncs = {
      ...currentForm.syncs,
      [parentKey]: {
        syncTo: childrenKeys,
        syncKeys,
      },
    };

    const { data: currentFormData = {} } = currentForm;
    const parent = get(currentFormData, [parentKey]);
    const reducedData = childrenKeys.reduce((result, key, index) => {
      const childData = get(currentFormData, [key], []);
      const syncedChild = childData.map((childItem, childIndex) => {
        const parentItem =
          parent.find(parentItem => parentItem.id === childItem.__parentId) ||
          parent[childIndex] ||
          last(parent);
        const foundIndex = parent.findIndex(
          parentItem => parentItem.id === childItem.__parentId
        );
        const parentIndex = foundIndex > 0 ? foundIndex : 0;

        return {
          ...childItem,
          __parentId: parentItem.id,
          __parentIndex: parentIndex,
          __parentHumanIndex: parentIndex + 1,
        };
      });
      result[key] = orderBy(syncedChild, ['__parentIndex']);
      return result;
    }, {});

    const data = {
      ...currentFormData,
      ...reducedData,
    };

    const selectedForm = {
      ...currentForm,
      data,
      syncs,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [GET_FORM_ACTIONS_REQUEST]: state => ({
    ...state,
    loading: true,
  }),
  [GET_FORM_ACTIONS_SUCCESS]: (
    state,
    { payload: { actions = [], resolutionLabel = '' } }
  ) => ({
    ...state,
    loading: false,
    actions,
    resolutionLabel,
  }),
  [GET_FORM_ACTIONS_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
  [SET_REVIEWER_ID]: (state, { payload: reviewer }) => {
    const { selectedForm: currentForm } = state;
    const { data: currentData } = currentForm;
    const data = {
      ...currentData,
      reviewer,
    };
    const selectedForm = {
      ...currentForm,
      data,
      selectedReviewerId: reviewer,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_RESOLUTION]: (state, { payload: resolution }) => {
    const { selectedForm: currentForm } = state;
    const { data: currentData } = currentForm;
    const { value: action, requires } = resolution;
    const data = {
      ...currentData,
      action,
      requires,
    };
    const selectedForm = {
      ...currentForm,
      data,
      selectedResolution: resolution,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [LOCATION_CHANGE]: state => ({
    ...state,
    loading: true,
    selectedForm: selectedFormReviewInitialState,
  }),
  [GET_RANKS_SUCCESS]: (state, { payload: actionPayload = {} }) => {
    const { formName = 'reviewForm', ...payload } = actionPayload;

    if (formName !== 'reviewForm') {
      return state;
    }

    const currentSources = get(state, 'selectedForm.sources', {});

    const defaultOptions = {
      mapEnumValue: 'id',
      mapEnumLabel: ['name'],
    };
    const defaultSourceName = 'ranks';
    const options = Object.assign({}, defaultOptions, payload.options || {});
    const rankSource = `__${options.source || defaultSourceName}`;
    const mapLabelParts = Array.isArray(options.mapEnumLabel)
      ? options.mapEnumLabel
      : [options.mapEnumLabel];

    const enums = {
      ...get(state, 'selectedForm.enums', []),
      [payload.enumRef]: get(payload, 'data', []).map(rank => ({
        value: get(rank, options.mapEnumValue, rank.id),
        label: mapLabelParts.map(part => get(rank, part, '')).join(' '),
        data: rank,
      })),
    };

    const mappedRanks = get(payload, 'data', []).reduce(
      (result, rank) => ({
        ...result,
        [rank.id]: rank,
      }),
      {}
    );

    const sources = {
      ...currentSources,
      [rankSource]: mappedRanks,
    };

    const selectedForm = {
      ...get(state, 'selectedForm', {}),
      enums,
      sources,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [GET_OFFICERS_SUCCESS]: (state, { payload: actionPayload = {} }) => {
    const { formName = 'reviewForm', ...payload } = actionPayload;

    if (formName !== 'reviewForm') {
      return state;
    }

    const currentSources = get(state, 'selectedForm.sources', {});

    const defaultOptions = {
      mapEnumValue: 'id',
      mapEnumLabel: ['firstName', 'middleName', 'lastName'],
    };
    const defaultSourceName = 'users';
    const options = Object.assign({}, defaultOptions, payload.options || {});
    const usersSource = `__${options.source || defaultSourceName}`;
    const mapLabelParts = Array.isArray(options.mapEnumLabel)
      ? options.mapEnumLabel
      : [options.mapEnumLabel];

    const enums = {
      ...get(state, 'selectedForm.enums', []),
      [payload.enumRef]: get(payload, 'data', []).map(officer => ({
        value: get(officer, options.mapEnumValue, officer.id),
        label: mapLabelParts
          .map(part => get(officer, part, ''))
          .filter(Boolean)
          .join(' '),
        data: officer,
      })),
    };

    const mappedUsers = get(payload, 'data', []).reduce(
      (result, officer) => ({
        ...result,
        [officer.id]: officer,
      }),
      {}
    );

    const sources = {
      ...currentSources,
      [usersSource]: {
        ...get(currentSources, [usersSource], {}),
        ...mappedUsers,
      },
    };

    const selectedForm = {
      ...get(state, 'selectedForm', {}),
      enums,
      sources,
    };

    return {
      ...state,
      selectedForm,
    };
  },
  [SET_SOURCE_SELECTION]: (
    state,
    { payload: { item = {}, sourceKey = '', formName = 'reviewForm' } }
  ) => {
    if (formName !== 'reviewForm') {
      return state;
    }

    const {
      selectedForm: currentSelectedForm = {},
      selectedForm: { data: currentData = {} } = {},
    } = state;

    return {
      ...state,
      selectedForm: {
        ...currentSelectedForm,
        data: {
          ...currentData,
          [sourceKey]: {
            ...get(currentData, [sourceKey], {}),
            [item.id]:
              sourceKey === '__reports'
                ? pick(item, [
                    'id',
                    'formType',
                    'formNumber',
                    'number',
                    'formCategory',
                    'draftDate',
                  ])
                : item,
          },
        },
      },
    };
  },
};

export default createReducer(initialState, events);
