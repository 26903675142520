import { get, find } from 'lodash';

import getTemplateAtVersionBeforeCreateReport from './utils/get-form/getTemplateAtVersionBeforeCreateReport';
import getFormTemplatesSuccess from './get-form-templates-success';

const getReportsTemplates = ({
  getSchemaFor,
  withTemplates,
  agencyIdFromOptions,
}) =>
  async function withChunk(dispatch, getState) {
    const agencyId =
      agencyIdFromOptions || get(getState(), 'session.currentUser.agencyId');

    return new Promise(async resolve => {
      try {
        const templateSchemasResolutions = await Promise.all(
          getSchemaFor.map(template =>
            getTemplateAtVersionBeforeCreateReport({
              agencyId,
              template,
            })
          )
        );

        const templateSchemas = templateSchemasResolutions
          .filter(([error]) => !error)
          .map(([_, template]) => template);
        const templateSchemasIds = templateSchemas.map(
          ({ id: templateId }) => templateId
        );
        dispatch(
          getFormTemplatesSuccess(
            withTemplates.map(template => {
              if (templateSchemasIds.includes(template.id)) {
                const newTemplate = find(
                  templateSchemas,
                  item => item.id === template.id
                );

                return Object.assign({}, template, newTemplate);
              }

              return template;
            })
          )
        );

        resolve([null]);
      } catch (error) {
        resolve([error]);
      }
    });
  };

export default getReportsTemplates;
