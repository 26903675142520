import { some, isEqual, omit, memoize } from 'lodash';

const propsHasChanged = (nextProps, prevProps, omitKeys = []) =>
  some(
    Object.keys(omit(nextProps, omitKeys)).concat(
      Object.keys(omit(prevProps, omitKeys))
    ),
    propName => !isEqual(nextProps[propName], prevProps[propName])
  );

export default propsHasChanged;
export const propsHasChangedMemo = memoize(propsHasChanged);
