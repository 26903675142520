import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';
/**
 * This new class is meant to call auth/v2 security endpoints
 *
 */
class securityV2Endpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'http://localhost:3005';
    super(process.env.REACT_APP_SECURITY_SERVICE_URI || DEV_URI, 'auth/v2');
  }

  /**
   * Get Roles by Tenant Id
   *
   * @param {tenantId} tenantId
   * @param {isInternal} isInternal
   */
  getSecurityRolesByTenant(tenantId, isInternal) {
    return this.getSecurityRolesByExample({ tenantId, isInternal });
  }

  /**
   * Get Roles given a List
   *
   * @param {roleIds} roleIds
   * @param {isInternal} isInternal
   */
  getSecurityRolesByRoleIds(roleIds, isInternal) {
    return this.getSecurityRolesByExample({ roleIds, isInternal });
  }

  /**
   * Get Roles by AgencyId
   *
   * @param {agencyId} agencyId
   * @param {isInternal} isInternal
   */
  getSecurityRolesByAgencyId(agencyId, isInternal) {
    return this.getSecurityRolesByExample({ agencyId, isInternal });
  }

  postSecurityNewRolesByJsonFormat(newRoles) {
    return this.postSecurityRolesOnBody(newRoles);
  }

  patchSecurityAttributesToExistingRoles(id, payload) {
    return this.patchSecurityRoles('/:id', { id }, payload);
  }

  /**
   * This is a generic method to query roles table based on agencyId, roleIds or tenantId
   * For instance:
   *  example = {agencyId : 1}
   *  example = {roleIds : 1,2}
   *  example = {tenantId : '10b234f0-8de1-4a37-b18e-f11916f4f6a8'}
   *  example = {isInternal : false}
   * @param {{isInternal: isInternal, tenantId: tenantId},
   * {isInternal: isInternal, roleIds: roleIds},
   * {isInternal: isInternal, agencyId: agencyId}} example
   */
  getSecurityRolesByExample(example) {
    if (example['isInternal'] === undefined) {
      delete example['isInternal'];
    }
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/roles'),
        method: 'GET',
      },
      {},
      example
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  postSecurityRolesOnBody(body) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/roles'),
        method: 'POST',
      },
      {},
      {},
      body
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  patchSecurityRoles(
    pathComplement = '',
    params = {},
    queryParams = {},
    body = null
  ) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(`/roles${pathComplement}`),
        method: 'PATCH',
      },
      params,
      queryParams,
      body
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }
}

export default new securityV2Endpoints();
