import React, { useCallback, useEffect, useRef, useState } from 'react';

import useFieldValueDataNew from './useFieldValueDataNew';
import useFieldValueDataUpdate from './useFieldValueDataUpdate';

const useFieldValuesData = ({ equipmentRecord, equipmentForm }) => {
  const { equipment: { id } = {} } = equipmentRecord;
  const changeFieldValuesMethod = id
    ? useFieldValueDataUpdate
    : useFieldValueDataNew;

  return useFieldValue({ equipmentForm, changeFieldValuesMethod });
};

const useFieldValue = ({ equipmentForm, changeFieldValuesMethod }) => {
  const [equipment, setEquipment] = useState(equipmentForm || []);
  const prevEquipmentFormRef = useRef(equipmentForm);

  // Update equipment when equipmentForm changes
  useEffect(() => {
    if (prevEquipmentFormRef.current !== equipmentForm) {
      setEquipment(equipmentForm || []);
      prevEquipmentFormRef.current = equipmentForm;
    }
  }, [equipmentForm]);

  const onChangeFieldValues = useCallback((value, field) => {
    setEquipment(prevEquipment => {
      return changeFieldValuesMethod({
        equipment: prevEquipment,
        value,
        field,
      });
    });
  }, []);

  return { onChangeFieldValues, equipment };
};

export default useFieldValuesData;
