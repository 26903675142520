import { castArray, set, isPlainObject } from 'lodash';

import {
  escapeInput,
  unEscapeInput,
  genOptions,
  route,
  fetchRequest,
  REACT_APP_FORMS_SERVICE_URI,
  encodeFilter,
} from '../utils/request';

import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';
import { authorizedRequest } from './utils';
import apiEndpoints from 'APP_ROOT/modules/FormBuilder/services/apiEndpoints';
import { symlinksPropertiesPropName } from 'APP_ROOT/utils/create-symlink';

export const getFormSchema = ({ id = '', agencyId = '' }) => {
  const url = route(
    urlBuilder('/Agencies/:agencyId/formTemplates/:id', { id, agencyId })
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const getReports = async ({
  agencyId,
  filter: filters,
  dataFilter: dataFilters,
  filterConfig,
  keywords,
  hierarchyKey,
  organizationalUnitId,
  view,
}) => {
  const filter = encodeFilter(castArray(filters));
  const dataFilter = encodeFilter(castArray(dataFilters));

  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/formSubmissions`,
      { agencyId },
      {
        ...filterConfig,
        form: filter,
        data: dataFilter,
        keywords,
        hierarchyKey,
        organizationalUnitId,
        view,
      }
    )
  );
  const options = genOptions('GET', null, `Bearer ${storage.get('token')}`);

  const response = await fetchRequest(url, options);
  return unEscapeInput(response);
};

// SW-1278, there is a problem with broken nested repeater references
// where symlink$props instead of having an array of strings, has an
// array of objects like [{0:'s', 1:'t', 2:'r', 3:'i', 4:'n', 5: g'}],
// this method is intended to detect that broken pattern and fix it
const fixBrokenRepeater = data => (newData, key) => {
  const whiteList = [
    'history',
    '__repeaterSymlinks',
    '__reports',
    '__users',
    '__usersOnBehalf',
  ];

  return Object.keys(data).reduce((newData, key) => {
    if (!whiteList.includes(key) && Array.isArray(data[key])) {
      if (key === symlinksPropertiesPropName) {
        newData[key] = data[key].map(value =>
          isPlainObject(value) ? Object.values(value).join('') : value
        );
      } else {
        newData[key] = data[key].map(value =>
          isPlainObject(value)
            ? Object.keys(value).reduce(fixBrokenRepeater(value), {})
            : value
        );
      }
    } else {
      newData[key] = data[key];
    }
    return newData;
  }, {});
};

export const getReport = async ({ agencyId, id, filter: filters = [] }) => {
  const filter = encodeFilter(
    filters.include
      ? filters
      : {
          include: [...filters],
        }
  );

  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/formSubmissions/:id`,
      { agencyId, id },
      { filter }
    )
  );
  const options = genOptions('GET', null, `Bearer ${storage.get('token')}`);
  const response = await fetchRequest(url, options);
  const _response = unEscapeInput(response);

  _response.data = Object.keys(_response.data || {}).reduce(
    fixBrokenRepeater(_response.data),
    {}
  );

  return _response;
};

export const getDORReport = async id => {
  const url = route(urlBuilder('/BenchmarkUsers/dorData', null, { id }));
  const options = genOptions('GET', null, storage.get('token'));

  const response = await fetchRequest(url, options);
  const _response = unEscapeInput(response);

  _response.data = Object.keys(_response.data || {}).reduce(
    fixBrokenRepeater(_response.data),
    {}
  );

  return _response;
};

// WORKFLOW MIGRATION ENDPOINT

// We use the host to be migrated

export const postDraftReport = async ({ agencyId, templateId, data = {} }) => {
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/formSubmissions`,
      {
        // Changes Agencies -> agencies as requested
        agencyId,
      }
    )
  );

  // We provide the correct token structure
  const escapedData = escapeInput(data) || {};
  const options = genOptions(
    'POST',
    { templateId, data: escapedData },
    `Bearer ${storage.get('token')}`
  );

  const response = await fetchRequest(url, options);
  return unEscapeInput(response);
};

export const updateDraftReport = async ({ agencyId, id, data }) => {
  if (data.data) {
    set(data, 'data', escapeInput(data.data));
  }
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/formSubmissions/:id`,
      {
        agencyId,
        id,
      }
    )
  );
  const options = genOptions('PUT', data, `Bearer ${storage.get('token')}`);

  const response = await fetchRequest(url, options);
  return unEscapeInput(response);
};

export const updateAutoSaveDraft = async (fsId, id, data) => {
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:id/formSubmissions/:fsId/save-field`,
      {
        fsId,
        id,
      }
    )
  );
  const dataEscaped = { field: escapeInput(data) };
  const options = genOptions(
    'PUT',
    dataEscaped,
    `Bearer ${storage.get('token')}`
  );

  return await fetchRequest(url, options);
};

export const getFormTemplates = ({ filter: filters = {}, agencyId = '' }) => {
  const filter = encodeFilter(filters);
  const url = route(
    urlBuilder(`/Agencies/:agencyId/formTemplates`, { agencyId }, { filter })
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const getFormTemplate = (id, agencyId = '') => {
  const url = route(
    urlBuilder(`/Agencies/:agencyId/formTemplates/:id`, { agencyId, id })
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

//WRK-457 Change URL for getAction URL to Forms service URL
export const getActions = ({ agencyId: id, formSubmissionId, templateId }) => {
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:id/formSubmissions/:formSubmissionId/actions`,
      {
        id,
        formSubmissionId,
      },
      {
        templateId,
      }
    )
  );
  const options = genOptions('GET', null, `Bearer ${storage.get('token')}`);

  return fetchRequest(url, options);
};

//WRK-456 Change URL for postReport URL to Forms service URL
export const postReport = async ({
  agencyId,
  templateId,
  id,
  reviewer = undefined,
  data,
  notes = undefined,
  action = 'submit',
}) => {
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/formSubmissions/:id/submit`,
      {
        agencyId,
        id,
      }
    )
  );

  const singleReviewerArray = reviewer ? [reviewer] : [];
  const dataEscaped = escapeInput(data) || {};

  const options = genOptions(
    'POST',
    {
      reviewers: Array.isArray(reviewer) ? reviewer : singleReviewerArray,
      data: dataEscaped,
      action,
      notes,
      templateId,
    },
    `Bearer ${storage.get('token')}`
  );

  const response = await fetchRequest(url, options);
  return unEscapeInput(response);
};

/**
 * @param {number} agencyId
 * @param {number} id
 * @return {Promise}
 */
export function deleteReport(agencyId, id) {
  const url = route(
    urlBuilder(`${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/forms/:id`, {
      agencyId,
      id,
    })
  );

  const options = genOptions('DELETE', {}, `Bearer ${storage.get('token')}`);

  return fetchRequest(url, options);
}

export function deleteReportSoftDelete(id) {
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:id/updateSoftDelete`,
      { id },
      { isDeleted: true }
    )
  );

  const options = genOptions('POST', null, `Bearer ${storage.get('token')}`);
  return fetchRequest(url, options);
}

export async function updateReportByAdmin(reportData) {
  const { id, data: dataReport, agencyId } = reportData;
  const data = escapeInput({ isAdminUpdate: true, ...dataReport });
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/formSubmissions/:id`,
      { agencyId, id }
    )
  );
  const options = genOptions('PUT', { data }, `Bearer ${storage.get('token')}`);

  const response = await fetchRequest(url, options);
  return unEscapeInput(response);
}

export const getSupportedAgency = agencyId => {
  const url = route(
    urlBuilder(`${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/support`, {
      agencyId,
    })
  );
  const options = genOptions('GET', {}, `Bearer ${storage.get('token')}`);
  return fetchRequest(url, options);
};

export const getReportsCount = ({ agencyId, filter: filters }) => {
  const filter = encodeFilter(filters);
  const url = route(
    urlBuilder(
      '/Agencies/:agencyId/formSubmissions/count',
      { agencyId },
      { filter }
    )
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const getCaseload = ({ agencyId, filters = {} }) => {
  const filter = encodeFilter(filters);
  const url = route(
    urlBuilder('/Agencies/:agencyId/caseload', { agencyId }, { filter })
  );
  const options = genOptions('GET', null, storage.get('token'));
  return fetchRequest(url, options);
};

/**
 * @param {object} reqParams The request params.
 * @param {string} reqParams.userId An user id.
 * @param {object} reqParams.filters The loopback filters for the request
 * @return {Promise} A fetch request promise.
 */
export const getUserCaseLoad = ({ userId, filters = {} }) => {
  const filter = encodeFilter(filters);
  const url = route(
    urlBuilder('/BenchmarkUsers/:userId/caseload', { userId }, { filter })
  );
  const options = genOptions('GET', null, storage.get('token'));
  return fetchRequest(url, options);
};

/**
 * A POST request to /Agencies/:agencyId/formSubmission/:formSubmissionId/toggleTimeout
 *
 * @param {string} agencyId
 * @param {string} formSubmissionId
 * @return {Promise}
 */
export const postFormSubmissionToggleTimeout = (
  agencyId,
  formSubmissionId,
  reason,
  notes
) => {
  const url = route(
    `/Agencies/${agencyId}/formSubmissions/${formSubmissionId}/toggleTimeout`
  );
  return authorizedRequest('POST', url, {
    reasonId: reason,
    notes,
  });
};

export const getIzendaReports = ({ agencyId = '' }) => {
  const url = route(
    urlBuilder('/Agencies/:agencyId/analytics/reports/', { agencyId })
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

// WORKFLOW MIGRATION ENDPOINT
export const getAgencyTemplates = ({
  filter: filters = {},
  agencyId = '',
  canCreate = true,
}) => {
  const filter = encodeFilter(filters);

  // We use the host to be migrated
  //Set path to consume the new endpoint
  const url = route(
    urlBuilder(`${REACT_APP_FORMS_SERVICE_URI}Templates`, null, {
      filter,
      agencyId,
      includeCanCreate: canCreate,
    })
  );

  // We provide the correct token structure
  const options = genOptions('GET', null, `Bearer ${storage.get('token')}`);

  return fetchRequest(url, options);
};

export const getAgencyTemplateVersion = ({
  templateId,
  versionId,
  agencyId = '',
  removeOffFields = true,
}) => {
  const url = route(
    urlBuilder(
      `/templates/:templateId/versions/:versionId`,
      { templateId, versionId },
      {
        agencyId,
        removeOffFields,
      }
    )
  );

  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const getAgencyTemplate = ({
  templateId,
  agencyId = '',
  isDefault = false,
  onlyPublished = false,
  templateVersionId,
}) => {
  return apiEndpoints.getForm(
    agencyId,
    templateId,
    isDefault,
    onlyPublished,
    templateVersionId
  );
};

/**
 * Request to /BenchmarkUsers/{userId}/dorTranscript
 * @param {*} userId
 */
export const getDORTranscript = userId => {
  const url = route(`/BenchmarkUsers/${userId}/dorTranscript`);
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export function ctrContributorMarkSection(requestProps, requestData) {
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/formSubmissions/:formSubmissionId/sections/:sectionId/mark/:mark`,
      requestProps
    )
  );
  const options = genOptions(
    'PUT',
    requestData,
    `Bearer ${storage.get('token')}`
  );

  return fetchRequest(url, options);
}
