import { get, has } from 'lodash';
import moment from 'moment';
import { SET_FORM_VALUES } from './';
import { getFormData } from '../selectors/form';
import { getAgencyTZ } from '../selectors/session';

export default (fields, props) => (dispatch, getState) => {
  const state = getState();
  const timezone = getAgencyTZ(state);
  const data = getFormData(state, props);

  const injectedFields = Object.keys(fields).reduce((result, field) => {
    const key = field;
    const value = fields[key];
    if (has(value, 'includes') && value.includes('$inject')) {
      const parsedValue = value.split(':')[1];
      let newValue;
      switch (parsedValue) {
        case 'timestamp':
          newValue = moment()
            .tz(timezone)
            .format();
          break;

        default:
          newValue = get(data, parsedValue, '');
          break;
      }
      return {
        ...result,
        [key]: newValue,
      };
    }
    return {
      ...result,
      [key]: value,
    };
  }, {});

  dispatch({
    type: SET_FORM_VALUES,
    payload: injectedFields,
  });
};
