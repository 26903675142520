import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import parsedDate from '../../utils/parse-date';
import { find, isEmpty } from 'lodash';
import { getTimelineUser } from './utils';

class ReassignItem extends Component {
  state = {
    isLoadingTo: true,
    isLoadingFrom: true,
    reassignTo: '',
    reassignFrom: '',
  };

  componentDidUpdate() {
    const { toId, fromId } = this.state;
    const { item } = this.props;
    if (toId !== item.toId) {
      this.setState({ toId: item.toId });
      this.getReassignTo();
    }
    if (fromId !== item.fromId) {
      this.setState({ fromId: item.fromId });
      this.getReassignFrom();
    }
  }

  componentDidMount() {
    this.getReassignTo();
    this.getReassignFrom();
  }

  getReassignTo = async () => {
    const { item, agencyId } = this.props;
    const { toId } = item;
    const reassignTo = await getTimelineUser(toId, agencyId);
    this.setState({ reassignTo, isLoadingTo: false });
  };

  getParticipant = (participants = [], id) => {
    return find(participants, _item => _item.id === id);
  };

  getReassignFrom = async () => {
    const { item, agencyId, participants } = this.props;
    const { fromId } = item;
    const participant = await this.getParticipant(participants, fromId);
    if (isEmpty(participant)) {
      const reassignFrom = await getTimelineUser(fromId, agencyId);
      this.setState({ reassignFrom, isLoadingFrom: false });
    } else {
      this.setState({
        reassignFrom: `${participant.rank.name} ${participant.fullName}`,
        isLoadingFrom: false,
      });
    }
  };

  render() {
    const { item, index, timezone } = this.props;
    const { reassignTo, isLoadingTo, isLoadingFrom, reassignFrom } = this.state;

    if (isLoadingTo || isLoadingFrom)
      return (
        <ContentLoader
          height={5}
          width={90}
          speed={2}
          primaryColor={'#f3f3f3'}
          secondaryColor={'#ecebeb'}
          className="loader-share-item"
        >
          <rect x="0" y="0" rx="4" ry="4" width="90" height="5" />
        </ContentLoader>
      );

    return (
      <div className={`timeline-item-date-${index}`}>
        <p className={`timeline-item-date-${index}`}>
          {parsedDate(item.createdAt, timezone)}
        </p>
        <p className={`timeline-item-message-${index}`}>
          <span dangerouslySetInnerHTML={{ __html: reassignFrom }} /> has
          reassigned this report to{' '}
          <span dangerouslySetInnerHTML={{ __html: reassignTo }} />
        </p>
      </div>
    );
  }
}

export default ReassignItem;
