import getAllReports from 'APP_ROOT/actions/get-all-reports';

const getAllDraftReports = (
  compoundEnumRef,
  searchByFilter = [],
  activeUser,
  value,
  templatesName,
  callback
) => {
  return getAllReports(
    compoundEnumRef,
    [
      ...searchByFilter,
      { showLinked: false },
      {
        column: 'submitterId',
        operator: 'eq',
        value: activeUser,
      },
      { column: 'formType', operator: 'inq', value: templatesName },
      {
        column: 'status',
        operator: 'inq',
        value: ['DRAFT'],
      },
    ],
    [],
    '',
    value,
    callback
  );
};

export default getAllDraftReports;
