import { get, omit } from 'lodash';
import FieldSelect from '../../components/FieldSelect';

import componentsManager from '../../services/componentsManager';
import tabsManager from '../../services/tabsManager';
import getCustomFieldOptionsWithFallback from './getCustomFieldOptionsWithFallback';

export default class resetRepeaters {
  constructor(values, value = '') {
    this.currentFieldId = values.id; // current field id
    const repeaters = componentsManager.repeatersReference || {};
    const fields = componentsManager.fields;

    this.name = 'resetRepeaters';
    this.label = 'Reset repeaters';
    this.component = FieldSelect;
    this.defaultValue = [];
    this.mode = 'multiple';
    this.value = value;
    this.selectOptions = fields.map((tab, i) => {
      const repeater = repeaters[i] || [];
      return {
        label: tab.title,
        enums: repeater
          .filter(r => !r.fields[this.currentFieldId])
          .map(r => {
            const [rep] = componentsManager.findContainersByKey(
              fields[i].properties,
              r.repeaterKey
            );
            return {
              label: rep.reportingKey,
              value: r.repeaterKey,
            };
          }),
      };
    });
    // if no activeTabIndex then we are in review note mode
    if (tabsManager.activeTabIndex < 0) {
      const option = this.selectOptions.pop();
      this.selectOptions = [...this.selectOptions, ...option.enums];
    }
  }

  onSave = values => {
    const selectedOptions = get(values, this.name, this.valu);
    const { options } = values;

    return {
      ...omit(values, this.name),
      options: {
        ...options,
        [this.name]: selectedOptions.map(key => ({ key })),
      },
    };
  };

  getInitialValue = values => {
    const { options } = values;
    if (options === undefined) {
      return this.defaultValue;
    }
    const resetRepeaters = get(options, this.name);

    return resetRepeaters
      ? resetRepeaters.map(({ key }) => key)
      : this.defaultValue;
  };

  beforeOnChange = (value, values) => {
    const current = values.customFields.find(cf => cf.name === this.name);
    const currentValues = { ...values, id: current.currentFieldId };
    const field = new resetRepeaters(currentValues, value);

    return {
      customFields: values.customFields.map(cf =>
        cf.name !== this.name
          ? cf
          : getCustomFieldOptionsWithFallback(values)(field)
      ),
    };
  };
}
