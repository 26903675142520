import React from 'react';
import { Route } from 'react-router';

import ReportType from '../../containers/report-type';
import Reports from '../../containers/reports';
import RefactoredReportsTestUI from '../../containers/reports/uiWithRefactoredEndpoint';
import Protected from '../../containers/protected';
import { PERMISSIONS } from '../../utils/admin';

const reportsRoutes = [
  <Route
    path="/reports/create-new"
    name="createNewReport"
    key="createNewReport"
    component={Protected(ReportType, [
      PERMISSIONS.submitUOFReport,
      PERMISSIONS.submitVPReport,
      PERMISSIONS.submitDORReport,
      PERMISSIONS.submitIACReport,
      PERMISSIONS.submitIAIReport,
      PERMISSIONS.submitReport,
    ])}
  />,
  <Route
    path="/reports"
    exact={true}
    name="reports"
    key="reports"
    component={Protected(Reports, [
      PERMISSIONS.submitUOFReport,
      PERMISSIONS.submitVPReport,
      PERMISSIONS.submitDORReport,
      PERMISSIONS.submitIACReport,
      PERMISSIONS.submitIAIReport,
      PERMISSIONS.reviewUOFReport,
      PERMISSIONS.viewAllUOFReports,
      PERMISSIONS.reviewVPReport,
      PERMISSIONS.viewAllVPReports,
      PERMISSIONS.reviewDORReport,
      PERMISSIONS.reviewIAIReport,
      PERMISSIONS.reviewIACReport,
      PERMISSIONS.viewAllDailyObservationReports,
      PERMISSIONS.viewOwnDailyObservationReports,
      PERMISSIONS.viewAllInvestigations,
      PERMISSIONS.viewAllIntakes,
      PERMISSIONS.submitReport,
      PERMISSIONS.reviewReport,
      PERMISSIONS.viewReports,
      PERMISSIONS.listOUReportsByHK,
    ])}
  />,
  <Route
    path="/reports-refactored"
    exact={true}
    name="reports"
    key="reports"
    component={Protected(RefactoredReportsTestUI, [
      PERMISSIONS.submitUOFReport,
      PERMISSIONS.submitVPReport,
      PERMISSIONS.submitDORReport,
      PERMISSIONS.submitIACReport,
      PERMISSIONS.submitIAIReport,
      PERMISSIONS.reviewUOFReport,
      PERMISSIONS.viewAllUOFReports,
      PERMISSIONS.reviewVPReport,
      PERMISSIONS.viewAllVPReports,
      PERMISSIONS.reviewDORReport,
      PERMISSIONS.reviewIAIReport,
      PERMISSIONS.reviewIACReport,
      PERMISSIONS.viewAllDailyObservationReports,
      PERMISSIONS.viewOwnDailyObservationReports,
      PERMISSIONS.viewAllInvestigations,
      PERMISSIONS.viewAllIntakes,
      PERMISSIONS.submitReport,
      PERMISSIONS.reviewReport,
      PERMISSIONS.viewReports,
      PERMISSIONS.listOUReportsByHK,
    ])}
  />,
  <Route
    path="/reports/:id"
    exact
    name="reports"
    key="reports"
    component={Protected(ReportType, [
      PERMISSIONS.submitUOFReport,
      PERMISSIONS.submitVPReport,
      PERMISSIONS.submitDORReport,
      PERMISSIONS.submitIACReport,
      PERMISSIONS.submitIAIReport,
      PERMISSIONS.reviewUOFReport,
      PERMISSIONS.reviewVPReport,
      PERMISSIONS.reviewDORReport,
      PERMISSIONS.reviewIAIReport,
      PERMISSIONS.reviewIACReport,
      PERMISSIONS.viewAllUOFReports,
      PERMISSIONS.viewAllVPReports,
      PERMISSIONS.viewAllDailyObservationReports,
      PERMISSIONS.viewOwnDailyObservationReports,
      PERMISSIONS.viewAllInvestigations,
      PERMISSIONS.viewAllIntakes,
      PERMISSIONS.viewCaseLoad,
      PERMISSIONS.viewAllReports,
      PERMISSIONS.submitReport,
      PERMISSIONS.reviewReport,
      PERMISSIONS.viewReports,
      PERMISSIONS.listOUReportsByHK,
    ])}
  />,
  <Route
    path="/reports/:id/:viewAs"
    exact
    name="reportsViewAs"
    key="reportsViewAs"
    component={Protected(ReportType, [
      PERMISSIONS.submitUOFReport,
      PERMISSIONS.submitVPReport,
      PERMISSIONS.submitDORReport,
      PERMISSIONS.submitIACReport,
      PERMISSIONS.submitIAIReport,
      PERMISSIONS.reviewUOFReport,
      PERMISSIONS.reviewVPReport,
      PERMISSIONS.reviewDORReport,
      PERMISSIONS.reviewIAIReport,
      PERMISSIONS.reviewIACReport,
      PERMISSIONS.submitReport,
      PERMISSIONS.reviewReport,
    ])}
  />,
];

export default reportsRoutes;
