import styled from 'styled-components';
import { Button } from 'antd';

const ButtonGroup = Button.Group;

const ButtonWrapper = styled(ButtonGroup)`
  display: flex;
  justify-content: flex-start;

  .first-button {
    border-radius: 24px 0 0 24px;
  }

  .second-button {
    padding: 8px 0;
    border-radius: 0 24px 24px 0 !important;
    margin-left: inherit !important;
    border-left-color: #7b97e6 !important;
  }
`;

export default ButtonWrapper;
