import React from 'react';
import { connect } from 'react-redux';
import Icon from 'antd/lib/icon';

// Components
import PageHeader from 'APP_COMPONENTS/PageHeader';

// Actions
import getAgencies from '../actions/get-all-agencies';
import { getAgencies as selectAgencies } from '../Agencies.selectors';

import PageActions from './PageActions';
import Table from './AgenciesTable';
import Pagination from './Pagination';

export class ListOfAgencies extends React.PureComponent {
  state = {
    page: 1,
    query: '',
    searchClear: false,
  };

  componentDidMount() {
    this.search();
  }

  search = () => {
    const { dispatch } = this.props;
    const { query, page } = this.state;
    dispatch(
      getAgencies(page, true, {
        where: {
          or: [
            {
              name: {
                ilike: `%${query}%`,
                options: 'i',
              },
            },
          ],
        },
      })
    );
  };

  changePage = page => {
    window.scrollTo(0, 0);
    this.setState({ page }, () => this.search());
  };

  handlequerySubmit = searchText => {
    const {
      target: { value: query = '' },
    } = searchText;
    this.setState({ query, page: 1 }, () => this.search());
  };

  handlequeryChange = value => {
    const {
      target: { value: searchValue = '' },
    } = value;

    this.setState({
      query: searchValue,
      searchClear: !!searchValue,
    });
  };

  handlequeryClear = () => {
    this.setState(
      {
        query: '',
        searchClear: false,
        page: 1,
      },
      () => this.search()
    );
  };

  render() {
    const { agencies = {} } = this.props;
    const { query, searchClear } = this.state;
    const {
      total: { count = 0 },
      per_page = 8,
      page = 1,
      entries = {},
      list = [],
      loading = true,
    } = agencies;

    const searchSuffix = searchClear ? (
      <Icon type="close-circle" onClick={this.handlequeryClear} />
    ) : (
      <Icon type="search" />
    );

    const actions = [];

    return (
      <div>
        <PageHeader title="Agencies" actions={actions} showBackButton={false} />

        <div className="administrator-content">
          <PageActions
            onSearchBoxPressEnter={this.handlequerySubmit}
            onSearchBoxChange={this.handlequeryChange}
            searchSuffix={searchSuffix}
            query={query}
          />

          <Table
            dataSource={list.map(id => entries[id])}
            onChange={this.handleChange}
            loading={loading}
          />

          {count > 0 && (
            <Pagination
              onChange={this.changePage}
              page={page}
              per_page={per_page}
              total={count}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  agencies: selectAgencies(state),
});

export default connect(mapStateToProps)(ListOfAgencies);
