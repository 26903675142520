export default (input = {}, securityRoles, state, uuid) => {
  const {
    feet,
    inches,
    role = [],
    rank = '',
    agencyId = '',
    agency,
    sworn = false,
    weight,
    isDeleted,
    isActive,
    isNewUser,
    customField1 = {
      pointer: '',
      value: '',
    },
    ...rest
  } = input;
  let height = 0;

  if (!isNaN(feet)) {
    height += feet * 12;
  }
  if (!isNaN(inches)) {
    height += inches;
  }

  const assignedRoles = securityRoles.filter(securityRole =>
    role.some(assignedRole => assignedRole == securityRole.id)
  );

  return {
    ...rest,
    height,
    agencyId: parseInt(agencyId, 10),
    securityRolesId: assignedRoles.map(role => role.id),
    weight: weight ? weight + [] : '',
    title: rank,
    disableUser: !isNewUser ? !isActive : undefined,
    sworn,
    customField1: {
      pointer:
        state.customField1Value === customField1.value
          ? customField1.pointer
          : uuid,
      value: customField1.value
        ? customField1.value.replace(/[-]/g, '')
        : customField1.value,
    },
  };
};
