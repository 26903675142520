import { get, castArray, some } from 'lodash';

const complyKeepInData = (data, _keepInData) => {
  const keepInData = Array.isArray(_keepInData) ? _keepInData : [_keepInData];
  return keepInData.map(keepInDataRule => {
    const { key, expect, toBe = 'eq' } = keepInDataRule;
    const fieldValue = get(data, key, '');
    const expectValue = castArray(expect);
    if (toBe === 'inq') {
      return some(expectValue, item => fieldValue.includes(item));
    } else if (toBe === 'ninq') {
      return !some(expectValue, item => fieldValue.includes(item));
    } else if (toBe === 'neq') {
      return fieldValue !== expect;
    } else {
      return fieldValue === expect;
    }
  });
};

export default complyKeepInData;
