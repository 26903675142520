import {
  GET_WORKFLOW_REQUEST,
  GET_WORKFLOW_FULLFILLED,
  GET_WORKFLOW_REJECTED,
} from '../ConfigureWorkflows.actionTypes';

import { getWorkflow } from 'APP_ROOT/api/manageWorkflows';

export default (options, callback) => dispatch => {
  dispatch({ type: GET_WORKFLOW_REQUEST, payload: options });

  const next = (...args) =>
    callback && typeof callback === 'function' && callback(...args);

  return new Promise(async (resolve, reject) => {
    try {
      const entry = await getWorkflow(options);
      dispatch({
        type: GET_WORKFLOW_FULLFILLED,
        payload: Object.assign({}, options, { entry }),
      });
      resolve(entry);
      next(null, entry);
    } catch (error) {
      dispatch({
        type: GET_WORKFLOW_REJECTED,
        payload: Object.assign({}, options, { error }),
      });
      reject(error);
      next(error);
    }
  });
};
