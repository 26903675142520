import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, message, Spin } from 'antd';
import { Helmet } from 'react-helmet';

import loginSSO from '../../actions/loginSSO';

import { getSessionWithAuthState } from '../../selectors/session';

import DashboardPage from '../../components/dashboard';
import Header from '../../components/layout/header';
import StyledFooter from '../../components/layout/styled-footer';

import getHash from '../../utils/get-data-from-hash';
import route from '../../utils/get-route';
import storage from '../../utils/storage';

const SSO_URI = process.env.REACT_APP_SSO_URI;
const SSO_REDIRECT = process.env.REACT_APP_SSO_REDIRECT;
const SSO_REDIRECT_URI =
  '/oauth/authorize?response_type=token&client_id=benchmark&scope=openid+email+agency+roles+rank+permissions';
const SSO_PRE_LOGIN_URI = process.env.REACT_APP_SSO_PRE_LOGIN_URI;

class LoginPage extends Component {
  static getPageConfig() {
    return {
      guestAccess: true,
    };
  }

  UNSAFE_componentWillMount() {
    const { history, location, dispatch, session } = this.props;
    const { loggedIn } = session;
    const { push } = history;
    const token = getHash('access_token', location);

    if (token) {
      dispatch(
        loginSSO(token, (error, response) => {
          if (error) {
            message.error('Login process failed.', 5);
            return false;
          }

          storage.set('token', token);
          message.success('You have successfully logged in.', 5);
          const redirectOnLogin = SSO_PRE_LOGIN_URI + '?access_token=' + token;
          setTimeout(function() {
            window.location.replace(redirectOnLogin);
          }, 5000);
        })
      );
    } else if (loggedIn) {
      // No token
      push(route('dashboard'));
    } else {
      if (SSO_REDIRECT) {
        const origin = window.location.origin;
        window.location.replace(
          `${SSO_URI}${SSO_REDIRECT_URI}&redirect_uri=${origin}/login`
        );
      } else {
        window.location.replace(`${SSO_URI}${SSO_REDIRECT_URI}`);
      }
    }
  }

  render() {
    return (
      <Layout>
        <Helmet title="Authenticating..." />
        <Header />
        <StyledLayout>
          <StyledLogInForm className="login-form">
            <div className="logo">
              <img
                src={'../../benchmark-analytics-logo_color.svg'}
                alt="Benchmark Analytics"
              />
            </div>
            <Spin size="default" />
          </StyledLogInForm>
        </StyledLayout>
        <StyledFooter />
      </Layout>
    );
  }
}

LoginPage.propTypes = {
  form: PropTypes.object,
  login: PropTypes.object,
  dispatch: PropTypes.func,
};

const StyledLayout = styled(Layout)`
  &.ant-layout {
    width: 100%;
    background-color: #fff;
    min-height: auto;
    align-items: center;
    flex-direction: row;
  }
`;

const StyledLogInForm = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 300px;
  padding: 50px 0;
  margin: 0 auto;
  text-align: center;

  .logo {
    width: 100%;
    max-width: 300px;
    margin: 0 auto 25px;
    position: relative;
    padding-bottom: 35%;

    img {
      display: block;
      width: 100%;
      position: absolute;
    }
  }
`;

const mapState = state => ({
  session: getSessionWithAuthState(state, storage),
});

export default DashboardPage(mapState, null)(LoginPage);
