import styled from 'styled-components';
import { Collapse, Checkbox } from 'antd';
import { BLUE } from '../../../config/theme';

const { Panel } = Collapse;

const getFontSize = size => {
  switch (size) {
    case 'medium':
      return 14;
    case 'large':
      return 18;
    case 'x-large':
      return 20;
    default:
      return 12;
  }
};

export const StyledCollapse = styled(Collapse)`
  margin: 25px 15px;
  background-color: transparent;

  .extra-margin-bottom-75 {
    margin-bottom: 75px !important;
  }

  .section-panel {
    background-color: #fff;
    border-radius: 6px !important;
    margin-bottom: 25px;

    hr {
      border-color: #e8edf1;
      border-style: solid;
      margin-bottom: 20px;
    }

    .ant-collapse-header {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const StyledPanel = styled(Panel)`
  padding: 5px 5px;
`;

export const StyledCheckbox = styled(Checkbox)`
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 24px;
`;

export const StyledPageTitle = styled.h1.attrs({
  className: 'custom-section-page-title',
})`
  h1 {
    font-family: Lato-Medium, sans-serif;
    font-size: 24px;
    color: #000;
  }
`;

export const StyledPanelTitle = styled.h2.attrs({
  className: 'custom-section-page-title',
})`
  font-weight: 'normal';
  font-size: ${props => getFontSize(props.size)}px;
  color: #000;
  line-height: 1.2em;

  &.double-margin-bottom {
    margin-bottom: 30px;
  }

  .anticon {
    font-size: 0.8em;
    color: ${BLUE};
    cursor: help;
  }
`;
