import styled from 'styled-components';
import { Col } from 'antd';

export const ButtonWrapper = styled(Col)`
  border: 1px solid #a0a0a0;
  border-radius: 3px;
  width: 97%;
  height: auto;
  padding: 4px;
  font-size: 13px;
  margin: 2px;

  span {
    display: block;
    margin: 0;
  }

  .anticon {
    font-size: 1.2rem;
    margin-left: 10px;
  }

  &.is-dragging {
    opacity: 0.3;
  }

  &:hover {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
  }
`;
