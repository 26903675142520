import React, { Component } from 'react';
import Col from 'antd/lib/col';
import Input from 'antd/lib/input';
import Icon from 'antd/lib/icon';
import Pagination from 'antd/lib/pagination';
import Table from 'antd/lib/table';
import { Link } from 'react-router-dom';
import getRoute from 'APP_ROOT/utils/get-route';
import Button from 'antd/lib/button';
import get from 'lodash/get';
import moment from 'moment';
import { StyledRow, StyledRowPagination } from './People.styled';
import savePreviousPath from 'APP_ROOT/utils/savePreviousPath';

// Actions
import getUsersSearch from '../../actions/get-users-search';
// Containers
import DashboardPage from '../../components/dashboard';

import { hasPermissions, PERMISSIONS } from '../../utils/admin';
import { connect } from 'react-redux';

import PageHeader from '../../components/PageHeader/PageHeader';
import withoutClutter from '../../components/without-clutter';

const { Column } = Table;
const PER_PAGE = 50;

class People extends Component {
  state = {
    search: '',
    page: 1,
    searchClear: false,
    agencyId: 1,
  };

  componentDidMount() {
    this.setState(
      {
        search: this.props.filter,
        page: this.props.page,
        searchClear: !!this.props.filter,
        agencyId: this.props.agencyId ?? this.props.currentUser.agencyId,
      },
      this.onSearch
    );
  }

  changePage = page => {
    window.scrollTo(0, 0);
    this.setState({ page }, this.onSearch);
  };

  onSearch = () => {
    const { dispatch } = this.props;
    const { page, search, agencyId } = this.state;
    dispatch(
      getUsersSearch({
        searchTerm: search,
        page: page,
        pageSize: PER_PAGE,
        agencyId: agencyId,
      })
    );
  };

  handlePeopleSearchSubmit = () => {
    this.setState({ page: 1 }, this.onSearch);
  };

  handlePeopleSearchChange = value => {
    const {
      target: { value: searchValue = '' },
    } = value;

    this.setState({
      search: searchValue,
      searchClear: !!searchValue,
    });
  };

  handlePeopleSearchClear = () => {
    this.setState(
      {
        search: '',
        searchClear: false,
        page: 1,
      },
      this.onSearch
    );
  };

  render() {
    const {
      total = 0,
      page = 1,
      users = [],
      currentUser,
      loading = false,
      shouldRenderHeader = true,
      route = () => {},
    } = this.props;

    const agencyId = this.props.agencyId ?? currentUser.agencyId;

    const { search, searchClear } = this.state;

    const canCreateUsers = hasPermissions(currentUser.permissions, [
      PERMISSIONS.createAnyUser,
      PERMISSIONS.createAgencyUser,
    ]);

    const searchSuffix = searchClear ? (
      <Icon type="close-circle" onClick={this.handlePeopleSearchClear} />
    ) : (
      <Icon type="search" />
    );

    const getUnitDivision = record =>
      [record.unitAssignment, record.division]
        .reduce((res, unit) => {
          if (res.indexOf(unit) === -1) {
            return [...res, unit];
          }

          return res;
        }, [])
        .join(', ');

    return (
      <>
        {shouldRenderHeader && (
          <PageHeader
            title="People"
            actions={[
              <Link
                key="create"
                to={savePreviousPath(
                  getRoute('administratorAgencyUser', {
                    agencyId,
                    userId: 'create',
                  })
                )}
                disabled={!canCreateUsers}
              >
                <Button
                  type="primary"
                  size="default"
                  icon="plus"
                  disabled={!canCreateUsers}
                >
                  Add User
                </Button>
              </Link>,
            ]}
          />
        )}
        <div className="section-container">
          <StyledRow type="flex" gutter={16} justify="end">
            <Col span={6}>
              <Input
                placeholder="Search"
                size="default"
                onPressEnter={this.handlePeopleSearchSubmit}
                onChange={this.handlePeopleSearchChange}
                suffix={searchSuffix}
                value={search}
              />
            </Col>
          </StyledRow>

          <StyledRow>
            <Col span={24}>
              <div className="responsive-table">
                <Table dataSource={users} loading={loading} pagination={false}>
                  <Column
                    title="Name"
                    key="name"
                    render={(text, record) => (
                      <Link
                        to={savePreviousPath(
                          route('administratorAgencyUser', {
                            agencyId: record.agencyId,
                            userId: record.id,
                          })
                        )}
                      >
                        {record.lastName}, {record.firstName}
                      </Link>
                    )}
                  />
                  <Column
                    title="Title"
                    dataIndex="title"
                    key="title"
                    render={(text, record) => (
                      <span>
                        {record.title
                          ? record.title
                          : get(record, 'rank.name', '')}
                      </span>
                    )}
                  />
                  <Column
                    title="Star No."
                    dataIndex="starNumber"
                    key="starNumber"
                  />
                  <Column
                    title="Unit & Division"
                    dataIndex="unitAssignment"
                    key="unitAssignment"
                    render={(text, record) => (
                      <span>{getUnitDivision(record)}</span>
                    )}
                  />
                  <Column
                    title="Appointment Date"
                    dataIndex="appointmentDate"
                    key="appointmentDate"
                    render={(text, record) => {
                      const appointmentDate = get(record, 'appointmentDate');
                      const momentifiedAppointmentDate = appointmentDate
                        ? moment(appointmentDate).utc()
                        : moment();
                      return (
                        <div>
                          <div>
                            {appointmentDate &&
                              momentifiedAppointmentDate.format('M/D/Y')}{' '}
                          </div>
                          {appointmentDate && (
                            <div>
                              <b>
                                ({momentifiedAppointmentDate.fromNow(true)})
                              </b>
                            </div>
                          )}
                        </div>
                      );
                    }}
                  />
                  <Column
                    title="Last Imported"
                    dataIndex="lastImportedDate"
                    key="lastImportedDate"
                    render={(text, record) => {
                      const importedDate = get(record, 'lastImportedDate');
                      const momentifiedImportedDate = importedDate
                        ? moment(importedDate).utc()
                        : moment();
                      return (
                        <div>
                          <div>
                            {importedDate &&
                              momentifiedImportedDate.format(
                                'M/D/Y HH:mm:ss'
                              )}{' '}
                          </div>
                        </div>
                      );
                    }}
                  />
                </Table>
              </div>
            </Col>
          </StyledRow>

          {total > 0 && (
            <StyledRowPagination>
              <Col span={24} className="text-right">
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  total={total}
                  pageSize={PER_PAGE}
                  onChange={this.changePage}
                />
              </Col>
            </StyledRowPagination>
          )}
        </div>
      </>
    );
  }
}

const mapState = ({
  users: { data, entries, total, page, loading, filter = '' },
}) => ({
  users: data.map(id => ({ ...entries[id], key: id })),
  total,
  page,
  loading,
  filter,
});

export const UnwrappedPeople = connect(mapState)(People);
export default withoutClutter(DashboardPage(mapState, null)(People));
