import styled from 'styled-components';
import { GRAY_1, BLACK } from 'APP_ROOT/config/theme';

const FooterActions = styled.p`
  color: ${GRAY_1};

  span {
    color: ${BLACK};
  }
`;

export default FooterActions;
