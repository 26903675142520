import React, { Component } from 'react';
import If from 'APP_COMPONENTS/utils/ConditionalRender';
import { change, arrayPush, arrayRemove } from 'redux-form';
import OrganizationDocument from '../organization-documents/OrganizationDocument';
import OrgProfileCommentTable from '../../../containers/organization-profile/CommentsTable';

class OrganizationSections extends Component {
  state = {
    editSection: null,
  };
  updateUserSectionField = (field, value) => {
    const { dispatch, formName } = this.props;
    dispatch(change(formName, field, value));
  };

  addUserSectionFieldValue = (field, value) => {
    const { dispatch, formName } = this.props;
    dispatch(arrayPush(formName, field, value));
  };

  removeUserSectionFieldValue = (field, index) => {
    const { dispatch, formName } = this.props;
    dispatch(arrayRemove(formName, field, index));
    this.setState({ editSection: null });
  };

  setEditUserSectionTable = (sectionId, key) => {
    if (sectionId) {
      this.setState({ editSection: { sectionId, key } });
    } else {
      this.setState({ editSection: null });
    }
  };

  renderCommentSection = () => {
    const { canViewComments, canManageComments, ouId, timezone } = this.props;

    if (canViewComments || canManageComments) {
      return (
        <OrgProfileCommentTable
          ouId={ouId}
          manageComments={canManageComments}
          timezone={timezone}
        />
      );
    }
  };

  render() {
    const {
      organizationProfileForm,
      isLoading,
      timezone,
      canViewDocuments,
      canManageDocuments,
    } = this.props;

    const { editSection } = this.state;

    return (
      <>
        <If condition={canViewDocuments}>
          <OrganizationDocument
            organizationProfileForm={organizationProfileForm}
            isLoading={isLoading}
            timezone={timezone}
            editSection={editSection}
            updateSectionField={this.updateUserSectionField}
            addSectionFieldValue={this.addUserSectionFieldValue}
            removeSectionFieldValue={this.removeUserSectionFieldValue}
            setEditSectionTable={this.setEditUserSectionTable}
            isSectionReadOnly={!canManageDocuments}
          />
        </If>
        {this.renderCommentSection()}
      </>
    );
  }
}

export default OrganizationSections;
