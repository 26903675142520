import React, { Component } from 'react';
import { Input, Icon } from 'antd';

class InputText extends Component {
  render() {
    const {
      enums,
      options,
      isReviewer,
      dataKey,
      parentKey,
      parentIndex,
      timezone,
      field_type,
      ...props
    } = this.props;

    if (isReviewer) {
      return (
        <div className="reviewer-field text-field file-field">
          <Icon type="file" /> <span>{props.value || ' '}&nbsp;</span>
        </div>
      );
    }

    return <Input {...props} ref={ref => (this.input = ref)} />;
  }
}

export default InputText;
