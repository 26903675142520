import styled from 'styled-components';
import { INACTIVE } from '../../../config/theme';

const FormActions = styled.div.attrs({ className: 'form-actions' })`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  & .form-actions__meta {
    font-size: 16px;
    font-weight: 500;
    color: ${INACTIVE};
    line-height: 1.6;
    text-align: right;
    flex-grow: 1;

    .in-line {
      display: inline;
    }

    span.submitted-date {
      font-size: 14px;
      font-weight: 500;
    }
  }

  & .form-actions__buttons {
    display: flex;
    justify-content: end;
    flex-grow: 1;
    line-height: 42px;
    min-width: 160px;

    .ant-btn img {
      height: 24px;
    }
  }

  .icon-btn {
    margin-right: 8px;
    display: inline-block;
    vertical-align: middle;
  }
`;

export default FormActions;
