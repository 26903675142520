import { get, difference, has } from 'lodash';

export default props => {
  const {
    data = {},
    options = {},
    validationState = {},
    message: getMessage = false,
    forceValidate = false,
  } = props;

  const submitRequested = get(validationState, 'form.submitRequested', false);

  const {
    source = '',
    min = 0,
    hasEvery = {},
    message = '',
    tabIndex = 0,
  } = options;

  if (!submitRequested && !forceValidate) {
    return getMessage
      ? { message, tabIndex, field: source, complies: true }
      : true;
  }

  const baseSource = get(data, source, []);
  const shouldHaveMin = isNaN(min) ? get(data, min, 0) : min;
  const hasTheMin = baseSource.length >= shouldHaveMin;

  if (!has(hasEvery, 'field') || !has(hasEvery, 'over')) {
    return getMessage
      ? { message, tabIndex, field: source, complies: hasTheMin }
      : hasTheMin;
  }

  const [repeaterKey, fieldName] = get(
    hasEvery,
    'field',
    `${source}.item.id`
  ).split('.item.');
  const [overRepeaterKey, overFieldName] = get(
    hasEvery,
    'over',
    `${source}.item.id`
  ).split('.item.');
  const fromSource = get(data, repeaterKey, []);
  const overSource = get(data, overRepeaterKey, []);
  const fromSourceValues = fromSource.reduce(
    (values, item) => [...values, get(item, fieldName, '')],
    []
  );
  const overSourceValues = overSource.reduce(
    (values, item) => [...values, get(item, overFieldName, '')],
    []
  );
  const compliesEvery =
    difference(fromSourceValues, overSourceValues).length === 0;

  return getMessage
    ? { message, tabIndex, field: source, complies: hasTheMin && compliesEvery }
    : hasTheMin && compliesEvery;
};
