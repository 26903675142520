import React from 'react';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import join from 'lodash/join';
import some from 'lodash/some';
import isObject from 'lodash/isObject';
import has from 'lodash/has';
import each from 'lodash/each';
import castArray from 'lodash/castArray';
import isPlainObject from 'lodash/isPlainObject';
import first from 'lodash/first';
import every from 'lodash/every';
import keys from 'lodash/keys';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import cond from 'lodash/cond';
import isEqual from 'lodash/isEqual';
import matches from 'lodash/matches';
import conforms from 'lodash/conforms';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import isInteger from 'lodash/isInteger';
import kebabCase from 'lodash/kebabCase';
import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import Button from 'antd/lib/button';
import { LAYOUT_TYPES, SELF_SERVICE_LAYOUT_TYPES } from '../constants/form';
import { REPEATER } from '../constants/layoutComponentTypes';
import { REQUEST_MORE_INFO } from '../constants/submit-actions';
import setDoneWithActions from '../actions/set-done-with-actions';
import setTaskName from '../actions/set-task-name';
import setPerformanceAssessmentName from '../actions/set-performance-assessment-name';
import setComplaintNumber from '../actions/set-complaint-number';
import setReportNumber from '../actions/set-report-number';
import ConditionalRender from '../components/utils/ConditionalRender';
import FORM_TYPES from '../constants/form-types';
import formActionDispatcher from './formDispatchEmitter';
import getItemWithKey from './getItemWithKey';
import { symlinksPropName } from 'APP_ROOT/utils/create-symlink';

import getDataByConditions from './form/getDataByConditions';
import { transferIdsForRepeaters } from './form/utils';

const emptyString = '';

export const mapProperties = ({
  settings = {},
  total,
  onClick,
  classNames = [],
  ...rest
}) => (property, index) => {
  const { type = '', key: dataKey } = property;
  const { isEditor = false } = settings;
  const isFirst = index === 0;
  const isLast = total > 0 && index + 1 === total;
  const Component = getTemplateComponent(type, isEditor);
  const propertyProps = {
    ...rest,
    ...property,
    dataKey,
    index,
    isFirst,
    isLast,
    settings,
    className: classNames.length ? classNames[index] : emptyString,
  };

  const parentKey = get(rest, 'parentKey');
  const parentIndex = get(rest, 'parentIndex');
  const entryId = get(rest, ['data', parentKey, index, 'id']);
  const key = []
    .concat(dataKey ? dataKey : [])
    .concat(`${parentKey}.${type}.${parentIndex}.${index}`)
    .concat(entryId ? entryId : [])
    .join('.');

  return <Component {...propertyProps} key={key} />;
};

export const getKeysFromSchema = (properties, withReportingKeys = false) => {
  let repeater = '';
  let keys = [];
  if (Array.isArray(properties)) {
    properties.some(function iter(child) {
      if (child.type == 'repeater') {
        repeater = child.key;
      }
      if (child.type == 'field') {
        if (withReportingKeys) {
          keys = [
            ...keys,
            { key: child.key, reportingKey: child.reportingKey },
          ];
        } else {
          keys = [...keys, child.key];
        }
      }
      return Array.isArray(child.properties) && child.properties.some(iter);
    });
  }
  return { repeater, keys };
};

export const mapFormKeys = (object, keys) => {
  const results = keys.map((key, index) => {
    let result;

    some(object, function matchKey(value, $key) {
      if (isObject(value) && has(value, 'key')) {
        if (value.key === key) {
          result = value.properties[0];
          return true;
        }
      } else if (isObject(value)) {
        return some(value, matchKey);
      }
    });

    return result;
  });

  return results;
};

export const getDefaultFieldValueByType = (type, _defaultValue = undefined) => {
  const defaultValue =
    _defaultValue === null || _defaultValue === undefined
      ? undefined
      : _defaultValue;
  switch (type) {
    case 'multiselect':
      return isArray(defaultValue) ? defaultValue : undefined;
    case 'date-time':
    case 'date':
      const value = defaultValue;
      return typeof value === 'string' && value.includes('--/--/--')
        ? new Date()
        : value;
    case 'switch':
      return isBoolean(defaultValue) ? defaultValue : undefined;
    case 'select':
    case 'dynamic-select':
    case 'number':
    default:
      return defaultValue;
  }
};

function getRepeater(array, key) {
  var o;
  array.some(function iterator(a) {
    if (a.key === key) {
      o = a.properties;
      return true;
    }
    return Array.isArray(a.properties) && a.properties.some(iterator);
  });
  return o;
}

// BTD-388: Recursive logic for custom propagation feature using reportingKeys (temporal workaround)
const getSchemaFromRepeater = (schema, repeaterKey) => {
  if (schema.properties) {
    return getRepeater(schema.properties, repeaterKey);
  }

  return {};
};

const getNestedRepeaters = (properties = []) => {
  return isArray(properties)
    ? properties.reduce((nested, item) => {
        if (item.type === REPEATER) {
          nested.push(item);
        } else {
          nested = nested.concat(getNestedRepeaters(item.properties));
        }
        return nested;
      }, [])
    : [];
};

// get all the schemas from repeater including nested repeaters
const getAllSchemasFromRepeater = (parentForm, fields, data) => {
  return isArray(fields)
    ? fields.reduce((schema, item) => {
        if (isPlainObject(item)) {
          const { field = '' } = item;

          if (isArray(data[field]) && !schema[field]) {
            schema[field] = getSchemaFromRepeater(parentForm, field);
            const nested = getNestedRepeaters(schema[field]);
            nested.forEach(item => {
              schema[item.key] = item.properties;
            });
          }
        }
        return schema;
      }, {})
    : {};
};

const findField = key => item => {
  if (isPlainObject(item)) {
    return item.field === key;
  }
  return false;
};

const mapDataFields = (
  sharingReportingKeys,
  parentKeys,
  reviewKeys,
  { omittedFields, pickedFields }
) => d => {
  // BTD-388: Custom return for fields sharing reporting keys
  // Here we replace the corresponding keys for correct propagation
  if (sharingReportingKeys) {
    return Object.keys(d).reduce((acc, fieldKey) => {
      const { reportingKey = '' } = find(parentKeys, { key: fieldKey }) || {};

      if (reportingKey) {
        const { key } = find(reviewKeys, { reportingKey }) || {};
        return {
          ...acc,
          [key]: d[fieldKey],
        };
      }

      return {
        ...acc,
        [fieldKey]: d[fieldKey],
      };
    }, {});
  }

  if (!isEmpty(omittedFields) && !isEmpty(pickedFields)) {
    //BTD-839 This "omit" transform the field value into an object that generates a bug on the render
    //because "omitted Fields" and "picked Fields" were empty objects
    //to solve the issue this validation was added
    return {
      ...omit(d, omittedFields),
      ...pick(d, pickedFields),
    };
  }
  //In this case the normal value is return.
  return d;
};

const transformDataFields = (data, form, repeaterSchema) => (acc, item) => {
  if (isPlainObject(item)) {
    const {
      field = '',
      pick: pickedFields = [],
      omit: omittedFields = [],
      propagateAs = '',
    } = item;

    let fieldValue = data[field] || '';

    if (isArray(data[field])) {
      // BTD-388: Added new custom logic on reder for data propagation.
      // The idea is to depend on the metadata of the fields (specifically reportingKeys)
      // to carry out a correct propagation of data within the connected repeaters.
      const parentRepeaterSchema = repeaterSchema[field];
      const { keys: parentKeys = [] } = getKeysFromSchema(
        parentRepeaterSchema,
        true
      );

      const reviewRepeaterSchema = getSchemaFromRepeater(form, propagateAs);
      const { keys: reviewKeys = [] } = getKeysFromSchema(
        reviewRepeaterSchema,
        true
      );

      // BTD-388: Validate if we are dealing with connected metadata between fields
      const sharingReportingKeys = parentKeys.some(
        field => !!find(reviewKeys, { reportingKey: field.reportingKey })
      );

      fieldValue = data[field].map(
        mapDataFields(sharingReportingKeys, parentKeys, reviewKeys, {
          omittedFields,
          pickedFields,
        })
      );
    }

    return {
      ...acc,
      [propagateAs || field]: fieldValue,
    };
  }

  const value = data[item];

  return {
    ...acc,
    [item]: isBoolean(value) || isInteger(value) ? value : value || null,
  };
};

export const transformData = (data = {}, schema = {}, parentSchema = {}) => {
  const {
    formSchema: { propagateFields: fields = [], form = {} } = {},
  } = schema;
  const { formSchema: { form: parentForm = {} } = {} } = parentSchema;
  const allFields = [...castArray(fields), '__users', symlinksPropName];
  // look for nested repeaters
  const repeaterSchema = getAllSchemasFromRepeater(parentForm, fields, data);
  // include nested repeaters into the propagation list
  Object.keys(repeaterSchema).forEach(key => {
    if (!allFields.find(findField(key))) {
      allFields.push({ field: key, propagateAs: key });
    }
  });

  return allFields.reduce(transformDataFields(data, form, repeaterSchema), {});
};

export const getPropagateFields = (data = {}, schema = {}) => {
  const { propagateFields: fields = [] } = schema;
  return fields.reduce((acc, item) => {
    if (isPlainObject(item)) {
      const { field = '', propagateAs = '' } = item;
      return [...acc, propagateAs || field];
    }
    return [...acc, item];
  }, {});
};

export { default as getFormPresentation } from './get-form-presentation';

export const getFormFields = async (
  schema = { properties: [] },
  validationRules = {},
  withProfileData = false
) => {
  let fields = {
    hasTabs: false,
    fields: [],
  };

  let result = {};

  const matchKeys = (parent, parentKey, tabIndex = 0, tabScheme = {}) => (
    property,
    index
  ) => {
    const isTab = has(property, 'type') && property.type === 'tab';
    const isFieldRepeaterOrUpload =
      has(property, 'key') && getItemWithKey(property.type);
    if (isTab) {
      processTab(fields, index, property, matchKeys, parentKey);
    } else if (isFieldRepeaterOrUpload) {
      processRegularFields({
        property,
        parentKey,
        validationRules,
        parent,
        matchKeys,
        tabIndex,
        tabScheme,
        withProfileData,
      });
    } else {
      each(
        property.properties,
        matchKeys(parent, parentKey, tabIndex, tabScheme)
      );
    }
  };

  each(schema.properties, matchKeys(result));

  return fields.hasTabs ? fields : { ...fields, fields: [result] };
};

function processRegularFields({
  property,
  parentKey,
  validationRules,
  parent,
  matchKeys,
  tabIndex,
  tabScheme,
  withProfileData,
}) {
  const isRepeater = property.type === 'repeater' || property.type === 'upload';
  const parentKeyForChild = isRepeater
    ? `${property.key}.defaultField.fields`
    : property.key;
  const keyInValidations = parentKey
    ? `${parentKey}.${property.key}`
    : property.key;
  const fieldRules = get(validationRules.fields, keyInValidations, []);
  processRegularFieldsAid({
    property,
    parentKey,
    parent,
    matchKeys,
    parentKeyForChild,
    tabIndex,
    tabScheme,
    fieldRules,
    withProfileData,
  });
}

function processRegularFieldsAid({
  property,
  parentKey,
  parent,
  matchKeys,
  parentKeyForChild,
  tabIndex,
  tabScheme,
  fieldRules,
  withProfileData,
}) {
  const {
    key = '',
    properties = [],
    field_type: fieldType = 'string',
    options: { defaultValue = null } = {},
    conditions,
  } = property;
  const isSymlink =
    property.key === 'categories' &&
    String(parentKey).includes('assessmentCalls');
  const hasPropertyAndNoSystemLink = has(property, 'properties') && !isSymlink;
  const isProfileData = has(property, 'fromSource');
  if (hasPropertyAndNoSystemLink) {
    if (!has(parent, key) && !Array.isArray(get(parent, key))) {
      parent[key] = [{}];
    }
    each(
      properties,
      matchKeys(parent[key][0], parentKeyForChild, tabIndex, tabScheme)
    );
  } else if (!isProfileData || (withProfileData && isProfileData)) {
    parent[key] = {
      tabIndex,
      defaultValue: getDefaultFieldValueByType(fieldType, defaultValue),
      conditions,
      validationRules: fieldRules,
    };
  }
}

function processTab(fields, index, property, matchKeys, parentKey) {
  fields['hasTabs'] = true;
  fields['fields'][index] = {};
  each(
    property.properties,
    matchKeys(
      fields['fields'][index],
      parentKey,
      index,
      fields['fields'][index]
    )
  );
}

export const mapFormChanges = (data, formState, presentation, storeState) => {
  const flattenPresentation = keys(presentation.fields).reduce(
    (result, tab, index) => {
      return {
        ...result,
        ...keys(presentation.fields[tab]).reduce(
          (tabResult, field, fieldIndex) => {
            result[field] = presentation.fields[tab][field];

            return result;
          },
          {}
        ),
      };
    },
    {}
  );

  const getValueFromPresentation = level => get(flattenPresentation, level);

  let changes = {};
  let state = {};

  const form = Object.keys(formState).reduce((result, cu) => {
    result = {
      ...result,
      [cu]: formState[cu],
    };

    return result;
  }, {});

  Object.keys(form).forEach(key => {
    const currentValue = form[key];
    const lodashKey = key.replace('[', '.').replace(']', '.');
    const isMetadata =
      Array.isArray(form[key]) &&
      form[key].length &&
      isPlainObject(first(get(form, lodashKey).filter(item => item)));

    if (isMetadata) {
      const objectFromChangesArray = [...[], ...form[key]];

      const editedIndex = Object.keys(objectFromChangesArray).filter(
        (item, index) => form[key][item] !== undefined
      )[0];

      const currentValuesFilled = Array(currentValue.length).fill({
        ignore: true,
      });

      const currentValuesFiltered = currentValuesFilled.reduce(
        (result, i, index) => {
          const item = currentValue[index] || {};

          if (currentValue[index] === undefined) {
            return result;
          }

          if (index !== Number(editedIndex)) {
            return result;
          }

          const editedItem = form[key][index];
          const editedItemKey = first(keys(editedItem));

          result[editedIndex] = {
            ...item,
          };

          result[editedIndex][editedItemKey] = get(
            editedItem,
            [editedItemKey, 'value'],
            getValueFromPresentation([key, 0, editedItemKey])
          );

          return result;
        },
        []
      );

      const currentStateFiltered = objectFromChangesArray.reduce(
        (result, i, index) => {
          const item = currentValue[index] || {};

          if (currentValue[index] === undefined) {
            return result;
          }

          const editedItem = form[key][index];
          const editedItemKey = first(keys(editedItem));

          result[index] = {
            ...item,
            [editedItemKey]: get(editedItem, [editedItemKey], {}),
          };

          return result;
        },
        []
      );

      changes = {
        ...changes,
        [key]: currentValuesFiltered,
      };

      state = {
        ...state,
        [key]: currentStateFiltered,
      };
    } else {
      changes = {
        ...changes,
        [key]: get(form, [key, 'value'], getValueFromPresentation([key])),
      };

      state = {
        ...state,
        [key]: {
          ...get(storeState, [key], {}),
          ...get(form, [key], {}),
        },
      };
    }
  });
  return { changes, state };
};

export const getTemplateComponent = (type, isEditor) => {
  if (isEditor) {
    return SELF_SERVICE_LAYOUT_TYPES[type];
  }
  return LAYOUT_TYPES[type] || LAYOUT_TYPES['section'];
};

export const addButtonTemplate = ({
  addButtonLabel,
  keys = [],
  templates = [],
  conditions = {},
  actions = [],
  symlinkPath,
}) => ({
  type: 'section',
  options: {
    className: 'bdm-repeater-placeholder bdm-repeater-footer',
  },
  isRepeaterAction: true,
  properties: [
    {
      type: 'row',
      properties: [
        {
          type: 'column',
          options: {
            span: 24,
          },
          properties: [
            {
              type: 'repeater-action',
              keys,
              title: addButtonLabel,
              templates,
              conditions,
              symlinkPath,
              options: {
                actions,
              },
            },
          ],
        },
      ],
    },
  ],
});

export const getFieldValue = (data = {}) => cb => cb(data);

export const dateValidator = timezone => (rule, value, callback) => {
  const validationMessage = rule.message
    ? rule.message
    : `${rule.field} is an invalid date.`;
  if (rule && !rule.required) {
    if (
      !!value &&
      !moment(value)
        .tz(timezone)
        .isValid()
    ) {
      callback(validationMessage);
    } else {
      callback();
    }
  } else if (rule && rule.required) {
    if (!value) {
      callback(validationMessage);
    } else if (
      !moment(value)
        .tz(timezone)
        .isValid()
    ) {
      callback(validationMessage);
    } else {
      callback();
    }
  } else {
    callback();
  }
};

export const numberValidator = defaultValue => (rule, value, callback) => {
  const validationMessage = rule.message
    ? rule.message
    : `${rule.field} is invalid.`;

  // The (!value && value !== 0) condition is because we dont want 0 to be considere as false.
  if (
    rule &&
    rule.required &&
    !value &&
    value !== 0 &&
    defaultValue !== value
  ) {
    callback(validationMessage);
  }

  const stringValue = value + '';
  if (isNaN(value) || stringValue.trim() === '') {
    callback(validationMessage);
  }

  callback();
};

export const mapObjectItem = (key, formData, reducerData = {}) => {
  const openIndex = key.indexOf('[');
  const closeIndex = key.indexOf(']');
  const parentKey = key.substr(0, openIndex);
  const editedIndex = key.substring(openIndex + 1, closeIndex);
  const dataKey = key.substring(key.lastIndexOf('.') + 1);
  const editedData = {
    ...get(reducerData, [parentKey, editedIndex], {}),
  };

  const currentData = get(
    reducerData,
    [parentKey],
    Array(editedIndex + 1).fill({})
  ).map((item, index) => {
    if (index === parseInt(editedIndex, 10)) {
      return {
        ...editedData,
        id: editedData.id || uuid(),
        [dataKey]: getFinalFieldValue(formData),
      };
    }

    return item;
  });

  return currentData;
};

export const getFinalFieldValue = value =>
  isPlainObject(value) ? get(value, 'value') : value;

/**
 * Returns the modified objects for form > selectedForm > state within the redux store
 *
 * @param {Object|Array} state - The modified object or array
 * @param {Object|Array} reducerState - The current validations state in the redux store
 * @return {Object}
 */
export const mapFormState = (state, reducerState) => {
  return keys(state).reduce((result, key) => {
    const itemData = state[key];
    if (Array.isArray(itemData)) {
      const itemKeys = Object.keys(itemData);
      let modifiedItem = get(reducerState || {}, key, []).concat([]);

      itemKeys.map(index => {
        modifiedItem[index] = get(itemData, index, {});
      });

      if (reducerState[key]) {
        const modifiedData = {};
        const currentData = cloneDeep(reducerState[key]);

        itemKeys.forEach(key => {
          currentData[key] = {
            ...currentData[key],
            ...modifiedItem[key],
          };
        });
        modifiedData[key] = currentData;

        return {
          ...result,
          ...modifiedData,
        };
      }
      return {
        ...result,
        [key]: modifiedItem,
      };
    }
    return {
      ...result,
      [key]: state[key],
    };
  }, {});
};

export const mapFormData = (data, reducerData) => {
  return keys(data).reduce((result, key) => {
    const isArrayNotation = key.includes('[');
    const openIndex = key.indexOf('[');
    const parentKey = key.substr(0, openIndex);
    const editedKey = isArrayNotation ? parentKey : key;
    const itemData = data[key];
    const isArray = Array.isArray(itemData);

    if (isArray) {
      const itemKeys = Object.keys(itemData);
      const modifiedIndex = first(itemKeys);
      const isRepeaterItem = isPlainObject(itemData[modifiedIndex]);
      if (isRepeaterItem) {
        let modifiedItem = get(reducerData, key, []).concat([]);
        modifiedItem[modifiedIndex] = Object.assign(
          {},
          get(reducerData, `${key}.${modifiedIndex}`, {}),
          mapFormData(get(itemData, `${modifiedIndex}`, {}), {})
        );
        return {
          ...result,
          [key]: modifiedItem,
        };
      }
    }

    return {
      ...result,
      [editedKey]: isArrayNotation
        ? mapObjectItem(key, data[key], reducerData)
        : getFinalFieldValue(data[key]),
    };
  }, {});
};

export const noFutureDateValidator = (timezone, rule, value, callback) => {
  const now = moment().tz(timezone);
  if (
    value &&
    moment(value)
      .tz(timezone)
      .isAfter(now)
  ) {
    callback('Please specify a date not in the future');
  }
  callback();
};

export const noFutureDateRule = timezone => {
  return {
    validator: noFutureDateValidator.bind(
      null,
      timezone ? timezone : moment.tz.guess()
    ),
    message: 'Please specify a date not in the future',
  };
};

export const phonePatternRule = {
  pattern: /^([0-9]( |-)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-)?([0-9]{3}( |-)?[0-9]{4}|[a-zA-Z0-9]{7})$/,
  message: 'Phone number must follow the format XXX-XXX-XXXX',
};

export const repeaterActions = {
  setDoneWithActions: ({ parentKey = '' }) => dispatch => {
    dispatch(setDoneWithActions(parentKey));
  },
  setTaskName: ({ parentKey = '' }) => dispatch => {
    dispatch(setTaskName(parentKey));
  },
  setPerformanceAssessmentName: ({ parentKey = '' }) => dispatch => {
    dispatch(setPerformanceAssessmentName(parentKey));
  },
  setComplaintNumber: field => dispatch => {
    dispatch(setComplaintNumber(field));
  },
  setReportNumber: field => dispatch => {
    dispatch(setReportNumber(field));
  },
};

export const repeaterActionConditions = {
  setDoneWithActions: (repeaterName = '', data = {}) => {
    const repeater = get(data, repeaterName, []) || [];

    if (repeater.length === 0) {
      return false;
    }

    const mappedState = repeater.map(
      item =>
        every(
          [
            get(item, 'interactionWho', '').length > 0,
            ![
              'Taser-Other',
              'Chemical Deployment-Other',
              'Canine Deployment-Other',
              'Impact Weapon-Other',
              'Edge Weapon-Other',
              'Physical Action-Other',
              'Firearm-Other',
              'Attacked-Other',
              'Taser-See Narrative',
              'Chemical Deployment-See Narrative',
              'Canine Deployment-See Narrative',
              'Impact Weapon-See Narrative',
              'Edge Weapon-See Narrative',
              'Physical Action-See Narrative',
              'Firearm-See Narrative',
              'Attacked-See Narrative',
              'Unknown-See Narrative',
              'Unknown-Other',
              'Other',
              'See Narrative',
              'Hobble',
            ].includes(get(item, 'interactionWhat', '')),
            get(item, 'interactionWhat', '').length > 0,
            get(item, 'interactionWhom', '').length > 0,
          ],
          Boolean
        ) && !get(item, 'doneWithAction', false)
    );

    return some(mappedState, Boolean);
  },
};

const onActionClick = (formName, callback, props) => e =>
  formActionDispatcher(formName, callback(props));

export const mapRepeaterActions = (data = {}, parentKey = '', props = {}) => (
  { trigger = '', condition = '', title: label = '' },
  index
) => {
  const callback = get(repeaterActions, trigger, () => {});
  const testCondition = get(repeaterActionConditions, condition, () => true);
  const asserts = testCondition(parentKey, data);
  const formName = get(props, 'settings.formName');

  return (
    <ConditionalRender key={index} condition={asserts}>
      <Button
        ghost
        type="primary"
        onClick={onActionClick(formName, callback, props)}
      >
        {label || ''}
      </Button>
    </ConditionalRender>
  );
};

export const toArrayNotated = key =>
  key.replace(/\.[\d+]\./g, match => `[${match.replace(/\./g, '')}].`);

export const getDataFromConditions = (data, formFields, whiteList = []) => {
  let result = {};
  getDataByConditions(data, formFields, data, data, result);

  // Get whitelisted fields from data and appends the fields to the result
  Object.assign(
    result,
    whiteList.reduce((prev, next) => ({ ...prev, [next]: data[next] }), {})
  );

  // Transfer ids for repeaters
  // The idea is to keep the id for each element in a repeater
  const newResult = transferIdsForRepeaters(data, result);

  // return clean data
  return JSON.parse(JSON.stringify(newResult));
};

export const getAllegationTypeList = (
  data = {},
  repeaterKey = '',
  key = ''
) => {
  const list = get(data, repeaterKey) || [];
  const listTypes = list.reduce((result, item) => {
    return item[key] ? result.concat(item[key]) : result;
  }, []);
  return join(uniq(listTypes), ', ');
};

export const getFindingsTypeList = (findings = {}, people = []) => {
  const findingsTypeGroup = groupBy(findings, 'overallFindingType');
  const findingsTypeList = Object.keys(findingsTypeGroup).reduce(
    (result, group) => {
      const groupName = findingsTypeGroup[group].map(officer => {
        const { overallFindingAccusedMemberId } = officer;
        let officerLabel;
        if (overallFindingAccusedMemberId) {
          if (overallFindingAccusedMemberId === 'Unknown') {
            const { __parentHumanIndex: index } = officer;
            officerLabel = `Unknown Accused ${index}`;
          } else {
            const officerId = !isNaN(overallFindingAccusedMemberId)
              ? parseInt(overallFindingAccusedMemberId, 10)
              : overallFindingAccusedMemberId;
            const { fullName = '', rank: { name: rankName = '' } = {} } =
              find(people, ['id', officerId]) || {};

            officerLabel = `${rankName} ${fullName}`;
          }
        }
        return officerLabel;
      });

      return {
        ...result,
        [group]: [...groupName],
      };
    },
    {}
  );
  const transformedfindingsTypeList = Object.keys(findingsTypeList).map(
    item => {
      const group = findingsTypeList[item].join(', ');
      return `${item} (${group})`;
    }
  );
  return transformedfindingsTypeList.join(', ');
};

export const transformParticipants = (participants = []) =>
  participants.reduce(
    (result, participant) => ({
      ...result,
      [participant.id]: participant,
    }),
    {}
  );

export const getParticipant = (id, participants = {}) =>
  get(participants, id, null);

export const isFinalLinkedSubmission = (id, linkedSubmissions = []) => {
  if (isEmpty(linkedSubmissions)) return false;
  return linkedSubmissions.reduce((result, form) => {
    const { actions = [] } = form;
    const { status } = actions[0] || {};
    return result && status === 'pending';
  }, true);
};

/**
 * Check if current form instance is linked to another form
 * validating the type of the link
 *
 * @param {Array}   links   Collection of link objects
 * @param {String}  type    Type of linking to evaluate (optional)
 * @returns {Boolean}
 */
export const isLinked = (links = [], type) => {
  if (isEmpty(links)) {
    return false;
  }

  if (type) {
    // Check if links exists based on type
    return links.some(link => link.linkType == type);
  }

  // Return true if links array is not empty
  return true;
};

const getInvestigationFormSubmissionMessage = (item, who, whom) => {
  const whoRankName = get(who, 'rank.name', '');
  const whomRankName = get(whom, 'rank.name', '');
  const {
    isRequestMoreInfo = false,
    isFirstSubmission = false,
    fromRequestMoreInfo = false,
  } = item;
  const resolution = isEqual(item.actionLabel, 'Concur')
    ? 'concurred'
    : 'did not concur';
  const resolutionOfWho = `${whoRankName} ${who.fullName} ${resolution} with this report`;
  return cond([
    [matches({ toState: 'close' }), () => resolutionOfWho],
    [
      conforms({ toState: value => /close/.test(value) }),
      () => resolutionOfWho,
    ],
    [
      conforms({
        action: value => isRequestMoreInfo || value.includes(REQUEST_MORE_INFO),
      }),
      () =>
        `${whoRankName} ${who.fullName} sent this report back to ${whomRankName} ${whom.fullName} for more information`,
    ],
    [
      conforms({
        fromState: value => isFirstSubmission || value.includes('initial'),
      }),
      () =>
        `${whoRankName} ${who.fullName} submitted this report to ${whomRankName} ${whom.fullName}`,
    ],
    [
      conforms({
        fromState: value =>
          /review/.test(value) ||
          fromRequestMoreInfo ||
          /more info/.test(value),
        toState: value => /review/.test(value),
      }),
      () =>
        `${resolutionOfWho} and submitted it to ${whomRankName} ${whom.fullName} for further review`,
    ],
  ])(item);
};

const getInvestigationAffairsComplaintMesssage = (item, who, whom) => {
  const whoRankName = get(who, 'rank.name', '');
  const whomRankName = get(whom, 'rank.name', '');

  return cond([
    [
      matches({
        isFirstSubmission: true,
        actionLabel: 'Send for Further Review',
      }),
      () =>
        `${whoRankName} ${who.fullName} submitted this report for further review`,
    ],
    [
      conforms({
        action: value => value.includes('submit'),
        actionLabel: value => value === 'Send for Further Review',
      }),
      () =>
        `${whoRankName} ${who.fullName} submitted this report to ${whomRankName} ${whom.fullName} for further review`,
    ],
    [
      conforms({
        action: value => value.includes('administratively-unfounded'),
        actionLabel: value => value === 'Administratively Unfounded',
      }),
      () =>
        `${whoRankName} ${who.fullName} resolved this report as Administratively Unfounded`,
    ],
    [
      conforms({
        action: value => value.includes('investigate-incident'),
      }),
      () =>
        `${whoRankName} ${who.fullName} assigned ${whomRankName} ${whom.fullName} to investigate incident`,
    ],
  ])(item);
};

const getParticipantName = participant => {
  return participant
    ? `${get(participant, 'rank.name', '')} ${participant.fullName}`
    : '';
};

const firstSubmissionMessage = (
  participant,
  usersOnBehalf,
  targetParticipant,
  formType
) => {
  const hasUsersOnBehalf = !isEmpty(usersOnBehalf);

  return `${getParticipantName(participant)} ${
    hasUsersOnBehalf
      ? `on behalf of ${usersOnBehalf[0].firstName} ${usersOnBehalf[0].lastName} `
      : ''
  } submitted this report ${
    kebabCase(formType) === FORM_TYPES['intake'] || !targetParticipant
      ? ''
      : `to ${getParticipantName(targetParticipant)} for review`
  }`;
};

const closedSubmissionMessage = (item, participant) => {
  const resolution = get(
    item,
    'actionLabel',
    item.action.includes('submit-not-policy')
      ? 'Use of Force not in Policy'
      : 'Appropriate Use of Force'
  );

  return `${getParticipantName(
    participant
  )} reviewed this report as ${resolution} and the report is now Closed`;
};

const submitMessage = (item, participant, targetParticipant) => {
  const resolution = get(
    item,
    'actionLabel',
    item.action.includes('submit-not-policy')
      ? 'Use of Force not in Policy'
      : 'Appropriate Use of Force'
  );

  return `${getParticipantName(
    participant
  )} reviewed this report as ${resolution} and submitted this report to ${getParticipantName(
    targetParticipant
  )} for further review`;
};

const getGenericMessage = (
  participant,
  str1 = '',
  targetParticipant,
  str2 = ''
) => {
  return [
    getParticipantName(participant),
    str1,
    getParticipantName(targetParticipant),
    str2,
  ]
    .filter(str => str)
    .join(' ');
};

export const getSubmissionMessage = (
  item,
  formType = '',
  participants = {},
  usersOnBehalf = []
) => {
  const {
    isFirstSubmission = false,
    isRequestMoreInfo = false,
    isClosedSubmission = false,
  } = item;
  const participant = getParticipant(item.submitter, participants);
  const targetParticipant = getParticipant(item.target, participants);
  switch (kebabCase(formType)) {
    case FORM_TYPES.investigation:
      return getInvestigationFormSubmissionMessage(
        item,
        participant,
        targetParticipant
      );
    case FORM_TYPES.intake:
      return getInvestigationAffairsComplaintMesssage(
        item,
        participant,
        targetParticipant
      );
    default:
      break;
  }

  switch (true) {
    case isFirstSubmission || item.action.includes('initial'):
      return firstSubmissionMessage(
        participant,
        usersOnBehalf,
        targetParticipant,
        formType
      );
    case isRequestMoreInfo || item.action.includes(REQUEST_MORE_INFO):
      return getGenericMessage(
        participant,
        'sent this report back to',
        targetParticipant,
        'for more information'
      );

    case isClosedSubmission || item.toState === 'done':
      return closedSubmissionMessage(item, participant);

    case item.action.includes('more info.submit'):
      return getGenericMessage(
        participant,
        'added more information to this report'
      );

    case item.action.includes('submit'):
    case item.action.includes('submit-not-policy'):
      return submitMessage(item, participant, targetParticipant);

    case item.action.includes('review.investigate-incident'):
      return getGenericMessage(
        participant,
        'assigned',
        targetParticipant,
        'to investigate incident'
      );

    case item.action.includes('review.administratively-unfounded'):
      return getGenericMessage(
        participant,
        'resolved this report as Administratively Unfounded'
      );

    default:
      return getGenericMessage(
        participant,
        'submitted this report to',
        targetParticipant,
        'for review'
      );
  }
};

/**
 * Assumes a "form" object as first parameter, with an "actions" attribute.
 * Checks if the "form object" passed is pending by checking on it's action list
 * attribute.
 *
 * @param {Object} report
 * @return {Boolean}
 */
export function formIsPending(form) {
  return some(get(form, 'actions', []), action => action.status === 'pending');
}

/**
 * Checks if any of the linkedSubmissions for a "form object" has
 * a pending submission.
 *
 * @param {Object} form
 * @return {Boolean}
 */
export function pendingLinkedSubmissions(form) {
  return get(form, ['meta', 'linkedSubmissions'], []).reduce(
    (result, linkedForm) => result || formIsPending(linkedForm),
    false
  );
}

/**
 * Check if a field is included in a protected arrays of fields.
 *
 * @param {Array} keys
 * @param {String} field
 * @returns {Boolean}
 */
export function isProtectedField(keys, field) {
  if (field.includes('.id') || field === 'id') {
    return true;
  }
  return keys.some(key => field.includes(key));
}

/**
 * Check if a field has a repeater notation.
 *
 * @param {String} key
 * @returns
 */
export function isRepeaterKey(key) {
  return key.includes('.');
}

export function getCurrentRepeaterFromField(fields, repeaters, currentField) {
  let repeaterFound;
  fields.map((tab, i) => {
    (repeaters[i] || []).map(repeater => {
      if (get(repeater, `fields.${currentField}`)) {
        repeaterFound = get(repeater, 'repeaterKey');
      }
    });
  });
  return repeaterFound;
}
