import React from 'react';
import ModalBody from 'APP_ROOT/components/common/modal/body';
export default props => {
  const { reassignedTo, reportName } = props;

  return (
    <ModalBody>
      <p>
        {reportName} report has been reassigned to {reassignedTo}
      </p>
    </ModalBody>
  );
};
