import React, { useEffect } from 'react';
import { get } from 'lodash';

import PageHeader from 'APP_COMPONENTS/PageHeader';
import AdministratorWrapper from '../Administrator.styled';
import getRoute from '../../../utils/get-route';
import withoutClutter from '../../../components/without-clutter';
import DashboardPage from '../../../components/dashboard';
import withOwnershipGuard from '../components/agency-ownership-guard';
import { AuditLogWrapper } from '../agency-audit-logs/AgencyAuditLogs.styled';
import ReportTimelineAuditLogsPage from './pages/report-timeline-audit-logs-page';
import { formCleanReportTimelineLogs } from '../../../actions/organizations/get-report-timeline-audit-logs';

const AgencyAuditLogsReportTimeline = ({
  dispatch,
  reportTimelineLogs = [],
  agencyId,
  tenantId,
  timezone,
}) => {
  useEffect(() => {
    return () => dispatch(formCleanReportTimelineLogs());
  }, []);
  return (
    <AdministratorWrapper>
      <PageHeader
        title="Report Timeline Audit Logs"
        goBackTo={getRoute('administratorAgency', { agencyId })}
      />
      <AuditLogWrapper>
        <ReportTimelineAuditLogsPage
          dispatch={dispatch}
          agencyId={agencyId}
          tenantId={tenantId}
          reportTimelineLogs={reportTimelineLogs}
          timezone={timezone}
        />
      </AuditLogWrapper>
    </AdministratorWrapper>
  );
};

const mapStateToProps = (state, props) => ({
  agencyId: get(props, 'match.params.agencyId'),
  tenantId: get(props, 'match.params.tenantId'),
  reportTimelineLogs: get(state, 'organization.reportTimelineLogs'),
  timezone: get(state, 'session.currentUser.agency.timezone'),
});

export default withoutClutter(
  DashboardPage(
    mapStateToProps,
    null,
    {}
  )(
    withOwnershipGuard(
      AgencyAuditLogsReportTimeline,
      'administratorAgencyReportTimelineAuditLogs'
    )
  )
);
