import styled from 'styled-components';
import { Col } from 'antd';

const ColumnEditor = styled(Col)`
  border-right: 1px solid #e6e6e6;

  &:last-child {
    border-right: none;
  }
`;

export default ColumnEditor;
