import { get } from 'lodash';

const getTemplatesById = templates =>
  Object.values(templates).reduce(
    (_output, template) => ({
      ..._output,
      [template.id]: {
        ...template,
        templateType: get(template, 'templateType.value'),
        type: get(template, 'templateType.value'),
      },
    }),
    {}
  );

export default getTemplatesById;
