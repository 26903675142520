import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { first, get } from 'lodash';

import { getSelectedForm } from '../../selectors/form';
import { getAgencyTZ } from '../../selectors/session';

import parseDate from '../../utils/parse-date';

class ParentSummary extends Component {
  render() {
    const {
      selectedForm: {
        meta: { linkedForms = [] },
      },
      className,
      timezone,
    } = this.props;

    const formParentMeta = linkedForms.length > 0 ? first(linkedForms) : null;

    if (!formParentMeta) {
      return null;
    }

    const formParentData = get(formParentMeta, 'data', {});

    const { allegations = [] } = formParentData;
    const {
      submitterId = '',
      submissionDate = '',
      participants = [],
    } = formParentMeta;

    const formattedSubmissionDate = parseDate(submissionDate, timezone);
    const submitter = participants.find(p => p.id === submitterId) || {};
    const submitterName = submitter.fullName;
    const allegationList = allegations.map(a => a.allegationType).join(',');
    return (
      <div className={className}>
        <span>
          Complaint submitted by {submitterName} at {formattedSubmissionDate}{' '}
        </span>
        <br />
        <span>Initial Allegation Types: {allegationList}</span> <br />
      </div>
    );
  }
}

const StyledSummary = styled(ParentSummary)`
  display: inline-table;
  padding: 0 0;

  a {
    text-decoration: underline;
  }
`;

const mapState = state => ({
  selectedForm: getSelectedForm(state),
  timezone: getAgencyTZ(state),
});

export default connect(mapState, null)(StyledSummary);
