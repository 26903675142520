import React, { Component } from 'react';
import { connect } from 'react-redux';
import omit from 'lodash/omit';

import IconButton from 'APP_COMPONENTS/common/buttons/icon-button';
import withModal from 'APP_COMPONENTS/common/modal/base';

import buildOptionsModalContent from '../../utils/buildOptionsModalContent';
import componentsManager from '../../services/componentsManager';
import tabsManager from '../../services/tabsManager';

import {
  TAB_VALUES,
  TAB_CONDITIONAL,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
} from '../OptionsModalBody/OptionsModalBody';
class TabTitle extends withModal(Component) {
  constructor(props) {
    super(props);

    this.state = {
      tab: props.tab,
      values: props.tab,
    };
  }

  componentDidMount() {
    this.createModal();
  }

  static getDerivedStateFromProps(props, state) {
    return {
      tab: props.tab,
      values: props.tab,
    };
  }

  onEditTabClick = e => {
    const { values } = this.state;
    const { tab, isVisible } = this.props;

    tabsManager.setActiveTab(tab.id);

    e.stopPropagation();

    const onDelete = isVisible
      ? () => {
          this.deleteTab(tab);
          this.deleteModal();
        }
      : undefined;
    const modalData = buildOptionsModalContent({
      type: values.type,
      title: {
        type: 'text',
        label: 'Title',
        value: values.title || '',
        maxLength: 75,
        placeholder: 'Title',
      },

      disableTab: [TAB_VALUES, TAB_CONDITIONAL, TAB_SHARE_KEY, TAB_INFORMATION],
      onCancel: this.deleteModal,
      onDelete,
      onSave: params => {
        const modifiedValues = Object.assign(
          {},
          values,
          omit(params, ['properties']),
          { title: params.title.value, properties: tab.properties }
        );

        this.setState({ values: modifiedValues });
        componentsManager.editTab(tab.id, modifiedValues);

        this.deleteModal();
      },
    });

    this.updateModal(modalData);

    this.showModal();
  };

  deleteTab = tab => {
    this.props.deleteTabMethod(tab);
  };

  render() {
    const { className, tab } = this.props;

    return (
      <div className={className}>
        <span>{tab.title}</span>
        <IconButton icon="setting" onClick={this.onEditTabClick} />
      </div>
    );
  }
}

export default connect()(TabTitle);
