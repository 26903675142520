import React from 'react';
import { Icon, Progress } from 'antd';
import { decodeFileName } from 'src/utils/form/utils.js';

import handleDownloadClickS3 from './handleDownloadClickS3';

import FILE_STATUSES from '../file-statuses.constants';

const [DONE, ERROR, UPLOADING] = FILE_STATUSES;

const ownerFileOrProgress = ({ props, setState, attachment }) => {
  const percent = Math.round(attachment.progress);
  switch (attachment.status) {
    case UPLOADING:
      return (
        <div className={`ant-upload-list-item-${attachment.status}`}>
          <div className="ant-upload-list-item-info">
            <span>
              <Icon type="loading" />
              <a
                className="ant-upload-list-item-name no-link"
                title={decodeFileName(attachment.name)}
                onClick={e => e.preventDefault()}
              >
                {decodeFileName(attachment.name)}
              </a>
            </span>

            <Progress
              percent={percent}
              size="small"
              status="active"
              showInfo={true}
              strokeWidth={5}
            />
          </div>
        </div>
      );
    case DONE:
    case ERROR:
    default:
      return (
        <div className={`ant-upload-list-item-${attachment.status}`}>
          <div className="ant-upload-list-item-info">
            <span>
              <Icon type="paper-clip" />

              {attachment.status === DONE && (
                <a
                  rel="noopener noreferrer"
                  className="ant-upload-list-item-name"
                  title={decodeFileName(attachment.name)}
                  href="#"
                  onClick={e =>
                    handleDownloadClickS3(
                      props,
                      setState,
                      e,
                      attachment.id,
                      attachment.name
                    )
                  }
                >
                  {decodeFileName(attachment.name)}
                </a>
              )}

              {attachment.status === ERROR && (
                <span
                  className="ant-upload-list-item-name"
                  title={decodeFileName(attachment.name)}
                >
                  {decodeFileName(attachment.name)}
                </span>
              )}
            </span>
          </div>
        </div>
      );
  }
};

export default ownerFileOrProgress;
