import React, { Component } from 'react';
import { Row, Col, Input as InputField, Icon } from 'antd';
import { Field } from 'redux-form';
import { get } from 'lodash';
import { Link } from 'react-router-dom';

import Section from 'APP_COMPONENTS/form-viewer/styled/section';
import ProfileCardWrapper from '../styled/ProfileCardWrapper';
import Title from 'APP_COMPONENTS/form-viewer/styled/section-title';
import Input from '../../../administrator/components/input';
import parseDate, { BENCHMARK_DATE_FORMAT } from 'APP_ROOT/utils/parse-date';
import FORM_TYPES from 'APP_ROOT/constants/form-types';
import { getDORTranscript } from 'APP_ROOT/api/reports';
import { getUsers } from 'APP_ROOT/api/users';
import { getTemplateConfiguration } from 'APP_ROOT/selectors/session';
import { connect } from 'react-redux';

const TextField = Input(InputField);

class FieldTraining extends Component {
  state = { show: false };

  async componentDidMount() {
    const { userId, agencyId, templateConfiguration } = this.props;
    const enable = get(templateConfiguration, 'enable.value');

    if (!enable) return;
    if (userId) {
      try {
        const response = await getDORTranscript(userId);
        if (response.ftoIds.length > 0) {
          const filters = {
            where: {
              id: {
                inq: response.ftoIds,
              },
            },
          };
          const users = await getUsers(agencyId, filters);
          const usersName = users.map(user => user.fullName).join(', ');
          const data = Object.assign({}, response, { usersName });
          this.setState({ data, show: true });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error', error);
      }
    }
  }

  render() {
    const { data = {}, show } = this.state;
    const { timezone, userId } = this.props;
    if (!show) return null;

    return (
      <ProfileCardWrapper>
        <Section className="bdm-form-section--no-padding-bottom bdm-form-section--no-margin-bottom">
          <Row>
            <Col className="gutter-row" span={20}>
              <Title size="default">Field Training</Title>
            </Col>
            <Col span={4} className="text-right">
              <Link to={`/field-training/${userId}`}>
                <Icon type="arrow-right arrow-large" />
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="FieldOficerTraining"
                label="Field Training Officer"
                component={TextField}
                defaultValue={data.usersName}
                disabled={true}
                plainText={true}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="assessmentStartDate"
                component={TextField}
                label="Field Training Start Date"
                defaultValue={parseDate(
                  data.assessmentStartDate,
                  timezone,
                  BENCHMARK_DATE_FORMAT
                )}
                disabled={true}
                plainText={true}
              />
            </Col>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="trainingProgramEndDate"
                label="Field Training End Date"
                component={TextField}
                defaultValue={parseDate(
                  data.trainingProgramEndDate,
                  timezone,
                  BENCHMARK_DATE_FORMAT
                )}
                disabled={true}
                plainText={true}
              />
            </Col>
          </Row>
        </Section>
      </ProfileCardWrapper>
    );
  }
}

const mapState = state => {
  const templateConfiguration = getTemplateConfiguration(FORM_TYPES.DOR);

  return {
    templateConfiguration: templateConfiguration(state),
  };
};

export default connect(mapState)(FieldTraining);
