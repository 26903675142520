import React, { Component } from 'react';
import { Row, Col, Pagination, Spin } from 'antd';

// Actions
import getActionItemsFeed from '../../actions/get-action-items-feed';
import markNotificationSeen from '../../actions/mark-seen-notification';
import markNotificationDismiss from '../../actions/mark-dismiss-notification';
// Containers
import DashboardPage from '../../components/dashboard';
// Components
import MainTitle from '../../components/common/main-title';
import ActivitiesPanel from '../../components/dashboard/activities-panel';
// utils
import parseDate from '../../utils/parse-date';
// selectors
import { getAgencyTZ } from '../../selectors/session';
//styles
import {
  StyledRow,
  StyledRowPagination,
} from './action-items-feed-table.styled';

class ActionItemsFeed extends Component {
  static getPageConfig() {
    return {
      title: 'Action Items Feed',
    };
  }

  componentDidMount() {
    const {
      dispatch,
      reportsFetched = false,
      session: { isLoggedIn } = {},
    } = this.props;

    if (!reportsFetched && isLoggedIn) {
      dispatch(getActionItemsFeed(1, null, true));
    }
  }

  markActivityAsRead = id => {
    const { dispatch } = this.props;

    dispatch(markNotificationSeen(id));
  };

  markActionAsDismiss = (id, e) => {
    const { dispatch, page } = this.props;
    dispatch(markNotificationDismiss(id, page));
    if (e) {
      e.preventDefault();
    }
  };

  getActionItems = () => {
    const { entries = {}, list = [], timezone } = this.props;

    return list.map(actionItemId => {
      const {
        title,
        created_at,
        data: {
          formId,
          casefileId = null,
          isStale = false,
          notificationLabel = 'Take Action',
          url,
        } = {},
        id,
        new: bold,
        isDismissable,
        provider = 'bms',
        type,
      } = entries[actionItemId];

      const status = {
        label: notificationLabel,
        key: 'take-action',
      };

      return {
        message: title,
        meta: parseDate(created_at, timezone),
        status,
        id,
        formId,
        casefileId,
        bold,
        isStale,
        isDismissable,
        type,
        provider,
        url,
      };
    });
  };

  changePage = page => {
    const { dispatch } = this.props;

    window.scrollTo(0, 0);

    dispatch(getActionItemsFeed(page));
  };

  render() {
    const { total = 0, per_page = 8, page = 1, loading = false } = this.props;

    const PaginationWrapper = props => {
      if (loading) {
        return <Spin>{props.children}</Spin>;
      }

      return <div>{props.children}</div>;
    };

    return (
      <div className="section-container">
        <Row>
          <Col span={24} className="text-left">
            <MainTitle>Action Items Feed</MainTitle>
          </Col>
        </Row>

        <StyledRow>
          <Col span={24}>
            <ActivitiesPanel
              data={this.getActionItems()}
              showTitle={false}
              onItemClick={this.markActivityAsRead}
              onItemDismiss={this.markActionAsDismiss}
            />
          </Col>
        </StyledRow>

        {total > 0 && (
          <StyledRowPagination>
            <Col span={24} className="text-right">
              <PaginationWrapper>
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  total={total}
                  pageSize={per_page}
                  onChange={this.changePage}
                />
              </PaginationWrapper>
            </Col>
          </StyledRowPagination>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    activity: {
      actionItemsFeed: {
        data: list = [],
        entries,
        fetched,
        total,
        per_page,
        page,
        loading,
      } = {},
    } = {},
  } = state;
  return {
    fetched,
    list,
    entries,
    total,
    per_page,
    page,
    loading,
    timezone: getAgencyTZ(state),
  };
};

export default DashboardPage(mapStateToProps, null)(ActionItemsFeed);
