import React from 'react';

import ModalBody from 'APP_COMPONENTS/common/modal/body';

const successFields = (
  <ModalBody>
    <p>The information has been saved successfully.</p>
  </ModalBody>
);

export default successFields;
