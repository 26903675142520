import { reduce } from 'lodash';
import verifyRule from './verifyRule';
import getFormFields from './getFormFields';

/**
 *
 * @param {Object} validations validations section from the FB json file
 * @param {Object} form form validations section from the FB json file
 * @param {Object} enums enums section from the FB json file
 * @returns {Object} object with the validations verified and keys
 *                  removed found { validations, removed}
 */
const verifyValidations = (validations, form, enums, isNote) => {
  const { types = {}, rules = {} } = validations;
  const { fieldsByKey, repeatersByKey } = form.properties.reduce(
    getFormFields,
    {
      fieldsByKey: {},
      repeatersByKey: {},
    }
  );

  const newRules = reduce(
    Object.keys(rules),
    verifyRule(rules, { ...fieldsByKey, ...repeatersByKey }, isNote),
    {
      enums,
      valid: {},
      removed: [],
      required: [],
      type: [],
    }
  );

  return {
    validations: { types, rules: newRules.valid },
    enums: newRules.enums,
    removed: newRules.removed,
    required: newRules.required,
    type: newRules.type,
  };
};

export default verifyValidations;
