import * as Rx from 'rxjs';
import {
  omit,
  isArray,
  kebabCase,
  padStart,
  cloneDeep,
  get,
  isEqual,
  isEmpty,
  reduce,
  has,
  flatMap,
  values,
  findIndex,
  last,
} from 'lodash';
import { save } from 'save-file';

import {
  SECTION,
  UPLOAD,
  SINGLE_UPLOAD,
  COLUMN,
  FIELD,
  INJURIES_BODY,
  GROUP,
  REPEATER,
  TABS,
} from 'APP_ROOT/constants/layoutComponentTypes';

import {
  OPERATOR_EQUAL,
  OPERATOR_NOT_EQUAL,
} from '../constants/conditionalOperators';

import { REMOVED, ADDED, MODIFIED } from '../constants/changelogActions';

import { repeaterKeyDivider } from '../../../utils/constants';

// import actionTypes from '../constants/fieldsManager';
import tabsManager from './tabsManager';
import FormComponent from '../models/FormComponent';
import addComponentToParent from '../utils/addComponentToParent';
import removeComponentFromParent from '../utils/removeComponentFromParent';
import updateComponent from '../utils/updateComponent';
import getFieldMeta from '../utils/getFieldMeta';
import mutateComponentsTree from '../utils/mutateComponentsTree';
import generateFieldKey from '../utils/generateFieldKey';
import uploadFields from '../utils/uploadFields';
import showToastMessage from '../utils/showToastMessage';
import getFieldsWithConditions from '../utils/getFieldsWithConditions';
import getFieldsRelated from '../utils/getFieldsRelated';
import getMathRelated from '../utils/getMathRelated';
import getDurationRelated from '../utils/getDurationRelated';

import { mapToTemplateEnumBy } from '../../../containers/administrator/utils/transformChanges';
import { isReviewNote } from '../utils/general-utilities';

import getValidationsDefault from './utils/getValidationsDefault';
import findContainersByType from './utils/findContainersByType';
import enumsManager from './utils/enumsManager';
import updateFieldValidation from './utils/updateFieldValidation';
import getComponentData from './utils/getComponentData';
import setHeader from './utils/setHeader';
import sortTabs from './utils/sortTabs';
import removeRepeaterReference from './utils/removeRepeaterReference';

import formValidator from './formValidator/formValidator';
import showValidationErrors from './formValidator/showValidationErrors';

import {
  IS_REVIEWER,
  IS_HIDDEN,
  EMPTY,
  INDEX,
  DATA_PREFIX,
} from '../constants/conditions';

class ComponentsManager {
  constructor() {
    this.initData();
  }

  initData = () => {
    this.header$ = new Rx.BehaviorSubject({});
    this.flags$ = new Rx.BehaviorSubject({
      isLoading: true,
    });
    this.changelog$ = new Rx.BehaviorSubject([]);
    this.fields$ = new Rx.BehaviorSubject([]);
    this.tabFields$ = new Rx.BehaviorSubject([]);
    this.activeField$ = new Rx.BehaviorSubject(null);
    this.saveChanges$ = new Rx.Subject();
    this.validations$ = new Rx.BehaviorSubject({ rules: {}, types: {} });
    this.propagateAction$ = new Rx.BehaviorSubject([]);
    this.propagateFields$ = new Rx.BehaviorSubject([]);
    this.updateNotification = new Rx.Subject();
    this.repeatersReference = {};
    this.enumsManager = new enumsManager();
  };

  notify(message) {
    this.updateNotification.next(message);
  }

  resetChangelog() {
    this.changelog$.next([]);
  }

  getChangelog() {
    return this.changelog$.value;
  }

  registerChange(changes) {
    const currentValue = this.changelog$.value;
    const updatedValue = [...currentValue, changes];
    this.changelog$.next(updatedValue);
  }

  setPropagateAction(nextValue = []) {
    this.propagateAction$.next(nextValue);
  }

  setPropagateFields(nextValue = []) {
    this.propagateFields$.next(nextValue);
  }

  addToChangelog(field, actionType) {
    const {
      id,
      key,
      type,
      reportingKey,
      field_type,
      conditions,
      required,
      disable,
      title,
    } = field;
    let changes = {};
    if (type !== COLUMN) {
      switch (actionType) {
        case ADDED:
        case REMOVED:
          changes = {
            title,
            key: key,
            reportingKey: reportingKey,
            componentId: id,
            fieldType: field_type || type,
            changeType: actionType,
          };
          break;
        case MODIFIED:
          changes = {
            title,
            id: id,
            key: key,
            reportingKey: reportingKey,
            disable: disable,
            fieldType: field_type,
            required: required,
            conditions: conditions,
            changeType: actionType,
          };
          break;
      }
      this.registerChange(changes);
    }
    if (has(field, 'properties')) {
      field.properties.forEach(property => {
        this.addToChangelog(property, actionType);
      });
    }
  }

  normalizeOptions(options) {
    return Object.assign({}, { resetFocus: true }, options);
  }

  hydrate(fields, options) {
    const normalizedOptions = this.normalizeOptions(options);
    const { resetFocus, setActiveTabIndex = 0 } = normalizedOptions;

    this.fields$.next(fields);

    tabsManager.hydrate(fields, normalizedOptions);

    if (resetFocus) {
      this.tabFields$.next(fields[setActiveTabIndex]);
      tabsManager.setActiveTab(fields[setActiveTabIndex].id);
    }

    return this.fields;
  }

  /**
   * Add a component into the field structure
   *
   * @param {Object} field new field to add
   * @param {String} parentId id of the direct parent of the new field
   * @param {Number} position position in the properties array
   * @param {String} parentRepeater
   */
  addComponent(field, parentId, position, parentRepeater) {
    let fields = this.fields;
    // if activeTabIndex < 0, there is no tab, ReviewNote mode
    const currentTabIndex =
      tabsManager.activeTabIndex < 0 ? 0 : tabsManager.activeTabIndex;
    const key = generateFieldKey({
      fields,
      prefix: `${field.type} ${field.field_type || field.type || ''}`,
    });
    const currentTabId = fields[currentTabIndex].id;
    // let children = field.properties;
    const parent = getFieldMeta({ fields, value: parentId });

    if (field.type === SECTION && field.columns === undefined) {
      field.columns = 2;
    }

    const [
      fieldOptions,
      enumRef,
      fieldValidations,
      children,
    ] = getComponentData(
      field,
      key,
      (...params) => this.enumsManager.updateEnum(...params),
      parent.columns || field.columns,
      parentRepeater
    );

    let newField = new FormComponent(
      field.type,
      {
        title: '',
        ...omit(field, 'properties', 'label'),
        options: {
          ...field.options,
          ...fieldOptions,
        },
        key,
        enumRef,
      },
      children
    );

    fields = mutateComponentsTree(
      fields,
      parentId !== null ? parentId : currentTabId,
      addComponentToParent(newField, position)
    );

    this.fields$.next(fields);
    // using here veryNewField since the repeater has an object wrapper
    this.validateRepeaters(newField, parent, parentRepeater);

    this.updateComponentValidations(
      field.type,
      newField,
      children,
      fieldValidations
    );
    this.addToChangelog(newField, ADDED);
    return newField;
  }

  updateComponentValidations(type, newField, children, fieldValidations) {
    const fields = this.fields;
    if (type === UPLOAD) {
      const uploadValidations = getValidationsDefault(newField);

      uploadValidations.item[0].fields = fieldValidations;
      this.updateValidations({
        required: false,
        fields,
        componentId: newField.id,
        validations: uploadValidations,
      });
    } else {
      this.updateValidations({
        required: false,
        fields,
        componentId: newField.id,
        validations: {
          ...getValidationsDefault(newField),
          ...fieldValidations,
        },
      });
    }

    if (type === INJURIES_BODY) {
      children.forEach(child => {
        this.updateValidations({
          required: false,
          fields,
          componentId: child.id,
          validations: getValidationsDefault(child),
        });
      });
    }
  }

  /**
   * Gets a new FormComponent based in a new key and a component type and adds the children into the new FormComponent.
   *
   * @param {Object} fields Current property fields.
   * @param {String} type type of component.
   * @param {Array} children children to the parent.
   */
  getWrapperComponent(fields, type, children) {
    const key = generateFieldKey({
      fields,
      prefix: `${type} ${type || ''}`,
    });

    return new FormComponent(type, { key, icon: type }, children);
  }

  moveComponent(field, parentId, position) {
    let fields = this.fields;

    // first remove it
    fields = mutateComponentsTree(
      fields,
      field.id,
      removeComponentFromParent()
    );

    // now put it in the new position
    fields = mutateComponentsTree(
      fields,
      parentId,
      addComponentToParent(field, position)
    );

    this.fields$.next(fields);
    this.evaluateMoveFieldsInOutRepeaters(field, parentId);
  }

  removeComponent(componentId) {
    let fields = this.fields;
    const field = getFieldMeta({ fields, value: componentId });
    fields = mutateComponentsTree(
      fields,
      componentId,
      removeComponentFromParent()
    );

    this.fields$.next(fields);
    this.validateToRemoveFields(field);
    this.addToChangelog(field, REMOVED);
  }

  getConditionalAffectedFields(fieldKey) {
    let fields = this.fields;
    const allFieldsByKey = getFieldsWithConditions(fields, fieldKey);
    const affectedFields = this.fetchAppliedRulesInFields(
      fieldKey,
      allFieldsByKey
    );
    return isEmpty(affectedFields.fieldsToUpdate) &&
      isEmpty(affectedFields.fieldsToRemove)
      ? {}
      : affectedFields;
  }

  getDurationAffectedFields(fieldKey) {
    const fields = this.fields;
    return getFieldsRelated(fields, fieldKey, getDurationRelated);
  }

  getMathAffectedFields(fieldKey) {
    const fields = this.fields;
    return getFieldsRelated(fields, fieldKey, getMathRelated);
  }

  fetchAppliedRulesInFields(fieldKey, allFieldsByKey) {
    const affectedFields = {
      fieldsToRemove: [],
      fieldsToUpdate: [],
    };
    if (fieldKey) {
      const field = allFieldsByKey[fieldKey];
      const fieldRules = this.fetchFieldRuleWithin(field, allFieldsByKey);
      this.checkForRulesInField(field, fieldKey, fieldRules, affectedFields);
    }
    return affectedFields;
  }

  fetchFieldRuleWithin(field, allFieldsByKey) {
    if (field) {
      return values(omit(allFieldsByKey, field.key));
    }
    return [];
  }

  checkForRulesInField(field, fieldKey, fieldRules, affectedFields) {
    if (field) {
      this.applyRules(field, fieldKey, fieldRules, affectedFields);
    }
  }

  applyRules(field, fieldKey, fieldRules, affectedFields) {
    fieldRules.forEach(fieldRule => {
      fieldRule.conditions.rules.forEach(rule => {
        if (rule.key === field.key) {
          if (fieldRule.conditions.rules.length > 1) {
            affectedFields.fieldsToUpdate.push(
              this.removeConditionFromField(fieldRule, fieldKey)
            );
          } else {
            affectedFields.fieldsToRemove.push(fieldRule);
          }
        }
      });
    });
  }

  removeConditionFromField(field, keyConditionToRemove) {
    const newRules = field.conditions.rules.filter(
      rule => rule.key !== keyConditionToRemove
    );
    const updatedField = { ...field };
    updatedField.conditions = { ...updatedField.conditions, rules: newRules };
    return updatedField;
  }

  removeValidations(key) {
    const { rules, ...untouchedValidations } = this.validations;
    if (rules[key]) {
      const newRules = omit(rules, [key]);
      this.validations$.next({
        ...untouchedValidations,
        rules: {
          ...newRules,
        },
      });
    }
  }

  editComponent(componentId, changes) {
    let fields = this.fields;
    let {
      required,
      encrypt,
      readOnly,
      select,
      parentEnum,
      conditions,
      validations,
      sharedKey,
      sharedRef,
      ...propChanges
    } = changes;
    let oldKey;
    let oldEnumRef;

    // here update key and ref...
    if (sharedKey) {
      // checking only sharedKey in case the field doesn't have enumRef
      oldKey = propChanges.key;
      oldEnumRef = propChanges.enumRef;

      const field = getFieldMeta({ fields, key: 'key', value: sharedKey });
      propChanges.key = sharedKey;
      propChanges.enumRef = sharedRef;
      propChanges.parentEnum = field.parentEnum;
      propChanges.reportingKey = field.reportingKey;
    }

    fields = mutateComponentsTree(
      fields,
      componentId,
      updateComponent({
        conditions,
        ...propChanges,
        readOnly: required ? false : readOnly,
      })
    );

    if (sharedKey) {
      // check if the key and ref are used somewhere else if not deleted
      if (!getFieldMeta({ fields, key: 'key', value: oldKey })) {
        this.removeValidations(oldKey);
        this.enumsManager.removeEnumRef(oldEnumRef, parentEnum);
        // update conditions references
        let enumRef;
        if (sharedRef) {
          enumRef = this.enumsManager.getEnum(
            sharedRef,
            propChanges.parentEnum
          );
        }
        fields = this.updateConditionsReference(
          fields,
          oldKey,
          sharedKey,
          enumRef
        );
      }
    }

    this.fields$.next(fields);

    if (!sharedKey) {
      if (![REPEATER, UPLOAD].includes(changes.type)) {
        this.updateValidations({
          required,
          encrypt,
          fields,
          componentId,
          conditions,
          validations,
        });
      }

      this.enumsManager.updateEnums(
        {
          options: select,
          fields,
          componentId,
          parentEnum,
          hasPopulateFrom: !isEmpty(get(propChanges, 'options.populateFrom')),
        },
        this.validations
      );
    }

    this.addToChangelog(changes, MODIFIED);
  }

  // to update conditions reference when sharing keys
  updateConditionsReference = (fields, oldKey, newKey, enumRef) => {
    return fields.reduce((allFields, currentField) => {
      const properties = get(currentField, 'properties', []);
      let field;
      const { conditions } = currentField;

      if (conditions) {
        const rules = conditions.rules
          .map(r => {
            if (r.key === oldKey) {
              if (
                !enumRef ||
                enumRef.some(ref => r.expect.includes(ref.value))
              ) {
                return {
                  ...r,
                  key: newKey,
                  expect: r.expect,
                };
              } else {
                return null;
              }
            } else {
              return r;
            }
          })
          .filter(r => r);

        field = {
          ...currentField,
          conditions: rules.length
            ? {
                ...conditions,
                rules,
              }
            : undefined,
        };
      } else {
        field = currentField;
      }
      if (field.type !== FIELD) {
        return allFields.concat({
          ...field,
          properties: this.updateConditionsReference(
            properties,
            oldKey,
            newKey,
            enumRef
          ),
        });
      }

      return allFields.concat(field);
    }, []);
  };

  uploadFormSchema(formSchema) {
    const {
      enums,
      validations,
      form,
      propagateAction,
      propagateFields,
      ...configs
    } = formSchema;

    this.validations$.next(validations);

    let fields;
    // if type is GROUP or TABS it is generated by FB
    if ([TABS, GROUP].includes(form.type)) {
      fields = uploadFields(form.properties);
    } else {
      // otherwise it should be a system RN
      fields = uploadFields([form]);
    }
    // making this call only to initialize the structure
    this.hydrate(fields);

    this.updateRepeatersReference(fields);
    // first update block condition references,
    // remove conditions to have the required fields
    ({ fields } = this.updateWrapperConditions(false, fields));

    this.hydrate(fields);

    this.enumsManager.uploadEnums(enums);
    this.configs = configs;

    this.setPropagateAction(propagateAction);
    this.setPropagateFields(propagateFields);
  }

  /**
   * to update 'data' prefix for fields in repeaters with conditions
   * referencing fields outside the repeater
   * @param {Object} currentField: current field
   * @param {Array} conditions
   * @param {Boolean} addPrefix: to add/remove the prefix
   */
  updateConditionsDataPrefix(currentField, conditions, addPrefix) {
    const repeaters = Object.values(this.repeatersReference);
    let repeaterField;
    // find out if currentField is in a repeater
    if (currentField.type === FIELD) {
      repeaters.some(tab => {
        repeaterField = tab.find(repeater => repeater.fields[currentField.key]);
        return repeaterField;
      });
    } else {
      const repeaterKey = this.findItemInRepeatersFields(currentField.id);
      if (repeaterKey) {
        repeaterField = { repeaterKey };
      }
    }
    return {
      conditions: {
        ...conditions,
        rules: conditions.rules.map(rule => {
          // only fields in repeaters need 'data' prefix
          let repeaterRule;
          let key;
          if (addPrefix) {
            // find out if rule key is in a repeater
            repeaters.some(tab => {
              repeaterRule = tab.find(repeater => repeater.fields[rule.key]);
              return repeaterRule;
            });
            if (repeaterField && repeaterRule) {
              // both are in repeaters, using `.item.` for consistency, however
              // when report evaluate the condition, it is not changing item
              // with the current item value (or index, btw index keyword didn't
              // work either)

              if (currentField.type === REPEATER) {
                // if type === REPEATER, means it is a nested repeater
                key = `${repeaterRule.repeaterKey}${repeaterKeyDivider}${rule.key}`;
              } else {
                key =
                  repeaterField.repeaterKey === repeaterRule.repeaterKey ||
                  !isEmpty(rule.fromSource)
                    ? rule.key
                    : `${DATA_PREFIX}${repeaterRule.repeaterKey}${repeaterKeyDivider}${rule.key}`;
              }
            } else if (repeaterField) {
              // only the field is in a repeater
              key =
                [IS_REVIEWER, IS_HIDDEN, EMPTY, INDEX].includes(rule.key) ||
                !isEmpty(rule.fromSource) ||
                (rule.key || '').startsWith(DATA_PREFIX)
                  ? rule.key
                  : `${DATA_PREFIX}${rule.key}`;
            } else if (repeaterRule) {
              // only rule key is in a repeater, set to index .0. since that is
              // how it is used in othe places, UI is not able, at the moment,
              // to let user difine it
              key = isEmpty(rule.fromSource)
                ? `${repeaterRule.repeaterKey}${repeaterKeyDivider}${rule.key}`
                : rule.key;
            } else {
              // none of them are in a repeater
              key = rule.key;
            }
          } else {
            // the correct code should be
            // key = rule.key && last(rule.key.split('.'));
            // however, doing that there is a problem when recreating
            // field reference when the fieldKey is 'id' (from a
            // repeater block), since 'id' is not a unique reference,
            // the process can take the wrong repeater reference to
            // recreate the field path, that's why we have this code
            // key = rule.key && rule.key.replace(DATA_PREFIX, '');
            // meanwhile we figure out a way to fix it
            const _key = rule.key && last(rule.key.split('.'));
            key =
              _key === 'id'
                ? rule.key && rule.key.replace(DATA_PREFIX, '')
                : _key;
          }
          return {
            ...rule,
            key,
          };
        }),
      },
    };
  }

  /****
   * for wrapper elements with conditions we need to replicate the conditions
   * in the required fields inside it to avoid errors when running the
   * report, because of required fields not showing up
   * @param {Boolean} add: to add/remove conditions to required fields
   * @param {Array} fields: field list
   * @param {Object} mainValidations: validation structure, optional to not modify main structure
   * @param {Object} conditions: initially undefined, used to pass the conditions to replicate
   */
  updateWrapperConditions(add, fields, mainValidations, conditions) {
    let validations = mainValidations;
    const newFields = fields.reduce((allFields, currentField) => {
      const properties = get(currentField, 'properties', []);
      let modifiedField = currentField;
      let props;

      if ([REPEATER, SECTION, GROUP].includes(currentField.type)) {
        const isReviewerRule = {
          key: IS_REVIEWER,
          toBe: OPERATOR_EQUAL,
          expect: true,
        };
        // I know this is silly, but people use this condition ¯\_(ツ)_/¯
        const _isReviewerRule = {
          key: IS_REVIEWER,
          toBe: OPERATOR_NOT_EQUAL,
          expect: false,
        };
        let updatedConditions = cloneDeep(currentField.conditions);
        if (updatedConditions) {
          // make sure condition reference is ok when nesting repeaters
          // or wrapping object/section inside repeaters
          ({ conditions: updatedConditions } = this.updateConditionsDataPrefix(
            currentField,
            currentField.conditions,
            add
          ));
        }

        // do not propagate when isReviewer == true,
        // that is just to display data
        // there is other block to capture that data
        const fieldConditions = (
          currentField.conditions || { rules: [] }
        ).rules.some(
          rule =>
            isEqual(rule, isReviewerRule) || isEqual(rule, _isReviewerRule)
        )
          ? undefined
          : currentField.conditions;
        ({ fields: props, validations } = this.updateWrapperConditions(
          add,
          properties,
          validations,
          fieldConditions || conditions
        ));
        // should we check for hide and disabled?
        return allFields.concat({
          ...currentField,
          conditions: updatedConditions,
          properties: props,
        });
      } else if (currentField.type === FIELD) {
        if (conditions) {
          if (add) {
            const [{ required = false, mustExist }] = this.getValidationRules(
              currentField.key
            );
            let addConditions;

            if (required || !!mustExist) {
              // update data prefix
              addConditions = this.updateConditionsDataPrefix(
                currentField,
                currentField.conditions || conditions,
                add
              );
              validations = this.updateValidations(
                {
                  required: true,
                  fields: [currentField],
                  componentId: currentField.id,
                  ...addConditions,
                  validations: {},
                },
                validations
              );
            } else if (currentField.conditions) {
              // update data prefix
              addConditions = this.updateConditionsDataPrefix(
                currentField,
                currentField.conditions,
                add
              );
            }
            modifiedField = { ...currentField, ...addConditions };
          } else if (!isEmpty(currentField.conditions)) {
            const [{ mustExist }] = this.getValidationRules(currentField.key);
            const equal = reduce(
              currentField.conditions,
              (result, value, key) => {
                if (result && isArray(value)) {
                  const rules = cloneDeep(value);
                  rules.forEach(
                    rule => (rule.key = rule.key.replace(DATA_PREFIX, ''))
                  );
                  return isEqual(rules, conditions.rules);
                } else {
                  return result && isEqual(value, conditions[key]);
                }
              },
              true
            );
            if (equal && mustExist) {
              const { conditions: odlConditions, ...theRest } = currentField;
              modifiedField = { ...theRest };
              validations = this.updateValidations(
                {
                  required: true,
                  fields: [currentField],
                  componentId: currentField.id,
                  conditions: undefined,
                  validations: {},
                },
                validations
              );
            } else {
              // update data prefix
              modifiedField = {
                ...currentField,
                ...this.updateConditionsDataPrefix(
                  currentField,
                  currentField.conditions,
                  add
                ),
              };
            }
          }
        } else if (!isEmpty(currentField.conditions)) {
          // update data prefix
          modifiedField = {
            ...currentField,
            ...this.updateConditionsDataPrefix(
              currentField,
              currentField.conditions,
              add
            ),
          };
        }
      } else if (currentField.type !== FIELD) {
        ({ fields: props, validations } = this.updateWrapperConditions(
          add,
          properties,
          validations,
          conditions
        ));
        return allFields.concat({
          ...currentField,
          properties: props,
        });
      }
      return allFields.concat(modifiedField);
    }, []);
    return { fields: newFields, validations };
  }

  /**
   * Calls methods to add or remove a field according the parent id and the field.
   *
   * @param {Object} field Object field to be added or removed in repeater reference.
   * @param {String} parentId String id that represent the target parent id of the field.
   */
  evaluateMoveFieldsInOutRepeaters(field, parentId) {
    this.removeRepeaterReference(field);
    this.addFieldIntoRepeater(field, parentId);
  }

  /**
   * Validates the new field and its parent to add in the repeaterReference object.
   *
   * @param {Objcet} newField field object to be added into repeater reference.
   * @param {String} parent String id that represent the target parent id of the field.
   * @param {String} parentRepeater id of the parent repeater in case of nesting
   */
  validateRepeaters(newField, parent, parentRepeater) {
    if (newField.type === REPEATER) {
      this.addRepeaterReference(newField, parentRepeater);
    } else {
      this.evaluateAddItemInRepeaterReference(newField, parent);
    }
  }

  /**
   * Updates the repeaterReference Object collecting the data from fields and update the new validations.
   *
   * @param {[Object]} fields Objects to be analized to collect the repeaters data.
   */
  updateRepeatersReference(fields) {
    let repeaters = {};
    fields.forEach((f, index) => {
      repeaters[index] = this.getRepeatersChild(f.properties);
    });
    this.repeatersReference = repeaters;
    this.validations$.next(this.getValidationsStructure(true));
  }

  /**
   * Gets the repeater childs.
   *
   * @param {[Object]} properties Objects that contains fields.
   */
  getRepeatersChild(properties) {
    // use reduce, in case the repeater key is duplicated, do not
    // generate 2 entries for the same key
    return findContainersByType(properties, REPEATER)
      .reduce((acc, repeater) => {
        // first push main repeater
        let propertyHash = this.getDataFromRepeaterFields(repeater.properties);
        acc.push({
          repeaterKey: repeater.key,
          fields: propertyHash,
        });
        // now look for nested
        return findContainersByType(repeater.properties, REPEATER).reduce(
          (acc, nested) => {
            const propHash = this.getDataFromRepeaterFields(nested.properties);
            for (const key in propHash) {
              if (propertyHash[key]) {
                // remove nested property from main property list
                delete propertyHash[key];
              }
            }
            acc.push({
              repeaterKey: nested.key,
              fields: propHash,
              parentRepeater: repeater.key,
            });
            return acc;
          },
          acc
        );
      }, [])
      .reduce((acc, item) => {
        // verify repeater keys are not duplicated
        const found = acc.find(re => re.key === item.repeaterKey);
        if (found) {
          found.fields = { ...found.fields, ...item.fields };
        } else {
          acc.push(item);
        }
        return acc;
      }, []);
  }

  /**
   * Finds an item in the repeater reference and returns a repeater key or undefined.
   *
   * @param {String} containerId String id that represent the target parent id of the field.
   */
  findItemInRepeatersFields(containerId) {
    const findContainerById = repeater =>
      this.findContainersById(repeater.properties, containerId).length > 0;

    // find all repeaters
    const repeaterFields = findContainersByType(
      this.fields,
      REPEATER,
      true
    ).filter(findContainerById);

    let repeaterFound;
    if (!isEmpty(repeaterFields)) {
      // check for nested repeater
      if (
        repeaterFields.length > 1 &&
        this.isNestedRepeater(repeaterFields[1].key)
      ) {
        repeaterFound = repeaterFields[1];
      } else {
        repeaterFound = repeaterFields[0];
      }
    }

    return repeaterFound && repeaterFound.key;
  }

  /**
   * finds an item in the repeaterReference structure, and returns
   * repeaterKey if the item is found
   *
   * @param {*} field
   */
  findItemInRepeatersReference(field) {
    const { key } = field;
    let repeaterFound;
    Object.values(this.repeatersReference).some(ref =>
      ref.some(rep => {
        repeaterFound = rep.fields[key] ? rep : false;
        return repeaterFound;
      })
    );
    return repeaterFound && repeaterFound.repeaterKey;
  }

  /**
   * Adds a repeater object into repeaterReference object/
   *
   * @param {Object} repeater Contains the repeater object.
   */
  addRepeaterReference(repeater, parentRepeater) {
    const { activeTabIndex } = tabsManager;
    // if activeTabIndex < 0, there is no tab, ReviewNote mode
    const tabIndex = activeTabIndex < 0 ? 0 : activeTabIndex;
    let foundTab = this.repeatersReference[tabIndex];
    foundTab
      ? foundTab.push({ repeaterKey: repeater.key, fields: {}, parentRepeater })
      : (this.repeatersReference[tabIndex] = [
          { repeaterKey: repeater.key, fields: {}, parentRepeater },
        ]);
  }

  /**
   * Adds a field into repeaterReference object.
   *
   * @param {Object} newItem field object to be added into repeater reference.
   * @param {String} parentId String id that represent the target parent id of the field.
   */
  addFieldIntoRepeater(newItem, parentId) {
    const { activeTabIndex } = tabsManager;
    // if activeTabIndex < 0, there is no tab, ReviewNote mode
    const tabIndex = activeTabIndex < 0 ? 0 : activeTabIndex;
    let tabData = this.repeatersReference[tabIndex];

    if (tabData) {
      const repeaterKey = this.findItemInRepeatersFields(parentId);
      if (repeaterKey) {
        const repeaterFound = tabData.find(
          repeater => repeater.repeaterKey === repeaterKey
        );
        if (repeaterFound) {
          if (newItem.properties && newItem.properties.length) {
            const newFields = this.getFieldsFromProperties(newItem.properties);
            newFields.forEach(newField => {
              if (newField.type === FIELD) {
                repeaterFound.fields[newField.key] = true;
              }
            });
          } else if (newItem.type === FIELD) {
            repeaterFound.fields[newItem.key] = true;
          }
        }
      }
    }
  }

  /**
   * Evaluates new item before to call addFieldIntoRepeater method.
   *
   * @param {Object} newItem field object to be added into repeater reference.
   * @param {String} parentId String id that represent the target parent id of the field.
   */
  evaluateAddItemInRepeaterReference(newItem, parent) {
    if (parent.type !== REPEATER) {
      this.addFieldIntoRepeater(newItem, parent.id);
    }
  }

  /**
   * Evaluate if a repeater is already nested. Evaluation is done in current tab
   *
   * @param {String} repeaterKey repeater key to validate
   */
  isNestedRepeater(repeaterKey) {
    const { activeTabIndex } = tabsManager;
    // if activeTabIndex < 0, there is no tab, ReviewNote mode
    const tabIndex = activeTabIndex < 0 ? 0 : activeTabIndex;
    const repeaters = this.repeatersReference[tabIndex];
    let nested = false;
    if (repeaters && repeaterKey) {
      const repeaterFound = repeaters.find(
        repeater => repeater.repeaterKey === repeaterKey
      );
      nested = repeaterFound && !!repeaterFound.parentRepeater;
    }
    return nested;
  }

  /**
   * Iterates fields and call a method to remove them.
   *
   * @param {[Object]} fields To be iterate into the method.
   * @param {Func} removeMethod Function to apply into the method.
   */
  removeItemsFromContainers(fields, removeMethod) {
    return fields.reduce((acc, r) => {
      if (r.properties && r.properties.length) {
        if (r.type === REPEATER) {
          removeMethod(r);
        }
        acc = acc.concat(
          this.removeItemsFromContainers(r.properties, removeMethod)
        );
      } else {
        if (r.type === FIELD) {
          acc.push(r);
          removeMethod(r);
          this.verifyRemoveFieldIfShared(r);
        }
      }

      return acc;
    }, []);
  }

  /**
   * Validates if the field will be removed from the repeaterReference object.
   *
   * @param {Object} field Object field to be removed.
   */
  validateToRemoveFields(field) {
    const { activeTabIndex } = tabsManager;
    // if activeTabIndex < 0, there is no tab, ReviewNote mode
    const tabIndex = activeTabIndex < 0 ? 0 : activeTabIndex;
    if (field.properties && field.type !== FIELD) {
      if (field.type === REPEATER) {
        let tabData = this.repeatersReference[tabIndex];
        this.repeatersReference[tabIndex] = tabData.filter(
          repeater => repeater.repeaterKey !== field.key
        );
      }
      this.removeItemsFromContainers(
        field.properties,
        this.removeFieldInAllReferences
      );
    }
    this.removeFieldInAllReferences(field);
  }

  /**
   * Verifies if the field is shared to remove it from the validations and enumRef object.
   *
   * @param {Object} field Object field to be analized.
   */
  verifyRemoveFieldIfShared = field => {
    const fields = this.fields;
    if (!getFieldMeta({ fields, key: 'key', value: field.key })) {
      // remove validations
      this.removeValidations(field.key);
      // remove validations
      this.enumsManager.removeEnumRef(field.enumRef, field.parentEnum);
    }
  };

  /**
   * Removes a field from the repeaterReference object.
   *
   * @param {Object} field Object field to be removed.
   */
  removeRepeaterReference(field) {
    const { activeTabIndex } = tabsManager;
    // if activeTabIndex < 0, there is no tab, ReviewNote mode
    const tabIndex = activeTabIndex < 0 ? 0 : activeTabIndex;

    removeRepeaterReference({
      repeatersReference: this.repeatersReference,
      tabIndex,
      field,
    });
  }

  /**
   * Calls methods to remove a field from repeaterReference and if the field is shared.
   */
  removeFieldInAllReferences = field => {
    this.removeRepeaterReference(field);
    this.verifyRemoveFieldIfShared(field);
  };

  /**
   * Gets the repeater childs form a field list.
   *
   * @param {[Object]} fields Object field list to be iterate.
   * @param {Object} accum To accumalate the result.
   */
  getDataFromRepeaterFields(fields, accum = {}) {
    return fields.reduce((acc, r) => {
      if (r.properties && r.properties.length) {
        return this.getDataFromRepeaterFields(r.properties, acc);
      }

      if (r.type === FIELD) {
        acc[r.key] = true;
      }

      return acc;
    }, accum);
  }

  /**
   * Gets fields from a field list object.
   *
   * @param {[Object]} fields Object list to be iterate.
   */
  getFieldsFromProperties(fields) {
    return fields.reduce((acc, r) => {
      if (r.properties && r.properties.length) {
        acc = acc.concat(this.getFieldsFromProperties(r.properties));
      } else {
        acc.push(r);
      }

      return acc;
    }, []);
  }

  /**
   * Gets and returns the new validations according the flat boolean value.
   *
   * @param {Boolean} flat Boolean that represent if the process is to flatten or not.
   */
  getValidationsStructure(flat, validations) {
    let newValidations = cloneDeep(validations || this.validations);
    let allKeys = {};
    if (this.repeatersReference) {
      if (!flat) {
        // if we want to unflatten, we need to add repeater prefix
        this.updateRepeaterReference(newValidations, true);
        flatMap(this.repeatersReference).reduce((acc, { fields }) => {
          Object.keys(fields).forEach(
            key => (acc[key] = acc[key] ? acc[key] + 1 : 1)
          );
          return acc;
        }, allKeys);
      }
      Object.values(this.repeatersReference).forEach(tab => {
        tab.forEach(repeater => {
          flat
            ? this.flattenRepeatersFields(repeater.repeaterKey, newValidations)
            : this.unflattenRepeaterFields(
                repeater.fields,
                repeater.repeaterKey,
                newValidations,
                allKeys
              );
        });
      });
      if (flat) {
        // if we want to flatten, we need to remove repeater prefix
        this.updateRepeaterReference(newValidations, false);
      }
    }

    return newValidations;
  }

  /**
   * Makes flat structure for repeaters using the repeater reference and validations.
   *
   * @param {String} repeaterKey String repeater key to find a repeater in validations.
   * @param {Object} newValidations Object clone of validations to remove fields.
   */
  flattenRepeatersFields(repeaterKey, newValidations) {
    let repeaterValidation = this.extractRepeaterValidation(
      repeaterKey,
      newValidations
    );
    if (repeaterValidation) {
      const [validation] = repeaterValidation;
      const [data] = validation.item;

      Object.keys(data.fields).forEach(fieldKey => {
        newValidations.rules[fieldKey] = data.fields[fieldKey];
      });
      data.fields = {};
    }
  }

  extractRepeaterValidation(repeaterKey, newValidations) {
    if (newValidations && newValidations.rules) {
      return newValidations.rules[repeaterKey];
    }
    return undefined;
  }

  /**
   * Update repeater reference, add or remove repeater prefix based on parameters
   *
   * @param {Object} newValidations Object validations clone object.
   * @param {Boolean} addPrefix Flag to add repeater prefix or remove it
   */
  updateRepeaterReference(newValidations, addPrefix) {
    if (isEmpty(newValidations)) return;
    // now we need to validate repeater references in newValidations
    // first get repeaters reference as array
    const repeaters = Object.values(this.repeatersReference);
    // now check all validation rules
    Object.entries(newValidations.rules).forEach(([key, validations]) => {
      repeaters.forEach(tab => {
        tab.forEach(repeater => {
          // since validations is an array
          validations.forEach(validation => {
            // check mustExist
            if (repeater.fields[key] && validation.mustExist) {
              validation.mustExist = addPrefix
                ? `${repeater.repeaterKey}.item.${key}`
                : key;
            }
            // now check conditions if any
            (validation.conditions || []).forEach(cond => {
              const condKey = addPrefix ? cond.key : cond.key.split('.').pop();
              if (repeater.fields[condKey]) {
                cond.key = addPrefix
                  ? `${repeater.repeaterKey}.item.${condKey}`
                  : condKey;
              }
            });
          });
        });
      });
    });
  }

  /**
   * When reordering tabs in UI, it is also necessary to reorder the json structure
   * accordingly
   *
   * @param {Array} order Array of tabId, showing new array order.
   */
  sortTabs(order) {
    const indexOrder = order.map(o => findIndex(this.fields, f => f.id === o));
    this.hydrate(sortTabs(this.fields, order, 'id'), {
      resetFocus: false,
    });
    this.updateRepeaterReferenceOrder(indexOrder);
  }

  /**
   * When reordering tabs, it is also necessary to reorder repeaterReference array
   * accordingly
   *
   * @param {Array} order Array of indexes, showing new array order.
   */
  updateRepeaterReferenceOrder(order = []) {
    const newRepeaterReference = {};
    order.forEach((o, i) => {
      newRepeaterReference[i] = this.repeatersReference[o];
    });
    this.repeatersReference = newRepeaterReference;
  }

  /**
   * Unmakes flat the repeater structure using the repeaterReference objcet and validations.
   *
   * @param {[String]} fields String array that contains a field list.
   * @param {String} repeaterKey String repeater key to find a repeater in validations
   * @param {Object} newValidations Object clone of validations to remove fields.
   * @param {Object} allKeys Object with all keys in the repeaters, with a counter of occurrences
   */
  unflattenRepeaterFields(
    repeaterFields,
    repeaterKey,
    newValidations,
    allKeys
  ) {
    let repeaterValidation = newValidations.rules[repeaterKey];
    if (repeaterValidation) {
      const [
        {
          item: [{ fields }],
        },
      ] = repeaterValidation;
      Object.keys(repeaterFields).forEach(fieldKey => {
        fields[fieldKey] = newValidations.rules[fieldKey];
        allKeys[fieldKey]--;
        if (allKeys[fieldKey] === 0) {
          delete newValidations.rules[fieldKey];
        }
      });
    }
  }

  updateValidations(rawValidations, mainValidations) {
    const { fields = this.fields, componentId } = rawValidations;
    const field = getFieldMeta({ fields, value: componentId });
    const { type } = field;
    // Enables the form validation so that fields in a form (template) pass the condition.
    const newValidations = updateFieldValidation(
      field,
      rawValidations,
      mainValidations || this.validations,
      this.header.isNote
    );

    if (
      !mainValidations &&
      [FIELD, REPEATER, UPLOAD, SINGLE_UPLOAD].includes(type)
    ) {
      this.validations$.next(newValidations);
    }
    return mainValidations ? newValidations : undefined;
  }

  editTab(componentId, changes) {
    const fields = this.fields.map(untouchedComponent =>
      untouchedComponent.id !== componentId
        ? untouchedComponent
        : {
            ...untouchedComponent,
            ...changes,
          }
    );

    this.fields$.next(fields);
  }

  importJSON(file, updateState, onDone) {
    const closeImportingMessage = showToastMessage(
      'loading',
      'Importing...',
      0
    );

    updateState({ reading: true, error: null });

    const onDoneReading = (result, isReviewNote) => {
      const action = json => () => onDone && onDone(json, isReviewNote);
      closeImportingMessage();
      const { form, errors } = result ? formValidator(result) : {};
      showValidationErrors(errors, action(form), action(result));
    };

    const reader = new FileReader();

    reader.onload = () => {
      setTimeout(() => {
        this.deconstructJSONText(reader.result, onDoneReading, updateState);
      }, 500);
    };

    reader.readAsText(file);
  }

  deconstructJSONText(text, onDoneReading, updateState) {
    try {
      const parsedData = this.preprocessJSONData(text);
      this.processJSONData(parsedData, updateState, onDoneReading);
    } catch (error) {
      this.processImportError(error, onDoneReading, updateState);
    }
  }

  processImportError(error, onDoneReading, updateState) {
    // eslint-disable-next-line no-console
    console.error(`Sometheing went wrong: ${error}`);
    onDoneReading(undefined, true);
    updateState({ reading: false, error: error }, () => {
      showToastMessage('error', 'Unable to import the selected file.');
    });
  }

  processJSONData(data, updateState, onDoneReading) {
    const { name, description, abbreviation } = data;
    updateState({ reading: false, name, abbreviation, description }, () => {
      onDoneReading(data, isReviewNote(data));
      showToastMessage('success', 'Successfully imported.');
    });
  }

  preprocessJSONData(text) {
    // remove non-printable chars, just in case
    const cleanText = text.replace(/[^\x20-\xFE]+/g, '');
    const parsed = JSON.parse(cleanText);
    this.checkForFormatErrors(parsed);
    return parsed;
  }

  checkForFormatErrors(template) {
    if (isReviewNote(template)) {
      this.checkForTabsInJSON(template);
    }
  }

  checkForTabsInJSON(template) {
    const type = get(template, 'formSchema.form.type', '');
    if (type.toLowerCase() === 'tabs') {
      const msg =
        'A custom review note template MUST NOT have TABS. Creating an empty review note as fallback.';
      const error = 'Invalid JSON format for a custom review note';
      this.handleValidationError(msg, error, 15);
    }
  }

  handleValidationError(msg, error, timer) {
    showToastMessage('error', msg, timer);
    throw new Error(error);
  }

  async exportJSON({
    name,
    abbreviation,
    description,
    category,
    configs = {},
  }) {
    const enumValue = mapToTemplateEnumBy(category);
    const templateSchema = {
      name,
      description,
      abbreviation,
      category: enumValue,
      formSchema: {
        ...configs,
        ...this.getFormSchema({ category }),
      },
    };

    const filename = kebabCase(`${name} ${abbreviation}`);

    const hide = showToastMessage('loading', 'Exporting..', 1);

    // Stringify and convert to unicode all char code > 127
    const str = JSON.stringify(templateSchema).replace(/./g, char => {
      const code = char.charCodeAt(0);
      if (code < 128) {
        return char;
      } else {
        let unicode = padStart(code.toString(16).toUpperCase(), 4, '0');
        return `\\u${unicode}`;
      }
    });
    await save(str, `${filename}.json`);

    hide();
  }

  get templateConfigs() {
    return this.configs;
  }

  getFormSchema(isReviewNote = false) {
    let validations = cloneDeep(this.validations);
    let fields;
    let form;
    // first update block condition references, replicate to the required fields
    ({ fields, validations } = this.updateWrapperConditions(
      true,
      this.fields,
      this.validations
    ));

    // if activeTabIndex < 0, there is no tab, ReviewNote mode
    if (tabsManager.activeTabIndex < 0) {
      form = { type: GROUP, properties: fields };
    } else {
      form = { type: TABS, properties: fields };
    }

    const template = {
      validations: this.getValidationsStructure(false, validations),
      form,
      enums: this.enumsManager.enums,
    };

    if (isReviewNote) {
      template.propagateAction = this.propagateAction;
      template.propagateFields = this.propagateFields;
    }

    return template;
  }

  setActiveField(id) {
    this.activeField$.next(id);
  }

  onSave() {
    this.saveChanges$.next(new Date());
  }

  clear() {
    this.fields$.next({});
    this.activeTabFields$.next({});
    this.activeField$.next(null);
  }

  getComponents() {
    return this.fields$.asObservable();
  }

  getTabComponens() {
    return this.tabFields$.asObservable();
  }

  getActiveField() {
    return this.activeField$.asObservable();
  }

  getSaveChanges() {
    return this.saveChanges$.asObservable();
  }

  get fields() {
    return this.fields$.value;
  }

  get tabFields() {
    return this.tabFields$.value;
  }

  get activeField() {
    return this.activeField$.value;
  }

  get validations() {
    return this.validations$.value;
  }

  get enums() {
    return this.enumsManager.enums;
  }

  get propagateAction() {
    return this.propagateAction$.value;
  }

  get propagateFields() {
    return this.propagateFields$.value;
  }

  /**
   * Finds the container object by id.
   *
   * @param {[Object]} fields Object list to be iterate.
   * @param {String} id String that represent an id.
   */
  findContainersById(fields = [], id) {
    return fields.reduce((acc, r) => {
      // looking for a container, no matter
      // have properties yet (length === 0)
      if (r.id === id) {
        acc.push(r);
      } else if (r.properties) {
        acc = acc.concat(this.findContainersById(r.properties, id));
      }

      return acc;
    }, []);
  }

  /**
   * Finds a container object by key.
   *
   * @param {[Object]} fields Object field list.
   * @param {String} key String value that represent a container key.
   */
  findContainersByKey(fields, key) {
    return fields.reduce((acc, r) => {
      // looking for a container, no matter
      // have properties yet (length === 0)
      if (r.key && r.key === key) {
        acc.push(r);
      } else if (r.properties && r.properties.length) {
        acc = acc.concat(this.findContainersByKey(r.properties, key));
      }

      return acc;
    }, []);
  }

  findFieldsByType(fields, type, field_type) {
    return fields.reduce((acc, r) => {
      if (r.properties && r.properties.length) {
        acc = acc.concat(this.findFieldsByType(r.properties, type, field_type));
      } else {
        if (r.type === type && r.field_type === field_type) {
          acc.push(r);
        }
      }

      return acc;
    }, []);
  }

  /**
   *
   * @param {[Object]} fields Object field list.
   * @param {String} key String value that represent a field key.
   */
  findFieldsByKey(fields, key) {
    return fields.reduce((acc, r) => {
      if (r.properties && r.properties.length) {
        acc = acc.concat(this.findFieldsByKey(r.properties, key));
      } else {
        if (r.key && r.key === key) {
          acc.push(r);
        }
      }

      return acc;
    }, []);
  }

  getFieldsByType = (type, field_type) => {
    return this.findFieldsByType(this.fields, type, field_type);
  };

  getValidationRules(key) {
    const validations = this.validations;
    const { rules = {} } = validations;
    return rules[key] || [{}];
  }

  getEnum(enumRef, parentEnum = '') {
    return this.enumsManager.getEnum(enumRef, parentEnum);
  }

  get header() {
    return this.header$.value;
  }

  set header(header) {
    this.header$.next(setHeader(this.header, header));
  }

  get flags() {
    return this.flags$.value;
  }

  set flags(flags) {
    const _flags = this.flags;
    this.flags$.next({
      ..._flags,
      ...flags,
    });
  }
}

export default new ComponentsManager();
