import React from 'react';
import { Button } from 'antd';
import uuid from 'uuid/v4';
import getAttributeDefaultValue from './getAttributeDefaultValue';
import { get } from 'lodash';

const SectionAddButton = ({
  sectionId,
  attributeId,
  attributes,
  mainForm,
  addSectionFieldValue,
  setEditSectionTable,
  buttonTitle = null,
  prefix,
  disabled = false,
  onClick,
}) => {
  const addRow = event => {
    event.stopPropagation();

    const key = uuid();
    const section = `${prefix}[${sectionId}]`;
    attributes.forEach(
      ({ attributeName, type, validValues, settings = {} }) => {
        const { defaultValue, unique = false } = settings;
        let takenValues;
        if (unique) {
          takenValues = get(mainForm, `values.${section}[${attributeName}]`);
        }
        const value =
          defaultValue !== undefined
            ? defaultValue
            : getAttributeDefaultValue(type, validValues, takenValues);
        addSectionFieldValue(`${section}[${attributeName}]`, value);
      }
    );
    addSectionFieldValue(`${section}[${attributeId}]`, key);
    setEditSectionTable(sectionId, key);
  };

  return (
    <Button
      type="primary"
      size="default"
      disabled={disabled}
      ghost
      onClick={onClick || addRow}
    >
      {buttonTitle || 'Add Record'}
    </Button>
  );
};

export default SectionAddButton;
