import OrganizationEndpoints from '../../../api/organization/organizationEndpoints';
export default (ouId, getSuppress = false) => {
  return new Promise((resolve, reject) => {
    OrganizationEndpoints.getOUPointOfContact(ouId, getSuppress)
      .then(response => {
        const list = response.content?.map(content => {
          let {
            id,
            firstName,
            lastName,
            title,
            contactType,
            phones,
            emails,
            notes,
          } = content;

          phones = phones
            .map(a => {
              return a.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
            })
            .join('\n');
          emails = emails
            .map(a => {
              return a.email;
            })
            .join('\n');

          return {
            key: id,
            name: `${firstName} ${lastName}`,
            title,
            contactType,
            phone: phones,
            email: emails,
            notes,
          };
        });

        resolve({ data: list });
      })
      .catch(error => {
        reject({ data: null, error });
      });
  });
};
