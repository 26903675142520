import { DELETE_REPORT_ERROR } from './';

/**
 * @param {object} error
 * @return {object}
 */
export default function deleteReportError(error) {
  return {
    type: DELETE_REPORT_ERROR,
    payload: error,
  };
}
