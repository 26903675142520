import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Row, Col, DatePicker, Spin } from 'antd';
import { get } from 'lodash';

import SliderPanel from '../../components/form-slider';
import Panel, { PanelTable, PanelTitle } from '../../components/common/panel';
import { BLACK, DIVIDER } from '../../config/theme';

import getStats from '../../actions/get-stats';
import changeStatsUOFDates from '../../actions/change-stats-uof-dates';

import parseDate, {
  BENCHMARK_DATE_FORMAT,
  parseDateFromFormat,
} from '../../utils/parse-date';

import { getAgencyTZ } from '../../selectors/session';

import { FORM_TEMPLATE_IDENTIFIERS } from '../../constants/form-types';
import ComingSoon from './styled/ComingSoon';

const RangePicker = DatePicker.RangePicker;

const renderCell = (text, record, index) => {
  const className = `table-cell ${
    record.className ? `${record.className}` : ''
  }`;
  return <div className={className}>{text ? text : 'N/A'}</div>;
};

const columns = [
  {
    key: 'label',
    title: 'Officer Force Applied',
    dataIndex: 'label',
    className: 'type',
    render: renderCell,
    width: '25%',
  },
  {
    key: 'officerCount',
    title: 'Officer Total',
    dataIndex: 'officerCount',
    className: 'center',
    render: renderCell,
    width: '25%',
  },
  {
    key: 'assignedUnitAverage',
    title: 'Assigned Unit Average',
    dataIndex: 'assignedUnitAverage',
    className: 'center',
    render: renderCell,
    width: '25%',
  },
  {
    key: 'departmentAverage',
    title: 'Department Average',
    dataIndex: 'departmentAverage',
    className: 'center',
    render: renderCell,
    width: '25%',
  },
];

const CustomTable = styled(PanelTable)`
  th {
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    font-weight: bold !important;
    text-align: center !important;
  }

  table {
    border-bottom: 1px solid ${DIVIDER} !important;
  }

  .table-cell {
    min-height: 44px;
    line-height: 44px;
  }

  .type {
    color: ${BLACK};
  }

  .center {
    text-align: center;
  }

  @media (max-width: 767px) {
    table {
      min-width: 600px;
    }
  }
`;

const TimeframedTable = props => {
  const LastRow = styled.div`
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
  `;
  const { data: { stats = [], resistedArrestCount = 0 } = {} } = props;

  const dataSource = stats.map((stat, index) => {
    return {
      key: index.toString(),
      ...stat,
    };
  });

  return (
    <Panel isTable>
      <CustomTable columns={columns} dataSource={dataSource} />
      <Row style={{ padding: 16 }}>
        <Col xs={24} sm={14} md={12} lg={10}>
          <LastRow>Use of Force Associated with Resisting Arrest:</LastRow>
        </Col>
        <Col xs={24} sm={10} md={12} lg={14}>
          {resistedArrestCount ? resistedArrestCount : 'N/A'}
        </Col>
      </Row>
    </Panel>
  );
};

class OfficerUseOfForce extends Component {
  changeDateRange = (date, dateString) => {
    const { timezone, changeStatsUOFDates, getStatsUOF } = this.props;

    const [_from, to] = dateString;

    changeStatsUOFDates({
      from: _from,
      to,
      timezone,
    });

    const datesRange = {
      fromDate: encodeURIComponent(_from),
      toDate: encodeURIComponent(to),
    };

    getStatsUOF('use-of-force', datesRange);

    if (this.slider) {
      this.slider.onChange(100, false);
    }
  };

  changeSlider = ({ from: initialDate }) => ({ toDate: finalDate }) => {
    const { getStatsUOF, timezone } = this.props;

    const fromDateToParse = initialDate.value;
    const fromDate = encodeURIComponent(
      typeof fromDateToParse === 'string'
        ? fromDateToParse
        : parseDate(fromDateToParse, timezone, BENCHMARK_DATE_FORMAT)
    );

    const toDateToParse = finalDate;
    const toDate = encodeURIComponent(
      typeof toDateToParse === 'string'
        ? toDateToParse
        : parseDate(toDateToParse, timezone, BENCHMARK_DATE_FORMAT)
    );

    const datesRange = {
      fromDate,
      toDate,
    };

    getStatsUOF(datesRange);
  };

  saveSliderRef = el => {
    this.slider = el;
  };

  isEnabled = () => {
    const {
      form: { templates },
    } = this.props;
    const UOF = get(templates, FORM_TEMPLATE_IDENTIFIERS.UOF, {});
    return get(UOF, 'isEnable', false);
  };

  render() {
    const {
      selectedUser: { stats },
      getStatsUOF,
      timezone,
    } = this.props;
    const { dates } = stats;
    const momentifiedFromDate = parseDateFromFormat(
      dates.from.value,
      timezone,
      BENCHMARK_DATE_FORMAT
    );
    const momentifiedToDate = parseDateFromFormat(
      dates.to.value,
      timezone,
      BENCHMARK_DATE_FORMAT
    );

    if (!this.isEnabled()) return <ComingSoon />;

    return (
      <DashContainer
        className="dashboard-container"
        style={{ overflow: 'hidden' }}
      >
        <PanelTitle>
          <h1>Use of Force</h1>

          <div className="range-control">
            <RangePicker
              allowClear={false}
              onChange={this.changeDateRange}
              format={'MM/DD/YYYY'}
              defaultValue={[momentifiedFromDate, momentifiedToDate]}
            />
          </div>

          <div className="clearfix" />
        </PanelTitle>

        <SliderPanel
          ref={this.saveSliderRef}
          dates={dates}
          timezone={timezone}
          defaultValue={100}
          onChange={(...args) => getStatsUOF('use-of-force', ...args)}
        />

        <Spin spinning={stats.loading}>
          <TimeframedTable data={stats.data} />
        </Spin>
      </DashContainer>
    );
  }
}

export const DashContainer = styled.div`
  h1 {
    float: left;

    @media screen and (max-width: 767px) {
      float: none;
    }
  }

  .range-control {
    float: right;

    @media screen and (max-width: 767px) {
      float: none;
      margin: 10px 0 15px;
    }
  }
`;

const mapState = state => {
  const { app, session, selectedUser, form } = state;
  return {
    app,
    session,
    form,
    selectedUser: {
      ...selectedUser,
      stats: get(selectedUser, 'stats.use-of-force', {}),
    },
    timezone: getAgencyTZ(state),
  };
};

export default connect(mapState, {
  getStatsUOF: getStats,
  changeStatsUOFDates,
})(OfficerUseOfForce);
