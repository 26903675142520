import isNil from 'lodash/isNil';

import { genOptions, route, fetchRequest } from '../utils/request';
import storage from '../utils/storage';

const ssoURI = process.env.REACT_APP_SSO_URI;

export const login = ({ user, token }) => {
  const url = route('/BenchmarkUsers/login');
  const options = genOptions('POST', user, token);

  return fetchRequest(url, options);
};

export const loginSSO = token => {
  const url = route(`${ssoURI}/oauth/check_token?token=${token}`);
  const options = genOptions('GET');

  return fetchRequest(url, options);
};

export const getMinifiedToken = userId => {
  const token = storage.get('token');
  const url = route(`/BenchmarkUsers/${userId}/minifyToken`);
  const options = genOptions('POST', {}, token);

  return fetchRequest(url, options);
};

export const logout = () => {
  const token = storage.get('token');
  const url = route(`${ssoURI}/oauth/revoke`);
  const options = genOptions('DELETE', null, token);
  return fetchRequest(url, options);
};

export const getUserHistory = ({ userId }) => {
  const filter = {};
  const token = storage.get('token');
  const url = route(
    `/BenchmarkUsers/${userId}/history?filter=${JSON.stringify(filter)}`
  );
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

export const forgotPassword = ({ values }) => {
  const url = route('/BenchmarkUsers/request-password-reset');
  const token = storage.get('token');
  const options = genOptions('PUT', values, token);
  return fetchRequest(url, options);
};

/**
 * @param {Object} pararms
 * @param {string} pararms.oldPassword Optional, required if params.token is not present.
 * @param {string} pararms.newPassword Required. The password to be assigned.
 * @param {string} pararms.token Optional, required if params.oldPassword is not
   present. Defaults to `storage.get('token')`
 * @return {Promise}
 */
export const updateUserPassword = ({
  oldPassword,
  newPassword,
  accessToken,
}) => {
  const url = route('/BenchmarkUsers/change-password');
  const requestToken = isNil(oldPassword) ? accessToken : storage.get('token');
  const options = genOptions(
    'POST',
    { oldPassword, newPassword },
    requestToken
  );

  return fetchRequest(url, options);
};

/**
 * It only fetches configurations that the FE would eventually use.
 * @param {string} agencyId the id of the agency
 * @return {Promise}
 */
export const getAgencyConfigurations = agencyId => {
  const token = storage.get('token');
  const configOptions = ['title', 'identity'];
  const url = route(
    `/Agencies/${agencyId}/configurations?entity=template&configOptions=${encodeURIComponent(
      JSON.stringify(configOptions)
    )}`
  );
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

/**
 *
 * @param {Integer} agencyId the id of the agency
 * @return {Promise}
 */
export const getUserKMICourses = userId => {
  const token = storage.get('token');
  const url = route(`/BenchmarkUsers/${userId}/stats/kmi-course`);
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

/**
 * fetch /BenchmarkUsers/:id/kmiCourseRegistrations
 * @param {*} userId
 * @param {*} RegistrationFilter optional
 * @param {*} courseFilter optional
 */
export const getUserCertifications = (
  userId,
  RegistrationFilter,
  courseFilter
) => {
  const token = storage.get('token');
  const url = route(
    `/BenchmarkUsers/${userId}/kmiCourseRegistrations?courseFilter=${courseFilter}&RegistrationFilter=${RegistrationFilter}`
  );
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};
