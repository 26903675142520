export const PAGE_SIZE = 10;
export const TABLE_ASC = 'ascend';
export const ASC_PARAM = 'ASC';
export const DESC_PARAM = 'DESC';

export const STATUS_OPEN = 'open';
export const STATUS_PAUSED = 'paused';
export const STATUS_CLOSED = 'closed';
export const STATUS_OVERDUE = 'overdue';
export const STATUS_WITHOUT = 'without_status';

// Initial state for casefile statuses statistics
export const CASEFILE_STATUSES = {
  [STATUS_OPEN]: {},
  [STATUS_PAUSED]: {},
  [STATUS_CLOSED]: 0,
  [STATUS_OVERDUE]: 0,
};
