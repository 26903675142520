import React, { Component } from 'react';
import { Select, Row, Col, Pagination, Table, DatePicker } from 'antd';
import moment from 'moment';
import {
  StyledRow,
  StyledFilterCol,
  StyledRowPagination,
} from './audit-logs-table.styled';
import MainTitle from '../../components/common/main-title';

import { BENCHMARK_DATE_FORMAT } from '../../utils/parse-date';

const Option = Select.Option;
const { RangePicker } = DatePicker;

class AuditLogsTable extends Component {
  state = {
    category: 'Data Element',
    detail: 'SSN',
    page: 1,
    searchClear: false,
    dateFrom: moment().format(BENCHMARK_DATE_FORMAT),
    dateTo: moment().format(BENCHMARK_DATE_FORMAT),
  };

  getParamsFromUrl = () => {
    const params = new URLSearchParams(global.location.search);
    const page = Number(params.get('page') || 1);
    const category = params.get('category') || 'Data Element';
    const detail = params.get('detail') || 'SSN';
    const dateFrom =
      params.get('date-from') || moment().format(BENCHMARK_DATE_FORMAT);
    const dateTo =
      params.get('date-to') || moment().format(BENCHMARK_DATE_FORMAT);
    this.setState({
      page,
      category,
      detail,
      dateFrom,
      dateTo,
      searchClear: !!category && !!detail,
    });
    return { page, category, detail, dateFrom, dateTo };
  };

  setSearchCriteria = (page, category, detail, dateFrom, dateTo) => {
    const { history, location } = this.props;
    const searchCriteria = `?page=${page}&category=${category}&detail=${detail}&date-from=${dateFrom}&date-to=${dateTo}`;
    history.push({
      pathname: location.pathname,
      search: searchCriteria,
    });
  };

  componentDidMount() {
    this.search();
  }

  changePage = page => {
    window.scrollTo(0, 0);
    const { category, detail, dateFrom, dateTo } = this.getParamsFromUrl();
    this.setSearchCriteria(page, category, detail, dateFrom, dateTo);
  };

  search = () => {
    const {
      page,
      category,
      detail,
      dateFrom,
      dateTo,
    } = this.getParamsFromUrl();
    const { onSearch } = this.props;
    onSearch(this.props, {
      page,
      category,
      detail,
      dateFrom,
      dateTo,
    });
  };

  handleSearchClear = () => {
    this.setSearchCriteria(1, '', '', '', '');
  };

  handleCategoryChange = (category = '') => {
    const { page, detail, dateFrom, dateTo } = this.state;
    this.setSearchCriteria(page, category, detail, dateFrom, dateTo);
  };

  handleDetailChange = (detail = '') => {
    const { page, category, dateFrom, dateTo } = this.state;
    this.setSearchCriteria(page, category, detail, dateFrom, dateTo);
  };

  disabledDate = current => current && current.valueOf() > Date.now();

  onDatePickerChange = (dates, dateStrings) => {
    const { page, category, detail } = this.state;
    this.setSearchCriteria(
      page,
      category,
      detail,
      dateStrings[0],
      dateStrings[1]
    );
  };

  render() {
    const {
      total = 0,
      pageSize = 10,
      data = [],
      loading = false,
      showTitle = true,
    } = this.props;

    const { page, detail, category, dateFrom, dateTo } = this.state;
    const dateFromValue = moment(dateFrom, BENCHMARK_DATE_FORMAT);
    const dateToValue = moment(dateTo, BENCHMARK_DATE_FORMAT);

    return (
      <div>
        {showTitle && (
          <Row>
            <Col span={12} className="text-left">
              <MainTitle>SSN Audit Logs</MainTitle>
            </Col>
          </Row>
        )}
        <StyledRow type="flex" gutter={16} justify="end">
          <StyledFilterCol md={4} sm={8} xs={24}>
            <Select
              optionFilterProp="children"
              style={{ width: '100%' }}
              value={category}
              aria-label="Category"
              placeholder="Category"
              size="default"
              onChange={this.handleCategoryChange}
            >
              <Option key={'Data Element'} value={'Data Element'}>
                {'Data Element'}
              </Option>
            </Select>
          </StyledFilterCol>
          <StyledFilterCol md={4} sm={8} xs={24}>
            <Select
              optionFilterProp="children"
              value={detail}
              style={{ width: '100%' }}
              aria-label="Detail"
              placeholder="Detail"
              size="default"
              onChange={this.handleDetailChange}
            >
              <Option key={'SSN'} value={'SSN'}>
                {'SSN'}
              </Option>
            </Select>
          </StyledFilterCol>

          <StyledFilterCol md={8} xl={6} sm={8} xs={24}>
            <RangePicker
              value={[dateFromValue, dateToValue]}
              format={BENCHMARK_DATE_FORMAT}
              onChange={this.onDatePickerChange}
              disabledDate={this.disabledDate}
              size="default"
              className="full-width"
            />
          </StyledFilterCol>
        </StyledRow>

        <StyledRow>
          <Col span={24}>
            <div className="responsive-table">
              <Table dataSource={data} loading={loading} pagination={false}>
                {this.props.children}
              </Table>
            </div>
          </Col>
        </StyledRow>

        {total > 0 && (
          <StyledRowPagination>
            <Col span={24} className="text-right">
              <Pagination
                defaultCurrent={1}
                current={page}
                total={total}
                pageSize={pageSize}
                onChange={this.changePage}
              />
            </Col>
          </StyledRowPagination>
        )}
      </div>
    );
  }
}

export default AuditLogsTable;
