import { castArray, some } from 'lodash';

export const getEditableFields = usingConfig => withFieldName => {
  const { editable = false } = usingConfig[withFieldName] || {};
  return editable;
};

export const shouldBeReadOnlyField = (fromFieldList, usingConfig) => {
  const fields = castArray(fromFieldList);

  return !some(fields, getEditableFields(usingConfig));
};
