const STATUS = {
  draft: 'Draft',
  'in-review': 'In Review',
  'take-action': 'Take Action',
  done: 'Done',
  closed: 'Closed',
  approved: 'Approved',
  pending: 'Pending',
  voided: 'Voided',
};

export default STATUS;
