import { has } from 'lodash';

import { REPEATER } from 'APP_ROOT/constants/layoutComponentTypes';

import getAllFieldsFlat from '../../utils/getAllFieldsFlat';

const removeRepeaterReference = ({ repeatersReference, tabIndex, field }) => {
  let tabData = repeatersReference[tabIndex] || [];

  if (field.type === REPEATER) {
    repeatersReference[tabIndex] = tabData.filter(
      repeater => repeater.repeaterKey !== field.key
    );
  } else {
    let allKeys = [field.key];
    let findKey = field.key;
    if (has(field, 'properties')) {
      const { fieldsByKey = {} } = getAllFieldsFlat([field]);
      allKeys = Object.keys(fieldsByKey);
      findKey = allKeys[0] || findKey;
    }
    const repeaterFound = tabData.find(repeater => repeater.fields[findKey]);

    if (repeaterFound) {
      allKeys.forEach(key => {
        delete repeaterFound.fields[key];
      });
    }
  }
};

export default removeRepeaterReference;
