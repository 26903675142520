export const SOURCE_TYPE = 'source';
export const SOURCE_TYPE_LABEL = 'Source';
export const USERS = 'users';
export const OFFICERS_LABEL = 'Officers';
export const OFFICERS_VALUE = 'officers';
export const OFFICERS_RANK_LABEL = 'Officers with Rank';
export const OFFICERS_RANK_VALUE = 'officers-rank';
export const OFFICERS_CIVILIANS_LABEL = 'Officers and Civilians';
export const OFFICERS_CIVILIANS_VALUE = 'all';
export const CIVILIANS_LABEL = 'Civilians';
export const CIVILIANS_VALUE = 'civilians';
export const TENANT_LABEL = 'Tenant users';
export const TENANT_VALUE = 'tenant';
export const TENANT_USERS = 'tenantUsers';
export const REPEATING_LABEL = 'Repeating Element';
export const REPEATING_VALUE = 'repeating';
export const OPTIONS_TYPE = 'options';
export const VALUE = 'value';
export const FILTER_TYPE = 'filter';
export const POPULATED_TYPE = 'populateFrom';
export const BASIC_LABELS = ['rank.name', 'firstName', 'lastName'];
export const BENCHMARK_ID = 'benchmarkId';
export const OR_CRITERIA = 'orCriteria';
export const STAR_NUMBER = 'starNumber';
export const EMPLOYEE_ID = 'employeeId';
export const FILTER_NON_OP_USERS = 'filterNonOpUsers';
export const IS_CONTRIBUTOR_SECTION = 'isContributorSection';

function generate_from(values) {
  return {
    __users: {
      prefix: '',
      label: [...values],
      value: 'id',
    },
  };
}

export const BASIC_FROM = {
  __users: {
    prefix: '',
    label: [...BASIC_LABELS],
    value: 'id',
  },
};
export const SPECIFIC_FROM = (...items) => generate_from(items);
