import * as Rx from 'rxjs';

class ChangesReportReassign {
  constructor() {
    this.submit$ = new Rx.BehaviorSubject(false);
    this.closeModal$ = new Rx.BehaviorSubject(false);
    this.isOpenModal$ = new Rx.BehaviorSubject(false);
  }

  setSubmit(value) {
    this.submit$.next(value);
  }

  getSubmit() {
    return this.submit$.asObservable();
  }

  setCloseModal(value) {
    this.closeModal$.next(value);
  }

  getCloseModal() {
    return this.closeModal$.asObservable();
  }

  setIsOpenModal(value) {
    this.isOpenModal$.next(value);
  }

  getIsOpenModal() {
    return this.isOpenModal$.asObservable();
  }
}

export default new ChangesReportReassign();
