import { camelCase } from 'lodash';

import WorkflowBuilder from './WorkflowBuilder';
import { PERMISSIONS } from '../../../../utils/admin';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/:workflowId`,
    name: camelCase(`${name}-workflow`),
    component: WorkflowBuilder,
    protectedWith: [PERMISSIONS.manageWorkflows],
    childRoutes: [],
  },
];
