import React from 'react';
import { isEmpty, pick } from 'lodash';
import { Button, Icon, Row, Col, Modal } from 'antd';
import moment from 'moment';
import StyledModal from './UserProfileSectionModal.styled';

// using moment directly instead of parseDate from parse-date,
// because parseDate needs a timezone which I don't have
import { BENCHMARK_DATE_TIMEZ_FORMAT } from '../../../../../utils/parse-date';

import UserProfileSectionForm from './UserProfileSectionForm';

import { SaveSectionWarning } from '../UserProfile.constants';
import organizationEndpoints from '../../../../../api/organization/organizationEndpoints';
import { connect } from 'react-redux';
import showErrorMessages from '../errors/OuErrorMessages';

const UserProfileSectionModal = ({
  title,
  attributes,
  visible,
  sectionId,
  profileForm,
  rowIndex,
  readOnly,
  modifiedBy,
  onOk,
  onCancel,
  userId,
  tenantId,
  sectionsDefinition,
  isNew,
  dispatch,
}) => (
  <StyledModal
    title={title}
    width={1000}
    className="user-profile-modal"
    centered
    visible={visible}
    onOk={onOk}
    onCancel={onCancel}
    footer={
      readOnly ? (
        [
          <Button key="Done" type="primary" onClick={onOk}>
            Done
          </Button>,
        ]
      ) : isNew ? (
        [
          <Button key="cancel" onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="Save" type="primary" onClick={onOk}>
            Apply changes
          </Button>,
        ]
      ) : (
        <div
          style={{
            display: 'flex',
          }}
        >
          <Button
            key="delete"
            type="danger"
            onClick={async () => {
              Modal.confirm({
                title: 'Heads Up!',
                okText: 'Yes',
                content: (
                  <p>
                    You cannot undo deleting this record; do you want to
                    continue?
                  </p>
                ),
                onOk: async () => {
                  try {
                    const section = sectionsDefinition.find(
                      section => section.sectionId === sectionId
                    );
                    const values =
                      section.tableValues[rowIndex].objectAttributeValues || [];
                    const valueIds = values.map(value =>
                      pick(value, ['valueId'])
                    );

                    const sections = {
                      sections: [
                        {
                          sectionId: sectionId,
                          tableValues: [
                            {
                              delete: true,
                              objectAttributeValues: valueIds,
                            },
                          ],
                        },
                      ],
                    };

                    await organizationEndpoints.updateTenantDatastoreUsers(
                      tenantId,
                      userId,
                      sections
                    );
                    window.location.reload();
                  } catch (err) {
                    showErrorMessages(dispatch, err);
                  }
                },
              });
            }}
          >
            Delete
          </Button>
          <Button
            key="cancel"
            onClick={onCancel}
            style={{
              marginLeft: 'auto',
            }}
          >
            Cancel
          </Button>
          <Button key="Save" type="primary" onClick={onOk}>
            Apply changes
          </Button>
        </div>
      )
    }
  >
    <UserProfileSectionForm
      key={sectionId}
      sectionId={sectionId}
      prefix="sectionsData"
      profileForm={profileForm}
      isModal={true}
      rowIndex={rowIndex}
      attributes={attributes}
      isSectionReadOnly={readOnly}
    />
    {!isEmpty(modifiedBy) && modifiedBy.createdBy && (
      <Row>
        <Col>
          Created by <strong>{modifiedBy.createdBy}</strong>,{' '}
          {moment(modifiedBy.createdAt).format(BENCHMARK_DATE_TIMEZ_FORMAT)}
        </Col>
      </Row>
    )}
    {!isEmpty(modifiedBy) && modifiedBy.lastModifiedBy && (
      <Row>
        <Col>
          Updated by <strong>{modifiedBy.lastModifiedBy}</strong>,{' '}
          {moment(modifiedBy.updatedAt).format(BENCHMARK_DATE_TIMEZ_FORMAT)}
        </Col>
      </Row>
    )}
    {!isEmpty(modifiedBy) && <br />}
    {!readOnly && (
      <Row type="flex" justify="center">
        <Col>
          <Icon type="exclamation-circle" />
          <SaveSectionWarning></SaveSectionWarning>
        </Col>
      </Row>
    )}
  </StyledModal>
);

export default connect()(UserProfileSectionModal);
