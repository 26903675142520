import styled from 'styled-components';
import { Layout } from 'antd';

const StyledLayout = styled(Layout)`
  &.ant-layout {
    width: 100%;
    background-color: #fff;
    min-height: auto;
    align-items: center;
    flex-direction: row;
  }
`;

export default StyledLayout;
