import React from 'react';
import {
  difference,
  flattenDeep,
  has,
  unionWith,
  isEmpty,
  isEqual,
} from 'lodash';

import { Alert, Button, Form, Icon, Input, Col, Row, Select } from 'antd';
import { withRouter } from 'react-router-dom';

import { toCapitalizedWords } from '../../../../../modules/FormBuilder/utils/general-utilities';

import './propagate-field.component.css';

const { Option, OptGroup } = Select;
const { Item } = Form;

const FIELD_INDEX = 0;
const PROPAGATE_AS_INDEX = 1;

const PROPAGATE_FIELDS = 'fields';
const PROPAGETE_REPEATERS = 'repeaters';

const MISMATCH_FIELDS = 'mismatchedFields';
const MISMATCH_PROPAGATE = 'mismatchedPropagation';

const EMPTY_VALUE_ERROR_FIELD = 'Please select a field to propagate from.';
const EMPTY_VALUE_ERROR_PROPAGATE_AS = 'Please select a field to propagate to.';
const MISSING_FIELD_ERROR_MESSAGE =
  'The value of this field was deleted or manually updated. Please replace it.';

class PropagateField extends React.Component {
  state = {
    isloading: false,
    [PROPAGATE_FIELDS]: [],
    [PROPAGETE_REPEATERS]: [],
  };

  addFieldLabel = 'Add Propagate Field';
  addRepeaterLabel = 'Add Propagate Repeater';

  getAllFieldValues = fields =>
    fields.flatMap(f => f.children).map(f => f.value);

  componentDidMount() {
    const { propagateFields, mainFields, mainRepeaters } = this.props;
    const allFields = this.getAllFieldValues(mainFields);
    const allRepeaters = this.getAllFieldValues(mainRepeaters);
    if (propagateFields && propagateFields.length > 0) {
      const fields = propagateFields.filter(element =>
        allFields.includes(element.field)
      );
      const repeaters = propagateFields.filter(element =>
        allRepeaters.includes(element.field)
      );
      this.setState({
        [PROPAGATE_FIELDS]: fields,
        [PROPAGETE_REPEATERS]: repeaters,
      });
    }
  }

  render() {
    const {
      mainFields,
      mainRepeaters,
      reviewNoteFields,
      reviewNoteRepeaters,
    } = this.props;
    this.props.form.getFieldDecorator(this.props.itemKey, {
      initialValue: this.props.propagateFields,
    });
    this.resetMismatches();
    return (
      <Row gutter={16}>
        <Col span={24}>
          {this.drawPropagateFieldsSection(
            PROPAGATE_FIELDS,
            mainFields,
            reviewNoteFields,
            this.addFieldLabel
          )}
        </Col>
        <Col span={24}>
          {this.drawPropagateFieldsSection(
            PROPAGETE_REPEATERS,
            mainRepeaters,
            reviewNoteRepeaters,
            this.addRepeaterLabel
          )}
        </Col>
      </Row>
    );
  }

  drawPropagateFieldsSection(
    key,
    mainTemplate,
    reviewNoteTemplate,
    addLabel = 'add'
  ) {
    if (this.state.isloading) {
      return this.drawInputLoader(
        'Loading fields...',
        'Loading propagateFields...'
      );
    }
    return (
      <Item label={toCapitalizedWords(key)}>
        <Row gutter={16}>
          <Col span={8} offset={16}>
            <Button icon="plus-circle" onClick={() => this.add(key)}>
              {addLabel}
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }} gutter={16}>
          <Col span={24}>
            {this.drawPropagationFieldElements(
              key,
              mainTemplate,
              reviewNoteTemplate
            )}
          </Col>
        </Row>
      </Item>
    );
  }

  drawInputLoader(
    label = 'Loading...',
    help = 'Content is loading...',
    placeholder = "I'm loading the content"
  ) {
    return (
      <Item
        label={toCapitalizedWords(label)}
        colon={false}
        hasFeedback
        validateStatus="validating"
        help={help}
      >
        <Input placeholder={placeholder} />
      </Item>
    );
  }

  drawPropagationFieldElements(key, mainFields = [], reviewNoteFields = []) {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator(key, { initialValue: this.state[key] });
    const fields = getFieldValue(key);
    this.checkForMismatches(fields, mainFields, reviewNoteFields);
    return this.matchKeysToPropagationBy(
      fields,
      mainFields,
      reviewNoteFields,
      key
    );
  }

  checkForMismatches(fields, mainFields, reviewNoteFields) {
    if (fields.length > 0) {
      const fieldValues = fields.map(field => field.field);
      const propagationValues = fields.map(field => field.propagateAs);
      const mainFormFieldValues = flattenDeep(
        mainFields.map(field => field.children)
      ).map(field => field.value);
      const reviewFormFieldValues = reviewNoteFields.map(field => field.value);
      this.findMismatches(fieldValues, mainFormFieldValues, MISMATCH_FIELDS);
      this.findMismatches(
        propagationValues,
        reviewFormFieldValues,
        MISMATCH_PROPAGATE
      );
    }
  }

  findMismatches(original, updated, updateKey) {
    const mismatch = difference(original, updated).filter(
      element => !isEmpty(element)
    );
    if (mismatch.length > 0) {
      this[updateKey] = this[updateKey].concat(mismatch);
    }
  }

  resetMismatches() {
    this[MISMATCH_FIELDS] = [];
    this[MISMATCH_PROPAGATE] = [];
  }

  matchKeysToPropagationBy(fields, mainFormFields, reivewNoteFields, key) {
    const fieldFromSettings = {
      validateTrigger: ['onChange', 'onBlur'],
      rules: [
        {
          required: true,
          whitespace: true,
          message: EMPTY_VALUE_ERROR_FIELD,
        },
      ],
    };
    const fieldToSettings = {
      validateTrigger: ['onChange', 'onBlur'],
      rules: [
        {
          required: true,
          whitespace: true,
          message: EMPTY_VALUE_ERROR_PROPAGATE_AS,
        },
      ],
    };
    return fields.map((field, index) => (
      <Item key={index}>
        <Row gutter={16}>
          <Col span={11}>
            <h4> {toCapitalizedWords(Object.keys(field)[FIELD_INDEX])} </h4>
            {this.drawSelectWithGroups(
              Object.keys(field)[FIELD_INDEX],
              index,
              key,
              fieldFromSettings,
              field,
              this[MISMATCH_FIELDS],
              'Please select a field from',
              mainFormFields
            )}
            {this.drawAlert(field.field, this[MISMATCH_FIELDS])}
          </Col>
          <Col span={11}>
            <h4>
              {toCapitalizedWords(Object.keys(field)[PROPAGATE_AS_INDEX])}
            </h4>
            {this.drawSelect(
              Object.keys(field)[PROPAGATE_AS_INDEX],
              index,
              key,
              fieldToSettings,
              field,
              this[MISMATCH_PROPAGATE],
              'Please select a field to',
              reivewNoteFields
            )}
            {this.drawAlert(field.propagateAs, this[MISMATCH_PROPAGATE])}
          </Col>
          <Col span={2}>
            <Icon
              className="dynamic-delete-button mt-2"
              type="minus-circle-o"
              onClick={() => this.remove(field, key)}
            />
          </Col>
        </Row>
      </Item>
    ));
  }

  drawSelectWithGroups(
    key,
    index,
    fieldType,
    fieldSettings,
    value,
    mismatchFields,
    placeholder = '',
    list = []
  ) {
    const { getFieldDecorator } = this.props.form;
    const uniqueKey = `${key} ${index} ${fieldType}`;
    this.setInitialValue(value[key], fieldSettings, mismatchFields);
    const groups = this.matchTemplateFieldToSelectOptionGroup(list);
    return (
      <Item colon={false} key={uniqueKey}>
        {' '}
        {getFieldDecorator(
          uniqueKey,
          fieldSettings
        )(
          <Select
            placeholder={placeholder}
            onSelect={value =>
              this.onFieldSection(value, fieldType, key, index)
            }
          >
            {groups}
          </Select>
        )}
      </Item>
    );
  }

  drawSelect(
    key,
    index,
    fieldType,
    fieldSettings,
    value,
    mismatchFields,
    placeholder = '',
    list = []
  ) {
    const { getFieldDecorator } = this.props.form;
    const uniqueKey = `${key} ${index} ${fieldType}`;
    this.setInitialValue(value[key], fieldSettings, mismatchFields);
    const options = this.matchTemplateFieldToSelectOption(list);
    return (
      <Item colon={false} key={uniqueKey}>
        {' '}
        {getFieldDecorator(
          uniqueKey,
          fieldSettings
        )(
          <Select
            placeholder={placeholder}
            onSelect={value =>
              this.onFieldSection(value, fieldType, key, index)
            }
          >
            {options}
          </Select>
        )}
      </Item>
    );
  }

  drawAlert(field, missingFields) {
    if (missingFields.includes(field)) {
      return (
        <Alert
          message="Warning"
          description={MISSING_FIELD_ERROR_MESSAGE}
          type="warning"
          showIcon
        />
      );
    }
  }

  setInitialValue(value, fieldSettings, mismatchFields) {
    if (value) {
      if (!mismatchFields.includes(value)) {
        fieldSettings.initialValue = value;
      } else {
        fieldSettings.initialValue = null;
      }
    }
  }

  matchTemplateFieldToSelectOptionGroup(list) {
    return list
      .filter(element => has(element, 'label'))
      .map(element => {
        return (
          <OptGroup key={list.indexOf(element)} label={element.label}>
            {this.matchTemplateFieldToSelectOption(element.children)}
          </OptGroup>
        );
      });
  }

  matchTemplateFieldToSelectOption(list) {
    return list
      .filter(element => has(element, 'label') && has(element, 'value'))
      .map(element => (
        <Option key={list.indexOf(element)} value={element.value}>
          {element.label}
        </Option>
      ));
  }

  removeElementFromList(element, list) {
    const index = list.indexOf(element);
    if (index !== -1) {
      list.splice(index, 1);
    }
  }

  updatePropagateFields() {
    const { form, itemKey } = this.props;
    const fields = form.getFieldValue(PROPAGATE_FIELDS);
    const repeater = form.getFieldValue(PROPAGETE_REPEATERS);
    const nextValue = unionWith(fields, repeater, isEqual);
    form.setFieldsValue({ [itemKey]: nextValue });
  }

  remove = (fieldToRemove, key) => {
    const { form, itemKey } = this.props;
    const formValues = form.getFieldValue(key);
    const propagateFields = form.getFieldValue(itemKey);
    // removes element in the dynamic field display
    this.removeElementFromList(fieldToRemove, formValues);
    // removes element in the data to submit
    this.removeElementFromList(fieldToRemove, propagateFields);
    // removes element in the form
    form.setFieldsValue({ [key]: formValues });
  };

  add = key => {
    const { form } = this.props;
    const propagateFields = form.getFieldValue(key);
    const nextValue = {
      [key]: propagateFields.concat({ field: '', propagateAs: '' }),
    };
    form.setFieldsValue(nextValue);
  };

  onFieldSection = (value, type, key, index) => {
    const formValues = this.props.form.getFieldValue(type);
    Object.assign(formValues[index], { [key]: value });
    this.updatePropagateFields();
  };
}

// "withRouter" enables the component to access URL variables, location and history.
export default withRouter(PropagateField);
