import callback from '../../utils/callback';
import documentManagementEndpoints from '../../../../api/documentManagement/documentManagementEndpoints';

export default (
  sortDirection,
  pageNumber,
  pageSize,
  sortBy,
  filters,
  tenantSlug,
  next
) => _dispatch => {
  return documentManagementEndpoints
    .getS3DocumentMetadata(
      sortDirection,
      pageNumber,
      pageSize,
      sortBy,
      filters,
      tenantSlug
    )
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
