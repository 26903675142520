import { get } from 'lodash';

import transformTemplateConfiguration, {
  getTemplateType,
  getTemplateOptions,
} from '../../../utils/transformTemplateConfiguration';

export default configs => template =>
  Object.assign({}, template, {
    templateConfig: transformTemplateConfiguration(
      getTemplateOptions(getTemplateType(template), get(configs, 'options', []))
    ),
  });
