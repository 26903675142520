import styled from 'styled-components';
import { Menu } from 'antd';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

const DropdownMenu = styled(Menu)`
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 180px; /* 32px * 5 (five elements height) + 16px (show half the 6th) + 4px (margin-top) */
`;

const MenuItem = styled(Menu.Item)`
  margin-right: 8px;
  background-color: #fff;

  overflow: hidden;
  max-width: 180px;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    cursor: text;
    background-color: #fff;
  }
`;

export { Container, MenuItem, DropdownMenu };
