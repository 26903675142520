import { Select } from 'antd';
import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { InlineInputWrapper } from '../../../components/input';
import organizationEndpoints from '../../../../../api/organization/organizationEndpoints';

const { Option } = Select;

export class SupervisorField extends Component {
  state = { isLoading: false, allUsers: [] };

  onSearch = async value => {
    this.setState({ isLoading: true });

    const { agencyId } = this.props;

    const filters = {
      pageSize: 20,
      includeDisabled: false,
      sworn: true,
      sortBy: 'firstName',
      fullName: value,
      projection: 'IdAndNameAndLastNameOnly',
    };

    this.setState({
      allUsers: await organizationEndpoints.getUsersSearch(agencyId, filters),
      isLoading: false,
    });
  };

  onChange = value => {
    this.props.input.onChange(value);
  };

  render() {
    const { allUsers, isLoading } = this.state;

    const generateOptions = () =>
      !isEmpty(allUsers)
        ? allUsers.map(item => {
            const fullName = item.firstName + ' ' + item.lastName;
            return <Option key={fullName}>{fullName}</Option>;
          })
        : null;

    return (
      <Select
        showSearch
        placeholder="Type name here"
        onSearch={this.onSearch}
        onChange={this.onChange}
        value={this.props.input.value}
        loading={isLoading}
        filterOption={false}
      >
        {generateOptions()}
      </Select>
    );
  }
}

export const WrappedSupervisorField = props => {
  const { label, ...rest } = props;

  return (
    <InlineInputWrapper label={label}>
      <SupervisorField {...rest} />
    </InlineInputWrapper>
  );
};
