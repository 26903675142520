import getComponentOptionsByType from './getComponentOptionsByType';
import getCustomFieldOptionsWithFallback from './getCustomFieldOptionsWithFallback';

const getCustomFields = (type, field_type, values) => {
  const customFields = getComponentOptionsByType(type, field_type, values);

  return Array.isArray(customFields)
    ? customFields.map(getCustomFieldOptionsWithFallback(values))
    : [customFields];
};

export default getCustomFields;
