import React, { useEffect, useState } from 'react';
import { Icon } from 'antd';

import {
  ShowMoreButton,
  StyledTable,
  FooterText,
} from './CasefileAuditLogs.styled';
import getCasefileAuditLogsTableConfig from './getCasefileAuditLogsTableConfig';
import caseFileEndpoints from '../../../../../api/caseFileEndpoints/caseFileEndpoints';
import CasefileAuditLogFilter from '../casefileAuditLogsFilters/CasefileAuditLogsFilters';

import { showError, ACTION_APPEND, ACTION_FETCH } from '../../constants';

function CasefileAuditLogs({ timezone, agencyId }) {
  const [auditLogs, setAuditLogs] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const tableConfig = getCasefileAuditLogsTableConfig(timezone);

  function fetchAuditLogs(action) {
    setLoading(true);
    caseFileEndpoints
      .getAuditLogs(filters, nextPage)
      .then(response => processResponse(action)(response))
      .catch(err => showError(err))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchAuditLogs(ACTION_FETCH);
  }, []);

  useEffect(() => {
    if (!loading) {
      fetchAuditLogs(ACTION_FETCH);
    }
  }, [filters]);

  const processResponse = action => response => {
    const {
      content: { auditLogs: _auditLogs, cursor },
    } = response;

    switch (action) {
      case ACTION_FETCH:
        setAuditLogs(_auditLogs);
        setNextPage(cursor);
        break;

      case ACTION_APPEND:
        setAuditLogs(prevState => [...prevState, ..._auditLogs]);
        setNextPage(cursor);
        break;

      default:
        return;
    }
  };

  const handleFilterChange = newFilters => {
    setNextPage(null);
    setFilters(newFilters);
  };

  return (
    <>
      <CasefileAuditLogFilter
        handleFilterChange={handleFilterChange}
        agencyId={agencyId}
        loading={loading}
      />
      <StyledTable
        rowKey={record => record.auditId}
        columns={tableConfig}
        dataSource={auditLogs}
        loading={loading}
        pagination={false}
        scroll={{ x: '100%' }}
      />
      {nextPage ? (
        <ShowMoreButton
          data-testid="showMoreButton"
          type="link"
          disabled={loading}
          onClick={() => fetchAuditLogs(ACTION_APPEND)}
        >
          {loading ? 'Loading...' : 'Show more'}
        </ShowMoreButton>
      ) : (
        <FooterText>
          <Icon type="file-text" style={{ fontSize: 48 }} />
          <span data-testid="noMoreLogsToShow">No more logs to show</span>
        </FooterText>
      )}
    </>
  );
}

export default CasefileAuditLogs;
