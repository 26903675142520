import React from 'react';

import { Icon } from 'antd';

const MenuButton = ({
  onClick,
  iconClassName,
  text,
  isLoading = false,
  iconType,
}) => {
  //Return a loading icon meanwhile the status is true for loading and a <i> with an icon when false for loading
  if (iconType && iconClassName) {
    return (
      <span onClick={onClick && (() => onClick())}>
        {isLoading ? <Icon type={iconType} /> : <i className={iconClassName} />}
        <span className="nav-text">{text}</span>
      </span>
    );
  }

  //Return <i> with an icon if non return any of the above.
  return (
    <span onClick={onClick && (() => onClick())}>
      {iconType ? <Icon type={iconType} /> : <i className={iconClassName} />}
      <span className="nav-text">{text}</span>
    </span>
  );
};

export default MenuButton;
