import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Icon } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DashboardPage from '../../components/dashboard';
import SubTitle from '../../components/common/sub-title';
// Modal
import withModal from '../../components/common/modal/base';
import showModal from '../../actions/show-modal';
import hideModal from '../../actions/hide-modal';
import updateModal from '../../actions/update-modal';
import createModal from '../../actions/create-modal';
import ModalTitle from '../../components/common/modal/title';

import {
  ModalButton,
  BodyReviewDismiss,
  BodyActiveCare,
  SuccessActiveCare,
  BodyInternalAffairs,
} from '../../components/common/modal/partials/internal-affairs';

import {
  PanelSection,
  Divider,
  CollapsePanel,
} from '../../components/common/panel';
import BigButton from '../../components/common/buttons/big-button';
import Breadcrumb, {
  Item as BreadcrumbItem,
} from '../../components/common/breadcrumb';
import { ButtonsContainer } from './styled';
const greenFlag = require('../../icons/ic_flag_green.svg');
const redFlag = require('../../icons/ic_flag.svg');
const icInternalAffairs = require('../../icons/ic_internal_affairs.svg');

class NeedingReview extends withModal(Component) {
  static propTypes = {
    isNeedingReview: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isNeedingReview: true,
  };

  state = {
    loading: false,
  };

  UNSAFE_componentWillMount() {
    this.createModal();
  }

  componentWillUnmount() {
    this.hideModal();
    this.deleteModal();
  }

  // Review and Dismiss
  onReviewDismiss = values => {
    const { dispatch } = this.props;

    const onCancel = () => {
      dispatch(hideModal('iacommander-review'));
    };

    const Footer = [
      <ModalButton key="cancel" label="Cancel" onClick={onCancel} />,
      <ModalButton
        key="submit"
        label="Submit"
        type="primary"
        onClick={onCancel}
      />,
    ];

    const options = {
      id: 'iacommander-review',
      title: (
        <ModalTitle success>
          <h3>Review and Dismiss</h3>
        </ModalTitle>
      ),
      children: BodyReviewDismiss,
      footer: Footer,
      onOk: () => {
        dispatch(hideModal(this.id));
      },
    };

    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  // Active C.A.R.E.
  onActivateCare = values => {
    const { loading } = this.state;
    const { dispatch } = this.props;

    const onOk = () => {
      const Footer = [
        <ModalButton key="cancel" label="Cancel" />,
        <ModalButton key="submit" label="Submit" loading type="primary" />,
      ];

      this.setState({ loading: true }, () => {
        dispatch(
          updateModal({
            id: 'iacommander-care',
            footer: Footer,
          })
        );
      });

      setTimeout(() => {
        dispatch(hideModal('iacommander-care'));
        dispatch(createModal(onSuccess));
        dispatch(showModal(onSuccess.id));
        this.setState({ loading: false });
      }, 1500);
    };

    const onCancel = () => {
      dispatch(hideModal('iacommander-care'));
    };

    const onSuccess = {
      id: 'iacommander-success',
      title: (
        <ModalTitle success>
          <Icon type="check-circle" /> <span>Success!</span>
        </ModalTitle>
      ),
      children: SuccessActiveCare,
    };

    const Footer = [
      <ModalButton key="cancel" label="Cancel" onClick={onCancel} />,
      <ModalButton
        key="submit"
        label="Submit"
        loading={loading}
        type="primary"
        onClick={onOk}
      />,
    ];

    const options = {
      id: 'iacommander-care',
      title: (
        <ModalTitle success>
          <h3>Activate C.A.R.E.</h3>
        </ModalTitle>
      ),
      children: BodyActiveCare,
      footer: Footer,
      onOk: () => {
        dispatch(hideModal(this.id));
      },
    };

    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  // Forward to Internal Affairs
  onForwardIA = values => {
    const { dispatch } = this.props;

    const onCancel = () => {
      dispatch(hideModal('iacommander-affairs'));
    };

    const Footer = [
      <ModalButton key="cancel" label="Cancel" onClick={onCancel} />,
      <ModalButton
        key="submit"
        label="Submit"
        type="primary"
        onClick={onCancel}
      />,
    ];

    const options = {
      id: 'iacommander-affairs',
      title: (
        <ModalTitle success>
          <h3>Forward to Internal Affairs</h3>
        </ModalTitle>
      ),
      children: BodyInternalAffairs,
      footer: Footer,
      onOk: () => {
        dispatch(hideModal(this.id));
      },
    };

    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  render() {
    const { isNeedingReview } = this.props;

    return (
      <div>
        <Breadcrumb separator=">">
          <BreadcrumbItem>Home</BreadcrumbItem>
          <BreadcrumbItem>Needing Review</BreadcrumbItem>
          <BreadcrumbItem>Office Ross Evers</BreadcrumbItem>
        </Breadcrumb>
        <CustomRow type="flex" align="middle">
          <Col span={22} className="text-left">
            <SubTitle>Officer Ross Evers</SubTitle>
          </Col>
          <Col span={2} className="text-right">
            <img
              src={isNeedingReview ? redFlag : greenFlag}
              alt="Flag"
              className="officer-flag"
            />
          </Col>
        </CustomRow>

        <CollapsePanel title="2 Use of Force" subtitle="Within 4 weeks">
          <Divider />
          <PanelSection>
            <Row type="flex" align="middle">
              <Col span={21}>
                <p>Physical Action</p>
              </Col>
              <Col span={3} className="text-right">
                <Link to="#">
                  <Icon type="arrow-right" />
                </Link>
              </Col>
            </Row>
          </PanelSection>
          <Divider />
          <PanelSection>
            <Row type="flex" align="middle">
              <Col span={21}>
                <p>Taser</p>
              </Col>
              <Col span={3} className="text-right">
                <Link to="#">
                  <Icon type="arrow-right" />
                </Link>
              </Col>
            </Row>
          </PanelSection>
        </CollapsePanel>

        <CollapsePanel title="3 Citizen Complaints" subtitle="Within 12 weeks">
          <Divider />
          <PanelSection>
            <Row type="flex" align="middle">
              <Col xs={11} sm={5}>
                <p>Verbal Abuse</p>
              </Col>
              <Col xs={11} sm={17}>
                <p>Under investigation</p>
              </Col>
              <Col span={2} className="text-right">
                <Link to="#">
                  <Icon type="arrow-right" />
                </Link>
              </Col>
            </Row>
          </PanelSection>
          <Divider />
          <PanelSection>
            <Row type="flex" align="middle">
              <Col xs={11} sm={5}>
                <p>Excessive Force</p>
              </Col>
              <Col xs={11} sm={17}>
                <p>Under investigation</p>
              </Col>
              <Col span={2} className="text-right">
                <Link to="#">
                  <Icon type="arrow-right" />
                </Link>
              </Col>
            </Row>
          </PanelSection>
          <Divider />
          <PanelSection>
            <Row type="flex" align="middle">
              <Col xs={11} sm={5}>
                <p>Failure to Provide Service</p>
              </Col>
              <Col xs={11} sm={17}>
                <p>Under investigation</p>
              </Col>
              <Col span={2} className="text-right">
                <Link to="#">
                  <Icon type="arrow-right" />
                </Link>
              </Col>
            </Row>
          </PanelSection>
        </CollapsePanel>

        <CollapsePanel
          title="2 Missed Court Appearances"
          subtitle="Within 12 weeks"
        />

        <ButtonsContainer className="text-center">
          <BigButton
            type="primary"
            size="default"
            ghost
            icon="check-square-o"
            onClick={this.onReviewDismiss}
          >
            Review & Dismiss
          </BigButton>
          <BigButton
            type="primary"
            size="default"
            ghost
            icon="star-o"
            onClick={this.onActivateCare}
          >
            Assign Intervention
          </BigButton>
          <BigButton
            type="primary"
            size="default"
            ghost
            onClick={this.onForwardIA}
          >
            <img src={icInternalAffairs} alt="internal-affairs" /> Forward to IA
          </BigButton>
        </ButtonsContainer>
      </div>
    );
  }
}

const CustomRow = styled(Row)`
  margin: 24px 0 16px 0;
`;

const mapState = () => ({});

export default withRouter(DashboardPage(mapState, null)(NeedingReview));
