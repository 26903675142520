import { createSelector } from 'reselect';
import { get } from 'lodash';

const getManageUsersState = state => state.manageUsers;
const getFormState = state => state.forms;

export const passAgencyId = (state, agencyId) => agencyId;
export const passUserId = (state, agencyId, userId) => userId;
export const passFormName = (state, formName) => formName;

export const getManageUsers = createSelector(
  getManageUsersState,
  manageUsers => manageUsers
);

export const getAgencyUsers = createSelector(
  [getManageUsersState, passAgencyId],
  (manageUsers, agencyId) => get(manageUsers, agencyId, {})
);

export const getAgencyUser = createSelector(
  [getManageUsersState, passAgencyId, passUserId],
  (manageUsers, agencyId, userId) =>
    get(manageUsers, [agencyId, 'currentUser', userId, 'data'], {})
);

export const getFormValues = createSelector(
  [getFormState, passFormName],
  (forms, formName) => get(forms, formName, {})
);
