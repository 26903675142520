import { unescape as htmlUnescape } from 'html-escaper';
import getAllFieldsFlat from '../../../modules/FormBuilder/utils/getAllFieldsFlat';
import { SELECT, MULTISELECT } from '../../../constants/fieldTypes';
import { isArray, isPlainObject, first } from 'lodash';

const unescapeValue = value =>
  isArray(value) ? value.map(htmlUnescape) : htmlUnescape(value);

const findRepeaters = data =>
  Object.keys(data).filter(
    key => isArray(data[key]) && isPlainObject(first(data[key]))
  );

const replaceValues = ({ report, template }) => {
  const { formSchema: { form: { properties = [] } } = {} } = template || {};
  const { fieldsByKey = {} } = getAllFieldsFlat(properties) || {};

  const selectableKeys = Object.keys(fieldsByKey).filter(
    key =>
      fieldsByKey[key].field_type === SELECT ||
      fieldsByKey[key].field_type === MULTISELECT
  );

  const repeaterKeys = findRepeaters(report.data);

  selectableKeys.forEach(key => {
    if (report.data.hasOwnProperty(key)) {
      report.data[key] = unescapeValue(report.data[key]);
    } else {
      repeaterKeys.forEach(repeaterKey => {
        report.data[repeaterKey].forEach(rItem => {
          if (rItem.hasOwnProperty(key)) {
            rItem[key] = unescapeValue(rItem[key]);
          }
        });
      });
    }
  });
};

export default replaceValues;
