import getRoute from 'APP_ROOT/utils/get-route';

export default ({ report, closeModal, push }) => () => {
  closeModal();
  // push(
  //   getRoute('izendaReportViewerSingle', {
  //     reportId: report.izendaReportId,
  //   }),
  // );
  // FIXME: this is temp, this is the workaround that allowed us to demo
  global.location.href = getRoute('izendaReportViewerSingle', {
    reportId: report.izendaReportId,
  });
};
