import styled from 'styled-components';
import { GRAY_2 } from '../../config/theme';

export const ParagraphContainer = styled.div`
  position: relative;
  display: block;

  p,
  .card-line,
  .linked-form-details {
    font-size: 15px;
    font-weight: 500;
    color: #4E4E4E;
  }

  .linked-form-details {
    &:last-child {
      margin-bottom: 0;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  .status-badge {
    padding: 5px;
    display: inline-block;
    background-color: #EFEFF0;
    color: #4E4E4E;
    font-size: 14px;
    font-weight: 500;
  }

  .event-date {
    color: ${GRAY_2};
  }

  @media (min-width: 768px) {
  .is-loading-animation {
    svg {
      width: 200px;
      max-width: 100%;
    }
  }

  .body-loader {
    &,
    svg {
      width: 400px;
      max-width: 100%;
    }
  }
`;

export default ParagraphContainer;
