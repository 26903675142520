import {
  FETCH_ACTIVITY_FEED_COUNT_REQUEST,
  FETCH_ACTIVITY_FEED_COUNT_SUCCESS,
  FETCH_ACTIVITY_FEED_COUNT_ERROR,
} from './';
import { getNotificationsCount } from '../api/activity';

export default (next = () => {}) => (dispatch, getState) => {
  const {
    session: { isLoggedIn, currentUser: { userId, agencyId } = {} } = {},
    activity: { activityFeed: { filter: filters = {} } = {} } = {},
    systemUpdates: { updating } = {},
  } = getState();

  const filter = {
    ...(filters.where || {}),
    new: true,
  };

  return new Promise((resolve, reject) => {
    if (isLoggedIn && agencyId && !updating) {
      dispatch({
        type: FETCH_ACTIVITY_FEED_COUNT_REQUEST,
        payload: { filter },
      });

      getNotificationsCount({ userId, filter })
        .then(response => {
          dispatch({
            type: FETCH_ACTIVITY_FEED_COUNT_SUCCESS,
            payload: response,
          });
          resolve(response);
        })
        .catch(err => {
          dispatch({ type: FETCH_ACTIVITY_FEED_COUNT_ERROR });
          reject(err);
        });
    } else {
      reject();
    }
  });
};
