import React from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { get, isEmpty } from 'lodash';

import withoutClutter from 'APP_COMPONENTS/without-clutter';
import DashboardPage from 'APP_COMPONENTS/dashboard';
import PageHeader from 'APP_COMPONENTS/PageHeader';

import AdministratorWrapper from '../Administrator.styled';

import getRoute from 'APP_ROOT/utils/get-route';
import { getTemplatesConfiguration } from 'APP_ROOT/selectors/session';
import parseDate, { BENCHMARK_DATE_FORMAT } from 'APP_ROOT/utils/parse-date';
import apiEndpoints from 'APP_ROOT/modules/FormBuilder/services/apiEndpoints';

import { getTemplates } from './ConfigureWorkflows.selectors';
import withOwnershipGuard from '../components/agency-ownership-guard';

import StyledRow from '../form-builder/FormBuilderHeader.styled';
import {
  TEMPLATE_NAME,
  TEMPLATE_NAME_LINK,
  TEMPLATE_CREATED,
  TEMPLATE_UPDATED,
  NO_TITLE,
  PAGE_SIZE,
  TABLE_ASC,
  TABLE_DESC,
  ASC_PARAM,
  DESC_PARAM,
} from '../form-builder/constants';
import sortString from '../form-builder/utils/sortString';
import sortDates from '../form-builder/utils/sortDates';

export class Component extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reading: false,
      loading: false,
      templateList: [],
      pagination: { pageSize: PAGE_SIZE, current: 1 },
    };

    this.COLUMNS = [
      {
        title: 'Name',
        dataIndex: TEMPLATE_NAME_LINK,
        key: 'nameLink',
        sorter: sortString,
      },
      {
        title: 'Template Version',
        children: [
          {
            title: 'Created',
            dataIndex: TEMPLATE_CREATED,
            key: 'created',
            sorter: sortDates,
            render: this.renderDate,
          },
          {
            title: 'Last Updated',
            dataIndex: TEMPLATE_UPDATED,
            key: 'updated',
            sorter: sortDates,
            defaultSortOrder: 'descend',
            render: this.renderDate,
          },
        ],
      },
    ];
  }

  componentDidMount() {
    this.fetchData(1, TEMPLATE_UPDATED, TABLE_DESC);
  }

  renderDate = date => {
    const { timezone } = this.props;
    return parseDate(date, timezone, BENCHMARK_DATE_FORMAT, false);
  };

  getTemplateLink = (template, agencyId) => (
    <Link
      to={getRoute('administratorAgencyWorkflowsTemplate', {
        agencyId,
        templateId: template.id,
      })}
    >
      {template.name}
    </Link>
  );

  getTableData = () => {
    const { agencyId } = this.props;
    const { templateList } = this.state;
    return templateList
      .filter(t => !t.default)
      .map(t => ({
        key: t.id,
        agencyId,
        [TEMPLATE_NAME]: t[TEMPLATE_NAME],
        [TEMPLATE_NAME_LINK]: this.getTemplateLink(t, agencyId),
        [TEMPLATE_CREATED]: t[TEMPLATE_CREATED],
        [TEMPLATE_UPDATED]: t[TEMPLATE_UPDATED],
      }));
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.fetchData(pagination.current, sorter.field, sorter.order);
  };

  fetchData = (currentPage = 1, sortBy, sortDirection) => {
    const { agencyId } = this.props;
    this.setState({ loading: true });

    apiEndpoints
      .getTemplateList({
        agencyId,
        onlyPublished: true,
        pageNumber: currentPage,
        pageSize: PAGE_SIZE,
        sortBy: sortBy === TEMPLATE_NAME_LINK ? TEMPLATE_NAME : sortBy,
        sortDirection: sortDirection === TABLE_ASC ? ASC_PARAM : DESC_PARAM,
      })
      .then(({ data, page }) => {
        const pagination = { ...this.state.pagination };
        pagination.total = page.totalRecords;
        const templateList = data.map(d => ({
          ...d,
          name: isEmpty(d.name) ? NO_TITLE : d.name,
        }));
        this.setState({ templateList, loading: false, pagination });
      });
  };

  render() {
    const { agencyId } = this.props;
    const { loading, pagination } = this.state;
    const data = this.getTableData();

    return (
      <AdministratorWrapper>
        <PageHeader
          title={'Workflows'}
          goBackTo={getRoute('administratorAgency', { agencyId })}
        />
        <div className="administrator-content">
          <StyledRow>
            <Table
              columns={this.COLUMNS}
              dataSource={data}
              loading={loading}
              pagination={pagination}
              onChange={this.handleTableChange}
            />
          </StyledRow>
        </div>
      </AdministratorWrapper>
    );
  }
}

const mapStateToProps = (state, props) => ({
  agencyTemplates: getTemplates(state),
  agencyId: get(props, 'match.params.agencyId'),
  templatesConfig: getTemplatesConfiguration(state),
});

export default withoutClutter(
  DashboardPage(mapStateToProps)(
    withOwnershipGuard(Component, 'administratorAgencyWorkflows')
  )
);
