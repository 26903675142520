import React from 'react';
import { get } from 'lodash';
import Repeater from '../../../components/static-repeater';
import If from '../../../../../components/utils/ConditionalRender';
import EquipmentSectionBuilder from './EquipmentSectionBuilder';
import { Row, Col } from 'antd';

const equipmentFieldNames = [
  'equipmentType',
  'equipmentTypeSubtype',
  'equipmentTypeType',
  'equipmentMake',
  'equipmentModel',
  'equipmentCaliberClass',
  'equipmentImporter',
  'equipmentSerialNumber',
  'equipmentRegistrationNumber',
];

const buildEquipmentFields = (equipmentSectionBuilder, fields, index) => {
  const rows = [];
  for (let i = 0; i < fields.length; i += 2) {
    rows.push(
      <Row key={`row-user-info-top${i}`}>
        <Col key={`col-${fields[i]}`} xs={24} sm={12} md={12} lg={12}>
          {equipmentSectionBuilder.get(fields[i], index)}
        </Col>
        <Col key={`col-${fields[i + 1]}`} xs={24} sm={12} md={12} lg={12}>
          {equipmentSectionBuilder.get(fields[i + 1], index)}
        </Col>
      </Row>
    );
  }
  return rows;
};

const EquipmentSection = ({
  formName,
  profileForm,
  saveRef,
  shouldSeeAnyField,
  shouldBeReadOnlyField,
  getFieldLabel,
  fetchingAgency,
}) => {
  const equipmentSectionBuilder = new EquipmentSectionBuilder({
    fetchingAgency,
    shouldBeReadOnlyField,
    shouldSeeAnyField,
    getFieldLabel,
  });

  const fieldNames = equipmentFieldNames.filter(field =>
    shouldSeeAnyField(field)
  );

  return (
    <If condition={shouldSeeAnyField(equipmentFieldNames)}>
      <Repeater
        formName={formName}
        field="equipment"
        items={get(profileForm, 'values.equipment')}
        ref={saveRef}
      >
        {(id, index) => (
          <div>
            <div className="divider" />
            {buildEquipmentFields(equipmentSectionBuilder, fieldNames, index)}
          </div>
        )}
      </Repeater>
    </If>
  );
};

export default EquipmentSection;
