import React from 'react';
import {
  sortAlphabetically,
  sortByCreationDate,
  renderDate,
} from './constants';

export default (timezone, handleClick) => [
  {
    title: 'Abbreviation',
    dataIndex: 'abbreviation',
    key: 'abbreviation',
    sorter: (a, b) => sortAlphabetically(a, b, 'abbreviation'),
    render: (abbreviation, casefileType) => (
      <a onClick={() => handleClick(casefileType)}>{abbreviation}</a>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    sorter: (a, b) => sortAlphabetically(a, b, 'type'),
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    sorter: (a, b) => sortAlphabetically(a, b, 'category'),
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => sortByCreationDate(a, b),
    render: data => renderDate(data, timezone),
  },
];
