import { Table, Icon, Button, Input } from 'antd';
import React, { useRef } from 'react';
import styled from 'styled-components';

export const SortAndFilterTable = ({
  rowKey,
  columns,
  data,
  pagination = true,
  scroll = 350,
}) => {
  const StyledTable = styled(Table)``;
  // eslint-disable-next-line no-unused-vars
  let searchInput = useRef(null);
  let onChange = (pagination, filters, sort) => {};
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = clearFilters => {
    clearFilters();
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={'Search'}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <div style={{ display: 'flex', justifyContent: 'center', gap: 5 }}>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon="search"
            size="small"
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small">
            Reset
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    render: text => text,
  });

  return (
    <div>
      <StyledTable
        columns={columns.map(obj => ({
          ...getColumnSearchProps(obj.dataIndex),
          ...obj,
        }))}
        size="small"
        rowKey={rowKey}
        dataSource={data}
        showSorterTooltip={'true'}
        onChange={onChange}
        pagination={pagination}
        scroll={{ y: scroll }}
      />
    </div>
  );
};

export default SortAndFilterTable;
