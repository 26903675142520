import { get } from 'lodash';

import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes';

const getFieldMeta = ({ fields, key = 'id', value }, meta = null) =>
  fields.reduce((fieldMeta, currentField) => {
    const properties = get(currentField, 'properties', []);

    if (currentField[key] === value) {
      return currentField;
    } else if (currentField.type !== FIELD && meta === null) {
      return getFieldMeta({ fields: properties, key, value }, fieldMeta);
    }

    return fieldMeta;
  }, meta);

export default getFieldMeta;
