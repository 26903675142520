import agencies from './agencies/Agencies.reducer';
import agencyProfile from './agency-profile/AgencyProfile.reducer';
import manageUsers from './agency-users/AgencyUsers.reducer';
import manageWorkflows from './configure-workflows/ConfigureWorkflows.reducer';

export default {
  agencies,
  manageUsers,
  manageWorkflows,
  agencyProfile,
};
