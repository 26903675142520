import has from 'lodash/has';
import each from 'lodash/each';

export const requireValidator = props => (rule, value, callback) => {
  const validationMessage = 'Compliant failing';

  const complies = false;

  if (!complies) {
    callback(validationMessage);
  }

  callback();
};

export default async (schema = { properties: [] }) => {
  let result = [];

  const matchKeys = (tabIndex = 0) => (property, index) => {
    if (has(property, 'type') && property.type === 'tab') {
      each(property.properties, matchKeys(index));
    } else if (has(property, 'type') && property.type === 'field') {
      // do nothing
    } else if (
      has(property, 'type') &&
      property.type === 'requirement-compliant'
    ) {
      const { options = {} } = property;

      result = [
        ...result,
        {
          ...options,
          tabIndex: tabIndex,
        },
      ];
    } else {
      each(property.properties, matchKeys(tabIndex));
    }
  };

  each(schema.properties, matchKeys());

  return result;
};
