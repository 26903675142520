import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';

class equipmentEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'http://localhost:3003';
    const apiPrefix = 'organizations/v1';
    super(
      process.env.REACT_APP_ORGANIZATIONS_SERVICE_URI || DEV_URI,
      apiPrefix
    );
  }

  async fetchRequestWrapper(fetchParams) {
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  async getUserEquipments({ tenantId, userId, pageNumber, pageSize, sort }) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/users/:userId/equipment'),
        method: 'GET',
      },
      { tenantId, userId },
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        sort: sort,
      }
    );

    return this.fetchRequestWrapper(fetchParams);
  }

  async getEquipmentCategories({ tenantId }) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/equipment/categories'),
        method: 'GET',
      },
      { tenantId }
    );

    return this.fetchRequestWrapper(fetchParams);
  }

  async postUserEquipment({ tenantId, userId, body }) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/users/:userId/equipment'),
        method: 'POST',
      },
      { tenantId, userId },
      {},
      body
    );

    return this.fetchRequestWrapper(fetchParams);
  }

  async updateUserEquipment({ tenantId, equipmentId, body }) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/equipment/:equipmentId'),
        method: 'PUT',
      },
      { tenantId, equipmentId },
      {},
      body
    );

    return this.fetchRequestWrapper(fetchParams);
  }

  async deleteUserEquipment({ tenantId, equipmentId }) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/equipment/:equipmentId'),
        method: 'DELETE',
      },
      { tenantId, equipmentId }
    );

    return this.fetchRequestWrapper(fetchParams);
  }

  async postUserEquipment({ tenantId, userId, body }) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/users/:userId/equipment'),
        method: 'POST',
      },
      {
        tenantId,
        userId,
      },
      {},
      body
    );

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }

  async updateUserEquipment({ tenantId, equipmentId, body }) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/equipment/:equipmentId'),
        method: 'PUT',
      },
      {
        tenantId,
        equipmentId,
      },
      {},
      body
    );

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }
}

export default new equipmentEndpoints();
