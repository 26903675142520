import { get, kebabCase } from 'lodash';

const currentUserId = props => get(props, 'data.activeUser', '');

const getCurrentWorkFlowStatus = props =>
  kebabCase(
    get(
      props,
      `workFlowData.status[${currentUserId(props)}].label`,
      get(props, 'workFlowData.state', '')
    )
  );

export default getCurrentWorkFlowStatus;
