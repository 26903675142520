import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'antd';
import YearPicker from '../agency-export-reports/YearPicker';
import styled from 'styled-components';
import { get } from 'lodash';
import moment from 'moment';
import AdministratorWrapper from '../Administrator.styled';
import PageHeader from 'APP_COMPONENTS/PageHeader';

// Containers
import DashboardPage from '../../../components/dashboard';

// Components
import IconButton from '../../../components/common/buttons/icon-button';

import dataExportEndpoints from '../../../api/data-exports/dataExportEndpoints';
import { StyledButton } from './Exports.styled';
import WithoutClutter from '../../../components/without-clutter/WithoutClutter';
import getRoute from '../../../utils/get-route';

const { Column } = Table;

function DataExportComponent(props) {
  const exportTypeNamesMap = {
    RULE_28: 'Rule 28',
    'RULE_24-31-315': 'Rule 24-31-315',
  };

  const agencyId = props.agency.id;

  const [loading, setLoading] = useState(false);
  const [dataExportList, setDataExportList] = useState(false);
  const [refreshExportRequest, setRefreshExportRequest] = useState(null);
  const [downloadId, setDownloadId] = useState(null);
  const [year, setYear] = useState(null);

  const filterRule28 = row => {
    return row.export_type === 'RULE_28';
  };

  const refreshExportsTable = async () => {
    try {
      setLoading(true);
      const result = await dataExportEndpoints.getDataExportList();
      setLoading(false);
      setDataExportList(result.filter(filterRule28));
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log('Exception loading data export list: ', ex);
    }
  };

  useEffect(() => {
    refreshExportsTable();
  }, []);

  useEffect(() => {
    const callExport = async () => {
      if (refreshExportRequest === null) {
        return;
      }
      try {
        setLoading(true);
        const result = await dataExportEndpoints.submitDataExportRequest(
          'RULE_28',
          null,
          `${year}-01-01`,
          `${year}-12-31`
        );
        setLoading(false);
        setDataExportList(result.filter(filterRule28));
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.log('Exception submitting data export request: ', ex);
      }
    };
    callExport();
  }, [refreshExportRequest]);

  useEffect(() => {
    const callDownload = async () => {
      if (downloadId === null) {
        return;
      }
      try {
        setDownloadId(null);
        const result = await dataExportEndpoints.getDownloadLink(downloadId);
        window.open(result, '_blank');
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.log('Exception downloading report: ', ex);
      }
    };
    callDownload();
  }, [downloadId]);

  return (
    <AdministratorWrapper>
      <PageHeader
        isLoading={false}
        title={<span>Rule 28 Exports</span>}
        goBackTo={getRoute('administratorAgency', {
          agencyId,
        })}
      ></PageHeader>
      <div
        className="administrator-content"
        style={{
          padding: '16px 16px',
        }}
      >
        <Row gutter={8}>
          <Col span={3} className="text-left">
            <YearPicker onChange={setYear} />
          </Col>
          <Col span={3} className="text-left">
            <StyledButton
              type="primary"
              size="default"
              disabled={!year}
              loading={false}
              onClick={() => {
                setRefreshExportRequest(!refreshExportRequest);
              }}
            >
              Generate Export
            </StyledButton>
          </Col>
        </Row>
        <StyledRow>
          <Col span={24}>
            <div className="responsive-table">
              <Table
                dataSource={dataExportList}
                // onChange={this.handleChange}
                loading={loading}
                pagination={true}
              >
                <Column
                  title="Report Name"
                  dataIndex="export_type"
                  key="export_type"
                  render={text => (
                    <div>
                      {exportTypeNamesMap[text] || JSON.stringify(text)}
                    </div>
                  )}
                />
                <Column
                  title="Generated Date"
                  dataIndex="generated_date"
                  key="generated_date"
                  render={text => {
                    const momentifiedStartDate = text
                      ? moment(text).utc()
                      : moment();
                    return (
                      <div>
                        {momentifiedStartDate &&
                          momentifiedStartDate.format('M/D/Y h:mm:ss a')}
                      </div>
                    );
                  }}
                />
                <Column
                  title="Export ID"
                  dataIndex="id"
                  key="id"
                  render={text => {
                    return text;
                  }}
                />
                <Column
                  title="Export Status"
                  dataIndex="job_status"
                  key="job_status"
                  render={text => {
                    return (
                      <div>
                        {{
                          PROGRESSING: 'In Progress',
                          FAILED: 'Failed',
                          COMPLETED: 'Completed',
                        }[text] || text}
                      </div>
                    );
                  }}
                />
                <Column
                  title="Download"
                  dataIndex="id"
                  key="id"
                  render={(text, record) => {
                    const id = get(record, 'id');
                    const status = get(record, 'job_status');
                    return (
                      status === 'COMPLETED' && (
                        <IconButton
                          icon="download"
                          className="arrow"
                          onClick={() => {
                            setDownloadId(id);
                          }}
                        />
                      )
                    );
                  }}
                />
              </Table>
            </div>
          </Col>
        </StyledRow>
      </div>
    </AdministratorWrapper>
  );
}

const StyledRow = styled(Row)`
  margin-top: 16px;

  .arrow {
    margin: 0 10px;
  }
`;

export default WithoutClutter(DashboardPage()(DataExportComponent));
