import React from 'react';

import ActivityReport from '../../components/dashboard/activity-report';

const Activity = props => (
  <div>
    <ActivityReport />
  </div>
);

export default Activity;
