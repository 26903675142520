import { URL_SEARCH_PARAM_PAGE } from './constants';

const setPageRetention = (page, history) => {
  const {
    location: { search, pathname },
  } = history;
  const params = new URLSearchParams(search);
  params.set(URL_SEARCH_PARAM_PAGE, page);
  const path = [pathname, params.toString()].join('?');
  history.push(path);
};

export default setPageRetention;
