import React from 'react';
import { first, get } from 'lodash';

import { Button, Drawer, Form, Col, Row } from 'antd';
import { withRouter } from 'react-router-dom';

import { toCapitalizedWords, isFunction } from '../../utils/general-utilities';

import PropagateAction from './propagate-action/propagate-action.component';
import PropagateField from './propagate-field/propagate-field.component';

import './review-note-drawer.component.css';

import TemplateElementFlattener from '../../utils/template-element-flattener';

class ReviewNoteDrawer extends React.Component {
  render() {
    const {
      name,
      visible,
      onClose,
      propagateActionsKey,
      propagateActions,
      propagateFieldsKey,
      propagateFields,
    } = this.props;
    const { getFieldDecorator } = this.props.form;
    const mainFields = this.mapMainFormFields();
    const reviewNoteFields = this.mapReviewFormFields();
    const mainRepeaters = this.mapMainFormRepeaters();
    const reviewNoteRepeaters = this.mapReviewFormRepeaters();
    return (
      <div>
        <Drawer
          title={`${toCapitalizedWords(name)}'s configurations`}
          width={600}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={24}>
                <PropagateAction
                  getFieldDecorator={getFieldDecorator}
                  propagateActions={propagateActions}
                  itemKey={propagateActionsKey}
                ></PropagateAction>
              </Col>
            </Row>
            <Row className="mt-2" gutter={16}>
              <Col span={24}>
                <PropagateField
                  form={this.props.form}
                  mainFields={mainFields}
                  mainRepeaters={mainRepeaters}
                  reviewNoteFields={reviewNoteFields}
                  reviewNoteRepeaters={reviewNoteRepeaters}
                  itemKey={propagateFieldsKey}
                  propagateFields={propagateFields}
                ></PropagateField>
              </Col>
            </Row>
          </Form>
          <div className="footer">
            <Button type="primary" onClick={this.saveReviewNoteConfigurations}>
              {' '}
              Save{' '}
            </Button>
            <Button className="ml-1" onClick={onClose}>
              {' '}
              Close{' '}
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }

  saveReviewNoteConfigurations = () => {
    this.props.form.validateFields(this.submitValues);
  };

  submitValues = (errors, values) => {
    if (!errors) {
      const {
        propagateActionsKey,
        propagateFieldsKey,
        onSubmission,
      } = this.props;
      const data = {
        [propagateActionsKey]: values[propagateActionsKey],
        [propagateFieldsKey]: values[propagateFieldsKey],
      };
      if (isFunction(onSubmission)) {
        onSubmission(data);
      }
      this.props.onClose();
    }
  };

  mapMainFormFields() {
    const tabs = this.mapFieldsOf(this.props.mainTemplate);
    return tabs.map(tab => {
      return {
        value: tab.title,
        label: tab.title,
        children: tab.properties.map(element => {
          return { label: this.getLabelOf(element), value: element.key };
        }),
      };
    });
  }

  mapMainFormRepeaters() {
    const tabs = this.mapRepeatersOf(this.props.mainTemplate);
    return tabs
      .filter(tab => tab.properties.length > 0)
      .map(tab => {
        return {
          value: tab.title,
          label: tab.title,
          children: tab.properties.map(element => {
            return { label: this.getLabelOf(element), value: element.key };
          }),
        };
      });
  }

  mapReviewFormFields() {
    const fields = get(
      first(this.mapFieldsOf(this.props.selectedTemplate)),
      'properties',
      []
    );
    return fields.map(field => {
      return { label: this.getLabelOf(field), value: field.key };
    });
  }

  mapReviewFormRepeaters() {
    const fields = get(
      first(this.mapRepeatersOf(this.props.selectedTemplate)),
      'properties',
      []
    );
    return fields.map(field => {
      return { label: this.getLabelOf(field), value: field.key };
    });
  }

  mapFieldsOf(template) {
    const fields = get(template, 'formSchema.form.properties', []);
    return TemplateElementFlattener.getAllFieldsExcludingRepeatersFlat(fields)
      .allTabs;
  }

  mapRepeatersOf(template) {
    const fields = get(template, 'formSchema.form.properties', []);
    return TemplateElementFlattener.getAllRepeaters(fields).allTabs;
  }

  getLabelOf(fieldProperties) {
    const title = get(fieldProperties, 'title');
    const reportingKey = get(fieldProperties, 'reportingKey');
    return title || reportingKey;
  }
}

// "withRouter" enables the component to access URL variables, location and history.
// "Form.create()" enables ant desgin form features in the component.
export default withRouter(Form.create()(ReviewNoteDrawer));
