import callback from '../../utils/callback';

import securityV2Endpoints from '../../../../api/security/securityV2Endpoints';

export default (agencyId, isInternal, next) => _dispatch => {
  return securityV2Endpoints
    .getSecurityRolesByAgencyId(agencyId, isInternal)
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
