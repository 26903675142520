import { camelCase } from 'lodash';
import { PERMISSIONS } from '../../../utils/admin';
import AgencyBulkUserImport from './AgencyBulkUsersImport';

export default ({ path = '', name = '' }) => [
  {
    path: `${path}/users-import`,
    name: camelCase(`${name}-bulk-users-import`),
    component: AgencyBulkUserImport,
    protectedWith: [PERMISSIONS.createAnyUser, PERMISSIONS.createAgencyUser],
    childRoutes: [],
  },
];
