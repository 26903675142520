import React from 'react';
import { Table, Button } from 'antd';
import { Link } from 'react-router-dom';
import route from 'APP_ROOT/utils/get-route';
import StyledRolesTable from './RolesTable.styled';
import parseDate from 'APP_ROOT/utils/parse-date';
import savePreviousPath from 'APP_ROOT/utils/savePreviousPath';

const { Column } = Table;

export default ({
  agencyId = 1,
  agencyName,
  timezone,
  roles = [],
  copyRoleToClipboard = () => {},
  isCopyButtonDisable = false,
}) => (
  <StyledRolesTable>
    <Table dataSource={roles} rowKey={record => record.id} pagination={false}>
      <Column
        title="Rank Number"
        dataIndex="rankNumber"
        key="rank"
        defaultSortOrder="ascend"
        sorter={(a, b) => a.rankNumber - b.rankNumber}
      />
      <Column
        title="Role"
        key="name"
        sorter={(a, b) => a.name.localeCompare(b.name)}
        render={(text, record) => (
          <Link
            to={savePreviousPath(
              route('permissionAttributes', {
                agencyId: agencyId,
                permission: record.name,
                roleId: record.id,
                agencyName: agencyName,
              })
            )}
          >
            {record.name}
          </Link>
        )}
      />
      <Column
        title="Role Id"
        dataIndex="id"
        key="id"
        sorter={(a, b) => a.id - b.id}
      />
      <Column
        title="Updated"
        dataIndex="updated"
        key="updated"
        render={(text, record) => parseDate(text, timezone)}
        sorter={(a, b) => a.updated.localeCompare(b.updated)}
      />
      <Column
        title="Copy"
        dataIndex="copyJson"
        key="copyJson"
        align="center"
        render={(text, { id, name, rankNumber }) => (
          <Button
            type="primary"
            icon="copy"
            size="small"
            onClick={() => copyRoleToClipboard(id, name, rankNumber)}
            disabled={isCopyButtonDisable}
            ghost
          />
        )}
      />
    </Table>
  </StyledRolesTable>
);
