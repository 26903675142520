import styled from 'styled-components';
import { Modal } from 'antd';

const StyledModal = styled(Modal)`
  &.user-profile-modal {
    max-width: 1000px;
    width: 100% !important;

    .ant-modal-footer {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
`;

export default StyledModal;
