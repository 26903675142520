import React from 'react';
import BrowserDetection from 'react-browser-detection';

import AdaptableSVG from './adaptable-svg';

const browserHandler = props => ({
  ie: () => <AdaptableSVG {...props} />,
  default: () => <svg {...props} />,
});

const SVG = props => {
  return (
    <BrowserDetection once={false}>{browserHandler(props)}</BrowserDetection>
  );
};

export default SVG;
