import { isEmpty } from 'lodash';

import configurationEndpoints from '../../../api/configuration/configurationEndpoints';

class CalculatedFieldsConfig {
  constructor() {
    this.calculatedConfig = [];
  }

  async loadCalculatedFieldsConfig(agencyId) {
    if (isEmpty(this.calculatedConfig) && agencyId) {
      this.calculatedConfig = await configurationEndpoints.getCalculatedConfig(
        agencyId
      );
    }
  }

  get calculatedFieldConfig() {
    return this.calculatedConfig;
  }

  set calculatedFieldConfig(config) {
    this.calculatedConfig = config;
  }
}

export default new CalculatedFieldsConfig();
