import { get } from 'lodash';

const addComponentToParent = (field, index) => (allFields, currentField) => {
  const properties = get(currentField, 'properties', []);

  let newProperties;
  if (index === undefined || index > properties.length || index < 0) {
    newProperties = properties.concat(field);
  } else {
    properties.splice(index, 0, field);
    newProperties = properties;
  }
  return allFields.concat({
    ...currentField,
    properties: newProperties,
  });
};

export default addComponentToParent;
