import styled from 'styled-components';

import { ACCENT, DARK_GRAY } from '../../config/theme';

export default styled.h1`
  font-size: 32px;
  font-weight: 600;
  padding: 0 0 24px;
  color: ${DARK_GRAY};

  @media screen and (max-width: 480px), (max-width: 992px) {
    padding: 0 0 12px;
  }

  .anticon-loading {
    font-size: 16px;
    color: ${ACCENT};

    &::before {
      animation-duration: 0.6s;
    }
  }
`;
