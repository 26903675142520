import { flatMap, compact, unionWith } from 'lodash';
import {
  FIELD,
  REPEATER,
  SECTION,
} from 'APP_ROOT/constants/layoutComponentTypes';

const getAllRepeaters = (fields, currentFieldId) => {
  const getField = field => {
    if (field.type === REPEATER) {
      return field.id === currentFieldId
        ? null
        : unionWith(
            [{ ...field, properties: [] }],
            compact(flatMap(field.properties, getField)),
            isPropEqual
          );
    }
    if (field.type === FIELD) {
      return null;
    }
    if (field.type === SECTION && field.id === currentFieldId) {
      return null;
    }
    if (field.properties) {
      return flatMap(field.properties, getField);
    }
    return null;
  };

  // to check if prop is equal
  const isPropEqual = (p1, p2) => p1.key === p2.key;
  const allTabs = flatMap(fields, tab => ({
    title: tab.title,
    key: tab.key,
    properties: unionWith(
      compact(flatMap(tab.properties, getField)),
      isPropEqual
    ),
  }));
  let fieldsByKey = {};

  allTabs.forEach(tab => {
    if (tab.properties) {
      fieldsByKey = tab.properties.reduce((hash, obj) => {
        hash[obj.key] = obj;
        return hash;
      }, fieldsByKey);
    }
  });

  return { allTabs, fieldsByKey };
};

// get all the fields in a flat structure and by tabs
// when addExtra is true, isReviewer and isHidden are
// added to fieldsByKey
const getAllFieldsExcludingRepeatersFlat = (fields, currentFieldId) => {
  const getField = field => {
    if (field.type === FIELD) {
      return field.id === currentFieldId ? null : field;
    }
    if (field.type === REPEATER) {
      return null;
    }
    if (field.type === SECTION && field.id === currentFieldId) {
      return null;
    }
    if (field.properties) {
      return flatMap(field.properties, getField);
    }
    return null;
  };

  // to check if prop is equal
  const isPropEqual = (p1, p2) => p1.key === p2.key;
  const allTabs = flatMap(fields, tab => ({
    title: tab.title,
    key: tab.key,
    properties: unionWith(
      compact(flatMap(tab.properties, getField)),
      isPropEqual
    ),
  }));
  let fieldsByKey = {};

  allTabs.forEach(tab => {
    if (tab.properties) {
      fieldsByKey = tab.properties.reduce((hash, obj) => {
        hash[obj.key] = obj;
        return hash;
      }, fieldsByKey);
    }
  });

  return { allTabs, fieldsByKey };
};

const TemplateElementFlattener = {
  getAllRepeaters,
  getAllFieldsExcludingRepeatersFlat,
};

export default TemplateElementFlattener;
