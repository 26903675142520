import React, { Component } from 'react';

import bodyDiagramFront from '../body-parts/diagram-front.json';
import Part from '../part-types';
import SVG from '../common/inline-svg';

class BodyFront extends Component {
  render() {
    const {
      onClick,
      selectedParts = [],
      validParts=[],
      isReviewer = false,
      disabled = false,
    } = this.props;

    const bodySide = bodyDiagramFront;
    const { childs } = bodySide;

    const strokeColor = disabled ? '#CCC' : '#2D2D2D';

    return (
      <SVG
        version="1.1"
        id="Layer_1"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="126.8 14.6 109.9 221.2"
        enableBackground="new 126.8 14.6 109.9 221.2"
        xmlSpace="preserve">
        <g id="body-sections">
          {childs &&
            childs.filter(child => validParts.includes(child.name)).map((child, index) => {
              const selected = selectedParts
                ? selectedParts.includes(child.name)
                : false;

              return (
                <Part
                  key={index}
                  path={child}
                  onClick={onClick}
                  selected={selected}
                  isReviewer={isReviewer}
                />
              );
            })}
        </g>
        <g id="body-diagram">
          <path
            fill="none"
            stroke={strokeColor}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            d="M186.8,43.3c0,0,0,3.1,0,5.3
      			c0,4,6.5,5.1,11.3,6.2c3.4,0.8,7.8,2.5,10,4.7c3.2,3.2,3.5,6,3.5,11.5c0,6.6,0,8.5,0,11c0,4.2,0.8,9,1.6,12.1
      			c0.4,1.8,2.9,4.6,5.1,12.3c2.5,8.8,6.2,17.7,8.3,20.6c0,0,1.9,2,5.2,5c2,1.9,1.7,3.8,2.8,5.6s1.9,3.5,0.4,3.5
      			c-1.6,0-2.6-1.6-4.1-2.5c0,0,0.3,1.4,0.3,3.5c0,2.8-0.2,4.6-0.3,6.1c0,1.1,0.1,3.9-2.4,3.9c0,0-0.4,1.1-1.6,1.1
      			c-1.3,0-1.5-0.8-1.5-0.8s-0.4,0.3-1.3,0c-1.3-0.5-1.6-2.4-1.6-2.4s-0.5,0.4-1.2,0.1c-1-0.4-1.2-2.4-1.3-3.1
      			c-0.4-2.1-0.7-2.7-1.4-4.9c-0.7-2.3-0.4-3.7-0.3-8.9c0-3.5-4.3-7.2-6-10.2c-2.3-4-6.1-10-7.1-11.5c-1.4-2.4-3.5-5.1-3.8-9.1
      			s-1.1-8.5-0.9-12c0,0-1.1,2.5-1.8,4.8c-1.2,4.5-2.1,8.8-1.6,12.4c0.4,2.8,1,3.7,1,6.5c0,1.6,1.5,3.7,1.9,5.1
      			c1.3,4.3,2.2,12.1,2.2,19.7c0,11.1-1.3,16.8-3.3,24.1c-1.8,6.7-2.9,9.9-3.4,11.8c-0.4,1.6-0.6,5.3,0.2,9.2
      			c0.7,3.1,1.2,10.1,0.2,16.3c-1,5.7-3.7,13.1-4.8,16.1c-1.8,5-1.2,6.7-1.2,8.6c0.1,2.2-0.3,2.6,3.4,5.4c2.7,2,3.1,2.5,3.1,3.6
      			c0,1-2.1,0.9-2.1,0.9s-4.6,0-5.8,0c-1,0-2.2-0.6-2.2-0.6s-0.5,0.7-2.2,0.7c-1.3,0-2.2,0-2.7-1.9c-0.4-1.4-0.2-3-0.2-4.8
      			c0-2,0.5-4.4,0.5-5.7c0.2-3.3,0.1-6-0.6-14.3c-0.3-3.3-1.2-8.8-0.1-13c1-4.1,0.5-4.4,0.3-6.9c-0.1-1.3-0.7-3.9-0.7-7.7
      			c0-1.1-0.5-9.3-0.4-19.1c0.1-4.9,0.4-10.2,0.3-14.4c-0.1-3.7-0.5-5.7-0.5-5.7s-0.2,8.9-0.1,18c0.1,9.8-0.1,19-0.1,20.1
      			c0.1,4-0.3,4.8-1,8.6c-0.6,3.2,1.4,5.1,1.4,10.1c0,10.5-0.4,10.7-0.6,14c-0.3,6.9-0.4,7.4-0.3,10.8c0.1,1.4,0.3,4.5,0.4,5.6
      			c0.1,1.8,0.4,3.2,0,4.6c-0.6,1.9-1.3,1.9-2.7,1.9c-1.8,0-2.2-0.7-2.2-0.7s-1.3,0.6-2.2,0.6c-1.2,0-5.8,0-5.8,0s-2.1,0.1-2.1-0.9
      			s0.4-1.5,3.1-3.6c3.7-2.8,1.8-3.3,3-5.2c0.9-1.3,1.4-3.6-0.4-8.7c-1.1-3.1-4.2-9.5-5.2-15.2c-1.1-6.3-0.4-13.8,0.3-16.9
      			c0.9-3.8,2.2-8,1.8-9.6c-0.5-1.9-2.1-5.3-4.5-11.8c-2.2-5.9-4-13.9-4-25c0-7.9,0.9-13.4,2.4-19.4c0.4-1.5,1.8-3.5,1.8-4.5
      			c-0.2-2.6,0-4.1,0.6-6.8c0.8-3.5-0.1-8.4-1.1-12.1c-0.8-2.8-0.8-2.8-0.8-2.8c0.1,2.6-2.3,5.4-2.6,9.5c-0.3,4-1,7.3-2.5,9.7
      			c-0.9,1.6-5.6,7.8-7.5,12c-1,2.1-3.1,4.7-3.1,9.8c0.1,5.2-0.3,6.6-1,8.9c-0.7,2.2-1,2.9-1.4,4.9c-0.1,0.7-0.4,2.7-1.3,3.1
      			c-0.7,0.4-1.2-0.1-1.2-0.1s-0.2,1.9-1.6,2.4c-0.9,0.3-1.3,0-1.3,0s-0.2,0.8-1.5,0.8c-1.2,0-1.6-1.1-1.6-1.1
      			c-2.5,0-2.4-2.7-2.4-3.8c-0.1-1.5-0.3-3.4-0.3-6.1c0-2.1,0.2-4.2,0.2-4.2c-1.4,0.4-2.5,3.2-4.1,3.2c-1.5,0-0.7-1.8,0.4-3.5
      			c1.1-1.8,0.8-3.7,2.8-5.6c3.2-3,4.8-5,4.8-5c1.5-4.7,4.2-10.9,6.5-19.7c1.7-6.8,5.2-11.5,5.6-13.3c0.4-1.7,1-9.8,1-12.1
      			c0-2.5-0.1-4.5-0.1-11c0-5.6,0.1-8.3,3.4-11.5c1.8-1.8,8.7-3.6,12.1-4.7c4.4-1.4,8.5-2.1,8.5-6.2c0-2.2,0-5.4,0-5.4"
          />
          <g>
            <g>
              <g>
                <path
                  fill={strokeColor}
                  d="M192.8,178.8c0,0-0.2,1.6-0.4,3.2c-0.1,0.8-0.2,1.6-0.3,2.2c-0.1,0.6-0.1,1-0.1,1h-0.1
      						c0,0,0-0.1-0.1-0.3s-0.1-0.4-0.2-0.8c0-0.2,0-0.3,0-0.5s0-0.3,0-0.5s0-0.4,0-0.6c0-0.2,0-0.4,0.1-0.6c0-0.2,0.1-0.4,0.1-0.6
      						c0-0.2,0.1-0.4,0.1-0.6c0-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.2-0.5C192.5,179,192.7,178.7,192.8,178.8L192.8,178.8z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M185.9,178.4c0,0,0.3,0,0.7,0c0.2,0,0.4,0.1,0.6,0.1c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0.1,0.5,0.1,0.7,0.2
      						c0.3,0,0.5,0.1,0.7,0.1s0.4,0,0.5,0.1c0.1,0,0.2,0,0.2,0v0.1c0,0-0.1,0-0.2,0s-0.3,0-0.5,0.1c-0.2,0-0.4,0-0.7,0s-0.5,0-0.8,0
      						s-0.5,0-0.8-0.1s-0.5-0.2-0.6-0.3c-0.2-0.1-0.3-0.2-0.4-0.3S185.9,178.4,185.9,178.4L185.9,178.4z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M185.3,179.3c0,0,0.1,0.1,0.3,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0.1,0.3,0.2,0.4,0.3
      						c0.1,0.1,0.3,0.3,0.4,0.4c0.3,0.3,0.5,0.5,0.8,0.8c0.1,0.1,0.1,0.1,0.2,0.2l0.2,0.2c0.2,0.1,0.4,0.3,0.5,0.5
      						c0.2,0.2,0.3,0.3,0.4,0.5c0.1,0.2,0.2,0.3,0.3,0.5s0.1,0.3,0.2,0.4c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.3,0,0.3h-0.1
      						c0,0,0-0.1-0.1-0.3s-0.2-0.4-0.4-0.6c-0.2-0.3-0.4-0.5-0.7-0.8c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.1-0.4-0.3-0.5-0.4
      						c-0.3-0.3-0.6-0.6-0.8-0.9c-0.3-0.3-0.4-0.6-0.6-0.9C185.3,179.8,185.2,179.4,185.3,179.3L185.3,179.3z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill={strokeColor}
                  d="M193.1,234.5c0,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0-0.1c0,0,0-0.1-0.1-0.1
      						c0-0.1-0.1-0.2-0.1-0.3s-0.1-0.2-0.1-0.3c-0.1-0.1-0.1-0.3-0.2-0.4c-0.3-0.5-0.6-1-0.8-1.4c-0.3-0.4-0.4-0.6-0.4-0.6l0.1-0.1
      						c0,0,0.2,0.2,0.6,0.5c0.3,0.3,0.8,0.7,1.1,1.2c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.2,0.1,0.3,0.1,0.5
      						s0,0.4-0.2,0.6c-0.1,0.2-0.3,0.2-0.4,0.2s-0.2,0-0.3-0.1C193.1,234.7,193.1,234.6,193.1,234.5L193.1,234.5z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M189.2,234.5c0,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.2
      						v-0.1v-0.1c0,0,0-0.1,0-0.2v-0.1v-0.1c0-0.1,0-0.3,0-0.4c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.1-0.3-0.1-0.4
      						c0-0.1-0.1-0.3-0.1-0.3c-0.2-0.4-0.3-0.7-0.3-0.7h0.1c0,0,0.2,0.2,0.5,0.6c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.2,0.2,0.3
      						c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.1,0.1,0.3,0.2,0.4s0.1,0.3,0.2,0.5v0.1v0.1c0,0.1,0,0.2,0,0.3s0,0.2-0.1,0.3
      						c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.3,0.3-0.4,0.3c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3-0.1
      						C189.3,234.6,189.2,234.6,189.2,234.5C189.2,234.5,189.1,234.5,189.2,234.5L189.2,234.5z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M185.1,234.9c0,0,0.1-0.1,0.2-0.2c0.1-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.2-0.5,0.2-0.8
      						c0-0.2,0-0.3-0.1-0.4c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.4,0-0.8,0.2-1.1
      						c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.2,0.2-0.2c0.1-0.1,0.2-0.2,0.2-0.2l0.1,0.1c0,0,0,0.1-0.1,0.3
      						c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2,0,0.3s0,0.3,0.1,0.4s0.1,0.3,0.1,0.5s0.1,0.3,0.1,0.5s0.1,0.3,0.2,0.6c0,0.2,0,0.4,0,0.6
      						c-0.1,0.4-0.3,0.7-0.4,0.9c-0.2,0.2-0.4,0.3-0.5,0.4C185.3,235,185.2,235,185.1,234.9L185.1,234.9z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill={strokeColor}
                  d="M182.7,232.8c0,0,0.3-0.2,0.9-0.3c0.3-0.1,0.6-0.1,1-0.1c0.2,0,0.4,0,0.6,0.3c0.1,0.1,0.1,0.1,0.2,0.2
      						l0.1,0.1c0,0.1,0.1,0.1,0.1,0.2v0.1c0,0.1-0.1,0.1-0.1,0.1s-0.1,0-0.1,0.1h-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0
      						c-0.2,0-0.4,0-0.5,0c-0.4,0-0.7-0.1-1-0.1c-0.5-0.1-0.9-0.1-0.9-0.1s0.4-0.1,0.9-0.1c0.3,0,0.6,0,1-0.1c0.2,0,0.4,0,0.5,0
      						s0.2,0,0.3,0h0.1l0,0l0,0c0,0,0,0,0,0.1v0.1c0,0.1,0,0,0,0l-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.2-0.1-0.4-0.1
      						c-0.3-0.1-0.6,0-0.9,0C183.1,232.8,182.7,232.8,182.7,232.8z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M189.5,233.3c0,0-0.1,0.1-0.3,0.2c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0
      						s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.2c0-0.1-0.1-0.1-0.1-0.1l0,0
      						c0,0,0.1,0,0.3,0.1c0.2,0,0.4,0.1,0.7,0.1s0.5,0.1,0.7,0.1C189.5,233.3,189.6,233.3,189.5,233.3L189.5,233.3z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M192.8,233.5c0,0-0.1,0.1-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1s-0.1,0-0.2,0s-0.1,0-0.2,0
      						c-0.1,0-0.2,0-0.4-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2-0.1-0.3-0.2-0.3-0.2l0,0c0,0,0.5,0,0.9,0
      						C192.3,233.5,192.8,233.5,192.8,233.5L192.8,233.5z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M195.3,233.4L195.3,233.4c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3,0-0.4,0
      						c-0.1,0-0.1-0.1-0.1-0.1h-0.1l0,0c0,0,0.3,0,0.5-0.1C195,233.4,195.3,233.4,195.3,233.4L195.3,233.4z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  fill={strokeColor}
                  d="M168.3,173.7c0,0,0.1,0.1,0.2,0.3c0.1,0.2,0.3,0.5,0.5,0.9c0.1,0.2,0.2,0.3,0.3,0.5s0.2,0.4,0.4,0.6
      						c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.3,0.4,0.4,0.5c0.2,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.4,0.3,0.5,0.3c0.1,0.1,0.2,0.1,0.3,0.1
      						s0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0s0.1,0,0.1,0v0.1
      						c0,0-0.1,0-0.4,0.1c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.1,0-0.2,0-0.3,0s-0.2,0-0.4,0c-0.1,0-0.2,0-0.4-0.1
      						c-0.1,0-0.3-0.1-0.3-0.1c-0.3-0.1-0.5-0.2-0.7-0.4s-0.4-0.3-0.6-0.5c-0.2-0.2-0.3-0.4-0.5-0.6c-0.2-0.2-0.3-0.4-0.4-0.7
      						c-0.2-0.4-0.4-0.9-0.5-1.3C168.3,174.3,168.2,173.7,168.3,173.7L168.3,173.7z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M169.2,178.1c0,0,0.2,0.3,0.4,0.6c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.1,0.2,0.2,0.2,0.3
      						c0.1,0.1,0.2,0.2,0.3,0.3s0.2,0.2,0.3,0.3s0.2,0.2,0.3,0.3s0.2,0.2,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.3,0.1
      						s0.2,0.1,0.2,0.1c0.1,0,0.2,0.1,0.2,0.1s-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.3,0s-0.2,0-0.3,0s-0.3,0-0.4-0.1
      						c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.2-0.3-0.3s-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.3-0.2-0.4
      						c-0.1-0.3-0.2-0.5-0.3-0.7C169.1,178.3,169.2,178.1,169.2,178.1z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M169.2,181c0,0,0,0.4,0.1,1.1c0.1,0.3,0.1,0.7,0.2,1.1c0.1,0.4,0.2,0.8,0.3,1.3
      						c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.2,0.3,0.2,0.5c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.1,0.2
      						c0.1,0.2,0.1,0.3,0.2,0.4c0,0.1,0.1,0.2,0.2,0.3c0.1,0.2,0.2,0.3,0.2,0.3l-0.1,0.1c0,0-0.1,0-0.3-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
      						c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.1-0.2-0.3-0.3-0.5s-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.4-0.2-0.7c0-0.2-0.1-0.4-0.1-0.7
      						c0-0.2-0.1-0.4-0.1-0.7c0-0.2,0-0.4-0.1-0.7c0-0.4,0-0.8,0-1.2c0-0.7,0.1-1.1,0.1-1.1L169.2,181z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill={strokeColor}
                  d="M168.5,234.5c0,0-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.3,0.1s-0.3,0-0.5-0.2s-0.2-0.4-0.2-0.5
      						c0-0.2,0-0.3,0.1-0.5s0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.5,0.7-1,1-1.3s0.5-0.5,0.5-0.5h0.1c0,0-0.1,0.3-0.4,0.7
      						c-0.2,0.4-0.5,0.9-0.7,1.5c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.3s0,0.1,0,0.1s0,0.1,0.1,0.1
      						s0.1,0.1,0.2,0.1C168.4,234.4,168.4,234.4,168.5,234.5L168.5,234.5z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M172.2,234.6L172.2,234.6c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1s-0.1,0-0.2,0
      						s-0.1,0-0.2-0.1c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.3s0-0.2,0-0.3v-0.1v-0.1
      						c0-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3
      						c0.3-0.4,0.5-0.6,0.5-0.6h0.1c0,0-0.1,0.3-0.3,0.7c-0.1,0.1-0.1,0.2-0.1,0.3s-0.1,0.3-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4
      						c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3,0,0.4v0.1v0.1c0,0.1,0,0.1,0,0.2v0.1v0.1c0,0.1,0.1,0.1,0.1,0.2
      						c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.1C172.1,234.5,172.2,234.6,172.2,234.6L172.2,234.6z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M175.6,234.8c0,0-0.1,0-0.2-0.1c-0.1-0.1-0.3-0.2-0.5-0.4s-0.4-0.5-0.4-0.9c0-0.2,0-0.4,0-0.6
      						c0-0.2,0.1-0.4,0.2-0.6c0-0.2,0.1-0.3,0.1-0.5s0.1-0.3,0.1-0.5c0-0.1,0-0.3,0.1-0.4c0-0.1,0-0.3,0-0.4c0-0.2-0.1-0.4-0.1-0.6
      						c0-0.2-0.1-0.3-0.1-0.3l0.1-0.1c0,0,0.1,0,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.1,0.2
      						c0.2,0.3,0.2,0.7,0.2,1.1c0,0.2-0.1,0.4-0.2,0.6s-0.2,0.3-0.3,0.5s-0.2,0.3-0.2,0.4c0,0.2-0.1,0.3-0.1,0.4
      						c0,0.3,0.1,0.6,0.2,0.8c0.1,0.2,0.3,0.4,0.4,0.5C175.6,234.7,175.7,234.8,175.6,234.8L175.6,234.8z"
                />
              </g>
            </g>
            <g>
              <g>
                <path
                  fill={strokeColor}
                  d="M177.9,232.6c0,0-0.4,0-1,0c-0.2,0-0.3,0-0.5,0s-0.3,0-0.5,0.1c-0.1,0-0.2,0-0.2,0.1l0,0l0,0l-0.1,0.1
      						c-0.1,0.1-0.1,0.2-0.2,0.2l0,0V233l0,0l0,0l0,0c0.1,0,0.4,0,0.6,0c0.2,0,0.4,0,0.6,0c0.4,0,0.7,0,1,0.1c0.6,0.1,1,0.1,1,0.1
      						s-0.4,0.1-1,0.1c-0.3,0-0.6,0.1-1,0.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0h-0.1h-0.1c0,0-0.1,0-0.1-0.1
      						c-0.1-0.1-0.1-0.2-0.1-0.3s0-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.3l0.1-0.1c0,0,0,0,0.1-0.1c0,0,0.1,0,0.1-0.1
      						c0.1-0.1,0.2-0.1,0.3-0.1c0.2,0,0.4,0,0.6,0c0.2,0,0.4,0,0.5,0.1C177.6,232.4,177.9,232.6,177.9,232.6z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M171.8,233.2c0,0,0.1-0.1,0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.2,0
      						c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1s0.1,0.1,0.1,0.1l0,0c0,0-0.1,0.1-0.3,0.1
      						s-0.4,0.1-0.7,0.1s-0.5,0-0.7,0C172,233.2,171.9,233.2,171.8,233.2L171.8,233.2z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M168.5,233.5c0,0,0.1-0.1,0.3-0.2c0.1,0,0.2-0.1,0.3-0.1s0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
      						c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0.1c0.2,0.1,0.3,0.1,0.3,0.1l0,0c0,0-0.5,0-0.9,0.1C169,233.5,168.5,233.5,168.5,233.5
      						L168.5,233.5z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M165.6,233.3L165.6,233.3c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.3,0,0.5,0s0.4,0.1,0.5,0.2
      						c0.1,0,0.1,0.1,0.2,0.1h0.1l0,0c0,0-0.3,0-0.7-0.1C165.9,233.4,165.6,233.3,165.6,233.3L165.6,233.3z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                fill={strokeColor}
                d="M200.5,95.8c0,0,0-0.1,0-0.2s-0.1-0.3-0.1-0.4s-0.1-0.4-0.1-0.7c0-0.3-0.1-0.6-0.1-0.9s-0.1-0.7-0.1-1.1
      					s-0.1-0.8-0.1-1.2s0-0.9,0-1.3s0.1-0.9,0.1-1.4c0-0.4,0.1-0.8,0.1-1.2c0-0.2,0.1-0.4,0.1-0.6s0.1-0.4,0.1-0.6
      					c0.1-0.2,0.1-0.4,0.1-0.6c0.1-0.2,0.1-0.4,0.2-0.6c0-0.1,0.1-0.2,0.1-0.3l0,0l0,0l0,0l0,0l0,0v-0.1c0-0.1,0-0.1,0-0.1
      					c0-0.1,0-0.3,0-0.5c-0.1-0.7-0.2-1.2-0.2-1.6c-0.1-0.4-0.1-0.6-0.1-0.6h0.1c0,0,0.1,0.2,0.2,0.6c0.1,0.4,0.3,0.9,0.4,1.6
      					c0,0.2,0.1,0.4,0.1,0.5c0,0.2,0,0.4,0,0.6c0,0.4-0.1,0.8-0.1,1.2c0,0.4,0,0.9-0.1,1.3c0,0.4,0,0.9-0.1,1.3
      					c-0.1,0.9-0.2,1.8-0.2,2.6c0,0.8-0.1,1.6-0.1,2.3c0,1.3-0.1,2.2-0.1,2.2L200.5,95.8z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M209.2,96.7c0,0-0.1,0.1-0.2,0.3c-0.1,0.2-0.3,0.3-0.5,0.6c-0.2,0.2-0.5,0.4-0.8,0.7
      					c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0.1-0.7,0.2-1,0.3
      					c-0.3,0.1-0.6,0.1-0.7,0.1c-0.2,0-0.3,0-0.3,0s0.1-0.1,0.3-0.2s0.4-0.2,0.7-0.4c0.1-0.1,0.3-0.2,0.4-0.2
      					c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.4-0.2,0.5-0.3
      					c0.4-0.2,0.6-0.3,0.9-0.5C208.9,96.9,209.2,96.7,209.2,96.7z"
              />
            </g>
            <g>
              <g>
                <path
                  fill={strokeColor}
                  d="M220,140.8c0,0,2.2-0.1,4.3-0.2c0.5,0,1.1,0,1.6-0.1c0.5,0,1-0.1,1.4-0.1s0.7-0.1,1-0.1
      						c0.2,0,0.4,0,0.4,0v0.1c0,0,0,0-0.1,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.1-0.3,0.1-0.5,0.2
      						c-0.2,0.1-0.4,0.1-0.6,0.2c-0.2,0.1-0.5,0.1-0.7,0.1c-0.3,0-0.5,0.1-0.8,0.1s-0.5,0-0.8,0s-0.5,0-0.8,0s-0.5,0-0.8,0
      						s-0.5,0-0.7-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.4-0.1-0.7-0.1-1-0.2C220.2,140.9,220,140.9,220,140.8L220,140.8z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M222.7,151.1c0,0,0,0,0-0.1s-0.1-0.2-0.1-0.3s-0.1-0.3-0.1-0.5s-0.1-0.4-0.1-0.6s-0.1-0.5-0.1-0.8
      						s-0.1-0.6-0.1-0.9c0-0.3-0.1-0.6-0.1-0.9c0-0.3,0-0.6,0-1c0-0.3,0-0.6,0-1c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9
      						c0-0.3,0-0.5,0.1-0.8c0-0.2,0-0.4,0.1-0.6c0-0.2,0-0.4,0.1-0.5c0-0.1,0-0.3,0.1-0.3c0-0.1,0-0.1,0-0.1h0.1c0,0,0.1,0.6,0.3,1.6
      						c0.1,0.5,0.1,1,0.1,1.6s0.1,1.2,0.1,1.9c0,2.5,0.1,5.1,0.1,5.1H222.7z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M225.5,153.1c0,0-0.1-0.2-0.2-0.5c-0.1-0.3-0.2-0.8-0.3-1.4s-0.2-1.2-0.3-1.9s-0.2-1.5-0.2-2.3
      						c0-0.4,0-0.8-0.1-1.1c0-0.4,0-0.8-0.1-1.1c0-0.7-0.1-1.4-0.1-2s-0.1-1-0.1-1.4s0-0.5,0-0.5h0.1c0,0,0,0.2,0.1,0.5
      						s0.2,0.8,0.3,1.4c0.1,0.6,0.2,1.2,0.3,2c0,0.4,0.1,0.7,0.1,1.1c0.1,0.4,0.1,0.8,0.2,1.1c0.1,0.8,0.2,1.5,0.3,2.3
      						c0.1,0.7,0.1,1.4,0.1,2s0,1.1,0,1.4S225.6,153.2,225.5,153.1L225.5,153.1z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M227.9,152.8c0,0,0-0.2-0.1-0.5c0-0.2-0.1-0.4-0.1-0.6c0-0.2-0.1-0.5-0.1-0.8s-0.1-0.6-0.1-0.9
      						c0-0.3-0.1-0.7-0.1-1s-0.1-0.7-0.1-1.1c0-0.4-0.1-0.7-0.1-1.1c0-0.4-0.1-0.7-0.1-1.1c-0.1-0.4-0.1-0.7-0.1-1.1
      						c0-0.4-0.1-0.7-0.1-1s0-0.6,0-0.9c0-0.6,0-1,0-1.4c0-0.2,0-0.3,0-0.4c0-0.1,0-0.2,0-0.2h0.1c0,0,0,0,0,0.1s0.1,0.2,0.1,0.4
      						c0.1,0.3,0.2,0.8,0.3,1.4c0.1,0.6,0.2,1.2,0.3,1.9c0.1,0.4,0.1,0.7,0.2,1.1c0,0.4,0.1,0.8,0.1,1.1
      						C228.2,149.7,228,152.8,227.9,152.8L227.9,152.8z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                fill={strokeColor}
                d="M159.3,81.1c0,0,0,0,0.1,0.1c0,0.1,0.1,0.2,0.2,0.4c0.1,0.2,0.2,0.4,0.3,0.6s0.2,0.5,0.3,0.8
      					s0.2,0.6,0.3,1s0.2,0.7,0.2,1.1c0,0.2,0.1,0.4,0.1,0.6c0,0.1,0,0.2,0,0.3l0,0l0,0l0,0l0,0c0-0.1,0,0,0,0l0,0v0.2
      					c0,0.2,0.1,0.3,0.1,0.5s0.1,0.4,0.1,0.6c0.1,0.4,0.2,0.8,0.2,1.2c0.1,0.4,0.1,0.8,0.2,1.1c0.1,0.4,0.1,0.7,0.2,1.1
      					c0.1,0.3,0.1,0.7,0.1,1s0,0.6,0.1,0.8s0,0.5,0,0.6c0,0.2,0,0.3,0,0.4s0,0.2,0,0.2h-0.1c0,0-0.2-0.8-0.5-1.9
      					c-0.3-1.2-0.7-2.7-1.1-4.3c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.4,0-0.8-0.1-1.2c-0.1-0.8-0.2-1.5-0.3-2.1c-0.2-1.2-0.3-2-0.3-2
      					L159.3,81.1z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M151.2,95.5c0,0,0.4,0.2,1.1,0.5c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.2,0.5,0.3
      					c0.2,0.1,0.4,0.3,0.5,0.4c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.3,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.3c0.4,0.2,0.7,0.3,1.1,0.5
      					c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0.1,0.3,0.1,0.3,0.1l0,0l0,0l0,0c0,0-0.1,0-0.3,0c-0.1,0-0.2,0-0.4-0.1
      					s-0.3-0.1-0.5-0.1c-0.2-0.1-0.3-0.1-0.5-0.2s-0.4-0.2-0.6-0.2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.2-0.6-0.3
      					c-0.4-0.2-0.8-0.4-1.2-0.7c-0.4-0.3-0.7-0.5-0.9-0.8C151.5,96,151.2,95.6,151.2,95.5C151.2,95.6,151.2,95.6,151.2,95.5
      					L151.2,95.5z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M131.2,138.8c0,0,0.1-0.3,0.3-0.6c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.2,0.4-0.3,0.7-0.4
      					c0.2-0.2,0.5-0.3,0.7-0.4c0.2-0.1,0.4-0.3,0.6-0.3c0.2-0.1,0.4-0.2,0.4-0.2c0.1,0,0.2-0.1,0.2-0.1v0.1c0,0-0.1,0-0.2,0.1
      					c-0.1,0.1-0.2,0.2-0.4,0.3c-0.3,0.3-0.7,0.6-1.1,1c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.4,0.3-0.6,0.4c-0.4,0.2-0.6,0.3-0.6,0.3
      					L131.2,138.8z"
              />
            </g>
            <g>
              <g>
                <path
                  fill={strokeColor}
                  d="M134.1,142.4c0,0,2.2,0.1,4.3,0.2c0.5,0,1.1,0,1.6,0.1c0.5,0,1,0,1.4,0s0.7,0,1,0c0.2,0,0.4,0,0.4,0
      						v0.1c0,0,0,0-0.1,0s-0.2,0.1-0.3,0.1s-0.3,0.1-0.4,0.2s-0.4,0.1-0.5,0.2c-0.2,0.1-0.4,0.1-0.7,0.1c-0.2,0-0.5,0.1-0.7,0.1
      						s-0.5,0-0.8,0s-0.5,0-0.8-0.1c-0.3,0-0.5,0-0.8-0.1c-0.3,0-0.5-0.1-0.8-0.1s-0.5-0.1-0.7-0.1c-0.2-0.1-0.4-0.1-0.6-0.1
      						c-0.4-0.1-0.7-0.2-1-0.3C134.3,142.5,134.2,142.5,134.1,142.4L134.1,142.4z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M135.1,152.8c0,0,0,0,0-0.1s-0.1-0.2-0.1-0.3s-0.1-0.3-0.1-0.5s-0.1-0.4-0.1-0.6c0-0.2-0.1-0.5-0.1-0.8
      						s-0.1-0.6-0.1-0.9s0-0.6-0.1-0.9c0-0.3,0-0.6,0-1s0-0.6,0-1s0-0.6,0.1-0.9c0-0.3,0-0.6,0.1-0.9c0-0.3,0.1-0.5,0.1-0.8
      						c0.1-0.3,0.1-0.4,0.2-0.7c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.1-0.3,0.1-0.3c0-0.1,0.1-0.1,0.1-0.1h0.1c0,0-0.1,0.6-0.1,1.6
      						c0,0.5,0,1,0,1.6s0,1.2,0,1.9C135.2,150.2,135.1,152.8,135.1,152.8L135.1,152.8z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M137.7,153c0,0,0-0.2-0.1-0.4c-0.1-0.3-0.1-0.7-0.2-1.1c0-0.5-0.1-1-0.1-1.6s0.1-1.2,0.1-1.8
      						c0-0.3,0-0.6,0-1c0-0.3,0-0.6,0-0.9c0-0.6,0.1-1.1,0.1-1.6c0.1-1,0.2-1.6,0.2-1.6h0.1c0,0,0,0.2,0,0.4c0,0.3,0,0.7,0,1.1
      						c0,0.5,0,1,0.1,1.6c0,0.3,0,0.6,0.1,0.9c0,0.3,0,0.6,0.1,1c0,0.6,0.1,1.3,0.1,1.9s-0.1,1.1-0.1,1.6c-0.1,0.5-0.1,0.9-0.2,1.1
      						C137.8,152.9,137.8,153.1,137.7,153L137.7,153z"
                />
              </g>
              <g>
                <path
                  fill={strokeColor}
                  d="M140.6,151.3c0,0,0-0.1-0.1-0.4c-0.1-0.2-0.1-0.6-0.2-1c0-0.2-0.1-0.4-0.1-0.6c0-0.2,0-0.5-0.1-0.7
      						s0-0.5,0-0.8s0-0.5,0-0.8v-0.4c0-0.2,0-0.3,0-0.4c0-0.3,0.1-0.5,0.1-0.8c0.1-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.2-0.4,0.3-0.6
      						c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.1,0.2-0.2l0.1-0.1h0.1c0,0,0,0,0,0.1s-0.1,0.2-0.1,0.3
      						c-0.1,0.2-0.2,0.5-0.3,0.9s-0.2,0.8-0.2,1.3c0,0.3-0.1,0.5-0.1,0.8s0,0.5-0.1,0.8C140.8,149.1,140.7,151.3,140.6,151.3
      						L140.6,151.3z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <path
                fill={strokeColor}
                d="M161.8,82.1c0,0,0,0,0.1,0.1s0.2,0.1,0.4,0.2s0.3,0.2,0.6,0.4s0.5,0.3,0.8,0.4s0.6,0.3,0.9,0.4
      					c0.3,0.1,0.7,0.3,1,0.4c0.4,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.7,0.1,1.1,0.1c0.4,0,0.8,0,1.2,0s0.8,0,1.2,0c0.2,0,0.4,0,0.6,0
      					c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0c0.4,0,0.7-0.1,1.1-0.1c0.3,0,0.6,0,0.9,0s0.5,0,0.7,0s0.4,0,0.4,0c0.1,0,0.2,0,0.2,0v0.1
      					c0,0-0.2,0.1-0.5,0.2c-0.4,0.1-0.9,0.3-1.5,0.4c-0.3,0.1-0.7,0.1-1,0.2c-0.4,0.1-0.8,0.2-1.1,0.2c-0.8,0.1-1.7,0.2-2.5,0.1
      					c-0.4,0-0.9-0.1-1.3-0.2c-0.4-0.1-0.8-0.2-1.2-0.4c-0.8-0.3-1.4-0.7-1.9-1.1s-0.9-0.8-1.2-1s-0.4-0.4-0.4-0.4L161.8,82.1z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M198.8,81.6c0,0,0,0-0.1,0.1s-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.3-0.5,0.4c-0.2,0.2-0.4,0.3-0.7,0.5
      					s-0.5,0.4-0.8,0.6s-0.6,0.4-1,0.5c-0.1,0-0.2,0.1-0.3,0.1s-0.2,0.1-0.3,0.1c-0.2,0.1-0.4,0.2-0.6,0.2c-0.4,0.1-0.9,0.2-1.3,0.3
      					c-0.4,0.1-0.9,0.1-1.3,0.1s-0.8,0-1.2,0s-0.7-0.1-1.1-0.1c-0.4,0-0.7-0.1-1-0.1s-0.6-0.1-0.8-0.2c-0.3,0-0.4-0.1-0.6-0.2
      					c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.2,0-0.2,0V84c0,0,0.8,0,2,0c0.6,0,1.3,0,2.1,0s1.6,0,2.4-0.2c0.4-0.1,0.8-0.1,1.1-0.3
      					c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.4-0.2,0.5-0.2c0.7-0.3,1.3-0.6,1.8-0.9s1-0.5,1.3-0.7s0.5-0.3,0.5-0.3L198.8,81.6z"
              />
            </g>
            <ellipse
              fill={strokeColor}
              cx="167.7"
              cy="80.3"
              rx="0.9"
              ry="0.8"
            />
            <g>
              <path
                fill={strokeColor}
                d="M199.8,119.1c0,0,0,0-0.1-0.2c-0.1-0.1-0.2-0.3-0.3-0.5c-0.3-0.5-0.6-1.1-1-2c-0.2-0.4-0.4-0.9-0.6-1.3
      					c-0.1-0.3-0.2-0.5-0.3-0.8c0-0.1,0-0.1-0.1-0.2l0,0l0,0l0,0c0,0,0,0,0,0.1l0.1,0.1l0,0c0,0-0.1,0-0.1,0.1s-0.1,0.1-0.1,0.1
      					c-0.3,0.4-0.6,0.9-1,1.4c-0.3,0.5-0.6,1-0.9,1.5c-0.3,0.5-0.6,1-0.9,1.6c-0.2,0.3-0.3,0.5-0.4,0.8c-0.2,0.3-0.3,0.5-0.5,0.7
      					c-0.6,1-1.4,1.8-2.1,2.4c-0.7,0.6-1.5,1-2,1.1c-0.3,0.1-0.5,0.1-0.6,0.2c-0.1,0-0.2,0-0.2,0v-0.1c0,0,0.1,0,0.2-0.1
      					c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.1,0.5-0.3,0.7-0.5c0.3-0.2,0.6-0.5,0.9-0.8s0.6-0.7,0.9-1.1c0.3-0.4,0.6-0.8,0.9-1.3
      					c0.2-0.2,0.3-0.4,0.5-0.7c0.2-0.3,0.3-0.5,0.5-0.7c0.3-0.5,0.7-1,1-1.5s0.7-1,1-1.5s0.7-1,1.1-1.4c0.1-0.1,0.1-0.1,0.2-0.2
      					c0.1-0.1,0.1-0.1,0.3-0.2c0,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.1,0.1,0.2l0,0l0,0c0,0.1,0,0.1,0,0.2
      					c0.1,0.3,0.1,0.5,0.2,0.7c0.2,0.5,0.3,1,0.5,1.4c0.3,0.8,0.6,1.5,0.8,2c0.1,0.3,0.2,0.4,0.2,0.6
      					C199.7,119,199.7,119,199.8,119.1L199.8,119.1z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M160.8,119.1c0,0,0-0.1,0.1-0.3c0-0.1,0.1-0.3,0.2-0.6c0.2-0.5,0.4-1.2,0.7-2.1c0.2-0.4,0.3-0.9,0.4-1.4
      					c0.1-0.3,0.2-0.5,0.2-0.7c0-0.1,0-0.1,0.1-0.3c0-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2,0,0.3,0s0.2,0.1,0.3,0.2s0.1,0.1,0.2,0.2
      					c0.4,0.4,0.7,1,1.1,1.5c0.3,0.5,0.6,1,1,1.5c0.3,0.5,0.6,1,1,1.5c0.2,0.3,0.3,0.5,0.5,0.7s0.3,0.5,0.5,0.7
      					c0.3,0.4,0.6,0.9,0.9,1.3c0.3,0.4,0.6,0.8,0.9,1.1c0.3,0.3,0.6,0.6,0.9,0.8s0.5,0.4,0.8,0.5c0.2,0.1,0.4,0.3,0.5,0.3
      					c0.1,0.1,0.2,0.1,0.2,0.1v0.1c0,0-0.1,0-0.2,0c-0.1,0-0.4-0.1-0.6-0.2c-0.3-0.1-0.6-0.2-0.9-0.4c-0.4-0.2-0.7-0.4-1.1-0.7
      					c-0.4-0.3-0.7-0.7-1.1-1c-0.3-0.4-0.7-0.9-1-1.3c-0.2-0.3-0.3-0.5-0.5-0.8s-0.3-0.5-0.4-0.8c-0.3-0.5-0.6-1.1-0.9-1.6
      					c-0.3-0.5-0.6-1-0.9-1.6c-0.3-0.5-0.6-1-0.9-1.4c-0.1-0.1-0.2-0.2-0.2-0.2s0,0,0.1,0h0.1c0,0,0,0,0,0.1
      					c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2,0.5-0.4,1-0.6,1.4c-0.4,0.8-0.7,1.5-0.9,2c-0.1,0.3-0.2,0.4-0.3,0.6S160.8,119.2,160.8,119.1
      					L160.8,119.1z"
              />
            </g>
            <ellipse fill={strokeColor} cx="180" cy="108.9" rx="0.6" ry="0.9" />
            <ellipse
              fill={strokeColor}
              cx="193.1"
              cy="79.6"
              rx="0.9"
              ry="0.8"
            />
          </g>
          <g>
            <path
              fill="none"
              stroke={strokeColor}
              strokeWidth="1.5"
              strokeMiterlimit="10"
              d="M172.3,42.4c-2.7-2.7-2.6-8.4-2.6-8.4
      				c-1.9,0-2.4-2-2.4-3.8S169,29,169,29s-0.2-2.5-0.2-3.2c0-7.1,5.7-10.3,11.1-10.3c6,0,11.2,4.2,11.1,11c0,0.9-0.2,2.5-0.2,2.5
      				s1.8-0.8,1.8,1.6c0,1.8-0.4,3.6-2.2,3.6c0,0,0.2,5.3-2.9,8.4c-3.7,3.7-5.3,4.5-7.5,4.5C177.6,47,176,46.1,172.3,42.4z"
            />
            <g>
              <path
                fill={strokeColor}
                d="M188.4,27.1c0,0-0.4-0.1-1-0.2c-0.3,0-0.7,0-1.1,0c-0.2,0-0.4,0-0.6,0c-0.1,0-0.2,0-0.3,0h-0.1h-0.2
      					c-0.2,0-0.4,0.1-0.6,0.1c-0.2,0-0.4,0.1-0.6,0.1c-0.4,0-0.7,0.1-1,0.1c-0.6,0.1-1,0.1-1,0.1l0,0h-0.1l0,0c0,0,0.1-0.1,0.2-0.2
      					s0.4-0.3,0.7-0.5c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.6-0.2s0.4-0.1,0.6-0.1s0.4,0,0.6,0h0.2c0.1,0,0.1,0,0.2,0
      					c0.1,0,0.2,0,0.3,0c0.2,0,0.4,0.1,0.6,0.1c0.4,0.1,0.8,0.2,1.1,0.3s0.5,0.2,0.7,0.3C188.3,27.1,188.4,27.1,188.4,27.1
      					C188.5,27.1,188.5,27.1,188.4,27.1C188.5,27.1,188.5,27.1,188.4,27.1z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M171.7,26.9c0,0,0.1-0.1,0.3-0.2s0.4-0.2,0.7-0.3c0.3-0.1,0.7-0.2,1-0.3c0.2,0,0.4-0.1,0.6-0.1
      					c0.2,0,0.4,0,0.6-0.1c0.4,0,0.9,0,1.2,0.1c0.4,0.1,0.7,0.2,1,0.4c0.3,0.1,0.5,0.3,0.7,0.4S178,27,178,27v0.1l0,0
      					c0,0-0.4,0-1-0.1c-0.3,0-0.6,0-1-0.1c-0.2,0-0.4,0-0.6-0.1s-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.6,0
      					c-0.2,0-0.4,0-0.5,0c-0.2,0-0.3,0-0.5,0c-0.3,0-0.5,0.1-0.7,0.1C171.9,26.9,171.8,27,171.7,26.9C171.7,27,171.7,27,171.7,26.9
      					L171.7,26.9z"
              />
            </g>
            <g>
              <ellipse
                transform="matrix(-0.9952 -9.785527e-02 9.785527e-02 -0.9952 348.2743 74.4009)"
                fill={strokeColor}
                cx="176"
                cy="28.7"
                rx="1"
                ry="0.9"
              />
              <g>
                <path
                  fill={strokeColor}
                  d="M173.1,28.7c0,0,0.1-0.1,0.1-0.2c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.2,0.4-0.3,0.7-0.4
      						c0.1,0,0.3-0.1,0.4-0.1c0.2,0,0.3-0.1,0.5-0.1s0.3,0,0.5,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0,0.2,0.1c0.2,0.1,0.3,0.1,0.4,0.2
      						c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.1,0.2,0.1,0.2v0.1c0,0-0.1,0-0.2-0.1c-0.1-0.1-0.3-0.1-0.5-0.2
      						s-0.4-0.2-0.7-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0.1-0.8,0.1c-0.3,0.1-0.5,0.1-0.7,0.2
      						s-0.4,0.1-0.5,0.2C173.2,28.7,173.1,28.8,173.1,28.7L173.1,28.7z"
                />
              </g>
            </g>
            <g>
              <ellipse
                transform="matrix(0.9952 -9.785537e-02 9.785537e-02 0.9952 -1.9255 18.1608)"
                fill={strokeColor}
                cx="184.2"
                cy="28.7"
                rx="1"
                ry="0.9"
              />
              <g>
                <path
                  fill={strokeColor}
                  d="M187,28.8c0,0-0.1,0-0.2-0.1c-0.1-0.1-0.3-0.1-0.5-0.2s-0.4-0.1-0.7-0.2s-0.5-0.1-0.8-0.1
      						c-0.1,0-0.3,0-0.4,0s-0.3,0-0.4,0.1c-0.3,0.1-0.5,0.1-0.7,0.2s-0.4,0.2-0.5,0.2c-0.1,0.1-0.2,0.1-0.2,0.1v-0.1
      						c0,0,0-0.1,0.1-0.2s0.2-0.3,0.4-0.4c0.1-0.1,0.2-0.2,0.3-0.2s0.3-0.1,0.4-0.2c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2,0,0.3-0.1
      						c0.2,0,0.3,0,0.5,0s0.3,0,0.5,0.1c0.2,0,0.3,0.1,0.4,0.1c0.3,0.1,0.5,0.3,0.7,0.4s0.4,0.3,0.4,0.4C187,28.6,187,28.7,187,28.8
      						L187,28.8z"
                />
              </g>
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M177.1,40.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.1,0.3-0.2s0.2-0.1,0.4-0.2
      					c0.1-0.1,0.2-0.1,0.4-0.2c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0s0.2,0,0.2,0h0.2c0.1,0,0.1,0,0.2,0.1h-0.4
      					c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.1,0,0.3,0c0,0,0.2,0,0.3,0.1c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.4,0.2
      					c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.1,0.3,0.2v0.1c-0.3,0-0.5-0.1-0.7-0.1l-0.7-0.2
      					c-0.1,0-0.2-0.1-0.3-0.1s-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.5,0.1-0.7,0.1l0,0l0,0c-0.2-0.1-0.5-0.1-0.7-0.2
      					c-0.1,0-0.2,0-0.4,0s-0.3,0.1-0.4,0.1l-0.7,0.2C177.6,40.1,177.4,40.2,177.1,40.2L177.1,40.2z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M177.9,40.7c0,0,0.4,0,0.8,0.1c0.1,0,0.2,0,0.3,0s0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0
      					c0.2,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0c0.4,0,0.8,0,0.8,0v0.1c0,0-0.3,0.1-0.7,0.3
      					c-0.2,0.1-0.5,0.2-0.8,0.2s-0.6,0.1-0.9,0.1c-0.6,0-1.2-0.2-1.6-0.4C178.1,40.9,177.8,40.7,177.9,40.7L177.9,40.7z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M180.5,32c0,0,0,0.3,0.1,0.9c0.1,0.5,0.1,1.2,0.3,1.9c0.1,0.2,0.1,0.3,0.2,0.4c0.1,0.2,0.1,0.2,0.3,0.3
      					c0.1,0.1,0.2,0.2,0.3,0.3s0.2,0.2,0.3,0.4c0.2,0.3,0.2,0.5,0.1,0.7c0,0.2,0,0.3,0,0.3l0,0c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.2
      					c0-0.1-0.1-0.2-0.1-0.3s-0.1-0.2-0.2-0.3c-0.2-0.2-0.4-0.3-0.7-0.6c-0.1-0.1-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.3-0.1-0.5
      					c-0.1-0.7-0.1-1.4-0.1-1.9C180.4,32.3,180.4,32,180.5,32L180.5,32z"
              />
            </g>
            <g>
              <path
                fill={strokeColor}
                d="M178.3,36.5c0,0,0.1,0.2,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.2c0.1,0,0.1,0,0.1,0l0,0l0,0h-0.1
      					c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1-0.1c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.3-0.1-0.3C178.2,36.6,178.2,36.6,178.3,36.5
      					C178.2,36.5,178.2,36.5,178.3,36.5L178.3,36.5z"
              />
            </g>
          </g>
        </g>
      </SVG>
    );
  }
}

export default BodyFront;
