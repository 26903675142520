import React from 'react';
import Button from 'antd/lib/button';
import { Link } from 'react-router-dom';
import savePreviousPath from 'APP_ROOT/utils/savePreviousPath';

// Components
import PageHeader from 'APP_COMPONENTS/PageHeader';

// Actions

// Utils
import getRoute from 'APP_ROOT/utils/get-route';
import { hasPermissions, PERMISSIONS } from '../../../../utils/admin';
import { UnwrappedPeople } from '../../../people';
import { UnwrappedPeopleOu } from '../../../people-ou';
import { CREATE_DEFAULT } from '../AgencyUsers.constants';

export class ListOfUsers extends React.PureComponent {
  render() {
    const {
      agencyId: currentAgencyId = '',
      session: {
        currentUser,
        currentUser: { permissions = [], agencyId: userAgencyId },
      } = {},
      route,
      history,
      hasOwnership,
    } = this.props;

    const isSuperAdmin = hasPermissions(
      permissions,
      PERMISSIONS.manageAgencies
    );
    const agencyId = isSuperAdmin ? currentAgencyId : userAgencyId;

    const canCreateUsers = hasPermissions(permissions, [
      PERMISSIONS.createAnyUser,
      PERMISSIONS.createAgencyUser,
    ]);

    const actions = [
      <Link
        key="1"
        to={savePreviousPath(
          getRoute('administratorAgencyUser', {
            agencyId,
            userId: CREATE_DEFAULT,
          })
        )}
        disabled={!canCreateUsers}
      >
        <Button
          type="primary"
          size="default"
          icon="plus"
          disabled={!canCreateUsers}
        >
          Add User
        </Button>
      </Link>,
    ];

    return (
      <div>
        <PageHeader
          title="Users"
          goBackTo={getRoute('administratorAgency', { agencyId })}
          actions={actions}
        />

        <div className="administrator-content">
          {hasPermissions(permissions, [PERMISSIONS.listUsersByHK]) ? (
            <UnwrappedPeopleOu
              currentUser={currentUser}
              agencyId={agencyId}
              route={route}
              history={history}
              hasOwnership={hasOwnership}
              shouldRenderHeader={false}
            />
          ) : (
            hasPermissions(permissions, [PERMISSIONS.viewAllUserProfiles]) && (
              <UnwrappedPeople
                currentUser={currentUser}
                agencyId={agencyId}
                route={route}
                shouldRenderHeader={false}
              />
            )
          )}
        </div>
      </div>
    );
  }
}

export default ListOfUsers;
