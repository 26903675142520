import styled from 'styled-components';
import { SIDEBAR_BG } from '../../../config/theme';

const WrapperContextSwitch = styled.div`
  background: ${SIDEBAR_BG};

  & .ant-menu {
    font-size: 14px;
    background-color: ${SIDEBAR_BG} !important;
    color: #fff;
  }

  & .ant-menu-submenu-title {
    font-size: 14px;
    text-align: left;
    height: auto !important;
    line-height: normal !important;
    text-wrap: wrap;
  }

  && .ant-menu .ant-menu-submenu-title .ant-menu-submenu-arrow {
    color: #fff;
  }

  && .ant-menu .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  && .ant-menu .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    background-image: none !important;
  }
`;

export default WrapperContextSwitch;
