import each from 'lodash/each';

import getSectionPresentation from './get-section-presentation';

export default ({
  schema = { properties: [] },
  data = {},
  overrides,
  enums,
  withProfileData = false,
}) => {
  let fields = {
    hasTabs: false,
    fields: [{}],
    data: {},
  };
  // let result = {};

  const getPresentation = getSectionPresentation(
    fields,
    data,
    overrides,
    enums
  );
  each(
    schema.properties,
    getPresentation({
      parent: fields.fields[0],
      parentKey: null,
      getPresentation,
      withProfileData,
    })
  );
  return fields; //fields.hasTabs ? fields : { ...fields, fields: [result] };
};
