import styled from 'styled-components';

import {
  WHEEL_ACTIVE_COLOR,
  WHEEL_INACTIVE_COLOR,
} from '../../../../config/theme';

const WheelNav = styled.div`
  margin-top: -44px;
  height: 115px;
  margin-bottom: 20px;

  .wheel-container {
    position: relative;
    max-width: 430px;
    margin: 0 auto;
  }

  .wheel-pie-wrapper {
    max-width: 350px;
    margin: 0 auto;
  }

  .wheel-nav-action {
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 10;

    &.arrow-right {
      left: auto;
      right: 0;
    }
  }

  svg {
    display: block;
    transition: top 0.5s;
    transform-origin: center center;
    position: relative;
    top: -235px;
    margin: 0 auto;
    max-width: 350px;
    width: 100%;
    z-index: 5;

    &:hover {
      top: -175px;
    }

    g.group-text {
      transform-origin: center center;
      cursor: pointer;

      image {
        opacity: 0;
        transition: opacity 0.5s;
      }

      text {
        fill: ${WHEEL_INACTIVE_COLOR};
        font-size: 12px;
        font-family: 'Lato', Arial, sans-serif;
        cursor: pointer;
        transition: font-size 0.5s, fill 0.5s;
      }

      &:hover,
      &.active {
        text {
          fill: ${WHEEL_ACTIVE_COLOR};
        }
      }

      &.active {
        text {
          font-size: 14px;
        }

        image {
          opacity: 1;
        }
      }
    }

    @media (max-width: 414px) {
      top: -180px;
    }

    @media (max-width: 361px) {
      top: -140px;
    }
  }
`;

export default WheelNav;
