import React, { Component, Fragment } from 'react';
import get from 'lodash/get';
import { notification } from 'antd';

import withoutClutter from 'APP_COMPONENTS/without-clutter';
import DashboardPage from 'APP_COMPONENTS/dashboard';
import withOwnershipGuard from 'APP_ROOT/containers/administrator/components/agency-ownership-guard';
import RolesListHeader from '../RolesListHeader/RolesListHeader';
import { sidebarIsCollapsed } from 'APP_ROOT/selectors/application';
import securityEndpoints from 'APP_ROOT/api/security/securityEndpoints';
import getAgency from '../../../../containers/administrator/agency-profile/actions/get-agency-info';
import RolesTable from '../RolesTable/RolesTable';
import SecurityBasicContent from '../SecurityBasicContent/SecurityBasicContent';
import { getAgencyTZ } from 'APP_ROOT/selectors/session';
import { getRoles } from '../../services/api';

export class RolesList extends Component {
  state = {
    roles: [],
    isCopyButtonDisable: false,
  };

  componentDidMount() {
    const { dispatch, agencyId } = this.props;
    dispatch(getAgency(agencyId));
    this.getRoles(agencyId);
  }

  getRoles = async agencyId => {
    const roles = await getRoles(agencyId);
    this.setState({ roles });
  };

  getJsonRole = (rolesPermissions, name, rankNumber) => {
    const permissions = rolesPermissions.map(permission => permission.name);
    const roleObject = {
      agencyId: 0,
      isInternal: false,
      name,
      permissions,
      rankNumber,
      tenantId: '',
    };
    return JSON.stringify(roleObject, undefined, 2);
  };

  copyRoleToClipboard = async (roleId, roleName, rankNumber) => {
    this.setState({ isCopyButtonDisable: true });
    try {
      const rolesPermissions = await securityEndpoints.getRolePermissions(
        roleId
      );
      navigator.clipboard.writeText(
        this.getJsonRole(rolesPermissions, roleName, rankNumber)
      );
      notification.success({
        message: 'Role successfully copied!',
        description: `Json for ${roleName} role  was copied to the clipboard successfully!`,
      });
    } catch (error) {
      notification.error({
        message: 'Sorry try again later!',
        description: 'Something bad occurred when copying to the clipboard',
      });
    }
    this.setState({ isCopyButtonDisable: false });
  };

  render() {
    const {
      isSidebarIsCollapsed,
      agencyProfile,
      agencyId,
      timezone,
    } = this.props;
    const agencyName = get(agencyProfile, 'name', '');
    const { roles, isCopyButtonDisable } = this.state;

    return (
      <Fragment>
        <RolesListHeader
          isSidebarIsCollapsed={isSidebarIsCollapsed}
          agencyName={agencyName}
          agencyId={agencyId}
          agencyProfile={agencyProfile}
          getRoles={this.getRoles}
        />
        <SecurityBasicContent>
          <h1>{agencyName} Roles</h1>
          <RolesTable
            agencyId={agencyId}
            agencyName={agencyName}
            roles={roles}
            timezone={timezone}
            copyRoleToClipboard={this.copyRoleToClipboard}
            isCopyButtonDisable={isCopyButtonDisable}
          />
        </SecurityBasicContent>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const agencyId = get(props, 'match.params.agencyId');
  return {
    agencyId,
    isSidebarIsCollapsed: sidebarIsCollapsed(state),
    agencyProfile: get(state, `agencyProfile.${agencyId}.data`, null),
    timezone: getAgencyTZ(state),
  };
};

export default withoutClutter(
  DashboardPage(mapStateToProps)(
    withOwnershipGuard(RolesList, 'administratorAgency')
  )
);
