import React from 'react';

const Label = ({ x, y, children, dy, transform }) => {
  return (
    <text textAnchor="middle" x={x} y={y} dy={dy} transform={transform}>
      {children}
    </text>
  );
};

export default Label;
