import { isPlainObject } from 'lodash';

// helper function to compare enum options
// used as a parameter for sort function
export default (a, b) => {
  if (isPlainObject(a)) {
    return a.order - b.order;
  }
  const textA = a.toUpperCase();
  const textB = b.toUpperCase();
  if (textA < textB) {
    return -1;
  }
  if (textA > textB) {
    return 1;
  }
  return 0;
};
