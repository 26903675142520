import styled from 'styled-components';

import { GRAY_2, FONT_FAMILY } from 'APP_ROOT/config/theme';
import VerticalInput from '../object-types/VerticalInput';

const StyledVerticalInput = styled(VerticalInput)`
  text-align: center;
  padding-right: 10px;
  padding-top: 15px;

  .value {
    height: 25px;
    line-height: 25px;
    font-size: 18px;
    margin: 0 0 8px 0;
    color: ${GRAY_2};
    font-family: ${FONT_FAMILY};
  }

  .label {
    margin: 0 0 8px 0;
    font-size: 14px;
    line-height: 19px;
    color: ${GRAY_2};
    font-family: ${FONT_FAMILY};
  }
`;

export default StyledVerticalInput;
