import React from 'react';
import { startCase } from 'lodash';

import parsedDate from '../../utils/parse-date';
import { getOperation } from '../utils/trainingTaskTimelineHelpers';

const getTaskValue = item => {
  switch (String(item.taskValue).toLowerCase()) {
    case 'completed':
    case 'uncompleted':
      return item.taskName;
    default:
      return startCase(item.taskValue);
  }
};

const getTaskActivity = item => {
  switch (String(item.taskValue).toLowerCase()) {
    case 'completed':
      return 'changed to complete';
    case 'uncompleted':
      return 'changed to incomplete';
    default:
      return `${getOperation(item.increaseValue)} to ${item.currentValue}`;
  }
};

const ActivityItem = props => {
  const { item, index, timezone } = props;
  return (
    <div className={`timeline-item-date-${index}`}>
      <p>
        {parsedDate(item.timestamp, timezone)} - {item.user}
      </p>
      <p>
        <strong>{getTaskValue(item)}:</strong> {getTaskActivity(item)}
      </p>
    </div>
  );
};

export default ActivityItem;
