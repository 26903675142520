import pageViewEndpoints from '../../../../api/organization/pageViewEndpoints';

export const userProfileFailureLog = async (tenantId, userId) => {
  try {
    return await pageViewEndpoints.createUserProfileLog(tenantId, userId);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return error;
  }
};

const userProfileSuccessLog = async (tenantId, auditId) => {
  try {
    return await pageViewEndpoints.updateUserProfileLog(tenantId, auditId);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return error;
  }
};

export const callUserProfileSuccessLog = async ({
  tenantId,
  auditId,
  contextOfThis,
  action,
  VIEW_USER,
}) => {
  const { isLoading, state } = contextOfThis;
  const { isLogUpdated } = state;
  const shouldTriggerLog = !isLoading && !isLogUpdated && action === VIEW_USER;
  if (shouldTriggerLog) {
    contextOfThis.setState({ isLogUpdated: true }); //This state is use to avoid make the call to the endpoint more than once.
    await userProfileSuccessLog(tenantId, auditId);
  }
};
