import { isArray, isPlainObject, last } from 'lodash';
import { TEXT, TEXTAREA } from '../../../../constants/fieldTypes';

const UPLOAD_SEPARATOR = '-';
const getRepeaterKeys = (formFields, fieldKeys, parentKey) => {
  const repeater = formFields[parentKey];
  const element = repeater[0] || {};
  const len = repeater.length;
  let isUpload = false;
  // key with '-' is only generated by the upload component '#-notes'
  const keys = Object.keys(element).filter(key =>
    fieldKeys.find(fKey => {
      if (key === fKey) return true;
      if (
        key.includes(UPLOAD_SEPARATOR) &&
        last(key.split(UPLOAD_SEPARATOR)) === fKey
      ) {
        isUpload = true;
        return true;
      }
    })
  );
  const repKeys = [];
  if (isUpload) {
    // it's an upload, we only worry about notes field
    repeater.forEach((item, i) => {
      const key = Object.keys(item).find(key => key.includes(UPLOAD_SEPARATOR));
      repKeys.push(`${parentKey}[${i}].${key}`);
    });
  } else {
    keys.forEach(key => {
      for (let i = 0; i < len; i++) {
        repKeys.push(`${parentKey}[${i}].${key}`);
      }
    });
  }
  return repKeys;
};

const reduceErrors = (values, errors, fieldsByKey) => (result, key) => {
  let value;
  // first entry of the array could be empty
  if (
    isArray(errors[key]) &&
    isPlainObject(errors[key].find(e => e !== undefined))
  ) {
    result[key] = errors[key].map((rep, i) => {
      return Object.keys(rep).reduce(
        reduceErrors(values[key][i], errors[key][i], fieldsByKey),
        {}
      );
    });
  } else {
    const _key = key.includes(UPLOAD_SEPARATOR)
      ? last(key.split(UPLOAD_SEPARATOR))
      : key;
    const defaultValue = [TEXT, TEXTAREA].includes(fieldsByKey[_key].field_type)
      ? ''
      : undefined;
    value = values[key] || defaultValue;
    result[key] = { value, ...errors[key] };
  }
  return result;
};

const validateSectionWrapperFields = (
  props,
  fieldKeys,
  fieldsByKey,
  next = () => {}
) => {
  const { form } = props;
  const formFields = form.getFieldsValue();

  const keys = Object.keys(formFields).reduce((toVerify, key) => {
    // check if the key is a repeater
    if (isArray(formFields[key]) && isPlainObject(formFields[key][0])) {
      const repKeys = getRepeaterKeys(formFields, fieldKeys, key);
      // save repeater keys in the array to verify
      toVerify = toVerify.concat(repKeys);
    } else if (fieldKeys.find(fKey => key === fKey)) {
      toVerify.push(key);
    }
    return toVerify;
  }, []);

  // trigger form field validation
  form.validateFields(keys, (errors, values) => {
    // similar to the on submit process, for conditional display fields, we have
    // errors that weren't detected in the form validation method, we need to tell
    // the form about them, this way the form will show up the error messages.
    // First create the object with the field values and errors
    const keys = Object.keys(errors || {});
    const allFields = keys.reduce(
      reduceErrors(values, errors, fieldsByKey),
      {}
    );
    // now update the form fields
    form.setFields(allFields);

    next(errors);
  });
};

export default validateSectionWrapperFields;
