import styled from 'styled-components';
import { Row } from 'antd';

const RowEditor = styled(Row)`
  .row-editor--actions-row {
    border-bottom: 1px solid #e6e6e6;
  }
`;

export default RowEditor;
