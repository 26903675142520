import { kebabCase, some } from 'lodash';

export default (
  {
    workFlowData: { status = {}, state, activeStateReviewers = [] } = {},
    actions = [],
  },
  currentUser = ''
) => {
  let { [currentUser]: currentStatus = {} } = status;

  if (state === 'closed' || state === 'investigate-incident') {
    return {
      label: 'Closed',
      key: 'closed',
      currentStatus,
    };
  }

  const isPending = some(actions, action => action.status === 'pending');

  // Early return if form is linked and has a status of 'pending'
  if (isPending) {
    return {
      label: 'Pending',
      key: 'pending',
      currentStatus,
    };
  }

  if (!currentStatus.label) {
    currentStatus = status[activeStateReviewers[state]] || {};
  }
  const label = currentStatus.label || '';
  const key = kebabCase(currentStatus.labelIdentifier || label);

  return {
    label,
    key,
    currentStatus,
  };
};
