import {
  FETCH_REASONS_REQUEST,
  FETCH_REASONS_SUCCESS,
  FETCH_REASONS_FAILURE,
} from './';

import { getErrorResponseStatus } from './utils';

import { getAgencyReasons } from '../api/agencies';

export default () => (dispatch, getState) => {
  const {
    session: {
      currentUser: { agencyId },
    },
  } = getState();
  dispatch({
    type: FETCH_REASONS_REQUEST,
  });
  return getAgencyReasons(agencyId)
    .then(response => {
      return dispatch({
        type: FETCH_REASONS_SUCCESS,
        payload: response,
      });
    })
    .catch(responseError => ({
      type: FETCH_REASONS_FAILURE,
      payload: getErrorResponseStatus(responseError),
    }));
};
