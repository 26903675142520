const getBodyDiagramChildEnumDefault = key => {
  const enums = {
    injuriesBodySideFront: [
      {
        label: 'Head',
        value: 'Head',
        order: 0,
      },
      {
        label: 'Forehead',
        value: 'Forehead',
        order: 1,
      },
      {
        label: 'Nose',
        value: 'Nose',
        order: 2,
      },
      {
        label: 'Mouth',
        value: 'Mouth',
        order: 3,
      },
      {
        label: 'Chin',
        value: 'Chin',
        order: 4,
      },
      {
        label: 'Neck',
        value: 'Neck',
        order: 5,
      },
      {
        label: 'Lower Abdomen',
        value: 'Lower Abdomen',
        order: 6,
      },
      {
        label: 'Pelvis',
        value: 'Pelvis',
        order: 7,
      },
      {
        label: 'Right Ear',
        value: 'Right Ear',
        order: 8,
      },
      {
        label: 'Right Eye',
        value: 'Right Eye',
        order: 9,
      },
      {
        label: 'Right Cheek',
        value: 'Right Cheek',
        order: 10,
      },
      {
        label: 'Right Shoulder',
        value: 'Right Shoulder',
        order: 11,
      },
      {
        label: 'Right Chest',
        value: 'Right Chest',
        order: 12,
      },
      {
        label: 'Right Upper Arm',
        value: 'Right Upper Arm',
        order: 13,
      },
      {
        label: 'Right Upper Abdomen',
        value: 'Right Upper Abdomen',
        order: 14,
      },
      {
        label: 'Right Lower Arm',
        value: 'Right Lower Arm',
        order: 15,
      },
      {
        label: 'Right Wrist',
        value: 'Right Wrist',
        order: 16,
      },
      {
        label: 'Right Palm',
        value: 'Right Palm',
        order: 17,
      },
      {
        label: 'Right Thigh',
        value: 'Right Thigh',
        order: 18,
      },
      {
        label: 'Right Knee',
        value: 'Right Knee',
        order: 19,
      },
      {
        label: 'Right Leg',
        value: 'Right Leg',
        order: 20,
      },
      {
        label: 'Right Calf',
        value: 'Right Calf',
        order: 21,
      },
      {
        label: 'Right Ankle',
        value: 'Right Ankle',
        order: 22,
      },
      {
        label: 'Right Foot',
        value: 'Right Foot',
        order: 23,
      },
      {
        label: 'Left Ear',
        value: 'Left Ear',
        order: 24,
      },
      {
        label: 'Left Eye',
        value: 'Left Eye',
        order: 25,
      },
      {
        label: 'Left Cheek',
        value: 'Left Cheek',
        order: 26,
      },
      {
        label: 'Left Shoulder',
        value: 'Left Shoulder',
        order: 27,
      },
      {
        label: 'Left Chest',
        value: 'Left Chest',
        order: 28,
      },
      {
        label: 'Left Upper Arm',
        value: 'Left Upper Arm',
        order: 29,
      },
      {
        label: 'Left Upper Abdomen',
        value: 'Left Upper Abdomen',
        order: 30,
      },
      {
        label: 'Left Lower Arm',
        value: 'Left Lower Arm',
        order: 31,
      },
      {
        label: 'Left Wrist',
        value: 'Left Wrist',
        order: 32,
      },
      {
        label: 'Left Palm',
        value: 'Left Palm',
        order: 33,
      },
      {
        label: 'Left Thigh',
        value: 'Left Thigh',
        order: 34,
      },
      {
        label: 'Left Knee',
        value: 'Left Knee',
        order: 35,
      },
      {
        label: 'Left Leg',
        value: 'Left Leg',
        order: 36,
      },
      {
        label: 'Left Calf',
        value: 'Left Calf',
        order: 37,
      },
      {
        label: 'Left Ankle',
        value: 'Left Ankle',
        order: 38,
      },
      {
        label: 'Left Foot',
        value: 'Left Foot',
        order: 39,
      },
    ],
    injuriesBodySideBack: [
      {
        label: 'Head',
        value: 'Head',
        order: 0,
      },
      {
        label: 'Neck',
        value: 'Neck',
        order: 1,
      },
      {
        label: 'Upper Back',
        value: 'Upper Back',
        order: 2,
      },
      {
        label: 'Lower Back',
        value: 'Lower Back',
        order: 3,
      },
      {
        label: 'Right Ear',
        value: 'Right Ear',
        order: 4,
      },
      {
        label: 'Right Shoulder',
        value: 'Right Shoulder',
        order: 5,
      },
      {
        label: 'Right Upper Arm',
        value: 'Right Upper Arm',
        order: 6,
      },
      {
        label: 'Right Hand',
        value: 'Right Hand',
        order: 7,
      },
      {
        label: 'Right Elbow',
        value: 'Right Elbow',
        order: 8,
      },
      {
        label: 'Right Lower Arm',
        value: 'Right Lower Arm',
        order: 9,
      },
      {
        label: 'Right Buttock',
        value: 'Right Buttock',
        order: 10,
      },
      {
        label: 'Right Thigh',
        value: 'Right Thigh',
        order: 11,
      },
      {
        label: 'Right Knee',
        value: 'Right Knee',
        order: 12,
      },
      {
        label: 'Right Leg',
        value: 'Right Leg',
        order: 13,
      },
      {
        label: 'Right Calf',
        value: 'Right Calf',
        order: 14,
      },
      {
        label: 'Right Ankle',
        value: 'Right Ankle',
        order: 15,
      },
      {
        label: 'Right Foot',
        value: 'Right Foot',
        order: 16,
      },
      {
        label: 'Left Ear',
        value: 'Left Ear',
        order: 17,
      },
      {
        label: 'Left Shoulder',
        value: 'Left Shoulder',
        order: 18,
      },
      {
        label: 'Left Upper Arm',
        value: 'Left Upper Arm',
        order: 19,
      },
      {
        label: 'Left Hand',
        value: 'Left Hand',
        order: 20,
      },
      {
        label: 'Left Elbow',
        value: 'Left Elbow',
        order: 21,
      },
      {
        label: 'Left Lower Arm',
        value: 'Left Lower Arm',
        order: 22,
      },
      {
        label: 'Left Buttock',
        value: 'Left Buttock',
        order: 23,
      },
      {
        label: 'Left Thigh',
        value: 'Left Thigh',
        order: 24,
      },
      {
        label: 'Left Knee',
        value: 'Left Knee',
        order: 25,
      },
      {
        label: 'Left Leg',
        value: 'Left Leg',
        order: 26,
      },
      {
        label: 'Left Calf',
        value: 'Left Calf',
        order: 27,
      },
      {
        label: 'Left Ankle',
        value: 'Left Ankle',
        order: 28,
      },
      {
        label: 'Left Foot',
        value: 'Left Foot',
        order: 29,
      },
    ],
  };
  return enums[key];
};

export default getBodyDiagramChildEnumDefault;
