import styled from 'styled-components';

import { Switch, Row, Button } from 'antd';

export const StyledTenantLabel = styled.label`
  color: #3772ff;
  margin-right: 10px;
`;
export const StyledSwitch = styled(Switch)`
  margin-right: 10px;
`;
export const StyledNoResults = styled.div`
  text-align: center;
  padding: 50px 0;
  color: #c7c7c7;
  font-size: 2em;
`;
export const StyledRow = styled(Row)`
  margin-top: 16px;
`;
export const StyledRowPagination = styled(Row)`
  margin-top: 16px;
`;
export const StyledButton = styled(Button)`
  margin-right: 5px;
`;
