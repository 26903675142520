import styled from 'styled-components';
import { Row } from 'antd';

const RowWrapper = styled(Row)`
  padding: 0;

  &.modal-title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 14px;
    margin: 5px 0;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  .scrollable {
    max-height: 360px;
    overflow-y: scroll;
    border: 1px solid #efefef;
  }

  .title {
    font-weight: 600;
    text-transform: capitalize;
    line-height: 2;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .ant-tag {
    text-transform: capitalize;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .edit-input {
    width: 100%;
  }

  .property {
    margin-right: 10px;
  }

  .select-values {
    .select-all {
      text-align: right;
    }

    .delete-selected {
      border-color: #ff4d4f;
      color: #ff4d4f;

      &:disabled {
        border-color: #a6a6a6;
        color: #a6a6a6;
      }
    }
  }

  .select-values-row {
    margin-bottom: 5px;

    .edit-input {
      width: 90%;
    }

    .select-values-row {
      background-color: whitesmoke;
      padding: 3px 3px 3px 7px;
      margin-bottom: 0;
      border-left: 1px solid lightgray;
      border-right: 1px solid lightgray;

      &:first-child {
        border-top: 1px solid lightgray;
      }

      &:last-child {
        border-bottom: 1px solid lightgray;
      }
    }
  }

  .ant-divider {
    font-size: 12px;
  }

  &.options-footer {
    border-top: 1px solid #e9e9e9;
    text-align: right;
    padding-bottom: 0;
    padding-top: 10px;

    .ant-btn {
      margin-left: 20px;
    }
  }

  &.conditional {
    .cond-join-type {
      width: 60px;
    }

    .cond-behavior {
      width: 122px;
    }

    .cond-select-field,
    .cond-comparition-type,
    .cond-compare-to {
      width: 100%;
      padding-right: 4px;
    }

    .cond-row {
      margin-bottom: 4px;
    }
  }

  &.share {
    .field-list {
      width: 80%;
    }

    .ant-tag {
      text-transform: none;
    }
  }

  &.information {
    height: 500px;

    .ql-editor {
      height: 440px;
    }
  }
`;

export default RowWrapper;
