import React from 'react';
import UserDocument from './UserDocument';

const UserDocumentSection = ({
  profileForm,
  timezone,
  editUserSection,
  updateUserSectionField,
  addUserSectionFieldValue,
  removeUserSectionFieldValue,
  setEditUserSectionTable,
  userProfile,
  canViewDocuments,
  canManageDocuments,
}) =>
  canViewDocuments && (
    <UserDocument
      profileForm={profileForm}
      timezone={timezone}
      editSection={editUserSection}
      updateSectionField={updateUserSectionField}
      addSectionFieldValue={addUserSectionFieldValue}
      removeSectionFieldValue={removeUserSectionFieldValue}
      setEditSectionTable={setEditUserSectionTable}
      userProfile={userProfile}
      isSectionReadOnly={!canManageDocuments}
    />
  );

export default UserDocumentSection;
