import styled from 'styled-components';
import { Layout } from 'antd';

const Content = styled(Layout.Content)`
  &.ant-layout-content {
    margin: 0 16px;

    @media screen and (max-width: 480px) {
      margin: 0 10px;
    }
  }

  .content-wrapper {
    position: relative;
    padding: 14px;
    min-height: 360px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-bottom: 20px;
    max-width: 1300px;

    @media screen and (max-width: 480px), (max-width: 992px) {
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 0;
    }
  }
`;

export default Content;
