import React from 'react';
import { Link } from 'react-router-dom';
import isPlainObject from 'lodash/isPlainObject';

import getRoute from '../../utils/get-route';

function getLabelOrLinkForAllegation(allegation, formSubmissionId) {
  const allegationText = allegation ? allegation : '';
  if (formSubmissionId) {
    return (
      <Link to={getRoute('report', { id: formSubmissionId })} alt={allegation}>
        {allegationText}
      </Link>
    );
  }
  return <span> {allegationText} </span>;
}

/**
 * @param {string[]} allegations The list of allegations in the investigation.
 * @param {string} formSubmissionId The ObjectID of the form submission.
 * @param {object} props
 * @return {object} A react component.
 */
function getAllegationColumnText(allegations, formSubmissionId, record = {}) {
  if (Array.isArray(allegations)) {
    const allegationsLastIndex = allegations.length - 1;
    const allegationLinks = allegations
      .sort()
      .reduce((result, allegation, index, initialValue) => {
        const lastIndex = allegationsLastIndex === index;
        const appendCharacter =
          allegationsLastIndex - 1 === index ? ' and ' : ', ';
        return result.concat(
          <span key={`${formSubmissionId}-${allegation}`}>
            {getLabelOrLinkForAllegation(allegation, formSubmissionId)}
            {lastIndex ? '' : appendCharacter}
          </span>
        );
      }, []);
    return (
      <div>
        <div>
          <b>{record.formSubmissionNumber}</b>
        </div>
        <div>{allegationLinks}</div>
      </div>
    );
  }

  if (isPlainObject(allegations)) {
    const entries = Object.entries(allegations);
    const filteredEntries = entries.filter(entry => entry[1].length);
    const allegationsLastIndex = filteredEntries.length - 1;

    const allegationLinks = filteredEntries.reduce(
      (result, [type, allegationList], index, initialValue) => {
        const lastIndex = allegationsLastIndex === index;
        const appendCharacter =
          allegationsLastIndex - 1 === index ? ' and ' : ', ';
        return result.concat(
          <span key={`${formSubmissionId}-${type}`}>
            <b>{type}</b>:{' '}
            {getLabelOrLinkForAllegation(
              allegationList.join(', '),
              formSubmissionId
            )}
            {lastIndex ? '' : appendCharacter}
          </span>
        );
      },
      []
    );
    return (
      <div>
        <div>
          <b>{record.formSubmissionNumber}</b>
        </div>
        <div>{allegationLinks}</div>
      </div>
    );
  }

  return '';
}

export default getAllegationColumnText;
