import get from 'lodash/get';
import { transformValidations } from './validations';
import { getFormPresentation, getFormFields } from './form';

const getTemplateFields = async (template, timezone) => {
  const schema = get(template, 'formSchema.form', {});
  const validations = get(template, 'formSchema.validations', {});
  const enums = get(template, 'formSchema.enums', {});

  const validationRules = await transformValidations(
    validations,
    enums,
    timezone
  );

  const presentation = getFormPresentation({
    schema,
    data: {},
    overrides: {},
  });
  const presentationRules = await getFormFields(schema, validationRules);
  const fields = Object.keys(
    presentation.fields.reduce((result, tab) => ({ ...result, ...tab }), {})
  );

  return {
    fields,
    presentation,
    presentationRules,
  };
};

export default getTemplateFields;
