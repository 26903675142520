import { isEmpty, last } from 'lodash';

const getChildOption = (child, disableKeys, disabled = false) =>
  !disabled && disableKeys.some(k => last(k) === child.value)
    ? { ...child, disabled: true }
    : { ...child, disabled };

const disableCascaderOptions = (
  cascaderOptions,
  parentRepeater,
  disableKeys = [],
  onlyParentRepeater = true
) => {
  return cascaderOptions.map(o => ({
    ...o,
    children: o.children.map(c => {
      const { value, children } = c;
      if (children !== undefined) {
        const checkParentRepeater =
          onlyParentRepeater ||
          (!onlyParentRepeater && !isEmpty(parentRepeater));
        const disabled = checkParentRepeater ? value !== parentRepeater : false;

        return {
          ...c,
          children: children.map(child =>
            getChildOption(child, disableKeys, disabled)
          ),
          disabled,
        };
      }
      return getChildOption(c, disableKeys);
    }),
  }));
};

export default disableCascaderOptions;
