import styled from 'styled-components';

const AdvanceFilterWrapper = styled.div`
  position: fixed;
  z-index: 1000;
  background-color: white;
  padding: 10px;
  right: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  @media (max-width: 992px) {
    padding: 3px;
  }

  @media (max-height: 500px) {
    max-height: 228px;

    .scrollable {
      overflow-y: scroll;
      max-height: 178px;
    }
  }

  .ant-select-dropdown-menu {
    @media screen and (max-width: 480px), (max-height: 500px) {
      max-height: 200px;
    }

    .ant-select-dropdown-menu-item {
      text-align: left;
    }
  }

  .ant-form-item {
    margin: 0px;
    border-bottom: 1px solid #e9e9e9;

    @media (max-width: 992px) {
      .ant-form-item-control {
        line-height: 39px;
      }
    }
  }

  .btn-apply {
    float: right;
  }

  // overlay arrow
  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 90%;
    margin-left: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
`;

export default AdvanceFilterWrapper;
