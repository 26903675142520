import urlBuilder from 'APP_ROOT/utils/url-builder';
import { route } from 'APP_ROOT/utils/request';

const getURL = props => {
  const { meta: { id = '' } = {} } = props;
  return route(
    urlBuilder('/Forms/:id/upload', {
      id,
    })
  );
};

export default getURL;
