import styled from 'styled-components';
import { Col } from 'antd';
import { ACCENT, BLACK } from 'APP_ROOT/config/theme';

const ButtonWrapper = styled(Col)`
  .admin-menu__button {
    width: 100%;
    padding: 20px 0;
    margin-top: 50px;
    height: auto;
    text-align: left;
    font-size: 24px;
    font-weight: 300;
    color: ${BLACK};

    .admin-buttonr__text {
      display: flex;
      align-items: center;
      width: 200px;
      margin: 0 auto;

      .admin-buttonr__icon {
        line-height: 0;
        margin-right: 30px;

        @media screen and (max-width: 480px) {
          margin-right: 10px;
        }
      }
    }

    &:hover {
      color: ${ACCENT};
    }

    @media screen and (max-width: 680px) {
      margin-top: 25px;
      text-align: center;
    }
  }
`;

export default ButtonWrapper;
