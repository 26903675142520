import React from 'react';

import parsedDate from '../../utils/parse-date';
import {
  timelineItemMessage as getTimeoutItemMessage,
  timeoutHistory,
} from '../form-transaction-timeline';

const lineBreakRegex = /&#013;&#010;/g;

const TimeoutItem = props => {
  const { item, index, timezone, history } = props;
  const { notes = '' } = item;
  const sanitizedNotes = notes
    .replace(lineBreakRegex, '<br />')
    .replace(/\n/g, '<br />');

  return (
    <div>
      <p className={`timeline-item-date-${index}`}>
        {parsedDate(item.fromDate, timezone)}
      </p>
      <p>
        <b>
          {' '}
          {item.formType} {item.formNumber}{' '}
        </b>
      </p>
      <p
        className={`timeline-item-message-${index}`}
        dangerouslySetInnerHTML={{
          __html: getTimeoutItemMessage(
            item.action,
            item,
            index,
            timeoutHistory(history)
          ),
        }}
      />
      {notes && <div dangerouslySetInnerHTML={{ __html: sanitizedNotes }} />}
    </div>
  );
};

export default TimeoutItem;
