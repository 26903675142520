const fetchReadableStream = (rb, cb) => {
  if (rb instanceof ReadableStream) {
    const reader = rb.getReader();
    const stream = new ReadableStream({
      start(controller) {
        // The following function handles each data chunk
        function push() {
          // "done" is a Boolean and value a "Uint8Array"
          reader.read().then(({ done, value }) => {
            // If there is no more data to read
            if (done) {
              controller.close();
              return;
            }
            // Get the data and send it to the browser via the controller
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const response = new Response(stream, {
      headers: { 'Content-Type': 'application/json' },
    });
    response.json().then(result => {
      cb && cb(result);
    });
  }
};

export default fetchReadableStream;
