import React, { Component, Fragment } from 'react';
import get from 'lodash/get';

import withoutClutter from 'APP_COMPONENTS/without-clutter';
import DashboardPage from 'APP_COMPONENTS/dashboard';
import withOwnershipGuard from 'APP_ROOT/containers/administrator/components/agency-ownership-guard';
import RoleHeader from '../RoleHeader/RoleHeader';
import { sidebarIsCollapsed } from 'APP_ROOT/selectors/application';
import getAgency from '../../../../containers/administrator/agency-profile/actions/get-agency-info';

export class Role extends Component {
  componentDidMount() {
    const { dispatch, agencyId, agencyProfile } = this.props;

    if (!agencyProfile) dispatch(getAgency(agencyId));
  }

  render() {
    const { isSidebarIsCollapsed, agencyProfile, agencyId } = this.props;
    const agencyName = get(agencyProfile, 'name', '');
    return (
      <Fragment>
        <RoleHeader
          isSidebarIsCollapsed={isSidebarIsCollapsed}
          agencyName={agencyName}
          agencyId={agencyId}
        />
        <div>NEW ROLE</div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  const agencyId = get(props, 'match.params.agencyId');
  return {
    agencyId,
    isSidebarIsCollapsed: sidebarIsCollapsed(state),
    agencyProfile: get(state, `agencyProfile.${agencyId}.data`, null),
  };
};

export default withoutClutter(
  DashboardPage(mapStateToProps)(
    withOwnershipGuard(Role, 'administratorAgency')
  )
);
