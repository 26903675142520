import styled from 'styled-components';

const SectionCollapseWrapper = styled.div.attrs({
  className: 'bdm-section-collapse',
})`
  position: relative;

  .collapse-header {
    .ant-collapse-header {
      font-size: 20px;
      line-height: 50px;
      padding: 8px 0 8px 50px;

      &:active,
      &:hover {
        background-color: transparent;
      }

      .arrow {
        font-size: 18px !important;
        line-height: 70px;
        left: 20px;
        transform: rotate(90deg) scale(0.75);
      }
    }

    &.ant-collapse-item-active {
      .ant-collapse-header .arrow {
        transform: rotate(-90deg) scale(0.75);
      }
    }
  }

  .collapse-status {
    position: absolute;
    top: 20px;
    right: 10px;
  }
`;

export default SectionCollapseWrapper;
