import React, { PureComponent } from 'react';
import { Arc } from '@vx/shape';
import { Group } from '@vx/group';
import Animate from 'react-move/Animate';
import { reverse } from 'lodash';

import { WHEEL_BACKGROUND_COLOR } from '../../../config/theme';

import IconButton from '../buttons/icon-button';
import Label from './label';
import WheelNav from './styled/wheel-nav';

import Svg from '../../body-diagram/common/inline-svg';

const arrowLeft = require('../../../icons/ic_arrow_left.svg');
const arrowRight = require('../../../icons/ic_arrow_right.svg');

class WheelNavigation extends PureComponent {
  BOX_WIDTH = 350;
  BOX_HEIGHT = 350;

  SVG_WIDTH = 350;
  SVG_HEIGHT = 350;

  SVG_HALF = this.SVG_WIDTH / 2;

  DONUT_CX = this.BOX_WIDTH / 2;
  DONUT_CY = this.BOX_HEIGHT / 2;
  DONUT_STROKE_WIDTH = this.BOX_WIDTH * 0.24;
  DONUT_RADIUS = this.BOX_WIDTH / 2 - this.DONUT_STROKE_WIDTH / 2;

  state = {
    prevRotation: 0,
    nextRotation: 0,
    totalRotation: [0, 0],
    firstRotation: true,
  };

  UNSAFE_componentWillMount() {
    const { active: selectedCategory = [0, 0], items = [] } = this.props;

    const active = selectedCategory[1];
    const prev = selectedCategory[0];

    const d = 360 / items.length;
    const rotation = d * active;
    const prev_rotation = d * prev;
    const half = items.length / 2;
    const offset = d * half;
    const startAngle =
      offset - d * (active + 1) + d * 0.5 - (active > 0 ? d * active : 0);
    const prevRotation = rotation - startAngle;
    const nextRotation = startAngle + prev_rotation;
    const totalRotation = [prevRotation, nextRotation];

    this.setState({
      prevRotation,
      nextRotation,
      totalRotation,
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { active: selectedCategory = [0, 0], items = [] } = props;
    const { firstRotation } = this.state;

    const active = selectedCategory[1];

    const total = items.length;
    const d = 360 / total;

    if (firstRotation) {
      const rotation = d * active;
      const half = total / 2;
      const offset = d * half;
      const startAngle =
        offset - d * (active + 1) + d * 0.5 - (active > 0 ? d * active : 0);
      const prevRotation = rotation - startAngle;
      const nextRotation = rotation + startAngle;
      const totalRotation = [prevRotation, nextRotation];

      this.setState({
        prevRotation,
        nextRotation,
        totalRotation,
        firstRotation: false,
        selectedCategory,
      });
    } else if (selectedCategory[1] !== this.state.selectedCategory[1]) {
      const { nextRotation } = this.state;

      const rotateTo = this.navigateTo(...selectedCategory);

      const nextStack = rotateTo.clockwise
        ? Number(nextRotation) - d * rotateTo.value
        : Number(nextRotation) + d * rotateTo.value;

      const next = {
        nextRotation: nextStack,
        prevRotation: nextRotation,
      };

      this.setState({
        ...next,
        selectedCategory,
      });
    }
  }

  wrapText = text => {
    const words = text.split(/\s+/);
    const maxWidth = 60;
    const lineHeight = 1.4;
    const charWidth = 4.06;
    let lines = [];
    let span = '';
    let childs = [];

    words.forEach(word => {
      span += span !== '' ? ' ' + word : word;

      const lineWidth = span.length * charWidth;

      if (lineWidth > maxWidth) {
        lines.push(word);
      } else {
        const lastIndex = lines.length - 1 > 0 ? lines.length - 1 : 0;

        lines[lastIndex] = span;
      }
    });

    childs = lines.map((line, index) => {
      return {
        text: line,
        dy: index * lineHeight,
      };
    });

    return childs;
  };

  getTransform = (rotation, suffix = 0) =>
    isNaN(rotation) ? `rotate(${0 + suffix})` : `rotate(${rotation + suffix})`;

  navigateTo = (startIndex, to) => {
    const { items: categories = [] } = this.props;
    const categoriesReverse = reverse(Object.assign([], categories));

    const jumpsToRight = categories.reduce(
      (res, cat, i) => {
        if ((i + startIndex) % categories.length === to) {
          return {
            value: res.value,
            end: true,
          };
        }

        if (res.end) {
          return res;
        }

        return {
          end: false,
          value: res.value + 1,
        };
      },
      { end: false, value: 0 }
    );

    const jumpsToLeft = categoriesReverse.reduce(
      (res, cat, i) => {
        if ((i + startIndex) % categories.length === to) {
          return {
            value: res.value,
            end: true,
          };
        }

        if (res.end) {
          return res;
        }

        return {
          end: false,
          value: res.value - 1,
        };
      },
      { end: false, value: categories.length }
    );

    return {
      clockwise: jumpsToRight.value < jumpsToLeft.value,
      value: Math.min(jumpsToLeft.value, jumpsToRight.value),
    };
  };

  render() {
    const {
      onClick = () => {},
      onNext = () => {},
      onPrev = () => {},
      update = () => {},
      items = [],
      active: selectedCategory = [],
    } = this.props;
    const { prevRotation, nextRotation } = this.state;

    const width = this.SVG_WIDTH;
    const height = this.SVG_HEIGHT;
    const portion = 100 / items.length;
    const radius = Math.min(width, height) / 2;

    const active = selectedCategory[1] + 1;

    const interpolator = [
      this.getTransform(prevRotation),
      this.getTransform(nextRotation),
    ];

    const onGroupClick = data => {
      if (data.id !== active - 1) {
        onClick(data);
      }
    };

    return (
      <WheelNav id="wheel-nav">
        <div className="wheel-container">
          <IconButton className="wheel-nav-action arrow-left" onClick={onNext}>
            <img src={arrowLeft} alt="Previous" />
          </IconButton>
          <IconButton className="wheel-nav-action arrow-right" onClick={onPrev}>
            <img src={arrowRight} alt="Next" />
          </IconButton>
          <Animate
            start={{
              transform: interpolator[1],
            }}
            update={{
              transform: interpolator,
              transformAsCSS: [
                this.getTransform(prevRotation, 'deg'),
                this.getTransform(nextRotation, 'deg'),
              ],
              transformInverted: [
                this.getTransform(prevRotation * -1),
                this.getTransform(nextRotation * -1),
              ],
              timing: {
                duration: 400,
              },
              events: { end: update },
            }}
          >
            {({ transform, transformInverted, transformAsCSS }) => {
              return (
                <div className="wheel-pie-wrapper">
                  <Svg
                    viewBox="0 0 350 350"
                    transform={transform}
                    style={{ transform: transformAsCSS }}
                    version="1.1"
                    width="100%"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlSpace="preserve"
                  >
                    <circle
                      id="bg"
                      cy={this.DONUT_CY}
                      cx={this.DONUT_CX}
                      r={this.DONUT_RADIUS}
                      fill="none"
                      stroke={WHEEL_BACKGROUND_COLOR}
                      strokeWidth={this.DONUT_STROKE_WIDTH}
                    />

                    <Group top={height / 2} left={width / 2}>
                      <Arc
                        data={items}
                        pieValue={d => portion}
                        outerRadius={radius}
                        innerRadius={radius - 82}
                        fill="none"
                        centroid={(centroid, arc) => {
                          const [x, y] = centroid;
                          const { startAngle, endAngle, data } = arc;
                          const tspans = this.wrapText(data.text);
                          const top = y;
                          const id = data.id + 1;

                          if (endAngle - startAngle < 0.1) return null;

                          return (
                            <Group
                              onClick={e => {
                                onGroupClick(data);
                              }}
                              left={x}
                              top={top}
                            >
                              <Group
                                className={`group-text ${
                                  active === id ? 'active' : ''
                                }`}
                              >
                                {active !== id &&
                                  tspans.map((span, tspanIndex) => (
                                    <Label
                                      transform={transformInverted}
                                      key={tspanIndex}
                                      y={`${span.dy}em`}
                                      dy={
                                        tspanIndex < tspans.length - 1
                                          ? '0.2em'
                                          : '0em'
                                      }
                                    >
                                      {span.text}
                                    </Label>
                                  ))}

                                {active === id && (
                                  <Label
                                    y={`0em`}
                                    transform={transformInverted}
                                  >
                                    {tspans.map(t => t.text).join(' ')}
                                  </Label>
                                )}
                              </Group>
                            </Group>
                          );
                        }}
                      />
                    </Group>
                  </Svg>
                </div>
              );
            }}
          </Animate>
        </div>
      </WheelNav>
    );
  }
}

export default WheelNavigation;
