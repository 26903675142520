import { FETCH_ACTIONS_COUNT_SUCCESS, FETCH_ACTIONS_COUNT_ERROR } from './';

import { getNotificationsCount } from '../api/activity';

export default (next = () => {}) => {
  return (dispatch, getState) => {
    const {
      session: { isLoggedIn, currentUser: { userId, agencyId } = {} } = {},
      activity: { actions: { filter: filters = {} } = {} } = {},
      systemUpdates: { updating } = {},
    } = getState();

    const filter = {
      ...(filters.where || {}),
    };

    return new Promise((resolve, reject) => {
      if (isLoggedIn && agencyId && !updating) {
        getNotificationsCount({ userId, filter })
          .then(response => {
            dispatch({
              type: FETCH_ACTIONS_COUNT_SUCCESS,
              payload: response,
            });
            resolve(response);
          })
          .catch(err => {
            dispatch({ type: FETCH_ACTIONS_COUNT_ERROR });
            reject(err);
          });
      } else {
        reject();
      }
    });
  };
};
