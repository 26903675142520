import { escape } from 'html-escaper';
import { filterByIds } from '../../modules/ReportSharing/utils/networkRequestFilters';
import { get } from 'lodash';
import { getUsers } from '../../modules/ReportSharing/services/api';

export const getTimelineUser = async (userId, agencyId) => {
  const filter = filterByIds([userId]);
  const users = await getUsers(agencyId, filter);
  return users
    .map(user => {
      const escapedFullname = escape(get(user, 'fullName', ''));
      return `${get(user, 'rank.name', '')} ${escapedFullname})`;
    })
    .join(', ');
};
