import styled, { css } from 'styled-components';
import { Form } from 'antd';

import { ERROR } from 'APP_ROOT/config/theme';

const FormItem = Form.Item;

const Item = styled(FormItem).attrs({
  className: 'bdm-form-field',
})`
  .required {
    color: red;
    font-size: 17px;
  }

  .ant-form-item-label {
    white-space: normal;
    line-height: 1.4em;
    transform: translateY(-50%);
    top: 1.4em;
  }

  /*
    Note: based on BDM-9555 this field should be of type Number but instead it's a text field which makes
    it appear larger than the other fields of type Number, is not possible to change the type because the
    BE doesn't accept it, so in order to solve it it's necessary to add this css rule only for this field
    and support the change for legacy templates
  */
  .ant-form-item-control {
    #totalUnitsInPursuit {
      width: 90px;
    }

    &.has-error {
      .ant-switch,
      .ant-checkbox-inner {
        border-color: ${ERROR};
      }
    }
  }

  &.bdm-form-field-group {
    &.flex-alignment {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;

      .ant-form-item-label {
        top: 0;
        transform: translateY(0);
      }
    }

    .bdm-form-field {
      margin-bottom: 10px !important;
    }

    .ant-form-extra {
      display: inline-block;
      color: #000;
      margin-right: 16px;
      margin-left: 8px;
    }

    .ant-row {
      margin-left: 0;
      margin-right: 0;

      .ant-form-item-control-wrapper {
        padding-left: 0;
      }

      &.ant-form-item-select {
        .ant-form-item-control-wrapper {
          padding: 0;
        }
      }

      ${props => {
        if (props.wrapperHalf) {
          return css`
            width: 50%;
          `;
        }
        return '';
      }};

      &.bdm-readonly-mode {
        width: auto;
      }
    }
  }

  &.ant-form-item {
    &.bdm-readonly-mode {
      .ant-form-item-control {
        display: flex;

        .reviewer-field {
          flex: 1 auto;
          height: auto;
          word-break: break-word;
          word-wrap: break-word;
        }
      }
    }

    /* Note: In order to fix IE11 issue which flexbox support is partial, we have to override nested
    fields to be inline instead of flex items. Addressing BDM-1923 */
    &.bdm-form-field-group {
      .ant-form-item {
        &.bdm-readonly-mode {
          .ant-form-item-control {
            .ant-form-extra {
              display: flex;
              align-items: center;
            }

            .reviewer-field {
              flex: none;
            }
          }
        }
      }
    }
  }

  &.reviewer-row {
    margin-left: -16px;
    margin-right: -16px;
    /* stylelint-disable */
    .ant-form-item-label {
      padding-right: 8px;
      padding-left: 16px;

      @media screen and (max-width: 767px) {
        padding-right: 16px;
      }
    }

    .ant-form-item-control-wrapper {
      padding-right: 16px;
      padding-left: 5px;

      @media screen and (max-width: 767px) {
        padding-left: 16px;
      }
    }
  }
`;

export default Item;
