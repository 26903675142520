import React from 'react';
import { Button, Icon } from 'antd';
import ButtonWrapper from './FromTemplateButton.styled';

const FromTemplateButton = ({ template, onClick }) => (
  <div className="btn-wrapper">
    <Button onClick={onClick}>
      <ButtonWrapper className="btn__text">
        <Icon type="solution" />
        &nbsp;<span>{template.name}</span>
      </ButtonWrapper>
    </Button>
  </div>
);

export default FromTemplateButton;
