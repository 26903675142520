import { get, omit, isEmpty } from 'lodash';
import isUUID from 'is-uuid';

import getOverrides from 'APP_ROOT/utils/get-field-overrides';
import { MASKED } from 'APP_ROOT/constants/fieldTypes';

import getValidateTrigger from './getValidateTrigger';
import getFieldRules from './getFieldRules';

const getFieldOptions = (props, fieldDataKey) => {
  const { overrides = {}, shouldShow = true, field_type } = props;

  const getFieldOverrides = getOverrides(props, overrides);
  const options = getFieldOverrides('options', {});

  const { defaultValue: initialValue = null } = options;
  const validateTrigger = getValidateTrigger(props);
  const rules = getFieldRules(props);
  const currentFieldValue = get(props, `data.${fieldDataKey}`);

  // when the field is encrypted and the user does not have permissions
  // to view encrypted data, the value of the field will be a UUID and
  // for masked fields, the pattern won't match the value, for instance
  // SSN pattern !== UUID, so, to avoid a validation error is better to
  // remove the pattern and keep only the required validation CV-293
  const isMaskedUUID =
    field_type === MASKED &&
    !isEmpty(currentFieldValue) &&
    isUUID.v4(currentFieldValue);

  return {
    rules: rules.map(rule =>
      rule.required || !!rule.mustExist
        ? {
            ...(rule.encrypt && isMaskedUUID ? omit(rule, 'pattern') : rule),
            required: shouldShow ? rule.required || !!rule.mustExist : false,
          }
        : rule.encrypt && isMaskedUUID
        ? omit(rule, 'pattern')
        : rule
    ),
    validateTrigger,
    initialValue: initialValue || currentFieldValue,
    key: `${fieldDataKey}.field-decorator`,
    trigger: validateTrigger,
  };
};

export default getFieldOptions;
