import { get, omit } from 'lodash';

import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes';
import FormComponent from '../models/FormComponent';

const uploadFields = fields =>
  fields.reduce((allFields, currentField) => {
    const properties = get(currentField, 'properties', []);
    // make sure id is string
    const id = `${currentField.id}`;

    if (currentField.type === FIELD) {
      return allFields.concat(
        new FormComponent(currentField.type, {
          id,
          key: `key-${id}`,
          ...omit(currentField, ['id', 'type']),
        })
      );
    } else {
      return allFields.concat(
        new FormComponent(
          currentField.type,
          {
            id,
            key: `key-${id}`,
            ...omit(currentField, ['id', 'type', 'properties']),
          },
          uploadFields(properties)
        )
      );
    }
  }, []);

export default uploadFields;
