import styled from 'styled-components';
import { GRAY_2 } from '../../../config/theme';

const StyledInputWrapper = styled.div`
  input:disabled {
    color: ${GRAY_2};
  }
`;

export default StyledInputWrapper;
