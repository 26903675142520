import callback from '../../utils/callback';
import { getReportsWithMention } from '../../../../api/manageUsers';

export default (userId, agencyId, next) => _dispatch => {
  return getReportsWithMention(userId, agencyId)
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
