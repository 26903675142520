import { get } from 'lodash';

const getProxyError = errors => {
  // this happens when using forms service
  const [{ code, message }] = errors;
  // message has the callstack with a json with the error message
  let first = message.indexOf('{');
  let last = message.lastIndexOf('}');
  if (first !== -1 && last !== -1 && first < last) {
    // form service as a proxy
    const parsedError = JSON.parse(message.substring(first, last + 1));
    return parsedError.error;
  } else {
    first = message.indexOf(code);
    if (first !== -1) {
      // form service as service
      last = message.indexOf('\n');
      return { message: message.substring(first + code.length + 1, last) };
    }
  }
};

const getEditedErrors = async response => {
  const errorJson = await response.json();
  const { error, errors } = errorJson;
  let editedErrors = '';
  let _error = error;
  if (errors !== undefined) {
    _error = getProxyError(errors);
  }
  if (_error !== undefined) {
    editedErrors = get(_error, 'errors', get(_error, 'message', ''));
  }

  return editedErrors;
};

export default getEditedErrors;
