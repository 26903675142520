import getOverrides from 'APP_ROOT/utils/get-field-overrides';

const getLabel = props => {
  const { overrides = {}, title = '' } = props;

  const getFieldOverrides = getOverrides(props, overrides);
  const fieldOverrides = getFieldOverrides();
  const options = getFieldOverrides('options', {});

  const { showTitle = true } = options;

  return showTitle ? fieldOverrides.title || title || ' ' : null;
};

export default getLabel;
