import React, { useEffect, useState } from 'react';
import { BorderWrapper } from './OrganizationAddress.styled';
import { Row, Col, Input as InputField, Checkbox, Select } from 'antd';
import Input from '../../../containers/administrator/components/input';
import { Field } from 'redux-form';
import {
  commonFieldOptions,
  selectFieldOptions,
  zipFieldOptions,
} from './OrganizationAddress.styled';
import { PanelSectionTable, PanelRow } from '../../../components/common/panel';
import getStates from '../../../containers/organization-profile/actions/get-states';
import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from 'APP_COMPONENTS/custom-sections/sections/Sections.styled';

const TextField = Input(InputField);
const SelectField = Input(Select);

function OrganizationMailingSection({ checked, updateCheckBox }) {
  let [selectOptions, setselectOptions] = useState([]);

  useEffect(() => {
    getStates().then(data => {
      setselectOptions(data.selectOptions);
    });
  }, []);

  return (
    <BorderWrapper>
      <StyledCollapse bordered={false} expandIconPosition="right">
        <StyledPanel
          header={<StyledPanelTitle size="large">Address</StyledPanelTitle>}
          key="Address"
          className="section-panel"
        >
          <div className="divider" />
          <PanelSectionTable>
            <PanelRow>
              <Row type="flex" align="middle">
                <Col span={12}>
                  <Field
                    type="text"
                    name="primary_address1"
                    label="Address Line 1"
                    component={TextField}
                    options={commonFieldOptions}
                    disabled={false}
                    plainText={false}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    type="text"
                    name="primary_address2"
                    label="Address Line 2"
                    component={TextField}
                    options={commonFieldOptions}
                    disabled={false}
                    plainText={false}
                  />
                </Col>
              </Row>
            </PanelRow>

            <PanelRow>
              <Row type="flex" align="middle">
                <Col span={12}>
                  <Field
                    type="text"
                    name="primary_city"
                    label="City"
                    component={TextField}
                    options={commonFieldOptions}
                    disabled={false}
                  />
                </Col>
                <Col span={7}>
                  <Field
                    type="select"
                    name="primary_state"
                    label="State"
                    component={SelectField}
                    dropdownOptions={selectOptions}
                    disabled={false}
                    options={selectFieldOptions}
                  />
                </Col>
                <Col span={5}>
                  <Field
                    type="text"
                    name="primary_zip"
                    label="Zip"
                    component={TextField}
                    options={zipFieldOptions}
                    disabled={false}
                    plainText={false}
                    maxLength={5}
                  />
                </Col>
              </Row>
            </PanelRow>
            <PanelRow>
              <Row type="flex" align="middle">
                <Col span={12}>
                  <Field
                    type="text"
                    name="primary_county"
                    label="County"
                    component={TextField}
                    options={commonFieldOptions}
                    disabled={false}
                    plainText={false}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    type="text"
                    name="primary_counties"
                    label="Additional County"
                    component={TextField}
                    options={commonFieldOptions}
                    disabled={false}
                    plainText={false}
                  />
                </Col>
              </Row>
            </PanelRow>
            <Row>
              <Col span={24}>
                <Checkbox
                  className="checkbox"
                  onChange={() => {
                    updateCheckBox();
                  }}
                  checked={checked}
                >
                  Mailing Address Same as Primary Address
                </Checkbox>
              </Col>
            </Row>
            <PanelRow>
              <Row type="flex" align="middle">
                <Col span={12}>
                  <Field
                    type="text"
                    name={checked ? 'primary_address1' : 'mailing_address1'}
                    label="Mailing Address Line 1"
                    component={TextField}
                    options={commonFieldOptions}
                    disabled={checked}
                    plainText={false}
                  />
                </Col>
                <Col span={12}>
                  <Field
                    type="text"
                    name={checked ? 'primary_address2' : 'mailing_address2'}
                    label="Mailing Address Line 2"
                    component={TextField}
                    options={commonFieldOptions}
                    disabled={checked}
                    plainText={false}
                  />
                </Col>
              </Row>
            </PanelRow>

            <PanelRow>
              <Row type="flex" align="middle">
                <Col span={12}>
                  <Field
                    type="text"
                    name={checked ? 'primary_city' : 'mailing_city'}
                    label="Mailing City"
                    options={commonFieldOptions}
                    component={TextField}
                    disabled={checked}
                  />
                </Col>
                <Col span={7}>
                  <Field
                    type="select"
                    name={checked ? 'primary_state' : 'mailing_state'}
                    label="Mailing State"
                    data-test="mailingState"
                    component={SelectField}
                    dropdownOptions={selectOptions}
                    disabled={checked}
                    options={selectFieldOptions}
                  />
                </Col>
                <Col span={5}>
                  <Field
                    type="text"
                    name={checked ? 'primary_zip' : 'mailing_zip'}
                    label="Mailing Zip"
                    component={TextField}
                    options={zipFieldOptions}
                    disabled={checked}
                    plainText={false}
                    maxLength={5}
                  />
                </Col>
              </Row>
            </PanelRow>

            <PanelRow>
              <Row type="flex" align="middle">
                <Col span={12}>
                  <Field
                    type="text"
                    name={checked ? 'primary_county' : 'mailing_county'}
                    label="Mailing County"
                    component={TextField}
                    options={commonFieldOptions}
                    disabled={checked}
                    plainText={false}
                  />
                </Col>
              </Row>
            </PanelRow>
          </PanelSectionTable>
        </StyledPanel>
      </StyledCollapse>
    </BorderWrapper>
  );
}

export default OrganizationMailingSection;
