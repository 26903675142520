export const FILTER_SEARCH = 'search';
export const FILTER_TYPE = 'type';
export const FILTER_RANGE = 'range';
export const FILTER_EVENT_DATE = 'eventDate';
export const FILTER_VIEW = 'view';
export const FILTER_STATUS = 'status';
export const FILTER_FORCE = 'force';
export const FILTER_ALLEGATION = 'allegation';
export const FILTER_MENTIONED_USERS = 'mentionedUsers';
export const FILTER_REPORT_SUBMITTER = 'reportSubmitter';

export const INCIDENT_OFFICER_FORCE_APPLIED = 'incidentOfficerForceApplied';
export const COMPLAINT_ALLEGATION_TYPE_FILTER_KEY =
  'allegations.allegationType';
export const INVESTIGATION_ALLEGATION_TYPE_FILTER_KEY =
  'findings.allegationType';

export const FILTER_LAYOUT_OPTIONS = {
  colon: false,
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const FILTER_ARRAY_SEPARATOR = '|';
