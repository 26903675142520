import {
  genOptions,
  route,
  fetchRequest,
  encodeFilter,
} from '../utils/request';
import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';

export const getUsers = ({
  agencyId = '',
  filters = {},
  includeDisabled = false,
  includeNoSworn = false,
}) => {
  const filter = encodeFilter(filters);
  const token = storage.get('token');
  const url = route(
    urlBuilder(
      `/Agencies/:agencyId/BenchmarkUsers`,
      { agencyId },
      { filter, includeDisabled, includeNoSworn }
    )
  );
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

export const getUsersCount = ({
  agencyId,
  filter: filters,
  includeDisabled = false,
  includeNoSworn = false,
}) => {
  const filter = encodeFilter(filters);
  const url = route(
    urlBuilder(
      '/Agencies/:agencyId/BenchmarkUsers/count',
      { agencyId },
      { filter, includeDisabled, includeNoSworn }
    )
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const createUser = data => {
  const url = route(urlBuilder('/BenchmarkUsers'));
  const options = genOptions('POST', data, storage.get('token'));

  return fetchRequest(url, options);
};

export const updateUser = (userId, data) => {
  const url = route(urlBuilder('/BenchmarkUsers/:userId', { userId }));
  const options = genOptions('PUT', data, storage.get('token'));

  return fetchRequest(url, options);
};

export const enableUser = (userId, data) => {
  const url = route(urlBuilder('/BenchmarkUsers/:userId/enable', { userId }));
  const options = genOptions('POST', undefined, storage.get('token'));

  return fetchRequest(url, options);
};

export const disableUser = (userId, data) => {
  const url = route(urlBuilder('/BenchmarkUsers/:userId/disable', { userId }));
  const options = genOptions('POST', undefined, storage.get('token'));

  return fetchRequest(url, options);
};

export const getUnitAssignments = agencyId => {
  const url = route(
    urlBuilder('/Agencies/:agencyId/units-of-assignment', {
      agencyId,
    })
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const getRanks = agencyId => {
  const url = route(
    urlBuilder('/Agencies/:agencyId/titles', {
      agencyId,
    })
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const getAgency = agencyId => {
  const url = route(
    urlBuilder('/Agencies/:agencyId', {
      agencyId,
    })
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const getAllAgencies = (filters = {}) => {
  const filter = encodeFilter(filters);
  const token = storage.get('token');
  const url = route(urlBuilder(`/Agencies`, null, { filter }));
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

export const getAgenciesCount = (filters = {}) => {
  const where = encodeFilter(filters);
  const url = route(urlBuilder('/Agencies/count', null, { where }));
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const getReportsWithMention = (userId, agencyId) => {
  const url = route(
    urlBuilder(
      '/BenchmarkUsers/:userId/reportsWithMention',
      {
        userId,
      },
      { agencyId }
    )
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};
