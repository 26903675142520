import { castArray, get } from 'lodash';

import { SET_SOURCE_SELECTION } from './';

export default (selection, source = '', formName = 'form') => (
  dispatch,
  getState
) => {
  const sourceKey = source.startsWith('__') ? source : `__${source}`;

  const {
    [formName]: {
      selectedForm: { sources: { [sourceKey]: sourceData = {} } = {} } = {},
    } = {},
  } = getState();

  if (selection) {
    castArray(selection).forEach(id => {
      const item = get(sourceData, id);

      if (item) {
        dispatch({
          type: SET_SOURCE_SELECTION,
          payload: { item, sourceKey, formName },
        });
      }
    });
  }
};
