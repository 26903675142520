import styled from 'styled-components';
import { Button, Table } from 'antd';

const StyledTable = styled(Table)`
  .ant-table table {
    border-bottom: none;
    background-color: #fff;

    color: #161616;
    font-weight: 400;
    font-size: 14px;
  }

  .ant-table-thead > tr > th {
    background: #e3e3e3;
    color: #000;
    font-weight: 600;
    font-size: 12px;
    text-align: center;

    &.ant-table-row-cell-last {
      text-align: left;
    }

    &:first-child {
      text-align: center;
    }
  }

  .ant-table-bordered .ant-table-thead > tr > th {
    &:not(:last-child) {
      border-right: 1px solid #d6d6d6;
    }
  }

  .ant-table-tbody > tr,
  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > td .text {
    white-space: initial;
    text-align: center;

    &:last-child {
      text-align: left;
    }

    &:first-child {
      text-align: center;
    }
  }

  .ant-table-tbody > tr > td {
    border-right: none;
  }

  .ant-table-footer {
    border-top: none;
    background-color: #fff;
  }

  .ant-table-tbody > tr > td.ant-table-column-sort {
    background: #fff;
  }
`;

const ShowMoreButton = styled(Button)`
  width: 175px;
  align-self: center;
  justify-self: center;
  gap: 8px;
`;

const FooterText = styled.span`
  display: flex;
  color: gray;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin: 16px 0;
  gap: 8px;
`;

export { ShowMoreButton, StyledTable, FooterText };
