import styled from 'styled-components';
import { Pagination, Col, Row } from 'antd';

export const StyledRow = styled(Row)`
  margin-top: 16px;
`;

export const StyledFilterCol = styled(Col)`
  @media (max-width: 576px) {
    margin-bottom: 10px;
  }
`;

export const StyledRowPagination = styled(Row)`
  margin-top: 16px;
`;

export const ValidationLabel = styled.div`
  height: 12px;
  text-align: left;
  color: #003bcc;
  font-size: 14px;
`;

export const StyledPagination = styled(Pagination)`
  &.ant-pagination {
    margin-bottom: 65px;
    float: right;
  }
`;
