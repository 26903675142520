import { has, isEmpty } from 'lodash';

import componentsManager from '../../services/componentsManager';
import FieldCheckbox from '../../components/FieldCheckbox/FieldCheckbox';
import getAllFieldsFlat from '../getAllFieldsFlat';

import {
  CALCULATED_FIELD_NAME,
  CALCULATED_DEFAULT_VALUE,
} from '../../constants/calculated';
import {
  EVENT_DATE,
  EVENT_DATE_LABEL,
  SEARCHABLE_BY,
} from '../../constants/constants';

export default class {
  constructor(values) {
    this.name = EVENT_DATE;
    this.label = EVENT_DATE_LABEL;
    this.fieldKey = values.key;
    this.fieldId = values.id;
    this.component = FieldCheckbox;
    this.defaultValue = false;
    this.popoverContent =
      'Limit 1 “Event Date” per form. If this field is set to true, users can search for reports by this date.';
  }

  onChange = (value, values) => {
    const { readOnly } = values;
    let otherValues = {};
    if (value && has(values, CALCULATED_FIELD_NAME)) {
      otherValues[CALCULATED_FIELD_NAME] = CALCULATED_DEFAULT_VALUE;
    }
    if (value && !isEmpty(readOnly)) {
      otherValues = {
        ...otherValues,
        readOnly: {
          ...readOnly,
          value: value ? false : readOnly.value,
        },
      };
    }
    return {
      ...values,
      [this.name]: value,
      ...otherValues,
    };
  };

  onSave = values => {
    const searchable = values[this.name];
    const { options = {} } = values;

    return {
      ...values,
      [this.name]: undefined,
      options: {
        ...options,
        [SEARCHABLE_BY]: searchable ? EVENT_DATE : undefined,
      },
    };
  };

  getInitialValue = values => {
    if (values.options === undefined) {
      return this.defaultValue;
    }

    const { [SEARCHABLE_BY]: searchable = this.defaultValue } = values.options;

    return searchable === EVENT_DATE;
  };

  isHidden = values => {
    const repeaters = componentsManager.repeatersReference;
    return Object.values(repeaters).some(tab =>
      tab.some(({ fields }) => fields[this.fieldKey])
    );
  };

  isDisabled = values => {
    const fields = componentsManager.fields;
    const { fieldsByKey } = getAllFieldsFlat(fields, this.fieldId, false);
    return Object.values(fieldsByKey).some(({ options = {} }) => {
      const { [SEARCHABLE_BY]: searchable } = options;
      return searchable === EVENT_DATE;
    });
  };
}
