const isMobileDevice = () => {
  const platform = [
    navigator?.userAgentData?.platform || '',
    navigator?.platform || '',
    navigator.userAgent || '',
  ];
  return platform.some(p => /ipad|iphone|ipod|android/.test(p.toLowerCase()));
};

export default isMobileDevice;
