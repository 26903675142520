import React from 'react';
import { Tooltip, Icon, Skeleton } from 'antd';
import { upperFirst } from 'lodash';
import { StatusCardWrapper, StatusGroup } from './StatusCard.styled';
import { CARD_THEMES } from '../constants/cardThemes';
import { STATUS_OVERDUE } from '../constants/caseFileList';

const StatusCard = ({
  title,
  count,
  loading,
  theme = 'open',
  subStatuses = [],
}) => {
  const isOverdue = title === upperFirst(STATUS_OVERDUE);
  const hasTooltip = subStatuses.length > 0 || isOverdue;
  return (
    <StatusCardWrapper
      title={
        loading ? (
          <Skeleton title={{ width: '100%' }} paragraph={false} active />
        ) : (
          <span>{count}</span>
        )
      }
      bordered={false}
      className={CARD_THEMES[theme]}
    >
      {loading && (
        <Skeleton title={{ width: '100%' }} paragraph={false} active />
      )}
      {!loading && (
        <>
          {hasTooltip ? (
            <Tooltip
              placement="bottom"
              title={
                isOverdue ? (
                  <strong>
                    Closed and Paused case files are not included in this count.
                  </strong>
                ) : (
                  <StatusGroup className="case-file-status-group">
                    {subStatuses.map(status => (
                      <span key={`status-group-${status.name}-${status.value}`}>
                        <strong>{status.name}</strong>: {status.value}
                      </span>
                    ))}
                  </StatusGroup>
                )
              }
            >
              <b>
                {title} <Icon type="info-circle" />
              </b>
            </Tooltip>
          ) : (
            <b>{title}</b>
          )}
        </>
      )}
    </StatusCardWrapper>
  );
};

export default StatusCard;
