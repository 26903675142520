import React from 'react';
import uuid from 'uuid/v4';
import { Select, Button } from 'antd';
import { Field } from 'redux-form';
import get from 'lodash/get';
import castArray from 'lodash/castArray';

import Transition from './Transition.styled';

import Input from '../../../components/input';

const SelectField = Input(Select);

const commonFieldOptions = {
  labelCol: 'ant-col-0',
  inputCol: 'ant-col-24',
  size: 'large',
};

class Workflow extends React.PureComponent {
  constructor(props) {
    super(props);

    const transitions = get(props, 'workflow.transitions', []);

    this.state = {
      transitions:
        transitions.length === 0 ? [this.newTransition()] : transitions,
    };
  }

  getFormValues = () => {
    const { workflowForm } = this.props;
    return get(workflowForm, 'values', {});
  };

  getFieldValue = (fieldName, defaultValue) => {
    const values = this.getFormValues();

    return get(values, fieldName, defaultValue);
  };

  getRoles = () => {
    const { roles = [] } = this.props;

    return roles.map(role => ({
      value: role.id + [],
      label: role.name,
    }));
  };

  getRolesEntries = roles =>
    roles.reduce((roles, role) => ({ ...roles, [role.value]: role.label }), {});

  getRolesOptions = roles =>
    roles.map(role => (
      <Select.Option key={role.value} value={role.value}>
        {role.label}
      </Select.Option>
    ));

  drawTransition = (transition, index) => {
    const { isLoading, isCustomWorkflow } = this.props;
    const roles = this.getRoles();
    const userRoles = this.getRolesOptions(roles);
    const roleEntries = this.getRolesEntries(roles);
    const prevReviewers = castArray(
      this.getFieldValue(`reviewer[${transition.prevId}]`, [])
    ).map(id => roleEntries[id]);

    return (
      <Transition key={transition.id}>
        {!transition.isFirst && !isCustomWorkflow && (
          <Button
            shape="circle-outline"
            icon="close"
            className="remove-transition-btn"
            onClick={() => this.removeTransition(transition.id, index)}
          />
        )}
        <span className="transition-number">{index + 1}</span>{' '}
        <div className="transition-content">
          <div className="transition-inline-text">
            One person from{' '}
            <div className="inline-field-wrapper">
              <Field
                type="select"
                name={`submitter[${transition.id}]`}
                label={null}
                placeholder="Select Submitter"
                component={SelectField}
                options={commonFieldOptions}
                dropdownOptions={userRoles}
                plainDropdownOptions={roles}
                defaultValue={transition.isFirst ? undefined : prevReviewers}
                plainText={!transition.isFirst || isCustomWorkflow}
                disabled={isLoading || isCustomWorkflow}
              />
            </div>{' '}
          </div>{' '}
          <div className="transition-inline-text">
            Fills out the report and submits to one person from{' '}
            <div className="inline-field-wrapper">
              <Field
                type="multiselect"
                name={`reviewer[${transition.id}]`}
                label={null}
                placeholder="Select First Reviewer"
                component={SelectField}
                options={commonFieldOptions}
                dropdownOptions={userRoles}
                mode="multiple"
                disabled={isLoading || isCustomWorkflow}
                plainText={isCustomWorkflow}
              />
            </div>
          </div>{' '}
        </div>
      </Transition>
    );
  };

  newTransition = (transition = {}) => {
    return {
      id: uuid(),
      submitter: null,
      reviewer: [],
      ...transition,
    };
  };

  addNewTransition = () => {
    this.setState(({ transitions }) => ({
      transitions: transitions.concat(this.newTransition()),
    }));
  };

  removeTransition = id => {
    this.setState(({ transitions }) => ({
      transitions: transitions.filter(transition => transition.id !== id),
    }));
  };

  mapTransitions = total => (transition, index) => ({
    ...transition,
    isFirst: index === 0,
    isLast: total === index + 1,
  });

  reduceTransitions = (reduced, transition, currentIndex, transitions) => [
    ...reduced,
    {
      ...transition,
      prevId: currentIndex > 0 ? transitions[currentIndex - 1].id : null,
    },
  ];

  getTransitions = () => [this.state.transitions, this.getRoles()];

  setTransitions = (transitions, onStateChanged) => {
    this.setState({ transitions }, onStateChanged);
  };

  render() {
    const { className, isLoading, isCustomWorkflow } = this.props;
    const { transitions } = this.state;
    const mapTransitions = this.mapTransitions(transitions.length);

    return (
      <div className={className}>
        {transitions
          .map(mapTransitions)
          .reduce(this.reduceTransitions, [])
          .map(this.drawTransition)}

        <div className="new-level-action">
          {!isCustomWorkflow && (
            <Button
              icon="plus"
              type="primary"
              size="default"
              ghost
              disabled={isLoading}
              onClick={this.addNewTransition}
            >
              Add Next Level of Review
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default Workflow;
