import callback from '../../utils/callback';
import documentManagementEndpoints from '../../../../api/documentManagement/documentManagementEndpoints';

export default (fileKey, next) => _dispatch => {
  return documentManagementEndpoints
    .getS3Document(fileKey)
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
