import React, { Fragment } from 'react';

import FieldText from '../../components/FieldText';
import FieldSelect from '../../components/FieldSelect';

import { Row, Alert } from 'antd';

import { REGEX_TYPE, INVALID_REGEX_MESSAGE } from '../../constants/fieldMasked';

export default class MaskedFieldOptions extends React.Component {
  onSelectChange = option => {
    const { onChange, value } = this.props;

    onChange && onChange({ ...value, option, regex: '', alert: undefined });
  };

  onChange = regex => {
    const { onChange, isValidRegex, value } = this.props;
    let alert = undefined;

    if (!isValidRegex(regex)) {
      alert = INVALID_REGEX_MESSAGE;
    }

    onChange && onChange({ ...value, regex, alert });
  };

  render = () => {
    const {
      selectOptions,
      value: { option, regex, alert },
    } = this.props;

    return (
      <Fragment>
        <Row>
          <FieldSelect
            selectOptions={selectOptions}
            value={option}
            onChange={this.onSelectChange}
            style={{ width: '100%' }}
            data-test="masked-select"
          ></FieldSelect>
          {option === REGEX_TYPE && (
            <span>
              &nbsp; e.g. <strong>^[0-9][0-9]-[0-9][0-9]$</strong>
            </span>
          )}
        </Row>
        <Row>
          {option === REGEX_TYPE && (
            <FieldText
              value={regex}
              onChange={this.onChange}
              style={{ width: '100%' }}
              placeholder="Regular expresion"
              data-test="masked-regex"
            ></FieldText>
          )}
          {alert && (
            <Alert
              message={alert}
              type="error"
              showIcon
              closable
              data-test="masked-alert"
            />
          )}
        </Row>
      </Fragment>
    );
  };
}
