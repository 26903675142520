import styled from 'styled-components';
import Panel, { PanelTitle, Divider } from '../common/panel';

export const StyledPanel = styled(Panel)`
  cursor: pointer;
  padding: 16px;
  margin: 0 0 5px 0;

  @media screen and (max-width: 992px) {
    padding: 10px;
  }

  @media screen and (max-width: 480px) {
    padding: 7px;
  }

  &.is-loader {
    margin-bottom: 10px;
  }

  .panel-body {
    min-height: 33px;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 2px 0 16px 0;
`;

export const StyledPanelTitle = styled(PanelTitle)`
  padding: 0;

  h2 {
    font-size: 18px;

    &.add-margin {
      margin-bottom: 14px;
    }
  }

  .case-number {
    font-weight: 400;
    color: black;
  }

  .header-loader {
    padding-top: 5px;

    &,
    svg {
      width: 200px;
      max-width: 100%;
    }
  }
`;
