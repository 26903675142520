import { createSelector } from 'reselect';
import { getCurrentUser } from './session';
import { emptyArray } from '../constants/form';

export const getSelectedUser = createSelector(
  state => state.selectedUser,
  selectedUser => selectedUser.data
);

export const getCompleteSelectedUser = createSelector(
  state => state.selectedUser,
  selectedUser => selectedUser
);

const getSelectedFieldFromProfile = (state, props) => {
  return { data: getSelectedUser(state) };
};

export const getFormFieldProfileData = createSelector(
  [
    getSelectedFieldFromProfile,
    (_, props) => props,
    state => getCurrentUser(state),
  ],
  (selectedForm, { isReviewer }, user) => {
    return {
      ...selectedForm.data,
      meta: selectedForm.meta,
      isReviewer,
      activeUser: user.id + emptyArray,
    };
  }
);
