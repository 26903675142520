import { PERMISSIONS } from '../../../../../utils/admin';

const attributeActions = {};

export default object => {
  return (operator, values) => {
    // Change for actual call with just one parameter called values
    attributeActions[PERMISSIONS.transferEmployment] = callback => {
      object.loadUserEmploymentDetail(values);
      callback();
    };

    return attributeActions[operator];
  };
};
