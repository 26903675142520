import {
  SHOW_ALL_CASES,
  SHOW_OPEN_CASES,
  SHOW_CLOSED_CASES,
  SHOW_PAUSED_CASES,
} from '../../constants/internal-affair-case-load-filter';

/**
 * @param {number} filterValue The filter value
 * @return {object} The filter object for the query param.
 */
export default function getIsOpenFilter(filterValue) {
  switch (filterValue) {
    case SHOW_OPEN_CASES:
      return { isOpen: true };
    case SHOW_CLOSED_CASES:
      return { isClosed: true };
    case SHOW_PAUSED_CASES:
      return { isPaused: true };
    case SHOW_ALL_CASES:
    default:
      return {};
  }
}
