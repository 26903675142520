import styled from 'styled-components';

const StyledSpan = styled.span`
  div,
  &.info-tooltip-wrapper {
    display: inline;
  }
`;

export default StyledSpan;
