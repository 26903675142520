import { isEmpty, pick } from 'lodash';
import moment from 'moment';

import { BENCHMARK_DATE_FORMAT } from '../../../../utils/parse-date';
import { SECTION_TYPE_FORM } from './UserProfile.constants';

const getSectionsData = sections => {
  const getFormFieldsData = section => {
    const formValues = section.formValues.reduce((values, value) => {
      values[value.attributeId] = value.value;
      return values;
    }, {});

    return section.sectionAttributes.reduce((attribute, attr) => {
      attribute[attr.attributeName] = formValues[attr.attributeId] || null;
      return attribute;
    }, {});
  };

  const getTableFieldsData = section => {
    const tableValues = section.sectionAttributes.reduce((attribute, attr) => {
      attribute[attr.attributeId] = [];
      return attribute;
    }, {});

    if (!isEmpty(tableValues)) {
      tableValues['objectValueId'] = [];
      tableValues['objectRowModified'] = [];
    }

    section.tableValues.forEach(({ objectValueId, objectAttributeValues }) => {
      tableValues['objectValueId'].push(objectValueId);
      const index = tableValues['objectValueId'].length - 1;
      objectAttributeValues.forEach(value => {
        tableValues[value.attributeId].push(value.value);
        if (value.createdBy !== undefined) {
          const modified = pick(value, [
            'createdBy',
            'createdAt',
            'lastModifiedBy',
            'updatedAt',
          ]);
          const objectRowModified = tableValues['objectRowModified'];
          if (objectRowModified.length === index) {
            objectRowModified.push(modified);
          } else if (
            (objectRowModified[index].updatedAt === undefined &&
              modified.updatedAt) ||
            moment(
              objectRowModified[index].updatedAt,
              BENCHMARK_DATE_FORMAT
            ).isBefore(moment(modified.updatedAt, BENCHMARK_DATE_FORMAT))
          ) {
            objectRowModified[index] = modified;
          }
        }
      });
    });

    const attributeValues = section.sectionAttributes.reduce(
      (attribute, attr) => {
        attribute[attr.attributeName] = tableValues[attr.attributeId];
        return attribute;
      },
      {}
    );

    if (!isEmpty(tableValues['objectValueId'])) {
      attributeValues['objectValueId'] = tableValues['objectValueId'];
      attributeValues['objectRowModified'] = tableValues['objectRowModified'];
    }

    return attributeValues;
  };

  return sections.reduce((section, sec) => {
    section[sec.sectionId] =
      sec.type === SECTION_TYPE_FORM
        ? getFormFieldsData(sec)
        : getTableFieldsData(sec);
    return section;
  }, {});
};

export default getSectionsData;
