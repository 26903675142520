import callback from '../../utils/callback';
import OrganizationEndpoints from '../../../../api/organization/organizationEndpoints';
import { orderBy, uniq } from 'lodash';

export default next => dispatch => {
  return OrganizationEndpoints.getAllOrganizationalUnitsOnHierarchy()
    .then(response => {
      const ouList = response.organizationalUnits.map(ou => {
        return { value: ou.id, label: ou.displayName };
      });

      callback(next, null, orderBy(uniq(ouList), ['label'], ['asc']));
    })
    .catch(error => {
      callback(next, error);
    });
};
