import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = () => (
  <div className="picker-loader">
    <ContentLoader
      height={25}
      width={280}
      speed={1}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="5" ry="5" width="25" height="25" />
      <rect x="45" y="0" rx="5" ry="5" width="235" height="25" />
    </ContentLoader>

    <ContentLoader
      height={25}
      width={280}
      speed={1}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="5" ry="5" width="25" height="25" />
      <rect x="45" y="0" rx="5" ry="5" width="235" height="25" />
    </ContentLoader>
  </div>
);

export default Loader;
