import React from 'react';

import createModal from 'APP_ROOT/actions/create-modal';
import showModal from 'APP_ROOT/actions/show-modal';
import { confirmId } from '../constants/modal';
import ConfirmBody from '../components/ConfirmBody';
import ConfirmHeader from '../components/ConfirmHeader';

export default props => {
  return (dispatch, getState) => {
    const title = <ConfirmHeader />;
    const body = <ConfirmBody {...props} />;
    dispatch(
      createModal({
        id: confirmId,
        visible: true,
        title,
        children: body,
      })
    );
    dispatch(showModal(confirmId));
  };
};
