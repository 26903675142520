import styled from 'styled-components';

const ProfileCardWrapper = styled.div`
  margin: 25px 15px;
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;
`;

export default ProfileCardWrapper;
