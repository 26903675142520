import { get, template, isPlainObject, padStart, first, trim } from 'lodash';
import moment from 'moment';
import { BENCHMARK_DATE_FORMAT } from 'APP_ROOT/utils/parse-date';
import getInitials from 'APP_ROOT/utils/get-initials';

export default (value, transforms) => {
  const { prefix, replace = [], format = [], compile } = transforms;

  return [
    { type: 'replace', payload: replace },
    { type: 'prefix', payload: prefix },
    { type: 'format', payload: format },
    { type: 'compile', payload: compile },
  ]
    .filter(item => {
      if (item.type === 'prefix') {
        return prefix;
      } else if (item.type === 'replace') {
        return replace;
      } else if (item.type === 'format') {
        return format;
      } else if (item.type === 'compile') {
        return compile;
      } else {
        return true;
      }
    })
    .reduce((output, transform) => {
      const { type, payload } = transform;

      if (type === 'prefix') {
        if (isPlainObject(payload)) {
          const { text, onlyInitials = false } = payload;
          const prefix = onlyInitials ? getInitials(text) : text;

          return `${prefix}${output}`;
        }

        return `${payload}${output}`;
      } else if (type === 'replace') {
        return get(transform, 'payload', []).reduce(
          (output_to_replace, transformReplace) =>
            output_to_replace.replace(
              transformReplace.from,
              transformReplace.to
            ),
          output
        );
      } else if (type === 'format') {
        const momentifiedValue = output ? moment(output).utc() : moment();
        const context = {
          dateFormat: momentifiedValue.format(BENCHMARK_DATE_FORMAT),
          fromNow: `${moment().diff(output, 'years')} years`,
        };
        return get(transform, 'payload', []).reduce(
          (output_to_replace, transformReplace) => {
            const interpolate = template(transformReplace.to);
            return interpolate(context);
          },
          output
        );
      } else if (type === 'compile') {
        const { template: textTemplate, tokens } = payload;
        const compiledTemplate = template(textTemplate);
        const compiledTokens = Object.keys(tokens).reduce((result, key) => {
          const regExp = new RegExp(tokens[key]);
          const regExpValue = output.match(regExp);
          let value = trim(first(regExpValue), '-');
          if (key === 'sequence') {
            value = padStart(value, 3, '0');
          }
          return {
            ...result,
            [key]: value,
          };
        }, {});
        return compiledTemplate(compiledTokens);
      }
      return output;
    }, value);
};
