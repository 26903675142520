import React, { Component } from 'react';
import cx from 'classnames';

import { DropTarget } from 'react-dnd';
import { Modal } from 'antd';

import Styled from './PickableZone.styled';
import componentsManager from '../../services/componentsManager';
import {
  FIELD,
  ROW,
  COLUMN,
  FIELD_GROUP,
  INJURIES_BODY,
  SECTION_WRAPPER,
  SECTION,
  GROUP,
  REPEATER,
  TAB,
  UPLOAD,
  SINGLE_UPLOAD,
} from 'APP_ROOT/constants/layoutComponentTypes';
import {
  DURATION,
  DATE,
  DATE_TIME,
  MATH,
  NUMBER,
  SELECT,
} from '../../../../constants/fieldTypes';
import { SEARCHABLE_BY, EVENT_DATE } from '../../constants/constants';
import moveComponentDurationRelated, {
  getComponentName,
  isMathRelated,
} from './utils';
import { isEmpty } from 'lodash';

const { confirm, info } = Modal;

class PickableZone extends Component {
  render() {
    const { children, connectDropTarget, isOver, canDrop, id } = this.props;

    return connectDropTarget(
      <div id={id}>
        <Styled
          className={cx('pickable-zone', {
            'can-drop': canDrop,
            'is-over': isOver,
          })}
        >
          {children}
        </Styled>
      </div>
    );
  }
}

const calculatePosition = (props, monitor) => {
  const element = document.getElementById(props.id);
  let position;
  if (element) {
    const { length } = props.children || {};
    if (length) {
      // calculate coordinates to drop above or below element
      const clientOffset = monitor.getClientOffset();
      const elementOffset = element.getBoundingClientRect();

      const sizePerComponent = elementOffset.height / length;
      const elementPos = clientOffset.y - elementOffset.top;

      position = Math.round(elementPos / sizePerComponent);
      position = length < position ? length : position;
    }
  }
  return position;
};

const resetEventDate = component => {
  const { options, ...rest } = component;
  return {
    ...rest,
    options: {
      ...options,
      [SEARCHABLE_BY]: undefined,
    },
  };
};

const moveInOutRepeater = (component, props, position, message) => {
  const rep1 = componentsManager.findItemInRepeatersFields(props.id);
  const rep2 = componentsManager.findItemInRepeatersFields(component.id);
  if (rep1 !== rep2) {
    const msg =
      message ||
      'This field cannot be moved in or out of a repeater because it is related to other field(s), remove the relation first.';
    info({
      title: 'Action not allowed',
      content: msg,
    });
  } else {
    componentsManager.moveComponent(component, props.id, position);
  }
};

const moveDuration = (component, props, position) => {
  const { options: { initial, final } = {} } = component;
  if (!isEmpty(initial) || !isEmpty(final)) {
    moveInOutRepeater(component, props, position);
  } else {
    componentsManager.moveComponent(component, props.id, position);
  }
};

const moveMathRelated = (component, props, position) => {
  const { options: { operands } = {} } = component;
  if (!isEmpty(operands)) {
    moveInOutRepeater(component, props, position);
  } else {
    const related = isMathRelated(component);
    if (related) {
      const fieldName = getComponentName(component);
      const relatedName = getComponentName(related);
      const msg = (
        <div>
          <p>
            Field <strong>{fieldName}</strong> cannot be moved into a repeater,
            because it is related to <strong>{relatedName}</strong>
          </p>
          <p>Remove relation with {relatedName} first</p>
        </div>
      );
      moveInOutRepeater(component, props, position, msg);
    } else {
      componentsManager.moveComponent(component, props.id, position);
    }
  }
};

const moveDate = (component, props, position) => {
  const {
    options: { [SEARCHABLE_BY]: searchable },
  } = component;
  const repeater = componentsManager.findItemInRepeatersFields(props.id);
  if (searchable === EVENT_DATE && repeater) {
    confirm({
      title: 'Are you sure you want to move this field?',
      content:
        'Event Date cannot be inside a repeater, moving it will reset event date property.',
      onOk() {
        moveComponentDurationRelated(
          resetEventDate(component),
          props,
          position
        );
      },
    });
  } else {
    moveComponentDurationRelated(component, props, position);
  }
};

const moveComponent = (component, props, position) => {
  switch (component.field_type) {
    case DURATION:
      moveDuration(component, props, position);
      break;
    case DATE:
    case DATE_TIME:
      moveDate(component, props, position);
      break;
    case MATH:
    case NUMBER:
    case SELECT:
      moveMathRelated(component, props, position);
      break;
    default:
      componentsManager.moveComponent(component, props.id, position);
      break;
  }
};

const canDropInColumn = (props, monitor) => {
  if (props.children) {
    const { type } = monitor.getItem();
    if (props.children.length !== 0) {
      return false;
    }
    if (type === UPLOAD) {
      // upload component cannot be inside a repeater
      const repeaterKey = componentsManager.findItemInRepeatersFields(props.id);
      return !repeaterKey;
    }
    return true;
  }
  // only fields have field_type and we only allow fields here
  const { field_type } = monitor.getItem();
  return field_type !== undefined;
};

export default DropTarget(
  params => {
    switch (params.type) {
      case COLUMN:
        return [FIELD, FIELD_GROUP, INJURIES_BODY, UPLOAD, SINGLE_UPLOAD];
      case SECTION:
        return [ROW, GROUP, REPEATER];
      case REPEATER:
        return [GROUP, SECTION, SECTION_WRAPPER];
      case GROUP:
      case TAB:
        return [GROUP, SECTION, SECTION_WRAPPER, REPEATER];
      case SECTION_WRAPPER:
        return [SECTION];
      default:
        return '';
    }
  },
  {
    canDrop: (props, monitor) => {
      if (props.type === COLUMN) {
        return canDropInColumn(props, monitor);
      } else if ([GROUP, SECTION, SECTION_WRAPPER].includes(props.type)) {
        const { type } = monitor.getItem();
        if (type === REPEATER) {
          const repeaterKey = componentsManager.findItemInRepeatersFields(
            props.id
          );
          return !componentsManager.isNestedRepeater(repeaterKey);
        }
      }
      // for all the other types
      return true;
    },
    drop: (props, monitor) => {
      const { toAdd = false, ...component } = monitor.getItem();
      const position = calculatePosition(props, monitor);
      if (toAdd) {
        let parentRepeater;
        // are we adding a nested repeater?
        if ([GROUP, SECTION, SECTION_WRAPPER].includes(props.type)) {
          const { type } = monitor.getItem();
          if (type === REPEATER) {
            parentRepeater = componentsManager.findItemInRepeatersFields(
              props.id
            );
          }
        }
        componentsManager.addComponent(
          component,
          props.id,
          position,
          parentRepeater
        );
      } else {
        moveComponent(component, props, position);
      }
    },
  },
  (connect, monitor) => {
    return {
      connectDropTarget: connect.dropTarget(),
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    };
  }
)(PickableZone);
