import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AutoComplete from 'antd/lib/auto-complete';
import Input from 'antd/lib/input';
import Icon from 'antd/lib/icon';
import Table from 'antd/lib/table';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import message from 'antd/lib/message';
import Pagination from 'antd/lib/pagination';
import isEmpty from 'lodash/isEmpty';
import ContentLoader from 'react-content-loader';

import parseDate, { BENCHMARK_DATE_FORMAT } from '../../utils/parse-date';
import {
  SHOW_OPEN_CASES,
  SHOW_CLOSED_CASES,
  SHOW_PAUSED_CASES,
} from '../../constants/internal-affair-case-load-filter';
import transformFormToNormalizedTimeline from '../../utils/transform-form-to-normalized-timeline';

import MainTitle from '../common/main-title';
import StyledRowPagination from '../styled-row-pagination';
import PauseResumeCaseLoadModal from '../internal-affairs/pause-resume-caseload-modal';
import withModal from '../common/modal/base';
import ModalTitle from '../common/modal/title';
import ModalBody from '../common/modal/body';
import FormHistoryTimeline from '../form-history-timeline';

import getAllegationColumnText from './get-allegation-column-text';
import getIsOpenFilter from './get-is-open-filter';

const Column = Table.Column;

const StyledRow = styled(Row)`
  margin-top: 16px;
`;

const StyledCol = styled(Col)`
  padding: 0 0 24px;
`;

const autoCompleteDataSource = [
  { value: SHOW_OPEN_CASES, text: 'Open' },
  { value: SHOW_PAUSED_CASES, text: 'Paused' },
  { value: SHOW_CLOSED_CASES, text: 'Closed' },
];

/**
 * @param {object[]} dataSource A list of case load items.
 * @return {object[]} A list of formatted cse load items, eg. field with date have been formatted.
 */
function formatCaseLoadDataSource(dataSource, agencyTZ) {
  return dataSource.map(({ opened, status, ...restOfcaseLoad }) => ({
    opened: parseDate(opened, agencyTZ, BENCHMARK_DATE_FORMAT),
    status: `${status.status} on ${parseDate(
      status.date,
      agencyTZ,
      BENCHMARK_DATE_FORMAT
    )}`,
    ...restOfcaseLoad,
  }));
}

class InternalAffairCaseLoad extends withModal(Component) {
  static propTypes = {
    /**
     * A list of case load objects.
     */
    dataSource: PropTypes.arrayOf(
      PropTypes.shape({
        dateOpen: PropTypes.string,
      })
    ),
    /**
     * A timezone string.
     */
    agencyTZ: PropTypes.string.isRequired,
    perPage: PropTypes.number,
    selectedCaseLoad: PropTypes.shape({
      dateOpen: PropTypes.string,
    }),
    page: PropTypes.number,
    getReportHistory: PropTypes.func,
  };

  static defaultProps = {
    dataSource: [],
    perPage: 10,
    page: 1,
    selectedCaseLoad: {},
  };

  getTableRowKey = ({ formSubmissionId, investigatorId }) => {
    return `${formSubmissionId}-${investigatorId}`;
  };

  onFilterChange = value => {
    const { onFilterChange } = this.props;
    onFilterChange(getIsOpenFilter(value));
  };

  viewPauseHistory = reportId => {
    const { getReportHistory } = this.props;

    const Title = (
      <ModalTitle>
        <span>Transaction Timeline</span>
      </ModalTitle>
    );

    const Body = (
      <ModalBody>
        <ContentLoader
          height={120}
          width={400}
          speed={2}
          primaryColor={'#f3f3f3'}
          secondaryColor={'#ecebeb'}
          className="loader-animation"
        >
          <circle cx="15" cy="15" r="10.16" />
          <rect x="35" y="10" rx="5" ry="5" width="90" height="10" />
          <rect x="135" y="10" rx="5" ry="5" width="20" height="10" />
          <rect x="162" y="10" rx="5" ry="5" width="30" height="10" />
          <rect x="35" y="30" rx="5" ry="5" width="115" height="10" />
          <rect x="160" y="30" rx="5" ry="5" width="180" height="10" />

          <circle cx="15" cy="75" r="10.16" />
          <rect x="35" y="70" rx="5" ry="5" width="90" height="10" />
          <rect x="135" y="70" rx="5" ry="5" width="20" height="10" />
          <rect x="162" y="70" rx="5" ry="5" width="30" height="10" />
          <rect x="35" y="90" rx="5" ry="5" width="115" height="10" />
          <rect x="160" y="90" rx="5" ry="5" width="180" height="10" />
        </ContentLoader>
      </ModalBody>
    );

    const updateModal = this.modalId ? this.updateModal : this.createModal;

    updateModal({
      visible: true,
      title: Title,
      children: Body,
    });

    this.showModal();

    getReportHistory(reportId, (error, report) => {
      if (error) {
        message.error(
          'Something occured while fetching this report transaction history. Please try again later.'
        );

        this.hideModal();

        return false;
      }

      this.showTimeline(report);
    });
  };

  showTimeline = report => {
    const { timezone } = this.props;

    const normalizedRecord = transformFormToNormalizedTimeline(
      report,
      timezone,
      false
    );

    const Body = (
      <ModalBody>
        <FormHistoryTimeline {...normalizedRecord} />
      </ModalBody>
    );

    this.updateModal({
      children: Body,
    });
  };

  /**
   * @param {object} record
   * @return {boolean}
   */
  onActionPauseClick = record => this.setState({ selectedCaseLoad: record });

  onSeeDetailClick = reportId => {
    this.viewPauseHistory(reportId);
  };

  /**
   * @param {object} record A case load object.
   * @param {function} onActionClick The function to execute when clicking.
   * @return {function} The component to render the link
   */
  pauseCaseLoadAction = (record, onActionClick, isLoading) => {
    const label = record.isPaused ? 'Resume' : 'Pause';
    const altText = record.isPaused
      ? 'resume investigation'
      : 'pause investigation';
    if (record.isClosed) {
      return '';
    }
    if (
      (record.canPause && !record.isPaused) ||
      (record.canResume && record.isPaused)
    ) {
      return this.pauseOrResumeButton(
        record,
        label,
        altText,
        onActionClick,
        isLoading
      );
    } else {
      return '';
    }
  };

  /**
   * @param {object} record A case load object.
   * @param {function} onSeeDetailClick The function to execute when clicking.
   * @return {function} The component to render the link
   */
  seeCaseLoadDetail = record => {
    const { daysOpen = '0', pauseHistory = [], isClosed = false } = record;
    const filteredPauseHistory = pauseHistory.filter(
      item => !['start', 'stop'].includes(item.action)
    );
    const canSeeDetails = !isClosed && !isEmpty(filteredPauseHistory);
    return (
      <div>
        <p>{daysOpen}</p>
        {canSeeDetails && (
          <p>
            <a
              onClick={event => this.onSeeDetailClick(record.formSubmissionId)}
            >
              See Details
            </a>
          </p>
        )}
      </div>
    );
  };

  pauseOrResumeButton = (record, label, altText, onActionClick, isLoading) => (
    <a
      href={`#${record.opened}`}
      alt={altText}
      disabled={isLoading}
      onClick={e => {
        e.preventDefault();
        onActionClick(record);
      }}
    >
      {label} {isLoading && <Icon type="loading" />}
    </a>
  );

  render() {
    const {
      dataSource,
      agencyTZ,
      perPage,
      page,
      onChangePage,
      total,
      agencyReasons,
      onPauseResumeModalSubmit,
      onCancel,
      selectedCaseLoad,
      onActionPauseClick,
      confirmLoading,
      loading,
    } = this.props;
    const formattedDataSource = formatCaseLoadDataSource(dataSource, agencyTZ);
    const isModalVisible = !isEmpty(selectedCaseLoad);
    const isLoading = id => confirmLoading[id];

    return (
      <div>
        <StyledRow>
          <Col span={18} className="text-left">
            <MainTitle>Internal Affairs</MainTitle>
          </Col>
          <StyledCol span={6}>
            <AutoComplete
              size="default"
              style={{ width: '100%' }}
              allowClear
              dataSource={autoCompleteDataSource}
              onChange={this.onFilterChange}
            >
              <Input placeholder="Type" suffix={<Icon type="filter" />} />
            </AutoComplete>
          </StyledCol>
        </StyledRow>

        <StyledRow>
          <Col span={24}>
            <div className="responsive-table">
              <Table
                dataSource={formattedDataSource}
                bordered
                rowKey={this.getTableRowKey}
                pagination={false}
                loading={loading}
              >
                <Column title="Date Opened" dataIndex="opened" key="opened" />
                <Column
                  title="Allegations"
                  dataIndex="allegations"
                  key="allegations"
                  width="30%"
                  render={(allegations, record) =>
                    getAllegationColumnText(
                      allegations,
                      record.formSubmissionId,
                      record
                    )
                  }
                />
                <Column
                  title="Investigator"
                  dataIndex="investigator"
                  key="investigator"
                />
                <Column title="Status" dataIndex="status" key="status" />
                <Column
                  title="Days Opened"
                  dataIndex="daysOpen"
                  key="daysOpen"
                  render={(text, record) => this.seeCaseLoadDetail(record)}
                />
                <Column
                  title="Actions"
                  key="actions"
                  render={(text, record) =>
                    this.pauseCaseLoadAction(
                      record,
                      onActionPauseClick,
                      isLoading(record.formSubmissionId)
                    )
                  }
                />
              </Table>
            </div>
          </Col>
        </StyledRow>

        <StyledRowPagination>
          <Col span={24} className="text-right">
            <Pagination
              defaultCurrent={1}
              current={page}
              total={total}
              pageSize={perPage}
              onChange={onChangePage}
            />
          </Col>
        </StyledRowPagination>

        <PauseResumeCaseLoadModal
          visible={isModalVisible}
          onCancel={onCancel}
          formattedCaseLoad={selectedCaseLoad}
          agencyReasons={agencyReasons}
          okText="Submit"
          onSubmit={onPauseResumeModalSubmit}
          confirmLoading={
            isModalVisible
              ? isLoading(selectedCaseLoad.formSubmissionId)
              : false
          }
        />
      </div>
    );
  }
}

export default InternalAffairCaseLoad;
