import { isEmpty } from 'lodash';
import ContentFieldOption from './getContentFieldOptions';

export default class {
  constructor() {
    this.name = 'content';
    this.label = '';
    this.component = ContentFieldOption;
    this.defaultValue = '';
  }

  isValid = values => {
    const content = values[this.name];

    return !isEmpty(content);
  };

  onChange = (value, values) => {
    return {
      ...values,
      [this.name]: value,
    };
  };

  onSave = values => {
    const content = values[this.name];
    const { options = {} } = values;
    const {
      labelCol = { md: 0, sm: 0, xs: 0 },
      wrapperCol = { md: 24, sm: 24, xs: 24 },
    } = options;

    return {
      ...values,
      [this.name]: undefined,
      options: {
        ...options,
        labelCol,
        wrapperCol,
        content,
      },
    };
  };

  getInitialValue = values => {
    if (values.options === undefined) {
      return this.defaultValue;
    }

    const { content = this.defaultValue } = values.options;

    return content;
  };
}
