import React from 'react';
import { Route } from 'react-router';

import {
  ExceptionalPerformance,
  NeedingReview,
  UnderCare,
} from '../../containers/actions';
import Protected from '../../containers/protected';
import { PERMISSIONS } from '../../utils/admin';

const actionsRoutes = [
  <Route
    path="/exceptional-performance/:id"
    name="exceptionalPerformance"
    key="exceptionalPerformance"
    component={Protected(ExceptionalPerformance, [
      PERMISSIONS.viewAllUserProfiles,
    ])}
  />,
  <Route
    path="/needing-review/:id"
    name="needingReview"
    key="needingReview"
    component={Protected(NeedingReview, [PERMISSIONS.viewAllUserProfiles])}
  />,
  <Route
    path="/under-care/:id"
    name="underCare"
    key="underCare"
    component={Protected(UnderCare, [PERMISSIONS.viewAllUserProfiles])}
  />,
];

export default actionsRoutes;
