import React, { Fragment } from 'react';
import { Row, Col, Input as InputField, DatePicker } from 'antd';
import { Field } from 'redux-form';
import { get } from 'lodash';

import Section from '../../../../components/form-viewer/styled/section';
import { StyledPanelTitle } from '../../../../components/custom-sections/sections/Sections.styled';
import Switch from '../../../../components/form-viewer/styled/input-switch';
import Input from '../../components/input';
import If from '../../../../components/utils/ConditionalRender';

const SwitchField = Input(Switch);
const TextField = Input(InputField);
const DateField = Input(DatePicker);
const commonFieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
  inputCol: 'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
};
const UserStatus = ({
  profileForm,
  fetchingAgency = false,
  onToggleUserState,
  canDelete = true,
  shouldBeReadOnlyField,
  shouldSeeAnyField,
  getFieldLabel,
  timezone,
  benchmarkDateFormat,
  disabledDate,
  formatDate,
  isOwnProfile,
}) => {
  const dateOptions = {
    format: benchmarkDateFormat,
    disabledDate: disabledDate,
    style: { width: '100%' },
  };

  return (
    <Section isLast>
      <If condition={canDelete && shouldSeeAnyField('isDeleted')}>
        <Fragment>
          <Row>
            <Col className="gutter-row" span={24}>
              <StyledPanelTitle size="large">User Status</StyledPanelTitle>
            </Col>
          </Row>
          <If condition={!isOwnProfile}>
            <Row>
              <Col xs={24} sm={18} md={12} lg={12}>
                <Field
                  type="text"
                  name="isDeleted"
                  data-test="isDeleted"
                  label={getFieldLabel('isDeleted')}
                  component={SwitchField}
                  inputProps={{
                    checked: !get(profileForm, 'values.isDeleted'),
                    size: 'large',
                    checkedChildren: 'Yes',
                    unCheckedChildren: 'No',
                  }}
                  options={Object.assign({}, commonFieldOptions, {
                    labelCol:
                      'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
                    inputCol:
                      'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
                    mustConfirmChange: !get(profileForm, 'values.isDeleted'),
                    promptMessage: [
                      'Deactivating this user profile removes it from website access, and you will be returned to the People list.',
                      'The person will no longer be able to access the system. You must contact Benchmark Support to reactivate the user.',
                      'Do you still want to continue?',
                    ],
                    promptConfirmText: 'Yes',
                    promptCancelText: 'Cancel',
                    promptOnConfirm: () => {
                      onToggleUserState({
                        ...get(profileForm, 'values'),
                        isDeleted: true,
                      });
                    },
                  })}
                  disabled={
                    fetchingAgency ||
                    !canDelete ||
                    shouldBeReadOnlyField(['isDeleted'])
                  }
                  onChange={(e, nextValue) => {
                    nextValue &&
                      onToggleUserState({
                        ...get(profileForm, 'values'),
                        isDeleted: !get(profileForm, 'values.isDeleted'),
                      });
                  }}
                />
              </Col>
            </Row>
          </If>
        </Fragment>
      </If>
      <If condition={shouldSeeAnyField(['dutyStatus', 'terminationDate'])}>
        <Row>
          <If condition={shouldSeeAnyField('dutyStatus')}>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="text"
                name="dutyStatus"
                data-test="dutyStatus"
                label={getFieldLabel('dutyStatus')}
                component={TextField}
                options={commonFieldOptions}
                disabled={fetchingAgency}
                plainText={shouldBeReadOnlyField(['dutyStatus'])}
              />
            </Col>
          </If>
          <If condition={shouldSeeAnyField('terminationDate')}>
            <Col xs={24} sm={12} md={12} lg={12}>
              <Field
                type="datepicker"
                name="terminationDate"
                data-test="terminationDate"
                label={getFieldLabel('terminationDate')}
                component={DateField}
                inputProps={dateOptions}
                options={commonFieldOptions}
                format={value =>
                  value == null ? '' : formatDate(value, timezone)
                }
                disabled={fetchingAgency}
                plainText={shouldBeReadOnlyField(['terminationDate'])}
              />
            </Col>
          </If>
        </Row>
      </If>
    </Section>
  );
};

export default UserStatus;
