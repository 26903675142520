import { get } from 'lodash';

import transformConditions from './transformConditions';
import getValidationsDefault from './getValidationsDefault';

import { SELECT, TEXT } from 'APP_ROOT/constants/fieldTypes';

const updateFieldValidationRule = (rawValidations, rules, field, isNote) => {
  const {
    required = false,
    encrypt = false,
    conditions,
    validations,
  } = rawValidations;
  const { key, type, field_type, enumRef } = field;

  let validationType;
  if ([SELECT].includes(field_type) && get(field, 'options.populateFrom')) {
    // this is a hack for interactinoWho, since it uses populateFrom for values
    // and doesn't have enumRef values in enum section, it needs to set 'string'
    // as type in validations section, otherwise an error is trigger when report
    // validates the input saying that "value should be of ''"
    validationType = {
      type,
      field_type: TEXT,
    };
  } else {
    validationType = { type, field_type };
  }
  const [rule = {}, ...untouchedRules] = rules[key] || [];
  const newConditions = transformConditions(
    conditions,
    {
      fieldKey: key,
      required,
      encrypt,
    },
    isNote
  );

  const {
    keepInData,
    mustExist,
    optional,
    required: oldRequired,
    encrypt: oldEncrypt,
    conditions: oldConditions,
    ...restOfTheRule
  } = rule;

  return {
    ...rules,
    [key]: [
      {
        enumRef,
        ...restOfTheRule,
        ...newConditions,
        ...{
          ...getValidationsDefault(validationType),
          ...validations,
        },
      },
      ...untouchedRules,
    ],
  };
};

export default updateFieldValidationRule;
