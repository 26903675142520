import { notification } from 'antd';
import parseDate, { BENCHMARK_DATE_FORMAT } from '../../../../utils/parse-date';

export const FORM_NAME = 'manageCaseFileType';

export const NOTIFICATION_ERROR = 'error';
export const NOTIFICATION_SUCCESS = 'success';

export const MAXIMUM_DAYS_VALUE = 999999;
export const MINIMUM_DAYS_VALUE = 2;

export const CLOSED_STATUS = 'CLOSED';
export const OPEN_STATUS = 'OPEN';

// ERROR HANDLING
export function showError(err) {
  const message = err.message || 'API response is not ok';
  showNotification(NOTIFICATION_ERROR, 'Error', message);
}

export function showSuccess(message) {
  showNotification(NOTIFICATION_SUCCESS, 'Success', message);
}

export function showNotification(type, message, description) {
  return notification[type]({ message, description });
}

// TABLE CONFIGURATION
export function renderDate(data, timezone) {
  if (data) {
    const date = parseDate(data, timezone, BENCHMARK_DATE_FORMAT);
    return `${date}`;
  }
  return '';
}

export function sortAlphabetically(a, b, field) {
  if (a[field] < b[field]) {
    return -1;
  }
  if (a[field] > b[field]) {
    return 1;
  }
  return 0;
}

export function sortByCreationDate(a, b) {
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}
