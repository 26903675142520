import { omit } from 'lodash';
import {
  FETCH_FORM_PARENT_REQUEST,
  FETCH_FORM_PARENT_SUCCESS,
  FETCH_FORM_PARENT_ERROR,
} from '../../actions';

import selectedFormInitialState from './state/selected-form';

const events = {
  [FETCH_FORM_PARENT_REQUEST]: state => {
    const selectedForm = {
      ...state.selectedForm,
      meta: {
        ...state.selectedForm.meta,
        isParentLoading: true,
        formParent: {},
      },
      data: {
        ...state.selectedForm.data,
        __formParent: {},
      },
    };
    return {
      ...state,
      selectedForm,
    };
  },
  [FETCH_FORM_PARENT_SUCCESS]: (state, { payload: formParent = {} }) => {
    const selectedForm = {
      ...state.selectedForm,
      meta: {
        ...state.selectedForm.meta,
        isParentLoading: false,
        formParent: omit(formParent, ['data']),
      },
      data: {
        ...state.selectedForm.data,
        __formParent: formParent.data,
      },
    };
    return {
      ...state,
      selectedForm,
    };
  },
  [FETCH_FORM_PARENT_ERROR]: (state, { payload: { error, id } }) => {
    const selectedForm = {
      ...selectedFormInitialState,
      meta: {
        ...state.meta,
        isParentLoading: false,
        parentError: error,
      },
    };
    return {
      ...state,
      selectedForm,
    };
  },
};

export default events;
