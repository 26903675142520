import React from 'react';
import { Tooltip } from 'antd';

const ConditionalDueDate = ({ hasDueDatePassed, message, children }) => {
  return hasDueDatePassed ? (
    <Tooltip title={message}>{children}</Tooltip>
  ) : (
    <>{children}</>
  );
};

export default ConditionalDueDate;
