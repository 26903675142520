import {
  UPLOAD,
  FIELD,
  REPEATER,
} from 'APP_ROOT/constants/layoutComponentTypes';

/**
 * to get all the fields from the form structure, this method is
 * intended to be used as a param for a reduce call
 * @param {Object} obj acumulator
 * @param {Object} field current field to process
 * @returns {Object} acumulator with the field added like { fieledKey: field }
 */
const getFormFields = (obj, field) => {
  if (field.type === FIELD) {
    obj.fieldsByKey[field.key] = field;
  } else if (field.properties) {
    if ([REPEATER, UPLOAD].includes(field.type)) {
      const response = field.properties.reduce(getFormFields, {
        fieldsByKey: {},
        repeatersByKey: {},
      });
      obj.repeatersByKey = {
        ...obj.repeatersByKey,
        ...response.repeatersByKey,
        [field.key]: response.fieldsByKey,
      };
    } else {
      obj = field.properties.reduce(getFormFields, obj);
    }
  }
  return obj;
};

export default getFormFields;
