import { DATE, DATE_TIME, CHECKBOX } from 'APP_ROOT/constants/fieldTypes';

const isFieldOfType = (props, dataType) => {
  const { dataKey = '', field_type: type = '' } = props;

  switch (dataType) {
    case 'date':
      return type === DATE || type === DATE_TIME;
    case 'phone':
      return dataKey.toLowerCase().includes('phone') && type === 'masked';
    case 'boolean':
      return type === CHECKBOX;
    default:
      return false;
  }
};

export default isFieldOfType;
