import React, { Component } from 'react';
import { Button } from 'antd';
import './days-to-follow-up-button.css';

class ModifyDaysToFollowUpButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { disabled, onClick, operator } = this.props;
    return (
      <>
        <Button
          ghost
          size="small"
          type="primary"
          shape="circle"
          icon={operator === 'add' ? 'plus' : 'minus'}
          className="operator-button"
          disabled={disabled}
          onClick={_ => onClick(operator)}
        />
      </>
    );
  }
}

export default ModifyDaysToFollowUpButton;
