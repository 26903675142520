import trainingServiceEndpoints from '../../../../api/trainingServiceEndpoints/trainingServiceEndpoints';

const updateUserRegistration = ({
  sessionId,
  userId,
  setLoading,
  setRegistrationResult,
}) => {
  setLoading(true);
  trainingServiceEndpoints
    .updateUserRegistration(sessionId, userId, { action: 'complete' })
    .then(response => {
      setRegistrationResult(true);
      setLoading(false);
    })
    .catch(error => {
      setRegistrationResult(false);
      setLoading(false);
    });
};

export default updateUserRegistration;
