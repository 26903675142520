import React, { useState, useRef } from 'react';
import { Button, Icon, Input } from 'antd';

import { StyledCol, StyledModal, StyledRow } from './index.styled';
import ModalContent from './ModalContent';
import { pasteFromClipboard, createRole } from './utils';

const RolesModal = ({
  isModalVisible,
  onModalVisibleAction,
  agencyProfile,
  agencyId,
  getRoles = () => {},
}) => {
  const [inputRoleValue, setInputRoleValue] = useState('');
  const [pasteButtonState, setPasteButtonState] = useState(false);
  const [isTenantLoading, setIsTenantLoading] = useState(true);
  const [isCreateButtonLoading, setIsCreateButtonLoading] = useState(false);

  const inputArea = useRef(null);

  const handleChange = e => {
    setInputRoleValue(e.target.value);
    setPasteButtonState(!!e.target.value);
  };
  const inputAction = (
    inputAreaRef,
    newInputRoleValue = '',
    newPasteButtonState = false
  ) => {
    setInputRoleValue(newInputRoleValue);
    setPasteButtonState(newPasteButtonState);
    inputAreaRef.current.focus();
  };

  const onCloseModal = () => {
    onModalVisibleAction(false);
    setInputRoleValue('');
    setPasteButtonState(false);
  };
  const onClickPasteButton = async () => {
    const clipboardValue = await pasteFromClipboard();
    const pasteButtonState = true;
    inputAction(inputArea, clipboardValue, pasteButtonState);
  };
  const onConfirmClean = () => inputAction(inputArea);
  const onCancelClean = () => inputArea.current.focus();

  const onCreatingRole = (isLoading = true) => {
    if (!isLoading) {
      setInputRoleValue('');
      onModalVisibleAction(isLoading);
      setPasteButtonState(isLoading);
      getRoles(agencyId);
    }
    setIsCreateButtonLoading(isLoading);
  };

  const onCreatingRoleError = (isLoading = false) => {
    setIsCreateButtonLoading(isLoading);
  };

  const onCreateButtonClick = ({
    inputRoleValue,
    inputAreaRef,
    onCreatingRole,
    onCreatingRoleError,
    agencyId,
  }) => {
    createRole({
      inputRoleValue,
      inputAreaRef,
      onCreatingRole,
      onCreatingRoleError,
      agencyId,
    });
  };

  const getTenantIdStatus = tenantIdStatus => {
    setIsTenantLoading(tenantIdStatus);
  };

  const footer = [
    <Button key="cancel" onClick={() => onCloseModal()}>
      Cancel
    </Button>,
    <Button
      key="create"
      type="primary"
      onClick={() =>
        onCreateButtonClick({
          inputRoleValue,
          inputAreaRef: inputArea,
          onCreatingRole,
          onCreatingRoleError,
          agencyId,
        })
      }
      disabled={!pasteButtonState}
      loading={isCreateButtonLoading}
    >
      Create
    </Button>,
  ];

  return (
    <StyledModal
      title="Create New Role"
      visible={isModalVisible}
      onCancel={() => onCloseModal()}
      footer={footer}
      maskClosable={false}
    >
      <ModalContent
        agencyId={agencyId}
        agencyProfile={agencyProfile}
        isTenantLoading={isTenantLoading}
        onClickPasteButton={onClickPasteButton}
        pasteButtonState={pasteButtonState}
        onConfirmClean={onConfirmClean}
        onCancelClean={onCancelClean}
        getTenantIdStatus={getTenantIdStatus}
      />
      <StyledRow>
        <StyledCol>
          <Input.TextArea
            ref={inputArea}
            onChange={handleChange}
            autoSize={{ minRows: 8, maxRows: 8 }}
            addonAfter={<Icon type="form" />}
            value={inputRoleValue}
          />
        </StyledCol>
      </StyledRow>
    </StyledModal>
  );
};

export default RolesModal;
