import React, { Component } from 'react';

export default WrapperComponent =>
  class WithFlexibleLayout extends Component {
    state = {
      height: global.innerHeight,
    };

    componentDidMount() {
      global.addEventListener('resize', this.updateHeight);
    }

    componentWillUnmount() {
      global.removeEventListener('resize', this.updateHeight);
    }

    updateHeight = () => {
      this.setState({ height: global.innerHeight });
    };

    render() {
      const { height } = this.state;

      return (
        <div style={{ minHeight: '100vh', height: `${height}px` }}>
          <WrapperComponent {...this.props} />
        </div>
      );
    }
  };
