export const auditLogsColums = [
  {
    title: 'Data Section',
    dataIndex: 'dataSection',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.dataSection.localeCompare(b.dataSection),
  },
  {
    title: 'Table Name',
    dataIndex: 'tableName',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.tableName.localeCompare(b.tableName),
  },
  {
    title: 'Executed By',
    dataIndex: 'executedByName',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.executedByName.localeCompare(b.executedByName),
  },
  {
    title: 'Action Type',
    dataIndex: 'actionType',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.actionType.localeCompare(b.actionType),
  },
  {
    title: 'User',
    dataIndex: 'userRecordName',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.userRecordName.localeCompare(b.userRecordName),
  },
  {
    title: 'Timestamp',
    dataIndex: 'timestamp',
    width: '14',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.timestamp.localeCompare(b.timestamp),
  },
  {
    title: 'Details',
    dataIndex: 'details',
    width: '14',
    defaultSortOrder: 'ascend',
  },
];
