import React from 'react';
import ContentLoader from 'react-content-loader';
import { Row, Col } from 'antd';

import SectionWrapper from 'APP_ROOT/components/form-viewer/styled/section-wrapper';

const LoaderOfficerProfile = () => (
  <Row>
    <Col md={12}>
      <SectionWrapper>
        <ContentLoader
          height={100}
          width={600}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="22" y="10" rx="4" ry="4" width="150" height="20" />
          <rect x="22" y="45" rx="4" ry="4" width="100" height="14" />
          <rect x="22" y="70" rx="4" ry="4" width="100" height="14" />
        </ContentLoader>
      </SectionWrapper>
    </Col>
  </Row>
);
export default LoaderOfficerProfile;
