import { has, get, isPlainObject, isEmpty } from 'lodash';

import getOverrides from 'APP_ROOT/utils/get-field-overrides';
import applyReadOnlyTransform from 'APP_ROOT/utils/applyReadOnlyTransform';
import { getValueFromPopulatedSource } from 'APP_ROOT/utils/conditionals';
import getPresenceUsage from 'APP_ROOT/utils/getPresenceUsage';
import { formatInchesToFtInch } from 'APP_ROOT/utils/units-transformation';
import getGroupData from './getGroupData';
import getIsReadOnly from './getIsReadOnly';
import mapOfficerNames from './mapOfficerNames';
import { DATE } from '../../../../constants/fieldTypes';
import {
  momentWithTZ,
  BENCHMARK_DATE_FORMAT,
} from '../../../../utils/parse-date';

const getCalculateValueFromSource = ({
  fromSource,
  data,
  groupData,
  type,
  isReadOnly,
  form,
  dataKey,
}) => {
  const {
    source = '',
    key = '',
    field = '',
    keyDependsOn,
    valueToKey = {},
    fieldFromRoot = false,
  } = fromSource;

  const { [`__${source}`]: sourceData = {} } = data;
  const { [field]: selection = '' } = fieldFromRoot ? data : groupData;
  let { [selection]: selectionData = {} } = sourceData;

  if (has(selectionData, 'height')) {
    selectionData = {
      ...selectionData,
      ...formatInchesToFtInch(selectionData.height),
    };
  }

  let sourceKey = key;

  if (keyDependsOn) {
    const keyDependsOnValue = groupData[keyDependsOn];
    sourceKey = valueToKey[keyDependsOnValue];
  }

  let value = null;

  if (Array.isArray(sourceKey)) {
    value = key.map(item => get(selectionData, item, '')).join(' ');
  } else {
    value = get(selectionData, sourceKey, null);
  }

  // here we'are getting the source = users to make sure that this data doesn't need to
  // have a formatted date with a spec timezone
  if (type === DATE && isReadOnly && source === 'users') {
    const fieldValue = value || form.getFieldValue(dataKey);
    if (!isEmpty(fieldValue)) {
      const formattedDate = momentWithTZ(
        fieldValue,
        undefined,
        undefined,
        false
      );
      value = formattedDate.format(BENCHMARK_DATE_FORMAT);
    }
  }
  return value;
};

const getCalculatedValue = props => {
  const {
    data = {},
    dataEnums,
    dataKey = '',
    field_type: type = '',
    overrides = {},
    parentIndex = 0,
    isReviewer = false,
    fromSource = null,
    dependsOn,
    loopFromEnumRef,
    form,
    isContributeReport,
    contributorAssignmentCanEdit,
  } = props;

  const getFieldOverrides = getOverrides(props, overrides);
  const options = getFieldOverrides('options', {});

  const {
    source = '',
    populateFrom = '',
    multiselect: canSelectMultiple,
    presenceUsageFrom,
    presenceUsageValue,
    presenceUsageFilterBy,
    showPresenceUsage,
  } = options;

  const groupData = getGroupData(props);
  const contributeReadOnly =
    isContributeReport && !contributorAssignmentCanEdit;
  const isReadOnly = getIsReadOnly(props) || contributeReadOnly;

  let calculatedValue = groupData[dataKey];

  const isDynamicallyPopulated = isReadOnly && populateFrom;
  const hasReadOnlyTransform = has(options, 'readOnlyTransform');

  if (dependsOn && hasReadOnlyTransform) {
    const { readOnlyTransform } = options;
    calculatedValue = get(groupData, dependsOn);

    if (isPlainObject(readOnlyTransform)) {
      calculatedValue = applyReadOnlyTransform(
        calculatedValue,
        readOnlyTransform
      );
    }
  }

  if (fromSource) {
    calculatedValue = getCalculateValueFromSource({
      fromSource,
      data,
      groupData,
      type,
      isReadOnly,
      form,
      dataKey,
    });
  } else if (isDynamicallyPopulated) {
    const sources = Object.keys(populateFrom);
    const fromField = get(options, 'populateFromField', null);

    const valueFromPopulatedSource = getValueFromPopulatedSource(
      sources,
      populateFrom,
      dataKey,
      data,
      groupData,
      fromField
    );

    calculatedValue = valueFromPopulatedSource;
  } else if (
    type === 'autocomplete' &&
    canSelectMultiple &&
    Array.isArray(calculatedValue) &&
    isReviewer
  ) {
    const sourceKey = source.startsWith('__') ? source : `__${source}`;
    const sourceData = get(data, sourceKey);

    calculatedValue = mapOfficerNames(calculatedValue, sourceData);
  } else if (type === 'autocomplete' && isReviewer) {
    const { tab } = props;
    const fields = get(props, 'presentation.fields', []);
    const data = fields[tab] || {};
    calculatedValue = get(data, dataKey, null);
  }

  if (showPresenceUsage) {
    calculatedValue = getPresenceUsage({
      dataKey,
      parentIndex,
      loopFromEnumRef,
      data,
      dataEnums,
      presenceUsageFrom,
      presenceUsageValue,
      presenceUsageFilterBy,
      showPresenceUsage,
    });
  }

  return calculatedValue;
};

export default getCalculatedValue;
