import React from 'react';
import { Button } from 'antd';
import { TAB } from 'APP_ROOT/constants/layoutComponentTypes';
import getFieldLabel from '../../utils/getFieldLabel';

const AddTabButton = ({ onClick }) => (
  <Button icon="plus" type="primary" onClick={onClick}>
    Add {getFieldLabel(TAB)}
  </Button>
);

export default AddTabButton;
