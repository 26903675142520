import React, { Component } from 'react';
import { Tag, Row, Col } from 'antd';
import { get, omit, isEmpty } from 'lodash';
import { connect } from 'react-redux';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import withModal from 'APP_COMPONENTS/common/modal/base';
import { ROW, COLUMN } from 'APP_ROOT/constants/layoutComponentTypes';

import Styled from './SectionEditor.styled';
import PickableZone from '../PickableZone';
import IconButton from '../../../../components/common/buttons/icon-button';
import componentsManager from '../../services/componentsManager';
import buildOptionsModalContent from '../../utils/buildOptionsModalContent';
import getCustomFields from '../../utils/getCustomFields';
import FormComponent from '../../models/FormComponent';
import getFieldLabel from '../../utils/getFieldLabel';
import getColumnOptionsByColumnNumber from '../../utils/getColumnOptionsByColumnNumber';
import getTitle from '../../utils/getTitle';
import setTitle from '../../utils/setTitle';
import getReviewerCssClass from '../../utils/getReviewerCssClass';
import { CONTRIBUTOR_ASSIGNMENT_SECTION } from '../../../../constants/contributeToReport.js';

import {
  TAB_VALUES,
  TAB_SHARE_KEY,
  TAB_INFORMATION,
} from '../OptionsModalBody/OptionsModalBody';
class SectionEditor extends withModal(Component) {
  constructor(props) {
    super(props);
    let field = props.field;
    if (props.field.reportingKey === CONTRIBUTOR_ASSIGNMENT_SECTION) {
      field.label = 'Performance Review Section';
    }
    this.state = {
      values: field,
    };
  }

  componentDidMount() {
    this.createModal();
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(this.props, nextProps);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      values: props.field,
    };
  }

  remove = () => {
    const { id } = this.props;

    componentsManager.removeComponent(id);
  };

  getValue = (name, defaultValue) =>
    get(this, `state.values.${name}`, defaultValue) || defaultValue;

  showOptionsModal = () => {
    const { values } = this.state;
    const { field, total } = this.props;

    const customFields = getCustomFields(field.type, null, values);
    const customFieldsInitialValue = customFields.reduce(
      (allInitialValues, { initialValue, name }) => ({
        ...allInitialValues,
        [name]: initialValue,
      }),
      {}
    );

    const fieldsOptions = {
      id: values.id,
      type: values.type,
      reportingKey: {
        type: 'text',
        label: 'Reporting Key',
        value: values.reportingKey,
        maxLength: 100,
        placeholder: 'Reporting key',
      },
      title: {
        type: 'text',
        label: 'Title',
        value: getTitle(values.title, ''),
        maxLength: 100,
        placeholder: 'Title',
      },
      ...customFieldsInitialValue,
      customFields,
      conditions: values.conditions,
      disableTab: [TAB_VALUES, TAB_SHARE_KEY, TAB_INFORMATION],
    };

    const onDelete =
      total > 1
        ? () => {
            this.remove();
            this.deleteModal();
          }
        : undefined;

    const modalData = buildOptionsModalContent({
      ...fieldsOptions,
      onCancel: this.deleteModal,
      onDelete,
      onSave: params => {
        const { title, columns = 2, conditions, reportingKey } = params;
        const mutations = [];
        customFields.forEach(customField => {
          mutations.push(customField.onSave);
        });

        const modifiedValues = mutations.reduce(
          (allChanges, mutation) => mutation(allChanges),
          Object.assign({}, values, {
            title: setTitle(values.title, title.value),
            reportingKey: reportingKey.value,
            columns,
            conditions,
            properties: this.updateProperties(columns, this.props.field),
            isSourceRequired: params.isSourceRequired,
            sourceFieldSection: params.sourceFieldSection,
          })
        );

        this.setState({ values: modifiedValues });

        componentsManager.editComponent(field.id, modifiedValues);

        this.deleteModal();
      },
    });

    this.updateModal(modalData);

    this.showModal();
  };

  updateProperties(newColumnsNumber, field) {
    const currentProperties = get(field, 'properties', []);

    if (field.columns === newColumnsNumber) {
      return currentProperties;
    }

    return currentProperties.map(row =>
      row.type === ROW
        ? {
            ...row,
            properties: row.properties
              .filter((_, idx) => idx < newColumnsNumber)
              .concat(
                newColumnsNumber > row.properties.length
                  ? Array.from(
                      Array(newColumnsNumber - row.properties.length),
                      (_, idx) => idx
                    ).map(() => new FormComponent(COLUMN))
                  : []
              )
              .map(col => {
                const options = get(col, 'options', {});
                const newLayoutOptions = get(
                  getColumnOptionsByColumnNumber(newColumnsNumber),
                  'options',
                  {}
                );

                return {
                  ...col,
                  options: {
                    ...omit(options, ['xs', 'sm', 'md', 'lg', 'span']),
                    ...newLayoutOptions,
                  },
                };
              }),
          }
        : row
    );
  }

  render() {
    const {
      children,
      type,
      id,
      field: { reportingKey },
    } = this.props;
    const {
      values: { conditions },
    } = this.state;
    const reviewerCss = getReviewerCssClass(conditions);
    const isContributorAssignedSection =
      reportingKey === CONTRIBUTOR_ASSIGNMENT_SECTION;

    return (
      <Styled
        className={reviewerCss}
        style={
          isContributorAssignedSection
            ? { opacity: 0.7, pointerEvents: 'none', backgroundColor: '#EEE' }
            : undefined
        }
      >
        <div className="section-title">
          <Row type="flex" align="middle">
            <Col className="ant-col">
              <span className="section-title--text">
                {getTitle(this.getValue('title'), reportingKey)}
              </span>
            </Col>
            <Col className="ant-col text-right section-title--actions">
              {!isEmpty(conditions) && <Tag color="gold">Conditional</Tag>}
              <Tag>{getFieldLabel(type).toUpperCase()}</Tag>
              <span>
                <IconButton
                  icon="setting"
                  onClick={this.showOptionsModal}
                  className="is-big is-focusable"
                />
              </span>
            </Col>
          </Row>
        </div>
        <div className="section-children">
          <PickableZone type={type} id={id}>
            {children}
          </PickableZone>
        </div>
      </Styled>
    );
  }
}

SectionEditor.displayName = 'FieldEditor.SectionEditor';

export default connect()(SectionEditor);
