import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';

const { Dispatcher } = require('@aux4/dispatcher.js');

export const DISPATCHER_LOADING_EVENT = 'LOADING_EVENT';

export const LoadingModal = props => {
  const [modals, setModals] = useState({});
  const subscriber = useMemo(() => {
    return (eventType, object) => {
      const { enable, name } = object;
      setModals({
        ...modals,
        [name]: enable ? object : undefined,
      });
    };
  }, []);
  useEffect(() => {
    Dispatcher.register(subscriber, DISPATCHER_LOADING_EVENT);
    return () => {
      Dispatcher.unregister(subscriber);
    };
  }, []);

  const components = useMemo(() => {
    return Object.values(modals).filter(modal => {
      return modal;
    });
  }, [modals]);
  return components.map(modal => {
    return (
      <Modal
        key={modal.title}
        title={modal.title}
        visible={true}
        closable={false}
        footer={null}
      >
        <h3>{modal.message}</h3>
      </Modal>
    );
  });
};
