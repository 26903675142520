import styled from 'styled-components';

export const BorderWrapper = styled.div`
  label {
    width: 15%;
  }

  .ant-row {
    margin-bottom: 5px !important;
  }

  .checkbox {
    width: 80%;
    padding: 10px;
    font-weight: regular;
    color: #000;
    font-size: 12px;
  }
`;

export const commonFieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
  inputCol: 'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
};
export const selectFieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-10 ant-col-lg-10',
  inputCol: 'ant-col-xs-24 ant-col-sm-12 ant-col-md-10 ant-col-lg-10',
};

export const zipFieldOptions = {
  labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-8 ant-col-lg-8',
  inputCol: 'ant-col-xs-24 ant-col-sm-12 ant-col-md-11 ant-col-lg-11',
};
