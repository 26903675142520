import React from 'react';

const Dot = props => (
  <svg height="20" width="20" className="bdm-dot">
    <circle
      cx="10"
      cy="10"
      r="5"
      style={{ stroke: `${props.color}`, fill: `${props.color}` }}
    />
  </svg>
);

export default Dot;
