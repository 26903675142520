import React from 'react';
import styled from 'styled-components';

import { Row, Col } from 'antd';

import Panel, { PanelTitle, Divider } from '../common/panel';

const CustomRow = styled(Row)`
  padding: 16px 0;
  border-bottom: 1px solid #e9e9e9;

  &:last-child {
    border-bottom: 0;
  }

  p {
    margin-bottom: 8px;
  }
`;

export default ({ results }) => {
  const {
    data: { activityInformation: rows },
  } = results;

  return (
    <Panel>
      <PanelTitle>
        <h2>General Information</h2>
      </PanelTitle>
      <Divider />
      {rows &&
        rows.map((row, index) => {
          const { label, value } = row;
          const bullets = Array.isArray(value)
            ? value.map((val, index) => <p key={index}>- {val}</p>)
            : value;
          return (
            <CustomRow key={index}>
              <Col xs={24} sm={6}>
                {label}:
              </Col>
              <Col xs={24} sm={18}>
                {bullets}
              </Col>
            </CustomRow>
          );
        })}
    </Panel>
  );
};
