import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import route from '../../utils/get-route';
import storage from '../../utils/storage';
import AssistantButton from './assistant-button';
import PageWrapper from './page-wrapper';
import {
  getTemplatesLoading,
  getTemplates,
  getSelectedForm,
} from '../../selectors/form';
import { getModals } from '../../selectors/modals';
import { getApplicationState } from '../../selectors/application';
import {
  getCurrentUser,
  getSessionWithAuthState,
} from '../../selectors/session';
import Loading from '../Loading';

const stateObjectFn = (state, props) => ({ url: props.url });
const actionsObjectFn = dispatch => ({ dispatch });

const DashboardPage = (
  mapToState = stateObjectFn,
  mapToActions = actionsObjectFn,
  pageOptions
) => WrappedComponent => {
  class Page extends PureComponent {
    get config() {
      const config =
        WrappedComponent.getPageConfig !== undefined
          ? WrappedComponent.getPageConfig.bind(this)
          : () => ({});

      return config(this.props);
    }

    get pageOptions() {
      return {
        raw: pageOptions,
        get(name, defaultValue) {
          return get(pageOptions, name, defaultValue);
        },
      };
    }

    render() {
      const props = this.props;
      const {
        session,
        location,
        currentUser: { roles = [], permissions = [] },
      } = props;

      const { guestAccess } = this.config;
      if (!session.loggedIn && !guestAccess) {
        props.history.push(route('login'), {
          next: encodeURIComponent(location.pathname),
        });

        return <Loading />;
      }

      if (guestAccess) {
        return <WrappedComponent {...props} />;
      }

      return (
        <PageWrapper
          roles={roles}
          permissions={permissions}
          {...props}
          config={this.config}
          pageOptions={this.pageOptions}
        >
          <WrappedComponent
            {...props}
            ref={el => {
              this.WrappedComponent = el;
            }}
            route={(name, params = false, querystring = null) =>
              route(name, params || props.match.params, querystring)
            }
          />
          <AssistantButton />
        </PageWrapper>
      );
    }
  }

  const mapToStateFn = mapToState || stateObjectFn;

  const extendMapToState = (state, props) => {
    return {
      ...mapToStateFn(state, props),
      selectedForm: getSelectedForm(state, ['changedFields']),
      session: getSessionWithAuthState(state, storage),
      currentUser: getCurrentUser(state),
      app: getApplicationState(state),
      modals: getModals(state),
      templatesLoading: getTemplatesLoading(state),
      templates: getTemplates(state),
      supportedAgency: state.supportedAgency,
    };
  };

  return connect(extendMapToState, mapToActions)(Page);
};

export default DashboardPage;
