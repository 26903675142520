import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import getRoute from '../../../utils/get-route';

import storage from 'APP_ROOT/utils/storage';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import DashboardPage from '../../../components/dashboard';
import withoutClutter from '../../../components/without-clutter';
import withOwnershipGuard from '../components/agency-ownership-guard';
import { Row, Col, Button, Upload } from 'antd';
import getRanks from '../agency-profile/actions/get-ranks';
import {
  titleRankCol,
  rolesCol,
  ouCol,
} from './AgencyBulkUserImportTableColumns';
import { hasPermissions, PERMISSIONS } from '../../../utils/admin';
import getAgencyById from './actions/get-agency-by-id';
import getAgencySecurityRoles from './actions/get-security-roles';
import getOrganizationalUnits from './actions/get-organizational-unit';
import Panel, {
  Divider,
  PanelTitle,
  PanelSectionTable,
} from '../../../components/common/panel';

import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from '../../../components/custom-sections/sections/Sections.styled';

import BodyWrapper, {
  HeaderWrapper,
  TableWrapper,
} from './AgencyBulkUsersImport.styled';
import ResultsContainer from './AgencyBulkUserImportResults';

import SortAndFilterTable from '../../../components/filterable-sortable-table/SortAndFilterTable';
import organizationEndpoints from '../../../api/organization/organizationEndpoints';

function AgencyBulkUserImport({ agencyId, dispatch, tenantId, ...props }) {
  const confluenceURL =
    'https://benchmarkanalytics.atlassian.net/wiki/spaces/BA/pages/3283550209/Bulk+User+Import+Self+Service+KB';
  const [result, setResult] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [agencyName, setAgencyName] = useState('');
  const [titleRanks, setTitleRanks] = useState([]);
  const [organizationalUnits, setOrganizationalUnits] = useState([]);
  const [roles, setRoles] = useState([]);
  const [showResults, setShowResults] = useState('');
  const userAgency = props.agency.id;
  const ENABLEORGANIZATIONFLAG = userAgency == agencyId;
  let createAnyUser = hasPermissions(
    props.permissions,
    PERMISSIONS.createAnyUser
  );

  const getAgencyName = () => {
    if (agencyId != userAgency) {
      dispatch(
        getAgencyById(agencyId, (err, pageAgency) => {
          if (err) {
            setDisabled(true);
            alert('There was an error retrieving agency name for import.');
            return;
          }
          setDisabled(false);
          setAgencyName(pageAgency.name);
        })
      );
    } else {
      setDisabled(false);
      setAgencyName(props.agency.name);
    }
  };

  useEffect(() => {
    getAgencyName();

    dispatch(
      getRanks(agencyId, (err, ranks) => {
        if (err) {
          return;
        }
        setTitleRanks(ranks);
      })
    );

    dispatch(
      getAgencySecurityRoles(agencyId, false, (err, role) => {
        if (err) {
          return;
        }
        setRoles(role);
      })
    );
    if (ENABLEORGANIZATIONFLAG) {
      dispatch(
        getOrganizationalUnits((err, data) => {
          if (err) {
            return;
          }
          setOrganizationalUnits(data);
        })
      );
    }
  }, []);

  const beforeUpload = () => {
    setResult({});
  };

  const onChange = e => {
    const { status, response = {} } = e.file;

    switch (status) {
      case 'done':
        setShowResults('Results');
        setResult(prev => ({
          ...prev,
          title: 'Import Successful',
          message: response.importMessage,
          createdUserDetails: response.createdUserDetails,
          debugMessage: response.rolesMessage,
          errors: [...response.errors],
        }));
        break;
      case 'error':
        setShowResults('Results');
        setResult(prev => ({
          ...prev,
          title: 'Import Error',
          message:
            'No records were imported.  Please correct the error and try again. ' +
            response.userMessage,
          debugMessage: response.debugMessage,
        }));

        break;
      default:
        break;
    }
  };

  return (
    <div>
      <HeaderWrapper>
        <PageHeader
          title="Bulk User Import"
          goBackTo={getRoute('administratorAgency', { agencyId })}
        >
          <PanelTitle className="text-xs-center">
            {createAnyUser && (
              <h2>
                <a
                  href={confluenceURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {'Bulk User Import Self Service KB'}
                </a>
              </h2>
            )}
          </PanelTitle>
        </PageHeader>
      </HeaderWrapper>

      <StyledCollapse
        bordered={false}
        defaultActiveKey=""
        expandIconPosition="right"
      >
        <Divider />
        <StyledPanel
          header={
            <StyledPanelTitle size="large">Reference Tables</StyledPanelTitle>
          }
          key="Reference"
          className="section-panel"
        >
          <Divider />
          <TableWrapper>
            <SortAndFilterTable
              data={titleRanks}
              columns={titleRankCol}
              agencyId={agencyId}
              pagination={false}
              rowKey={'name'}
            />
          </TableWrapper>
          <TableWrapper>
            <SortAndFilterTable
              data={roles}
              columns={rolesCol}
              agencyId={agencyId}
              pagination={false}
              rowKey={'name'}
            />
          </TableWrapper>
          {ENABLEORGANIZATIONFLAG && (
            <TableWrapper>
              <SortAndFilterTable
                data={organizationalUnits}
                columns={ouCol}
                agencyId={agencyId}
                pagination={false}
                rowKey={'name'}
              />
            </TableWrapper>
          )}
        </StyledPanel>
      </StyledCollapse>

      <BodyWrapper>
        <Panel>
          <PanelTitle className="text-xs-center">
            <h2>Upload</h2>
          </PanelTitle>

          <Divider />
          <PanelSectionTable>
            <Row type="flex" align="middle">
              <Col>
                <Upload
                  accept=".csv"
                  action={organizationEndpoints.getBulkUploadPath(
                    '/users/import'
                  )}
                  align="middle"
                  loading={''}
                  beforeUpload={beforeUpload}
                  data={{
                    agencyName: agencyName,
                  }}
                  onClick={{}}
                  showUploadList={true}
                  name="file"
                  headers={{
                    Authorization: `Bearer ${storage.get('token')}`,
                    'X-Requested-With': null,
                  }}
                  multiple={false}
                  onChange={onChange}
                  onRemove={{}}
                >
                  <Button icon="upload" disabled={disabled} type="">
                    Click to Upload
                  </Button>
                </Upload>
              </Col>
            </Row>
          </PanelSectionTable>
        </Panel>
      </BodyWrapper>

      <StyledCollapse
        bordered={false}
        activeKey={showResults}
        expandIconPosition="right"
      >
        <StyledPanel
          header={<StyledPanelTitle size="large">Results</StyledPanelTitle>}
          key="Results"
          className="section-panel"
        >
          <Divider />

          <ResultsContainer
            title={result.title}
            message={result.message}
            createdUserDetails={result.createdUserDetails}
            debugMessage={result.debugMessage}
            loading={false}
            errors={result.errors}
          />
        </StyledPanel>
      </StyledCollapse>
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  agencyId: get(props, 'match.params.agencyId'),
  tenantId: get(props, 'match.params.tenantId'),
});

export default withoutClutter(
  DashboardPage(
    mapStateToProps,
    null,
    {}
  )(
    withOwnershipGuard(
      AgencyBulkUserImport,
      'administratorAgencyBulkUsersImport'
    )
  )
);
