import {
  UPLOAD,
  FIELD,
  REPEATER,
} from 'APP_ROOT/constants/layoutComponentTypes';
import {
  SELECT,
  MULTISELECT,
  TIME,
  DATE,
  DATE_TIME,
  MASKED,
  AUTOCOMPLETE,
  TEXTAREA,
  TEXT,
  CHECKBOX,
  SWITCH,
  NUMBER,
  STRING,
  DURATION,
} from 'APP_ROOT/constants/fieldTypes';

const getValidationsDefaultFieldType = field_type => {
  let type = 'string';
  switch (field_type) {
    case SELECT:
      type = 'enum';
      break;
    case MULTISELECT:
      type = 'multi';
      break;
    case TIME:
    case DATE:
    case DATE_TIME:
      type = 'date';
      break;
    case MASKED:
    case AUTOCOMPLETE:
    case TEXTAREA:
    case TEXT:
    case STRING:
      type = 'string';
      break;
    case CHECKBOX:
    case SWITCH:
      type = 'boolean';
      break;
    case DURATION:
    case NUMBER:
      type = 'number';
      break;
    default:
      type = 'string';
      break;
  }
  return type;
};

const getValidationsDefaultField = type => {
  let validation;
  if ([REPEATER, UPLOAD].includes(type)) {
    validation = {
      type: 'array',
      item: [
        {
          type: 'object',
          fields: {},
        },
      ],
    };
  }
  return validation;
};

const getValidationsDefault = ({ type, field_type }) => {
  let validation = {};
  if (type === FIELD) {
    validation.type = getValidationsDefaultFieldType(field_type);
  } else {
    validation = getValidationsDefaultField(type);
  }

  return validation;
};

export default getValidationsDefault;
export { getValidationsDefaultFieldType };
