import React, { PureComponent } from 'react';
import { Row, Col } from 'antd';
import get from 'lodash/get';

// Containers
import DashboardPage from '../../components/dashboard';

// Components
import ChangePassword from '../../components/settings/change-password';
import SystemUpdates from '../../components/settings/system-updates';
import { hasPermissions, PERMISSIONS } from '../../utils/admin';
import PageHeader from '../../components/PageHeader/PageHeader';
import withoutClutter from '../../components/without-clutter';

class Settings extends PureComponent {
  static getPageConfig() {
    return {
      title: 'Settings',
    };
  }

  render() {
    const { session } = this.props;
    const userPermissions = get(session, 'currentUser.permissions', []);

    const canUpdate = hasPermissions(userPermissions, PERMISSIONS.updateSystem);

    return (
      <div>
        <PageHeader title="Settings" />
        <Row>
          <Col span={24}>
            <ChangePassword />
          </Col>
          {canUpdate && (
            <Col span={24}>
              <SystemUpdates />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

const mapState = () => ({});

export default withoutClutter(DashboardPage(mapState, null)(Settings));
