import styled from 'styled-components';

import { DIVIDER, GRAY_2, ACCENT } from '../../../config/theme';

const FormViewer = styled.div.attrs({
  className: 'bdm-form-viewer',
})`
  padding-bottom: 60px;

  .read-only {
    height: 28px;
    line-height: 28px;
    border: 0;
    border-bottom: 1px solid ${DIVIDER};
    color: ${GRAY_2};
    cursor: default;
  }

  .reviewer-field {
    &.text-field,
    &.select-field {
      height: 28px;
      line-height: 28px;
      color: ${GRAY_2};
      cursor: default;

      @media screen {
        padding: 10px 0;
        font-size: 15px;
        font-weight: 400;
      }
    }

    &.select-field,
    &.textarea-field {
      height: auto;
      line-height: 1.6em;

      @media screen {
        padding: 10px 0;
        font-size: 15px;
        font-weight: 400;
      }
    }

    &.file-field {
      color: ${ACCENT};
      cursor: pointer;

      span {
        text-decoration: underline;
      }
    }
  }

  .bdm-form-field-group {
    .reviewer-field {
      display: inline-block;
      margin-right: 10px;
    }
  }
`;

export default FormViewer;
