import React from 'react';
import { Form, Input, Button } from 'antd';
import { Link } from 'react-router-dom';
import ContentBlock from './content-block';

const FormItem = Form.Item;

class ForgotPasswordForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    const { form, onSubmit } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values, form);
      }
    });
  };

  render() {
    const { form, isLoading, error } = this.props;
    const { getFieldDecorator } = form;
    const formItemErrorProps = error
      ? {
          validateStatus: 'error',
          help:
            'The username you have provided is not associated with any active Benchmark Analytics account.',
        }
      : {};
    return (
      <ContentBlock
        title="Let's find your account"
        content={
          <div>
            <p>Enter the username you use to log into Benchmark Analytics.</p>
            <p>We will email you a link to reset your password.</p>
          </div>
        }
      >
        <Form onSubmit={this.handleSubmit} className="login-form">
          <div className="form-wrapper">
            <FormItem {...formItemErrorProps}>
              {getFieldDecorator('username', {
                rules: [
                  {
                    required: true,
                    message: 'Username is required!',
                  },
                ],
                validateTrigger: 'onBlur',
              })(<Input placeholder="Username" />)}
            </FormItem>
            <FormItem className="actions">
              <Link
                className="ant-btn ant-btn-primary ant-btn-lg ant-btn-background-ghost"
                to="/login"
              >
                <span>Back to Log In</span>
              </Link>{' '}
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading}
                size="default"
                className="login-form-button"
              >
                Submit
              </Button>
            </FormItem>
          </div>
        </Form>
      </ContentBlock>
    );
  }
}

const WrappedForgotPasswordForm = Form.create()(ForgotPasswordForm);

export default WrappedForgotPasswordForm;
