import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Table, Select, DatePicker, Spin } from 'antd';
import styled from 'styled-components';
import { get } from 'lodash';
import moment from 'moment';
import AdministratorWrapper from '../Administrator.styled';
import PageHeader from 'APP_COMPONENTS/PageHeader';

// Containers
import DashboardPage from '../../../components/dashboard';

// Components
import MainTitle from '../../../components/common/main-title';
import IconButton from '../../../components/common/buttons/icon-button';

import dataExportEndpoints from '../../../api/data-exports/dataExportEndpoints';
import templateEndpoints from '../../../api/data-exports/templateEndpoints';
import { StyledButton } from './Exports.styled';
import WithoutClutter from '../../../components/without-clutter/WithoutClutter';
import getRoute from '../../../utils/get-route';

import YearPicker from './YearPicker';
import {
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_DATE_TIME_FORMAT,
} from '../../../utils/parse-date';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Column } = Table;

const exportTypeNamesMap = {
  RULE_28: 'Rule 28',
  'RULE_24-31-315': 'Rule 24-31-315',
};

function DataExportComponent(props) {
  const agencyId = props.agency.id;
  const { agency } = props;

  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [dataExportList, setDataExportList] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const [refreshExportRequest, setRefreshExportRequest] = useState(null);
  const [downloadId, setDownloadId] = useState(null);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [selectedDates, setSelectedDates] = useState([null, null]);
  const [selectedExportType, setSelectedExportType] = useState(undefined);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function isListChanged(list1, list2) {
    return JSON.stringify(list1) !== JSON.stringify(list2);
  }

  function splitWithTail(str, delim, count) {
    var parts = str.split(delim);
    var tail = parts.slice(count).join(delim);
    var result = parts.slice(0, count);
    result.push(tail);
    return result.filter(item => {
      return item !== '';
    });
  }

  const templateNamesById = useMemo(() => {
    return Object.fromEntries(
      templatesList.map(template => {
        return [
          template.id,
          template.label || template.name || template.templateType?.name,
        ];
      })
    );
  }, [templatesList]);

  const exportType = useMemo(() => {
    const selectionPrefix =
      selectedExportType && splitWithTail(selectedExportType, '_', 2)[0];
    if (!selectionPrefix) {
      return null;
    }
    switch (selectionPrefix) {
      case 'rule28':
        return 'RULE_28';
      case 'rule24-31-315':
        return 'RULE_24-31-315';
      default:
        return 'REPORTS';
    }
  }, [selectedExportType]);

  const template = useMemo(() => {
    return selectedExportType && splitWithTail(selectedExportType, '_', 2)[1];
  }, [selectedExportType]);

  const enableGenerateButton = useMemo(() => {
    return (
      !!exportType &&
      (['RULE_28', 'RULE_24-31-315'].includes(exportType) ||
        (selectedDates[0] && selectedDates[1]))
    );
  }, [exportType, template, selectedDates]);

  const enableDateRange = useMemo(() => {
    return !!exportType && !['RULE_28', 'RULE_24-31-315'].includes(exportType);
  }, [exportType]);

  const enableYearPicker = useMemo(() => {
    return !!exportType && exportType === 'RULE_28';
  }, [exportType]);

  const sortFunc = (a, b) => {
    return a[1].localeCompare(b[1]);
  };

  const ExportTypeDropdown = useMemo(() => {
    return () => {
      const menu = (
        <Select
          placeholder="Data Exports"
          className="full-width"
          disabled={templatesLoading}
          value={selectedExportType}
          onChange={setSelectedExportType}
          // onDropdownVisibleChange={open=>setIsMenuOpen(open)}
        >
          {props.permissions.includes('manageRule28DataExport') && (
            <Option value="rule28">Rule 28</Option>
          )}
          {props.permissions.includes('manageRule28DataExport') && (
            <Option value="rule24-31-315">Rule 24-31-315</Option>
          )}
          {props.permissions.includes('manageReportDataExport') && (
            <Option value="" disabled className="divider-option">
              ───── Templates ─────
            </Option>
          )}
          {props.permissions.includes('manageReportDataExport') &&
            (Object.entries(templateNamesById) || [])
              .sort(sortFunc)
              .map(([id, name]) => {
                return (
                  <Option key={'key_' + id} value={'template_' + id}>
                    {name}
                  </Option>
                );
              })}
        </Select>
      );

      return menu;
    };
  }, [
    setSelectedExportType,
    setIsMenuOpen,
    props.permissions,
    templateNamesById,
    selectedExportType,
    setSelectedExportType,
  ]);

  const onDatePickerChange = (dates, dateStrings) => {
    // const { timezone } = this.props;
    // const dateFrom = moment.tz(dateStrings[0], timezone).format();
    // const dateTo = moment.tz(dateStrings[1], timezone).format();
    // const range = dates.length ? [dateFrom, dateTo] : [];

    // this.setPreFilters(filterType, range);
    setSelectedDates(dates);
    setDateFrom(dateStrings[0]);
    setDateTo(dateStrings[1]);
    // console.log({ dates, dateStrings })
  };

  const exportRangePicker = useMemo(() => {
    const rangePicker = (
      <RangePicker
        onChange={onDatePickerChange}
        size="default"
        className="full-width"
        value={selectedDates}
      />
    );

    return rangePicker;
  }, [onDatePickerChange, selectedDates]);

  const refreshExportsTable = async () => {
    try {
      setExportLoading(true);
      const result = await dataExportEndpoints.getDataExportList();
      setExportLoading(false);
      setDataExportList(result);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log('Exception loading data export list: ', ex);
    }
  };

  const refreshTemplatesList = async () => {
    try {
      setTemplatesLoading(true);
      const result = await templateEndpoints.getTemplateList(agency.id);
      result.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (b.name < a.name) {
          return 1;
        }
        return 0;
      });
      setTemplatesLoading(false);
      setTemplatesList(result);
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log('Exception loading templates list: ', ex);
    }
  };

  useEffect(() => {
    refreshExportsTable();
    refreshTemplatesList();
    const refreshInterval = () => {
      refreshExportsTable();
    };
    const intervalId = setInterval(refreshInterval, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const callExport = async () => {
      if (refreshExportRequest === null) {
        return;
      }
      try {
        setExportLoading(true);
        const result = await dataExportEndpoints.submitDataExportRequest(
          exportType,
          template,
          dateFrom,
          dateTo
        );
        setExportLoading(false);
        setDataExportList(
          result.map(item => {
            return {
              ...item,
              submissionDateRange: {
                dateFrom: item.date_from,
                dateTo: item.date_to,
              },
            };
          })
        );
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.log('Exception submitting data export request: ', ex);
      }
    };
    callExport();
  }, [refreshExportRequest]);

  useEffect(() => {
    const callDownload = async () => {
      if (downloadId === null) {
        return;
      }
      try {
        setDownloadId(null);
        const result = await dataExportEndpoints.getDownloadLink(downloadId);
        window.open(result, '_blank');
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.log('Exception downloading report: ', ex);
      }
    };
    callDownload();
  }, [downloadId]);
  return (
    <AdministratorWrapper>
      <PageHeader
        isLoading={false}
        title={<span>Data Export</span>}
        goBackTo={getRoute('administratorAgency', {
          agencyId,
        })}
      ></PageHeader>
      <div
        className="administrator-content"
        style={{
          padding: '16px 16px',
        }}
      >
        <Row gutter={8} key="row-1">
          <Col span={6} className="text-left">
            <ExportTypeDropdown />
          </Col>
          {enableYearPicker && (
            <Col span={3} className="text-left">
              <YearPicker
                onChange={year => {
                  setDateFrom(`${year}-01-01`);
                  setDateTo(`${year}-12-31`);
                }}
              />
            </Col>
          )}
          {enableDateRange && (
            <Col span={4} className="text-left">
              {exportRangePicker}
            </Col>
          )}
          <Col span={4} className="text-left">
            <StyledButton
              type="primary"
              size="default"
              disabled={!enableGenerateButton}
              loading={false}
              onClick={() => {
                setRefreshExportRequest(!refreshExportRequest);
              }}
            >
              Generate Export
            </StyledButton>
          </Col>
        </Row>
        <StyledRow>
          <Col span={24}>
            <MainTitle>Download History</MainTitle>
            {/*{exportLoading && !isMenuOpen && <> <Spin /></>}*/}
            <div className="responsive-table">
              <Table dataSource={dataExportList} rowKey="id" pagination={true}>
                <Column
                  title="Report Name"
                  dataIndex="export_type"
                  key="export_type"
                  render={text => {
                    if (text.startsWith('REPORTS_')) {
                      const templateId = text.split('_')[1];
                      text = `Reports - ${templateNamesById[templateId] ||
                        `Template #${templateId}`}`;
                    } else {
                      text = exportTypeNamesMap[text] || text;
                    }
                    return <div>{text}</div>;
                  }}
                />
                <Column
                  title="Submission Date Range"
                  dataIndex="submissionDateRange"
                  key="submissionDateRange"
                  render={(dummy, { export_type, date_from, date_to }) => {
                    const momentifiedDateFrom = date_from
                      ? moment(date_from).utc()
                      : '';
                    const momentifiedDateTo = date_to
                      ? moment(date_to).utc()
                      : '';
                    return (
                      <div>
                        {momentifiedDateFrom &&
                          momentifiedDateFrom.format(
                            BENCHMARK_DATE_FORMAT
                          )}{' '}
                        {(momentifiedDateFrom || momentifiedDateTo) && '- '}
                        {momentifiedDateTo &&
                          momentifiedDateTo.format(BENCHMARK_DATE_FORMAT)}
                      </div>
                    );
                  }}
                />
                <Column
                  title="Generated Date"
                  dataIndex="generated_date"
                  key="generated_date"
                  render={text => {
                    const momentifiedStartDate = text
                      ? moment(text).utc()
                      : moment();
                    return (
                      <div>
                        {momentifiedStartDate &&
                          momentifiedStartDate.format(
                            BENCHMARK_DATE_TIME_FORMAT
                          )}
                      </div>
                    );
                  }}
                />
                <Column
                  title="Export ID"
                  dataIndex="id"
                  key="id"
                  render={text => {
                    return text;
                  }}
                />
                <Column
                  title="Export Status"
                  dataIndex="job_status"
                  key="job_status"
                  render={text => {
                    return (
                      <div>
                        {{
                          PROGRESSING: 'In Progress',
                          FAILED: 'Failed',
                          COMPLETED: 'Completed',
                        }[text] || text}
                      </div>
                    );
                  }}
                />
                <Column
                  title="Download"
                  dataIndex="id"
                  key="id"
                  render={(text, record) => {
                    const id = get(record, 'id');
                    const status = get(record, 'job_status');
                    return (
                      status === 'COMPLETED' && (
                        <IconButton
                          icon="download"
                          className="arrow"
                          onClick={() => {
                            setDownloadId(id);
                          }}
                        />
                      )
                    );
                  }}
                />
              </Table>
            </div>
          </Col>
        </StyledRow>
      </div>
    </AdministratorWrapper>
  );
}

const StyledRow = styled(Row)`
  margin-top: 16px;

  .ant-table table {
    border: 1px solid #e3e3e3;
    border-bottom: none;
    background-color: #fff;

    tr td a {
      text-decoration: underline;
    }
  }

  .ant-table-thead > tr > th {
    background: #e3e3e3;
    color: #000;
    font-weight: 600;
    font-size: 12px;
  }

  .arrow {
    margin: 0 10px;
  }
`;

// export default DashboardPage(null, null)(DataExportComponent);
export default WithoutClutter(DashboardPage()(DataExportComponent));
