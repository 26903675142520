import EventEmitter from 'eventemitter3';

export default new EventEmitter();

export const EventTypes = {
  CREATE_REPORT: 'create-report',
  SET_TAB: 'set-tab',
  DISPATCH_IN_FORM: 'dispatch-in-form',
  REMOVE_REPEATER_ITEM: 'remove-repeater-item',
  CALL_FORM_METHOD: 'call-form-method',
  OPEN_FIELD_EDITOR: 'open-field-editor',
  TOGGLE_STATUS_TRAINING_TASK: 'toggle-status-training-task',
  OPEN_TRAINING_TASK: 'open-training-task',
  CTR_ATTEMPT_SECTION_COMPLETE_OR_INCOMPLETE:
    'ctr-attempt-section-complete-or-incomplete',
  CTR_SECTION_TIMESTAMP_UPDATE: 'ctr-section-timestamp-update',
  CTR_SECTION_COMPLETE_SWITCH_UPDATE: 'ctr-section-complete-update',
  CTR_COMPLETE_BUTTON_STOP_LOADING: 'ctr-complete-bottom-stop-loading',
};
