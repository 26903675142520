import { camelCase } from 'lodash';

import getFieldMeta from './getFieldMeta';

const generateFieldKey = ({ fields, prefix = '' }) => {
  let key;
  do {
    const rand = Math.trunc(Math.random() * 1000);
    key = camelCase(`${prefix}${rand}`);
  } while (getFieldMeta({ fields, key: 'key', value: key }));

  return key;
};

export default generateFieldKey;
