import { get } from 'lodash';
import {
  symlinksPropName,
  symlinkToPropertyName,
  symlinkFromPropertyName,
} from 'APP_ROOT/utils/create-symlink';

const getRepeaterPropsForAutoSave = (changedFields, data) => {
  const [repeaterKey] = Object.keys(changedFields);
  const { [symlinksPropName]: symlinks = [] } = data;
  let repeaterData;
  const { [symlinkFromPropertyName]: from } =
    symlinks.find(s => s[symlinkToPropertyName] === repeaterKey) || {};
  if (from) {
    repeaterData = {
      [from]: get(data, from),
      [symlinksPropName]: symlinks,
    };
  }
  return { [repeaterKey]: get(data, repeaterKey), ...repeaterData };
};

export default getRepeaterPropsForAutoSave;
