export const titleRankCol = [
  {
    title: 'Title/Rank',
    dataIndex: 'name',
    width: '20%',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
];

export const rolesCol = [
  {
    title: 'Role Id',
    dataIndex: 'id',
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: 'Roles',
    dataIndex: 'name',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
];

export const ouCol = [
  {
    title: 'Organization Display Name',
    dataIndex: 'label',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.label.localeCompare(b.label),
  },
  {
    title: 'Organization Import Name',
    dataIndex: 'name',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
];
