import React, { Component } from 'react';

// Containers
import DashboardPage from '../../components/dashboard';
import withoutClutter from '../../components/without-clutter-izenda';

import InsertEmbed from '../../components/izenda-embed/InsertEmbed';
import withFlexibleContainer from '../../components/izenda-embed/WithFlexibleLayout';

class EmbediZendaViewer extends Component {
  componentWillUnmount() {
    if (this.izenda) {
      this.izenda.destroy();
    }
  }

  loadReportViewer = (izenda, target) => {
    this.izenda = izenda.renderReportPage(target);
  };

  render() {
    return <InsertEmbed onReady={this.loadReportViewer} />;
  }
}

const mapState = () => ({});

export default withoutClutter(
  DashboardPage(mapState, null)(withFlexibleContainer(EmbediZendaViewer)),
  { '.content-wrapper': { 'max-width': '100%' } }
);
