import { get, omit, first } from 'lodash';
import FieldSelect from '../../components/FieldSelect';

import componentsManager from '../../services/componentsManager';
import getCustomFieldOptionsWithFallback from './getCustomFieldOptionsWithFallback';
import getAllFieldsFlat from '../../utils/getAllFieldsFlat';
import { AUTOCOMPLETE } from 'APP_ROOT/constants/fieldTypes';

export default class removeKeys {
  constructor(values, value = '') {
    this.currentFieldId = values.id;
    const { allTabs } = getAllFieldsFlat(
      componentsManager.fields,
      this.currentFieldId
    );

    this.name = 'removeKeys';
    this.label = 'Remove keys';
    this.component = FieldSelect;
    this.defaultValue = '';
    this.defaultOptions = get(values, ['options', 'removeKeys']);
    this.value = value;
    this.selectOptions = allTabs.map(tab => ({
      label: tab.title,
      enums: tab.properties
        .filter(p => [AUTOCOMPLETE].includes(p.field_type))
        .map(p => ({
          label: p.key,
          value: p.key,
        })),
    }));
  }
  onSave = values => {
    const selectedOptions = get(values, this.name, this.value);
    const { options } = values;
    let savedOptions;

    if (selectedOptions) {
      savedOptions = [
        {
          ...first(this.defaultOptions),
          key: selectedOptions,
        },
      ];
    }

    return {
      ...omit(values, this.name),
      options: {
        ...options,
        [this.name]: savedOptions,
      },
    };
  };
  getInitialValue = values => {
    const { options } = values;
    if (options === undefined) {
      return this.defaultValue;
    }

    const removeKeys = get(options, this.name);
    const valueRemoveKeys = get(first(removeKeys), 'key');

    return valueRemoveKeys ? valueRemoveKeys : this.defaultValue;
  };

  beforeOnChange = (value, values) => {
    const current = values.customFields.find(cf => cf.name === this.name);
    const currentValues = { ...values, id: current.currentFieldId };
    const field = new removeKeys(currentValues, value);

    return {
      customFields: values.customFields.map(cf =>
        cf.name !== this.name
          ? cf
          : getCustomFieldOptionsWithFallback(values)(field)
      ),
    };
  };
}
