import {
  ALERT_MESSAGE,
  GOTO_BLOCK,
  HISTORICAL_UNIT_ASSIGNMENT,
  PLAN_TEXT,
  REPEATER_ACTION,
  REQUIREMENT_COMPLIANT,
  SECTION_COLLAPSE,
  TAB,
  TABS,
  TRAINING_TASK_DOCUMENT,
  TRAINING_TASK_TIMELIME,
  COLUMN,
  DIVIDER,
  FIELD,
} from './layoutComponentTypes';

export default [
  COLUMN,
  DIVIDER,
  ALERT_MESSAGE,
  FIELD,
  GOTO_BLOCK,
  HISTORICAL_UNIT_ASSIGNMENT,
  PLAN_TEXT,
  REPEATER_ACTION,
  REQUIREMENT_COMPLIANT,
  SECTION_COLLAPSE,
  TAB,
  TABS,
  TRAINING_TASK_DOCUMENT,
  TRAINING_TASK_TIMELIME,
];
