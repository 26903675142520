import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

export const filterTemplateSelfService = enties => {
  const filterNotReviewNotesTemplates = template =>
    !get(template, 'config.note', false);
  const templateList = Object.values(enties);
  const templates = sortBy(templateList, 'config.weight').filter(
    filterNotReviewNotesTemplates
  );
  return templates;
};
