import React from 'react';
import { first, get, has, isNaN, toNumber } from 'lodash';

import { Button, Drawer, Form, Input, Col, Row, Select, Switch } from 'antd';
import { withRouter } from 'react-router-dom';

import {
  getTemplateVisibility,
  setTemplateVisibility,
  getTemplateSumitterVisibility,
  setTemplateSumitterVisibility,
} from '../../../../api/template-visibility';
import { securitySettingsStorageKey } from '../form-builder-constants/security-settings-drawer-constants';
import { toCapitalizedWords } from '../../../../modules/FormBuilder/utils/general-utilities';

import securityEndpoints from '../../../../api/security/securityEndpoints';
import apiEndpoints from '../../../../modules/FormBuilder/services/apiEndpoints';

import './security-settings-drawer.component.css';

const { Option } = Select;
const { Item } = Form;

class SecuritySettingsDrawer extends React.Component {
  // set shareable as undefined, because at this point the value has
  // not been loaded yet from back end, not even after componentDidMount
  // the idea is to have it updated from props when it is uploaded and
  // to know if the user change it avoid an unwanted overwrite
  state = {
    roleConstraints: [],
    roles: [],
    hasSubmitterVisibility: undefined,
    hasDenialList: undefined,
    hasTemplateId: false,
    initialVisibility: false,
    shareable: undefined,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // shareable attribute is loaded with getTemplate (endpoint) data, and passed to this
    // component in the props, however we only accept it only if the user hasn't change it
    if (
      nextProps.shareable !== undefined &&
      prevState.shareable === undefined
    ) {
      return { shareable: nextProps.shareable };
    }
    return null;
  }

  componentDidMount() {
    const {
      match: {
        params: { agencyId, id },
      },
    } = this.props;
    this.fetchSecuritySettings(toNumber(id), agencyId);
    this.fetchRoles(agencyId);
  }

  render() {
    const { visible, onClose } = this.props;
    return (
      <div>
        <Drawer
          title="Security Settings"
          width={400}
          onClose={onClose}
          visible={visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={24}>{this.drawRoleConstraintsSection()}</Col>
            </Row>
            <Row className="mt-2" gutter={16}>
              {/* COR-1514 commented out in case we want to put it back later on */}
              {/* <Col span={12}>{this.drawDenialListSection()}</Col> */}
              <Col span={12}>{this.drawSubmitterVisibilitySection()}</Col>
            </Row>
            <Row className="mt-2" gutter={16}>
              <Col span={12}>{this.drawShareableSection()}</Col>
              <Col span={12}></Col>
            </Row>
          </Form>
          <div className="footer">
            <Button
              type="primary"
              onClick={this.saveSecuritySettings}
              disabled={!this.state.hasTemplateId}
            >
              {' '}
              Save{' '}
            </Button>
            <Button className="ml-1" onClick={onClose}>
              {' '}
              Close{' '}
            </Button>
          </div>
        </Drawer>
      </div>
    );
  }

  drawRoleConstraintsSection() {
    return this.state.roles.length === 0
      ? this.drawInputLoader('Loading association...', 'Loading roles...')
      : this.drawRoleConstraintsSelect(this.state.roles);
  }

  drawDenialListSection() {
    const { hasDenialList } = this.state;
    return hasDenialList === undefined
      ? this.drawSwitchLoader(
          'Loading denial list...',
          'denial...',
          'Loading denial list...'
        )
      : this.drawSwitch('denialList', hasDenialList, this.handleDenialChange);
  }

  drawSubmitterVisibilitySection() {
    const { hasSubmitterVisibility } = this.state;
    return hasSubmitterVisibility === undefined
      ? this.drawSwitchLoader(
          'Loading submitter visibility...',
          'visibility...',
          'Loading visibility...'
        )
      : this.drawSwitch(
          'submitterVisibility',
          hasSubmitterVisibility,
          this.handleVisibilityChange
        );
  }

  drawShareableSection() {
    // when save a template, shareable attribute default value is true, that is why
    // we default it to true here
    const { shareable = true } = this.state;
    return this.drawSwitch('shareable', shareable, this.handleShareableChange);
  }

  drawInputLoader(
    label = 'Loading...',
    help = 'Content is loading...',
    placeholder = "I'm loading the content"
  ) {
    return (
      <Item label={label} hasFeedback validateStatus="validating" help={help}>
        <Input placeholder={placeholder} />
      </Item>
    );
  }

  drawSwitchLoader(
    label = 'Loading...',
    message = 'loading',
    help = 'Content is loading...'
  ) {
    return (
      <Item label={label} validateStatus="validating" help={help}>
        <Switch
          disabled
          defaultChecked={false}
          unCheckedChildren={message}
          loading
        />
      </Item>
    );
  }

  drawRoleConstraintsSelect(roles) {
    const { roleConstraints } = this.state;
    const selectedRoles = get(first(roleConstraints), 'roleConstraints');
    return this.drawMultiSelect(
      roles,
      'roleAssociation',
      'Please select roles',
      this.handleSelectRoleChange,
      selectedRoles
    );
  }

  drawSwitch(
    title,
    ischecked,
    operation,
    msgChecked = 'On',
    msgUnchecked = 'Off'
  ) {
    return (
      <Item label={toCapitalizedWords(title)}>
        {' '}
        {
          <Switch
            defaultChecked={ischecked}
            checkedChildren={msgChecked}
            unCheckedChildren={msgUnchecked}
            onChange={operation}
          />
        }
      </Item>
    );
  }

  drawMultiSelect(list, title, placeholder, operation, startingValues = []) {
    const { getFieldDecorator } = this.props.form;
    const fieldSettings = {
      initialValue: startingValues,
      rules: [],
    };
    const options = this.matchToOptionFrom(list);
    return (
      <Item label={toCapitalizedWords(title)}>
        {' '}
        {getFieldDecorator(
          title,
          fieldSettings
        )(
          <Select
            mode="multiple"
            placeholder={placeholder}
            onChange={operation}
          >
            {options}
          </Select>
        )}
      </Item>
    );
  }

  fetchSecuritySettings(templateId, agencyId) {
    const override = this.getSecuritySettingsFromLocalStorage();
    if (override) {
      return this.loadFromOverride(override, templateId);
    }
    if (!isNaN(templateId)) {
      return this.loadDataFromAPI(templateId, agencyId);
    }
    this.setState({
      hasSubmitterVisibility: false,
      initialVisibility: false,
      hasDenialList: false,
    });
  }

  loadFromOverride(override, templateId) {
    const {
      roleConstraints,
      hasDenialList,
      hasSubmitterVisibility,
      initialVisibility,
      shareable = true,
    } = override;
    const hasTemplateId = !isNaN(templateId);
    localStorage.removeItem(securitySettingsStorageKey);
    this.setState({
      roleConstraints,
      hasDenialList,
      hasSubmitterVisibility,
      initialVisibility,
      hasTemplateId,
      shareable,
    });
  }

  loadDataFromAPI(templateId, agencyId) {
    this.fetchTemplateVisibility(templateId, agencyId);
    this.fetchSubmitterVisibility();
    this.fetchdenialList();
  }

  matchToOptionFrom(list) {
    if (has(first(list), 'name')) {
      return list.map(element => (
        <Option key={list.indexOf(element)} value={element.name}>
          {element.name}
        </Option>
      ));
    }
    return list.map(element => (
      <Option key={list.indexOf(element)} value={element}>
        {element}
      </Option>
    ));
  }

  getSecuritySettingsFromLocalStorage() {
    const storedValue = JSON.parse(
      localStorage.getItem(securitySettingsStorageKey)
    );
    if (storedValue) {
      return this.processSecuritySettings(storedValue);
    }
    return undefined;
  }

  processSecuritySettings(storedValue) {
    const roleConstraints = storedValue.roles
      ? [{ roleConstraints: storedValue.roles }]
      : [{ roleConstraints: [] }];
    const hasDenialList = get(storedValue, 'denial', false);
    const hasSubmitterVisibility = get(storedValue, 'visibility', false);
    const initialVisibility = get(storedValue, 'initialVisibility', false);
    return {
      roleConstraints: roleConstraints,
      hasDenialList: hasDenialList,
      hasSubmitterVisibility: hasSubmitterVisibility,
      initialVisibility,
    };
  }

  async saveAll(values) {
    await this.setRoleVisibility(values.roleAssociation);
    await this.setDenialList(this.state.hasDenialList);
    await this.setSubmitterVisibility(this.state.hasSubmitterVisibility);
    this.setShareable(this.state.shareable);
    this.props.onClose();
  }

  async setRoleVisibility(roleAssociation) {
    const {
      match: {
        params: { agencyId, id: templateId },
      },
    } = this.props;
    return setTemplateVisibility(templateId, agencyId, roleAssociation);
  }

  async setSubmitterVisibility(submitterVisibilityValue = false) {
    const {
      match: {
        params: { agencyId, id: templateId },
      },
    } = this.props;
    const { initialVisibility } = this.state;
    if (initialVisibility !== submitterVisibilityValue) {
      return setTemplateSumitterVisibility(
        templateId,
        agencyId,
        submitterVisibilityValue
      );
    }
    return;
  }

  setShareable(shareable = true) {
    const {
      match: {
        params: { agencyId, id: templateId },
      },
    } = this.props;
    return apiEndpoints.setTemplateShareable(templateId, agencyId, shareable);
  }

  async setDenialList(denyList) {
    // const { match: { params: { agencyId, id: templateId } } } = this.props;
  }

  async fetchTemplateVisibility(templateId, agencyId) {
    const roleConstraints = await getTemplateVisibility(templateId, agencyId);
    this.setState({ roleConstraints: roleConstraints, hasTemplateId: true });
  }

  async fetchRoles(agencyId) {
    const roles = await securityEndpoints.getAgencySecurityRoles(agencyId);
    this.setState({ roles: roles });
  }

  async fetchSubmitterVisibility() {
    const {
      match: {
        params: { agencyId, id: templateId },
      },
    } = this.props;
    const reponse = await getTemplateSumitterVisibility(templateId, agencyId);
    const hasVisibility = get(first(reponse), 'submitterVisibility', false);
    this.setState({
      hasSubmitterVisibility: hasVisibility,
      initialVisibility: hasVisibility,
    });
  }

  async fetchdenialList() {
    const hasDenialList = await this.flagStub;
    this.setState({ hasDenialList: hasDenialList });
  }

  handleVisibilityChange = checked => {
    if (!this.state.hasTemplateId) {
      this.persistInStorage('visibility', checked);
    }
    this.setState({ hasSubmitterVisibility: checked });
  };

  handleShareableChange = checked => {
    if (!this.state.hasTemplateId) {
      this.persistInStorage('shareable', checked);
    }
    this.setState({ shareable: checked });
  };

  handleDenialChange = checked => {
    if (!this.state.hasTemplateId) {
      this.persistInStorage('denial', checked);
    }
    this.setState({ hasDenialList: checked });
  };

  handleSelectRoleChange = value => {
    if (!this.state.hasTemplateId) {
      this.persistInStorage('roles', value);
    }
  };

  persistInStorage(key, nextValue) {
    const storedValue = this.withdrawStoredItem();
    storedValue[key] = nextValue;
    localStorage.setItem(
      securitySettingsStorageKey,
      JSON.stringify(storedValue)
    );
  }

  withdrawStoredItem() {
    const storedValue = JSON.parse(
      localStorage.getItem(securitySettingsStorageKey)
    );
    return storedValue ? storedValue : {};
  }

  saveSecuritySettings = () => {
    this.props.form.validateFields(this.submitValues);
  };

  submitValues = (errors, values) => {
    if (!errors) {
      this.saveAll(values);
    }
  };

  flagStub = new Promise((resolve, reject) => {
    setTimeout(function() {
      resolve(false);
    }, 2000);
  });
}

// "withRouter" enables the component to access URL variables, location and history.
// "Form.create()" enables ant desgin form features in the component.
export default withRouter(Form.create()(SecuritySettingsDrawer));
