import { get } from 'lodash';

import { GET_RANKS_REQUEST, GET_RANKS_SUCCESS, GET_RANKS_ERROR } from './';

import organizationEndpoints from '../api/organization/organizationEndpoints';

export default (payload = {}, next = () => {}) => {
  return (dispatch, getState) => {
    const {
      session: { currentUser: { agencyId = '' } = {} },
    } = getState();
    dispatch({ type: GET_RANKS_REQUEST, payload });

    organizationEndpoints
      .getRanks(agencyId)
      .then(ranks => {
        dispatch({
          type: GET_RANKS_SUCCESS,
          payload: { ...payload, data: ranks },
        });
        next(null, ranks);
      })
      .catch(error => {
        dispatch({
          type: GET_RANKS_ERROR,
          payload: get(error, ['response', 'status']),
        });
        next(error);
      });
  };
};
