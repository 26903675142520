import React from 'react';
import { Row, Col, Select } from 'antd';
import { InlineInputWrapper } from '../../../components/input';

const Option = Select.Option;

const UserEquipmentModalCategoryInput = ({
  selectedCategory = '',
  categories,
  equipmentRecord,
  loading,
  onChangeSelected,
}) => {
  const { equipment: { id } = {} } = equipmentRecord;
  return (
    <Row>
      <Col xs={24} sm={12} md={12} lg={12}>
        <InlineInputWrapper
          label={'Category'}
          options={{
            labelCol: 'ant-col-xs-24 ant-col-sm-8 ant-col-md-6 ant-col-lg-6',
            inputCol: 'ant-col-xs-24 ant-col-sm-14 ant-col-md-16 ant-col-lg-16',
          }}
        >
          {selectedCategory && (
            <Select
              showSearch
              placeholder="Select a category"
              optionFilterProp="children"
              disabled={loading || !!id}
              onChange={onChangeSelected}
              filterOption={false}
              value={selectedCategory}
              className="full-width"
            >
              {Array.isArray(categories) &&
                categories.map(category => (
                  <Option key={category.id} value={category.id}>
                    {category.label}
                  </Option>
                ))}
            </Select>
          )}
        </InlineInputWrapper>
      </Col>
    </Row>
  );
};

export default UserEquipmentModalCategoryInput;
