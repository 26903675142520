import { createSelector } from 'reselect';

const getSelectedForm = createSelector(
  state => state.form,
  form => form.selectedForm
);

const getFormMeta = createSelector(
  getSelectedForm,
  selectedForm => selectedForm.meta
);

export const getParticipantsId = createSelector(
  getFormMeta,
  meta => meta.participantsId || []
);
