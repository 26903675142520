import styled from 'styled-components';

const ReportMetaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-tooltip.ant-custom-tooltip .ant-tooltip-content .ant-tooltip-inner {
    color: white;
    background-color: #575f67;
  }
`;

export default ReportMetaWrapper;
