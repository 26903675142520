import React from 'react';
import ContentLoader from 'react-content-loader';
import { Row, Col } from 'antd';

import {
  StyledDivider,
  StyledPanel,
  StyledPanelTitle,
} from './ReportCard.styled';
import ParagraphContainer from './ParagraphContainer.styled';

const LoadingReport = ({ showSecondLoadingLine = false }) => (
  <StyledPanel className="is-loader">
    <Row>
      <Col xs={24} sm={12}>
        <StyledPanelTitle>
          <div className="header-loader">
            <ContentLoader
              height={20}
              width={200}
              speed={2}
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            >
              <rect x="0" y="0" rx="5" ry="5" width="130" height="20" />
              <rect x="140" y="0" rx="5" ry="5" width="50" height="20" />
            </ContentLoader>
          </div>
        </StyledPanelTitle>
      </Col>
    </Row>

    <StyledDivider />

    <ParagraphContainer>
      <div className="body-loader">
        <ContentLoader
          height={40}
          width={400}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="0" y="0" rx="3" ry="3" width="350" height="10" />
          {showSecondLoadingLine && (
            <rect x="0" y="30" rx="3" ry="3" width="380" height="10" />
          )}
        </ContentLoader>
      </div>
    </ParagraphContainer>
  </StyledPanel>
);

export default LoadingReport;
