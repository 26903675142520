const getCustomFieldOptionsWithFallback = values => componentOptions => {
  const optionsWithFallback = Object.assign(
    {},
    {
      onChange(value, values) {
        const additionalValue =
          componentOptions.beforeOnChange &&
          componentOptions.beforeOnChange(value, values);
        if (additionalValue) {
          return {
            ...values,
            [this.name]: value,
            ...additionalValue,
          };
        } else {
          return {
            ...values,
            [this.name]: value,
          };
        }
      },
      onSave: values => values,
      beforeInitialValue: () => undefined,
      getInitialValue: () => undefined,
    },
    componentOptions
  );

  return Object.assign({}, optionsWithFallback, {
    ...['onSave', 'onChange'].reduce(
      (bindedMethods, methodName) => ({
        ...bindedMethods,
        [methodName]: optionsWithFallback[methodName].bind(optionsWithFallback),
      }),
      {}
    ),
    beforeInitialValue: optionsWithFallback.beforeInitialValue(
      optionsWithFallback
    ),
    initialValue: optionsWithFallback.getInitialValue.call(
      optionsWithFallback,
      values
    ),
  });
};

export default getCustomFieldOptionsWithFallback;
