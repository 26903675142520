import moment from 'moment-timezone';
import { FILTER_ARRAY_SEPARATOR, FILTER_RANGE } from './constants';

const getDateRangeFilter = (
  dateStrings = '',
  timezone,
  filterType = FILTER_RANGE
) => {
  const dates = dateStrings.split(FILTER_ARRAY_SEPARATOR);
  const dateFrom = moment.tz(dates[0], timezone);
  const dateTo = moment.tz(dates[1], timezone);
  const isValidRange =
    dates.length > 1 && dateFrom.isValid() && dateTo.isValid();
  const column = filterType === FILTER_RANGE ? 'submitted_at' : 'eventDate';

  return isValidRange
    ? [
        {
          column,
          operator: 'between',
          value: [dateFrom.format(), dateTo.format()],
        },
      ]
    : [];
};

export default getDateRangeFilter;
