import styled from 'styled-components';

const SectionEditor = styled.div`
  position: relative;
  min-height: 30px;
  margin-bottom: 5px;
  border: 1px solid #bebebe;
  border-radius: 10px;
  padding: 10px 3px;

  .show-reviewer {
    background-color: hsla(180, 100%, 25%, 0.1);
  }

  .hide-reviewer {
    background-color: hsla(0, 100%, 50%, 0.1);
  }

  .section-title {
    font-size: 12px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;

    .ant-col {
      width: 50%;
    }
  }

  .section-children {
    position: relative;
  }

  [class*='ant-row'] {
    margin-bottom: 5px;
  }
`;

export default SectionEditor;
