import store from '../store';

import { isDevelopmentEnv } from 'APP_ROOT/appVersion';
import { LOGIN_REQUEST } from '../actions';
import storage from '../utils/storage';

import { getMinifiedToken } from '../api/session';
import getUser from '../actions/get-user';
import loginSuccess from '../actions/login-success';

export default (token, userId, permissions = []) =>
  store.dispatch(async dispatch => {
    dispatch({ type: LOGIN_REQUEST });

    storage.set('token', token);
    try {
      const { token: minifiedToken } = await getMinifiedToken(userId);
      storage.set('minified-token', minifiedToken);
      dispatch(loginSuccess({ id: userId, permissions }));
      dispatch(getUser(userId));
    } catch (error) {
      // eslint-disable-next-line no-console
      isDevelopmentEnv && console.log(error);
    }
  });
