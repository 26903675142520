import { kebabCase, some, get, has } from 'lodash';
import { LOCATION_CHANGE } from 'react-router-redux';

import { EDIT_FORM, EDIT_FORM_SUCCESS } from '../../actions';

import selectedFormInitialState from './state/selected-form';

const events = {
  [EDIT_FORM]: state => {
    const selectedForm = {
      ...selectedFormInitialState,
      fetched: false,
    };

    return {
      ...state,
      selectedForm,
      isLoading: true,
    };
  },
  [EDIT_FORM_SUCCESS]: (
    state,
    {
      payload: {
        id,
        formType,
        data,
        actions,
        linkedSubmissions,
        onBehalf,
        ...meta
      },
    }
  ) => {
    const type = kebabCase(formType);

    const linkedFormSubmitted =
      linkedSubmissions.length > 0 && // has linked forms
      linkedSubmissions[0].actions &&
      linkedSubmissions[0].actions.length > 0; //the linked form has been submitted at least once

    const isPending = some(actions, action => action.status === 'pending');
    const formData = isPending
      ? get(
          actions.filter(action => action.status === 'pending'),
          [0, 'data'],
          data
        )
      : data;

    const userList = formData.__users;
    const newUsers = Object.keys(userList).reduce((users, userId) => {
      if (
        has(userList[userId], ['height']) &&
        has(userList[userId], ['weight'])
      ) {
        users[userId] = {
          ...userList[userId],
          id: userList[userId].id + [],
          height: userList[userId].height + [],
          weight: userList[userId].weight + [],
        };
      } else {
        users[userId] = {
          ...userList[userId],
          id: userList[userId].id + [],
        };
      }
      return users;
    }, {});

    const selectedForm = {
      ...selectedFormInitialState,
      data: {
        ...formData,
        __users: newUsers,
        linkedFormSubmitted, //adding to data is necessary so it can be used in readonly conditions
      },
      actions,
      meta: {
        ...meta,
        linkedSubmissions,
        formType,
        id,
      },
      id,
      template: type,
      presentation: type,
      fetched: true,
      onBehalf,
    };

    return {
      ...state,
      selectedForm,
      isLoading: false,
    };
  },
  [LOCATION_CHANGE]: state => {
    return {
      ...state,
      selectedForm: selectedFormInitialState,
      loading: true,
    };
  },
};

export default events;
