import React, { Component } from 'react';
import cx from 'classnames';
import { get, keys } from 'lodash';

import LabelWrapper from '../styled/form-label-editor';
import ChangesTracker, {
  TrackingChangeTypes,
} from '../../../containers/administrator/services/changesTracker';

class EditorLabel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: ChangesTracker.activeField === props.id,
      hasUnpublishedChanges: false,
      hasPublishedChanges: false,
      label: null,
    };
  }

  componentDidMount() {
    this.activeFieldSuscription = ChangesTracker.getActiveField().subscribe(
      this.setActiveIfMatches
    );

    this.changesTrackerSuscription = ChangesTracker.getUnpublishedChanges().subscribe(
      this.checkIfHasChanges
    );

    this.onPublishChangeSuscription = ChangesTracker.getPublishedChanges().subscribe(
      this.updateField
    );
  }

  componentWillUnmount() {
    this.activeFieldSuscription.unsubscribe();
    this.changesTrackerSuscription.unsubscribe();
    this.onPublishChangeSuscription.unsubscribe();
  }

  updateField = () => {
    const { id } = this.props;

    const hasChanges = ChangesTracker.hasPublishedFieldChanges(
      id,
      TrackingChangeTypes.PROPERTY_CHANGE
    );
    const nextTitle = this.getChangedLabel();

    if (hasChanges) {
      this.setState({ label: nextTitle });
    }

    this.setState({ hasPublishedChanges: hasChanges });
  };

  getChanges = () => {
    const { id } = this.props;

    const fieldChanges = ChangesTracker.getPublishedFieldChanges(
      id,
      TrackingChangeTypes.PROPERTY_CHANGE
    );

    return fieldChanges;
  };

  getChangedLabel = () => {
    const fieldChanges = this.getChanges();

    return get(fieldChanges, 'data.title');
  };

  setActiveIfMatches = activeFieldId => {
    const { id } = this.props;

    const isActive = activeFieldId === id;

    if (this.state.isActive !== isActive) {
      this.setState({ isActive });
    }
  };

  checkIfHasChanges = a => {
    const { id } = this.props;
    const { hasUnpublishedChanges: hasChanges } = this.state;

    const hasPropertyChanges = keys(TrackingChangeTypes).some(key =>
      ChangesTracker.hasUnpublishedFieldChanges(id, key)
    );

    if (hasChanges !== hasPropertyChanges) {
      this.setState({ hasUnpublishedChanges: hasPropertyChanges });
    }
  };

  handleClick = e => {
    const { onFieldEditorClick } = this.props;

    onFieldEditorClick(e);
  };

  render() {
    const { children, disabled } = this.props;
    const {
      isActive,
      hasUnpublishedChanges,
      hasPublishedChanges,
      label,
    } = this.state;

    return (
      <LabelWrapper
        className={cx({
          'is-disabled': disabled,
          'is-active': isActive,
          'has-changes': hasUnpublishedChanges,
        })}
        onClick={this.handleClick}
      >
        {hasPublishedChanges ? label : children}
      </LabelWrapper>
    );
  }
}

export default EditorLabel;
