import React from 'react';

export const SECTION_TYPE_FORM = 'form';
export const SECTION_TYPE_TABLE = 'table';

export const ATTRIBUTE_TYPE_STRING = 'string';
export const ATTRIBUTE_TYPE_TEXTAREA = 'text';
export const ATTRIBUTE_TYPE_DATE = 'date';
export const ATTRIBUTE_TYPE_LIST = 'list';
export const ATTRIBUTE_TYPE_DATETIME = 'datetime';
export const ATTRIBUTE_TYPE_TIME = 'time';
export const ATTRIBUTE_TYPE_NUMBER = 'number';
export const ATTRIBUTE_TYPE_SWITCH = 'boolean';

export const ATTRIBUTE_TYPE_BOOL = 'bool';
export const ATTRIBUTE_TYPE_LINK = 'link';
export const ATTRIBUTE_TYPE_TREE = 'tree';

export const ATTRIBUTE_TYPE_BOOL_FALSE = 'false';

export const ORGANIZATION_HISTORY_CONFIG = 'organization';
export const EMPLOYMENT_HISTORY_CONFIG = 'employment';

export const FULL_ROW_WIDTH = 24;
export const HALF_ROW_WIDTH = 12;

export function SaveSectionWarning() {
  return (
    <em>
      {' '}
      Changes to this section <strong>are not saved</strong> until you &#32;
      <strong>Save</strong>&#32; on the main page.{' '}
    </em>
  );
}
