import callback from '../../utils/callback';
import trainingEndpoints from '../../../../api/training/trainingEndpoints';

export default ({
  tenantId,
  userId,
  pageNumber,
  pageSize,
  sort,
  filters,
  next,
}) => _dispatch => {
  return trainingEndpoints
    .getUserTrainingData(tenantId, userId, pageNumber, pageSize, sort, filters)
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
