import { DELETE_FORM_ATTACHMENT } from './';
import { deleteFormSubmissionAttachment } from '../api/attachments';
import deleteFormAttachmentSuccess from './delete-form-attachment-success';
import deleteFormAttachmentError from './delete-form-attachment-error';
import removeRepeaterItem from './remove-repeater-item';

export default (
  {
    kind = '',
    formId = '',
    field = '',
    attachmentId = '',
    singleUpload = false,
  },
  next = () => {}
) => (dispatch, getState) => {
  dispatch({
    type: DELETE_FORM_ATTACHMENT,
    payload: { kind, formId, field, attachmentId },
  });
  return deleteFormSubmissionAttachment({
    formSubmissionId: formId,
    id: attachmentId,
  })
    .then(() => {
      dispatch(
        deleteFormAttachmentSuccess({
          attachmentId,
          formId,
          field,
          kind,
          singleUpload,
        })
      );
      if (!singleUpload) {
        const {
          form: {
            selectedForm: { data },
          },
        } = getState();
        const storeAttachments = data[field] || [];
        const indexToRemove = storeAttachments.findIndex(
          a => a.id === attachmentId
        );
        if (indexToRemove >= 0) {
          dispatch(removeRepeaterItem(field, indexToRemove));
        }
      }
      next();
    })
    .catch(error => {
      dispatch(deleteFormAttachmentError(error));
      next(error);
    });
};
