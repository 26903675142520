import styled from 'styled-components';

const UploadEditor = styled.div`
  padding: 2px;

  .show-reviewer {
    background-color: hsla(180, 100%, 25%, 0.1);
  }

  .hide-reviewer {
    background-color: hsla(0, 100%, 50%, 0.1);
  }

  &.is-dragging {
    opacity: 0.3;
  }

  .single-upload--header,
  .upload--header {
    border: 1px solid lightgray;
    border-bottom: none;

    &:hover {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
    }

    .type {
      .ant-tag {
        font-size: 9px;
        margin: 2px 0 0 2px;
      }

      margin-bottom: 0;
    }

    .title {
      padding-bottom: 0;
    }

    .actions {
      .ant-btn {
        font-size: 12px;
      }
    }
  }

  .single-upload--detail,
  .upload--detail {
    border: 1px solid lightgray;
    border-top: none;
    background-color: #eaeaea;
    pointer-events: none;

    i {
      color: darkgray;
    }
  }

  .single-upload--header:hover,
  .single-upload--header:hover + .single-upload--detail,
  .upload--header:hover,
  .upload--header:hover + .upload--detail {
    border-color: blue;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid lightgray;
    margin: 0;
    padding: 0;
  }
`;

export default UploadEditor;
