import React, { Component } from 'react';
import { get, find } from 'lodash';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';

import { getTrainingTaskTimeline } from '../../../api/agencies';
import {
  getFormSubmissionId,
  getFormParticipants,
  getTaskName,
} from 'APP_ROOT/selectors/form';
import propsHasChanged from '../../../utils/propsHasChanged';
import FormHistoryTimeline from '../../form-history-timeline';
import createModal from '../../../actions/create-modal';
import updateModal from '../../../actions/update-modal';
import showModal from '../../../actions/show-modal';
import ModalTitle from '../../common/modal/title';
import ModalBody from '../../common/modal/body';
import formActionDispatcher from 'APP_ROOT/utils/formDispatchEmitter';

const modalId = 'activity-timeline';

class TrainingTaskTimeline extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(this.props, nextProps);
  }

  getAgencyId = props => get(props, 'settings.agencyId', '');

  openModal = async () => {
    const { submissionId, participants, taskName } = this.props;
    const filter = { taskName };
    const agencyId = this.getAgencyId(this.props);

    this.openLoadingModal();

    if (submissionId) {
      try {
        const [response] = await getTrainingTaskTimeline(
          agencyId,
          submissionId,
          filter
        );

        this.createModal(response, participants);
      } catch (_) {
        // do
      }
    } else {
      this.createModal(null, participants);
    }
  };

  openLoadingModal = () => {
    const {
      options: { titleModal = 'Activity Timeline' },
    } = this.props;

    const Title = (
      <ModalTitle>
        <span>{titleModal}</span>
      </ModalTitle>
    );

    const Body = (
      <ModalBody>
        <ContentLoader
          height={120}
          width={400}
          speed={2}
          primaryColor={'#f3f3f3'}
          secondaryColor={'#ecebeb'}
          className="loader-animation"
        >
          <circle cx="15" cy="15" r="10.16" />
          <rect x="35" y="10" rx="5" ry="5" width="90" height="10" />
          <rect x="135" y="10" rx="5" ry="5" width="20" height="10" />
          <rect x="162" y="10" rx="5" ry="5" width="30" height="10" />
          <rect x="35" y="30" rx="5" ry="5" width="115" height="10" />
          <rect x="160" y="30" rx="5" ry="5" width="180" height="10" />

          <circle cx="15" cy="75" r="10.16" />
          <rect x="35" y="70" rx="5" ry="5" width="90" height="10" />
          <rect x="135" y="70" rx="5" ry="5" width="20" height="10" />
          <rect x="162" y="70" rx="5" ry="5" width="30" height="10" />
          <rect x="35" y="90" rx="5" ry="5" width="115" height="10" />
          <rect x="160" y="90" rx="5" ry="5" width="180" height="10" />
        </ContentLoader>
      </ModalBody>
    );

    const updateModalAction = this.modalId ? updateModal : createModal;

    this.modalId = modalId;

    this.dispatch(
      updateModalAction({
        id: modalId,
        visible: true,
        title: Title,
        children: Body,
      })
    );

    this.dispatch(showModal(this.modalId));
  };

  createModal = (timeline, participants) => {
    const {
      timezone,
      options: { titleModal = 'Timeline' },
      taskName,
    } = this.props;
    if (!timeline) {
      timeline = {
        history: [],
      };
    }
    const history = timeline.history.map(item => {
      const participant = find(participants, _item => _item.id === item.userId);
      return {
        ...item,
        user: `${get(participant, 'rank.name')} ${get(
          participant,
          'fullName'
        )}`,
        type: 'activity',
        activity: item.id,
        taskValue: item.taskValue,
        taskName,
      };
    });
    const Title = (
      <ModalTitle>
        <span>{titleModal}</span>
      </ModalTitle>
    );

    const Body = (
      <ModalBody>
        {history.length > 0 && (
          <FormHistoryTimeline history={history} timezone={timezone} />
        )}
        {history.length === 0 && <p>No records found</p>}
      </ModalBody>
    );

    this.dispatch(
      updateModal({
        id: this.modalId,
        visible: true,
        title: Title,
        children: Body,
      })
    );

    this.dispatch(showModal(this.modalId));
  };

  dispatch = action => {
    const { settings } = this.props;

    formActionDispatcher(get(settings, 'formName'), action);
  };

  render() {
    const {
      options: { linkLabel = 'Timeline' },
      className,
    } = this.props;
    return (
      <div className={className}>
        <a className="link-timeline" onClick={this.openModal}>
          {linkLabel}
        </a>
      </div>
    );
  }
}

const mapState = (state, props) => {
  return {
    submissionId: getFormSubmissionId(state, props),
    participants: getFormParticipants(state, props),
    taskName: getTaskName(state, props),
  };
};

export default connect(mapState)(TrainingTaskTimeline);
