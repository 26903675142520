import moment from 'moment';

import {
  FETCH_ACTIONS_REQUEST,
  FETCH_ACTIONS_SUCCESS,
  FETCH_ACTIONS_ERROR,
  REFRESH_ACTIONS_REQUEST,
  REFRESH_ACTIONS_SUCCESS,
  REFRESH_ACTIONS_ERROR,
  FETCH_ACTIONS_COUNT_SUCCESS,
} from '../../../actions';

export default (initialState = {}) => ({
  [FETCH_ACTIONS_REQUEST]: state => ({
    ...state,
    actions: {
      ...state.actions,
      loading: true,
    },
  }),
  [FETCH_ACTIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    actions: {
      ...state.actions,
      loading: false,
      error: null,
      fetched_at: moment().unix(),
      data: payload.map(item => item.id),
      entries: payload.reduce(
        (result, item) => ({
          ...result,
          [item.id]: item,
        }),
        {}
      ),
    },
  }),
  [FETCH_ACTIONS_ERROR]: (state, { payload: error }) => ({
    ...state,
    actions: {
      ...state.actions,
      loading: false,
      error,
    },
  }),
  [REFRESH_ACTIONS_REQUEST]: state => ({
    ...state,
    actions: {
      ...state.actions,
      loading: false,
      refreshing: true,
    },
  }),
  [REFRESH_ACTIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    actions: {
      ...state.actions,
      refreshing: false,
      loading: false,
      error: null,
      refreshed_at: moment().unix(),
      data: payload.map(item => item.id),
      entries: payload.reduce(
        (result, item) => ({
          ...result,
          [item.id]: item,
        }),
        {}
      ),
    },
  }),
  [REFRESH_ACTIONS_ERROR]: (state, { payload: error }) => ({
    ...state,
    actions: {
      ...state.actions,
      refreshing: false,
      loading: false,
      error,
    },
  }),
  [FETCH_ACTIONS_COUNT_SUCCESS]: (state, { payload: { count: total } }) => ({
    ...state,
    actions: {
      ...state.actions,
      total,
    },
  }),
});
