import React from 'react';

import { Link } from 'react-router-dom';
import getRoute from 'APP_ROOT/utils/get-route';
import parseDate, {
  BENCHMARK_DATE_FORMAT_HUMAN,
} from '../../../../../utils/parse-date';
import WrapperUserAssignment from './UserAssignment.styled';

const UserAssignment = ({
  employmentType,
  assignment = [],
  hireDate,
  endOfDetailDate,
  isDetail,
  tourOfDuty,
  timezone,
}) => {
  //Remove the first OU, it corresponds to the level 2.
  //The tenant root is not a level of the assignment.
  assignment.shift();
  const lastIndex = assignment.length - 1;
  const employmentTypeLabel =
    employmentType && assignment.length > 0
      ? `${employmentType}:   `
      : 'No Active Assignments';

  const getSuffix = isLast => {
    if (!isLast) {
      return '';
    }
    const tourOfDutyLabel = tourOfDuty ? `| ${tourOfDuty}` : '';
    return isDetail
      ? `(
          ${parseDate(hireDate, timezone, BENCHMARK_DATE_FORMAT_HUMAN)} - 
          ${parseDate(endOfDetailDate, timezone, BENCHMARK_DATE_FORMAT_HUMAN)}
          ) ${tourOfDutyLabel}`
      : ` ${tourOfDutyLabel}`;
  };

  return (
    <WrapperUserAssignment>
      <div className="user-assignment-container">
        <div
          data-show="true"
          className={`ant-alert ${
            isDetail ? 'user-assignment-detail' : 'user-assignment-normal'
          } ant-alert-no-icon`}
          role="alert"
        >
          <div className="ant-alert-content">
            <div className="ant-breadcrumb">
              <span key={`link-${employmentTypeLabel}`}>
                <span className="user-assignment-action ant-breadcrumb-link">
                  {employmentTypeLabel}
                </span>
              </span>
              {assignment.map((unit, i) => (
                <span key={`link-${unit.value}`}>
                  <span className="user-assignment ant-breadcrumb-link">
                    <Link
                      to={getRoute('organizationProfile', {
                        organizationalUnitId: unit.value,
                      })}
                    >
                      {`${unit.label} ${getSuffix(i == lastIndex)}`}
                    </Link>
                  </span>
                  <span className="user-assignment ant-breadcrumb-separator">
                    /
                  </span>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </WrapperUserAssignment>
  );
};

export default UserAssignment;
