import { FETCH_DOR_FORM_REQUEST, SET_FORM_VALUES } from './';
import { getDORReport } from '../api/reports';
import { get, has, find, pick, isEmpty } from 'lodash';

const KEY_TRAINING_TASK = 'trainingTaskCategories';

export default (id, evaluateTrainingTask = false, next = () => {}) => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_DOR_FORM_REQUEST });
    getDORReport(id)
      .then(response => {
        let calculatedData;
        if (evaluateTrainingTask && has(response.data, KEY_TRAINING_TASK)) {
          const {
            form: {
              selectedForm: { data = {} },
            },
          } = getState();

          const currentTrainingTaskCategories = get(data, KEY_TRAINING_TASK);
          const newTrainingTaskCategories = get(
            response.data,
            KEY_TRAINING_TASK
          );

          const resetTrainingTaskCategories = isEmpty(
            newTrainingTaskCategories
          );

          const calculatedTrainingTaskCategories = currentTrainingTaskCategories.map(
            currentItem => {
              const lastItem = find(newTrainingTaskCategories, {
                taskName: currentItem.taskName,
              });

              if (resetTrainingTaskCategories) {
                return pick(currentItem, ['value', 'id', 'taskName']);
              }

              return {
                ...currentItem,
                ...lastItem,
              };
            }
          );

          calculatedData = {
            ...response.data,
            trainingTaskCategories: calculatedTrainingTaskCategories,
          };
        }

        const data = calculatedData ? calculatedData : response.data;

        dispatch({
          type: SET_FORM_VALUES,
          payload: data,
        });
        next(null, response);
      })
      .catch(error => {
        next(error);
      });
  };
};
