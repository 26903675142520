import styled from 'styled-components';
import { Radio, Divider } from 'antd';

const { Group } = Radio;

export const RadioStyled = styled(Radio)`
  display: block;
  height: 30px;
  line-height: 30px;
`;

export const RadioGroupStyled = styled(Group)`
  padding: 0 40px;
`;

export const ButtonGroupStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
`;
export const DividerStyled = styled(Divider)`
  margin: 10px 0 10px 0;
`;
