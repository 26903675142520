import getOverrides from 'APP_ROOT/utils/get-field-overrides';

const getFormFieldLayout = props => {
  const { overrides = {} } = props;

  const getFieldOverrides = getOverrides(props, overrides);
  const options = getFieldOverrides('options', {});

  const {
    labelCol = { md: 8, sm: 10, xs: 24 },
    mode = 'horizontal',
    wrapperCol = { md: 16, sm: 14, xs: 24 },
  } = options;

  return mode === 'horizontal'
    ? {
        labelCol,
        wrapperCol,
      }
    : null;
};

export default getFormFieldLayout;
