import { PERMISSIONS } from '../../../utils/admin';

export const VIEW_ALL = 0;
export const VIEW_USER = 1;
export const ADD_USER = 2;

export const CREATE_PERMISSIONS = [
  PERMISSIONS.createAnyUser,
  PERMISSIONS.createAgencyUser,
];

export const UPDATE_PERMISSIONS = [
  PERMISSIONS.updateAnyUser,
  PERMISSIONS.updateAgencyUser,
];

export const VIEW_PERMISSIONS = [
  PERMISSIONS.listUsersByHK,
  PERMISSIONS.viewAllUserProfiles,
];

export const CREATE_FROM_OU = 'create-from-ou';
export const CREATE_DEFAULT = 'create';
export const VIEW_ALL_USERS = 'viewAll';
