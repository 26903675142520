import styled from 'styled-components';
import { Button, Modal } from 'antd';

const StyledModal = styled(Modal)`
  &.add-task-modal {
    .ant-modal-title {
      font-weight: bold;
      font-size: 16px;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const RemoveButton = styled(Button)`
  &:disabled {
    background-color: #ededed;
    color: #4e4e4e;
  }
`;

export { StyledModal, RemoveButton };
