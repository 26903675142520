import {
  ATTRIBUTE_TYPE_BOOL,
  ATTRIBUTE_TYPE_DATE,
} from '../../UserProfile.constants';
import moment from 'moment';

const getDefaultFieldData = field => {
  switch (field.type) {
    case ATTRIBUTE_TYPE_DATE:
      return field.defaultValue === 'now' ? moment() : field.defaultValue;
    case ATTRIBUTE_TYPE_BOOL:
      return field.defaultValue === 'true';
    default:
      return field.defaultValue;
  }
};

export default getDefaultFieldData;
