import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';

import getOverrides from 'APP_ROOT/utils/get-field-overrides';

import ConditionsAssert from './conditional';
import Field from './FormField';
import {
  getSelectedFormChangedFields,
  getFormValidationState,
  getFormTemplate,
  getDataEnums,
} from 'APP_ROOT/selectors/form';

import { getFieldSelector } from 'APP_ROOT/utils/renderSchema';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';
import getFieldIsDisabled from './FormField/getFieldIsDisabled';

class FieldWrapper extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  getGroupData = () => {
    const {
      data = {},
      parentIndex = 0,
      parentKey = '',
      isReviewer = false,
      syncFrom,
    } = this.props;

    const hasParent = !!parentKey;

    return {
      ...(hasParent ? data[parentKey][parentIndex] : data),
      syncFrom: get(data, [syncFrom, parentIndex]),
      index: parentIndex,
      humanReadableIndex: parentIndex + 1,
      isReviewer,
    };
  };

  labelComponent = ({ children }) => <span>{children}</span>;

  getRenderField = (shouldShow = false, behavior = 'hide') => (
    <Field
      {...this.props}
      shouldShow={shouldShow}
      behavior={behavior}
      labelComponent={this.labelComponent}
    />
  );

  render() {
    const {
      data = {},
      dataEnums,
      dataKey = '',
      overrides = {},
      parentIndex = 0,
      parentKey = '',
      form = {},
      autoResetFieldValues = true,
      type,
      field_type,
      settings,
      properties,
    } = this.props;

    const hasParent = !!parentKey;
    const getFieldOverrides = getOverrides(this.props, overrides);
    const conditions = getFieldOverrides('conditions', {});
    const contextPath = hasParent ? `${parentKey}[${parentIndex}].` : '';
    const fieldDataKey = `${contextPath}${dataKey}`;
    const rules = get(conditions, 'rules');
    const hasRules = rules && Array.isArray(rules) && rules.length > 0;
    const groupData = this.getGroupData();
    const disabled = getFieldIsDisabled(this.props);
    const errorsState = get(
      this.props,
      `validationState.sections[${this.props.tab}].errors`,
      []
    );
    const hasErrors = Object.keys(errorsState).some(
      field => field === fieldDataKey
    );

    if (!hasRules) {
      return this.getRenderField(true);
    }

    return (
      <ConditionsAssert
        properties={properties}
        type={type}
        field_type={field_type}
        settings={settings}
        conditions={conditions}
        data={groupData}
        wholeData={data}
        parentIndex={parentIndex}
        parentKey={parentKey}
        field={fieldDataKey}
        dataEnums={dataEnums}
        render={this.getRenderField}
        disabled={disabled}
        form={form}
        autoResetFieldValues={autoResetFieldValues}
        needRefresh={hasErrors}
      />
    );
  }
}

const mapState = (state, props) => {
  const dataSelector = getFieldSelector(props);
  const formTemplate = getFormTemplate(state, props);

  return {
    data: dataSelector(state, props),
    changedFields: getSelectedFormChangedFields(state, props),
    validationState: getFormValidationState(state, props),
    dataEnums: getDataEnums(state, props, formTemplate),
  };
};

export default connect(mapState)(FieldWrapper);
