import { get } from 'lodash';

const getGroupData = props => {
  const {
    data = {},
    parentIndex = 0,
    parentKey = '',
    isReviewer = false,
    syncFrom,
  } = props;

  const hasParent = !!parentKey;

  return {
    ...(hasParent ? data[parentKey][parentIndex] : data),
    syncFrom: get(data, [syncFrom, parentIndex]),
    index: parentIndex,
    humanReadableIndex: parentIndex + 1,
    isReviewer,
  };
};

export default getGroupData;
