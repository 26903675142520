import urlBuilder from 'APP_ROOT/utils/url-builder';
import { genOptions, route, fetchRequest } from 'APP_ROOT/utils/request';
import storage from 'APP_ROOT/utils/storage';

import getUploadURL from './getUploadURL';

/**
 * Custom method that sends file to S3.
 * By default, antd (ant design) sends a POST request on their Upload element
 * and S3 needs a PUT request + with no authorization headers
 * @param antdUploadElementData
 */
const uploadOverride = props => async antdUploadElementData => {
  const { meta: { id = '' } = {} } = props;
  const { file, onError, onProgress, onSuccess } = antdUploadElementData;
  const { postUrl, attachmentId } = await getUploadURL(
    props,
    file.name,
    file.type,
    file.size
  );

  onProgress({ percent: 0 });
  const xhr = new XMLHttpRequest();
  const response = await new Promise(resolve => {
    xhr.upload.addEventListener('progress', event => {
      if (event.lengthComputable) {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    });
    xhr.addEventListener('loadend', () => {
      resolve(xhr.readyState === 4 && xhr.status === 200);
    });
    xhr.open('PUT', postUrl, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
  if (response) {
    // S3 uploads do not return node-api attachment record
    const options = genOptions('GET', {}, storage.get('token'));
    const getAttachmentRoute = route(
      urlBuilder('/Forms/:id/attachments/:attachmentId', {
        id,
        attachmentId,
      })
    );
    const attachment = await fetchRequest(getAttachmentRoute, options);
    onSuccess(attachment);
  } else {
    onError(new Error('unable to get a response'));
  }
};

export default uploadOverride;
