import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Select, Spin } from 'antd';
import { flatten, get, isEmpty, debounce, uniq } from 'lodash';

import { getUsers, shareReport, unshareReport } from '../services/api';
import ModalBody from 'APP_ROOT/components/common/modal/body';
import { getCurrentUser } from 'APP_ROOT/selectors/session';
import { getFormMeta, getSelectedFormData } from 'APP_ROOT/selectors/form';
import { filterByIds } from '../utils/networkRequestFilters';
import ChangesReportSharing from '../services/changesReportSharing';
import { formItemLayout } from '../constants/modal';
import openConfirm from '../actions/openConfirm';
import {
  getShare,
  getReportId,
  getAgencyId,
  getRank,
  getFullName,
  getReportName,
  getListOfNames,
  getListToShareUnshare,
  getListNamesToShareUnshare,
} from '../utils/utils';
import updateShare from '../actions/updateShare';
import { getTemplateVisibility } from '../../../api/template-visibility';

import updateTimeline from '../actions/updateTimeline';
import { getTemplates } from '../../../selectors/form';
import organizationEndpoints from '../../../api/organization/organizationEndpoints';
import { hasPermissions, PERMISSIONS } from '../../../utils/admin';

const FormItem = Form.Item;
const Option = Select.Option;

class Modal extends Component {
  state = {
    data: [],
    usersSelected: [],
    contributorUsers: [],
    fetching: false,
    initialUsersSelected: [],
    added: [],
    shares: [],
  };

  async componentWillMount() {
    const {
      meta: { templateId },
      user: { agencyId },
    } = this.props;
    const roles = await getTemplateVisibility(templateId, agencyId);
    this.setState({ roles });
  }

  componentDidMount() {
    this.submitSubscription = ChangesReportSharing.getSubmit().subscribe(
      data => data && this.submit()
    );

    this.isOpenModalSubscription = ChangesReportSharing.getIsOpenModal().subscribe(
      data => {
        // reset state when open to make sure we are in a valid state
        // from previous call, open -> close -> open
        data && this.resetState();
        data && this.getUsersSelected();
      }
    );
  }

  componentWillUnmount() {
    this.submitSubscription.unsubscribe();
    this.isOpenModalSubscription.unsubscribe();
  }

  resetState() {
    this.setState({
      data: [],
      usersSelected: [],
      contributorUsers: [],
      fetching: false,
      initialUsersSelected: [],
      added: [],
      shares: [],
    });
  }

  getUsers = async (filter = '', roles = []) => {
    const permissions = get(this.props, 'user.permissions', []);
    const agencyId = getAgencyId(this.props);
    // is for a drop down, I don't want to page, get them all
    const pageSize = 50;
    const pageNumber = 0;
    let users;
    // only users with listUserByHK permission can get OU-Users
    const useOUScope = hasPermissions(permissions, [PERMISSIONS.listUsersByHK]);
    users = await organizationEndpoints.getUsersSearch(
      agencyId,
      {
        roleNames: roles.join(','),
        pageSize,
        pageNumber,
        fullName: filter,
      },
      useOUScope,
      false,
      ['benchmarkId', 'employeeId', 'starNumber'],
      false
    );
    return users;
  };

  fetchUser = debounce(async (value = '') => {
    const { contributorUserIds = [] } = this.props;
    const { roles } = this.state;
    this.setState({ fetching: true });
    const users = await this.getUsers(
      value,
      (roles[0] && roles[0].roleConstraints) || []
    );
    const data = users.map(user => ({
      label: getFullName(user),
      key: user.id,
      rank: getRank(user),
      disabled: contributorUserIds.includes(user.id),
    }));
    this.setState({ data, fetching: false });
  }, 900);

  getUsersSelected = async () => {
    const { contributorUserIds = [] } = this.props;
    this.setState({ fetching: true });
    const share = getShare(this.props);
    this.setState({ shares: share.shares });
    const shares = flatten(share.shares.map(({ shareToId }) => shareToId));
    // for some reason that I don't know yet, sometimes shares object
    // doesn't have the contributors assigned to the report, so concat
    // them to make sure we have the complete list of shares
    const ids = uniq(shares.concat(contributorUserIds));
    if (ids.length > 0) {
      const filter = filterByIds(ids);
      const agencyId = getAgencyId(this.props);
      const response = await getUsers(agencyId, filter);
      const usersFetched = response.map(user => ({
        label: getFullName(user),
        id: user.id,
        key: user.id.toString(),
        rank: getRank(user),
      }));
      const usersSelected = usersFetched.filter(
        u => !contributorUserIds.includes(u.id)
      );
      const contributorUsers = usersFetched.filter(u =>
        contributorUserIds.includes(u.id)
      );
      this.setState({
        usersSelected,
        contributorUsers,
        fetching: false,
        initialUsersSelected: usersSelected,
      });
    }
  };

  submitShare = async () => {
    const { usersSelected, initialUsersSelected } = this.state;
    const id = getReportId(this.props);
    const { added } = this.state;
    const usersToShare = getListToShareUnshare(
      usersSelected,
      initialUsersSelected
    );
    if (usersToShare.length === 0) return '';
    const response = await shareReport(id, usersToShare);
    this.addShared(response);
    this.updateTimeline(response);
    const namesToShare = getListOfNames(response.shareToId, added);
    return namesToShare;
  };

  closeModal = namesToShare => {
    const { dispatch } = this.props;
    const { shares, initialUsersSelected, usersSelected } = this.state;
    const reportName = getReportName(this.props);
    const usersToUnshare = isEmpty(initialUsersSelected)
      ? []
      : getListNamesToShareUnshare(initialUsersSelected, usersSelected);
    ChangesReportSharing.setSubmit(false);
    ChangesReportSharing.setCloseModal(false);
    if (!isEmpty(namesToShare) || !isEmpty(usersToUnshare)) {
      dispatch(openConfirm({ namesToShare, reportName, usersToUnshare }));
      dispatch(updateShare(shares));
    }
    this.resetState();
  };

  addShared = item => {
    const { shares } = this.state;
    this.setState({ shares: [...shares, item] });
  };

  updateTimeline = item => {
    const { dispatch } = this.props;
    const timelineHistory = {
      ...item,
      type: 'share',
    };
    dispatch(updateTimeline(timelineHistory));
  };

  removeShared = ids => {
    const { shares } = this.state;
    const updateShares = shares
      .map(share => {
        const shareToId = share.shareToId.filter(id => !ids.includes(id));
        return {
          ...share,
          shareToId,
        };
      })
      .filter(share => share.shareToId.length > 0);
    this.setState({ shares: updateShares });
  };

  submitUnshare = async () => {
    const { usersSelected, initialUsersSelected } = this.state;
    const id = getReportId(this.props);
    const usersToUnshare = getListToShareUnshare(
      initialUsersSelected,
      usersSelected
    );
    if (usersToUnshare.length > 0) {
      const response = await unshareReport(id, usersToUnshare);
      this.updateTimeline(response);
      this.removeShared(usersToUnshare);
    }
  };

  submit = async () => {
    const namesToShare = await this.submitShare();
    await this.submitUnshare();
    this.closeModal(namesToShare);
  };

  onSelect = value => {
    const { data, added } = this.state;
    const selected = data.filter(
      user => Number(user.key) === Number(value.key)
    );
    this.setState({
      added: [...added, ...selected],
    });
  };

  handleChange = usersSelected => {
    this.setState({
      usersSelected,
      data: [],
      fetching: false,
    });
  };

  render() {
    const { fetching, data, usersSelected, contributorUsers = [] } = this.state;
    const contributors = contributorUsers.map(u => u.label);
    const hasContributors = contributors.length > 0;

    return (
      <ModalBody>
        <Form>
          <FormItem {...formItemLayout} label="Share report" colon={false}>
            <Select
              mode="multiple"
              labelInValue
              value={usersSelected}
              placeholder="Please select"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSelect={this.onSelect}
              onFocus={this.fetchUser}
              onSearch={this.fetchUser}
              onChange={this.handleChange}
              style={{ width: '100%' }}
            >
              {data.map(d => (
                <Option key={d.key} disabled={d.disabled}>
                  {d.label}
                </Option>
              ))}
            </Select>
          </FormItem>
          {hasContributors && (
            <FormItem {...formItemLayout} label="Contributor(s)" colon={false}>
              {contributors.join(', ')}
            </FormItem>
          )}
        </Form>
      </ModalBody>
    );
  }
}

const mapState = (state, props) => {
  const { __assignedSections = [] } = getSelectedFormData(state);
  const contributorUserIds = __assignedSections
    .filter(s => !!s.userId)
    .map(s => Number(s.userId));

  return {
    contributorUserIds: uniq(contributorUserIds),
    user: getCurrentUser(state),
    meta: getFormMeta(state),
    templates: getTemplates(state),
  };
};

export default connect(mapState)(Modal);
