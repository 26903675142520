import { get } from 'lodash';

import logChangedProps from './logChangedProps';
import getDataFragmentForConditions from './getDataFragmentForConditions';
import propsHasChanged from './propsHasChanged';

export const propsHasChangedForConditions = (next, prev, omitKeys = []) => {
  const conditions = get(next, 'conditions', {});
  const source = get(conditions, 'source');
  const field = get(next, 'field');
  const rules = get(conditions, 'rules', []);
  const hasConditionals = rules.length;
  const dataStates = ['next', 'prev'];
  const dataStateProps = { next, prev };

  const data = dataStates.reduce(
    (output, target) => ({
      ...output,
      [target]: get(dataStateProps, `${target}.data`, {}),
    }),
    {}
  );

  const dataEnums = dataStates.reduce(
    (output, target) => ({
      ...output,
      [target]: get(dataStateProps, `${target}.dataEnums`, {}),
    }),
    {}
  );

  const parentIndex = dataStates.reduce(
    (output, target) => ({
      ...output,
      [target]: get(dataStateProps, `${target}.parentIndex`),
    }),
    {}
  );

  const wholeData = dataStates.reduce(
    (output, target) => ({
      ...output,
      [target]: get(dataStateProps, `${target}.wholeData`, {}),
    }),
    {}
  );

  const sourceData = dataStates.reduce(
    (output, target) => ({
      ...output,
      [target]: get(
        dataStateProps,
        `${target}.wholeData.${source}.${parentIndex[target]}`,
        {}
      ),
    }),
    {}
  );

  const fieldsData = dataStates.reduce(
    (output, target) => ({
      ...output,
      [target]: {
        ...get(data, target, {}),
        isHidden: false,
        data: get(wholeData, target, {}),
        sourceData: get(sourceData, target, {}),
      },
    }),
    {}
  );

  let rulesForDataFragment = rules;

  if (field) {
    rulesForDataFragment = rulesForDataFragment.concat({
      key: String(field).includes('.') ? `data.${field}` : field,
    });
  }

  // draftDate is used by encryptedField to do some validations
  // when the report is in draft state, if draftDate changed
  // then field should be updated
  const dataFragment = dataStates.reduce(
    (output, target) => ({
      ...output,
      [target]: {
        ...getDataFragmentForConditions(
          get(wholeData, target, {}),
          rulesForDataFragment,
          get(fieldsData, target, {}),
          field ? get(dataEnums, target, {}) : {}
        ),
        draftDate: get(fieldsData, `${target}.meta.draftDate`),
        disabled: get(dataStateProps, `${target}.disabled`),
      },
    }),
    {}
  );

  logChangedProps(
    ...dataStates.map(target => dataFragment[target]),
    'ConditionalAssert'
  );

  if (!hasConditionals) {
    return propsHasChanged(next, prev, omitKeys);
  } else if (next.forcedUpdatesOn) {
    return propsHasChanged(
      ...dataStates.map(target => ({
        forcedOn: get(dataStateProps, `${target}.forcedUpdatesOn`, {}),
      }))
    );
  }

  return propsHasChanged(
    ...dataStates.map(target => dataFragment[target]),
    omitKeys
  );
};

export default propsHasChangedForConditions;
