import {
  CREATE_USER_FULLFILLED,
  CREATE_USER_REJECTED,
  CREATE_USER_REQUEST,
  POST_USER_SECURITY_ROLES_REQUEST,
  POST_USER_SECTIONS_REQUEST,
  POST_USER_SECTIONS_FULFILLED,
  POST_USER_SECTIONS_REJECTED,
  POST_USER_EMPLOYMENT_HISTORY_REQUEST,
  POST_USER_EMPLOYMENT_HISTORY_FULFILLED,
  POST_USER_EMPLOYMENT_HISTORY_REJECTED,
  POST_USER_CREATE_IN_OU_FULFILLED,
  POST_USER_CREATE_IN_OU_REJECTED,
} from '../AgencyUsers.actionTypes';
import { createUser } from '../../../../api/manageUsers';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';

export default ({
  _userId,
  data,
  agencyId,
  tenantId,
  sectionsData,
  employmentHistory,
  _userDocuments,
  showMessageCb = () => {},
  callback = () => {},
}) => dispatch => {
  dispatch({ type: CREATE_USER_REQUEST, agencyId, payload: data });
  dispatch({ type: POST_USER_SECURITY_ROLES_REQUEST });

  const { securityRolesId = [] } = data;

  if (sectionsData) {
    dispatch({ type: POST_USER_SECTIONS_REQUEST });
  }

  if (employmentHistory) {
    dispatch({ type: POST_USER_EMPLOYMENT_HISTORY_REQUEST });
  }
  return new Promise((resolve, reject) => {
    createUser(data)
      .then(response => {
        dispatch({
          type: CREATE_USER_FULLFILLED,
          agencyId,
          payload: { response },
        });
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  })
    .then(userResponse => {
      // create the user in Organizational User table
      organizationEndpoints
        .postOrganizationalUserFromUser(
          userResponse.integrationId,
          securityRolesId
        )
        .then(response => {
          dispatch({
            type: POST_USER_CREATE_IN_OU_FULFILLED,
            agencyId,
            payload: response,
          });
          return response;
        })
        .catch(error => {
          dispatch({
            type: POST_USER_CREATE_IN_OU_REJECTED,
            agencyId,
            payload: error,
          });
          showMessageCb(error);
        })
        .then(response => {
          if (sectionsData) {
            organizationEndpoints
              .updateTenantDatastoreUsers(
                tenantId,
                userResponse.integrationId,
                sectionsData
              )
              .then(response => {
                dispatch({
                  type: POST_USER_SECTIONS_FULFILLED,
                  agencyId, // tenantId
                  payload: response,
                });
                return response;
              })
              .catch(error => {
                dispatch({
                  type: POST_USER_SECTIONS_REJECTED,
                  agencyId, // tenantId
                  payload: error,
                });
                showMessageCb(error);
              });
          }
        })
        .then(response => {
          if (employmentHistory) {
            organizationEndpoints
              .postUserEmploymentHistory(
                userResponse.integrationId,
                employmentHistory,
                tenantId
              )
              .then(response => {
                dispatch({
                  type: POST_USER_EMPLOYMENT_HISTORY_FULFILLED,
                  agencyId, // tenantId
                  payload: response,
                });
                return response;
              })
              .catch(error => {
                dispatch({
                  type: POST_USER_EMPLOYMENT_HISTORY_REJECTED,
                  agencyId, // tenantId
                  payload: error,
                });
                showMessageCb(error);
              });
          }
        })
        .then(response => {
          showMessageCb(false, response);
          callback(userResponse);
        });
    })
    .catch(error => {
      dispatch({ type: CREATE_USER_REJECTED, agencyId, payload: error });
      showMessageCb(error);
    });
};
