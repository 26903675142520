import { endsWith, startsWith } from 'lodash';

import { getEnvironmentVariable } from '../appVersion';

const kmiUrl = getEnvironmentVariable('kmi_host');
const normalizedBaseUrl = endsWith(kmiUrl, '/')
  ? kmiUrl.substr(0, kmiUrl.length - 1)
  : kmiUrl;

const getLmsUrl = (path = '') => {
  const normalizedPath = startsWith(path, '/')
    ? path.substr(1, path.length)
    : path;

  return [normalizedBaseUrl, normalizedPath].join('/');
};

export default getLmsUrl;
