import { get, kebabCase, isBoolean, set } from 'lodash';

import editFormSuccess from '../../edit-form-success';
import getFormPresentation from '../../get-form-presentation';
import fetchFormParent from '../../fetch-form-parent';

const onGetReportSuccess = ({
  dispatch,
  report,
  templates,
  next,
  templateParam,
  withProfileData = false,
}) => {
  const type = get(report, ['formType'], 'Use of Force');
  const formData = get(report, ['data'], {});
  const templateType = kebabCase(type);
  const template = get(templates, templateType, templateParam);
  const bool = [];

  const {
    formSchema: { form: schema = {}, validations, enums, overrides } = {},
  } = template;
  const actions = get(report, 'actions', []);

  const getBool = (validations, parentKey) => {
    Object.keys(validations).forEach(key => {
      let val = validations[key];
      if (Array.isArray(validations[key])) {
        [val] = validations[key];
      }
      if (val.type === 'boolean') {
        if (parentKey) {
          bool.push(`${parentKey}.${key}`);
        } else {
          bool.push(key);
        }
      } else if (val.type === 'array') {
        const {
          item: [{ fields }],
        } = val;
        getBool(fields, key);
      }
    });
  };

  if (validations !== undefined) {
    getBool(validations.rules);
  }

  bool.forEach(key => {
    const value = get(formData, key);
    if (value !== undefined && !isBoolean(value)) {
      set(formData, key, value === 'true');
    }
  });

  dispatch(
    getFormPresentation({
      schema,
      type,
      validations,
      enums,
      formData: formData || {},
      actions,
      overrides,
      withProfileData,
    })
  );
  dispatch(editFormSuccess(report));
  dispatch(fetchFormParent());
  next(null, report);
};

export default onGetReportSuccess;
