import React from 'react';
import { Select } from 'antd';

const Option = Select.Option;

const Status = ({
  label = '',
  template = {},
  propagateDirectValue = false,
  disabled = false,
  setFilters,
  filterType,
  filterValues,
  ...props
}) => {
  const { statuses = [] } = template;
  const [, ...value] = filterValues[filterType];

  const options = statuses.map((item, index) => (
    <Option key={index} value={item.id}>
      {item.label}
    </Option>
  ));

  const onSelectChange = values => {
    const items = values.length ? [propagateDirectValue, ...values] : [];

    setFilters(filterType, items);
  };

  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      placeholder={label}
      size="default"
      disabled={disabled}
      onChange={onSelectChange}
      value={value}
      allowClear
      {...props}
    >
      {options}
    </Select>
  );
};

export default Status;
