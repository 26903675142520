export const halfColumn = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
};

export const formItemLayout = {
  labelCol: { md: 8, sm: 10, xs: 24 },
  wrapperCol: { md: 16, sm: 14, xs: 24 },
};

export const initialState = {
  isLoading: true,
};
