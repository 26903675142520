import styled from 'styled-components';
import { Modal } from 'antd';

const StyledModal = styled(Modal)`
  &.integration-picker-modal {
    max-width: 1000px;
    width: 40% !important;
    font-size: 50px;

    .ant-integration-picker-content-label {
      text-align: right;
      padding-right: 10px;
    }

    .ant-input.edit-input {
      width: 100% !important;
    }
  }
`;

export default StyledModal;
