import { getReport } from '../../../api/reports';

const retrieveReport = ({ id, agencyId, filter }) =>
  new Promise(async resolve => {
    try {
      const report = await getReport({ id, agencyId, filter });
      resolve([null, report]);
    } catch (error) {
      resolve([error]);
    }
  });

export default retrieveReport;
