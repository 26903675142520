import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Button, Input, Form } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';

import StyledModal from './AddPredefinedTaskModal.styled';

const { TextArea } = Input;
const FormItem = Form.Item;

const layout = {
  labelCol: {
    span: 2,
  },
  wrapperCol: {
    span: 22,
  },
};

function AddPredefinedTaskModal(props) {
  const [description, setDescription] = useState('');
  const { visible } = props;
  const disableSave = isEmpty(description.trim());

  function initData() {
    setDescription('');
  }

  function handleChangeDescription(e) {
    const { value } = e.target;
    setDescription(value);
  }

  function handleSave() {
    const { onOk } = props;
    onOk && onOk(description);
    initData();
  }

  function handleCancel() {
    const { onCancel } = props;
    onCancel && onCancel();
    initData();
  }

  return (
    <StyledModal
      title={'New Default Task'}
      width={1000}
      centered
      visible={visible}
      onOk={handleSave}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="Save"
          type="primary"
          onClick={handleSave}
          disabled={disableSave}
        >
          Save
        </Button>,
      ]}
    >
      <FormItem {...layout} label="Description" colon={false}>
        <TextArea
          rows={5}
          maxLength={2500}
          value={htmlUnescape(description)}
          onChange={handleChangeDescription}
          autoFocus
        />
      </FormItem>
    </StyledModal>
  );
}

export default AddPredefinedTaskModal;
