import React from 'react';
import { reduxForm } from 'redux-form';

const ConnectedForms = ({ render, ...props }) => {
  return <div>{render(props)}</div>;
};

export default (formName, onSubmit) =>
  reduxForm({
    form: formName,
    getFormState: state => state.forms,
    onSubmit,
  })(ConnectedForms);
