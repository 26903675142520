import { genOptions, fetchRequest } from '../utils/request';
import storage from '../utils/storage';

/**
 * @param {string} method The HTTP method name.
 * @param {string} url The URL for the request.
 * @param {object} options
 * @param {string} tokenType The type of token (bearer)
 */
export const authorizedRequest = (
  method,
  url,
  options,
  tokenType = '',
  isBearer = false
) => {
  const bearer = isBearer ? 'Bearer' : '';
  const authorization = tokenType
    ? `${tokenType} ${storage.get('token')}`
    : `${bearer} ${storage.get('token')}`;
  return fetchRequest(url, genOptions(method, options, authorization));
};
