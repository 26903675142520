import { Component, createElement } from 'react';
import { get } from 'lodash';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';

import SectionChildren from './SectionChildren';
import SectionWithConditions from './SectionWithConditions';

class Section extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  render() {
    const { conditions = {} } = this.props;
    const rules = get(conditions, 'rules');
    const hasRules = rules && Array.isArray(rules) && rules.length > 0;

    if (!hasRules) {
      return createElement(SectionChildren, this.props);
    }

    return createElement(SectionWithConditions, this.props);
  }
}

export default Section;
