import React, { useState, useEffect } from 'react';

const useSelectedCategoryData = ({
  loading,
  categories = [],
  equipmentRecord = {},
}) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const { equipment: { id } = {} } = equipmentRecord;

  const onChangeSelected = value => {
    setSelectedCategory(value);
  };

  useEffect(() => {
    if (!loading && categories.length > 0) {
      setSelectedCategory(
        equipmentRecord.category
          ? equipmentRecord.category.id
          : categories[0].id
      );
    }
  }, [loading, categories, id]);

  return { selectedCategory, onChangeSelected };
};

export default useSelectedCategoryData;
