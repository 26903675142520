import React from 'react';
import UserReports from './UserReports';

const UserReportSection = ({
  profileForm,
  timezone,
  editUserSection,
  updateUserSectionField,
  addUserSectionFieldValue,
  removeUserSectionFieldValue,
  setEditUserSectionTable,
  userProfile,
  canViewReportsWithMention,
  history,
  currentUser,
}) =>
  canViewReportsWithMention && (
    <UserReports
      profileForm={profileForm}
      timezone={timezone}
      editSection={editUserSection}
      updateSectionField={updateUserSectionField}
      addSectionFieldValue={addUserSectionFieldValue}
      removeSectionFieldValue={removeUserSectionFieldValue}
      setEditSectionTable={setEditUserSectionTable}
      userProfile={userProfile}
      isSectionReadOnly
      history={history}
      currentUser={currentUser}
    />
  );

export default UserReportSection;
