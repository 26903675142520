import {
  INCIDENT_OFFICER_FORCE_APPLIED,
  FILTER_ARRAY_SEPARATOR,
} from './constants';

const getForceFilter = (forceApplied = '') => {
  if (forceApplied) {
    return [
      {
        column: INCIDENT_OFFICER_FORCE_APPLIED,
        operator: 'inq',
        value: forceApplied.split(FILTER_ARRAY_SEPARATOR),
      },
    ];
  }
  return [];
};

export default getForceFilter;
