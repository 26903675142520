import urlBuilder from 'APP_ROOT/utils/url-builder';
import { genOptions, route, fetchRequest } from 'APP_ROOT/utils/request';
import storage from 'APP_ROOT/utils/storage';

/**
 * Calls node-api to get S3 ready link to upload a file
 * It also gets attachment information of the upload link
 * @param fileName String of the file name to upload
 * @param fileType String of the data type of the file
 * @param fileSize Number describing byte size of the file
 * @returns {Promise<{postUrl: (string), attachmentId: (number)}>}
 */
const getUploadURL = async (props, fileName, fileType, fileSize) => {
  const options = genOptions(
    'POST',
    {
      fileName,
      fileType,
      fileSize,
    },
    storage.get('token')
  );
  const { meta: { id = '' } = {} } = props;
  const request = route(
    urlBuilder('/Forms/:id/upload/request-url', {
      id,
    })
  );
  const response = await fetchRequest(request, options);
  if (!response || !response.url) {
    throw Error('Unable to upload at the moment');
  }
  return {
    postUrl: response.url,
    attachmentId: response.attachmentId,
  };
};

export default getUploadURL;
