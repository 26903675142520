import { LOGOUT } from './';

export default ({ onSuccess, onFail }) => dispatch => {
  return new Promise((resolve, reject) => {
    try {
      dispatch({ type: LOGOUT });
      onSuccess();
      resolve();
    } catch (error) {
      reject(error);
      onFail();
    }
  });
};
