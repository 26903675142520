// to call the API, for instance
// https://{organization-service}:3003/api/organizations/v1/agencies/ranks/9001

import { fetchRequest } from '../../utils/request';
import ServiceClient from '../serviceClient';
import { isEmpty } from 'lodash';

const OR_CRITERIA_DEFAULT = ['starNumber'];
const FULLNAME = 'fullName';

class organizationEndpoints extends ServiceClient {
  constructor() {
    const DEV_URI = 'http://localhost:3003';
    const apiPrefix = 'organizations/v1';
    super(
      process.env.REACT_APP_ORGANIZATIONS_SERVICE_URI || DEV_URI,
      apiPrefix
    );
  }

  _defaultUserFilters = {
    agencyId: '',
    pageSize: '50',
    pageNumber: '',
    sortBy: 'lastName',
    id: '',
    firstName: '',
    lastName: '',
    includeDisabled: 'false',
    includeRelatedUsers: 'false',
    rank: '',
    fullName: '',
    projection: '',
    roleNames: '',
    orCriteria: '',
    orValue: '',
  };

  getBulkUploadPath(path) {
    return `${this.uri}/${this.withApiPrefix(path)}`;
  }

  clearFilterUser(customFilters) {
    let clearFilters = { ...this._defaultUserFilters };
    Object.keys(clearFilters).forEach(function(key) {
      if (customFilters[key]) {
        clearFilters[key] = customFilters[key];
      }
    });

    return attachSpecialFilterSworn(clearFilters, customFilters);
  }

  async getRanks(agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/ranks/:agencyId'),
        method: 'GET',
      },
      { agencyId },
      {}
    );
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }

  async getAgencyById(agencyId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/agencies/:agencyId'),
        method: 'GET',
      },
      { agencyId },
      {}
    );
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }

  async getUsers(
    includeDisabled = false,
    page,
    pageSize,
    sortBy = 'lastName',
    searchTerm,
    agencyId
  ) {
    const pageNumber = page > 0 ? page - 1 : page;
    const params = {
      includeDisabled,
      pageNumber,
      pageSize,
      sortBy,
    };

    if (searchTerm) {
      params.searchTerm = searchTerm;
    }

    if (agencyId) {
      params.agencyId = agencyId;
    }

    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/users'),
        method: 'GET',
      },
      {},
      params
    );
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }
  /**
   * Handles the searh of user based on the filters
   * @param {number} agencyId agency id to look for users.
   * @param {object} filters filters to apply to the search of users.
   * @param {boolean} useOUScope true, returns all users in scope,
   *                             false, returns all tenant users regardless of their role
   * @param {boolean} extraProps to get the whole pagination object of the search
   * @return user list or whole pagination object(user list included) based on the filters.
   */
  async getUsersSearch(
    agencyId,
    filters = {},
    useOUScope = true,
    extraProps = false,
    _orCriteria,
    excludeNonOperational = false
  ) {
    // in case of an [], default parameter won't catch it
    const orCriteria = isEmpty(_orCriteria) ? OR_CRITERIA_DEFAULT : _orCriteria;
    let clearFilters = this.clearFilterUser(filters);
    // orValue is always using the same as fullName
    const orValue = clearFilters[FULLNAME];
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/search'),
        method: 'GET',
      },
      {},
      {
        ...clearFilters,
        agencyId,
        useOUScope,
        orCriteria,
        orValue,
        excludeNonOperational,
      }
    );
    return new Promise(async (resolve, reject) => {
      try {
        const allUsers = await fetchRequest(
          fetchParams.URI,
          fetchParams.request
        );
        resolve(extraProps ? allUsers : allUsers.content);
        this.clearFilterUser({});
      } catch (e) {
        reject(e);
        this.clearFilterUser({});
      }
    });
  }
  /**
   * Handles the tenant datastore of an user
   * @param {string} tenantId tenant id to look for users.
   * @param {string} id user id id to look for users.
   * @return tenant datastore list of sections and field elements.
   */
  getTenantDatastoreUsers(tenantId = '', id = '') {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/tenants/:tenantId/user-datastore/users/:id/section-values'
        ),
        method: 'GET',
      },
      { tenantId, id },
      {}
    );
    const allTenantDatastore = fetchRequest(
      fetchParams.URI,
      fetchParams.request
    );
    return allTenantDatastore;
  }

  getUserWithOUIds(userIntegrationId = '') {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userIntegrationId'),
        method: 'GET',
      },
      { userIntegrationId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getOrganizationalUnitsByUserIntegrationId(userIntegrationId = '') {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/users/:userIntegrationId/organizational-units'
        ),
        method: 'GET',
      },
      { userIntegrationId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getOrganizationalUnit(organizationalUnitId = '') {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/organizational-units/:organizationalUnitId'),
        method: 'GET',
      },
      { organizationalUnitId: organizationalUnitId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getOUAddressesByOuId(organizationalUnitId = '') {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/organizational-units/:organizationalUnitId/addresses'
        ),
        method: 'GET',
      },
      { organizationalUnitId: organizationalUnitId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  createOUAddress(content) {
    const postParameter = this.buildFetchParams(
      {
        path: this.withApiPrefix('/addresses/organizational-units/'),
        method: 'POST',
      },
      {},
      {},
      content
    );
    return fetchRequest(postParameter.URI, postParameter.request);
  }

  patchOUAddress(organizationalUnitAddressId = '', filters) {
    const parameter = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/addresses/organizational-units/:organizationalUnitAddressId'
        ),
        method: 'PATCH',
      },
      { organizationalUnitAddressId: organizationalUnitAddressId },
      {},
      filters
    );
    return fetchRequest(parameter.URI, parameter.request);
  }

  patchOrganizationalUnit(organizationalUnitId = '', attributes) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/organizational-units/:organizationalUnitId/attributes'
        ),
        method: 'PATCH',
      },
      { organizationalUnitId: organizationalUnitId },
      {},
      attributes
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getAllOrganizationalUnitsOnHierarchyTree() {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/organizational-units/hierarchy-tree'),
        method: 'GET',
      },
      {},
      {}
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  /**
   * Handles updating of user datastore values
   * @param {string} tenantId tenant id.
   * @param {string} id user id to update.
   * @param {json} sections Object with the list of sections to update.
   * @return tenant datastore list of sections and field elements.
   */
  updateTenantDatastoreUsers(tenantId = '', id = '', sections = {}) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/tenants/:tenantId/user-datastore/users/:id/section-values'
        ),
        method: 'PATCH',
      },
      { tenantId, id },
      {},
      sections
    );
    const allTenantDatastore = fetchRequest(
      fetchParams.URI,
      fetchParams.request
    );
    return allTenantDatastore;
  }

  /**
   * Fetches the list of organizational units.
   * @return Promise that resolves with a list of organizational units.
   */
  getOrganizationalUnits(pageData = {}) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/organizational-units'),
        method: 'GET',
      },
      {},
      { includeNonOp: false, ...pageData }
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getAllOrganizationalUnitsOnHierarchy() {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/organizational-units/hierarchy'),
        method: 'GET',
      },
      {},
      { includeNonOp: false }
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getUserEmploymentHistory(userId = '') {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userId/organizational-unit-history'),
        method: 'GET',
      },
      { userId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getUserEmploymentHistoryById(userId = '', historyId) {
    // eslint-disable-next-line
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/users/:userId/organizational-unit-history/:historyId/employment'
        ),
        method: 'GET',
      },
      { userId, historyId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  postUserEmploymentHistory(userId = '', history, tenantId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userId/organizational-unit-history'),
        method: 'POST',
      },
      { userId },
      {},
      { tenantIdContext: tenantId, ...history }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  deleteUserEmploymentHistory(userId, historyId, employmentHistoryId, filters) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/users/:userId/organizational-unit-history/:historyId/employment/:id'
        ),
        method: 'DELETE',
      },
      {
        userId,
        historyId,
        id: employmentHistoryId,
      },
      { ...filters }
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getOuUsers({
    page,
    size,
    filter = '',
    customField = '',
    agencyId,
    orgUnitId,
    includeHierarchy = true,
  }) {
    let queryParams = { page, size, filter, customField, includeHierarchy };

    if (agencyId) {
      queryParams.agencyId = agencyId;
    }

    if (orgUnitId) {
      queryParams.organizationalUnitId = orgUnitId;
    }

    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/organizational-units/users'),
        method: 'GET',
      },
      {},
      queryParams
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getOUPointOfContact(ouId = '', pageNumber = 1, pageSize = 50) {
    const fetchPOCParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/organizational-units/:ouId/contacts'),
        method: 'GET',
      },
      { ouId, pageNumber, pageSize },
      {}
    );
    return fetchRequest(fetchPOCParams.URI, fetchPOCParams.request);
  }

  getUserDocuments(userId = '') {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userId/documents'),
        method: 'GET',
      },
      { userId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  postUserDocuments(userId = '', documents) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userId/documents'),
        method: 'POST',
      },
      { userId },
      {},
      documents
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  deleteUserDocument(userId = '', documentId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userId/documents/:documentId'),
        method: 'DELETE',
      },
      { userId, documentId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  /**
   * @param {string} userIntegrationId the user id.
   * @param {list} roleIds the roleIds.
   */
  postOrganizationalUserFromUser(userIntegrationId = '', roleIds = []) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userIntegrationId'),
        method: 'POST',
      },
      { userIntegrationId },
      { roleIds }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  /**
   * @param {string} userIntegrationId the user id.
   * @param {*[]} roleIds the roleIds.
   */
  updateRolesToOrganizationalUser(userIntegrationId = '', roleIds = []) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userIntegrationId/roles'),
        method: 'PUT',
      },
      { userIntegrationId },
      { roleIds }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getOrganizationalUnitDocuments(ouId = '') {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/organizational-units/:ouId/documents'),
        method: 'GET',
      },
      { ouId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  postOrganizationalUnitDocuments(ouId = '', documents) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/organizational-units/:ouId/documents'),
        method: 'POST',
      },
      { ouId },
      {},
      documents
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getUserEmploymentHistoryConfig(tenantId, source) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/tenants/:tenantId/employment-history-configuration/sections/:source'
        ),
        method: 'GET',
      },
      { tenantId, source },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getUserAssignments(userId = '') {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userId/assignments'),
        method: 'GET',
      },
      { userId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getUserOURoles(userId = '') {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/users/:userId/roles'),
        method: 'GET',
      },
      { userId },
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getSSNLogs(
    auditPageNumber,
    auditPageSize,
    tenantId,
    agencyId,
    eventType,
    initialDate,
    endDate
  ) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/audit-logs/pointers'),
        method: 'GET',
      },
      {},
      {
        auditPageNumber,
        auditPageSize,
        tenantId,
        agencyId,
        eventType,
        initialDate,
        endDate,
      }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getViewLogs(
    auditPageNumber,
    auditPageSize,
    tenantId,
    userId,
    executedBy,
    initialDate,
    endDate,
    tableNames
  ) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId/audit-logs'),
        method: 'GET',
      },
      { tenantId },
      {
        auditPageNumber,
        auditPageSize,
        tenantId,
        userId,
        executedBy,
        initialDate,
        endDate,
        tableNames,
      }
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  getTenant(tenantId = '') {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/tenants/:tenantId'),
        method: 'GET',
      },
      { tenantId },
      {}
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  postTransferEmployment(userId, ouId, transferRequest) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/users/:userId/organizational-unit-history/:ouId/transfer'
        ),
        method: 'POST',
      },
      {
        userId: userId,
        ouId: ouId,
      },
      {},
      transferRequest
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  postPointOfContacs(organizationalUnitId, createContactRequest) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/organizational-units/:organizationalUnitId/contacts'
        ),
        method: 'POST',
      },
      { organizationalUnitId },
      {},
      createContactRequest
    );

    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  updatePointOfContacs(organizationalUnitId, contactId, createContactRequest) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/organizational-units/:organizationalUnitId/contacts/:contactId'
        ),
        method: 'PATCH',
      },
      { organizationalUnitId, contactId },
      {},
      createContactRequest
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }

  deletePointOfContacs(organizationalUnitId, contactId) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix(
          '/organizational-units/:organizationalUnitId/contacts/:contactId'
        ),
        method: 'DELETE',
      },
      { organizationalUnitId, contactId },
      {},
      {}
    );
    return fetchRequest(fetchParams.URI, fetchParams.request);
  }
}

function attachSpecialFilterSworn(filter, customFilters) {
  if (Object.keys(customFilters).includes('sworn')) {
    filter.sworn = customFilters.sworn;
  }
  return filter;
}

export default new organizationEndpoints();
