export const CONTRIBUTOR_ASSIGNMENT_SECTION = 'contributorAssignmentSection';
export const CONTRIBUTOR_ASSIGNMENT = 'contributorAssignment';
export const CONTRIBUTOR_ASSIGNMENT_DATE = 'contributorAssignmentDate';
export const CONTRIBUTOR_SECTION_COMPLETE = 'contributorSectionComplete';
export const CONTRIBUTOR_SECTION_COMPLETE_DATE =
  'contributorSectionCompleteDate';
export const SECTION_ASSIGN_ACTION = 'section-assign';
export const SECTION_UNASSIGN_ACTION = 'section-unassign';
export const SECTION_COMPLETE_ACTION = 'section-complete';
export const SECTION_INCOMPLETE_ACTION = 'section-incomplete';
export const SECTION_COMPLETE_MARK = 'complete';
export const SECTION_INCOMPLETE_MARK = 'incomplete';
export const CONTRIBUTE_REPORT_NOTIFICATION_TYPE = 'contribute-report';

// Event emit labels
export const CONTRIBUTOR_MANUAL_SAVE = 'contributor-manual-save';
export const CONTRIBUTOR_SHARE_UPDATE = 'contributor-share/unshare';
export const CONTRIBUTOR_ASSIGNATION = 'contributor-assignment-date';
export const CONTRIBUTOR_COMPLETION = 'contributor-completion-date';
