import styled from 'styled-components';
import { Modal, Input } from 'antd';

export const StyledModal = styled(Modal)`
  &.ant-modal {
    max-width: 1000px;
    width: 100% !important;
    max-height: 500px;
    height: 100% !important;
  }

  &.ant-modal-body {
    padding: 12px 12px 0 12px;
  }
`;

export const StyleInputReadOnly = styled(Input)`
  &.ant-input[disabled] {
    background-color: #fff;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 12px 0 12px;
`;

export const ButtonRemoveWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonPrimaryWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const StyledPhoneNumberReadOnly = {
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  cursor: 'not-allowed',
};

export const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
  colon: false,
};

export const inputTextAreaLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 19 },
  colon: false,
};
