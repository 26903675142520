import callback from '../../../administrator/utils/callback';
import organizationEndpoints from '../../../../api/organization/organizationEndpoints';

export default (attributes, next) => _dispatch => {
  return organizationEndpoints
    .createOUAddress(attributes)
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
