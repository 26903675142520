import { get, find } from 'lodash';

export const parseAttachmentsWithNotes = (
  attachmentOnStorage,
  attachmentField,
  newAttachments,
  data
) => {
  return Object.keys(attachmentOnStorage)
    .reduce(
      (allAttachments, attachmentId) => [
        ...allAttachments,
        {
          id: attachmentId,
          notes: get(
            attachmentOnStorage,
            `${attachmentId}.notes`,
            get(
              find(data[attachmentField], {
                id: attachmentId,
              }),
              'notes',
              ''
            )
          ),
        },
      ],
      []
    )
    .concat(...newAttachments);
};
