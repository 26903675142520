import _ from 'lodash';
import { Form } from 'antd';

import { FORM_TEMPLATES } from '../constants/form-types';

export default (
  data = {},
  fields_state = {},
  presentation = {},
  extraFields = {}
) => {
  const flattenPresentation = _.keys(presentation.fields).reduce(
    (result, tab, index) => {
      return {
        ...result,
        ..._.keys(presentation.fields[tab]).reduce(
          (tabResult, field, fieldIndex) => {
            result[field] = presentation.fields[tab][field];

            return result;
          },
          {}
        ),
      };
    },
    {}
  );

  const getValueFromPresentation = level => _.get(flattenPresentation, level);

  const firstIsObject = values => {
    return _.isArray(values) && _.isPlainObject(_.get(values, [0], ''));
  };

  const childrenReducer = (parent, index) => (result, key) => {
    const value = _.get(
      data,
      [parent, index, key],
      getValueFromPresentation([parent, 0, key])
    );

    const nestedState = _.get(fields_state, [parent, index, key]);
    const fromArrayNotation = fields_state[`${parent}[${index}].${key}`];
    const childrenState = nestedState || fromArrayNotation || {};
    result[`${parent}[${index}].${key}`] = Form.createFormField({
      ...childrenState,
      value: value,
    });
    return result;
  };

  const reduceChildrenIteree = parent => (result, key, index) => ({
    ...result,
    ...Object.keys(data[parent][index]).reduce(
      childrenReducer(parent, index),
      {}
    ),
  });

  const reducer = (result, key) => {
    if (firstIsObject(data[key])) {
      result = {
        ...result,
        ...data[key].reduce(reduceChildrenIteree(key), {}),
      };
    } else {
      const value = _.get(data, [key], getValueFromPresentation([key]));

      result[key] = Form.createFormField({
        ..._.get(fields_state, [key], {}),
        value: value,
      });
    }

    return result;
  };

  return _.keys(data).reduce(reducer, extraFields);
};

export const getFormAttachments = (
  attachments = {},
  template = '',
  id = ''
) => {
  const { [FORM_TEMPLATES[template]]: templateAttachments = {} } = attachments;
  const { [id]: formAttachments = {} } = templateAttachments;
  const { 'review-attachments': reviewAttachments = {} } = formAttachments;

  return Object.keys(reviewAttachments).reduce((res, id, index) => {
    if (id.trim() === '') {
      return res;
    }

    return {
      ...res,
      [`review-attachments[${index}].notes`]: {
        value: _.get(reviewAttachments, [id, 'notes'], ''),
      },
      [`review-attachments[${index}].id`]: {
        value: id,
      },
    };
  }, {});
};

export const initializeFormFields = (fields = {}) => {
  return Object.entries(fields).reduce(
    (_fields, [key, field]) => ({
      ..._fields,
      [key]: Form.createFormField(field),
    }),
    fields
  );
};
