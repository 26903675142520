import getCurrentWorkFlowStatus from './getCurrentWorkFlowStatus';

import REPORT_STATUS from 'APP_ROOT/constants/status';
import emitFormEvent from 'APP_ROOT/utils/emitFormEvent';

import { EDITABLE_STATUS } from '../constants';

const saveDraft = props => {
  const { settings, isAdminEdit } = props;
  const status = getCurrentWorkFlowStatus(props);

  if (
    !isAdminEdit &&
    (EDITABLE_STATUS.includes(REPORT_STATUS[status]) ||
      props.contributorAssignmentCanEdit)
  ) {
    emitFormEvent('onSaveDraft', settings.formName);
  }
};

export default saveDraft;
