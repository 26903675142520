import { get } from 'lodash';

import { GET_ALL_REPORTS_REQUEST } from './';
import { getReports } from '../api/reports';

import getAllReportsSuccess from './get-all-reports-success';
import getAllReportsError from './get-all-reports-error';

export default (
  enumRef = '',
  filters = {},
  dataFilters = {},
  keywords = '',
  value,
  next = () => {}
) => (dispatch, getState) => {
  const {
    session: {
      currentUser: {
        agencyId,
        organizationalUnitId = '',
        hierarchyKey = '',
      } = {},
    } = {},
  } = getState();
  dispatch({
    type: GET_ALL_REPORTS_REQUEST,
    payload: {
      enumRef,
      filters,
      dataFilters,
      keywords,
      hierarchyKey,
      organizationalUnitId,
    },
  });

  const cb = (...args) =>
    next && typeof next === 'function' ? next(...args) : null;

  if (agencyId) {
    return new Promise((resolve, reject) => {
      getReports({
        agencyId,
        filter: filters,
        dataFilter: dataFilters,
        keywords,
        hierarchyKey,
        organizationalUnitId,
      })
        .then(response => {
          const { results: reports } = response;
          dispatch(getAllReportsSuccess({ enumRef, data: reports, value }));
          cb(null, response);
          resolve(response);
        })
        .catch(error => {
          dispatch(getAllReportsError(get(error, ['response', 'status'])));
          cb(error);
          reject(error);
        });
    });
  }

  cb();
  return Promise.resolve();
};
