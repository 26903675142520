import useUserEquipmentNew from './useSaveEquipmentNew';
import useUserEquipmentUpdate from './useSaveEquipmentUpdate';

const useUserEquipment = ({
  equipmentModalFields,
  tenantId,
  userId,
  selectedCategory,
  equipment,
  onSave,
  dispatch,
  equipmentRecord,
}) => {
  const { equipment: { id } = {} } = equipmentRecord;
  const useEquipmentHook = id ? useUserEquipmentUpdate : useUserEquipmentNew;

  return useEquipmentHook({
    equipmentModalFields,
    tenantId,
    userId,
    selectedCategory,
    equipment,
    onSave,
    dispatch,
    equipmentRecord,
  });
};

export default useUserEquipment;
