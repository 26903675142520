import routes from '../routes';

export default (name = '', params = {}, query = {}) => {
  const keys = params ? Object.keys(params) : [];
  const queryKeys = query ? Object.keys(query) : [];
  let filtered = routes.filter(route => route.name === name);

  const recursiveLookup = _routes => {
    _routes.forEach(route => {
      if (route.childRoutes && route.childRoutes.length) {
        const subRoutes = route.childRoutes.filter(
          sub_route => sub_route.name === name
        );

        filtered = filtered.concat(subRoutes);

        recursiveLookup(route.childRoutes);
      }
    });
  };

  recursiveLookup(routes);

  if (filtered.length) {
    let route = filtered[0].path;
    let queryString = queryKeys.map(key => `${key}=${query[key]}`).join('&');

    keys.forEach(key => {
      route = route.replace(`:${key}`, params[key] || 'undef');
    });

    if (queryKeys.length) {
      return `${route}?${queryString}`;
    }

    return route;
  }

  return null;
};
