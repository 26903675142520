import styled from 'styled-components';
import ComingSoon from '../ComingSoon';

const StyledComingSoon = styled(ComingSoon)`
  background-color: #fff;
  border-radius: 5px;
  padding: 100px 50px;
  text-align: center;
  min-height: 300px;

  h2 {
    color: #c7c7c7;
    font-size: 2em;
  }
`;

export default StyledComingSoon;
