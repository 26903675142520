import React from 'react';
import styled from 'styled-components';

const ContentBlock = ({ title, content, children }) => (
  <StyledContentBlock>
    <div className="help">
      <h2>{title}</h2>
      {content}
    </div>
    <div className="form-wrapper">{children}</div>
  </StyledContentBlock>
);

const StyledContentBlock = styled.div`
  max-width: 420px;
  margin: 0 auto;

  .form-wrapper {
    margin: 0 auto;
    margin-bottom: 30px;
  }

  text-align: center;

  .help {
    margin-bottom: 30px;

    h2 {
      font-size: 24px;
      font-family: 'Lato', Arial, sans-serif;
      font-weight: bold;
      line-height: 34px;
      margin-bottom: 15px;
    }

    p {
      font-size: 18px;
      line-height: 30px;
      font-family: 'Lato', Arial, sans-serif;
    }
  }

  .actions {
    .ant-btn {
      display: inline-block;
      line-height: 30px;
    }
  }

  .login-form-button {
    width: 150px;
    display: inline-block;
    margin-left: 15px;
  }
`;

export default ContentBlock;
