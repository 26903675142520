import { get, omit } from 'lodash';
import FieldSelect from '../../components/FieldSelect';

import componentsManager from '../../services/componentsManager';
import tabsManager from '../../services/tabsManager';
import getCustomFieldOptionsWithFallback from './getCustomFieldOptionsWithFallback';
import getAllFieldsFlat from '../../utils/getAllFieldsFlat';
import {
  SELECT,
  MULTISELECT,
  AUTOCOMPLETE,
  CHECKBOX,
  DATE,
  DATE_TIME,
  TIME,
  NUMBER,
  SWITCH,
  TEXT,
  TEXTAREA,
  MASKED,
} from 'APP_ROOT/constants/fieldTypes';

export default class resetKeys {
  constructor(values, value = '') {
    this.currentFieldId = values.id;
    const { allTabs } = getAllFieldsFlat(
      componentsManager.fields,
      this.currentFieldId
    );
    // get repeater reference because we cannot reset
    // a field inside a repeater, for that we should
    // specify what entry like repeater[0].fieldKey
    const { repeatersReference } = componentsManager;
    this.name = 'resetKeys';
    this.label = 'Reset keys';
    this.component = FieldSelect;
    this.defaultValue = [];
    this.mode = 'multiple';
    this.value = value;
    this.selectOptions = allTabs.map((tab, i) => ({
      label: tab.title,
      enums: (tab.properties || [])
        .filter(
          p =>
            [
              SELECT,
              MULTISELECT,
              AUTOCOMPLETE,
              CHECKBOX,
              DATE,
              DATE_TIME,
              TIME,
              NUMBER,
              SWITCH,
              TEXT,
              TEXTAREA,
              MASKED,
            ].includes(p.field_type) &&
            !(repeatersReference[i] || []).some(
              repeater => repeater.fields[p.key]
            )
        )
        .map(p => ({
          label: p.title || p.reportingKey || p.field_type,
          value: p.key,
        })),
    }));
    // if no activeTabIndex then we are in review note mode
    if (tabsManager.activeTabIndex < 0) {
      const option = this.selectOptions.pop();
      this.selectOptions = [...this.selectOptions, ...option.enums];
    }
  }

  onSave = values => {
    const selectedOptions = get(values, this.name, this.value);
    const { options } = values;

    return {
      ...omit(values, this.name),
      options: {
        ...options,
        [this.name]: selectedOptions,
      },
    };
  };
  getInitialValue = values => {
    const { options } = values;
    if (options === undefined) {
      return this.defaultValue;
    }
    const resetKeys = get(options, this.name);

    return resetKeys ? resetKeys : this.defaultValue;
  };

  beforeOnChange = (value, values) => {
    const current = values.customFields.find(cf => cf.name === this.name);
    const currentValues = { ...values, id: current.currentFieldId };
    const field = new resetKeys(currentValues, value);

    return {
      customFields: values.customFields.map(cf =>
        cf.name !== this.name
          ? cf
          : getCustomFieldOptionsWithFallback(values)(field)
      ),
    };
  };
}
