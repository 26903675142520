import getOverrides from 'APP_ROOT/utils/get-field-overrides';
import getDisableAllFields from './getDisableAllFields';

const getFieldIsDisabled = props => {
  const {
    overrides = {},
    behavior = 'hide',
    isEditor = false,
    disable: turnedOff = false,
  } = props;

  const disableAllFields = getDisableAllFields(props);

  if (disableAllFields) {
    return disableAllFields;
  }

  if (isEditor && turnedOff) {
    return turnedOff;
  }

  const getFieldOverrides = getOverrides(props, overrides);
  const options = getFieldOverrides('options', {});

  const { disable = false } = options;

  return disable || behavior === 'disable';
};

export default getFieldIsDisabled;
