import { createReducer } from 'redux-create-reducer';

import {
  ORGANIZATION_GET_VIEW_LOGS_REQUEST,
  ORGANIZATION_GET_VIEW_LOGS_SUCCESS,
  ORGANIZATION_GET_VIEW_LOGS_ERROR,
  ORGANIZATION_CLEAR_VIEW_LOGS,
} from '../../actions/organizations/get-view-audit-logs';

export const initialState = {
  total: 0,
  totalPages: 0,
  pageSize: 50,
  error: false,
  loading: false,
  data: {},
};

const events = {
  [ORGANIZATION_GET_VIEW_LOGS_REQUEST]: (state, { payload }) => ({
    ...state,
    payload,
    loading: true,
  }),
  [ORGANIZATION_GET_VIEW_LOGS_SUCCESS]: (state, { payload }) => {
    const {
      totalPages,
      totalElements,
      pageable: { pageSize },
    } = payload;
    return {
      ...state,
      totalPages: totalPages,
      pageSize,
      total: totalElements,
      loading: false,
      data: payload,
    };
  },
  [ORGANIZATION_GET_VIEW_LOGS_ERROR]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: true,
  }),
  [ORGANIZATION_CLEAR_VIEW_LOGS]: () => ({ ...initialState }),
};

export default createReducer(initialState, events);
