import { kebabCase, omit, get } from 'lodash';
import { getFormPresentation, getFormFields } from '../utils/form';
import getRequirementCompliantFormFields from '../utils/get-requirement-compliant-fields';
import { transformValidations } from '../utils/validations';
import getDataFromNotes from '../utils/get-data-from-notes';
import { getAgencyTZ } from '../selectors/session';
import { GET_FORM_REVIEW_PRESENTATION } from './';

export default ({
  schema: formSchema = {},
  type: formType,
  validations = {},
  enums = {},
  formData = {},
  overrides,
  propagateAction = '',
  templateId,
}) => async (dispatch, getState) => {
  const state = getState();
  const {
    session: { currentUser = {} } = {},
    form: {
      selectedForm: {
        meta: { notes = [], formType: parentFormType = '' },
      },
      templates = {},
    },
  } = state;
  const timezone = getAgencyTZ(state);

  const dataWithCurrentUser = {
    ...formData,
    currentUserId: currentUser.userId + [],
    __users: {
      [currentUser.userId]: {
        ...currentUser,
        id: currentUser.id + [],
      },
    },
  };

  const parentSchema = get(templates, [parentFormType], {});

  const dataFromNotes = propagateAction
    ? getDataFromNotes(notes, propagateAction)
    : {};
  const schema = getFormPresentation({
    schema: formSchema,
    data: dataWithCurrentUser,
    overrides,
  });
  const validationRules = await transformValidations(
    validations,
    enums,
    timezone
  );

  const formFields = await getFormFields(formSchema, validationRules);
  const type = kebabCase(formType);
  const requirementCompliantFields = await getRequirementCompliantFormFields(
    formSchema,
    validationRules
  );

  dispatch({
    type: GET_FORM_REVIEW_PRESENTATION,
    payload: {
      schema,
      parentSchema,
      type,
      formFields,
      formData: Object.assign({}, formData, schema.data || {}),
      requirementCompliantFields,
      templateId,
      noteData: omit(dataFromNotes, ['action', 'reviewer', 'notes']),
    },
  });
};
