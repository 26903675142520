import React from 'react';

import { Row } from 'antd';
import styled from 'styled-components';
import { tablet } from '../../config/breakpoints';
import Footer from '../layout/footer';

const year = new Date().getFullYear();

const StyledFooter = styled(Footer)`
  .footer-link {
    display: block;
    color: #fff;
    text-decoration: none;
    margin: 10px 40px;

    @media (min-width: ${tablet}) {
      display: inline-block;
      margin: 20px 40px;
    }
  }
`;

export default () => {
  const systemVersion = `V${global.VERSION.VERSION}`
    .toLowerCase()
    .replace('vunknown', '')
    .toUpperCase();

  return (
    <StyledFooter>
      <Row>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://benchmarkanalytics.com/contact.html"
          className="footer-link"
        >
          Customer Support
        </a>
      </Row>
      <Row>
        <p>&copy; {year} Benchmark Analytics &bull;</p>
        <p>All Rights Reserved &bull; {systemVersion}</p>
      </Row>
    </StyledFooter>
  );
};
