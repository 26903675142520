import { get, isNil, every, some, isEmpty } from 'lodash';

const isEmptyValue = value =>
  ['number', 'boolean'].includes(typeof value) ? false : isEmpty(value);

const valueIncluded = (conditionValue, valueToTest) => {
  const condIsArray = Array.isArray(conditionValue);
  const valueIsArray = Array.isArray(valueToTest);
  let included = false;
  if (condIsArray && valueIsArray) {
    included = conditionValue.some(cv => valueToTest.includes(cv));
  } else if (condIsArray) {
    included = conditionValue.includes(valueToTest);
  } else if (valueIsArray) {
    included = valueToTest.includes(conditionValue);
  } else {
    included =
      isEmptyValue(valueToTest) && isEmptyValue(conditionValue)
        ? true
        : valueToTest === conditionValue;
  }
  return included;
};

const validateBackendCondition = (condition, data, index) => {
  const { key = '' } = condition;
  const keyToTest = key.replace('.item.', `.${index}.`);
  const hasIncludes =
    Array.isArray(condition.includes) || typeof condition.includes === 'string';
  const hasExpected = !isNil(condition.expected);
  const valueToTest = get(data, keyToTest);
  let isExpected = true;
  let includesIt = true;
  if (hasExpected) {
    isExpected = valueIncluded(condition.expected, valueToTest);
  }
  const hasNotExpected = !isNil(condition.notExpected);
  if (hasNotExpected) {
    isExpected = !valueIncluded(condition.notExpected, valueToTest);
  }
  if (hasIncludes) {
    const valueToTestArray = valueToTest ? valueToTest : [];
    const validArrayValueToTest = Array.isArray(valueToTestArray)
      ? valueToTestArray
      : [valueToTestArray];
    const containsSingle = valueToTest
      ? valueToTest.includes(condition.includes)
      : false;
    includesIt = Array.isArray(condition.includes)
      ? validArrayValueToTest.some(e => condition.includes.includes(e))
      : containsSingle;
  }

  return isExpected && includesIt;
};

const validateRule = (fieldKey, rule, data, index) => {
  if (!rule) return true;

  const hasMustExist = rule.mustExist;
  const hasConditions = Array.isArray(rule.conditions);
  if (!hasConditions && !hasMustExist) return true;
  const { conditionType = 'and' } = rule;
  const assertMethod = conditionType === 'and' ? every : some; // shortcircuit evaluation of conditions: every=and, some=or
  let doesExist = true;
  let passesConditions = true;
  if (hasMustExist) {
    if (Array.isArray(rule.mustExist)) {
      doesExist = rule.mustExist
        .map(me => me.replace('.item.', `.${index}.`))
        .includes(fieldKey);
    } else {
      const k = rule.mustExist.replace('.item.', `.${index}.`);
      doesExist = k === fieldKey;
    }
  }
  if (hasConditions) {
    passesConditions = assertMethod(rule.conditions, cond =>
      validateBackendCondition(cond, data, index)
    );
  }

  return passesConditions && doesExist;
};

const validateBackendConditions = (fieldKey, validationRules, data, index) => {
  if (Array.isArray(validationRules)) {
    return validationRules.reduce(
      (acc, rule) => acc && validateRule(fieldKey, rule, data, index),
      true
    );
  }
  return validateRule(fieldKey, validationRules, data, index);
};

export default validateBackendConditions;
