import {
  FETCH_ACTIONS_REQUEST,
  FETCH_ACTIONS_SUCCESS,
  FETCH_ACTIONS_ERROR,
  REFRESH_ACTIONS_REQUEST,
  REFRESH_ACTIONS_SUCCESS,
  REFRESH_ACTIONS_ERROR,
  FETCH_ACTIVITY_FEED_REQUEST,
  FETCH_ACTIVITY_FEED_SUCCESS,
  FETCH_ACTIVITY_FEED_ERROR,
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_SUCCESS,
  FETCH_ACTIVITIES_ERROR,
  REFRESH_ACTIVITIES_REQUEST,
  REFRESH_ACTIVITIES_SUCCESS,
  REFRESH_ACTIVITIES_ERROR,
  FETCH_FORM_ATTACHMENTS,
  FETCH_FORM_ATTACHMENTS_SUCCESS,
  FETCH_FORM_ATTACHMENTS_ERROR,
  DELETE_FORM_ATTACHMENT,
  DELETE_FORM_ATTACHMENT_SUCCESS,
  DELETE_FORM_ATTACHMENT_ERROR,
  FETCH_FORM_LINKS_REQUEST,
  FETCH_FORM_LINKS_SUCCESS,
  FETCH_FORM_LINKS_ERROR,
  POST_FORM_LINK_REQUEST,
  POST_FORM_LINK_SUCCESS,
  POST_FORM_LINK_ERROR,
  DELETE_FORM_LINK_REQUEST,
  DELETE_FORM_LINK_SUCCESS,
  DELETE_FORM_LINK_ERROR,
  EDIT_FORM,
  EDIT_FORM_SUCCESS,
  EDIT_FORM_ERROR,
  FETCH_FORM_PARENT_REQUEST,
  FETCH_FORM_PARENT_SUCCESS,
  FETCH_FORM_PARENT_ERROR,
  FETCH_FORM,
  FETCH_FORM_SUCCESS,
  FETCH_FORM_ERROR,
  GET_FORM_TEMPLATES,
  GET_FORM_TEMPLATES_SUCCESS,
  GET_FORM_TEMPLATES_ERROR,
  GET_FORM_TEMPLATE,
  GET_FORM_TEMPLATE_SUCCESS,
  GET_FORM_TEMPLATE_ERROR,
  GET_REPORTS_REQUEST,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_ERROR,
  POST_REPORT_REQUEST,
  POST_REPORT_SUCCESS,
  POST_REPORT_ERROR,
  UPDATE_REPORT_REQUEST,
  UPDATE_REPORT_SUCCESS,
  UPDATE_REPORT_ERROR,
  POST_DRAFT_REPORT_REQUEST,
  POST_DRAFT_REPORT_SUCCESS,
  POST_DRAFT_REPORT_ERROR,
  UPDATE_DRAFT_REPORT_REQUEST,
  UPDATE_DRAFT_REPORT_SUCCESS,
  UPDATE_DRAFT_REPORT_ERROR,
  DELETE_REPORT_REQUEST,
  DELETE_REPORT_SUCCESS,
  DELETE_REPORT_ERROR,
  POST_EMPTY_DRAFT_REPORT_REQUEST,
  POST_EMPTY_DRAFT_REPORT_SUCCESS,
  POST_EMPTY_DRAFT_REPORT_ERROR,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  GET_STATS_REQUEST,
  GET_STATS_SUCCESS,
  GET_STATS_ERROR,
  GET_USERS_REQUEST,
  GET_USERS_SEARCH_REQUEST,
  ADD_ONBEHALF,
  RESET_ONBEHALF,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  GET_FORM_ACTIONS_REQUEST,
  GET_FORM_ACTIONS_SUCCESS,
  GET_FORM_ACTIONS_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_ERROR,
  LOGOUT,
} from '../../actions';

const setLoading = state => loading => ({
  ...state,
  loading,
});

export default defaultReducer => (state, action) => {
  const saveState = setLoading(state);

  switch (action.type) {
    case FETCH_ACTIONS_REQUEST:
    case REFRESH_ACTIONS_REQUEST:
    case FETCH_ACTIVITY_FEED_REQUEST:
    case FETCH_ACTIVITIES_REQUEST:
    case REFRESH_ACTIVITIES_REQUEST:
    case FETCH_FORM_ATTACHMENTS:
    case DELETE_FORM_ATTACHMENT:
    case FETCH_FORM_LINKS_REQUEST:
    case POST_FORM_LINK_REQUEST:
    case DELETE_FORM_LINK_REQUEST:
    case EDIT_FORM:
    case FETCH_FORM_PARENT_REQUEST:
    case FETCH_FORM:
    case GET_FORM_TEMPLATES:
    case GET_FORM_TEMPLATE:
    case GET_REPORTS_REQUEST:
    case POST_REPORT_REQUEST:
    case UPDATE_REPORT_REQUEST:
    case POST_DRAFT_REPORT_REQUEST:
    case UPDATE_DRAFT_REPORT_REQUEST:
    case DELETE_REPORT_REQUEST:
    case POST_EMPTY_DRAFT_REPORT_REQUEST:
    case GET_USER_REQUEST:
    case GET_USER_PROFILE_REQUEST:
    case GET_STATS_REQUEST:
    case GET_USERS_REQUEST:
    case GET_USERS_SEARCH_REQUEST:
    case ADD_ONBEHALF:
    case RESET_ONBEHALF:
    case GET_FORM_ACTIONS_REQUEST:
    case LOGOUT_REQUEST:
      return saveState(true);
    case FETCH_ACTIONS_SUCCESS:
    case FETCH_ACTIONS_ERROR:
    case REFRESH_ACTIONS_SUCCESS:
    case REFRESH_ACTIONS_ERROR:
    case FETCH_ACTIVITY_FEED_SUCCESS:
    case FETCH_ACTIVITY_FEED_ERROR:
    case FETCH_ACTIVITIES_SUCCESS:
    case FETCH_ACTIVITIES_ERROR:
    case REFRESH_ACTIVITIES_SUCCESS:
    case REFRESH_ACTIVITIES_ERROR:
    case FETCH_FORM_ATTACHMENTS_SUCCESS:
    case FETCH_FORM_ATTACHMENTS_ERROR:
    case DELETE_FORM_ATTACHMENT_SUCCESS:
    case DELETE_FORM_ATTACHMENT_ERROR:
    case FETCH_FORM_LINKS_SUCCESS:
    case FETCH_FORM_LINKS_ERROR:
    case POST_FORM_LINK_SUCCESS:
    case POST_FORM_LINK_ERROR:
    case DELETE_FORM_LINK_SUCCESS:
    case DELETE_FORM_LINK_ERROR:
    case EDIT_FORM_SUCCESS:
    case EDIT_FORM_ERROR:
    case FETCH_FORM_PARENT_SUCCESS:
    case FETCH_FORM_PARENT_ERROR:
    case FETCH_FORM_SUCCESS:
    case FETCH_FORM_ERROR:
    case GET_FORM_TEMPLATES_SUCCESS:
    case GET_FORM_TEMPLATES_ERROR:
    case GET_FORM_TEMPLATE_SUCCESS:
    case GET_FORM_TEMPLATE_ERROR:
    case GET_REPORTS_SUCCESS:
    case GET_REPORTS_ERROR:
    case POST_REPORT_SUCCESS:
    case POST_REPORT_ERROR:
    case UPDATE_REPORT_SUCCESS:
    case UPDATE_REPORT_ERROR:
    case POST_DRAFT_REPORT_SUCCESS:
    case POST_DRAFT_REPORT_ERROR:
    case UPDATE_DRAFT_REPORT_SUCCESS:
    case UPDATE_DRAFT_REPORT_ERROR:
    case DELETE_REPORT_SUCCESS:
    case DELETE_REPORT_ERROR:
    case POST_EMPTY_DRAFT_REPORT_SUCCESS:
    case POST_EMPTY_DRAFT_REPORT_ERROR:
    case GET_USER_SUCCESS:
    case GET_USER_ERROR:
    case GET_USER_PROFILE_SUCCESS:
    case GET_USER_PROFILE_ERROR:
    case GET_STATS_SUCCESS:
    case GET_STATS_ERROR:
    case GET_USERS_SUCCESS:
    case GET_USERS_ERROR:
    case GET_FORM_ACTIONS_SUCCESS:
    case GET_FORM_ACTIONS_ERROR:
    case LOGOUT:
    case LOGOUT_ERROR:
      return saveState(false);
    default:
      return defaultReducer(state, action);
  }
};
