import { get, isEmpty } from 'lodash';

import { POST_DRAFT_REPORT_REQUEST } from './';
import { postDraftReport } from '../api/reports';
import postDraftReportSuccess from './post-draft-report-success';
import postDraftReportError from './post-draft-report-error';
import resetOnBehalf from './reset-onBehalf';

export default (data, next = () => {}) => async (dispatch, getState) => {
  const {
    session: {
      currentUser: { agencyId, organizationalUnitId, hierarchyKey },
    },
    form: {
      selectedForm: { template },
      templates,
      __usersOnBehalf,
    },
  } = getState();
  const templateId = templates[template].id;

  if (data && !data.organizationalUnitId) {
    data = {
      ...data,
      organizationalUnitId: organizationalUnitId,
      hierarchyKey: hierarchyKey,
    };
  }

  dispatch({ type: POST_DRAFT_REPORT_REQUEST });

  return new Promise(async (resolve, reject) => {
    try {
      const report = await postDraftReport({
        agencyId,
        templateId,
        data: {
          ...data,
          __usersOnBehalf: !isEmpty(__usersOnBehalf) ? __usersOnBehalf : {},
        },
      });
      dispatch(postDraftReportSuccess(report));
      dispatch(resetOnBehalf());
      next(false, report);
      resolve(report);
    } catch (e) {
      dispatch(postDraftReportError(get(e, ['response', 'status'])));
      next(e);
      reject(e);
    }
  });
};
