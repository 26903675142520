import styled from 'styled-components';

const WrapperUserAssignment = styled.div`
  .user-assignment-normal {
    background-color: #fff;
    border: 1px solid #d9d9d9;
  }

  .user-assignment-detail {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }

  .user-assignment-container {
    margin-bottom: 12px;
    padding-right: 32px;
  }

  .user-assignment-action {
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    padding-right: 10px;
  }

  .user-assignment {
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
  }

  .ant-breadcrumb a {
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
  }

  .ant-breadcrumb > span:last-child a {
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
  }
`;

export default WrapperUserAssignment;
