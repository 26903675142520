import styled from 'styled-components';
import { Checkbox } from 'antd';

import { GRAY_5 } from '../../../config/theme';

const StyledCheckbox = styled(Checkbox).attrs({
  className: 'bdm-input-switch',
})`
  &.is-reviewing {
    margin-right: 25px;
  }

  .ant-checkbox .ant-checkbox-inner {
    border-color: ${GRAY_5};
  }
`;

export default StyledCheckbox;
