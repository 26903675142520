import React from 'react';
import ReactQuill from 'react-quill';
import { Row, Col } from 'antd';

import RowWrapper from './getContentFieldOptions.styled';

export default class ContentFieldOption extends React.Component {
  onChangeContent = content => {
    const { onChange } = this.props;

    onChange && onChange(content);
  };

  render = () => {
    const { value } = this.props;

    return (
      <RowWrapper className="static-content">
        <Row type="flex" justify="center">
          <Col span={4}>
            <strong>Content</strong>
          </Col>
        </Row>

        <Row>
          <ReactQuill
            theme="snow"
            value={value}
            onChange={this.onChangeContent}
          />
        </Row>
      </RowWrapper>
    );
  };
}
