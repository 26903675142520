import React from 'react';
import styled from 'styled-components';

import GroupedChartItem from './grouped-chart-item';

export default ({ items, maxValue = 0 }) => {
  const { label, data } = items;

  return (
    <GroupedColumn>
      <StyledColumn>
        {data &&
          data.map((item, index) => (
            <GroupedChartItem
              key={index}
              item={item}
              maxValue={maxValue}
              label={label}
            />
          ))}
      </StyledColumn>
      <h3>{label}</h3>
    </GroupedColumn>
  );
};

const GroupedColumn = styled.div`
  h3 {
    margin-top: 1em;
  }
`;

const StyledColumn = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
`;
