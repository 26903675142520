import { camelCase } from 'lodash';

import agencyProfileRoutes from '../agency-profile/AgencyProfile.routes';
import Agencies from './Agencies';
import { PERMISSIONS } from '../../../utils/admin';

export default ({ path = '', name = '' }) => {
  const routeConfig = {
    path: `${path}/agencies`,
    name: camelCase(`${name}-agencies`),
  };

  return [
    {
      ...routeConfig,
      protectedWith: [
        PERMISSIONS.manageAgencies,
        PERMISSIONS.accessAnalyticsReports,
      ],
      component: Agencies,
      childRoutes: [
        ...agencyProfileRoutes({
          ...routeConfig,
          name: camelCase(`${name}-agency`),
        }),
      ],
    },
  ];
};
