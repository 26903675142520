import styled from 'styled-components';
import { SIDEBAR_BG } from '../../config/theme';

export const SidebarFooter = styled.div`
  background-color: ${SIDEBAR_BG};

  @media screen and (max-width: 992px) {
    .footer-logo {
      margin: 5px 25px;
    }
  }
`;
