import validateConditions from 'APP_ROOT/utils/validateConditions';

import getGroupData from './getGroupData';

const getIsReadOnly = props => {
  const {
    data = {},
    parentIndex = 0,
    isReviewer = false,
    asReviewer = false,
    readOnlyConditions,
  } = props;

  const groupData = getGroupData(props);

  return (
    asReviewer ||
    isReviewer ||
    // these conditions are meant when the form is in edit mode but some fields need to be readonly
    (!!readOnlyConditions &&
      validateConditions(readOnlyConditions, groupData, data, parentIndex))
  );
};

export default getIsReadOnly;
