import {
  DYNAMIC_SELECT,
  VERTICAL_INPUT,
  AVATAR,
  FILE,
  STRING,
  BOOLEAN,
  SYNC_TO,
  SOURCE_FIELD,
} from './fieldTypes';

export default [
  DYNAMIC_SELECT,
  VERTICAL_INPUT,
  AVATAR,
  FILE,
  STRING,
  BOOLEAN,
  SYNC_TO,
  SOURCE_FIELD,
];
