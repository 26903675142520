import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import find from 'lodash/find';
import first from 'lodash/first';
import omit from 'lodash/omit';

export default ({ templateType, templates, parentIndex, options }) => {
  const defaultTasks = { tasks: [] };
  const template = Object.assign(
    {},
    defaultTasks,
    get(templates, templateType, {})
  );
  const templateEnums = get(template, 'formSchema.enums', {});
  const taskDocuments = get(template, 'tasks');
  const loopFromEnumRef = get(options, 'loopFromEnumRef', 'trainingCategories');
  const currentTaskFromEnum = get(
    templateEnums,
    `${loopFromEnumRef}.${parentIndex}`,
    ''
  );
  const currentTaskValue = isPlainObject(currentTaskFromEnum)
    ? get(currentTaskFromEnum, 'value', '')
    : currentTaskFromEnum;
  const currentTask = find(
    taskDocuments,
    item => item.name === currentTaskValue
  );

  if (currentTask) {
    return Object.assign({}, omit(currentTask, ['attachments']), {
      file: first(get(currentTask, 'attachments', [])),
    });
  }

  return null;
};
