import { createReducer } from 'redux-create-reducer';
import { get } from 'lodash';
import {
  ADD_ATTACHMENT,
  UPDATE_ATTACHMENT,
  REMOVE_ATTACHMENT,
  DELETE_ALL_ATTACHMENTS,
  FETCH_FORM_ATTACHMENTS,
  FETCH_FORM_ATTACHMENTS_SUCCESS,
  FETCH_FORM_ATTACHMENTS_ERROR,
  DELETE_FORM_ATTACHMENT,
  DELETE_FORM_ATTACHMENT_SUCCESS,
  DELETE_FORM_ATTACHMENT_ERROR,
} from '../actions';
import FORM_TYPES from '../constants/form-types';

const initialState = {
  ...Object.keys(FORM_TYPES).reduce(
    (prev, next) => ({ ...prev, [next]: {} }),
    {}
  ),
  loading: false,
  fetched: false,
  error: null,
};

const events = {
  [ADD_ATTACHMENT]: (
    state,
    { payload: { kind, formId, field, attachment } }
  ) => ({
    ...state,
    [kind]: {
      ...state[kind],
      [formId]: {
        ...get(state, [kind, formId], {}),
        [field]: {
          ...get(state, [kind, formId, field], {}),
          [attachment.id]: attachment,
        },
      },
    },
  }),
  [UPDATE_ATTACHMENT]: (
    state,
    { payload: { kind, formId, field, attachment } }
  ) => ({
    ...state,
    [kind]: {
      ...state[kind],
      [formId]: {
        ...get(state, [kind, formId], {}),
        [field]: {
          ...get(state, [kind, formId, field], {}),
          [attachment.id]: {
            ...get(state, [kind, formId, field, attachment.id], {}),
            ...attachment,
          },
        },
      },
    },
  }),
  [REMOVE_ATTACHMENT]: (
    state,
    { payload: { kind, formId, field, attachmentId } }
  ) => ({
    ...state,
    [kind]: {
      ...state[kind],
      [formId]: {
        ...state[kind][formId],
        [field]: Object.keys(get(state, [kind, formId, field], {})).reduce(
          (prev, next) =>
            next === attachmentId
              ? { ...prev }
              : {
                  ...prev,
                  [next]: state[kind][formId][field][next],
                },
          {}
        ),
      },
    },
  }),
  [DELETE_ALL_ATTACHMENTS]: (state, { payload: { kind, formId } }) => ({
    ...state,
    [kind]: Object.keys(state[kind] || {}).reduce(
      (prev, next) =>
        next === formId ? { ...prev } : { ...prev, [next]: state[kind][next] },
      {}
    ),
    loading: false,
  }),
  [FETCH_FORM_ATTACHMENTS]: state => ({ ...state, loading: true }),
  [FETCH_FORM_ATTACHMENTS_SUCCESS]: (
    state,
    { payload: { kind, formId, field, attachments, singleUpload } }
  ) => {
    const data = singleUpload
      ? {}
      : {
          [kind]: {
            ...state[kind],
            [formId]: {
              ...get(state, [kind, formId], {}),
              [field]: {
                ...get(state, [kind, formId, field], {}),
                ...attachments.reduce(
                  (prev, next) => ({ ...prev, [next.id]: next }),
                  {}
                ),
              },
            },
          },
        };

    return {
      ...state,
      loading: false,
      fetched: true,
      ...data,
    };
  },
  [FETCH_FORM_ATTACHMENTS_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    fetched: false,
    error,
  }),
  [DELETE_FORM_ATTACHMENT]: state => ({ ...state, loading: true }),
  [DELETE_FORM_ATTACHMENT_SUCCESS]: (
    state,
    { payload: { kind, formId, field, attachmentId, singleUpload } }
  ) => {
    const data = singleUpload
      ? {}
      : {
          [kind]: {
            ...state[kind],
            [formId]: {
              ...state[kind][formId],
              [field]: Object.keys(
                get(state, [kind, formId, field], {})
              ).reduce((prev, next) => {
                if (next === attachmentId) {
                  return prev;
                }
                return {
                  ...prev,
                  [next]: state[kind][formId][field][next],
                };
              }, {}),
            },
          },
        };
    return {
      ...state,
      loading: false,
      ...data,
    };
  },
  [DELETE_FORM_ATTACHMENT_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    loading: false,
    error,
  }),
};

export default createReducer(initialState, events);
