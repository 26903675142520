import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';
import some from 'lodash/some';

export const getOverrides = (props, overrides) => {
  const hasParent = !!props.parentKey;

  // Get the current field overrides (if exist)
  const fieldOverrides =
    get(
      overrides,
      hasParent ? `${props.parentKey}_item_${props.dataKey}` : props.dataKey
    ) || {};

  // This is what would be the result for plain object
  const nextProps = Object.assign({}, props, fieldOverrides);

  // Evaluate if is it needed to do individual override
  const hasObjectChildren = some(props, item => isPlainObject(item));

  // If some props are also objects, override them individually
  const nextOverrides = hasObjectChildren
    ? Object.keys(nextProps).reduce(
        (result, key) => ({
          ...result,
          [key]: isPlainObject(get(props, key))
            ? Object.assign({}, get(props, key), get(fieldOverrides, key))
            : get(nextProps, key),
        }),
        nextProps
      )
    : nextProps;

  return (name, defaultValue = null) => {
    if (name) {
      return get(nextOverrides, name) || defaultValue;
    }

    return nextOverrides;
  };
};

export default props => (name, defaultValue = null) =>
  name ? get(props, name, defaultValue) : props;
