import styled from 'styled-components';
import {
  GRAY_1,
  SIDEBAR_QUICKLINK_COLOR,
  SIDEBAR_DISCLAIMER_COLOR,
} from '../../config/theme.js';

const Footer = styled.div.attrs({
  className: 'bdm-footer',
})`
  padding: 15px 0;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background-color: ${props => props.background || GRAY_1};

  .footer-quick-links {
    display: block;

    li {
      display: inline-block;
      margin: 0 15px;

      a {
        color: #fff;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  &.sidebar-footer {
    color: ${SIDEBAR_DISCLAIMER_COLOR};
    font-family: 'Lato', Arial, sans-serif;
    font-size: 10px;
    width: 200px;

    .footer-quick-links {
      margin-bottom: 10px;

      li {
        a {
          color: ${SIDEBAR_QUICKLINK_COLOR};
          font-size: 12px;
          font-weight: 600;
          display: inline-block;
          border-bottom: 1px solid ${SIDEBAR_QUICKLINK_COLOR};

          &,
          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
          }
        }
      }
    }

    &.is-collapsed {
      overflow: hidden;

      > * {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
`;

export const AppFooter = styled(Footer)`
  position: relative;
  padding: 13px 10px;

  .footer-logo {
    display: inline-block;
    vertical-align: middle;
  }
`;

export default Footer;
