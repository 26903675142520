export default components => (type = 'workflow') => {
  switch (type) {
    case 'timeout':
    case 'activity':
    case 'workflow':
    case 'share':
    case 'reassign':
    case 'formAdministrativeEdit':
    case 'contribute-report':
      return components[type];
    default:
      return components['workflow'];
  }
};
