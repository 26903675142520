import {
  genOptions,
  route,
  fetchRequest,
  encodeFilter,
} from '../utils/request';
import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';
import { authorizedRequest } from 'APP_ROOT/api/utils';

export const fetchNotifications = (userId = '', filters = {}) => {
  const filter = encodeFilter(filters);
  const token = storage.get('token');
  const url = route(
    urlBuilder(`/BenchmarkUsers/:userId/notifications`, { userId }, { filter })
  );
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

export const markNotificationSeen = (userId = '', notificationId) => {
  const token = storage.get('token');
  const url = route(
    urlBuilder(
      `/BenchmarkUsers/:userId/notifications/:notificationId/seen`,
      { userId, notificationId },
      {}
    )
  );
  const options = genOptions('PUT', null, token);

  return fetchRequest(url, options);
};

export const markNotificationDismiss = (userId = '', notificationId) => {
  const token = storage.get('token');
  const url = route(
    urlBuilder(
      `/BenchmarkUsers/:userId/notifications/:notificationId`,
      { userId, notificationId },
      {}
    )
  );
  const options = genOptions('DELETE', null, token);

  return fetchRequest(url, options);
};

export const getNotificationsCount = ({ userId, filter: where }) => {
  const encodedFilter = encodeFilter({ where });
  const url = route(
    urlBuilder(
      `/BenchmarkUsers/:userId/notifications/count`,
      { userId },
      { filter: encodedFilter }
    )
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

/**
 * Inserts a notification for user
 * @param {Number} userId user that receives the notification
 * @param {Object} payload
 * @return {Promise}
 */
export const addContributeToReportNotification = (userId, payload) => {
  const url = route(
    urlBuilder('/BenchmarkUsers/:userId/notifications', { userId }, {})
  );
  return authorizedRequest('POST', url, payload, '', true);
};

/**
 * Deletes section assignation notifications for given user
 * @param {Number} userId
 * @returns {Promise}
 */
export const deleteContributeNotifications = userId => {
  const url = route(
    urlBuilder('/BenchmarkUsers/:userId/notifications/contribute', { userId })
  );
  return authorizedRequest('DELETE', url, {});
};
