import React from 'react';
import Table from 'antd/lib/table';
import StyledRolesTable from '../../../modules/Security/components/RolesTable/RolesTable.styled';
const { Column } = Table;

export default ({ roles = [], loading }) => (
  <StyledRolesTable>
    <Table
      dataSource={roles}
      rowKey={record => record.id}
      pagination={false}
      loading={loading}
    >
      <Column
        title="Environment"
        dataIndex="environment"
        key="rank"
        sorter={(a, b) => a.environment.localeCompare(b.environment)}
      />
      <Column
        title="Permission ID"
        dataIndex="id"
        key="id"
        sorter={(a, b) => a.id - b.id}
      />
      <Column
        title="Permission Name"
        dataIndex="name"
        key="updated"
        defaultSortOrder="ascend"
        sorter={(a, b) => a.name.localeCompare(b.name)}
      />
      <Column
        title="Permission Description"
        dataIndex="description"
        key="updated"
        sorter={(a, b) => a.description.localeCompare(b.description)}
      />
    </Table>
  </StyledRolesTable>
);
