import React from 'react';
import classNames from 'classnames';
import { isEqual, castArray, find, omit } from 'lodash';
import { Modal } from 'antd';
import { isMoment } from 'moment';

import InputWrapper from './InputWrapper.styled';
import Label from './Label.styled';

const mapValueFromDropdownOptions = (value, options) => {
  const _out = castArray(value)
    .map(_value => {
      const _item = find(options, item => item.value === _value);

      if (_item) {
        return _item.label;
      }

      return _value;
    })
    .join(', ');

  return _out;
};

const Input = Field =>
  class InputComponent extends React.Component {
    shouldComponentUpdate(nextProps) {
      return !isEqual(nextProps, this.props);
    }

    render() {
      const {
        input,
        type,
        placeholder,
        meta: { touched, error, form },
        label,
        options = {},
        plainText = false,
        disabled,
        dropdownOptions = [],
        plainDropdownOptions = [],
        addon = '',
        inputProps = {},
        defaultValue,
        mode,
        'data-test': dataTestValue = '',
      } = this.props;

      const { name, value, onChange } = input;
      const id = `${form}_${name}`;
      const {
        labelCol = 'ant-col-6',
        inputCol = 'ant-col-16',
        mustConfirmChange = false,
        promptMessage = 'Do you want to continue?',
        promptConfirmText = 'Continue',
        promptCancelText = 'Cancel',
        promptOnConfirm,
      } = options;

      let plainTextValue = value;

      if (
        ['time', 'date', 'datetime'].includes(type) &&
        inputProps.format &&
        isMoment(value)
      ) {
        plainTextValue = value.format(inputProps.format);
      } else if (
        type === 'boolean' &&
        inputProps.checkedChildren &&
        inputProps.unCheckedChildren
      ) {
        plainTextValue = value
          ? inputProps.checkedChildren
          : inputProps.unCheckedChildren;
      }

      const fieldValue =
        ['select', 'multiselect', 'autocomplete'].includes(type) && !value
          ? undefined
          : value;

      // for current antd version antd@3.26.14 keyword `mode` is not supported
      // for DatePicker, it is supported for version 4.x and because of that
      // we cannot have a datetime working properly, to solve the problem we
      // will keep keyword `mode` only where we really need it
      const fieldMode = ['select', 'multiselect', 'autocomplete'].includes(type)
        ? { mode }
        : undefined;

      // there is also a problem with function blur, antd@3.26.14 DatePicker is
      // not handling correctly `onBlur` method and to avoid weird behavior
      // better remove it for datetime type
      const fieldInput =
        type === 'datetime' ? { ...omit(input, 'onBlur') } : input;

      const onFieldValueChange = (...args) => {
        const performChange = cb => {
          onChange(...args);
          cb && cb();
        };

        const expectConfirmation =
          typeof mustConfirmChange === 'function'
            ? mustConfirmChange(fieldValue)
            : mustConfirmChange;

        if (expectConfirmation) {
          Modal.confirm({
            title: 'Heads Up!',
            content: (
              <div>
                {castArray(promptMessage).map((textLine, currentIndex) => (
                  <p key={currentIndex}>{textLine}</p>
                ))}
              </div>
            ),
            iconType: 'question-circle',
            onOk: () => performChange(promptOnConfirm),
            okText: promptConfirmText,
            cancelText: promptCancelText,
          });
        } else {
          performChange();
        }
      };

      return (
        <InputWrapper
          className={classNames(
            'ant-row ant-form-item ant-form-item-no-colon',
            { 'ant-form-item-with-help': touched && error }
          )}
        >
          {label && (
            <div
              className={classNames('ant-form-item-label text-left', labelCol)}
            >
              <Label htmlFor={id}>{label}</Label>
            </div>
          )}

          <div
            className={classNames(
              'ant-form-item-control-wrapper',
              label ? inputCol : 'ant-col-24'
            )}
          >
            <div
              className={classNames('ant-form-item-control', {
                'has-error': touched && error,
              })}
            >
              <span className="ant-form-item-children">
                {plainText && (
                  <span
                    className="ant-form-text ant-form-text-field"
                    data-test={dataTestValue}
                  >
                    {value &&
                    ['select', 'multiselect', 'autocomplete'].includes(type)
                      ? mapValueFromDropdownOptions(value, plainDropdownOptions)
                      : castArray(plainTextValue || defaultValue || ' ').join(
                          ', '
                        )}{' '}
                    {addon && (
                      <span className="ant-form-text ant-form-text-addon">
                        {' '}
                        {addon}
                      </span>
                    )}
                  </span>
                )}

                {!plainText && (
                  <Field
                    {...fieldInput}
                    {...inputProps}
                    data-test={dataTestValue}
                    onChange={onFieldValueChange}
                    placeholder={placeholder}
                    type={
                      ['text', 'password', 'email', 'number'].includes(type)
                        ? type
                        : undefined
                    }
                    id={id}
                    size="default"
                    disabled={disabled}
                    value={fieldValue || defaultValue}
                    {...fieldMode}
                    // eslint-disable-next-line react/no-children-prop
                    children={
                      ['select', 'multiselect', 'autocomplete'].includes(type)
                        ? dropdownOptions
                        : null
                    }
                  />
                )}

                {!plainText && addon && (
                  <span
                    className="ant-form-text ant-form-text-addon"
                    data-test={dataTestValue}
                  >
                    {' '}
                    {addon}
                  </span>
                )}
              </span>

              {touched && error && (
                <div className="ant-form-explain">{error}</div>
              )}
            </div>
          </div>
        </InputWrapper>
      );
    }
  };

export class InlineInputWrapper extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    const {
      label,
      options = {},
      children,
      'data-test': dataTestValue = '',
    } = this.props;

    const { labelCol = 'ant-col-6', inputCol = 'ant-col-16' } = options;

    return (
      <InputWrapper className="ant-row ant-form-item ant-form-item-no-colon">
        <div className={classNames('ant-form-item-label text-left', labelCol)}>
          <Label>{label}</Label>
        </div>

        <div className={classNames('ant-form-item-control-wrapper', inputCol)}>
          <div className="ant-form-item-control" data-test={dataTestValue}>
            {children}
          </div>
        </div>
      </InputWrapper>
    );
  }
}

export default Input;
