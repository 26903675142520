import { uniqWith, castArray, isEmpty } from 'lodash';

import { REPEATER } from 'APP_ROOT/constants/layoutComponentTypes';

import componentsManager from '../services/componentsManager';

import getAllFieldsFlat from './getAllFieldsFlat';
import getFieldLabel from './getFieldLabel';

const mapProperties = prop => ({
  value: prop.key,
  label: prop.title || prop.reportingKey || prop.field_type,
});

const filterPropperties = filterBy => {
  let filterProps;
  if (typeof filterBy === 'function') {
    filterProps = filterBy;
  } else {
    const _filterBy = castArray(filterBy);
    if (isEmpty(_filterBy)) {
      filterProps = () => true;
    } else {
      filterProps = prop => _filterBy.includes(prop.field_type);
    }
  }
  return filterProps;
};

const getCascaderOptionsFromFields = (
  currentFieldId,
  addExtra = false,
  filterBy = [],
  { allFields, repeatersReference } = {}
) => {
  const _allFields =
    allFields ||
    getAllFieldsFlat(componentsManager.fields, currentFieldId, addExtra);

  const { allTabs } = _allFields;
  const _repeatersReference =
    repeatersReference || componentsManager.repeatersReference;
  const repeaters = [];
  const cascaderOptions = allTabs.map((tab, i) => {
    // since we use key to identify fields, there is no point to keep duplicated keys
    // also, react is very picky with the duplicated keys
    let properties = uniqWith(
      tab.properties,
      (arrVal, othVal) => arrVal.key === othVal.key
    ).filter(filterPropperties(filterBy));
    repeaters[i] = {};
    if (_repeatersReference[i]) {
      _repeatersReference[i].forEach(({ fields, repeaterKey }) => {
        repeaters[i][repeaterKey] = properties.filter(prop => fields[prop.key]);
        properties = properties.filter(prop => !fields[prop.key]);
      });
    }
    return {
      value: tab.key,
      label: tab.title || tab.field_type,
      children: properties.map(mapProperties),
    };
  });

  repeaters.forEach((repeater, i) => {
    Object.entries(repeater).forEach(([key, properties]) => {
      if (properties && properties.length) {
        const [repeater] = componentsManager.findContainersByKey(
          componentsManager.fields,
          key
        );
        cascaderOptions[i].children.push({
          value: key,
          label: repeater.reportingKey || getFieldLabel(REPEATER),
          children: properties.map(mapProperties),
        });
      }
    });
  });

  return cascaderOptions.filter(t => !isEmpty(t.children));
};

export default getCascaderOptionsFromFields;
