import { memoize } from 'lodash';

const getFormSettings = (
  syncTab,
  formSchema,
  validateFields,
  validateFormFields,
  getTabFieldsError,
  saveTabRef,
  supportsDraft,
  formExist,
  templateType,
  agencyId,
  formName,
  isEditor,
  isEditMode
) => ({
  syncTab,
  formSchema,
  validateFields,
  validateFormFields,
  getTabFieldsError,
  saveTabRef,
  supportsDraft,
  formExist,
  templateType,
  agencyId,
  formName,
  isEditor,
  isEditMode,
});

export default getFormSettings;
export const getFormSettingsMemo = memoize(getFormSettings);
