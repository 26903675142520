import { get } from 'lodash';
import { FETCH_FORM_PARENT_REQUEST } from './';
import { getReport } from '../api/reports';
import fetchFormParentSuccess from './fetch-form-parent-success';
import fetchFormParentError from './fetch-form-parent-error';

export default (next = () => {}) => {
  return (dispatch, getState) => {
    const {
      form: {
        selectedForm: {
          meta: { agencyId, formParentId },
        },
      },
    } = getState();
    if (formParentId) {
      dispatch({ type: FETCH_FORM_PARENT_REQUEST });
      getReport({
        agencyId,
        id: formParentId,
        filter: ['participants'],
      })
        .then(response => {
          dispatch(fetchFormParentSuccess(response));
          next(null, response);
        })
        .catch(error => {
          dispatch(fetchFormParentError(get(error, ['response', 'status'])));
          next(error);
        });
    }
  };
};
