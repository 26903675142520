import { getParticipant, transformParticipants } from 'APP_ROOT/utils/form';

import getModalTitleAndTextSubmit from './getModalTitleAndTextSubmit';
import getModalBody from './getModalBody';
import getMoreInfoLinked from './getMoreInfoLinked';

const getModalOptions = ({
  report,
  isBlendLink,
  isFinalLinked,
  linkedSubmission,
  sendingMoreInfo,
  actionLabel,
  formType,
  formNumber,
}) => {
  const { activeReviewerId = '', participants = [] } = report;

  const transformedParticipants = transformParticipants(participants);
  const activeReviewer =
    getParticipant(activeReviewerId, transformedParticipants) || {};
  const {
    rank: { name: activeReviewerRank = '' } = {},
    fullName: activeReviewerfullName = '',
  } = activeReviewer;

  const [Title, TextSubmit] = getModalTitleAndTextSubmit({
    isBlendLink,
    isFinalLinked,
    activeReviewerId,
    linkedSubmission,
    sendingMoreInfo,
    activeReviewerRank,
    activeReviewerfullName,
    actionLabel,
    formType,
    formNumber,
  });

  const TextRequest = getModalBody(
    {},
    `You have sent this report back to ${activeReviewerRank} ${activeReviewerfullName} for more information`
  );

  const MoreInfoLinked = getMoreInfoLinked(
    activeReviewerRank,
    activeReviewerfullName,
    linkedSubmission,
    formType
  );

  const MoreInfo = isBlendLink ? MoreInfoLinked : TextRequest;

  return {
    id: 'report-success',
    title: Title,
    children: !sendingMoreInfo ? TextSubmit : MoreInfo,
    hideAll: true,
  };
};

export default getModalOptions;
