import React, { useEffect } from 'react';
import get from 'lodash/get';

import DashboardPage from '../../../components/dashboard';
import withoutClutter from '../../../components/without-clutter';
import withOwnershipGuard from '../components/agency-ownership-guard';
import getRoute from 'APP_ROOT/utils/get-route';
import AdministratorWrapper from '../Administrator.styled';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import AuditViewLogsPage from './pages/audit-view-logs-page';
import { AuditLogWrapper } from '../agency-audit-logs/AgencyAuditLogs.styled';
import { organizationClearViewLogs } from '../../../actions/organizations/get-view-audit-logs';

function AgencyAuditViewLogs({
  dispatch,
  auditLogs,
  agencyId,
  tenantId,
  timezone,
}) {
  useEffect(() => {
    return () => dispatch(organizationClearViewLogs());
  }, []);
  return (
    <AdministratorWrapper>
      <PageHeader
        title="User Profile Audit Logs"
        goBackTo={getRoute('administratorAgency', { agencyId })}
      ></PageHeader>
      <AuditLogWrapper>
        <AuditViewLogsPage
          agencyId={agencyId}
          tenantId={tenantId}
          dispatch={dispatch}
          auditLogs={auditLogs}
          timezone={timezone}
        />
      </AuditLogWrapper>
    </AdministratorWrapper>
  );
}

const mapStateToProps = (state, props) => ({
  auditLogs: get(state, 'organization.auditLogs'),
  agencyId: get(props, 'match.params.agencyId'),
  tenantId: get(props, 'match.params.tenantId'),
  timezone: get(state, 'session.currentUser.agency.timezone'),
});

export default withoutClutter(
  DashboardPage(
    mapStateToProps,
    null,
    {}
  )(withOwnershipGuard(AgencyAuditViewLogs, 'administratorAgencyViewAuditLogs'))
);
