import { getDataFromConditions } from 'APP_ROOT/utils/form';

const getTransformedData = (fields, formFields, data, isEditable) => {
  let transformedData;

  if (Object.keys(fields).length) {
    const allFields = formFields.fields.reduce(
      (acc, tab) => ({ ...acc, ...tab }),
      {}
    );
    transformedData = {
      ...getDataFromConditions(
        {
          ...data,
          isReviewer: !isEditable,
        },
        allFields,
        this.whiteListKeys
      ),
      ...fields,
    };
  }
  return transformedData;
};

export default getTransformedData;
