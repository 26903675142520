import get from 'lodash/get';

export const isOcalaOnPremise = identifier =>
  process.env.REACT_APP_RUNTIME_ENV === 'on-premise' && identifier === 'ocala';

export default (allowFor = [], session) => {
  const agencyIdentifier = get(session, 'currentUser.agency.identifier', '');

  return isOcalaOnPremise(agencyIdentifier) ? ['nobody'] : allowFor;
};

export const visibleOnlyForSandboxFromSession = (allowFor = []) => agency => {
  const agencyIdentifier = get(agency, 'identifier', '');

  return isOcalaOnPremise(agencyIdentifier) ? ['nobody'] : allowFor;
};
