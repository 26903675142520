import { last } from 'lodash';

const checkCascaderOption = (options, val) => {
  const { value, children } = options;

  if (children !== undefined) {
    return children.some(c => checkCascaderOption(c, val));
  } else {
    return value === val;
  }
};

const getCascaderOption = (cascaderOptions, parentRepeater, value) => {
  let option;
  const dataCtx = value.includes('data.');
  const values = value.split('.');
  const _value = last(values);
  const _values = dataCtx ? values.slice(1) : [...values];
  cascaderOptions.some(op => {
    if (checkCascaderOption(op, _value)) {
      option =
        parentRepeater && !dataCtx
          ? [op.value, parentRepeater, _value]
          : [op.value, ..._values];
      return true;
    }
    return false;
  });
  return option;
};

// to be called on onSave
export const saveValue = (parentRepeater, value) => {
  const _value = value.slice(1);
  if (parentRepeater) {
    return _value.length === 2 ? _value[1] : `data.${_value[0]}`;
  }
  return _value.length === 2 ? _value.join('.') : _value[0];
};

export default getCascaderOption;
