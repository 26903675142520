import React from 'react';
import { Row, Col, Icon } from 'antd';
import { Link } from 'react-router-dom';

const GotoBlock = ({ title, options, link = '' }) => (
  <div {...options}>
    <Row type="flex" align="middle">
      <Col span={10}>
        <p>{title}</p>
      </Col>
      <Col span={14} className="text-right">
        <Link to={link}>
          <Icon type="arrow-right" />
        </Link>
      </Col>
    </Row>
  </div>
);
export default GotoBlock;
