import React from 'react';

import OptionsModalBody from '../components/OptionsModalBody/OptionsModalBody';

const buildOptionsModalContent = (options, onCancel) => {
  return {
    onCancel,
    visible: true,
    children: <OptionsModalBody {...options} />,
  };
};

export default buildOptionsModalContent;
