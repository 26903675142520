import { createReducer } from 'redux-create-reducer';
import { get } from 'lodash';

import {
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  GET_STATS_REQUEST,
  GET_STATS_SUCCESS,
  GET_STATS_ERROR,
  CHANGE_STATS_UOF_DATE,
} from '../actions';

import moment from 'moment-timezone';
import { BENCHMARK_DATE_FORMAT } from '../utils/parse-date';

const initialStatsState = {
  loading: true,
  data: {},
  courses: [],
  dates: {
    from: {
      value: moment('01/01/2017', BENCHMARK_DATE_FORMAT),
      title: '01/01/2017',
    },
    to: { value: moment(), title: 'Today' },
  },
};

const statsCategories = {
  UOF: 'use-of-force',
  IA: 'internal-affairs',
};

const initialState = {
  loading: true,
  userId: null,
  data: {},
  history: [],
  courses: [],
  error: null,
  stats: {
    [statsCategories.UOF]: initialStatsState,
    [statsCategories.IA]: initialStatsState,
  },
};

const events = {
  [GET_USER_PROFILE_REQUEST]: state => {
    return {
      ...state,
      history: [],
      courses: [],
      firearmCertifications: [],
      nonFirearmCertifications: [],
      classes: [],
      loading: true,
      userId: null,
      error: null,
    };
  },
  [GET_USER_PROFILE_SUCCESS]: (
    state,
    {
      payload: {
        userId,
        data,
        history,
        courses = [],
        firearmCertifications = [],
        nonFirearmCertifications = [],
        classes = [],
      },
    }
  ) => {
    return {
      ...state,
      data,
      history,
      loading: false,
      userId,
      courses,
      firearmCertifications,
      nonFirearmCertifications,
      classes,
    };
  },
  [GET_USER_PROFILE_ERROR]: (state, { payload: error }) => {
    return {
      ...state,
      loading: false,
      error,
    };
  },
  [GET_STATS_REQUEST]: (state, { payload }) => {
    return {
      ...state,
      stats: {
        ...state.stats,
        [payload.category]: {
          ...get(state, `stats.${payload.category}`, {}),
          loading: true,
        },
      },
    };
  },
  [GET_STATS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      stats: {
        ...state.stats,
        [payload.category]: {
          ...get(state, `stats.${payload.category}`, {}),
          loading: false,
          data: get(payload, 'data', {}),
        },
      },
    };
  },
  [GET_STATS_ERROR]: (state, { payload }) => {
    return {
      ...state,
      stats: {
        ...state.stats,
        [payload.category]: {
          ...get(state, `stats.${payload.category}`, {}),
          loading: false,
          error: get(payload, 'error', {}),
        },
      },
    };
  },
  [CHANGE_STATS_UOF_DATE]: (
    state,
    { payload: { from: fromDate, to: toDate, timezone } }
  ) => {
    const today = moment()
      .tz(timezone)
      .format(BENCHMARK_DATE_FORMAT);
    const range = {
      from: { title: today === fromDate ? 'Today' : fromDate, value: fromDate },
      to: { title: today === toDate ? 'Today' : toDate, value: toDate },
    };
    return {
      ...state,
      stats: {
        ...state.stats,
        dates: range,
      },
    };
  },
};

export default createReducer(initialState, events);
