import callback from '../../utils/callback';
import OrganizationEndpoints from '../../../../api/organization/organizationEndpoints';

export default next => dispatch => {
  return OrganizationEndpoints.getAllOrganizationalUnitsOnHierarchyTree()
    .then(response => {
      function transformResponse(organizationalUnit) {
        if (
          organizationalUnit.children == [] ||
          organizationalUnit.children == null
        ) {
          return {
            value: organizationalUnit.id,
            label: organizationalUnit.displayName,
          };
        } else {
          return {
            value: organizationalUnit.id,
            label: organizationalUnit.displayName,
            children: organizationalUnit.children.map(ou =>
              transformResponse(ou)
            ),
          };
        }
      }

      const ouTree = response.organizationalUnits.map(ou =>
        transformResponse(ou)
      );
      callback(next, null, ouTree);
    })
    .catch(error => {
      callback(next, error);
    });
};
