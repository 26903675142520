import { isPlainObject } from 'lodash';
import { ResponseException } from 'APP_ROOT/utils/request';
import { notification } from 'antd';

const showError = description => {
  notification.error({ message: 'Error!', description });
};

const displayErrorMessage = (prefixMsg, error) => {
  setTimeout(() => {
    if (error instanceof EvalError || error instanceof RangeError) {
      // from try catch block
      showError(`${prefixMsg}: ${error.name} ${error.message}`);
    } else if (isPlainObject(error)) {
      // probably response from fetch
      showError(`${prefixMsg}: ${error.status} ${error.statusText}`);
    } else if (error instanceof ResponseException) {
      error.response.text().then(function(data) {
        if (data != '') {
          error.message = data;
        } else {
          error.message = 'API response is not ok';
        }

        showError(`${prefixMsg}: ${error.message}`);
      });
    } else {
      // string error
      showError(`${prefixMsg}: ${error}`);
    }
  }, 300);
};

export default displayErrorMessage;
