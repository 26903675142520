import { authorizedRequest } from 'APP_ROOT/api/utils';
import { route, REACT_APP_FORMS_SERVICE_URI } from 'APP_ROOT/utils/request';
import urlBuilder from 'APP_ROOT/utils/url-builder';
import { encodeFilter } from '../../../utils/request';

/**
 * Request to /Agencies/:agencyId/BenchmarkUsers.
 * @param {string} agencyId
 * @param {object} filter
 * @return {Promise}
 */
export const getUsers = (
  agencyId = '',
  formId = '',
  filters = {},
  hasReportUserSelectionFuzzySearch,
  page,
  size
) => {
  /*
  fuzzySearch query param is set to indicate that the request is made
  for this specific case "reassing modal". As a consequence the node-api's
  endpoint will expect certain format in the filter which is:
  where {or: [fullName: like ???],[lastName: ???]},
  be carefull when changing the filter here when using the fuzzySearch request
  param equal true.
  */
  const query = {
    fuzzySearch: hasReportUserSelectionFuzzySearch,
    filter: encodeFilter(filters),
  };
  if (page !== undefined && size !== undefined) {
    query.page = page;
    query.size = size;
  }
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/formId/:formId`,
      { agencyId, formId },
      query
    )
  );
  return authorizedRequest('GET', url, '', '', true);
};

/**
 * Request to /Forms/:fsId/share.
 * @param {string} fsId (formSubmitionId)
 * @param {array} reassignTo
 * @return {Promise}
 */
export const reassignReport = (fsId = '', reassignTo) => {
  const url = route(
    urlBuilder(`${REACT_APP_FORMS_SERVICE_URI}Forms/:fsId/reassign`, { fsId })
  );
  return authorizedRequest('POST', url, { reassignTo }, '', true);
};
