import { createElement } from 'react';
import { get } from 'lodash';

import {
  SECTION_WRAPPER,
  SECTION,
  UPLOAD,
  SINGLE_UPLOAD,
  ROW,
  COLUMN,
  FIELD,
  FIELD_GROUP,
  GROUP,
  REPEATER,
  INJURIES_BODY,
} from 'APP_ROOT/constants/layoutComponentTypes';

import FieldEditor from '../components/FieldEditor';
import SectionWrapperEditor from '../components/SectionWrapperEditor';
import SectionEditor from '../components/SectionEditor';
import RowEditor from '../components/RowEditor';
import ColumnEditor from '../components/ColumnEditor';
import FieldGroupEditor from '../components/FieldGroupEditor';
import GroupEditor from '../components/GroupEditor';
import RepeaterEditor from '../components/RepeaterEditor';
import UnsupportedField from '../components/UnsupportedField';
import InjuriesBodyEditor from '../components/InjuriesBodyEditor';
import UploadEditor from '../components/UploadEditor';
import SingleUploadEditor from '../components/SingleUploadEditor/SingleUploadEditor';

const componentsMap = {
  [FIELD]: FieldEditor,
  [SECTION_WRAPPER]: SectionWrapperEditor,
  [SECTION]: SectionEditor,
  [UPLOAD]: UploadEditor,
  [SINGLE_UPLOAD]: SingleUploadEditor,
  [ROW]: RowEditor,
  [COLUMN]: ColumnEditor,
  [FIELD_GROUP]: FieldGroupEditor,
  [GROUP]: GroupEditor,
  [REPEATER]: RepeaterEditor,
  [INJURIES_BODY]: InjuriesBodyEditor,
};

const mapChildren = fields =>
  fields.map(field => {
    const Component = get(componentsMap, field.type, UnsupportedField);
    const children = get(field, 'properties', []);
    return createElement(
      Component,
      {
        field: field,
        key: field.id,
        type: field.type,
        field_type: field.field_type,
        id: field.id,
        total: fields.length,
      },
      mapChildren(children)
    );
  });

export default mapChildren;
