import securityV2Endpoints from '../../../../api/security/securityV2Endpoints';
import {
  notificationSuccess,
  notificationError,
} from '../../../../modules/Security/components/RolesModal/utils';

const SOMETHING_BAD_HAPPEN = 'Something bad happened';

export const stepsArray = [
  {
    status: 'finish',
    description:
      'Permissions can be added or removed as a comma-separated list.',
  },
  {
    status: 'finish',
    description:
      'The isInternal flag set to ‘true’ hides the role from the Benchmark Roles field.',
  },
  {
    status: 'finish',
    description: 'Update rank number, if needed.',
  },
];

export const getRole = roleId => {
  try {
    return securityV2Endpoints.getSecurityRolesByRoleIds(roleId, '');
  } catch (e) {
    notificationError(SOMETHING_BAD_HAPPEN, e.message);
  }
};

const removeEntersKeyFromString = string => {
  return string.replaceAll('\n', '');
};

const removeSpacesFromString = string => {
  return string.replaceAll(' ', '');
};

const removeQuotesFromString = string => {
  return string.replaceAll('"', '');
};

export const updateRole = async (
  values,
  onRequestSuccess,
  onRequestFailure
) => {
  const {
    addPermissionsValue,
    isInternalValue,
    rankNumberValue,
    removePermissionsValue,
    roleId,
  } = values;
  const id = roleId;
  const payload = {
    addPermissionNames: removeQuotesFromString(
      removeSpacesFromString(removeEntersKeyFromString(addPermissionsValue))
    ),
    removePermissionNames: removeQuotesFromString(
      removeSpacesFromString(removeEntersKeyFromString(removePermissionsValue))
    ),
    rankNumber: rankNumberValue,
    isInternal: isInternalValue,
  };
  try {
    await securityV2Endpoints.patchSecurityAttributesToExistingRoles(
      id,
      payload
    );
    onRequestSuccess();
    notificationSuccess('The Role was updated successfully!');
  } catch (e) {
    notificationError(SOMETHING_BAD_HAPPEN, e.message);
    onRequestFailure();
  }
};
