import React, { Component, Fragment } from 'react';
import { Row, Col } from 'antd';
import { isPlainObject, get, isEmpty, capitalize } from 'lodash';
import { connect } from 'react-redux';

import { getDataSelector } from 'APP_ROOT/utils/renderSchema';
import BodyFront from './body-sides/body-front';
import BodyBack from './body-sides/body-back';
import { mapTitle } from '../../utils/conditionals';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';

import ObjectField from '../form-viewer/object-types/object-field';

class BodyDiagram extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  onClick = bodyPart => (name, selected) => {
    const {
      data,
      parentIndex,
      parentKey,
      form,
      isReviewer = false,
      disable: turnedOff = false,
    } = this.props;

    const dataKey = parentKey
      ? `${parentKey}[${parentIndex}].${bodyPart}`
      : bodyPart;
    const currentValues =
      (parentKey ? data[parentKey][parentIndex][bodyPart] : data[bodyPart]) ||
      [];

    if (!isReviewer && !turnedOff) {
      if (selected) {
        form.setFieldsValue({
          [dataKey]: [...currentValues.filter(key => key !== name)],
        });
      } else {
        form.setFieldsValue({
          [dataKey]: [...currentValues, name],
        });
      }
    }
  };

  handleSelectChange = value => {};

  createObjectField = () => {
    const { properties, disable: turnedOff = false } = this.props;

    return {
      type: 'object',
      properties: [
        {
          type: 'row',
          options: {
            className: 'body-dropdown',
          },
          properties: properties.map((property, index) => ({
            type: 'column',
            options: {
              xs: 24,
              sm: 12,
            },
            properties: [
              {
                ...property,
                disable: turnedOff,
                title: `Body Contact ${index === 0 ? 'Front' : 'Back'}`,
              },
            ],
          })),
        },
      ],
    };
  };

  getSide = property => {
    const { enumRef = '' } = property;
    const bodySide = get(property, 'options.side');
    let side;
    if (isEmpty(bodySide)) {
      side = enumRef === 'injuriesBodySideFront' ? 'Front' : 'Back';
    } else {
      side = capitalize(bodySide);
    }
    return side;
  };

  getValidParts = property => {
    const { templates } = this.props;
    const { enumRef = '' } = property;
    const templateType = get(this.props, 'settings.templateType');
    const bodyParts = get(
      templates,
      [templateType, 'formSchema', 'enums', enumRef],
      []
    );
    return bodyParts.map(b => b.value);
  };

  renderBodySides = () => {
    const {
      data,
      properties,
      parentKey,
      parentIndex,
      isReviewer = false,
      disable: turnedOff = false,
    } = this.props;

    return properties.map((property, index) => {
      const { key: bodyPart } = property;
      const currentValues = parentKey
        ? data[parentKey][parentIndex][bodyPart]
        : data[bodyPart];
      const side = this.getSide(property);
      const validParts = this.getValidParts(property);

      return (
        <Col key={index} span={12}>
          {side === 'Front' ? (
            <BodyFront
              key={property.key}
              selectedParts={currentValues}
              validParts={validParts}
              onClick={this.onClick(property.key)}
              isReviewer={isReviewer || turnedOff}
              disabled={turnedOff}
            />
          ) : (
            <BodyBack
              key={property.key}
              selectedParts={currentValues}
              validParts={validParts}
              onClick={this.onClick(property.key)}
              isReviewer={isReviewer || turnedOff}
              disabled={turnedOff}
            />
          )}
        </Col>
      );
    });
  };

  render() {
    const {
      data,
      parentKey,
      parentIndex,
      title = '',
      disable: turnedOff = false,
    } = this.props;

    const objectField = this.createObjectField();

    const groupData = !!parentKey ? data[parentKey][parentIndex] : data;

    const interpolatedTitle = title
      ? isPlainObject(title)
        ? mapTitle(title, parentIndex, data, groupData)
        : title
      : 'Select the injured body area';

    return (
      <Fragment>
        <Row gutter={32} style={{ maxWidth: '950px' }}>
          <Col span={24}>
            <p
              style={{
                marginBottom: 15,
                color: turnedOff ? '#b7b7b7' : 'inherit',
              }}
            >
              {interpolatedTitle}:
            </p>
          </Col>
        </Row>
        <Row gutter={32} style={{ maxWidth: '950px' }}>
          <div className="body-diagram">{this.renderBodySides()}</div>
        </Row>
        <Row gutter={50}>
          <Col span={24}>
            <Row
              gutter={16}
              style={{ paddingTop: 30, paddingBottom: 0, clear: 'both' }}
            >
              <Col span={24}>
                <ObjectField
                  disable={turnedOff}
                  {...this.props}
                  {...objectField}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapState = (state, props) => {
  const dataSelector = getDataSelector(props);

  return {
    data: dataSelector(state, props),
  };
};

export default connect(mapState)(BodyDiagram);
