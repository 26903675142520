import { fetchRequest, fetchRequestBlob } from '../../utils/request';
import ServiceClient from '../serviceClient';

class documentManagementEndpoints extends ServiceClient {
  _defaultDocumentMetadataFilters = {
    createdDate: '',
    documentCategory: '',
    documentDescription: '',
    documentName: '',
    documentSubject: '',
    documentType: '',
    uploadedBy: '',
    uploadedDate: '',
  };

  constructor() {
    const DEV_URI = 'http://localhost:3010';
    const apiPrefix = 'api/v1/doc';
    super(process.env.REACT_APP_DOCUMENT_SERVICE_URI || DEV_URI, apiPrefix);
  }

  parseDocumentMetadataFilters(filters) {
    let parsedFilters = { ...this._defaultDocumentMetadataFilters };

    Object.keys(parsedFilters).forEach(key => {
      if (filters[key]) {
        parsedFilters[key] = filters[key];
      }
    });

    return parsedFilters;
  }

  async getS3DocumentMetadata(
    sortDirection,
    pageNumber,
    pageSize,
    sortBy,
    filters,
    tenantSlug
  ) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/documents'),
        method: 'GET',
      },
      {},
      {
        direction: sortDirection,
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortBy: sortBy,
        source: 's3',
        tenantSlug: tenantSlug,
        ...this.parseDocumentMetadataFilters(filters),
      }
    );

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequest(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }

  getS3Document(fileKey) {
    const fetchParams = this.buildFetchParams(
      {
        path: this.withApiPrefix('/documents/:documentId'),
        method: 'GET',
      },
      {
        documentId: fileKey,
      },
      {}
    );

    return new Promise(async (resolve, reject) => {
      try {
        resolve(await fetchRequestBlob(fetchParams.URI, fetchParams.request));
      } catch (e) {
        reject(e);
      }
    });
  }
}

export default new documentManagementEndpoints();
