import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import Panel, {
  PanelTitle,
  Divider,
  PanelSection,
  PanelRow,
} from '../common/panel';
import { DIVIDER, GRAY_2, BLACK } from '../../config/theme';

export default ({ data = [], title = '', right, renderTitle }) => {
  const voidData = !data.length;

  return (
    <Panel>
      <PanelTitle right={right}>
        {renderTitle ? renderTitle() : <h2>{title || 'Panel'}</h2>}
      </PanelTitle>

      <Divider />

      {voidData && (
        <PanelSection>
          <PanelRow>No records found</PanelRow>
        </PanelSection>
      )}

      {!voidData &&
        data.length &&
        data.map((row, index) => {
          const { label, value } = row;
          return (
            <CustomRow key={index}>
              <Col xs={24} sm={6}>
                <p className="title">{label}:</p>
              </Col>
              <Col xs={24} sm={18}>
                <p className="value">{value}</p>
              </Col>
            </CustomRow>
          );
        })}
    </Panel>
  );
};

const CustomRow = styled(Row)`
  padding: 16px 0;
  border-bottom: 1px solid ${DIVIDER};

  &:last-child {
    border-bottom: none;
  }

  .title {
    color: ${BLACK};
  }

  .value {
    color: ${GRAY_2};
  }
`;
