import {
  UNBLOCK_NAVIGATION,
  BLOCK_NAVIGATION,
  PAUSE_IDLE_TIMER,
  RESUME_IDLE_TIMER,
} from './';

const [DONE, UPLOADING] = ['done', 'uploading'];

export default attachments => (dispatch, getState) => {
  const { app: { blockNavigation } = {} } = getState();

  const pendingAttachments = Object.keys(attachments)
    .map(id => attachments[id])
    .map(file => file.status || DONE)
    .includes(UPLOADING);

  if (blockNavigation && !pendingAttachments) {
    dispatch({ type: UNBLOCK_NAVIGATION });
    dispatch({ type: RESUME_IDLE_TIMER });
  } else if (!blockNavigation && pendingAttachments) {
    dispatch({ type: BLOCK_NAVIGATION });
    dispatch({ type: PAUSE_IDLE_TIMER });
  }
};
