import {
  fetchRequestBlob,
  genOptions,
  REACT_APP_FORMS_SERVICE_URI,
  route,
} from '../../utils/request';
import urlBuilder from '../../utils/url-builder';
import storage from '../../utils/storage';

export default (agencyId, formsId) => {
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/forms/:formsId/download`,
      { agencyId, formsId }
    )
  );
  const options = genOptions('POST', null, `Bearer ${storage.get('token')}`);
  return fetchRequestBlob(url, options);
};
