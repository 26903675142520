import {
  genOptions,
  route,
  fetchRequest,
  encodeFilter,
} from '../utils/request';
import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';

export const getFormSubmissionAttachments = ({ id = '', filters = {} }) => {
  const filter = encodeFilter({
    ...filters,
  });
  const url = route(urlBuilder('/Forms/:id/attachments', { id }, { filter }));
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const deleteFormSubmissionAttachment = ({
  formSubmissionId = '',
  id = '',
}) => {
  const url = route(
    urlBuilder('/Forms/:formSubmissionId/attachments/:id', {
      formSubmissionId,
      id,
    })
  );
  const options = genOptions('DELETE', null, storage.get('token'));

  return fetchRequest(url, options);
};
