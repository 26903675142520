import styled from 'styled-components';

import Box from './Box';

const BoxWrapper = styled(Box).attrs({ className: 'box-card' })`
  padding: 15px;

  &.is-white {
    background-color: #fff;
  }

  &.is-rounded {
    border-radius: 6px;
  }
`;

export default BoxWrapper;
