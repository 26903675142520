import React, { useEffect, useState } from 'react';
import { Button, Divider, notification, Modal, Typography, Icon } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';

import { StyledTable } from '../../AgencyCasefiles.styled';
import getReasonsTableConfig from './getReasonsTableConfig';

import { PageWrapper, Content, Header, Title } from './Reasons.styled';
import AddReasonModal from './AddReasonModal';
import caseFileEndpoints from '../../../../../api/caseFileEndpoints/caseFileEndpoints';
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from '../../constants';

const { confirm } = Modal;
const { Text } = Typography;

function Reasons(props) {
  const [reasons, setReasons] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { timezone } = props;
  const tableConfig = getReasonsTableConfig(timezone, handleDeleteReason);

  useEffect(() => {
    const { casefileType } = props;
    setLoadingData(true);
    caseFileEndpoints
      .getCasefileTypeReasons(casefileType.key)
      .then(response => {
        if (response) {
          const reasons = response.content.map(reason => ({
            key: reason.id,
            title: htmlUnescape(reason.label),
            createdAt: reason.createdAt,
          }));
          setReasons(reasons);
        }
      })
      .catch(err => showError(err))
      .finally(() => setLoadingData(false));
  }, []);

  function showError(err) {
    const message = err.message || 'API response is not ok';
    showNotification(NOTIFICATION_ERROR, 'Error', message);
  }

  function showSuccess(message) {
    showNotification(NOTIFICATION_SUCCESS, 'Success', message);
  }

  function showNotification(type, message, description) {
    return notification[type]({ message, description });
  }

  function handleToggleModal() {
    setShowModal(prevState => !prevState);
  }

  function handleDeleteReason(data) {
    const { casefileType } = props;
    confirm({
      autoFocusButton: null,
      title: (
        <Text
          strong
          style={{
            color: '#121212',
            fontSize: '16px',
          }}
        >
          Are you sure you want to delete this reason?
        </Text>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      width: 600,
      icon: <Icon type="exclamation-circle" style={{ color: 'red' }} />,
      onOk: () => {
        setLoadingData(true);
        caseFileEndpoints
          .archiveReason(casefileType.key, data.key)
          .then(response => {
            if (response) {
              setShowModal(false);
              showSuccess('Reason removed successfully');
              const updatedReasons = reasons.filter(
                task => task.key !== data.key
              );
              setReasons(updatedReasons);
            }
          })
          .catch(err => showError(err))
          .finally(() => setLoadingData(false));
      },
      onCancel: () => {},
    });
  }

  function handleSaveNewReason(label) {
    const { casefileType } = props;
    const reasonExists = reasons.find(reason => reason.title === label);

    if (reasonExists) {
      const message = `The reason with label '${label}' already exists.`;
      showNotification(NOTIFICATION_ERROR, 'Error', message);
    } else {
      setLoadingData(true);
      caseFileEndpoints
        .createCasefileTypeReason(casefileType.key, {
          label,
        })
        .then(response => {
          if (response) {
            setShowModal(false);
            showSuccess(`Reason '${label}' created successfully`);
            const newReason = {
              key: response.content.id,
              title: htmlUnescape(response.content.label),
              createdAt: response.content.createdAt,
            };
            setReasons(prevState => [newReason, ...prevState]);
          }
        })
        .catch(err => showError(err))
        .finally(() => setLoadingData(false));
    }
  }

  return (
    <PageWrapper>
      <Content>
        <Header>
          <Title>Reasons</Title>
          <Button
            type="primary"
            onClick={handleToggleModal}
            disabled={loadingData}
          >
            Add Reason
          </Button>
        </Header>
        <Divider />
        <StyledTable
          columns={tableConfig}
          dataSource={reasons}
          loading={loadingData}
        />
      </Content>
      <AddReasonModal
        onOk={handleSaveNewReason}
        onCancel={handleToggleModal}
        visible={showModal}
      />
    </PageWrapper>
  );
}

export default Reasons;
