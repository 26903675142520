export const TEMPLATE_NAME = 'name';
export const TEMPLATE_NAME_LINK = 'nameLink';
export const TEMPLATE_CREATED = 'createdAt';
export const TEMPLATE_UPDATED = 'updatedAt';
export const TEMPLATE_CHANGELOG = 'changelog';

export const NO_TITLE = 'No Title';

export const PAGE_SIZE = 50;
export const TABLE_ASC = 'ascend';
export const TABLE_DESC = 'descend';
export const ASC_PARAM = 'ASC';
export const DESC_PARAM = 'DESC';
