import React, { PureComponent } from 'react';
import { Button, Icon } from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';
import first from 'lodash/first';

import lookUpForUpdates from '../../../actions/lookup-system-updates';
import createModal from '../../../actions/create-modal';
import showModal from '../../../actions/show-modal';
import updateModal from '../../../actions/update-modal';
import hideModal from '../../../actions/hide-modal';
import deleteModal from '../../../actions/delete-modal';
import startUpdate from '../../../actions/start-system-update';
import getSystemUpdateStatus from '../../../actions/get-system-update-status';
import resetSystemUpdateState from '../../../actions/reset-system-update-state';

import Panel, {
  PanelTitle,
  PanelSection,
  PanelRow,
  Divider,
} from '../../common/panel';
import UpdatingScreen from '../updating-screen';

export class SystemUpdates extends PureComponent {
  timerId;

  componentWillMount() {
    const { dispatch } = this.props;

    this.modalId = 'system-updates';

    dispatch(
      createModal({
        id: this.modalId,
        title: 'Heads Up!',
        children: <p>&hellip;</p>,
        footer: (
          <div>
            <Button onClick={() => dispatch(hideModal(this.modalId))}>
              Cancel
            </Button>
          </div>
        ),
      })
    );
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(deleteModal(this.modalId));

    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  startPolling = version => {
    const { dispatch } = this.props;

    if (this.timerId) {
      clearInterval(this.timerId);
    }

    const seconds = 1000;

    this.timerId = setInterval(() => {
      dispatch(
        getSystemUpdateStatus(this.onUpdateStatusChange(version), () => {
          clearInterval(this.timerId);
        })
      );
    }, 15 * seconds);
  };

  startUpdate = version => {
    const { dispatch } = this.props;

    dispatch(hideModal(this.modalId));
    dispatch(startUpdate(version));

    this.startPolling(version);
  };

  lookedUpForUpdates = () => {
    const { dispatch } = this.props;

    dispatch(lookUpForUpdates());
  };

  confirmSystemUpdate = version => {
    const { dispatch } = this.props;

    dispatch(
      updateModal({
        id: this.modalId,
        title: (
          <ModalTitle>
            <Icon type="exclamation-circle" /> Heads Up!
          </ModalTitle>
        ),
        children: (
          <div>
            <p>You are about to install update V{version.version}</p>
            <p>
              This update may take some time to complete. Do not use the system
              when the update is in progress.
            </p>
          </div>
        ),
        footer: (
          <div>
            <Button onClick={() => dispatch(hideModal(this.modalId))}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => this.startUpdate(version)}>
              Start Update
            </Button>
          </div>
        ),
      })
    );

    dispatch(showModal(this.modalId));
  };

  onUpdateStatusChange = version => ({ status }) => {
    const { dispatch } = this.props;
    if (status === 'success') {
      dispatch(resetSystemUpdateState());

      dispatch(
        updateModal({
          id: this.modalId,
          title: (
            <ModalTitle>
              <Icon type="check-circle" /> Success!
            </ModalTitle>
          ),
          children: (
            <p>
              Benchmark Management System has been successfully updated to V
              {version.version}
            </p>
          ),
          footer: null,
        })
      );

      dispatch(showModal(this.modalId));
    } else if (status === 'failure') {
      dispatch(
        updateModal({
          id: this.modalId,
          title: (
            <ModalTitle>
              <Icon type="close-circle" /> System Update Failed
            </ModalTitle>
          ),
          children: (
            <div>
              <p>An error occured during the update.</p>
              <p>
                Please try again or contact Benchmark&apos;s Customer Support
                via phone 1-888-40BENCH (option 3) or email
                info@benchmarkanalytics.com.
              </p>
              <p>Please provide Error Code 203.</p>
            </div>
          ),
          footer: null,
        })
      );

      dispatch(showModal(this.modalId));
    }
  };

  render() {
    const { systemUpdates = {} } = this.props;
    const {
      lookedUp,
      lookingUp: loadingLookup,
      versions = [],
      updating,
    } = systemUpdates;
    const hasUpdates = versions.length > 0;
    const lastVersion = first(versions);

    if (updating) {
      return <UpdatingScreen version={lastVersion} />;
    }

    return (
      <Panel>
        <PanelTitle
          className={`${!lookedUp ? 'is-paddingless' : ''}`}
          right={
            <UpdatesButton>
              <Button
                onClick={this.lookedUpForUpdates}
                disabled={loadingLookup}
                icon={loadingLookup ? 'loading' : ''}
                type="primary"
                ghost
              >
                Check for Updates
              </Button>
            </UpdatesButton>
          }
        >
          <h2>System Updates</h2>
        </PanelTitle>
        {lookedUp && <Divider />}
        {lookedUp && hasUpdates && (
          <PanelSection>
            <PanelRow>
              <UpdateAvailable>
                <div className="update-changelog">
                  <h3>v{lastVersion.version}</h3>
                  <p>{lastVersion.changelog}</p>
                </div>
                <div className="update-action">
                  <Button
                    type="primary"
                    onClick={e => this.confirmSystemUpdate(lastVersion)}
                  >
                    Update
                  </Button>
                </div>
                <div className="clearfix" />
              </UpdateAvailable>
            </PanelRow>
          </PanelSection>
        )}
        {lookedUp && !hasUpdates && (
          <PanelSection>
            <PanelRow>
              <NoUpdates>No Updates Available</NoUpdates>
            </PanelRow>
          </PanelSection>
        )}
      </Panel>
    );
  }
}
const mapState = ({ systemUpdates }) => ({
  systemUpdates,
});

const UpdatesButton = styled.div`
  transform: translateY(-5px);

  @media screen and (max-width: 480px) {
    transform: translateY(0);
  }
`;

const NoUpdates = styled.h2`
  display: block;
  text-align: center;
  padding: 15px;
  color: #ccc;
`;

const UpdateAvailable = styled.div`
  position: relative;

  .update-changelog {
    padding-right: 100px;

    p {
      line-height: 1.5;
    }
  }

  .update-action {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ModalTitle = styled.div`
  .anticon {
    color: #f1c40f;

    &.anticon-check-circle {
      color: #2ecc71;
    }

    &.anticon-close-circle {
      color: #e74c3c;
    }
  }
`;

export default connect(mapState, null)(SystemUpdates);
