import styled from 'styled-components';
import { Button, Modal } from 'antd';

const StyledModal = styled(Modal)`
  &.add-note-modal {
    .ant-modal-footer {
      display: flex;
    }

    button:nth-child(2) {
      margin-left: auto;
    }
  }
`;

const RemoveButton = styled(Button)`
  &:disabled {
    background-color: #ededed;
    color: #4e4e4e;
  }
`;

export { StyledModal, RemoveButton };
