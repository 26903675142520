export default (url = '', params = {}, query = {}) => {
  const keys = params ? Object.keys(params) : [];
  const queryKeys = query ? Object.keys(query) : [];

  let queryString = queryKeys.map(key => `${key}=${query[key]}`).join('&');

  keys.forEach(key => {
    url = url.replace(`:${key}`, params[key] || 'undefined');
  });

  return queryKeys.length ? `${url}?${queryString}` : url;
};
