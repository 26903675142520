import callback from '../../utils/callback';
import OrganizationEndpoints from '../../../../api/organization/organizationEndpoints';

export default (tenantId, source, next) => dispatch => {
  return OrganizationEndpoints.getUserEmploymentHistoryConfig(tenantId, source)
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
