import React from 'react';
import UserProfileSections from './UserProfileSections';

const UserProfileCustomSections = ({
  profileForm,
  timezone,
  sectionsDefinition,
  editUserSection,
  updateUserSectionField,
  addUserSectionFieldValue,
  removeUserSectionFieldValue,
  setEditUserSectionTable,
  session,
}) => (
  <UserProfileSections
    profileForm={profileForm}
    timezone={timezone}
    sectionsDefinition={sectionsDefinition}
    editSection={editUserSection}
    updateSectionField={updateUserSectionField}
    addSectionFieldValue={addUserSectionFieldValue}
    removeSectionFieldValue={removeUserSectionFieldValue}
    setEditSectionTable={setEditUserSectionTable}
    isSectionReadOnly={false}
    session={session}
  />
);

export default UserProfileCustomSections;
