export default (text = '') => {
  const urlRegex = /^(https?|ftp):/; ///[^\s/$.?#].[^\s]*$/i;
  return text
    .split(' ')
    .map(word =>
      word.match(urlRegex)
        ? `<a href="${word}" target="_blank">${word}</a>`
        : word
    )
    .join(' ');
};
