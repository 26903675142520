import { isPlainObject, isEqual, some } from 'lodash';

import { getDebugMode } from 'APP_ROOT/enableDebugMode';
import { isTestingEnv, isDevelopmentEnv } from 'APP_ROOT/appVersion';

const encapsulateProp = prop =>
  isPlainObject(prop) || Array.isArray(prop)
    ? JSON.stringify(prop, null, 2)
    : prop;

const hasDiff = (prevProps, props) =>
  some(Object.keys(prevProps), key => !isEqual(prevProps[key], props[key]));

const showDiff = (prevProps, props) => {
  Object.keys(prevProps).forEach(key => {
    if (!isEqual(prevProps[key], props[key])) {
      if (isPlainObject(prevProps[key])) {
        console.group(key);
        showDiff(prevProps[key], props[key], key);
        console.groupEnd(key);
      } else {
        console.log(
          `"${key}" changed from ${encapsulateProp(
            prevProps[key],
          )} to ${encapsulateProp(props[key])}`,
        );
      }
    }
  });
};

const logChangedProps = (prevProps, props, name, forceInDev) => {
  if (isTestingEnv) return;

  const debugMode = getDebugMode();
  const isLoggerEnabled =
    (debugMode.loggerIsEnabled &&
      debugMode.getLoggerValue('showPropsChange') &&
      debugMode.getLoggerValue('showPropsChangeLogFor', []).includes(name)) ||
    (forceInDev && isDevelopmentEnv);

  if (!isLoggerEnabled) {
    return;
  }

  if (hasDiff(prevProps, props)) {
    console.group(name);
    showDiff(prevProps, props);
    console.groupEnd(name);
  }
};

export default logChangedProps;
