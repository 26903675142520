import styled from 'styled-components';
import { Select } from 'antd';

import { GRAY_5 } from '../../../config/theme';

const StyledSelect = styled(Select).attrs({
  className: 'bdm-input-select',
})`
  .ant-select-selection__placeholder,
  .ant-select-arrow {
    color: ${GRAY_5};
  }
`;

export default StyledSelect;
