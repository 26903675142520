import callback from '../../utils/callback';
import trainingEndpoints from '../../../../api/training/trainingEndpoints';

export default ({ tenantId, userId, source, next }) => _dispatch => {
  return trainingEndpoints
    .getUserTrainingDataAsCsv(tenantId, userId, source)
    .then(response => {
      callback(next, null, response);
    })
    .catch(error => {
      callback(next, error);
    });
};
