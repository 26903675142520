import React from 'react';
import { debounce } from 'lodash';

function useDebounce(callback, delay) {
  const callbackRef = React.useRef(callback);
  React.useLayoutEffect(() => {
    callbackRef.current = callback;
  });
  return React.useMemo(
    () => debounce((...args) => callbackRef.current(...args), delay),
    [delay]
  );
}

export default useDebounce;
