import { get, isEmpty } from 'lodash';

import getOverrides from 'APP_ROOT/utils/get-field-overrides';
import { DATE } from 'APP_ROOT/constants/fieldTypes';
import formInterface from './formInterface';
import getEnums, { getEnumsDynamic } from './getEnums';
import getGroupData from './getGroupData';
import { REPEATING_VALUE } from '../../../../modules/FormBuilder/constants/fieldAutocomplete';

const getFieldProps = (props, { onChange, onSearch, onAutocompleteSelect }) => {
  const {
    data = {},
    dataKey = '',
    field_type: type = '',
    form = formInterface,
    overrides = {},
    parentIndex = 0,
    parentKey = '',
    placeholder = '',
    title = '',
    isReviewer = false,
    readOnly = false,
    alertType = '',
    message = '',
    timezone,
  } = props;

  const getFieldOverrides = getOverrides(props, overrides);
  const options = getFieldOverrides('options', {});
  const source = get(props, 'options.source', '');
  const isSourceRepeating = source === REPEATING_VALUE;
  // when source is repeating value, we need to calculate the
  // enums dynamically, in all other cases is ok to use the
  // momoize version of getEnums
  const enums = isSourceRepeating ? getEnumsDynamic(props) : getEnums(props);
  const extraOptions =
    type === DATE && !isEmpty(props.fromSource)
      ? { useTimezoneOffset: false }
      : {};

  const fieldProps = {
    enums,
    options: Object.assign({}, extraOptions, options, {
      settings: props.settings,
    }),
    placeholder,
    isReviewer,
    readOnly,
    onChange,
    form,
    title,
    data,
    parentKey,
    parentIndex,
    dataKey,
    timezone,
    ...(type === 'alert' ? { alertType, message } : {}),
  };

  const groupData = getGroupData(props);

  if (type === 'autocomplete') {
    fieldProps['onSearch'] = onSearch(get(groupData, dataKey, ''));
    fieldProps['onSelect'] = onAutocompleteSelect(get(groupData, dataKey, ''));
  }

  return fieldProps;
};

export default getFieldProps;
