import {
  route,
  fetchRequest,
  REACT_APP_FORMS_SERVICE_URI,
  genOptions,
} from '../../utils/request';
import urlBuilder from '../../utils/url-builder';
import storage from '../../utils/storage';
import { omit } from 'lodash';

export const getAuditLogs = (tenantId, params = {}) => {
  const filteredParams = params.eventType ? params : omit(params, 'eventType');
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}audits/tenants/:tenantId`,
      { tenantId },
      {
        ...filteredParams,
      }
    )
  );
  const options = genOptions('GET', null, `Bearer ${storage.get('token')}`);
  return fetchRequest(url, options);
};
