import React, { Component } from 'react';
import { get } from 'lodash';
import { Icon } from 'antd';

import { route } from 'APP_ROOT/utils/request';
import urlBuilder from 'APP_ROOT/utils/url-builder';
import storage from 'APP_ROOT/utils/storage';

import getTemplateTypeFromFormSettings from '../getTemplateTypeFromFormSettings';
import getTrainingTaskDocument from '../getTrainingTaskDocument';

class TrainingTaskDocument extends Component {
  shouldComponentUpdate(nextProps) {
    const prevFile = this.getDocument(this.props);
    const nextFile = this.getDocument(nextProps);
    const differentIds =
      prevFile &&
      prevFile.file &&
      nextFile &&
      nextFile.file &&
      prevFile.file.id !== nextFile.file.id;

    return (prevFile && !nextFile) || (!prevFile && nextFile) || differentIds;
  }

  getDocument = ({ settings, parentIndex, templates, options }) => {
    const templateType = getTemplateTypeFromFormSettings(settings);
    return getTrainingTaskDocument({
      templateType,
      templates,
      parentIndex,
      options,
    });
  };

  getFile = task => get(task, 'file', null);

  getFilename = file => get(file, 'originalFilename', '');

  getFileDownUrl = file => get(file, 'id', '');

  getAgencyId = props => get(props, 'settings.agencyId', '');

  getFileDownUrl = task => {
    const agencyId = this.getAgencyId(this.props);
    return route(
      urlBuilder(
        '/Agencies/:agencyId/tasks/:taskId/download',
        { taskId: task.id, agencyId },
        { access_token: storage.get('minified-token') }
      )
    );
  };

  render() {
    const { className, ...props } = this.props;
    const taskDocument = this.getDocument(props);
    const file = this.getFile(taskDocument);
    const filename = this.getFilename(file);

    if (taskDocument && file) {
      return (
        <div className={className}>
          <a
            href={this.getFileDownUrl(taskDocument)}
            title={filename}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon type="paper-clip" /> {filename}
          </a>
        </div>
      );
    }

    return <div />;
  }
}

export default TrainingTaskDocument;
