import React, { Fragment } from 'react';
import { Row, Alert } from 'antd';

import FieldSelect from '../../components/FieldSelect';

import {
  CALCULATED_DEFAULT_VALUE,
  EMPTY_OPTION,
} from '../../constants/calculated';

const STYLE_OPTIONS = { width: 180 };

export default class CalculatedFieldOptions extends React.Component {
  onFieldChange = newField => {
    const { onChange, value, userProfileKeys } = this.props;
    let newValue;
    if (newField === EMPTY_OPTION.value) {
      // if key is set to empty, clean key and field
      newValue = CALCULATED_DEFAULT_VALUE;
    } else {
      newValue = { ...value, field: newField };
      if (newValue.key === CALCULATED_DEFAULT_VALUE.key) {
        // field should not be empty, set it
        // with the first available value
        newValue.key = userProfileKeys[0].value;
      }
    }

    onChange && onChange(newValue);
  };

  onKeyChange = newKey => {
    const { onChange, value } = this.props;
    onChange && onChange({ ...value, key: newKey });
  };

  render() {
    const { autocompleteFields, userProfileKeys, value } = this.props;
    const showFieldSelect = value.key !== EMPTY_OPTION.value;
    return (
      <Fragment>
        <Row></Row>
        <Row>
          <FieldSelect
            value={value.field}
            style={STYLE_OPTIONS}
            onChange={this.onFieldChange}
            selectOptions={autocompleteFields}
          />
          {showFieldSelect && (
            <FieldSelect
              value={value.key}
              style={STYLE_OPTIONS}
              onChange={this.onKeyChange}
              selectOptions={userProfileKeys}
            />
          )}
        </Row>
        <Row>
          {!showFieldSelect && (
            <Alert
              message="To set a Profile Data field you need to define an autocomplete field"
              type="info"
            />
          )}
        </Row>
      </Fragment>
    );
  }
}
