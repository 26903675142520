//Initializes Datadog RUM SDK
import { datadogRum } from '@datadog/browser-rum';

const InitializeDatadogRUM = () => {
  if (
    !process.env.REACT_APP_DATADOG_APP_ID ||
    !process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  ) {
    return;
  }

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'benchmark-website-rum',
    env: process.env.NODE_ENV,
    version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
  });
};

export default InitializeDatadogRUM;
