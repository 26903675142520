import _ from 'lodash';
import {
  GET_USER_REQUEST,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
} from './';

import {
  getUserHistory,
  getUserKMICourses,
  getUserCertifications,
} from '../api/session';
import getUserSuccess from './get-user-success';
import getUserError from './get-user-error';
import {
  certificationFilterByStatus,
  firearmCertificationFilter,
  nonFirearmCertificationFilter,
  classesFilter,
} from '../constants/filters';

import organizationEndpoints from '../api/organization/organizationEndpoints';
import { getDataFromToken } from './utils';
import configurationEndpoints from '../api/configuration/configurationEndpoints';

export default (id, organizationUnit, next = () => {}) => {
  return (dispatch, getState) => {
    const {
      session: {
        currentUser: { userId = id, permissions, userIntegrationId },
      },
    } = getState();
    const { agency } = getDataFromToken();

    dispatch({ type: GET_USER_REQUEST, payload: { userId } });

    return new Promise(async (resolve, reject) => {
      try {
        if (userId) {
          const userData = await organizationEndpoints.getUsersSearch(
            agency.id,
            { id: userId },
            false
          );
          const response = userData[0];

          const organizationalUnits = await organizationEndpoints.getOrganizationalUnitsByUserIntegrationId(
            userIntegrationId
          );

          const featureFlags = await configurationEndpoints.getTenantFeaturesSlim(
            agency.integrationId
          );

          const organizationalUnitId = organizationUnit.id;
          const hierarchyKey = organizationUnit.hierarchyKey;

          const {
            agencyId,
            email,
            fullName,
            firstName,
            lastName,
            isIzendaAdmin,
            rank,
            sworn,
            title,
          } = response;

          dispatch(
            getUserSuccess(userId, {
              userId,
              agencyId,
              agency,
              email,
              fullName,
              firstName,
              lastName,
              isIzendaAdmin,
              rank,
              sworn,
              title,
              permissions,
              organizationalUnitId,
              hierarchyKey,
              organizationalUnits,
              featureFlags,
            })
          );
          next(null, response);
          resolve(response);
        } else {
          reject();
        }
      } catch (error) {
        dispatch(getUserError(_.get(error, ['response', 'status'])));
        next(error);
        reject(error);
      }
    });
  };
};

export const getUserProfile = (userId, next = () => {}) => {
  return async (dispatch, getState) => {
    dispatch({ type: GET_USER_PROFILE_REQUEST, payload: { userId } });
    const { agency } = getDataFromToken();
    return new Promise(async (resolve, reject) => {
      try {
        const [
          response,
          history,
          firearmCertifications,
          nonFirearmCertifications,
          classes,
        ] = await Promise.all([
          organizationEndpoints.getUsersSearch(_, { id: userId }),
          getUserHistory({ userId }),
          getUserCertifications(
            userId,
            certificationFilterByStatus,
            firearmCertificationFilter
          ).catch(err => console.log(err)), // eslint-disable-line no-console
          getUserCertifications(
            userId,
            certificationFilterByStatus,
            nonFirearmCertificationFilter
          ).catch(err => console.log(err)), // eslint-disable-line no-console
          getUserCertifications(
            userId,
            certificationFilterByStatus,
            classesFilter
          ).catch(err => console.log(err)), // eslint-disable-line no-console
        ]);

        let courses = {};
        try {
          courses = await getUserKMICourses(userId);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
        }
        const userResponse = { ...response[0], agency };
        dispatch({
          type: GET_USER_PROFILE_SUCCESS,
          payload: {
            userId,
            data: userResponse,
            history,
            courses,
            firearmCertifications,
            nonFirearmCertifications,
            classes,
          },
        });
        next(null, userResponse);
        resolve(userResponse);
      } catch (error) {
        dispatch({
          type: GET_USER_PROFILE_ERROR,
          payload: _.get(error, ['response', 'status']),
        });
        next(error);
        reject(error);
      }
    });
  };
};
