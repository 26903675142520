import { createSelector } from 'reselect';
import get from 'lodash/get';

import {
  initialAgencyTemplateListState,
  initialTemplateState,
} from './ConfigureWorkflows.reducer';

export const passAgencyId = (state, agencyId) => agencyId;
export const getWorkflowsReducer = state => state.manageWorkflows;
export const getTemplateParams = (
  { manageWorkflows },
  agencyId,
  templateId
) => ({
  manageWorkflows,
  agencyId,
  templateId,
});

export const getTemplates = createSelector(
  getWorkflowsReducer,
  manageWorkflows =>
    get(manageWorkflows, ['templates'], initialAgencyTemplateListState)
);

export const getTemplate = createSelector(
  getTemplateParams,
  ({ manageWorkflows, templateId, agencyId }) =>
    get(
      manageWorkflows,
      ['templateWorkflows', `${agencyId}.${templateId}`],
      initialTemplateState
    )
);
