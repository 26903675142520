export default class FieldTemplate {
  props = {};

  constructor(type = '', props = {}) {
    this.type = type;
    this.props = props;
  }

  setType = (type = '') => {
    this.type = type;
  };

  setProps = (props = {}) => {
    this.props = {
      ...this.props,
      ...props,
    };
  };

  getProps = () => {
    return this.props;
  };

  toJSON = () => {
    return {
      type: 'field',
      field_type: this.type,
      ...this.getProps(),
    };
  };
}
