import {
  GET_AGENCY_SECURITY_ROLES_FULFILLED,
  GET_AGENCY_SECURITY_ROLES_REJECTED,
  GET_AGENCY_SECURITY_ROLES_REQUEST,
} from '../AgencyProfile.actionTypes';
import securityV2Endpoints from '../../../../api/security/securityV2Endpoints';

export default (agencyId, isInternal) => dispatch => {
  dispatch({
    type: GET_AGENCY_SECURITY_ROLES_REQUEST,
    payload: { agencyId },
  });

  return securityV2Endpoints
    .getSecurityRolesByAgencyId(agencyId, isInternal)
    .then(response =>
      dispatch({
        type: GET_AGENCY_SECURITY_ROLES_FULFILLED,
        payload: { agencyId, response },
      })
    )
    .catch(error =>
      dispatch({
        type: GET_AGENCY_SECURITY_ROLES_REJECTED,
        payload: { agencyId, error },
      })
    );
};
