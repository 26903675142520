import React, { useEffect, useState } from 'react';
import get from 'lodash/get';

import PageHeader from 'APP_COMPONENTS/PageHeader';
import DashboardPage from '../../../components/dashboard';
import withoutClutter from '../../../components/without-clutter';
import getRoute from '../../../utils/get-route';
import CommunityTable from './CommunityTable';
import getCommunityList from '../../../actions/organizations/get-community-list';
import { StyledRowPagination, StyledPagination } from './CommunityTable.styled';
import { Button } from 'antd';
import { hasPermissions } from 'APP_ROOT/utils/admin';

const TITLE = 'Community';
const Community = ({
  dispatch,
  pageSize = 50,
  tenantId,
  communityList,
  manageCommunityMember,
}) => {
  const { total } = communityList;
  const currentDefaultForPagination = 1;
  const [currentPage, setCurrentPage] = useState(1);
  let pageNumber = currentPage - 1;

  useEffect(() => {
    dispatch(getCommunityList(tenantId, '', pageNumber, pageSize));
  }, [currentPage]);

  const changePage = page => {
    setCurrentPage(page);
  };

  const actions = [
    <Button
      key="submit"
      type="primary"
      size="default"
      icon="plus"
      disabled={false}
      htmlType="submit"
      href={getRoute('createCommunityDetail')}
    >
      Add Member
    </Button>,
  ];

  return (
    <>
      <PageHeader
        title={TITLE}
        goBackTo={getRoute('dashboard')}
        actions={manageCommunityMember ? actions : []}
      />
      <CommunityTable communityList={communityList} />
      {total > 0 && (
        <StyledRowPagination>
          <StyledPagination
            defaultCurrent={currentDefaultForPagination}
            current={currentPage}
            total={total}
            pageSize={pageSize}
            onChange={changePage}
          />
        </StyledRowPagination>
      )}
    </>
  );
};

const mapState = (state, props) => ({
  manageCommunityMember: hasPermissions(
    props.permissions,
    'manageCommunityMember'
  ),
  tenantId: get(props, ['agency', 'integrationId']),
  communityList: get(state, ['organization', 'community']),
  dispatch: props.dispatch,
});

export default withoutClutter(DashboardPage(mapState, null)(Community));
