import React from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber,
  message,
} from 'antd';

import { PanelSectionTable, PanelRow } from '../../../components/common/panel';
import {
  StyledCollapse,
  StyledPanel,
  StyledPanelTitle,
} from 'APP_COMPONENTS/custom-sections/sections/Sections.styled';
import { TableWrapper } from './CommunityMemberForm.styled';
import TextArea from 'antd/lib/input/TextArea';
import { PhoneNumberField } from 'APP_COMPONENTS/form-viewer/field-types/input-masked';
import { states } from '../../../utils/states-list/states';
import { ethnicity } from '../../../utils/community-Member-Lists.js/ethnicity';
import { race } from '../../../utils/community-Member-Lists.js/race';
import { gender } from '../../../utils/community-Member-Lists.js/gender';
import { veteranStatus } from '../../../utils/community-Member-Lists.js/veteran-status';
import { language } from '../../../utils/community-Member-Lists.js/language';
import { mapDataForCreate, mapDataForView } from './communityUtils';
import createCommunityMember from './actions/Create-Community-Member';
import PageHeader from '../../../components/PageHeader/PageHeader';
import getRoute from '../../../utils/get-route';
import { BENCHMARK_DATE_FORMAT } from '../../../utils/parse-date';
import getCommunityList from '../../../actions/organizations/get-community-list';
import updateCommunityMember from './actions/Update-Community-Member';

const { Option } = Select;

class CommunityMemberForm extends React.Component {
  constructor() {
    super();

    this.state = { isDisabled: false, communityMemberId: null };
  }

  async componentDidMount() {
    const { communityMemberId } = this.props;

    this.setState({ communityMemberId: communityMemberId });

    this.props.manageCommunityMember
      ? this.setState({ isDisabled: false })
      : this.setState({ isDisabled: true });

    await this.loadData();
    this.props.form.setFields(mapDataForView(this.props.communityList.data[0]));
  }

  loadData = async () => {
    await this.props.dispatch(
      getCommunityList(this.props.tenantId, this.props.communityMemberId, 0, 1)
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        message.error('There was an error creating community member.');
        return;
      }
      let formattedData = mapDataForCreate(values);
      this.saveData(
        this.props.tenantId,
        this.state.communityMemberId,
        formattedData
      );
    });
  };

  returnSelectOptions(list) {
    const { isDisabled } = this.state;
    return list.map((l, key) => (
      <Option key={key} value={l.value} disabled={isDisabled}>
        {l.value}
      </Option>
    ));
  }

  saveData = async (tenantId, integrationId, data) => {
    if (this.state.communityMemberId) {
      await updateCommunityMember(tenantId, integrationId, data)
        .then(response => {
          message.success('Successfully updated Community Member');
        })
        .catch(() => {
          message.error(
            'There was an error updating Community Member please try again.'
          );
        });
    } else {
      await createCommunityMember(tenantId, data)
        .then(response => {
          message.success('Successfully created Community Member');
          this.props.history.push(`/community/view/${response.content.id}`);
        })
        .catch(() => {
          message.error(
            'There was an error creating Community Member please try again.'
          );
        });
    }
  };

  setHeaderButtons = () => {
    let headerButtons = '';
    if (this.props.manageCommunityMember)
      headerButtons = [
        <Button
          key="cancel"
          type="secondary"
          size="default"
          onClick={() => {
            this.props.history.push('/community');
          }}
          htmlType="submit"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          size="default"
          onClick={e => this.handleSubmit(e)}
          htmlType="submit"
        >
          Save
        </Button>,
      ];
    return headerButtons;
  };

  render() {
    const {
      form: { getFieldDecorator },
      communityMemberId = '',
    } = this.props;
    const { isDisabled } = this.state;
    return (
      <TableWrapper>
        <PageHeader
          title={
            communityMemberId
              ? 'Community Member Detail'
              : 'New Community Member'
          }
          goBackTo={getRoute('community')}
          actions={this.setHeaderButtons()}
        />
        <StyledCollapse
          bordered={false}
          expandIconPosition="right"
          defaultActiveKey="CommunityMemberInfo"
        >
          <StyledPanel
            header={
              <StyledPanelTitle size="large">
                Community Member Info
              </StyledPanelTitle>
            }
            key="CommunityMemberInfo"
            className="section-panel"
          >
            <div className="divider" />
            <PanelSectionTable>
              <PanelRow>
                <Row type="flex" align="middle">
                  <Col span={12}>
                    <Form.Item
                      label="First Name"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('firstName')(
                        <Input readOnly={isDisabled} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Middle Name"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('middleName')(
                        <Input readOnly={isDisabled} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" align="middle">
                  <Col span={12}>
                    <Form.Item
                      label="Last Name"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('lastName', {
                        rules: [
                          {
                            required: true,
                            message:
                              'Last Name is required to create a Community Member',
                          },
                        ],
                      })(<Input readOnly={isDisabled} />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Alternate Name"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('alternateName')(
                        <Input readOnly={isDisabled} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" align="middle">
                  <Col span={12}>
                    <Form.Item
                      label="Driver’s License Number"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('driversLicenceNo')(
                        <Input readOnly={isDisabled} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Gender"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('gender')(
                        <Select span={6}>
                          {this.returnSelectOptions(gender)}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" align="middle">
                  <Col span={12}>
                    <Form.Item
                      label="Date of Birth"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('birthday', {
                        rules: [
                          {
                            type: 'object',
                            required: false,
                            message: 'Please input valid Date',
                            whitespace: true,
                          },
                        ],
                      })(
                        <DatePicker
                          format={BENCHMARK_DATE_FORMAT}
                          disabled={isDisabled}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Race"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('race')(
                        <Select span={6}>
                          {this.returnSelectOptions(race)}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" align="middle">
                  <Col span={12}>
                    <Form.Item
                      label="Veteran Status"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('veteranStatus')(
                        <Select span={6}>
                          {this.returnSelectOptions(veteranStatus)}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Ethnicity"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('ethnicity')(
                        <Select span={6}>
                          {this.returnSelectOptions(ethnicity)}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" align="middle">
                  <Col span={12} />
                  <Col span={12}>
                    <Form.Item
                      label="Preferred Language"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('preferredLanguage')(
                        <Select span={6}>
                          {this.returnSelectOptions(language)}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </PanelRow>
            </PanelSectionTable>
          </StyledPanel>
        </StyledCollapse>
        <StyledCollapse
          bordered={false}
          expandIconPosition="right"
          defaultActiveKey="ContactInfo"
        >
          <StyledPanel
            header={
              <StyledPanelTitle size="large">Contact Info</StyledPanelTitle>
            }
            key="ContactInfo"
            className="section-panel"
          >
            <div className="divider" />
            <PanelSectionTable>
              <PanelRow>
                <Row type="flex" align="middle">
                  <Col span={12}>
                    <Form.Item
                      label="Address Line 1"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('address1')(
                        <Input readOnly={isDisabled} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Phone Number"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('phoneNumber', {
                        rules: [
                          {
                            pattern: new RegExp(
                              /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
                            ),
                            message: 'Phone must be 10 digits',
                          },
                        ],
                      })(<PhoneNumberField readOnly={isDisabled} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" align="middle">
                  <Col span={12}>
                    <Form.Item
                      label="Address Line 2"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('address2')(
                        <Input readOnly={isDisabled} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Email"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('emailAddress', {
                        rules: [
                          {
                            type: 'email',
                            message: 'The input is not valid E-mail!',
                          },
                        ],
                      })(<Input readOnly={isDisabled} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" align="middle">
                  <Col span={12}>
                    <Form.Item
                      label="City"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('city')(
                        <Input readOnly={isDisabled} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="State"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('state')(
                        <Select span={6}>
                          {this.returnSelectOptions(states)}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex" align="middle">
                  <Col span={12}>
                    <Form.Item
                      label="Zip"
                      name="zip"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('zip', {
                        rules: [
                          {
                            pattern: new RegExp(/^\d{5}$/),
                            message: 'Zip code must be 5 digits',
                          },
                        ],
                      })(<InputNumber maxLength="5" readOnly={isDisabled} />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Notes"
                      labelCol={{ span: 6 }}
                      wrapperCol={{ span: 16 }}
                    >
                      {getFieldDecorator('notes')(
                        <TextArea readOnly={isDisabled} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </PanelRow>
            </PanelSectionTable>
          </StyledPanel>
        </StyledCollapse>
      </TableWrapper>
    );
  }
}
export default CommunityMemberForm;
