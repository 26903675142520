import { capitalize } from 'lodash';

import * as COMPONENT_TYPES from '../../../constants/layoutComponentTypes';
import * as FIELD_TYPES from '../../../constants/fieldTypes';
import BLACKLISTED_FIELDS from '../../../constants/formFieldTypesBlacklist';
import BLACKLISTED_COMPONENTS from '../../../constants/formComponentTypesBlacklist';
import getFieldLabel from '../utils/getFieldLabel';

export default Object.values(FIELD_TYPES)
  .filter(fieldType => !BLACKLISTED_FIELDS.includes(fieldType))
  .reduce(
    (componentList, fieldType) =>
      componentList.concat({
        type: COMPONENT_TYPES.FIELD,
        label: capitalize(fieldType),
        field_type: fieldType,
        icon: fieldType,
      }),
    []
  )
  .concat(
    Object.values(COMPONENT_TYPES)
      .filter(componentType => !BLACKLISTED_COMPONENTS.includes(componentType))
      .reduce(
        (componentList, componentType) =>
          componentList.concat({
            type: componentType,
            label: getFieldLabel(componentType),
            icon: componentType,
          }),
        []
      )
  );
