import conditionsComplies from './getConditionsComplies';

const isConditionallyRequired = (props, rule) => {
  const { data = {}, dataKey = '' } = props;

  if (Array.isArray(rule.mustExist)) {
    return (
      rule.mustExist.some(me => me.includes(dataKey)) &&
      conditionsComplies(props, rule, data)
    );
  } else {
    return (
      rule.mustExist &&
      rule.mustExist.includes(dataKey) &&
      conditionsComplies(props, rule, data)
    );
  }
};

export default isConditionallyRequired;
