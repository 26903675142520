import { get, last, findIndex, isEqual, has } from 'lodash';
import MaskedFieldOption from './getMaskedFieldOptions';

import {
  MASK_TYPE,
  MASK_TYPE_LABEL,
  PHONE_NUMBER,
  PHONE_NUMBER_LABEL,
  EMAIL,
  EMAIL_LABEL,
  SSN_TYPE,
  SSN_LABEL,
  SIMPLE_TYPE,
  OPTIONS_TYPE,
  PATTERN,
  STRING,
  REGEX_TYPE,
  REGEX_LABEL,
  REGEX_FLAGS,
} from '../../constants/fieldMasked';
import {
  REG_EXP_PATTERNS,
  REG_EXP_PATTERNS_STRING,
} from '../../constants/fieldPatterns';
import { CASE_NUMBER } from '../../constants/constants';

export default class {
  constructor() {
    this.name = MASK_TYPE;
    this.label = MASK_TYPE_LABEL;
    this.component = MaskedFieldOption;
    this.defaultValue = { option: PHONE_NUMBER, regex: '' };
    this.selectOptions = [
      {
        value: PHONE_NUMBER,
        label: PHONE_NUMBER_LABEL,
        pattern: REG_EXP_PATTERNS[PHONE_NUMBER],
        validations: {
          type: STRING,
          pattern: REG_EXP_PATTERNS_STRING[PHONE_NUMBER],
        },
      },
      {
        value: EMAIL,
        label: EMAIL_LABEL,
        pattern: REG_EXP_PATTERNS[EMAIL],
        validations: {
          type: STRING,
          pattern: REG_EXP_PATTERNS_STRING[EMAIL],
        },
      },
      {
        value: SSN_TYPE,
        label: SSN_LABEL,
        pattern: REG_EXP_PATTERNS[SSN_TYPE],
        validations: {
          type: STRING,
          pattern: REG_EXP_PATTERNS_STRING[SSN_TYPE],
        },
      },
      {
        value: REGEX_TYPE,
        label: REGEX_LABEL,
        pattern: '',
        validations: {
          type: STRING,
          pattern: '',
        },
      },
    ];
  }

  isValidRegex = regex => {
    let isValid = true;
    if (!regex) {
      isValid = false;
    } else {
      try {
        new RegExp(regex, REGEX_FLAGS);
      } catch (e) {
        isValid = false;
      }
    }

    return isValid;
  };

  isValid = values => {
    const { option, regex } = values[this.name];

    return option === REGEX_TYPE ? this.isValidRegex(regex) : true;
  };

  onChange = (value, values) => {
    const { option } = value;
    let caseNumber = {};
    if (has(values, CASE_NUMBER) && option !== REGEX_TYPE) {
      caseNumber[CASE_NUMBER] = false;
    }
    return {
      ...values,
      [this.name]: value,
      ...caseNumber,
    };
  };

  onSave = values => {
    const {
      options,
      [this.name]: { option: value, regex },
    } = values;
    let pattern;
    let validations = {};

    const selectedOption = this.selectOptions.find(
      option => option.value === value
    );

    if (selectedOption.value === REGEX_TYPE) {
      pattern = regex;
      validations = {
        ...selectedOption.validations,
        pattern,
      };
    } else {
      pattern = selectedOption.pattern;
      validations = selectedOption.validations;
    }

    return {
      ...values,
      [this.name]: undefined,
      validations,
      options: {
        ...options,
        [this.name]: SIMPLE_TYPE,
        pattern,
      },
    };
  };

  getInitialValue = values => {
    if (values.options === undefined) {
      return this.defaultValue;
    }

    const pattern = get(values, `${OPTIONS_TYPE}.${PATTERN}`);

    if (pattern === undefined) {
      return this.defaultValue;
    }

    const index = findIndex(this.selectOptions, option =>
      isEqual(option.pattern, pattern)
    );
    // if index == -1 means it is a custom regex (last option)
    const selectedOption =
      index === -1 ? last(this.selectOptions) : this.selectOptions[index];

    return {
      option: selectedOption.value,
      regex: index === -1 ? pattern : '',
    };
  };
}
