import React from 'react';
import { Steps } from 'antd';
import { omit } from 'lodash';

import emitter, { EventTypes } from 'APP_ROOT/utils/eventEmitter';

const Step = Steps.Step;

const setTab = tab => e => emitter.emit(EventTypes.SET_TAB, tab);
const getValidProps = props =>
  omit(props, [
    'dataEnums',
    'dataKey',
    'index',
    'isFirst',
    'isLast',
    'onClick',
    'onSubmit',
    'overrides',
    'presentation',
    'properties',
    'settings',
    'tab',
    'title',
    'validations',
    'wrappedComponentRef',
    'isReviewer',
    'templates',
    'timezone',
    'tabsRefs',
    'formHeader',
    'options',
    'nodeId',
    'storeSubscription',
    'reportingKey',
  ]);

const Tab = props => {
  const { index = '', title = '' } = props;

  const tabProps = getValidProps(props);

  return (
    <Step key={index} title={title} onClick={setTab(index)} {...tabProps} />
  );
};

export default Tab;
