import resource from './resource';

export default {
  ...resource,
  per_page: 10,
  filter: {
    where: { actionItem: false },
    order: 'created_at DESC',
    limit: 10,
  },
  unread_count: 0,
};
