import * as Rx from 'rxjs';
import { findIndex, first } from 'lodash';

import FormComponent from '../models/FormComponent';
import {
  TAB,
  SECTION_WRAPPER,
  SECTION,
  ROW,
  COLUMN,
} from 'APP_ROOT/constants/layoutComponentTypes';
import getFieldLabel from '../utils/getFieldLabel';

// import actionTypes from '../constants/tabsManager';
import componentsManager from './componentsManager';
import getColumnOptionsByColumnNumber from '../utils/getColumnOptionsByColumnNumber';

class TabsManager {
  constructor() {
    this.tabsManager$ = new Rx.BehaviorSubject([]);
    this.activeTab$ = new Rx.BehaviorSubject(null);
  }

  hydrate(tabs, { resetFocus = true }) {
    const firstTab = first(tabs);

    if (firstTab.type === TAB) {
      this.tabsManager$.next(tabs);

      if (resetFocus) {
        this.setActiveTab(firstTab.id);
      }
    } else {
      this.clear();
    }
  }

  push() {
    return this.tabsManager;
  }

  existsTab(tabId) {
    return this.tabsManager.filter(t => t.id === tabId).length > 0;
  }

  setActiveTab(tabId) {
    if (this.activeTab !== tabId && this.existsTab(tabId)) {
      this.activeTab$.next(tabId);
    }
  }

  clear() {
    this.tabsManager$.next([]);
    this.activeTab$.next(null);
  }

  getTabsManager() {
    return this.tabsManager$.asObservable();
  }

  getActiveTab() {
    return this.activeTab$.asObservable();
  }

  addTab() {
    const columnOptions = getColumnOptionsByColumnNumber(2);
    const rnd = Math.trunc(Math.random() * 1000);
    const key = `${TAB}_${rnd}`;

    componentsManager.hydrate(
      componentsManager.fields.concat(
        new FormComponent(
          TAB,
          {
            key,
            title: `${getFieldLabel(TAB)} ${this.tabsManager.length + 1}`,
          },
          [
            new FormComponent(SECTION_WRAPPER, null, [
              new FormComponent(SECTION, { columns: 2 }, [
                new FormComponent(ROW, null, [
                  new FormComponent(COLUMN, columnOptions),
                  new FormComponent(COLUMN, columnOptions),
                ]),
              ]),
            ]),
          ]
        )
      ),
      {
        resetFocus: true,
        setActiveTabIndex: componentsManager.fields.length,
      }
    );
  }

  deleteTab(tab) {
    let indexToActive = this.tabsManager.findIndex(t => t.id === tab.id);

    if (indexToActive === this.tabsManager.length - 1) {
      indexToActive = indexToActive - 1;
    } else if (indexToActive < this.tabsManager.length - 1) {
      const { activeTabIndex } = this;
      indexToActive =
        indexToActive < activeTabIndex ? activeTabIndex - 1 : activeTabIndex;
    }

    indexToActive = indexToActive < 0 ? 0 : indexToActive;
    componentsManager.removeComponent(tab.id);
    componentsManager.hydrate(componentsManager.fields, {
      resetFocus: true,
      setActiveTabIndex: indexToActive,
    });
  }

  sortTabs(order) {
    componentsManager.sortTabs(order);
  }

  tabsSize() {
    return this.tabsManager.length;
  }

  get tabsManager() {
    return this.tabsManager$.value;
  }

  get activeTab() {
    return this.activeTab$.value;
  }

  get activeTabIndex() {
    return this.activeTab
      ? findIndex(this.tabsManager, { id: this.activeTab })
      : -1;
  }
}

export default new TabsManager();
