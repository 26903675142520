import styled from 'styled-components';
import { AutoComplete } from 'antd';

import { GRAY_5 } from '../../../config/theme';

const StyledAutoComplete = styled(AutoComplete).attrs({
  className: 'bdm-input-select',
})`
  .ant-select-selection__placeholder {
    color: ${GRAY_5};
  }
`;

export const StyledStatus = styled.div`
  .status {
    display: ${props => (props.showStatus ? 'block' : 'none')};
    color: rgba(78, 78, 78, 0.8);
    min-height: 19px;
    margin-top: -2px;
    font-size: 12px;
    line-height: 1.5;
  }
`;

export default StyledAutoComplete;
